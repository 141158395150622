import React, { useEffect, useState } from "react";
import {
  pageLoadEnd,
  pageLoadStart,
  recordLoadEnd,
  recordLoadStart,
} from "../../../redux/actions/appActions";
// import axios from "../../src/axios-interceptor";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import logo from "./../../../assets/img/avatars/modal-logo.png";

import { toastr } from "react-redux-toastr";
import classnames from "classnames";
import Spinner from "../../../components/Spinner";
import _, { cloneDeep } from "lodash";
import update from "immutability-helper";
import {
  Input,
  FormGroup,
  Modal,
  Nav,
  NavItem,
  NavLink,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  ModalFooter,
  Button,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Table from "reactstrap/lib/Table";
import { cancel } from "redux-saga/effects";
import { setMainConfig } from "react-doc-viewer/build/state/actions";
import axios from "../../../axios-interceptor";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
// import { NavLink } from "react-router-dom";
import {
  faCalendarAlt,
  faCopy,
  faRefresh,
  faChevronCircleRight,
  faTimesCircle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { proposalListRedirectToEdit } from "../../../redux/actions/proposal/Actions";
import { parse } from "postcss";
import * as proposalActions from "../../../redux/actions/proposal/Actions";

function VbmCopyToallMarket({ MarketCopy, setMarketCopy, Vendor }) {
  const dispatch = useDispatch();

  const userRecord = useSelector(({ user }) => user.userRecord);

  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const projects = useSelector(({ projects }) => projects.items);
  let vendorreducer = useSelector(({ vendorReducer }) => vendorReducer);
  const app = useSelector(({ app }) => app);
  const cancelPopUp = () => {
    setMarketCopy(!MarketCopy);
  };
  var project = useSelector(({ currentProject }) => currentProject.newProject);
  const splitdata = useSelector(({ vendorReducer }) => vendorReducer.SplitData);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const [allMarketCopying, setAllMarketCopying] = useState({});
  const [saveAndNext, setSaveAndNext] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([
    { value: 1, label: "Additional Information From RFQ" },
    { value: 2, label: "Additional Information From SUD" },
    { value: 3, label: "Bidding Details" },
    { value: 4, label: "Recommended Vendors" },
  ]);

  useEffect(() => {
    if (
      allMarketCopying.SelectedFromCountries &&
      allMarketCopying.SelectedFromCountries.label
    ) {
      let methodology = project?.CostingProfiles[
        project.CostingProfiles.length - 1
      ].CountrySpecs?.filter(
        (c) => allMarketCopying.SelectedFromCountries.label == c.CountryName
      )[0].MethodologySpecs.map((meth) => ({
        label: meth.Label,
        value: meth.id,
      }));

      setAllMarketCopying((prevState) => ({
        ...prevState,
        SelectedFromMethodologies: methodology,
      }));

      setAllMarketCopying((prevState) => ({
        ...prevState,
        SelectedCategories: categoryOptions,
      }));
    }
  }, [allMarketCopying.SelectedFromCountries]);

  function copyVendorCost(from, to, sourceVendor) {
    let CostingOptionData = [];
    let data;
    to.CostingOptions.forEach((a, i) => {
      data = {};
      data.History = [];
      data.CostOptionId = a.id;
      // data.CostingsData = (sourceVendor.VendorCosts[i]?.CostingsData)?(sourceVendor.VendorCosts[i]?.CostingsData):[];
      data.CostingsData = [];
      data.IsInvited = true;
      data.IsSelected = false;
      data.SelectedDate = "";
      data.IsEditable = true;
      data.Comments = "";
      data.AchivableSamples = 0;
      data.SampleDistributionDetailsId = null;
      data.RoundId =
        _.last(
          splitdata
            .filter((s) => s.id)
            ?.filter((m) => m.MethodologySpecId == to.id)
        )?.SampleDistributionMasters?.filter(
          (c) => c.CostingOptionId == a.id
        )[0]?.SampleDistributionDetails?.length == 0
          ? _.last(
              splitdata
                .filter((s) => s.id)
                ?.filter((m) => m.MethodologySpecId == to.id)
            ).id
          : null;
      CostingOptionData.push(data);
    });
    return CostingOptionData;
  }
  function defaultvalueforcurrency(fromcurrency,indexCountry) {
    var currdata = [];
    var curr =
      project.CostingProfiles[project.CostingProfiles.length - 1] &&
        project.CostingProfiles[project.CostingProfiles.length - 1]?.CountrySpecs.length > 0
        ? project.CostingProfiles[
          project.CostingProfiles.length - 1
        ]?.ProfileSetting.CurrenciesData.find(
          (c) =>
            c.Code ==
            project.CostingProfiles[project.CostingProfiles.length - 1]?.CountrySpecs[
              indexCountry
            ].CountryCode
        )
        : "";
    var commcountrycurr =
      project.CostingProfiles[project.CostingProfiles.length - 1] &&
        project.CostingProfiles[project.CostingProfiles.length - 1]?.CountrySpecs.length > 0
        ? project.CostingProfiles[project.CostingProfiles.length - 1]?.CountrySpecs[
          indexCountry
        ].CountryCode != project.CommissioningCountry
          ? project.CostingProfiles[
            project.CostingProfiles.length - 1
          ]?.ProfileSetting.CurrenciesData.find(
            (c) => c.Code == project.CommissioningCountry
          )
          : ""
        : "";

    //project.CommissioningCountry;
    if (curr != "" && curr != undefined) {
      curr.AllCurrencyUnit = codeLabels.FieldingCountriesOptions.find(
        (fc) => fc.Code == curr.Code
      ).AllCurrencyUnit;
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = curr.AllCurrencyUnit
        ? curr.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          currdata.push({
            id: i + 1,
            label: all,
            value: i + 1,
            code: curr.Code,
            ConversionRateToLocal: project.CostingProfiles[
              project.CostingProfiles.length - 1
            ]?.ProfileSetting.CurrenciesData.filter(
              (curr) => curr.CurrencyUnit == all
            )[0].ConversionRateToLocal,
          });
        });
      }
    }
    if (commcountrycurr != "" && commcountrycurr != undefined) {
      var currlength = currdata.length;
      commcountrycurr.AllCurrencyUnit =
        codeLabels.FieldingCountriesOptions.find(
          (fc) => fc.Code == commcountrycurr.Code
        ).AllCurrencyUnit;
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = commcountrycurr.AllCurrencyUnit
        ? commcountrycurr.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          if (!currdata.find((a) => a.label == all)) {
            currdata.push({
              id: currlength + i + 1,
              label: all,
              value: currlength + i + 1,
              code: commcountrycurr.Code,
              ConversionRateToLocal: project.CostingProfiles[
                project.CostingProfiles.length - 1
              ]?.ProfileSetting.CurrenciesData.filter(
                (curr) => curr.CurrencyUnit == all
              )[0].ConversionRateToLocal,
            });
          }
        });
      }
    }
    var defaultvalue = 
    currdata?.map(a=>a.label)?.includes(fromcurrency?.label)?fromcurrency:    
    currdata.find((a) => a.label == curr.CurrencyUnit);

    return defaultvalue;
  }


  function copy() {
    dispatch(recordLoadStart());
    var projectTemp = _.cloneDeep(project);
    let fromCS = project?.CostingProfiles[
      project.CostingProfiles.length - 1
    ].CountrySpecs.filter(
      (c) => allMarketCopying.SelectedFromCountries.label == c.CountryName
    );
    let toCS = project?.CostingProfiles[
      project.CostingProfiles.length - 1
    ].CountrySpecs.filter((c) =>
      allMarketCopying.SelectedToCountries.map((a) => a.label).includes(
        c.CountryName
      )
    );
    let fromMS = fromCS[0].MethodologySpecs.filter((sm) =>
      allMarketCopying.SelectedFromMethodologies.map((a) => a.label).includes(
        sm.Label
      )
    );

    projectTemp?.CostingProfiles[
      projectTemp.CostingProfiles.length - 1
    ].CountrySpecs.forEach((fc, fci) => {
      fc.MethodologySpecs.filter(
        (sm) => sm.CostingType == 3 && !sm.NotApplicable
      ).forEach((meth) => {
        if (!meth.BiddingDetail) {
          meth.BiddingDetail = {};
        }
        if (
          toCS.map((a) => a.CountryName).includes(fc.CountryName) &&
          fromMS.map((a) => a.Label).includes(meth.Label)
        ) {
          let fromMethodology = fromMS.find((m) => m.Label == meth.Label);
          if (
            allMarketCopying.SelectedCategories.find(
              (category) => category.label == "Additional Information From RFQ"
            ) &&
            fromMethodology.BiddingDetail?.SelectedRFQ?.length > 0
          ) {
            meth.BiddingDetail.SelectedRFQ = [];
            fromMethodology.BiddingDetail.SelectedRFQ?.map((selRFQ) => {
              if (meth.Variables.map((v) => v.id).includes(selRFQ.key)) {
                selRFQ.Comments = "";
                meth.BiddingDetail.SelectedRFQ.push(selRFQ);
              }
            });
          }
          if (
            allMarketCopying.SelectedCategories.find(
              (category) => category.label == "Additional Information From SUD"
            ) &&
            fromMethodology.BiddingDetail?.SelectedSUD?.length > 0
          ) {
            meth.BiddingDetail.SelectedSUD = [];
            fromMethodology.BiddingDetail.SelectedSUD?.map((selSUD) => {
              if (
                meth.OpResourcesVariables.map((v) => v.id).includes(selSUD.key)
              ) {
                selSUD.Comments = "";
                meth.BiddingDetail.SelectedSUD.push(selSUD);
              }
              if (
                selSUD.key == "trackingProject" &&
                meth.CostingOptions?.filter((a) => a.Selected)[0]
                  ?.CostingOptionData?.trackingProject
              ) {
                selSUD.Comments = "";
                meth.BiddingDetail.SelectedSUD.push(selSUD);
              }
            });
          }
          if (
            allMarketCopying.SelectedCategories.find(
              (category) => category.label == "Recommended Vendors"
            ) &&
            fromMethodology.InvitedVendors.length > 0
          ) {
            // meth.InvitedVendors = [];
            let destinationIvId = meth.InvitedVendors.map((id) => id.VendorId);
            if (
              fromMethodology.BiddingDetail.IsFullService !=
              meth.BiddingDetail.IsFullService
            ) {
              meth.InvitedVendors = [];
              destinationIvId = [];
            }
            fromMethodology.InvitedVendors.filter((i) => !i.isDeleted)
              .map((iv) => {
                if(!destinationIvId.includes(iv.VendorId)){
                let condition = Vendor.find(
                  (v) =>
                    v.label == iv.label &&
                    v.Methodology.map((m) => m.Code).includes(meth.Code) &&
                    v.Countries.map((C) => C.Code).includes(fc.CountryCode)
                );
                if (condition) {
                  let tempiv = {
                    ...iv,
                    CostHistory: [],
                    MethodologySpecId: meth.id,
                    CostOptionId: null,
                    SampleDistributionDetailsId: null,
                    RoundId: null,
                    SelectedRows:iv.SelectedRows,
                    VendorCosts: copyVendorCost(fromMethodology, meth, iv),
                  };
                  delete tempiv.id;
                  meth.InvitedVendors.push(tempiv);
                }}
                else
                {  meth.InvitedVendors.forEach(a=>{if(a.VendorId==iv.VendorId){
                    a.SelectedRows=iv.SelectedRows;
                    a.VendorCosts?.forEach(co => co.CostingsData?.forEach(cd => {
                          cd.selected = iv.SelectedRows.filter(sr=>sr.id==cd.id)[0].selected
                  }))
                  }})
                }
              });
          }
          if (
            allMarketCopying.SelectedCategories.find(
              (category) => category.label == "Bidding Details"
            ) &&
            fromMethodology.BiddingDetail
          ) {
            meth.BiddingDetail = {
              ...meth.BiddingDetail,
              QCValidationMode: fromMethodology.BiddingDetail?.QCValidationMode
                ? fromMethodology.BiddingDetail.QCValidationMode
                : null,
              OtherProjectSpecification: fromMethodology.BiddingDetail
                ?.OtherProjectSpecification
                ? fromMethodology.BiddingDetail.OtherProjectSpecification
                : null,
              IsFullService: fromMethodology.BiddingDetail?.IsFullService,
              Currency: defaultvalueforcurrency(fromMethodology?.BiddingDetail?.Currency,fci),
            };
          }
        }
      });
    });
    console.log(projectTemp);
    updateproject(projectTemp);
    dispatch(recordLoadEnd());
    cancelPopUp();
    toastr.success("Copied Successfully!");
  }
  function updateproject(newProposal) {
    // project.CostingProfiles[project?.CostingProfiles?.length - 1] = newProposal;
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: newProposal,
    });
  }
  var imgStyle = {
    width: "150px",
  };
  var roundclosedstyle = {
    color: "#b94e48",
    marginBottom: "5px",
  };
  var ModalFooterStyle = {
    display: "flex",
    justifyContent: "flex-Start",
  };

  return (
    <>
      <Modal
        isOpen={MarketCopy}
        backdrop="static"
        toggle={cancelPopUp}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={cancelPopUp}>
          {/* <img src={logo} className="modallogo" /> */}
          <h1> </h1>
          <h3>Copy Accross Markets</h3>
          {allMarketCopying.SelectedFromCountries && (
            <h6>{`Copying ${allMarketCopying.SelectedFromCountries.label} Methodology data to other applicable methodologies.`}</h6>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label>Select Source Country</Label>
                <sup>(</sup>
                <sup className="requiredField">*</sup>
                <sup>)</sup>
                <Select
                  name={"selectCountriesFrom"}
                  // value={project?.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  menuPortalTarget={document.querySelector("modal-body")}
                  menuPosition={"fixed"}
                  options={project?.CostingProfiles[
                    project.CostingProfiles.length - 1
                  ].CountrySpecs?.map((fc) => {
                    if (
                      fc.MethodologySpecs.filter(
                        (sm) => sm.CostingType == 3 && !sm.NotApplicable
                      ).length > 0
                    )
                      return {
                        label: fc.CountryName,
                        value: fc.id,
                      };
                  })
                    .filter((a) => a != undefined)
                    .filter(
                      (s) =>
                        s.label !=
                        allMarketCopying?.SelectedFromCountries?.label
                    )}
                  // isMulti
                  onChange={(val) => {
                    setAllMarketCopying((prevState) => ({
                      ...prevState,
                      SelectedFromCountries: val,
                      SelectedToCountries:
                        allMarketCopying.SelectedToCountries?.length > 0
                          ? prevState.SelectedToCountries.filter(
                              (a) => a.label != val.label
                            )
                          : [],
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          {allMarketCopying.SelectedFromCountries && (
            <Row>
              <Col>
                <FormGroup>
                  <Label>Select Destination Countries</Label>
                  <sup>(</sup>
                  <sup className="requiredField">*</sup>
                  <sup>)</sup>
                  <Select
                    name={"selectCountriesTo"}
                    value={
                      allMarketCopying.SelectedToCountries?.length > 0
                        ? allMarketCopying.SelectedToCountries
                        : []
                    }
                    className="react-select-container"
                    classNamePrefix="react-select"
                    menuPortalTarget={document.querySelector("modal-body")}
                    menuPosition={"fixed"}
                    options={project?.CostingProfiles[
                      project.CostingProfiles.length - 1
                    ].CountrySpecs?.filter(
                      (c) =>
                        allMarketCopying.SelectedFromCountries.label !=
                        c.CountryName
                    )
                      ?.map((fc) => {
                        if (
                          fc.MethodologySpecs.filter(
                            (sm) => sm.CostingType == 3 && !sm.NotApplicable
                          ).length > 0
                        )
                          return {
                            label: fc.CountryName,
                            value: fc.id,
                          };
                      })
                      .filter((a) => a != undefined)}
                    isMulti
                    onChange={(val) => {
                      setAllMarketCopying((prevState) => ({
                        ...prevState,
                        SelectedToCountries: val,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          {allMarketCopying.SelectedFromCountries &&
            allMarketCopying.SelectedToCountries?.length > 0 && (
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Select Methodologies</Label>
                    <sup>(</sup>
                    <sup className="requiredField">*</sup>
                    <sup>)</sup>
                    <Select
                      name={"selectMethodologies"}
                      value={allMarketCopying.SelectedFromMethodologies}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      menuPortalTarget={document.querySelector("modal-body")}
                      menuPosition={"fixed"}
                      options={project?.CostingProfiles[
                        project.CostingProfiles.length - 1
                      ].CountrySpecs?.filter(
                        (c) =>
                          allMarketCopying.SelectedFromCountries.label ==
                          c.CountryName
                      )[0].MethodologySpecs.map((meth) => ({
                        label: meth.Label,
                        value: meth.id,
                      }))}
                      isMulti
                      onChange={(val) => {
                        setAllMarketCopying((prevState) => ({
                          ...prevState,
                          SelectedFromMethodologies: val,
                        }));
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
          {allMarketCopying.SelectedFromCountries &&
            allMarketCopying.SelectedToCountries?.length > 0 &&
            allMarketCopying.SelectedFromMethodologies?.length > 0 && (
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Select Categories</Label>
                    <sup>(</sup>
                    <sup className="requiredField">*</sup>
                    <sup>)</sup>
                    <Select
                      name={"selectCategories"}
                      // value={project?.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      menuPortalTarget={document.querySelector("modal-body")}
                      menuPosition={"fixed"}
                      options={categoryOptions}
                      value={allMarketCopying.SelectedCategories}
                      isMulti
                      onChange={(val) => {
                        setAllMarketCopying((prevState) => ({
                          ...prevState,
                          SelectedCategories: val,
                        }));
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              //  <div style={{ "overflow-x": "scroll" }}>
              //             <Table
              //               striped
              //               bordered
              //               hover
              //               variant="dark"
              //               className="table-split"
              //             >
              //               {returnSplit()}
              //             </Table>
              //           </div>
            )}
          <Row>
            <Col>
              <Label>
                {" "}
                <strong>Note:</strong> Copying the information may change the
                data in selected markets.
              </Label>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={ModalFooterStyle}>
          {_.last(project.CostingProfiles)?.VenodrBidDeadLineStatus !=
            "close" && (
            <Button
              color="primary"
              onClick={() => {
                !allMarketCopying.SelectedFromCountries ||
                !allMarketCopying.SelectedToCountries?.length ||
                !allMarketCopying.SelectedCategories?.length ||
                !allMarketCopying.SelectedFromMethodologies?.length
                  ? toastr.error(
                      "Please complete selections in Mandatory fields!"
                    )
                  : setSaveAndNext(true);
              }}
              disabled={app.recordloading}
            >
              Yes
              {app.recordloading && <Spinner size="small" color="#495057" />}
            </Button>
          )}
          <Button
            color="secondary"
            onClick={cancelPopUp}
            disabled={app.recordloading}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={saveAndNext}
        backdrop="static"
        toggle={cancelPopUp}
        centered={true}
        size="sm"
      >
        <ModalHeader toggle={cancelPopUp}>
          {/* <img src={logo} className="modallogo" /> */}
          <h1> </h1>
        </ModalHeader>
        <div>
          {" "}
          <span className="modaltitle">{`Confirm Copying Data`}</span>
        </div>
        <ModalBody style={{ minHeight: "60px" }}>
          <Label>{`You are about to copy the data to other methodologies.
               This action is irreversible.Are you sure want to proceed with copying data?`}</Label>
        </ModalBody>
        <ModalFooter style={ModalFooterStyle}>
          {_.last(project.CostingProfiles)?.VenodrBidDeadLineStatus !=
            "close" && (
            <Button
              color="primary"
              onClick={() => copy()}
              disabled={app.recordloading}
            >
              Yes
              {app.recordloading && <Spinner size="small" color="#495057" />}
            </Button>
          )}
          <Button
            color="secondary"
            onClick={cancelPopUp}
            disabled={app.recordloading}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default VbmCopyToallMarket;
