import React, { useState, useEffect } from "react";
import axios from "../../../axios-interceptor";
import _ from "lodash";
import classnames from "classnames";
import Select from "react-select";
import { useDispatch } from "react-redux";
import update from "immutability-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toastr } from "react-redux-toastr";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronDown,
  faPen,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledCollapse
} from "reactstrap";
import { useSelector } from "react-redux";
import BiddingFiles from "./BiddingFiles"
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Table
} from "reactstrap";
import WarningModal from "./components/modals/WarningModal";
import HistoryModal from "./components/modals/HistoryModal";
import { VendorType, qcAll } from "./constants/detailConstants";
import "./css/common.css";

function VendorBiddingForm(props) {
  const baseurl = process.env.REACT_APP_API_BASE_URL

  var project = useSelector(({ currentProject }) => currentProject.newProject);
  const dispatch = useDispatch();
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const [messageBox, setMessageBox] = useState(false);
  const [removeData, setRemovedata] = useState(false);
  const [alertBox, setAlertBox] = useState();
  const [checked, setChecked] = useState();
  const [splitTab, setSplitTab] = useState(0);

  const [valid, setValid] = useState(false);
  const [openModalSplit, setOpenModalSplit] = useState(false);
  const [validBooster, setValidBooster] = useState(false);
  const [validCity, setValidCity] = useState(false);

  function selectVendorType(e) {
    vendoradd([
      {
        label: e.label,
        value: 0,
        CostingData: [],
        group: SudTabsName,
        Comments: ""
      },
    ]);
  }

  function closeSampleSplit() {
    setOpenModalSplit(false);

  }
  function openModal(e) {
    setChecked(e.target.checked)
    var tempproject = _.cloneDeep(
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]
        .MethodologySpecs
    );
    if (tempproject[props.indexMethodology].Vendors != null && tempproject[props.indexMethodology].Vendors.length > 0) {
      setRemovedata(false)
      setMessageBox(true)
      setAlertBox(`Data will be lost, if you unselect ${e.target.checked == true ? "Field Execution and Setup & Delivery Work Only" : "Full Service"} !`)
    }
    else {
      isFullServiceRequired(true, e.target.checked)
    }
  }

  const mystyle = {
    color: "blue",
    cursor: "pointer",
    fontFamily: "Arial",
  };
  const style = {
    border: "1px solid black",
  };

  const filetypestyle = {
    cursor: "pointer",
    fontSize: "11px"
  };

  function qcApplied() {
    return qcAll.filter((qc) => {
      return (
        qc.applied == "all" ||
        (qc.applied && qc.applied.filter((a) => a == props.sm.Label).length > 0) ||
        (qc.notApplied &&
          qc.notApplied.filter((na) => na == props.sm.Label).length == 0)
      );
    });
  }

  function currencyDropdown() {
    var currdata = [];
    var curr = project.CostingProfiles[costingProfileIndex] && project.CostingProfiles[costingProfileIndex]?.CountrySpecs.length > 0 ? project.CostingProfiles[costingProfileIndex]?.ProfileSetting.CurrenciesData.find(
      (c) => c.Code == project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry].CountryCode
    ) : "";
    var commcountrycurr = project.CostingProfiles[costingProfileIndex] && project.CostingProfiles[costingProfileIndex]?.CountrySpecs.length > 0 ?
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry].CountryCode != project.CommissioningCountry ?
        project.CostingProfiles[costingProfileIndex]?.ProfileSetting.CurrenciesData.find(
          (c) => c.Code == project.CommissioningCountry
        ) : "" : "";

    //project.CommissioningCountry;
    if (curr != "" && curr != undefined) {
      curr.AllCurrencyUnit = codeLabels.FieldingCountriesOptions.find(fc => fc.Code == curr.Code).AllCurrencyUnit
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = curr.AllCurrencyUnit ? curr.AllCurrencyUnit.split(",") : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          currdata.push({
            id: i + 1, label: all, value: i + 1, code: curr.Code, ConversionRateToLocal: project.CostingProfiles[costingProfileIndex]?.ProfileSetting.CurrenciesData.
              filter(curr => curr.CurrencyUnit == all)[0].ConversionRateToLocal
          })
        }
        )
      }
    }
    if (commcountrycurr != "" && commcountrycurr != undefined) {
      var currlength = currdata.length
      commcountrycurr.AllCurrencyUnit = codeLabels.FieldingCountriesOptions.find(fc => fc.Code == commcountrycurr.Code).AllCurrencyUnit
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = commcountrycurr.AllCurrencyUnit ? commcountrycurr.AllCurrencyUnit.split(",") : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          if (!currdata.find(a => a.label == all)) {
            currdata.push({
              id: currlength + i + 1, label: all, value: currlength + i + 1, code: commcountrycurr.Code, ConversionRateToLocal: project.CostingProfiles[costingProfileIndex]?.ProfileSetting.CurrenciesData.
                filter(curr => curr.CurrencyUnit == all)[0].ConversionRateToLocal
            })
          }
        }
        )
      }
    }
    return currdata;
  }
  function defaultvalueforcurrency() {

    var currdata = [];
    var curr = project?.CostingProfiles[costingProfileIndex] && project?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.length > 0 ? project?.CostingProfiles[costingProfileIndex]?.ProfileSetting?.CurrenciesData?.find(
      (c) => c.Code == project?.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]?.CountryCode
    ) : "";
    if (curr != "") {
      curr.AllCurrencyUnit = curr?.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = curr?.AllCurrencyUnit ? curr?.AllCurrencyUnit.split(",") : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          currdata.push({
            id: i + 1, label: all, value: i + 1, code: curr.Code, ConversionRateToLocal: project.CostingProfiles[costingProfileIndex]?.ProfileSetting.CurrenciesData.
              filter(curr => curr.CurrencyUnit == all)[0].ConversionRateToLocal
          })
        }
        )
      }
    }

    var defaultvalue = currdata.find(a => a.label == curr.CurrencyUnit)

    const newProposal = update(project.CostingProfiles[costingProfileIndex], {
      CountrySpecs: {
        [props.indexCountry]: {
          MethodologySpecs: {
            [props.indexMethodology]: {
              Currency: { $set: defaultvalue },
            },
          },
        },
      },
    });

    project.CostingProfiles[costingProfileIndex] = newProposal;

    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });

    return defaultvalue;
  }
  const [costingProfileIndex, setCostingProfileIndex] = useState(0);
  const [proposal, setProposalData] = useState({});
  const [activeTabCostingProfile, setActiveTabCostingProfile] = useState(0);
  const [behalfofvendor, setbehalfofvendor] = useState(false);
  const [show, setshow] = useState(false);
  const [activeTabCountry, setActiveTabCountry] = useState(0);
  const [activeTabMethodology, setActiveTabMethodology] = useState(0);
  const [modelState, setModelState] = useState(false);
  const [chatData, setChatData] = useState(props.chatData);
  const [lastvalue, setlastvalue] = useState(0);
  const [list, setlist] = useState();
  const [conversionRate, setConversionRate] = useState();
  const [activevendor, setactivevendor] = useState(0);
  const [methactiveTabSM, setmethActiveTabSM] = useState(0);
  const [enable, setenable] = useState(true);
  const [biddingType, setBiddingType] = useState(0);
  const [VendorData, setVendorData] = useState([]);
  const [SudTabs, setSudTabs] = useState(0);
  const [initailstate, setinitailstate] = useState([]);
  const [SudTabsName, setSudTabsName] = useState(0);
  var initialstateQuant = []
  var initialstateQual = []
  var initailstateOnline = []

  const costingProfiles = useSelector(
    ({ costings }) => costings.costingProfiles
  );
  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );

  useEffect(() => {
    var index = project.CostingProfiles?.length - 1
    setCostingProfileIndex(index)
    var SelectedMeth = _.cloneDeep(
      project.CostingProfiles[index].CountrySpecs[props.indexCountry]
        .MethodologySpecs[props.indexMethodology]);
    if (props.cCProfile == null) {
      props.setCCProfile(project.CostingProfiles[index])
    }
    if (project.CostingProfiles[index]?.CountrySpecs[props.indexCountry]?.MethodologySpecs[props.indexMethodology]?.SplitSample &&
      project.CostingProfiles[index]?.CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology]?.Vendors?.filter(x => x.biddingType == 0)[0]?.value
    ) {
      setactivevendor(project?.CostingProfiles[index]?.CountrySpecs[props.indexCountry]?.MethodologySpecs[props.indexMethodology]?.Vendors?.
        findIndex(f => f?.value == project?.CostingProfiles[index]?.CountrySpecs[props.indexCountry]?.MethodologySpecs[props.indexMethodology]?.Vendors?.filter(x => x.biddingType == 0)[0]?.value));
    }

    var tempvendor = SelectedMeth.Vendors

    var COData = SelectedMeth.CostingOptions;

    if (tempvendor) {
      tempvendor.map(vendor => {
        COData.forEach(co => {
          if (!vendor.selectedCO.find(fil => fil.id == co.id)) {
            if (vendor.biddingType == 1) {
              var SUDTAbArray = [...new Set(vendor.selectedCO.flatMap(x => x.group))]

              SUDTAbArray.forEach((s, i) => {
                vendor.selectedCO.push({ name: co.Name, id: co.id, selected: co.Selected, group: s, enable: co.Selected })

                vendor.CostingData.push({
                  loi: co.CostingOptionData?.lengthOfInterview
                    ? co.CostingOptionData?.lengthOfInterview
                    : "0",
                  sampleSize:
                    co.CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
                      || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id] == undefined ? "0" :
                      co.CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
                        || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id]
                  ,
                  coid: co.id,
                  soid: 14,
                  enable: true,
                  VendorData: "0",
                  cLabel: co.label,
                  AdditionalCost: {},
                  FinalCost: 0,
                  GroupValues: {}
                });
              }
              )
            }

            else {
              vendor.selectedCO.push({ name: co.Name, id: co.id, selected: co.Selected, group: "Field Cost", enable: co.Selected })

              vendor.CostingData.push({
                loi: co.CostingOptionData?.lengthOfInterview
                  ? co.CostingOptionData?.lengthOfInterview
                  : "0",
                sampleSize:
                  co.CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
                    || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id] == undefined ? "0" :
                    co.CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
                      || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id]
                ,
                coid: co.id,
                soid: 14,
                enable: true,
                VendorData: "0",
                cLabel: co.label,
                AdditionalCost: {},
                FinalCost: 0,
                GroupValues: {}
              });
            }
          }
          else {
            vendor.selectedCO.find(fil => fil.id == co.id).selected = co.Selected
            vendor.CostingData.forEach(a => {
              if (a.coid == co.id && a.soid == 14) {
                a.sampleSize = co.CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
                  || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id] == undefined ? "0" :
                  co.CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
                    || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id]
              }

            })



          }
        })

        // var selectedCOdata = COData.map((u) => {
        //   return { id: u.id, name: u.Name, selected: u.Selected, enable: u.Selected };
        // });
        // vendor.selectedCO = selectedCOdata;
      })

      const newProposal = update(project.CostingProfiles[index], {
        CountrySpecs: {
          [props.indexCountry]: {
            MethodologySpecs: {
              [props.indexMethodology]: {
                Vendors: { $set: tempvendor },
              },
            },
          },
        },
      });

      project.CostingProfiles[index] = newProposal;

      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: project,
      });
    }

  }, []);

  useEffect(() => {
    if (props.proposal && !props.proposal.id) {
    } else {
      setProposalData(props.proposal);
    }
  }, [props.proposal]);

  function setList() {
    if (vendorAndList && vendorAndList?.length > 0) {
      initialstateQuant = vendorAndList.filter(obj => obj.Group == 'fieldexecutionandsudtable')[0].List.filter(v => v.Name == "initailstatequant")[0]?.Values;
      initialstateQual = vendorAndList.filter(obj => obj.Group == 'fieldexecutionandsudtable')[0].List.filter(v => v.Name == "initailstatequal")[0]?.Values;
      initailstateOnline = vendorAndList.filter(obj => obj.Group == 'fieldexecutionandsudtable')[0].List.filter(v => v.Name == "initailstateonline")[0]?.Values;
      let typeofquant = codeLabels?.SubMethodologyOptions?.filter(sm => sm.ResearchType == "Quantitative")?.map(c => c.Code)
      setinitailstate(
        props.sm.label == "Online Self Completion Survey"
          ? initailstateOnline
          : typeofquant.find(a => a == props.sm.Code)
            ? initialstateQuant
            : initialstateQual
      );

    }
  }

  useEffect(() => {
    setList()
    var rate = props?.sm?.Currency?.ConversionRateToLocal
    setConversionRate(rate)
    if (props.sm.SplitSampledata) { setlist(props.sm.SplitSampledata) }
  }, [props.sm]);

  useEffect(() => {
    if (props.msg) alert.show(props.msg);
  }, [props.msg]);

  const vendorAndList = useSelector(
    ({ fetchVendorAndList }) => fetchVendorAndList.vendorAndList);

  useEffect(() => {
    if (vendorAndList && vendorAndList?.length > 0) {
      setList();
    }
  }, [vendorAndList]);

  function toggleCostingProfile(tab) {
    if (activeTabCostingProfile !== tab) {
      setActiveTabCostingProfile(tab);
      setActiveTabCountry(0);
      setActiveTabMethodology(0);
    }
  }

  function toggleCountry(tab) {
    if (activeTabCountry !== tab) {
      setActiveTabCountry(tab);
      setActiveTabMethodology(0);
    }
  }
  function splitChange(e) {
    var tempproject = _.cloneDeep(
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]
        .MethodologySpecs
    );
    if (e.target.checked == true) {
      tempproject[props.indexMethodology].SplitSample = 1
      setOpenModalSplit(true)
    }
    else {
      tempproject[props.indexMethodology].SplitSample = 0
    }
    const newProposal = update(project.CostingProfiles[costingProfileIndex], {
      CountrySpecs: {
        [props.indexCountry]: {
          MethodologySpecs: { $set: tempproject },
        },
      },
    });
    setBiddingType(0)
    project.CostingProfiles[costingProfileIndex] = newProposal;

    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
  }
  function toggleMethodology(tab, value) {
    if (methactiveTabSM !== tab) {
      var i = 0;
      props.sm.Vendors.forEach((v, ind) => {
        if (v.value == value) {
          i = ind;
        }
      });
      setactivevendor(i);
      setmethActiveTabSM(tab);
    }
  }

  function onMethodologyInfoChange(
    indexCostingProfile,
    indexCountry,
    indexMethodology,
    e
  ) {
    const newProposal = update(proposal, {
      CostingProfiles: {
        [indexCostingProfile]: {
          CountrySpecs: {
            [indexCountry]: {
              MethodologySpecs: {
                [indexMethodology]: {
                  $merge: { [e.target.name]: e.target.value },
                },
              },
            },
          },
        },
      },
    });
    setProposalData(newProposal);
  }
  function setchk(e) {
    var tempselected = _.cloneDeep(
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]
        .MethodologySpecs[props.indexMethodology].Vendors[activevendor]
        .selectedCO
    );
    if (tempselected?.filter(a => a.selected)?.length == 1 && e.target.checked == false) {
      toastr.error("Please Select atleast one Costing Option.")
    }
    else {
      if (biddingType == 1) {
        tempselected.forEach((ts) => {
          if (ts.id == e.target.id && ts.group == SudTabsName) {
            ts.selected = e.target.checked;
          }
        });
      }
      else {
        if (props.sm.FullServiceRequired) {
          tempselected.forEach((ts) => {
            if (ts.id == e.target.id) {
              ts.selected = e.target.checked;
            }
          })
        }
        else {
          tempselected.forEach((ts) => {
            if (ts.id == e.target.id && ts.group == "Field Cost") {
              ts.selected = e.target.checked;
            }
          })
        }
      }
      const newProposal = update(project.CostingProfiles[costingProfileIndex], {
        CountrySpecs: {
          [props.indexCountry]: {
            MethodologySpecs: {
              [props.indexMethodology]: {
                Vendors: {
                  [activevendor]: {
                    selectedCO: { $set: tempselected },
                  },
                },
              },
            },
          },
        },
      });

      project.CostingProfiles[costingProfileIndex] = newProposal;

      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: project,
      });
    }
  }

  function rowselected(e, id, group = '') {
    var tempselected = _.cloneDeep(
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]
        .MethodologySpecs[props.indexMethodology].Vendors[activevendor]
        .selectedrows
    );
    var tempvendor = _.cloneDeep(
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]
        .MethodologySpecs[props.indexMethodology].Vendors[activevendor]
    );
    var tempvendordata = _.cloneDeep(
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]
        .MethodologySpecs[props.indexMethodology].Vendors[activevendor].CostingData
    );
    var GroupData
    if (!group) {
      if (tempselected?.filter(tf => tf.selected && tf.group == tempselected?.filter(tf => tf.id == id)[0].group && tf.type != "TimingSection" && tf.id != 14).length == 1 &&
        e.target.checked == false && tempselected.filter(tf => tf.selected && tf.id == id)[0].type != "TimingSection" && tempvendor.biddingType != 2) {
        toastr.error("Atleast one field needs to be selected");
      }
      else
        if (tempselected?.filter(tf => tf.selected && tf.group && tf.type != "TimingSection" && tf.id != 14).length == 1 &&
          e.target.checked == false && tempselected.filter(tf => tf.selected && tf.id == id)[0].type != "TimingSection" && tempvendor.biddingType == 2) {
          toastr.error("Atleast one field needs to be selected");
        }

        else {
          if (tempselected.filter((y) => y.id == id).length == 0) {
            tempselected.push({ id: id, name: e.target.name, selected: true });
          }
          tempselected.forEach((ts) => {
            if (ts.id === id) {
              ts.selected = e.target.checked;
            }
          });

          if (tempselected?.filter(a => a.id == id)[0].type == "TimingSection") {
            GroupData = tempselected?.filter(a => a.selected && a.type == "TimingSection" && a.name != "TIMINGS ( # of WORKING DAYS)")

            if (tempselected.filter(tf => tf.name == 'TIMINGS ( # of WORKING DAYS)').length) {
              if (GroupData?.length > 0 && tempselected) {
                tempselected.filter(tf => tf.name == 'TIMINGS ( # of WORKING DAYS)')[0].selected = true
              }
              else {
                tempselected.filter(tf => tf.name == 'TIMINGS ( # of WORKING DAYS)')[0].selected = false
              }
            }
          }
          else {
            GroupData = tempselected?.filter(a => a.group == tempselected?.filter(b => b.id == id)[0].group && a.selected && a.type != "TimingSection" && a.id != 14)
            if (GroupData?.length > 0 && tempselected) {
              tempselected.filter(tf => tf.name == GroupData[0].group)[0].selected = true
            }
            else {
              tempselected.filter(tf => tf.name == tempselected?.filter(a => a.group == tempselected?.filter(b => b.id == id)[0].group)[0].group)[0].selected = false
            }
          }
        }
    } else {
      if (tempselected.filter(tf => tf.selected && !tf.group && tf.name != "TIMINGS ( # of WORKING DAYS)").length == 1 && e.target.checked == false && tempselected.filter(tf => tf.selected && tf.id == id)[0].type != "TimingSection") {
        toastr.error("Atleast one service needs to be selected");
      }
      else {
        if (group == "TIMINGS ( # of WORKING DAYS)") {
          tempselected.forEach((ts) => {
            if (ts.type == "TimingSection") {
              ts.selected = e.target.checked;
            }
          });
        }
        else {
          tempselected.forEach((ts) => {
            if ((ts.id == id || ts.group == group) && ts.type != "TimingSection") {
              ts.selected = e.target.checked;
            }
          });
        }
      }
    }
    if (!e.target.checked) {
      tempvendordata.filter(a => a.soid == id)?.forEach(x => { x.VendorData = ""; x.VendorDataUSD = "0.0000" })
    }
    const vendorSRows = {
      [activevendor]: {
        selectedrows: { $set: tempselected },
        CostingData: { $set: tempvendordata },
      }
    };
    updateProposal('Vendors', props.indexCountry, props.indexMethodology, project, vendorSRows);
  }

  function filteredVendors() {

    var vendorList = props.Vendor && props.sm.Vendors && props.sm.Vendors.length > 0
      ? props.Vendor.filter(
        (v) =>
          !props.sm.Vendors.some(
            (s) => s.value == v.value
          )
      )
      : props.Vendor
    if (props.sm.FullServiceRequired == 1) {
      vendorList = vendorList?.length > 0 ? (vendorList?.filter(a => (a.Countries?.filter(b => b.Code == project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry].CountryCode)?.length > 0)
      ).filter(v => v.Methodology?.filter(s => s.Code == props.sm.Code)?.length > 0)) : []

    }
    else {

      vendorList = vendorList?.length > 0 ? (vendorList?.filter(a => (a.Countries?.filter(b => b.Code == project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry].CountryCode)?.length > 0)
      ).filter(v => v.Methodology?.filter(s => s.Code == props.sm.Code)?.length > 0)
        .filter(vt => vt.VendorType?.filter(ct => ct.Code == "Field Costs")?.length > 0)) : []
    }

    return vendorList

  }

  function vendoradd(e) {
    var VendorsData = _.cloneDeep(
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]
        .MethodologySpecs[props.indexMethodology].Vendors
    );
    var SelectedVendorData = _.cloneDeep(
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]
        .MethodologySpecs[props.indexMethodology].SelectedVendor
    );

    var tempvendors;
    var tempselectedvendors = SelectedVendorData;
    var len = (VendorsData == null || VendorsData.length == 0) ? 1 : biddingType == 1 && e != null && e.length > 0 ?
      VendorsData.filter(vendor => vendor.group.includes(SudTabsName)).length
      : (e != null && e.length > 0 ? VendorsData.filter(vendor => vendor.group == e[0].group).length : 0)
    if (e == null && VendorsData) {
      tempvendors = []
      var removedvendor = VendorsData?.filter(object1 => {
        return !e?.some(object2 => {
          return object1.id === object2.id;
        });
      })
      if (removedvendor[0]?.id) {
        var selectedvd = SelectedVendorData?.map(fx => fx?.selected?.filter(fy => fy.VendorID != removedvendor[0].id))
        tempselectedvendors?.map((fx, i) => fx.selected = selectedvd[i])
      }

      if (biddingType == 1) {
        VendorsData.forEach(v => {
          if (v.biddingType == 1) {

            v.group = v.group.filter(grp => grp != SudTabsName);
            v.selectedrows = v.selectedrows.filter(sr => sr.group != SudTabsName)
            v.CostingData.filter(cd => cd.soid == 14)?.forEach(s => delete (s.GroupValues[SudTabsName]))
            v.CostingData = v.CostingData.filter(sr => sr.group != SudTabsName)
            tempvendors.push(v)
          }
          else if (v.biddingType == 1 && v.group.length == 0) { }
          else {

            tempvendors.push(v)
          }
        })
      }
      else if (props.sm.FullServiceRequired == 1) {
        tempvendors = VendorsData.filter(vd => vd.biddingType != 2)
      }
      else {
        tempvendors = VendorsData.filter(vd => vd.biddingType != 0)
      }
    }
    else if (e.length < len) {
      var removedvendor = VendorsData?.filter(object1 => {
        return !e?.some(object2 => {
          return object1.id === object2.id;
        });
      })
      if (removedvendor[0]?.id) {
        var selectedvd = SelectedVendorData?.map(fx => fx?.selected?.filter(fy => fy.VendorID != removedvendor[0].id))
        tempselectedvendors?.map((fx, i) => fx.selected = selectedvd[i])
      }

      if (biddingType == 1) {
        var vendors = VendorsData.filter(v => v.group.includes(SudTabsName))
        // var vendors=VendorsData.filter(v=>v.biddingType!=1)
        var vendors1 = VendorsData.filter(v => !v.group.includes(SudTabsName))
        var vendors2 = vendors.filter(x => !e.some(e => e.value == x.value))
        // e.map(v=>vendors1.push(v))
        vendors2[0].group = vendors2[0].group.filter(v => v != SudTabsName)
        vendors2[0].CostingData.filter(cd => cd.soid == 14)?.forEach(s => delete (s.GroupValues[SudTabsName]))
        vendors2[0].CostingData = vendors2[0].CostingData.filter(v => v != SudTabsName)
        vendors2[0].selectedrows = vendors2[0].selectedrows.filter(g => g.group != SudTabsName)
        tempvendors = [...e, ...vendors1, ...vendors2]
      }
      else if (props.sm.FullServiceRequired == 1) {
        tempvendors = VendorsData.filter(vendor => vendor.biddingType != 2)
        e.forEach(el => {
          tempvendors.push(el)
        })
      }
      else {

        tempvendors = VendorsData.filter(vendor => vendor.biddingType != 0)
        e.forEach(el => {
          tempvendors.push(el)
        })
      }
    }
    else {
      if (VendorsData == null || VendorsData.length == 0) {
        tempvendors = [];
        VendorsData = [];
      }
      if (e && e.length > 0) {
        tempvendors = VendorsData;
        if (e[0].label.includes("External Vendor")) {
          VendorsData.filter((f) => (f.label.includes("TCS") && f.group.includes(SudTabsName))).length > 0 &&
            VendorsData.filter((f) => (f.label.includes("TCS") && f.group.includes(SudTabsName))).forEach(fv => {
              fv.group = fv.group.filter(s => s != SudTabsName)
              fv.selectedrows = fv.selectedrows.filter(sr => sr.group != SudTabsName)
              fv.CostingData.filter(sr => (sr.soid == 14))?.forEach(a => delete (a.GroupValues[SudTabsName]))
              fv.CostingData = fv.CostingData.filter(sr => (sr.group != SudTabsName))
            })
          tempvendors = VendorsData
        }
        else {
          if (e[0].label.includes("TCS")) {
            VendorsData.filter((f) => (f.group.includes(SudTabsName))).length > 0 &&
              VendorsData.filter((f) => (f.group.includes(SudTabsName))).forEach(fv => {
                fv.group = fv.group.filter(s => s != SudTabsName)
                fv.selectedrows = fv.selectedrows.filter(sr => sr.group != SudTabsName)
                fv.CostingData.filter(sr => (sr.soid == 14))?.forEach(a => delete (a.GroupValues[SudTabsName]))
                fv.CostingData = fv.CostingData.filter(sr => (sr.group != SudTabsName))

              })
            tempvendors = VendorsData
          }

          var element1 = _.cloneDeep(e)

          element1.forEach((element) => {
            if (biddingType == 1 && tempvendors.filter((tv) => tv.value == element.value).length > 0) {
              if (!tempvendors.filter(
                (v) => v.value == element.value
              )[0].group.includes(SudTabsName)) {
                tempvendors.filter(
                  (v) => v.value == element.value
                )[0].group.push(initailstate.filter((e) => e.header && e.sud == true)[
                  SudTabs
                ].header)
                var newrows = (initailstate
                  .filter((e) => e.group == SudTabsName || e.header == SudTabsName)
                  .map((u) => {
                    return { id: u.id, name: u.name, selected: true, group: u.group, type: u.type, key: u.key };
                  }))
                var oldrows = tempvendors.filter(
                  (v) => v.value == element.value
                )[0].selectedrows
                tempvendors.filter(
                  (v) => v.value == element.value
                )[0].selectedrows = [...oldrows, ...newrows];
              }
            }
            else if (biddingType == 0 && tempvendors.filter((tv) => tv.value == element.value).length > 0) {
            }
            else {
              var coSelected = [];
              if (biddingType == 0 && props.sm.Vendors != null && props.sm.Vendors.length > 0 && props.sm.Vendors.filter((v) => v.value == element.value).length > 0) {
                coSelected.push(
                  ...props.sm.Vendors.filter(
                    (v) => v.value == element.value
                  )[0].selectedCO
                );
              }
              else if (biddingType == 1 && props.sm.Vendors != null && props.sm.Vendors.length > 0 && props.sm.Vendors.filter((v) => v.value == element.value).length > 0) {
                coSelected.push(
                  ...props.sm.Vendors.filter(
                    (v) => v.value == element.value
                  )[0].selectedCO
                );
              }
              else {
                props.sm.CostingOptions.map((co) => {
                  if (biddingType == 1) {
                    initailstate.filter(a => a.header).map(s => {
                      coSelected.push({
                        name: co.Name,
                        id: co.id,
                        selected: co.Selected,
                        group: s.header,
                        enable: co.Selected,
                      })
                    })
                  }
                  else {

                    coSelected.push({
                      name: co.Name,
                      id: co.id,
                      selected: co.Selected,
                      group: "Field Cost",
                      enable: co.Selected,
                    })

                  }

                });
              }
              var vendorarray = [];
              element.selectedCO = coSelected;
              props.sm.CostingOptions.forEach(
                (s) => {
                  vendorarray.push({
                    loi: s.CostingOptionData?.lengthOfInterview
                      ? s.CostingOptionData?.lengthOfInterview
                      : "0",
                    sampleSize:
                      s.CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
                        || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id] == undefined ? "0" :
                        s.CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
                          || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id]
                    ,
                    coid: s.id,
                    soid: 14,
                    enable: true,
                    VendorData: "0",
                    cLabel: s.label,
                    AdditionalCost: {},
                    FinalCost: 0,
                    GroupValues: {}
                  });
                }
              );
              if (biddingType == 0) {
                element.group =
                  project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]
                    .MethodologySpecs[props.indexMethodology]
                    .FullServiceRequired == 1
                    ? "Full Service"
                    : "Field Cost";
                if (props.sm.FullServiceRequired == 0) {
                  //    element.selectedrows = initailstate.filter((e) => e.group == "Field Cost" || e.name == "Field Cost" || e.header == "TIMINGS ( # of WORKING DAYS)").map((u) => {
                  element.selectedrows = initailstate.filter((e) => e.group == "Field Cost" || e.name == "Field Cost").map((u) => {
                    return { id: u.id, name: u.name, selected: true, group: u.group, type: u.type, key: u.key };
                  });
                }
                else {
                  element.selectedrows = initailstate.map((u) => {
                    return { id: u.id, name: u.name, selected: true, type: u.type, group: u.group, key: u.key };
                  });
                }
              }
              if (biddingType == 1) {
                if (Array.isArray(element.group)) {
                  element.group.push(initailstate.filter((e) => e.header && e.sud == true)[
                    SudTabs
                  ].header)
                }
                else {
                  element.group = [];
                  element.group.push(initailstate.filter((e) => e.header && e.sud == true)[
                    SudTabs
                  ].header)
                }
                element.selectedrows = initailstate
                  // .filter((e) => e.group == element.group || e.name == element.group || e.header=="TIMINGS ( # of WORKING DAYS)")
                  .filter((e) => e.group == element.group || e.name == element.group)
                  .map((u) => {
                    return { id: u.id, name: u.name, selected: true, group: u.group, type: u.type, key: u.key };
                  });
              }
              element.CostingData = vendorarray;
              // initailstate=(props.sm.label=="Online Self Completion Survey"?initailstateOnline:(props.sm.Quant?initialstateQuant:initialstateQual))
              //  element.biddingType = biddingType;
              element.biddingType =
                project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]
                  .MethodologySpecs[props.indexMethodology].FullServiceRequired ==
                  1
                  ? 2
                  : biddingType;
              element.vendorType =
                element.label.includes("TCS")
                  ? "TCS"
                  : "External Vendor";
              element.mail = 0;
              element.Save = false;
              tempvendors.push(element);
            }
          });
        }
      }
    }
    if (biddingType == 1) {

      setactivevendor(
        tempvendors?.findIndex(vendor => vendor.group.includes(SudTabsName))
      )
    } else if (biddingType == 0 && (props.sm.FullServiceRequired == 0 || !props.sm.FullServiceRequired)) {
      setactivevendor(
        tempvendors?.findIndex(vendor => vendor.group == "Field Cost")
      )
    }
    else {
      setactivevendor(
        tempvendors ? (tempvendors?.length - 1) : 0
      )
      setmethActiveTabSM(tempvendors ? (tempvendors?.length - 1) : 0);
    }
    var temporaryvendors = _.cloneDeep(tempvendors?.filter(x => x.group.length > 0))
    const mergeVendors = {
      [props.indexMethodology]: {
        $merge: {
          Vendors: temporaryvendors,
          SelectedVendor: tempselectedvendors
        },
      },
    };
    updateProposal('Methodology', props.indexCountry, 0, project, mergeVendors);
  }

  function updateMethodologyInfo(e, type) {
    let dataSet;
    let proposalType;
    let indexM;
    switch (type) {
      case 'Comments':
        dataSet = e.target.value;
        proposalType = 'VendorComments';
        indexM = props.indexMethodology;
        break;
      case 'OtherProjectSpecification':
        dataSet = {
          [props.indexMethodology]: {
            OtherProjectSpecification: { $set: e.target.value },
          },
        };
        proposalType = 'Methodology';
        indexM = 0;
        break;
      case 'QCValidationMode':
        dataSet = {
          [props.indexMethodology]: {
            QCValidationMode: { $set: e },
          },
        };
        proposalType = 'Methodology';
        indexM = 0;
        break;
      case 'Currency':
        dataSet = {
          [props.indexMethodology]: {
            Currency: { $set: e },
          },
        };
        proposalType = 'Methodology';
        indexM = 0;
        break;
      default:
        break;
    }
    updateProposal(proposalType, props.indexCountry, indexM, project, dataSet);
  }
  var file;
  function FileChange(e) {
    var filePath = e.target.value;
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.xls|\.xlsx|\.doc|\.docx)$/i;
    if (!allowedExtensions.exec(filePath)) {
      toastr.error("Please upload file having extensions .jpeg/.jpg/.png/.pdf/.xls/.xlsx/ .doc/.docx only")
    }
    else
      if (e.target.files[0].size > 4200000) {
        toastr.error("Please upload file having size less than 4 mb")
      }
      else {
        if (project.other_required_documents_filename) {
          file = project.other_required_documents_filename
          file[e.target.name + "-" + project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[props.indexCountry].label + "-" + props.sm.Label] = e.target.files[0];
          project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology]["UploadOtherRequiredDocuments"] = e.target.files[0].name
          project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology]["NotSaved"] = true
        }
        else {
          file = {}
          file[e.target.name + "-" + project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[props.indexCountry].label + "-" + props.sm.Label] = e.target.files[0];
          project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology]["UploadOtherRequiredDocuments"] = e.target.files[0].name
          project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology]["NotSaved"] = true
        }
        const newProposal = update(project, {
          other_required_documents_filename: { $set: file },
        });

        project = newProposal;

        dispatch({
          type: currentProjectActions.UPDATE_NEW_PROJECT,
          newProject: project,
        });
      }
  }
  function saveChat() {
    setModelState(!modelState);
    var vendorName =
      proposal.CostingProfiles[costingProfileIndex]?.CountrySpecs[0].MethodologySpecs[0].Vendors[0]
        .label;
    if (vendorName) chatData["CreatedBy"] = vendorName;
    props.saveChat(chatData);
    window.location.href = window.location.href;
  }

  const current1 = new Date();
  var d = current1.getDate();
  var m = current1.getMonth() + 1;
  var y = current1.getFullYear();
  var current = d + "-" + m + "-" + y;

  function addHistory(e, header, statement, vendor) {
    var i;
    project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
      props.indexCountry
    ].MethodologySpecs[props.indexMethodology].Vendors.forEach((v, ind) => {
      if (v.value == vendor.value) {
        i = ind;
      }
    });
    const tempcostdata = _.cloneDeep(props.sm.Vendors[i].CostingData);
    if (
      tempcostdata.filter(
        (tc) => tc.soid == statement.id && tc.coid == header.id
      ).length > 0
    ) {
      if (
        tempcostdata.filter(
          (tc) => tc.soid == statement.id && tc.coid == header.id
        )[0].History != undefined
      ) {
        if (_.last(tempcostdata
          .filter((tc) => tc.soid == statement.id && tc.coid == header.id)[0]
          .History).lastvalue != tempcostdata.filter(
            (tc) => tc.soid == statement.id && tc.coid == header.id
          )[0].VendorData)
          tempcostdata
            .filter((tc) => tc.soid == statement.id && tc.coid == header.id)[0]
            .History.push({
              date: current,
              lastvalue: tempcostdata.filter(
                (tc) => tc.soid == statement.id && tc.coid == header.id
              )[0].VendorData,
              coname: header?.Name,
              changedby: project.CreatedBy,
            });
      } else {
        tempcostdata.filter(
          (tc) => tc.soid == statement.id && tc.coid == header.id
        )[0].History = [];

        tempcostdata
          .filter((tc) => tc.soid == statement.id && tc.coid == header.id)[0]
          .History.push({
            date: current,
            lastvalue: lastvalue,
            coname: header?.Name,
            changedby: project.CreatedBy,
          });
      }
    }

    var groups = _.uniq(tempcostdata.filter((f) => f.group && f.type != "TimingSection")?.map((v) => v.group)).filter(
      (f) => f != undefined
    );
    var groupvalues = {};
    groups.forEach((m, mi) => {
      groupvalues[m] = tempcostdata
        .filter((cd) => {
          return cd.group == m && cd.soid != 14 && cd.coid == header.id && cd.type != "TimingSection";
        })
        //     .map((c) => (isNaN(c.VendorData) || c.VendorData == '' ? "0" : c.VendorData))

        .map((c) => (isNaN(c.VendorDataUSD) || c.VendorDataUSD == '' ? "0" : c.VendorDataUSD))
        .reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0);
    });

    if (
      tempcostdata.filter(
        (tc) => tc.soid == statement.id && tc.coid == header.id
      ).length > 0
    ) {
      tempcostdata.filter(
        (tc) => tc.soid == statement.id && tc.coid == header.id
      )[0].VendorData = e.target.value;
    } else {
      tempcostdata.push({
        coid: header.id,
        soid: statement.id,
        name: statement.name,
        group: statement.group,
        type: statement.type,
        VendorData: e.target.value,
        key: statement.key,
        History: [],
      });

      tempcostdata
        .filter((tc) => tc.soid == statement.id && tc.coid == header.id)[0]
        .History.push({
          date: current,
          lastvalue: 0,
          coname: header?.Name,
          changedby: project.CreatedBy,
        });
    }
    const sumtot = tempcostdata
      .filter((cd) => {
        return cd.VendorData && cd.coid == header.id && cd.soid != 14 && cd.type != "TimingSection";
      })
      .map((v) => v.VendorData)
      .reduce((a, b) => parseInt(a) + parseInt(b) + 0, 0);
    const sumsud = tempcostdata
      .filter((cd) => {
        return (
          cd.VendorData &&
          cd.coid == header.id &&
          cd.soid != 14 &&
          cd.soid != 29 &&
          cd.soid != 30 &&
          cd.soid != 31 &&
          cd.soid != 86
        );
      })
      .map((v) => v.VendorData)
      .reduce((a, b) => parseInt(a) + parseInt(b) + 0, 0);
    const sumfield = tempcostdata
      .filter((cd) => {
        return (
          cd.VendorData &&
          cd.coid == header.id &&
          cd.soid != 14 &&
          (cd.soid == 29 || cd.soid == 30 || cd.soid == 31 || cd.soid == 86)
        );
      })
      .map((v) => v.VendorData)
      .reduce((a, b) => parseInt(a) + parseInt(b) + 0, 0);
    if (
      tempcostdata.filter((tc) => tc.soid == 14 && tc.coid == header.id)
        .length > 0
    ) {
      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].GroupValues = groupvalues;

      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].VendorData = sumtot;
      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].FIELD = sumfield;
      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].SUD = sumsud;
      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].sampleSize = props.sm.CostingOptions.filter(
        (tc) => tc.id == header.id
      )[0].CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
        || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id] == undefined ? "0" :
          props.sm.CostingOptions.filter(
            (tc) => tc.id == header.id
          )[0].CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
            || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id];
      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].loi = props.sm.CostingOptions.filter(
        (c) => c.id == header.id
      )[0].CostingOptionData["lengthOfInterview"];
      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].cLabel = props.sm.CostingOptions.filter((c) => c.id == header.id)[0][
        "Name"
        ];
    } else {
      tempcostdata.push({
        coid: header.id,
        soid: 14,
        VendorData: sumtot,
        SUD: sumsud,
        FIELD: sumfield,
        sampleSize: props.sm.CostingOptions.filter(
          (tc) => tc.id == header.id
        )[0].CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
          || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id] == undefined ? "0" :
          props.sm.CostingOptions.filter(
            (tc) => tc.id == header.id
          )[0].CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
            || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id],

        cLabel: props.sm.CostingOptions.filter((c) => c.id == header.id)[0][
          "Name"
        ],
        loi: props.sm.CostingOptions.filter((tc) => tc.id == header.id)[0]
          .CostingOptionData["lengthOfInterview"],

        GroupValues: groupvalues,
      });
    }
    const vendorCostingData = {
      [activevendor]: {
        CostingData: { $set: tempcostdata },
      },
    };
    updateProposal('Vendors', props.indexCountry, props.indexMethodology, project, vendorCostingData);
  }

  function addvendor(e, header, statement, vendor) {
    var i;

    project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
      props.indexCountry
    ].MethodologySpecs[props.indexMethodology].Vendors.forEach((v, ind) => {
      if (v.value == vendor.value) {
        i = ind;
      }
    });
    const tempcostdata = _.cloneDeep(props.sm.Vendors[i].CostingData);

    if (
      tempcostdata.filter(
        (tc) => tc.soid == statement.id && tc.coid == header.id
      ).length > 0
    ) {
      setlastvalue(
        tempcostdata.filter(
          (tc) => tc.soid == statement.id && tc.coid == header.id
        )[0].VendorData
      );
      tempcostdata.filter(
        (tc) => tc.soid == statement.id && tc.coid == header.id
      )[0].VendorData = e.target.value;
      tempcostdata.filter(
        (tc) => tc.soid == statement.id && tc.coid == header.id
      )[0].VendorDataUSD = (e.target.value / conversionRate).toFixed(4);
    } else {
      tempcostdata.push({
        coid: header.id,
        soid: statement.id,
        name: statement.name,
        key: statement.key,
        VendorData: e.target.value,
        VendorDataUSD: (e.target.value / conversionRate).toFixed(4),
        group: statement.group,
        type: statement.type,
        History: [],
      });
    }
    const sumtot = tempcostdata
      .filter((cd) => {
        return cd.VendorData && cd.coid == header.id && cd.soid != 14 && cd.type != "TimingSection";
      })
      .map((v) => v.VendorData)
      .reduce((a, b) => parseInt(a) + parseInt(b) + 0, 0);
    const sumUSD = tempcostdata
      .filter((cd) => {
        return cd.VendorDataUSD && cd.coid == header.id && cd.soid != 14 && cd.type != "TimingSection"
      })
      .map((v) => v.VendorDataUSD)
      .reduce((a, b) => parseFloat(a) + parseFloat(b) + 0, 0);
    var groups = _.uniq(tempcostdata.filter((f) => f.group && f.type != "TimingSection")?.map((v) => v.group)).filter(
      (f) => f != undefined
    );
    var groupvalues = {};
    groups.forEach((m, mi) => {
      groupvalues[m] = tempcostdata
        .filter((cd) => {
          return cd.group == m && cd.soid != 14 && cd.coid == header.id && cd.type != "TimingSection";
        })
        //    .map((c) => (isNaN(c.VendorData) || c.VendorData == '' ? "0" : c.VendorData))
        .map((c) => (isNaN(c.VendorDataUSD) || c.VendorDataUSD == '' ? "0" : c.VendorDataUSD))
        .reduce((a, b) => Math.round((a)) + Math.round(b) + 0, 0);
    });

    const sumsud = tempcostdata
      .filter((cd) => {
        return (
          cd.VendorData &&
          cd.coid == header.id &&
          cd.soid != 14 &&
          cd.soid != 29 &&
          cd.soid != 30 &&
          cd.soid != 31 &&
          cd.soid != 86
        );
      })
      .map((v) => v.VendorData)
      .reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0);
    const sumfield = tempcostdata
      .filter((cd) => {
        return (
          cd.VendorData &&
          cd.coid == header.id &&
          cd.soid != 14 &&
          (cd.soid == 29 || cd.soid == 30 || cd.soid == 31 || cd.soid == 86)
        );
      })
      .map((v) => v.VendorData)
      .reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0);
    if (
      tempcostdata.filter((tc) => tc.soid == 14 && tc.coid == header.id)
        .length > 0
    ) {
      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].GroupValues = groupvalues;

      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].VendorData = sumtot;
      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].VendorDataUSD = sumUSD;
      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].FIELD = sumfield;
      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].SUD = sumsud;
      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].sampleSize =
        props.sm.CostingOptions.filter((c) => c.id == header.id)[0]
          .CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
            || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id] == undefined ? "0" :
          props.sm.CostingOptions.filter((c) => c.id == header.id)[0]
            .CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
              || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id]

      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].loi = props.sm.CostingOptions.filter(
        (c) => c.id == header.id
      )[0].CostingOptionData["lengthOfInterview"];
      tempcostdata.filter(
        (tc) => tc.soid == 14 && tc.coid == header.id
      )[0].cLabel = props.sm.CostingOptions.filter((c) => c.id == header.id)[0][
        "Name"
        ];
    } else {
      tempcostdata.push({
        GroupValues: groupvalues,
        coid: header.id,
        soid: 14,
        VendorData: sumtot,
        VendorDataUSD: sumUSD,
        sampleSize:
          props.sm.CostingOptions.filter((c) => c.id == header.id)[0]
            .CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
              || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id] == undefined ? "0" :
            props.sm.CostingOptions.filter((c) => c.id == header.id)[0]
              .CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize")))
                || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id],

        cLabel: props.sm.CostingOptions.filter((c) => c.id == header.id)[0][
          "Name"
        ],
        groupvalues: { ...groupvalues },
        loi: props.sm.CostingOptions.filter((tc) => tc.id == header.id)[0]
          .CostingOptionData["lengthOfInterview"],
      });
    }
    const vendorCostingData = {
      [activevendor]: {
        CostingData: { $set: tempcostdata },
      },
    };
    updateProposal('Vendors', props.indexCountry, props.indexMethodology, project, vendorCostingData);
  }

  var i = 0;
  function disableinput(id, element) {
    if (element.saveAsDraft) {
      toastr.error("Vendor has not Submitted the Cost")
    }
    else {
      var tempdata = _.cloneDeep(
        props.sm.Vendors
      );
      tempdata.forEach((x) => {
        if (x.id == element.id) {
          x.CostingData.forEach((a) => {
            if (a.coid == id && a.soid == 14) {
              a.enable = false;
            }
          });
        }
      });
      updateProposal('Vendors', props.indexCountry, props.indexMethodology, project, { $set: tempdata });
    }
  }
  const [open, setOpen] = useState(false);
  const [sid, setsid] = useState(0);

  function Tooltip(id) {
    setOpen(true);
    setsid(id);
  }
  function Togglesud(tm) {
    setSudTabs(0);
    setmethActiveTabSM(0);
    setBiddingType(tm);
    if (
      props.sm.Vendors &&
      props.sm.Vendors.filter((p) => p.biddingType == tm).length > 0
    ) {
      var val = props.sm.Vendors.filter((p) => p.biddingType == tm)[0].value;

      var ind = 0;
      props.sm.Vendors.forEach((d, i) => {
        if (d.value == val) {
          ind = i;
        }
      });
      setactivevendor(ind);
    } else {
      setactivevendor(
        props.sm.Vendors &&
        props.sm.Vendors.filter((p) => p.biddingType != tm).length
      );
    }
  }

  function changeFieldName(e, v, vendor) {

    var selectedRows = _.cloneDeep(vendor.selectedrows).map(row => {
      if (row.id == v.id) {
        row['otherCost'] = e.target.value ? e.target.value : ""
      } return row;
    })


    const newProposal = update(project.CostingProfiles[costingProfileIndex], {
      CountrySpecs: {
        [props.indexCountry]: {
          MethodologySpecs: {
            [props.indexMethodology]: {
              Vendors: {
                [activevendor]: {
                  selectedrows: {
                    $set: [...selectedRows]
                  }

                },
              },
            },
          },
        },
      },
    });

    project.CostingProfiles[costingProfileIndex] = newProposal;

    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
  }
  function DistributeSampleSize(coheader, e, vendor) {
    if (parseInt(coheader.CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize"))) || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id]) < parseInt(e.target.value)) { setValid(true) }
    else {
      setValid(false)
      updateDistribution(vendor, coheader, "DistributedSampleSize", e.target.value);
    }
  }

  function DistributeCity(coheader, e, vendor) {
    updateDistribution(vendor, coheader, "DistributedCity", e);
  }

  function DistributeBooster(coheader, e, vendor) {
    if (parseInt(coheader.CostingOptionData.sampleSizeBooster) < parseInt(e.target.value)) { setValidBooster(true) }
    else {
      setValidBooster(false)
      updateDistribution(vendor, coheader, "DistributedBoosterSampleSize", e.target.value);
    }
  }

  function DistributeComments(coheader, e, vendor) {
    updateDistribution(vendor, coheader, "DistributedComments", e.target.value);
  }
  function updateDistribution(vendor, coheader, type, value) {
    var newVendor = _.cloneDeep(props.sm.Vendors);
    newVendor.forEach(a => {
      if (a.value === vendor.value) {
        a.CostingData.filter(c => c.coid === coheader.id && c.soid === 14)[0][type] = value;
      }
    })
    const newProposal = update(project.CostingProfiles[costingProfileIndex], {
      CountrySpecs: {
        [props.indexCountry]: {
          MethodologySpecs: {
            [props.indexMethodology]: {
              Vendors: { $set: newVendor },
            },
          },
        },
      },
    });

    project.CostingProfiles[costingProfileIndex] = newProposal;

    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
  }
  var COID = props.sm?.CostingOptions[splitTab]?.id;

  function samplesplitsize(e, name, id, coid) {
    var splitsize = _.cloneDeep(list)
    if (splitsize.SplitsData == undefined) {

      splitsize["SplitsData"] = [];
      splitsize.SplitsData.push({ [name]: e, id: id, coid: coid });
    }
    else {
      if (splitsize.SplitsData.filter(x => x.id == id && x.coid == coid).length > 0) {
        splitsize.SplitsData.filter(x => x.id == id && x.coid == coid)[0][name] = e;
      }
      else {
        splitsize.SplitsData.push({ [name]: e, id: id, coid: coid });
      }
    }
    setlist(splitsize)

  }

  function SaveSampleSplitData() {
    var Datasamplesizebooster = 0;
    var Datasamplesizemain = 0;
    var Distributionnamelength = 0;
    var ActualDistributionSize = 0;
    var Datasamplesizemainlength = 0;
    var ActualBoosterSize = 0;
    var Datasamplesizeboosterlength = 0;
    var onsubmit = 0;
    var condition = false
    var splitsize = _.cloneDeep(list)
    var vendor = _.cloneDeep(props.sm.Vendors)
    props.sm.CostingOptions.map(co => {

      if (co.Selected) {

        Distributionnamelength = splitsize?.SplitsData?.filter(x => (x.coid == co.id)).map(x => x.DistributionName).filter(y => y).length

        Datasamplesizemain = splitsize?.SplitsData?.filter(x => (x.coid == co.id))
          .map(split => split.DistributedSampleSize)
          .reduce((a, b) => parseInt(!a ? 0 : a) + parseInt(!b ? 0 : b) + 0, 0);

        Datasamplesizemainlength = splitsize?.SplitsData?.filter(x => (x.coid == co.id))?.map(x => x.DistributedSampleSize).filter(y => y > 0).length;

        Datasamplesizebooster = splitsize?.SplitsData?.filter(x => (x.coid == co.id))
          .map(split => split.DistributedBoosterSampleSize)
          .reduce((a, b) => parseInt(!a ? 0 : a) + parseInt(!b ? 0 : b) + 0, 0);

        Datasamplesizeboosterlength = splitsize?.SplitsData?.filter(x => (x.coid == co.id))?.map(x => x.DistributedBoosterSampleSize).filter(y => y > 0).length;

        ActualBoosterSize = (!co.CostingOptionData?.sampleSizeBooster ? 0 : co.CostingOptionData?.sampleSizeBooster)

        ActualDistributionSize = isNaN(parseInt(splitsize?.NumberOfSplits.filter(x => x.coid == co.id).map(y => y.size))) ? 0 :
          parseInt(splitsize?.NumberOfSplits.filter(x => x.coid == co.id).map(y => y.size));

        if (!splitsize) {
          toastr.error(`Please Fill The Mandatory Field for ${co.label}`);
        }
        else if (!ActualDistributionSize) {
          toastr.error(`Please Enter Valid Number Of Splits for ${co.label}`);
        }
        else if (ActualDistributionSize != Distributionnamelength || !ActualDistributionSize) {
          toastr.error(`Please Enter Distribution Name for ${co.label}`)
        }
        else if (((co.CostingOptionData.sampleSizeMain || co.CostingOptionData.sampleSize || co.CostingOptionData.numberOfGroup) !=
          (isNaN(Datasamplesizemain) ? 0 : Datasamplesizemain)) || ActualDistributionSize != Datasamplesizemainlength) {
          toastr.error(`Please Enter Correct Sample Size Main Split / Number of Groups Split  for ${co.label}`)
        }
        else if (ActualBoosterSize && (ActualDistributionSize != Datasamplesizeboosterlength || (ActualBoosterSize != (isNaN(Datasamplesizebooster) ? 0 : Datasamplesizebooster)))) {
          toastr.error(`Please Enter Correct Sample Split Booster Size for ${co.label}`)
        }
        else if (((co.CostingOptionData.sampleSizeMain || co.CostingOptionData.sampleSize || co.CostingOptionData.numberOfGroup) ==
          (isNaN(Datasamplesizemain) ? 0 : Datasamplesizemain)
          && ((!co.CostingOptionData?.sampleSizeBooster ? 0 : co.CostingOptionData?.sampleSizeBooster) ==
            (isNaN(Datasamplesizebooster) ? 0 : Datasamplesizebooster)))) {
          splitsize.NumberOfSplits.find(size => size.coid == co.id).saved = true
          onsubmit = onsubmit + 1;
          if (vendor?.length > 0) {
            vendor.forEach(v => v.CostingData?.filter(b => b.soid == 14)?.forEach(s => {
              s.Selected = null
              s.DistributedSampleSize = null
              s.DistributionName = null
              s.DistributedCity = null
              s.DistributedBoosterSampleSize = null
            }))
          }

        }
        else {
          toastr.error("Please Fill The Mandatory Field");
        }
      }
    })

    if (onsubmit == props.sm.CostingOptions.filter(x => x.Selected).length) {
      toastr.success("Submitted")
      closeSampleSplit()
    }

    if (splitsize)
      splitsize["validate"] = condition ? false : true
    setlist(splitsize)
    const SplitSampledata = {
      [props.indexMethodology]: {
        SplitSampledata: { $set: splitsize },
        Vendors: { $set: vendor }
      }
    };
    updateProposal('Methodology', props.indexCountry, props.indexMethodology, project, SplitSampledata);

  }


  function returnSplit(e) {
    let Coid = props?.sm?.CostingOptions[splitTab]?.id;
    return (
      <>
        <tr>
          <td style={{ position: "relative" }} colSpan={4}>Distribution Name(*)</td>
          {[...Array((!list?.NumberOfSplits ||
            list?.NumberOfSplits.filter(x => x.coid == Coid).length == 0 || list?.NumberOfSplits.filter(x => x.coid == Coid)[0].size == ''
            || parseInt(list?.NumberOfSplits.filter(x => x.coid == Coid)[0].size) < 1) ? 0 : parseInt(list
              ?.NumberOfSplits.filter(x => x.coid == Coid)[0].size))].map((x, i) => (
                <td>
                  <Label>
                    <input
                      type="text"
                      value={list.SplitsData?.filter(x => x.id == i && x.coid == Coid).length > 0 ?
                        (list?.SplitsData.filter(x => x.id == i && x.coid == Coid)[0]?.DistributionName == undefined ? ""
                          : list?.SplitsData.filter(x => x.id == i && x.coid == Coid)[0]?.DistributionName) : ""}
                      name={props.sm.CostingOptions[splitTab].name}

                      onChange={(e) => samplesplitsize(e.target.value, "DistributionName", i, Coid)}>
                    </input>
                  </Label>
                </td>

              ))}


        </tr>

        {(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize"))) || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup")))) &&
          <tr>
            <td style={{ position: "relative" }} colSpan={4}>{(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize"))) || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup")))).label} Split(*)</td>

            {[...Array((list?.NumberOfSplits == undefined || list?.NumberOfSplits.filter(x => x.coid == Coid).length == 0 || list?.NumberOfSplits.filter(x => x.coid == Coid)[0].size == '' || parseInt(list?.NumberOfSplits.filter(x => x.coid == Coid)[0].size) < 1) ? 0 : parseInt(list?.NumberOfSplits.filter(x => x.coid == Coid)[0].size))].map((x, i) => (
              (props.sm.CostingOptions[splitTab].CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize"))) || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id] != undefined ?
                <td>
                  <input
                    type="number"
                    name={props.sm.CostingOptions[splitTab].name}
                    id={i}

                    min={0}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    pattern="[0-9]"

                    onChange={(e) => {
                      (e.target.value < 0) ? toastr.error("Please Enter Valid Number")
                        : samplesplitsize(e.target.value, "DistributedSampleSize", i, Coid)
                    }}
                    value={(list?.SplitsData != null || list?.SplitsData != undefined) ? (list?.SplitsData.filter(x => x.id == i && x.coid == Coid).length > 0 ? list?.SplitsData.filter(x => x.id == i && x.coid == Coid)[0]?.DistributedSampleSize == undefined ? "" : list?.SplitsData.filter(x => x.id == i && x.coid == Coid)[0]?.DistributedSampleSize : "") : ""}
                  />
                  {valid && (
                    <div style={{ color: "#AD4400" }}>Entered Number is invalid</div>
                  )}
                </td>
                : <td>No Sample Size Found</td>
              )
            ))}
          </tr>
        }
        <tr>
          <td style={{ position: "relative" }} colSpan={4}>City Coverage Split</td>
          {[...Array((list?.NumberOfSplits == undefined || list?.NumberOfSplits.filter(x => x.coid == Coid).length == 0 || list?.NumberOfSplits.filter(x => x.coid == Coid)[0].size == '' || parseInt(list?.NumberOfSplits.filter(x => x.coid == Coid)[0].size) < 1) ? 0 : parseInt(list?.NumberOfSplits.filter(x => x.coid == Coid)[0].size))].map((x, i) => (
            (props.sm.CostingOptions[splitTab].CostingOptionData.citiesCoverage?.length > 0 ?
              <td>
                <Select
                  options={props.sm.CostingOptions[splitTab].CostingOptionData.citiesCoverage}
                  name={props.sm.CostingOptions[splitTab].name}
                  onChange={(e) => samplesplitsize(e, "DistributedCity", i, Coid)}
                  value={(list?.SplitsData != null || list?.SplitsData != undefined) ? (list?.SplitsData.filter(x => x.id == i && x.coid == Coid).length > 0 ? list?.SplitsData.filter(x => x.id == i && x.coid == Coid)[0]?.DistributedCity == undefined ? "" : list?.SplitsData.filter(x => x.id == i && x.coid == Coid)[0]?.DistributedCity : "") : ""}
                  isMulti

                />
                {validCity && (
                  <div style={{ color: "#AD4400" }}>Please select the city</div>
                )}
              </td> : <td>No Cities Found</td>
            )
          ))}


        </tr>

        {_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id == "sampleSizeBooster")) &&
          <tr>

            <td style={{ position: "relative" }} colSpan={4}>Sample Size Boosters Split(*)</td>
            {[...Array((list?.NumberOfSplits == undefined || list?.NumberOfSplits.filter(x => x.coid == Coid).length == 0 || list?.NumberOfSplits.filter(x => x.coid == Coid)[0].size == '' || parseInt(list?.NumberOfSplits.filter(x => x.coid == Coid)[0].size) < 1) ? 0 : parseInt(list?.NumberOfSplits.filter(x => x.coid == Coid)[0].size))].map((x, i) => (
              (props.sm.CostingOptions[splitTab].CostingOptionData.citiesCoverage?.length > 0 ?

                <td>
                  <input
                    type="number"
                    name={props.sm.CostingOptions[splitTab].name}
                    id={i}
                    min={0}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    pattern="[0-9]"
                    onChange={(e) => {
                      (e.target.value < 0) ? toastr.error("Please Enter Valid Number")
                        : samplesplitsize(e.target.value, "DistributedBoosterSampleSize", i, Coid)
                    }}
                    value={(list?.SplitsData != null || list?.SplitsData != undefined) ? (list?.SplitsData.filter(x => x.id == i && x.coid == COID).length > 0 ? list?.SplitsData.filter(x => x.id == i && x.coid == COID)[0]?.DistributedBoosterSampleSize == undefined ? "" : list?.SplitsData.filter(x => x.id == i && x.coid == COID)[0]?.DistributedBoosterSampleSize : "") : ""}
                  />
                  {validBooster && (
                    <div style={{ color: "#AD4400" }}>Entered Number is invalid</div>
                  )}
                </td>
                : <td>No Sample Size Found</td>

              )))}
          </tr>
        }

      </>
    )
  }
  function splitdata(e) {
    if (e.target.value < 0) {
      toastr.error("Please Enter Valid Number")
    }
    else {
      var splitsize = _.cloneDeep(list)
      let Cid = props.sm.CostingOptions[splitTab].id;
      if (splitsize == null) {
        splitsize = {};
        splitsize["NumberOfSplits"] = [];
        splitsize["NumberOfSplits"].push({ size: e.target.value, coid: Cid, saved: false });
      }
      else {
        if (splitsize["NumberOfSplits"].filter(x => x.coid == Cid).length > 0) {
          splitsize["NumberOfSplits"].filter(x => x.coid == Cid)[0].size = e.target.value;
          splitsize["NumberOfSplits"].filter(x => x.coid == Cid)[0].saved = false;
          splitsize["SplitsData"] = splitsize?.SplitsData?.filter(data => data.coid != Cid)
        }
        else {
          splitsize["NumberOfSplits"].push({ size: e.target.value, coid: Cid });

        }
      }
      setlist(splitsize)
    }
  }
  function returnDistribution(vendor) {
    return (
      <>
        <tr>
          <td style={{ position: "relative" }} colSpan={4}>Select Distribution</td>
          {props.sm.CostingOptions.map(
            (coheader, indexcoheader) =>
            (coheader.Selected && (<td>
              <Select
                name={coheader.name}
                className="react-select-container"
                classNamePrefix="react-select"
                options={!props.sm?.SplitSampledata?.validate ? [] : (props.sm.SplitSampledata?.SplitsData?.filter(y => (y.coid == coheader.id)).map(x => ({ value: x.id, label: x.DistributionName })))}
                onChange={(e) => NameOfDistributions(coheader, e, vendor)}
                value={props?.sm?.Vendors?.filter(a => a.value == vendor.value)[0].CostingData?.filter(b => b.coid == coheader.id && b.soid == 14)[0]?.Selected}
              />
            </td>
            )))}
        </tr>
        {(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize"))) || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup")))) &&
          <tr>
            <td style={{ position: "relative" }} colSpan={4}>{(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize"))) || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup")))).label} Split</td>
            {props.sm.CostingOptions.map(
              (coheader, indexcoheader) =>

              (coheader.Selected && (coheader.CostingOptionData[(_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("sampleSize"))) || _.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id?.includes("numberOfGroup"))))?.id] != undefined ?
                <td>
                  <input
                    type="number"
                    disabled={true}
                    name={coheader.name}
                    onChange={(e) => DistributeSampleSize(coheader, e, vendor)}
                    value={props.sm.Vendors.filter(a => a.value == vendor.value)[0].CostingData.filter(b => b.coid == coheader.id && b.soid == 14)
                    [0]?.DistributedSampleSize != undefined ? props.sm.Vendors.filter(a => a.value == vendor.value)[0].CostingData
                      .filter(b => b.coid == coheader.id && b.soid == 14)[0]?.DistributedSampleSize : ""}
                  />
                  {valid && (
                    <div style={{ color: "#AD4400" }}>Entered Number is invalid</div>
                  )}
                </td>
                : <td>No Sample Size Found</td>
              ))
            )}
          </tr>
        }
        <tr>
          <td style={{ position: "relative" }} colSpan={4}>City Coverage Split</td>
          {props.sm.CostingOptions.map(
            (coheader, indexcoheader) =>
            (coheader.Selected && (coheader.CostingOptionData.citiesCoverage?.length > 0 ?
              <td>
                <Select
                  options={coheader.CostingOptionData.citiesCoverage}
                  name={coheader.name}
                  onChange={(e) => DistributeCity(coheader, e, vendor)}
                  value={props.sm.Vendors.filter(a => a.value == vendor.value)[0].CostingData.filter(b => b.coid == coheader.id && b.soid == 14)[0]?.DistributedCity != undefined ? props.sm.Vendors.filter(a => a.value == vendor.value)[0].CostingData.filter(b => b.coid == coheader.id && b.soid == 14)[0]?.DistributedCity : ""}
                  isDisabled
                  isMulti
                />
                {validCity && (
                  <div style={{ color: "#AD4400" }}>Please select the city</div>
                )}
              </td> : <td>No Cities Found</td>
            ))
          )}


        </tr>

        {_.head(props.sm.CostingOptions[0].CostingOptionSchema?.filter(a => a.id == "sampleSizeBooster")) &&
          <tr>

            <td style={{ position: "relative" }} colSpan={4}>Sample Size Boosters Split</td>
            {props.sm.CostingOptions.map(
              (coheader, indexcoheader) =>
              (coheader.Selected && (
                <td>
                  <input
                    type="number"
                    name={coheader.name}
                    onChange={(e) => DistributeBooster(coheader, e, vendor)}
                    value={props.sm.Vendors.filter(a => a.value == vendor.value)[0].CostingData.filter(b => b.coid == coheader.id && b.soid == 14)[0]?.DistributedBoosterSampleSize != undefined ? props.sm.Vendors.filter(a => a.value == vendor.value)[0].CostingData.filter(b => b.coid == coheader.id && b.soid == 14)[0]?.DistributedBoosterSampleSize : ""}
                    disabled={true}
                  />
                  {validBooster && (
                    <div style={{ color: "#AD4400" }}>Entered Number is invalid</div>
                  )}
                </td>

              ))
            )}


          </tr>}


        <tr>
          <td style={{ position: "relative" }} colSpan={4}>Comments(City Coverage)</td>
          {props.sm.CostingOptions.map(
            (coheader, indexcoheader) =>
            (coheader.Selected && (
              <td>

                <textarea
                  className="form-control w-100"
                  type="text"
                  name={coheader.name}
                  onChange={(e) => DistributeComments(coheader, e, vendor)}
                  value={props.sm.Vendors.filter(a => a.value == vendor.value)[0].CostingData.filter(b => b.coid == coheader.id && b.soid == 14)[0]?.DistributedComments != undefined ? props.sm.Vendors.filter(a => a.value == vendor.value)[0].CostingData.filter(b => b.coid == coheader.id && b.soid == 14)[0]?.DistributedComments : ""}

                />


              </td>
            ))
          )}


        </tr>

      </>);

  }
  function NameOfDistributions(coheader, e, selectedvendor) {

    var newVendor = _.cloneDeep(props.sm.Vendors)
    newVendor.forEach(vendor => {
      if (vendor.value == selectedvendor.value) {

        vendor.CostingData.filter(c => c.coid == coheader.id && c.soid == 14)[0]["Selected"] = e;
        vendor.CostingData.filter(c => c.coid == coheader.id && c.soid == 14)[0]["DistributionName"] = e.label;
        vendor.CostingData.filter(c => c.coid == coheader.id && c.soid == 14)[0]["DistributedCity"] =
          list.SplitsData?.filter(x => x.id == e.value && x.coid == coheader.id && x.DistributionName == e.label)[0].DistributedCity;
        vendor.CostingData.filter(c => c.coid == coheader.id && c.soid == 14)[0]["DistributedSampleSize"] =
          parseInt(list.SplitsData?.filter(x => x.id == e.value && x.coid == coheader.id && x.DistributionName == e.label)[0].DistributedSampleSize);
        vendor.CostingData.filter(c => c.coid == coheader.id && c.soid == 14)[0]["DistributedBoosterSampleSize"] =
          parseInt(list.SplitsData?.filter(x => x.id == e.value && x.coid == coheader.id && x.DistributionName == e.label)[0].DistributedBoosterSampleSize);

      }
    }

    )
    const newProposal = update(project.CostingProfiles[costingProfileIndex], {
      CountrySpecs: {
        [props.indexCountry]: {
          MethodologySpecs: {
            [props.indexMethodology]: {
              Vendors: { $set: newVendor },
            },
          },
        },
      },
    });

    project.CostingProfiles[costingProfileIndex] = newProposal;

    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });

  }
  function returnControl(vendor) {
    if (vendor.biddingType == 2) {
      return (
        <>
          {" "}
          {(project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]
            .MethodologySpecs[props.indexMethodology].FullServiceRequired != 1
            ? initailstate.filter((f) => f.group == "Field Cost")
            : initailstate
          ).map(
            (v, index) =>
              v.id != 14 && (
                <tr>
                  {!v.header && (
                    <>
                      <td style={{ position: "relative" }} colSpan={v.name.includes("Other Cost") ? 1 : 2} >
                        <input
                          style={{
                            marginRight: "10px",
                          }}
                          type="checkbox"
                          checked={

                            vendor.selectedrows.filter((a) => a.id == v.id)
                              .length == 0
                              ? false
                              : vendor.selectedrows.filter(
                                (a) => a.id == v.id
                              )[0].selected
                          }
                          name={v.name}
                          title="Select Option"
                          onChange={(e) => {
                            rowselected(e, v.id);
                          }}
                        />
                        {
                          v.name
                        }
                      </td>
                      {
                        v.name.includes("Other Cost") ?
                          <td>
                            <input type="text" placeholder="Please Specify..."
                              style={{ marginRight: "1px", right: "0" }}
                              value={
                                vendor.selectedrows.filter((a) => a.id == v.id).length > 0 && vendor.selectedrows.filter((a) => a.id == v.id)[0].otherCost ?
                                  vendor.selectedrows.filter((a) => a.id == v.id)[0].otherCost : ""
                              }

                              onChange={(e) => {
                                changeFieldName(e, v, vendor)
                              }}
                            /></td>
                          : null
                      }
                      <td>
                        <FontAwesomeIcon
                          title="History"
                          className="align-middle"
                          icon={faHistory}
                          style={{
                            cursor: "pointer",
                            marginLeft: "10px",
                          }}
                          onClick={() => {
                            Tooltip(v.id);
                          }}
                        />
                      </td>
                    </>
                  )}
                  {v.header && (


                    <th
                      style={{
                        backgroundColor: "#dcdcdc",
                        borderStyle: "groove",
                        borderRight: "10px",
                        borderBottom: "10px",
                      }}
                      colSpan={
                        v.header ? props.sm.CostingOptions.length + 3 : 1
                      }
                    >
                      <input
                        style={{
                          marginRight: "10px",
                        }}
                        type="checkbox"
                        checked={
                          vendor.selectedrows.filter((a) => a.id == v.id)
                            .length == 0
                            ? false
                            : (vendor.selectedrows.filter(
                              (a) => a.id == v.id
                            )[0].selected)
                        }
                        name={v.name}
                        title="Select Option"
                        onChange={(e) => {
                          rowselected(e, v.id, v.name);
                        }}
                      />

                      {v.header}
                    </th>

                  )}

                  {props.sm.CostingOptions.map(
                    (coheader, indexcoheader) =>
                      !v.header && coheader.isSelected && (
                        <td>
                          <InputGroup >


                            <input
                              type="number"
                              name={coheader.name}
                              min={0}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              pattern="[0-9]"
                              disabled={
                                vendor.label.includes("TCS") ? false : (
                                  vendor.CostingData.filter(
                                    (cd) => cd.coid == coheader.id && cd.soid == 14
                                  ).length > 0
                                    ? vendor.CostingData.filter(
                                      (cd) =>
                                        cd.coid == coheader.id && cd.soid == 14
                                    )[0].enable
                                    : true)
                                  ||
                                  (vendor.selectedrows.filter((a) => a.id == v.id)[0]?.selected ? false : true)
                              }
                              onChange={(e) => {
                                (e.target.value < 0) ? toastr.error("Please Enter Valid Number")
                                  : addvendor(e, coheader, v, vendor)
                              }}
                              onFocus={(e) => {
                                (e.target.value < 0) ? toastr.error("Please Enter Valid Number")
                                  : addHistory(e, coheader, v, vendor)
                              }}
                              value={

                                (vendor.CostingData.filter(
                                  (cd) =>
                                    cd.coid == coheader.id && cd.soid == v.id
                                ).length > 0
                                  ? (
                                    vendor.CostingData.filter(
                                      (cd) =>
                                        cd.coid == coheader.id && cd.soid == v.id
                                    )[0].VendorData == '' ? "" :
                                      (v.type == "TimingSection" ? vendor.CostingData.filter(
                                        (cd) =>
                                          cd.coid == coheader.id && cd.soid == v.id
                                      )[0].VendorData :
                                        Math.round(vendor.CostingData.filter(
                                          (cd) =>
                                            cd.coid == coheader.id && cd.soid == v.id
                                        )[0].VendorDataUSD * conversionRate)
                                      ))
                                  : "")
                              }
                            />
                            {v.type == "TimingSection" ? <InputGroupAddon addonType="prepend">Days</InputGroupAddon> : <InputGroupAddon addonType="prepend">{props.sm.Currency == null ? "USD" : props.sm.Currency.label}</InputGroupAddon>}
                          </InputGroup>
                        </td>
                      )
                  )}
                </tr>
              )
          )}
          {initailstate.map(
            (v, index) =>
              v.id == 14 && (
                <tr>
                  <th colSpan={biddingType == 0 ? 2 : 1}>{biddingType == 0 ? v.name : "Total SUD"}</th>
                  <td></td>

                  {props.sm.CostingOptions.map((coheader, indexcoheader) => (
                    coheader.isSelected &&
                    <td>
                      <InputGroup>
                        <input
                          type="number"
                          name={coheader.name}
                          min={0}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          pattern="[0-9]"

                          disabled={v.disable}
                          onChange={(e) => {
                            (e.target.value < 0) ? toastr.error("Please Enter Valid Number")
                              : addvendor(e, coheader, v, vendor)
                          }}
                          value={
                            vendor.CostingData.filter(
                              (cd) =>
                                cd.coid == coheader.id && cd.soid == v.id
                            ).length > 0 ?
                              (props.sm.Currency.label == "USD" ?
                                vendor.CostingData
                                  .filter((cd) => {
                                    return cd.VendorDataUSD && cd.coid == coheader.id && cd.soid != 14 && cd.type != "TimingSection";
                                  })
                                  .map((v) => v.VendorDataUSD)
                                  .reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0) :
                                vendor.CostingData
                                  .filter((cd) => {
                                    return cd.VendorDataUSD && cd.coid == coheader.id && cd.soid != 14 && cd.type != "TimingSection"
                                  })
                                  .map((v) => v.VendorDataUSD * conversionRate)
                                  .reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0))

                              //   Math.round(vendor.CostingData.filter(
                              //     (cd) =>
                              //       cd.coid == coheader.id && cd.soid == v.id
                              //   )[0].VendorDataUSD * conversionRate))

                              : ""
                          }
                        />
                        {v.type == "TimingSection" ? <InputGroupAddon addonType="prepend">Days</InputGroupAddon> : <InputGroupAddon addonType="prepend">{props.sm.Currency == null ? "USD" : props.sm.Currency.label}</InputGroupAddon>}
                      </InputGroup>
                    </td>
                  ))}
                </tr>
              )
          )}
        </>
      );
    } else if (biddingType == 0 || biddingType == 1) {
      return (
        <>
          {" "}

          {props.sm.Vendors.filter(
            (v) =>
              v.group.includes(biddingType == 0 ? "Field Cost" : SudTabsName)
          ).length > 0 &&
            initailstate
              .filter(
                (e) =>
                  e.group ==
                  (biddingType == 0 ? "Field Cost" : initailstate.filter((s) => (s.header && s.sud == true))[SudTabs].header) ||
                  e.header ==
                  (biddingType == 0 ? "Field Cost" : initailstate.filter((s) => (s.header && s.sud == true))[SudTabs].header) ||
                  e.id == 14 ||
                  e.compulsory
              )
              .map(
                (v, index) =>
                  v.id != 14 && (
                    <tr>
                      {!v.header && (
                        <>
                          <td
                            colSpan={v.name.includes("Other Cost") ? 1 : 2}
                          >
                            <input
                              style={{
                                marginRight: "10px",
                              }}
                              type="checkbox"
                              checked={
                                vendor.selectedrows.filter((a) => a.id == v.id)
                                  .length == 0
                                  ? false
                                  : vendor.selectedrows.filter(
                                    (a) => a.id == v.id
                                  )[0].selected
                              }
                              name={v.name}
                              title="Select Option"
                              onChange={(e) => {
                                rowselected(e, v.id);
                              }}
                            />

                            {v.name}
                          </td>
                          {
                            v.name.includes("Other Cost") ?
                              <td>
                                <input type="text" placeholder="Please Specify..."
                                  style={{ marginRight: "1px", right: "0" }}
                                  value={
                                    vendor.selectedrows.filter((a) => a.id == v.id).length > 0 && vendor.selectedrows.filter((a) => a.id == v.id)[0].otherCost ?
                                      vendor.selectedrows.filter((a) => a.id == v.id)[0].otherCost : ""
                                  }

                                  onChange={(e) => {
                                    changeFieldName(e, v, vendor)
                                  }}
                                /></td>
                              : null
                          }
                          <td>
                            <FontAwesomeIcon
                              title="History"
                              className="align-middle"
                              icon={faHistory}
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => {
                                Tooltip(v.id);
                              }}
                            />
                          </td>
                        </>
                      )}

                      {v.header && (
                        <th
                          style={{
                            backgroundColor: "#dcdcdc",
                            borderStyle: "groove",
                            borderRight: "10px",
                            borderBottom: "10px",
                          }}
                          colSpan={
                            v.header ? props.sm.CostingOptions.length + 3 : 1
                          }
                        >
                          {/* <input
                            style={{
                              marginRight: "10px",
                            }}
                            type="checkbox"
                            checked={
                              vendor.selectedrows.filter((a) => a.id == v.id)
                                .length == 0
                                ? false
                                : (vendor.selectedrows.filter(
                                  (a) => a.id == v.id
                                )[0].selected)
                            }
                            name={v.name}
                            title="Select Option"
                            onChange={(e) => {
                              rowselected(e, v.id, v.name);
                            }}
                          /> */}

                          {v.header}
                        </th>

                      )}

                      {props.sm.CostingOptions.map(
                        (coheader, indexcoheader) =>
                          !v.header && coheader.isSelected && (
                            <td>
                              <InputGroup>
                                <input
                                  type="number"
                                  min={0}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  pattern="[0-9]"

                                  name={coheader.name}
                                  // disabled={
                                  //   vendor.CostingData.filter(
                                  //     (cd) =>
                                  //       cd.coid == coheader.id && cd.soid == 14
                                  //   ).length > 0
                                  //     ? vendor.CostingData.filter(
                                  //         (cd) =>
                                  //           cd.coid == coheader.id &&
                                  //           cd.soid == 14
                                  //       )[0].enable
                                  //     : true
                                  // }
                                  disabled={
                                    vendor.label.includes("TCS") ? false : (
                                      vendor.CostingData.filter(
                                        (cd) => cd.coid == coheader.id && cd.soid == 14
                                      ).length > 0
                                        ? vendor.CostingData.filter(
                                          (cd) =>
                                            cd.coid == coheader.id && cd.soid == 14
                                        )[0].enable
                                        : true) ||
                                      (vendor.selectedrows.filter((a) => a.id == v.id)[0]?.selected ? false : true)
                                  }
                                  onChange={(e) => {
                                    (e.target.value < 0) ? toastr.error("Please Enter Valid Number")
                                      : addvendor(e, coheader, v, vendor)
                                  }}
                                  onFocus={(e) => {
                                    (e.target.value < 0) ? toastr.error("Please Enter Valid Number")
                                      : addHistory(e, coheader, v, vendor)
                                  }
                                  }
                                  value={
                                    vendor.CostingData.filter(
                                      (cd) =>
                                        cd.coid == coheader.id && cd.soid == v.id
                                    ).length > 0
                                      ? (
                                        vendor.CostingData.filter(
                                          (cd) =>
                                            cd.coid == coheader.id && cd.soid == v.id
                                        )[0].VendorData == '' ? "" : (v.type == "TimingSection" ? vendor.CostingData.filter(
                                          (cd) =>
                                            cd.coid == coheader.id && cd.soid == v.id
                                        )[0].VendorData :
                                          Math.round(vendor.CostingData.filter(
                                            (cd) =>
                                              cd.coid == coheader.id &&
                                              cd.soid == v.id
                                          )[0].VendorDataUSD * conversionRate)
                                        )) : ""
                                  }
                                />

                                {v.type == "TimingSection" ? <InputGroupAddon addonType="prepend">Days</InputGroupAddon> : <InputGroupAddon addonType="prepend">{props.sm.Currency == null ? "USD" : props.sm.Currency.label}</InputGroupAddon>}
                              </InputGroup>
                            </td>
                          )
                      )}
                    </tr>
                  )
              )}
          {initailstate.map(
            (v, index) =>
              v.id == 14 && (
                <tr>
                  <th
                    colSpan={2}
                  >{biddingType == 0 ? v.name : "Total SUD"}</th>
                  <td></td>

                  {props.sm.CostingOptions.map((coheader, indexcoheader) => (
                    coheader.isSelected &&
                    <td>
                      <InputGroup>
                        <input
                          type="number"
                          min={0}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          pattern="[0-9]"

                          name={coheader.name}
                          disabled={v.disable}
                          onChange={(e) => {
                            (e.target.value < 0) ? toastr.error("Please Enter Valid Number")
                              : addvendor(e, coheader, v, vendor)
                          }}
                          value={
                            vendor.CostingData &&
                              vendor.CostingData.length > 0 ?

                              vendor.CostingData.filter((cd) => {
                                return cd.group == (biddingType == 0 ? "Field Cost" : SudTabsName) && cd.soid != 14 && cd.coid == coheader.id && cd.type != "TimingSection"
                              }).length > 0 ?
                                (props.sm.Currency.label == "USD" ?
                                  vendor.CostingData.filter((cd) => {
                                    return cd.group == (biddingType == 0 ? "Field Cost" : SudTabsName) && cd.soid != 14 && cd.coid == coheader.id && cd.type != "TimingSection"
                                  })
                                    .map((c) => (isNaN(c.VendorDataUSD) ? "0" : c.VendorDataUSD))
                                    .reduce((a, b) => Math.round(a) + Math.round(b) + 0, 0) :
                                  Math.round(vendor.CostingData.filter((cd) => {
                                    return cd.group == (biddingType == 0 ? "Field Cost" : SudTabsName) && cd.soid != 14 && cd.coid == coheader.id && cd.type != "TimingSection"
                                  })
                                    .map((c) => (isNaN(c.VendorDataUSD) ? "0" : c.VendorDataUSD))
                                    .reduce((a, b) => parseFloat(a) + parseFloat(b) + 0, 0) * conversionRate))
                                : 0 : 0
                          }
                        />
                        {v.type == "TimingSection" ? <InputGroupAddon addonType="prepend">Days</InputGroupAddon> : <InputGroupAddon addonType="prepend">{props.sm.Currency == null ? "USD" : props.sm.Currency.label}</InputGroupAddon>}
                      </InputGroup>
                    </td>
                  ))}
                </tr>
              )
          )}
        </>
      );
    }
  }

  function returnSudTabs() {
    // initailstate=(props.sm.label=="Online Self Completion Survey"?initailstateOnline:(props.sm.Quant?initialstateQuant:initialstateQual))

    return (

      <Col md={12} className={"methodologyTabs"}>
        {
          <Nav tabs>
            {initailstate.length > 0 &&
              initailstate
                .filter((s) => s.header && s.sud == true)
                .map((subM, indexsubM) => (
                  <NavItem key={indexsubM}>
                    <NavLink
                      className={classnames({ active: SudTabs === indexsubM })}
                      onClick={() => {
                        if (SudTabs != indexsubM) {
                          setSudTabs(indexsubM);
                          setSudTabsName(subM.header);
                          setmethActiveTabSM(0)

                          props.sm.Vendors &&
                            setactivevendor(props.sm.Vendors.findIndex(val =>
                              val.group.includes(initailstate.filter((s) => s.header && s.sud)[indexsubM].header) && val.biddingType == 1
                            ));
                          setVendorData(
                            props.Vendor.filter(
                              (v) =>
                                props.sm.Vendors ? !props.sm.Vendors.some(
                                  (s) => s.biddingType == 0 && s.value == v.value
                                )
                                  : props.Vendor)
                          )
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          top: "-3rem",
                        }}
                      >
                        <div>
                          <b>{subM.header}</b>
                        </div>
                      </div>
                    </NavLink>
                  </NavItem>
                ))}
          </Nav>
        }
      </Col>
    );
  }

  function handleWarning(type, flag) {
    let warnFlag = (type === 'ok') ? true : false;
    setMessageBox(flag);
    isFullServiceRequired(warnFlag, checked);
  }

  function handleHistory(type, flag) {
    setOpen((type === 'close') ? flag : true)
  }

  function isFullServiceRequired(remove, check) {
    var tempproject = _.cloneDeep(
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]
        .MethodologySpecs
    );
    if (remove) {
      let flag = (check) ? 1 : 0;
      tempproject[props.indexMethodology].FullServiceRequired = flag;
      tempproject[props.indexMethodology].Vendors = []
      setBiddingType(!flag)
    }
    setBiddingType(0)
    updateProposal('Methodology', props.indexCountry, 0, project, { $set: tempproject });
  }
  async function onFileClick(e) {
    try {
      var res = await axios.get(`/files/download/vendor/${e}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/pdf'
        }
      });
      if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${e}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      }

    }
    catch (err) {

      toastr.error("Loading Failed", err?.data?.message);
    }

  }
  function updateProposal(type, indCo, indMeth, project, data) {
    let newProposal;
    switch (type) {
      case 'Methodology':
        newProposal = update(project.CostingProfiles[costingProfileIndex], {
          CountrySpecs: {
            [indCo]: {
              MethodologySpecs: data,
            },
          },
        });
        break;
      case 'Vendors':
        newProposal = update(project.CostingProfiles[costingProfileIndex], {
          CountrySpecs: {
            [indCo]: {
              MethodologySpecs: {
                [indMeth]: {
                  Vendors: data,
                },
              },
            },
          },
        });
        break;
      case 'VendorComments':
        newProposal = update(project.CostingProfiles[costingProfileIndex], {
          CountrySpecs: {
            [indCo]: {
              MethodologySpecs: {
                [indMeth]: {
                  Vendors: {
                    [activevendor]: {
                      Comments: { $set: data },
                    },
                  }
                },
              },
            },
          },
        });
        break;
      default:
        break;
    }
    project.CostingProfiles[costingProfileIndex] = newProposal;
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
  }

  return (
    <>
      <Form>
        <Card>
          <CardHeader>
            <CardTitle tag="h5" className="my-2">
              <Row>
                <Col md={11}>
                  <Label>Bidding Details</Label>
                </Col>
                <Col md={1}>
                  <FontAwesomeIcon
                    id="collapseBiddingDetails"
                    icon={faChevronDown}
                    href="#collapseBiddingDetails"
                  />
                </Col>
              </Row>
            </CardTitle>
          </CardHeader>
          <UncontrolledCollapse
            toggler="#collapseBiddingDetails"
            defaultOpen={true}
          >
            <CardBody>
              <Row form>
                <Col md={4}>
                  <FormGroup>
                    <Label>QC Validation Mode</Label>
                    <Select
                      name="QCValidationMode"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={qcApplied()}
                      onChange={(e) => updateMethodologyInfo(e, 'QCValidationMode')}
                      value={
                        !props.sm.QCValidationMode
                          ? "no value"
                          : props.sm.QCValidationMode
                      }
                      isMulti
                    />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>Currency Selection</Label>
                    <Select
                      name="CurrencySelection"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={project.CostingProfiles[costingProfileIndex]?.CountrySpecs.length > 0 && _.head(project.CostingProfiles[costingProfileIndex]?.ProfileSetting.CurrenciesData) ? currencyDropdown() : []}
                      onChange={(e) => updateMethodologyInfo(e, 'Currency')}
                      value={
                        props.sm.Currency ? props.sm.Currency : (project?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.length > 0 ? defaultvalueforcurrency() : "")
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Other Project Specification</Label>
                    <textarea
                      className="form-control w-100"
                      style={{ height: "4rem" }}
                      type="text"
                      name="OtherProjectSpecification"
                      onChange={(e) => updateMethodologyInfo(e, 'OtherProjectSpecification')}
                      value={
                        props.sm.OtherProjectSpecification
                          ? props.sm.OtherProjectSpecification
                          : ""
                      }
                    />


                  </FormGroup>
                </Col>

              </Row>
              <Row>

                <Col md={12}>
                  <BiddingFiles methodology={props.sm} vendorfiles={false} MethId={props.sm.id} index={props.indexMethodology} 
                  indexcountry={props.indexCountry} fullservicerequired={props.sm.FullServiceRequired}></BiddingFiles>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        openModal(e);

                      }}
                      checked={
                        project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
                          props.indexCountry
                        ].MethodologySpecs[props.indexMethodology]
                          .FullServiceRequired == 1
                          ? true
                          : false
                      }
                      disabled={(project?.CostingProfiles[costingProfileIndex]?.Deadlinestatus == "close" ? true : false)}
                    />
                    <Label> Full Service Required</Label>
                  </FormGroup>
                </Col>
                {biddingType == 0 && <>



                  <Col md={4}>
                    <FormGroup>


                      <input
                        type="checkbox"
                        onChange={(e) => {
                          splitChange(e);
                        }}
                        checked={
                          project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
                            props.indexCountry
                          ].MethodologySpecs[props.indexMethodology]
                            .SplitSample == 1
                            ? true
                            : false
                        }
                        disabled={(project?.CostingProfiles[costingProfileIndex]?.Deadlinestatus == "close" ? true : false)}
                      />
                      <Label> Sample/City Split Required ?</Label>



                    </FormGroup>
                  </Col>


                  {
                    project?.CostingProfiles[costingProfileIndex]?.CountrySpecs[
                      props?.indexCountry
                    ]?.MethodologySpecs[props.indexMethodology]
                      ?.SplitSample == 1 &&

                    <Button color="primary"
                      onClick={() => setOpenModalSplit(true)}
                    >Define SampleSplit
                    </Button>
                  }
                </>}

              </Row>

              <Row>
                <Col md={12} className={"methodologyTabs"}>
                  <Nav tabs>
                    {
                      <NavItem>
                        <NavLink
                          className={classnames({ active: biddingType == 0 })}
                          onClick={() => {
                            Togglesud(0);
                          }}
                        >
                          <b>
                            {props.sm.FullServiceRequired == 1
                              ? "Full Service"
                              : "Field Cost"}
                          </b>
                        </NavLink>
                      </NavItem>
                    }
                    {project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
                      props.indexCountry
                    ].MethodologySpecs[props.indexMethodology]
                      .FullServiceRequired != 1 && (
                        <NavItem>
                          <NavLink
                            className={classnames({ active: biddingType == 1 })}
                            onClick={() => {
                              Togglesud(1);

                              setSudTabsName(initailstate?.filter((d) => d.header && d.sud)[0]?.header)
                              setVendorData(
                                props.Vendor?.filter(
                                  (v) =>
                                    props.sm.Vendors ? !props.sm.Vendors.some(
                                      (s) => s.biddingType == 0 && s.value == v.value
                                    ) : props.Vendor
                                )
                              )
                            }}
                          >
                            <b>Setup & Delivery Work Only</b>
                          </NavLink>
                        </NavItem>
                      )}
                  </Nav>
                </Col>
              </Row>
              <br />
              <br />

              {biddingType == 1 && returnSudTabs()}

              <br />
              <br />
              {biddingType == 1 && (
                <Col md={12}>
                  <FormGroup>
                    <Label>Select Vendor Type</Label>
                    <Select
                      name="SelectVendorType"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={VendorType}
                      onChange={(e) => selectVendorType(e)}
                      value={
                        props.sm.Vendors &&
                          props.sm.Vendors.filter(
                            (v) => v.biddingType == 1
                          ).filter(
                            (f) =>
                              f.vendorType == "TCS" &&
                              f.group.includes(SudTabsName)
                          ).length > 0
                          ? VendorType[0]
                          : VendorType[1]
                      }
                    />
                  </FormGroup>
                </Col>
              )}


              {
                biddingType == 0 &&
                (props.sm.FullServiceRequired == 1 ? true : (props.sm.Vendors == null ? true : props.sm.Vendors.filter((v) => v.biddingType == 0).filter((t) => t.vendorType == "TCS").length == 0)) &&



                (<Col md={12}>
                  <FormGroup>
                    <Label>Recommended Vendors</Label>
                    <Select
                      name="Recommended"
                      onChange={(e) => (project?.CostingProfiles[costingProfileIndex]?.Deadlinestatus == "close" ? toastr.error("Bidding is closed") : vendoradd(e))}
                      className="basic-multi-select"
                      classNamePrefix="react-select"
                      disabled={(project?.CostingProfiles[costingProfileIndex]?.Deadlinestatus == "close" ? true : false)}
                      value={
                        props.sm.Vendors != null
                          ? biddingType == 0 &&
                            project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
                              props.indexCountry
                            ].MethodologySpecs[props.indexMethodology]
                              .FullServiceRequired != 1
                            ? props.sm.Vendors.filter(
                              (v) => v.biddingType == biddingType
                            )
                            : project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
                              props.indexCountry
                            ].MethodologySpecs[props.indexMethodology]
                              .FullServiceRequired == 1
                              ? props.sm.Vendors.filter(
                                (v) => v.biddingType == 2
                              )
                              : props.sm.Vendors.filter(
                                (v) => v.biddingType == biddingType
                              ).filter(
                                (t) =>
                                  t.group ==
                                  initailstate.filter((e) => e.header)[
                                    SudTabs
                                  ].header
                              )
                          : null
                      }
                      options={filteredVendors()}
                      isMulti
                    />
                  </FormGroup>
                </Col>

                )}

              {
                biddingType == 1 &&
                (props.sm.Vendors == null ? true : props.sm.Vendors.filter((v) => v.biddingType == 1).filter((t) => t.vendorType == "TCS" && t.group.includes(SudTabsName))?.length == 0) &&


                (<Col md={12}>

                  <FormGroup>
                    <Label>Recommended Vendors</Label>
                    <Select
                      name="Recommended"
                      onChange={(e) => (project?.CostingProfiles[costingProfileIndex]?.Deadlinestatus == "close" ? toastr.error("Bidding is closed") : vendoradd(e))}
                      className="basic-multi-select"
                      classNamePrefix="react-select"
                      disabled={(project?.CostingProfiles[costingProfileIndex]?.Deadlinestatus == "close" ? true : false)}
                      value={
                        props.sm.Vendors != null
                          ? props.sm.Vendors.filter(v => v.biddingType == 1).filter(d => d.group.includes(SudTabsName)) : null
                      }
                      options={
                        VendorData?.length > 0 ?
                          VendorData?.filter(a => (a.Countries?.filter(b => b.Code == project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry].CountryCode)?.length > 0)
                          ).filter(v => v.Methodology?.filter(s => s.Code == props.sm.Code)?.length > 0)
                            .filter(vt => vt.VendorType?.filter(ct => ct.Code == SudTabsName)?.length > 0) : []
                      }
                      isMulti
                    />
                  </FormGroup>
                </Col>

                )}
              {project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]
                .MethodologySpecs[props.indexMethodology].Vendors != null &&
                project.CostingProfiles[costingProfileIndex]?.CountrySpecs[props.indexCountry]
                  .MethodologySpecs[props.indexMethodology].Vendors?.length >
                0 &&
                initailstate?.length > 0 && (
                  <TabContent activeTab={methactiveTabSM}>
                    <TabPane tabId={methactiveTabSM}>
                      <Col md={12} className={"methodologyTabs"}>
                        {props.sm.Vendors && (
                          <Nav tabs>
                            {(biddingType == 1
                              ? props.sm.Vendors.filter(
                                (u) => u.biddingType == 1
                              )
                                .filter(
                                  (e) =>
                                    e.group.includes(SudTabsName)
                                )
                              : biddingType == 0 &&
                                project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
                                  props.indexCountry
                                ].MethodologySpecs[props.indexMethodology]
                                  .FullServiceRequired == 1
                                ? props.sm.Vendors.filter(
                                  (u) => u.biddingType == 2
                                )
                                : props.sm.Vendors.filter(
                                  (u) => u.biddingType == 0
                                )
                            ).map((vendor, indxMV) => (
                              <NavItem>
                                <NavLink
                                  style={vendor.decline ? { backgroundColor: "#b94e48" } : {}}
                                  className={classnames({
                                    active: methactiveTabSM === indxMV,
                                  })}
                                  onClick={() => {
                                    toggleMethodology(indxMV, vendor.value);
                                  }}
                                >
                                  <b>{vendor.label}</b>
                                </NavLink>
                              </NavItem>
                            ))}
                          </Nav>
                        )}
                        {(biddingType == 1
                          ? props.sm.Vendors.filter((u) => u.biddingType == 1)
                            .filter(
                              (e) =>
                                e.group.includes(SudTabsName)
                            )
                          : biddingType == 0 &&
                            project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
                              props.indexCountry
                            ].MethodologySpecs[props.indexMethodology]
                              .FullServiceRequired == 1
                            ? props.sm.Vendors.filter((u) => u.biddingType == 2)
                            : props.sm.Vendors.filter((u) => u.biddingType == 0)
                        ).map(
                          (vendor, indxMV) =>
                            methactiveTabSM === indxMV && (
                              <>
                                {vendor.decline &&
                                  <Row form style={{ marginTop: "1rem" }}>
                                    <Col md={12}>
                                      <Card style={{ backgroundColor: "#b94e48", color: "white" }}>
                                        Note: This Vendor has declined this project.
                                      </Card>
                                    </Col>
                                  </Row>

                                }
                                <Row form>
                                  {props.sm.SplitSample == 1 && biddingType == 0 &&
                                    props.sm?.Vendors[activevendor]?.selectedrows?.filter(a => a.group == "Field Cost" && a.selected)?.length > 0 &&
                                    <Table
                                      className="table table-stripped"
                                      size="sm"
                                      bordered
                                      style={{
                                        backgroundColor:
                                          //vendor.decline?"#b94e48": 
                                          "white",
                                        color:
                                          //vendor.decline?"white":
                                          "black",
                                        maxWidth: "100%",
                                        width: "auto",
                                      }}>                                        <tr>
                                        <th style={{
                                          // display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          minWidth: "250px",
                                        }}
                                          colSpan={4}>
                                          Required Sample
                                        </th>
                                        {props.sm.CostingOptions.map(
                                          (coheader) => (
                                            coheader.Selected &&
                                            (<th
                                              style={{
                                                maxWidth: "300px",
                                                backgroundColor: "dodgerblue",
                                                color: "white",
                                              }}
                                            >
                                              {coheader.Name}
                                            </th>)
                                          )
                                        )}

                                      </tr>

                                      {returnDistribution(vendor)}


                                    </Table>}
                                </Row>




                                <Row form>
                                  {
                                    <Table
                                      className="table table-stripped"
                                      size="sm"
                                      bordered
                                      style={{
                                        backgroundColor:
                                          //vendor.decline?"#b94e48": 
                                          "white",
                                        color:
                                          //vendor.decline?"white": 
                                          "black",
                                        maxWidth: "100%",
                                        width: "auto",
                                      }}
                                    >
                                      <tr>
                                        <th
                                          style={{
                                            // display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            minWidth: "250px",
                                          }}
                                          colSpan={2}
                                        >
                                          {vendor.biddingType == 0
                                            ? ""
                                            : ""}
                                        </th>
                                        <th></th>

                                        {props.sm.CostingOptions.map(
                                          (coheader) => (
                                            coheader.isSelected &&
                                            <th
                                              style={{
                                                maxWidth: "300px",
                                                backgroundColor: "dodgerblue",
                                                color: "white",
                                              }}
                                            >
                                              {coheader.Name}
                                              <input
                                                style={{
                                                  marginLeft: "10px",
                                                  marginRight: "10px",
                                                }}
                                                type="checkbox"
                                                checked={
                                                  biddingType == 1 ?
                                                    vendor.selectedCO.filter(
                                                      (f) =>
                                                        f.id == coheader.id && f.group == SudTabsName
                                                    )[0]?.selected
                                                    : vendor.selectedCO.filter(
                                                      (f) =>
                                                        f.id == coheader.id && f.group == "Field Cost"
                                                    )[0]?.selected
                                                  // coheader.isSelected

                                                }
                                                id={coheader.id}
                                                name={coheader.Name}
                                                title="Select Option"
                                                onChange={(e) => {
                                                  setchk(e);
                                                }}
                                              />

                                              <FontAwesomeIcon
                                                title="Edit"
                                                className="align-middle"
                                                icon={(vendor.label.includes("TCS") || vendor.decline) ? "" : faPen}
                                                style={{
                                                  cursor: "pointer",
                                                  marginLeft: "10px",
                                                }}
                                                onClick={() => {
                                                  disableinput(
                                                    coheader.id,
                                                    vendor
                                                  );
                                                }}
                                              />
                                            </th>
                                          )
                                        )}
                                      </tr>

                                      {returnControl(vendor)}
                                    </Table>
                                  }
                                </Row>
                                <BiddingFiles vendor={vendor} vendorfiles={true} VendorID={vendor.value} methodology={props.sm} MethId={props.sm.id} index={props.indexMethodology} indexcountry={props.indexCountry} fullservicerequired={props.sm.FullServiceRequired}></BiddingFiles>
                                <Col md={12}>
                                  <FormGroup>

                                    <div
                                      className={"tab tab-methodology-vendor"}
                                    >

                                      <Col md={12}>
                                        <FormGroup>
                                          <Label>Comments</Label>
                                          <textarea
                                            className="form-control w-100"
                                            type="text"
                                            onChange={(e) => updateMethodologyInfo(e, 'Comments')}
                                            value={props.sm.Vendors[activevendor]?.Comments}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </div>


                                  </FormGroup>
                                </Col>
                              </>
                            )
                        )}
                      </Col>
                    </TabPane>
                  </TabContent>
                )}

            </CardBody>
          </UncontrolledCollapse>
        </Card>
      </Form>
      {open && (
        <Modal
          isOpen={open}
          toggle={() => setOpen(!open)}
          centered
          className={"modelSelectVendors"}
        >
          <ModalHeader
            toggle={() => {
              setOpen(!open);
            }}
          >
            <span>Change History</span>
          </ModalHeader>
          <ModalBody className="m-3">
            <div style={{ maxHeight: "400px", overflow: "auto" }}>
              <table
                className="table table-bordered "
                border="0.4"
                style={{ position: "relative" }}
              >
                <tr style={{ backgroundColor: "#00F000", color: "black" }}>
                  <th>S.NO</th>
                  <th>Date</th>
                  <th>Costing Option</th>
                  <th>Last Value</th>
                  <th>Changed By</th>{" "}
                </tr>

                {project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
                  props.indexCountry
                ].MethodologySpecs[props.indexMethodology].Vendors[
                  activevendor
                ].CostingData.filter((r) => r.soid == sid)?.map((s) =>
                  //  {if(props.sm.Vendors[activevendor].selectedCO.filter(a=>a.id==s.coid)[0].selected){ 
                  s.History?.map((m) => (
                    <tr>
                      <td>{(i = i + 1)}</td>
                      <td>{m.date}</td>
                      <td>{m.coname}
                        {/* {
                          project.CostingProfiles[0].CountrySpecs[
                            props.indexCountry
                          ].MethodologySpecs[props.indexMethodology].Vendors[
                            activevendor
                          ].CostingData.filter(
                            (d) => d.coid == s.coid && d.soid == 14
                          )[0].cLabel
                        } */}
                      </td>
                      <td>{m.lastvalue}</td>
                      <td>{m.changedby}</td>
                    </tr>
                  ))
                  //}}
                )}
              </table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => { setOpen(false); }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )
      }

      {openModalSplit && (
        <Modal
          size="xl"
          isOpen={openModalSplit}
          toggle={() => {
            if (props.sm.SplitSampledata) { setlist(props.sm.SplitSampledata) }
            setOpenModalSplit(!openModalSplit)
          }}
          centered
          className={"modelSelectVendors"}
        >
          <ModalHeader
            toggle={() => {
              if (props.sm.SplitSampledata) { setlist(props.sm.SplitSampledata) }
              setOpenModalSplit(!openModalSplit);
            }}
          >
            <span>Split Sample Size/City</span>
          </ModalHeader>
          <ModalBody className="m-3">
            <div>
              <Nav tabs>
                {props.sm.CostingOptions.map((a, INDEX) =>
                  <NavItem>
                    {a.Selected && <NavLink
                      className={classnames({ active: splitTab == INDEX })}
                      onClick={() => {
                        setSplitTab(INDEX);
                      }}
                    >
                      <b>{a.Name}
                      </b>
                    </NavLink>}
                  </NavItem>
                )}

              </Nav>
              <Form><Card><CardBody><Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      {props.sm.CostingOptions[splitTab].CostingOptionSchema.filter(val => (val.id == "sampleSizeMain" || val.id == "numberOfGroup"))[0]?.label}
                    </Label>
                    <input
                      type="text"
                      value={props.sm.CostingOptions[splitTab]?.CostingOptionData?.numberOfGroup ?
                        props.sm.CostingOptions[splitTab]?.CostingOptionData?.numberOfGroup :
                        props.sm.CostingOptions[splitTab]?.CostingOptionData?.sampleSizeMain ?
                          props.sm.CostingOptions[splitTab]?.CostingOptionData?.sampleSizeMain : ""
                      }
                      disabled='disabled'

                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Sample Size (Booster)  </Label>
                    <input
                      type="text"
                      value={props.sm.CostingOptions[splitTab]?.CostingOptionData?.sampleSizeBooster}
                      disabled='disabled'

                    />
                  </FormGroup>
                </Col>
              </Row></CardBody></Card></Form>
              <TabContent activeTab={splitTab} style={{ padding: "0.25" }}>
                <><TabPane tabId={splitTab} key={props.sm.value}>
                  <div>
                    <Table striped bordered hover variant="dark" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {props.sm.CostingOptions[splitTab]?.CostingOptionData && <tr>
                        <td colSpan={4}>Number of Splits(*)</td>
                        <td><input
                          type="number"
                          name={"noofsplits"}
                          min={0}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          pattern="[0-9]"

                          // onInput={(e)=>setNumberOfSplits(Number(e.target.value)<1 ? 1 : Number(e.target.value))}>
                          onChange={(e) => splitdata(e)}
                          value={(list?.NumberOfSplits != null || list?.NumberOfSplits != undefined) ? (list?.NumberOfSplits.filter(x => x.coid == props.sm.CostingOptions[splitTab].id).length > 0 ? (list.NumberOfSplits.filter(x => x.coid == props.sm.CostingOptions[splitTab].id)[0]?.size) : "") : ""}
                        >
                        </input>
                        </td></tr>}
                    </Table>

                    <Table striped bordered hover variant="dark" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      {returnSplit()}
                    </Table>

                  </div>
                </TabPane>
                </>
              </TabContent>

            </div>
          </ModalBody>

          <ModalFooter style={{ justifyContent: "space-between" }}>
            <div style={{ color: "black", fontWeight: "bold" }}>
              Note : 1. Sum of Sample Size Main Split/Number of Groups Split should be equal to Sample Size Main/Number of Groups.
              <br />
              &emsp;&emsp;&ensp;&ensp;
              2. Sum of Sample Size Boosters Split should be equal to Sample Size (Booster) if available.
            </div>

            <div >
              <Button color="primary"
                onClick={() => SaveSampleSplitData()}
              >Submit
              </Button>
              &nbsp;
              <Button
                color="secondary"
                onClick={() => {
                  if (props.sm.SplitSampledata) { setlist(props.sm.SplitSampledata) }
                  closeSampleSplit()
                }}
              >
                Close
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )
      }
      <HistoryModal isOpen={open} project={project} indexCountry={props.indexCountry}
        indexMethodology={props.indexMethodology} activevendor={activevendor} sid={sid} popupHandler={(type, ev) => handleHistory(type, ev)} />

      <WarningModal isOpen={messageBox} message={alertBox} popupHandler={(type, ev) => handleWarning(type, ev)} />
    </>
  );
}
export default VendorBiddingForm;