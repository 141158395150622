import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import logo from "../../assets/img/avatars/modal-logo.png"; 
var imgStyle = {
  width: "150px",
};
export const ModalGeneric = ({
  isOpen,
  toggle,
  header,
  body,
  footer,
  backdrop,
  keyboard,
  headerNoToggle,
  size,
  id
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered={true}
      backdrop={backdrop}
      keyboard={keyboard}
      size={size ? size : "md"}
      id={id}
    >
      <ModalHeader toggle={headerNoToggle ? null : toggle}>
        {/* {header} */}
      {/* <img src={logo}  className="modallogo"/> */}

      </ModalHeader>
      <div>
      <span className="modaltitle">{header}</span>
      </div>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </Modal>
  );
};
