import React, { useEffect, useState } from "react";
import axios from "../../axios-interceptor";
import Wrapper from "../../components/Wrapper";
import Sidebar from "../../components/Sidebar";
import Main from "../../components/Main";
import ProjectNavbar from "../../components/ProjectNavbar";
import TimeCostModal from "../../components/TimeCostModal";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import Select from "react-select";
import {
  Row,
  Button,
  Col,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
  Table,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Container } from "react-bootstrap";
import {
  faPencilAlt,
  faInfoCircle,
  faCopy,
  faPlusCircle,
  faSave
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import _ from "lodash";
import * as sideBarActions from "../../redux/actions/sidebarActions";
import { Prompt } from "react-router-dom";
import TimeCostTable from "./TimeCostTable";
import CostingMarketTable from "./CostingMarketTable";
import {
  setCommercialHours,
  setOperationsHours,
  setOperationsCost,
  saveHourspayload,
  saveTotalCost,
  saveTimecost
} from "../../redux/actions/timecostHoursActions";
import InfoModal from "../../components/TimeCostModals/InfoModal";
import CopyOptionsModal from "./CopyOptionsModal";
import { toastr } from "react-redux-toastr";

const OperationsTimecost = () => {
  const dispatch = useDispatch();
  const timecostView = useSelector(({ timeCost }) => timeCost.selectedRole);
  const pageloaded = useSelector(({ app }) => app.pageloaded);
  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  let opsCostData = useSelector(
    ({ timecostHours }) => timecostHours.operations
  );
  let operationsCostData = useSelector(({ timecostHours }) => timecostHours.operationCost);
  const levelInput=timecostView==="commercial"?profile.CSInput:profile.OpsInput
  const currencies = useSelector(({ currentCosting }) =>
  currentCosting.currentCostingProfile.ProfileSetting &&
    currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
    ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
    : []
  );
  const [infoModal, setInfoModal] = useState(false);
  const [activeTabFC, setActiveTabFC] = useState(0);
  const [timecostViewModal, settimecostViewModal] = useState(false);
  const [activeTabSM, setActiveTabSM] = useState(0);
  const [activeTabCO, setActiveTabCO] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [OPSband, setOPSBand] = useState([]);
  const [activitiesForOPS, setActivitiesForOPS] = useState([]);
  const [alert, setAlert] = useState(false);
  const [activityMapping, setActivityMapping] = useState([]);
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const [localSupportOPS, setLocalSupportOPS] = useState(true);
  const [localSupportModalOPS, setLocalSupportModalOPS] = useState(true);
  const [role, setRole] = useState("OPS");
  const [OPSTeamDataOptions, setOPSTeamDataOptions] = useState([]);
  const [OPSTeamDataOptionsTemp, setOPSTeamDataOptionsTemp] = useState([]);
  const [defaultRates, setDefaultRates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isClear, setIsClear] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCostOptionID, setSelectedCostOptionID] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedCountryForClear, setSelectedCountryForClear] = useState();
  const [selectedCountryData, setSelectedCountryData] = useState({});
  const [selectedMethodologyData1, setSelectedMethodologyData1] = useState({});
  const [modalMethodology, setModalMethodology] = useState(false);
  const [opsModal, setOpsModal] = useState(false);
  const [options, setoptions] = useState();
  const [makeScroll, setMakeScroll] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [costoptionsDetails,setCostoptionsDetails]=useState({});
  const [deletedTimeCostData,setDeletedTimeCostData]=useState({});
  const [deletedCostData,setDeletedCostData]=useState({})
  const [OPSCurrencyUnit,setOPSCurrencyUnit]=useState("")
  const [OPSCurrencyConversion,setOPSCurrencyConversion]=useState(1)
  const [OPScurrencyDropdown,setOPScurrencyDropdown]=useState({})
  const [OPSCurrencyCode,setOPSCurrencyCode]=useState("")

  const teamData = async(countryArray,callBack) => {
    axios.get(`/timecost/country`).then((res) => {
      let opsTeamData = [];
      let opsTeamCountry = res.data.result;
      let opsTempData = [];

      opsTeamCountry.map((opsTeam) => {
          let optionObj = {
            value: opsTeam.Code,
            label: opsTeam.Label,
          };
          opsTeamData.push(optionObj);
      });
      if (countryArray && countryArray?.length > 0) {
        let cArray = [];
        countryArray?.map((c) => {
          if (profile.CountrySpecs[activeTabFC].MethodologySpecs[
            activeTabSM
          ]?.CostingOptions[activeTabCO]?.id == Object.keys(c)[0]
          ) {
            cArray.push(Object.values(c)[0])
          }
        });
        if (cArray && cArray?.length > 0) {
          opsTempData = opsTeamData.filter((data) =>
            !cArray.includes(data.label)
          );
        }
      }
      setOPSTeamDataOptions(opsTeamData);
      setOPSTeamDataOptionsTemp(opsTempData);
      callBack();
    });
  };
  const gettimecost = async () => {
    setIsLoading(true);
    const res = await axios.get(`/timecost/defaults`);
    const response = await axios.get(`/timecost/${profile.id}`);
    const rates = res?.data?.rates?.filter((rate) => rate.function_id === 2);
    setDefaultRates(rates);
    const activityDataForCS = res.data.activities.filter(
      (activity) => activity.function_id === 2
    );
    const bandsDataForCS = res.data.bands.filter(
      (activity) => activity.function_id === 1
    );
    const activityDataForOPS = res.data.activities.filter(
      (activity) => activity.function_id === 2
    );
    const bandsDataForOPS = res.data.bands.filter(
      (activity) => activity.function_id === 2
    );

    let temparr = [];
    let csarr = res.data.Mapping.filter(
      (activity) => activity.funtction_id === 1
    );
    temparr.push(csarr);
    let opsarr = res.data.Mapping.filter(
      (activity) => activity.funtction_id === 2
    );
    temparr.push(opsarr);
    setActivityMapping(temparr);
    setOPSBand(bandsDataForOPS);
    setActivitiesForOPS(activityDataForOPS);
    let commercial = {};
    let operations = {};
    let operationCost = {};
    let countryArray = [];

    response.data.datas.map((co) => {
      console.log(co.OptionId);
      let id = co.OptionId;
      commercial[id] = [];
      operations[id] = [];
      operationCost[id] = [];

      co.OPMSchema && co.OPMSchema?.length > 0 && co.OPMSchema.map((schema) => {
        let pmtable = [];
        let costTable = [];
        for (
          let activitypm = 0;
          activitypm < activityDataForOPS.length;
          activitypm++
        ) {
          let temparr = [];
          let activityName = activityDataForOPS[activitypm].activity_name;
          if (!(activityName in schema.Rateselection)) {
            temparr = Array(bandsDataForOPS.length).fill("");
          } else {
            let activityarr = schema.Rateselection[activityName];

            for (let bandCS = 0; bandCS < bandsDataForOPS.length; bandCS++) {
              let bandname = bandsDataForOPS[bandCS].band_name;
              let flag = true;
              activityarr.forEach(function (obj) {
                if (bandname in obj) {
                  temparr.push(obj[bandname]);
                  flag = false;
                }
              });
              if (flag) {
                temparr.push("");
              }
            }
          }
          pmtable.push(temparr);
        }
        let totalCost = 0;
        for (let bandCS = 0; bandCS < bandsDataForOPS.length; bandCS++) {
          let bandname = bandsDataForOPS[bandCS].band_name;
          totalCost += Number(schema.Rateselection[bandname]);           
          costTable.push({ [bandname]: schema.Rateselection[bandname] });
        }
        if(costTable && costTable?.length > 0){
          costTable.push({["totalCost"]:(Math.round(totalCost * 100) / 100).toFixed(2)});
        }
        let countryName = schema.Rateselection.dropdownCountry.label;
        countryArray.push({[id]:countryName});
        let obj = {};
        let costObj = {};
        costObj[countryName] = costTable;
        obj[countryName] = pmtable;
        operations[id].push(obj);
        operationCost[id].push(costObj);
      });
    });
    if(countryArray && countryArray?.length > 0){
      await teamData(countryArray,()=>{
        const tableHours = [];
    const tableCosts = [];
    
    for (let i = 0; i < activityDataForOPS.length; i++) {
      tableHours.push(Array(bandsDataForOPS.length).fill(""));
    }

    for (let i = 0; i < bandsDataForOPS.length; i++) {
      tableCosts.push(Array(bandsDataForOPS.length).fill(""));
    }

    profile.CountrySpecs.map((country, index) => {
      const newRow = {
        [profile.CountrySpecs[index]?.CountryName]: tableHours,
      };
  
      const newCostRow = {
        [profile.CountrySpecs[index]?.CountryName]: tableCosts,
      };
  
      const newTable = [newRow];
      const newCost = [newCostRow];

      country.MethodologySpecs.map((method) => {
        method.CostingOptions.map((co,index) => {
          if(!(co.id in operations) || (operations[co.id] && !operations[co.id].length)) {
            operations[co.id] = newTable;
          }
          if(!(co.id in operationCost) || (operationCost[co.id] && !operationCost[co.id].length)) {
            operationCost[co.id] = newCost;
          }
        });
      });
    });

    dispatch(setCommercialHours(commercial));
    dispatch(setOperationsHours(operations));
    dispatch(setOperationsCost(operationCost));
    setIsLoading(false);
      });
    }else{
      let newArray = [];
      let id=profile.CountrySpecs[activeTabFC].MethodologySpecs[
        activeTabSM
      ]?.CostingOptions[activeTabCO]?.id;
      let name = profile.CountrySpecs[activeTabFC].CountryName;
      newArray.push({ [id]: name });      
      await teamData(newArray,()=>{
        const tableHours = [];
    const tableCosts = [];
    
    for (let i = 0; i < activityDataForOPS.length; i++) {
      tableHours.push(Array(bandsDataForOPS.length).fill(""));
    }

    for (let i = 0; i < bandsDataForOPS.length; i++) {
      tableCosts.push(Array(bandsDataForOPS.length).fill(""));
    }

    profile.CountrySpecs.map((country, index) => {
      const newRow = {
        [profile.CountrySpecs[index]?.CountryName]: tableHours,
      };
  
      const newCostRow = {
        [profile.CountrySpecs[index]?.CountryName]: tableCosts,
      };
  
      const newTable = [newRow];
      const newCost = [newCostRow];

      country.MethodologySpecs.map((method) => {
        method.CostingOptions.map((co,index) => {
          if(!(co.id in operations) || (operations[co.id] && !operations[co.id].length)) {
            operations[co.id] = newTable;
          }
          if(!(co.id in operationCost) || (operationCost[co.id] && !operationCost[co.id].length)) {
            operationCost[co.id] = newCost;
          }
        });
      });
    });

    dispatch(setCommercialHours(commercial));
    dispatch(setOperationsHours(operations));
    dispatch(setOperationsCost(operationCost));
    setIsLoading(false);
      });
    }  
  };
  const setoptionsData = () => {
    let options = {};
    for (let country = 0; country < profile.CountrySpecs.length; country++) {
      let method = [];
      for (
        let methodology = 0;
        methodology < profile.CountrySpecs[country].MethodologySpecs.length;
        methodology++
      ) {
        let cost = [];
        for (
          let costingOption = 0;
          costingOption <
          profile.CountrySpecs[country].MethodologySpecs[methodology]
            .CostingOptions.length;
          costingOption++
        ) {
          let label =
            profile.CountrySpecs[country].MethodologySpecs[methodology]
              .CostingOptions[costingOption].label;
          let ID =
            profile.CountrySpecs[country].MethodologySpecs[methodology]
              .CostingOptions[costingOption].id;
          let productName=profile.CountrySpecs[country].MethodologySpecs[methodology]
          .CostingOptions[costingOption].CostingOptionData.producttype
              if(!(productName.includes("EXPENSE RECHARGE")))
              {
                cost.push({ label: label, value: ID })
                
              }  
        }
        method.push(cost);
      }
      options[country] = method;
    }
    setoptions(options);
  };

  useEffect(() => {
    if (!isLoading && makeScroll) {
      window.scrollTo(0, scrollPosition);
      setMakeScroll(false);
    }
  }, [isLoading, makeScroll]);

  useEffect(() => {
    // getActivityDetails();
    setoptionsData();
    gettimecost();
    getCurrencyDetails()
  }, []);

  useEffect(()=>{
    let tempObj=costoptionsDetails
    profile.CountrySpecs.map((country)=>{
      country.MethodologySpecs.map((method)=>{
        method.CostingOptions.map((co,index)=>{
         tempObj[co.id]={countryName:country.CountryName,oppID:co.OpportunityLineItemID
         }
        })
      })
    })
    setCostoptionsDetails(tempObj)
    console.log(tempObj,"tempObj")

  },[])

  const getCurrencyDetails=()=>{
    if(timecostView==="operations")
    {
      let countryCode=profile.OPSCurrencySelection||project.CommissioningCountry
      let currencyArray=currencies.filter((item)=>item.Code===countryCode)
      setOPSCurrencyCode(countryCode)
      setOPSCurrencyUnit(currencyArray[0].CurrencyUnit)
      setOPSCurrencyConversion(currencyArray[0].ConversionRateToLocal)
      let finalCurrency = {
				value: `${currencyArray[0].Code}-${currencyArray[0].CurrencyUnit}`,
				label: `${currencyArray[0].Label} (1USD = ${currencyArray[0].ConversionRateToLocal}${currencyArray[0].CurrencyUnit})`,
			};
      setOPScurrencyDropdown(finalCurrency)
    }
  }

  const toggleSM = (tab) => {
    setActiveTabCO(0);
    const tableData = _.cloneDeep(opsCostData);
    let costTableData =
      tableData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions[0].id
      ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    
    let dataOption=OPSTeamDataOptions;
    let res= dataOption.filter((data)=>
      !countryMap.includes(data.label)
    );
    setOPSTeamDataOptionsTemp(res);
        if (activeTabSM !== tab) {
      setActiveTabSM(tab);
    }
    let opsSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions[0]
        .CostingOptionData.operationalpm;
    if (opsSupport === "Yes") {
      setLocalSupportOPS(true);
    } else {
      setLocalSupportOPS(false);
    }
    let modalSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions[0]
        .CostingOptionData?.operationpm;
    if (modalSupport === "Yes") {
      setLocalSupportModalOPS(true);
    } else {
      setLocalSupportModalOPS(false);
    }
  };

  const toggleTabPane = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const toggleCO = (tab) => {
    const tableData = _.cloneDeep(opsCostData);
    let costTableData =
      tableData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[tab].id
      ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    
    let dataOption=OPSTeamDataOptions;
    let res= dataOption.filter((data)=>
      !countryMap.includes(data.label)
    );
    setOPSTeamDataOptionsTemp(res);
    if (activeTabCO !== tab) {
      setActiveTabCO(tab);
    }
    let opsSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[tab].CostingOptionData.operationalpm;
    if (opsSupport === "Yes") {
      setLocalSupportOPS(true);
    } else {
      setLocalSupportOPS(false);
    }
    let modalSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[tab].CostingOptionData?.operationpm;
    if (modalSupport === "Yes") {
      setLocalSupportModalOPS(true);
    } else {
      setLocalSupportModalOPS(false);
    }
  };

  const infoModalToggle = () => {
    setInfoModal(false);
  };
  const handleOPSCurrencyChange =(e)=>{
    let countryCode=e.value.split("-")[0]
    let currencyArray=currencies.filter((item)=>item.Code===countryCode)
    setOPSCurrencyCode(countryCode)
    setOPSCurrencyUnit(currencyArray[0].CurrencyUnit)
    setOPSCurrencyConversion(currencyArray[0].ConversionRateToLocal)
    let finalCurrency = {
      value: `${currencyArray[0].Code}-${currencyArray[0].CurrencyUnit}`,
      label: `${currencyArray[0].Label} (1USD = ${currencyArray[0].ConversionRateToLocal}${currencyArray[0].CurrencyUnit})`,
    };
    setOPScurrencyDropdown(finalCurrency)
  }
  function populateSideBarLinks(variables) {
    if (variables) {
      dispatch(
        sideBarActions.addCostingOptionsLink(
          "#costing_options_card_" + activeTabFC + "_" + activeTabSM
        )
      );
      dispatch(
        sideBarActions.addSudOptionsLink(
          "#sud_options_card_" + activeTabFC + "_" + activeTabSM
        )
      );

      if (profile.CountrySpecs.length > 1)
        dispatch(
          sideBarActions.resOptionsLink(
            "#res_options_card_" + activeTabFC + "_" + activeTabSM
          )
        );
      else dispatch(sideBarActions.resOptionsLink(""));

      let links = [];
      variables.forEach((x, index) => {
        if (x.isNewSection)
          links.push({ label: x.sectionTitle, value: "#header_" + index });
      });
      dispatch(sideBarActions.addSidebarLinks(links));
    }
  }

  function toggle(tab) {
    setActiveTabSM(0);
    setActiveTabCO(0);
    const tableData = _.cloneDeep(opsCostData);
    let costTableData =
      tableData[
        profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0].id
      ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    
    let dataOption=OPSTeamDataOptions;
    let res= dataOption.filter((data)=>
      !countryMap.includes(data.label)
    );
    setOPSTeamDataOptionsTemp(res);
    if (activeTabFC !== tab) {
      setActiveTabFC(tab);
      let allMeth = profile.CountrySpecs[tab].MethodologySpecs.map(
        (m, mIndex) => ({
          methodology: m,
          index: mIndex,
        })
      );
      let reqMeth = allMeth.find((x) => !x.methodology.NotApplicable);
      if (!reqMeth) reqMeth = _.head(allMeth);
    }

    if (
      !(
        "CostingOptionData" in
        profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0]
      )
    ) {
      populateSideBarLinks(
        profile.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]
          ?.Variables
      );
    }
    let opsSupport =
      profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0]
        .CostingOptionData.operationalpm;
    if (opsSupport === "Yes") {
      setLocalSupportOPS(true);
    } else {
      setLocalSupportOPS(false);
    }
    let modalSupport =
      profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0]
        .CostingOptionData?.operationpm;
    if (modalSupport === "Yes") {
      setLocalSupportModalOPS(true);
    } else {
      setLocalSupportModalOPS(false);
    }
  }

  const updateModal = () => {
    settimecostViewModal(!timecostViewModal);
  };

  const getWBSnumber = () => {
    let oppID = profile?.CountrySpecs ? 
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[activeTabCO].OpportunityLineItemID : "";
    let ProductTypeData = profile?.ProductTypeData !== undefined ? profile?.ProductTypeData : [];
    let filterarray = Array.isArray(ProductTypeData) ? ProductTypeData?.filter(
      (item) => item.OpportunityLineItemID == oppID
    ) : [];
    if (filterarray) {
      return filterarray[0]?.WBSNumber;
    }
    return null;
  };

  const clearOPSTable = (country) => {
    setShowModal(true);
    setSelectedCountryForClear(country);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOk = () => {
    setIsClear(selectedCountryForClear);
    setShowModal(false);
  };

  const deleteOPSTable = (costOptionID, index) => {
    console.log("......", costOptionID, index);
    setSelectedCostOptionID(costOptionID);
    setSelectedIndex(index);
    setShowDeleteModal(true);
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleOKModal = () => {
    let csdata = _.cloneDeep(opsCostData);
    let costdata = _.cloneDeep(operationsCostData);

    const data = csdata[selectedCostOptionID]
      .map((c) => c)
      .filter((country, i) => i != selectedIndex);
    const cdata = costdata[selectedCostOptionID]
      .map((c) => c)
      .filter((country, i) => i != selectedIndex);
    const deletedData = csdata[selectedCostOptionID]
      .map((c) => c)
      .filter((country, i) => i === selectedIndex);
    const deletedCData = costdata[selectedCostOptionID]
      .map((c) => c)
      .filter((country, i) => i === selectedIndex);
      let teamData = OPSTeamDataOptions?.map((m)=>m.label);

    //opsdata  
    let clonedDeletedData = _.cloneDeep(deletedTimeCostData);
    if(teamData.includes(Object.keys(deletedData[0])[0])){
    if (clonedDeletedData && Object.keys(clonedDeletedData).length) {
      if (clonedDeletedData?.[selectedCostOptionID] === undefined) {
        let newObj = { [selectedCostOptionID]: deletedData };
        Object.assign(clonedDeletedData, newObj);
      }else{
        let newArray = clonedDeletedData[selectedCostOptionID];
        newArray.push(deletedData[0]);
        clonedDeletedData[selectedCostOptionID] = newArray;
      }
    }
    else {
      clonedDeletedData = { [selectedCostOptionID]: deletedData };
    }
  }
    //cost data
    let clonedCostData = _.cloneDeep(deletedCostData);
    if(teamData.includes(Object.keys(deletedCData[0])[0])){
    if (clonedCostData && Object.keys(clonedCostData).length) {
      if (clonedCostData?.[selectedCostOptionID] === undefined) {
        let newObj = { [selectedCostOptionID]: deletedCData };
        Object.assign(clonedCostData, newObj);
      }else{
        let newArray = clonedCostData[selectedCostOptionID];
        newArray.push(deletedCData[0]);
        clonedCostData[selectedCostOptionID] = newArray;
      }
    }
    else {
      clonedCostData = { [selectedCostOptionID]: deletedCData };
    }
  }
    if (clonedDeletedData && Object.keys(clonedDeletedData)?.length) {
      setDeletedTimeCostData(clonedDeletedData);
    }
    if (clonedCostData && Object.keys(clonedCostData)?.length) {
      setDeletedCostData(clonedCostData);
    }
    csdata[selectedCostOptionID] = data;
    costdata[selectedCostOptionID] = cdata;
     //excluding the already selected country from the list
     let costTableData = csdata[selectedCostOptionID];
     let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
     
     let dataOption=OPSTeamDataOptions;
     let res= dataOption.filter((data)=>
       !countryMap.includes(data.label)
     );
    setOPSTeamDataOptionsTemp(res);
    dispatch(setOperationsHours(csdata));
    dispatch(setOperationsCost(costdata));
    setShowDeleteModal(false);
  };

  const handleSelectChange = (e, index) => {
    const tableData = _.cloneDeep(opsCostData);
    const tableCostData = _.cloneDeep(operationsCostData);

    let coData =
      tableData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
          .CostingOptions[activeTabCO].id
      ];
      let costData = tableCostData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
          .CostingOptions[activeTabCO].id
      ];
    let obj = {};
    let costObj = {};
    obj[e.label] = Object.values(coData[index])[0];
    coData[index] = obj;
    costObj[e.label] = Object.values(costData[index])[0];
    costData[index] = costObj;

    //excluding the already selected country from the list
    let costTableData =
    tableData[
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[activeTabCO].id
    ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    let dataOption=OPSTeamDataOptions;
    let res= dataOption.filter((data)=>
    !countryMap.includes(data.label)
    );

    setOPSTeamDataOptionsTemp(res);
    dispatch(setOperationsHours(tableData));
    dispatch(setOperationsCost(tableCostData));
    console.log(tableData, "tableData");
  };

  const copyMethodologiesData = () => {
    var convertedMethodologies = [];

    if (profile.CountrySpecs[activeTabFC].MethodologySpecs[0].label) {
      convertedMethodologies = profile.CountrySpecs[
        activeTabFC
      ].MethodologySpecs.map((fc) => ({ label: fc.label, value: fc.label }));
    } else {
      convertedMethodologies = profile.CountrySpecs[
        activeTabFC
      ].MethodologySpecs.map((fc) => ({ label: fc.Label, value: fc.Label }));
    }
    setSelectedMethodologyData1(convertedMethodologies);
    setModalMethodology(true);
  };

  const generateRandomKey = () => {
    const randomKey = Math.random().toString(36).substring(2, 10);
    return randomKey;
  };

  const addNewCostingRow = () => {
    const costingOptionID =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        ?.CostingOptions[activeTabCO]?.id;
    const tableHours = [];
    for (var i = 0; i < activitiesForOPS.length; i++) {
      tableHours.push(Array(OPSband.length).fill(""));
    }
    const newRow = {
      [generateRandomKey()]: tableHours,
    };

    const tableCosts = [];
    for (var i = 0; i < OPSband.length; i++) {
      tableCosts.push(Array(OPSband.length).fill(""));
    }
    const newCostRow = {
      [generateRandomKey()]: tableCosts,
    };

    const tableData = _.cloneDeep(opsCostData);
    const tableCostData = _.cloneDeep(operationsCostData);

    //for hours
    if (tableData && Object.keys(tableData).length && tableData[costingOptionID]) {
      tableData[costingOptionID].push(newRow);
    } else {
      const optionIdAssignment = {
        [costingOptionID]: [newRow],
      };
      Object.assign(tableData, optionIdAssignment);
    }

    //for cost
    if (tableCostData && Object.keys(tableCostData).length && tableCostData[costingOptionID]) {
      tableCostData[costingOptionID].push(newCostRow);
    } else {
      const optionIdAssignment = {
        [costingOptionID]: [newCostRow],
      };
      Object.assign(tableCostData, optionIdAssignment);
    }
    dispatch(setOperationsHours(tableData));
    dispatch(setOperationsCost(tableCostData))
    toastr.success("New Costing Market Added");
  };

  const copyCountriesDataOps = () => {
    var convertedCountries = [];

    if (profile.CountrySpecs[0].label) {
      convertedCountries = profile.CountrySpecs.map((fc) => ({
        label: fc.label,
        value: fc.label,
      }));
    } else {
      convertedCountries = profile.CountrySpecs.map((fc) => ({
        label: fc.CountryName,
        value: fc.CountryName,
      }));
    }

    setSelectedCountryData(convertedCountries);
    copyMethodologiesData();

    setOpsModal(true);
  };

  const getCSOPSDetails=(coID)=>{
    let result=[];
    profile.CountrySpecs.map((country)=>{
      country.MethodologySpecs.map((method)=>{
        method.CostingOptions.map((co,index)=>{
          if(coID==co.id)
          {
            result.push(co.CostingOptionData.CommericalLocalsu,co.CostingOptionData.operationalpm,co.CostingOptionData.Commericalsupport,co.CostingOptionData.operationpm,method.Code,country.CountryCode)
          }
        })
      })
    })
    return result
  };

  const copyHoursWBSlevel=async()=>{
    const response =await axios.get(`/timecost/${profile.id}`)
    let costingOptionDetailsObj=costoptionsDetails
    let hoursPayload={
      records: [],
    };
    let totalCostPayload = {
      records: [],
    };
    for (const costingOptionID in opsCostData) {
      const [csSupport,opsSupport,csModal,opsModal]=getCSOPSDetails(costingOptionID)
      
        if(opsSupport==="No")
        {
          continue
        }
        if(opsModal==="No")
        {
          continue
        }
      
     
      let oppID=costingOptionDetailsObj[costingOptionID].oppID
      let countryName=costingOptionDetailsObj[costingOptionID].countryName
      let costArray=operationsCostData[costingOptionID]
      if(levelInput[oppID]==="wbslevel")
      {
        let tableArray=opsCostData[costingOptionID]
        let OPMSchema=[]
        tableArray.map((country,index)=>{
          let countryName;
          let hours;
          for (const [key, value] of Object.entries(country)) {
            countryName=key
            hours=value
          }
          let opsCostArray=costArray[index]
          let bandCostArray=opsCostArray[countryName]

          const isCountry = OPSTeamDataOptions?.some((obj) => obj.label === countryName);

          if(bandCostArray === undefined && !isCountry) {
            return null;
          }
  
          let pmData = {};
          OPSband.map((bandValue, index) => {
            let bandObj=bandCostArray[index]
            pmData[bandValue.band_name] = bandObj[bandValue.band_name];
          });
        let pmTable=hours
        pmData["dropdownCountry"]={label:countryName}
        for(let activity=0;activity<activitiesForOPS.length;activity++)
        {
          let temparr=[]
          let activityName=activitiesForOPS[activity].activity_name
        
          for(let bandCS=0;bandCS<OPSband.length;bandCS++)
          {
            let bandname=OPSband[bandCS].band_name
            let tempObj={}
            tempObj[bandname]=pmTable[activity][bandCS]
            temparr.push(tempObj)
          }
          pmData[activityName]=temparr
        }
        let finalObj={
          Rateselection: pmData,
        }
        response.data.datas.map((res)=>{
          if(res.OptionId===costingOptionID)
          {
            if(res.OPMSchema.length>index)
            {
              let schema=res.OPMSchema[index]
              if("CostId" in schema)
              {
                finalObj["CostId"]=schema.CostId
              }
            }
          }
        });
  
        OPMSchema.push(finalObj)
        })
          profile.CountrySpecs.map((country)=>{
          country.MethodologySpecs.map((method)=>{
            method.CostingOptions.map((co,index)=>{
              if(co.OpportunityLineItemID==oppID && country.CountryName==countryName && co.id!=costingOptionID&&co.CostingOptionData.CommericalLocalsu==="Yes")
              {
                let copyOPSschema=_.cloneDeep(OPMSchema)
                let costingOptionPayload={
                  ProfileId: profile.id,
                }
                costingOptionPayload["CountryId"]=country.CountryCode
                costingOptionPayload["MethodologyCode"]=method.Code
                costingOptionPayload["OptionId"]=co.id

                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    copyOPSschema.map((schemaItem,index)=>{
                      if(res.OPMSchema.length>index)
                      {
                        let schema=res.OPMSchema[index]
                        if("CostId" in schema)
                        {
                          schemaItem["CostId"]=schema.CostId
                        }
                      }
                    })
                    
                  }
                });
                costingOptionPayload["OPMSchema"]=copyOPSschema


                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    costingOptionPayload["id"]=res.id
                  }
                })
                hoursPayload.records.push(costingOptionPayload)
              }
              })
            })
          })

          const totalOperationsCost = operationsCostData[costingOptionID]?.map((csCost) => {
            return Object.values(csCost)[0];
          });
    
          const finalOpsCost = totalOperationsCost.reduce((outerAcc, innerArray) => {
            const innerSum = innerArray.reduce((innerArray, obj) => {
              const totalCostValue = parseFloat(obj.totalCost);
              if(!isNaN(totalCostValue)) {
                innerArray += totalCostValue;
              }
              return innerArray;
            }, 0);
            return outerAcc + innerSum;
          }, 0);

          
          profile.CountrySpecs.map((country)=>{
            country.MethodologySpecs.map((method)=>{
              method.CostingOptions.map((co,index)=>{
                if(co.OpportunityLineItemID==oppID && country.CountryName==countryName && co.id!=costingOptionID&&co.CostingOptionData.CommericalLocalsu==="Yes")
                {
                  let payload;
                  if(co.CostingOptionData.Booster==="No")
                  {
                    payload = {
                      id: co.id,
                      TotalOPSTimeCost: finalOpsCost
                    };
                    totalCostPayload.records.push(payload);
                  }
                  else
                  {
                    payload = {
                      id: co.id,
                      TotalOPSTimeCost: finalOpsCost*0.05
                    };
                    totalCostPayload.records.push(payload);
                  }
                }
              })
            })
          })

                      
      }
    }

    let resultpayload=[hoursPayload,totalCostPayload]
    console.log("copyhoursPayload",hoursPayload);
    return resultpayload
  }
    //*********************************************************** */
    const deleteHoursWBSlevel=async(response)=>{
      
    let costingOptionDetailsObj=costoptionsDetails
    let hoursPayload=[];
    for (const costingOptionID in deletedTimeCostData) {
      const [csSupport,opsSupport,csModal,opsModal]=getCSOPSDetails(costingOptionID)
      
        if(opsSupport==="No")
        {
          continue
        }
        if(opsModal==="No")
        {
          continue
        }
      
     
      let oppID=costingOptionDetailsObj[costingOptionID].oppID
      let countryName=costingOptionDetailsObj[costingOptionID].countryName
      let costArray=deletedCostData[costingOptionID]
      if(levelInput[oppID]==="wbslevel")
      {
        let tableArray=deletedTimeCostData[costingOptionID]
        let OPMSchema=[]
        tableArray.map((country,index)=>{
          let countryName;
          let hours;
          for (const [key, value] of Object.entries(country)) {
            countryName=key
            hours=value
          }
          let opsCostArray=costArray[index]
          let bandCostArray=opsCostArray[countryName]

          const isCountry = OPSTeamDataOptions?.some((obj) => obj.label === countryName);

          if(bandCostArray === undefined && !isCountry) {
            return null;
          }
  
          let pmData = {};
          OPSband.map((bandValue, index) => {
            let bandObj=bandCostArray[index]
            pmData[bandValue.band_name] = bandObj[bandValue.band_name];
          });
        let pmTable=hours
        pmData["dropdownCountry"]={label:countryName}
        for(let activity=0;activity<activitiesForOPS.length;activity++)
        {
          let temparr=[]
          let activityName=activitiesForOPS[activity].activity_name
        
          for(let bandCS=0;bandCS<OPSband.length;bandCS++)
          {
            let bandname=OPSband[bandCS].band_name
            let tempObj={}
            tempObj[bandname]=pmTable[activity][bandCS]
            temparr.push(tempObj)
          }
          pmData[activityName]=temparr
        }
        let finalObj={
          Rateselection: pmData,
          Isdeleted: true,
        }
        response.data.datas.map((res)=>{
          if(res.OptionId===costingOptionID)
          {
            if(res.OPMSchema.length>index)
            {
              let schema=res.OPMSchema[index]
              if("CostId" in schema)
              {
                finalObj["CostId"]=schema.CostId
              }
            }
          }
        });
  
        OPMSchema.push(finalObj)
        })
          profile.CountrySpecs.map((country)=>{
          country.MethodologySpecs.map((method)=>{
            method.CostingOptions.map((co,index)=>{
              if(co.OpportunityLineItemID==oppID && country.CountryName==countryName && co.id!=costingOptionID&&co.CostingOptionData.CommericalLocalsu==="Yes")
              {
                let copyOPSschema=_.cloneDeep(OPMSchema)
                let costingOptionPayload={
                  ProfileId: profile.id,
                }
                costingOptionPayload["CountryId"]=country.CountryCode
                costingOptionPayload["MethodologyCode"]=method.Code
                costingOptionPayload["OptionId"]=co.id

                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    copyOPSschema.map((schemaItem,index)=>{
                      if(res.OPMSchema.length>index)
                      {
                        res.OPMSchema?.map((r)=>{
                          if (r.Rateselection.dropdownCountry.label == schemaItem.Rateselection.dropdownCountry.label) {
                            if ("CostId" in r) {
                              schemaItem["CostId"] = r.CostId
                            }
                          }
                         })
                      }
                    })
                    
                  }
                });
                costingOptionPayload["OPMSchema"]=copyOPSschema


                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    costingOptionPayload["id"]=res.id
                  }
                })
                hoursPayload.push(costingOptionPayload)
              }
              })
            })
          })                    
      }
    }
    return hoursPayload;
    }
    //************************************************************ */
  const deleteTimeCostDatas = async (response) => {
    console.log("?????", deletedTimeCostData);
    let records=await deleteHoursWBSlevel(response);
    for(let costingOptionID in deletedTimeCostData)
    {
      const [csSupport,opsSupport,csModal,opsModal,methodCode,countryCode]=getCSOPSDetails(costingOptionID)
      if(opsSupport==="No")
      {
        continue
      }
      if(opsModal==="No")
      {
        continue
      }
      let tableArray=deletedTimeCostData[costingOptionID]
      let costArray=deletedCostData[costingOptionID]
      let costingOptionPayload={
        ProfileId: profile.id,
  
        CountryId: countryCode,

        MethodologyCode:methodCode,

        OptionId: costingOptionID,
        OPMSchema:[]
      }

      tableArray.map((country,index)=>{
        let countryName;
        let hours;
        for (const [key, value] of Object.entries(country)) {
          countryName=key
          hours=value
        }
        let opsCostArray=costArray[index]
        let bandCostArray=opsCostArray[countryName]

        let pmData = {};
        OPSband.map((bandValue, index) => {
          let bandObj=bandCostArray[index]
          pmData[bandValue.band_name] = bandObj[bandValue.band_name];
        });
      let pmTable=hours
      pmData["dropdownCountry"]={label:countryName}
      for(let activity=0;activity<activitiesForOPS.length;activity++)
      {
        let temparr=[]
        let activityName=activitiesForOPS[activity].activity_name
      
        for(let bandCS=0;bandCS<OPSband.length;bandCS++)
        {
          let bandname=OPSband[bandCS].band_name
          let tempObj={}
          tempObj[bandname]=pmTable[activity][bandCS]
          temparr.push(tempObj)
        }
        pmData[activityName]=temparr
      }
      let finalObj={
        Rateselection: pmData,
        Isdeleted: true,
      }
      response.data.datas.map((res)=>{
        if(res.OptionId==costingOptionID)
        {
          res.OPMSchema.map((r)=>{
            if (r.Rateselection.dropdownCountry.label === countryName) {
              if ("CostId" in r) {
                finalObj["CostId"] = r.CostId
              }
            } 
          })
        }
      });
      costingOptionPayload.OPMSchema.push(finalObj)
      })


      response.data.datas.map((res)=>{
        if(res.OptionId==costingOptionID)
        {
          costingOptionPayload["id"]=res.id
        }
      })

      records.push(costingOptionPayload)
    }
    
    await axios.put(`/timecost/Trash/${profile.id}`, records).then(
      res => {
        setDeletedTimeCostData({});
        setDeletedCostData({});
      }
    ).catch(err => {
      console.log(err.message);
    })
  }
  const saveTableData=async()=>{
    setIsLoading(true);
    setAlert(false);
    let payload=await copyHoursWBSlevel()
    console.log("?????",deletedTimeCostData);
    const response = await axios.get(`/timecost/${profile.id}`);
    let hoursPayload=payload[0]

    let totalCostPayload = payload[1]


    if(deletedTimeCostData && Object.keys(deletedTimeCostData)?.length && response?.data?.datas?.length >0){
      deleteTimeCostDatas(response);  
    }
    
    for(let costingOptionID in opsCostData)
    {
      const [csSupport,opsSupport,csModal,opsModal,methodCode,countryCode]=getCSOPSDetails(costingOptionID)
      if(opsSupport==="No")
      {
        continue
      }
      if(opsModal==="No")
      {
        continue
      }
      let tableArray=opsCostData[costingOptionID]
      let costArray=operationsCostData[costingOptionID]
      let costingOptionPayload={
        ProfileId: profile.id,
  
        CountryId: countryCode,

        MethodologyCode:methodCode,

        OptionId: costingOptionID,
        OPMSchema:[]
      }

      tableArray.map((country,index)=>{
        let countryName;
        let hours;
        for (const [key, value] of Object.entries(country)) {
          countryName=key
          hours=value
        }
        let opsCostArray=costArray[index]
        let bandCostArray=opsCostArray[countryName]

        const isCountry = OPSTeamDataOptions?.some((obj) => obj.label === countryName);

        if(bandCostArray === undefined && !isCountry) {
          for(let country=0;country<profile.CountrySpecs.length;country++){
            for(let methodology=0;methodology<profile.CountrySpecs[country].MethodologySpecs.length;methodology++){
              for(let costingOption=0;costingOption<profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions.length;costingOption++){
                if(profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions[costingOption].id == costingOptionID) {
                  toastr.warning(`Please select a country for ${profile.CountrySpecs[country].CountryName} > ${profile.CountrySpecs[country].MethodologySpecs[methodology].label} > ${profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions[costingOption].Name} > Team ${index+1}`, '', {timeOut: 5000});
                }
              }
            }
          }
          return null;
        }

        let pmData = {};
        OPSband.map((bandValue, index) => {
          let bandObj=bandCostArray[index]
          pmData[bandValue.band_name] = bandObj[bandValue.band_name];
        });
      let pmTable=hours
      pmData["dropdownCountry"]={label:countryName}
      for(let activity=0;activity<activitiesForOPS.length;activity++)
      {
        let temparr=[]
        let activityName=activitiesForOPS[activity].activity_name
      
        for(let bandCS=0;bandCS<OPSband.length;bandCS++)
        {
          let bandname=OPSband[bandCS].band_name
          let tempObj={}
          tempObj[bandname]=pmTable[activity][bandCS]
          temparr.push(tempObj)
        }
        pmData[activityName]=temparr
      }
      let finalObj={
        Rateselection: pmData,
      }
      response.data.datas.map((res)=>{
        if(res.OptionId==costingOptionID)
        {
          if(res.OPMSchema.length>index)
          {
            let schema=res.OPMSchema[index]
            if("CostId" in schema)
            {
              finalObj["CostId"]=schema.CostId
            }
          }
        }
      });

      costingOptionPayload.OPMSchema.push(finalObj)
      })

      

      response.data.datas.map((res) => {
        if(res.OptionId==costingOptionID)
        {
          costingOptionPayload["id"]=res.id
        }
      });

      const totalOperationsCost = operationsCostData[costingOptionID]?.map((csCost) => {
        return Object.values(csCost)[0];
      });

      const finalOpsCost = totalOperationsCost.reduce((outerAcc, innerArray) => {
        const innerSum = innerArray.reduce((innerArray, obj) => {
          const totalCostValue = parseFloat(obj.totalCost);
          if(!isNaN(totalCostValue)) {
            innerArray += totalCostValue;
          }
          return innerArray;
        }, 0);
        return outerAcc + innerSum;
      }, 0);


      let payload = {
        id: costingOptionID,
        TotalOPSTimeCost: finalOpsCost,
        OPSCurrencySelection:OPSCurrencyCode
      };

      hoursPayload.records.push(costingOptionPayload);
      totalCostPayload.records.push(payload);
    }
    console.log("hoursPayload",hoursPayload);
    console.log(totalCostPayload,"totalcostpayload");

    if(hoursPayload?.records[0]?.OPMSchema.length > 0) {
      // if(totalCostPayload.records.length > 0) {
      //   dispatch(saveTotalCost(totalCostPayload, profile.id));
      // }
      // if(hoursPayload.records.length > 0)
      // {
      //   dispatch(saveHourspayload(hoursPayload, profile.id));
      // }
      let tempProfile=_.cloneDeep(profile)
      tempProfile["OPSCurrencySelection"]=OPSCurrencyCode
      dispatch(currentCostingActions.updateProfile(tempProfile));
      dispatch(saveTimecost(hoursPayload,totalCostPayload,profile.id))

      setIsLoading(false);
    } else {
      toastr.warning("Data not saved");
      setIsLoading(false);
    }
  };

  const getTotalBandHours = (bandIndex, data) => {
    let totalHours = 0;
    let hours = Object.values(data)[0];
    for (let i = 0; i < activitiesForOPS.length; i++) {
      if (hours[i][bandIndex] !== "") totalHours += hours[i][bandIndex];
    }
    return totalHours;
  };

  const getTotalCost = (id, i, data) => {
    let bandRates = [];    
    let totalHours = getTotalBandHours(i, data);
    if (isNaN(totalHours)) {
      return 0;
    }
    const country = OPSTeamDataOptions?.filter((opsTeam =>opsTeam.label === Object.keys(data)[0] ));
    if(country && country?.length > 0){
      bandRates = defaultRates?.filter((rate) => rate.country_id === country[0]?.value);
    }
    let rate = bandRates?.filter((r)=>r.band_id === id);
    let finalRate = 0;
    if(rate && rate?.length > 0){
    finalRate = Number(
    Math.round(totalHours * rate[0].rate * 100) / 100
    ).toFixed(2);
    }
    return finalRate; 
  };

  const totalCost = (data) => {    
    let totalCost = 0;
    OPSband.forEach((b,index)=>{
      let tempCsValue = getTotalCost(b.band_id, index, data);
      if (!isNaN(tempCsValue)) {
        totalCost += Number(tempCsValue);
      }
    })
    return (Math.round(totalCost * 100) / 100).toFixed(2);
  };

  const totalOpsCostArray = profile?.CountrySpecs !== undefined ? opsCostData[
    profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
      ?.CostingOptions[activeTabCO]?.id
  ]?.map(data => totalCost(data)) : [];
  
  const totalOpsCost = totalOpsCostArray?.length > 0 ? totalOpsCostArray?.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0) : 0;
  
  const getProductName=()=>{
    return profile?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions[activeTabCO]?.CostingOptionData.producttype
  }

  return (
    <Wrapper>
      <Sidebar />
      <Main>
        <ProjectNavbar />
        {isLoading || !pageloaded? (
          <div id="pageCoverSpin"></div>
        ) : (
          <div className={"tab mt-12"}>
            <div
              style={{
                border: "1px solid grey",
                width: "470px",
                fontSize: "12px",
                float: "right",
                marginTop: "5px",
              }}
            >
              <p style={{ marginBottom: "0px" }}>
                Please refer to the Time Cost Planning Dashboard link below as a
                reference on how much hours to be budgeted.
              </p>
              <p>
                <a
                  href="https://bit.ly/NIQ-Time-Cost-Dashboard"
                  style={{ color: "blue" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://bit.ly/NIQ-Time-Cost-Dashboard
                </a>
              </p>
              <p style={{ marginBottom: "0px" }}>
                Access to be granted by Capacity Management Team (CMT).Please
                send an email request to
                m2m_capacity_management@nielsenenterprise.onmicrosoft.com. And
                indicate the user's email address (also include other team
                members if any) and market/s that want access to.
              </p>
            </div>
            <Row style={{ position: "sticky", zIndex: "1", top: "70px" }}>
              <Col
                md={12}
                className={"countryTabs tab-vertical"}
                style={{ height: "180px" }}
              >
                <div className={"tab mt-4"}>
                  <Nav tabs style={{ width: "100px", display: "inline-block" }}>
                    {profile.CountrySpecs?.map((fc, indxFC) => (
                      <NavItem key={fc.value}>
                        <NavLink
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          className={classnames({
                            active: activeTabFC === indxFC,
                          })}
                          onClick={() => {
                            toggle(indxFC);
                          }}
                        >
                          <b>{fc.label ? fc.label : fc.CountryName}</b>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeTabFC}>
                    {profile.CountrySpecs?.map(
                      (fc, indxFC) =>
                        activeTabFC === indxFC && (
                          <>
                            <TabPane tabId={indxFC} key={fc.value}>
                              <Row>
                                <Col md={12} className={"methodologyTabs"}>
                                  <Nav tabs>
                                    {fc.MethodologySpecs?.map((sm, indxSM) => (
                                      <>
                                        <NavItem key={sm.value}>
                                          <NavLink
                                            className={classnames({
                                              active: activeTabSM === indxSM,
                                            })}
                                            onClick={() => {
                                              toggleSM(indxSM);
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <div className="hoverEffect">
                                                {sm.NotApplicable}
                                                <b>
                                                  {sm.label
                                                    ? sm.label
                                                    : sm.Label}
                                                </b>
                                              </div>
                                            </div>
                                          </NavLink>
                                        </NavItem>
                                      </>
                                    ))}
                                  </Nav>
                                </Col>
                              </Row>
                            </TabPane>
                          </>
                        )
                    )}
                  </TabContent>
                  <TabContent activeTab={activeTabFC}>
                    {profile.CountrySpecs?.map(
                      (fc, indxFC) =>
                        activeTabFC === indxFC && (
                          <>
                            <TabPane tabId={indxFC} key={fc.value}>
                              <Row>
                                <Col md={12} className={"methodologyTabs"}>
                                  <Nav tabs style={{ marginLeft: "95px" }}>
                                    {fc.MethodologySpecs?.[
                                      activeTabSM
                                    ].CostingOptions.map(
                                      (costingOption, indxSM) => {
                                        return (
                                          <NavItem key={indxSM.value}>
                                            <NavLink
                                              className={classnames({
                                                active: activeTabCO === indxSM,
                                              })}
                                              disabled={
                                                timecostView === "commercial"
                                                  ? costingOption
                                                      .CostingOptionData
                                                      .Commericalsupport ===
                                                    "No"
                                                  : costingOption
                                                      .CostingOptionData
                                                      .operationpm === "No"
                                              }
                                              onClick={() => {
                                                toggleCO(indxSM);
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div className="hoverEffect">
                                                  <b>
                                                    {costingOption.label
                                                      ? costingOption.label
                                                      : costingOption.Name}
                                                  </b>
                                                </div>
                                              </div>
                                            </NavLink>
                                          </NavItem>
                                        );
                                      }
                                    )}
                                  </Nav>
                                </Col>
                              </Row>
                            </TabPane>
                          </>
                        )
                    )}
                  </TabContent>
                </div>
              </Col>
            </Row>
            <Row className="mt-2 mb-2">
              <Col md={6}>
                <h3 style = {{marginLeft: "160px"}}>Product: {profile?.CountrySpecs?profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[activeTabCO]
            .CostingOptionData.producttype:null}</h3>
              <h3 style = {{marginLeft: "160px"}}>WBS Number: {getWBSnumber()}</h3>
              </Col>
              <Col md={4}>
                  <h4>Currency Selection</h4>
                    <Select
													// isDisabled={profile.ProfileStatus > 1}
													placeholder="Select a Currency..."
													options={currencies?.map((c) => {
														return {
															value: `${c.Code}-${c.CurrencyUnit}`,
															label: `${c.Label} (1USD = ${c.ConversionRateToLocal}${c.CurrencyUnit})`,
														};
													})}
                          value={OPScurrencyDropdown?OPScurrencyDropdown:null}
                          onChange={handleOPSCurrencyChange}
										/>
              </Col>
            </Row>
            <Container
              style={{
                width: "50%",
                marginLeft: "160px",
                backgroundColor: "white",
              }}
            >
              <Table responsive hover striped bordered size="sm">
                <tbody>
                  <tr style={{ fontWeight: "bold", fontSize: "15px" }}>
                    <td>Summary</td>
                  </tr>
                  {
                    <tr style={{ fontWeight: "bold", fontSize: "15px" }}>
                      <td>Total Operations Time Cost ({OPSCurrencyUnit})</td>
                      <td>{(totalOpsCost*OPSCurrencyConversion)?.toFixed(2)}</td>
                    </tr>
                  }
                </tbody>
              </Table>
            </Container>
            <CostingMarketTable
              activities={activitiesForOPS}
              band={OPSband}
              role={role}
              activeTabSM={activeTabSM}
              activeTabCO={activeTabCO}
              activeTabFC={activeTabFC}
              teamDataOptions={OPSTeamDataOptions}
              tableData={_.cloneDeep(opsCostData)}
              defaultRates={defaultRates}
              costingOptionID={ profile.CountrySpecs !== undefined ?
                profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                  ?.CostingOptions[activeTabCO]?.id : ""                
              }
              CurrencyConversion={OPSCurrencyConversion}
            />
            {opsCostData && profile.CountrySpecs &&
              opsCostData[
                profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                  ?.CostingOptions[activeTabCO]?.id
              ]?.map((opsData, index) => (
                <>
                  <hr />
                  <Container style={{ marginLeft: "140px" }}>
                    <Row>
                      <Col
                        md={4}
                        style={{
                          marginLeft: "20px",
                          maxWidth: "287px !important",
                        }}
                      >
                        <h4>Select Operation Project Team {`${index + 1}`}</h4>
                      </Col>
                      <Col md={5} style={{ marginLeft: "-50px" }}>
                        <Select
                          options={OPSTeamDataOptionsTemp}
                          value={OPSTeamDataOptions.filter(function (option) {
                            return option.label === Object.keys(opsData)[0];
                          })}
                          onChange={(e) => {
                            handleSelectChange(e, index);
                          }}
                          isDisabled={
                            !localSupportModalOPS ||
                            !localSupportOPS ||
                            project.PriceChange == 3 ||
                            profile.ProfileStatus === "6" ||
                            profile.ProfileStatus === "99" ||
                            project.ProjectStatus == 3||
                            getProductName().includes("EXPENSE RECHARGE")
                          }
                        />
                      </Col>
                      <Col>
                        {index >= 2 && (
                          <Button
                            style={{
                              width: "80px",
                            }}
                            color="primary"
                            onClick={() => {
                              deleteOPSTable(
                                profile.CountrySpecs[activeTabFC]
                                  .MethodologySpecs[activeTabSM]
                                  ?.CostingOptions[activeTabCO]?.id,
                                index
                              );
                            }}
                            //  disabled={project.PriceChange == 3 || profile.ProfileStatus === "6" || profile.ProfileStatus === "99" || project.ProjectStatus == 3}
                          >
                            Delete
                          </Button>
                        )}
                      </Col>
                      <Col md={2}>
                        <Button
                          style={{
                            width: "80px",
                            marginLeft: "-43px",
                          }}
                          //  onClick={clearOPSTable}
                          color="primary"
                          onClick={() => {
                            clearOPSTable(Object.keys(opsData)[0]);
                          }}
                          //  disabled={project.PriceChange == 3 || profile.ProfileStatus === "6" || profile.ProfileStatus === "99" || project.ProjectStatus == 3}
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                  <TimeCostTable
                    reduxData = {_.cloneDeep(opsCostData)}
                    costingOptionID={
                      profile.CountrySpecs[activeTabFC].MethodologySpecs[
                        activeTabSM
                      ]?.CostingOptions[activeTabCO]?.id
                    }
                    activities={activitiesForOPS}
                    band={OPSband}
                    role="OPS"
                    inputCountry={Object.keys(opsData)[0]}
                    rowIndex={index}
                    tableData={Object.values(opsData)[0]}
                    localSupport={localSupportOPS}
                    localModalSupport={localSupportModalOPS}
                    defaultRates={defaultRates}
                    countryOptions={OPSTeamDataOptions}
                    isClearTable={isClear}
                    setIsClear={setIsClear}
                    costData = {_.cloneDeep(operationsCostData)}
                    setAlert={setAlert}
                    CurrencyUnit={OPSCurrencyUnit}
                    CurrencyConversion={OPSCurrencyConversion}
                    productName={getProductName()}
                  />
                </>
              ))}
            <Prompt
              when={alert}
              message='Clicking on "OK" will navigate to next page without saving data '
            />
            <InfoModal
              infoModal={infoModal}
              infoModalToggle={infoModalToggle}
              activityMapping={activityMapping}
              currentActiveTab={currentActiveTab}
              toggleTabPane={toggleTabPane}
            />
            <Row>
              <Col className="justify-content-end">
              <Button style={{top:"75%"}}
                  className="validateandsave costing-profile-btn"
                  color="primary"
                  onClick={saveTableData}
                  disabled={project.PriceChange == 3 || profile.ProfileStatus === "6" || profile.ProfileStatus === "99" || project.ProjectStatus == 3}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faSave}
                    fixedWidth
                  />
                  Save
                </Button>
              </Col>
            </Row>

            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "45%" }}
                  className="copy-to-all-market costing-profile-btn"
                  color="primary"
                  onClick={() => {
                    setInfoModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faInfoCircle}
                    fixedWidth
                  />
                  Activities info
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "65%" }}
                  className="savedraft costing-profile-btn"
                  color="primary"
                  onClick={() => {
                    copyCountriesDataOps();
                  }}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faCopy}
                    fixedWidth
                  />
                  Copy Operations tables
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "35%" }}
                  className="copy-to-all-market costing-profile-btn"
                  color="primary"
                  onClick={() => {
                    settimecostViewModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faPencilAlt}
                    fixedWidth
                  />
                  Edit Level of Input
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "55%" }}
                  className="copy-to-all-market costing-profile-btn"
                  color="primary"
                  onClick={addNewCostingRow}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faPlusCircle}
                    fixedWidth
                  />
                  Add Costing Market
                </Button>
              </Col>
            </Row>
            <TimeCostModal isOpen={timecostViewModal} isClose={updateModal} />
          </div>
        )}
        <Modal
          isOpen={showModal}
          toggle={handleClose}
          style={{ height: "30px" }}
        >
          <ModalHeader toggle={handleClose}>Clear Table Data</ModalHeader>
          <ModalBody>Are you sure to clear table data?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleOk()}>
              Ok
            </Button>
            <Button color="secondary" onClick={() => handleClose()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={showDeleteModal}
          toggle={handleCloseModal}
          style={{ height: "30px" }}
        >
          <ModalHeader toggle={handleClose}>Delete Table</ModalHeader>
          <ModalBody>Are you sure to Delete table?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleOKModal()}>
              Ok
            </Button>
            <Button color="secondary" onClick={() => handleCloseModal()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <CopyOptionsModal
          mode={"OPS"}
          modal={opsModal}
          activeTabFC={activeTabFC}
          activeTabCO={activeTabCO}
          activeTabSM={activeTabSM}
          setModal={setOpsModal}
          setIsLoading={setIsLoading}
          selectedMethodologyData1={selectedMethodologyData1}
          activities={activitiesForOPS}
          band={OPSband}
          options={options}
          selectedCountryData={selectedCountryData}
        />
      </Main>
    </Wrapper>
  );
};

export default OperationsTimecost;