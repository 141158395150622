import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Collapse,
  CardTitle,
  CardHeader,
  Label,
  Input
} from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import Navbar from "../../components/Navbar";
import Layout from "../../layouts/Project";
import * as survey from "./surveyFields.json";
import { getLabel } from "../../utils/codeLabels";
import { saveDelivery } from "../../redux/actions/deliveryActions";
import Spinner from "../../components/Spinner";
import Select from "react-select";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";
import { toastr } from "react-redux-toastr";

const SurveyInformation = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentDeliverable = useSelector(
    ({ deliveries }) => deliveries.currentProject
  );
  const currentProject = useSelector(({ currentProject }) => currentProject);
  const currentUser = useSelector(({ user }) => user.userRecord);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const currentDelivery = useSelector(
    ({ deliveries }) => deliveries.currentDelivery
  );
  const app = useSelector(({ app }) => app);
  const otherDeliveryCollectionCode = _.head(
    codeLabels.DeliveryDataCollectionTypeOptions.filter(
      (ds) => ds.Label == "Other"
    )
  )?.Code;
  //const [editableDelivery, setprops.editableDelivery] = useState({ ...currentDelivery })

  // const editSurveyForm = (eve, isWaveSpec) => {
  //     if (isWaveSpec) {
  //         editableDelivery.WaveSpec[eve.target.id] = eve.target.value
  //     } else {
  //         editableDelivery[eve.target.id] = eve.target.value
  //     }
  //     setprops.editableDelivery({ ...editableDelivery })
  // }

  // currentUser.IsTCSUser = true
  let surveyFields = [];

  // const [props.moduleData,props.setModuleData] = useState({[props.activeTabModule]:{}}) 

  if (currentUser.IsTCSUser)
    surveyFields = Object.keys(survey.default.properties).filter(
      (prop) =>
        survey.default.properties[prop].isTCSOnly ||
        survey.default.properties[prop].isTCSOnly == undefined
    );
  else
    surveyFields = Object.keys(survey.default.properties).filter(
      (prop) =>
        !survey.default.properties[prop].isTCSOnly ||
        survey.default.properties[prop].isTCSOnly == undefined
    );

  const [allTabs] = useState({
    assignProgrammer: "Assign Programmer",
    testLink: "Test Link Sent",
    dataProcessing: "Data Processing",
    charting: "Charting",
    projectField: "Project In Field",
    close: "Close Job",
    cancelled: "Cancelled",
  });
  const [allTabsStatus, setTabStatus] = useState({
    assignProgrammer: false,
    testLink: false,
    dataProcessing: false,
    charting: false,
    projectField: false,
    close: false,
    cancelled: false,
  });
  const [activeTab, setActiveTab] = useState(Object.keys(allTabs).shift());

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    // <Layout>
    <div className={`container ${props.isNotEditable ? "not-editable" : ""}`}>
      <Container>
        {/* {JSON.stringify(props.moduleData)} */}
        <Row className="m-2">
          <Col md={4}>
            <Label>Module Name *</Label>
          </Col>
          <Col md={8}>
            <Input type="text" placeholder="Enter Module Name"  onChange={(e)=>{
              let temp = _.cloneDeep(props.moduleData)
              temp[props.activeTabModule]=
              {...temp[props.activeTabModule], ModuleUserInputName: e ? e.target.value : ""}
              props.setModuleData(temp)
            }}
            value = {
              props.moduleData && props.moduleData[props.activeTabModule] &&
              props.moduleData[props.activeTabModule].ModuleUserInputName
              ? props.moduleData[props.activeTabModule].ModuleUserInputName
              : ""
            }
            />
          </Col>
        </Row>
        <Card>
          <CardHeader
            onClick={(e) =>
              setTabStatus({
                ...allTabsStatus,
                assignProgrammer: !allTabsStatus.assignProgrammer,
              })
            }
          >
            <Row>
              <Col xs="11">
                <CardTitle className="mb-0">Assign Programmer</CardTitle>
              </Col>
              <Col xs="1">
                <FontAwesomeIcon
                  className="align-middle mr-2"
                  icon={
                    !allTabsStatus.assignProgrammer
                      ? faChevronRight
                      : faChevronDown
                  }
                  fixedWidth
                />
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={allTabsStatus.assignProgrammer}>
            <CardBody>
              <Row xs="12">
                {_.includes(surveyFields, "programmerAssigned") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Programmer Assigned
                    </Col>
                    <Col xs="6" className="form-group">
                      {/* <select
                        className="form-control searchable"
                        defaultValue={props.editableDelivery.ProgrammerAssigned}
                        onChange={(e) => props.editSurveyForm(e)}
                        id="ProgrammerAssigned"
                      >
                        <option value={0}>Please select an option</option>
                        {props.programmers?.map((pro) => (
                          <option value={pro.Email}>{pro.Email}</option>
                        ))}
                      </select> */}
                      <Select
                        className="custom-select-box"
                        isSearchable={true}
                        options={props.programmers.map((ddto) => {
                          return {
                            value: ddto.Email,
                            label: ddto.Email,
                          };
                        })}
                        // defaultValue={
                        //   props.editableDelivery.ProgrammerAssigned
                        //     ? {
                        //         value: _.head(
                        //           props.programmers.filter(
                        //             (pro) =>
                        //               pro.Email ==
                        //               props.editableDelivery.ProgrammerAssigned
                        //           )
                        //         )?.Email,
                        //         label: _.head(
                        //           props.programmers.filter(
                        //             (pro) =>
                        //               pro.Email ==
                        //               props.editableDelivery.ProgrammerAssigned
                        //           )
                        //         )?.Email,
                        //       }
                        //     : ""
                        // }

                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                          props.moduleData[props.activeTabModule].ProgrammerAssigned
                          ? 
                          {
                                    value: _.head(
                                      props.programmers.filter(
                                        (pro) =>
                                          pro.Email ==
                                         props.moduleData[props.activeTabModule].ProgrammerAssigned
                                      )
                                    )?.Email,
                                    label: _.head(
                                      props.programmers.filter(
                                        (pro) =>
                                          pro.Email ==
                                         props.moduleData[props.activeTabModule].ProgrammerAssigned
                                      )
                                    )?.Email,
                                  }
                                : ""
                          // props.moduleData[props.activeTabModule].ProgrammerAssigned:""
                        }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ProgrammerAssigned : e ? e.value : ""}
                          props.setModuleData(temp)

                          props.editSurveyForm({
                            target: {
                              id: "ProgrammerAssigned",
                              value: e ? e.value : "",
                            },
                          });
                        }}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "groupLeadName") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Group Lead Name
                    </Col>
                    <Col xs="6" className="form-group">
                      {/* <select
                        className="form-control"
                        defaultValue={props.editableDelivery.GroupLeadEmail}
                        onChange={(e) => props.editSurveyForm(e)}
                        id="GroupLeadEmail"
                      >
                        <option value={0}></option>
                        {props.teamLeads?.map((tl) => (
                          <option value={tl.Email}>{tl.Email}</option>
                        ))}
                      </select> */}
                      <Select
                        className="custom-select-box"
                        isSearchable={true}
                        options={props.teamLeads.map((ddto) => {
                          return {
                            value: ddto.Email,
                            label: ddto.Email,
                          };
                        })}
                        // defaultValue={
                        //   props.editableDelivery.GroupLeadEmail
                        //     ? {
                        //         value: _.head(
                        //           props.teamLeads.filter(
                        //             (pro) =>
                        //               pro.Email ==
                        //               props.editableDelivery.GroupLeadEmail
                        //           )
                        //         )?.Email,
                        //         label: _.head(
                        //           props.teamLeads.filter(
                        //             (pro) =>
                        //               pro.Email ==
                        //               props.editableDelivery.GroupLeadEmail
                        //           )
                        //         )?.Email,
                        //       }
                        //     : ""
                        // }

                        value = {
                          props.moduleData &&props.moduleData[props.activeTabModule] && props.moduleData[props.activeTabModule].GroupLeadEmail
                          ? {
                              value: _.head(
                                props.teamLeads.filter(
                                  (pro) =>
                                    pro.Email ==
                                    props.moduleData[props.activeTabModule].GroupLeadEmail
                                )
                              )?.Email,
                              label: _.head(
                                props.teamLeads.filter(
                                  (pro) =>
                                    pro.Email ==
                                    props.moduleData[props.activeTabModule].GroupLeadEmail
                                )
                              )?.Email,
                            }
                          : ""
                        }
                        onChange={(e) => {

                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],GroupLeadEmail : e ? e.value : ""}
                          props.setModuleData(temp)

                          props.editSurveyForm({
                            target: {
                              id: "GroupLeadEmail",
                              value: e ? e.value : "",
                            },
                          });
                        }}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "initialSetupCTDays") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Initial Setup CT Days
                    </Col>
                    <Col xs="6" className="form-group">
                      <SelectWrapper
                        id="InitialSetupCTDays"
                        // defaultValue={props.editableDelivery.InitialSetupCTDays}
                       value = {
                        props.moduleData && props.moduleData[props.activeTabModule] &&
                          props.moduleData[props.activeTabModule].InitialSetupCTDays
                          ?props.moduleData[props.activeTabModule].InitialSetupCTDays:""
                       }
                       
                        onChange={(e) => {
                          
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],InitialSetupCTDays : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                      >
                        <option value={0}>Please select an option</option>
                        {codeLabels.DeliveryInitialSetupCTDaysOptions.map(
                          (ctc) => {
                            return (
                              <option value={ctc.Code}>{ctc.Label}</option>
                            );
                          }
                        )}
                      </SelectWrapper>
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "costCentre") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Cost Centre
                    </Col>
                    <Col xs="6" className="form-group">
                      {/* <select
                        className="form-control"
                        id="CostCentre"
                        defaultValue={props.editableDelivery.CostCentre}
                        onChange={(e) => props.editSurveyForm(e)}
                      >
                        <option value={0}>Please select an option</option>
                        {codeLabels.DeliveryCostCentreOptions.map((ctc) => {
                          return <option value={ctc.Code}>{ctc.Label}</option>;
                        })}
                      </select> */}

                      <Select
                        className="custom-select-box"
                        isSearchable={true}
                        options={codeLabels.DeliveryCostCentreOptions.map(
                          (ddto) => {
                            return {
                              value: ddto.Code,
                              label: ddto.Label,
                            };
                          }
                        )}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].CostCentre
                            ?{
                              value: _.head(
                                codeLabels.DeliveryCostCentreOptions.filter(
                                  (pro) =>
                                    pro.Code ==
                                    props.moduleData[props.activeTabModule].CostCentre
                                )
                              )?.Code,
                              label: _.head(
                                codeLabels.DeliveryCostCentreOptions.filter(
                                  (pro) =>
                                    pro.Code ==
                                    props.moduleData[props.activeTabModule].CostCentre
                                )
                              )?.Label,
                            }:""
                         }
                        // defaultValue={
                        //   props.editableDelivery.CostCentre
                        //     ? {
                        //         value: _.head(
                        //           codeLabels.DeliveryCostCentreOptions.filter(
                        //             (pro) =>
                        //               pro.Code ==
                        //               props.editableDelivery.CostCentre
                        //           )
                        //         )?.Code,
                        //         label: _.head(
                        //           codeLabels.DeliveryCostCentreOptions.filter(
                        //             (pro) =>
                        //               pro.Code ==
                        //               props.editableDelivery.CostCentre
                        //           )
                        //         )?.Label,
                        //       }
                        //     : ""
                        // }
                        onChange={(e) => {
                          
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],CostCentre : e.value }
                          props.setModuleData(temp)
                          props.editSurveyForm({
                            target: {
                              id: "CostCentre",
                              value: e ? e.value : "",
                            },
                          });
                        }}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "complexityLevel") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Complexity Level
                    </Col>
                    <Col xs="6" className="form-group">
                      <SelectWrapper
                        id="ComplexityLevel"
                        // defaultValue={props.editableDelivery.ComplexityLevel}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ComplexityLevel
                            ?props.moduleData[props.activeTabModule].ComplexityLevel:""
                         }
                        onChange={(e) => {
                          
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ComplexityLevel : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                      >
                        <option value={0}>Please select an option</option>
                        {codeLabels.DeliveryComplexityLevelSPOptions.map(
                          (ctc) => {
                            return (
                              <option value={ctc.Code}>{ctc.Label}</option>
                            );
                          }
                        )}
                      </SelectWrapper>
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "platform") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Platform
                    </Col>
                    <Col xs="6" className="form-group">
                      <SelectWrapper
                        id="Platform"
                        // defaultValue={props.editableDelivery.Platform}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].Platform
                            ?props.moduleData[props.activeTabModule].Platform:""
                         }
                        onChange={(e) =>{ 
                          try{
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],Platform : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)
                          }
                          catch(error){
                            toastr.error("Something Went Wrong")
                          }
                        }}
                      >
                        <option value={0}>Please select an option</option>
                        {codeLabels.DeliveryPlatformSPOptions.map((ctc) => {
                          return <option value={ctc.Code}>{ctc.Label}</option>;
                        })}
                      </SelectWrapper>
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "dataCollection") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Data Collection
                    </Col>
                    <Col xs="6" className="form-group">
                      <Select
                        className="custom-select-box"
                        isMulti={true}
                        options={codeLabels.DeliveryDataCollectionTypeOptions.map(
                          (ddto) => {
                            return {
                              value: ddto.Code,
                              label: ddto.Label,
                            };
                          }
                        )}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DataCollectionMethod
                            ?  props.moduleData[props.activeTabModule].DataCollectionMethod.split(
                              ","
                            ).map((dm) => {
                              return {
                                value: dm,
                                label: getLabel(
                                  "DeliveryDataCollectionTypeOptions",
                                  dm
                                ),
                              };
                            })
                          : ""
                         }
                        // defaultValue={
                        //   props.editableDelivery?.DataCollectionMethod
                        //     ? props.editableDelivery.DataCollectionMethod.split(
                        //         ","
                        //       ).map((dm) => {
                        //         return {
                        //           value: dm,
                        //           label: getLabel(
                        //             "DeliveryDataCollectionTypeOptions",
                        //             dm
                        //           ),
                        //         };
                        //       })
                        //     : ""
                        // }
                        onChange={(e) => {
                          
                          let _value = e? e.map((val) => val.value).join(): null
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DataCollectionMethod : _value
                            
                            }
                          // props.setModuleData(temp)
                          props.editSurveyForm({
                            target: {
                              id: "DataCollectionMethod",
                              value: e
                                ? e.map((val) => val.value).join()
                                : null,
                            },
                          });
                          if (
                            !_.includes(
                              e?.map((val) => val.value),
                              otherDeliveryCollectionCode
                            )
                          ) {

                            
                          // let temp2 = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DataCollectionMethodOther :null }
                                                  props.editSurveyForm({
                              target: {
                                id: "DataCollectionMethodOther",
                                value: null,
                              },
                            });
                          }
                          props.setModuleData(temp)

                        }}
                      />
                    </Col>
                  </>
                ) : null}
                {props.editableDelivery.DataCollectionMethod &&
                _.includes(
                  props.editableDelivery.DataCollectionMethod.split(","),
                  otherDeliveryCollectionCode
                ) ? (
                  <>
                    <Col xs="6" className="form-group">
                      Data Collection Other
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        id="DataCollectionMethodOther"
                        className="form-control"
                        // defaultValue={
                        //   props.editableDelivery.DataCollectionMethodOther
                        // }

                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DataCollectionMethodOther
                            ?props.moduleData[props.activeTabModule].DataCollectionMethodOther:""
                         }
                        onChange={(e) =>{
                          
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DataCollectionMethodOther : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
              </Row>
            </CardBody>
          </Collapse>
        </Card>
      </Container>
      <Container>
        <Card>
          <CardHeader
            onClick={(e) =>
              setTabStatus({
                ...allTabsStatus,
                testLink: !allTabsStatus.testLink,
              })
            }
          >
            <Row>
              <Col xs="11">
                <CardTitle className="mb-0">Test Link Sent</CardTitle>
              </Col>
              <Col xs="1">
                <FontAwesomeIcon
                  className="align-middle mr-2"
                  icon={
                    !allTabsStatus.testLink ? faChevronRight : faChevronDown
                  }
                  fixedWidth
                />
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={allTabsStatus.testLink}>
            <CardBody>
              <Row xs="12">
                {_.includes(surveyFields, "actualDateFinalQuestionnaire") ? (
                  <>
                    {" "}
                    <Col xs="6" className="form-group">
                      Actual Date Final Questionnaire to Programming
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="DateFinalQuestionnaireActual"
                        // defaultValue={
                        //   props.editableDelivery.WaveSpec.DateFinalQuestionnaireActual?.split(
                        //     "T"
                        //   )[0]
                        // }

                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DateFinalQuestionnaireActual
                            ?props.moduleData[props.activeTabModule].DateFinalQuestionnaireActual:""
                         }
                        onChange={(e) =>{
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DateFinalQuestionnaireActual : e.target.value }
                          props.setModuleData(temp)
                          
                          props.editSurveyForm(e, true)}}
                        type="date"
                      />
                    </Col>
                  </>
                ) : null}

                {_.includes(surveyFields, "actualDateProgrammingStart") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Actual Date Programming Start
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="DateProgrammingStartActual"
                        // defaultValue={
                        //   props.editableDelivery.WaveSpec.DateProgrammingStartActual?.split(
                        //     "T"
                        //   )[0]
                        // }

                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DateProgrammingStartActual
                            ?props.moduleData[props.activeTabModule].DateProgrammingStartActual:""
                         }
                        onChange={(e) =>{
                          
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DateProgrammingStartActual : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e, true)}}
                        type="date"
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "firstTestLinkSentDate") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Date First Test Link Sent
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="DateFirstTestLinkActual"
                        // defaultValue={
                        //   props.editableDelivery.WaveSpec.DateFirstTestLinkActual?.split(
                        //     "T"
                        //   )[0]
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DateFirstTestLinkActual
                            ?props.moduleData[props.activeTabModule].DateFirstTestLinkActual:""
                         }
                        onChange={(e) =>{
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DateFirstTestLinkActual : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e, true)}}
                        type="date"
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "changesBeforeDelivery") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Changes Before Initial Link Delivery
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="ChangesBeforeInitialLinkDelivery"
                        // defaultValue={
                        //   props.editableDelivery
                        //     .ChangesBeforeInitialLinkDelivery
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ChangesBeforeInitialLinkDelivery
                            ?props.moduleData[props.activeTabModule].ChangesBeforeInitialLinkDelivery:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ChangesBeforeInitialLinkDelivery : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "testSurveyProjID") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Test Survey Project Id
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="TestSurveyProjectId"
                        // defaultValue={
                        //   props.editableDelivery.TestSurveyProjectId
                        // }

                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].TestSurveyProjectId
                            ?props.moduleData[props.activeTabModule].TestSurveyProjectId:""
                         }
                        onChange={(e) =>{
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],TestSurveyProjectId : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
              </Row>
            </CardBody>
          </Collapse>
        </Card>
      </Container>
      <Container>
        <Card>
          <CardHeader
            onClick={(e) =>
              setTabStatus({
                ...allTabsStatus,
                dataProcessing: !allTabsStatus.dataProcessing,
              })
            }
          >
            <Row>
              <Col xs="11">
                <CardTitle className="mb-0">Data Processing</CardTitle>
              </Col>
              <Col xs="1">
                <FontAwesomeIcon
                  className="align-middle mr-2"
                  icon={
                    !allTabsStatus.dataProcessing
                      ? faChevronRight
                      : faChevronDown
                  }
                  fixedWidth
                />
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={allTabsStatus.dataProcessing}>
            <CardBody>
              <Row xs="12">
                {_.includes(surveyFields, "DataProcessingAssignedAssociate") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Assigned Data Processing associate
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        id="DPAssignedAssociate"
                        // defaultValue={
                        //   props.editableDelivery.DPAssignedAssociate
                        // }

                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DPAssignedAssociate
                            ?props.moduleData[props.activeTabModule].DPAssignedAssociate:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DPAssignedAssociate : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "DataProcessingComplexity") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Complexity Level
                    </Col>
                    <Col xs="6" className="form-group">
                      <SelectWrapper
                        id="DPComplexityLevel"
                        // defaultValue={
                        //   props.editableDelivery.DPComplexityLevel
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DPComplexityLevel
                            ?props.moduleData[props.activeTabModule].DPComplexityLevel:""
                         }
                        onChange={(e) =>{
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DPComplexityLevel : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                      >
                        <option value={0}>Please select an option</option>
                        {codeLabels.DeliveryComplexityLevelSPOptions.map(
                          (ctc) => {
                            return (
                              <option value={ctc.Code}>{ctc.Label}</option>
                            );
                          }
                        )}
                      </SelectWrapper>
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "DataProcessingPlatform") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Platform
                    </Col>
                    <Col xs="6" className="form-group">
                    <SelectWrapper
                        id="DPPlatform"
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DPPlatform
                            ?props.moduleData[props.activeTabModule].DPPlatform:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DPPlatform : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                      >
                        <option value={0}>Please select an option</option>
                        {codeLabels.DeliveryPlatformSPOptions.map((ctc) => {
                          return <option value={ctc.Code}>{ctc.Label}</option>;
                        })}
                      </SelectWrapper>
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "DataProcessingDateSpecsReceived") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Actual Date tab specs/analysis plan received
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        type="date"
                        id="DPActualDate"
                        // defaultValue={
                        //   props.editableDelivery.DPActualDate?.split("T")[0]
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DPActualDate
                            ?props.moduleData[props.activeTabModule].DPActualDate:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DPActualDate : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(
                  surveyFields,
                  "DataProcessingDateFirstDelivered"
                ) ? (
                  <>
                    <Col xs="6" className="form-group">
                      Actual Date First set of tables delivered
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        type="date"
                        id="DPTablesDeliveredActualDate"
                        // defaultValue={
                        //   props.editableDelivery.DPTablesDeliveredActualDate?.split("T")[0]
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DPTablesDeliveredActualDate
                            ?props.moduleData[props.activeTabModule].DPTablesDeliveredActualDate:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DPTablesDeliveredActualDate : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(
                  surveyFields,
                  "DataProcessingExtraTableRequests"
                ) ? (
                  <>
                    <Col xs="6" className="form-group">
                      Number of changes or extra table requests AFTER first set
                      of tables delivered (count each individual change)
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        type="number"
                        min={0}
												step="any"
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DPNumberOfChanges : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                        id="DPNumberOfChanges"
                        // defaultValue={props.editableDelivery.DPNumberOfChanges}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DPNumberOfChanges
                            ?props.moduleData[props.activeTabModule].DPNumberOfChanges:""
                         }
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "DataProcessingChangeIterations") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Number of change iterations requested (count the number of
                      change batches requested)
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        type="number"
                        min={0}
												step="any"
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DPNumberOfChangeIterations : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                        id="DPNumberOfChangeIterations"
                        // defaultValue={props.editableDelivery.DPNumberOfChangeIterations}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DPNumberOfChangeIterations
                            ?props.moduleData[props.activeTabModule].DPNumberOfChangeIterations:""
                         }
                      />
                    </Col>
                  </>
                ) : null}
              </Row>
            </CardBody>
          </Collapse>
        </Card>
      </Container>
      <Container>
        <Card>
          <CardHeader
            onClick={(e) =>
              setTabStatus({
                ...allTabsStatus,
                charting: !allTabsStatus.charting,
              })
            }
          >
            <Row>
              <Col xs="11">
                <CardTitle className="mb-0">Charting</CardTitle>
              </Col>
              <Col xs="1">
                <FontAwesomeIcon
                  className="align-middle mr-2"
                  icon={
                    !allTabsStatus.charting ? faChevronRight : faChevronDown
                  }
                  fixedWidth
                />
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={allTabsStatus.charting}>
            <CardBody>
              <Row xs="12">
                {_.includes(surveyFields, "ChartingAssignedAssociate") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Assigned Charting associate
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ChartAssignedAssociate : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                        id="ChartAssignedAssociate"
                        // defaultValue={props.editableDelivery.ChartAssignedAssociate}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ChartAssignedAssociate
                            ?props.moduleData[props.activeTabModule].ChartAssignedAssociate:""
                         }
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "ChartingComplexity") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Complexity Level
                    </Col>
                    <Col xs="6" className="form-group">
                    <SelectWrapper
                        id="ChartComplexityLevel"
                        // defaultValue={props.editableDelivery.ChartComplexityLevel}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ChartComplexityLevel
                            ?props.moduleData[props.activeTabModule].ChartComplexityLevel:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ChartComplexityLevel : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                      >
                        <option value={0}>Please select an option</option>
                        {codeLabels.DeliveryComplexityLevelSPOptions.map(
                          (ctc) => {
                            return (
                              <option value={ctc.Code}>{ctc.Label}</option>
                            );
                          }
                        )}
                      </SelectWrapper>
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "ChartingDateSpecsReceived") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Actual Date charting specs/templated received
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        type="date"
                        onChange={(e) => {
                          
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ChartActualDate : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                        id="ChartActualDate"
                        // defaultValue={props.editableDelivery.ChartActualDate?.split("T")[0]}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ChartActualDate
                            ?props.moduleData[props.activeTabModule].ChartActualDate:""
                         }
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "ChartingDateFirstDelivered") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Actual Date First set of charts delivered
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        type="date"
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ChartTablesDeliveredActualDate : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                        id="ChartTablesDeliveredActualDate"
                        // defaultValue={props.editableDelivery.ChartTablesDeliveredActualDate?.split("T")[0]}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ChartTablesDeliveredActualDate
                            ?props.moduleData[props.activeTabModule].ChartTablesDeliveredActualDate:""
                         }
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "ChartingExtraChartRequests") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Number of changes or extra chart requests AFTER first set
                      of charts delivered (count each individual change)
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        type="number"
                        step="any"
                        min={0}
                        onChange={(e) => {
                          
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ChartNumberOfChanges : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e)}}
                        id="ChartNumberOfChanges"
                        // defaultValue={props.editableDelivery.ChartNumberOfChanges}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ChartNumberOfChanges
                            ?props.moduleData[props.activeTabModule].ChartNumberOfChanges:""
                         }
                       />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "ChartingChangeIterations") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Number of change iterations requested (count the number of
                      change batches requested)
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        type="number"
                        step="any"
                        min={0}
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ChartNumberOfChangeIterations : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                        id="ChartNumberOfChangeIterations"
                        // defaultValue={props.editableDelivery.ChartNumberOfChangeIterations}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ChartNumberOfChangeIterations
                            ?props.moduleData[props.activeTabModule].ChartNumberOfChangeIterations:""
                         }
                      />
                    </Col>
                  </>
                ) : null}
              </Row>
            </CardBody>
          </Collapse>
        </Card>
      </Container>
      <Container>
        <Card>
          <CardHeader
            onClick={(e) =>
              setTabStatus({
                ...allTabsStatus,
                projectField: !allTabsStatus.projectField,
              })
            }
          >
            <Row>
              <Col xs="11">
                <CardTitle className="mb-0">Project In Field</CardTitle>
              </Col>
              <Col xs="1">
                <FontAwesomeIcon
                  className="align-middle mr-2"
                  icon={
                    !allTabsStatus.projectField ? faChevronRight : faChevronDown
                  }
                  fixedWidth
                />
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={allTabsStatus.projectField}>
            <CardBody>
              <Row xs="12">
                {_.includes(surveyFields, "DateLiveLinkProvided") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Date Live Link Provided
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        type="date"
                        id="DateLiveLinkActual"
                        // defaultValue={
                        //   props.editableDelivery.WaveSpec.DateLiveLinkActual?.split(
                        //     "T"
                        //   )[0]
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DateLiveLinkActual
                            ?props.moduleData[props.activeTabModule].DateLiveLinkActual:""
                         }
                        onChange={(e) =>{
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DateLiveLinkActual : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e, true)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "ActualStartFieldDate") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Actual Start Field Date
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        type="date"
                        id="DateFieldStartActual"
                        // defaultValue={
                        //   props.editableDelivery.WaveSpec.DateFieldStartActual?.split(
                        //     "T"
                        //   )[0]
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DateFieldStartActual
                            ?props.moduleData[props.activeTabModule].DateFieldStartActual:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DateFieldStartActual : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e, true)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "NumberOfQuestions") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Number of Questions
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="NumberOfQuestions"
                        // defaultValue={props.editableDelivery.NumberOfQuestions}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].NumberOfQuestions
                            ?props.moduleData[props.activeTabModule].NumberOfQuestions:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],NumberOfQuestions : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}

                {_.includes(surveyFields, "ChangesToUniqueQuestions") ? (
                  <>
                    <Col xs="6" className="form-group">
                      <span>
                        Changes to Unique Questions
                        <label className="ml-2 mr-2">
                          <input
                            type="checkbox"
                            id="ChangesToUniqueQuestionsNA"
                            // defaultChecked={
                            //   props.editableDelivery.ChangesToUniqueQuestionsNA
                            //     ? true
                            //     : false
                            // }

                            checked = {
                              props.moduleData && props.moduleData[props.activeTabModule] &&
                                props.moduleData[props.activeTabModule].ChangesToUniqueQuestionsNA
                                ?true:false
                             }
                            onChange={(e) =>{
                              let temp = _.cloneDeep(props.moduleData)
                              temp[props.activeTabModule]=
                              {...temp[props.activeTabModule],ChangesToUniqueQuestionsNA : e.target.checked }
                              props.setModuleData(temp)
                              props.editSurveyForm({
                                ...e,
                                target: {
                                  ...e.target,
                                  value: e.target.checked,
                                  id: e.target.id,
                                },
                              })
                            }}
                          />
                        </label>
                        NA
                      </span>
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="ChangesToUniqueQuestions"
                        disabled={
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                                props.moduleData[props.activeTabModule].ChangesToUniqueQuestionsNA
                            ? true
                            : false
                        }
                        value={
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                                props.moduleData[props.activeTabModule].ChangesToUniqueQuestions
                            ? props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ChangesToUniqueQuestions
                            :  props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ChangesToUniqueQuestions ==
                              null
                            ? ""
                            : ""
                        }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ChangesToUniqueQuestions : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "NumberOfPids") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Number of PIDs
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="NumberOfPids"
                        // defaultValue={props.editableDelivery.NumberOfPids}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].NumberOfPids
                            ?props.moduleData[props.activeTabModule].NumberOfPids:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],NumberOfPids : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "ChangesAfterInitialLinkDelivery") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Changes After Initial Link Delivery
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="ChangesAfterInitialLinkDelivery"
                        // defaultValue={
                        //   props.editableDelivery.ChangesAfterInitialLinkDelivery
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ChangesAfterInitialLinkDelivery
                            ?props.moduleData[props.activeTabModule].ChangesAfterInitialLinkDelivery:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ChangesAfterInitialLinkDelivery : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "NumberOfIterations") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Number of Iterations TILL NOW
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="NumberOfIterations"
                        // defaultValue={props.editableDelivery.NumberOfIterations}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].NumberOfIterations
                            ?props.moduleData[props.activeTabModule].NumberOfIterations:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],NumberOfIterations : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "ErrorsInInitialLinkDelivery") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Errors In Initial Link Delivery
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="ErrorsInInitialLinkDelivery"
                        // defaultValue={
                        //   props.editableDelivery.ErrorsInInitialLinkDelivery
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ErrorsInInitialLinkDelivery
                            ?props.moduleData[props.activeTabModule].ErrorsInInitialLinkDelivery:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ErrorsInInitialLinkDelivery : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "ErrorsAfterInitialLinkDelivery") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Errors After Initial Link Delivery
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="ErrorsAfterInitialLinkDelivery"
                        // defaultValue={
                        //   props.editableDelivery.ErrorsAfterInitialLinkDelivery
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ErrorsAfterInitialLinkDelivery
                            ?props.moduleData[props.activeTabModule].ErrorsAfterInitialLinkDelivery:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ErrorsAfterInitialLinkDelivery : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "JobCount") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Job Count
                    </Col>
                    <Col xs="6" className="form-group">
                      <SelectWrapper
                        id="JobCount"
                        // defaultValue={props.editableDelivery.JobCount}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].JobCount
                            ?props.moduleData[props.activeTabModule].JobCount:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],JobCount : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      >
                        <option value={0}></option>
                        {_.sortBy(codeLabels.DeliveryJobCountSP,"Label").map((dj) => (
                          <option value={dj.Code}>{dj.Label}</option>
                        ))}
                      </SelectWrapper>
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "LiveSurveyProjectId") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Live Survey Project Id
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="LiveSurveyProjectId"
                        // defaultValue={
                        //   props.editableDelivery.LiveSurveyProjectId
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].LiveSurveyProjectId
                            ?props.moduleData[props.activeTabModule].LiveSurveyProjectId:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],LiveSurveyProjectId : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
              </Row>
            </CardBody>
          </Collapse>
        </Card>
      </Container>
      <Container>
        <Card>
          <CardHeader
            onClick={(e) =>
              setTabStatus({ ...allTabsStatus, close: !allTabsStatus.close })
            }
          >
            <Row>
              <Col xs="11">
                <CardTitle className="mb-0">Close Job</CardTitle>
              </Col>
              <Col xs="1">
                <FontAwesomeIcon
                  className="align-middle mr-2"
                  icon={!allTabsStatus.close ? faChevronRight : faChevronDown}
                  fixedWidth
                />
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={allTabsStatus.close}>
            <CardBody>
              <Row xs="12">
                {_.includes(surveyFields, "ActualFieldEndDate") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Actual Field End Date
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        type="date"
                        id="DateFieldEndActual"
                        // defaultValue={
                        //   props.editableDelivery.WaveSpec.DateFieldEndActual?.split(
                        //     "T"
                        //   )[0]
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DateFieldEndActual
                            ?props.moduleData[props.activeTabModule].DateFieldEndActual:""
                         }
                        onChange={(e) =>{ 
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DateFieldEndActual : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e, true)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "ActualDeliveryToGODate") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Actual Delivery To GO Date
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        type="date"
                        id="DateDeliveryToGOActual"
                        // defaultValue={
                        //   props.editableDelivery.WaveSpec.DateDeliveryToGOActual?.split(
                        //     "T"
                        //   )[0]
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].DateDeliveryToGOActual
                            ?props.moduleData[props.activeTabModule].DateDeliveryToGOActual:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],DateDeliveryToGOActual : e.target.value }
                          props.setModuleData(temp)
                          props.editSurveyForm(e, true)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "PlatformProjectId") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Confirmit/Decipher/STG Project ID
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="PlatformProjectId"
                        // defaultValue={props.editableDelivery.PlatformProjectId}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].PlatformProjectId
                            ?props.moduleData[props.activeTabModule].PlatformProjectId:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],PlatformProjectId : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "ChangesAfterLiveLinkCreated") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Changes After Live Link Created
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="ChangesAfterLiveLinkCreated"
                        // defaultValue={
                        //   props.editableDelivery.ChangesAfterLiveLinkCreated
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ChangesAfterLiveLinkCreated
                            ?props.moduleData[props.activeTabModule].ChangesAfterLiveLinkCreated:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ChangesAfterLiveLinkCreated : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "ErrorsAfterLiveLinkCreated") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Errors After Live Link Created
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="ErrorsAfterLiveLinkCreated"
                        // defaultValue={
                        //   props.editableDelivery.ErrorsAfterLiveLinkCreated
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ErrorsAfterLiveLinkCreated
                            ?props.moduleData[props.activeTabModule].ErrorsAfterLiveLinkCreated:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ErrorsAfterLiveLinkCreated : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "TotalNumberOfIterations") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Total Number Of Iterations (FINAL)
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="TotalNumberOfIterations"
                        // defaultValue={
                        //   props.editableDelivery.TotalNumberOfIterations
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].TotalNumberOfIterations
                            ?props.moduleData[props.activeTabModule].TotalNumberOfIterations:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],TotalNumberOfIterations : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "ActualLOI") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Actual LOI
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="ActualLOIMins"
                        // defaultValue={props.editableDelivery.ActualLOIMins}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ActualLOIMins
                            ?props.moduleData[props.activeTabModule].ActualLOIMins:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ActualLOIMins : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "TotalCompletes") ? (
                  <>
                    <Col xs="6" className="form-group">
                      Total Completes
                    </Col>
                    <Col xs="6" className="form-group">
                      <input
                        className="form-control"
                        id="TotalCompletes"
                        // defaultValue={props.editableDelivery.TotalCompletes}
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].TotalCompletes
                            ?props.moduleData[props.activeTabModule].TotalCompletes:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],TotalCompletes : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
              </Row>
            </CardBody>
          </Collapse>
        </Card>
      </Container>
      <Container>
        <Card>
          <CardHeader
            onClick={(e) =>
              setTabStatus({
                ...allTabsStatus,
                cancelled: !allTabsStatus.cancelled,
              })
            }
          >
            <Row>
              <Col xs="11">
                <CardTitle className="mb-0">Cancelled</CardTitle>
              </Col>
              <Col xs="1">
                <FontAwesomeIcon
                  className="align-middle mr-2"
                  icon={
                    !allTabsStatus.cancelled ? faChevronRight : faChevronDown
                  }
                  fixedWidth
                />
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={allTabsStatus.cancelled}>
            <CardBody>
              <Row xs="12">
                {_.includes(surveyFields, "CancelledDate") ? (
                  <>
                    <Col xs="6">Cancelled Date</Col>
                    <Col xs="6">
                      <input
                        className="form-control"
                        type="date"
                        id="CancelledDate"
                        // defaultValue={
                        //   props.editableDelivery.CancelledDate?.split("T")[0]
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].CancelledDate
                            ?props.moduleData[props.activeTabModule].CancelledDate:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],CancelledDate : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
                {_.includes(surveyFields, "ReasonForCancellation") ? (
                  <>
                    <Col xs="12">Reason for cancellation</Col>
                    <Col xs="12">
                      <textarea
                        className="form-control"
                        id="ReasonForCancellation"
                        // defaultValue={
                        //   props.editableDelivery.ReasonForCancellation
                        // }
                        value = {
                          props.moduleData && props.moduleData[props.activeTabModule] &&
                            props.moduleData[props.activeTabModule].ReasonForCancellation
                            ?props.moduleData[props.activeTabModule].ReasonForCancellation:""
                         }
                        onChange={(e) => {
                          let temp = _.cloneDeep(props.moduleData)
                          temp[props.activeTabModule]=
                          {...temp[props.activeTabModule],ReasonForCancellation : e.target.value }
                          props.setModuleData(temp)
props.editSurveyForm(e)}}
                      />
                    </Col>
                  </>
                ) : null}
              </Row>
            </CardBody>
          </Collapse>
        </Card>
      </Container>
      {/* <div className="d-flex justify-content-between">
                <button className="btn btn-warning mt-2 float-right btn-md"
                    disabled={app.recordloading}
                    onClick={() => history.replace('/deliveries')}
                >Cancel</button>
                <button className="btn btn-primary mt-2 float-right btn-md"
                    disabled={app.recordloading}
                    onClick={submitSurveyInfo}>
                    Save
                        {app.recordloading ? (
                        <Spinner size="small" color="#000000" />
                    ) : null}</button>
            </div> */}
    </div>
    // </Layout>
  );
};

export default SurveyInformation;