const AzureUser = async (token) => {
    let userProvider;
    const res = await fetch(`https://graph.microsoft.com/v1.0/me`, {
        method: 'get',
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    const me = await res.json();
    let  attributes= null;
    // if(me.mail.split("@")[1].includes("gfk")){
    const _me = await fetch(`https://graph.microsoft.com/v1.0/users/${me.id}?$select=onPremisesExtensionAttributes`, {
        method: 'get',
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    attributes= await _me.json()
    console.log(attributes)
    // }
    let extAttribute = attributes?.onPremisesExtensionAttributes?.extensionAttribute3;
    let emailattribute;
    let baseURL = process.env.REACT_APP_API_BASE_URL;
    // if (baseURL == "https://cintralapi.nielseniq.com/" && extAttribute != null && extAttribute.includes("gfk.com")) {
    //     emailattribute = extAttribute;
    // } else {
        emailattribute = me.mail;
    // }
    
    if (me.id) {
        userProvider = {
            name: me.displayName,
            email:emailattribute,
            officeLocation: me.officeLocation,
            firstName: me.givenName,
            lastName: me.surname

        }
    } else if(me?.error) {
        userProvider = {
            error: true,
            errorObj: me?.error
        }

    }
    return userProvider;
}


export default AzureUser;
