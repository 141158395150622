import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
  CardTitle,
  Input,
  InputGroup,
  Label,
  Table,
  Row,
  ModalBody,
} from "reactstrap";
import axios from "../../axios-interceptor";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { pageLoadEnd, pageLoadStart } from "../../redux/actions/appActions";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { getProject } from "../../redux/actions/currentProjectActions";
import * as projectsActions from "../../redux/actions/projectsActions";
import _ from "lodash";
import logo from "../../assets/img/avatars/modal-logo.png"; 
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import SalesforceInput from "../proposalNew/SalesforceInput";
function SyndicateModal({
  synPopUp,
  setSynPopUp,
  updateProject,
  isOpSearchDisableErr,
  project,
  setisOpSearchDisableErr,
  setisOpSearchDisable,
  isOpSearchDisable,
}) {
  const [listPopUp, setlistPopUp] = useState(false);
  

  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [resultsearchData, setResultSearchData] = useState({});
  const [resultInputData, setResultInputData] = useState("");
  const [infiniteLoad, setInfiniteLoad] = useState(false);
  const [valid, setValid] = useState(false);
  const [getnewData, setGetNewData] = useState({
    oppNo:"",
    oppName:"",
    Amount:"",
    clientName:"",
      });
  const [childProject, setChildProject] = useState("");
  const projects = useSelector(({ projects }) => projects.items);
  const hasMore = useSelector(({ projects }) => projects.hasMore);
  const totalItems = useSelector(({ projects }) => projects.totalItems);
  const dispatch = useDispatch();
  const history = useHistory();
  var searchValue;
  var getSearchedData;

  const [isProjectEdit, setIsProjectEdit] = useState(false);
  const [showProjectNameError, setProjectNameError] = useState(false);
  
  const handleList = () => {
    getList();
  };

  const getList = () => {
    axios
      .post("projects/syndicatedProjects/")
      .then((res) => {
        let list = [];
        res.data.items.forEach((el) => {
          var getData =
            "Project Id-" + el.ProjectId + " Project Name-" + el.ProjectName;
          list.push({
            label: getData,
            value: el.ProjectId,
            pid: el.ProjectId,
            pname: el.ProjectName,
            ContractDetails: _.head(el.ContractDetails)
              ? el.ContractDetails[0]?.OpportunityNumber
              : "",
          });
        });
        setSearchData(list);
        setlistPopUp(true);
      })
      .catch((error) => {
        console.log("errr");
      });
  };
  const checklist = () => {
    if (Object.keys(resultsearchData).length === 0) {
      toastr.error("Please Select any Syndicate Project");
    } else if (getnewData.oppNo === "") {
      toastr.error("Client is required to create child Syndicate project");
    } else if (getnewData.oppNo == resultsearchData.ContractDetails) {
      toastr.error(
        "Cannot proceed with Proposal creation, since the provided Opportunity number is already linked to the selected syndicated Project"
      );
      setisOpSearchDisable(!isOpSearchDisable)
    } else {
      getSearchedData = searchValue;
      setConfirmPopUp(true);
    }
  };
  const getSelectedProject = (e) => {
    setResultSearchData(e);
  };

  const getSearchData = (e) => {
    if (e?.length > 0) {
      var inpVal = e;
      axios
        .post("projects/syndicatedSearchProjects/", {
          params: {
            inpVal,
          },
        })
        .then((res) => {
          if (res.data.items) {
            let resData = res.data.items;
            let finalData = resData;
            let searchedArray = [];
            finalData.forEach((el) => {
              var getData =
                "Project Name-" +
                el.ProjectName +
                " Project Id-" +
                el.ProjectId;
              searchedArray.push({
                label: getData,
                value: el.ProjectId,
                pid: el.ProjectId,
                pname: el.ProjectName,
                ContractDetails: _.head(el.ContractDetails)
                  ? el.ContractDetails[0]?.OpportunityNumber
                  : "",
              });
            });
            setSearchData(searchedArray);
          }
        })
        .catch((error) => {
          console.log("errr");
        });
    }
  };
  var imgStyle = {
    width: "150px",
  };

  const handleNewProjectSave = () => {
    const childOPName = childProject.trim();
    setChildProject(childOPName);
    !childOPName ? setProjectNameError(true) : getSummary(childOPName);
  }

  const handleOPInput = (e) => {
    let val = e.target.value;
    val.trim().length<=50 && setChildProject(val);
    val.trim() ? setProjectNameError(false) : setProjectNameError(true);
  }

  const handleOPEditIcon = () => {
    setIsProjectEdit(true)
    setProjectNameError(false)
  }

  const resetParentModel = () => {
    setlistPopUp(!listPopUp);
    setisOpSearchDisable(isOpSearchDisable);
    setGetNewData({
      oppNo:"",
      oppName:"",
      clientName:"",
      Amount:""      
    });
    setChildProject("");
    setResultSearchData({});
  }

  const resetChildModel = () => {
    setConfirmPopUp(!confirmPopUp);
    setChildProject("");
    setProjectNameError(false);
    setIsProjectEdit(false);
  }
  
  const getSummary = (childOPName) => {
    dispatch(pageLoadStart());
    axios
      .post("projects/SyndicateCopyProposal/" + resultsearchData.pid, {
        OpportunityNumber: getnewData.oppNo,
        projectName: childOPName
      })
      .then((res) => {
        dispatch(getProject(res.data.Project.ProjectId));
        dispatch(pageLoadEnd());
        toastr.success("Proposal Fetch Successful!");
        if (res.data.costingProfiles[0]?.id) {
          dispatch(
            currentCostingActions.getSummaryCosting(res.data.costingProfiles[0], () =>
              history.push(`/summary/${res.data.costingProfiles[0].id}`),
              res?.data?.costingProfiles[0]?.IsOPSOOPAdjusted ? false : true
            )
          );
        }
      })
      .catch((error) => {
        dispatch(pageLoadEnd());
        toastr.error("Fetching Proposal Failed!");
      });
  };
  const addOpp = (val) => {
    setGetNewData({
      oppNo:val.OpportunityNumber,
      oppName:val.OpportunityName,
      clientName:val.AccountName,
      Amount:`${val.AmountUSD} USD`      
    })
    setChildProject(val.OpportunityName);
  };
  return (
    <>
      {/* first modal */}
      <Modal
      centered={true}
        isOpen={synPopUp}
        size="sm"
        toggle={() => {
          setSynPopUp(!synPopUp);
        }}
      >
        <ModalHeader toggle={() => setSynPopUp(!synPopUp)}>
        {/* <img src={logo}  className="modallogo"/> */}
        </ModalHeader>
          <div>
           <span className="modaltitle"> Is this Project related to any of the syndicated Projects?</span>
           </div>
        <ModalFooter>
          <Button
            color="primary"
            onClick={(e) => {
              handleList();
            }}
          >
            Yes
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              setSynPopUp(!synPopUp);
            }}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      {/* second modal */}
      <Modal
      centered={true}
        isOpen={listPopUp}
        size="md"
        toggle={() => {
          setlistPopUp(!listPopUp);
        }}
      >
        <ModalHeader toggle={() => resetParentModel()}>
        {/* <img src={logo}  className="modallogo"/> */}
       </ModalHeader>
       <div>
        <span className=" modaltitle modaltitlesyndicated">For New Syndicated Project</span> 
        </div>
        <ModalBody>
          <Row className="m-0">
            <span className="modaltext">Select parent project (Syndicated)</span>
            <Select
              className="react-select-container popupsearchOOP"
              classNamePrefix="react-select"
              options={searchData}
              onChange={(e) => getSelectedProject(e)}
              onInputChange={(e) => {
                getSearchData(e);
              }}
            />
          </Row>
          <Row className="m-0">
            <SalesforceInput
              addOpp={addOpp}
              inpopup={true}
              setisOpSearchDisableErr={setisOpSearchDisableErr}
              isOpSearchDisableErr={isOpSearchDisableErr}
              setisOpSearchDisable={setisOpSearchDisable}
              isOpSearchDisable={isOpSearchDisable}
              setGetNewData={setGetNewData}
            />
          </Row>

          {getnewData.oppNo != "" && (
            <Table>
              <tr>
                <td>Opportunity Number</td>
                <td>Opportunity Name</td>
                <td>Client Name</td>
                <td>Amount</td>
              </tr>

              <tr>
                <td>{getnewData.oppNo}</td>
                <td>{getnewData.oppName}</td>
                <td>{getnewData.clientName}</td>
                <td>{getnewData.Amount}</td>
              </tr>
            </Table>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {
            checklist();
            setChildProject(getnewData.oppName);
          }}
          >
            Yes
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => resetParentModel()}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>

      {/* Third Modal */}
      <Modal
      centered={true}
        isOpen={confirmPopUp}
        size="sm"
        toggle={() => {
          setConfirmPopUp(!confirmPopUp);
        }}
      >
        <ModalHeader toggle={() => resetChildModel()}>
        {/* <img src={logo}  className="modallogo"/> */}
          
        </ModalHeader>
        <div>
        <span className="modaltitle"> You are about to create a proposal based on {resultsearchData.pid}</span>

            <div className="op_parent_input"> 
              <p className="op_label"> New Child Project Name : </p>
              <InputGroup className="align-items-center">
                  {isProjectEdit ? <Input
                    placeholder={childProject ?? "Please Enter Project name"}
                    value={childProject}
                    onChange={(e) => handleOPInput(e) }
                  /> : 
                  <div className="op_name_parent">
                    <div className="op_name_text">
                      {childProject ? childProject : getnewData.oppName}
                    </div>
                    <div className="op_editicon">
                      <FontAwesomeIcon
                        title="Edit Project Name"
                        className="align-middle mr-2"
                        icon={faPencilAlt}
                        fixedWidth
                        onClick={() => handleOPEditIcon()}
                      />
                    </div>
                  </div>
                  }
              </InputGroup>
              {showProjectNameError ? <span className="op_error_text">Project Name Shouldn't be Empty!</span> : ""}
            </div>

        </div>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              handleNewProjectSave();
            }}
          >
            Yes
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => resetChildModel()}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default SyndicateModal;
