import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter, multiSelectFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import CardBody from 'reactstrap/lib/CardBody';
import Select from "react-select";
import _ from "lodash";
import {
  faFilter
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  Card,
  // CardBody,
  Container,
  Collapse,
  CardHeader,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  InputGroup,
  InputGroupAddon,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
  CustomInput
} from "reactstrap";
import { useSelector } from 'react-redux';



function ComparisonView(props) {
  ///*** */ to do ****

  // Static data will be chnaged with the data from selected vendor data

  const Cdata = props.ComparisonViewData
  const ViewOnlyMode = props.ViewOnlyMode
  const [comparisonData, setComparisonData] = useState(
    Cdata
  )
  const [country, setCountry] = useState([])
  const [methodology, setMethodology] = useState([])
  const [costingOption, setCostingOption] = useState([])
  const [costingType, setCostingType] = useState([])
  const [currentCurrencySelected, setCurrentCurrencySelected] = useState({})
  const currencies = useSelector(({ currentCosting }) =>
    currentCosting.currentCostingProfile.ProfileSetting &&
      currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      : []
  );
  useEffect(() => {
    let tempVendorSelection = comparisonData.map(tempVendor => {
      tempVendor.ConstantCostFromVendor = parseFloat(tempVendor.ConstantCostFromVendor).toFixed(2)
      // tempVendor.ConstantCostFromVendor=_.round(parseFloat(tempVendor.ConstantCostFromVendor),2)
      tempVendor.TotalCostusd = isNaN(tempVendor.ConstantCostFromVendor) ? 0 : parseFloat(tempVendor.ConstantCostFromVendor).toFixed(2)
      tempVendor.FinalCPIusd = isNaN(_.round((tempVendor.Costingtype == "Field Cost" ? tempVendor?.AchivableSamples ? (tempVendor.ConstantCostFromVendor / tempVendor.AchivableSamples) : 0 : (tempVendor.ConstantCostFromVendor / tempVendor.SampleSize)), 2)) ? 0 :
        _.round((tempVendor.Costingtype == "Field Cost" ? tempVendor?.AchivableSamples ? (tempVendor.ConstantCostFromVendor / tempVendor.AchivableSamples) : 0 : (tempVendor.ConstantCostFromVendor / tempVendor.SampleSize)), 2)
      tempVendor.CurrentCurrencyConstantCostFromVendor = parseFloat(tempVendor.ConstantCostFromVendor)
      tempVendor.CurrentCurrencyFinalCPIusd = parseFloat(tempVendor.FinalCPIusd)

      return tempVendor
    })
    setComparisonData(tempVendorSelection)
  }, [])
  function getLabel(cell) {
    if (!props.cc_masters["FieldingCountriesOptions"]) return cell;
    let label = props.cc_masters["FieldingCountriesOptions"].filter(
      (x) => x.Label == cell
    )[0]["Label"];
    return label;
  }
  function getProfileSelectOptions1(cell) {
    let values = [];
    if (Cdata.length > 0)
      values = Array.from(
        new Set(Cdata.filter((x) => !x?.PsudoType)?.map((x) => x[cell.dataField])));

    const selectOptions = [];
    values.forEach((x, i) => {
      if (x && !x.includes("Other Costs"))
        selectOptions.push({ label: x, value: x, id: i })
    })
    return selectOptions;
  }
  function optionsCurrency() {
    let tempCurrency = currencies?.filter(c => {
      if (_.uniq(comparisonData?.map(c => c.Country)).find(a => a == c.Label)) {
        return c
      }
    })
    if (!tempCurrency.some(s => s.CurrencyUnit == "USD")) {
      let usdCurr = {
        id: 0,
        Code: "US",
        Label: "United States",
        CurrencyUnit: "USD",
        AllCurrencyUnit: "USD",
        ConversionRateToLocal: 1
      }
      tempCurrency.push(usdCurr)
    }
    let res = tempCurrency?.map((c) => {
      return {
        ...c,
        value: `${c.Code}-${c.CurrencyUnit}`,
        label: `${c.Label} (1USD = ${c.ConversionRateToLocal}${c.CurrencyUnit})`,
      };
    })
    return res
  }
  function onCurrencyChange(val) {
    setCurrentCurrencySelected(val);
    let tempComparisonData = comparisonData
    tempComparisonData = tempComparisonData.map(data => {
      return {
        ...data,
        CurrentCurrencyConstantCostFromVendor: parseFloat(data.ConstantCostFromVendor * val.ConversionRateToLocal).toFixed(2),
        CurrentCurrencyFinalCPIusd: parseFloat(data.FinalCPIusd * val.ConversionRateToLocal).toFixed(2),
      }
    })
    if (val.CurrencyUnit == "USD") {
      tempComparisonData = tempComparisonData.map(data => {
        return {
          ...data,
          CurrentCurrencyConstantCostFromVendor: data.ConstantCostFromVendor,
          CurrentCurrencyFinalCPIusd: data.FinalCPIusd,
        }
      })
    }
    setComparisonData(tempComparisonData)
  }
  const tableColumnsComparisonViewSummary = [
    {
      dataField: "Country",
      text: "Market",
      sort: true,
      filter: customFilter(),
      align: 'center',
      filterRenderer: (onFilter, column) =>
        <Select
          name="CountryFIlter"
          isDisabled={ViewOnlyMode}
          onChange={(e) => {
            setCountry(e)
            if (e && e.length) {

              let filterLabels = e.map(filter1 => filter1.label)
              // let filterarr = Cdata.filter(f => filterLabels.includes(f.Country))
              let filterarr = Cdata.filter(f => _.uniq(comparisonData.map(q => q.Costingtype)).includes(f.Costingtype))
                .filter(f => _.uniq(comparisonData.map(q => q.Methodology)).includes(f.Methodology))
                .filter(f => _.uniq(comparisonData.map(q => q.CostingOption)).includes(f.CostingOption))
                .filter(m => filterLabels.includes(m.Country))
              setComparisonData(_.sortBy(filterarr, "uniquekey"))


            }
            // else if(e=null){
            //   setComparisonData(Cdata)
            // }
            else {
              if ((methodology && methodology.length) || (costingOption && costingOption.length) || (costingType && costingType.length)) {
                let arr1 = []
                let arr2 = []
                let arr3 = []

                arr1 = (methodology && methodology.length) ?
                  comparisonData.length == 0 ?
                    Cdata.filter(f => _.uniq(methodology.map(q => q.label)).includes(f.Methodology))
                    : Cdata.filter(f => _.uniq(comparisonData.map(q => q.Methodology)).includes(f.Methodology))
                  : Cdata.filter(f => _.uniq(Cdata.map(q => q.Methodology)).includes(f.Methodology))

                arr2 = (costingOption && costingOption.length) ?
                  comparisonData.length == 0 ?
                    arr1.filter(f => _.uniq(costingOption.map(q => q.label)).includes(f.CostingOption))
                    : arr1.filter(f => _.uniq(comparisonData.map(q => q.CostingOption)).includes(f.CostingOption))
                  : arr1.filter(f => _.uniq(Cdata.map(q => q.CostingOption)).includes(f.CostingOption))


                arr3 = (costingType && costingType.length) ?
                  comparisonData.length == 0 ?
                    arr2.filter(f => _.uniq(costingType.map(q => q.label)).includes(f.Costingtype))
                    : arr2.filter(f => _.uniq(comparisonData.map(q => q.Costingtype)).includes(f.Costingtype))
                  : arr2.filter(f => _.uniq(Cdata.map(q => q.Costingtype)).includes(f.Costingtype))


                setComparisonData(arr3)
              }

              else {
                setComparisonData(Cdata)
              }
            }
          }
          }
          options={getProfileSelectOptions1({ dataField: "Country" })}
          // options = {[]}
          isMulti
          value={country}
        ></Select>
      ,
      editable: false
    },
    {
      dataField: "Methodology",
      text: "Methodology",
      sort: true,
      editable: false,
      filter: customFilter(),
      align: 'center',
      filterRenderer: (onFilter, column) =>
        <Select
          name="MethodologyFIlter"
          isDisabled={ViewOnlyMode}
          onChange={(e) => {
            setMethodology(e)
            if (e && e.length) {
              let filterLabels = e.map(filter1 => filter1.label)
              // let filterarr =  Cdata.filter(f => filterLabels.includes(f.Methodology))
              let filterarr = Cdata.filter(f => _.uniq(comparisonData.map(q => q.Country)).includes(f.Country))
                .filter(f => _.uniq(comparisonData.map(q => q.CostingOption)).includes(f.CostingOption))
                .filter(f => _.uniq(comparisonData.map(q => q.Costingtype)).includes(f.Costingtype))
                .filter(m => filterLabels.includes(m.Methodology))
              setComparisonData(_.sortBy(filterarr, "uniquekey"))




            }
            else {
              if ((country && country.length) || (costingOption && costingOption.length) || (costingType && costingType.length)) {
                let arr1 = []
                let arr2 = []
                let arr3 = []

                arr1 = (country && country.length) ?
                  comparisonData.length == 0 ?
                    Cdata.filter(f => _.uniq(country.map(q => q.label)).includes(f.Country))
                    : Cdata.filter(f => _.uniq(comparisonData.map(q => q.Country)).includes(f.Country))
                  : Cdata.filter(f => _.uniq(Cdata.map(q => q.Country)).includes(f.Country))


                arr2 = (costingOption && costingOption.length) ?
                  comparisonData.length == 0 ?
                    arr1.filter(f => _.uniq(costingOption.map(q => q.label)).includes(f.CostingOption))
                    : arr1.filter(f => _.uniq(comparisonData.map(q => q.CostingOption)).includes(f.CostingOption))
                  : arr1.filter(f => _.uniq(Cdata.map(q => q.CostingOption)).includes(f.CostingOption))


                arr3 = (costingType && costingType.length) ?
                  comparisonData.length == 0 ?
                    arr2.filter(f => _.uniq(costingType.map(q => q.label)).includes(f.Costingtype))
                    : arr2.filter(f => _.uniq(comparisonData.map(q => q.Costingtype)).includes(f.Costingtype))
                  : arr2.filter(f => _.uniq(Cdata.map(q => q.Costingtype)).includes(f.Costingtype))


                setComparisonData(arr3)
              }

              else {
                setComparisonData(Cdata)
              }
            }
          }}
          options={getProfileSelectOptions1({ dataField: "Methodology" })}
          isMulti
          value={methodology}
        ></Select>
      ,
    },
    {
      dataField: "CostingOptionID",
      text: "CostingOptionID",
      sort: true,
      editable: false,
      hidden: true,
      align: 'center',
    },
    {
      dataField: "cid",
      text: "cid",
      sort: true,
      editable: false,
      hidden: true,
      align: 'center',
    },
    {
      dataField: "CostingOptionindex",
      text: "CostingOptionindex",
      sort: true,
      editable: false,
      hidden: true,
      align: 'center',
    },
    {
      dataField: "CostingOption",
      text: "Costing Option",
      editable: false,
      filter: customFilter(),
      align: 'center',
      filterRenderer: (onFilter, column) =>
        <Select
          name="CostingOptionFIlter"
          isDisabled={ViewOnlyMode}
          onChange={(e) => {
            setCostingOption(e)
            if (e && e.length) {
              let filterLabels = e.map(filter1 => filter1.label)
              // let filterarr = Cdata.filter(f => filterLabels.includes(f.CostingOption))
              let filterarr = Cdata.filter(f => _.uniq(comparisonData.map(q => q.Country)).includes(f.Country))
                .filter(f => _.uniq(comparisonData.map(q => q.Methodology)).includes(f.Methodology))
                .filter(f => _.uniq(comparisonData.map(q => q.Costingtype)).includes(f.Costingtype))
                .filter(m => filterLabels.includes(m.CostingOption))
              setComparisonData(_.sortBy(filterarr, "uniquekey"))
            }
            else {
              if ((country && country.length) || (methodology && methodology.length) || (costingType && costingType.length)) {
                let arr1 = []
                let arr2 = []
                let arr3 = []

                arr1 = (country && country.length) ?
                  comparisonData.length == 0 ?
                    Cdata.filter(f => _.uniq(country.map(q => q.label)).includes(f.Country))
                    : Cdata.filter(f => _.uniq(comparisonData.map(q => q.Country)).includes(f.Country))
                  : Cdata.filter(f => _.uniq(Cdata.map(q => q.Country)).includes(f.Country))

                arr2 = (methodology && methodology.length) ?

                  comparisonData.length == 0 ?
                    arr2.filter(f => _.uniq(methodology.map(q => q.label)).includes(f.Methodology))
                    : arr2.filter(f => _.uniq(comparisonData.map(q => q.Methodology)).includes(f.Methodology))
                  : arr2.filter(f => _.uniq(Cdata.map(q => q.Methodology)).includes(f.Methodology))


                arr3 = (costingType && costingType.length) ?
                  comparisonData.length == 0 ?
                    arr2.filter(f => _.uniq(costingType.map(q => q.label)).includes(f.Costingtype))
                    : arr2.filter(f => _.uniq(comparisonData.map(q => q.Costingtype)).includes(f.Costingtype))
                  : arr2.filter(f => _.uniq(Cdata.map(q => q.Costingtype)).includes(f.Costingtype))


                setComparisonData(arr3)
              }

              else {
                setComparisonData(Cdata)
              }

            }
          }}
          options={getProfileSelectOptions1({ dataField: "CostingOption" })}
          isMulti
          value={costingOption}
        ></Select>
      ,
    },



    {
      dataField: "Costingtype",
      text: "Costing Type",
      editable: false,
      align: 'center',
      filter: customFilter(),
      filterRenderer: (onFilter, column) =>
        <Select
          name="CostingtypeFIlter"
          isDisabled={ViewOnlyMode}
          onChange={(e) => {
            setCostingType(e)
            if (e && e.length) {
              let filterLabels = e.map(filter1 => filter1.label)
              // let filterarr = Cdata.filter(f => filterLabels.includes(f.Costingtype))
              let filterarr = Cdata.filter(f => _.uniq(comparisonData.map(q => q.Country)).includes(f.Country))
                .filter(f => _.uniq(comparisonData.map(q => q.Methodology)).includes(f.Methodology))
                .filter(f => _.uniq(comparisonData.map(q => q.CostingOption)).includes(f.CostingOption))
                .filter(m => filterLabels.includes(m.Costingtype))
              setComparisonData(_.sortBy(filterarr, "uniquekey"))
            }

            else {
              if ((country && country.length) || (methodology && methodology.length) || (costingOption && costingOption.length)) {
                let arr1 = []
                let arr2 = []
                let arr3 = []

                arr1 = (country && country.length) ?
                  comparisonData.length == 0 ?
                    Cdata.filter(f => _.uniq(country.map(q => q.label)).includes(f.Country))
                    : Cdata.filter(f => _.uniq(comparisonData.map(q => q.Country)).includes(f.Country))
                  : Cdata.filter(f => _.uniq(Cdata.map(q => q.Country)).includes(f.Country))

                arr2 = (methodology && methodology.length) ?
                  comparisonData.length == 0 ?
                    arr2.filter(f => _.uniq(methodology.map(q => q.label)).includes(f.Methodology))
                    : arr2.filter(f => _.uniq(comparisonData.map(q => q.Methodology)).includes(f.Methodology))
                  : arr2.filter(f => _.uniq(Cdata.map(q => q.Methodology)).includes(f.Methodology))

                arr3 = (costingOption && costingOption.length) ?

                  comparisonData.length == 0 ?
                    arr1.filter(f => _.uniq(costingOption.map(q => q.label)).includes(f.CostingOption))
                    : arr1.filter(f => _.uniq(comparisonData.map(q => q.CostingOption)).includes(f.CostingOption))
                  : arr1.filter(f => _.uniq(Cdata.map(q => q.CostingOption)).includes(f.CostingOption))

                setComparisonData(arr3)
              }

              else {
                setComparisonData(Cdata)
              }

            }
          }}
          options={getProfileSelectOptions1({ dataField: "Costingtype" })}
          isMulti
          value={costingType}
        ></Select>
      ,
    },
    {
      dataField: "AchivableSamples",
      text: "Sample Size",
      editable: false,
    },
    {
      dataField: "LOI",
      text: "LOI",
      sort: true,
      editable: false,
    },
    {
      dataField: "Vendor",
      text: "Vendor Name",
      sort: true,

      editable: false,
    },
    {
      dataField: "ConstantCostFromVendor",
      text: "Total Vendor Cost (USD)",
      editable: false,
      align: 'center',
    },
    {
      dataField: "CPIusd",
      text: "CPI Vendor (USD)",
      //   formatter: CPIFormatter,
      sort: true,
      align: 'center',
      editable: false,
    },
    {
      dataField: "CurrentCurrencyConstantCostFromVendor",
      text: `Total Vendor Cost (${currentCurrencySelected.CurrencyUnit ? currentCurrencySelected.CurrencyUnit : "USD"})`,
      editable: false,
      align: 'center',
    },
    {
      dataField: "CurrentCurrencyFinalCPIusd",
      text: `CPI Vendor (${currentCurrencySelected.CurrencyUnit ? currentCurrencySelected.CurrencyUnit : "USD"})`,
      //   formatter: CPIFormatter,
      sort: true,
      align: 'center',
      editable: false,
    },
    {
      dataField: "VendorID",
      text: "Vendor ID",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "key",
      text: "key",
      sort: true,
      editable: false,
      hidden: true,
    },


    {
      dataField: "MethodologyIndex",
      text: "M Index",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "MethodologyID",
      text: "MethodologyID",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "ProfileID",
      text: "ProfileID",
      sort: true,
      editable: false,
      hidden: true,
    },
    {
      dataField: "CountryIndex",
      text: "C Index",
      sort: true,
      editable: false,
      hidden: true,
    },




    // {
    //   dataField: "FinalCPIusd",
    //   text: "Final CPI in USD",
    //   editable: false,
    //   align: 'center',
    // },
    // {
    //   dataField: "TotalCostusd",
    //   text: "Total Cost in USD",
    //   sort: true,
    //   editable: false,
    //   align: 'center',
    // },
  ];

  function resetFilters() {
    setComparisonData(Cdata)
    setCountry([])
    setMethodology([])
    setCostingOption([])
    setCostingType([])
    setCurrentCurrencySelected({})
  }
  return (
    <>

      <Button color="primary" className="btn btn-primary"
        onClick={() => resetFilters()}
        disabled={ViewOnlyMode}
        style={{ float: "right", marginBottom: "1rem" }}>
        Reset Filters
        <FontAwesomeIcon
          title="Reset Filters"
          className="align-middle"
          icon={faFilter}
          style={{
            marginLeft: "5px",
          }}
        />
      </Button>
      <Card className="ml-2 mr-2 mb-2" style={{ marginTop: "3rem" }}>
        <CardHeader>
          <Row className="d-flex justify-content-between">
            <Col
              xs="12"
              lg="3"
              md="3"
              sm="12"
              className="align-self-center mt-1 mb-1"
            >
            </Col>
            <Col
              xs="12"
              lg="9"
              md="9"
              sm="12"
              className="align-self-center"
            >
              <Row className="d-flex justify-content-end">
                <Col
                  xs="12"
                  lg="6"
                  md="6"
                  sm="12"
                  className="align-self-center mt-1 mb-1"
                >
                  <Label className="h5">Available Currency</Label>
                  <Select
                    placeholder="Select a Currency..."
                    isDisabled={ViewOnlyMode}
                    options={optionsCurrency()}
                    defaultValue={optionsCurrency().filter(f => f.CurrencyUnit == "USD").length > 0 ?
                      optionsCurrency().filter(f => f.CurrencyUnit == "USD")[0]
                      : []}
                    onChange={(val) => onCurrencyChange(val)}
                  />
                </Col>

              </Row>

              <Row className="d-flex justify-content-end">
                <Col
                  xs="12"
                  lg="6"
                  md="6"
                  sm="12"
                  className="align-self-center mt-1 mb-1">

                </Col>

                <Col
                  xs="12"
                  lg="2"
                  md="2"
                  sm="12"
                  className="align-self-center mt-1 mb-1"
                >
                </Col>
              </Row>
            </Col>
          </Row>
        </CardHeader>
      </Card>
      <ToolkitProvider
        keyField="index"
        data={comparisonData.filter(sv => sv.Type != "Internal Cost")}
        columns={tableColumnsComparisonViewSummary}
      >
        {(props) => (
          <CardBody className="bg-white">
            <BootstrapTable
              id="comparisontable"
              {...props.baseProps}
              bootstrap4
              wrapperClasses="table-responsive"
              bordered={true}
              filter={filterFactory()}
              filterPosition="top"
            />
          </CardBody>
        )}
      </ToolkitProvider>


    </>
  )
    ;
}

export default ComparisonView;