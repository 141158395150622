import React from "react";

function Account() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000">
          <path d="M12 2c2.989 0 5.41 2.462 5.41 5.5a5.514 5.514 0 01-2.762 4.797l5.813 2.845L22 22H2l1.54-6.858 5.813-2.845A5.514 5.514 0 016.59 7.5C6.59 4.462 9.012 2 12 2zm0 11.219l-6.753 3.306L4.466 20h15.067l-.78-3.475L12 13.219zM12 4C10.1 4 8.557 5.567 8.557 7.5S10.1 11 12 11c1.902 0 3.444-1.567 3.444-3.5S13.902 4 12 4z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Account;