import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { toastr } from "react-redux-toastr";
import { useSelector, useDispatch } from "react-redux";
import * as appActions from "../../redux/actions/appActions";
import { mailRequest } from "../../redux/actions/requestsActions";
import {
  handleRequestProps,
  setProfileRequests,
} from "../../redux/actions/requestsActions";
import Spinner from "../../components/Spinner";
import axios from "../../axios-interceptor";
import _ from "lodash";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Input,
  Row,
  Col,
  FormFeedback,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import logo from "../../assets/img/avatars/modal-logo.png"; 

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import ExpandableRows from "./ExpandableRows";

import { getLabel, stringToMulti } from "../../utils/codeLabels";

const RequestViewModal = ({ isOpen, toggle }) => {
  let { requestId } = useParams();
  const dispatch = useDispatch();
  const [request, setRequest] = useState({});
  const [validation, setValidation] = useState({});
  const [infiniteLoad, setInfiniteLoad] = useState(false);

  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const user = useSelector(({ user }) => user.userRecord);
  const loading = useSelector(({ app }) => app.recordloading);
  const requestsList = useSelector(({ requests }) => requests.items);
  const totalItems = useSelector(({ requests }) => requests.totalItems);

  const getNameFromMail = (mail) => {
    let allMails = mail?.split(",");
    if (allMails)
      return allMails
        .map((mail) => {
          return _.head(mail.toLowerCase().split("@")).replaceAll(".", " ");
        })
        .join(", ");
    else return "";
  };

  const fetchMoreData = () => {
    setInfiniteLoad(true);
    if (requestsList?.length >= totalItems) {
      setInfiniteLoad(false);
      dispatch(handleRequestProps("hasMore", false));
      return;
    }
    //todo: api call
    // axios
    //     .get("/requests?limit=20&offset=" + requestsList.length)
    //     .then((res) => {
    //         //console.log("new records here");
    //         //console.log(res);
    //         dispatch(appendRequests(res.data.items, res.data.totalItems));
    //         setInfiniteLoad(false);
    //     })
    //     .catch((err) => {
    //         //console.log(err);
    //     });
  };
  useEffect(() => {
    if ((!requestsList || !requestsList.length) && !requestId) {
      dispatch(setProfileRequests(profile.id));
    }
    dispatch(handleRequestProps("hasMore", false));
  }, []);

  const resetForm = () => {
    setRequest({});
    setValidation({});
  };
  useEffect(() => {
    //console.log("request change", request);
  }, [request]);
  const onChangeHandler = (field, value) => {
    //console.log("request", request);
    //console.log(field, value);
    setRequest({ ...request, [field]: value });
  };

  const invalidate = () => {
    let invalid = false;
    let newObj = {};
    if (!request.RequestType || request.RequestType === "") {
      newObj.RequestType = true;
      invalid = true;
    }
    if (!request.Methodology || request.Methodology === "") {
      newObj.Methodology = true;
      invalid = true;
    }
    if (!request.DateDue || request.DateDue === "") {
      invalid = true;
      newObj.DateDue = true;
    }
    if (!request.InitialNotes || request.InitialNotes === "") {
      invalid = true;
      newObj.InitialNotes = true;
    }
    setValidation(newObj);
    return invalid;
  };
  const createRequest = () => {
    let data = {
      ...request,
      RequestorEmail: user.Email,
      CostingProfileId: profile.id,
    };
    if (!invalidate()) {
      //console.log(data);
      dispatch(appActions.recordLoadStart());
      axios
        .post("/requests", data)
        .then((res) => {
          //console.log(res.data);
          dispatch(mailRequest(res.data.Request.id, "new"));
          dispatch(appActions.recordLoadEnd());
          toastr.success("Request Sent", res.data.message);
          resetForm();
        })
        .catch((err) => {
          //console.log(err);
          dispatch(appActions.recordLoadEnd());
          toastr.error("Send failed", err.data.message);
        });
    } else {
      //console.log("validations failed", validation);
      //console.log(data);
    }
  };

  const requestTypeHandler = (e) => {
    //console.log("in request type handler");
    //console.log(e);
    setRequest({
      ...request,
      RequestType: e.value,
      AgentEmail: e.PrimaryContactEmails,
      CcAgentEmails: e.OtherContactEmails,
    });
  };

  const data = {
    tableColumns: [
      {
        dataField: "id",
        text: "ID",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "5%" };
        },
      },
      {
        dataField: "ProjectId",
        text: "PROJECT ID",
        sort: true,
        // headerStyle: (colum, colIndex) => {
        //     return { width: "12%" };
        // },
        formatter: (cell, row) => (
          <span>{row.CostingProfile?.Project.ProjectId}</span>
        ),
      },
      {
        dataField: "ProjectName",
        text: "PROJECT NAME",
        sort: true,
        formatter: (cell, row) => (
          <span>{row.CostingProfile?.Project.ProjectName}</span>
        ),
      },
      {
        dataField: "RequestType",
        text: "REQUEST TYPE",
        sort: true,
        formatter: (cell, row) => <span>{row.RequestType}</span>,
      },
      {
        dataField: "RequestedBy",
        text: "REQUEST BY",
        sort: true,
        // formatter: (cell, row) => <span>{row.RequestorEmail}</span>,
        formatter: (cell, row) => {
          return row.RequestorEmail ? (
            <span className="capitalize">
              {getNameFromMail(row.RequestorEmail)}
            </span>
          ) : (
            ""
          );
        },
      },
      {
        dataField: "AssignedTo",
        text: "ASSIGNED TO",
        sort: true,
        formatter: (cell, row) => {
          return row.AgentEmail ? (
            <span className="capitalize">
              {getNameFromMail(row.AgentEmail)}
            </span>
          ) : (
            ""
          );
          // if (row.AgentEmail) {
          //     return row.AgentEmail.toLowerCase()
          //         .split("@")[0]
          //         .split(".")

          //         .join(" ");
          // } else {
          //     return "";
          // }
        },
      },
      {
        dataField: "createdAt",
        text: "CREATED DATE",
        sort: true,
        formatter: (cell, row) => (
          <span>{cell ? _.head(cell.split("T")) : ""}</span>
        ),
      },
      {
        dataField: "IsClosed",
        text: "STATUS",
        sort: true,
        formatter: (cell, row) =>
          row.IsClosed ? (
            <span style={{ color: "grey", fontWeight: "bold" }}>
              {"Closed"}
            </span>
          ) : row.AgentEmail ? (
            <span style={{ color: "#00b700", fontWeight: "bold" }}>
              {"Open"}
            </span>
          ) : (
            <span style={{ color: "#e6e631", fontWeight: "bold" }}>
              {"Unassigned"}
            </span>
          ),
      },
    ],
    tableData: requestsList && requestsList.length ? requestsList : [],
  };
  var imgStyle = {
    width: "150px",
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered={true}
      fade={true}
      backdrop={"static"}
      unmountOnClose={true}
      className="modal-xl"
    >
      <ModalHeader toggle={toggle}>
      {/* <img src={logo}  className="modallogo"/> */}
        
      </ModalHeader>
      <div>
        <span className="modaltitle">
        Profile Requests
        </span>
      </div>
      <ModalBody>
        {loading ? (
          <Spinner></Spinner>
        ) : (
          <ExpandableRows {...data}></ExpandableRows>
        )}
      </ModalBody>
      {/* <ModalFooter>
        <div className="d-flex justify-content-end">
          <Button
            className="mr-2"
            color="secondary"
            //   onClick={() => toggle()}
          >
            <FontAwesomeIcon
              title="Upload Files to Costings Folder"
              icon={faPaperclip}
              fixedWidth
              onClick={() => {
                // window.open(
                //   "https://drive.google.com/drive/folders/" +
                //     profile.Project.CostingsFolderId
                // );
              }}
            />
          </Button>
          <Button
            className="mr-2"
            color="secondary"
            onClick={() => {
              // resetForm();
              toggle();
            }}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            // onClick={() => createRequest()}
            disabled={loading}
          >
            Send
            {loading ? <Spinner size="small" /> : null}
          </Button>
        </div>
      </ModalFooter> */}
    </Modal>
  );
};

export default RequestViewModal;
