import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Row, Button, Col } from "reactstrap";
import _ from "lodash";
import { getLabel } from "../../utils/codeLabels";
import Spinner from "../../components/Spinner";
import { saveCostingProfile, updateProfile } from "../../redux/actions/currentCostingActions";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";


const ReviewSpecifications = ({ profile, indxFC, methodology, indxSM,disableforCS }) => {
    // const profile = useSelector(
    //     ({ currentCosting }) => currentCosting.currentCostingProfile
    // );
    const [rfqData, setRfqData] = useState({});
    const [rfqDataActual, setRfqDataActual] = useState({});
    const [rfqFieldsActualStatus, setRFQActualFieldsStatus] = useState({});

    const app = useSelector(({ app }) => app);

    const dispatch = useDispatch()
    const userRecord = useSelector(({ user }) => user.userRecord);

    useEffect(() => {
        if (profile && !Object.keys(rfqData).length && profile.CountrySpecs) {
            let finalData = {};
            let finalActualData = {};
            profile.CountrySpecs.map((cs) => {
                cs.MethodologySpecs.filter((ms) => !ms.NotApplicable).map((ms) => {
                    if (!finalData[cs.CountryCode]) {
                        finalData[cs.CountryCode] = {};
                        finalActualData[cs.CountryCode] = {};
                    }
                    if (!finalData[cs.CountryCode][ms.Code]) {
                        finalData[cs.CountryCode][ms.Code] = {};
                        finalActualData[cs.CountryCode][ms.Code] = {};
                    }
                    finalData[cs.CountryCode][ms.Code] = ms.RFQData ? ms.RFQData : {};
                    finalActualData[cs.CountryCode][ms.Code] = ms.RFQDataActual ? ms.RFQDataActual : {};
                });
            });
            setRfqData(finalData);
            setRfqDataActual(finalActualData);
            canRenderSection()
        }
    }, [profile]);

    const getProperty = (schema, prop) => {
        if (schema.properties[prop]) return schema.properties[prop];
        else {
            let reqVal = "";
            if (schema.dependencies) {
                Object.keys(schema.dependencies).map((dep) => {
                    schema.dependencies[dep].oneOf.map((oo) => {
                        if (oo.properties[prop] && oo.properties[prop].title) {
                            reqVal = oo.properties[prop];
                        }
                    });
                });
            }
            return reqVal;
        }
    };
    const getPropertyStatus = (prop) => {
        let status = false
        if (rfqData) {
            Object.keys(rfqData).map(CountryCode => {
                Object.keys(rfqData[CountryCode]).map(Code => {
                    if (rfqData[CountryCode][Code][prop])
                        status = true
                })
            })
        }
        return status
    }
    const saveReviewSpecifications = () => {
        let currentProfile = { ...profile }
        currentProfile.CountrySpecs = currentProfile.CountrySpecs.map(cs => {
            cs.MethodologySpecs = cs.MethodologySpecs.map(ms => {
                if (rfqDataActual[cs.CountryCode] && rfqDataActual[cs.CountryCode][ms.Code])
                    ms.RFQDataActual = rfqDataActual[cs.CountryCode][ms.Code]
                return { ...ms }
            })
            return { ...cs }
        })
        // dispatch(saveCostingProfile(currentProfile))
        console.log(currentProfile);
    }
    const updateActualValue = (CountryCode, msCode, rfq, e) => {
        let _rfqDataActual = { ...rfqDataActual }

        if (msCode) {
            if (!_rfqDataActual[CountryCode])
                _rfqDataActual[CountryCode] = {}
            if (!_rfqDataActual[CountryCode][msCode])
                _rfqDataActual[CountryCode][msCode] = {}
            _rfqDataActual[CountryCode][msCode] = { ..._rfqDataActual[CountryCode][msCode], [rfq]: e.target.value }
        } else if (CountryCode == "OtherNotes") {
            if (!_rfqDataActual[profile.CountrySpecs[indxFC].CountryCode])
                _rfqDataActual[profile.CountrySpecs[indxFC].CountryCode] = {}
            if (!methodology.NotApplicable) {
                if (!_rfqDataActual[profile.CountrySpecs[indxFC].CountryCode][methodology.Code])
                    _rfqDataActual[profile.CountrySpecs[indxFC].CountryCode][methodology.Code] = {}

                _rfqDataActual[profile.CountrySpecs[indxFC].CountryCode][methodology.Code]["OtherNotes"] = e.target.value
            }

        }
        setRfqDataActual({ ..._rfqDataActual });
        let currentProfile = { ...profile }

        currentProfile.CountrySpecs = currentProfile.CountrySpecs.map(cs => {
            cs.MethodologySpecs = cs.MethodologySpecs.map(ms => {
                if (_rfqDataActual[cs.CountryCode] && _rfqDataActual[cs.CountryCode][ms.Code])
                    ms.RFQDataActual = _rfqDataActual[cs.CountryCode][ms.Code]
                return { ...ms }
            })
            return { ...cs }
        })
        dispatch(updateProfile(currentProfile));

    }
    const canRenderSection = () => {
        if (profile && profile.CountrySpecs && _.head(profile.CountrySpecs).MethodologySpecs) {
            let reqMethodologySpecs = _.head(profile.CountrySpecs).MethodologySpecs
            let finalRFQFields = {}
            let mainProp = ""
            methodology.RFQSchema?.order.map(rcma => {
                if (rcma != "*") {
                    let currentProperty = getProperty(methodology.RFQSchema, rcma)
                    if (currentProperty.sectionTitle) {
                        mainProp = currentProperty.sectionTitle
                    }
                    if (!finalRFQFields[mainProp])
                        finalRFQFields[mainProp] = []
                    if (!_.includes(finalRFQFields[mainProp], rcma))
                        finalRFQFields[mainProp].push(rcma)

                }
            })

            let allRfqData = []
            profile.CountrySpecs.map(cs => {
                if (cs.MethodologySpecs)
                    cs.MethodologySpecs.map(ms => {
                        if (ms.RFQData)
                            allRfqData.push(ms.RFQData)
                    })
            })
            let actualFieldsToDisplay = {}
            Object.keys(finalRFQFields).map(seckey => {
                let render = false
                finalRFQFields[seckey].map(rfqKey => {
                    if (allRfqData.filter(ard => ard[rfqKey]).length)
                        render = true
                })
                actualFieldsToDisplay[seckey] = render
            })
            setRFQActualFieldsStatus(actualFieldsToDisplay)
        }
    }
    const getOtherSpecificationNotes = () => {
        let otherNotes = "";

        // Object.keys(rfqDataActual).map(rda => {
        //     Object.keys(rfqDataActual[rda]).map(rdaMeth => {
        //         otherNotes = rfqDataActual[rda][rdaMeth]["OtherNotes"]
        //     })
        // })
        try {
            otherNotes = rfqDataActual[profile.CountrySpecs[indxFC].CountryCode][methodology.Code]["OtherNotes"];
        } catch (err) {
        }
        return otherNotes
    }
    return <>
        <Table responsive hover striped bordered={true} size="sm">
            <tbody>
                <>
                    <tr>
                        <th>
                            <h5 className="text-uppercase mb-0">
                                {methodology.Label}
                            </h5>
                        </th>
                        
                            <th>
                                {getLabel(
                                    "FieldingCountriesOptions",
                                    profile.CountrySpecs[indxFC].CountryCode
                                )}<br></br>
                                Commissioned Value
                            </th>
                            <th>
                                {getLabel(
                                    "FieldingCountriesOptions",
                                    profile.CountrySpecs[indxFC].CountryCode
                                )}<br></br>
                                Actual Value
                            </th>

                    </tr>
                    {!methodology.RFQSchemaNA ? (
                        methodology.RFQSchema.order.map((rfq) => {
                            let property = getProperty(methodology.RFQSchema, rfq);
                            let showCurrentProperty = getPropertyStatus(rfq)
                            let country = profile.CountrySpecs[indxFC]
                            return (property) ? (
                                <>
                                    {(property.isNewSection && rfqFieldsActualStatus[property.sectionTitle]) ? (
                                        <tr>
                                            <th>{property.sectionTitle}</th>

                                            <>
                                                <th>
                                                  
                                                </th>
                                                <th>
                                                 
                                                </th>
                                            </>

                                        </tr>
                                    ) : null}
                                    {showCurrentProperty ? <tr>
                                        <td>{property.title}</td>
                                        <><td>
                                            {rfqData[country.CountryCode] &&
                                                rfqData[country.CountryCode][
                                                methodology.Code
                                                ]
                                                ? Array.isArray(
                                                    rfqData[country.CountryCode][
                                                    methodology.Code
                                                    ][rfq]
                                                )
                                                    ? rfqData[country.CountryCode][
                                                        methodology.Code
                                                    ][rfq].length
                                                        ?
                                                        rfqData[country.CountryCode][
                                                            methodology.Code
                                                        ][rfq].map(xy => xy.value).join()
                                                        : "-"
                                                    : typeof rfqData[
                                                        country.CountryCode
                                                    ][methodology.Code][rfq] == "boolean"
                                                        ? rfqData[country.CountryCode][
                                                            methodology.Code
                                                        ][rfq]
                                                            ? "Yes"
                                                            : "No"
                                                        : rfqData[country.CountryCode][
                                                            methodology.Code
                                                        ][rfq]
                                                            ? rfqData[country.CountryCode][
                                                            methodology.Code
                                                            ][rfq]
                                                            : "-"
                                                : "-"}
                                        </td>
                                            <td>
                                                {property.type == "string" && property.enum && property.enum.length ?
                                                    <SelectWrapper
                                                        defaultValue={rfqDataActual[country.CountryCode]
                                                            && rfqDataActual[country.CountryCode][methodology.Code]
                                                            && rfqDataActual[country.CountryCode][methodology.Code][rfq]
                                                            ? rfqDataActual[country.CountryCode][methodology.Code][rfq] : null}
                                                        disabled={!userRecord.IsOpsProjectManager||disableforCS}
                                                        readOnly={!userRecord.IsOpsProjectManager}
                                                        onChange={e => {
                                                            updateActualValue(country.CountryCode, methodology.Code, rfq, e)
                                                        }}
                                                    >
                                                        <option value="NA">Select Option</option>
                                                        {property.enum.map(propopt => <option value={propopt}>{propopt}</option>)}
                                                    </SelectWrapper> : <input className="form-control"
                                                        disabled={!userRecord.IsOpsProjectManager||disableforCS}
                                                        readOnly={!userRecord.IsOpsProjectManager}
                                                        onChange={e => {
                                                            updateActualValue(country.CountryCode, methodology.Code, rfq, e)
                                                        }}
                                                        defaultValue={
                                                            rfqDataActual[country.CountryCode]
                                                                && rfqDataActual[country.CountryCode][methodology.Code]
                                                                && rfqDataActual[country.CountryCode][methodology.Code][rfq]
                                                                ? rfqDataActual[country.CountryCode][methodology.Code][rfq] : null
                                                        } />
                                                }
                                            </td>
                                        </>

                                    </tr> : null}
                                </>
                            ) : null;
                        })
                    ) : (
                        <tr>
                            <td>{methodology.Label}</td>
                            <td>
                                No data is available for this methodology.
                            </td>
                        </tr>
                    )}
                </>

            </tbody>
        </Table>
        <hr></hr>
        <Col>
            <Row>
                <strong>Other specification related notes</strong>
            </Row>
            <Row>
                <textarea
                    id="OtherNotes"
                    value={
                        getOtherSpecificationNotes()
                        // rfqDataActual && rfqDataActual["OtherNotes"] ? rfqDataActual["OtherNotes"] : ""
                    }
                    disabled={disableforCS}
                    onChange={e => {
                        updateActualValue("OtherNotes", null, null, e)
                    }}
                    className="form-control other-notes-textarea"
                ></textarea>
            </Row>
        </Col>
        {/* <Row className="justify-content-end mt-2 ml-0 mr-0">
            <Button color="primary"
                disabled={!userRecord.IsOpsProjectManager}
                onClick={saveReviewSpecifications}>
                Save {app.recordloading ? (
                    <Spinner size="small" color="#495057" />
                ) : null}
            </Button>
        </Row> */}
    </>
}

export default ReviewSpecifications