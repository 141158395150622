import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "@alexcambose/react-infinite-scroll";
import axios from "../../axios-interceptor";
import Navbar from "../../components/Navbar";
import DashboardLayout from "../../layouts/Dashboard";
import RecordsSpinner from "../../components/RecordsSpinner";
import RecordsBadge from "../../components/RecordsBadge";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { getLabel } from "../../utils/codeLabels";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
  Label,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Card,
  Row,
  Col,
} from "reactstrap";
import _ from "lodash";
import { pageLoadEnd, pageLoadStart } from "../../redux/actions/appActions";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import projectsReducer from "../../redux/reducers/projectsReducer";

import logo from "../../assets/img/avatars/modal-logo.png";
var imgStyle = {
  width: "150px",
};
const OpsReport = () => {
  const dispatch = useDispatch();
  const [infiniteLoad, setInfiniteLoad] = useState(false);
  const comissioningCountries =
    useSelector(({ codeLabels }) => codeLabels.CommissioningCountriesOptions) ||
    [];
  const fieldingCountries =
    useSelector(({ codeLabels }) => codeLabels.FieldingCountriesOptions) || [];
  const verticals =
    useSelector(({ codeLabels }) => codeLabels.VerticalOptions) || [];
  const methodology =
    useSelector(({ codeLabels }) => codeLabels.MethodologyOptions) || [];
  const [record, setRecord] = useState({
    records: [],
    hasMore: true,
    totalItems: null,
  });
  const [selectColumns, openSelectColumns] = useState(false);
  const [allColumns] = useState([
    "ID",
    "Project ID",
    "Project Name",
    "Microsoft Dynamics Number",
    "WBS Number",
    "Opportunity Number",
    "Account Name",
    "Microsoft Dynamics Stage",
    "Project Manager Email",
    "Commissioning Country",
    "Fielding Countries",
    "Industry",
    "Practice Area",
    "Product Description",
    "Microsoft Dynamics Probability",
    "Methodology",
    "Sub-Methodology",
    "MCP",
    "Syndicated",
    "Project Status",
    "Status Notes",
    "Tracker",
    "Total Waves",
    "Profile ID",
    "Profile CreatedBy",
    "Profile Status",
    "Profile Name",
    "Currency",
    "Price To Client (USD)",
    "TotalExternalOperationsCost (USD)",
    "TotalInternalCommercialCost (USD)",
    "TotalCostsRaw (USD)",
    "Price To Client (LCY)",
    "TotalExternalOperationsCost (LCY)",
    "TotalInternalCommercialCost (LCY)",
    "TotalCostsRaw (LCY)",
    "TotalExtCommConsultant",
    "TotalExtCommTnE",
    "TotalExtCommOtherCS",
    "TotalExternalCommercialCost",
    "TotalIntOpsProgramming",
    "TotalIntOpsProjectManagement",
    "TotalIntOpsDPCA",
    "TotalIntOpsOtherOps",
    "TotalInternalOperationsCost",
    "TotalExtOpsInterviewer",
    "TotalExtOpsTnE",
    "TotalExtOpsResources",
    "TotalExtOpsIncentives",
    "TotalExtOpsConsultant",
    "TotalExtOpsVenueHire",
    "TotalExtOpsPrinting",
    "TotalExtOpsShipping",
    "TotalExtOpsOtherOps",
    "TotalExtOpsMCP",
    "CostExtOpsOtherTaxVAT",
    // "OutOfPocket",
    "ApprovalJustification",
    "CM%"
  ]);
  const [selectedDisplayColumns, setSelectedDisplayColumns] = useState(
    allColumns
  );
  const [selectedTempDisplayColumns, setSelectedTempDisplayColumns] = useState(
    allColumns
  );
  const [resetDisplayCols, setResetDisplayCols] = useState([]);

  useEffect(() => {
    //console.log("initial call");
    dispatch(pageLoadStart());
    axios
      .post("/reports/ops?limit=30")
      .then((res) => {
        dispatch(pageLoadEnd());
        //console.log(res);
        setRecord({
          ...record,
          records: res.data.items,
          totalItems: res.data.totalItems,
        }); // change records to whatever array is named
      })
      .catch((err) => {
        // toastr.error("Loading Failed", err.data.message);
      });
  }, []);
  const loadAllOps = () => {
    dispatch(pageLoadStart());
    axios
      .post("/reports/ops")
      .then((res) => {
        dispatch(pageLoadEnd());
        //console.log(res);
        setRecord({
          ...record,
          records: res.data.items,
          totalItems: res.data.totalItems,
        });
      })
      .catch((err) => {
        dispatch(pageLoadEnd());
        toastr.error("Loading Failed", err?.data?.message);
      });
  };
  useEffect(() => {
    //console.log("record has changed", record);
  }, [record]);

  const fetchMoreData = () => {
    //console.log("fetch more called");
    //console.log(record);
    if (record.records.length >= record.totalItems) {
      //console.log("set hasMore false");
      setRecord({ ...record, hasMore: false });
      return;
    }
    setInfiniteLoad(true);
    axios
      .post(
        "/reports/ops?limit=30&offset=" + record.records.length
      )
      .then((res) => {
        //console.log(res);
        //console.log(
        //   "new array",
        //   update(record.records, { $push: res.data.items })
        // );
        setRecord({
          ...record,
          records: update(record.records, { $push: res.data.items }),
          totalItems: res.data.totalItems,
        });
        setInfiniteLoad(false);
      })
      .catch((err) => {
        //console.log(err);
        // toastr.error("Loading Failed", err.data.message);
        setInfiniteLoad(false);
      });
  };
  const getCommaSeperatedData = (array, value) => {
    let output = [];
    if (value) {
      let list = value.split(",");
      for (let i of list) {
        let a = array.find((data) => data.Code === i);
        if (a) output.push(a.Label);
      }
      return output.join();
    } else return "";
  };
  const getCommissionCountries = (value) => {
    if (value) {
      let a = comissioningCountries.find((data) => data.Code === value);
      if (a) return a.Label;
      return "";
    } else return "";
  };
  const getContractDetails = (data, value) => {
    let output = [];
    if (data) {
      switch (value) {
        case "industry":
          for (let contract of data) {
            output.push(contract.Industry);
          }
          return output.join(", ");
        case "practiceArea":
          for (let contract of data) {
            for (let oppLine of contract.opportunityLineItemDetails) {
              output.push(oppLine.PracticeArea);
            }
          }
          return output.join(", ");
        case "opNumber":
          for (let contract of data) {
            output.push(contract.OpportunityNumber);
          }
          return output.join(", ");
        case "AccountName":
          for (let contract of data) {
            if (contract.AccountName && contract.OpportunityNumber)
              output.push(`${contract.AccountName}(${contract.OpportunityNumber})`);
          }
          return output.join(", ");
        case "SfStage":
          for (let contract of data) {
            if (contract.Stage && contract.OpportunityNumber)
              output.push(`${contract.Stage}(${contract.OpportunityNumber})`);
          }
          return output.join(", ");
        case "prodDesc":
          for (let contract of data) {
            for (let oppLine of contract.opportunityLineItemDetails) {
              output.push(oppLine.ProductDescription);
            }
          }
          return output.join(", ");
        // case "WBSNumber":
        //   for (let contract of data) {
        //     for (let oppLine of contract.opportunityLineItemDetails) {
        //       output.push(oppLine.WBSNumber);
        //     }
        //   }
        //   return output.join(",");
        case "method":
          return getCommaSeperatedData(methodology, data);
        case "sfProb":
          let res;
          for (let contract of data) {
            res = contract.Probability;
          }
          return res;
        default:
          return "";
      }
    } else return "";
  };
  const getOriginalPriceValue = (profile, cost) => {
    let values = profile.CostInputCurrency.split("-");
    let currencyUnit = _.last(values);
    let countryCode = _.head(values);
    if(!profile.ProfileSetting.CurrenciesData)
    return null;
    let finalCurrency = _.head(
      profile.ProfileSetting.CurrenciesData.filter(
        (cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
      )
    );
    let finalValue = "";
    if (profile[cost]) {
      finalValue = `${_.round(
        profile[cost] * finalCurrency.ConversionRateToLocal,
        2
      )} ${currencyUnit}`;
    }

    return finalValue;
  };
  const formattedDate = (date, flag) => {
    if (flag === false) return "not required";
    if (date) return date.substring(0, 10);
    else return "not available";
  };
  const formattedPrec = (data) => {
    if (data) return (data * 100).toFixed(2) + "%";
    else return "not available";
  };
  const finalColumns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      formatter: (cell, row) => {
        return <span>{row.id}</span>;
      },
      filter: textFilter(),
    },
    {
      dataField: "ProjectId",
      text: "Project ID",
      sort: true,
      formatter: (cell, row) => {
        return <span>{row.Project?.ProjectId}</span>;
      },
      filter: textFilter(),
    },
    {
      dataField: "ProjectName",
      text: "Project Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "SFNumber",
      text: "Microsoft Dynamics Number",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "WBSNumber",
      text: "WBS Number",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "OpportunityNumber",
      text: "Opportunity Number",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "AccountNameFormatted",
      text: "Account Name",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "SfStageFormatted",
      text: "Microsoft Dynamics Stage",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "ProjectManagerEmail",
      text: "Project Manager Email",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CommissioningCountryFormatted",
      text: "Commissioning Country",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "FieldingCountriesFormatted",
      text: "Fielding Countries",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "Industry",
      text: "Industry",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "PracticeArea",
      text: "Practice Area",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "ProductDescription",
      text: "Product Description",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "SFProbability",
      text: "Microsoft Dynamics Probability",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "MethodologyFormatted",
      text: "Methodology",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "SubMethodologyFormatted",
      text: "Sub-Methodology",
      sort: true,

      filter: textFilter(),
    },

    {
      dataField: "IsMultiCountryFormatted",
      text: "MCP",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "IsSyndicatedFormatted",
      text: "Syndicated",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "ProjectStatusName",
      text: "Project Status",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "NotesProjectStatusFormatted",
      text: "Status Notes",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "IsTrackerFormatted",
      text: "Tracker",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "NumberOfWavesFormatted",
      text: "Total Waves",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "id",
      text: "Profile Id",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CreatedBy",
      text: "Profile CreatedBy",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "ProfileStatusName",
      text: "Profile Status",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "ProfileName",
      text: "Profile Name",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostInputCurrency",
      text: "Currency",
      sort: true,
      formatter: (cell, row) => {
        let values = row.CostInputCurrency.split("-");
        let currencyUnit = _.last(values);
        return currencyUnit ? currencyUnit : "USD"
      },
      csvFormatter: (cell, row) => {
        let values = row.CostInputCurrency.split("-");
        let currencyUnit = _.last(values);
        return currencyUnit ? currencyUnit : "USD"
      },
      filter: textFilter(),
    },
    {
      dataField: "PriceToClientFormatted",
      text: "Price To Client (USD)",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "PriceToClientLocalFormatted",
      text: "Price To Client (LCY)",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostTotalExternalOperationsFormatted",
      text: "TotalExternalOperationsCost (USD)",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "CostTotalExternalOperationsOriginalFormatted",
      text: "TotalExternalOperationsCost (LCY)",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostTotalInternalCommercialFormatted",
      text: "TotalInternalCommercialCost (USD)",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostTotalInternalCommercialLocalFormatted",
      text: "TotalInternalCommercialCost (LCY)",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "TotalCostsRawFormatted",
      text: "TotalCostsRaw (USD)",
      sort: true,

      filter: textFilter(),
    },

    {
      dataField: "TotalCostsRawLocalFormatted",
      text: "TotalCostsRaw (LCY)",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostExtCommConsultantFormatted",
      text: "TotalExtCommConsultant",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostExtCommTEFormatted",
      text: "TotalExtCommTnE",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostExtCommOthersFormatted",
      text: "TotalExtCommOtherCS",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostTotalExternalCommercialFormatted",
      text: "TotalExternalCommercialCost",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostIntOpsProgrammingFormatted",
      text: "TotalIntOpsProgramming",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "TotalIntOpsProjectManagementFormatted",
      text: "TotalIntOpsProjectManagement",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostIntOpsDPCodingAnalysisFormatted",
      text: "TotalIntOpsDPCA",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostIntOpsOthersFormatted",
      text: "TotalIntOpsOtherOps",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostTotalInternalOperationsFormatted",
      text: "TotalInternalOperationsCost",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostExtOpsInterviewersFormatted",
      text: "TotalExtOpsInterviewer",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostExtOpsTEFormatted",
      text: "TotalExtOpsTnE",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostExtOpsDCQCDPSPFormatted",
      text: "TotalExtOpsResources",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostExtOpsIncentivesFormatted",
      text: "TotalExtOpsIncentives",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostExtOpsConsultantVendorFormatted",
      text: "TotalExtOpsConsultant",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostExtOpsVenueHireRecruitmentFormatted",
      text: "TotalExtOpsVenueHire",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostExtOpsPrintingStationeryFormatted",
      text: "TotalExtOpsPrinting",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostExtOpsFreightShippingFormatted",
      text: "TotalExtOpsShipping",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostExtOpsOthersFormatted",
      text: "TotalExtOpsOtherOps",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "CostTotalExternalOperationsIncMCPMarginFormatted",
      text: "TotalExtOpsMCP",
      sort: true,

      filter: textFilter(),
    },
    // {
    //   dataField: "OutOfPocketCostPercentFormatted",
    //   text: "OutOfPocket",
    //   sort: true,

    //   filter: textFilter(),
    // },
    {
      dataField: "ApprovalNotes",
      text: "ApprovalJustification",
      sort: true,

      filter: textFilter(),
    },
    {
      dataField: "ContributionMarginPercentFormatted",
      text: "CM%",
      sort: true,

      filter: textFilter(),
    }
  ];
  const [tableColumns, setTableColumns] = useState(finalColumns);
  useEffect(() => {
    if (selectedDisplayColumns) {
      dispatch(pageLoadStart());
      let columns = finalColumns
        .filter((col) => _.includes(selectedDisplayColumns, col.text))
        .map((col) => {
          return { ...col };
        });
      setTableColumns([]);
      setTimeout(() => {
        setTableColumns([...columns]);
        dispatch(pageLoadEnd());
      });
    }
  }, [selectedDisplayColumns]);
  const applyLabels = (projects) => {
    let finalProjects = projects.map((proj) => {
      proj.ProductDescription =
        proj &&
          proj.Project &&
          proj.Project.ContractDetails
          ? getContractDetails(
            proj.Project.ContractDetails,
            "prodDesc"
          )
          : "";

      proj.MethodologyFormatted =
        proj && proj.Methodology
          ? getContractDetails(proj.Methodology, "method")
          : "";

      proj.SubMethodologyFormatted = proj.SubMethodology?.split(",")
        .map((item) => {
          return getLabel("SubMethodologyOptions", item);
        })
        .join(', ');



      proj.WBSNumber =
        // proj.CostingProfile &&
        // proj.CostingProfile.Project &&
        // proj.CostingProfile.Project.ContractDetails
        //   ? getContractDetails(
        //       proj.CostingProfile.Project.ContractDetails,
        //       "WBSNumber"
        //     )
        //   : "";

        proj.Project?.ContractDetails?.map((contract) => {
          return contract?.opportunityLineItemDetails
            .map((item) => {
              return item.WBSNumber;
            })
            .filter(
              (item, i, array) =>
                item !== null && item !== "" && array.indexOf(item) === i
            )
            .join(",\n");
        })
          .join(",\n")
          .split(",\n")
          .filter(
            (item, i, array) =>
              item !== null && item !== "" && array.indexOf(item) === i
          )
          .join(",\n");
      proj.OpportunityNumber =
        proj &&
          proj.Project &&
          proj.Project.ContractDetails
          ? getContractDetails(
            proj.Project.ContractDetails,
            "opNumber"
          )
          : "";
      proj.SFNumber = "";
      proj.AccountNameFormatted =
        proj &&
          proj.Project &&
          proj.Project.ContractDetails
          ? getContractDetails(
            proj.Project.ContractDetails,
            "AccountName"
          )
          : "";
      proj.SfStageFormatted =
        proj &&
          proj.Project &&
          proj.Project.ContractDetails
          ? getContractDetails(
            proj.Project.ContractDetails,
            "SfStage"
          )
          : "";
      proj.Industry =
        proj &&
          proj.Project &&
          proj.Project.ContractDetails
          ? getContractDetails(
            proj.Project.ContractDetails,
            "industry"
          )
          : "";
      proj.PracticeArea =
        proj &&
          proj.Project &&
          proj.Project.ContractDetails
          ? getContractDetails(
            proj.Project.ContractDetails,
            "practiceArea"
          )
          : "";
      proj.SFProbability =
        proj &&
          proj.Project &&
          proj.Project.ContractDetails
          ? getContractDetails(
            proj.Project.ContractDetails,
            "sfProb"
          )
          : "";
      proj.IsMultiCountryFormatted = proj.IsMultiCountry ? "Yes" : "No";
      proj.IsTrackerFormatted = proj.IsTracker ? "Yes" : "No";
      proj.IsSyndicatedFormatted = proj.Project?.IsSyndicated
        ? "Yes"
        : "No";
      proj.NumberOfWavesFormatted = proj.IsTracker
        ? proj.NumberOfWaves
        : "NA";
      proj.PriceToClientFormatted =
        proj && proj.PriceToClient
          ? proj.PriceToClient.toFixed(2)
          : "";
      // let totalLocalMCP = 0;

      // proj.CostingProfile.CountrySpecs.map(cs => cs.MethodologySpecs.map(ms => {
      //   if (ms.CostsData)
      //     totalLocalMCP = totalLocalMCP + (ms.CostsData.MCPLocalCountryExtra ? ms.CostsData.MCPLocalCountryExtra : 0)
      // }))
      proj.CostTotalExternalOperationsFormatted =
        proj && proj.CostTotalExternalOperations
          ? proj.CostTotalExternalOperations.toFixed(2)
          : "";

      proj.CostTotalExternalOperationsOriginalFormatted =
        proj && proj.CostTotalExternalOperations
          ? getOriginalPriceValue(
            proj,
            "CostTotalExternalOperations"
          )
          : "";

      proj.CostTotalExternalOperationsIncMCPMarginFormatted =
        proj && proj.CostTotalExternalOperationsIncMCPMargin
          ? proj.CostTotalExternalOperationsIncMCPMargin.toFixed(2)
          : "";

      proj.OutOfPocketCostPercentFormatted =
        proj && proj.OutOfPocketCostPercent
          ? formattedPrec(proj.OutOfPocketCostPercent)
          : "";
      proj.OutOfPocketCostIncMCPPercent =
        proj && proj.OutOfPocketCostIncMCPPercent
          ? formattedPrec(proj.OutOfPocketCostIncMCPPercent)
          : "";

      proj.ContributionMarginPercentFormatted =
        proj && proj.ContributionMarginPercent
          ? formattedPrec(proj.ContributionMarginPercent)
          : "";

      proj.TotalCostsRawFormatted =
        proj && proj.TotalCostsRaw
          ? proj.TotalCostsRaw.toFixed(2)
          : "";
      proj.TotalCostsRawLocalFormatted =
        proj && proj.TotalCostsRaw
          ? getOriginalPriceValue(proj, "TotalCostsRaw")
          : "";

      proj.PriceToClientLocalFormatted =
        proj && proj.PriceToClient
          ? getOriginalPriceValue(proj, "PriceToClient")
          : "";

      proj.CostTotalInternalCommercialLocalFormatted =
        proj && proj.CostTotalInternalCommercial
          ? getOriginalPriceValue(proj, "CostTotalInternalCommercial")
          : "";
      proj.CostTotalInternalCommercialFormatted =
        proj && proj.CostTotalInternalCommercial
          ? proj.CostTotalInternalCommercial.toFixed(2)
          : "";

      proj.CostExtCommConsultantFormatted =
        proj && proj.CostExtCommConsultant
          ? proj.CostExtCommConsultant.toFixed(2)
          : "";

      proj.CostExtCommTEFormatted =
        proj && proj.CostExtCommTE
          ? proj.CostExtCommTE.toFixed(2)
          : "";

      proj.CostExtCommOthersFormatted =
        proj && proj.CostExtCommOthers
          ? proj.CostExtCommOthers.toFixed(2)
          : "";

      proj.CostTotalExternalCommercialFormatted =
        proj && proj.CostTotalExternalCommercial
          ? proj.CostTotalExternalCommercial.toFixed(2)
          : "";

      proj.CostIntOpsProgrammingFormatted =
        proj && proj.CostIntOpsProgramming
          ? proj.CostIntOpsProgramming.toFixed(2)
          : "";

      proj.TotalIntOpsProjectManagementFormatted =
        proj && proj.TotalIntOpsProjectManagement
          ? proj.TotalIntOpsProjectManagement.toFixed(2)
          : "";

      proj.CostIntOpsDPCodingAnalysisFormatted =
        proj && proj.CostIntOpsDPCodingAnalysis
          ? proj.CostIntOpsDPCodingAnalysis.toFixed(2)
          : "";

      proj.CostIntOpsOthersFormatted =
        proj && proj.CostIntOpsOthers
          ? proj.CostIntOpsOthers.toFixed(2)
          : "";

      proj.CostTotalInternalOperationsFormatted =
        proj && proj.CostTotalInternalOperations
          ? proj.CostTotalInternalOperations.toFixed(2)
          : "";

      proj.CostExtOpsInterviewersFormatted =
        proj && proj.CostExtOpsInterviewers
          ? proj.CostExtOpsInterviewers.toFixed(2)
          : "";

      proj.CostExtOpsTEFormatted =
        proj && proj.CostExtOpsTE
          ? proj.CostExtOpsTE.toFixed(2)
          : "";

      proj.CostExtOpsDCQCDPSPFormatted =
        proj && proj.CostExtOpsDCQCDPSP
          ? proj.CostExtOpsDCQCDPSP.toFixed(2)
          : "";

      proj.CostExtOpsIncentivesFormatted =
        proj && proj.CostExtOpsIncentives
          ? proj.CostExtOpsIncentives.toFixed(2)
          : "";

      proj.CostExtOpsConsultantVendorFormatted =
        proj && proj.CostExtOpsConsultantVendor
          ? proj.CostExtOpsConsultantVendor.toFixed(2)
          : "";

      proj.CostExtOpsVenueHireRecruitmentFormatted =
        proj && proj.CostExtOpsVenueHireRecruitment
          ? proj.CostExtOpsVenueHireRecruitment.toFixed(2)
          : "";

      proj.CostExtOpsPrintingStationeryFormatted =
        proj && proj.CostExtOpsPrintingStationery
          ? proj.CostExtOpsPrintingStationery.toFixed(2)
          : "";

      proj.CostExtOpsFreightShippingFormatted =
        proj && proj.CostExtOpsFreightShipping
          ? proj.CostExtOpsFreightShipping.toFixed(2)
          : "";

      proj.CostExtOpsOthersFormatted =
        proj && proj.CostExtOpsOthers
          ? proj.CostExtOpsOthers.toFixed(2)
          : "";

      proj.ContributionMarginFormatted =
        proj && proj.ContributionMargin
          ? proj.ContributionMargin.toFixed(2)
          : "";

      proj.NotesProjectStatusFormatted =
        projectsReducer.Project?.NotesProjectStatus;
      proj.ProjectStatusName = getLabel(
        "ProjectStatusOptions",
        proj.Project?.ProjectStatus
      );
      proj.ProfileStatusName = getLabel(
        "CostingStatusOptions",
        proj.ProfileStatus
      );
      proj.FieldingCountriesFormatted =
        proj && proj.FieldingCountries
          ? getCommaSeperatedData(
            fieldingCountries,
            proj.FieldingCountries
          )
          : "";
      proj.CommissioningCountryFormatted =
        proj &&
          proj.Project &&
          proj.Project.CommissioningCountry
          ? getCommissionCountries(
            proj.Project.CommissioningCountry
          )
          : "";
      proj.ProjectName = proj.Project?.ProjectName;
      proj.ProjectId = proj.Project?.ProjectId;
      proj.ResearchType = proj.ResearchType;
      proj.ProfileName = proj.ProfileName;
      proj.ProjectManagerEmail = proj.ProjectManagerEmail;
      return proj;
    });
    return finalProjects;
  };
  const data = {
    tableColumns: [...tableColumns],
    tableData: applyLabels(record.records),
  };

  const ExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-secondary mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  return (
    <>
      <DashboardLayout
        navbar={<Navbar headerTitle="OPS REPORT" show={false} />}
      >
        <>
          {/* {record.records ? ( */}
          <InfiniteScroll
            loadMore={fetchMoreData}
            hasMore={record.hasMore}
            isLoading={infiniteLoad}
            loading={<RecordsSpinner />}
            noMore={<RecordsBadge recordTypeLabel="records" />}
            initialLoad={false}
          >
            <ToolkitProvider
              keyField="id"
              data={data.tableData}
              columns={[...data.tableColumns]}
              exportCSV={{ onlyExportFiltered: true, exportAll: false }}
            >
              {(props) => (
                // <Container fluid className="p-0">
                <Card>
                  <CardHeader>
                    <div>
                      <div className="float-left">
                        <h2>
                          Showing {record.records?.length || 0} of{" "}
                          {record.totalItems || 0} Records
                        </h2>
                      </div>
                      <Row className="justify-content-end">
                        <button
                          className="btn btn-secondary mt-2 mr-2"
                          onClick={() => {
                            openSelectColumns(!selectColumns);
                            setResetDisplayCols(selectedTempDisplayColumns);
                          }}
                        >
                          Select Columns to display
                        </button>
                        <ExportCSV {...props.csvProps} />
                        <button
                          className="btn btn-secondary mt-2 ml-2"
                          onClick={() => loadAllOps()}
                        >
                          Load all Ops Report data
                        </button>
                      </Row>
                    </div>
                  </CardHeader>
                  <CardBody className="p-4">
                    {data.tableColumns && data.tableColumns.length ? (
                      <BootstrapTable
                        classes="table-responsive"
                        {...props.baseProps}
                        bootstrap4
                        bordered={true}
                        striped
                        hover
                        condensed
                        filter={filterFactory()}
                        noDataIndication={<b>No data available</b>}   />
                    ) : null}
                  </CardBody>
                </Card>
                //</Container>
              )}
            </ToolkitProvider>
          </InfiniteScroll>
        </>
        {/* ) : null} */}
        <Modal
          toggle={() => openSelectColumns(!selectColumns)}
          isOpen={selectColumns}
          centered={true}
        >
          <ModalHeader toggle={() => openSelectColumns(!selectColumns)}>
          {/* <img src={logo}  className="modallogo"/> */}
          </ModalHeader>
          <div>
                        <span className="modaltitle modaltitleDisplay">
                        Select Columns to display
                        </span>
                    </div>
          <ModalBody>
            <Row className="justify-content-end">
              <a
                className="select-link"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedTempDisplayColumns([...allColumns]);
                }}
              >
                Select all
              </a>
              <a
                className="ml-2 mr-4 select-link"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedTempDisplayColumns([]);
                }}
              >
                Deselect all
              </a>
            </Row>
            {allColumns.map((ac, index) => (
              <Col lg="6" className="d-inline-flex">
                <span>
                  <input
                    id={`column_${index}`}
                    type="checkbox"
                    checked={_.includes(selectedTempDisplayColumns, ac)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedTempDisplayColumns([
                          ...selectedTempDisplayColumns,
                          ac,
                        ]);
                      } else {
                        setSelectedTempDisplayColumns([
                          ...selectedTempDisplayColumns.filter(
                            (sdc) => sdc != ac
                          ),
                        ]);
                      }
                    }}
                  />
                  <label className="ml-2 pointer" for={`column_${index}`}>
                    {ac}
                  </label>
                </span>
              </Col>
            ))}
          </ModalBody>
          <ModalFooter>
            <Row>
              <Button
                color="secondary"
                size="sm"
                onClick={() => {
                  openSelectColumns(!selectColumns);
                  setSelectedTempDisplayColumns([...resetDisplayCols]);
                  setResetDisplayCols([]);
                }}
              >
                Close
              </Button>
              <Button
                color="primary"
                size="sm"
                className="ml-2"
                onClick={() => {
                  setSelectedDisplayColumns([...selectedTempDisplayColumns]);
                  openSelectColumns(!selectColumns);
                  // setSelectedTempDisplayColumns([])
                }}
              >
                Confirm
              </Button>
            </Row>
          </ModalFooter>
        </Modal>
      </DashboardLayout>
    </>
  );
};

export default OpsReport;
