import * as types from "../constants";

const initialState = {
    isOpen: false,
    isSticky: false,
    links: [],
    costingOptionLink: null
};

export default function reducer(state = initialState, actions)
{
    switch (actions.type)
    {
        case types.SIDEBAR_ADD_COS_LINK:
            {
                let newState = {
                    ...state,
                    costingOptionLink: actions.payload
                };
                
                return newState;
            }
            case types.SIDEBAR_ADD_SUD_LINK:
            {
                let newState = {
                    ...state,
                    sudOptionLink: actions.payload
                };
                
                return newState;
            }
            case types.SIDEBAR_ADD_RES_LINK:
                {
                    let newState = {
                        ...state,
                        resOptionLink: actions.payload
                    };
                    
                    return newState;
                }
        case types.SIDEBAR_ADD_LINKS:
            {
                let newState = {
                    ...state,
                    links: actions.payload
                };
                
                return newState;
            }
        case types.SIDEBAR_VISIBILITY_SHOW:
            return {
                ...state,
                isOpen: true
            };
        case types.SIDEBAR_VISIBILITY_HIDE:
            return {
                ...state,
                isOpen: false
            };

        case types.SIDEBAR_STICKY_TOGGLE:
            return {
                ...state,
                isSticky: !state.isSticky
            };
        case types.SIDEBAR_STICKY_ENABLE:
            return {
                ...state,
                isSticky: true
            };
        case types.LAYOUT_BOXED_ENABLE:
        case types.LAYOUT_BOXED_TOGGLE:
        case types.SIDEBAR_STICKY_DISABLE:
            return {
                ...state,
                isSticky: false
            };

        default:
            return state;
    }
}
