import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Col, Container, Row, Table, Label, Tooltip, Card, CardBody, CardHeader, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import classnames from "classnames";

import * as commercialTimeSchema from "./commercialTimeSchema.json";
import update from "immutability-helper";
import Selector from "../../components/Selector/Selector_Wavelevel";
import { toastr } from "react-redux-toastr";

import * as waveSpecsActions from "../../redux/actions/waveSpecsActions";
import * as currentWaveSpecActions from "../../redux/actions/currentWaveSpecActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import axios from "../../axios-interceptor";
import { updateProfile } from "../../redux/actions/currentCostingActions";
import { calcAll } from "../../utils/calculations";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";

const CommercialHours = ({ profile, indxFC, methodology, indxSM, updateProject, showErrorMessages, errorFieldsObj, editable, disableFields }) => {
  const dispatch = useDispatch();
  const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
  // const currentWaveSpec = useSelector(({ currentWaveSpec }) => currentWaveSpec);
  const [currentWaveSpec, setCurrentWaveSpec] = useState({})
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const [commercialTooltips, setCommercialTooltips] = useState({});
  const [schema, setSchema] = useState({});
  const [finalObjectRef, setFinalObjectRef] = useState({});
  const [clientServiceCards, setClientServiceCards] = useState();
  const [clientRatesCalled, setClientRatesCalled] = useState(false);
  const [calledCurrencies, setCalledCurrencies] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState();
  // const [currencies, setCurrencies] = useState([])
  const [waveBreakDown, setWaveBreakDown] = useState({})
  const [modifiedMeths, setModifiedMeths] = useState([])
  const [activeTabFC, setActiveTabFC] = useState();
  const [activeTabSM, setActiveTabSM] = useState();
  const [activeWave, setActiveWave] = useState("1");

  const currentProject = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const currencies = useSelector(({ currentCosting }) =>
    currentCosting.currentCostingProfile.ProfileSetting &&
      currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      : []
  );
  let pacificData = _.head(codeLabels.PacificCountriesList).Label;
  const [pacificMarkets] = useState(pacificData.split(','));
  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  // const schema = currentCostingProfile.ProfileSetting.CommercialHoursSchema;
  const countrySpecs = useSelector(({ countrySpecs }) => countrySpecs);
  const rateCards = useSelector(({ rateCards }) => rateCards);
  const [wavesSpecsData, setwavesSpecsData] = useState(profile.WaveSpecs)


  useEffect(() => {
    if (currentCostingProfile &&
      wavesSpecsData
      && wavesSpecsData.length && !Object.keys(waveBreakDown).length) {
      var rData, checData;
      if (!editable) {
        rData = (profile.CountrySpecs[indxFC]);
        checData = (rData?.MethodologySpecs[indxSM])
      }
      else {
        rData = _.head(profile.CountrySpecs);
        checData = _.head(rData?.MethodologySpecs)
      }

      setActiveTabFC(rData.id);
      setActiveTabSM(checData.id);
      let _waveBreakdownMain = wavesSpecsData.filter(x => x.MethodologySpecId === checData.id)
      let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
      setModifiedMeths((_waveBreakdownMain))
      Object.keys(_waveBreakdown).map(w => {
        _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
      })
      setWaveBreakDown(_waveBreakdown)
      setwavesSpecsData(wavesSpecsData);
      let data = _.head(_waveBreakdown[activeWave])
      setCurrentWaveSpec(data)
      dispatch(currentWaveSpecActions.selectWaveSpec({ ...data }));

    }
  }, [currentCostingProfile])
  useEffect(() => {
    if (
      (!currencies || !currencies.length) &&
      currentCostingProfile &&
      currentCostingProfile.ProfileSetting &&
      currentCostingProfile.ProfileSetting.CurrenciesData
    ) {
      // if (!calledCurrencies && !currentCostingProfile.ProfileSetting.CurrenciesData) {
      // setCalledCurrencies(true);
      // dispatch(
      // setCurrencies(currentCostingProfile.ProfileSetting.CurrenciesData)
      // );
    }
  }, [currentCostingProfile]);

  useEffect(() => {
    if (
      schema &&
      !Object.keys(schema).length &&
      currentCostingProfile &&
      currentCostingProfile.ProfileSetting
    )
      setSchema(currentCostingProfile.ProfileSetting.CommercialHoursSchema);
  }, [currentCostingProfile]);

  useEffect(() => {
    if (
      currencies &&
      !currentCurrency &&
      currentCostingProfile.CostInputCurrency
    ) {
      let values = currentCostingProfile.CostInputCurrency.split("-");
      let currencyUnit = _.last(values);
      let countryCode = _.head(values);
      let finalCurrency = _.head(
        currencies.filter(
          (cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
        )
      );
      setCurrentCurrency(finalCurrency);
    }
  }, [currentCostingProfile, currencies]);
  useEffect(() => {
    if (schema) {
      setFinalObjectRef({
        "Executive Director": "ExecutiveDirector",
        Director: "Director",
        "Associate Director": "AssociateDirector",
        "Senior Manager": "SeniorManager",
        Manager: "Manager",
        "Senior Executive": "SeniorExecutive",
        Executive: "Executive",
        "Data Science": "DatascienceInternalComm",
      });
    }
  }, [schema]);

  useEffect(() => {
    if (
      (!clientServiceCards || (profile.ProfileSetting && !profile.ProfileSetting.CSRateCardUsed)) &&
      currentProject &&
      currentProject.BusinessUnitId &&
      !clientRatesCalled &&
      currencies.length
    ) {
      setClientRatesCalled(true);
      axios
        .get(
          `marketsettings/${currentProject.BusinessUnitId}/clientservicerates/all`
        )
        .then((response) => {
          setClientServiceCards(response.data.CSRatecards);
          let defaultCard = _.head(
            response.data.CSRatecards.filter((cs) => cs.IsDefault)
          );
          if (defaultCard && defaultCard.id && !profile.ProfileSetting.CSRateCardUsed) {
            let currentprofile = {
              ...profile,
              ProfileSetting: { ...profile.ProfileSetting },
            };
            profile.ProfileSetting = {
              ...currentprofile.ProfileSetting,
              CSRateCardUsed: { ...defaultCard },
            };
            // const tempProfiles = currentProject.CostingProfiles.map(cp => {
            //   if (cp.id == currentprofile.id)
            //     return { ...currentprofile }
            //   return { ...cp }
            // });
            // updateProject({ ...currentProject, CostingProfiles: tempProfiles });
            dispatch(updateProfile(_.cloneDeep(profile)));
          }
        })
        .catch((error) => {
          toastr.error("Error while retrieving rates", error.data.error);
        });
    }
  }, [profile, currencies]);
  const bands = schema?.bands;

  const selectorHandler = (item) => {
    setActiveWave(item.WaveNumber);
    let _waveBreakdownMain = wavesSpecsData.filter(x => x.MethodologySpecId === activeTabSM)
    let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
    setModifiedMeths((_waveBreakdownMain))
    Object.keys(_waveBreakdown).map(w => {
      _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
    })
    // do nothing if clicked item is current item
    if (item === (currentWaveSpec)?.MethodologySpecId) return;
    else {
      let data = _.head(_waveBreakdown[item.WaveNumber])
      setCurrentWaveSpec(data);
    }

  };

  const applyAllWaves = () => {
    if (!editable) {
      modifiedMeths.map(x => x.CommercialHoursData = currentWaveSpec.CommercialHoursData)
      setModifiedMeths((modifiedMeths));

      let data = wavesSpecsData.map(x => {
        const item = modifiedMeths.find(({ id }) => id === x.id);
        return item ? item : x;
      });
      setwavesSpecsData(data);
      let wavespecs = data;
      wavespecs = calcAll(
        currentProject,
        profile,
        countrySpecs,
        wavespecs,
        rateCards, pacificMarkets
      );
      const profileTemp = update(profile, {

        WaveSpecs: { $set: wavespecs.waveSpecs }
      });

      const tempProfiles = currentProject.CostingProfiles.map(cp => {
        if (cp.id == profileTemp.id)
          cp = _.cloneDeep(profileTemp)
        return { ...cp }
      });
      dispatch(updateProject({ CostingProfiles: tempProfiles }));
      dispatch(updateProfile(profileTemp));
      toastr.success("Success", "Applied to all the Waves");

    }
  };

  const renderSelector = () => {
    if (!waveSpecs || (waveSpecs && waveSpecs.length === 1)) return null;
    return (
      <Col lg="2" md="2" sm="12" xs="12">
        
        {disableFields?
       <Selector 
          heading={"Waves"}
          records={modifiedMeths}
          // applyAll={disableFields?"":applyAllWaves}
          // applyAllText={disableFields?"":"Apply to All Waves"}
          clicked={selectorHandler}
          selected={currentWaveSpec}
          interPolField={["WaveNumber", "WaveName"]}
         
        />:
         <Selector 
          heading={"Waves"}
          records={modifiedMeths}
          applyAll={(editable || disableFields)?"":applyAllWaves}
          applyAllText={(editable || disableFields)?"":"Apply to All Waves"}
          clicked={selectorHandler}
          selected={currentWaveSpec}
          interPolField={["WaveNumber", "WaveName"]}
    
        />}

      </Col>
    );
  };

  const onChangeHandler = (band, field, value) => {
    //console.log("on change");
    //console.log(wavesSpecsData, "Before");
    let wavedata = _.cloneDeep(wavesSpecsData);


    let newCommercialHoursData = {
      ...currentWaveSpec.CommercialHoursData,
    };

    newCommercialHoursData[band] = newCommercialHoursData[band] || {};
    newCommercialHoursData[band][field] = Number(value) || 0;

    bands?.forEach((band) => {
      newCommercialHoursData[band] = newCommercialHoursData[band] || {};
      let total = 0;
      Object.keys(newCommercialHoursData[band]).forEach((key) => {
        if (key !== "Total") {
          total += Number(newCommercialHoursData[band][key]);
        }
      });
      newCommercialHoursData[band]["Total"] = total;
    });

    let currentwavespec = update(currentWaveSpec, {
      CommercialHoursData: { $set: newCommercialHoursData },
    });
    setCurrentWaveSpec((currentwavespec));

    var foundIndex = wavedata.findIndex(x => x.id == currentwavespec?.id);
    wavedata[foundIndex] = currentwavespec;
    setwavesSpecsData(wavedata);
    // let waveData = _.cloneDeep(wavesSpecsData);
    let waveData = [...wavedata];
    waveData = waveData.map((ws) => {
      if (ws.id == currentwavespec.id) return { ...currentwavespec };
      else return { ...ws };
    });
    waveData = calcAll(
      currentProject,
      profile,
      countrySpecs,
      waveData,
      rateCards, pacificMarkets
    );
    //console.log(waveData, "waveData");
    const profileTemp = update(profile, {

      WaveSpecs: { $set: waveData.waveSpecs }
    });

    const tempProfiles = currentProject.CostingProfiles.map(cp => {
      if (cp.id == profileTemp.id)
        cp = _.cloneDeep(profileTemp)
      return { ...cp }
    });
    dispatch(updateProject({ CostingProfiles: tempProfiles }));
    dispatch(updateProfile(profileTemp));

  };
  const commercialFields = {
    "Associate Director": "CostIntCommAssociateDirector",
    "Data Science": "CostIntCommDataScience",
    Director: "CostIntCommDirector",
    "Executive Director": "CostIntCommExecDirector",
    Executive: "CostIntCommExecutive",
    Manager: "CostIntCommManager",
    "Senior Executive": "CostIntCommSeniorExecutive",
    "Senior Manager": "CostIntCommSeniorManager",
  };

  const rateCardReferences = {
    "Executive Director": "ExecutiveDirector",
    Director: "Director",
    "Associate Director": "AssociateDirector",
    "Senior Manager": "SeniorManager",
    Manager: "Manager",
    "Senior Executive": "SeniorExecutive",
    Executive: "Executive",
    "Data Science": "DatascienceInternalComm",
  };
  const CommFieldOnBlur = () => {
    let wavespecs = [...wavesSpecsData];
    let CSRateCardUsed = {};
    if (
      profile.ProfileSetting &&
      profile.ProfileSetting.CSRateCardUsed
    ) {
      CSRateCardUsed = profile.ProfileSetting.CSRateCardUsed;
    }
    // currentCostingProfile.WaveSpecs = [...newWaveSpecs]
    Object.keys(commercialFields).map((cf) => {
      wavespecs = wavespecs.map((ws) => {
        ws[commercialFields[cf]] =
          ws.CommercialHoursData && ws.CommercialHoursData[cf]
            ? ws.CommercialHoursData[cf]["Total"] *
            CSRateCardUsed[rateCardReferences[cf]]
            : 0;
        return { ...ws };
      });
    });
    let currentwavespec = _.head(
      wavespecs.filter((ws) => ws.id == currentWaveSpec?.id)
    );

    dispatch(waveSpecsActions.setWaveSpecs(wavespecs));

    dispatch(currentWaveSpecActions.updateCurrentWaveSpec(currentwavespec));
  };
  const CSRateChanged = (eve) => {
    let currentprofile = {
      ...profile,
      ProfileSetting: { ...profile.ProfileSetting },
    };
    if (eve.target.value) {
      let finalRateCard = _.head(
        clientServiceCards.filter((csc) => csc.id == eve.target.value)
      );
      currentprofile.ProfileSetting = {
        ...currentprofile.ProfileSetting,
        CSRateCardUsed: { ...finalRateCard },
      };
    } else {
      currentprofile.ProfileSetting = {
        ...currentprofile.ProfileSetting,
        CSRateCardUsed: null,
      };
    }
    // const tempProfiles = currentProject.CostingProfiles.map(cp => {
    //   if (cp.id == currentprofile.id)
    //     return { ...currentprofile }
    //   return { ...cp }
    // });
    // updateProject({ ...currentProject, CostingProfiles: tempProfiles });
    dispatch(updateProfile(currentprofile));

    const profileTemp = update(profile, {

      ProfileSetting: { $set: currentprofile.ProfileSetting }
    });

    const tempProfiles = [profileTemp];
    dispatch(updateProject({ CostingProfiles: tempProfiles }));


  };
  const getCurrentCurrency = (actualvalue) => {
    if (
      currencies &&
      currentCurrency &&
      currentCurrency.ConversionRateToLocal
    ) {
      if (actualvalue) {
        let finalVal = _.round(
          actualvalue * currentCurrency.ConversionRateToLocal,
          2
        );
        return `${finalVal} ${currentCurrency.CurrencyUnit}`;
      } else return `0 ${currentCurrency.CurrencyUnit}`;
    }
  };
  const toggleFC = (tab) => {
    if (activeTabFC !== tab) {
      setActiveTabFC(tab);
      let cData = profile.CountrySpecs.filter(x => x.id === tab);
      let mData = _.head(cData[0].MethodologySpecs);
      setActiveTabSM(mData.id)
      onMethodologyChange(mData.id)
    }

  };

  const toggleSM = (tab) => {
    if (activeTabSM !== tab) {
      setActiveTabSM(tab);
      onMethodologyChange(tab)

    }
  };
  const onMethodologyChange = (methID) => {
    let _waveBreakdownMain = wavesSpecsData.filter(x => x.MethodologySpecId === methID)
    let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
    setModifiedMeths((_waveBreakdownMain))

    setWaveBreakDown(_waveBreakdown)
    let _currentwaveset = _.head(Object.values(_waveBreakdown))
    let data = [_.head(wavesSpecsData.filter(cws => cws.MethodologySpecId == methID))]
    setCurrentWaveSpec(data[0]);


  }
  return (
    <>
      {clientServiceCards?.length
        ?
        <Card className="m-2">
          <CardHeader>
            <Row>
              <Col style={{ display: "flex", justifyContent: "space-between" }}>
                <CardTitle id="header_op_r" className="mb-0">
                  Commercial Time Cost
                </CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {editable &&
              <Row>
                <Col md={12} className={'countryTabs tab-vertical'}>
                  <div className={"tab mr-3"}>
                    <Nav tabs>
                      {profile.CountrySpecs &&
                        profile.CountrySpecs.map((fc, indxFC) => (
                          <NavItem key={fc.value}>
                            <NavLink
                              className={classnames({ active: activeTabFC === fc.id })}
                              onClick={() => {
                                toggleFC(fc.id);
                              }}
                            >
                              <b>{fc.CountryName}</b>
                            </NavLink>
                          </NavItem>
                        ))}
                    </Nav>
                    <TabContent activeTab={activeTabFC}>
                      {profile.CountrySpecs &&
                        profile.CountrySpecs.map((fc, indxFC) => (
                          activeTabFC === fc.id &&
                          <TabPane tabId={fc.id} key={fc.value}>
                            <Row>
                              <Col md={12} className={"methodologyTabs"}>
                                <Nav tabs>
                                  {fc.MethodologySpecs &&
                                    fc.MethodologySpecs.filter(x => !x.NotApplicable).map((sm, indxSM) =>
                                    // setIndexSM(indxSM)
                                    (
                                      <NavItem key={sm.value}>
                                        <NavLink className={classnames({ active: activeTabSM === sm.id })}
                                          onClick={() => {
                                            toggleSM(sm.id);
                                          }}
                                        >
                                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div>
                                              <b>{sm.Label}</b>
                                            </div>

                                          </div>
                                        </NavLink>
                                      </NavItem>
                                    ))}
                                </Nav>

                              </Col>
                            </Row>
                          </TabPane>
                        ))}
                    </TabContent>
                  </div></Col>
              </Row>
            }
            {wavesSpecsData?.length ?
              <Container fluid>
                <Row>
                  {renderSelector()}
                  <Col>
                    <div className="ml-auto mb-2">
                      {!editable &&
                        <Row>
                          <Col lg-1 md-1 xs-12>
                            <Label className="h5">Rate card in use:</Label>
                          </Col>
                          <Col lg-7 md-7 xs-12>
                            <SelectWrapper
                            disabled={disableFields}
                              onChange={(eve) => CSRateChanged(eve)}
                              value={
                                profile.ProfileSetting &&
                                  profile.ProfileSetting.CSRateCardUsed
                                  ? profile.ProfileSetting.CSRateCardUsed.id
                                  : clientServiceCards.length ? _.head(clientServiceCards).id : null
                              }
                            >
                              <option value={0}>Select Rate card</option>
                              {clientServiceCards?.map((csc) => (
                                <option value={csc.id}>{csc.ProfileName}</option>
                              ))}
                            </SelectWrapper>
                          </Col>
                        </Row>
                      }
                    </div>
                    <div className="d-flex">
                      <Table responsive hover striped bordered size="sm">
                        <thead>
                          <tr className="commercialTable-Row">
                            <th>Hourly Chargeout Rate</th>
                            {bands && currencies && currentCurrency
                              ? bands.map((band) => (
                                <td>
                                  {profile.ProfileSetting.CSRateCardUsed
                                    ? getCurrentCurrency(
                                      profile.ProfileSetting
                                        .CSRateCardUsed[finalObjectRef[band]]
                                    )
                                    :
                                    clientServiceCards.length ?
                                      getCurrentCurrency(_.head(clientServiceCards)[finalObjectRef[band]]) :
                                      getCurrentCurrency()}
                                </td>
                              ))
                              : null}
                          </tr>
                          <tr className="commercialTable-Row">
                            <th>Stages</th>
                            {bands?.map((band) => (
                              <th>{band}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="commercialHours-Table">
                          {schema && schema.properties
                            ? Object.keys(schema.properties).map((field) => {

                              return (
                                <tr className="commercialTable-Row">
                                  <td>
                                    <span id={field}>
                                      {schema.properties[field].title}
                                    </span>
                                  </td>
                                  <Tooltip
                                    placement="top"
                                    isOpen={
                                      commercialTooltips[field] &&
                                      schema.properties[field].description
                                    }
                                    target={field}
                                    toggle={() =>
                                      setCommercialTooltips({
                                        ...commercialTooltips,
                                        [field]: !commercialTooltips[field],
                                      })
                                    }
                                  >
                                    {schema.properties[field].description}
                                  </Tooltip>
                                  {bands?.map((band) => {
                                    return (
                                      <td>
                                        <Input
                                          id={`${band}-${field}`}
                                          type="number"
                                          value={
                                            currentWaveSpec?.CommercialHoursData &&
                                              currentWaveSpec?.CommercialHoursData[band]
                                              ? currentWaveSpec?.CommercialHoursData[
                                              band
                                              ][field] || ""
                                              : ""
                                          }
                                          onChange={(e) =>
                                            onChangeHandler(
                                              band,
                                              field,
                                              e.target.value
                                            )
                                          }
                                          onBlur={CommFieldOnBlur}
                                          disabled={(profile.ProfileStatus == 99) || !currentWaveSpec || !currentWaveSpec?.id || editable || disableFields}

                                          min={0}
                                        />
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })
                            : null}
                        </tbody>
                        <tfoot className="commercialHours-TableBody">
                          <tr className="commercialTable-Row">
                            <th>Band Totals (Hours)</th>
                            {bands?.map((band) => {
                              return (
                                <td>
                                  {currentWaveSpec?.CommercialHoursData &&
                                    currentWaveSpec?.CommercialHoursData[band]
                                    ? currentWaveSpec?.CommercialHoursData[band][
                                    "Total"
                                    ] ?? 0
                                    : 0}
                                </td>

                              );
                            })}
                          </tr>
                          <tr className="commercialTable-Row">
                            <th>Band Totals (Values)</th>
                            {bands?.map((band) => {
                              return (
                                <td>
                                  {currentWaveSpec?.CommercialHoursData &&
                                    currentWaveSpec?.CommercialHoursData[band]
                                    ? getCurrentCurrency(currentWaveSpec?.CommercialHoursData[band][
                                      "Total"
                                      // ] * currentCostingProfile?.ProfileSetting?.CSRateCardUsed?.[rateCardReferences[band]] ?? 0, false)
                                    ] * currentCostingProfile?.ProfileSetting?.CSRateCardUsed?.[rateCardReferences[band]])
                                    : 0}
                                </td>

                              );
                            })}

                          </tr>
                          <tr className="commercialTable-Row">
                            <th>Total Wave Hours</th>
                            <td>
                              {currentWaveSpec?.CommercialHoursData
                                ? _.sum(
                                  Object.keys(
                                    currentWaveSpec?.CommercialHoursData
                                  ).map((band) => {
                                    return currentWaveSpec?.CommercialHoursData[band]
                                      .Total;
                                  })
                                )
                                : 0}
                            </td>
                            <td>
                              {currentWaveSpec?.CommercialHoursData
                                ? getCurrentCurrency(_.sum(
                                  Object.keys(
                                    currentWaveSpec?.CommercialHoursData
                                  ).map((band) => {
                                    return currentWaveSpec?.CommercialHoursData[band]
                                      .Total * currentCostingProfile?.ProfileSetting?.CSRateCardUsed?.[rateCardReferences[band]];
                                  })
                                ), false)
                                : 0}
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </Container>
              : <>Please Save RFQ form in order to fill Commercial hours</>}
          </CardBody>

        </Card>
        : null}
    </>
  );
};

export default React.memo(CommercialHours);
