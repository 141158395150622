import React, { useEffect, useState } from "react";
import {
  pageLoadEnd,
  pageLoadStart,
  recordLoadEnd,
  recordLoadStart,
} from "../redux/actions/appActions";
import axios from "../../src/axios-interceptor";
import { useSelector, useDispatch } from "react-redux";
import * as currentCostingActions from "../redux/actions/currentCostingActions";
import OopAdjustmentLog from "../pages/summary/OopAdjustmentLog"
import { toastr } from "react-redux-toastr";
import Spinner from "../components/Spinner";
import _ from "lodash";
import logo from "../assets/img/avatars/modal-logo.png";
import * as projectsActions from "../redux/actions/projectsActions";
import Select from "react-select";
import {
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Table from "reactstrap/lib/Table";

function TotalOPSOOPModal({
  opsOpp,
  setOpsOpp,
  profile = null,
  project,
  dashboard,
}) {
  const [_profile, setProfile] = useState(profile);
  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const currentProfile = _profile != null ? _profile : currentCostingProfile;
  let commissioning_currency = currentProfile?.ProfileSetting?.CurrenciesData?.find(
    (s) => s.Code == project.CommissioningCountry
  )
    ? currentProfile?.ProfileSetting?.CurrenciesData?.find(
        (s) => s.Code == project.CommissioningCountry
      )
    : {}
    const [currentCurrency, setCurrentCurrency] = useState({
    "value": `${commissioning_currency.Code}-${commissioning_currency.CurrencyUnit}`,
    "label" : `${commissioning_currency.Label !== '' ? commissioning_currency.Label : ''} (1USD = ${commissioning_currency.ConversionRateToLocal}${commissioning_currency.CurrencyUnit})`,
    "ConversionRateToLocal" : `${commissioning_currency.ConversionRateToLocal}`,
    "CurrencyUnit" : `${commissioning_currency.CurrencyUnit}`,
  "code" : `${commissioning_currency.Code}`}
  );
  const [isChangeFlagOps, setIsChangeFlagOps] = useState(true);
  const [isChangeFlagCS, setIsChangeFlagCS] = useState(true);
  const [isChangeFlagOpsInt, setIsChangeFlagOpsInt] = useState(true);
  const [isChangeFlagCSInt, setIsChangeFlagCSInt] = useState(true);
  var wbsNumbers=[]
  currentProfile && currentProfile.WBSLevelCosts && 
  currentProfile?.WBSLevelCosts?.filter((num)=>wbsNumbers.push(num.WBSNumber))
  const [wbsDropDown, setWbsDropDown] = useState({value: wbsNumbers[0], label: wbsNumbers[0]})
  console.log('wbsNumberswbs',wbsNumbers)
  console.log('wbsDropDown232',wbsDropDown)
  const filterWbsNum = wbsDropDown !== undefined && currentProfile && currentProfile?.WBSLevelCosts &&
                       currentProfile?.WBSLevelCosts?.filter((val)=>val?.WBSNumber === wbsDropDown?.value);
  console.log('filterWbsNum',filterWbsNum)
  const [valOOP, setvalOOP] = useState(
    (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
    _.round(
    filterWbsNum[0]?.CostTotalExternalOperationswbs *
      currentCurrency?.ConversionRateToLocal,
    2
  ) : _.round(
    currentProfile?.CostTotalExternalOperations *
      currentCurrency?.ConversionRateToLocal,
    2
  ));
  const [valCS, setvalCS] = useState(
    (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? _.round(
      filterWbsNum[0]?.CostTotalExternalCommercialwbs *
      currentCurrency?.ConversionRateToLocal,
    2
  ) : _.round(
    currentProfile?.CostTotalExternalCommercial *
      currentCurrency?.ConversionRateToLocal,
    2
  ));
  const [newOopPercent, setNewOopPercent] = useState(0);
  const [valOpsInternal, setvalOpsInternal] = useState(
    (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? _.round(
    (filterWbsNum[0]?.CostTotalInternalOperationswbs || 0)*
      currentCurrency?.ConversionRateToLocal,
    2
  ) : _.round(
    (currentProfile?.CostTotalInternalOperations || 0)*
      currentCurrency?.ConversionRateToLocal,
    2
  ));
  const [valCsInternal, setvalCsInternal] = useState(
    (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? _.round(
    (filterWbsNum[0]?.CostTotalInternalCommercialwbs || 0)*
      currentCurrency?.ConversionRateToLocal,
    2
  ) : _.round(currentProfile?.CostTotalInternalCommercial || 0)*
  currentCurrency?.ConversionRateToLocal,
2
);
  const [valProjMarAbs, setvalProjMarAbs] = useState();
  const [valTotalExt, setvalTotalExt] = useState();
  const [valTotalInt, setvalTotalInt] = useState(); 
  const [newMarginPercent, setNewMarginPercent] = useState(0);
  const [HistoryLogData, setHistoryLogData] = useState([]);
  const [isHistoryLogOpen, openHistoryLog] = useState(false);
  const [valReason, setReason] = useState("");
  const [negativeValues, setNegativeValues] = useState({
    oop: false,
    reasonforadjustment: false,
  });
  
  const wbslevelcost=currentProfile?.WBSLevelCosts;
  // console.log(wbslevelcost);
  // useEffect(() => {
  //   if(profile && profile.WBSLevelCosts){
  //   setCurrentProfile(profile);
  //   console.log(currentProfile);
  //   setFilterWbsNum(wbsDropDown !== undefined && currentProfile && currentProfile?.WBSLevelCosts &&
  //     currentProfile?.WBSLevelCosts?.filter((val)=>val?.WBSNumber === wbsDropDown?.value));
  //   console.log(filterWbsNum);
  //   }

  // }, [profile])
  const [historyData,setHistoryData]=useState([]);
  const [isHistoryAvailable,setIsHistoryAvailable]=useState(false);
  const [filteredCommissionedData,setFilteredCommisionData]=useState([]);

  const currencies = useSelector(({ currentCosting }) =>
    currentCosting.currentCostingProfile.ProfileSetting &&
      currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      : []
  );
  useEffect(() => {
    if((filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) &&
       (filterWbsNum[0].WBSNumber === wbsDropDown.value) && isChangeFlagOps && isChangeFlagCS &&
        isChangeFlagOpsInt && isChangeFlagCSInt){
      console.log('checkWBNSJSJS')
      setInputOpsExt(_.round(
        filterWbsNum[0]?.CostTotalExternalOperationswbs *
        currentCurrency?.ConversionRateToLocal,
        2
      ))
      setvalOOP(_.round(
        filterWbsNum[0]?.CostTotalExternalOperationswbs *
          currentCurrency?.ConversionRateToLocal,
        2
      ))
      setInputOpsInt(_.round(
        (filterWbsNum[0]?.CostTotalInternalOperationswbs || 0)*
          currentCurrency?.ConversionRateToLocal,
        2
      ))
      setvalOpsInternal(_.round(
        (filterWbsNum[0]?.CostTotalInternalOperationswbs || 0)*
          currentCurrency?.ConversionRateToLocal,
        2
      ))
      setInputCsInt(_.round(
        (filterWbsNum[0]?.CostTotalInternalCommercialwbs || 0)*
          currentCurrency?.ConversionRateToLocal,
        2
      ))
      setvalCsInternal(_.round(
        (filterWbsNum[0]?.CostTotalInternalCommercialwbs || 0)*
          currentCurrency?.ConversionRateToLocal,
        2
      ))
      setInputCsExt(_.round(
        filterWbsNum[0]?.CostTotalExternalCommercialwbs *
          currentCurrency?.ConversionRateToLocal,
        2
      ))
      setvalCS(_.round(
        filterWbsNum[0]?.CostTotalExternalCommercialwbs *
          currentCurrency?.ConversionRateToLocal,
        2
      ))
    }
  },[filterWbsNum, wbsDropDown])
  const [inputOpsExt, setInputOpsExt] = useState(
    (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
    _.round(
      filterWbsNum[0]?.CostTotalExternalOperationswbs *
      currentCurrency?.ConversionRateToLocal,
      2
    ) : _.round(
    currentProfile?.CostTotalExternalOperations *
    currentCurrency?.ConversionRateToLocal,
    2
  ));
  const [inputCsExt, setInputCsExt] = useState(
    (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
    _.round(
      filterWbsNum[0]?.CostTotalExternalCommercialwbs *
        currentCurrency?.ConversionRateToLocal,
      2
    ) :
    _.round(
    currentProfile?.CostTotalExternalCommercial *
      currentCurrency?.ConversionRateToLocal,
    2
  ));
  const [inputOpsInt, setInputOpsInt] = useState(
    (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
    _.round(
    (filterWbsNum[0]?.CostTotalInternalOperationswbs || 0)*
      currentCurrency?.ConversionRateToLocal,
    2
  ): _.round(
    (currentProfile?.CostTotalInternalOperations || 0)*
      currentCurrency?.ConversionRateToLocal,
    2
  ));
  const [inputCsInt, setInputCsInt] = useState(
    (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ?
    _.round(
    (filterWbsNum[0]?.CostTotalInternalCommercialwbs || 0)*
      currentCurrency?.ConversionRateToLocal,
    2
  ) : _.round(
    (currentProfile?.CostTotalInternalCommercial || 0)*
      currentCurrency?.ConversionRateToLocal,
    2
  ));
  const [currencyDropDown, setCurrencyDropDown] = useState(
      currentProfile?.ProfileSetting?.CurrenciesData
  );

 

const CCcostShow = new Date('2023-07-26T00:00:00.000Z')
const CommissionedDate = new Date(currentProfile?.CommissionedDate);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  let pacificData = _.head(codeLabels?.PacificCountriesList)?.Label;
  const [pacificMarkets] = useState(pacificData?.split(',') || []);
  const dispatch = useDispatch();

  const userRecord = useSelector(({ user }) => user.userRecord);

  // const currentCostingProfile = useSelector(
  //   ({ currentCosting }) => currentCosting.currentCostingProfile
  // );
  const projects = useSelector(({ projects }) => projects?.items);
  const app = useSelector(({ app }) => app);
  // const currentProfile = _profile != null ? _profile : currentCostingProfile; 
  useEffect(() => {
    setCurrentCurrency(
      {
        "value": `${commissioning_currency.Code}-${commissioning_currency.CurrencyUnit}`,
        "label" : `${commissioning_currency.Label !== '' ? commissioning_currency.Label : ''} (1USD = ${commissioning_currency.ConversionRateToLocal}${commissioning_currency.CurrencyUnit})`,
        "ConversionRateToLocal" : `${commissioning_currency.ConversionRateToLocal}`,
        "CurrencyUnit" : `${commissioning_currency.CurrencyUnit}`}
    );
  }, [currentProfile, currentProfile?.ProfileSetting, opsOpp]);

  let CC = currentProfile?.CountrySpecs?.map( c => {
       return c?.MethodologySpecs?.map(m => {
           return (m?.NotApplicable === null) 
           ?  (parseFloat(m?.CostingOptions[0]?.CostsData?.CCCSCoordinatingCost) + parseFloat(m?.CostingOptions[0]?.CostsData?.CCOPSCoordinatingCost))
          : 0;
        });

       });
       let CCcost = (CC?.reduce((acc,val) => acc+(parseFloat(val[0] || 0)),0)) || 0;
  useEffect(() => {
    if (!isNaN(parseFloat(valOOP))) {
      let calulationOop = !_.includes(
        pacificMarkets,
        project.CommissioningCountry
      ) ||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
        ? (parseFloat(valOOP) +
          (((!isNaN(parseFloat(valCS))) ? parseFloat(valCS) : currentProfile.CostTotalExternalCommercial*
              currentCurrency?.ConversionRateToLocal) +
              (currentProfile?.VAT*
                currentCurrency?.ConversionRateToLocal) +
              (currentProfile?.WHT*
                currentCurrency?.ConversionRateToLocal))) /
          (currentProfile?.PriceToClient *
            currentCurrency?.ConversionRateToLocal)
        : parseFloat(valOOP) /
          (currentProfile.PriceToClient *
            currentCurrency?.ConversionRateToLocal);
      let _newOopPercent =
        currentProfile.PriceToClient != 0 &&
        currentProfile.PriceToClient != null
          ? isNaN(calulationOop)
            ? 0
            : calulationOop
          : 0;
      setNewOopPercent(_newOopPercent);


      let calculationMarginPercent = (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
      ((filterWbsNum[0].PriceToClientwbs *
        currentCurrency?.ConversionRateToLocal) - 
        ((((!isNaN(parseFloat(valCS)) ? parseFloat(valCS) : filterWbsNum[0].CostTotalExternalCommercialwbs*
        currentCurrency?.ConversionRateToLocal) +
          (filterWbsNum[0].VATwbs *
            currentCurrency?.ConversionRateToLocal) +
          (filterWbsNum[0].WHTwbs*
            currentCurrency?.ConversionRateToLocal))) + parseFloat(valOOP) +
            (((!isNaN(parseFloat(valOpsInternal))) ? parseFloat(valOpsInternal) : filterWbsNum[0].CostTotalInternalOperationswbs* currentCurrency?.ConversionRateToLocal) 
            + ((!isNaN(parseFloat(valCsInternal))) ? parseFloat(valCsInternal) : filterWbsNum[0].CostTotalInternalCommercialwbs* currentCurrency?.ConversionRateToLocal)) +
        (CCcost * currentCurrency?.ConversionRateToLocal) +
         (filterWbsNum[0].CostTotalMCPMarginwbs * currentCurrency?.ConversionRateToLocal) +
         (filterWbsNum[0].Overheadswbs * currentCurrency?.ConversionRateToLocal)))/(filterWbsNum[0].PriceToClientwbs * currentCurrency?.ConversionRateToLocal)
         : ((currentProfile.PriceToClient *
          currentCurrency?.ConversionRateToLocal) - 
          ((((!isNaN(parseFloat(valCS)) ? parseFloat(valCS) : currentProfile.CostTotalExternalCommercial*
          currentCurrency?.ConversionRateToLocal) +
            (currentProfile.VAT *
              currentCurrency?.ConversionRateToLocal) +
            (currentProfile.WHT*
              currentCurrency?.ConversionRateToLocal))) + parseFloat(valOOP) +
              (((!isNaN(parseFloat(valOpsInternal))) ? parseFloat(valOpsInternal) : currentProfile.CostTotalInternalOperations* currentCurrency?.ConversionRateToLocal) 
              + ((!isNaN(parseFloat(valCsInternal))) ? parseFloat(valCsInternal) : currentProfile.CostTotalInternalCommercial* currentCurrency?.ConversionRateToLocal)) +
          (CCcost * currentCurrency?.ConversionRateToLocal) +
           (currentProfile.CostTotalMCPMargin * currentCurrency?.ConversionRateToLocal) +
           (currentProfile.Overheads * currentCurrency?.ConversionRateToLocal)))/(currentProfile.PriceToClient * currentCurrency?.ConversionRateToLocal);

         let _newMarginPercent =
      currentProfile.PriceToClient != 0 &&
      currentProfile.PriceToClient != null
        ? isNaN(calculationMarginPercent)
          ? 0
          : calculationMarginPercent
        : 0;
    setNewMarginPercent(_newMarginPercent);
    //console.log(currentCostingProfile);
    }
  }, [valOOP, currentProfile, filterWbsNum]);

  useEffect(() => {
    if (!isNaN(parseFloat(valCS))) {
      let calulationCS = (!_.includes(
        pacificMarkets,
        project.CommissioningCountry
      )||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0"))
       && (filterWbsNum?.length === 0 && Object.keys(wbsDropDown).length === 0) 
        ? (parseFloat(valCS) +
        (((!isNaN(parseFloat(valOOP))) ? parseFloat(valOOP) : currentProfile.CostTotalExternalOperations *
              currentCurrency?.ConversionRateToLocal)+
              (currentProfile.VAT *
                currentCurrency?.ConversionRateToLocal)+
              (currentProfile.WHT*
                currentCurrency?.ConversionRateToLocal))) /
          (currentProfile.PriceToClient *
            currentCurrency?.ConversionRateToLocal)
        : ((filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? (parseFloat(valCS) +
           (((!isNaN(parseFloat(valOOP))) ? parseFloat(valOOP) : filterWbsNum[0].CostTotalExternalOperationswbs *
              currentCurrency?.ConversionRateToLocal)+
              (filterWbsNum[0].VATwbs *
                currentCurrency?.ConversionRateToLocal)+
              (filterWbsNum[0].WHTwbs*
                currentCurrency?.ConversionRateToLocal))) /
          (filterWbsNum[0].PriceToClientwbs *
            currentCurrency?.ConversionRateToLocal)
          : parseFloat(valCS) /
          (currentProfile.PriceToClient *
            currentCurrency?.ConversionRateToLocal));
      let _newOopPercent =
        currentProfile.PriceToClient != 0 &&
        currentProfile.PriceToClient != null
          ? isNaN(calulationCS)
            ? 0
            : calulationCS
          : 0;
      setNewOopPercent(_newOopPercent);
      let calculationMarginPercent = (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
      ((filterWbsNum[0].PriceToClientwbs *
        currentCurrency?.ConversionRateToLocal) - 
        ((((!isNaN(parseFloat(valOOP))) ? parseFloat(valOOP) : (filterWbsNum[0].CostTotalExternalOperationswbs*
          currentCurrency?.ConversionRateToLocal)) +
          (filterWbsNum[0].VATwbs*
            currentCurrency?.ConversionRateToLocal) +
          (filterWbsNum[0].WHTwbs*
            currentCurrency?.ConversionRateToLocal)) + parseFloat(valCS) +
        (((!isNaN(parseFloat(valOpsInternal))) ? parseFloat(valOpsInternal) :filterWbsNum[0].CostTotalInternalOperationswbs* currentCurrency?.ConversionRateToLocal) 
          + ((!isNaN(parseFloat(valCsInternal))) ? parseFloat(valCsInternal) : filterWbsNum[0].CostTotalInternalCommercialwbs* currentCurrency?.ConversionRateToLocal)) +
          (CCcost * currentCurrency?.ConversionRateToLocal) +
         (filterWbsNum[0].CostTotalMCPMarginwbs * currentCurrency?.ConversionRateToLocal) +
         (filterWbsNum[0].Overheadswbs * currentCurrency?.ConversionRateToLocal)))/(filterWbsNum[0].PriceToClientwbs * currentCurrency?.ConversionRateToLocal)
         : ((currentProfile.PriceToClient *
        currentCurrency?.ConversionRateToLocal) - 
        ((((!isNaN(parseFloat(valOOP))) ? parseFloat(valOOP) : (currentProfile.CostTotalExternalOperations*
          currentCurrency?.ConversionRateToLocal)) +
          (currentProfile.VAT*
            currentCurrency?.ConversionRateToLocal) +
          (currentProfile.WHT*
            currentCurrency?.ConversionRateToLocal)) + parseFloat(valCS) +
        (((!isNaN(parseFloat(valOpsInternal))) ? parseFloat(valOpsInternal) :currentProfile.CostTotalInternalOperations* currentCurrency?.ConversionRateToLocal) 
          + ((!isNaN(parseFloat(valCsInternal))) ? parseFloat(valCsInternal) : currentProfile.CostTotalInternalCommercial* currentCurrency?.ConversionRateToLocal)) +
          (CCcost * currentCurrency?.ConversionRateToLocal) +
         (currentProfile.CostTotalMCPMargin * currentCurrency?.ConversionRateToLocal) +
         (currentProfile.Overheads * currentCurrency?.ConversionRateToLocal)))/(currentProfile.PriceToClient * currentCurrency?.ConversionRateToLocal);
          let _newMarginPercent =
      currentProfile.PriceToClient != 0 &&
      currentProfile.PriceToClient != null
        ? isNaN(calculationMarginPercent)
          ? 0
          : calculationMarginPercent
        : 0;
    setNewMarginPercent(_newMarginPercent);
    }
  }, [valCS, currentProfile, filterWbsNum]);

useEffect(() => {
  if (!isNaN(parseFloat(valOpsInternal))) {
    let calculationMarginPercent = (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
    (((filterWbsNum[0].PriceToClientwbs *
      currentCurrency?.ConversionRateToLocal) - 
      ((((!isNaN(parseFloat(valCS))) ? parseFloat(valCS) : filterWbsNum[0].CostTotalExternalCommercialwbs*
        currentCurrency?.ConversionRateToLocal) + ((!isNaN(parseFloat(valOOP))) ? parseFloat(valOOP) : filterWbsNum[0].CostTotalExternalOperationswbs*
        currentCurrency?.ConversionRateToLocal)) +
        (filterWbsNum[0].VATwbs*
          currentCurrency?.ConversionRateToLocal) +
        (filterWbsNum[0].WHTwbs*
          currentCurrency?.ConversionRateToLocal) +
      (parseFloat(valOpsInternal) + 
      ((!isNaN(parseFloat(valCsInternal))) ? parseFloat(valCsInternal) : filterWbsNum[0].CostTotalInternalCommercialwbs * currentCurrency?.ConversionRateToLocal)) +
      (CCcost* currentCurrency?.ConversionRateToLocal) +
       (filterWbsNum[0].CostTotalMCPMarginwbs * currentCurrency?.ConversionRateToLocal) +
       (filterWbsNum[0].Overheadswbs * currentCurrency?.ConversionRateToLocal)))/(filterWbsNum[0].PriceToClientwbs * currentCurrency?.ConversionRateToLocal))
       : (((currentProfile.PriceToClient *
        currentCurrency?.ConversionRateToLocal) - 
        ((((!isNaN(parseFloat(valCS))) ? parseFloat(valCS) : currentProfile.CostTotalExternalCommercial*
          currentCurrency?.ConversionRateToLocal) + ((!isNaN(parseFloat(valOOP))) ? parseFloat(valOOP) : currentProfile.CostTotalExternalOperations*
          currentCurrency?.ConversionRateToLocal)) +
          (currentProfile.VAT*
            currentCurrency?.ConversionRateToLocal) +
          (currentProfile.WHT*
            currentCurrency?.ConversionRateToLocal) +
        (parseFloat(valOpsInternal) + 
        ((!isNaN(parseFloat(valCsInternal))) ? parseFloat(valCsInternal) : currentProfile.CostTotalInternalCommercial * currentCurrency?.ConversionRateToLocal)) +
        (CCcost* currentCurrency?.ConversionRateToLocal) +
         (currentProfile.CostTotalMCPMargin * currentCurrency?.ConversionRateToLocal) +
         (currentProfile.Overheads * currentCurrency?.ConversionRateToLocal)))/(currentProfile.PriceToClient * currentCurrency?.ConversionRateToLocal));

          let _newMarginPercent =
      currentProfile.PriceToClient != 0 &&
      currentProfile.PriceToClient != null
        ? isNaN(calculationMarginPercent)
          ? 0
          : calculationMarginPercent
        : 0;
    setNewMarginPercent(_newMarginPercent);

  }
}, [valOpsInternal, currentProfile, filterWbsNum]);

useEffect(() => {
  if (!isNaN(parseFloat(valCsInternal))) {
    let calculationMarginPercent = (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
    (((filterWbsNum[0].PriceToClientwbs *
      currentCurrency?.ConversionRateToLocal) - 
      ((((!isNaN(parseFloat(valCS))) ? parseFloat(valCS) : filterWbsNum[0].CostTotalExternalCommercialwbs*
        currentCurrency?.ConversionRateToLocal) + ((!isNaN(parseFloat(valOOP))) ? parseFloat(valOOP) : filterWbsNum[0].CostTotalExternalOperationswbs*
        currentCurrency?.ConversionRateToLocal)) +
        (filterWbsNum[0].VATwbs*
          currentCurrency?.ConversionRateToLocal) +
        (filterWbsNum[0].WHTwbs*
          currentCurrency?.ConversionRateToLocal) +
      (parseFloat(valCsInternal) + 
      ((!isNaN(parseFloat(valOpsInternal))) ? parseFloat(valOpsInternal) : filterWbsNum[0].CostTotalInternalOperationswbs * currentCurrency?.ConversionRateToLocal)) +
      (CCcost* currentCurrency?.ConversionRateToLocal) +
       (filterWbsNum[0].CostTotalMCPMarginwbs * currentCurrency?.ConversionRateToLocal) +
       (filterWbsNum[0].Overheadswbs * currentCurrency?.ConversionRateToLocal)))/(filterWbsNum[0].PriceToClientwbs * currentCurrency?.ConversionRateToLocal)) 
       : (((currentProfile.PriceToClient *
        currentCurrency?.ConversionRateToLocal) - 
        ((((!isNaN(parseFloat(valCS))) ? parseFloat(valCS) : currentProfile.CostTotalExternalCommercial*
          currentCurrency?.ConversionRateToLocal) + ((!isNaN(parseFloat(valOOP))) ? parseFloat(valOOP) : currentProfile.CostTotalExternalOperations*
          currentCurrency?.ConversionRateToLocal)) +
          (currentProfile.VAT*
            currentCurrency?.ConversionRateToLocal) +
          (currentProfile.WHT*
            currentCurrency?.ConversionRateToLocal) +
        (parseFloat(valCsInternal) + 
        ((!isNaN(parseFloat(valOpsInternal))) ? parseFloat(valOpsInternal) : currentProfile.CostTotalInternalOperations * currentCurrency?.ConversionRateToLocal)) +
        (CCcost* currentCurrency?.ConversionRateToLocal) +
         (currentProfile.CostTotalMCPMargin * currentCurrency?.ConversionRateToLocal) +
         (currentProfile.Overheads * currentCurrency?.ConversionRateToLocal)))/(currentProfile.PriceToClient * currentCurrency?.ConversionRateToLocal));

          let _newMarginPercent =
      currentProfile.PriceToClient != 0 &&
      currentProfile.PriceToClient != null
        ? isNaN(calculationMarginPercent)
          ? 0
          : calculationMarginPercent
        : 0;
    setNewMarginPercent(_newMarginPercent);

  }
}, [valCsInternal, currentProfile, filterWbsNum]);
useEffect(()=>{
  let costs = [
    "OldOpsOOP",
    "NewOpsOOP",
    "OldCSOOP",
    "NewCSOOP",
    "OldTotalExternalCosts",
    "NewTotalExternalCosts",
    "OldCsInternal",
    "NewCsInternal",
    "OldOpsInternal",
    "NewOpsInternal",
    "OldTotalInternalCosts",
    "NewTotalInternalCosts",
  ];
  axios
    .post("opsAdjustments/opsoop_adjustments/", { ProfileId: currentProfile?.id })

    .then((res) => {
      if (filterWbsNum?.length > 0) {
      if(res.data.Result.length>0){
        let tempAdjustments = res.data.Result.map((resData) => {
          resData.PricetoClient =  (_.round(
            currentProfile?.PriceToClient * currentCurrency?.ConversionRateToLocal,
            2
          )) 
          resData.WBSNumber = resData.WBSNumber === null ? "NA" : resData.WBSNumber;
          costs.forEach((cd) => {
            resData[cd] = _.round(
              ( resData[cd] * currentCurrency?.ConversionRateToLocal),2
             );
          });
          console.log(resData, "resData")
          return resData;
        });
        setHistoryData(tempAdjustments);
        setIsHistoryAvailable(true);
        const commissionedData=tempAdjustments.filter((r)=>r.WBSNumber===filterWbsNum[0]?.WBSNumber);
        setFilteredCommisionData(commissionedData);
        
      }
    }
      else{
        setIsHistoryAvailable(false);

      }
     
    })

},[currentCurrency]);

  const getOpsOop = (e) => {
    let valuesForNumber = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
    if (e.target.value && !valuesForNumber.test(e.target.value)) {
      setvalOOP("");
      setNegativeValues({ ...negativeValues, oop: true });
      setInputOpsExt(_.round(
        currentProfile.CostTotalExternalOperations *
        currentCurrency?.ConversionRateToLocal,
        2
      ));
    } else {
      setNegativeValues({ ...negativeValues, oop: false });
      setvalOOP(e.target.value === "" ? 0 :Number(e.target.value));
      setInputOpsExt(e.target.value === "" ? "" : Number(e.target.value));
      setIsChangeFlagOps(false)
    }
  };

  const getCsOop = (e) => {
    let valuesForNumber = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
    if (e.target.value && !valuesForNumber.test(e.target.value)) {
      setvalCS("");
      setNegativeValues({ ...negativeValues, oop: true });
      setInputCsExt(_.round(
        currentProfile.CostTotalExternalCommercial *
          currentCurrency?.ConversionRateToLocal,
        2
      ));
    } else {
      setNegativeValues({ ...negativeValues, oop: false });
      setvalCS(e.target.value === "" ? 0 :Number(e.target.value));
      setInputCsExt(e.target.value === "" ? "" : Number(e.target.value));
      setIsChangeFlagCS(false)
    }
  };

  const getOpsInternal = (e) => {
    let valuesForNumber = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
    if (e.target.value && !valuesForNumber.test(e.target.value)) {
      setvalOpsInternal("");
      setNegativeValues({ ...negativeValues, oop: true });
      setInputOpsInt(_.round(
        (currentProfile.CostTotalInternalOperations || 0)*
          currentCurrency?.ConversionRateToLocal,
        2
      ));
    } else {
      setNegativeValues({ ...negativeValues, oop: false });
      setvalOpsInternal(e.target.value === "" ? 0 :Number(e.target.value));
      setInputOpsInt(e.target.value === "" ? "" : Number(e.target.value));
      setIsChangeFlagOpsInt(false);
    }
  };

  const getCsInternal = (e) => {
    let valuesForNumber = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
    if (e.target.value && !valuesForNumber.test(e.target.value)) {
      setvalCsInternal("");
      setNegativeValues({ ...negativeValues, oop: true });
      setInputCsInt(_.round(
        (currentProfile.CostTotalInternalCommercial || 0)*
          currentCurrency?.ConversionRateToLocal,
        2
      ));
    } else {
      setNegativeValues({ ...negativeValues, oop: false });
      setvalCsInternal(e.target.value === "" ? 0 :Number(e.target.value));
      setInputCsInt(e.target.value === "" ? "" : Number(e.target.value));
      setIsChangeFlagCSInt(false);
    }
  };

 
  const getReasonForAdjustment = (e) => {
    setReason(e.target.value);
    if (e.target.value) {
      setNegativeValues({ ...negativeValues, reasonforadjustment: false });
    }
  };

  const getGeneratedSummary = (e) => {
    dispatch(recordLoadStart());
   if (!valOOP && !valReason) {
      setNegativeValues({ oop: true, reasonforadjustment: true });
      if (valOOP == 0 && !valReason) {
        toastr.error(
          "OPS OOP value must be greater than 0 and Reason For Adjustment"
        );
      } else {
        toastr.error("Enter OPS OOP & Reason For Adjustment");
      }

      dispatch(recordLoadEnd());
    } else if (!valOOP) {
      setNegativeValues({ oop: true, reasonforadjustment: false });
      if (valOOP == 0) {
        toastr.error("OPS OOP value must be greater than 0");
      } else {
        toastr.error("Enter OPS OOP");
      }
      dispatch(recordLoadEnd());
    } else if (!valReason) {
      setNegativeValues({ oop: false, reasonforadjustment: true });
      toastr.error("Enter Reason for Adjustment");
      dispatch(recordLoadEnd());
    } else {
      let obtaineddata = {
        CostingProfileId: currentProfile.id,
        OldOpsOOP: currentProfile.CostTotalExternalOperations,
        NewOpsOOP: _.round(parseFloat(valOOP) / currentCurrency?.ConversionRateToLocal,2),
        OldCSOOP: currentProfile.CostTotalExternalCommercial,
        NewCSOOP: _.round(parseFloat(valCS) / currentCurrency?.ConversionRateToLocal,2),
        OldOpsOOPTotal: _.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion != "v2.0"
          ? currentProfile.CostTotalExternalOperations 
          : ((filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ?
              filterWbsNum[0].CostTotalExternalOperationswbs
          : currentProfile.TotalExternalCosts),
        NewOpsOOPTotal: !_.includes(
          pacificMarkets,
          project.CommissioningCountry
        )||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
      ? _.round(
          parseFloat((!isNaN(parseFloat(valOOP/currentCurrency?.ConversionRateToLocal))) ? (valOOP/currentCurrency?.ConversionRateToLocal) : currentProfile.CostTotalExternalOperations) +
          parseFloat((!isNaN(parseFloat(valCS/currentCurrency?.ConversionRateToLocal))) ? (valCS/currentCurrency?.ConversionRateToLocal) : currentProfile.CostTotalExternalCommercial) +
                    (currentProfile.VAT + currentProfile.WHT),
          2
        )
      : ((filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
          _.round(
        parseFloat((!isNaN(parseFloat(valOOP/currentCurrency?.ConversionRateToLocal))) ? (valOOP/currentCurrency?.ConversionRateToLocal) : filterWbsNum[0].CostTotalExternalOperationswbs) +
        parseFloat((!isNaN(parseFloat(valCS/currentCurrency?.ConversionRateToLocal))) ? (valCS/currentCurrency?.ConversionRateToLocal) : filterWbsNum[0].CostTotalExternalCommercialwbs) +
                  (filterWbsNum[0].VATwbs + filterWbsNum[0].WHTwbs),
        2
      ) : parseFloat(valOOP/currentCurrency?.ConversionRateToLocal)),
      NewOpsOOPTotalWithoutTax: _.includes(

        pacificMarkets,

        project.CommissioningCountry

      )||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")

        ? parseFloat(valOOP) / currentCurrency?.ConversionRateToLocal

        : (parseFloat(!_.includes(

          pacificMarkets,

          project.CommissioningCountry

        )||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")

      ? _.round(

          parseFloat((!isNaN(parseFloat(valOOP/currentCurrency?.ConversionRateToLocal))) ? (valOOP/currentCurrency?.ConversionRateToLocal) : currentProfile.CostTotalExternalOperations) +

            (((!isNaN(parseFloat(valCS/currentCurrency?.ConversionRateToLocal))) ? (valCS/currentCurrency?.ConversionRateToLocal) : currentProfile.CostTotalExternalCommercial)

              +

              (currentProfile.VAT) +

              (currentProfile.WHT)),

          2

        )

      : parseFloat(valOOP/currentCurrency?.ConversionRateToLocal))) - (currentProfile.VAT + currentProfile.WHT),
        WBSNumber: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? filterWbsNum[0].WBSNumber : null,
        id: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? filterWbsNum[0].id : null,
        WBS: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? true : false,
        NewOpsInternalwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ?
                            _.round(parseFloat(valOpsInternal) / currentCurrency?.ConversionRateToLocal,2) : null,
        OldOpsInternalwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
                            filterWbsNum[0].CostTotalInternalOperationswbs : null,
        NewCsInternalwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ?
                          _.round(parseFloat(valCsInternal) / currentCurrency?.ConversionRateToLocal,2) : null,
        OldCsInternalwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
                           filterWbsNum[0].CostTotalInternalCommercialwbs :null,
        NewCSOOPwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
                      parseFloat(valCS) / currentCurrency?.ConversionRateToLocal :null,
        OldCSOOPwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
                      filterWbsNum[0].CostTotalExternalCommercialwbs : null,
        NewOpsOOPwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ?
                       parseFloat(valOOP) / currentCurrency?.ConversionRateToLocal : null,
        OldOpsOOPwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
                       filterWbsNum[0].CostTotalExternalOperationswbs : null,
        Total: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
                filterWbsNum[0].Total : null,
        PriceToClientwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
                           filterWbsNum[0].PriceToClientwbs : null,
        MarginPercentwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
                           filterWbsNum[0].MarginPercentwbs : null,
        Overheadswbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
                       filterWbsNum[0].Overheadswbs : null,
        NewMCPMarginwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
                          filterWbsNum[0].MCPMarginwbs : null,
        NewMarginwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
                       newMarginPercent*100 : null,
        OldMarginwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
                       filterWbsNum[0].MarginPercentwbs : null,
        NewOopPercentwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
                           newOopPercent*100 : null,
        OldOopPercentwbs: (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
                           filterWbsNum[0].OutOfPocketCostPercentwbs : 0,
        // OldOpsOOPTotal: _.includes(pacificMarkets, project.CommissioningCountry)
        //                   ? filterWbsNum[0].CostTotalExternalOperationswbs
        //                   : filterWbsNum[0].CostTotalExternalOperationswbs +
        //                     filterWbsNum[0].CostTotalExternalCommercialwbs +
        //                     filterWbsNum[0].WHTwbs +
        //                     filterWbsNum[0].VATwbs,
        // NewOpsOOPTotal: !_.includes(
        //                     pacificMarkets,
        //                     project.CommissioningCountry
        //                   )
        //                   ? _.round(
        //                     parseFloat((!isNaN(parseFloat(valOOP/currentCurrency?.ConversionRateToLocal))) ? (valOOP/currentCurrency?.ConversionRateToLocal) : filterWbsNum[0].CostTotalExternalOperationswbs) +
        //                     (((!isNaN(parseFloat(valCS/currentCurrency?.ConversionRateToLocal))) ? (valCS/currentCurrency?.ConversionRateToLocal) : filterWbsNum[0].CostTotalExternalCommercialwbs)
        //                   +
        //                     (filterWbsNum[0].VATwbs) +
        //                     (filterWbsNum[0].WHTwbs)),
        //                   2
        //                 )
        //                 : parseFloat(valOOP/currentCurrency?.ConversionRateToLocal),
        Source: "USER",
        NewTotalExternalCosts: _.round((!_.includes(
          pacificMarkets,
          project.CommissioningCountry
        ) ||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
      ? _.round(
          parseFloat((!isNaN(parseFloat(valOOP/currentCurrency?.ConversionRateToLocal))) ? (valOOP/currentCurrency?.ConversionRateToLocal) : currentProfile.CostTotalExternalOperations) +
          parseFloat((!isNaN(parseFloat(valCS/currentCurrency?.ConversionRateToLocal))) ? (valCS/currentCurrency?.ConversionRateToLocal) : currentProfile.CostTotalExternalCommercial) +
          (currentProfile.VAT + currentProfile.WHT),
          2
        )
      : parseFloat(valOOP/currentCurrency?.ConversionRateToLocal)),2),
        OldTotalExternalCosts: currentProfile.TotalExternalCosts,
        NewOopPercent: newOopPercent,
        OldOopPercent: currentProfile.OutOfPocketCostPercent,
        NewCsInternal: _.round(parseFloat(valCsInternal) / currentCurrency?.ConversionRateToLocal,2),
        OldCsInternal: currentProfile.CostTotalInternalCommercial,
        NewOpsInternal: _.round(parseFloat(valOpsInternal) / currentCurrency?.ConversionRateToLocal,2),
        OldOpsInternal: currentProfile.CostTotalInternalOperations,
        OldTotalInternalCosts: currentProfile.CostTotalInternalOperations + currentProfile.CostTotalInternalCommercial + CCcost,
        NewTotalInternalCosts: _.round(parseFloat(
          !_.includes(
          pacificMarkets,
          project.CommissioningCountry
        )||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
      ? _.round(
          parseFloat((!isNaN(parseFloat(valOpsInternal/currentCurrency?.ConversionRateToLocal))) ? (valOpsInternal/currentCurrency?.ConversionRateToLocal) : currentProfile.CostTotalInternalOperations) +
          parseFloat(((!isNaN(parseFloat(valCsInternal/currentCurrency?.ConversionRateToLocal))) ? (valCsInternal/currentCurrency?.ConversionRateToLocal) : currentProfile.CostTotalInternalCommercial) +
          (CCcost)
             ),2
          
        )
      : parseFloat(valOpsInternal/currentCurrency?.ConversionRateToLocal)
      ),2),
        NewMargin: newMarginPercent,
        OldMargin: currentProfile.MarginPercent,
        AdjustmentReason: valReason,
        UpdatedBy: userRecord.Email,
        CurrencySelected : currentCurrency.CurrencyUnit,
        CurrencyConversionToLocal: currentCurrency.ConversionRateToLocal,
      };

      axios
        .post("costingprofiles/opsopp/", [obtaineddata])
        .then((res) => {
          let profileTemp = _.cloneDeep(currentProfile);
          console.log('profileTempprofileTemp',profileTemp)
          if (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0){
            console.log('_.cloneDeep(currentProfile)',_.cloneDeep(currentProfile?.WBSLevelCosts))
            console.log('filterOO',profileTemp.WBSLevelCosts.findIndex(obj => obj.WBSNumber === filterWbsNum[0].WBSNumber))
            const indexValue = profileTemp.WBSLevelCosts.findIndex(obj => obj.WBSNumber === filterWbsNum[0].WBSNumber);
              profileTemp.WBSLevelCosts[indexValue].CostTotalExternalOperationswbs = _.round(parseFloat(valOOP) / currentCurrency?.ConversionRateToLocal,2);
              profileTemp.WBSLevelCosts[indexValue].CostTotalExternalCommercialwbs = _.round(parseFloat(valCS) / currentCurrency?.ConversionRateToLocal,2);
              profileTemp.WBSLevelCosts[indexValue].OutOfPocketCostPercentwbs = newOopPercent*100;
              profileTemp.WBSLevelCosts[indexValue].CostTotalInternalCommercialwbs =
              _.round(parseFloat(valCsInternal) / currentCurrency?.ConversionRateToLocal,2);
              profileTemp.WBSLevelCosts[indexValue].CostTotalInternalOperationswbs =
              _.round(parseFloat(valOpsInternal) / currentCurrency?.ConversionRateToLocal,2);
              profileTemp.WBSLevelCosts[indexValue].MarginPercentwbs = newMarginPercent * 100;

          //profile level update
          console.log("profile level update");
          //CostTotalExternalCommercial
          console.log("CostTotalExternalCommercial",profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalExternalCommercialwbs) || 0);
          }, 0));
          profileTemp.CostTotalExternalCommercial = profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalExternalCommercialwbs) || 0);
          }, 0)

          //CostTotalExternalOperations
          console.log("CostTotalExternalOperations",profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalExternalOperationswbs) || 0);
          }, 0));
          profileTemp.CostTotalExternalOperations = profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalExternalOperationswbs) || 0);
          }, 0)

          //TotalExternalCostsWithoutTax
          profileTemp.TotalExternalCostsWithoutTax = (profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalExternalCommercialwbs) || 0);
          }, 0)) + (profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalExternalOperationswbs) || 0);
          }, 0))

          console.log("TotalExternalCostsWithoutTax", profileTemp.TotalExternalCostsWithoutTax);

          //TotalExternalCosts
          let TotalExt = (profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalExternalCommercialwbs) || 0);
          }, 0)) + (profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalExternalOperationswbs) || 0);
          }, 0)) + (parseFloat(profileTemp.VAT)) + (parseFloat(profileTemp.WHT))

          profileTemp.TotalExternalCosts = (profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalExternalCommercialwbs) || 0);
          }, 0)) + (profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalExternalOperationswbs) || 0);
          }, 0)) + (parseFloat(profileTemp.VAT)) + (parseFloat(profileTemp.WHT))

          console.log("TotalExternalCosts",profileTemp.TotalExternalCosts);


          //OutOfPocketCostPercent
          profileTemp.OutOfPocketCostPercent = ((profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalExternalCommercialwbs) || 0);
          }, 0)) + (profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalExternalOperationswbs) || 0);
          }, 0)) + (parseFloat(profileTemp.VAT)) + (parseFloat(profileTemp.WHT)))/(parseFloat(profileTemp.PriceToClient))
          
          console.log("OutOfPocketCostPercent",profileTemp.OutOfPocketCostPercent);

          //CostTotalInternalCommercial
          console.log("CostTotalInternalCommercial",profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalInternalCommercialwbs) || 0);
          }, 0));
          profileTemp.CostTotalInternalCommercial = profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalInternalCommercialwbs) || 0);
          }, 0)

          //CostTotalInternalOperations
          console.log("CostTotalInternalOperations",profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalInternalOperationswbs) || 0);
          }, 0));
          profileTemp.CostTotalInternalOperations =profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalInternalOperationswbs) || 0);
          }, 0)

          //MarginPercent
          let TotalInt = (profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalInternalOperationswbs) || 0);
          }, 0)) + (profileTemp.WBSLevelCosts.reduce((total, element) => {
            return total + (parseFloat(element.CostTotalInternalCommercialwbs) || 0);
          }, 0))

          profileTemp.MarginPercent = ((parseFloat(profileTemp.PriceToClient)) - (TotalExt + TotalInt + parseFloat(profileTemp.CostTotalMCPMargin) + parseFloat(profileTemp.Overheads)))/(parseFloat(profileTemp.PriceToClient))
          console.log("MarginPercent",profileTemp.MarginPercent);
          } else {
            profileTemp.CostTotalExternalOperations =
            _.round(parseFloat(valOOP) / currentCurrency?.ConversionRateToLocal,2); //oop
            profileTemp.CostTotalExternalCommercial =
            _.round(parseFloat(valCS) / currentCurrency?.ConversionRateToLocal,2);
            profileTemp.TotalExternalCostsWithoutTax = _.includes(
              pacificMarkets,
              project.CommissioningCountry
            )&& project.CostingTemplateVersion != "v2.0"
              ? _.round(parseFloat(valOOP) / currentCurrency?.ConversionRateToLocal,2)
              : ( _.round(
                parseFloat((!isNaN(parseFloat(valOOP/currentCurrency?.ConversionRateToLocal))) ? (valOOP/currentCurrency?.ConversionRateToLocal) : currentProfile.CostTotalExternalOperations) +
                parseFloat((!isNaN(parseFloat(valCS/currentCurrency?.ConversionRateToLocal))) ? (valCS/currentCurrency?.ConversionRateToLocal) : currentProfile.CostTotalExternalCommercial),
                2
              )) ; // total oop without tax
            profileTemp.TotalExternalCosts = _.round(parseFloat(!_.includes(
              pacificMarkets,
              project.CommissioningCountry
            )||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
          ? _.round(
              parseFloat((!isNaN(parseFloat(valOOP/currentCurrency?.ConversionRateToLocal))) ? (valOOP/currentCurrency?.ConversionRateToLocal) : currentProfile.CostTotalExternalOperations) +
                (((!isNaN(parseFloat(valCS/currentCurrency?.ConversionRateToLocal))) ? (valCS/currentCurrency?.ConversionRateToLocal) : currentProfile.CostTotalExternalCommercial)
                  +
                  (currentProfile.VAT) +
                  (currentProfile.WHT)),
              2
            )
          : parseFloat(valOOP/currentCurrency?.ConversionRateToLocal)),2); // tota; oop with tax
            profileTemp.OutOfPocketCostPercent = newOopPercent; // oop%
            profileTemp.CostTotalInternalCommercial =
            _.round(parseFloat(valCsInternal) / currentCurrency?.ConversionRateToLocal,2);
            profileTemp.CostTotalInternalOperations =
            _.round(parseFloat(valOpsInternal) / currentCurrency?.ConversionRateToLocal,2);
            profileTemp.MarginPercent = newMarginPercent;
            profileTemp.IsOPSOOPAdjusted = true;
          }
          //we dont need to update Costing profile when updated from Dashboard
          if (!dashboard) {
            if (filterWbsNum?.length === 0 && Object.keys(wbsDropDown)?.length === 0) {
              dispatch(currentCostingActions.updateProfile(profileTemp));
            }
          }
          setProfile(profileTemp);
          setOpsOpp(!opsOpp);
          setvalOOP(
            (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? _.round(
            filterWbsNum[0].CostTotalExternalOperationswbs *
              currentCurrency?.ConversionRateToLocal,
            2
          ) : _.round(
            profileTemp.CostTotalExternalOperations *
              currentCurrency?.ConversionRateToLocal,
            2
          ));
          setInputOpsExt(_.round(
            profileTemp.CostTotalExternalOperations *
            currentCurrency?.ConversionRateToLocal,
            2
          ));
          setvalCS(
            (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? _.round(
              filterWbsNum[0].CostTotalExternalCommercialwbs *
              currentCurrency?.ConversionRateToLocal,
            2
          ) : _.round(
            profileTemp.CostTotalExternalCommercial *
              currentCurrency?.ConversionRateToLocal,
            2
          ));
          setInputCsExt(_.round(
            profileTemp.CostTotalExternalCommercial *
              currentCurrency?.ConversionRateToLocal,
            2
          ));
          setvalOpsInternal(
            (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? _.round(
            (filterWbsNum[0].CostTotalInternalOperationswbs || 0)*
              currentCurrency?.ConversionRateToLocal,
            2
          ) : _.round(
            (profileTemp.CostTotalInternalOperations || 0)*
              currentCurrency?.ConversionRateToLocal,
            2
          ));
          setInputOpsInt(_.round(
            (profileTemp.CostTotalInternalOperations || 0)*
              currentCurrency?.ConversionRateToLocal,
            2
          ));
          setvalCsInternal(
            (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ?
            _.round(
            (filterWbsNum[0].CostTotalInternalCommercialwbs || 0)*
              currentCurrency?.ConversionRateToLocal,
            2
          ) : _.round(
            (profileTemp.CostTotalInternalCommercial || 0)*
              currentCurrency?.ConversionRateToLocal,
            2
          ));
          setInputCsInt(_.round(
            (profileTemp.CostTotalInternalCommercial || 0)*
              currentCurrency?.ConversionRateToLocal,
            2
          ));
          setvalTotalExt("");
          setvalTotalInt("");
          setNewOopPercent(profileTemp.OutOfPocketCostPercent);
          setNewMarginPercent(profileTemp.MarginPercent);
          setReason("");
          setIsChangeFlagOps(true);
          setIsChangeFlagCS(true);
          setIsChangeFlagOpsInt(true);
          setIsChangeFlagCSInt(true);
          //to update DashBoard
          //   if(dashboard)
          //  {
          projects.forEach((currentProject) => {
            if (currentProject.ProjectId == project.ProjectId) {
              currentProject.CostingProfiles.forEach(
                (currentProfile, index) => {
                  if (currentProfile.id == profileTemp.id) {
                    currentProject.CostingProfiles[index] = profileTemp;
                  }
                }
              );
            }
          });
          dispatch(projectsActions.setProjects(projects));
          dispatch(recordLoadEnd());
          toastr.success("Submitted Succesfully ");
          if (!dashboard) {
          window.location.reload();
          }
        })
        .catch((error) => {
          console.log("errr");
          dispatch(recordLoadEnd());
        });
    }
  };
  const getCancel = () => {
    setOpsOpp(!opsOpp);
    setvalOOP(
      (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
      _.round(
      filterWbsNum[0].CostTotalExternalOperationswbs *
        currentCurrency?.ConversionRateToLocal,
      2
    ) : _.round(
      currentProfile.CostTotalExternalOperations *
        currentCurrency?.ConversionRateToLocal,
      2
    ));
    setInputOpsExt(_.round(
      currentProfile.CostTotalExternalOperations *
      currentCurrency?.ConversionRateToLocal,
      2
    ));
    setvalCS(
      (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? _.round(
      filterWbsNum[0].CostTotalExternalCommercialwbs *
        currentCurrency?.ConversionRateToLocal,
      2
    ) : _.round(
      currentProfile.CostTotalExternalCommercial *
        currentCurrency?.ConversionRateToLocal,
      2
    ));
    setInputCsExt(_.round(
      currentProfile.CostTotalExternalCommercial *
        currentCurrency?.ConversionRateToLocal,
      2
    ));
    setvalOpsInternal(
      (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? _.round(
      (filterWbsNum[0].CostTotalInternalOperationswbs || 0)*
        currentCurrency?.ConversionRateToLocal,
      2
    ) : _.round(
      (currentProfile.CostTotalInternalOperations || 0)*
        currentCurrency?.ConversionRateToLocal,
      2
    ));
    setInputOpsInt(_.round(
      (currentProfile.CostTotalInternalOperations || 0)*
        currentCurrency?.ConversionRateToLocal,
      2
    ));
    setvalCsInternal(
      (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ? 
      _.round(
      (filterWbsNum[0].CostTotalInternalCommercialwbs || 0)*
        currentCurrency?.ConversionRateToLocal,
      2
    ) : _.round(
      (currentProfile.CostTotalInternalCommercial || 0)*
        currentCurrency?.ConversionRateToLocal,
      2
    ));
    setInputCsInt(_.round(
      (currentProfile.CostTotalInternalCommercial || 0)*
        currentCurrency?.ConversionRateToLocal,
      2
    ));
    setvalTotalExt("");
    setvalTotalInt("");
    setNewOopPercent(currentProfile.OutOfPocketCostPercent);
    setNewMarginPercent(currentProfile.MarginPercent);
    setReason("");
    setNegativeValues({ oop: false, reasonforadjustment: false });
    setIsChangeFlagOps(true);
    setIsChangeFlagCS(true);
    setIsChangeFlagOpsInt(true);
    setIsChangeFlagCSInt(true);
  };
  function historylogData() {
    let costs = [
      "OldOpsOOP",
      "NewOpsOOP",
      "OldCSOOP",
      "NewCSOOP",
      "OldTotalExternalCosts",
      "NewTotalExternalCosts",
      "OldCsInternal",
      "NewCsInternal",
      "OldOpsInternal",
      "NewOpsInternal",
      "OldTotalInternalCosts",
      "NewTotalInternalCosts",
    ];
    
    setHistoryLogData(historyData);

    openHistoryLog(true);
      
  }
  var imgStyle = {
    width: "150px",
  };
  const updateWBS = (e) => {
    console.log('eupdateWBS',e)
    if(!isChangeFlagOps || !isChangeFlagCS || !isChangeFlagOpsInt || !isChangeFlagCSInt){
      toastr.error(
        "Please note that changing WBS Number without submitting is not allowed."
      );
     } else {
      setWbsDropDown(e);
      const commissionedData=historyData.filter((r)=>r.WBSNumber===e.value);
      console.log("afterchange",commissionedData);
      setFilteredCommisionData(commissionedData);
     }
  }

  const handlecurrencychange = (e) => {
    console.log("handlecurrencychange ",e)
    setCurrentCurrency(e)
       
  }
  const totalExtCostValue = () =>{
    if(!_.includes(pacificMarkets,project?.CommissioningCountry)){
      if((isChangeFlagOps && isChangeFlagCS) && 
         (filterWbsNum?.length === 0 && Object.keys(wbsDropDown)?.length === 0)){
          return (_.round(
            (currentProfile?.CostTotalExternalOperations + currentProfile?.CostTotalExternalCommercial + currentProfile?.VAT + currentProfile?.WHT) *
              currentCurrency?.ConversionRateToLocal,
            2
          )).toFixed(2)
         } else if((isChangeFlagOps && isChangeFlagCS) && 
         (filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0)){
          return (_.round(
            (filterWbsNum[0]?.CostTotalExternalOperationswbs + filterWbsNum[0]?.CostTotalExternalCommercialwbs + filterWbsNum[0]?.VATwbs + filterWbsNum[0]?.WHTwbs) *
              currentCurrency?.ConversionRateToLocal,
            2
          )).toFixed(2)
         } else {
           if((filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0)){
            return (_.round(
              parseFloat((!isNaN(parseFloat(valOOP))) ? valOOP : filterWbsNum[0]?.CostTotalExternalOperationswbs *
              currentCurrency?.ConversionRateToLocal) +
              parseFloat((!isNaN(parseFloat(valCS))) ? valCS : filterWbsNum[0]?.CostTotalExternalCommercialwbs *
                currentCurrency?.ConversionRateToLocal) + (filterWbsNum[0]?.VATwbs * currentCurrency?.ConversionRateToLocal) 
                + (filterWbsNum[0]?.WHTwbs * currentCurrency?.ConversionRateToLocal),
              2
            )).toFixed(2)
           } else {
            return (_.round(
              parseFloat((!isNaN(parseFloat(valOOP))) ? valOOP : currentProfile?.CostTotalExternalOperations *
              currentCurrency?.ConversionRateToLocal) +
              parseFloat((!isNaN(parseFloat(valCS))) ? valCS : currentProfile?.CostTotalExternalCommercial *
                currentCurrency?.ConversionRateToLocal) + (currentProfile?.VAT * currentCurrency?.ConversionRateToLocal) 
                + (currentProfile?.WHT * currentCurrency?.ConversionRateToLocal),
              2
            )).toFixed(2)
           }
         }
    } else {
     return _.round(parseFloat(valOOP),2).toFixed(2)
    }
  }

  const getMarginPercent = () => {
    if(isChangeFlagOps && isChangeFlagCS && isChangeFlagOpsInt && isChangeFlagCSInt
       && (filterWbsNum?.length === 0 && Object.keys(wbsDropDown)?.length === 0)){
      return `${(_.round(
        currentProfile.MarginPercent * 100,
        2
      )).toFixed(2)}%`
    } else if(isChangeFlagOps && isChangeFlagCS && isChangeFlagOpsInt && isChangeFlagCSInt
      && (filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0)){
        return `${(_.round(
          filterWbsNum[0].MarginPercentwbs,
          2
        )).toFixed(2)}%`
    }else if(!isNaN(newMarginPercent)){
      return `${(_.round(newMarginPercent * 100, 2)).toFixed(2)}%`
    } else {
      return `${( _.round(
        currentProfile.MarginPercent * 100,
        2
      )).toFixed(2)}%`
    }
  }

  const getOOPpercent = () => {
    // `${
    //   (isChangeFlagOps && isChangeFlagCS) ?
    //   (_.round(
    //     currentProfile.OutOfPocketCostPercent * 100,
    //     2
    //   )).toFixed(2) :
    //   (!isNaN(newOopPercent) ? (_.round(newOopPercent * 100, 2)).toFixed(2) : (_.round(
    //   currentProfile.OutOfPocketCostPercent * 100,
    //   2
    // )).toFixed(2)
    // )}%`
    
    if(isChangeFlagOps && isChangeFlagCS && 
      (filterWbsNum?.length === 0 && Object.keys(wbsDropDown)?.length === 0)){
      return `${(_.round(
        currentProfile.OutOfPocketCostPercent * 100,
        2
      )).toFixed(2)}%`
    } else if(isChangeFlagOps && isChangeFlagCS && 
      (filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0)){
      return `${(_.round(
        filterWbsNum[0].OutOfPocketCostPercentwbs,
        2
      )).toFixed(2)}%`
    } else if(!isNaN(newOopPercent)){
      return `${(_.round(newOopPercent * 100, 2)).toFixed(2)}%`
    } else {
      return `${(_.round(
        currentProfile.OutOfPocketCostPercent * 100,
        2
      )).toFixed(2)}%`
    }
  }

  const getTotalIntCost = () => {
  //   !_.includes(
  //     pacificMarkets,
  //     project.CommissioningCountry
  //   )||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
  // ? (isChangeFlagOpsInt && isChangeFlagCSInt) ?
  // (_.round(
  //   (((currentProfile.CostTotalInternalCommercial || 0) + (currentProfile.CostTotalInternalOperations || 0) + (CCcost) )*
  //     currentCurrency?.ConversionRateToLocal),
  //   2
  // )).toFixed(2)
  // : (_.round(
  //     parseFloat((!isNaN(parseFloat(valOpsInternal))) ? valOpsInternal  : currentProfile.CostTotalInternalOperations*currentCurrency?.ConversionRateToLocal) +
  //     parseFloat(((!isNaN(parseFloat(valCsInternal))) ? valCsInternal : currentProfile.CostTotalInternalCommercial*currentCurrency?.ConversionRateToLocal) + (CCcost*currentCurrency?.ConversionRateToLocal)
  //        ),2

  //   )).toFixed(2)
  // : _.round(parseFloat(valOpsInternal),2).toFixed(2)
  if(!_.includes(
      pacificMarkets,
      project.CommissioningCountry
    )||(_.includes(pacificMarkets, project.CommissioningCountry)  && project?.CostingTemplateVersion == "v2.0")){
      if(isChangeFlagOpsInt && isChangeFlagCSInt && (filterWbsNum?.length === 0 && Object.keys(wbsDropDown)?.length === 0)){
        return (_.round(
          (((currentProfile?.CostTotalInternalCommercial || 0) + (currentProfile?.CostTotalInternalOperations || 0) + (CCcost) )*
            currentCurrency?.ConversionRateToLocal),
          2
        )).toFixed(2)
      } else if(isChangeFlagOpsInt && isChangeFlagCSInt && (filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0)){
        return (_.round(
          (((filterWbsNum[0]?.CostTotalInternalCommercialwbs || 0) + (filterWbsNum[0]?.CostTotalInternalOperationswbs || 0) + (CCcost) )*
            currentCurrency?.ConversionRateToLocal),
          2
        )).toFixed(2)
      } else {
        if((filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0)){
         return (_.round(
           parseFloat((!isNaN(parseFloat(valOpsInternal))) ? valOpsInternal : filterWbsNum[0]?.CostTotalInternalOperationswbs *
           currentCurrency?.ConversionRateToLocal) +
           parseFloat((!isNaN(parseFloat(valCsInternal))) ? valCsInternal : filterWbsNum[0]?.CostTotalInternalCommercialwbs *
             currentCurrency?.ConversionRateToLocal) + (CCcost*
              currentCurrency?.ConversionRateToLocal),
           2
         )).toFixed(2)
        } else {
         return (_.round(
           parseFloat((!isNaN(parseFloat(valOpsInternal))) ? valOpsInternal : currentProfile?.CostTotalInternalOperations *
           currentCurrency?.ConversionRateToLocal) +
           parseFloat((!isNaN(parseFloat(valCsInternal))) ? valCsInternal : currentProfile?.CostTotalInternalCommercial *
             currentCurrency?.ConversionRateToLocal)+ (CCcost*
              currentCurrency?.ConversionRateToLocal),
           2
         )).toFixed(2)
        }
      }
    } else {
      return _.round(parseFloat(valOpsInternal),2).toFixed(2)
    }
  }
  const disableInputHandler=(filterwbsno)=>{
    // console.log(filterwbsno);
    let r= false;
    let c= false;
    wbslevelcost.forEach((val,index)=>{
      // console.log(val)
      if(val.WBSNumber==filterwbsno){
        r=val.IsClosed;
      }
    })
    wbslevelcost.forEach((val,index)=>{
      // console.log(val)
      if(val.WBSNumber==filterwbsno){
        c=val.IsCancel;
      }
    })
    if(c==true || r==true){
      return true;
    }
    else{
      return false;
    }
    // console.log(r);
    
  }
  return (
    <>
      <Modal
        centered={true}
        isOpen={opsOpp}
        backdrop="static"
        toggle={getCancel}
        size="lg"
        style = {{maxWidth: '1200px', margin: 'auto'}}
      >
        <ModalHeader toggle={getCancel}>
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <div>
          <span className="modaltitle modalops">Cost Adjustment</span>
          {wbsNumbers?.length > 0 && <React.Fragment> 
          <Row>
            <Col>
              <Label className="h5">WBS Number</Label>
            </Col>
          </Row>
           <Row>
             <Col>
              <Select
                className="react-select-container mb-3 lg-12 md-12"
                classNamePrefix="react-select"
                options={wbsNumbers.map((item) => {
                  return { value: item, label: item };
                })}
                isSearchable
                value={wbsDropDown}
                // isDisabled= {project?.ProjectStatus == "7"}
                onChange={(e) => {
                  updateWBS(e)
                  // updateProject({ ProposalOwnerEmail: e });
                }}
                
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label className="h5">Currency</Label>
            </Col>
          </Row>
           <Row>
             <Col>
              <Select
                className="react-select-container mb-3 lg-12 md-12"
                // classNamePrefix="react-select"
                options={currencyDropDown?.map((c) => {
                  return {
                    value: `${c.Code}-${c.CurrencyUnit}`,
                    label: `${c.Label} (1USD = ${c.ConversionRateToLocal}${c.CurrencyUnit})`,
                    ConversionRateToLocal : `${c.ConversionRateToLocal}`,
                    CurrencyUnit : `${c.CurrencyUnit}`
                  };
                })}
                isSearchable
                value={currentCurrency}
                onChange={(e) => {
                    handlecurrencychange(e)
                }
                }
                
              />
            </Col>
          </Row>
          </React.Fragment>}
          
                      { 
                        <a
                          className="cost-breakdown-link"
                          style={{float: "right", margin: "10px"}}
                          onClick={(e) => {
                            e.stopPropagation();
                            historylogData();
                          }}
                        >
                          Cost Adjustments History
                        </a>
                      }

                    </div>
                    { Object.keys(wbsDropDown).length > 0 && filterWbsNum?.length > 0
                     ?
                        <h9
                          className="modaltext"
                          style={{float: "left", margin: "10px"}}
                        >
                          Please note that values shown here are at WBS ({wbsDropDown.value}) level.
                        </h9>
                      : <h9
                      className="modaltext"
                      style={{float: "left", margin: "10px", marginTop: "10px"}}
                    >
                      Please note that values shown here are at Profile ({currentProfile?.Project?.ProjectId}) level.
                    </h9>
                      }
                    <Container fluid={currentProfile?.IsTracker}>
                    <Modal
              isOpen={isHistoryLogOpen}
              backdrop="static"
              size="xl"
              toggle={() => openHistoryLog(!isHistoryLogOpen)}
            >
              <ModalHeader toggle={() => openHistoryLog(!isHistoryLogOpen)} centered={true}>
                {/* <h4>Log of Past OOP Adjustments done</h4>
                <h7>
                  Note: Cost Displayed here is in {profile.CostInputCurrency}
                </h7> */}
                {/* <img src={logo} className="modallogo" /> */}
              </ModalHeader>
              <div>
                <span className="modaltitle modaltitleDisplay">
                  Log of Past Cost Adjustments done
                </span>
                <div>
                  <h7>
                    Note: Cost Displayed here is in {currentCurrency?.value}
                  </h7>
                </div>
              </div>
              <ModalBody>
                <OopAdjustmentLog
                  HistoryLogData={HistoryLogData}
                ></OopAdjustmentLog>
              </ModalBody>
              <ModalFooter>
                <Row className="justify-content-end">
                  <Button
                    size="sm"
                    color="secondary"
                    onClick={() => openHistoryLog(!isHistoryLogOpen)}
                  >
                    Close
                  </Button>
                </Row>
              </ModalFooter>
            </Modal>
            </Container>

        <ModalBody>
          <Table>
            <div className="oops-main-div">
            <div className="oopspopupborder" style={{width:"380px"}}></div>
              <div className="oopspopupborder" style={{width:"755px"}}></div>

              <Row className="m-0 mb-2">
              <Col>
                  <span className="modaltext">Commissioned - Original Cost</span>
                </Col>
                <Col>
                  <span className="modaltext">Existing</span>
                </Col>
                <Col>
                  <span className="modaltext">Adjusted</span>
                </Col>
              </Row>

              <Row className="m-0 mb-2">
              <Col style={{marginTop:"5px"}}>
                  <Label>OPS External (OOP)</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filteredCommissionedData && filteredCommissionedData?.length>0)? (
                                ( (_.round(filteredCommissionedData[filteredCommissionedData.length-1].OldOpsOOP,2)).toFixed(2)) )
                        :(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ? 
                        (_.round(
                          filterWbsNum[0]?.CostTotalExternalOperationswbs *
                            currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)
                        : (_.round(
                          currentProfile?.CostTotalExternalOperations *
                          currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                  <Label>OPS External (OOP)</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ? 
                        (_.round(
                          filterWbsNum[0]?.CostTotalExternalOperationswbs *
                          currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2) 
                        : (_.round(
                          currentProfile?.CostTotalExternalOperations *
                          currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                       currentCurrency?.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                  <Col style={{marginTop:"5px"}}>
                  <Label>
                  OPS External (OOP)
                  </Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={inputOpsExt}
                      className={negativeValues.oop && "oopschecking"}
                      onChange={(e) => getOpsOop(e)}
                      disabled={ filterWbsNum?.length > 0 && disableInputHandler(filterWbsNum[0].WBSNumber)}
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency?.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
              {(!_.includes(pacificMarkets, project.CommissioningCountry)
              ||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
              )&& (
                <Row className="m-0 mb-2">
                   <Col style={{marginTop:"5px"}}>
                    <Label>CS External (OOP)</Label>
                  </Col>
                  <Col style={{marginTop:"5px"}}>
                    <InputGroup className="m-0">
                      <Input
                        type="number"
                        step="any"
                        value={(filteredCommissionedData && filteredCommissionedData?.length>0) ? (
                              ( (_.round(filteredCommissionedData[filteredCommissionedData.length-1].OldCSOOP,2)).toFixed(2)))
                          :(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ?
                          (_.round(
                            filterWbsNum[0]?.CostTotalExternalCommercialwbs *
                              currentCurrency?.ConversionRateToLocal,
                            2
                          )).toFixed(2)
                          : (_.round(
                          currentProfile?.CostTotalExternalCommercial *
                            currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)}
                        disabled
                      ></Input>
                      <InputGroupAddon addonType="append">
                        
                        {
                          currentCurrency?.CurrencyUnit
                        }
                      </InputGroupAddon>
                      </InputGroup>
                  </Col>
                  <Col style={{marginTop:"5px"}}>
                    <Label>CS External (OOP)</Label>
                  </Col>
                  <Col style={{marginTop:"5px"}}>
                    <InputGroup className="m-0">
                      <Input
                        type="number"
                        step="any"
                        value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ?
                          (_.round(
                            filterWbsNum[0]?.CostTotalExternalCommercialwbs *
                              currentCurrency?.ConversionRateToLocal,
                            2
                          )).toFixed(2)
                          : (_.round(
                          currentProfile?.CostTotalExternalCommercial *
                            currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)}
                        disabled
                      ></Input>
                      <InputGroupAddon addonType="append">
                        
                        {
                          currentCurrency?.CurrencyUnit
                        }
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col style={{marginTop:"5px"}}>
                  <Label>CS External (OOP)</Label>
                  </Col>
                  <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={inputCsExt}
                      className={negativeValues.oop && "oopschecking"}
                      disabled={ filterWbsNum?.length > 0 && disableInputHandler(filterWbsNum[0].WBSNumber)}
                      onChange={(e) => getCsOop(e)}
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency?.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                  </Col>
                </Row>
              )}

               <Row className="m-0 mb-2">
               <Col style={{marginTop:"5px"}}>
                  <Label>Tax</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={
                        (!_.includes(
                          pacificMarkets,
                          project.CommissioningCountry
                        )||(_.includes(pacificMarkets, project?.CommissioningCountry)  && project?.CostingTemplateVersion == "v2.0")) &&
                           ((filterWbsNum?.length === 0 && Object.keys(wbsDropDown)?.length === 0))
                          ? _.round(
                            ((currentProfile.VAT *
                              currentCurrency?.ConversionRateToLocal) +
                              (currentProfile.WHT *
                              currentCurrency?.ConversionRateToLocal)),
                              2
                            )
                          : ((filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) 
                          ? _.round(
                            ((filterWbsNum[0]?.VATwbs *
                              currentCurrency?.ConversionRateToLocal) +
                              (filterWbsNum[0]?.WHTwbs *
                              currentCurrency?.ConversionRateToLocal)),
                              2
                            ) : _.round(
                            ((currentProfile?.VAT *
                              currentCurrency?.ConversionRateToLocal) +
                              (currentProfile?.WHT *
                              currentCurrency?.ConversionRateToLocal)),
                              2
                            ))
                      }
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency?.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>

               <Col style={{marginTop:"5px"}}>
                  <Label>Tax</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={
                        (!_.includes(
                          pacificMarkets,
                          project.CommissioningCountry
                        )||(_.includes(pacificMarkets, project?.CommissioningCountry)  && project?.CostingTemplateVersion == "v2.0")) &&
                           ((filterWbsNum?.length === 0 && Object.keys(wbsDropDown)?.length === 0))
                          ? _.round(
                            ((currentProfile.VAT *
                              currentCurrency?.ConversionRateToLocal) +
                              (currentProfile.WHT *
                              currentCurrency?.ConversionRateToLocal)),
                              2
                            )
                          : ((filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) 
                          ? _.round(
                            ((filterWbsNum[0]?.VATwbs *
                              currentCurrency?.ConversionRateToLocal) +
                              (filterWbsNum[0]?.WHTwbs *
                              currentCurrency?.ConversionRateToLocal)),
                              2
                            ) : _.round(
                            ((currentProfile?.VAT *
                              currentCurrency?.ConversionRateToLocal) +
                              (currentProfile?.WHT *
                              currentCurrency?.ConversionRateToLocal)),
                              2
                            ))
                      }
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                       currentCurrency?.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                  <Label>Tax</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={
                        (!_.includes(
                          pacificMarkets,
                          project.CommissioningCountry
                        )||(_.includes(pacificMarkets, project?.CommissioningCountry)  && project?.CostingTemplateVersion == "v2.0")) &&
                           (filterWbsNum?.length === 0 && Object.keys(wbsDropDown)?.length === 0) 
                          ? _.round(
                            ((currentProfile?.VAT *
                              currentCurrency?.ConversionRateToLocal) +
                              (currentProfile?.WHT *
                                currentCurrency?.ConversionRateToLocal)),
                            2
                          )
                          : ((filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ?
                            _.round(
                            ((filterWbsNum[0]?.VATwbs *
                              currentCurrency?.ConversionRateToLocal) +
                              (filterWbsNum[0]?.WHTwbs *
                                currentCurrency?.ConversionRateToLocal)),
                            2
                            ): _.round(
                            ((currentProfile?.VAT *
                              currentCurrency?.ConversionRateToLocal) +
                              (currentProfile?.WHT *
                                currentCurrency?.ConversionRateToLocal)),
                            2
                          ))
                      }
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                       currentCurrency?.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>

              <Row className="m-0 mb-2">
              <Col style={{marginTop:"5px"}}>
                  <Label><b>Total External Cost (OOP + Tax)</b></Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filteredCommissionedData && filteredCommissionedData?.length>0) ?(_.round(filteredCommissionedData[filteredCommissionedData.length-1].OldTotalExternalCosts+ (currentProfile?.VAT *
                        currentCurrency?.ConversionRateToLocal) +
                        (currentProfile?.WHT *
                          currentCurrency?.ConversionRateToLocal),2)).toFixed(2):
                        (!_.includes(
                          pacificMarkets,
                          project.CommissioningCountry
                        )||(_.includes(pacificMarkets, project?.CommissioningCountry)  && project?.CostingTemplateVersion == "v2.0")) &&
                           (filterWbsNum?.length === 0 && Object.keys(wbsDropDown)?.length === 0)
                          ? (_.round(
                            (currentProfile?.CostTotalExternalOperations + currentProfile?.CostTotalExternalCommercial + currentProfile?.VAT + currentProfile?.WHT) *
                                currentCurrency?.ConversionRateToLocal,
                              2
                            )).toFixed(2)
                          : ((filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ?
                          (_.round(
                            (filterWbsNum[0]?.CostTotalExternalOperationswbs + filterWbsNum[0]?.CostTotalExternalCommercialwbs + filterWbsNum[0]?.VATwbs + filterWbsNum[0]?.WHTwbs) *
                                currentCurrency?.ConversionRateToLocal,
                              2
                            )).toFixed(2)
                          : (_.round(
                            currentProfile?.CostTotalExternalOperations *
                              currentCurrency?.ConversionRateToLocal,
                            2
                          )).toFixed(2))
                      }
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                  <Label><b>Total External Cost (OOP + Tax)</b></Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={
                        (!_.includes(
                          pacificMarkets,
                          project.CommissioningCountry
                        )||(_.includes(pacificMarkets, project?.CommissioningCountry)  && project?.CostingTemplateVersion == "v2.0")) &&
                           (filterWbsNum?.length === 0 && Object.keys(wbsDropDown)?.length === 0)
                          ? (_.round(
                            (currentProfile?.CostTotalExternalOperations + currentProfile?.CostTotalExternalCommercial + currentProfile?.VAT + currentProfile?.WHT) *
                                currentCurrency?.ConversionRateToLocal,
                              2
                            )).toFixed(2)
                          : ((filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ?
                          (_.round(
                            (filterWbsNum[0]?.CostTotalExternalOperationswbs + filterWbsNum[0]?.CostTotalExternalCommercialwbs + filterWbsNum[0]?.VATwbs + filterWbsNum[0]?.WHTwbs) *
                                currentCurrency?.ConversionRateToLocal,
                              2
                            )).toFixed(2)
                          : (_.round(
                            currentProfile?.CostTotalExternalOperations *
                              currentCurrency?.ConversionRateToLocal,
                            2
                          )).toFixed(2))
                      }
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>

                <Col style={{marginTop:"5px"}}>
                  <Label><b>Total External Cost (OOP + Tax)</b></Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={totalExtCostValue()}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="m-0 mb-2"></Row>
              <Row className="m-0 mb-2">
              <Col>
                  <Label>OPS Internal</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filteredCommissionedData && filteredCommissionedData?.length>0) ?
                        (( (_.round(filteredCommissionedData[filteredCommissionedData.length-1].OldOpsInternal,2)).toFixed(2)))
                        
                        :(filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ?
                        (_.round(
                          (filterWbsNum[0]?.CostTotalInternalOperationswbs || 0)*
                            currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)
                        : (_.round(
                        (currentProfile?.CostTotalInternalOperations || 0)*
                          currentCurrency?.ConversionRateToLocal,
                        2
                      )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                       currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col>
                  <Label>OPS Internal</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown).length > 0) ?
                        (_.round(
                          (filterWbsNum[0]?.CostTotalInternalOperationswbs || 0)*
                            currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)
                        : (_.round(
                        (currentProfile?.CostTotalInternalOperations || 0)*
                          currentCurrency?.ConversionRateToLocal,
                        2
                      )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                <Label>OPS Internal</Label>
                </Col>
                <Col>
                <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={inputOpsInt}
                      className={negativeValues?.oop && "oopschecking"}
                      disabled={ filterWbsNum?.length > 0 && disableInputHandler(filterWbsNum[0].WBSNumber)}
                      onChange={(e) => getOpsInternal(e)}
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                       currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                </Row>
                <Row className="m-0 mb-2">
                <Col>
                  <Label>CS Internal</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={ (filteredCommissionedData && filteredCommissionedData?.length>0)?
                        (( (_.round(filteredCommissionedData[filteredCommissionedData.length-1].OldCsInternal,2)).toFixed(2)))
                          
                        :(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ?
                        (_.round(
                          (filterWbsNum[0]?.CostTotalInternalCommercialwbs || 0)*
                            currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)
                        : (_.round(
                        (currentProfile?.CostTotalInternalCommercial || 0)*
                          currentCurrency?.ConversionRateToLocal,
                        2
                      )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                      currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col>
                  <Label>CS Internal</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ?
                        (_.round(
                          (filterWbsNum[0]?.CostTotalInternalCommercialwbs || 0)*
                            currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)
                        : (_.round(
                        (currentProfile?.CostTotalInternalCommercial || 0)*
                          currentCurrency?.ConversionRateToLocal,
                        2
                      )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                <Label>CS Internal</Label>
                </Col>
                <Col>
                <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={inputCsInt}
                      className={negativeValues?.oop && "oopschecking"}
                      disabled={ filterWbsNum?.length > 0 && disableInputHandler(filterWbsNum[0].WBSNumber)}
                      onChange={(e) => getCsInternal(e)}
                    ></Input>
                    <InputGroupAddon addonType="append">

                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                </Row>
                
                {(CCcost > 0) && <Row className="m-0 mb-2">
                <Col style={{marginTop:"5px"}}>
                  <Label>Total Coordinating Cost</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={
                        _.round(CCcost* currentCurrency?.ConversionRateToLocal,2).toFixed(2)
                      }
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                  <Label>Total Coordinating Cost</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={
                        _.round(CCcost * currentCurrency?.ConversionRateToLocal, 2).toFixed(2)
                      }
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                </Row>}
                <Row className="m-0 mb-2">
                <Col style={{marginTop:"5px"}}>
                  <Label><b>Total Internal Cost</b></Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={  (filteredCommissionedData && filteredCommissionedData?.length>0) ?
                        (( (_.round(filteredCommissionedData[filteredCommissionedData.length-1].OldTotalInternalCosts,2)).toFixed(2)))
                        
                        :(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ? (_.round(
                        (((filterWbsNum[0]?.CostTotalInternalCommercialwbs || 0) + (filterWbsNum[0]?.CostTotalInternalOperationswbs || 0) + (CCcost) )*
                          currentCurrency?.ConversionRateToLocal),
                        2
                      )).toFixed(2) : (_.round(
                      (((currentProfile?.CostTotalInternalCommercial || 0) + (currentProfile?.CostTotalInternalOperations || 0) + (CCcost) )*
                      currentCurrency?.ConversionRateToLocal),
                        2
                      )).toFixed(2) }
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                  <Label><b>Total Internal Cost</b></Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ? (_.round(
                        (((filterWbsNum[0]?.CostTotalInternalCommercialwbs || 0) + (filterWbsNum[0]?.CostTotalInternalOperationswbs || 0) + (CCcost) )*
                          currentCurrency?.ConversionRateToLocal),
                        2
                      )).toFixed(2) : (_.round(
                      (((currentProfile?.CostTotalInternalCommercial || 0) + (currentProfile?.CostTotalInternalOperations || 0) + (CCcost) )*
                      currentCurrency?.ConversionRateToLocal),
                        2
                      )).toFixed(2) }
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                       currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                <Label><b>Total Internal Cost</b></Label>
                </Col>
                <Col>
                <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={getTotalIntCost()}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                       currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                </Row>
                <Row className="m-0 mb-2"></Row>
                <Row className="m-0 mb-2">
                <Col style={{marginTop:"5px"}}>
                  <Label>Price To Client</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ?
                        (_.round(
                          filterWbsNum[0]?.PriceToClientwbs *
                            currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)
                        : (_.round(
                        currentProfile?.PriceToClient *
                          currentCurrency?.ConversionRateToLocal,
                        2
                      )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                       currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                  <Label>Price To Client</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ?
                        (_.round(
                          filterWbsNum[0]?.PriceToClientwbs *
                            currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)
                        : (_.round(
                        currentProfile?.PriceToClient *
                          currentCurrency?.ConversionRateToLocal,
                        2
                      )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                  <Label>Price To Client</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ?
                        (_.round(
                          filterWbsNum[0]?.PriceToClientwbs *
                            currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)
                        : (_.round(
                        currentProfile?.PriceToClient *
                          currentCurrency?.ConversionRateToLocal,
                        2
                      )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                       currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="m-0 mb-2"></Row>
              <Row className="m-0 mb-2">
              <Col style={{marginTop:"5px"}}>
                  <Label> <b> OOP% </b> </Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="text"
                      step="any"
                      value={
                          (filteredCommissionedData && filteredCommissionedData?.length>0)? 
                           `${(_.round((filteredCommissionedData[filteredCommissionedData.length-1].OldOopPercent),2)).toFixed(2)}%` 
                        :(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ?
                        `${(_.round(
                          filterWbsNum[0]?.OutOfPocketCostPercentwbs,
                          2
                        )).toFixed(2)}%`
                        : `${(_.round(
                        currentProfile?.OutOfPocketCostPercent * 100,
                        2
                      )).toFixed(2)}%`}
                      disabled
                    ></Input>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                  <Label> <b> OOP% </b> </Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="text"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ?
                        `${(_.round(
                          filterWbsNum[0]?.OutOfPocketCostPercentwbs,
                          2
                        )).toFixed(2)}%`
                        : `${(_.round(
                        currentProfile?.OutOfPocketCostPercent * 100,
                        2
                      )).toFixed(2)}%`}
                      disabled
                    ></Input>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                  <Label> <b>OOP%</b> </Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="text"
                      step="any"
                      value={getOOPpercent()}
                      disabled
                    ></Input>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="m-0 mb-2"></Row>
              <Row className="m-0 mb-2">
              <Col style={{ marginTop: "5px" }}>
                  <Label>MCP Margin</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ? 
                        (_.round(
                          filterWbsNum[0]?.CostTotalMCPMarginwbs *
                          currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)
                       : (_.round(
                        currentProfile?.CostTotalMCPMargin *
                        currentCurrency?.ConversionRateToLocal,
                        2
                      )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col style={{ marginTop: "5px" }}>
                  <Label>MCP Margin</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ? 
                        (_.round(
                          filterWbsNum[0]?.CostTotalMCPMarginwbs *
                          currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)
                       : (_.round(
                        currentProfile?.CostTotalMCPMargin *
                        currentCurrency?.ConversionRateToLocal,
                        2
                      )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                  <Label>MCP Margin</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ? 
                        (_.round(
                          filterWbsNum[0]?.CostTotalMCPMarginwbs *
                          currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)
                       : (_.round(
                        currentProfile?.CostTotalMCPMargin *
                        currentCurrency?.ConversionRateToLocal,
                        2
                      )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                </Row>
                <Row className="m-0 mb-2">
                <Col style={{ marginTop: "5px" }}>
                  <Label>Overhead %</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ? 
                        (_.round(
                          filterWbsNum[0]?.Overheadswbs *
                          currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)
                       : (_.round(
                        currentProfile?.Overheads *
                        currentCurrency?.ConversionRateToLocal,
                        2
                      )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col style={{ marginTop: "5px" }}>
                  <Label>Overhead %</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ? 
                        (_.round(
                          filterWbsNum[0]?.Overheadswbs *
                          currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)
                       : (_.round(
                        currentProfile?.Overheads *
                        currentCurrency?.ConversionRateToLocal,
                        2
                      )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col style={{ marginTop: "5px" }}>
                  <Label>Overhead %</Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="number"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ? 
                        (_.round(
                          filterWbsNum[0]?.Overheadswbs *
                          currentCurrency?.ConversionRateToLocal,
                          2
                        )).toFixed(2)
                        : (_.round(
                        currentProfile?.Overheads *
                        currentCurrency?.ConversionRateToLocal,
                        2
                      )).toFixed(2)}
                      disabled
                    ></Input>
                    <InputGroupAddon addonType="append">
                      {
                        currentCurrency.CurrencyUnit
                      }
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="m-0 mb-2">
              <Col style={{marginTop:"5px"}}>
                  <Label> <b>Margin%</b>  </Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="text"
                      step="any"
                      value={ (filteredCommissionedData && filteredCommissionedData?.length>0) ? 
                             `${(_.round((filteredCommissionedData[filteredCommissionedData.length-1].OldMargin),2)).toFixed(2)}%`
                        
                         :(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ?
                        `${(_.round(
                          filterWbsNum[0]?.MarginPercentwbs,
                          2
                        )).toFixed(2)}%`
                        : `${(_.round(
                        currentProfile?.MarginPercent * 100,
                        2
                      )).toFixed(2)}%`}
                      disabled
                    ></Input>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                  <Label> <b>Margin%</b>  </Label>
                </Col>
                <Col>
                  <InputGroup className="m-0">
                    <Input
                      type="text"
                      step="any"
                      value={(filterWbsNum?.length > 0 && Object.keys(wbsDropDown)?.length > 0) ?
                        `${(_.round(
                          filterWbsNum[0]?.MarginPercentwbs,
                          2
                        )).toFixed(2)}%`
                        : `${(_.round(
                        currentProfile?.MarginPercent * 100,
                        2
                      )).toFixed(2)}%`}
                      disabled
                    ></Input>
                  </InputGroup>
                </Col>
                <Col style={{marginTop:"5px"}}>
                <Label> <b>Margin%</b> </Label>
                </Col>
                <Col>
                <InputGroup className="m-0">
                    <Input
                      type="text"
                      step="any"
                      value={getMarginPercent()}
                      disabled
                    ></Input>
                  </InputGroup>
                </Col>
                </Row>
            </div>
            <Row className="m-0 mb-2"></Row>
            <Row className="m-0 mb-0">
              <Col>
                <Label>
                  Reason for Adjustment<sup className="requiredField">*</sup>
                </Label>
              </Col>
            </Row>
            <Row className="m-0 mb-2">
              <Col>
                <textarea
                  type="text"
                  className={
                    negativeValues.reasonforadjustment
                      ? "oopschecking reasonforadjustment"
                      : "reasonforadjustment"
                  }
                  disabled={ filterWbsNum?.length > 0 && disableInputHandler(filterWbsNum[0].WBSNumber)}
                  onInput={(e) => getReasonForAdjustment(e)}
                ></textarea>
              </Col>
            </Row>
            <span>
          {" "}
          {_.includes(pacificMarkets, project?.CommissioningCountry)&& project?.CostingTemplateVersion != "v2.0"
            ? "NOTE:Total OOP=OPS OOP"
            : 
            <>
            NOTE:Total External Cost = OPS External Cost + CS External Cost + Tax <br />
            &emsp;  &emsp; &nbsp; Total Internal Cost = Ops Internal Cost + CS Internal Cost + Total Coordinating Cost
            </>
            }
        </span>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={(e) => getGeneratedSummary(e)}
            disabled={app.recordloading ||
               filterWbsNum?.length > 0 && disableInputHandler(filterWbsNum[0].WBSNumber)
            }
          >
            Submit
            {app.recordloading && <Spinner size="small" color="#495057" />}
          </Button>
          <Button
            color="secondary"
            onClick={getCancel}
            disabled={app.recordloading}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default TotalOPSOOPModal;