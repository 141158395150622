import { takeEvery, call, put, takeLatest } from "redux-saga/effects";
import * as types from '../actions/vendor/ActionTypes';
import axios from '../../axios-interceptor';

function* fetchVendors(action)
{
    try
    {
        yield put({ type: types.SET_LOADING });
        let data = {
           type:action.tempSearchBy,
           value:action.tempSearchVal
        };
        let url = "vendors/list?limit=20" + (action.offsetss ? "&offset=" + action.offsetss : "");
        //console.log(url)     
        const vendors = yield axios.post(url, data);
        //console.log(vendors);

        vendors.data.items.forEach(x =>
        {
            x["Countries"]=x.countries.filter(c=>c.Blacklisted==0)?.map(d=>d.Country)
            x["BlacklistCountries"]=x.countries.filter(c=>c.Blacklisted==1)?.map(d=>d.Country)
        
        });

        if (!action.offsetss)
            yield put({
                type: types.SET_VENDORS,
                vendors: vendors.data,
            });
        else
            yield put({
                type: types.APPEND_VENDORS,
                vendors: vendors.data,
            });
    }
    catch (error)
    {
        const errorMsg = error.message;
        yield put({
            type: types.FETCH_VENDORS_FAILURE,
            payload: errorMsg
        });
    }
}

function* saveVendor(action)
 {
    try
    {
        ;
        let url=""
        if(!action.vendor.id)
         url = "vendors/save";
       else
       url = "vendors/savestatus";
        var token= localStorage["auth-token"];
        yield call(() => axios.post(url, action.vendor))
        yield put({
            type: types.SAVE_VENDOR_SUCCESS,
            payload: action.vendor
        });
    }
    catch (error)
    {
        ;
        const errorMsg = error.message;
        yield put({
            type: types.SAVE_VENDOR_FAILURE,
            payload: errorMsg
        });
    }
}

function* fetchVendorDetails(action)
{
    try
    {
        ;
        const res = yield axios.get("vendors/details?Id=" + parseInt(action.payload.id));
        const vendor = res.data.Vendor;
        let keys = Object.keys(vendor);

        keys.forEach(x =>
        {

            vendor[x] = vendor[x] || '';
            if (x == 'Office' || x == 'DesignationKeyContact' || x == 'PrimaryDesignation' || x == 'SecondaryDesignation' || x == 'DueDeligenceType')
            {
                if (vendor[x])
                {
                    vendor[x] = vendor[x]["id"];
                }
            }

            if (x == 'Countries' || x == 'Cities' || x == 'Methodologies')
            {
                ;
                if (vendor[x])
                {
                    if (Array.isArray(vendor[x]))
                    {
                        vendor[x].forEach(y =>
                        {
                            y["value"] = y["id"];
                            y["label"] = y["Label"];
                        });
                    }
                }
            }
            if (x == "SOPTrainingEvidanceFile" || x == "SignedMSAFile" || x == "DueDeligenceDocumentsFile" || x == "CompanyProfileFile" || x == "OtherDocumentsFile"
                || x == "PanelBookFile" || x == "SignedNDAFile")
            {
                vendor[x + "Saved"] = vendor[x];
            }

        });
        //yield put({ type: types.API_VENDOR_REDIRECT_TO_EDIT, link: '/pages/vendor-form' });
        ;
        yield put({ type: types.FETCH_VENDOR_DETAILS_SUCCESS, payload: { vendor: vendor, editMode: true } });
    }
    catch (error)
    {
        const errorMsg = error.message;
        yield put({ type: types.FETCH_VENDOR_DETAILS_FAILURE, error: errorMsg })
    }
}

export function* waitForLoadVendors()
{

    yield takeEvery(types.FETCH_VENDORS_REQUEST, fetchVendors);
}

export function* waitForLoadMoreVendors()
{
    yield takeEvery(types.FETCH_MORE_VENDORS, fetchVendors);
}

export function* waitForVendorDetails()
{

    yield takeEvery(types.FETCH_VENDOR_DETAILS_REQUEST, fetchVendorDetails);
}

export function* waitForSaveVendors()
{
    yield takeEvery(types.SAVE_VENDOR_REQUEST, saveVendor);
}