import React, { useEffect, useState, useRef } from "react";
import { toastr } from "react-redux-toastr";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "@alexcambose/react-infinite-scroll";
import axios from "../../axios-interceptor";
import Navbar from "../../components/Navbar";
import DashboardLayout from "../../layouts/Dashboard";
import RecordsSpinner from "../../components/RecordsSpinner";
import RecordsBadge from "../../components/RecordsBadge";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";

import {
  getMultiOptions
} from "../../utils/codeLabels";
import Select from "react-select";
import {
  FormGroup,
  Label,
} from "reactstrap";



import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  CardHeader,
  CardBody,
  Card,
  Row,
  Col,
  Container,
} from "reactstrap";
import _ from "lodash";
import { pageLoadEnd, pageLoadStart } from "../../redux/actions/appActions";
import { filterCriteria } from "../../redux/actions/filterCriteria/filterCriteria";

import filterFactory, {
  textFilter,
} from "react-bootstrap-table2-filter";
import moment from "moment";
import { finalColumns, csvHeaders, reportColumns } from "./constants";
import { useLocation } from "react-router-dom";
import logo from "../../assets/img/avatars/modal-logo.png"; 
import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';
import * as EventName from '../../utils/GoogleAnalyticsEvents';

import './oop.css';



var imgStyle = {
  width: "150px",
};



const OopReport = (props) => {
  const gaEventTracker = useAnalyticsEventTracker(EventName.OOPApprovalReport);

  const dispatch = useDispatch();
  const route = useLocation();
  const [actualPath, setActualPath] = useState("")
  const [isLoad, setLoad] = useState(false)
  const [ReportName, setReportName] = useState("");
  const [approvalStatus, setApprovalStatus] = useState([]);
  const [infiniteLoad, setInfiniteLoad] = useState(false);
  const [apiTrigger, setApiTrigger] = useState(false);
  const [record, setRecord] = useState({
    records: [],
    hasMore: true,
    totalItems: null,
  });
  const [page,setPage]=useState(1);
  const [sizePerPage,setSizePerPage]=useState(30);
  const [mydata,setData]=useState();
  const [selectColumns, openSelectColumns] = useState(false);
  const [allColumns] = useState(reportColumns);
  const [selectedDisplayColumns, setSelectedDisplayColumns] = useState(
    allColumns
  );
  const [selectedTempDisplayColumns, setSelectedTempDisplayColumns] = useState(
    allColumns
  );
  const [resetDisplayCols, setResetDisplayCols] = useState([]);
  const reportStatuses =
    useSelector(({ reportStatuses }) => reportStatuses) || [];


  const [reportItems, setReportItems] = useState([]);
  const [mystyle,setmystyle]=useState();
  const [reportRemoteFilters, setReportRemoteFilters] = useState({});
  const csvInstance = useRef(null);
  const fetchReportData = (actualpath) => {
    dispatch(pageLoadStart());
    loadInitial(actualpath, reportRemoteFilters)
  }

  const loadInitial = async (actualpath, filters) => {
    let _actualPath = actualpath ? actualpath : _.head(route.pathname?.replace("/", "").split("/"));
    axios
      .post("/reports/oop?limit=30", { reportType: _actualPath, filters: filters })
      .then((res) => {
        dispatch(pageLoadEnd());
        //console.log(res);
        setRecord({
          ...record,
          records: res.data.items,
          totalItems: res.data.totalItems,
          hasMore: true
        }); // change records to whatever array is named
      })
      .catch((err) => {
        // toastr.error("Loading Failed", err.data.message);
      });
  }
  useEffect(() => {
    let _actualPath = _.head(route.pathname?.replace("/", "").split("/"));
    let _reportName = _actualPath == "oop-report" ? 'OOP APPROVAL REPORT' : _actualPath == "rfcreport" ? 'READY FOR COMMISSIONING REPORT' : ""
    if (!actualPath || _actualPath != actualPath) {
      setReportRemoteFilters({})
      setActualPath(_actualPath)
      fetchReportData(_actualPath)
      setReportName(_reportName)
    }
  }, [route])
  // useEffect(() => {
  //   fetchReportData()
  // }, []);

  const loadAllOop = async () => {
    try {
      dispatch(pageLoadStart());
      var res = await axios.get(`/reports/download/${oopReportStatus.FileId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'text/csv'
        }
      });
      if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${actualPath == "oop-report" ? "OopApprovalReport" : "ReadyForCommissioningReport"}-${(oopReportStatus && oopReportStatus.ReportEndTime) ? moment.utc(oopReportStatus.ReportEndTime).format('l LT') : ''}-UTC.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
      dispatch(pageLoadEnd());
    }
    catch (err) {
      dispatch(pageLoadEnd());
      toastr.error("Loading Failed", err?.data?.message);
    }
  };

  useEffect(() => {
    //console.log("record has changed", record);
  }, [record]);

  const fetchMoreData = () => {
    //console.log("fetch more called");
    //console.log(record);
    if (record.records.length >= record.totalItems) {
      //console.log("set hasMore false");
      setRecord({ ...record, hasMore: false });
      return;
    }
    setInfiniteLoad(true);
    let _actualPath = actualPath ? actualPath : _.head(route.pathname?.replace("/", "").split("/"));
    axios
      .post(
        "/reports/oop?limit=30&offset=" + record.records.length
        , { reportType: _actualPath, filters: reportRemoteFilters })
      .then((res) => {
        //console.log(res);
        //console.log(
        //   "new array",
        //   update(record.records, { $push: res.data.items })
        // );
        setRecord({
          ...record,
          records: update(record.records, { $push: res.data.items }),
          totalItems: res.data.totalItems,
        });
        setInfiniteLoad(false);
      })
      .catch((err) => {
        //console.log(err);
        // toastr.error("Loading Failed", err.data.message);
        setInfiniteLoad(false);
      });
  };

  const formattedPrec = (data) => {
    if (data) return (data * 100).toFixed(2) + "%";
    else return "not available";
  };

  const [tableColumns, setTableColumns] = useState(finalColumns);
  useEffect(() => {
    let _finalColumns = finalColumns
    if (actualPath != "oop-report")
      _finalColumns = _finalColumns.filter(fc => !fc.isOOPOnly)
      // const displayColumnData = []
      
      // if(displayColumnData){
      //   console.log("displayColumnData", displayColumnData)
      //   setSelectedDisplayColumns(displayColumnData)
      //   setSelectedTempDisplayColumns(displayColumnData)
      // }

    setTableColumns(_finalColumns)

    
  }, [])


  useEffect(() => {
    if (selectedDisplayColumns) {
      // localStorage.setItem("displayColumn", selectedDisplayColumns)
      dispatch(pageLoadStart());
      let _finalColumns = finalColumns
      if (actualPath != "oop-report")
        _finalColumns = _finalColumns.filter(fc => !fc.isOOPOnly)

      let columns = _finalColumns
        .filter((col) => _.includes(selectedDisplayColumns, col.text))
        .map((col) => {
          return { ...col };
        });
      setTableColumns([]);
      setTimeout(() => {
        setTableColumns([...columns]);
        dispatch(pageLoadEnd());
      });
    }
  }, [selectedDisplayColumns, actualPath]);


  const approvalArray =  approvalStatus.map(item =>  item.label)

  const filterData = approvalStatus.length ?  
      record.records.filter(item => 
        approvalArray.includes(item.approvalResult) 
      ) 
    : 
      record.records

  const disabledApprovalDropdown = 
  [...tableColumns].filter(item => item?.dataField === "approvalResult").map(item => item).length ? true : false
  const data = {
    tableColumns: [...tableColumns],
    tableData: filterData,
  };

  const ExportCSV = (props) => {
    const handleClick = () => {
      gaEventTracker(EventName.Export);
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-secondary" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const oopReportStatus = reportStatuses && reportStatuses.find((s) => s.ReportName === ReportName)
    ? reportStatuses.find((s) => s.ReportName === ReportName) : null
  const getReportStatus = (oopReportStatus) => {
    if (!oopReportStatus)
      return 'Report not generated.';
    switch (oopReportStatus.Status) {
      case 'STARTED':
      case 'REPORT_GENERATED':
        return 'The report data is available for view now, for Export... it is still in progress.'
        break;
      case 'ERROR':
        return 'Report generation failed.'
        break;
      default:
        return ''
        break;
    }
  }

  useEffect(()=>{
    let userRecord = JSON.parse(localStorage.getItem("userRecord"))

    let defaultCriteria = userRecord?.UserDefaultCriteria?.length > 0 
    ? userRecord.UserDefaultCriteria.find(e => e.Source == "OOP Approval Report") : null

    if(defaultCriteria) {
      let filterData =  defaultCriteria.CriteriaData?.reportColumns
      setSelectedDisplayColumns([...filterData])
      setSelectedTempDisplayColumns([...filterData])
    }
  },[])


  useEffect(()=>{
    if(props.filterCriteriaData?.filterErr === false) {
      setLoad(false)
      if(apiTrigger){
        setApiTrigger(false)
        setSelectedDisplayColumns([...selectedTempDisplayColumns]);
      }
      openSelectColumns(false);
    } else if ((props.filterCriteriaData?.filterErr)) {
      setLoad(false)
      setApiTrigger(false)
      openSelectColumns(false);
    }

  }, [props.filterCriteriaData])

  const handleSelectColumn = () => {
    setLoad(true)
    setApiTrigger(true)
    dispatch(filterCriteria({Source: "OOP Approval Report", CriteriaData: {
      reportColumns: [
        ...selectedTempDisplayColumns
      ]
    },
    }))
  }

  const handleTableChange = (type, { filters }) => {
    //console.log('filters', filters display)
    let _actualPath = actualPath ? actualPath : _.head(route.pathname?.replace("/", "").split("/"));
    if(Object.keys(filters).length !== 0){
      for (const [key, value] of Object.entries(filters)) { 
        value.filterVal = value.filterVal.trim()
      }     
    
    }
    setReportRemoteFilters(filters)
    loadInitial(_actualPath, filters)
    return true
  }

  const [value, setValue] = React.useState(0);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };


  const handleApprovalStatus = (e) => {
    // Methodology change only removes SubMethodologies that no longer apply
    if (e) {
      setApprovalStatus(e)
    } else {
      setApprovalStatus([])
    }
  };

  const approvalStatusArray = [
    {
      Code: "1",
      Label : "In Progress"
    },
    {
      Code: "2",
      Label : "Denied"
    },
    {
      Code: "3",
      Label : "Approved"
    }
]
  return (
    
    <div className={actualPath == "oop-report" ? "oops_reports" : "oops_reports oops_reports_rfc"} >
      <DashboardLayout
        navbar={<Navbar headerTitle={actualPath == "oop-report" ? "OOP APPROVAL REPORT" : "READY FOR COMMISSIONING REPORT"} show={false} header_nav={true}/>}
      >

        <CSVLink className="csvlink" 
          filename={`${actualPath == "oop-report" ? "OopApprovalReport" : "ReadyForCommissioningReport"}-${(oopReportStatus && oopReportStatus.ReportEndTime && oopReportStatus.Status === 'CSV_UPLOADED') ? moment.utc(oopReportStatus.ReportEndTime).format('l LT') : ''}-UTC.csv`}
          data={reportItems} headers={csvHeaders} separator={","} enclosingCharacter={`"`}
          ref={csvInstance}>
          Download me
        </CSVLink>
        <>
        
          {!isLoad ? (
          <div style={{position:'relative'}}>
          <InfiniteScroll
            loadMore={fetchMoreData}
            hasMore={(oopReportStatus && oopReportStatus.ReportEndTime && (oopReportStatus.Status === 'CSV_UPLOADED'|| oopReportStatus.Status === 'REPORT_GENERATED')) && record.hasMore}
            isLoading={infiniteLoad}
            loading={<RecordsSpinner />}
            noMore={(oopReportStatus && oopReportStatus.ReportEndTime && (oopReportStatus.Status === 'CSV_UPLOADED'|| oopReportStatus.Status === 'REPORT_GENERATED')) ? <div className="my-back-top"> <RecordsBadge back_top={true} recordTypeLabel="records" /></div> : null}
            initialLoad={false}
            disable={true}
          >
            <Container className="p-0">
            <ToolkitProvider
              keyField="id"
              data={data.tableData}
              columns={[...data.tableColumns]}
              exportCSV={{ onlyExportFiltered: true, exportAll: false, fileName: `${actualPath == "oop-report" ? "OopApprovalReport" : "ReadyForCommissioningReport"}${(oopReportStatus && oopReportStatus.ReportEndTime && oopReportStatus.Status === 'CSV_UPLOADED') ? moment.utc(oopReportStatus.ReportEndTime).format('l LT') : ''}-UTC.csv` }}
            >
        
              {(props) => (
                <>
                
                <div>
                <Card>
                  <CardHeader style={{position:'fixed',top:'92px',zIndex:'999',width:'-webkit-fill-available',padding: '8px 12px 0px 15px',borderBottom:'1px solid lightgray',height:actualPath == "oop-report" ?'150px':'148px'}}>
                    <div className="row inner" style={{display:'flex',flexWrap:'nowrap', marginTop: "70px"}}>
                    <div className={`${actualPath == "oop-report" ?"col-lg-2":"col-lg-3"} col-md-3 float-left pr-0`}>
                            <h3 style={{fontSize:'15.5px'}}>
                              Showing {record.records?.length || 0} of{" "}
                              {record.totalItems || 0} Records
                            </h3>
                            {oopReportStatus && oopReportStatus.ReportEndTime && <span className='small'>Last refresh on : {moment.utc(oopReportStatus.ReportEndTime).format('l LT')} UTC</span>}
                    </div>
                      {oopReportStatus && oopReportStatus.ReportEndTime && oopReportStatus.FileId &&
                        <div className={`${actualPath == "oop-report" ?"col-lg-2 mtBtn":"col-lg-3 text-right"}  col-md-2 p-0  `}>
                          <button
                            className="btn btn-secondary"
                            onClick={() => loadAllOop()}
                          >
                            Export {actualPath == "oop-report" ? "OOP Approval data" : "Ready For Commissioning Report"}
                          </button>
                          
                        </div>
                      }
                      {oopReportStatus && oopReportStatus.ReportEndTime && (oopReportStatus.Status === 'CSV_UPLOADED'|| oopReportStatus.Status === 'REPORT_GENERATED') &&
                        <div className={`col-md-7 ${actualPath == "oop-report"?'col-lg-8':'col-lg-6'}`}>
                          <div className="row">
                          <div className={`pl-0 text-right ml-1 col-md-2 ${actualPath == "oop-report"?"mtBtn col-lg-1":'col-lg-2'}`}>
                            <ExportCSV {...props.csvProps} />
                          </div>
                          {actualPath == "oop-report"  &&
                          <div className="col-lg-7 col-md-6">
                          <FormGroup>
                            <Label className="h5">Approval Status</Label>
                            <Select
                              className= {disabledApprovalDropdown ? "react-select-container" : 
                              "react-select-container disabled"}
                              classNamePrefix="react-select"
                              options={getMultiOptions(approvalStatusArray)}
                              isMulti
                              value={approvalStatus}
                              onChange={(e) => handleApprovalStatus(e)}
                            />
                          </FormGroup>
                          </div>
                          }
                          <div className={`col-md-4 text-center p-0 ${actualPath == "oop-report"?"mtBtn col-lg-3 ":'col-lg-4'}`}>
                          <div className="select-column">
                            <button
                              className="btn btn-secondary"
                              onClick={() => {
                                openSelectColumns(!selectColumns);
                                setResetDisplayCols(selectedTempDisplayColumns);
                              }}
                            >
                              Select Columns to display
                            </button>
                          </div>
                          </div>
                          
                          </div>
                        </div>
                      }
                    </div>
                  </CardHeader>
                 
                  <CardBody className="p-4" style={{position:'relative',marginTop:oopReportStatus && oopReportStatus.ReportEndTime && oopReportStatus.Status === 'CSV_UPLOADED'?'1rem':'10.1rem'}}>
                  <p className={`text-danger text-center`}style={{position:'fixed',left:'45%'}}>{getReportStatus(oopReportStatus)}</p>
                    {oopReportStatus && oopReportStatus.ReportEndTime && (oopReportStatus.Status === 'CSV_UPLOADED'|| oopReportStatus.Status === 'REPORT_GENERATED') 
                    ?
                      <>
                        {data.tableColumns && data.tableColumns.length ? (
                          
                          <>
                          {/* {data.tableColumns.map((item)=>{
                            setmystyle(item.text.length)
                          })} */}
                          <div className="fixTableHead">
                          <BootstrapTable
                            id="mapping_table"
                            classes= {actualPath == "oop-report"? "sticky-top-table oop-report" : "sticky-top-table oop-report mapping_tablerfc"}
                            remote={{ filter: true }}
                            {...props.baseProps}
                            bootstrap4
                            bordered={true}
                            striped
                            hover
                            condensed
                            onTableChange={handleTableChange}
                            filter={filterFactory()}
                            keyField="id"
                            data={data.tableData}
                            headerClasses="header-class"
                            columns={[...data.tableColumns]}
                            style={{textOverflow:data.tableColumns>30?'ellipsis':'normal'}}
                            noDataIndication={<b>No data available</b>}
                          />
                          </div>
                          
                          </>
                          
                          ) : null}
                          
                      </>
                      : null
                    }
                    
                  </CardBody>
                    
                </Card>
                </div>
                </>
              )}
            </ToolkitProvider>
            </Container>
          </InfiniteScroll>
          </div>)
          :
            <div style={{marginTop:'100px'}}>
              <RecordsSpinner />
            </div>
          }
        </>
        {!isLoad &&
          <Modal
            toggle={() => openSelectColumns(!selectColumns)}
            isOpen={selectColumns}
            centered={true}
          >
            <ModalHeader toggle={() => openSelectColumns(!selectColumns)}>
            {/* <img src={logo}  className="modallogo"/> */}
            </ModalHeader>
            <div>
                          <span className="modaltitle modaltitleDisplay">
                          Select Columns to display
                          </span>
                      </div>
            <ModalBody>
              <Row className="justify-content-end">
                <a
                  className="select-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTempDisplayColumns([...allColumns]);
                  }}
                >
                  Select all
                </a>
                <a
                  className="ml-2 mr-4 select-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTempDisplayColumns([]);
                  }}
                >
                  Deselect all
                </a>
              </Row>
              {finalColumns.filter((fc) => { return (actualPath != "oop-report" ? !fc.isOOPOnly : true) }).map((a) => a.text).map((ac, index) => (
                <Col lg="6" className="d-inline-flex">
                  <span>
                    <input
                      id={`column_${index}`}
                      type="checkbox"
                      checked={_.includes(selectedTempDisplayColumns, ac)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedTempDisplayColumns([
                            ...selectedTempDisplayColumns,
                            ac,
                          ]);
                        } else {
                          setSelectedTempDisplayColumns([
                            ...selectedTempDisplayColumns.filter(
                              (sdc) => sdc != ac
                            ),
                          ]);
                        }
                      }}
                    />
                    <label className="ml-2 pointer" for={`column_${index}`}>
                      {ac}
                    </label>
                  </span>
                </Col>
              ))}
            </ModalBody>
            <ModalFooter>
              <Row>
                <Button
                  color="primary"
                  size="sm"
                  className="mr-2"
                  onClick={()=>
                    handleSelectColumn()
                  }
                >
                  Confirm & Save
                </Button>
                <Button
                  color="secondary"
                  size="sm"
                  onClick={() => {
                    openSelectColumns(!selectColumns);
                    setSelectedTempDisplayColumns([...resetDisplayCols]);
                    setResetDisplayCols([]);
                  }}
                >
                  Close
                </Button>
              </Row>
            </ModalFooter>
          </Modal>
        }
      </DashboardLayout>
    </div>
  );
};


const mapStateToProps = (state) => {
	return {
		filterCriteriaData: state.filterCriteriaData,
	};
};



export default connect(mapStateToProps, null)(OopReport);
