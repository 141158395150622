import async from "../components/Async";

import { Sliders as SlidersIcon } from "react-feather";
import { clearCurrentProject } from "../redux/actions/currentProjectActions";
import RequestsBoard from "../pages/requestsBoard/RequestsBoard";
import NotificationsBoard from "../pages/notificationsBoard/NotificationsBoard";
import {
  getFullMarkets,
  getMarkets,
} from "../redux/actions/marketDefaultsActions";

const SignIn = async(() => import("../pages/auth/SignIn"));
const ProjectDashboard = async(() =>
  import("../pages/dashboard/Projects/Dashboard")
);
const Proposal = async(() => import("../pages/proposal/Proposal"));
const ManageUsers = async(() => import("../pages/manageUsers/ManageUsers"));
const MarketDefaults = async(() =>
  import("../pages/marketDefault/MarketDefault")
);
const DeliveryDashboard = async(() =>
  import("../pages/deliveryDasboard/DeliveryDashboard")
);
const Summary = async(() => import("../pages/summary/Summary"));
const Finance = async(() => import("../pages/finance/Finance"));
const OpsReport = async(() => import("../pages/opsReport/OpsReport"));
const DeliveryReport = async(() =>
  import("../pages/deliveryReport/DeliveryReport")
);
const DeliveryReportNew = async(() =>
  import("../pages/deliveryReport/DeliveryReportNew")
);
const FinanceNew = async(() => import("../pages/finance/FinanceNew"));
const OpsReportNew = async(() => import("../pages/opsReport/OpsReportNew"));
const FinanceLogsNew = async(() => import("../pages/finance/FinanceLogsNew"));
const OopReport = async(() => import("../pages/oopReport/OopReport"));

// Routes
// const authRoutes = {
//   path: "/auth",
//   name: "Auth",
//   badgeColor: "primary",
//   icon: SlidersIcon,
//   containsHome: true,
//   component: SignIn,
//   hidden: true,
// };
// const landingRoutes = {
//   path: "/",
//   name: "Dashboard",
//   badgeColor: "primary",
//   icon: SlidersIcon,
//   containsHome: true,
//   component: BigPage,
// };

const proposal = {
  path: "/proposal-new",
  name: "New Proposal",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: Proposal,
  onRedirection: clearCurrentProject,
  access: "CanCreateNewProposal",
};

const dashboardRoutes = {
  path: "/",
  name: "Dashboard",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: ProjectDashboard,
  access: "InternalDashBoardAccess",
};
const vendordashboardRoutes = {
  path: "/vendordashboard",
  name: "Vendor Dashboard",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: ProjectDashboard,
  access: "VendorManagementAccess",
};

const vendoronboardngroutes = {
  path: "/vendoronboarding",
  name: "Vendor Onboarding",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: ProjectDashboard,
  access: "VendorOnboardingAccess",
};
const manageUsersRoutes = {
  path: "/users",
  name: "User Management",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: ManageUsers,
  access: "ManageUsersAccess",
};


const notificationsBoardRoutes = {
  path: "/Notificationsboard",
  name: "Notification Board",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: NotificationsBoard,
  access: "InternalDashBoardAccess",
};


const marketDefaults = {
  path: "/marketsettings",
  name: "Market Settings",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: MarketDefaults,
  onRedirection: getFullMarkets,
  access: "ManageMarketAccess",
};

const deliveryDashboardRoutes = {
  path: "/deliveries",
  name: "Setup and Delivery",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: DeliveryDashboard,
  access: "DeliveryDashboardAccess",
};

// const deliveryReportRoutes = {
//   path: "/deliveryReport",
//   name: "Delivery Report",
//   badgeColor: "primary",
//   icon: SlidersIcon,
//   containsHome: true,
//   component: DeliveryReport,
//   access: "DeliveryDashboardAccess",
// };
const deliveryReportNewRoutes = {
  path: "/deliveryReportNew",
  name: "Delivery Report",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: DeliveryReportNew,
  access: "DeliveryDashboardAccess",
};
const requestsBoardRoutes = {
  path: "/requests",
  name: "Requests Board",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: RequestsBoard,
  access: "RequestsBoardAccess",
};

// const financeRoutes = {
//   path: "/finance",
//   name: "Finance Reports",
//   badgeColor: "primary",
//   icon: SlidersIcon,
//   containsHome: true,
//   component: Finance,
//   access: "FinanceAccess",
// };

const financeNewRoutes = {
  path: "/finance-new",
  name: "Finance Reports",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: FinanceNew,
  access: "FinanceAccess",
};

const financeLogRoutes = {
  path: "/finance-logs",
  name: "Finance Logs",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: FinanceLogsNew,
  access: "FinanceAccess",
};

// const opsReportRoutes = {
//   path: "/ops-report",
//   name: "Ops Report",
//   badgeColor: "primary",
//   icon: SlidersIcon,
//   containsHome: true,
//   component: OpsReport,
//   access: "FinanceAccess",
// };

const opsReportNewRoutes = {
  path: "/ops-report-new",
  name: "Ops Report",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: OpsReportNew,
  access: "FinanceAccess",
};

const oopReportRoutes = {
  path: "/oop-report",
  name: "OOP Approval Report",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: OopReport,
  access: "FinanceAccess",
};
const readyForCommissioningReportRoutes = {
  path: "/rfcreport",
  name: "Ready for Commissioning Report",
  badgeColor: "primary",
  icon: SlidersIcon,
  containsHome: true,
  component: OopReport,
  access: "FinanceAccess",
};

export const dashboard = [
  dashboardRoutes,
  proposal,
  manageUsersRoutes,
  marketDefaults,
  // financeRoutes,
  // opsReportRoutes,
  // financeNewRoutes,
  // opsReportNewRoutes,
  financeLogRoutes,
  oopReportRoutes,

];
export const landing = [];
// export const auth = [authRoutes];

const routes = [
  proposal,
  dashboardRoutes,
  vendordashboardRoutes,
  vendoronboardngroutes,
  deliveryDashboardRoutes,
  // deliveryReportRoutes,
  requestsBoardRoutes,
  // financeRoutes,
  // opsReportRoutes,
  deliveryReportNewRoutes,
  // financeNewRoutes,
  financeLogRoutes,
  // opsReportNewRoutes,
  oopReportRoutes,
  readyForCommissioningReportRoutes,
  marketDefaults,
  manageUsersRoutes,
  notificationsBoardRoutes
];
export default routes;
