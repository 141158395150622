import React from "react";
import { connect } from "react-redux";

import { toggleBoxedLayout } from "../redux/actions/layoutActions";
import { toggleStickySidebar } from "../redux/actions/sidebarActions";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { enableClassicTheme } from "../redux/actions/themeActions";
import { enableCorporateTheme } from "../redux/actions/themeActions";
import { enableModernTheme } from "../redux/actions/themeActions";

import { Badge, Button } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { List as SettingsIcon } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { HashLink } from 'react-router-hash-link';

import
{
    faAngleRight,
    faShoppingCart
} from "@fortawesome/free-solid-svg-icons";

class Settings extends React.Component
{
    constructor()
    {
        super();
        this.state = {
            isOpen: false
        };
        this.scrollWithOffset = this.scrollWithOffset.bind(this)
    }

    toggleSidebar()
    {
        this.props.flagAlertoff();
        this.setState({ isOpen: !this.state.isOpen });
    }
    Navchange()
    {

    }

    scrollWithOffset(el) {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -document.getElementsByClassName("navbar").item(0).offsetHeight;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'})
    }

    render()
    {
        const { isOpen } = this.state;
        const { layout, sidebar, dispatch, sectionLinks } = this.props;

        return (
            <div className={"settings " + (isOpen ? "open" : "")}>
                <div className="settings-toggle" onClick={() => this.toggleSidebar()}>
                    <SettingsIcon />
                </div>

                <div className="settings-panel">
                    <div className="settings-content">
                        <PerfectScrollbar>
                            <div className="settings-title" style={{ marginTop: '4rem' }}>
                                <Button close style={{color: 'white'}} onClick={() => this.toggleSidebar()} />
                                <h4 style={{color: 'white'}}>Section-Wise RFQ Navigation</h4>
                                
                            </div>
                            <div className="settings-section">
                                <ul style={{ listStyleType: 'square', color: 'white'}}>
                                    <li>    
                                        {sectionLinks.costingOptionLink &&
                                            <HashLink smooth to={'#'} scroll={this.scrollWithOffset}> Costing Options </HashLink>
                                        }
                                    </li>
                                    {sectionLinks.links.map((link, indexLink) =>
                                        (
                                            <li>
                                                <HashLink smooth to={link.value} scroll={this.scrollWithOffset} > {link.label} </HashLink>
                                            </li>
                                        ))
                                    }
                                    <li>
                                        {sectionLinks.sudOptionLink &&
                                            <HashLink smooth to={'#header_op_r'} scroll={this.scrollWithOffset}>Setup & Delivery</HashLink>
                                        }
                                    </li>
                                    {sectionLinks.resOptionLink !="" &&
                                    <li>
                                        
                                            <HashLink smooth to={'#header_responsibility'} scroll={this.scrollWithOffset}>Responsibilities</HashLink>
                                        
                                    </li>
                                    }
                                </ul>
                            </div>
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(store => ({
    layout: store.layout,
    sidebar: store.sidebar,
    sectionLinks: store.sectionLinks
}))(Settings);
