import React from "react";

function Copy() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#000"
          d="M18 3H6v21h16.5V7.5L18 3zm3 19.5H7.5v-18h9V9H21v13.5zM15 0H3v19.5h1.5v-18H15V0z"
        ></path>
      </g>
    </svg>
  );
}

export default Copy;