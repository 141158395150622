import React, { useState, useEffect } from "react"
import _, { split } from "lodash";
import Select from "react-select";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Container,
    Input, InputGroup, InputGroupAddon,
    Label, Row, Table
} from "reactstrap";
import { toastr } from "react-redux-toastr";
import './css/common.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HistoryModal from "./components/modals/HistoryModal";
import {
    faChevronDown,
    faPen, faMoneyBill,
    faHistory,
} from "@fortawesome/free-solid-svg-icons";
import CostingDataView from "../../summary/CostingDataView";
import { Edit } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import vendorReducer from "../../../redux/reducers/vendorReducer";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import {
    SET_BIDDING_SPLIT_DATA
} from "../../../redux/actions/vendor/ActionTypes";

function VendorCostingAndTimeline(props) {
    const dispatch = useDispatch();
    const [cIndex, setCindex] = useState();
    const [open, setOpen] = useState(false);
    const [sid, setsid] = useState(0);
    const [sidtype, setsidtype] = useState(0);
    const [vendor, setVendor] = useState(0);
    const [ViewOnlyMode, setViewOnlyMode] = useState(false);
    const [resetCosting, setResetCosting] = useState(false);
    var project = useSelector(({ currentProject }) => currentProject.newProject);
    const userRecord = useSelector(({ user }) => user.userRecord);
    var index;
    const splitdata = useSelector(
        ({ vendorReducer }) => vendorReducer.SplitData
    )
    useEffect(() => {
        setVendor(props.vendor)
        setCindex(project.CostingProfiles.length - 1)
        setViewOnlyMode(((project.CostingProfiles[project.CostingProfiles.length - 1].VenodrBidDeadLineStatus == "close")
            || (project?.ProjectStatus == 7)
            || (["2", "3", "4", "5", "6"].indexOf(_.last(project?.CostingProfiles).ProfileStatus) != -1)
        ) ? true : false)
    }, []);
    function handleHistory(type, flag) {
        setOpen((type === 'close') ? flag : true)
    }
    function option(coheader, type) {
        var val = splitdata.filter(a => a.id)?.filter(a => a.id == props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.RoundId)
        var list = []

        if (val.length > 0) {
            list = val[0]?.SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
                return {
                    label: a.DistributionName,
                    id: a.id,
                    value: a.id,
                    RoundId: val[0].id

                }
            })
        }
        else {
            list = splitdata ? _.last(splitdata?.filter(a => a.MethodologySpecId == props.meth.id))?.SampleDistributionMasters.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
                return {
                    label: a.DistributionName,
                    id: a.id,
                    value: a.id,
                    RoundId: _.last(splitdata?.filter(a => a.MethodologySpecId == props.meth.id)).id
                }
            }) : []
        }

        return list
    }

    function returnDistribution(coheader, type) {
        var val = splitdata.filter(a => a.id)?.filter(a => a.id == props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.RoundId)
        var list
        switch (type) {
            case "options":
                if (props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId) {

                    if (val.length > 0) {
                        list = val[0].SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
                            return {
                                label: a.DistributionName,
                                id: a.id, value: a.id

                            }
                        })
                    }
                }
                else {
                    list = splitdata ? _.last(splitdata?.filter(a => a.MethodologySpecId == props.meth.id))?.SampleDistributionMasters.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
                        return {
                            label: a.DistributionName,
                            id: a.id, value: a.id
                        }
                    }) : []
                }
                break;
            case "distribution":
                {
                    if (val.length > 0) {
                        list = val[0]?.SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
                            return {
                                label: a.DistributionName,
                                id: a.id,
                                value: a.id
                            }
                        })?.filter(l => l.id == props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId)
                    }
                }
                break;
            case "round":
                {
                    list = splitdata?.filter(a => a.id)?.filter(a => a.id == props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.RoundId)[0]?.RoundName ?
                        splitdata?.filter(a => a.id)?.filter(a => a.id == props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.RoundId)[0]?.RoundName : ""
                }
                break;
            case "cities":
                {
                    if (val.length > 0) {
                        list = val[0]?.SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
                            return {
                                label: a.DistributionName,
                                value: a.id,
                                CoverageDetails: a.CoverageDetails
                            }
                        }).filter(l => l.value == props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId)[0]?.CoverageDetails?.map(a => a.label)?.toString();
                    }
                }
                break;
            case "targetsample":
                {
                    if (val.length > 0) {
                        if (props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId) {
                            list = val[0]?.SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
                                return {
                                    label: a.DistributionName,
                                    value: a.id,
                                    DistributionSize: a.DistributionSize
                                }
                            }).filter(l => l.value == props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId)[0]?.DistributionSize
                        }
                        else {
                            list = val[0]?.SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader.id)[0]?.TargetSamples
                        }
                    }
                }
                break;
            default:
                break;
        }
        return list
    }
    function resetCostingButton() {
        if (props.vendor?.VendorCosts?.every(a => a.IsVendorSubmit == false)) {
            toastr.error("Vendor has not submitted the cost")
        }
        else if (_.last(project.CostingProfiles)?.VenodrBidDeadLineStatus == "close") {
            toastr.error("Bidding is closed")
        }
        else if (!props.vendor?.IsCostSubmitted) {
            toastr.error("Bid is not yet sent to the Vendor")
        }
        else {
            setResetCosting(true);
            let tempSubmit = 0
            var vendorCostings = _.cloneDeep(props.vendor.VendorCosts)
            props.meth.CostingOptions.forEach(coheader => {
                if (props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsInvited &&
                    props.vendor?.IsCostSubmitted && (
                        (props.vendor.ServiceType != 1 && !props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsSelected) ? true :
                            (props.vendor.ServiceType == 1 ? true : false))
                ) {
                    vendorCostings.filter(a => a.CostOptionId == coheader.id)[0].IsVendorSubmit = false
                    tempSubmit = tempSubmit + 1
                }
            })
            index = props.meth.InvitedVendors.findIndex(v => v.VendorId === props.vendor.VendorId)
            project.CostingProfiles[cIndex].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].InvitedVendors[index].VendorCosts = vendorCostings
            dispatch({
                type: currentProjectActions.UPDATE_NEW_PROJECT,
                newProject: project,
            });
            if (tempSubmit > 0) {
                toastr.success("Vendor Costings has been Enabled! Please click Save as Draft or Submit to enable costing to the Vendor.")
            }
        }
    }
    function Edit(e, type, coheader) {
        var data = _.cloneDeep(props.vendor.VendorCosts)
        let cosample = 0;
        switch (type) {
            case "Pen":
                {
                    if (props.ServiceType == 1) {
                        data.filter(a => a.CostOptionId == coheader.id)[0].IsEditable = false
                    }
                    else
                        if (!props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0].RoundId) {
                            toastr.error("Round has not initiated for this Costing Option")
                        }
                        else {
                            // if (splitdata?.filter(a => (a.id == props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.RoundId))[0]?.RoundClosedOn) {
                            //     toastr.error("Round has been closed")
                            // }
                            // else {
                            // if (props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsSelected)
                            //     toastr.error("Vendor is Selected for this costing option")
                            // else
                            data.filter(a => a.CostOptionId == coheader.id)[0].IsEditable = false
                            // }
                        }
                }
                break;
            case "IsSelected":
                {

                    if (e.target.checked) {
                        var total
                        if (props.ServiceType == 1) {
                            total = props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.type != "TimingSection" && a.group == props.SudTabs && a.selected)?.map((c) => ((isNaN(c.VendorDataUSD) || c.VendorDataUSD == '') ? "0" : c.VendorDataUSD))
                                .reduce((a, b) => parseFloat(a) + parseFloat(b) + 0, 0)
                        }
                        else {
                            total = props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.type != "TimingSection" && a.selected)?.map((c) => ((isNaN(c.VendorDataUSD) || c.VendorDataUSD == '') ? "0" : c.VendorDataUSD))
                                .reduce((a, b) => parseFloat(a) + parseFloat(b) + 0, 0)
                        }
                        if (total > 0) {
                            props.meth.InvitedVendors.forEach(a => {
                                if (a.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0]?.IsSelected) {
                                    cosample = parseInt(cosample) + parseInt(a.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].AchivableSamples)
                                }
                            })
                            cosample = parseInt(cosample) + parseInt(props.vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].AchivableSamples)
                            if (parseInt(cosample) > parseInt(coheader.CostingOptionData.numberOfGroup || coheader.CostingOptionData.sampleSizeMain)) {
                                toastr.error("Selected Achivable Samples exceeding than Sample Size main")
                            }
                            else
                                data.filter(a => a.CostOptionId == coheader.id)[0].IsSelected = e.target.checked;
                            data.filter(a => a.CostOptionId == coheader.id)[0].SelectedDate = current1;
                        }
                        else
                            toastr.error("Selection will happen only when the costs are filled")
                    }
                    else {
                        data.filter(a => a.CostOptionId == coheader.id)[0].IsSelected = e.target.checked;
                        data.filter(a => a.CostOptionId == coheader.id)[0].SelectedDate = null;
                    }

                }
                break;
            case "IsInvited":
                {
                    if (splitdata?.filter(a => (a.id == props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.RoundId))[0]?.RoundClosedOn) {
                        toastr.error("Round has been closed")
                    } else
                        if (e.target.checked) {
                            if (!props.vendor.IsMailSent) {
                                data.filter(a => a.CostOptionId == coheader.id)[0].IsInvited = e.target.checked
                            }
                            else {
                                toastr.error("Bidding has been sent to vendor")
                            }
                        }
                        else {
                            if (!props.vendor.IsMailSent) {
                                if (!(data.filter(a => a.IsInvited).length == 1)) {
                                    if (data.filter(a => a.CostOptionId == coheader.id)[0].IsSelected)
                                        toastr.error("Vendor has been selected for the costing so we are not able to deselect the vendor")
                                    else {
                                        data.filter(a => a.CostOptionId == coheader.id)[0].IsInvited = e.target.checked
                                    }
                                }
                                else
                                    toastr.error("Atleast one costing option needs to be selected")
                            }
                            else {
                                toastr.error("Bidding has been sent to vendor")
                            }
                        }
                }
                break;
            case "IsVendorSubmit":
                {
                    if (splitdata?.filter(a => (a.id == props.vendor?.VendorCosts
                        ?.find(a => a.CostOptionId == coheader.id)?.RoundId))[0]?.RoundClosedOn) {
                        toastr.error("Round has been closed")
                    }
                    else
                        if (_.last(project.CostingProfiles)?.VenodrBidDeadLineStatus != "close") {

                            if (!e.target.checked && props.vendor.IsCostSubmitted) {
                                data.filter(a => a.CostOptionId == coheader.id)[0].IsVendorSubmit = false

                                toastr.success("Costing Option has been Enabled! Please click Save as Draft or Submit to enable costing to the Vendor.")
                            }
                            else if (data.filter(a => a.CostOptionId == coheader.id)[0].IsVendorSubmit == false
                                && !props.vendor.IsCostSubmitted) {
                                toastr.error("Vendor has not submitted the cost")
                            }
                            else if (e.target.checked && props.vendor.IsCostSubmitted) {
                                data.filter(a => a.CostOptionId == coheader.id)[0].IsVendorSubmit = true
                                toastr.error("Costing Option has been Disabled! Please click Save as Draft or Submit to disable costing to the Vendor.")
                            }
                            else {
                                if (!props.vendor.IsMailSent) {
                                    if (!props.vendor.IsCostSubmitted) { toastr.error("Vendor is not yet Invited") }
                                    data.filter(a => a.CostOptionId == coheader.id)[0].IsVendorSubmit = false
                                }
                                // if (!props.vendor.IsMailSent) {
                                //     if (!(data.filter(a => a.IsInvited).length == 1)) {
                                //         if (data.filter(a => a.CostOptionId == coheader.id)[0].IsSelected)
                                //             toastr.error("Vendor has been selected for the costing so we are not able to deselect the vendor")
                                //         else {
                                //             data.filter(a => a.CostOptionId == coheader.id)[0].IsInvited = e.target.checked
                                //         }
                                //     }
                                //     else
                                //         toastr.error("Atleast one costing option needs to be selected")
                                // }
                                // else {
                                //     toastr.error("Bidding has been sent to vendor")
                                // }
                            }
                        }
                        else {
                            toastr.error("Bidding has been Closed!")
                        }
                }
                break;
            default:
                break;

        }
        index = props.meth.InvitedVendors.findIndex(v => v.VendorId === props.vendor.VendorId)
        project.CostingProfiles[cIndex].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].InvitedVendors[index].VendorCosts = data
        dispatch({
            type: currentProjectActions.UPDATE_NEW_PROJECT,
            newProject: project,
        });

        if (type == "IsSelected") {
            if (props.meth?.InvitedVendors) {
                let remaingSamples = 0
                let acieveableSamples = []
                props.meth?.InvitedVendors?.filter(iv => iv.IsDeleted == false)?.forEach(iv => {
                    let selectedIv = iv.VendorCosts.find(vc => vc.CostOptionId == coheader.id && vc.IsSelected == true)
                    if (selectedIv) {
                        acieveableSamples.push({
                            InvitedVendorId: iv.VendorId,
                            AchivableSamples: selectedIv.AchivableSamples ? selectedIv.AchivableSamples : 0,
                            SelectedDate: selectedIv.SelectedDate
                        })
                    }
                })

                if (coheader.CostingOptionData.sampleSizeMain) {
                    remaingSamples = parseInt(coheader.CostingOptionData.sampleSizeMain) - (acieveableSamples.map(ca => ca.AchivableSamples).reduce((a, b) => parseInt(a) + parseInt(b) + 0, 0))
                }
                else {
                    remaingSamples = parseInt(coheader.CostingOptionData.numberOfGroup) - (acieveableSamples.map(ca => ca.AchivableSamples).reduce((a, b) => parseInt(a) + parseInt(b) + 0, 0))
                }
                if (splitdata.length) {
                    let tempList = _.last(splitdata.filter(sd => sd.MethodologySpecId == props.meth.id))?.SampleDistributionMasters.forEach(master => {
                        if (master.CostingOptionId == coheader.id) {
                            master.AchivableSamples = acieveableSamples ? acieveableSamples : []
                            master.RemainingSamples = remaingSamples
                        }
                    })
                }
                dispatch({ type: SET_BIDDING_SPLIT_DATA, data: splitdata })
            }

        }
    }
    function returnTotal(coheader) {
        var total
        if (props.ServiceType == 1) {
            total = props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.type != "TimingSection" && a.group == props.SudTabs && a.selected)?.map((c) => ((isNaN(c.VendorDataUSD) || c.VendorDataUSD == '') ? "0" : c.VendorDataUSD))
                ?.reduce((a, b) => parseFloat(a) + parseFloat(b) + 0, 0)
        }
        else {
            total = props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.type != "TimingSection" && a.selected)?.map((c) => ((isNaN(c.VendorDataUSD) || c.VendorDataUSD == '') ? "0" : c.VendorDataUSD))
                ?.reduce((a, b) => parseFloat(a) + parseFloat(b) + 0, 0)
        }

        total = props?.meth?.BiddingDetail?.Currency?.label == "USD" ? total : (total * props?.meth?.BiddingDetail?.Currency?.ConversionRateToLocal)

        return (total?.toFixed(2))
    }
    function returnTotalCPI(coheader) {
        var total
        var totalCPI
        if (props.ServiceType == 1) {
            total = props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.type != "TimingSection" && a.group == props.SudTabs && a.selected)?.map((c) => ((isNaN(c.VendorDataUSD) || c.VendorDataUSD == '') ? "0" : c.VendorDataUSD))
                ?.reduce((a, b) => parseFloat(a) + parseFloat(b) + 0, 0)
        }
        else {
            total = props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.type != "TimingSection" && a.selected)?.map((c) => ((isNaN(c.VendorDataUSD) || c.VendorDataUSD == '') ? "0" : c.VendorDataUSD))
                ?.reduce((a, b) => parseFloat(a) + parseFloat(b) + 0, 0)
        }
        total = props?.meth?.BiddingDetail?.Currency?.label == "USD" ? total : (total * props?.meth?.BiddingDetail?.Currency?.ConversionRateToLocal).toFixed(2)
        if (props.ServiceType != 1) {
            if (props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.AchivableSamples != ""
                && props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.AchivableSamples) {
                totalCPI = total / parseInt(props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.AchivableSamples)
            }
            else {
                totalCPI = 0
            }
        }
        else
            totalCPI = total / (coheader.CostingOptionData?.sampleSizeMain || coheader.CostingOptionData?.numberOfGroup)

        return (totalCPI?.toFixed(2))
    }
    function SelectedRows(e, data, type, val) {
        var vendor = _.cloneDeep(props.vendor);
        switch (type) {
            case "Costing":
                if (val == "full") {
                    vendor.SelectedRows?.forEach(a => {
                        if (a.group == data.header && a.type != "TimingSection") {
                            a.selected = e.target.checked
                        }
                    });
                    vendor.VendorCosts?.forEach(co => co.CostingsData?.forEach(cd => {
                        if (cd.group == data.header && cd.type != "TimingSection") {
                            cd.selected = e.target.checked
                        }
                    }))

                }
                else {
                    if (!e.target.checked) {
                        if (!(vendor.SelectedRows?.filter(a => a.group == data.group && a.type != "TimingSection" && !a.header).filter(r => r.selected).length == 1)) {
                            vendor.SelectedRows?.forEach(a => {
                                if (a.id == data.id) {
                                    a.selected = e.target.checked
                                }
                            })
                            vendor.VendorCosts?.forEach(co => co.CostingsData?.forEach(cd => {
                                if (cd.id == data.id) {
                                    cd.selected = e.target.checked
                                }
                            }))
                        }
                        else
                            toastr.error("Atleast one field should be selected")
                    }
                    else
                        vendor.SelectedRows?.forEach(a => {
                            if (a.id == data.id) {
                                a.selected = e.target.checked
                            }
                        })
                    vendor.VendorCosts?.forEach(co => co.CostingsData?.forEach(cd => {
                        if (cd.id == data.id) {
                            cd.selected = e.target.checked
                        }
                    }))

                }
                break;
            case "timing":
                if (val == "full") {
                    vendor.SelectedRows?.forEach(a => {
                        if (a.group == data.header && a.type == "TimingSection") {
                            a.selected = e.target.checked
                        }
                    })
                    vendor.VendorCosts?.forEach(co => co.CostingsData?.forEach(cd => {
                        if (cd.group == data.header && cd.type == "TimingSection") {
                            cd.selected = e.target.checked
                        }
                    }))
                }
                else {
                    vendor.SelectedRows?.forEach(a => {
                        if (a.id == data.id) {
                            a.selected = e.target.checked
                        }
                    })
                    vendor.VendorCosts?.forEach(co => co.CostingsData?.forEach(cd => {
                        if (cd.id == data.id) {
                            cd.selected = e.target.checked
                        }
                    }))
                }
                break;
            case "header":
                {
                    vendor.SelectedRows?.forEach(a => {
                        if (a.group == data.header || a.header == data.header) {
                            a.selected = e.target.checked
                        }
                    })
                    vendor.VendorCosts?.forEach(co => co.CostingsData?.forEach(cd => {
                        if (cd.group == data.header || cd.header == data.header) {
                            cd.selected = e.target.checked
                        }
                    }))
                }
                break;
            default:
                break;
        }
        index = props.meth.InvitedVendors.findIndex(v => v.VendorId === props.vendor.VendorId)
        project.CostingProfiles[cIndex].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].InvitedVendors[index] = vendor
        dispatch({
            type: currentProjectActions.UPDATE_NEW_PROJECT,
            newProject: project,
        });
    }
    function Tooltip(data) {
        setOpen(true);
        setsid(data);

    }
    function changesplit(e, coheader, type) {
        var VendorCosts = _.cloneDeep(props.vendor.VendorCosts)
        switch (type) {
            case "distribution": {
                VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].SampleDistributionDetailsId = e.id
                VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].RoundId = e.RoundId
            }
                break;
            case "AchievableSamples": {
                let distval = returnDistribution(coheader, "targetsample")
                if (e.target.value == "" || parseInt(e.target.value) < 0) {
                    VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].AchivableSamples = 0
                }
                else {
                    if (parseInt(e.target.value) > parseInt(distval || coheader.CostingOptionData.numberOfGroup || coheader.CostingOptionData.sampleSizeMain)
                        || parseInt(e.target.value < 0)) {
                        toastr.error("Please enter valid value")
                    }
                    else {
                        VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].AchivableSamples = parseInt(e.target.value)
                        e.target.value = parseInt(e.target.value).toLocaleString()
                    }
                }
            }
                break;
            case "Comments": {
                VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].Comments = e.target.value
            } break;
            default:
                break;
        }
        index = props.meth.InvitedVendors.findIndex(v => v.VendorId === props.vendor.VendorId)
        project.CostingProfiles[cIndex].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].InvitedVendors[index].VendorCosts = VendorCosts
        dispatch({
            type: currentProjectActions.UPDATE_NEW_PROJECT,
            newProject: project,
        });
    }
    function OtherCost(e, data) {
        var selectedRows = _.cloneDeep(props.vendor.SelectedRows);
        if (selectedRows.filter(a => a.id == data.id).length > 0) {
            selectedRows.filter(a => a.id == data.id)[0]["OtherCost"] = e.target.value
        }
        index = props.meth.InvitedVendors.findIndex(v => v.VendorId === props.vendor.VendorId)
        project.CostingProfiles[cIndex].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].InvitedVendors[index].SelectedRows = selectedRows
        dispatch({
            type: currentProjectActions.UPDATE_NEW_PROJECT,
            newProject: project,
        });
    }
    const current1 = new Date();
    var d = current1.getDate();
    var m = current1.getMonth() + 1;
    var y = current1.getFullYear();
    var current = d + "-" + m + "-" + y;

    function ChangeValue(name, e, coheader, cdata) {
        e.target.value = "0";
        switch (name) {
            case "History": {
                addHistory(e, coheader, cdata)
            }
            case "Cost": {
                AddValues(e, coheader, cdata)
            }
        }
    }

    function addHistory(e, coheader, cdata) {
        var Vendor;
        Vendor = _.cloneDeep(props.vendor)
        var calculatedvaluetoUSD = (props?.meth?.BiddingDetail?.Currency?.label == "USD" || cdata.type == "TimingSection") ? e.target.value :
            ((e.target.value == "0" || isNaN(e.target.value)) ? 0 : (e.target.value / props?.meth?.BiddingDetail?.Currency?.ConversionRateToLocal).toFixed(4))
        var history = [];
        let oldVal = vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData.filter(a => a.id == cdata.id)[0]?.VendorDataUSD ? vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData.filter(a => a.id == cdata.id)[0]?.VendorDataUSD : 0
        if (!(oldVal == calculatedvaluetoUSD)) {
            if (!Vendor.CostHistory) {
                Vendor.CostHistory = []
                history.push({ "oldval": oldVal, "latestvalue": calculatedvaluetoUSD, "doneby": userRecord.FirstName + " " + userRecord.LastName, "CostingOptionsName": coheader.Name, "date": current, "OrignalValue": e.target.value, "Currencylabel": props?.meth?.BiddingDetail?.Currency?.label })
                Vendor.CostHistory.push({ "id": cdata.id, "history": history })
            }
            else {
                if (Vendor.CostHistory.filter(a => a.id == cdata.id).length > 0) {
                    Vendor.CostHistory.filter(a => a.id == cdata.id)[0].history.push({ "oldval": oldVal, "date": current, "latestvalue": calculatedvaluetoUSD, "doneby": userRecord.FirstName + " " + userRecord.LastName, "CostingOptionsName": coheader.Name, "OrignalValue": e.target.value, "Currencylabel": props?.meth?.BiddingDetail?.Currency?.label })
                }
                else {
                    history.push({ "oldval": oldVal, "date": current, "latestvalue": calculatedvaluetoUSD, "doneby": userRecord.FirstName + " " + userRecord.LastName, "CostingOptionsName": coheader.Name, "OrignalValue": e.target.value, "Currencylabel": props?.meth?.BiddingDetail?.Currency?.label })
                    Vendor.CostHistory.push({ "id": cdata.id, "history": history })
                }
            }
            index = props.meth.InvitedVendors.findIndex(v => v.VendorId === props.vendor.VendorId)
            project.CostingProfiles[cIndex].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].InvitedVendors[index] = Vendor
            dispatch({
                type: currentProjectActions.UPDATE_NEW_PROJECT,
                newProject: project,
            });
            setVendor(Vendor)
        }
    }
    function AddValues(e, coheader, cdata) {
        if (e.target.value < 0) {
            toastr.error("Please enter valid value")
        }
        else {
            var Vendor;
            Vendor = _.cloneDeep(props.vendor)
            var calculatedvaluetoUSD = (props?.meth?.BiddingDetail?.Currency?.label == "USD" || cdata.type == "TimingSection") ? e.target.value :
                ((e.target.value == "0" || isNaN(e.target.value)) ? 0 : (e.target.value / props?.meth?.BiddingDetail?.Currency?.ConversionRateToLocal).toFixed(5))
            var oldval;
            if (Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData.filter(a => a.id == cdata.id).length > 0) {
                oldval = Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData.filter(a => a.id == cdata.id)[0].VendorDataUSD;

                Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].CostingsData.filter(a => a.id == cdata.id)[0].VendorDataUSD = (calculatedvaluetoUSD == "" || isNaN(calculatedvaluetoUSD)) ? "" : calculatedvaluetoUSD
                Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].CostingsData.filter(a => a.id == cdata.id)[0].OrignalValue = e.target.value;
                Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0].CostingsData.filter(a => a.id == cdata.id)[0].Currencylabel = props?.meth?.BiddingDetail?.Currency?.label
            }
            else {
                oldval = 0;
                Vendor.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData.push({ "VendorDataUSD": ((calculatedvaluetoUSD == "" || isNaN(calculatedvaluetoUSD)) ? "" : calculatedvaluetoUSD), "key": cdata.key, "name": cdata.name, "id": cdata.id, "type": cdata.type, "group": cdata.group, "selected": Vendor.SelectedRows.filter(a => a.id == cdata.id)[0].selected, "OrignalValue": e.target.value, "Currencylabel": props?.meth?.BiddingDetail?.Currency?.label })
            }

            index = props.meth.InvitedVendors.findIndex(v => v.VendorId === props.vendor.VendorId)
            project.CostingProfiles[cIndex].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].InvitedVendors[index] = Vendor
            dispatch({
                type: currentProjectActions.UPDATE_NEW_PROJECT,
                newProject: project,
            });
        }

    }
    function returnvalue(coheader, cdata) {

        var value = (props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == cdata.id).length > 0 ?
            props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == cdata.id)[0].VendorDataUSD : "")
        let OV = (props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == cdata.id).length > 0 ?
            props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == cdata.id)[0]?.OrignalValue : "")
        let OVLabel = (props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == cdata.id).length > 0 ?
            props.vendor?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == cdata.id)[0]?.Currencylabel : "")
        var tosend;
        if (value == "") { tosend = "" }
        else {
            if (OVLabel == props?.meth?.BiddingDetail?.Currency?.label) {
                if (!OV?.includes("."))
                    tosend = Math.round(value * props.meth?.BiddingDetail?.Currency.ConversionRateToLocal)
                else {
                    if (OV?.lastIndexOf(".") == OV?.length - 1) {
                        tosend = Math.round(value * props.meth?.BiddingDetail?.Currency.ConversionRateToLocal) + "."
                    }
                    else {
                        let fixeddata = (OV?.length - 1) - OV?.lastIndexOf(".")
                        tosend = (value * props.meth?.BiddingDetail?.Currency.ConversionRateToLocal).toFixed(fixeddata > 2 ? 2 : fixeddata)
                    }
                }
            }
            else
                tosend = (value * props.meth?.BiddingDetail?.Currency.ConversionRateToLocal).toFixed(2)
        }

        return (tosend)
    }

    return (
        <>
            <Container>
                <Row className="justify-content-end m-2">
                    <Button style={{ marginBottom: "10px", marginTop: "10px", float: "right" }}
                        onClick={(e) => { resetCostingButton() }}
                        disabled={ViewOnlyMode}
                    >
                        Unblock all Costing
                    </Button>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Table
                        className="table table-stripped"
                        size="sm"
                        bordered
                        style={{
                            backgroundColor:
                                "white",
                            color:
                                "black",
                            maxWidth: "100%",
                            width: "auto",
                        }}
                    >
                        <tr style={{ align: "right" }}>

                            {
                                // props.meth.CostingOptions?.map(
                                //         (coheader) => (

                                // )
                                // )
                            }

                        </tr>
                        <tr>
                            <th
                                style={{
                                    // display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    minWidth: "250px",
                                }}
                                colSpan={2}
                            >
                            </th>
                            <th></th>
                            {props.meth.CostingOptions?.map(
                                (coheader) => (
                                    <th
                                        style={{
                                            maxWidth: "300px",
                                            backgroundColor: coheader.Selected ? "#00f000" : "#ff0000",
                                            color: "black",
                                            textAlign: 'center'
                                        }}
                                    >
                                        {coheader.Name}
                                        {coheader.Selected && <input
                                            style={{
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                            }}
                                            type="checkbox"
                                            id={props.vendor.id}
                                            name={props.vendor.label + " " + coheader.Name}
                                            title="Select Option"
                                            checked={props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsInvited}
                                            onChange={(e) => { Edit(e, "IsInvited", coheader) }}
                                            disabled={ViewOnlyMode}
                                        />
                                        }
                                        {coheader.Selected && props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsInvited &&
                                            <FontAwesomeIcon
                                                title="Edit"
                                                className="align-middle"
                                                icon={(props.vendor.VendorType == "Internal" || props.vendor.IsDeclined || ViewOnlyMode) ? "" : faPen}
                                                color="#000"
                                                style={{
                                                    cursor: "pointer",
                                                    marginLeft: "10px",
                                                }}
                                                onClick={(e) => { Edit(e, "Pen", coheader) }}

                                            />
                                        }
                                    </th>
                                )
                            )}
                        </tr>

                        {props.ServiceType == 0 &&
                            <>
                                <tr>
                                    <td style={{ position: "relative" }} colSpan={2}>
                                        Bid Round
                                    </td><td></td>
                                    {props.meth.CostingOptions?.map(
                                        (coheader) => (
                                            <td style={{ textAlign: 'center' }}>
                                                <label>{returnDistribution(coheader, "round")}</label>
                                            </td>))}
                                </tr>

                                <tr>
                                    <td style={{ position: "relative" }} colSpan={2}>
                                        Vendor Selection
                                    </td>
                                    <td></td>
                                    {props.meth.CostingOptions?.map(
                                        (coheader) => (
                                            <td style={{ textAlign: 'center' }}>
                                                <input
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                    type="checkbox"
                                                    name={coheader.name}
                                                    title="Select Option"
                                                    disabled={(ViewOnlyMode) || (props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.AchivableSamples == 0 ? true : false) || props.vendor.IsDeclined || (!coheader.Selected) || (props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.IsInvited == false)}
                                                    onChange={(e) => { Edit(e, "IsSelected", coheader) }}
                                                    checked={props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsSelected}
                                                />
                                            </td>))}
                                </tr>

                            </>
                        }
                        <><tr>
                            <td style={{ position: "relative" }} colSpan={2}>
                                Block Costing
                            </td><td></td>
                            {props.meth.CostingOptions?.map(
                                (coheader) => (
                                    <td style={{ textAlign: 'center' }}>
                                        <input
                                            style={{
                                                marginRight: "10px",
                                            }}
                                            type="checkbox"
                                            name={coheader.name}
                                            title="Select Option"
                                            disabled={(ViewOnlyMode) || (!props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsInvited || props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsSelected) ? true : false
                                                // ((!props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsVendorSubmit &&
                                                //  !coheader.Selected) ?
                                                // true : props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsSelected ?
                                                // true : false)

                                                // !props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsInvited ||
                                                // // !props.vendor?.IsMailSent ||
                                                // props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsSelected 
                                            }
                                            onChange={(e) => { Edit(e, "IsVendorSubmit", coheader) }}
                                            checked={props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsVendorSubmit}

                                        />
                                    </td>))}
                        </tr>
                        </>



                        {props.mappingState?.filter(a => a.header && a.type != "TimingSection").map(tab => {
                            return (<>
                                <tr>
                                    <th
                                        style={{
                                            backgroundColor: "#00f000",
                                            borderStyle: "groove",
                                            borderRight: "10px",
                                            borderBottom: "10px",
                                        }}
                                        colSpan={
                                            props.meth.CostingOptions?.length + 3
                                        }
                                    >  <input
                                            style={{
                                                marginRight: "10px",
                                            }}
                                            type="checkbox"
                                            name={tab.name}
                                            title="Select Option"
                                            onChange={(e) => { SelectedRows(e, tab, "header", "full") }}
                                            disabled={ViewOnlyMode}
                                            checked={props.vendor?.SelectedRows?.filter(a => a.id == tab.id)[0]?.selected}
                                        />
                                        {tab.header}
                                    </th>
                                </tr>
                                {tab.header == "Field Cost" && <>
                                    <tr>
                                        <td style={{ position: "relative" }} colSpan={2}>
                                            Distribution
                                        </td>
                                        <td></td>
                                        {props.meth.CostingOptions?.map(
                                            (coheader) => (option(coheader, "options")?.length > 0 ?
                                                <td>
                                                    <FormGroup>
                                                        <Select
                                                            name={coheader.Name}
                                                            onChange={(e) => { changesplit(e, coheader, "distribution") }}
                                                            options={option(coheader, "options")}
                                                            value={returnDistribution(coheader, "distribution") ? returnDistribution(coheader, "distribution") : []}
                                                            className="basic-multi-select"
                                                            classNamePrefix="react-select"
                                                            isDisabled={!props.vendor?.SelectedRows?.filter(a => a.id == tab.id)[0]?.selected
                                                                || props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsSelected
                                                                || (splitdata?.filter(a => (a.id == props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.RoundId))[0]?.RoundClosedOn ? true : false)
                                                                || (props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.AchivableSamples > 0 ? true : false)
                                                                || (props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.CostingsData?.filter(a => a.VendorDataUSD > 0)?.length > 0)
                                                                || (!coheader.Selected)
                                                                || (props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.IsInvited == false)
                                                                || (ViewOnlyMode)
                                                            }
                                                        />
                                                    </FormGroup>
                                                </td>
                                                :
                                                <td></td>))}

                                    </tr>

                                    <tr>
                                        <td style={{ position: "relative" }} colSpan={2}>
                                            Target Sample
                                        </td>
                                        <td></td>
                                        {props.meth.CostingOptions?.map(
                                            (coheader) => (
                                                <td>
                                                    <input
                                                        style={{
                                                            marginLeft: "10px",
                                                            marginRight: "10px",
                                                        }}
                                                        type="number"
                                                        id={coheader.id}
                                                        name={coheader.Name}
                                                        disabled={true}
                                                        title="Target Samples"
                                                        value={returnDistribution(coheader, "targetsample") ? returnDistribution(coheader, "targetsample") : ""}
                                                    />
                                                </td>))}
                                    </tr>
                                    <tr>
                                        <td style={{ position: "relative" }} colSpan={2}>
                                            Achievable Samples
                                        </td>
                                        <td></td>
                                        {props.meth.CostingOptions?.map(
                                            (coheader) => (
                                                <td>
                                                    <input
                                                        style={{
                                                            marginLeft: "10px",
                                                            marginRight: "10px",
                                                        }}
                                                        type="number"
                                                        id={coheader.id}
                                                        name={coheader.Name}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        pattern="[0-9]"
                                                        onChange={(e) => { changesplit(e, coheader, "AchievableSamples") }}
                                                        value={props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.AchivableSamples
                                                        }
                                                        title="Achievable Samples"
                                                        disabled={(ViewOnlyMode) || !props.vendor?.SelectedRows?.filter(a => a.id == tab.id)[0]?.selected || props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsSelected || props.vendor.IsDeclined || (!coheader.Selected) || !returnDistribution(coheader, "targetsample") || (props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.IsInvited == false)}
                                                    />
                                                </td>))}
                                    </tr>
                                    <tr>
                                        <td style={{ position: "relative" }} colSpan={2}>
                                            CityCoverage
                                        </td>
                                        <td></td>
                                        {props.meth.CostingOptions?.map(
                                            (coheader) => (
                                                <td>
                                                    <label>{props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId ?
                                                        returnDistribution(coheader, "cities") : coheader.CostingOptionData["citiesCoverage"]?.map(a => a.label)?.toString()}</label>
                                                </td>))}
                                    </tr>
                                    <tr>
                                        <td style={{ position: "relative" }} colSpan={2}>
                                            Comments (By Costing Option)
                                        </td>
                                        <td></td>
                                        {props.meth.CostingOptions?.map(
                                            (coheader) => (
                                                <td>
                                                    <textarea
                                                        className="form-control w-100"
                                                        type="text"
                                                        onChange={(e) => { changesplit(e, coheader, "Comments") }}
                                                        value={props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.Comments
                                                        }
                                                        disabled={(ViewOnlyMode) || !props.vendor?.SelectedRows?.filter(a => a.id == tab.id)[0]?.selected || props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsSelected || props.vendor.IsDeclined || (!coheader.Selected) || !returnDistribution(coheader, "targetsample") || (props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.IsInvited == false)}

                                                    />
                                                </td>))}
                                    </tr>
                                </>
                                }

                                {/* timing section  */}
                                {props.mappingState?.filter(a => (a.type == "TimingSection" && a.group == tab.header) || a.header == 'TIMINGS ( # of WORKING DAYS)')?.length > 0 &&
                                    <tr>
                                        <th
                                            style={{
                                                backgroundColor: "#dcdcdc",
                                                borderStyle: "groove",
                                                borderRight: "10px",
                                                borderBottom: "10px",
                                            }}
                                            colSpan={2}
                                        >
                                            <input
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                                type="checkbox"
                                                name={tab.name}
                                                title="Select Option"
                                                onChange={(e) => { SelectedRows(e, tab, "timing", "full") }}
                                                checked={props.vendor?.SelectedRows?.filter(a => a.type == "TimingSection" && a.group == tab.header && !a.header).map(a => a.selected).filter(a => !a).length > 0 ? false : true}
                                                disabled={(ViewOnlyMode) || !props.vendor?.SelectedRows?.filter(a => a.id == tab.id)[0]?.selected
                                                }
                                            />
                                            TIMINGS ( # of WORKING DAYS)
                                        </th>
                                        <th
                                            style={{
                                                textAlign: "center",
                                                backgroundColor: "#dcdcdc",
                                                borderStyle: "groove",
                                                borderRight: "10px",
                                                borderBottom: "10px",
                                            }}>
                                        </th>
                                        {props.meth.CostingOptions?.map(a => (
                                            <th
                                                style={{
                                                    textAlign: "center",
                                                    backgroundColor: "#dcdcdc",
                                                    borderStyle: "groove",
                                                    borderRight: "10px",
                                                    borderBottom: "10px",
                                                }}>
                                                Days
                                            </th>))
                                        }
                                    </tr>}
                                {props.mappingState?.filter(a => (a.type == "TimingSection" && a.group == tab.header) || a.header == 'TIMINGS ( # of WORKING DAYS)')?.map(cdata => {
                                    return (
                                        <tr>
                                            <td style={{ position: "relative" }} colSpan={cdata.name.includes("Other Cost") ? 1 : 2} >
                                                <input
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                    type="checkbox"
                                                    name={cdata.name}
                                                    title="Select Option"
                                                    onChange={(e) => { SelectedRows(e, cdata, "timing", "single") }}
                                                    checked={props.vendor?.SelectedRows?.filter(a => a.id == cdata.id)[0]?.selected}
                                                    disabled={(ViewOnlyMode) || !props.vendor?.SelectedRows?.filter(a => a.id == tab.id)[0]?.selected}

                                                />
                                                {
                                                    cdata.name
                                                }
                                            </td>
                                            {cdata.name.includes("Other Cost") && <td>
                                                <input
                                                    style={{
                                                        marginLeft: "10px",
                                                        marginRight: "10px",
                                                    }}
                                                    type="number"
                                                    name={"othercost" + cdata.id}
                                                    title="Select Option"
                                                    onChange={(e) => { OtherCost(e, cdata) }}
                                                    value={props.vendor?.SelectedRows?.filter(a => a.id == cdata.id) ?
                                                        props.vendor?.SelectedRows?.filter(a => a.id == cdata.id)[0].OtherCost ?
                                                            props.vendor?.SelectedRows?.filter(a => a.id == cdata.id)[0].OtherCost : "" : ""}
                                                    disabled={ViewOnlyMode}
                                                />
                                            </td>}
                                            <td>
                                                <FontAwesomeIcon
                                                    title="History"
                                                    className="align-middle"
                                                    icon={faHistory}
                                                    style={{
                                                        cursor: "pointer",
                                                        marginLeft: "10px",
                                                    }}
                                                    onClick={() => { Tooltip(cdata) }}
                                                />
                                            </td>
                                            {props.meth.CostingOptions?.map(
                                                (coheader) => (
                                                    <td>

                                                        <input
                                                            style={{
                                                                marginLeft: "10px",
                                                                marginRight: "10px",
                                                            }}
                                                            type="text"
                                                            id={coheader.id}
                                                            name={coheader.Name}
                                                            title="Select Option"
                                                            pattern="[0-9]"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                e.target.value.includes(".") ? ChangeValue("History", e, coheader, cdata)
                                                                    : addHistory(e, coheader, cdata)
                                                            }}
                                                            onChange={(e) => {
                                                                e.target.value.includes(".") ? ChangeValue("Cost", e, coheader, cdata)
                                                                    : AddValues(e, coheader, cdata)
                                                            }}
                                                            value={
                                                                (props.vendor?.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.
                                                                    filter(a => a.id == cdata.id).length > 0 ? props.vendor?.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.
                                                                        filter(a => a.id == cdata.id)[0].VendorDataUSD : "")
                                                            }
                                                            disabled={props.vendor?.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0]?.IsEditable ||
                                                                !props.vendor?.SelectedRows?.filter(a => a.id == tab.id)[0]?.selected
                                                                // || props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsSelected
                                                                //|| (splitdata?.filter(a => (a.id == props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.RoundId))[0]?.RoundClosedOn ? true : false)
                                                                || (!coheader.Selected)
                                                                || (props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.IsInvited == false)
                                                                || (ViewOnlyMode)
                                                            }
                                                        />
                                                    </td>
                                                ))}
                                        </tr>)
                                })}
                                {/* Costing  */}
                                <tr>
                                    <th
                                        style={{
                                            backgroundColor: "#dcdcdc",
                                            borderStyle: "groove",
                                            borderRight: "10px",
                                            borderBottom: "10px",
                                        }}
                                        colSpan={
                                            2
                                        }
                                    >

                                        <input
                                            style={{
                                                marginRight: "10px",
                                            }}
                                            type="checkbox"
                                            name={tab.name}
                                            title="Select Option"
                                            onChange={(e) => { SelectedRows(e, tab, "Costing", "full") }}
                                            checked={props.vendor?.SelectedRows?.filter(a => a.type != "TimingSection" && a.group == tab.header && !a.header).map(a => a.selected).filter(a => !a).length > 0 ? false : true}
                                            disabled={(ViewOnlyMode) || !props.vendor?.SelectedRows?.filter(a => a.id == tab.id)[0]?.selected}
                                        />
                                        Costing Section
                                    </th>
                                    <th style={{
                                        textAlign: "center",
                                        backgroundColor: "#dcdcdc",
                                        borderStyle: "groove",
                                        borderRight: "10px",
                                        borderBottom: "10px",
                                    }}></th>
                                    {props.meth.CostingOptions?.map(a => (
                                        <th
                                            style={{
                                                textAlign: "center",
                                                backgroundColor: "#dcdcdc",
                                                borderStyle: "groove",
                                                borderRight: "10px",
                                                borderBottom: "10px",
                                            }}>
                                            <FontAwesomeIcon
                                                title="Currency"
                                                className="align-middle"
                                                icon={faMoneyBill}
                                                style={{
                                                    cursor: "pointer",
                                                    marginLeft: "10px",
                                                }}
                                            />

                                            {props.meth?.BiddingDetail?.Currency == null ? "USD" : props.meth?.BiddingDetail?.Currency?.label}
                                        </th>))
                                    }
                                </tr>
                                {props.mappingState?.filter(a => (a.group == tab.header) && a.id != 14 && a.type != "TimingSection" && !a.header).map(cdata => {
                                    return (
                                        <tr>
                                            <td style={{ position: "relative" }} colSpan={cdata.name.includes("Other Cost") ? 1 : 2} >
                                                <input
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                    type="checkbox"
                                                    name={cdata.name}
                                                    title="Select Option"
                                                    onChange={(e) => { SelectedRows(e, cdata, "Costing", "single") }}
                                                    checked={props.vendor?.SelectedRows?.filter(a => a.id == cdata.id)[0]?.selected}
                                                    disabled={(ViewOnlyMode) || !props.vendor?.SelectedRows?.filter(a => a.id == tab.id)[0]?.selected}
                                                />
                                                {
                                                    cdata.name
                                                }
                                            </td>
                                            {cdata.name.includes("Other Cost") && <td>
                                                <input
                                                    style={{
                                                        marginLeft: "10px",
                                                        marginRight: "10px",
                                                    }}
                                                    type="text"
                                                    name={"othercost" + cdata.id}
                                                    title="Select Option"
                                                    onChange={(e) => { OtherCost(e, cdata) }}
                                                    value={props.vendor?.SelectedRows?.filter(a => a.id == cdata.id) ?
                                                        props.vendor?.SelectedRows?.filter(a => a.id == cdata.id)[0].OtherCost ?
                                                            props.vendor?.SelectedRows?.filter(a => a.id == cdata.id)[0].OtherCost : "" : ""}
                                                    disabled={ViewOnlyMode}
                                                />
                                            </td>}
                                            <td>
                                                <FontAwesomeIcon
                                                    title="History"
                                                    className="align-middle"
                                                    icon={faHistory}
                                                    style={{
                                                        cursor: "pointer",
                                                        marginLeft: "10px",
                                                    }}
                                                    onClick={() => { Tooltip(cdata) }}

                                                />
                                            </td>
                                            {props.meth.CostingOptions?.map(
                                                (coheader) => (
                                                    <td>

                                                        <input
                                                            style={{
                                                                marginLeft: "10px",
                                                                marginRight: "10px",
                                                            }}
                                                            type="text"
                                                            id={coheader.id}
                                                            name={coheader.Name}
                                                            title="Select Option"
                                                            onBlur={(e) => { addHistory(e, coheader, cdata) }}
                                                            onChange={(e) => { AddValues(e, coheader, cdata) }}
                                                            disabled={props.vendor?.VendorCosts.filter(a => a.CostOptionId == coheader.id)[0]?.IsEditable
                                                                || (!props.vendor?.SelectedRows?.filter(a => a.id == tab.id)[0]?.selected)
                                                                //|| props.vendor?.VendorCosts?.find(a => a.CostOptionId == coheader.id)?.IsSelected
                                                                || (!coheader.Selected)
                                                                || (props.vendor.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.IsInvited == false)
                                                                || (ViewOnlyMode)
                                                            }
                                                            value={returnvalue(coheader, cdata)}
                                                        />
                                                    </td>

                                                ))}
                                        </tr>)
                                }
                                )}

                            </>)
                        })
                        }

                        <>
                            <tr>
                                <td style={{ position: "relative" }} colSpan={2} >
                                    Total Outsourced Vendor Data collection with QC (External)
                                </td>
                                <td></td>
                                {props.meth.CostingOptions?.map(
                                    (coheader) => (
                                        <td>

                                            <input
                                                style={{
                                                    marginLeft: "10px",
                                                    marginRight: "10px",
                                                }}
                                                type="number"
                                                id={coheader.id}
                                                name={coheader.Name}
                                                title="Select Option"
                                                disabled={true}
                                                value={returnTotal(coheader)}

                                            />
                                        </td>
                                    ))}
                            </tr>
                            <tr>
                                <td style={{ position: "relative" }} colSpan={2} >
                                    Total Outsourced Vendor Data collection with QC (External) CPI
                                </td>
                                <td></td>
                                {props.meth.CostingOptions?.map(
                                    (coheader) => (
                                        <td>

                                            <input
                                                style={{
                                                    marginLeft: "10px",
                                                    marginRight: "10px",
                                                }}
                                                type="number"
                                                id={coheader.id}
                                                name={coheader.Name}
                                                title="Select Option"
                                                disabled={true}
                                                value={returnTotalCPI(coheader)}


                                            />
                                        </td>
                                    ))}
                            </tr>
                        </>


                    </Table>
                </Row>
            </Container>
            <HistoryModal isOpen={open} vendor={props.vendor} meth={props.meth} sid={sid.id} sidtype={sid.type} popupHandler={(type, ev) => handleHistory(type, ev)} />
        </>

    )
}
export default VendorCostingAndTimeline