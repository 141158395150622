import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../components/Spinner";
import logo from "../../assets/img/avatars/modal-logo.png";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Row,
  Col,
  FormFeedback,
  FormGroup,
} from "reactstrap";
import {
  pageLoadEnd,
  pageLoadStart,
  recordLoadEnd,
  recordLoadStart,
} from "../../redux/actions/appActions";
// import { useParams } from "react-router-dom";
import axios from "../../../src/axios-interceptor";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import { toastr } from "react-redux-toastr";

function ResetApprovalModal({
  resetModal,
  setResetModal,
  reasonForApproval,
  setreasonForApproval,
  getHistoryForApproval
}) {
  // let { profileId } = useParams();
  // console.log(profileId, "profileId");
  const dispatch = useDispatch();
  const userRecord = useSelector(({ user }) => user.userRecord);

  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const app = useSelector(({ app }) => app);
  const loading = useSelector(({ app }) => app.recordloading);

  const getReasonForApproval = (e) => {
    setreasonForApproval(e.target.value);

  };
  // console.log("reasonForApproval", reasonForApproval);
  const confirmApproval = (e) => {
    dispatch(recordLoadStart());
    if (!reasonForApproval) {
      toastr.error("Enter reason for Reset of Approval")
      setResetModal(false);
    } else {
      toastr.success("Approval(s) reset success")

      // dispatch(recordLoadStart());
      // dispatch(currentCostingActions.unlockPriceToClient());
      setreasonForApproval("")
      setResetModal(!resetModal);
      dispatch(currentCostingActions.unlockPriceToClient());
      dispatch(currentCostingActions.resetApprovals());
    }
    let detailsForApproval = profile.ApprovalDetails.filter((level) => {
      if (level.Order <= profile.ApprovalLevelNeeded)
      return level.Label
    });
    let approverContacts = []
    profile.ApprovalDetails.map((val) => {

      val.ApproverContacts.map((eadd) => {
        approverContacts.push(eadd.EmailAddress)
      })

    })
    let approvalStatus = {}
    profile.ApprovalDetails.map((level) => {
      approvalStatus[level.Order] =
        profile.ApprovalLevelReached >=
          level.Order
          ? "Approved"
          : profile.ApprovalLevelAwaiting ===
            level.Order
            ? "Awaiting"
            : null
    })


    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + time;
    const userIs = userRecord.Email;

    let dataforapproval = {
      profile: profile,
      resetData: {
        ProjectId: profile.ProjectId,
        ProfileId: profile.id,
        Reason: reasonForApproval,
        ApprovalAction: "Reset",
        ByUser: userIs,
        PerformedOn: dateTime,
        ProfileStatusWas: {
          detailsForApproval,
          approverContacts,
          approvalStatus
        }
      }
    };

    axios.post("/costingprofiles/approvals/reset", dataforapproval)
      .then((res) => {
        console.log(res);
        dispatch(recordLoadEnd())
        getHistoryForApproval()

      })
      .catch((error) => {
        console.log("errr", error);
        dispatch(recordLoadEnd());
      });
  };
  const closePopup = () => {
    setResetModal(!resetModal);
    setreasonForApproval("")
  }
  return (
    <>
      <Modal
        isOpen={resetModal}
        toggle={() => setResetModal(!resetModal)}
        centered={true}
        size="md"
        backdrop="static"
      >
        <ModalHeader toggle={() => setResetModal(!resetModal)}>
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <div>
          <span className="modaltitle modalops">Reset Approval</span>
        </div>
        <ModalBody>
          <span className="modaltext">Are you sure you want to Reset Approval?</span>

          <Row className="mb-1">
            <Col>
              {" "}
              Reason for Reset of Approval<sup className="requiredField">(*)</sup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Input
                  type="textarea"
                  // invalid={validation.approvalReason}
                  placeholder="Please provide reason for Resetting of Approval details here..."
                  // value={request.InitialNotes ?? ""}
                  onChange={(e) => getReasonForApproval(e)}

                />
                <FormFeedback>
                  Please provide reason for Resetting of Approval
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={(e) => {
              // dispatch(currentCostingActions.unlockPriceToClient());
              // setResetModal(!resetModal);
              // setReasonForApproval("");
              confirmApproval()
              // dispatch(buttonClicked(e))
              // console.log("dispatch(buttonClicked(e))",dispatch(buttonClicked(e)));
            }}
            disabled={app.recordloading}>
            Confirm {loading ? <Spinner size="small" /> : null}
          </Button>
          <Button
            color="secondary"
            onClick={closePopup}
            disabled={app.recordloading}>
            Cancel
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ResetApprovalModal;
