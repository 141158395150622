import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    AvForm,
    AvField,
    AvRadioGroup,
    AvInput,
    AvRadio
} from "availity-reactstrap-validation";
import {
    CustomInput,
    Form,
    FormGroup,
    FormFeedback,
    Input,
    Label,
    Row,
    Col,
    Table,
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader
    , UncontrolledTooltip
} from "reactstrap";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { getMultiOptions } from "../../utils/codeLabels";
import { useState } from "react";
import { faPencilAlt, faCopy, faPlus, faPlusCircle, faTimesCircle, faUserCheck, faUndo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import update from 'immutability-helper'
import _ from "lodash";
import { updateProfile } from "../../redux/actions/currentCostingActions";
import { useDispatch, useSelector } from "react-redux";
import ReturnControls from "./ReturnControl";

const Start = ({ profile, countryIndex, methodology, methodologyIndex, updateProject, errorFieldsObj,
    vendor = true, enable = true, isEditable,
    disableFields }) => {
    const dispatch = useDispatch();
    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );
    // useEffect(() => {
    //     if(profile.CountrySpecs && profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions && profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[0] && profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[0].Variables && profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[0].Variables[4] && profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[0].Variables[4].value)
    //     {
    //     let trackingValue = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables[4].value;
    //     if (trackingValue)
    //         InfoChange(0, 4, "Tracking Project", trackingValue);
    //     }
    // }, [project.ReloadForms]);

    const styleModelBody = {
        minHeight: "200px"
    }
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);

    const [modelState, setModelState] = useState(false);

    const [modelStateVar, setModelStateVar] = useState(false);

    const [costingOptionIndex, setCostingOptionIndex] = useState(null);

    const [modelMode, setModelMode] = useState('new');

    const [newCostingOption, setNewCostingOption] = useState(null);

    const [addVariables, setAddVariables] = useState([]);

    const [searchValue, setSearchValue] = useState(null);


    const [copyToCostingOption, setCopyTo] = useState(null);

    const ClearSearch = () => {
        setSearchValue(null);
        let tempAddVariables = _.cloneDeep(addVariables);
        tempAddVariables.forEach(x => {
            x.show = false;
            x.selected = false;
        });
        setAddVariables(tempAddVariables);
    }
    const updateProjectAndProfileWRTCostingOption = (profileTemp) => {
        const tempProfiles = project.CostingProfiles.map(cp => {
            if (cp.id == profileTemp.id)

                return { ..._.cloneDeep(profileTemp) }
            return { ..._.cloneDeep(cp) }
        });
        dispatch(updateProfile(profileTemp))
        dispatch(updateProject({ CostingProfiles: tempProfiles }));
    }
    const saveCostingOption = (mode) => {
        if (mode == "new") {
            // let profileTemp = update(profile, {
            //     CountrySpecs: {
            //         [countryIndex]: {
            //             MethodologySpecs:
            //             {
            //                 [methodologyIndex]:
            //                 {
            //                     CostingOptions:
            //                     {
            //                         $push: [
            //                             { label: newCostingOption, Variables: _.cloneDeep(_.head(methodology.CostingOptions).Variables) },
            //                         ]
            //                     }
            //                 }
            //             }
            //         }
            //     }
            // });
            let profileTemp = _.cloneDeep(profile)
            let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
            profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = _.cloneDeep(
                [...costingOptionsClone, ...[
                    { label: newCostingOption, Variables: _.cloneDeep(_.head(methodology.CostingOptions).Variables) },
                ]])
            const tempProfiles = project.CostingProfiles.map(cp => {
                if (cp.id == profileTemp.id)
                    return { ..._.cloneDeep(profileTemp) }
                return { ..._.cloneDeep(cp) }
            });
            dispatch(updateProfile(profileTemp))
            dispatch(updateProject({ CostingProfiles: tempProfiles }));
        }
        else if (mode == "rename") {
            // let profileTemp = update(profile, {
            //     CountrySpecs: {
            //         [countryIndex]: {
            //             MethodologySpecs:
            //             {
            //                 [methodologyIndex]:
            //                 {
            //                     CostingOptions:
            //                     {
            //                         [costingOptionIndex]:
            //                         {
            //                             $merge: { label: newCostingOption }
            //                         }
            //                     }
            //                 }
            //             }
            //         }
            //     }
            // });
            let profileTemp = _.cloneDeep(profile)
            let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
            costingOptionsClone[costingOptionIndex].label = newCostingOption
            profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
            const tempProfiles = project.CostingProfiles.map(cp => {
                if (cp.id == profileTemp.id)
                    return { ..._.cloneDeep(profileTemp) }
                return { ..._.cloneDeep(cp) }
            });
            dispatch(updateProfile(profileTemp))
            dispatch(updateProject({ CostingProfiles: tempProfiles }));
        }
        else if (mode == "copy") {
            if (newCostingOption) {
                // let profileTemp = update(profile, {
                //     CountrySpecs: {
                //         [countryIndex]: {
                //             MethodologySpecs:
                //             {
                //                 [methodologyIndex]:
                //                 {
                //                     CostingOptions:
                //                     {
                //                         $push: [
                //                             { label: newCostingOption, Variables: _.cloneDeep(methodology.CostingOptions[costingOptionIndex]).Variables },
                //                         ]
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // });
                let profileTemp = _.cloneDeep(profile)
                let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
                profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = [...costingOptionsClone, ...[
                    { label: newCostingOption, Variables: _.cloneDeep(methodology.CostingOptions[costingOptionIndex]).Variables },
                ]]
                const tempProfiles = project.CostingProfiles.map(cp => {
                    if (cp.id == profileTemp.id)
                        return { ..._.cloneDeep(profileTemp) }
                    return { ..._.cloneDeep(cp) }
                });
                dispatch(updateProfile(profileTemp))
                dispatch(updateProject({ CostingProfiles: tempProfiles }));
            }
            else if (copyToCostingOption) {
                const CostingOptions = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions;
                const indexCopyToCostingOption = CostingOptions.findIndex(x => x.label == copyToCostingOption.label);
                const costingOptionClone = _.cloneDeep(CostingOptions[indexCopyToCostingOption]);
                costingOptionClone.Variables = _.cloneDeep(CostingOptions[costingOptionIndex].Variables);

                // let profileTemp = update(profile, {
                //     CountrySpecs: {
                //         [countryIndex]: {
                //             MethodologySpecs:
                //             {
                //                 [methodologyIndex]:
                //                 {
                //                     CostingOptions:
                //                     {
                //                         [indexCopyToCostingOption]: { $set: costingOptionClone }
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // });
                let profileTemp = _.cloneDeep(profile)
                let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
                costingOptionsClone[indexCopyToCostingOption] = costingOptionClone
                profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
                const tempProfiles = project.CostingProfiles.map(cp => {
                    if (cp.id == profileTemp.id)
                        return { ..._.cloneDeep(profileTemp) }
                    return { ..._.cloneDeep(cp) }
                });
                dispatch(updateProfile(profileTemp))
                dispatch(updateProject({ CostingProfiles: tempProfiles }));
            }
        }
    }

    const errorStyles = {

        control: (styles) => ({ ...styles, borderColor: "#AD4400" })


    }
    const CommissioningCountryCheck = (actualstatus) => {
        let _updatedStatus = actualstatus
        if (project.CommissioningCountry == profile.CountrySpecs[countryIndex].CountryCode) {
            let fieldingCountries = Array.isArray(profile.FieldingCountries) ? profile.FieldingCountries.map(fc => fc.value) : profile.FieldingCountries?.split(",")
            if (!_.includes(fieldingCountries, project.CommissioningCountry)) {
                return true
            } else if (_.includes(fieldingCountries, project.CommissioningCountry)) {
                return false
            }
        }

        return _updatedStatus
    }
    // const ReturnControl = (co,indexCO, indexV, variable) => {
    //     const v = methodology.CostingOptions.find(x => x.id === co.id).Variables.filter(x => x.label == variable.label)[0] || methodology.CostingOptions[0].Variables.filter(x => x.label == variable.label)[0];
    //     //if (v.disabled)
    //     //    return null;
    //     if (methodology.RFQSchema.properties && methodology.RFQSchema.properties[v.id] && !v.value) {
    //         if (methodology.RFQSchema.properties[v.id].defaultValue) {
    //             v.value = (v.type === 'creatableMulti' && v.optionsLabel && v.optionsLabel === 'Cities') ?
    //                 project.CommissioningCountry === profile.CountrySpecs[countryIndex].CountryCode ?
    //                     methodology.RFQSchema.properties[v.id].defaultValue : '' :
    //                 (v.type === 'single' && v.optionsLabel && v.optionsLabel === 'LengthOfInterviewOptions') ?
    //                     methodology.RFQSchema.properties[v.id].defaultValue.value :
    //                     (v.type === 'integer' ||
    //                         v.type === 'text' ||
    //                         v.type === 'textarea') ? methodology.RFQSchema.properties[v.id].defaultValue :
    //                         (v.type === 'single') ? (_.isObject(methodology.RFQSchema.properties[v.id].defaultValue)) ?
    //                             methodology.RFQSchema.properties[v.id].defaultValue.value : methodology.RFQSchema.properties[v.id].defaultValue :
    //                             (v.type === 'multi') ? methodology.RFQSchema.properties[v.id].defaultValue : v.value
    //         }
    //     }
    //     v.disabled = CommissioningCountryCheck(v.disabled)

    //     var val1 = { required: { value: v.mandatory }, min: { value: 0, errorMessage: "Value should be greater than 0" } }
    //     var val2 = { required: { value: v.mandatory }, min: { value: 1, errorMessage: 'Your value must be between 1 & 100 ' }, max: { value: 100, errorMessage: 'Your value must be between 1 & 100 ' } }
    //     if (methodology.RFQSchema.properties && methodology.RFQSchema.properties[v.id] && !v.value) {
    //         if (methodology.RFQSchema.properties[v.id].defaultValue) {
    //             v.value = (v.type === 'creatableMulti' && v.optionsLabel && v.optionsLabel === 'Cities') ?
    //                 project.CommissioningCountry === profile.CountrySpecs[countryIndex].CountryCode ?
    //                     methodology.RFQSchema.properties[v.id].defaultValue : '' :
    //                 (v.type === 'single' && v.optionsLabel && v.optionsLabel === 'LengthOfInterviewOptions') ?
    //                     methodology.RFQSchema.properties[v.id].defaultValue.value :
    //                     (v.type === 'integer' ||
    //                         v.type === 'text' ||
    //                         v.type === 'textarea') ? methodology.RFQSchema.properties[v.id].defaultValue :
    //                         (v.type === 'single') ? (_.isObject(methodology.RFQSchema.properties[v.id].defaultValue)) ?
    //                             methodology.RFQSchema.properties[v.id].defaultValue.value : methodology.RFQSchema.properties[v.id].defaultValue :
    //                             (v.type === 'multi') ? methodology.RFQSchema.properties[v.id].defaultValue : v.value
    //         }
    //     }
    //     if (v.type == "integer")
    //         return (
    //             <AvField        //chanege by mridul rishab
    //                 name={v.label}
    //                 value={v.value || ''}
    //                 disabled={(profile.ProfileStatus == 99) || (v.disabled && enable == true) || methodology.NotApplicable || disableFields}
    //                 style={{ width: "100%" }}
    //                 placeholder=""
    //                 type="number"
    //                 min={v.label == "Incidence Rate Main" ? 1 : 0}
    //                 onChange={(e) => InfoChange(indexCO, indexV, e.target.name, parseInt(e.target.value) < 0 ? "1" : e.target.value)}
    //                 required={v.disabled ? false : v.mandatory}
    //                 invalid={v.label == "Incidence Rate Main" ? (parseInt(v.value) > 100) || (parseInt(v.value) < 1) : (parseInt(v.value) < 0)}
    //                 validate={(v.mandatory && !methodology.NotApplicable && !v.disabled && (!_.includes(v.value)
    //                 )) ? v.label == "Incidence Rate Main" ? val2 : val1 : {}}
    //             />
    //         )
    //     else if (v.type == "header")
    //         return (
    //             <h5 className={'header'}>{v.label}</h5>
    //         )
    //     else if (v.type == "text")
    //         return (
    //             <AvField
    //                 name={v.label}
    //                 placeholder=""
    //                 type="text"
    //                 style={{ width: "100%" }}
    //                 disabled={(profile.ProfileStatus == 99) || (v.disabled && enable == true) || methodology.NotApplicable || disableFields}
    //                 onChange={(e) => InfoChange(indexCO, indexV, e.target.name, e.target.value)}
    //                 value={v.value || ''}
    //             />
    //         )
    //     else if (v.type == "textarea") {

    //         return (
    //             <AvField
    //                 name={v.label}
    //                 placeholder=""
    //                 disabled={(profile.ProfileStatus == 99) || (v.disabled && enable == true) || methodology.NotApplicable || disableFields}
    //                 type="textarea"
    //                 style={{ width: "100%" }}
    //                 onChange={(e) => InfoChange(indexCO, indexV, e.target.name, e.target.value)}
    //                 value={v.value || ''}
    //                 validate={(v.mandatory && !methodology.NotApplicable && !v.disabled && (!_.includes(v.value)
    //                 )) ? { required: { value: v.mandatory, errorMessage: 'field is required' } } : {}}
    //             />

    //         )
    //     }
    //     else if (v.type == "single") {
    //         let optionsTemp = v.optionsLabel ? codeLabels[v.optionsLabel] : v.options;
    //         if (optionsTemp?.find(x => (x.Label && x.Label.toLowerCase() == 'other specify') || (x.label && x.label.toLowerCase() == 'other specify')))
    //             return (
    //                 <>
    //                     <CreatableSelect
    //                         name={v.label}
    //                         value={v.value}
    //                         disabled={(profile.ProfileStatus == 99) || (v.disabled && enable == true) || methodology.NotApplicable }
    //                         isDisabled={disableFields}
    //                         onChange={(e) => InfoChange(indexCO, indexV, v.label, e, v.id)}
    //                         className="react-select-container"
    //                         classNamePrefix="react-select"
    //                         styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]
    //                             && errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]["CostingOptions"]
    //                             && errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]["CostingOptions"][profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Label]
    //                             && _.includes(errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]["CostingOptions"][profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Label], v.label, v.value)
    //                             ? errorStyles : ""}
    //                         validate={(v.mandatory && !v.disabled && (!_.includes(v.value)
    //                         )) ? { required: { value: v.mandatory } } : {}}
    //                         // required={v.mandatory}
    //                         options={
    //                             v.optionsLabel ?
    //                                 codeLabels[v.optionsLabel].map(
    //                                     (opt) => {
    //                                         return {
    //                                             value: opt.Code,
    //                                             label: opt.Label,
    //                                         }
    //                                     })
    //                                 :
    //                                 v.options.map((option) => {
    //                                     return {
    //                                         value: option.value,
    //                                         label: option.label,
    //                                     }
    //                                 })

    //                         }
    //                     />

    //                     {!v.disabled && errorFieldsObj && errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]
    //                         && errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]["CostingOptions"]
    //                         && errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]["CostingOptions"][profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Label]
    //                         && _.includes(errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]["CostingOptions"][profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Label], v.label, v.value)
    //                         ? <p className="invalid-feedback d-flex">field is required</p> : null}
    //                     {/* {v.mandatory && !v.value &&<p className="invalid-feedback d-flex">field is required</p>} */}
    //                 </>
    //             )
    //         else
    //             return (
    //                 <AvField
    //                     tag={CustomInput}
    //                     name={v.label}
    //                     value={v.value}
    //                     disabled={(profile.ProfileStatus == 99) || (v.disabled && enable == true) || methodology.NotApplicable || disableFields}
    //                     type="select"
    //                     onChange={(e) => InfoChange(indexCO, indexV, v.label, e.target.value)}
    //                     validate={(v.mandatory && !methodology.NotApplicable && !v.disabled && (!_.includes(v.value)
    //                     )) ? { required: { value: v.mandatory, errorMessage: 'field is required' } } : {}}
    //                 >
    //                     <option value="0"></option>
    //                     {
    //                         v.optionsLabel ?
    //                             codeLabels[v.optionsLabel]?.map(
    //                                 (opt) => (
    //                                     <option key={opt.Code} value={opt.Code}>{opt.Label}</option>
    //                                 ))
    //                             :
    //                             v.options?.map((option) => (
    //                                 <option key={option.value} value={option.value}>{option.label}</option>
    //                             ))
    //                     }
    //                 </AvField>
    //             )
    //     }


    //     else if (v.type == "multi" && v.optionsLabel && v.optionsLabel == 'Cities')
    //         return (
    //             <div style={{ textAlign: 'center' }}>
    //                 {
    //                     codeLabels['CitiesOptions'].filter(x => x.Code == profile.CountrySpecs[countryIndex].CountryCode)[0] ?
    //                         <Select
    //                             name={v.label}
    //                             value={v.value}
    //                             isDisabled={(profile.ProfileStatus == 99) || (v.disabled && enable == true) || methodology.NotApplicable || disableFields}
    //                             onChange={(e) => InfoChange(indexCO, indexV, v.label, e)}
    //                             className="react-select-container"
    //                             classNamePrefix="react-select"
    //                             menuPortalTarget={document.querySelector('card-body')}
    //                             menuPosition={'fixed'}
    //                             options={
    //                                 codeLabels['CitiesOptions'].filter(x => x.Code == profile.CountrySpecs[countryIndex].CountryCode)[0]['Cities'].map(
    //                                     (opt) => {
    //                                         return {
    //                                             value: opt.id,
    //                                             label: opt.Label,
    //                                         }
    //                                     })
    //                             }
    //                             isMulti
    //                         /> : 'No City Found'
    //                 }
    //             </div>
    //         )
    //     else if (v.type == "creatableMulti" && v.optionsLabel && v.optionsLabel == 'Cities')
    //         return (
    //             <>
    //                 {
    //                     codeLabels['CitiesOptions'].filter(x => x.Code == profile.CountrySpecs[countryIndex].CountryCode)[0] ?
    //                         <CreatableSelect
    //                             className="react-select-container"
    //                             classNamePrefix="react-select"
    //                             isMulti
    //                             isDisabled={(profile.ProfileStatus == 99) || (v.disabled && enable == true) || methodology.NotApplicable || disableFields}
    //                             value={v.value}
    //                             onChange={(e) => InfoChange(indexCO, indexV, v.label, e)}
    //                             options={
    //                                 codeLabels['CitiesOptions'].filter(x => x.Code == profile.CountrySpecs[countryIndex].CountryCode)[0]['Cities'].map(
    //                                     (opt) => {
    //                                         return {
    //                                             value: opt.id,
    //                                             label: opt.Label,
    //                                         }
    //                                     })
    //                             }
    //                         /> : 'No City Found'
    //                 }
    //             </>
    //         )
    //     else if (v.type == "multi")
    //         return (
    //             <div>
    //                 <Select
    //                     name={v.label}
    //                     value={v.value}
    //                     isDisabled={(profile.ProfileStatus == 99) || (v.disabled && enable == true) || methodology.NotApplicable || disableFields}
    //                     onChange={(e) => InfoChange(indexCO, indexV, v.label, e)}
    //                     className="react-select-container"
    //                     classNamePrefix="react-select"
    //                     options={
    //                         v.optionsLabel ?
    //                             codeLabels[v.optionsLabel].map(
    //                                 (opt) => {
    //                                     return {
    //                                         value: opt.Code,
    //                                         label: opt.Label,
    //                                     }
    //                                 })
    //                             :
    //                             v.options.map((option) => {
    //                                 return {
    //                                     value: option.value,
    //                                     label: option.label,
    //                                 }
    //                             })

    //                     }
    //                     isMulti
    //                 />
    //             </div>
    //         )

    //     else if (v.type == "check")
    //         return (
    //             <CustomInput
    //                 type="switch"
    //                 name={v.label}
    //                 id={v.label}
    //                 defaultChecked={v.value ? v.value : true}
    //                 className="h5"
    //                 disabled={(profile.ProfileStatus == 99) || methodology.NotApplicable || disableFields}
    //                 onChange={(e) => {

    //                     InfoChange(indexCO, indexV, e.target.name, e.target.checked);
    //                 }}
    //             />
    //         )
    //     else if (v.type == "date")
    //         return (
    //             <FormGroup>
    //                 <AvField
    //                     // disabled={(_.includes(opsResourcesVariables, v.id)
    //                     //     && !userRecord.CanEditOpsResources) || methodology.NotApplicable}
    //                     name={"field_" + indexV}
    //                     placeholder=""
    //                     type="date"
    //                     errorMessage="field is required"
    //                     // validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
    //                     //     && userRecord.CanEditOpsResources))) ? { required: { value: v.mandatory } } : {}}
    //                     onChange={(e) => InfoChange(indexCO, indexV, v.label, e.target.value)}
    //                     value={v.value || ''}
    //                 />
    //             </FormGroup>
    //         )
    // };

    const ReturnControl = (co, indexCO, indexV, variable) => {
        let aa=profile.CountrySpecs[countryIndex].CountryCode
        // if(aa !== project.CommissioningCountry){
        //   if(variable.label === "Commercial Local Support" && !variable.value){
        //      variable.value=fcValue
        //   }
        // }
        let v
        if (variable.label === "Product") {
          v =
          profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO].Variables.filter(
            (x) => x.label == variable.label
          )[0] ||
          profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables.filter(
            (x) => x.label == variable.label
          )[0];
          if (v?.value === undefined && profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length === 1) {
            let prodVar = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables.filter(e => e.label === "Product")
            
            v.value = prodVar[0].options[0].Productname;
          }
        } else {
          v = methodology.CostingOptions.find(x => x.id === co.id).Variables.filter(x => x.label == variable.label)[0] || 
              methodology.CostingOptions[0].Variables.filter(x => x.label == variable.label)[0];
        //  v =
        //   methodology.CostingOptions[indexCO].Variables.filter(
        //     (x) => x.label == variable.label
        //   )[0] ||
        //   methodology.CostingOptions[0].Variables.filter(
        //     (x) => x.label == variable.label
        //   )[0];
        }
        return (
          <ReturnControls
          v= {v}
          methodology ={methodology}
          profile = {profile}
          project = {project}
          indexV ={indexV}
          indexCO = {indexCO}
          CommissioningCountryCheck  ={CommissioningCountryCheck}
          indxFC = {countryIndex}
          indxSM = {methodologyIndex}
          errorFieldsObj ={errorFieldsObj}
          component = "CO"
          country= {aa}
          InfoChange = {InfoChange}
          errorStyles = {errorStyles}
          disableFields = {disableFields}
          >
          </ReturnControls>)
      }
      
    const RenderVariables = (variables) => {
        let tempTitle = "";
        let tempIndex = null;
        variables.filter(x => x.mandatory || x.label == "Boosters required").forEach((x, i) => {
            if (x.label == "Boosters required") {
                x.firstField = true;
                x.sectionTitle = "Sample Specification";
            }
            else if (x.label == "Age") {
                x.firstField = true;
                x.sectionTitle = "Quota Specification";
            }

        });
        return (
            variables.map((variable, indexV) => {
                if (variable.mandatory || variable.label == "Boosters required") {
                    if (!variable.Default && variable.firstField)
                        return <>
                            <Col md={'12'}><h5 id={"header_" + indexV} className={'header'}> {variable.sectionTitle}</h5></Col>
                            <Col key={"col_" + indexV} style={{ paddingLeft: "3rem", paddingTop: "0.25rem" }} md={4}>
                                <Input type="checkbox" defaultChecked={variable.selected ? true : false}
                                    onChange={(e) => { if (!methodology.NotApplicable) checkChangeVariable(indexV, e.target.checked) }} />
                                {variable.label}
                            </Col>
                        </>
                    else if (!variable.Default)
                        return <Col key={"col_" + indexV} style={{ paddingLeft: "3rem", paddingTop: "0.25rem" }} md={4}>
                            <Input type="checkbox" defaultChecked={variable.selected ? true : false}
                                onChange={(e) => { if (!methodology.NotApplicable) checkChangeVariable(indexV, e.target.checked) }} />
                            {variable.label}
                        </Col>
                }
            }));
    };

    const RenderVariablesNew = () => {

        return (
            addVariables.map((variable, indexV) => {
                if (!variable.Default && variable.show)
                    return <Col key={"col_" + indexV} style={{ paddingLeft: "2.2rem", paddingTop: "1.8rem" }} md={4}>
                        <Input type="checkbox" defaultChecked={variable.selected ? true : false} disabled={(profile.ProfileStatus == 99) || methodology.NotApplicable || disableFields}
                            onChange={(e) => { if (!methodology.NotApplicable) checkChangeVariable(indexV, e.target.checked) }} />
                        {variable.label}
                    </Col>
                else
                    return null;

            }));
    }

    function removeItem(indexCO) {
        let deletableCostingOption = profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[indexCO]
        if (deletableCostingOption)
            deletableCostingOption.toRemove = true
        // let profileTemp = update(profile, {
        //     CountrySpecs: {
        //         [countryIndex]: {
        //             MethodologySpecs:
        //             {
        //                 [methodologyIndex]:
        //                 {
        //                     CostingOptions:
        //                     {
        //                         $splice: [[indexCO, 1]]
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // });

        let profileTemp = _.cloneDeep(profile)
        let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
        costingOptionsClone.splice(indexCO, 1)
        if (!profileTemp.deletedCostingOptions)
            profileTemp.deletedCostingOptions = []

        if (deletableCostingOption && deletableCostingOption.id)
            profileTemp.deletedCostingOptions.push(deletableCostingOption)

        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
        updateProjectAndProfileWRTCostingOption(profileTemp)
    }

    function InfoChange(indexCO, indexV, name, value, id = null) {

        const variablesClone = _.cloneDeep(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO].Variables);

        const currentVariable = variablesClone[indexV];
        currentVariable.value = value;
        if (name == "Sample size per scenario per option")
            variablesClone.filter(hidVar => hidVar.id == "totalSamplePerCountryPerOPtion")[0].value =
                (parseInt(variablesClone.filter(hidVar => hidVar.id == "numberofTestScenario")[0].value) + parseInt(variablesClone.filter(hidVar => hidVar.id == "numberofControlScenario")[0].value)) * value;
        if (name == "Type Of Venue") {//make it to id, not sure why id isn't working
            let typeOfChannel = variablesClone.find(vc => vc.id == "typeOfChannel")
            typeOfChannel.options = value == "Store" ? [
                { label: "Hypermarket", value: "Hypermarket" },
                { label: "Supermarket", value: "Supermarket" },
                { label: "Convenience", value: "Convenience" },
                { label: "Discounter", value: "Discounter" },
                { label: "Traditional Trade", value: "Traditional Trade" },
                { label: "On-trade", value: "On-trade" },
                { label: "Gas/Petrol Station", value: "Gas/Petrol Station" },
                { label: "Other", value: "Other" }
            ] : value == "Restaurants/pubs" ? [
                { label: "Restaurant", value: "Restaurant" },
                { label: "Pub", value: "Pub" }
            ] : value == "Leaflets/App/Web" ? [
                { label: "Leaflet", value: "Leaflet" }
            ] : []
        }
        variablesClone.forEach((v, indexV) => {
            if (v.label != currentVariable.label && v.dependentOn && v.dependentOn.fields.find(f => f.fieldName == currentVariable.label)) {

                let op = v.dependentOn.andor == "OR" ? "||" : "&&";
                let expression = "";
                v.dependentOn.fields.forEach((f, indexF) => {

                    let fieldVal = f.fieldName != currentVariable.label ? _.head(variablesClone.filter(v => v.label == f.fieldName))?.value : currentVariable["value"];
                    if (Array.isArray(fieldVal)) {
                        let tempBool = variablesClone.filter(v => v.label == f.fieldName)[0]["value"].filter(x => x.label == f.fieldValue).length > 0;
                        expression += tempBool + f.operator + "true" + (indexF != v.dependentOn.fields.length - 1 ? op : '');
                    }
                    else {
                        expression += getChangedValue(fieldVal) + f.operator + getChangedValue(f.fieldValue) + (indexF != v.dependentOn.fields.length - 1 ? op : '');
                    }
                });

                let enabled = eval(expression);
                if (enabled)
                    v.disabled = false;
                else {
                    v.disabled = true;
                    v.value = null;
                    variablesClone.forEach((v2, indexV2) => {
                        if (v2.dependentOn && v2.dependentOn.fields.find(f => f.fieldName == v.label)) {
                            v2.disabled = true;
                            v2.value = null;
                        }
                    });
                }
            }
        });


        let arr = [];
        let rfqVariableClone = _.cloneDeep(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Variables);
        rfqVariableClone.forEach((v, indexV) => {
            if (v.label != currentVariable.label && v.dependentOn && v.dependentOn.fields.find(f => f.fieldName == currentVariable.label)) {
                arr.push({ label: v.label });
            }
        });
        rfqVariableClone = OtherVariablesShowHide(indexCO, currentVariable, rfqVariableClone, arr);

        arr = [];
        let opResourcesVariablesClone = _.cloneDeep(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].OpResourcesVariables);
        opResourcesVariablesClone.forEach((v, indexV) => {
            if (v.label != currentVariable.label && v.dependentOn && v.dependentOn.fields.find(f => f.fieldName == currentVariable.label)) {
                arr.push({ label: v.label });
            }
        });
        opResourcesVariablesClone = OtherVariablesShowHide(indexCO, currentVariable, opResourcesVariablesClone, arr);
        isEditable(true);
        // let profileTemp = update(profile, {
        //     CountrySpecs: {
        //         [countryIndex]: {
        //             MethodologySpecs:
        //             {
        //                 [methodologyIndex]:
        //                 {
        //                     CostingOptions:
        //                     {
        //                         [indexCO]:
        //                         {
        //                             Variables: { $set: variablesClone }
        //                         }
        //                     },
        //                     Variables: { $set: rfqVariableClone },
        //                     OpResourcesVariables: { $set: opResourcesVariablesClone }
        //                 }
        //             }
        //         }
        //     }
        // });

        let profileTemp = _.cloneDeep(profile)
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex] = {
            ...profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
            , Variables: rfqVariableClone
            , OpResourcesVariables: opResourcesVariablesClone
        }

        if (profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO])
            profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO].Variables = variablesClone

        let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
        //not required, just for cloning, felt cute, will delete later
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
        updateProjectAndProfileWRTCostingOption(profileTemp)
    }

    function OtherVariablesShowHide(indexCO, currentVar, variablesClone, arr) {

        const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
        costingOptionsClone.forEach((co, index_co) => {
            let currentVariable = index_co == indexCO ? currentVar : co.Variables.filter(x => x.label == currentVar.label)[0];
            if (arr.filter(x => !x.done).length == 0)
                return;
            variablesClone.forEach((v, indexV) => {
                if (v.label != currentVariable.label && v.dependentOn && v.dependentOn.fields.find(f => f.fieldName == currentVariable.label)) {
                    if (arr.filter(x => x.label == v.label)[0].done)
                        return;
                    let op = v.dependentOn.andor == "OR" ? "||" : "&&";
                    let expression = "";
                    v.dependentOn.fields.forEach((f, indexF) => {
                        let fieldVal = f.fieldName != currentVariable.label ? variablesClone.filter(v => v.label == f.fieldName)[0]?.value : currentVariable["value"];
                        if (Array.isArray(fieldVal)) {
                            let tempBool = false
                            if (variablesClone.filter(v => v.label == f.fieldName)[0]["value"]) {
                                tempBool = variablesClone.filter(v => v.label == f.fieldName)[0]["value"].filter(x => x.label == f.fieldValue).length > 0;
                            }
                            else if (currentVariable["value"]) {
                                tempBool = currentVariable["value"].filter(x => x.label == f.fieldValue).length > 0;
                            }
                            expression += tempBool + f.operator + "true" + (indexF != v.dependentOn.fields.length - 1 ? op : '');
                        }
                        else {
                            expression += getChangedValue(fieldVal) + f.operator + getChangedValue(f.fieldValue) + (indexF != v.dependentOn.fields.length - 1 ? op : '');
                        }
                    });
                    let enabled = eval(expression);
                    if (enabled) {
                        //custom code starts
                        if (v.label == 'Approx Percentage Change - Each Wave' && currentVariable.label == 'Tracking Project') {

                            if (currentVariable["value"] == 'Yes') {
                                v.enabled = true;
                                v.disabled = false;
                                arr.filter(x => x.label == v.label)[0].done = true;
                            }
                            else {
                                v.enabled = false;
                                v.disabled = true;
                                v.value = null;
                            }
                        }
                        //custom code starts
                        else {
                            v.disabled = false;
                            arr.filter(x => x.label == v.label)[0].done = true;
                        }
                    }
                    else {
                        v.disabled = true;
                        v.value = null;
                        variablesClone.forEach((v2, indexV2) => {
                            if (v2.dependentOn && v2.dependentOn.fields.find(f => f.fieldName == v.label)) {
                                v2.disabled = true;
                                v2.value = null;
                            }
                        });
                    }
                }
            });
        });
        return variablesClone;
    }

    function FoundDependent(currentVariable, variablesClone) {
        let found = false;
        variablesClone.forEach((v, indexV) => {
            if (v.label != currentVariable.label && v.dependentOn && v.dependentOn.fields.find(f => f.fieldName == currentVariable.label)) {
                found = true;
            }
        });
        return found;
    }

    function getChangedValue(value) {
        let rValue = null;
        if (isNaN(value))
            rValue = "'" + value + "'";
        else
            rValue = value;
        return rValue;
    }

    const checkChangeVariable = (indexV, value) => {

        const addVariablesClone = _.cloneDeep(addVariables);

        addVariablesClone.forEach((x, index) => {
            if (index == indexV) {
                x["selected"] = value;
                x["value"] = null;
            }
            else if (x.dependentOn && x.dependentOn.fields.find(f => f.fieldName == addVariablesClone[indexV].label) && (x.AddToCostingOption || x.sectionTitle == addVariablesClone[indexV].sectionTitle)) {

                x["selected"] = value;
                if (value == true)
                    x.disabled = true;
            }
        });
        setAddVariables(addVariablesClone);
    }

    function SetAdditionalVariables() {
        let RFQVariables = _.cloneDeep(methodology.Variables);
        RFQVariables.forEach(v => {
            v.RFQ = true;
            v.show = false;
        });
        let OPResourceVariables = _.cloneDeep(methodology.OpResourcesVariables);
        OPResourceVariables.forEach(v => {
            v.OPR = true;
            v.show = false;
        });
        let tempVariables = [...RFQVariables, ...OPResourceVariables];
        setAddVariables(tempVariables);
    }

    function SearchValueChanged(searchValue) {
        let tempAddVariables = _.cloneDeep(addVariables);
        if (searchValue && searchValue.length >= 3) {
            tempAddVariables.forEach(av => {
                const co = _.cloneDeep(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0]);
                const parentFound = av.dependentOn && av.dependentOn.fields.some(f => co.Variables.find(v => v.label == f.fieldName));
                const matchFound = av.label.replace(/\s+/g, '').toLowerCase().includes(searchValue.replace(/\s+/g, '').toLowerCase());
                if (av.selected)
                    av.show = true;
                else if ((matchFound && !av.disabled) || (matchFound && parentFound))
                    av.show = true;
                else
                    av.show = false;
            });
        }
        else {
            tempAddVariables.forEach(av => {
                if (av.selected && !av.dependentOn)
                    av.show = true;
                else
                    av.show = false;
            });
        }
        setAddVariables(tempAddVariables);
    }

    function AddRemoveOptionalVariables() {
        const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
        const addVariablesClone = _.cloneDeep(addVariables);

        costingOptionsClone.forEach((co, indexCO) => {
            const variablesClone = _.cloneDeep(co.Variables);
            let tempVariables = [];
            tempVariables = variablesClone.filter((vc, indexVC) => vc.Default);
            addVariablesClone.filter(mv => mv.selected).forEach((mv, indexMV) => {
                const indexVar = variablesClone.findIndex(vc => vc.label == mv.label);
                if (indexVar > -1)
                    tempVariables.push(_.cloneDeep(variablesClone[indexVar]));
                else {
                    mv.value = null;
                    tempVariables.push(_.cloneDeep(mv));
                }
            });

            co.Variables = tempVariables;
        });

        // let profileTemp = update(profile, {
        //     CountrySpecs: {
        //         [countryIndex]: {
        //             MethodologySpecs:
        //             {
        //                 [methodologyIndex]:
        //                 {
        //                     CostingOptions: { $set: costingOptionsClone },
        //                     Variables: { $set: addVariables }
        //                 }
        //             }
        //         }
        //     }
        // });
        let profileTemp = _.cloneDeep(profile)
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex] = {
            ...profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex],
            CostingOptions: costingOptionsClone,
            Variables: addVariables
        }
        // let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
        // profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
        updateProjectAndProfileWRTCostingOption(profileTemp)

    }

    function AddRemoveOptionalVariablesNew() {
        const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
        const addVariablesClone = _.cloneDeep(addVariables);
        const RFQVariables = _.cloneDeep(addVariables.filter(v => v.RFQ));
        const OPResourceVariables = _.cloneDeep(addVariables.filter(v => v.OPR));
        setModelStateVar(!modelStateVar);
        const titleAddedVariables = [{ label: "RFQ Form", flag: false, costingheader: true }, { label: "Setup & Delivery", flag: false, costingheader: true }];
        costingOptionsClone.forEach((co, indexCO) => {
            const coVariablesClone = _.cloneDeep(co.Variables);
            let tempVariables = [];
            tempVariables = coVariablesClone.filter((vc, indexVC) => vc.Default);
            addVariablesClone.filter(av => av.selected).forEach((av, indexAV) => {
                const indexVar = coVariablesClone.findIndex(vc => vc.label == av.label);
                if (indexVar > -1) {
                    let temp = _.cloneDeep(coVariablesClone[indexVar]);
                    tempVariables.push(temp);
                }
                else {

                    av.parent = av.label == 'Data Processing Required' ? "Data Processing" : av.sectionTitle;
                    av.value = null;
                    if (RFQVariables.findIndex(x => x.id == av.id && x.label == av.label) > -1)
                        av.form = "RFQ Form";
                    if (OPResourceVariables.findIndex(x => x.id == av.id && x.label == av.label) > -1)
                        av.form = "Setup & Delivery";

                    tempVariables.push(_.cloneDeep(av));


                }
            });
            co.Variables = tempVariables;
        });

        let distinctParents = costingOptionsClone[0].Variables.filter(v => !v.Default).map(v => v.parent).filter((item, i, ar) => ar.indexOf(item) === i);

        costingOptionsClone.forEach((co, indexCO) => {
            const coVariablesClone = _.cloneDeep(co.Variables);
            let tempVariables = [];
            tempVariables = coVariablesClone.filter((vc, indexVC) => vc.Default);
            distinctParents.forEach(p => {
                coVariablesClone.filter(v => !v.Default && v.parent == p).forEach(v => {
                    if (v.form == "RFQ Form" && !titleAddedVariables[0].flag) {
                        titleAddedVariables[0].flag = true;
                        tempVariables.push(titleAddedVariables[0]);
                    }
                    if (v.form == "Setup & Delivery" && !titleAddedVariables[1].flag) {
                        titleAddedVariables[1].flag = true;
                        tempVariables.push(titleAddedVariables[1]);
                    }

                    tempVariables.push(_.cloneDeep(v));
                });
            });
            titleAddedVariables[0].flag = false;
            titleAddedVariables[1].flag = false;
            co.Variables = tempVariables;
        });

        // let profileTemp = update(profile, {
        //     CountrySpecs: {
        //         [countryIndex]: {
        //             MethodologySpecs:
        //             {
        //                 [methodologyIndex]:
        //                 {
        //                     CostingOptions: { $set: costingOptionsClone },
        //                     Variables: { $set: RFQVariables },
        //                     OpResourcesVariables: { $set: OPResourceVariables }
        //                 }
        //             }
        //         }
        //     }
        // });
        let profileTemp = _.cloneDeep(profile)
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex] = {
            ...profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
            , CostingOptions: costingOptionsClone
            , Variables: RFQVariables
            , OpResourcesVariables: OPResourceVariables
        }
        // let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
        // profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
        updateProjectAndProfileWRTCostingOption(profileTemp)

        ClearSearch();
    }

    function RemoveAdditionalVariable(v, indexV) {
        const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
        const addVariablesClone = _.cloneDeep(methodology.Variables);
        const OpResourcesVariables = _.cloneDeep(methodology.OpResourcesVariables);

        addVariablesClone.forEach((x, index) => {
            if (x.label == v.label) {
                x.selected = false;
                x.value = null;
            }
            else if (x.dependentOn && x.dependentOn.fields.find(f => f.fieldName == v.label)) {
                x.selected = false;
                x.disabled = true;
                x.value = null;
            }
        });

        OpResourcesVariables.forEach((x, index) => {
            if (x.label == v.label) {
                x.selected = false;
                x.value = null;
            }
            else if (x.dependentOn && x.dependentOn.fields.find(f => f.fieldName == v.label)) {
                x.selected = false;
                x.disabled = true;
                x.value = null;
            }
        });

        costingOptionsClone.forEach((co, indexCO) => {
            let variablesClone = _.cloneDeep(co.Variables);
            variablesClone = variablesClone.filter((x, index) => index != indexV && (!x.dependentOn || !x.dependentOn.fields.find(f => f.fieldName == v.label)));
            const rfqvariables = variablesClone.filter(x => x.form == "RFQ Form").length;
            variablesClone = variablesClone.filter(x => rfqvariables > 0 ? x == x : x.label != "RFQ Form");
            const stpvariables = variablesClone.filter(x => x.form == "Setup & Delivery").length;
            variablesClone = variablesClone.filter(x => stpvariables > 0 ? x == x : x.label != "Setup & Delivery");
            co.Variables = variablesClone;
        });

        // let profileTemp = update(profile, {
        //     CountrySpecs: {
        //         [countryIndex]: {
        //             MethodologySpecs:
        //             {
        //                 [methodologyIndex]:
        //                 {
        //                     CostingOptions: { $set: costingOptionsClone },
        //                     Variables: { $set: addVariablesClone },
        //                     OpResourcesVariables: { $set: OpResourcesVariables }
        //                 }
        //             }
        //         }
        //     }
        // });
        let profileTemp = _.cloneDeep(profile)
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex] = {
            ...profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
            , CostingOptions: costingOptionsClone
            , Variables: addVariablesClone
            , OpResourcesVariables: OpResourcesVariables
        }
        updateProjectAndProfileWRTCostingOption(profileTemp)
    }

    function CopyCostingOption(indexCO) {
        let costingOption = _.cloneDeep(methodology.CostingOptions[indexCO]);
        delete costingOption["id"];
        const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
        costingOptionsClone.splice(indexCO + 1, 0, costingOption);
        if (costingOption.label.includes("copy")) {
            var regex = /[\d|,|.|e|E|\+]+/g;
            const nameCostingOption = costingOption.label.split("-")[1];
            const copyPart = costingOption.value.split("-")[0];
            var copyNumber = copyPart.match(regex);
            costingOption.value = "copy" + (copyNumber + 1) + "_" + costingOption.label;
        }
        else {
            costingOption.label = "copy_" + costingOption.label;
            costingOption.value = "copy1_" + costingOption.label;
        }
        // let profileTemp = update(profile, {
        //     CountrySpecs: {
        //         [countryIndex]: {
        //             MethodologySpecs:
        //             {
        //                 [methodologyIndex]:
        //                 {
        //                     CostingOptions: { $set: costingOptionsClone }
        //                 }
        //             }
        //         }
        //     }
        // });
        let profileTemp = _.cloneDeep(profile)
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
        updateProjectAndProfileWRTCostingOption(profileTemp)
    }

    function RefreshCostingOption(indexCO) {

        const costingOption = _.cloneDeep(methodology.CostingOptions[indexCO]);
        costingOption.Variables.forEach(v => {
            if (v.type == 'single')
                v.value = '';
            else
                v.value = null;
        });
        // let profileTemp = update(profile, {
        //     CountrySpecs: {
        //         [countryIndex]: {
        //             MethodologySpecs:
        //             {
        //                 [methodologyIndex]:
        //                 {
        //                     CostingOptions:
        //                     {
        //                         [indexCO]: { $set: costingOption }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // });
        let profileTemp = _.cloneDeep(profile)
        let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
        costingOptionsClone[indexCO] = costingOption
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
        updateProjectAndProfileWRTCostingOption(profileTemp)

    }

    function RenameClicked(indexCO) {
        // let profileTemp = update(profile, {
        //     CountrySpecs: {
        //         [countryIndex]: {
        //             MethodologySpecs:
        //             {
        //                 [methodologyIndex]:
        //                 {
        //                     CostingOptions:
        //                     {
        //                         [indexCO]: {
        //                             $merge: { "rename": true }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // });
        let profileTemp = _.cloneDeep(profile)
        let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
        costingOptionsClone[indexCO] = { ...costingOptionsClone[indexCO], "rename": true }
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
        updateProjectAndProfileWRTCostingOption(profileTemp)
    }


    function RenameCostingProfile(indexCO, value) {
        // let profileTemp = update(profile, {
        //     CountrySpecs: {
        //         [countryIndex]: {
        //             MethodologySpecs:
        //             {
        //                 [methodologyIndex]:
        //                 {
        //                     CostingOptions:
        //                     {
        //                         [indexCO]: {
        //                             $merge: { "label": value }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // });
        let profileTemp = _.cloneDeep(profile)
        let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
        costingOptionsClone[indexCO] = { ...costingOptionsClone[indexCO], "label": value }
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
        updateProjectAndProfileWRTCostingOption(profileTemp)
    }
    function selectCostingOption(indexCO, e) {
        // let profileTemp = update(profile, {
        //     CountrySpecs: {
        //         [countryIndex]: {
        //             MethodologySpecs:
        //             {
        //                 [methodologyIndex]:
        //                 {
        //                     CostingOptions:
        //                     {
        //                         [indexCO]: {
        //                             $merge: { "isSelected": e.target.checked ? true : false }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // });
        let profileTemp = _.cloneDeep(profile)
        let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
        costingOptionsClone[indexCO] = { ...costingOptionsClone[indexCO], "isSelected": e.target.checked ? true : false }
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
        updateProjectAndProfileWRTCostingOption(profileTemp)
    }
    function RenameDone(indexCO) {
        // let profileTemp = update(profile, {
        //     CountrySpecs: {
        //         [countryIndex]: {
        //             MethodologySpecs:
        //             {
        //                 [methodologyIndex]:
        //                 {
        //                     CostingOptions:
        //                     {
        //                         [indexCO]: {
        //                             $merge: { "rename": false }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // });
        let profileTemp = _.cloneDeep(profile)
        let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
        costingOptionsClone[indexCO] = { ...costingOptionsClone[indexCO], "rename": false }
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
        updateProjectAndProfileWRTCostingOption(profileTemp)
    }

    return (
        <React.Fragment>

            <Card className="ml-2 mr-2">
                <CardHeader>
                    <Row>
                        <Col>
                            <CardTitle className="mb-0">
                                <span id={"costing_options_card_" + countryIndex + "_" + methodologyIndex}>Costing Options</span>
                            </CardTitle>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={12} className={"tableCopyProject"}>
                            <AvForm encType="multipart/form-data" id="CostiongOptionsForm">
                                <Table size="sm" bordered style={{ backgroundColor: "white", maxWidth: "100%", width: "auto" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ display: "flex", justifyContent: "space-between", alignItems: "center", minWidth: "250px", maxWidth: "250px" }}>
                                                <span>Costing Parameters</span>
                                            </th>
                                            {methodology.CostingOptions && methodology.CostingOptions?.filter(co => ((project.CostingTemplateVersion == "v2.0"  && ( co.CoSelectedForSummary==true ||( co.CoSelectedForSummary==null && co.Selected)))
                                                || (project.CostingTemplateVersion != "v2.0" && co.Selected)))?.map((co, indexCO) =>
                                                    <th style={{ minWidth: "250px", maxWidth: "250px", backgroundColor: co.isSelected ? '#2D6DF6' : '', color: co.isSelected ? 'white' : '' }} key={'c_' + countryIndex + '_m_' + methodologyIndex + '_co_' + indexCO}>
                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", minWidth: "250px", maxWidth: "250px", paddingRight: ".3rem" }}>
                                                            {!co.rename &&
                                                                <>

                                                                    <div style={{ flexDirection: 'column' }}>
                                                                        {<p title={co.label} style={{
                                                                            whiteSpace: 'nowrap',
                                                                            overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '220px',
                                                                            color: '#fff !important'
                                                                        }}>{co.label}</p>}
                                                                        {!disableFields ? <div style={{
                                                                            justifyContent: "space-around",
                                                                            flexDirection: 'row', maxWidth: '220px', display: "flex"
                                                                        }}>

                                                                            {vendor == true &&
                                                                                <FontAwesomeIcon
                                                                                    title="Rename Costing Option"
                                                                                    className="align-middle mr-2 addButton"
                                                                                    style={{ cursor: (methodology.NotApplicable || CommissioningCountryCheck(false)) ? "no-drop" : "pointer" }}
                                                                                    icon={faPencilAlt}
                                                                                    fixedWidth
                                                                                    onClick={(e) => {
                                                                                        if (!methodology.NotApplicable && !CommissioningCountryCheck(false))
                                                                                            RenameClicked(indexCO);
                                                                                        //setModelState(true);
                                                                                        //setNewCostingOption(co.label);
                                                                                        //setCostingOptionIndex(indexCO);
                                                                                        //setModelMode("rename");
                                                                                    }}
                                                                                />}

                                                                            <div>
                                                                                <input type="checkbox" checked={co.isSelected} title="Select Option" onChange={(e) => selectCostingOption(indexCO, e)} />
                                                                            </div>
                                                                            <div>
                                                                                {methodology.CostingOptions?.length > 1 && vendor == true &&
                                                                                    <FontAwesomeIcon
                                                                                        title="Delete Costing Option"
                                                                                        className="align-middle mr-2 deleteButton"
                                                                                        icon={faTimesCircle}
                                                                                        fixedWidth
                                                                                        style={{ cursor: (methodology.NotApplicable || CommissioningCountryCheck(false)) ? "no-drop" : "pointer" }}
                                                                                        onClick={() => {
                                                                                            if (!methodology.NotApplicable)
                                                                                                if (window.confirm('Are you sure to delete the costing option?'))
                                                                                                    removeItem(indexCO)
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                {vendor == true &&
                                                                                    <FontAwesomeIcon
                                                                                        title="Copy costing options"
                                                                                        className="align-middle mr-2 addButton"
                                                                                        icon={faCopy}
                                                                                        fixedWidth
                                                                                        style={{ cursor: (methodology.NotApplicable || CommissioningCountryCheck(false)) ? "no-drop" : "pointer" }}
                                                                                        onClick={() => {
                                                                                            if (!methodology.NotApplicable)
                                                                                                CopyCostingOption(indexCO);
                                                                                            //setModelState(true);
                                                                                            //setModelMode("copy");
                                                                                            //setCostingOptionIndex(indexCO);
                                                                                            //setNewCostingOption(null);
                                                                                        }}
                                                                                    />}
                                                                                {vendor == true &&
                                                                                    <FontAwesomeIcon
                                                                                        title="Refresh Data"
                                                                                        className="align-middle mr-2 addButton"
                                                                                        icon={faUndo}
                                                                                        fixedWidth
                                                                                        style={{ cursor: (methodology.NotApplicable || CommissioningCountryCheck(false)) ? "no-drop" : "pointer" }}
                                                                                        onClick={() => {
                                                                                            if (!methodology.NotApplicable)
                                                                                                RefreshCostingOption(indexCO);
                                                                                        }}
                                                                                    />}
                                                                            </div>

                                                                        </div> : null}
                                                                    </div>
                                                                </>
                                                            }
                                                            {co.rename &&
                                                                <input type="text" value={co.label || ''} style={{ minWidth: "95%", maxWidth: "95%" }} onChange={(e) => RenameCostingProfile(indexCO, e.target.value)} onBlur={() => { RenameDone(indexCO) }} />
                                                            }
                                                        </div>
                                                    </th>
                                                )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {_.head(methodology.CostingOptions) && _.head(methodology.CostingOptions).Variables?.filter(hidVar => !hidVar.ishidden).map((v, indexV) =>
                                            <tr key={'c_' + countryIndex + '_m_' + methodologyIndex + "_v_" + indexV}>
                                                <td style={{ fontWeight: v.costingheader ? "bold" : "" }} colSpan={v.costingheader ? methodology.CostingOptions.length + 1 : 1}>
                                                    {!v.Default && !v.costingheader && vendor == true && !disableFields &&
                                                        <FontAwesomeIcon
                                                            title="Delete Variable"
                                                            className="align-middle mr-2 deleteButton"
                                                            icon={faTimesCircle}
                                                            fixedWidth
                                                            style={{ cursor: (methodology.NotApplicable) ? "no-drop" : "pointer" }}
                                                            onClick={() => {
                                                                if (!methodology.NotApplicable)
                                                                    if (window.confirm('Are you sure to delete the variable?'))
                                                                        RemoveAdditionalVariable(v, indexV);
                                                            }}
                                                        />
                                                    }
                                                    {v.label + (v.mandatory ? '(*)' : '')}
                                                </td>
                                                {!v.costingheader && methodology.CostingOptions?.filter(co => ((project.CostingTemplateVersion == "v2.0"  && ( co.CoSelectedForSummary==true ||( co.CoSelectedForSummary==null && co.Selected)))
                                                    || (project.CostingTemplateVersion != "v2.0" && co.Selected))).map((co, indexCO) =>
                                                        <td key={'c_' + countryIndex + '_m_' + methodologyIndex + '_co_' + indexCO} style={{ maxWidth: "300px" }}
                                                            className="costingOptions">
                                                            {ReturnControl(co,indexCO, indexV, v)}
                                                        </td>
                                                    )}
                                            </tr>
                                        )}

                                    </tbody>
                                </Table>
                            </AvForm>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Modal isOpen={modelState} toggle={() => setModelState(!modelState)} centered size="md">
                <ModalHeader toggle={() => setModelState(!modelState)}>
                    {modelMode == 'new' ? 'New Costing Option' : (modelMode == 'copy' ? 'Copy Costing Option' : 'Rename Costing Option')}
                </ModalHeader>
                <ModalBody className="m-3" style={styleModelBody}>
                    <Row>
                        <Col md={modelMode == 'copy' && methodology.CostingOptions?.length > 1 ? 6 : 12}>
                            <FormGroup>
                                <Label>Costing Option Name </Label>
                                <input type="text" style={{ width: "100%" }} value={newCostingOption} onChange={(e) => {
                                    setNewCostingOption(e.target.value);
                                }} />
                            </FormGroup>
                        </Col>
                        {modelMode == 'copy' && methodology.CostingOptions?.length > 1 && !newCostingOption &&
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Copy Data To</Label>
                                    <Select
                                        name={"copyFrom"}
                                        value={copyToCostingOption}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={methodology.CostingOptions.filter((x, i) => i != costingOptionIndex)}
                                        onChange={(val) => setCopyTo(val)}
                                    />
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                </ModalBody>
                <ModalFooter>

                    <Button color="success" onClick={() => {
                        setModelState(!modelState);
                        saveCostingOption(modelMode);
                        setCostingOptionIndex(null);
                        setNewCostingOption(null);

                    }}>


                        Save
                    </Button>
                    <Button color="danger" onClick={() => setModelState(false)}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
            {addVariables && addVariables.length > 0 &&
                <Modal isOpen={modelStateVar} toggle={() => setModelStateVar(!modelStateVar)} centered size="lg">
                    <ModalHeader toggle={() => {
                        setModelStateVar(!modelStateVar);
                        ClearSearch();
                    }}>
                        {'Adding Additional Costing Parameters'}
                    </ModalHeader>
                    <ModalBody className="m-3" style={styleModelBody}>
                        <Row>
                            <Col md={'2'}><b>Type To Search</b></Col>
                            <Col md={'8'}>
                                <input type="text" style={{ width: "100%" }} value={searchValue || ''} placeholder='Please type the field name that you want to add as "Costing Parameters"' onChange={(e) => {
                                    setSearchValue(e.target.value);
                                    SearchValueChanged(e.target.value);
                                }} />
                            </Col>
                        </Row>
                        <Row>
                            {RenderVariablesNew()}
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {/* <h4>Please type the field name that you want to add as "Costing Parameters"</h4> */}
                        <Button color="success" onClick={() => {
                            AddRemoveOptionalVariablesNew();
                        }}>
                            Save
                        </Button>
                        <Button color="danger" onClick={() => {
                            setModelStateVar(false);
                            ClearSearch();
                        }}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            }
        </React.Fragment>
    );
};
export default Start;
