import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react'
import { toastr } from 'react-redux-toastr';
import { Button, Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';

function AdditionalCostPopUp({internalAdditionalCost,project,setInternalCost,InternalCost,activeTabFC,activeTabSM,
    SetinternalCostValue,CopyInternalCost,saveInternalCost,staticModelData,updateName,internalOtherSpecify,activeInternalDropCurrency,
    activeCoId

}) {
  return (
    <Modal
          isOpen={InternalCost}
          toggle={() => setInternalCost(!InternalCost)}
          centered
          size="lg"
          className={"modelSelectVendors"}
        >
          <ModalHeader
            toggle={() => {
              setInternalCost(!InternalCost);
            }}
          >
            <span>Select Internal Cost</span>
          </ModalHeader>
          <ModalBody className="m-3"  >
            {/* {JSON.stringify(internalAdditionalCost)} */}
            <div style={{ maxHeight: "380px", overflow: "auto" }}>
              <Table className="table" style={{ position: "relative" }}>
                <tr className="table bg-primary text-white">
                  <th>Costing Type
                    {/* {project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[
                      activeTabFC
                    ].MethodologySpecs[activeTabSM].CostingOptions.length > 1 &&
                      <FontAwesomeIcon
                        title="Copy data"
                        className="align-middle"
                        icon={faCopy}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => { saveInternalCost(); CopyInternalCost() }}
                      />} */}
                  </th>
                  <th>Internal Cost</th>
                </tr>{
                  (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Label 
                    == "Online Self Completion Survey" ? staticModelData.filter(data => data.OnlineMethodology == true)
                    : staticModelData)
                    .map((d) => {
                      return (
                        <tr>
                          <td >
                            {d.Costingtype.includes("Other Costs - PLEASE SPECIFY") ?
                              <>
                                <label>Other Cost </label>
                                <input type="text" name={d.key} placeholder="Please Specify..."
                                  value={
                                    internalOtherSpecify && internalOtherSpecify.hasOwnProperty(d.key) ?
                                      internalOtherSpecify[d.key] :
                                      (
                                        project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor
                                          &&
                                          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.length > 0
                                          &&
                                          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId).length > 0
                                          &&
                                          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected &&
                                          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected.length > 0 &&
                                          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(v => v.key == d.key).length > 0
                                          ?
                                          project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor.filter(co => co.id == activeCoId)[0].selected.filter(v => v.key == d.key)[0].pleaseSpecify : "")
                                  }
                                  onBlur={(e) => {
                                    updateName(e.target.value, d)}}
                                />
                              </> :
                              d.Costingtype
                            }  </td>
                          <td >
                            <InputGroup >
                              <Input type="number"
                                min={0}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                pattern="[0-9]"

                                // value={
                                //   parseInt(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC]?.
                                //MethodologySpecs[activeTabSM]?.SelectedVendor?.filter(co => co.id == activeCoId)[0]?.selected.filter(v => v.key == d.key)[0]?.CostForTable == undefined ? "" :
                                //     (activeInternalDropCurrency == undefined ? project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.SelectedVendor?.filter(co => co.id == activeCoId)[0]?.selected.filter(v => v.key == d.key)[0]?.CostForTable :
                                //       (project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.SelectedVendor?.filter(co => co.id == activeCoId)[0]?.selected.filter(v => v.key == d.key)[0]?.AdditionalCostUSD * (activeInternalDropCurrency == undefined ?
                                //         1 : activeInternalDropCurrency.ConversionRateToLocal)).toFixed(2))) || ""
                                // }
                                // value={internalAdditionalCost[0].InternalCost.filter(ic=>ic.key == d.key).length>0?
                                //     parseInt(internalAdditionalCost[0].InternalCost.find(ic=>ic.key == d.key).CostForTable):0}
                                value={
                                    internalAdditionalCost.filter(f=>(f.Type=="Internal Cost"||f.Type=="Total Internal Cost")&&f.keyID == d.key).length>0?
                                    parseFloat(internalAdditionalCost.find(ic=>ic.keyID == d.key)?.AdditionalCostUSD):""
                                }
                                    onChange={(e) => {
                                  (e.target.value < 0) ? toastr.error("Please Enter Valid Number")
                                    : SetinternalCostValue(e, d)
                                }} />
                              <InputGroupAddon addonType="prepend">{activeInternalDropCurrency == null || activeInternalDropCurrency == undefined || !activeInternalDropCurrency ?
                                project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].Currency?.label
                                : activeInternalDropCurrency.label}</InputGroupAddon>
                            </InputGroup>
                          </td>
                        </tr>
                      )
                    })
                }
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary"
              onClick={() => { saveInternalCost() }}>
              Submit
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setInternalCost(!InternalCost);
                project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].SelectedVendor = _.cloneDeep(project.CostingProfiles[project?.CostingProfiles?.length - 1].CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].copySelectedVendor);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
  )
}

export default AdditionalCostPopUp