import {
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import update from 'immutability-helper';
import _ from "lodash";
import React, { useRef, useEffect ,useState} from "react";
import jspreadsheet from "jspreadsheet-ce";
import "jspreadsheet-ce/dist/jspreadsheet.css";
import { faAngleUp,faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './spreadsheet.css';
import {
    Col, Row, Card,
    CustomInput,
    CardBody,
    CardTitle,
    CardHeader, FormFeedback, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane
    , Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip, UncontrolledCollapse,
    Table,
    Container,
    Input,
    } from "reactstrap";
    
import './common.css';
import Select from "react-select";
import
{
    AvForm,
    AvField,
    AvRadioGroup,
    AvInput,
    AvRadio
} from "availity-reactstrap-validation";

export default function JSpreadSheetsDemo({ optionsInput,spreadSheetData,setSpreadSheetData,setTempData,indxSD })
{

    const [mainStatsState, setMainStatsState] = useState(false);  
     const [tc,setTc]= useState(0)
   function handleChangeMainStats(indexMS, indexMSD, e)
    {
        
        let dataTemp = _.cloneDeep(spreadSheetData);
        dataTemp[indxSD].options.mainStatsData[indexMS].data[indexMSD].value=e.target ? e.target.value:e;       
        if(indexMS==7)
        {  
            let tempDataObj=dataTemp[indxSD].options.mainStatsData[7].data[indexMSD];
            if(e.value==2 || e.value==3)
                tempDataObj["disabled"]=false;             
            else 
            tempDataObj["disabled"]=true;   
            changeMCPMargin(e.value,++indexMSD);
        }         
        setSpreadSheetData(dataTemp);
    }
    var OOPTAX;  
    var indr=optionsInput.data.map(function(x) {return x.description; }).indexOf("Costing Summary")  
     var  ind = `A${indr+1}`
    const [spreadSheetOption, setSpreadSheetOption] = useState({
       minDimentions:[5,5],
    
 
        data: null,
        columns:null, 
        nestedHeaders: null,
        style:null,
        rowResize: false,
        columnDrag: false,
        allowInsertRow: false,
        allowInsertColumn: false,  
        allowDeleteRow:false,   
        allowDeleteColumn:false,       
        tableOverflow:true,
        lazyLoading:false,
        loadingSpin:true,
        tableWidth: "100%",
        tableHeight: "600px",
        freezeColumns: 1,  
        onload:function()
        {
         
            // let samp=optionsInput.map(x=>x.filter(!x.description))
        //       let v=  optionsInput.data.filter(x=>x.description=="SUBTOTAL OPERATIONS OOP"||x.description=="SUBTOTAL COMMERCIAL OOP")
           
        //    v.forEach(x=>{
               
        //   let row= optionsInput.data.map(function(i) {return i.description; }).indexOf(x.description)
        //     let keys= Object.keys(x)
        //         let fkeys = keys.filter(x=>x!="description")
        //             fkeys.forEach(y=>{
        //                  let fKey="fv_" + y;  
        //      let fValue= 0   
        //      setTempData({index:indxSD,rowIndex:row+1,key:y,value:0,fKey:fKey,fValue:fValue,type:"spreadsheet"});
            
        //            })

        //    })
           
              jRef.current.jspreadsheet.setMerge(ind, optionsInput.columns.length, 1);
            jRef.current.jspreadsheet.setHeight(parseInt(indr),50);
         
         
        
        }   ,    
        onchange: function (instance, cell, x, y, cellValue)
        {          
            // let dataTemp = _.cloneDeep(spreadSheetData);
            // let obj= dataTemp.data[indxSD].options.data[y];
            // var key = Object.keys(obj)[x];
            // dataTemp.data[indxSD].options.data[y][key]=value;       
                
            let obj= optionsInput.data[y];  
            let key = Object.keys(obj)[x];
            let value = cellValue;
            let fKey= String(value).startsWith("=")? "fv_" + key:null;  
            let fValue= String(value).startsWith("=")?cell.innerText:value===""?0:value;   
            setTempData({index:indxSD,rowIndex:y,key:key,value:value,fKey:fKey,fValue:fValue,type:"spreadsheet"});
            if(obj.description=="SUBTOTAL OPERATIONS OOP")
            changeOpsCPIWithOutTax(x,y,cell.innerText);

            //Changes by manish
            if(obj.description=="Final Price to client")
            changeCPItoclientTax(x,y,cell.innerText);

            if(obj.description=="SUBTOTAL OPERATIONS OOP")
            changewithtax1(x,y,cell.innerText);
                       
                       
            if(obj.description=="TAX COMPONENT")  
            changewithtax2(x,y,cell.innerText);
            // if (obj.description == "Total Time Cost Operations")
            // handleval(x,y,cell.innerText,cell)

            // setTempData(prevState => ({
            //     data: prevState.data.map(
            //       (el,index) => index === indxSD? tempObject: el
            //     )              
            //   }));
            
            //setTempData(prevState=> [...prevState, {name:y}]);     
            //setSpreadSheetData({[spreadSheetData]: newArray });
            // setSpreadSheetData(prevState => ({
            //     ...prevState,data:dataTemp
            //   }));
            // setSpreadSheetData(prevState => ({
            //     data: prevState.data.map(
            //       (el,index) => index === indxSD? tempObject: el
            //     )              
            //   }));

              //setSpreadSheetData({...spreadSheetData,data:dataTemp.data});
                 
            // setSpreadSheetData((prevState) =>{
            //     return {
            //       data: dataTemp                  
            //     }
            //   });  
        },
        updateTable: function(el, cell, x, y, source, value, id) {   
          
            {
            //here x=column index zero based
            //here y=row index zero based            
            

            
            let readonly= optionsInput.readonly.find(ro=>ro.x==x && ro.y==y);
            let val=value;
            let idd=id;
            let celll=cell;
            let xx=x;
            let yy=y;
            let ell=el;
            let sourcee=source;
            if (readonly || x==0) {
                cell.classList.add('readonly');
               
            }
          }
        }
    });
    const jRef = useRef(null);
    //to hide 1,2,3,4 in first column
    //const onLoad = (instance) =>
    //{
    //    jRef.current.jexcel.hideIndex();
    //}  

    //to change the value of cell
    var handleChange = (instance, cell, x, y, value) =>
    {
        var cellName = jRef.current.jexcel.getColumnNameFromId([x, y]);
       // //console.log(cellName)
    }
    //to make a cell read only
    var updatetable=(el, cell, x, y, source, value, id)=>{
        
        if (x == 2 && y == 2) {
            cell.classList.add('readonly');
        }
    }

    const changeMCPMargin = (arg,x) =>
    {
        
       let indexVar= optionsInput.data.map(function(e) { return e.description; }).indexOf('MCP Margin - 5% OR 10% [EXCLUDED FOR VENDOR ONLY]');
       let formula=jRef.current.jexcel.getValueFromCoords(x,indexVar);
      let index= formula.indexOf('*');
       
       let newFormula=String(formula).substr(0,index)+'*'+ (arg==1?'0.1':(arg==3?'0.05':'0'))+String(formula).substr(index+2,String(formula).length);       
       jRef.current.jexcel.setValueFromCoords(x,indexVar,newFormula,true);          
    };
  
    const changeOpsCPIWithOutTax=(x,y,val)=>
    {
   
        let dataTemp = _.cloneDeep(spreadSheetData);
        let sampleSizeVars=dataTemp[indxSD].options.data.filter(d=>String(d.description).startsWith("Sample Size"));
        let sampleSizeTotal=0;
        sampleSizeVars.forEach(v=>
            {
                 Object.keys(v).forEach(k=>
                    {
                          if(k.startsWith("co_"+x))
                          sampleSizeTotal+= v[k]==null?0:parseFloat(v[k]);
                    });
            });        
        let tempValue= sampleSizeTotal==0?0:parseInt(val)/sampleSizeTotal; 
        tempValue=tempValue.toFixed(2)
        //dataTemp[0].options.mainStatsData[4].data[x-1].value= parseInt(val)/sampleSizeTotal; 
        setTempData({index:indxSD,rowIndex:4,colIndex:x,type:"mainstats",value:tempValue});              
        //setSpreadSheetData(dataTemp);
    }


    const changeCPItoclientTax=(x,y,val)=>
    {
    
        let dataTemp = _.cloneDeep(spreadSheetData);
        let sampleSizeVars=dataTemp[indxSD].options.data.filter(d=>String(d.description).startsWith("Sample Size"));
        let sampleSizeTotal=0;
        sampleSizeVars.forEach(v=>
            {
                 Object.keys(v).forEach(k=>
                    {
                        
                          if(k.startsWith("co_"+x))
                          sampleSizeTotal+=v[k]==null?0: parseFloat(v[k]);
                    });
            });        
        let tempValue=sampleSizeTotal==0?0: parseInt(val)/sampleSizeTotal; 
        tempValue=tempValue.toFixed(2)
        //dataTemp[0].options.mainStatsData[4].data[x-1].value= parseInt(val)/sampleSizeTotal; 
        setTempData({index:indxSD,rowIndex:3,colIndex:x,type:"mainstats",value:tempValue});              
        //setSpreadSheetData(dataTemp);
    }

    const changewithtax1=(x,y,val)=>
    {

        let dataTemp = _.cloneDeep(spreadSheetData);
        let sampleSizeVars=dataTemp[indxSD].options.data.filter(d=>String(d.description).startsWith("Sample Size"));
        let sampleSizeTotal=0;
        sampleSizeVars.forEach(v=>
            {
                 Object.keys(v).forEach(k=>
                    {
                          if(k.startsWith("co_"+x))
                          sampleSizeTotal+= v[k]==null?0:parseFloat(v[k]);
                    });
            });        
            OOPTAX=0;
            OOPTAX = (parseInt(val)); 
        //dataTemp[0].options.mainStatsData[4].data[x-1].value= parseInt(val)/sampleSizeTotal; 
        //setTempData({index:indxSD,rowIndex:5,colIndex:x,type:"mainstats",value:tempValue});              
        //setSpreadSheetData(dataTemp);
    } 

    const changewithtax2=(x,y,val,taxval)=>
    {
      
        let dataTemp = _.cloneDeep(spreadSheetData);
        let sampleSizeVars=dataTemp[indxSD].options.data.filter(d=>String(d.description).startsWith("Sample Size"));
        let sampleSizeTotal=0;
        sampleSizeVars.forEach(v=>
            {
                 Object.keys(v).forEach(k=>
                    {
                          if(k.startsWith("co_"+x))
                          sampleSizeTotal+= v[k]==null?0:parseFloat(v[k]);
                    });
            });        
        let tempValue= sampleSizeTotal==0?0:(parseInt(val)+ OOPTAX)/sampleSizeTotal; 
        tempValue=tempValue.toFixed(2)
     
        //dataTemp[0].options.mainStatsData[4].data[x-1].value= parseInt(val)/sampleSizeTotal; 
        setTempData({index:indxSD,rowIndex:5,colIndex:x,type:"mainstats",value:tempValue});              
        //setSpreadSheetData(dataTemp);
    }

    
    useEffect(() =>
    {
     
        setSpreadSheetOption({
            ...spreadSheetOption,
            data:optionsInput.data
            ,columns:optionsInput.columns
            ,nestedHeaders:optionsInput.nestedHeaders
            ,style:optionsInput.style
        });
        
    }, []); 

    useEffect(() =>
    {
        
        if(spreadSheetOption.data)
        {       
            if (!jRef.current.jspreadsheet)
            {
                jspreadsheet(jRef.current, spreadSheetOption);
            }
        }
    }, [spreadSheetOption]); 

    return (
        <>         
        <Row>            
          <Col md={12} className={"tableCosting"}>       
            
            <AvForm encType="multipart/form-data">
                    <Table size="sm" bordered style={{ maxWidth: "100%", width: "auto",border:'solid 1px black' }}>
                        <thead>                         
                        {optionsInput.mainStatsData.map((ms, indexMS) =>
                            <tr key={'ms_'+indxSD+"_"+indexMS}>
                                {(indexMS==0 || mainStatsState) &&
                                <>
                                <th style={{ width:"450px",minWidth:"450px", maxWidth:"450px",backgroundColor:'#f3f3f3'}}>
                                 <div style={{display:"flex",justifyContent:"space-between"}}>
                                 {ms.name}  
                                 { indexMS==0 &&                                  
                                 <>
                                 <span id={'toggle-main-stats-' + indxSD+"-"+indexMS} onClick={()=>setMainStatsState(!mainStatsState)}>
                                 <FontAwesomeIcon 
                                                className="align-middle mr-2"
                                                icon={mainStatsState ? faChevronDown: faChevronRight}
                                                fixedWidth   
                                 />  
                                 </span>
                                 <UncontrolledTooltip placement="left" target={'toggle-main-stats-' + indxSD+"-"+indexMS}>
                                 Click To Toggle
                                 </UncontrolledTooltip>
                                 </>                                 
                                 } 
                                 </div>                            
                                </th>
                                {ms.data.map((msd,indexMSD)=>                                
                                <td key={'msd_'+indexMS+"_"+indexMSD} style={{ width: "160px",minWidth:"160px",maxWidth:"160px",color:'black',fontWeight:'bold',textAlign:'center'}}>
                                     { ms.type=='select' &&
                                          <Select
                                          name={ms.label}
                                          isDisabled={ms.disabled}
                                          value={msd.value}   
                                          className="react-select-container"
                                          classNamePrefix="react-select"  
                                          menuPortalTarget={document.querySelector('tableCosting')}
                                          menuPosition={'fixed'}       
                                          onChange={(e) => handleChangeMainStats(indexMS, indexMSD, e)}                                
                                          options={ ms.name!= 'Currency' ?                                       
                                            ms.options.map(
                                                      (opt) =>
                                                      {
                                                          return {
                                                              value: opt.value,
                                                              label: opt.label
                                                          }
                                                      })
                                            : optionsInput.currencies.map(
                                                (opt) =>
                                                {
                                                    return {
                                                        value: opt.id+"##"+opt.Code,
                                                        label: opt.CurrencyUnit+" ("+opt.Label+")"
                                                    }                                                    
                                                }) 
                                          }                                                                         
                                      />
                                     }
                                     { ms.type=='text' &&
                                     <input type="text" disabled={ms.disabled||msd.disabled} name={ms.disabled} value={msd.value} style={{ width: "100%" }}
                                     onChange={(e) => handleChangeMainStats(indexMS, indexMSD, e)}
                                      />
                                     }
                                     { ms.type=='checkbox' &&                                         
                                        <input type="checkbox" checked={msd.value}  onChange={(e) => handleChangeMainStats(indexMS, indexMSD, e.target.checked)}/>
                                     }
                                </td>      
                                )}  
                                </>
                                }                                        
                            </tr>                            
                        )}                                                                                
                        </thead>                                        
                    </Table>
                    </AvForm>
                        </Col>
                    </Row>
            <div ref={jRef} />
             
            <br /> 
        </>
    );
} 