import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import * as projectActions from "../../redux/actions/currentProjectActions";
import {setOpportunityValue} from "../../redux/actions/currentProjectActions"
import * as currentCostingActions from "../../redux/actions/currentCostingActions"
import store from "../../redux/store/index"
import {
  CustomInput,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import Select from "react-select";
import moment from "moment";
import {
  getMultiOptions,
  getMultiOptionsWithDependency,
  getSubMethodologyAttribute,
  getSingleOptions
} from "../../utils/codeLabels";
import _, { method } from "lodash";
import { toastr } from "react-redux-toastr";
import { func } from "prop-types";
import axios from "../../axios-interceptor";
import CostingMarkets from "./CostingMarkets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons";

const ProjectDetails = ({ setCostingMarkets,project, fieldInvalidation, updateProject, setProfileDetailsChange }) => {
  // delete state for methodologies
  const [methodologyData, setMethodologyData] = useState([]);
  const [startDate,setStartDate]=useState('')
  // delete state for submethodologies
  const [subMethodologyData, setsubMethodologyData] = useState([]);
  const [methodoptions,setMethodoptions]=useState([{ value: 'QUANT', label: 'QUANT' },
  { value: 'QUAL', label: 'QUAL' }])
  const [tableData,setTableData]=useState([])
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [editIndex,setEditIndex]=useState()
  const [addIndex,setAddIndex]=useState(-1);
  const [quanOptions,SetQuanOptions]=useState()
  const [qualOptions,SetQualOptions]=useState()
  const [cloneQuanOptions,setCloneQuanOptions]=useState()
  const [cloneQualOptions,setCloneQualOptions]=useState()
  const [methodologiesOptions,setMethodologiesOptions]=useState([])
  const [modalData,setModalData]=useState({})
  const [cintralmethod,setCintralmethod]=useState([])
  const [productList,setProductList]=useState([])
  const [ProductDescription,setProductDescription]=useState("")
  const [materialIDoptions,setMaterialIDoptions]=useState([])
  const [cintralmethodarr,setCintralmethodarr]=useState([])
  const [costingMarketTable,setCostingMarketTable]=useState([])
  const [productOptions,setProductOptions]=useState([])
  const [indexPostion,setIndexPostion]=useState(null)
  const [countryList,setCountryList]=useState([])
  const [modal3,setmodal3]=useState(false)
  const [productValue,setProductvalue]=useState(null)
  const [functionOptions,setFunctionOptions]=useState([{ value: 'Commercial', label: 'Commercial' },
  { value: 'Operations', label: 'Operations' }])
  const [costingmarketModal,setCostingmarketModal]=useState(false)
  const [functions,setFunctions]=useState(null)
  // toast msg for deletig methodology
  const [toastDelete, setToastDelete] = useState(false);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const [selectedValues, setSelectedValues] = useState([]);
  const [materialID, setMaterialID] = useState({})
  const [methodologyTableData, setMethodologyTableData] = useState([])
  const [methodologyType, setMethodologyType] = useState({})
  const [errorMessageWBS, setErrorMessageWBS] = useState('');
  const [inputValueWBS, setInputValueWBS] = useState('');
  const [WaveNumber, setWaveNumber] = useState({})
  const [WBSNumber, setWBSNumber] = useState({})  

  const [fieldInvalidationModal, setFieldInvalidationModal] = useState({
  
    MaterialID:false,
    methodology:false,
    methodologyType:false,
    StartDate:false,
    EndDate:false,
  });
  const product = useSelector(

    ({ currentProject }) => currentProject.productValue

  );
  let oppValue = useSelector(

    ({ currentProject }) => currentProject.opportunityValue

  );
  let fieldingCountryValue = useSelector(
    ({ currentProject }) => currentProject.newProject.ExcludedFieldingCountries
  );
  const methodology = useSelector(

    ({ currentProject }) => currentProject.methodologyValue

  );
  let oppData = useSelector(

    ({currentProject}) => currentProject.opportunityData

  );
  var showText;
  const tableDataRedux=useSelector(

    ({currentProject}) => currentProject.tabledata

  );
  console.log("currentProject:",useSelector(({currentProject}) => currentProject));

  let styles={
    border: "1px solid red"
  }
  const toggle3=()=>{
    setmodal3(!modal3)
  }
  
  const methodologyvalue = [...new Set(methodology[0]?.methodology)].map((item) => item.toString());
  const productvalue = [...new Set(methodology[0]?.product)];

  const costingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  console.log("currentCostingProfile:",store.getState().currentCosting.currentCostingProfile);
  const lineitems=useSelector(
    ({ currentProject }) => currentProject.lineitems
  )
  const setMethodolodyValues=(index)=>{
    if(!project.IsSyndicated && tableData[index]?.methodologyType !==undefined){
      if(tableData[index]?.methodologyType==="QUANT"){
        let methodologyLabel = tableData[index]?.methodology.map((i) => i.label)
        let qOtions = quanOptions.filter(qo => !methodologyLabel.includes(qo.label));
        setMethodologiesOptions(qOtions);
      }  else{
        let methodologyLabel = tableData[index]?.methodology.map((i) => i.label)
        let qOtions = qualOptions.filter(qo => !methodologyLabel.includes(qo.label));
        setMethodologiesOptions(qOtions);
      }
      }
      else if(project.IsSyndicated && tableData[index]?.methodologyType !==undefined){
        if(tableData[index]?.methodologyType==="QUANT"){
        let methodologyLabel = tableData[index]?.methodology.map((i) => i.label)
        let qOtions = quanOptions.filter(qo => !methodologyLabel.includes(qo.label));
        setMethodologiesOptions(qOtions);
      }  else{
        let methodologyLabel = tableData[index]?.methodology.map((i) => i.label)
        let qOtions = qualOptions.filter(qo => !methodologyLabel.includes(qo.label));
        setMethodologiesOptions(qOtions);
      }

      }
  }

  const setMethodologyDataValues=(index)=>{
   if(tableData[index]?.methodology && tableData[index]?.methodology.length > 0) {
    setMethodologyTableData(tableData[index]?.methodology);
   }else{
    setMethodologyTableData([]);
   }
  }
  //for Edit of non-syndicated and add of syndicated
  const toggle = () =>
  {
  
    setProductDescription("")
    setModalData({})
    setMethodologyType({})
    setModal(!modal);
  } 
  //for edit of syndicated
  const toggle2 = () =>
  {
    
    setProductDescription("")
    setModalData({})
    setMaterialID({})
    setMethodologyTableData([])
    setMethodologyType({})
    setModal2(!modal2);
  } 
  const updateCostingProfile = (_costingProfile) => {
    setProfileDetailsChange(true)
    dispatch({
      type: "UPDATE_NEW_COSTING",
      currentCostingProfile: _costingProfile,
    });
    updateProject({
      FieldingCountries: _costingProfile.FieldingCountries,
    });
  };

  
  const dispatch = useDispatch();
  // methodologies
  const [methodologyList, setmethodologyList] = useState([]);
  // submethodologies
  const [subMethodologyList, setsubMethodologyList] = useState([]);
  const handleFunctionChange = (e) => {
    setFunctions(e)
  }
  const handleChildWBSchange=(e,index)=>{debugger;
    let temparr=_.cloneDeep(costingMarketTable)
    temparr[index]["ChildWBSNumber"]=e.target.value
    setCostingMarketTable(temparr)
    setCostingMarkets(temparr);
  }
  const handleAddCountry = (e) => {
    if (indexPostion === null || countryList.length === 0 || functions===null) {
      toastr.warning("Select product , countries and functions");
      return;
    }
    let functionitems;
    console.log(functions);
    if(functions.length===1)
    {
      functionitems=functions[0].label
    }
    else if(functions.length===2)
    {
      functionitems="Commercial & Operations"
    }
    let index = -1;
    let obj = {};
    for (let i = costingMarketTable.length - 1; i >= 0; i--) {
      if (costingMarketTable[i].productIndex === indexPostion) {
        index = i;
        obj = _.cloneDeep(costingMarketTable[i]);
        break;
      }
    }

    if("id" in obj)
    {
      delete obj["id"]
    }
    let checkarr = [];
    costingMarketTable.map((item) => {
      if (item.productIndex === indexPostion) {
        checkarr.push({Country:item.Countries,functions:item.functions,source:item.Source});
      }
    });

    let countryarr = countryList;
    countryarr=countryarr.filter((item)=> checkarr.findIndex((i)=>i.Country===item.label&& i.functions==="Commercial & Operations")===-1)
    if(functionitems==="Commercial & Operations")
    {
      countryarr=countryarr.filter((item)=> checkarr.findIndex((i)=>i.Country===item.label)===-1 )
    }
    let countryListarr = countryarr.filter(
      (item) => checkarr.findIndex((i)=>i.Country===item.label && i.functions===functionitems)===-1
    );
    let check=false
    let temp = _.cloneDeep(costingMarketTable);
    countryListarr.map((item,index)=>{
      temp.map((i)=>{
        if(item.label===i.Countries && i.Source==="Added")
        {
          i.functions="Commercial & Operations"
          countryListarr.splice(index,1);
          check=true
        }
      })
    })
    setCostingMarketTable(temp)
    
    setCostingMarkets(temp);
    if (countryListarr.length > 0) {
      if(index!=-1)
      {
        let arrObj = countryListarr.map((country) => {
          return {
            ...obj,
            ...{ Source: "Added", Countries: country.label, SourceColumn: "",functions:functionitems,ChildWBSNumber: "",IsSelected: false },
          };
        });

        let temparr = _.cloneDeep(costingMarketTable);
        if (index !== -1) {
          temparr.splice(index + 1, 0, ...arrObj);
        }
        setCostingMarketTable(temparr)
        setCostingMarkets(temparr);
      }
      else
      {
        toastr.warning("Select countries for this material ID in timecost to add more countries");
      }
    }
    else if(check)
    {

    } 
    else {
      toastr.warning("Selected countries are already added");
    }
    setCountryList([]);
  };
  
 
  useEffect(() => {
   

    if(costingProfile.id!==undefined)
   
 
{
  axios.put(`/timecost/CostMarket/${costingProfile.id}`).then((re)=>{

  
      axios.get(`/timecost/CostMarket/${costingProfile.id}`).then((res)=>{
        console.log(res);
      
      let CostMarketCountries=res.data.result[0].CostMarketCountries

      if (CostMarketCountries !== undefined) {
        if (
          CostMarketCountries.length > 0 &&
          costingProfile.ProductTableData != undefined
        ) {
          while (typeof costingProfile.ProductTableData === "string") {
            costingProfile.ProductTableData = JSON.parse(
              costingProfile.ProductTableData
            );
          }
          let productarr=costingProfile.ProductTableData.filter((item)=>!("isClone" in item && item.isClone===true))
          let products = productarr.map((item, index) => ({
            value: index,
            label: index + 1 + ". " + item.MaterialID,
          }));
          if (products.length === 1) {
            setProductvalue(products[0].label);
            setIndexPostion(0);
          }
          setProductOptions(products);
          let costMarketCountries = CostMarketCountries;
          while (typeof costingProfile.CostingCountries === "string") {
            costingProfile.CostingCountries = JSON.parse(
              costingProfile.CostingCountries
            );
          }
          let costingMarkets = res.data.result[0].CostingCountries;
         
          let checkarr=[]
          let tableData = costingProfile.ProductTableData.filter((item)=>!("isClone" in item && item.isClone===true));
          tableData.map((item, index) => {
            if( costingMarkets[item.OpportunityLineItemID]!==undefined)
            {
            let country = [];
            costMarketCountries.map((table) => {
              if (table.productIndex >index) {
                return;
              }
              if (table.Source === "Timecost" && table.productIndex===index) country.push(table.Countries);
            });
            let difference = costingMarkets[item.OpportunityLineItemID].filter((i) => !country.includes(i.country));
            let itemarr= costingMarkets[item.OpportunityLineItemID].map((item)=>item.country)
            checkarr[index]=itemarr
            let findIndex;
            for (let i = costMarketCountries.length - 1; i >= 0; i--) {
              if (costMarketCountries[i].productIndex === index) {
                findIndex = i;
                break;
              }
            }
            let newcountryarr = [];

            difference.map((market) => {
              let indexnumber = index + 1 + ". ";
              if (market.country === "Pakistan") {
                newcountryarr.push({
                  MaterialID: indexnumber + item.MaterialID,
                  SubProjectType: item.methodologyType,
                  ParentWBSNumber: item.WBSNumber,
                  Source: "Timecost",
                  Countries: market.country,
                  functions:market.function,
                  SourceColumn: "Icon Hub",
                  ChildWBSNumber: "",
                  IsSelected: false,
                  toDisplay: false,
                  productIndex: index,
                  isValid: true,
                  OpportunityLineItemID: item.OpportunityLineItemID,
                });
              } else if (market.country === "Russia") {
                newcountryarr.push({
                  MaterialID: indexnumber + item.MaterialID,
                  SubProjectType: item.methodologyType,
                  ParentWBSNumber: item.WBSNumber,
                  Source: "Timecost",
                  Countries: market.country,
                  functions:market.function,
                  SourceColumn: "Smart Store",
                  ChildWBSNumber: "",
                  IsSelected: false,
                  toDisplay: false,
                  productIndex: index,
                  isValid: true,
                  OpportunityLineItemID: item.OpportunityLineItemID,
                });
              } else {
                newcountryarr.push({
                  MaterialID: indexnumber + item.MaterialID,
                  SubProjectType: item.methodologyType,
                  ParentWBSNumber: item.WBSNumber,
                  Source: "Timecost",
                  Countries: market.country,
                  functions:market.function,
                  SourceColumn: "",
                  ChildWBSNumber: "",
                  IsSelected: false,
                  toDisplay: false,
                  productIndex: index,
                  isValid: true,
                  OpportunityLineItemID: item.OpportunityLineItemID,
                });
              }
            });
            costMarketCountries.splice(findIndex + 1, 0, ...newcountryarr);
          }
          });
          costMarketCountries = costMarketCountries.filter(
            (item) =>
              checkarr[item.productIndex].includes(item.Countries) || item.Source === "Added"
          );

          costMarketCountries.map((table)=>{
            if(costingMarkets[table.OpportunityLineItemID]!==undefined)
            {
              costingMarkets[table.OpportunityLineItemID].map((item)=>{
                if(item.country===table.Countries && table.Source==="Timecost")
                {
                  table.functions=item.function
                }
              })
            }
          })
          let finalcostmarket=[]

          costingProfile.ProductTableData.map((item,index)=>{
              costMarketCountries.map((table)=>{
                if(index===table.productIndex)
                {
                  table.ParentWBSNumber=item.WBSNumber
                  finalcostmarket.push(table)
                }
              })
          })
          setCostingMarketTable(finalcostmarket);
        } else {
          while (typeof costingProfile.CostingCountries === "string") {
            costingProfile.CostingCountries = JSON.parse(
              costingProfile.CostingCountries
            );
          }
          let costingMarkets=res.data.result[0].CostingCountries;
          

          if (costingMarkets !== null && costingMarkets !== "null") {
              if (costingProfile.ProductTableData != undefined) {
                let temparr = [];

                while (typeof costingProfile.ProductTableData === "string") {
                  costingProfile.ProductTableData = JSON.parse(
                    costingProfile.ProductTableData
                  );
                }
                let productarr=costingProfile.ProductTableData.filter((item)=>!("isClone" in item && item.isClone===true))
                let products = productarr.map(
                  (item, index) => ({
                    value: index,
                    label: index + 1 + ". " + item.MaterialID,
                  })
                );
                if (products.length === 1) {
                  setProductvalue(products[0].label);
                  setIndexPostion(0);
                }
                setProductOptions(products);

                let tableArr = [];
                let tableData = costingProfile.ProductTableData.filter((item)=>!("isClone" in item && item.isClone===true));;
                tableData.map((item, i) => {
                  if(costingMarkets[item.OpportunityLineItemID]!==undefined){
                    costingMarkets[item.OpportunityLineItemID].map((market, index) => {
                      let indexnumber = i + 1 + ". ";
                      if (index === 0) {
                        if (market.country === "Pakistan") {
                          tableArr.push({
                            MaterialID: indexnumber + item.MaterialID,
                            SubProjectType: item.methodologyType,
                            ParentWBSNumber: item.WBSNumber,
                            Source: "Timecost",
                            Countries: market.country,
                            functions:market.function,
                            SourceColumn: "Icon Hub",
                            ChildWBSNumber: "",
                            IsSelected: false,
                            toDisplay: true,
                            productIndex: i,
                            isValid: true,
                            OpportunityLineItemID: item.OpportunityLineItemID,
                          });
                        } else if (market.country === "Russia") {
                          tableArr.push({
                            MaterialID: indexnumber + item.MaterialID,
                            SubProjectType: item.methodologyType,
                            ParentWBSNumber: item.WBSNumber,
                            Source: "Timecost",
                            Countries: market.country,
                            functions:market.function,
                            SourceColumn: "Smart Store",
                            ChildWBSNumber: "",
                            IsSelected: false,
                            toDisplay: true,
                            productIndex: i,
                            isValid: true,
                            OpportunityLineItemID: item.OpportunityLineItemID,
                          });
                        } else {
                          tableArr.push({
                            MaterialID: indexnumber + item.MaterialID,
                            SubProjectType: item.methodologyType,
                            ParentWBSNumber: item.WBSNumber,
                            Source: "Timecost",
                            Countries: market.country,
                            functions:market.function,
                            SourceColumn: "",
                            ChildWBSNumber: "",
                            IsSelected: false,
                            toDisplay: true,
                            productIndex: i,
                            isValid: true,
                            OpportunityLineItemID: item.OpportunityLineItemID,
                          });
                        }
                      } else {
                        if (market.country === "Pakistan") {
                          tableArr.push({
                            MaterialID: indexnumber + item.MaterialID,
                            SubProjectType: item.methodologyType,
                            ParentWBSNumber: item.WBSNumber,
                            Source: "Timecost",
                            Countries: market.country,
                            functions:market.function,
                            SourceColumn: "Icon Hub",
                            ChildWBSNumber: "",
                            IsSelected: false,
                            toDisplay: false,
                            productIndex: i,
                            isValid: true,
                            OpportunityLineItemID: item.OpportunityLineItemID,
                          });
                        } else if (market.country === "Russia") {
                          tableArr.push({
                            MaterialID: indexnumber + item.MaterialID,
                            SubProjectType: item.methodologyType,
                            ParentWBSNumber: item.WBSNumber,
                            Source: "Timecost",
                            Countries: market.country,
                            functions:market.function,
                            SourceColumn: "Smart Store",
                            ChildWBSNumber: "",
                            IsSelected: false,
                            toDisplay: false,
                            productIndex: i,
                            isValid: true,
                            OpportunityLineItemID: item.OpportunityLineItemID,
                          });
                        } else {
                          tableArr.push({
                            MaterialID: indexnumber + item.MaterialID,
                            SubProjectType: item.methodologyType,
                            ParentWBSNumber: item.WBSNumber,
                            Source: "Timecost",
                            Countries: market.country,
                            functions:market.function,
                            SourceColumn: "",
                            ChildWBSNumber: "",
                            IsSelected: false,
                            toDisplay: false,
                            productIndex: i,
                            isValid: true,
                            OpportunityLineItemID: item.OpportunityLineItemID,
                          });
                        }
                      }
                    });
                  }
                });
                setCostingMarketTable(tableArr);
              }
            
          }
        }
      }
     
    })
  }).catch((error)=>{
      console.log(error)
    })
    }
    
  },[costingProfile.id] );
  
  const findMethodologyFromTypeData=(p,typeData,index)=>{
    let tData =[];
    if(typeData!=undefined){
    typeData.map((t)=>{
      if(t?.OppItemId!==undefined){
        if(t?.OppItemId === index){
        tData.push({label: t?.Label, value: t?.Code, isFixed: true})
        }
      } else{
        let methodology = [];
        let methodologyStr = "";
        codeLabels.MethodologyOptions?.map((cl)=>{
        if(cl.Label ===p.methodology)  {
          methodology.push(cl.Code);
        }
        })
        methodologyStr = methodology[0];
        let subMethCodes = codeLabels?.SubMethodologyOptions?.filter(sub=> sub.ParentMethodologyCode === methodologyStr);
        let costSubMeth = costingProfile?.SubMethodology?.map((sm)=>sm.label);
        let sub=subMethCodes?.filter((s)=>costSubMeth?.includes(s.Label));
        sub?.map((s)=>{
          if(tData && tData?.length >0){
            let labels = tData?.map((sm)=>sm.label);
            if(!labels?.includes(s?.Label)){
              tData.push({label: s?.Label, value: s?.Code, isFixed: true})
            }
          }else{
            tData.push({label: s?.Label, value: s?.Code, isFixed: true})
          }
        })
      }
    })
    }
    console.log("tData:",tData)
    return(tData);
    }
  
  const setTableDataBasedOnTheCostingProfileResponse=(productData,productTypeData)=>{
    let data = [];
    if(productData!=undefined){
    productData.map((p,index)=>{
    let obj = {};
    if(project.IsSyndicated){
     obj = {
      MaterialID : p.MaterialID,
      ProductDescription: p.ProductDescription,
      methodologyType: p.methodologyType,
      methodology: findMethodologyFromTypeData(p,productTypeData,index),
      StartDate:p.StartDate,
      EndDate:p.EndDate,
      WBSNumber:p.WBSNumber,
      Submethodology:p.Submethodology,
      OpportunityLineItemID:p.OpportunityLineItemID,
      WaveNumber:p.WaveNumber
    }  
  }
  else{
    obj = {
      CharacteristicValues:p.CharacteristicValues,
      PracticeArea:p.PracticeArea,
      SubBrand:p.SubBrand,
      GLAccount:p.GLAccount,
      ProfitCentre:p.ProfitCentre,
      ProjectID:p.ProjectID,
      TotalPriceUSD:p.TotalPriceUSD,
      WBSNumber:p.WBSNumber,
      ReconciliationAmount:p.ReconciliationAmount,
      QuoteDetailName:p.QuoteDetailName,
      ExpectedThirdPartyCost:p.ExpectedThirdPartyCost,
      MaterialID : p.MaterialID,
      ProductDescription: p.ProductDescription,
      methodologyType: p.methodologyType,
      methodology: findMethodologyFromTypeData(p,productTypeData,index),
      StartDate:p.StartDate,
      EndDate:p.EndDate,
      // Submethodology:p.Submethodology,
      OpportunityLineItemID:p.OpportunityLineItemID,
      WaveNumber:p.WaveNumber,
      isRed:p.isRed,
      isAmendedit:p.isAmendedit
    }  
  }
    data.push(obj);

    })

    return data;
    }
  }
  
  //setting the table data for the first time
  useEffect(()=>{
    if(oppData !== undefined && Object.keys(oppData).length==0)
    {
      
      if(costingProfile.ProductTableData!=undefined)
      {
        let temparr=[]
        let productData = [];
        let productTypeData = [];
        while(typeof costingProfile.ProductTableData === "string"){
          costingProfile.ProductTableData = JSON.parse(costingProfile.ProductTableData)
        }
        productData=costingProfile.ProductTableData;
        console.log("productData",productData)
        while(typeof costingProfile.ProductTypeData === "string"){
          costingProfile.ProductTypeData = JSON.parse(costingProfile.ProductTypeData)
        }
        productTypeData=costingProfile.ProductTypeData;
        console.log("productTypeData",productTypeData)
        let newTableData=setTableDataBasedOnTheCostingProfileResponse(productData,productTypeData);
        setTableData(newTableData)

           temparr=costingProfile.ProductTableData
        let methodarray=[]
        let prodarray=[]
       
        temparr.map((lineitems)=>{
          methodarray.push(lineitems.methodology)
          prodarray.push(lineitems.MaterialID)
        })
        setCintralmethod(methodarray)
        setProductList(prodarray)
      }
      
     
    }
    else
    {
      
      if(project.ContractDetails[0]?.isUpdated == true){
        while(typeof costingProfile.ProductTableData === "string"){
          costingProfile.ProductTableData = JSON.parse(costingProfile.ProductTableData)
        }
        setTableData(costingProfile.ProductTableData)
      }
      else{
        setTableData( _.cloneDeep(lineitems))
      }
      
    }
    
  },[lineitems,costingProfile.ProductTableData])
  



  useEffect(() => {


    if (
      project.CommissioningCountry !== "" &&
      project.FieldingCountries &&
      project.FieldingCountries.length == 1
    ) {
      updateCostingProfile({
        FieldingCountries: getMultiOptions(
          codeLabels.FieldingCountriesOptions,
          (project.id) ? project.FieldingCountries[0].value : project.CommissioningCountry
        ),
      });
    } else {
      if (!project.id) {
        setMethodologyData([])
        setsubMethodologyData([])
      }
    }
  }, [project.CommissioningCountry]);
  // methodologies
  useEffect(() => {
    console.log("currentCostingProfile_savemodal",costingProfile)
    setmethodologyList(getMultiOptions(codeLabels.MethodologyOptions));
    let temp = [];
    if (costingProfile.Methodology && Array.isArray(costingProfile.Methodology))
      costingProfile.Methodology.map((item) => {
        temp.push({ ...item, isFixed: true });
      });
    else {
      if (costingProfile.Methodology && typeof costingProfile.Methodology == "string") {
        costingProfile.Methodology.split(",").map(m => {
          let meth = codeLabels.MethodologyOptions.find(mo => mo.Code == m)
          temp.push({ label: meth?.Label, value: m, isFixed: true })
        })
      }
    }
    setMethodologyData(temp);
  }, [costingProfile]);

  useEffect(() => {
    if (Object.keys(project.ContractDetails).length < 1)
     {
        dispatch({ type: currentCostingActions.CLEAR_OPP }); 
        setCintralmethodarr([]);
        setCintralmethod([]);
    }
  }, [project.ContractDetails])

  function fieldingCountriesList() {
    let fieldingCountries = []
    costingProfile.FieldingCountries.split(",").map(f => {
      let fc = codeLabels.FieldingCountriesOptions.find(fco => fco.Code == f)
      fieldingCountries.push({ label: fc?.Label, value: f, isFixed: true })
    })
    return fieldingCountries
  }
  const handlemethodoptions=(e)=>
  {
    setMethodologyTableData([]);
    setMethodologyType({value: e.value, label: e.value})
    if(e.value==="QUAL")
    {
      setMethodologiesOptions(qualOptions)
    }
    else if(e.value==="QUANT")
    {
      setMethodologiesOptions(quanOptions)
    }
    
    let temparr=modalData
    temparr.methodologyType=e.value
    setModalData(temparr)
  }
   const handleAddButton=()=>{
     if(project.ContractDetails.length>0)
     {
       setAddIndex(addIndex+1);
       toggle()
     }
     else
     {
       toastr.warning("Please add client details to proceed")
     }
   }

   useEffect(()=>{
    
    let qualOption=codeLabels.SubMethodologyOptions.filter(QUALoption => QUALoption.MethodologyType==='QUAL').map((Qualoption)=>({ label: Qualoption.Label, value: Qualoption.Label}))
    let quanOption=codeLabels.SubMethodologyOptions.filter(QUANToption => QUANToption.MethodologyType==='QUANT').map((Quantoption)=>({ label: Quantoption.Label, value: Quantoption.Label}))
    let materialID=codeLabels.ProductTypeOptions.map((obj) => ({ value: obj.Label, label: obj.Code, isFixed: false }));
    setMaterialIDoptions(materialID)
    SetQualOptions(qualOption)
    SetQuanOptions(quanOption)
  },[])

  // function productTypeList() {
  //   let temp = []
  //   if (costingProfile?.ProductType !== null) {
  //   costingProfile?.ProductType.map(m => {
  //     temp.push({ label: m?.Label, value: m, isFixed: true })
  //   })
  //   return temp
  // }
  // }
  // function productTypeList() {
  //   let temp = []
  //   costingProfile.ProductType.split(",").map(m => {
  //     let meth = codeLabels.ProductTypeOptions.find(mo => mo.Code == m)
  //     temp.push({ label: meth?.Label, value: m, isFixed: true })
  //   })
  //   return temp
  // }
  function productTypeList() {
    let temp = []
    if(costingProfile.ProductType === ""){
      costingProfile.ProductType.split(",").map(m => {
        // let meth = codeLabels.ProductTypeOptions.find(mo => mo.Code == m)
        // temp.push({ label: meth?.Label, value: m, isFixed: true })
        temp.push({ label: m, value: m, isFixed: true })
      })
   
      return temp
    }
    else{
      let temp=[]
      project.CostingProfiles.map(m=>{
        if (m.ProfileStatus !== "99") {
          while(typeof m.ProductTableData === "string"){
            m.ProductTableData = JSON.parse(m.ProductTableData)
          }
          let data = m.ProductTableData.map(t => t.MaterialID);
        if(data.includes(","))
        {
          const array = data.split(",")
          array.map((arr)=>{
            temp.push({label: arr, value: arr})
          })
        }
        else{
          temp = data.map(t => {
            return ({label: t, value: t});
          })
        }
        
        }
        

      })
      return temp
    }
  }
  // submethodologies
  useEffect(() => {
    setsubMethodologyList(
      getMultiOptionsWithDependency(codeLabels.SubMethodology)
    );
    const temp = [];

    if (costingProfile.SubMethodology && Array.isArray(costingProfile.SubMethodology))
      costingProfile.SubMethodology.map((item) => {
        temp.push({ ...item, isFixed: true });
      });

    else {
      if (costingProfile.SubMethodology && typeof costingProfile.SubMethodology == "string") {
        costingProfile.SubMethodology.split(",").map(m => {
          let meth = costingProfile.CountrySpecs[0].MethodologySpecs.find(mo => mo.Code == m)
          temp.push({ label: meth?.Label, value: m, isFixed: true })
        })
      }
    }
    setsubMethodologyData(temp);
  }, [costingProfile]);

  // useEffect(() => {
    
  //   const tempSubMethodologyData = [];
    
  //   if (costingProfile.SubMethodology && Array.isArray(costingProfile.SubMethodology)) {
  //   costingProfile.SubMethodology.forEach((item) => {
  //   tempSubMethodologyData.push({ ...item, isFixed: true });
  //   });
  //   } else {
  //   if (costingProfile.SubMethodology && typeof costingProfile.SubMethodology === "string") {
  //   costingProfile.SubMethodology.split(",").forEach((m) => {
  //   const meth = costingProfile.CountrySpecs[0].MethodologySpecs.find((mo) => mo.Code === m);
  //   tempSubMethodologyData.push({ label: meth?.Label, value: m, isFixed: true });
  //   });
  //   }
  //   }
    
  //   setsubMethodologyData(tempSubMethodologyData);
  //   }, [costingProfile, methodologyvalue]);

  useEffect(() => {
    if(tableData[editIndex]?.isClone) {
      handleCloneMethOptions(tableData[editIndex]);
    }
  }, [editIndex]);

  const handleCloneMethOptions = (data) => {
    let qual = qualOptions.filter(opt => !data?.excludeMeth.includes(opt.label));
    let quan = quanOptions.filter(opt => !data?.excludeMeth.includes(opt.label));
    setCloneQualOptions(qual);
    setCloneQuanOptions(quan);
  }

  const handleProductTypeChange = (value) => {
    setProfileDetailsChange(true)
    updateCostingProfile({
      ProductType: value,
    });
  };
  const handleCheckboxChange=(index)=>{
    let temparr=_.cloneDeep(costingMarketTable)
    temparr[index]["IsSelected"]=!temparr[index]["IsSelected"]
    setCostingMarketTable(temparr)
    setCostingMarkets(temparr);
    

  }
 

  const handleCostingMarketsSave =(e)=>{
    let temparr=_.cloneDeep(costingMarketTable)
    let invalid=false
    temparr.map((item,index)=>{
      if(item.ChildWBSNumber!=="")
      {
      if(!item.ChildWBSNumber.startsWith(item.ParentWBSNumber) || item.ChildWBSNumber.length <=item.ParentWBSNumber.length )
      {
        invalid=true
        temparr[index]["isValid"]=false
      }
      else
      {
        temparr[index]["isValid"]=true
      }
      }
      else
      {
        temparr[index]["isValid"]=true
      }
    })

    setCostingMarketTable(temparr)

    if(invalid)
    {
      toastr.warning("Check Child WBS number input")
    }
    else
    {
      axios.post(`/timecost/CostMarket/${costingProfile.id}`,temparr).then((res)=>{
        toastr.success("Success","Saved successfully")
      }).catch((error)=>{
        console.log(error);
      })
    }
    
  }
  const handleCountryChange=(e)=>{
    setCountryList(e)
  }
  const handleproductchange=(e)=>{
    setIndexPostion(e.value)
    setProductvalue(e.label)
  }
  
  

  
  const getProductOptions = (product) => {
    return product?.map((option) => ({
      value: option,
      label: option,
    }));
  };
  const methData =()=>{
   
    let temp=[]
    var newMethData 
    project.ContractDetails.map((data)=>{
      
      data.opportunityLineItemDetails.flatMap((item) =>{
       if(item?.CharacteristicValues[0]?.Z_FIELDINGMETHOD!==undefined)
       {
        var arr = item?.CharacteristicValues[0]?.Z_FIELDINGMETHOD[0]?.includes("-")?
        item?.CharacteristicValues[0]?.Z_FIELDINGMETHOD[0].replace("- ",""):item?.CharacteristicValues[0]?.Z_FIELDINGMETHOD[0]
        if( arr !== undefined){
          temp.push({label:arr,value:arr})
        }
      }
      else{
        project.CostingProfiles.map((item)=>{
          // item.Methodology.map((data)=>{
            newMethData = item.Methodology
          // })
        })
      }
      }
       )

      })
      const methValues = [...new Set(temp)].map((item) => item.label.toString());
      const uniq = [...new Set(methValues)];
      const finalData= []
      uniq.map((item)=>{
        finalData.push({label:item,value:item})
      })
    if(finalData.length >0){
      return finalData
    }
    else{
      return newMethData
    }
    
    
  }
  //
  const handleMethodologyChange = (e, option) => {
    const { CostingProfiles } = project
    const methodologiesArray = CostingProfiles.length
      ?
      CostingProfiles[0]?.Methodology.map((item, index) => item.label)
      :
      []
    setProfileDetailsChange(true)
    if (handleToastr(methodologiesArray, e)) {
      toastr.error("You cannot delete methodology when costing is in progress");
      return;
    }
    // setToastDelete
    setToastDelete(true);

    // setmethodologyList(e);

    setMethodologyData(e);

    // Methodology change only removes SubMethodologies that no longer apply
    if (e) {
      let temp = _.cloneDeep(costingProfile.SubMethodology) || [];
      if (typeof temp == "string") {
        temp = []
        costingProfile.SubMethodology.split(",").map(m => {
          let meth = costingProfile.CountrySpecs[0].MethodologySpecs.find(mo => mo.Code == m)
          temp.push({ label: meth?.Label, value: m, isFixed: true })
        })
      }
      let tempSub = [];
      e.forEach((x) => {
        let tempRes = getMultiOptionsWithDependency(
          codeLabels.MethodologyOptions,
          [x]
        );
        if (tempRes.length == 1) {
          tempSub.push(tempRes[0]);
        } else {
          tempRes.forEach((y) => {
            if (temp.find((x) => x.label == y.label)) tempSub.push(y);
          });
        }
      });
      setsubMethodologyData(tempSub)
      updateCostingProfile({
        Methodology: e,
        SubMethodology: tempSub, //[]
        ResearchType: "",
        FieldType: "",
      });
    } else {
      updateCostingProfile({
        Methodology: [],
        SubMethodology: [],
        ResearchType: "",
        FieldType: "",
      });
    }
  };

  const handleToastr = (apiData, optionvalue) => {
    if (apiData.length) {
      if (!optionvalue) {
        return true;
      }
      const mappingLabel = optionvalue.map((item) => item.label)

      const methodologyLabel = apiData.map((i) => {
        if(i.hasOwnProperty("isFixed") && i.isFixed=== true){
          return i.label

        }
      })
      const methlabels= methodologyLabel.filter((i)=>i!==undefined)
      let isFounded = mappingLabel.filter(ai => methlabels.includes(ai));
      if (isFounded.length !== methlabels.length) {
        return true;
      }
    }
    return false
  }



  const handleSubMethodologyChange = (e, option) => {
    setProfileDetailsChange(true)
    const { CostingProfiles } = project
    const subMethodologiesArray = CostingProfiles.length
      ?
      CostingProfiles[0]?.SubMethodology.map((item, index) => item.label)
      :
      []
    if (handleToastr(subMethodologiesArray, e)) {
      toastr.error("You cannot delete submethodology when costing is in progress");
      return;
    }
    setsubMethodologyData(e);

    if (e) {
      updateCostingProfile({
        SubMethodology: e,
        ResearchType: getSubMethodologyAttribute(
          codeLabels.MethodologyOptions,
          project.Methodology,
          e,
          "ResearchType"
        ),
        FieldType: getSubMethodologyAttribute(
          codeLabels.MethodologyOptions,
          project.Methodology,
          e,
          "FieldType"
        ),
      });
    } else {
      updateCostingProfile({
        SubMethodology: [],
        ResearchType: "",
        FieldType: "",
      });
    }
  };

 function studyTypeList() {
    let temp = []
    costingProfile.StudyType.split(",").map(m => {
      let meth = codeLabels.StudyTypeOptions.find(mo => mo.Code == m)
      temp.push({ label: meth?.Label, value: m, isFixed: true })
    })
    return temp
  }
  const handleStudyTypeChange = (value) => {
    setProfileDetailsChange(true)
    updateCostingProfile({
      StudyType: value,
    });

  };
  const handlematerialID=(e)=>{
    let tempObj=modalData
    tempObj["MaterialID"]=e.label
    tempObj["ProductDescription"]=e.value
    setMaterialID({value: e.label, label: e.label})
    setProductDescription(e.value)
    setModalData(tempObj)
  }
  const handleTrackerChange = (val) => {
    //updateCostingProfile({
    //    IsTracker: !e.target.defaultChecked,
    //    NumberOfWaves: 1,
    //    TrackingFrequency: "",
    //});

    updateProject({
      IsTracker: val,
      NumberOfWaves: 1,
      TrackingFrequency: "",
    });
  };
  
  const handleWBSNumber=(e)=>{
    const regex = /^YS\.[a-zA-Z0-9]{6}\.[a-zA-Z0-9]{4}\.[a-zA-Z0-9]{3}$/;
    const regexNew = /^XS\.[a-zA-Z0-9]{7}\.[a-zA-Z0-9]{3}$/;
    if(!(regex.test(e.target.value) || regexNew.test(e.target.value)) && e.target.value){
      setErrorMessageWBS('Each WBS number taxonomy should be matched');
    } else {
      setErrorMessageWBS('');
    }
    setInputValueWBS(e.target.value.toUpperCase());
    let tempObj=modalData
    tempObj["WBSNumber"]=e.target.value.toUpperCase();
    setModalData(tempObj)

  }
  const handleMultiCountryChange = (val) => {
    setProfileDetailsChange(true)
    if (!val) {
      let fc = null;
      if (project.CommissioningCountry)
        fc = getMultiOptions(
          codeLabels.FieldingCountriesOptions,
          project.CommissioningCountry
        );

      updateCostingProfile({
        IsMultiCountry: false,
        FieldingCountries: fc,
      });
    } else {
      updateCostingProfile({
        IsMultiCountry: true,
      });
    }
  };
  const ProjectStatus = () => {
    if (project.ProjectStatus === "3" || project?.ProjectStatus == "7" ||project.PriceChange===3) {
      return true
    }
  }
  const handlemethodologyCintral=(e)=>{
   if ((tableData[editIndex]?.isAmendedit === undefined || tableData[editIndex]?.isAmendedit === true) ) {
    if (project?.id && handleToastr(tableData[editIndex]?.methodology ? tableData[editIndex]?.methodology : [], e)) {
      toastr.error("You cannot delete submethodology when costing is in progress");
      return;
    }
   }
    let temparr=modalData;
    temparr.methodology=e;
    setModalData(temparr);
    setMethodologyTableData(e);
  }

  const handleWaveNumber=(e)=>{
    let temparr=modalData
    temparr.WaveNumber=e.target.value
    setModalData(temparr)    
    setWaveNumber(e.target.value)
  }
  const rowStyle = {color:'red'};
  const handleStartDate=(e)=>{
    
    let temparr=modalData
    temparr.StartDate=e.target.value
    setStartDate(e.target.value)
    setModalData(temparr)
  }
  
  const handleEndDate=(e)=>{
    
    let temparr=modalData
    temparr.EndDate=e.target.value
    setModalData(temparr)
  }
  
  const addModal=()=>{
    
    
    let isValid = true;
    let isWBSValid = true;
    let updatedObj = {};
    const regex = /^YS\.[a-zA-Z0-9]{6}\.[a-zA-Z0-9]{4}\.[a-zA-Z0-9]{3}$/;
    const regexNew = /^XS\.[a-zA-Z0-9]{7}\.[a-zA-Z0-9]{3}$/;
    if(!(regex.test(modalData.WBSNumber) || regexNew.test(modalData.WBSNumber)) && modalData.WBSNumber){
      isWBSValid=false 
      updatedObj["WBSNumber"] = true;
    }
    if(modalData.methodology===undefined)
      {
       isValid=false 
       updatedObj["methodology"] = true;
      }
     
      if(modalData.MaterialID===undefined)
      {
        isValid=false 
        updatedObj["MaterialID"] = true;
      }
     if(modalData.methodologyType===undefined)
      {
        isValid=false 
        updatedObj["methodologyType"] = true;
      }
      // if(modalData.StartDate ===undefined)
      // {
      //   isValid=false 
      //   updatedObj["StartDate"] = true;
      // }
      // if(modalData.EndDate ===undefined)
      // {
      //   isValid=false 
      //   updatedObj["EndDate"] = true;
      // }
      setFieldInvalidationModal({ ...fieldInvalidationModal, ...updatedObj });
      if (!isValid) {
        toastr.error("Validation Errors", "Please check for missing Inputs");
        return;
      } else if(!isWBSValid) {
        toastr.error("Validation Errors", "Please check WBS Number format");
        return;
      }
    let temparr=tableData
    temparr.push(modalData)
    setMethodologyTableData(modalData.methodology);
    setTableData(temparr)
    setModalData({})
    toggle()
    let productarr=productList
    if(!productarr.includes(modalData.MaterialID))
    {
      productarr.push(modalData.MaterialID)
    }
    
    setProductList(productarr)
    let cintralarr=_.cloneDeep(tableData.map(t => t.methodology));
    console.log(cintralarr);
    cintralarr[editIndex]=modalData.methodology    
    let cintArray = [];
    cintralarr.map(c => {
     if(c!==undefined){
     c.forEach(cint => {
       if(cint!==undefined){
       cintArray.push(cint.label)
       }
     })
     }
     });

    setCintralmethod(cintArray)
    let cintralarruniq=[...new Set(cintArray)]
    cintralarruniq = cintralarruniq.filter(x => x !== undefined);

    setCintralmethodarr(cintralarruniq)

    let contractDetails=project.ContractDetails[0]
    

    contractDetails.opportunityLineItemDetails=temparr
    updateProject({
      // ContractDetails: [...project.ContractDetails, newOpp],
      ContractDetails:[ contractDetails]
    });

    let mCodes = null;
    let MethCodes = cintralarruniq.map(m => {
      let meth = codeLabels.SubMethodologyOptions.find(mo => mo.Label == m)
       return ({ label: meth?.Label, value: meth?.Code, isFixed: false })
    })
    let productCode = productarr.map(m => {
      return ({ label: m, value: m, isFixed: false })
    })
  updateCostingProfile({
    SubMethodology:MethCodes,
    ProductType: productCode,
    StudyType:productCode,
    Methodology:null
  })
  if(modalData.methodology!=undefined)
      {
        temparr[addIndex].Submethodology=modalData.methodology
      }
  dispatch(projectActions.setTableData(temparr))
  dispatch(projectActions.setOpportunityData(temparr))
    
  }
  const handleMSDRefresh=()=>{
    

    
    while(typeof costingProfile.OPPDatas === "string"){
      costingProfile.OPPDatas = JSON.parse(costingProfile.OPPDatas)
    }
    let opportunityData=costingProfile.OPPDatas

   

      // if (project.ContractDetails[0].updatedAt) {
      //   let lastUpdated =
      //     (new Date(moment().format("YYYY-MM-DD hh:mm:ss")).getTime() -
      //       new Date(project.ContractDetails[0].updatedAt).getTime()) /
      //     1000 /
      //     60;
      //   if (lastUpdated < 1)
      //     toastr.info("Already Updated", "Opportunity is already updated");
      //   else
      //     dispatch(
      //       projectActions.msdRefresh(project.id, project.ContractDetails[0], () => {
      //       })
      //     );
      // } else {
      //   dispatch(
      //     projectActions.msdRefresh(project.id, project.ContractDetails[0], () => {
      //     })
      //   );
      // }
      dispatch(
        projectActions.msdRefresh(project.id, codeLabels, project.ContractDetails[0], (oppline) => {
          
          let lineItems = oppline.filter(
            (lineId) => !opportunityData.some((line) => line.OpportunityLineItemID === lineId.OpportunityLineItemID)
          );
    
    
        
      
      if (lineItems?.length > 0){
    
        lineItems.map((item)=> opportunityData.push(item))
      // console.log("contractDetails",opportunityData);
    
      
    
    
      let temparr=tableData
    
      lineItems.map((item)=> temparr.push(item))
      setTableData(temparr)
      dispatch(projectActions.setTableData(temparr))
      dispatch(projectActions.setOpportunityData(opportunityData))
    
      
    
      let product= opportunityData.map(m => {
        return m.MaterialID
      })
      let prodRemoveDupl = [...new Set(product)]
      let productCode = prodRemoveDupl.map(m => {
        return ({ label: m, value: m, isFixed: false })
      })
      updateCostingProfile({
        ProductType: productCode,
        StudyType:productCode
      });
    
      }
      // console.log('tableData111',tableData)
      // console.log('project.ContractDetails[0].o',project.ContractDetails[0].opportunityLineItemDetails)
    //   let reees = [
    //     {
    //         "id": 33063,
    //         "GLAccount": "U&A & Market Sizing",
    //         "MaterialID": "CI-U A/MKT SIZING",
    //         "OpportunityLineItemID": "33756802-f1ba-4d53-9d4a-c92868004582",
    //         "PracticeArea": "CI\\CI",
    //         "ProductDescription": "CI-U A/MKT SIZING",
    //         "ProfitCentre": "Customized Research",
    //         "ProjectID": null,
    //         "SubBrand": "CI",
    //         "SubtotalUSD": null,
    //         "TotalPriceUSD": 24830,
    //         "WBSNumber": "J2.01.234",
    //         "StartDate": "2021-07-05T00:00:00.000Z",
    //         "EndDate": "2021-09-03T00:00:00.000Z",
    //         "createdAt": "2023-09-05T09:22:41.000Z",
    //         "updatedAt": "2023-09-05T09:22:41.000Z",
    //         "ContractDetailId": 84622,
    //         "CharacteristicValues": [
    //             {
    //                 "Z_MULTI_COUNTRY": [
    //                     "DOMESTIC"
    //                 ],
    //                 "Z_FIELDINGMETHOD": [
    //                     "CLT (SELF ADMINISTERED-ONLINE)"
    //                 ],
    //                 "Z_DATATYPE": [
    //                     "AD HOC"
    //                 ],
    //                 "id": 17480,
    //                 "createdAt": "2023-09-05T09:22:42.000Z",
    //                 "updatedAt": "2023-09-05T09:22:42.000Z",
    //                 "OpportunityLineItemDetailId": 33063
    //             }
    //         ]
    //     }
    // ]
    let tempAr = tableData
    
    // Updating table data with new msd response
    const tableDataRef = tempAr.map(temp => {
      const matchOppLineItem = oppline.filter(opp => opp.OpportunityLineItemID === temp.OpportunityLineItemID);

      if (matchOppLineItem.length > 0) {
        temp.WBSNumber = matchOppLineItem[0].WBSNumber;
        temp.MaterialID = matchOppLineItem[0].MaterialID;
        temp.ProductDescription = matchOppLineItem[0].ProductDescription;
        temp.StartDate = matchOppLineItem[0].StartDate;
        temp.EndDate = matchOppLineItem[0].EndDate;
        temp.CharacteristicValues.Z_FIELDINGMETHOD[0] = matchOppLineItem[0].CharacteristicValues.Z_FIELDINGMETHOD[0];
      }

      return temp;
    });
    // Updating OpportunityData with new msd response
    const updatedOppData = opportunityData.map(temp => {
      const matchOppLineItem = oppline.filter(opp => opp.OpportunityLineItemID === temp.OpportunityLineItemID);

      if (matchOppLineItem.length > 0) {
        temp.WBSNumber = matchOppLineItem[0].WBSNumber;
        temp.MaterialID = matchOppLineItem[0].MaterialID;
        temp.ProductDescription = matchOppLineItem[0].ProductDescription;
        temp.StartDate = matchOppLineItem[0].StartDate;
        temp.EndDate = matchOppLineItem[0].EndDate;
      }

      return temp;
    });
    // setting red colour object to identify deleted line items at MSD
    let updateTempAr = tableDataRef.map((row) => ({
      ...row,
      isRed : !oppline.some(line => line.OpportunityLineItemID === row.OpportunityLineItemID),
    }));
    let updatedOpportunityData = updatedOppData.map((row) => ({
      ...row,
      isRed : !oppline.some(line => line.OpportunityLineItemID === row.OpportunityLineItemID),
    })); 
    dispatch(projectActions.setOpportunityData(updatedOpportunityData))
    setTableData(updateTempAr)
    dispatch(projectActions.setTableData(updateTempAr));
    showText = updateTempAr.some(item => item?.isRed);
    if (showText) {
      toastr.info("Please check the product line item information from Project Detail Page, make sure the methodology assoication and cost option selection are updated with the newly added or deleted line item")
    }
      if (lineItems?.length === 0) {
        toastr.info("No new line items added");
      }
        })
  )
      
  

  }
  
  const clone=(index)=> {
    if (!(tableData[index].isRed)) {
      const newTable = [...tableData]
      let newOppData;
      if (oppData !== undefined && Object.keys(oppData).length > 0) {
        newOppData = _.cloneDeep(oppData);
      } else {
        while(typeof costingProfile.OPPDatas === "string"){
          costingProfile.OPPDatas = JSON.parse(costingProfile.OPPDatas)
        }
         newOppData=_.cloneDeep(costingProfile.OPPDatas);
      }
        
      const clonedRow = _.cloneDeep(tableData[index]);
      let templines = tableData.filter(opt => opt.OpportunityLineItemID === clonedRow.OpportunityLineItemID);
      let excludeM = templines.map((opt) => {return opt.methodology});
      clonedRow.excludeMeth = excludeM;
      delete clonedRow.methodology;
      clonedRow.isClone = true;
      const clonedOpp = _.cloneDeep(clonedRow);
      //clonedOpp.MSDMethodology = clonedOpp.CharacteristicValues.Z_FIELDINGMETHOD[0];
      newOppData.push(clonedOpp);
      newTable.push(clonedRow);
      // newTable[index].isClone = true;
      // newOppData[index].isClone = true;
      setTableData(newTable);
      dispatch(projectActions.setTableData(newTable));
      dispatch(projectActions.setOpportunityData(newOppData));
      toastr.info(`cloned row ${parseInt(index) +1} of the table`);
    } else {
      toastr.info(`Row is deleted from MSD. Cannot be cloned`);
    }

  }
  
  const handleCountryEdit=(e,index)=>{
    
    let checkarr=codeLabels.FieldingCountriesOptions.map((item)=>item.Label)
    if(checkarr.includes(e.target.innerHTML))
    {
      let temparr=costingMarketTable
      temparr[index].Countries=e.target.innerHTML
      setCostingMarketTable(temparr)
    }
    else
    {
      toastr.warning("Invalid country name")
      let temparr=costingMarketTable
      temparr[index].Countries=""
      setCostingMarketTable(temparr)
    }

  }


  const editModal=()=>{
    let temparr=tableData
    if(Object.keys(modalData).length > 0){
      if(modalData.methodology!=undefined)
      {
        temparr[editIndex].methodology=modalData.methodology
        temparr[editIndex].Submethodology=modalData.methodology
      }
      if(modalData.WaveNumber!=undefined)
      {
        temparr[editIndex].WaveNumber=modalData.WaveNumber
      }
      if(modalData.MaterialID!=undefined)
      {
        temparr[editIndex].MaterialID=modalData.MaterialID
      }
      if(modalData.WBSNumber!=undefined)
      {
        const regex = /^YS\.[a-zA-Z0-9]{6}\.[a-zA-Z0-9]{4}\.[a-zA-Z0-9]{3}$/;
        const regexNew = /^XS\.[a-zA-Z0-9]{7}\.[a-zA-Z0-9]{3}$/;
        if(regex.test(modalData.WBSNumber) || regexNew.test(modalData.WBSNumber) || !modalData.WBSNumber) {
          temparr[editIndex].WBSNumber = modalData.WBSNumber
        } else {
          toastr.error("Validation Errors", "Please check WBS Number format");
          return;
        }
      }
      if(modalData.ProductDescription!=undefined)
      {
        temparr[editIndex].ProductDescription=modalData.ProductDescription
      }
      if(modalData.methodologyType!=undefined)
      {
        temparr[editIndex].methodologyType=modalData.methodologyType
      }
      // if(modalData.StartDate !=undefined)
      // {
      //   temparr[editIndex].StartDate=modalData.StartDate
      // }
      // if(modalData.EndDate !=undefined)
      // {
      //   temparr[editIndex].EndDate=modalData.EndDate
      // }
    setTableData(temparr)
    setModalData({})
    toggle2()
    let contractDetails=project.ContractDetails[0]


    contractDetails.opportunityLineItemDetails=temparr
    updateProject({
      // ContractDetails: [...project.ContractDetails, newOpp],
      ContractDetails:[ contractDetails]
    });

    if(modalData.methodology!==undefined)
    {
    // let methodarr=cintralmethod
    // methodarr[editIndex]=modalData.methodology
    let cintralarr=_.cloneDeep(tableData.map(t => t.methodology));
    console.log(cintralarr);
    cintralarr[editIndex]=modalData.methodology    
    let cintArray = [];
    cintralarr.map(c => {
     if(c!==undefined){
     c.forEach(cint => {
       if(cint!==undefined){
       cintArray.push(cint.label)
       }
     })
     }
     });

    setCintralmethod(cintArray)
    let cintralarruniq=[...new Set(cintArray)]
    cintralarruniq = cintralarruniq.filter(x => x !== undefined);

    setCintralmethodarr(cintralarruniq)
    let MethCodes = cintralarruniq.map(m => {
      let meth = codeLabels.SubMethodologyOptions.find(mo => mo.Label == m)
       return ({ label: meth?.Label, value: meth?.Code, isFixed: false })
    })
    updateCostingProfile({
      SubMethodology: MethCodes, 
    });
    updateCostingProfile({
      Methodology:null,
    })
    }
    if(modalData.MaterialID!==undefined)
    {
      let prodarr=productList
    prodarr[editIndex]=modalData.MaterialID
    setProductList(prodarr)
    
    let productCode = prodarr.map(m => {
      return ({ label: m, value: m, isFixed: false })
    })
    
    updateCostingProfile({
      ProductType: productCode,
      StudyType:productCode

    })
    }
    
  dispatch(projectActions.setTableData(temparr))
  dispatch(projectActions.setOpportunityData(temparr))
    } else {
      toggle2()
    }
  }
 

  const customStyles = { multiValueRemove: (base) => ({ ...base, display: 'none' }) }


  const savemodal=()=>
  {
   let temparr=_.cloneDeep(tableData)
   console.log("tableData",oppData);
  //  modalData.methodology.forEach((x)=>{
  //   console.log(x);
  //   getMethdolologyData(codeLabels.SubMethodologyOptions,x.label);
  //  })
  // let subMethLabels=modalData.methodology.map((obj)=>obj.label);
   if(modalData.methodology!=undefined)
   {
   
    temparr[editIndex].methodology=modalData.methodology;
   }
   if(modalData.methodologyType!=undefined)
   {
    temparr[editIndex].methodologyType=modalData.methodologyType
   }
   
   if( modalData.WaveNumber!=undefined)
   {
    temparr[editIndex].WaveNumber=modalData.WaveNumber
   }
   else
   {
    temparr[editIndex].WaveNumber=""
   }
   if (modalData.methodology !== undefined && modalData.methodology !== null) {
    let tempobj = {};
    
    if(oppData !== undefined && Object.keys(oppData).length==0){
      while(typeof costingProfile.OPPDatas === "string"){
        costingProfile.OPPDatas = JSON.parse(costingProfile.OPPDatas)
      }
      tempobj=_.cloneDeep(costingProfile.OPPDatas);
    }else{
      if(project.ContractDetails[0]?.isUpdated == true){
        while(typeof costingProfile.OPPDatas === "string"){
          costingProfile.OPPDatas = JSON.parse(costingProfile.OPPDatas)
        }
        tempobj=_.cloneDeep(costingProfile.OPPDatas);
      }
      else{
        tempobj=oppData
      }
       
    }
    tempobj[editIndex].MSDMethodology=tempobj[editIndex].CharacteristicValues.Z_FIELDINGMETHOD[0]
    //tempobj[editIndex].CharacteristicValues.Z_FIELDINGMETHOD[0]=modalData.methodology;
    tempobj[editIndex].Submethodology=modalData.methodology;
    dispatch(projectActions.setOpportunityData(tempobj))
    console.log(" tempobj", tempobj);
   }

  //  let contractDetails=project.ContractDetails[0]
    

  //   contractDetails.opportunityLineItemDetails=tempobj
  //   updateProject({
  //     // ContractDetails: [...project.ContractDetails, newOpp],
  //     ContractDetails:[ contractDetails]
  //   });
  
    setTableData(temparr)
    dispatch(projectActions.setTableData(temparr));
    console.log("temparr",temparr);
 
   setModalData({})
   toggle()
   //modalData.methodology now contains sub-methodology values
   if(modalData.methodology!==undefined)
   {
  //old code
   let cintralarr=_.cloneDeep(tableData.map(t => t.methodology));
   console.log(cintralarr);
   cintralarr[editIndex]=modalData.methodology
   
   let cintArray = [];
   cintralarr.map(c => {
    if(c!==undefined){
    c.forEach(cint => {
      if(cint!==undefined){
      cintArray.push(cint.label)
      }
    })
    }
    });
   let cintralarruniq=[...new Set(cintArray)]
   cintralarruniq = cintralarruniq.filter(x => x !== undefined);
   
    setCintralmethod(cintArray)
    setCintralmethodarr(cintralarruniq)

  //new code

  // let cintralarr=tableData?.methodology.map(m => m.label);
  // console.log(cintralarr);
  // cintralarr[editIndex]=modalData.methodology
  // let cintralarruniq=[...new Set(cintralarr)]
  // cintralarruniq = cintralarruniq.filter(x => x !== undefined);
  //  setCintralmethod(cintralarr)
  //  setCintralmethodarr(cintralarruniq)

    //setting sub-methodologies to the table data
    let MethCodes = cintralarruniq.map(m => {
       let meth = codeLabels.SubMethodologyOptions.find(mo => mo.Label == m)
       return ({ label: meth?.Label, value: meth?.Code, isFixed: false })
    })
   
  //not using as part of sub-methodology changes

  // if (cintralarruniq) {
  //   let temp = _.cloneDeep(costingProfile.SubMethodology) || [];
  //   if (typeof temp == "string") {
  //     temp = []
  //     costingProfile.SubMethodology.split(",").map(m => {
  //       let meth = costingProfile.CountrySpecs[0].MethodologySpecs.find(mo => mo.Code == m)
  //       temp.push({ label: meth?.Label, value: m, isFixed: true })
  //     })
  //   }
    
    //  let tempSub = [];
    // cintralarruniq.forEach((x) => {
    //   let tempRes = getMultiOptionsWithDependency(
    //     codeLabels.MethodologyOptions,
    //     [x]
    //   );
    //   if (tempRes.length == 1) {
    //     tempSub.push(tempRes[0]);
    //   } else {
    //     tempRes.forEach((y) => {
    //       if (temp.find((x) => x.label == y.label)) tempSub.push(y);
    //     });
    //   }
    // });
    // setsubMethodologyData(tempSub)
    // updateCostingProfile({
      
    //   SubMethodology: tempSub, //[]
      
    // });
  //}
   updateCostingProfile({
      SubMethodology: MethCodes, 
    });
  updateCostingProfile({
    Methodology:null,
  })
  }
  }
  return (
    <React.Fragment>
      { oppValue === "" ?
        !project.IsSyndicated?
      <div>
        {/* <FormGroup>
        <Label className="h5">Study Type</Label>
        <Select
          className={
            fieldInvalidation.StudyType
              ? "react-select-container is-invalid"
              : "react-select-container"
          }
          classNamePrefix="react-select"
          options={getMultiOptions(codeLabels.StudyTypeOptions)}
          isMulti
          value={typeof costingProfile.StudyType == "string" ? studyTypeList() : costingProfile.StudyType}
          onChange={(e) => handleStudyTypeChange(e ?? [])}
          isDisabled={ProjectStatus()}
        />
        <FormFeedback>Please check study type selection.</FormFeedback>
      </FormGroup> */}
      <FormGroup>
        {/* <Label className="h5">Methodologies</Label>

        <br /> */}
        <Label>
          <a
          className={ project.ProjectStatus == "7" ?"no-actions":""}
            href="https://nielsenenterprise.sharepoint.com/:x:/s/CINTRALLibrary/EfD08KSRQy5Im2-wSfSvQ7YBPJECXHIVboGZARVTOWwGZQ?e=tHnijw&isSPOFile=1"
            target="_blank" rel="noreferrer"
          >
            View Mapping List
          </a>
        </Label>
        {/* <Select
          className={
            fieldInvalidation.Methodology
              ? "react-select-container is-invalid"
              : "react-select-container"
          }
          classNamePrefix="react-select"
          options={methodologyList}
          isMulti
          value={methodologyData ? methodologyData : null}
          isClearable={
            methodologyData && methodologyData.some((v) => !v.isFixed)
          }
          onChange={(e, option) => handleMethodologyChange(e, option)}
          isDisabled={ProjectStatus()}
        /> */}
        {/* checking delete methogology error msg */}
        {/* {toastDelete ? (
          <FormFeedback>Please check methodology selection.</FormFeedback>
        ) : (
          ""
        )} */}
      </FormGroup>
      </div>:
      <div>
        <div>
        <Button onClick={handleAddButton}  disabled={project.ProjectStatus=="8"}>Add</Button>
        <Table responsive hover striped bordered>
            <thead>
              <tr>
              <th>S.No</th>
                <th>ProductLine</th>
                <th>Wave Number</th>
                <th>WBS Number</th>
                <th>Material ID</th>
                <th>Product Description</th>
                <th>Methodology</th>
                <th>Methodology Type</th> 
                {/* <th>Fieldwork Start Date (MSD)</th>
                <th>Client Delivery Date (EOD) (MSD)</th> */}
              </tr>
            </thead>
            <tbody>
              
            {tableData.map((data,index)=>{
              if (typeof data.methodology === "string") {
                if(data.methodology.includes(","))
                {
                  const array = data.methodology.split(",")
                  data.methodology = array.map((arr)=>{
                    return ({label: arr, value: arr})
                  })
                } else {
                  let t = data.methodology;
                  data.methodology =  [{label: t, value: t}]
                }
              }
                  let methodology = data.methodology && data.methodology.map((m)=>m.label);
                  let  methodologyStr = methodology?.join("\r\n")
                  //let methodologyStr =methodology.map(entry => JSON.stringify(entry)).join("\n");
                  return <tr>
                  <td>{index+1}</td>
                  <td>{index+1}-{data.MaterialID}</td>
                  <td>{data.WaveNumber!=undefined?data.WaveNumber:""}</td>
                  <td>{data.WBSNumber}</td>
                  <td>{data.MaterialID}</td>
                  <td>{data.ProductDescription}</td>
                  <td style={{ whiteSpace: "pre-line" }}>{methodologyStr!=undefined?methodologyStr:""}</td>                
                  <td>{data.methodologyType!=undefined?data.methodologyType:""}</td>
                  {/* <td>{data.StartDate}</td>
                  <td>{data.EndDate}</td> */}
                  <td><Button disabled = {project.ProjectStatus == 3 || project.ProjectStatus == 9 || project.ProjectStatus == 11} onClick={()=>{toggle2();  setEditIndex(index);setMethodolodyValues(index); setMethodologyDataValues(index);setWaveNumber(data.WaveNumber);setInputValueWBS(data.WBSNumber)}}>Edit</Button></td>
                  </tr>
                })}
          
          </tbody>
          </Table>
        </div>
      {/* <FormGroup>
      <Label className="h5">Product Type</Label>
      <Select
        className={
          fieldInvalidation.StudyType
            ? "react-select-container is-invalid"
            : "react-select-container"
        }
        classNamePrefix="react-select"
        options={[]}
        isMulti
        //value={typeof costingProfile.StudyType == "string" ? studyTypeList() : costingProfile.StudyType}
        value={typeof costingProfile.ProductType === "string" ? productTypeList() : costingProfile.ProductType}        
        onChange={(e) => handleStudyTypeChange(e ?? [])}
        isDisabled={true}
        isClearable={false}
        styles={customStyles}
      />
      <FormFeedback>Please check Product type selection.</FormFeedback>
    </FormGroup> */}
    <FormGroup>
      {/* <Label className="h5">Methodologies</Label>

      <br /> */}
      <Label>
        <a
        className={ project.ProjectStatus == "7" ?"no-actions":""}
          href="https://nielsenenterprise.sharepoint.com/:x:/s/CINTRALLibrary/EfD08KSRQy5Im2-wSfSvQ7YBPJECXHIVboGZARVTOWwGZQ?e=tHnijw&isSPOFile=1"
          target="_blank" rel="noreferrer"
        >
          View Mapping List
        </a>
      </Label>
      {/* <Select
        className={
          fieldInvalidation.Methodology
            ? "react-select-container is-invalid"
            : "react-select-container"
        }
        classNamePrefix="react-select"
        options={[]}
        isMulti
        value={methodologyData ? methodologyData : null}
        // isClearable={
        //   methodologyData && methodologyData.some((v) => !v.isFixed)
        // }
        onChange={(e, option) => handleMethodologyChange(e, option)}
        isDisabled={true}
        isClearable={false}
        styles={customStyles}
      /> */}
      {/* checking delete methogology error msg */}
      {/* {toastDelete ? (
        <FormFeedback>Please check methodology selection.</FormFeedback>
      ) : (
        ""
      )} */}
    </FormGroup>
    <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add</ModalHeader>
        <ModalBody>
        
          <FormGroup>
            <Label>Wave Number</Label>
            <Input type="number" onChange={(e)=>handleWaveNumber(e)} />
          </FormGroup>
          <FormGroup>
            <Label>WBS Number</Label>
            <Input type="text" onChange={(e)=>handleWBSNumber(e)} value={inputValueWBS}/>
            {errorMessageWBS && 
              <p style={{color:"red"}}>
                <FontAwesomeIcon
                  icon={faThumbsDown}
                  color={"red"}
                  // title=""
                  className="align-middle mr-2"
                  size="sm"
                />
                {errorMessageWBS}
              </p>
            }
          </FormGroup>
          <FormGroup>
            <Label>Material ID</Label>
            <Select
        
              options={materialIDoptions}
              onChange={(e)=>handlematerialID(e)}
              
              className={
                fieldInvalidationModal.MaterialID
                  ? "react-select-container is-invalid"
                  : "react-select-container"
              }
              
            />
            
          </FormGroup>
          <FormGroup>
            <Label>Product Desc</Label>
            <h6>{ProductDescription}</h6>

          </FormGroup>
          <FormGroup>
            <Label>Methodology Type</Label>
            <Select
              
              options={methodoptions}
              onChange={(e)=>{handlemethodoptions(e)}}
              isDisabled={project.id!==null}
              className={
                fieldInvalidationModal.methodologyType
                  ? "react-select-container is-invalid"
                  : "react-select-container"
              }
            />
          </FormGroup>
          <FormGroup>
            <Label>Methodology</Label>
            <Select
              options={methodologiesOptions}
              isMulti
              onChange={(e)=>handlemethodologyCintral(e)}
              value={methodologyTableData && methodologyTableData?.length > 0 ? methodologyTableData :[null] }
              isDisabled={project.id!==null}
              className={
                fieldInvalidationModal.methodology
                  ? "react-select-container is-invalid"
                  : "react-select-container"
              }
            />
            
          </FormGroup>
          {/* <FormGroup>
            <Label>Fieldwork Start Date (MSD)</Label>
            <Input type="date" onChange={(e)=>handleStartDate(e)} min={new Date().toISOString().split("T")[0]} />
          </FormGroup> */}
          {/* <FormGroup>
            <Label>Client Delivery Date (EOD) (MSD)</Label>
            <Input type="date" onChange={(e)=>handleEndDate(e)} min={ startDate}/>
          </FormGroup> */}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={addModal}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    
    <div>
    {tableData.length && editIndex!=undefined && <Modal isOpen={modal2} toggle={toggle2}>
    <ModalHeader toggle={toggle2}>Edit</ModalHeader>
    <ModalBody>
    
      <FormGroup>
        <Label>Wave Number</Label>
        <Input type="number"  value = {WaveNumber} onChange={(e)=>handleWaveNumber(e)} isDisabled={project.ProjectStatus==="5"}/>
      </FormGroup>
      <FormGroup>
        <Label>WBS Number</Label>
        <Input type="text" onChange={(e)=>handleWBSNumber(e)} value={inputValueWBS}/>
        {errorMessageWBS && 
          <p style={{color:"red"}}>
            <FontAwesomeIcon
              icon={faThumbsDown}
              color={"red"}
              // title=""
              className="align-middle mr-2"
              size="sm"
            />
            {errorMessageWBS}
          </p>
        }
      </FormGroup>
      <FormGroup>
        <Label>Material ID</Label>
        <Select
          value={Object.keys(materialID).length > 0 ? materialID : setMaterialID({value: tableData[editIndex].MaterialID, label: tableData[editIndex].MaterialID})}
          options={materialIDoptions}
          onChange={(e)=>handlematerialID(e)}
          isDisabled={project.id!==null}
          
          
        />
      </FormGroup>
      <FormGroup>
        <Label>Product Desc</Label>
        <h6>{ProductDescription === "" ? tableData[editIndex].ProductDescription : ProductDescription}</h6>
      </FormGroup>
      <FormGroup>
        <Label>Methodology Type</Label>
        <Select
          value={Object.keys(methodologyType).length > 0 ? methodologyType : setMethodologyType({value: tableData[editIndex].methodologyType, label: tableData[editIndex].methodologyType})}
          options={methodoptions}
          onChange={(e)=>handlemethodoptions(e)}
          isDisabled={project.id!==null}
          
          
        />
      </FormGroup>
      <FormGroup>
        <Label>Methodology</Label>
        <Select
          //value={methodologyTableData && methodologyTableData?.length > 0 ? methodologyTableData : setMethodologyTableData(tableData[editIndex].methodology)}
          value={methodologyTableData && methodologyTableData?.length > 0 ? methodologyTableData :[null] }
          options={methodologiesOptions}
          onChange={(e)=>handlemethodologyCintral(e)}
          isDisabled={project.id!==null}
          isMulti
        />
        
      </FormGroup>
      {/* <FormGroup>
            <Label>Fieldwork Start Date (MSD)</Label>
            <Input type="date" value = {tableData[editIndex].StartDate} onChange={(e)=>handleStartDate(e)} disabled={project.id!==null}/>
          </FormGroup>
          <FormGroup>
            <Label>Client Delivery Date (EOD) (MSD)</Label>
            <Input type="date" value = {tableData[editIndex].EndDate} onChange={(e)=>handleEndDate(e)} disabled={project.id!==null}/>
          </FormGroup> */}
          <p style={{ color: "red", fontWeight:900 }}> The information entered cannot be edited after commission.</p> 
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={editModal}>
        Save
      </Button>{" "}
      <Button color="secondary" onClick={toggle2}>
        Cancel
      </Button>
    </ModalFooter>
  </Modal>}
  </div>
</div>

        : 
        <div>
          {project.ProjectId !== "" ? <Button disabled={!(tableData.some((t) => t?.isAmendedit === undefined || t?.isAmendedit === true))} onClick={handleMSDRefresh}>Refresh from MSD</Button> : null}

        <div>
        <Table responsive hover striped bordered>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Opportunity#</th>
                <th>ProductLine</th>
                <th>Wave Number</th>
                <th>WBS Number</th>
                <th>Material ID</th>
                <th>Product Description</th>
                <th>MSD Methodology</th>
                <th>Methodology</th>
                <th>Sub Project Type</th> 
                {/* <th>Fieldwork Start Date (MSD)</th>
                <th>Client Delivery Date (EOD) (MSD)</th> */}
              </tr>
            </thead>
           <tbody>

           {tableData.map((data,index)=>{
            if (typeof data.methodology === "string") {
              if(data.methodology.includes(","))
              {
                const array = data.methodology.split(",")
                data.methodology = array.map((arr)=>{
                  return ({label: arr, value: arr})
                })
              } else {
                let t = data.methodology;
                data.methodology =  [{label: t, value: t}]
              }
            }
                  let methodology = data.methodology && data.methodology.map((m)=>m.label);
                  // let methodology = data.methodology
                  let  methodologyStr = methodology?.join('\r\n')
                  return <tr style = {data?.isRed ? rowStyle : {}}>
                  <td>{index+1}</td>
                  <td>{oppValue}</td>
                  <td>{index+1}-{data.MaterialID}</td>
                  <td>{data.WaveNumber!=undefined?data.WaveNumber:""}</td>
                  <td>{data.WBSNumber}</td>
                  <td>{data.MaterialID}</td>
                  <td>{data.ProductDescription}</td>
                  <td>{data.CharacteristicValues.Z_FIELDINGMETHOD[0]}</td>
                  <td style={{ whiteSpace: "pre-line" }}>{methodologyStr!==undefined?methodologyStr:""}</td>
                  <td>{data.methodologyType!=undefined?data.methodologyType:""}</td>
                  {/* <td>{data.StartDate}</td>
                  <td>{data.EndDate}</td> */}
                  <td><Button disabled = {project.ProjectStatus == 3 || project.ProjectStatus == 9 || project.ProjectStatus == 11} onClick={ ()=>{toggle();  setEditIndex(index); setMethodolodyValues(index); setMethodologyDataValues(index);setWaveNumber(data.WaveNumber);setInputValueWBS(data.WBSNumber)}}>Edit</Button></td>
                  </tr>
                })}
            
            </tbody>
          </Table>
          
          {
          showText = tableData?.length > 0 ? tableData?.some(item => item?.isRed) : false
          }
           {showText && (
            <p className="h5">Red Coloured items in above table are removed from MSD. Please unselect CostingOptions related to the line items in red colour.</p>
          )}
        </div>
        {/* <FormGroup>
        <Label className="h5">Product</Label>
        <Select
          className={
            fieldInvalidation.ProductType
              ? "react-select-container is-invalid"
              : "react-select-container"
          }
          classNamePrefix="react-select"
          options={[]}
          isMulti
          value={typeof costingProfile.ProductType === "string" ? productTypeList() : costingProfile.ProductType}
          onChange={(e) => handleProductTypeChange(e ?? [])}
          isDisabled={true}
          isClearable={false}
          styles={customStyles}
        />
        <FormFeedback>Please check product type selection.</FormFeedback>
      </FormGroup> */}
      <FormGroup>
        {/* <Label className="h5">Methodologies</Label>

        <br /> */}
        <Label>
          <a
          className={ project.ProjectStatus == "7" ?"no-actions":""}
            href="https://nielsenenterprise.sharepoint.com/:x:/s/CINTRALLibrary/EfD08KSRQy5Im2-wSfSvQ7YBPJECXHIVboGZARVTOWwGZQ?e=tHnijw&isSPOFile=1"
            target="_blank" rel="noreferrer"
          >
            View Mapping List
          </a>
        </Label>
        {/* <Select
          className={
            fieldInvalidation.Methodology
              ? "react-select-container is-invalid"
              : "react-select-container"
          }
          classNamePrefix="react-select"
          options={[]}
          //options={cintralmethod.length === 0 ? methData() : cintralmethod.map(option => ({value: option, label: option}))}
          isMulti
          // value={cintralmethod.map(option => ({value: option, label: option}))}
          value={cintralmethodarr.length !== 0 ?cintralmethodarr.map(option => ({value: option, label: option})):methData()}
          // isClearable={
          //   methodologyData && methodologyData.some((v) => !v.isFixed)
          // }
          onChange={(e, option) => handleMethodologyChange(e, option)}
          isDisabled={true}
          isClearable={false}
          styles={customStyles}
        /> */}
        {/* checking delete methogology error msg */}
        {/* {toastDelete ? (
          <FormFeedback>Please check methodology selection.</FormFeedback>
        ) : (
          ""
        )}  */}
      </FormGroup>
      {tableData.length && editIndex!=undefined &&<Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit</ModalHeader>
        <ModalBody>
        <FormGroup>
            <Label>ID</Label>
            {/* <Input type="number" onChange={(e)=>handleWaveNumber(e)}/> */}
            <p>{tableData[editIndex].OpportunityLineItemID}</p>
          </FormGroup>
          <FormGroup>
            <Label>Wave Number</Label>
            <Input type="number"  value = {WaveNumber} onChange={(e)=>handleWaveNumber(e)}/>
          </FormGroup>
          <FormGroup>
            <Label>WBS Number</Label>
            <p>{tableData[editIndex].WBSNumber}</p>
          </FormGroup>
          <FormGroup>
            <Label>Material ID</Label>
            <p>{tableData[editIndex].MaterialID}</p>
          </FormGroup>
          <FormGroup>
            <Label>Product Desc</Label>
            <p>{tableData[editIndex].ProductDescription}</p>
          </FormGroup>
          <FormGroup>
            <Label>MSD Methodology</Label>
            <p>{tableData[editIndex].CharacteristicValues.Z_FIELDINGMETHOD[0]}</p>
          </FormGroup>
          <FormGroup>
            <Label>Sub Project Type</Label>
            {!(tableData[editIndex]?.isClone) ? <Select
              
              options={methodoptions}
              // value={methodologyType}
              onChange={(e)=>handlemethodoptions(e)}
              value={Object.keys(methodologyType).length > 0 ? methodologyType : setMethodologyType({value: tableData[editIndex].methodologyType, label: tableData[editIndex].methodologyType})}
              isDisabled={
                tableData[editIndex]?.methodology && tableData[editIndex]?.methodology.some((v) => v.isFixed)
              }   
            /> : 
            <>
            <p>{tableData[editIndex].methodologyType}</p>
            </>
            
            
            }
            
          </FormGroup>
          <FormGroup>
            <Label>Methodology</Label>
            <Select
              className={
                fieldInvalidation.SubMethodology
                  ? "react-select-container is-invalid"
                  : "react-select-container"
              }
              classNamePrefix="react-select"
              // value={methodologyTableData && methodologyTableData?.length > 0 ? methodologyTableData : setMethodologyTableData(tableData[editIndex]?.methodology?tableData[editIndex]?.methodology: [null] )}
              value={methodologyTableData && methodologyTableData?.length > 0 ? methodologyTableData :[null] }
              options={methodologiesOptions}
              onChange={(e)=>handlemethodologyCintral(e)}
              isClearable=
              {
                tableData[editIndex]?.methodology && tableData[editIndex]?.methodology.some((v) => !v.isFixed)
              }
              isMulti
            />
            
          </FormGroup>
          <p style={{ color: "red", fontWeight:900 }}> The information entered cannot be edited after commission.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={savemodal}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>}
      </div>
      
      }
      {/* {getMultiOptionsWithDependency(
        codeLabels.MethodologyOptions,
        project.Methodology
      )?.length !== 0 ? (
        <FormGroup>
          <Label className="h5">Sub-Methodologies</Label>
          <Select
          
            className={
              fieldInvalidation.SubMethodology
                ? "react-select-container is-invalid"
                : "react-select-container"
            }
            classNamePrefix="react-select"
            
            options={getMultiOptionsWithDependency(
              codeLabels.MethodologyOptions,
              cintralmethodarr
            )}
            isMulti
            value={subMethodologyData ? subMethodologyData : null}
            // value={
            //   costingProfile.SubMethodology
            //     ? costingProfile.SubMethodology
            //     : null
            // }
            isClearable={
              subMethodologyData && subMethodologyData.some((v) => !v.isFixed)
            }
            onChange={(e, option) => {
              handleSubMethodologyChange(e, option);
            }}
            isDisabled={ProjectStatus()}
          />
          <FormFeedback>Please check sub-Methodology selection.</FormFeedback>
        </FormGroup>
      ) : null} */}
      <CustomInput
        type="switch"
        id="multiCountrySwitch"
        name="customSwitch"
        label="Multi-Country Project"
        className="h5"
        checked={costingProfile.IsMultiCountry}
        onChange={(e) => handleMultiCountryChange(e.target.checked)}

        disabled={ProjectStatus()}


      />

      <Label className="h5">Fielding Markets</Label>
      <FormGroup>
        <Select
          className={
            fieldInvalidation.FieldingCountries
              ? "react-select-container is-invalid"
              : "react-select-container"
          }
          classNamePrefix="react-select"
          options={getMultiOptions(codeLabels.FieldingCountriesOptions)}
          isMulti
          isSearchable
          isDisabled={!costingProfile.IsMultiCountry || ProjectStatus()}
          value={typeof costingProfile.FieldingCountries == "string" ?
            fieldingCountriesList()
            : costingProfile.FieldingCountries}
          onChange={(e) => updateCostingProfile(e === undefined ? undefined : { FieldingCountries: e })}

        />
        <FormFeedback>
          Multi-Country projects must include at least two different countries
          (including commission country).
        </FormFeedback>
      </FormGroup>
      {!project.IsSyndicated && fieldingCountryValue !== null && fieldingCountryValue !== "" && 
      fieldingCountryValue !==undefined ? fieldingCountryValue.split(",").length>1 ? (
        <Label style={{ color: "Red" }}>
          The MSD fielding countries {fieldingCountryValue} were excluded
          because they are not found in CINTRAL. Should you need help on this,
          please contact the CINTRAL Support team. Thank you.{" "}
        </Label>
      ) : <Label style={{ color: "Red" }}>
      The MSD fielding country {fieldingCountryValue} is excluded
      because it is not found in CINTRAL. Should you need help on this,
      please contact the CINTRAL Support team. Thank you.{" "}
    </Label>: null}
      <div>
     <h5>Costing Markets</h5>
      <ModalBody>
        {costingMarketTable.length ? (
          <div>
            <Row className="mt-3 mb-3">
              <Col lg="3">
                <h5>Material ID</h5>
                <Select
                  classNamePrefix="react-select"
                  options={productOptions}
                  onChange={handleproductchange}
                  value={{ label: productValue }}
                />
              </Col>
              <Col lg="3">
                <h5>Costing Markets</h5>
                <Select
                  classNamePrefix="react-select"
                  options={getMultiOptions(codeLabels.FieldingCountriesOptions)}
                  isMulti
                  isSearchable
                  onChange={handleCountryChange}
                  value={countryList}
                />
              </Col>
              <Col lg="3">
                <h5>Function</h5>
                <Select
                  classNamePrefix="react-select"
                  options={functionOptions}
                  isMulti
                  isSearchable
                  onChange={handleFunctionChange}
                />
              </Col>
              <Col lg="1">
                <Button
                  style={{ position: "relative", top: "25px" }}
                  onClick={handleAddCountry}
                >
                  Add
                </Button>
              </Col>
            </Row>

            <Table responsive hover striped bordered>
              <thead>
                <tr>
                  <th>MaterialID</th>
                  <th>Sub Project Type</th>
                  <th>Parent WBS</th>
                  <th>Source</th>
                  <th>Costing Markets</th>
                  <th>Function</th>
                  <th>Remark</th>
                  <th>Child WBS</th>
                  <th>Selection</th>
                </tr>
              </thead>
              <tbody>
                {costingMarketTable.map((item, index) => {
                  return (
                    <tr>
                      <td style={{ fontWeight: 600 }}>{item.MaterialID}</td>
                      <td>{item.SubProjectType}</td>
                      <td>{item.ParentWBSNumber}</td>
                      <td>{item.Source}</td>
                      <td onBlur={(e) => handleCountryEdit(e, index)} contentEditable={item.Countries === ""? true: false}>{item.Countries}</td>
                      <td>{item.functions}</td>
                      <td>{item.SourceColumn}</td>
                      <td>
                        <input
                          style={
                            !item.isValid ? { border: "1px solid red" } : {}
                          }
                          type="text"
                          value={item.ChildWBSNumber}
                          onChange={(e) => handleChildWBSchange(e, index)}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={item.IsSelected}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <p>Please select countries in Timecost page</p>
        )}
      </ModalBody>
      
      
    
  
    
    </div>
      


    </React.Fragment>
  );
};

export default ProjectDetails;