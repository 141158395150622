import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "@alexcambose/react-infinite-scroll";
import axios from "../../axios-interceptor";
import Navbar from "../../components/Navbar";
import DashboardLayout from "../../layouts/Dashboard";
import RecordsSpinner from "../../components/RecordsSpinner";
import RecordsBadge from "../../components/RecordsBadge";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { getLabel } from "../../utils/codeLabels";
import moment from "moment";

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Table,
    Input,
    Label,
    CardHeader,
    CardTitle,
    CardBody,
    Container,
    Card,
    Row,
    Col,
} from "reactstrap";
import _ from "lodash";
import { pageLoadEnd, pageLoadStart } from "../../redux/actions/appActions";
import filterFactory, {
    selectFilter,
    textFilter,
} from "react-bootstrap-table2-filter";

import logo from "../../assets/img/avatars/modal-logo.png"; 
const FinanceLogs = ({ setShowLogs }) => {
    const dispatch = useDispatch();
    const [infiniteLoad, setInfiniteLoad] = useState(false);
    const comissioningCountries =
        useSelector(({ codeLabels }) => codeLabels.CommissioningCountriesOptions) ||
        [];
    const fieldingCountries =
        useSelector(({ codeLabels }) => codeLabels.FieldingCountriesOptions) || [];
    const verticals =
        useSelector(({ codeLabels }) => codeLabels.VerticalOptions) || [];
    const methodology =
        useSelector(({ codeLabels }) => codeLabels.MethodologyOptions) || [];
    const [record, setRecord] = useState({
        records: [],
        hasMore: true,
        totalItems: null,
    });
    const [selectColumns, openSelectColumns] = useState(false);
    const [allColumns] = useState(["Date Of Change",
        "Project ID",
        "Project Name",
        "Profile ID",
        "Wave Number",
        "Wave Name",
        "WBS Number",
        "Wave Methodology",
        "Wave Country",
        "Opportunity Number",
        "Commissioning Country",
        "User ID",
        "Total Project Value (USD)",
        "Total Project Value (USD) Updated",
        "Total Third Party Cost (USD)",
        "Total Third Party Cost (USD) Updated",
        "OOP%",
        "OOP% Updated",
        "Field Start Planned",
        "Field Start Updated",
        "Field Start Actual Planned",
        "Field Start Actual Updated",
        "Field End Planned",
        "Field End Updated",
        "Field End Actual Planned",
        "Field End Actual Updated",
        "Final Report Planned",
        "Final Report Updated",
        "Final Report Actual Planned",
        "Final Report Actual Updated"]);
    const [selectedDisplayColumns, setSelectedDisplayColumns] = useState(
        allColumns
    );
    const [selectedTempDisplayColumns, setSelectedTempDisplayColumns] = useState(
        allColumns
    );
    const [resetDisplayCols, setResetDisplayCols] = useState([]);

    useEffect(() => {
        dispatch(pageLoadStart());
        axios
            .post("/reports/finance-logs/all?limit=30")
            .then((res) => {
                dispatch(pageLoadEnd());
                //console.log(res);
                setRecord({
                    ...record,
                    records: res.data.items,
                    totalItems: res.data.totalItems,
                }); // change records to whatever array is named
            })
            .catch((err) => {
                // toastr.error("Loading Failed", err.data.message);
            });
    }, []);
    const loadAllFinances = () => {
        dispatch(pageLoadStart());
        axios
            .post("/reports/finance-logs/all")
            .then((res) => {
                dispatch(pageLoadEnd());
                //console.log(res);
                setRecord({
                    ...record,
                    records: res.data.items,
                    totalItems: res.data.totalItems,
                });
            })
            .catch((err) => {
                dispatch(pageLoadEnd());
                toastr.error("Loading Failed", err?.data?.message);
            });
    };
    useEffect(() => {
        //console.log("record has changed", record);
    }, [record]);

    const fetchMoreData = () => {
        //console.log("fetch more called");
        //console.log(record);
        if (record.records.length >= record.totalItems) {
            //console.log("set hasMore false");
            setRecord({ ...record, hasMore: false });
            return;
        }
        setInfiniteLoad(true);
        axios
            .post(
                "/reports/finance-logs/all?limit=30&offset=" + record.records.length
            )
            .then((res) => {
                //console.log(res);
                //console.log(
                //     "new array",
                //     update(record.records, { $push: res.data.items })
                // );
                setRecord({
                    ...record,
                    records: update(record.records, { $push: res.data.items }),
                    totalItems: res.data.totalItems,
                });
                setInfiniteLoad(false);
            })
            .catch((err) => {
                //console.log(err);
                // toastr.error("Loading Failed", err.data.message);
                setInfiniteLoad(false);
            });
    };
    const getCommaSeperatedData = (array, value) => {
        let output = [];
        if (value) {
            let list = value.split(",");
            for (let i of list) {
                let a = array.find((data) => data.Code === i);
                if (a) output.push(a.Label);
            }
            return output.join();
        } else return "";
    };
    const getCommissionCountries = (value) => {
        if (value) {
            let a = comissioningCountries.find((data) => data.Code === value);
            if (a) return a.Label;
            return "";
        } else return "";
    };
    const getContractDetails = (data, value) => {
        let output = [];
        if (data) {
            switch (value) {
                case "industry":
                    for (let contract of data) {
                        output.push(contract.Industry);
                    }
                    return output.join();
                case "practiceArea":
                    for (let contract of data) {
                        for (let oppLine of contract.opportunityLineItemDetails) {
                            output.push(oppLine.PracticeArea);
                        }
                    }
                    return output.join();
                case "opNumber":
                    for (let contract of data) {
                        output.push(contract.OpportunityNumber);
                    }
                    return output.join();
                case "prodDesc":
                    for (let contract of data) {
                        for (let oppLine of contract.opportunityLineItemDetails) {
                            output.push(oppLine.ProductDescription);
                        }
                    }
                    return output.join();
                // case "WBSNumber":
                //   for (let contract of data) {
                //     for (let oppLine of contract.opportunityLineItemDetails) {
                //       output.push(oppLine.WBSNumber);
                //     }
                //   }
                //   return output.join(",");
                case "method":
                    return getCommaSeperatedData(methodology, data);
                case "sfProb":
                    let res;
                    for (let contract of data) {
                        res = contract.Probability;
                    }
                    return res;
                default:
                    return "";
            }
        } else return "";
    };
    const formattedDate = (date, flag) => {
        if (flag === false) return "not required";
        if (date) return date.substring(0, 10);
        else return "not available";
    };
    const formattedPrec = (data) => {
        if (data) return (data * 100).toFixed(2) + "%";
        else return "not available";
    };
    const finalColumns = [
        {
            dataField: "updatedAt",
            text: "Date Of Change",
            sort: true,
            formatter: (cell, row) => <span>{row.updatedAt ? moment(row.updatedAt).format("YYYY-MM-DD") : "-"}</span>,
            filter: textFilter(),
        },
        {
            dataField: "ProjectId",
            text: "Project ID",
            sort: true,
            filter: textFilter(),
        },
        {
            dataField: "ProjectName",
            text: "Project Name",
            sort: true,
            filter: textFilter(),
        },
        {
            dataField: "ProfileId",
            text: "Profile ID",
            sort: true,
            filter: textFilter(),
        },
        {
            dataField: "WaveNumber",
            text: "Wave Number",
            sort: true,
            filter: textFilter(),
        },
        {
            dataField: "WaveName",
            text: "Wave Name",
            sort: true,
            filter: textFilter(),
        },
        {
            dataField: "WBSNumber",
            text: "WBS Number",
            sort: true,
            filter: textFilter(),
        },
        {
            dataField: "MethodologySpec",
            text: "Wave Methodology",
            sort: true,
            formatter: (cell, row) => {
              return <span>{row.MethodologySpec?.Label}</span>;
            },
            filter: textFilter(),
          },
          {
            dataField: "CountrySpec",
            text: "Wave Country",
            sort: true,
            formatter: (cell, row) => {
              return <span>{row.MethodologySpec?.CountrySpec?.CountryName}</span>;
            },
            filter: textFilter(),
          },
      
        {
            dataField: "OpportunityNumber",
            text: "Opportunity Number",
            sort: true,
            filter: textFilter(),
        },
        {
            dataField: "CommissioningCountry",
            text: "Commissioning Country",
            sort: true,

            filter: textFilter(),
        },
        {
            dataField: "CreatedBy",
            text: "User ID",
            sort: true,
            filter: textFilter(),
        },
        {
            dataField: "OldPriceToClient",
            text: "Total Project Value (USD)",
            sort: true,
            formatter: (cell, row) => <span>{row.OldPriceToClient ? row.OldPriceToClient.toFixed(2) : "-"}</span>,
            csvFormatter: (cell, row) => { return row.OldPriceToClient ? row.OldPriceToClient.toFixed(2) : "-" },
            filter: textFilter(),
        },
        {
            dataField: "PriceToClient",
            text: "Total Project Value (USD) Updated",
            sort: true,

            filter: textFilter(),
        },

        {
            dataField: "OldCostTotalExternalOperations",
            text: "Total Third Party Cost (USD)",
            sort: true,
            formatter: (cell, row) => <span>{row.OldCostTotalExternalOperations ? row.OldCostTotalExternalOperations.toFixed(2) : "-"}</span>,
            csvFormatter: (cell, row) => {
                return row.OldCostTotalExternalOperations ? row.OldCostTotalExternalOperations.toFixed(2) : "-"
            },
            filter: textFilter(),
        },
        {
            dataField: "CostTotalExternalOperations",
            text: "Total Third Party Cost (USD) Updated",
            sort: true,

            filter: textFilter(),
        },
        {
            dataField: "OldOutOfPocketCostPercent",
            text: "OOP%",
            sort: true,
            formatter: (cell, row) => <span>{row.OldOutOfPocketCostPercent
                ? formattedPrec(row.OldOutOfPocketCostPercent)
                : "-"}</span>,
            csvFormatter: (cell, row) => {
                return row.OldOutOfPocketCostPercent
                    ? formattedPrec(row.OldOutOfPocketCostPercent)
                    : "-"
            },
            filter: textFilter(),
        },
        {
            dataField: "OutOfPocketCostPercent",
            text: "OOP% Updated",
            sort: true,

            filter: textFilter(),
        },
        {
            dataField: "OldDateFieldStart",
            text: "Field Start Planned",
            sort: true,
            formatter: (cell, row) => <span>{row.OldDateFieldStart ? moment(row.OldDateFieldStart).format("YYYY-MM-DD") : "-"}</span>,
            filter: textFilter(),
        },
        {
            dataField: "NewDateFieldStart",
            text: "Field Start Updated",
            sort: true,
            formatter: (cell, row) => <span>{row.NewDateFieldStart ? moment(row.NewDateFieldStart).format("YYYY-MM-DD") : "-"}</span>,
            filter: textFilter(),
        },
        {
            dataField: "OldDateFieldStartActual",
            text: "Field Start Actual Planned",
            sort: true,
            formatter: (cell, row) => <span>{row.OldDateFieldStartActual ? moment(row.OldDateFieldStartActual).format("YYYY-MM-DD") : "-"}</span>,
            filter: textFilter(),
        },
        {
            dataField: "NewDateFieldStartActual",
            text: "Field Start Actual Updated",
            sort: true,
            formatter: (cell, row) => <span>{row.NewDateFieldStartActual ? moment(row.NewDateFieldStartActual).format("YYYY-MM-DD") : "-"}</span>,
            filter: textFilter(),
        },
        {
            dataField: "OldDateFieldEnd",
            text: "Field End Planned",
            sort: true,
            formatter: (cell, row) => <span>{row.OldDateFieldEnd ? moment(row.OldDateFieldEnd).format("YYYY-MM-DD") : "-"}</span>,
            filter: textFilter(),
        },
        {
            dataField: "NewDateFieldEnd",
            text: "Field End Updated",
            sort: true,
            formatter: (cell, row) => <span>{row.NewDateFieldEnd ? moment(row.NewDateFieldEnd).format("YYYY-MM-DD") : "-"}</span>,
            filter: textFilter(),
        },
        {
            dataField: "OldDateFieldEndActual",
            text: "Field End Actual Planned",
            sort: true,
            formatter: (cell, row) => <span>{row.OldDateFieldEndActual ? moment(row.OldDateFieldEndActual).format("YYYY-MM-DD") : "-"}</span>,
            filter: textFilter(),
        },
        {
            dataField: "NewDateFieldEndActual",
            text: "Field End Actual Updated",
            sort: true,
            formatter: (cell, row) => <span>{row.NewDateFieldEndActual ? moment(row.NewDateFieldEndActual).format("YYYY-MM-DD") : "-"}</span>,
            filter: textFilter(),
        },
        {
            dataField: "OldDateFinalReport",
            text: "Final Report Planned",
            sort: true,
            formatter: (cell, row) => <span>{row.OldDateFinalReport ? moment(row.OldDateFinalReport).format("YYYY-MM-DD") : "-"}</span>,
            filter: textFilter(),
        },
        {
            dataField: "NewDateFinalReport",
            text: "Final Report Updated",
            sort: true,
            formatter: (cell, row) => <span>{row.NewDateFinalReport ? moment(row.NewDateFinalReport).format("YYYY-MM-DD") : "-"}</span>,
            filter: textFilter(),
        },
        {
            dataField: "OldDateFinalReportActual",
            text: "Final Report Actual Planned",
            sort: true,
            formatter: (cell, row) => <span>{row.OldDateFinalReportActual ? moment(row.OldDateFinalReportActual).format("YYYY-MM-DD") : "-"}</span>,
            filter: textFilter(),
        },
        {
            dataField: "NewDateFinalReportActual",
            text: "Final Report Actual Updated",
            sort: true,
            formatter: (cell, row) => <span>{row.NewDateFinalReportActual ? moment(row.NewDateFinalReportActual).format("YYYY-MM-DD") : "-"}</span>,
            filter: textFilter(),
        }
    ];
    const [tableColumns, setTableColumns] = useState(finalColumns);

    useEffect(() => {
        if (selectedDisplayColumns) {
            dispatch(pageLoadStart());
            let columns = finalColumns
                .filter((col) => _.includes(selectedDisplayColumns, col.text))
                .map((col) => {
                    return { ...col };
                });
            setTableColumns([]);
            setTimeout(() => {
                setTableColumns([...columns]);
                dispatch(pageLoadEnd());
            });
        }
    }, [selectedDisplayColumns]);
    const applyLabels = (projects) => {
        let finalProjects = projects.map((proj) => {
            // proj.DateOfChange = proj.updatedAt
            proj.ProjectId = proj.WaveSpec.CostingProfile.Project.ProjectId
            proj.ProjectName = proj.WaveSpec.CostingProfile.Project.ProjectName
            proj.ProfileId = proj.WaveSpec.CostingProfile.id
            proj.WaveNumber = proj.WaveSpec.WaveNumber
            proj.WaveName = proj.WaveSpec.WaveName
            proj.OpportunityNumber =
                proj.WaveSpec.CostingProfile &&
                    proj.WaveSpec.CostingProfile.Project &&
                    proj.WaveSpec.CostingProfile.Project.ContractDetails
                    ? getContractDetails(
                        proj.WaveSpec.CostingProfile.Project.ContractDetails,
                        "opNumber"
                    )
                    : "";

            proj.WBSNumber =
                proj.WaveSpec.CostingProfile?.Project?.ContractDetails?.map((contract) => {
                    return contract?.opportunityLineItemDetails
                        .map((item) => {
                            return item.WBSNumber;
                        })
                        .filter(
                            (item, i, array) =>
                                item !== null && item !== "" && array.indexOf(item) === i
                        )
                        .join(",\n");
                })
                    .join(",\n")
                    .split(",\n")
                    .filter(
                        (item, i, array) =>
                            item !== null && item !== "" && array.indexOf(item) === i
                    )
                    .join(",\n");

            proj.CommissioningCountry =
                proj.WaveSpec.CostingProfile &&
                    proj.WaveSpec.CostingProfile.Project &&
                    proj.WaveSpec.CostingProfile.Project.CommissioningCountry
                    ? getCommissionCountries(
                        proj.WaveSpec.CostingProfile.Project.CommissioningCountry
                    )
                    : "";
            // proj.UserId = proj.CreatedBy

            proj.PriceToClient =
                proj.WaveSpec.CostingProfile && proj.WaveSpec.CostingProfile.PriceToClient
                    ? proj.WaveSpec.CostingProfile.PriceToClient.toFixed(2)
                    : "";
            proj.CostTotalExternalOperations =
                proj.WaveSpec.CostingProfile && proj.WaveSpec.CostingProfile.CostTotalExternalOperations
                    ? proj.WaveSpec.CostingProfile.CostTotalExternalOperations.toFixed(2)
                    : "";

            proj.OutOfPocketCostPercent =
                proj.WaveSpec.CostingProfile && proj.WaveSpec.CostingProfile.OutOfPocketCostPercent
                    ? formattedPrec(proj.WaveSpec.CostingProfile.OutOfPocketCostPercent)
                    : "";

            return proj;
        });
        return finalProjects;
    };
    const data = {
        tableColumns: [...tableColumns],
        tableData: applyLabels(record.records),
    };

    const ExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <div>
                <button className="btn btn-secondary mt-2" onClick={handleClick}>
                    Export
        </button>
            </div>
        );
    };
    var imgStyle = {
        width: "150px",
      };
    return (
        <div>
            <DashboardLayout
                navbar={<Navbar headerTitle="FINANCE LOGS" show={false} />}
            >
                <>
                    {/* {record.records ? ( */}
                    <InfiniteScroll
                        loadMore={fetchMoreData}
                        hasMore={record.hasMore}
                        isLoading={infiniteLoad}
                        loading={<RecordsSpinner />}
                        noMore={<RecordsBadge recordTypeLabel="records" />}
                        initialLoad={false}
                    >
                        <ToolkitProvider
                            keyField="id"
                            data={data.tableData}
                            columns={[...data.tableColumns]}
                            exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                        >
                            {(props) => (
                                // <Container fluid className="p-0">
                                <Card>
                                    <CardHeader>
                                        <div>
                                            <div className="float-left">
                                                <h2>
                                                    Showing {record.records?.length || 0} of{" "}
                                                    {record.totalItems || 0} Waves
                        </h2>
                                            </div>
                                            <Row className="justify-content-end">
                                                <Button color="secondary" className="mt-2 mr-2" onClick={() => setShowLogs(false)}>
                                                    Show Finance Dashboard
                                                </Button>
                                                <button
                                                    className="btn btn-secondary mt-2 mr-2"
                                                    onClick={() => {
                                                        openSelectColumns(!selectColumns);
                                                        setResetDisplayCols(selectedTempDisplayColumns);
                                                    }}
                                                >
                                                    Select Columns to display
                        </button>
                                                <ExportCSV {...props.csvProps} />
                                                <button
                                                    className="btn btn-secondary mt-2 ml-2"
                                                    onClick={() => loadAllFinances()}
                                                >
                                                    Load all finance logs
                        </button>
                                            </Row>
                                        </div>
                                    </CardHeader>
                                    <CardBody className="p-4">
                                        {data.tableColumns && data.tableColumns.length ? (
                                            <BootstrapTable
                                                classes="table-responsive"
                                                {...props.baseProps}
                                                bootstrap4
                                                bordered={true}
                                                striped
                                                hover
                                                condensed
                                                filter={filterFactory()}
                                                noDataIndication={<b>No data available</b>}
                                           />
                                        ) : null}
                                    </CardBody>
                                </Card>
                                //</Container>
                            )}
                        </ToolkitProvider>
                    </InfiniteScroll>
                </>
                {/* ) : null} */}
                <Modal
                    toggle={() => openSelectColumns(!selectColumns)}
                    isOpen={selectColumns}
                    centered={true}
                >
                    <ModalHeader toggle={() => openSelectColumns(!selectColumns)}>
                    {/* <img src={logo}  className="modallogo"/> */}
          </ModalHeader>
          <div>
                        <span className="modaltitle modaltitleDisplay">
                        Select Columns to display
                        </span>
                    </div>
                    <ModalBody>
                        <Row className="justify-content-end">
                            <a
                                className="select-link"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedTempDisplayColumns([...allColumns]);
                                }}
                            >
                                Select all
              </a>
                            <a
                                className="ml-2 mr-4 select-link"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedTempDisplayColumns([]);
                                }}
                            >
                                Deselect all
              </a>
                        </Row>
                        {allColumns.map((ac, index) => (
                            <Col lg="6" className="d-inline-flex">
                                <span>
                                    <input
                                        id={`column_${index}`}
                                        type="checkbox"
                                        checked={_.includes(selectedTempDisplayColumns, ac)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedTempDisplayColumns([
                                                    ...selectedTempDisplayColumns,
                                                    ac,
                                                ]);
                                            } else {
                                                setSelectedTempDisplayColumns([
                                                    ...selectedTempDisplayColumns.filter(
                                                        (sdc) => sdc != ac
                                                    ),
                                                ]);
                                            }
                                        }}
                                    />
                                    <label className="ml-2 pointer" for={`column_${index}`}>
                                        {ac}
                                    </label>
                                </span>
                            </Col>
                        ))}
                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Button
                                color="secondary"
                                size="sm"
                                onClick={() => {
                                    openSelectColumns(!selectColumns);
                                    setSelectedTempDisplayColumns([...resetDisplayCols]);
                                    setResetDisplayCols([]);
                                }}
                            >
                                Close
              </Button>
                            <Button
                                color="primary"
                                size="sm"
                                className="ml-2"
                                onClick={() => {
                                    setSelectedDisplayColumns([...selectedTempDisplayColumns]);
                                    openSelectColumns(!selectColumns);
                                    // setSelectedTempDisplayColumns([])
                                }}
                            >
                                Confirm
              </Button>
                        </Row>
                    </ModalFooter>
                </Modal>
            </DashboardLayout>
        </div>
    );
};

export default FinanceLogs;
