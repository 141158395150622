import React from "react";

function Open() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#000"
          d="M0 3h7.5v1.5h-6v18h18v-6H21V24H0V3zm7.5 11.379L9.62 16.5 21 5.121V10.5h3V0H13.5v3h5.379L7.5 14.379z"
        ></path>
      </g>
    </svg>
  );
}

export default Open;