import React, { useEffect, useState } from "react";
import { Card, CardTitle, Col, Row, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from "reactstrap";
import Select from "react-select";
import { toastr } from "react-redux-toastr";
import * as _ from "lodash";
import axios from "../../../axios-interceptor";
import Spinner from "../../../components/Spinner";
import FormPage from "./FormPage";
import update from 'immutability-helper';
import { useSelector } from "react-redux";
import logo from "../../../assets/img/avatars/modal-logo.png"; 
import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';
import * as EventName from '../../../utils/GoogleAnalyticsEvents';
import { UI_LOCATION } from "./ModalRFQ";


const DynamicRFQ  =(props) => {
    const gaEventTracker = useAnalyticsEventTracker(EventName.MarketSettings);

     let pacificData = _.head(props.codeLabels.PacificCountriesList).Label;
    // let AsianMarkets= _.head(props.codeLabels.AsiaPacificCountriesList).Label;
    //  console.log("hiiii");
    //  console.log("AsianMarkets",AsianMarkets);
 let arr = pacificData.split(',');
//  let arr = AsianMarkets.split(',');
var imgStyle = {
    width: "150px",
  };
   const [pacificMarkets] = useState(arr)
//  const [AsianPacificMarkets]= useState(arr)
    const [isBusy, setIsBusy] = useState(false);
    const [copyMasterSchema, setCopyMasterSchema] = useState(props.codeLabels.SubMethodologyOptions);
    const [changedItems,setChangedItems] = useState([])
    const [changedItemsSUD,setChangedItemsSUD] = useState([])

    const [selectedMethodologies,setSelectedMethodologies]=useState([])
    const [submethodologies, setSUBMethodologies] = useState([]);
    const [methodology, setMethodology] = useState({});
    const [RFQMasterSchema, setRFQMasterSchema] = useState({});
    const [SUDMasterSchema, setSUDMasterSchema] = useState({});
    const userRecord = useSelector(
        ({ user }) => user.userRecord
    );
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);
    const [copyCountryMethodologySchema, setcopyCountryMethodologySchema] = useState({});
    const [smCode, setSMCode] = useState("");
    const [modalConfirmMethodology, setModalConfirmMethodology] = useState(false);
    const [modalConfirmMethodologyToAllMarkets, setModalConfirmMethodologyToAllMarkets] = useState(false);

    const [modalMethodologiesState, setModalMethodologiesState] = useState(false);
    const [countriesList] = useState(userRecord?.Countries?.split(','));
    const [countriesData, setCountriesData] = useState({
        Countries: codeLabels.CommissioningCountriesOptions?.filter(cc => _.includes(countriesList, cc.Code))
            .map(cc => {
                return { value: cc.Code, label: cc.Label }
            })
    });
    useEffect(() => {
        if (_.isEmpty(methodology)) {
            const methodologiesToExclude = ['SM000024', 'SM000026', 'SM000029',"SM000038"];
            const t = copyMasterSchema.filter(wh => {
                if (!methodologiesToExclude.includes(wh.Code)) { return wh; }
            }).map(
                (opt) => {
                    return {
                        value: opt.Code,
                        label: opt.Label,
                    }
                });
            smDataHandler(t[0]);
            setSUBMethodologies(t)
        }
    }, [copyMasterSchema]);

    const onChangeSubMethodologyHandler = (data) => {
        smDataHandler(data);
    }

    const smDataHandler = (data) => {
        getCountryMethodologyByCode(data.value);
        setMethodology(data);
    }

    // fetch call for CountryMethodologySchema
    const getCountryMethodologyByCode = (smCode) => {
        setSMCode(smCode);
        var RFQ = {}, SUD = {};
        setIsBusy(true);
        axios.get(`/country-methodology-schema/${props.currentCountry.id}/${smCode}`, {
            headers: { "auth-token": localStorage.getItem("auth-token") },
        }).then((res) => {
            setIsBusy(false);
            const csmItem = { ...res.data.data };
            setcopyCountryMethodologySchema(csmItem);
            RFQ = {
                ...props.codeLabels.RFQMasterSchema[0],
                schema: JSON.parse(props.codeLabels.RFQMasterSchema[0].Label)
            }
            SUD = {
                ...props.codeLabels.SUDMasterSchema[0],
                schema: JSON.parse(props.codeLabels.SUDMasterSchema[0].Label)
            }
            // setRFQMasterSchema(schemaDataTransform(RFQ, csmItem.RFQSchema));
            // setSUDMasterSchema(schemaDataTransform(SUD, csmItem.SetUpSchema));
            setRFQMasterSchema(schemaDataTransformAsianCountry(RFQ, csmItem.RFQSchema));
            setSUDMasterSchema(schemaDataTransformAsianCountry(SUD, csmItem.SetUpSchema));
            
        }).catch((err) => {
            toastr.error("Retrieve Failed", err?.data?.message);
            setIsBusy(false);
        });
    }

    // data transform
    // const schemaDataTransform = (master, data) => {
      
    //  let _master= _.includes(pacificMarkets,props.currentCountry.Code)?Object.keys(master.schema.properties):Object.entries(master.schema.properties).filter(p=>!p[1].isPacific).map(s=>s[0])
    //  if (!_.includes(pacificMarkets,props.currentCountry.Code))
    //  {
    //     Object.entries(master.schema.properties).filter(p=>p[1].isPacific).map(s=>s[0]).forEach(prop=>{
    //         delete master.schema.properties[prop]
    //     })  
    //  }
    
    
     
    //  const diff = _.difference(_master, Object.keys(data.properties));
    //     const inter = _.intersection(_master, Object.keys(data.properties));
    //     if (inter.length > 0) {
    //         inter.forEach((P, ind) => {
    //             if (data.properties[P].defaultValue) {
    //                 master.schema.properties[P].defaultValue = data.properties[P].defaultValue;
    //             }
    //             if (master.Code === 'MasterRFQSchema') {
    //                 if (data.properties[P].isDefaultCostingOption) {
    //                     master.schema.properties[P].isDefaultCostingOption = data.properties[P].isDefaultCostingOption;
    //                 } else {
    //                     master.schema.properties[P].isDefaultCostingOption = false;
    //                 }
    //             }
    //             master.schema.properties[P].isShow = true;
    //             master.schema.properties[P].title = data.properties[P].title;
    //             if (master.schema.properties[P].enum) {
    //                 master.schema.properties[P].enum = data.properties[P].enum;
    //             }
    //             if (data.required.includes(P)) {
    //                 master.schema.properties[P].isRequired = true;
    //             } else {
    //                 master.schema.properties[P].isRequired = false;
    //             }
    //         })
    //     }
    //     if (diff.length > 0) {
    //         diff.forEach((P, ind) => {
    //             if (data.properties[P]) {
    //                 if (data.properties[P].defaultValue) {
    //                     master.schema.properties[P].defaultValue = data.properties[P].defaultValue;
    //                 }
    //                 if (master.Code === 'MasterRFQSchema') {
    //                     if (data.properties[P].isDefaultCostingOption) {
    //                         master.schema.properties[P].isDefaultCostingOption = data.properties[P].isDefaultCostingOption;
    //                     } else {
    //                         master.schema.properties[P].isDefaultCostingOption = false;
    //                     }
    //                 }
    //                 master.schema.properties[P].title = data.properties[P].title;
    //                 if (master.schema.properties[P].enum) {
    //                     master.schema.properties[P].enum = data.properties[P].enum;
    //                 }
    //             }
    //             master.schema.properties[P].isShow = false;
    //             master.schema.properties[P].isRequired = false;
    //         })
    //     }
    //     return master;
    // }

   
    //  data transform for asian markets

    const schemaDataTransformAsianCountry= (master, data) => {
      
        let _master= _.includes(pacificMarkets,props.currentCountry.Code)?Object.keys(master.schema.properties):Object.entries(master.schema.properties).filter(p=>!p[1].isPacific).map(s=>s[0])
        if (!_.includes(pacificMarkets,props.currentCountry.Code))
        {
           Object.entries(master.schema.properties).filter(p=>p[1].isPacific).map(s=>s[0]).forEach(prop=>{
               delete master.schema.properties[prop]
           })  
        }
       
       
        
        const diff = _.difference(_master, Object.keys(data.properties));
           const inter = _.intersection(_master, Object.keys(data.properties));
           if (inter.length > 0) {
               inter.forEach((P, ind) => {
                   if (data.properties[P].defaultValue) {
                       master.schema.properties[P].defaultValue = data.properties[P].defaultValue;
                   }
                   if (master.Code === 'MasterRFQSchema') {
                       if (data.properties[P].isDefaultCostingOption) {
                           master.schema.properties[P].isDefaultCostingOption = data.properties[P].isDefaultCostingOption;
                       } else {
                           master.schema.properties[P].isDefaultCostingOption = false;
                       }
                   }
                   master.schema.properties[P].isShow = true;
                   master.schema.properties[P].title = data.properties[P].title;
                   if (data.properties[P].ui_location) {
                       master.schema.properties[P].ui_location = data.properties[P].ui_location;
                   }
                   if (master.schema.properties[P].enum) {
                       master.schema.properties[P].enum = data.properties[P].enum;
                   }
                   if (data.required.includes(P)) {
                       master.schema.properties[P].isRequired = true;
                   } else {
                       master.schema.properties[P].isRequired = false;
                   }
               })
           }
           if (diff.length > 0) {
               diff.forEach((P, ind) => {
                   if (data.properties[P]) {
                       if (data.properties[P].defaultValue) {
                           master.schema.properties[P].defaultValue = data.properties[P].defaultValue;
                       }
                       if (master.Code === 'MasterRFQSchema') {
                           if (data.properties[P].isDefaultCostingOption) {
                               master.schema.properties[P].isDefaultCostingOption = data.properties[P].isDefaultCostingOption;
                           } else {
                               master.schema.properties[P].isDefaultCostingOption = false;
                           }
                       }
                       master.schema.properties[P].title = data.properties[P].title;
                       if (master.schema.properties[P].enum) {
                           master.schema.properties[P].enum = data.properties[P].enum;
                       }
                   }
                   master.schema.properties[P].isShow = false;
                   master.schema.properties[P].isRequired = false;
               })
           }
           return master;
       }

       const updateForm = (schema) => {
        if (schema.Code === 'MasterRFQSchema') {
            setRFQMasterSchema(schema);
        } else {
            setSUDMasterSchema(schema);
        }
    }


    // changed schema update
    const schemaUpdate = (masterSchema, type) => {
        var requiredArray = [];
        Object.keys(masterSchema.schema.properties).map((P, ind) => {
            if (masterSchema.schema.properties[P].isRequired) {
                requiredArray.push(P);
            }
            if (!masterSchema.schema.properties[P].isShow) {
                delete masterSchema.schema.properties[P];
            }
        });
        masterSchema.schema.required.forEach(P => {
            if (!requiredArray.includes(P)) {
                requiredArray = _.without(requiredArray, P);
            }
        });
        const updateSchema = update(copyCountryMethodologySchema, {
            [type]: {
                properties: { $set: masterSchema.schema.properties },
                required: { $set: requiredArray }
            }
        })
        return updateSchema[type];
    }

    // update CountryMethodologySschema
    const saveSchema = () => {
        const obj = {
            ...copyCountryMethodologySchema,
            RFQSchema: schemaUpdate(RFQMasterSchema, 'RFQSchema'),
            SetUpSchema: schemaUpdate(SUDMasterSchema, 'SetUpSchema'),
        };
        setIsBusy(true);
        axios.put(`/country-methodology-schema/${props.currentCountry.Code}/${methodology.value}`, obj, {
            headers: { "auth-token": localStorage.getItem("auth-token") },
        }).then((res) => {
            setIsBusy(false);
            getCountryMethodologyByCode(methodology.value);
            toastr.success("Save Success:", res?.data?.message);
        }).catch((err) => {
            toastr.error("Save Failed", err?.data?.message);
            setIsBusy(false);
        });
    }
    
    const copyToAllMethodologiesToAllMarkets = () => {
        const obj = {
            RFQSchema: schemaUpdate(RFQMasterSchema, 'RFQSchema'),
            SetUpSchema: schemaUpdate(SUDMasterSchema, 'SetUpSchema'),
            countriesData
        };
        setIsBusy(true);
        axios.put(`/country-methodology-schema/copyToAllMarkets/${props.currentCountry.Code}/${methodology.value}`, obj, {
            headers: { "auth-token": localStorage.getItem("auth-token") },
        }).then((res) => {
            setIsBusy(false);
            getCountryMethodologyByCode(methodology.value);
            toastr.success("Save Success:", res?.data?.message);
        }).catch((err) => {
            toastr.error("Save Failed", err?.data?.message);
            setIsBusy(false);
        });
        setModalConfirmMethodologyToAllMarkets(false);
    }
    const copyToAllMethodologies = () => {
        const obj = {
            RFQSchema: schemaUpdate(RFQMasterSchema, 'RFQSchema'),
            SetUpSchema: schemaUpdate(SUDMasterSchema, 'SetUpSchema'),
            changedItemsSUD,
            changedItems,
            selectedMethodologies
        };
        setIsBusy(true);
        axios.put(`/country-methodology-schema/copyToAll/${props.currentCountry.Code}/${methodology.value}`, obj, {
            headers: { "auth-token": localStorage.getItem("auth-token") },
        }).then((res) => {
            setIsBusy(false);
            getCountryMethodologyByCode(methodology.value);
            toastr.success("Save Success:", res?.data?.message);
        }).catch((err) => {
            toastr.error("Save Failed", err?.data?.message);
            setIsBusy(false);
        });
        setModalConfirmMethodology(false);
    }
    const onChange = (obj) => {
        let jsonBody = {
            ...countriesData,
            ...obj,
        };
        setCountriesData(jsonBody);
    };
    return (
        <Card>
            <Row>
                <Modal isOpen={modalConfirmMethodology} toggle={() => setModalConfirmMethodology(!modalConfirmMethodology)} centered size={"md"}>
                    <ModalHeader toggle={() => setModalConfirmMethodology(!modalConfirmMethodology)}>
                        {/* <img src={logo}  className="modallogo"/> */}
                    </ModalHeader>
                    <div>
                        <span className="modaltitle">
                        {`Confirm Copying Data`}
                        </span>
                    </div>
                    <ModalBody style={{ minHeight: '60px' }}>
                        <Label>{`You are about to copy the ${methodology.label} data to other methodologies. This action is irreversible. Are you sure want to proceed with copying data ?`}</Label>
                   <Select
                    options={
                        submethodologies
                    }
                isMulti
                isSearchable
                defaultValue=  {
                    submethodologies.filter(meth=>meth.value!=methodology.value)
                }
                onChange={(val) =>
                        {
                           
                            setSelectedMethodologies(val)
                }}
                   >

                   </Select>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {
                            
                            setSelectedMethodologies(submethodologies)
                            setChangedItems([])
                           setChangedItemsSUD([])
                            copyToAllMethodologies()
                        }}>
                            Yes
                        </Button>
                        <Button color="secondary" onClick={() => setModalConfirmMethodology(false)}>
                            No
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={modalConfirmMethodologyToAllMarkets} toggle={() => setModalConfirmMethodologyToAllMarkets(!modalConfirmMethodologyToAllMarkets)} centered size={"sm"}>
                    <ModalHeader toggle={() => setModalConfirmMethodologyToAllMarkets(!modalConfirmMethodologyToAllMarkets)}>
                        {/* <img src={logo}  className="modallogo"/> */}
                    </ModalHeader>
                    <div>
                        <span className="modaltitle modalcopyingdata">
                        {`Confirm Copying Data`}
                        </span>
                    </div>
                    <ModalBody style={{ minHeight: '60px' }}>
                        <Label>{`You are about to copy the ${methodology.label} data to other Market methodologies. This action is irreversible. Are you sure want to proceed with copying data ?`}</Label>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {
                            setChangedItems([])
                            setChangedItemsSUD([])
                            copyToAllMethodologiesToAllMarkets()

                        }}>
                            Yes
                        </Button>
                        <Button color="secondary" onClick={() => setModalConfirmMethodologyToAllMarkets(false)}>
                            No
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={modalMethodologiesState} toggle={() => setModalMethodologiesState(!modalMethodologiesState)} centered size={"xm"}>
                    <ModalHeader toggle={() => setModalMethodologiesState(!modalMethodologiesState)}>
                        {/* {`Copying ${methodology.label} Methodologies data to other applicable markets`} */}
					{/* <img src={logo}  className="modallogo"/> */}

                    </ModalHeader>
                    <div>
                        <span className="modaltitle">
                        {`Copying ${methodology.label} Methodologies data to other applicable markets`}
                        </span>
                    </div>
                    <ModalBody style={{ minHeight: '60px' }}>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Select Countries</Label>
                                    <Select
                                        options={codeLabels.CommissioningCountriesOptions.filter(cc => _.includes(countriesList, cc.Code))
                                            .map(cc => {
                                                return { value: cc.Code, label: cc.Label }
                                            })}
                                        isMulti
                                        isSearchable
                                        defaultValue={codeLabels.CommissioningCountriesOptions.filter(cc => _.includes(countriesList, cc.Code))
                                            .map(cc => {
                                                return { value: cc.Code, label: cc.Label }
                                            })}
                                        onChange={(val) =>
                                            onChange({
                                                Countries: val,
                                            })
                                        }

                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label> <strong>Note:</strong> Copying the information may change the data in selected markets.</Label>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {
                            setModalMethodologiesState(false);
                            setModalConfirmMethodologyToAllMarkets(true)
                        }}>
                            Yes
                        </Button>
                        <Button color="secondary" onClick={() => setModalMethodologiesState(false)}>
                            No
                        </Button>
                    </ModalFooter>
                </Modal>
                <Col md={8}>
                    <CardTitle className="p-2">Select the fields need for "Request for Quotation (RFQ)" form to {props.currentCountry.Label} </CardTitle>
                    <Label for="required-toggle" className="p-2">(Please toggle the button beside the field name to indicate the field required or not)</Label>
                </Col>
                <Col md={4}>
                    <Select
                        className="react-select-container mt-2 mr-2 mb-2"
                        classNamePrefix="react-select"
                        menuPortalTarget={document.querySelector('card-body')}
                        menuPosition={'static'}
                        options={
                           submethodologies
                        }
                        onChange={(select) =>{
                            onChangeSubMethodologyHandler(select)
                            setChangedItems([])
                           setChangedItemsSUD([])}
                        }
                        value={methodology}
                    />

                </Col>
            </Row>
            {
                (isBusy) ? <Row className="justify-content-center details-section-loading">
                    <Spinner size="small" color="#495057" /></Row> :
                    (!_.isEmpty(RFQMasterSchema) && !_.isEmpty(SUDMasterSchema)) ?
                        <>
                             <FormPage id="RFQMasterSchema" type="rfq" MasterSchema={RFQMasterSchema} initValues={props} changedItems={changedItems} setChangedItems={setChangedItems} updateForm={updateForm} smCode={smCode} />
                            <FormPage id="SUDMasterSchema" type="sud" MasterSchema={SUDMasterSchema} initValues={props} updateForm={updateForm} changedItems={changedItemsSUD} smCode={smCode} setChangedItems={setChangedItemsSUD}   />
                        </> : ''
            }
            <Row className="justify-content-end mb-2 mr-2">
                <Button color="info" className="mr-2 btn btn-primary" onClick={() => {
                    gaEventTracker(EventName.CopytoAllMarketsandSave);
                    setModalMethodologiesState(true)
                }}>
                    Copy to all Markets and Save
                </Button>
                <Button color="info" className="mr-2 btn btn-primary" onClick={() => {
                    gaEventTracker(EventName.Copytoallmethodologiesandsave);
                    setModalConfirmMethodology(true)
                    setSelectedMethodologies(submethodologies)
                }}>
                    Copy to all Methodologies and Save
                </Button>
                <button className="btn btn-primary" onClick={() => { gaEventTracker(EventName.SaveSchema);saveSchema() }}>Save Schema</button>
            </Row>
        </Card>

    );

}
export default DynamicRFQ;