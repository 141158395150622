import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter, multiSelectFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import CardBody from 'reactstrap/lib/CardBody';
import Select from "react-select";
import _ from "lodash";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  Card,
  // CardBody,
  Container,
  Collapse,
  CardHeader,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  InputGroup,
  InputGroupAddon,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
  CustomInput
} from "reactstrap";
import { useSelector } from 'react-redux';


function FinalCost({ project, cc_masters, FinalCostData, ViewOnlyMode }) {
  let [dataTemp, setTemp] = useState([])
  const [currentCurrencySelected, setCurrentCurrencySelected] = useState({})
  const currencies = useSelector(({ currentCosting }) =>
    currentCosting.currentCostingProfile.ProfileSetting &&
      currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      : []
  );
  ///*** */ to do ****
  // Neeed to pass project and cc masters to this componenet
  // Static data will be chnaged with the data from selected vendor data
  const Cdata = FinalCostData

  useEffect(() => {
    if (project?.CostingProfiles?.length) {
      let totalRows = []
      _.last(project?.CostingProfiles).CountrySpecs.map((cs, csi) =>
        cs.MethodologySpecs.filter(m => m.CostingType == 3).map((ms, msi) => {
          let temp = {}
          let selectedVendors = FinalCostData.filter(data => data.MethodologyID == ms.id && data.Country == cs.CountryName)
          temp.Country = cs.CountryName
          temp.Methodology = ms.Label
          temp.Vendor = selectedVendors.length ? _.uniq(selectedVendors.map(sv => sv.Vendor)).toString() : ""
          temp.SampleSize =
            //  selectedVendors.map(sv=> parseFloat(sv.AchivableSamples)).reduce((total, ss) => total + ss + 0, 0)
            (ms.CostingOptions?.filter(x => x.Selected).find(co => co.CostingOptionData.sampleSizeMain) ?
              ms.CostingOptions?.filter(x => x.Selected).map(co => parseFloat(co.CostingOptionData.sampleSizeMain)).reduce((total, ss) => total + ss + 0, 0) :
              ms.CostingOptions?.filter(x => x.Selected).map(co => parseFloat(co.CostingOptionData.numberOfGroup)).reduce((total, ss) => total + ss + 0, 0))
          temp.CostFromVendor = (selectedVendors.filter(a => a.Type != 'Total Internal Cost').map(sv => typeof sv.ConstantCostFromVendor == "string"
            ? parseFloat(sv.ConstantCostFromVendor).toFixed(2)
            : sv.ConstantCostFromVendor).filter(cost => cost).reduce((total, cv) => parseFloat(total) + parseFloat(cv) + 0, 0))
          temp.ConstantCostFromVendor = parseFloat(temp.CostFromVendor).toFixed(2)
          temp.CPI = _.round((parseFloat(temp.CostFromVendor) / temp.SampleSize), 2)
          temp.CPIusd = _.round((parseFloat(temp.ConstantCostFromVendor) / temp.SampleSize), 2)
          temp.key = msi
          let TotalInternalCost = selectedVendors.filter(sv => sv.Type == "Total Internal Cost").length ?
            _.round(selectedVendors.filter(sv => sv.Type == "Total Internal Cost").map(k => k.TotalCostusd).reduce((total, ic) => parseFloat(total) + parseFloat(ic) + 0, 0), 2)
            : 0
          temp.TotalInternalCost = isNaN(TotalInternalCost) ? 0 : parseFloat(TotalInternalCost).toFixed(2)
          temp.CostFromVendor = typeof temp.CostFromVendor == "string" ? parseFloat(temp.CostFromVendor) : temp.CostFromVendor
          //  temp.TotalInternalCost= typeof temp.TotalInternalCost =="string" ?parseFloat(temp.TotalInternalCost):temp.TotalInternalCost
          temp.TotalCostusd = isNaN(TotalInternalCost + temp.CostFromVendor) ? 0 : (parseFloat(temp.TotalInternalCost) + parseFloat(temp.CostFromVendor)).toFixed(2)
          temp.FinalCPIusd = isNaN(temp.TotalCostusd / temp.SampleSize) ? 0 : (parseFloat(temp.TotalCostusd) / parseFloat(temp.SampleSize)).toFixed(2)
          temp.CurrencyChangeTotalInternalCost = temp.TotalInternalCost
          temp.CurrencyChangeTotalCostusd = temp.TotalCostusd
          temp.CurrencyChangeFinalCPIusd = temp.FinalCPIusd

          totalRows.push(temp)

          //console.log(temp)
        })
      )
      setTemp(totalRows)
      setFinalCostData(totalRows)

    }
  }, [])
  const [finalCostData, setFinalCostData] = useState(Cdata)
  const [country, setCountry] = useState([])
  const [methodology, setMethodology] = useState([])
  function getProfileSelectOptions1(cell) {
    let values = [];
    if (Cdata.length > 0)
      values = Array.from(
        new Set(Cdata.filter(c => c.Type != "Internal Cost").map((x) => x[cell.dataField]))
        // new Set(project["data"].map((x) => x[cell.dataField]))
      );
    // const selectOptions = {};
    // values.forEach((x, i) => {
    //   selectOptions[x]=x
    // });

    const selectOptions = [];
    values.forEach((x, i) => {
      selectOptions.push({ label: x, value: x, id: i })
    })
    return selectOptions;
  }

  const tableColumnsFinalCostSummary = [
    {


      dataField: "Country",
      text: "Market",
      sort: true,
      align: 'center',

      editable: false,


      filter: customFilter(),
      filterRenderer: (onFilter, column) =>
        <Select
          name="CountryFIlter"
          isDisabled={ViewOnlyMode}
          onChange={(e) => {
            setCountry(e)
            if (e && e.length) {

              let filterLabels = e.map(filter1 => filter1.label)

              let filterarr = finalCostData.filter(f => _.uniq(finalCostData.map(q => q.Methodology)).includes(f.Methodology))

                .filter(m => filterLabels.includes(m.Country))
              setFinalCostData(_.sortBy(filterarr, "uniquekey"))


            }

            else {
              if ((methodology && methodology.length)) {
                let arr1 = []


                arr1 = (methodology && methodology.length) ?
                  finalCostData.length == 0 ?
                    finalCostData.filter(f => _.uniq(methodology.map(q => q.label)).includes(f.Methodology))
                    : finalCostData.filter(f => _.uniq(finalCostData.map(q => q.Methodology)).includes(f.Methodology))
                  : finalCostData.filter(f => _.uniq(finalCostData.map(q => q.Methodology)).includes(f.Methodology))



                setFinalCostData(arr1)
              }

              else {
                if (dataTemp)
                  setFinalCostData(dataTemp)
              }
            }
          }}
          options={getProfileSelectOptions1({ dataField: "Country" })}
          // options={[]}
          isMulti
          value={country}
        ></Select>,
      footer: 'Total Cost',
      footerStyle: {
        color: "blue",
      }

    },
    {
      dataField: "Methodology",
      text: "Methodology",
      sort: true,
      align: 'center',

      editable: false,
      filter: customFilter(),
      filterRenderer: (onFilter, column) =>
        <Select
          name="CountryFIlter"
          isDisabled={ViewOnlyMode}
          onChange={(e) => {
            setMethodology(e)
            if (e && e.length) {
              let filterLabels = e.map(filter1 => filter1.label)

              let filterarr = finalCostData.filter(f => _.uniq(finalCostData.map(q => q.Country)).includes(f.Country)).filter(m => filterLabels.includes(m.Methodology))

              setFinalCostData(_.sortBy(filterarr, "uniquekey"))




            }
            else {
              if ((country && country.length)) {
                let arr1 = []

                arr1 = (country && country.length) ?
                  finalCostData.length == 0 ?
                    finalCostData.filter(f => _.uniq(country.map(q => q.label)).includes(f.Country))
                    : finalCostData.filter(f => _.uniq(finalCostData.map(q => q.Country)).includes(f.Country))
                  : finalCostData.filter(f => _.uniq(finalCostData.map(q => q.Country)).includes(f.Country))




                setFinalCostData(arr1)
              }

              else {
                if (dataTemp)
                  setFinalCostData(dataTemp)
              }
            }
          }}
          options={getProfileSelectOptions1({ dataField: "Methodology" })}
          // options={[]}
          isMulti
        ></Select>
      ,
      footer: ''

    },
    {
      dataField: "Vendor",
      text: "Vendor Name",
      align: 'center',
      sort: true,
      footer: ''


    },


    {
      dataField: "SampleSize",
      text: "Total Sample Size/ Groups",
      editable: false,
      align: 'center',
      footer: ''
      // footer: columnData => (columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0)),
      // footerAlign: (column, colIndex) => 'center',

      // footerStyle: {
      //   color: "blue",
      //   textAlign: "left"

      // }
    },

    {
      dataField: "ConstantCostFromVendor",
      text: "Total Vendor Cost (USD)",
      editable: false,
      footer: columnData => (columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0)).toFixed(2),
      footerAlign: (column, colIndex) => 'center',
      align: 'center',
      footerStyle: {
        color: "blue",
        textAlign: "left"
      }
    },
    {
      dataField: "CPIusd",
      text: "CPI Vendor",
      //   formatter: CPIFormatter,
      sort: true,
      editable: false,
      align: 'center',
      footer: columnData => (columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0)).toFixed(2),
      footerAlign: (column, colIndex) => 'center',

      footerStyle: {
        color: "blue",
        textAlign: "left"
      }
    },
    {
      dataField: "TotalInternalCost",
      text: "Additional Cost Internal (USD)",
      sort: true,
      editable: false,
      align: 'center',
      footer: columnData => (columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0)).toFixed(2),
      footerAlign: (column, colIndex) => 'center',

      footerStyle: {
        color: "blue",
        textAlign: "left"
      }
    },

    {
      dataField: "key",
      text: "key",
      sort: true,

      hidden: true,
    },
    {
      dataField: "TotalCostusd",
      text: "Total Cost (USD)",
      sort: true,
      editable: false,
      footer: columnData => (columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0)).toFixed(2),
      footerAlign: (column, colIndex) => 'center',
      align: 'center',
      footerStyle: {
        color: "blue",
        textAlign: "left"
      }
    },

    {
      dataField: "FinalCPIusd",
      text: "Final CPI (USD)",
      editable: false,
      align: 'center',
      footer: columnData => (columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0)).toFixed(2),
      footerAlign: (column, colIndex) => 'center',

      footerStyle: {
        color: "blue",
        textAlign: "left"
      }
    },
    {
      dataField: "CurrencyChangeTotalInternalCost",
      text: `Additional Cost Internal (${currentCurrencySelected.CurrencyUnit ? currentCurrencySelected.CurrencyUnit : "USD"})`,
      sort: true,
      editable: false,
      align: 'center',
      footer: columnData => (columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0)).toFixed(2),
      footerAlign: (column, colIndex) => 'center',

      footerStyle: {
        color: "blue",
        textAlign: "left"
      }
    },
    {
      dataField: "CurrencyChangeTotalCostusd",
      text: `Total Cost (${currentCurrencySelected.CurrencyUnit ? currentCurrencySelected.CurrencyUnit : "USD"})`,
      sort: true,
      editable: false,
      footer: columnData => (columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0)).toFixed(2),
      footerAlign: (column, colIndex) => 'center',
      align: 'center',
      footerStyle: {
        color: "blue",
        textAlign: "left"
      }
    },

    {
      dataField: "CurrencyChangeFinalCPIusd",
      text: `Final CPI (${currentCurrencySelected.CurrencyUnit ? currentCurrencySelected.CurrencyUnit : "USD"})`,
      editable: false,
      align: 'center',
      footer: columnData => (columnData.reduce((acc, item) => parseFloat(acc) + parseFloat(item == undefined ? 0 : item), 0)).toFixed(2),
      footerAlign: (column, colIndex) => 'center',

      footerStyle: {
        color: "blue",
        textAlign: "left"
      }
    },
  ];

  function optionsCurrency() {
    let tempCurrency = currencies?.filter(c => {
      if (_.uniq(finalCostData?.map(c => c.Country)).find(a => a == c.Label)) {
        return c
      }
    })
    if (!tempCurrency.some(s => s.CurrencyUnit == "USD")) {
      let usdCurr = {
        id: 0,
        Code: "US",
        Label: "United States",
        CurrencyUnit: "USD",
        AllCurrencyUnit: "USD",
        ConversionRateToLocal: 1
      }
      tempCurrency.push(usdCurr)
    }
    let res = tempCurrency?.map((c) => {
      return {
        ...c,
        value: `${c.Code}-${c.CurrencyUnit}`,
        label: `${c.Label} (1USD = ${c.ConversionRateToLocal}${c.CurrencyUnit})`,
      };
    })
    return res
  }
  function onCurrencyChange(val) {
    setCurrentCurrencySelected(val);
    let tempfinalCostData = finalCostData
    tempfinalCostData = tempfinalCostData.map(data => {
      return {
        ...data,
        CurrencyChangeTotalInternalCost: parseFloat(data.TotalInternalCost * val.ConversionRateToLocal).toFixed(2),
        CurrencyChangeTotalCostusd: parseFloat(data.TotalCostusd * val.ConversionRateToLocal).toFixed(2),
        CurrencyChangeFinalCPIusd: parseFloat(data.FinalCPIusd * val.ConversionRateToLocal).toFixed(2),
      }
    })
    setFinalCostData(tempfinalCostData)
  }

  return (
    <>
      <Card className="ml-2 mr-2 mb-2">
        <CardHeader>
          <Row className="d-flex justify-content-between">
            <Col
              xs="12"
              lg="3"
              md="3"
              sm="12"
              className="align-self-center mt-1 mb-1"
            >
            </Col>
            <Col
              xs="12"
              lg="9"
              md="9"
              sm="12"
              className="align-self-center"
            >
              <Row className="d-flex justify-content-end">
                <Col
                  xs="12"
                  lg="6"
                  md="6"
                  sm="12"
                  className="align-self-center mt-1 mb-1"
                >
                  <Label className="h5">Available Currency</Label>
                  <Select
                    placeholder="Select a Currency..."
                    options={optionsCurrency()}
                    isDisabled={ViewOnlyMode}
                    defaultValue={optionsCurrency().filter(f => f.CurrencyUnit == "USD").length > 0 ?
                      optionsCurrency().filter(f => f.CurrencyUnit == "USD")[0]
                      : []}
                    onChange={(val) => onCurrencyChange(val)}
                  />
                </Col>

              </Row>

              <Row className="d-flex justify-content-end">
                <Col
                  xs="12"
                  lg="6"
                  md="6"
                  sm="12"
                  className="align-self-center mt-1 mb-1">

                </Col>

                <Col
                  xs="12"
                  lg="2"
                  md="2"
                  sm="12"
                  className="align-self-center mt-1 mb-1"
                >
                </Col>
              </Row>
            </Col>
          </Row>
        </CardHeader>
      </Card>
      {finalCostData.length > 0 && FinalCostData.length > 0 && <ToolkitProvider
        keyField="index"
        data={finalCostData}
        // data={dataTemp}
        columns={tableColumnsFinalCostSummary}

      >
        {(props) => (
          <CardBody className="bg-white">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4
              wrapperClasses="table-responsive"
              bordered={true}
              filter={filterFactory()}
              filterPosition="top"


            />
          </CardBody>
        )}

      </ToolkitProvider>}


    </>
  )
    ;
}

export default FinalCost;