import React from "react";
import filterFactory, {
    textFilter, dateFilter, Comparator
} from "react-bootstrap-table2-filter";
import _ from "lodash";

export const reportColumns = [
    "ID",
    "Project Delivery Number",
    "ModuleID",
    "Module Name",
    "Profile Id",
    "Project Id",
    "WBS Number",
    "Opportunity Number",
    "Project Name",
    "Proposal Owner",
    "Other CS Contacts",
    "Commissioning Country",
    "Commissioning Office",
    "Business Unit",
    "Industry Vertical",
    "Fielding Countries",
    "Methodology",
    "Syndicated",
    "Status",
    "Tracker",
    "Tracking Frequency",
    "Number Of Waves",
    "Wave Methodology",
    "Wave Country",

    "Date Wave Commissioned",
    "Date Final Questionnaire",
    "Date Translations",
    "Date Field Start",
    "Date Field End",
    "Planned FW End Date",
    "Planned FW Start Date",
    "Date Data Processing",
    "Date Charts",
    "Date Dashboards",
    "Date Final Report",

    "survey Programming Required",
    "survey Programming Complexity",
    "survey Programming Number Of Questions",
    "survey Programming Percentage Change Per Wave",
    "survey Programming Involve Fielding Market",
    "data Processing Complexity",
    "charting Complexity",
    "charting Number",
    "dashboarding",
    "translations",
    "translations Languages",
    "verbatim Coding",
    "verbatim Coding Full Open Ended",
    "verbatim Coding Other Specify",
    "text Analytics",
    "other Data Preparation Assistance",
    "other Data PreparationAssistance Hours",
    "additional Operations Support",
    "additional Operations Support Hours",

    "TCSProgrammerAssigned",
    "TCSGroupLeadName",
    "TCSInitialSetupCTDays",
    "TCSComplexityLevel",
    "TCSActualDateFinalQuestionnaireToProgramming",
    "TCSActualDateProgrammingStart",
    "TCSDateFirstTestLinkSent",
    "TCSChangesBeforeInitialLinkDelivery",
    "TCSDateLiveLinkProvided",
    "TCSActualStartFieldDate",
    "TCSNumberOfQuestions",
    "TCSChangesToUniqueQuestions",
    "TCSNumberOfPids",
    "TCSChangesAfterInitialLinkDelivery",
    "TCSNumberOfIterations",
    "TCSErrorsInInitialLinkDelivery",
    "TCSErrorsAfterInitialLinkDelivery",
    "TCSJobCount",
    "TCSActualFieldEndDate",
    "TCSActualDeliveryToGODate",
    "TCSPlatformProjectId",
    "TCSChangesAfterLiveLinkCreated",
    "TCSErrorsAfterLiveLinkCreated",
    "TCSTotalNumberOfIterations",
    "TCSActualLOI",
    "TCSTotalCompletes",
    "TCSCancelledDate",
    "TCSReasonForCancellation",
    "TCSPlatform",
    "TCSDataCollection",
    "TCSCostCentre",
    "TCSWaveStatus",
    "TCSDataCollectionOther",
    "TCSChangesToUniqueQuestionsNA",
    "TCSLiveSurveyProjectId",
    "TCSTestSurveyProjectId",

    "TCSIsInternalDelivery",
    "TCSIsDecommissionedFixed",
]

const getDates = () => {
    const fields = {
        DateWaveCommissioned: "Date Wave Commissioned",
        DateFinalQuestionnaire: "Date Final Questionnaire",
        DateFinalQuestionnaireActual:
            "TCSActualDateFinalQuestionnaireToProgramming",
        DateProgrammingStartActual: "TCSActualDateProgrammingStart",
        DateTranslations: "Date Translations",
        DateFieldStart: "Planned FW Start Date",
        DateFieldStartActual: "TCSActualStartFieldDate",
        DateFieldEnd: "Planned FW End Date",
        DateFieldEndActual: "TCSActualFieldEndDate",
        DateDeliveryToGOActual: "TCSActualDeliveryToGODate",
        DateDataProcessing: "Date Data Processing",
        DateCharts: "Date Charts",
        DateDashboards: "Date Dashboards",
        DateFinalReport: "Date Final Report",
        DateFirstTestLinkActual: "TCSDateFirstTestLinkSent",
        DateLiveLinkActual: "TCSDateLiveLinkProvided",
    };

    return Object.keys(fields).map((key) => {
        return {
            dataField: key,
            text: fields[key],
            sort: true,
            // filter: textFilter(),
            formatter: (cell, row) => {
                return <span>{row[key]?.split("T")[0]}</span>;
            },
            csvFormatter: (cell, row) => {
                return row[key]?.split("T")[0] || "not available";
            },
            filter: dateFilter({
                delay: 400,
                placeholder: 'custom placeholder',
                withoutEmptyComparatorOption: false,
                comparators: [Comparator.EQ, Comparator.GT, Comparator.LT]
            })
        };
    });
};

const getOpsResources = () => {
    const fields = {
        SurveyProgrammingRequired: "survey Programming Required",
        SurveyProgrammingComplexity: "survey Programming Complexity",
        SurveyProgrammingNumberOfQuestions:
            "survey Programming Number Of Questions",
        SurveyProgrammingPercentageChangePerWave:
            "survey Programming Percentage Change Per Wave",
        SurveyProgrammingInvolveFieldingMarket:
            "survey Programming Involve Fielding Market",
        DataProcessingComplexity: "data Processing Complexity",
        ChartingComplexity: "charting Complexity",
        ChartingNumber: "charting Number",
        Dashboarding: "dashboarding",
        Translations: "translations",
        TranslationsLanguages: "translations Languages",
        VerbatimCoding: "verbatim Coding",
        VerbatimCodingFullOpenEnded: "verbatim Coding Full Open Ended",
        VerbatimCodingOtherSpecify: "verbatim Coding Other Specify",
        TextAnalytics: "text Analytics",
        OtherDataPreparationAssistance: "other Data Preparation Assistance",
        OtherDataPreparationAssistanceHours:
            "other Data PreparationAssistance Hours",
        AdditionalOperationsSupport: "additional Operations Support",
        AdditionalOperationsSupportHours: "additional Operations Support Hours",
    };
    const sKeys = [
        "SurveyProgrammingRequired",
        "Dashboarding",
        "Translations",
        "VerbatimCoding",
        "TextAnalytics",
        "OtherDataPreparationAssistance",
        "AdditionalOperationsSupport",
    ];
    return Object.keys(fields).map((key) => {
        return {
            dataField: key,
            text: fields[key],
            sort: true,
            formatter: (cell, row) => {
                if (sKeys.indexOf(key) !== -1) {
                    return (
                        <span>
                            {row[key] ? row[key] : ""}
                        </span>
                    );
                }
                if (key === "TranslationsLanguages") {
                    return (
                        <span>
                            {row[key]
                                ? row[key].split(',').join(', ') || ""
                                : ""}
                        </span>
                    );
                }
                return (
                    <span>
                        {row[key]
                            ? row[key] || ""
                            : ""}
                    </span>
                );
            },
            csvFormatter: (cell, row) => {
                if (sKeys.indexOf(key) !== -1) {
                    return row[key]
                        ? row[key]
                            ? "Yes"
                            : "No"
                        : ""
                }
                if (key === "TranslationsLanguages") {
                    return row[key]
                        ? row[key].split(',').join('|') || ""
                        : ""
                }
                return row[key]
                    ? row[key] || ""
                    : ""
            },
        };
    });
};

const getTCS = () => {
    const fields = {
        ProgrammerAssigned: "TCSProgrammerAssigned",
        GroupLeadEmail: "TCSGroupLeadName",
        //InitialSetupCTDays: "Initial Setup CT Days",
        //ComplexityLevel: "Complexity Level",
        ChangesBeforeInitialLinkDelivery: "TCSChangesBeforeInitialLinkDelivery",
        NumberOfQuestions: "TCSNumberOfQuestions",
        ChangesToUniqueQuestions: "TCSChangesToUniqueQuestions",
        NumberOfPids: "TCSNumberOfPids",
        ChangesAfterInitialLinkDelivery: "TCSChangesAfterInitialLinkDelivery",
        NumberOfIterations: "TCSNumberOfIterations",
        ErrorsInInitialLinkDelivery: "TCSErrorsInInitialLinkDelivery",
        ErrorsAfterInitialLinkDelivery: "TCSErrorsAfterInitialLinkDelivery",
        //JobCount: "Job Count",
        PlatformProjectId: "TCSPlatformProjectId",
        ChangesAfterLiveLinkCreated: "TCSChangesAfterLiveLinkCreated",
        ErrorsAfterLiveLinkCreated: "TCSErrorsAfterLiveLinkCreated",
        TotalNumberOfIterations: "TCSTotalNumberOfIterations",
        ActualLOIMins: "TCSActualLOI",
        TotalCompletes: "TCSTotalCompletes",
        CancelledDate: "TCSCancelledDate",
        ReasonForCancellation: "TCSReasonForCancellation",
        //Platform: "Platform",
        //DataCollectionMethod: "Data Collection Method",
        //CostCentre: "Cost Centre",
        //DeliveryStatus: "Delivery Status",
        DataCollectionMethodOther: "TCSDataCollectionOther",
        ChangesToUniqueQuestionsNA: "TCSChangesToUniqueQuestionsNA",
        LiveSurveyProjectId: "TCSLiveSurveyProjectId",
        TestSurveyProjectId: "TCSTestSurveyProjectId",
        IsInternalDelivery: "TCSIsInternalDelivery",
        IsDecommissionedFixed: "TCSIsDecommissionedFixed",
    };
    const sKeys = {
        DeliveryStatus: {
            title: "TCSWaveStatus",
            codeLabel: "DeliveryStatusOptions",
        },
        DataCollectionMethod: {
            title: "TCSDataCollection",
            codeLabel: "DeliveryDataCollectionTypeOptions",
        },
        InitialSetupCTDays: {
            title: "TCSInitialSetupCTDays",
            codeLabel: "DeliveryInitialSetupCTDaysOptions",
        },
        CostCentre: {
            title: "TCSCostCentre",
            codeLabel: "DeliveryCostCentreOptions",
        },
        ComplexityLevel: {
            title: "TCSComplexityLevel",
            codeLabel: "DeliveryComplexityLevelSPOptions",
        },
        JobCount: { title: "TCSJobCount", codeLabel: "DeliveryJobCountSP" },
        Platform: {
            title: "TCSPlatform",
            codeLabel: "DeliveryPlatformSPOptions",
        },
    };
    let columns = [];
    columns = columns.concat(
        Object.keys(fields).map((key) => {
            return {
                dataField: key,
                text: fields[key],
                sort: true,
                //filter: textFilter(),
                formatter: (cell, row) => {
                    return <span>{row[key] || ""}</span>;
                },
                csvFormatter: (cell, row) => {
                    return row[key] || "";
                },
            };
        })
    );
    columns = columns.concat(
        Object.keys(sKeys).map((key) => {
            return {
                dataField: key,
                text: sKeys[key]["title"],
                sort: true,
                //filter: textFilter(),
                formatter: (cell, row) => {
                    return (
                        <span>{row[key] && row[key].split(',').join(', ') || ""}</span>
                    );
                },
                csvFormatter: (cell, row) => {
                    return row[key] && row[key].split(',').join('|') || "";
                },
            };
        })
    );
    return columns;
};

export const finalColumns = [
    {
        dataField: "id",
        text: "ID",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.DeliverySpecId}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.DeliverySpecId || "";
        },
        //filter: textFilter(),
    },
    {
        dataField: "ProjectDeliveryNumber",
        text: "Project Delivery Number",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.ProjectDeliveryNumber || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ProjectDeliveryNumber || "";
        },
        filter: textFilter()
    },
    {
        dataField: "ModuleID",
        text: "ModuleID",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.ModuleName || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ModuleName || "";
        },
        filter: textFilter()
    },
    {
        dataField: "Module Nmae",
        text: "Module Name",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.ModuleUserInputName || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ModuleUserInputName || "";
        },
    },
    {
        dataField: "ProfileId",
        text: "Profile Id",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return <span>{row.ProfileId}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ProfileId || "";
        },
    },
    {
        dataField: "ProjectId",
        text: "Project Id",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return <span>{row.ProjectId}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ProjectId || "";
        },
    },
    {
        dataField: "WBSNumber",
        text: "WBS Number",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.WBSNumber && row.WBSNumber.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.WBSNumber && row.WBSNumber.split(',').join('|') || ""
            );
        },
    },
    {
        dataField: "OpportunityNumber",
        text: "Opportunity Number",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>{row.OpportunityNumber ? row.OpportunityNumber.split(',').join(', ') : ""}</span>
            );
        },
        csvFormatter: (cell, row) => { return row.OpportunityNumber ? row.OpportunityNumber.split(',').join('|') : "" },
        filter: textFilter()
    },
    {
        dataField: "ProjectName",
        text: "Project Name",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>{row.ProjectName}</span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ProjectName || "";
        },
        filter: textFilter()
    },
    {
        dataField: "ProposalOwner",
        text: "Proposal Owner",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ProposalOwner}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.ProposalOwner || ""
            );
        },
        filter: textFilter()
    },
    {
        dataField: "OtherCSContacts",
        text: "Other CS Contacts",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.OtherCSContacts && row.OtherCSContacts.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.OtherCSContacts && row.OtherCSContacts.split(',').join('|') || ""
            );
        },
    },
    {
        dataField: "CommissioningCountry",
        text: "Commissioning Country",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.CommissioningCountry}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.CommissioningCountry || ""
            );
        },
        filter: textFilter()
    },
    {
        dataField: "CommissioningOffice",
        text: "Commissioning Office",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.CommissioningOffice}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.CommissioningOffice || ""
            );
        },
    },
    {
        dataField: "BusinessUnit",
        text: "Business Unit",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.BusinessUnit}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.BusinessUnit || ""
            );
        },
        filter: textFilter()
    },
    {
        dataField: "IndustryVertical",
        text: "Industry Vertical",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.IndustryVertical}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.IndustryVertical || ""
            );
        },
        filter: textFilter()
    },
    {
        dataField: "FieldingCountries",
        text: "Fielding Countries",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.FieldingCountries && row.FieldingCountries.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.FieldingCountries && row.FieldingCountries.split(',').join('|') || ""
            );
        },
    },
    {
        dataField: "Methodology",
        text: "Methodology",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.Methodology && row.Methodology.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.Methodology && row.Methodology.split(',').join('|') || ""
            );
        },
        filter: textFilter()
    },
    {
        dataField: "Syndicated",
        text: "Syndicated",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.IsSyndicated}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.IsSyndicated || "";
        },
    },
    {
        dataField: "Status",
        text: "Status",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ProjectStatus}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.ProjectStatus || ""
            );
        },
        filter: textFilter()
    },
    {
        dataField: "Tracker",
        text: "Tracker",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>{row.IsTracker}</span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.IsTracker || "";
        },
    },
    {
        dataField: "TrackingFrequency",
        text: "Tracking Frequency",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.TrackingFrequency}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.TrackingFrequency || ""
            );
        },
    },
    {
        dataField: "NumberOfWaves",
        text: "Number Of Waves",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return <span>{row.NumberOfWaves}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.NumberOfWaves || "";
        },
    },
    {
        dataField: "WaveMethodology",
        text: "Wave Methodology",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.WaveMethodology}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WaveMethodology || "";
        },
        filter: textFilter()
    },
    {
        dataField: "WaveCountryName",
        text: "Wave Country",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.WaveCountryName}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WaveCountryName || "";
        },
        filter: textFilter()
    },
    ...getDates(),
    ...getOpsResources(),
    ...getTCS(),
    // ...getOnline(),
];



export const csvHeaders = finalColumns.map(col => ({ label: col.text, key: col.dataField }));
