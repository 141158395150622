const VendorType = [
    { id: 1, label: "TCS", value: 1 },
    { id: 2, label: "External Vendor", value: 2 },
  ];
  
  const qcAll = [
    {
      id: 1,
      label: " Recontact",
      value: 1,
      notApplied: [
        "Eye Tracking",
        "Path Tracking",
        "Smart Store VR",
        "Telephone / B2B-TEL",
        "Online Self Completion Survey",
      ],
    },
    {
      id: 3,
      label: "CAPI Silent Recording",
      value: 3,
      applied: [
        "CLT No Venue - SI/Places of Work/Leisure",
        "CLT No Venue - In-Store Intercept",
        "CLT IA With Venue (for CLT Interviewer-administered With Venue) / CLT With venue",
        "CLT SA With Venue",
        "CLT No Venue - Exit Intercept",
        "Door-to-Door",
        "B2B-FTF",
        "Mystery Shopping / Mystery Calling",
        "In-Store Observation/Respondent (Pre & Post Interviews)",
        "Online CLT Self-Administrated",
      ],
    },
    {
      id: 2,
      label: "CATI Audio Listening",
      value: 2,
      applied: ["Telephone / B2B-TEL",
        "Mystery Shopping / Mystery Calling"
      ]
  
    },
    {
      id: 4,
      label: "Evidance Check",
      value: 4,
      applied: ["Mystery Shopping / Mystery Calling"],
    },
  
    {
      id: 6,
      label: "DQA",
      value: 6,
      applied: [
        "CLT No Venue - SI/Places of Work/Leisure",
        "CLT No Venue - In-Store Intercept",
        "CLT IA With Venue (for CLT Interviewer-administered With Venue) / CLT With venue",
        "CLT SA With Venue",
        "CLT No Venue - Exit Intercept",
        "Door-to-Door",
        "B2B-FTF",
        "Mystery Shopping / Mystery Calling",
        "In-Store Observation/Respondent (Pre & Post Interviews)",
        "Telephone / B2B-TEL",
        "Online CLT Self-Administrated"
      ],
    },
    {
      id: 7, label: "EWS", value: 7, applied: [
        "CLT No Venue - SI/Places of Work/Leisure",
        "CLT No Venue - In-Store Intercept",
        "CLT IA With Venue (for CLT Interviewer-administered With Venue) / CLT With venue",
        "CLT SA With Venue",
        "CLT No Venue - Exit Intercept",
        "Door-to-Door",
        "B2B-FTF",
        "Mystery Shopping / Mystery Calling",
        "In-Store Observation/Respondent (Pre & Post Interviews)",
        "Telephone / B2B-TEL",
        "Online CLT Self-Administrated",
        "Online Self Completion Survey",
      ]
    },
    { id: 8, label: "ISQ", value: 8, applied: ["Online Self Completion Survey"] },
  ];
  
  export { VendorType, qcAll }