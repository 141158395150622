// Sidebar
export const FETCH_VENDORS_REQUEST = "FETCH_VENDORS_REQUEST";
export const FETCH_VENDORS_SUCCESS = "FETCH_VENDORS_SUCCESS";
export const FETCH_VENDORS_FAILURE = "FETCH_VENDORS_FAILURE"; 

export const SET_LOADING = "SET_LOADING";
export const SET_FILES = "SET_FILES";
export const SET_VENDOR_FLAG = "SET_VENDOR_FLAG";
export const SET_VENDORS = "SET_VENDORS";
export const APPEND_VENDORS = "APPEND_VENDORS";
export const FETCH_MORE_VENDORS = "FETCH_MORE_VENDORS";
export const SET_HAS_MORE_VENDORS = "SET_HAS_MORE_VENDORS";
export const SET_BIDDING_SPLIT_DATA = "SET_BIDDING_SPLIT_DATA";


export const SEARCH_VENDORS = "SEARCH_VENDORS";

export const FETCH_VENDOR_DETAILS_REQUEST = "FETCH_VENDOR_DETAILS_REQUEST";
export const FETCH_VENDOR_DETAILS_SUCCESS = "FETCH_VENDOR_DETAILS_SUCCESS";
export const FETCH_VENDOR_DETAILS_FAILURE = "FETCH_VENDOR_DETAILS_FAILURE"; 
 
export const LOAD_VENDOR_MASTERS = "LOAD_VENDOR_MASTERS";


export const SAVE_VENDOR_REQUEST = "SAVE_VENDOR_REQUEST";
export const SAVE_VENDOR_SUCCESS = "SAVE_VENDOR_SUCCESS";
export const SAVE_VENDOR_FAILURE = "SAVE_VENDOR_FAILURE";

export const API_VENDOR_CLEAR_REDIRECT_TO_STATE = "API_VENDOR_CLEAR_REDIRECT_TO_STATE";
export const API_VENDOR_REDIRECT_TO_EDIT = 'API_VENDOR_REDIRECT_TO_EDIT';
export const API_VENDOR_REDIRECT_TO_ADD = 'API_VENDOR_REDIRECT_TO_ADD';


export const RESET_MSG = "RESET_MSG";

export const SET_TOTAL_FILES = "SET_TOTAL_FILES"
export const SET_UPLOADED_FILES ="SET_UPLOADED_FILES"
export const SET_UPLOAD_STATUS= 'SET_UPLOAD_STATUS'
export const SET_UPLOAD_SUMMARY= 'SET_UPLOAD_SUMMARY'

 