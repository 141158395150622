import * as types from "../actions/vendor/ActionTypes";
import update from "immutability-helper";

const initialState = {
    vendorOnboardingRequest: {},
    totalFiles: 0,
    uploadedFiles: 0,
    uploadStatus: false,
    uploadSummary: [],
    vendorFlag: false,
    loading: true,
    saving: false,
    vendors: [],
    vendor: {},
    biddingFiles: [],
    SplitData: [],
    error: '',
    msg: '',
    redirectTo: '',
    editMode: false,
    hasMore: true,
    totalItems: 0,
    items: []
}


export default function vendorReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_VENDORS:
            return {
                ...state,
                items: action.vendors.items,
                totalItems: action.vendors.totalItems,
                loading: false,
                hasMore: state.items.length < action.vendors.totalItems
            }
        case types.SET_FILES:
            return {
                ...state,
                biddingFiles: action.files
            }
        case types.SET_BIDDING_SPLIT_DATA:
            return {
                ...state,
                SplitData: action.data
            }
        case types.SET_VENDOR_FLAG:
            return {
                ...state,
                vendorFlag: action.Vendor
            }

        case types.APPEND_VENDORS:
            {
                const newState = update(state, { items: { $push: action.vendors.items } });
                newState.loading = false;
                newState.hasMore = newState.items.length < newState.totalItems;
                return newState;
            }

        case types.SET_LOADING:
            return {
                ...state,
                loading: true
            }

        case types.FETCH_VENDORS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case types.FETCH_VENDORS_SUCCESS:
            return {
                loading: false,
                vendors: action.payload,
                error: ''
            }

        case types.FETCH_VENDORS_FAILURE:
            return {
                loading: false,
                vendors: [],
                error: action.payload
            }

        case types.FETCH_VENDOR_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                vendor: action.payload.vendor,
                editMode: action.payload.editMode
            }

        case types.FETCH_VENDOR_DETAILS_FAILURE:
            return {
                loading: false,
                vendors: [],
                error: action.payload,
                vendor: {}
            }

        case types.SAVE_VENDOR_REQUEST:
            return {
                ...state,
                saving: true,
                msg: ''
            }

        case types.SAVE_VENDOR_SUCCESS:
            return {
                ...state,
                vendor: {},
                msg: action.payload.msg || 'Saved Successfully',
                saving: false
            }
        case types.SAVE_VENDOR_FAILURE:
            return {
                ...state,
                saving: false,
                error: action.payload,
                msg: ''
            }

        case types.API_VENDOR_CLEAR_REDIRECT_TO_STATE:
            ;
            return {
                vendorOnboardingRequest: {},
                loading: true,
                saving: false,
                vendors: [],
                vendor: {},
                error: '',
                msg: '',
                redirectTo: '',
                editMode: false,
                hasMore: true,
                totalItems: 0,
                items: []
            };
        case types.RESET_MSG:
            ;
            return {
                ...state,
                msg: ''
            }

        case types.SET_TOTAL_FILES:
            return { ...state, totalFiles: action.payload }

        case types.SET_UPLOADED_FILES:
            return { ...state, uploadedFiles: action.payload ? state.uploadedFiles + action.payload : 0 }

        case types.SET_UPLOAD_STATUS:
            return { ...state, uploadStatus: action.payload }

        case types.SET_UPLOAD_SUMMARY:
            return { ...state, uploadSummary: action.payload === null ? [] : [action.payload] }


        default: return state
    }

}
