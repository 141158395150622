import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useState, useEffect } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardTitle,
    Collapse,
    Modal,
    ModalFooter,
    ModalBody,
    ModalHeader,
    Row,
    Table,
} from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import Col from "reactstrap/lib/Col";
import {
    faChevronDown,
    faChevronRight,
    faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import _, { cloneDeep } from "lodash";
import update from "immutability-helper";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import { useDispatch } from "react-redux";

import { MinusCircle, PlusCircle } from "react-feather";
function SUDTable(props) {
    const dispatch = useDispatch();
    const [costingProfileIndex, setCostingProfileIndex] = useState(0);
    const [modelState1, setModelState1] = useState(false);
    const [ViewOnlyMode, setViewOnlyMode] = useState(false);

    var [unExpandableRows, setunExpandableRows] = useState([]);

    const data2 = returnData()
    function returnData() {
        var returnData = props?.sm?.OpResourcesVariables
            ? props?.sm?.OpResourcesVariables?.filter(
                (op) => !op.dependentOn && op.value
            ).map((m, index) => {
                if (m.value != "") {
                    return {
                        value: Array.isArray(m.value)
                            ? m.value.map((m) => m.value).toString()
                            : m.value,
                        label: m.label,
                        key: m.id,
                        Comments:
                            props.sm?.BiddingDetail?.SelectedSUD &&
                                props.sm?.BiddingDetail?.SelectedSUD.length > 0 &&
                                props.sm?.BiddingDetail?.SelectedSUD?.filter((sr) => sr.label == m.label).length > 0
                                ? props.sm?.BiddingDetail?.SelectedSUD?.filter((sr) => sr.label == m.label)[0]
                                    .Comments
                                : "",
                    }
                }
            })
            : [];

        if (props?.sm?.OpResourcesVariables?.filter(a => a.value)?.find(a => a.id == "codingRequired")) {
            let codingValue = props?.sm?.OpResourcesVariables?.find(a => a.id == "codingRequired");
            returnData.push({
                value: Array.isArray(codingValue.value)
                    ? codingValue.value.map((m) => m.value).toString()
                    : codingValue.value,
                label: codingValue.label,
                key: codingValue.id,
                Comments:
                    props.sm?.BiddingDetail?.SelectedSUD &&
                        props.sm?.BiddingDetail?.SelectedSUD.length > 0 &&
                        props.sm?.BiddingDetail?.SelectedSUD?.filter((sr) => sr.label == codingValue.label).length > 0
                        ? props.sm?.BiddingDetail?.SelectedSUD?.filter((sr) => sr.label == codingValue.label)[0]
                            .Comments
                        : "",

            })
        }
        var trackingProject = _.head(props?.sm.CostingOptions?.filter(a => a.Selected)[0]?.CostingOptionSchema?.filter(a => a.id == "trackingProject"))
        if (props?.sm.CostingOptions?.filter(a => a.Selected)[0]?.CostingOptionData?.trackingProject) {
            if (props?.sm.CostingOptions?.filter(a => a.Selected).map(a => a.CostingOptionData?.trackingProject)?.filter(v => v == 'Yes').length > 0) {
                returnData.push({
                    value: 'Yes', label: trackingProject.label, key: trackingProject.id,
                    Comments: props.sm.BiddingDetail?.SelectedSUD &&
                        props.sm?.BiddingDetail?.SelectedSUD.length > 0 &&
                        props.sm?.BiddingDetail?.SelectedSUD?.filter((sr) => sr.label == trackingProject.label).length > 0
                        ? props.sm?.BiddingDetail?.SelectedSUD?.filter((sr) => sr.label == trackingProject.label)[0]
                            .Comments
                        : "",
                })
            }
            else {
                returnData.push({
                    value: 'No', label: trackingProject.label, key: trackingProject.id,
                    Comments: props.sm.BiddingDetail?.SelectedSUD &&
                        props.sm?.BiddingDetail?.SelectedSUD.length > 0 &&
                        props.sm?.BiddingDetail?.SelectedSUD?.filter((sr) => sr.label == trackingProject.label).length > 0
                        ? props.sm?.BiddingDetail?.SelectedSUD?.filter((sr) => sr.label == trackingProject.label)[0]
                            .Comments
                        : "",
                })
            }
        }
        return returnData


    }
    useEffect(() => {
        setViewOnlyMode(((props.project?.CostingProfiles[props.project?.CostingProfiles.length - 1]?.VenodrBidDeadLineStatus == "close")
            || (props.project?.ProjectStatus == 7)
            || (["2", "3", "4", "5", "6"].indexOf(_.last(props.project?.CostingProfiles).ProfileStatus) != -1)) ? true : false)
    }, []);

    useEffect(() => {
        setCostingProfileIndex(props.project.CostingProfiles.length - 1)
        setunExpandableRows(unExpandable(data2.length > 0 ? data2?.filter(a => a) : data2));
    }, [props.sm]);

    const [modelRowIndex, setModelSelectedRow] = useState(null);
    const [commentModal, setCommentModal] = useState(false);
    const [comments, setComments] = useState("");
    var [allselected, setallselected] = useState([]);

    function formatLink(cell, row) {
        return (
            <>
                {row.Comments == "" ? null : (
                    <FontAwesomeIcon
                        title="View Comments"
                        className="align-middle"
                        icon={faClipboard}
                        style={{ fontSize: "1rem", marginLeft: "1rem" }}
                        onClick={() => {
                            setCommentModal(true);
                            setComments(row);
                        }}
                    />
                )}
            </>
        );
    }
    const rowStyle2 = (row, rowIndex) => {
        const style = {};
        if (
            props.sm &&
            props.sm.OpResourcesVariables &&
            props.sm.OpResourcesVariables?.filter(
                (v) => v.dependentOn && v.value
            ).some((v) => v.dependentOn.fields.some((f) => f.fieldName == row.label && row.value != "No"))
        ) {
            style.backgroundColor = "#9CF89C";
        } else {
            style.backgroundColor = "#eeeeee";
        }

        if (
            props.sm &&
            props.sm.OpResourcesVariables &&
            props.sm.OpResourcesVariables?.filter((v) => v.dependentOn).some((v) =>
                v.dependentOn.fields.some((f) => f.fieldName == row.label)
            )
        ) {
        }

        return style;
    };

    function unExpandable(rows) {
        var unExpandableRows = rows?.filter((row, i) => !_.uniq(props.sm.OpResourcesVariables?.filter(v => v.dependentOn && v.value)?.map(fields => fields.dependentOn.fields?.map(a => a?.fieldName))?.flat())?.includes(row.label))
        var unexpandableIndex = unExpandableRows?.map(ue => { return ue.key })
        return _.union(unexpandableIndex, rows?.map((ue, i) => { if (ue.value == "No") return ue.key })?.filter(f => f != undefined))
    }

    const selectRow = {
        mode: "checkbox",
        selected: props.sm.BiddingDetail?.SelectedSUD
            ? props.sm.BiddingDetail?.SelectedSUD.length > 0
                ? props.sm.BiddingDetail?.SelectedSUD.map((sr) => sr.key)
                : []
            : [],
        nonSelectable: props.sm.BiddingDetail?.SelectedSUD
            ? props.sm.BiddingDetail?.SelectedSUD.length > 0
                ? props.sm.BiddingDetail?.SelectedSUD?.filter((v) => v.dependent).map((sr) => sr.key)
                : []
            : [],

        onSelect: (row, isSelect, rowIndex, e) => {
            var tempRFQ = props.sm.BiddingDetail?.SelectedSUD ? props.sm.BiddingDetail?.SelectedSUD : [];
            if (e.target.checked && tempRFQ.length > 0) {
                if (tempRFQ.map((r) => r.key).indexOf(row.key) === -1) {
                    tempRFQ.push(row);
                    if (
                        props.sm.OpResourcesVariables?.filter((v) => v.dependentOn)?.filter(
                            (v) =>
                                v.dependentOn.fields.some((sm) => sm.fieldName == row.label)
                        ).length > 0
                    ) {
                        props.sm.OpResourcesVariables?.filter((v) => v.dependentOn)
                            ?.filter((v) =>
                                v.dependentOn.fields.some((sm) => sm.fieldName == row.label)
                            )
                            .forEach((e, i) => {
                                if (e.value && tempRFQ.every((v) => v.label != e.label)) {
                                    tempRFQ.push({
                                        dependentOn: row.label,
                                        dependent: true,
                                        value: Array.isArray(e.value)
                                            ? e.value.map((v) => v.value).toString()
                                            : e.value,
                                        label: e.label,
                                        Comments: "",
                                        key: e.id,
                                    });
                                }
                            });
                    }
                }
                if (tempRFQ.map((r) => r.key).indexOf(row.key) != -1) {
                    tempRFQ[tempRFQ.map((r) => r.key).indexOf(row.key)] = row;
                    props.sm.OpResourcesVariables?.filter((v) => v.dependentOn)
                        ?.filter((v) =>
                            v.dependentOn.fields.some((sm) => sm.fieldName == row.label)
                        )
                        .forEach(
                            (e, i) =>
                            (tempRFQ[tempRFQ.map((r) => r.label).indexOf(e.label)] = {
                                dependentOn: row.label,
                                dependent: true,
                                value: Array.isArray(e.value)
                                    ? e.value.map((v) => v.value).toString()
                                    : e.value,
                                label: e.label,
                                Comments: "",
                                key: e.id,
                            })
                        );
                }
            }
            if (e.target.checked == false) {
                tempRFQ.splice(tempRFQ.map((r) => r.key).indexOf(row.key), 1);
                // props.sm.OpResourcesVariables?.filter((v) => v.dependentOn)
                //     ?.filter((v) =>
                //         v.dependentOn.fields.some((sm) => sm.fieldName == row.label)
                //     )
                //     .forEach((sp) => {

                // tempRFQ.splice(tempRFQ.map((r) => r.key).indexOf(sp.key), 1);
                //     });
            }
            if (e.target.checked && tempRFQ.length == 0) {
                tempRFQ.push(row);
                if (
                    props.sm.OpResourcesVariables?.filter((v) => v.dependentOn)?.filter(
                        (v) => v.dependentOn.fields.some((sm) => sm.fieldName == row.label)
                    ).length > 0
                ) {
                    props.sm.OpResourcesVariables?.filter((v) => v.dependentOn)
                        ?.filter((v) =>
                            v.dependentOn.fields.some((sm) => sm.fieldName == row.label)
                        )
                        .forEach((e, i) => {
                            if (e.value) {
                                tempRFQ.push({
                                    dependentOn: row.label,
                                    dependent: true,
                                    value: Array.isArray(e.value)
                                        ? e.value.map((v) => v.value).toString()
                                        : e.value,
                                    label: e.label,
                                    Comments: "",
                                    key: e.id,
                                });
                            }
                        });
                }
            }

            const newProposal = update(props.project.CostingProfiles[costingProfileIndex], {
                CountrySpecs: {
                    [props.indexCountry]: {
                        MethodologySpecs: {
                            [props.indexMethodology]: {
                                BiddingDetail: {
                                    SelectedSUD: { $set: tempRFQ },
                                }
                            },
                        },
                    },
                },
            });
            props.project.CostingProfiles[costingProfileIndex] = newProposal;
            dispatch({
                type: currentProjectActions.UPDATE_NEW_PROJECT,
                newProject: props.project,
            });
        },

        onSelectAll: (isSelect, rows) => {
            var tempRFQ = [];
            const ids = rows.map((r) => r.key);
            if (isSelect) {
                setallselected(ids);
                tempRFQ = [...rows];
                rows.forEach((row) => {
                    props.sm.OpResourcesVariables?.filter((v) => v.dependentOn)
                        ?.filter((v) =>
                            v.dependentOn.fields.some((sm) => sm.fieldName == row.label)
                        )
                        .forEach((e, i) => {
                            if (e.value) {
                                tempRFQ.push({
                                    dependentOn: row.label,
                                    dependent: true,
                                    label: e.label,
                                    value: Array.isArray(e.value)
                                        ? e.value.map((v) => v.value).toString()
                                        : e.value,
                                    Comments: "",
                                    key: e.id,
                                });
                            }
                        });
                });
            } else {
                setallselected([]);
                tempRFQ = [];
            }

            const newProposal = update(props.project.CostingProfiles[costingProfileIndex], {
                CountrySpecs: {
                    [props.indexCountry]: {
                        MethodologySpecs: {
                            [props.indexMethodology]: {
                                BiddingDetail: {
                                    SelectedSUD: { $set: tempRFQ },
                                }
                            },
                        },
                    },
                },
            });
            props.project.CostingProfiles[costingProfileIndex] = newProposal;
            dispatch({
                type: currentProjectActions.UPDATE_NEW_PROJECT,
                newProject: props.project,
            });
        },
    };

    function tabledata(row) {
        var datanew = [];

        {
            props.sm.OpResourcesVariables?.filter((v) => v.dependentOn)?.filter((v) =>
                v.dependentOn.fields.some((sm) => sm.fieldName == row.label)
            ).length > 0 &&
                props.sm.OpResourcesVariables?.filter((v) => v.dependentOn)
                    ?.filter((v) =>
                        v.dependentOn.fields.some((sm) => sm.fieldName == row.label)
                    )
                    .map((SUDdata, indexSUDdata) => {
                        if (SUDdata.value != undefined) {
                            var datapush = {
                                label: SUDdata.label ? SUDdata.label : "",
                                value: typeof (SUDdata.value) != "object" ? SUDdata.value
                                    ? SUDdata.value
                                    : "" :
                                    (Array.isArray(SUDdata.value))
                                        ? SUDdata.value.map((val) => val.value).toString()
                                        :
                                        SUDdata.value?.label,
                                Comments:
                                    props.sm?.BiddingDetail?.SelectedSUD?.find((a) => a.label == SUDdata.label)
                                        ?.Comments != undefined
                                        ? props.sm?.BiddingDetail?.SelectedSUD?.find((a) => a.label == SUDdata.label)
                                            ?.Comments
                                        : "",
                                key: SUDdata.id,
                            };

                            datanew.push(datapush);
                        }
                    });
        }

        return datanew;
    }

    const expandRow = {
        renderer: (row) =>
            row &&
                props.sm &&
                props.sm.OpResourcesVariables?.filter((v) => v.dependentOn)?.filter((v) =>
                    v.dependentOn.fields.some((sm) => sm.fieldName == row.label)
                ).length > 0 ? (
                <Col md={12}>
                    <Card>
                        <ToolkitProvider
                            keyField="key"
                            data={row.value != "No" && row.value != "0" && row.value != undefined ? tabledata(row) : []}
                            columns={tableColumns2}
                        >
                            {(props) => (
                                <CardBody>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        bootstrap4
                                        wrapperClasses="table-responsive"
                                        bordered={true}
                                        filterPosition="top"
                                    />
                                </CardBody>
                            )}
                        </ToolkitProvider>
                    </Card>
                </Col>
            ) : (
                "No Dependents Fields"
            ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expanded: [modelRowIndex],
        nonExpandable: unExpandableRows,
        expandHeaderColumnRenderer: ({ isAnyExpands }) =>
            isAnyExpands ? (
                <MinusCircle width={16} height={16} />
            ) : (
                <PlusCircle width={16} height={16} />
            ),
        expandColumnRenderer: ({ expanded }) =>
            expanded ? (
                <MinusCircle width={16} height={16} />
            ) : (
                <PlusCircle width={16} height={16} />
            ),
        className: "expanding-foo",
    };
    const tableColumns2 = [
        {
            dataField: "label",
            text: "Task",

            editable: false,
        },
        {
            dataField: "value",
            text: "Details",

            editable: false,
        },
        {
            dataField: "Comments",
            text: "Comments",
            formatter: formatLink,
            editable: false,
        },
    ];

    const tableColumns = [
        {
            dataField: "label",
            text: "Task",

            editable: false,
        },
        {
            dataField: "value",
            text: "Details",

            editable: false,
        },

        {
            dataField: "Comments",
            text: "Comments",
            formatter: formatLink,
            editable: false,
        },
    ];
    return (
        <>
            <Card>
                <CardHeader
                    onClick={() => {
                        setModelState1(!modelState1);
                    }}
                >
                    <Row>
                        <Col xs="11">
                            <CardTitle className="mb-0">
                                Select Additional Information From SUD
                            </CardTitle>
                        </Col>
                        <Col xs="1">
                            <FontAwesomeIcon
                                className="align-middle mr-2"
                                icon={!modelState1 ? faChevronRight : faChevronDown}
                                fixedWidth
                            />
                        </Col>
                    </Row>
                </CardHeader>
                <Collapse isOpen={modelState1}>
                    <CardBody>
                        {
                            <div className={"tab tabContentCostSummary"}>
                                <Col md={11}>
                                    {
                                        <Card>
                                            <ToolkitProvider
                                                keyField="key"
                                                data={data2.length > 0 ? data2?.filter(a => a) : data2}
                                                columns={tableColumns}
                                            >
                                                {(props) => (
                                                    <CardBody className="details-flex">
                                                        {ViewOnlyMode && ((data2.length > 0 ? data2?.filter(a => a) : data2).length > 0 ?
                                                            <BootstrapTable
                                                                {...props.baseProps}
                                                                bootstrap4
                                                                wrapperClasses="table-responsive"
                                                                bordered={true}
                                                                filterPosition="top"
                                                                expandRow={expandRow}
                                                                rowStyle={rowStyle2}
                                                            /> : <span className="nodataavailability" >No SUD details provided</span>)}
                                                        {!ViewOnlyMode && ((data2.length > 0 ? data2?.filter(a => a) : data2).length > 0 ?
                                                            <BootstrapTable
                                                                {...props.baseProps}
                                                                bootstrap4
                                                                wrapperClasses="table-responsive"
                                                                bordered={true}
                                                                filterPosition="top"
                                                                selectRow={selectRow}
                                                                expandRow={expandRow}
                                                                rowStyle={rowStyle2}
                                                            /> : <span className="nodataavailability" >No SUD details provided</span>)}

                                                    </CardBody>
                                                )}
                                            </ToolkitProvider>
                                        </Card>
                                    }
                                </Col>
                            </div>
                        }
                    </CardBody>
                </Collapse>
            </Card>
            <Modal isOpen={commentModal}>
                <ModalHeader>
                    {props.sm &&
                        props.sm.BiddingDetail?.SelectedSUD &&
                        props.sm.BiddingDetail.SelectedSUD?.filter((sr) => sr.label == comments.label)
                            .length > 0
                        ? props.sm.BiddingDetail?.SelectedSUD?.filter((sr) => sr.label == comments.label)[0]
                            .label
                        : null}
                </ModalHeader>
                <ModalBody>
                    <Table>
                        <tr>
                            <th>Vendor Name</th>
                            <th>Comment</th>
                        </tr>

                        {props.sm &&
                            props.sm.BiddingDetail?.SelectedSUD &&
                            props.sm.BiddingDetail?.SelectedSUD?.filter((sr) => sr.label == comments.label)
                                .length > 0
                            ? props.sm.BiddingDetail?.SelectedSUD
                                ?.filter((sr) => sr.label == comments.label)[0]
                                .Comments.map((c) => (
                                    <tr>
                                        <td>
                                            {c.vendorName
                                                ? c.vendorName
                                                : props.sm.Vendors?.filter(
                                                    (v) => v.value == c.vendorid
                                                )[0].label}
                                        </td>
                                        <td>{c.comment}</td>
                                    </tr>
                                ))
                            : null}
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            setCommentModal(false);
                        }}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default SUDTable;