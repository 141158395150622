import React from "react";

function Excel() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#000"
          d="M8.75 12h-3V9h3v3zm0-7.5h-3v3h3v-3zm7.5 0h-6v3h6v-3zm0 4.5h-6v3h6V9zM23 4.5V24H2V0h16.5L23 4.5zm-19.5-3v12h18V5.25h-3.75V1.5H3.5zm2.606 16.883L3.865 21.75H5.45l1.431-2.223 1.402 2.223h1.684l-2.242-3.36 2.062-3.068H8.238l-1.304 2.053-1.259-2.053h-1.64l2.071 3.06zm4.425-3.06v6.427h4.545v-1.19h-3.13v-5.237h-1.415zm6.3 4.292h-1.365c-.015.354.059.707.216 1.026.144.281.352.525.607.712.265.19.565.33.882.409.337.087.683.13 1.03.13.391.007.781-.043 1.157-.15.304-.087.588-.232.837-.427.218-.175.392-.399.508-.653.116-.258.174-.537.172-.819a1.575 1.575 0 00-.23-.887 1.92 1.92 0 00-.544-.552 2.4 2.4 0 00-.635-.3 6.053 6.053 0 00-.5-.13c-.402-.102-.727-.187-.975-.252a3.514 3.514 0 01-.584-.199.636.636 0 01-.284-.216.573.573 0 01-.075-.305.604.604 0 01.09-.342.772.772 0 01.23-.225c.094-.061.2-.104.31-.126.112-.024.227-.037.342-.037.161-.003.322.01.48.04.139.026.271.077.392.15.116.075.21.178.274.3.075.148.116.311.122.477h1.367a1.97 1.97 0 00-.205-.949 1.85 1.85 0 00-.572-.644 2.432 2.432 0 00-.825-.365 4.087 4.087 0 00-.967-.112c-.292.001-.582.04-.864.117-.277.073-.54.195-.774.36-.226.16-.415.367-.553.607-.146.263-.22.56-.212.86-.02.496.204.97.6 1.27.186.141.393.255.612.336.23.088.464.162.702.22.234.067.465.127.693.181.209.048.413.11.612.19.162.06.31.151.436.269a.565.565 0 01.167.423.623.623 0 01-.126.401.881.881 0 01-.315.248c-.128.06-.265.1-.405.121-.134.02-.27.03-.405.032-.182 0-.364-.023-.54-.069a1.342 1.342 0 01-.454-.206 1.033 1.033 0 01-.311-.364 1.189 1.189 0 01-.116-.55h-.002z"
        ></path>
      </g>
    </svg>
  );
}

export default Excel;