import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import { toastr } from "react-redux-toastr";
import axios from "../../axios-interceptor";
import _, { method } from "lodash";

import {
  getMultiOptions,
  getMultiOptionsWithDependency,
  getSubMethodologyAttribute,
  getSingleOptions,
} from "../../utils/codeLabels";
import { useHistory } from "react-router";
const CostingMarkets = ({ isOpen, closeModal }) => {
  const [functionOptions, setFunctionOptions] = useState([
    { value: "Commercial", label: "Commercial" },
    { value: "Operations", label: "Operations" },
  ]);
  const [productValue, setProductvalue] = useState(null);
  const [costingMarketTable, setCostingMarketTable] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [indexPostion, setIndexPostion] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [functions,setFunctions]=useState(null)
  const [isLoading,setIsLoading]=useState(false)
  const [saved,setSaved]=useState(false)

  let styles = {
    border: "1px solid red",
  };
  const costingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  let oppData = useSelector(
    ({ currentProject }) => currentProject.opportunityData
  );
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);

  const history=useHistory();

  const handleCostingMarketsSave = (e) => {
    // let temparr = _.cloneDeep(costingMarketTable);
    // let invalid = false;
    // temparr.map((item, index) => {
    //   if (item.ChildWBSNumber !== "") {
    //     if (
    //       !item.ChildWBSNumber.startsWith(item.ParentWBSNumber+".") ||
    //       item.ChildWBSNumber.length <= item.ParentWBSNumber.length
    //     ) {
    //       invalid = true;
    //       temparr[index]["isValid"] = false;
    //     } else {
    //       temparr[index]["isValid"] = true;
    //     }
    //   } else {
    //     temparr[index]["isValid"] = true;
    //   }
    // });

    // setCostingMarketTable(temparr);

    // if (invalid) {
    //   toastr.warning("Check Child WBS number input");
    // } else {
      axios
        .post(`/timecost/CostMarket/${costingProfile.id}`, costingMarketTable)
        .then((res) => {
          toastr.success("Success", "Saved successfully");
          closeModal(false);
          setSaved(true)
        })
        .catch((error) => {
          console.log(error);
        });
    // }
  };
  const handleCheckboxChange = (index) => {
    let temparr = _.cloneDeep(costingMarketTable);
    temparr[index]["IsSelected"] = !temparr[index]["IsSelected"];
    setCostingMarketTable(temparr);
  };
  const handleChildWBSchange = (e, index) => {
    let temparr = _.cloneDeep(costingMarketTable);
    temparr[index]["ChildWBSNumber"] = e.target.value;
    setCostingMarketTable(temparr);
  };
  const handleproductchange = (e) => {
    setIndexPostion(e.value);
    setProductvalue(e.label);
  };
  const handleCountryEdit = (e, index) => {
    console.log("e,index", e, index);
    console.log("e.target.innerHTML", e.target.innerHTML);
    let checkarr = codeLabels.FieldingCountriesOptions.map(
      (item) => item.Label
    );
    if (checkarr.includes(e.target.innerHTML)) {
      let temparr = costingMarketTable;
      temparr[index].Countries = e.target.innerHTML;
      setCostingMarketTable(temparr);
    } else {
      toastr.warning("Invalid country name");
      let temparr = costingMarketTable;
      temparr[index].Countries = "";
      setCostingMarketTable(temparr);
    }
  };
  const handleAddCountry = (e) => {
    if (indexPostion === null || countryList.length === 0 || functions===null) {
      toastr.warning("Select product , countries and functions");
      return;
    }
    let functionitems;
    console.log(functions);
    if(functions.length===1)
    {
      functionitems=functions[0].label
    }
    else if(functions.length===2)
    {
      functionitems="Commercial & Operations"
    }
    let index = -1;
    let obj = {};
    for (let i = costingMarketTable.length - 1; i >= 0; i--) {
      if (costingMarketTable[i].productIndex === indexPostion) {
        index = i;
        obj = _.cloneDeep(costingMarketTable[i]);
        break;
      }
    }

    if("id" in obj)
    {
      delete obj["id"]
    }
    let checkarr = [];
    costingMarketTable.map((item) => {
      if (item.productIndex === indexPostion) {
        checkarr.push({Country:item.Countries,functions:item.functions,source:item.Source});
      }
    });

    let countryarr = countryList;
    countryarr=countryarr.filter((item)=> checkarr.findIndex((i)=>i.Country===item.label&& i.functions==="Commercial & Operations")===-1)
    if(functionitems==="Commercial & Operations")
    {
      countryarr=countryarr.filter((item)=> checkarr.findIndex((i)=>i.Country===item.label)===-1 )
    }
    let countryListarr = countryarr.filter(
      (item) => checkarr.findIndex((i)=>i.Country===item.label && i.functions===functionitems)===-1
    );
    let check=false
    let temp = _.cloneDeep(costingMarketTable);
    countryListarr.map((item,index)=>{
      temp.map((i)=>{
        if(item.label===i.Countries && i.Source==="Added")
        {
          i.functions="Commercial & Operations"
          countryListarr.splice(index,1);
          check=true
        }
      })
    })
    setCostingMarketTable(temp)
    
    if (countryListarr.length > 0) {
      if(index!=-1)
      {
        let arrObj = countryListarr.map((country) => {
          return {
            ...obj,
            ...{ Source: "Added", Countries: country.label, SourceColumn: "",functions:functionitems,ChildWBSNumber: "",IsSelected: false },
          };
        });

        let temparr = _.cloneDeep(costingMarketTable);
        if (index !== -1) {
          temparr.splice(index + 1, 0, ...arrObj);
        }
        setCostingMarketTable(temparr);
      }
      else
      {
        toastr.warning("Select countries for this material ID in timecost to add more countries");
      }
    }
    else if(check)
    {

    } 
    else {
      toastr.warning("Selected countries are already added");
    }
    setCountryList([]);
  };
  const handleCountryChange = (e) => {
    setCountryList(e);
  };
  const handleFunctionChange = (e) => {
    setFunctions(e)
  };
  const toggleModal = () => {
    closeModal(false);
  };

  useEffect(() => {
    setIsLoading(true)
   
    if(costingProfile.id!==undefined && isOpen===true)
   
 
{
      axios.get(`/timecost/CostMarket/${costingProfile.id}`).then((res)=>{
        console.log(res);
        setIsLoading(true)
      let CostMarketCountries=res.data.result[0].CostMarketCountries

      if (CostMarketCountries !== undefined) {
        if (
          CostMarketCountries.length > 0 &&
          costingProfile.ProductTableData != undefined
        ) {
          while (typeof costingProfile.ProductTableData === "string") {
            costingProfile.ProductTableData = JSON.parse(
              costingProfile.ProductTableData
            );
          }
          let productarr=costingProfile.ProductTableData.filter((item)=>!("isClone" in item && item.isClone===true))
          let products = productarr.map((item, index) => ({
            value: index,
            label: index + 1 + ". " + item.MaterialID,
          }));
          if (products.length === 1) {
            setProductvalue(products[0].label);
            setIndexPostion(0);
          }
          setProductOptions(products);
          let costMarketCountries = CostMarketCountries;
          while (typeof costingProfile.CostingCountries === "string") {
            costingProfile.CostingCountries = JSON.parse(
              costingProfile.CostingCountries
            );
          }
          let costingMarkets = res.data.result[0].CostingCountries;
          // let costingMarkets={
          //   "3346361f-6e50-ee11-be6d-000d3a2ac08a": [
          //     {
          //       country: "India",
          //       function: "ops",
          //     },
          //     {
          //       country: "UAE",
          //       function: "both",
          //     },
          //     {
          //       country: "Greece",
          //       function: "CS",
          //     },
          //   ],
          //   "f765769f-6d50-ee11-be6d-000d3a2ac795": [
          //     {
          //       country: "India",
          //       function: "ops",
          //     },
          //     {
          //       country: "Japan",
          //       function: "both",
          //     },
          //   ],
          // };
          // let country = [];
          // costMarketCountries.map((table) => {
          //   if (table.productIndex === 1) {
          //     return;
          //   }
          //   if (table.Source === "Timecost") country.push(table.Countries);
          // });

          // let difference = costingMarkets.filter((i) => !country.includes(i));
          let checkarr=[]
          let tableData = costingProfile.ProductTableData.filter((item)=>!("isClone" in item && item.isClone===true));
          tableData.map((item, index) => {
            if( costingMarkets[item.OpportunityLineItemID]!==undefined)
            {
            let country = [];
            costMarketCountries.map((table) => {
              if (table.productIndex >index) {
                return;
              }
              if (table.Source === "Timecost" && table.productIndex===index) country.push(table.Countries);
            });
            let difference = costingMarkets[item.OpportunityLineItemID].filter((i) => !country.includes(i.country));
            let itemarr= costingMarkets[item.OpportunityLineItemID].map((item)=>item.country)
            checkarr[index]=itemarr
            let findIndex;
            for (let i = costMarketCountries.length - 1; i >= 0; i--) {
              if (costMarketCountries[i].productIndex === index) {
                findIndex = i;
                break;
              }
            }
            let newcountryarr = [];

            difference.map((market) => {
              let indexnumber = index + 1 + ". ";
              if (market.country === "Pakistan") {
                newcountryarr.push({
                  MaterialID: indexnumber + item.MaterialID,
                  SubProjectType: item.methodologyType,
                  ParentWBSNumber: item.WBSNumber,
                  Source: "Timecost",
                  Countries: market.country,
                  functions:market.function,
                  SourceColumn: "Icon Hub",
                  ChildWBSNumber: "",
                  IsSelected: false,
                  toDisplay: false,
                  productIndex: index,
                  isValid: true,
                  OpportunityLineItemID: item.OpportunityLineItemID,
                });
              } else if (market.country === "Russia") {
                newcountryarr.push({
                  MaterialID: indexnumber + item.MaterialID,
                  SubProjectType: item.methodologyType,
                  ParentWBSNumber: item.WBSNumber,
                  Source: "Timecost",
                  Countries: market.country,
                  functions:market.function,
                  SourceColumn: "Smart Store",
                  ChildWBSNumber: "",
                  IsSelected: false,
                  toDisplay: false,
                  productIndex: index,
                  isValid: true,
                  OpportunityLineItemID: item.OpportunityLineItemID,
                });
              } else {
                newcountryarr.push({
                  MaterialID: indexnumber + item.MaterialID,
                  SubProjectType: item.methodologyType,
                  ParentWBSNumber: item.WBSNumber,
                  Source: "Timecost",
                  Countries: market.country,
                  functions:market.function,
                  SourceColumn: "",
                  ChildWBSNumber: "",
                  IsSelected: false,
                  toDisplay: false,
                  productIndex: index,
                  isValid: true,
                  OpportunityLineItemID: item.OpportunityLineItemID,
                });
              }
            });
            costMarketCountries.splice(findIndex + 1, 0, ...newcountryarr);
          }
          });
          costMarketCountries = costMarketCountries.filter(
            (item) =>
              checkarr[item.productIndex].includes(item.Countries) || item.Source === "Added"
          );

          costMarketCountries.map((table)=>{
            if(costingMarkets[table.OpportunityLineItemID]!==undefined)
            {
              costingMarkets[table.OpportunityLineItemID].map((item)=>{
                if(item.country===table.Countries && table.Source==="Timecost")
                {
                  table.functions=item.function
                }
              })
            }
          })
          let finalcostmarket=[]

          costingProfile.ProductTableData.map((item,index)=>{
              costMarketCountries.map((table)=>{
                if(index===table.productIndex)
                {
                  table.ParentWBSNumber=item.WBSNumber
                  finalcostmarket.push(table)
                }
              })
          })
          setCostingMarketTable(finalcostmarket);
        } else {
          while (typeof costingProfile.CostingCountries === "string") {
            costingProfile.CostingCountries = JSON.parse(
              costingProfile.CostingCountries
            );
          }
          let costingMarkets=res.data.result[0].CostingCountries;
          // let costingMarkets = {
          //   "3346361f-6e50-ee11-be6d-000d3a2ac08a": [
          //     {
          //       country: "India",
          //       function: "ops",
          //     },
          //     {
          //       country: "UAE",
          //       function: "both",
          //     },
          //   ],
          //   // "f765769f-6d50-ee11-be6d-000d3a2ac795": [
          //   //   {
          //   //     country: "India",
          //   //     function: "ops",
          //   //   },
          //   //   {
          //   //     country: "Japan",
          //   //     function: "both",
          //   //   },
          //   // ],
          // };

          if (costingMarkets !== null && costingMarkets !== "null") {
              if (costingProfile.ProductTableData != undefined) {
                let temparr = [];

                while (typeof costingProfile.ProductTableData === "string") {
                  costingProfile.ProductTableData = JSON.parse(
                    costingProfile.ProductTableData
                  );
                }
                let productarr=costingProfile.ProductTableData.filter((item)=>!("isClone" in item && item.isClone===true))
                let products = productarr.map(
                  (item, index) => ({
                    value: index,
                    label: index + 1 + ". " + item.MaterialID,
                  })
                );
                if (products.length === 1) {
                  setProductvalue(products[0].label);
                  setIndexPostion(0);
                }
                setProductOptions(products);

                let tableArr = [];
                let tableData = costingProfile.ProductTableData.filter((item)=>!("isClone" in item && item.isClone===true));;
                tableData.map((item, i) => {
                  if(costingMarkets[item.OpportunityLineItemID]!==undefined){
                    costingMarkets[item.OpportunityLineItemID].map((market, index) => {
                      let indexnumber = i + 1 + ". ";
                      if (index === 0) {
                        if (market.country === "Pakistan") {
                          tableArr.push({
                            MaterialID: indexnumber + item.MaterialID,
                            SubProjectType: item.methodologyType,
                            ParentWBSNumber: item.WBSNumber,
                            Source: "Timecost",
                            Countries: market.country,
                            functions:market.function,
                            SourceColumn: "Icon Hub",
                            ChildWBSNumber: "",
                            IsSelected: false,
                            toDisplay: true,
                            productIndex: i,
                            isValid: true,
                            OpportunityLineItemID: item.OpportunityLineItemID,
                          });
                        } else if (market.country === "Russia") {
                          tableArr.push({
                            MaterialID: indexnumber + item.MaterialID,
                            SubProjectType: item.methodologyType,
                            ParentWBSNumber: item.WBSNumber,
                            Source: "Timecost",
                            Countries: market.country,
                            functions:market.function,
                            SourceColumn: "Smart Store",
                            ChildWBSNumber: "",
                            IsSelected: false,
                            toDisplay: true,
                            productIndex: i,
                            isValid: true,
                            OpportunityLineItemID: item.OpportunityLineItemID,
                          });
                        } else {
                          tableArr.push({
                            MaterialID: indexnumber + item.MaterialID,
                            SubProjectType: item.methodologyType,
                            ParentWBSNumber: item.WBSNumber,
                            Source: "Timecost",
                            Countries: market.country,
                            functions:market.function,
                            SourceColumn: "",
                            ChildWBSNumber: "",
                            IsSelected: false,
                            toDisplay: true,
                            productIndex: i,
                            isValid: true,
                            OpportunityLineItemID: item.OpportunityLineItemID,
                          });
                        }
                      } else {
                        if (market.country === "Pakistan") {
                          tableArr.push({
                            MaterialID: indexnumber + item.MaterialID,
                            SubProjectType: item.methodologyType,
                            ParentWBSNumber: item.WBSNumber,
                            Source: "Timecost",
                            Countries: market.country,
                            functions:market.function,
                            SourceColumn: "Icon Hub",
                            ChildWBSNumber: "",
                            IsSelected: false,
                            toDisplay: false,
                            productIndex: i,
                            isValid: true,
                            OpportunityLineItemID: item.OpportunityLineItemID,
                          });
                        } else if (market.country === "Russia") {
                          tableArr.push({
                            MaterialID: indexnumber + item.MaterialID,
                            SubProjectType: item.methodologyType,
                            ParentWBSNumber: item.WBSNumber,
                            Source: "Timecost",
                            Countries: market.country,
                            functions:market.function,
                            SourceColumn: "Smart Store",
                            ChildWBSNumber: "",
                            IsSelected: false,
                            toDisplay: false,
                            productIndex: i,
                            isValid: true,
                            OpportunityLineItemID: item.OpportunityLineItemID,
                          });
                        } else {
                          tableArr.push({
                            MaterialID: indexnumber + item.MaterialID,
                            SubProjectType: item.methodologyType,
                            ParentWBSNumber: item.WBSNumber,
                            Source: "Timecost",
                            Countries: market.country,
                            functions:market.function,
                            SourceColumn: "",
                            ChildWBSNumber: "",
                            IsSelected: false,
                            toDisplay: false,
                            productIndex: i,
                            isValid: true,
                            OpportunityLineItemID: item.OpportunityLineItemID,
                          });
                        }
                      }
                    });
                  }
                });
                setCostingMarketTable(tableArr);
              }
            
          }
        }
      }
      setIsLoading(false)
    }).catch((error)=>{
      console.log(error)
    })
    }
    setIsLoading(false)
  }, [isOpen] );

  console.log("costingMarketTable", costingMarketTable);

  return (
    <div>
     
      
     {isLoading ?( <div id="pageCoverSpin"></div>):(<Modal isOpen={isOpen} toggle={toggleModal} size="xl">
     
      
     <ModalHeader toggle={toggleModal}>Costing Markets</ModalHeader>
      <ModalBody>
        {costingMarketTable.length ? (
          <div>
            <Row className="mt-3 mb-3">
              <Col lg="3">
                <h5>Material ID</h5>
                <Select
                  classNamePrefix="react-select"
                  options={productOptions}
                  onChange={handleproductchange}
                  value={{ label: productValue }}
                />
              </Col>
              <Col lg="3">
                <h5>Costing Markets</h5>
                <Select
                  classNamePrefix="react-select"
                  options={getMultiOptions(codeLabels.FieldingCountriesOptions)}
                  isMulti
                  isSearchable
                  onChange={handleCountryChange}
                  value={countryList}
                />
              </Col>
              <Col lg="3">
                <h5>Function</h5>
                <Select
                  classNamePrefix="react-select"
                  options={functionOptions}
                  isMulti
                  isSearchable
                  onChange={handleFunctionChange}
                />
              </Col>
              <Col lg="1">
                <Button
                  style={{ position: "relative", top: "25px" }}
                  onClick={handleAddCountry}
                >
                  Add
                </Button>
              </Col>
            </Row>

            <Table responsive hover striped bordered>
              <thead>
                <tr>
                  <th>MaterialID</th>
                  <th>Sub Project Type</th>
                  <th>Parent WBS</th>
                  <th>Source</th>
                  <th>Costing Markets</th>
                  <th>Function</th>
                  <th>Remark</th>
                  <th>Child WBS</th>
                  <th>Selection</th>
                </tr>
              </thead>
              <tbody>
                {costingMarketTable.map((item, index) => {
                  return (
                    <tr>
                      <td style={{ fontWeight: 600 }}>{item.MaterialID}</td>
                      <td>{item.SubProjectType}</td>
                      <td>{item.ParentWBSNumber}</td>
                      <td>{item.Source}</td>
                      <td onBlur={(e) => handleCountryEdit(e, index)} contentEditable={item.Countries === "Pakistan" ||item.Countries === "Russia" ||item.Countries === ""? true: false}>{item.Countries}</td>
                      <td>{item.functions}</td>
                      <td>{item.SourceColumn}</td>
                      <td>
                        <input
                          style={
                            !item.isValid ? { border: "1px solid red" } : {}
                          }
                          type="text"
                          value={item.ChildWBSNumber}
                          onChange={(e) => handleChildWBSchange(e, index)}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={item.IsSelected}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <p>Please select countries in Timecost page</p>
        )}
      </ModalBody>
      <ModalFooter>
        {costingMarketTable.length ? (
          <>
            <Button onClick={handleCostingMarketsSave}>Save</Button>{" "}
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </>
        ) : null}
      </ModalFooter>
      </Modal>)
    }
  
    
    </div>
  );
};

export default CostingMarkets;
