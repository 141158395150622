import * as appActions from "../../redux/actions/appActions";
import axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import { Prompt } from "react-router";
import jspreadsheet from "jspreadsheet-ce";
import "jspreadsheet-ce/dist/jspreadsheet.css";

import React, { useState, useEffect, Suspense } from "react";
import ManualCostEntry from "../costingProfile/ManualCostEntry";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvInput,
  AvRadio,
  AvSelectField,
} from "availity-reactstrap-validation";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  FormFeedback,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
  UncontrolledCollapse,
  Table,
  Container,
} from "reactstrap";
import Spinner from "../../components/Spinner";
import { useSelector } from "react-redux";
import { CustomInput, Input, Label, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { getMultiOptions } from "../../utils/codeLabels";
import classnames from "classnames";
import "./common.css";
import CostingOption from "./Detailed_CostingOptionFinal";
import NewSpreadsheet from "./NewSpreadsheet";
import TestPageRFQ from "./TestPageRFQ";
import CostInput from "./CostInput";
import TestPageOpResource from "./TestOpResource";
import RFQForm from "./TestPageRFQ";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faCopy,
  faPlus,
  faPlusCircle,
  faTimesCircle,
  faFileUpload,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
//import CostingOption from "./CostingOption";
import _ from "lodash";
import Select from "react-select";
import update from "immutability-helper";

import { useHistory, useLocation } from "react-router-dom";
import * as sideBarActions from "../../redux/actions/sidebarActions";
import Settings from "../../components/Settings";
import TestResponsibilities from "./TestResponsibilities";
import TestResponsibilities_internal from "./TestResponsibilities_internal";
import CostSpreadsheet from "./CostSpreadsheet";
import * as mapperFunctions from "../../utils/rfqMapper";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import RecordsSpinner from "../../components/RecordsSpinner";
import CommercialHours from "../costingProfile/CommercialHours";
import { setFormErrorStatus } from "../../redux/actions/currentProjectActions";
import { func } from "prop-types";
import CreatableSelect from "react-select/creatable";
import {
  pageLoadEnd,
  pageLoadStart,
  recordLoadEnd,
  recordLoadStart,
  setCostingStatus,
  valueChangedInRFQ,
} from "../../redux/actions/appActions";
import WaveLevelEdit from "../wavelevel/WaveLevelEdit_internal";
import CommercialHours_internal from "../costingProfile/CommercialHours_internal";

import logo from "../../assets/img/avatars/modal-logo.png";
const CountryMethodologyTabsCopy = ({ updateProject, setOpenNewProfile }) => {
  const location = useLocation();
  const history = useHistory();
  var imgStyle = {
    width: "150px",
  };
  const profile = location.profile;
  const disableFields = location.state;
  const project = location.project;
  const recordloading = useSelector(({ app }) => app.recordloading);
  const localPageload = useSelector(({ app }) => app.localPageload);

  const userRecord = useSelector(({ user }) => user.userRecord);

  const currentCosting = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const currencies = useSelector(({ currentCosting }) =>
    currentCosting.currentCostingProfile.ProfileSetting &&
    currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      : []
  );
  // const project = useSelector(
  //     ({ currentProject }) => currentProject.newProject
  // );

  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const RfqformErrors = useSelector(
    ({ currentProject }) => currentProject.RfqformErrors
  );
  const dispatch = useDispatch();
  const styleModelBody = {
    minHeight: "200px",
  };
  const [profileCopy, setprofileCopy] = useState(profile);
  var isvalid = true;
  const app = useSelector(({ app }) => app);
  const [activeTabFC, setActiveTabFC] = useState(0);
  const [activeTabSM, setActiveTabSM] = useState(0);
  const [modelState, setModalState] = useState(false);
  const [copyProfile, setCopyProfile] = useState({});
  const [spreadSheetData, setSpreadSheetData] = useState(null);
  const [showSpreadSheet, setShowSpreadSheet] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [sideBarOpened, setsideBarOpened] = useState(false);
  const costingStatus = useSelector(({ app }) => app.costingStatus);
  const [tempData, setTempData] = useState(null);
  const [isModalOpen, handleOpen] = useState(false);
  const [waveModal, setwaveModal] = useState(false);
  const [validationHeader, setValidationHeader] = useState(
    "Cannot Save as some Fields are Mandatory!!"
  );
  const [opsResourcesVariables] = useState([
    "surveyProgrammingResource",
    "surveyProgrammingResourceVendor",
    "dataProcessingResource",
    "dataProcessingResourceVendor",
    "dataProcessingComplexity",
    "translationsResource",
    "translationsResourceVendor",
    "verbatimCodingResource",
    "verbatimCodingResourceVendor",
    "chartingResource",
    "chartingResourceVendor",
    "dashboardingResource",
    "dashboardingResourceVendor",
    "qualityControl",
    "qualityControlComments",
    "dataCollectionPlatform",
    "platformComments",
  ]);
  const [modalMethodologiesState, setModalMethodologiesState] = useState(false);
  const [advanceCopying, setAdvanceCopying] = useState({});
  const [modalConfirmMethodology, setModalConfirmMethodology] = useState(false);
  let pacificData = _.head(codeLabels.PacificCountriesList).Label;
  const [pacificMarkets] = useState(pacificData?.split(","));

  const [errorObj, setErrorObj] = useState({});


  const MyComponent = () => (
    <>
      <Prompt
        when={
          shouldBlockNavigation &&
          !sideBarOpened &&
          costingStatus.showCostingInput
        }
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {/* Component JSX */}
    </>
  );

  useEffect(() => {
    if (_.isEqual(profileCopy, profile)) {
      setShouldBlockNavigation(false);
    } else {
      setShouldBlockNavigation(true);
    }
  }, [profile]);

  useEffect(() => {
    if (costingStatus.showCostingInput) dispatch(sideBarActions.hideSidebar());
  }, [costingStatus]);

  useEffect(() => {
    if (
      profile.CountrySpecs?.length &&
      _.head(profile.CountrySpecs)?.MethodologySpecs?.length
    )
      populateSideBarLinks(
        _.head(_.head(profile.CountrySpecs).MethodologySpecs)?.Variables
      );
  }, [profile.CountrySpecs]);
  useEffect(() => {
    if (!tempData) return;
    let cloneSpreadsheetData = _.cloneDeep(spreadSheetData);
    if (tempData.type == "spreadsheet") {
      cloneSpreadsheetData[tempData.index].options.data[tempData.rowIndex][
        tempData.key
      ] = tempData.value;
      if (tempData.fKey)
        cloneSpreadsheetData[tempData.index].options.data[tempData.rowIndex][
          tempData.fKey
        ] = tempData.fValue;
    } else if (tempData.type == "mainstats") {
      cloneSpreadsheetData[tempData.index].options.mainStatsData[
        tempData.rowIndex
      ].data[tempData.colIndex - 1].value = tempData.value;
    }
    setSpreadSheetData(cloneSpreadsheetData);
  }, [tempData]);

  function populateSideBarLinks(variables) {
    if (variables) {
      dispatch(
        sideBarActions.addCostingOptionsLink(
          "#costing_options_card_" + activeTabFC + "_" + activeTabSM
        )
      );
      dispatch(
        sideBarActions.addSudOptionsLink(
          "#sud_options_card_" + activeTabFC + "_" + activeTabSM
        )
      );

      if (profile.CountrySpecs.length > 1)
        dispatch(
          sideBarActions.resOptionsLink(
            "#res_options_card_" + activeTabFC + "_" + activeTabSM
          )
        );
      else dispatch(sideBarActions.resOptionsLink(""));

      let links = [];
      variables.forEach((x, index) => {
        if (x.isNewSection)
          links.push({ label: x.sectionTitle, value: "#header_" + index });
      });
      dispatch(sideBarActions.addSidebarLinks(links));
    }
  }
  useEffect(() => {
    if (profile.CountrySpecs && profile.CountrySpecs.length > 0) {
      let allMeth = _.head(profile.CountrySpecs)?.MethodologySpecs?.map(
        (m, mIndex) => ({
          methodology: m,
          index: mIndex,
        })
      );
      let reqMeth = allMeth.find((x) => !x.methodology.NotApplicable);
      if (!reqMeth) reqMeth = _.head(allMeth);
      setActiveTabSM(reqMeth.index);
    }
  }, []);

  function toggle(tab) {
    if (activeTabFC !== tab) {
      setActiveTabFC(tab);
      let allMeth = profile.CountrySpecs[tab].MethodologySpecs.map(
        (m, mIndex) => ({
          methodology: m,
          index: mIndex,
        })
      );
      let reqMeth = allMeth.find((x) => !x.methodology.NotApplicable);
      if (!reqMeth) reqMeth = _.head(allMeth);

      setActiveTabSM(reqMeth.index);
    }
    populateSideBarLinks(
      profile.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]
        ?.Variables
    );
  }

  function toggleSM(tab) {
    if (activeTabSM !== tab) {
      setActiveTabSM(tab);
    }
    populateSideBarLinks(
      profile.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]
        ?.Variables
    );
  }


  function CopyAsIsClicked() {
    const methodologyName =
      profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[
        copyProfile.indexSM
      ].label;
    const Countries = _.cloneDeep(
      profile.CountrySpecs.filter(
        (x, index) =>
          index != copyProfile.indexFC &&
          x.MethodologySpecs.find((y) => y.label == methodologyName)
      ).map((fc) => ({ label: fc.label, value: fc.id }))
    );

    setCopyProfile((prevState) => ({
      ...prevState,
      Mode: "CopyAsIs",
      SelectedCostingOptions: _.cloneDeep(copyProfile.CostingOptions),
      SelectedCountries: _.cloneDeep(Countries),
    }));
  }
  const updateProjectAndProfileWRTCostingOption = (
    profile,
    tempCountrySpecs,
    notReload
  ) => {
    let profileTemp = _.cloneDeep(profile);
    profileTemp.CountrySpecs = _.cloneDeep(tempCountrySpecs);

    const tempProfiles = project.CostingProfiles.map((cp) => {
      if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
      return { ..._.cloneDeep(cp) };
    });

    dispatch(currentCostingActions.updateProfile(profileTemp));
    if (!notReload) {
      let tempReloadForms = project.ReloadForms == true ? false : true;
      dispatch(
        updateProject({
          CostingProfiles: tempProfiles,
          ReloadForms: tempReloadForms,
        })
      );
    } else {
      dispatch(updateProject({ CostingProfiles: tempProfiles }));
    }
  };
  function CopyProfileActionClicked(back = null) {
    if (back && (copyProfile.Mode == "First" || copyProfile.Mode == "CopyAsIs"))
      setCopyProfile((prevState) => ({
        ...prevState,
        Mode: "Selection",
      }));
    else if (back && copyProfile.Mode == "Second")
      setCopyProfile((prevState) => ({
        ...prevState,
        Mode: "First",
      }));
    else if (back && copyProfile.Mode == "Save")
      setCopyProfile((prevState) => ({
        ...prevState,
        Mode: "Second",
      }));
    else if (copyProfile.Mode == "First") {
      if (
        !copyProfile.SelectedCostingOptions ||
        !copyProfile.SelectedCountries
      ) {
        toastr.error("Error", "Please select required options");
        return;
      }
      let tempCountryWiseCostingOptions = [];
      copyProfile.SelectedCostingOptions.forEach((co, indexCO) => {
        copyProfile.SelectedCountries.forEach((country, indexCountry) => {
          const tempMethodologies = _.cloneDeep(
            profile.CountrySpecs.filter(
              (fc) => fc.id == country.value
            )[0].MethodologySpecs.map((m) => ({
              label: m.label,
              value: m.Code,
            }))
          );

          tempCountryWiseCostingOptions.push({
            CostingOption: co,
            Country: country,
            Methodologies: tempMethodologies,
          });
        });
      });
      setCopyProfile((prevState) => ({
        ...prevState,
        CountryWiseCostingOptions: tempCountryWiseCostingOptions,
        Mode: "Second",
      }));
    } else if (copyProfile.Mode == "Second") {
      let error2 = null;
      const label1 =
        profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[
          copyProfile.indexSM
        ].label;

      if (
        label1 != "Path Tracking" &&
        label1 != "Eye Tracking" &&
        label1 != "Smart Store VR"
      ) {
        copyProfile.CountryWiseCostingOptions.forEach((x) =>
          x.SelectedMethodologies.map((m) => {
            if (
              m.label == "Path Tracking" ||
              m.label == "Eye Tracking" ||
              m.label == "Smart Store VR"
            ) {
              error2 = true;
            }
          })
        );
      }

      if (error2) {
        toastr.error(
          "Error",
          "This Methodology Can not be Copied to Path Tracking,Eye Tracking,Smart Store VR"
        );
        return;
      }

      let error = null;
      copyProfile.CountryWiseCostingOptions.forEach((x) => {
        if (!x.SelectedMethodologies) error = true;
      });
      if (error) {
        toastr.error("Error", "Please select required options");
        return;
      }

      let srcCountry = profile.CountrySpecs[copyProfile.indexFC];
      let SelectedCountries = _.cloneDeep(copyProfile.SelectedCountries);
      //             copyProfile.CountryWiseCostingOptions.forEach((country, indexC) => {
      //                 let tempMethodologies = [];
      //                 let colSpanCountry = 0;
      //                 country.SelectedMethodologies.forEach((sm,indexM)=>
      //                 {
      //                     let objMethodology = _.cloneDeep(sm);
      //                     objMethodology["CostingOptions"] = [];
      //                     objMethodology["ColSpan"] = null;
      //                     let variables=[];

      //                     let destCostingOptions = _.cloneDeep(profile.CountrySpecs.filter(c=>c.id==country.Country.value)[0].MethodologySpecs.filter(m=> m.Code==sm.value)[0].CostingOptions[0]);
      //                     let cloneCostingOptions = _.cloneDeep(country.CostingOption);
      //                     cloneCostingOptions.Variables.forEach(sOption=>{
      //                         if(destCostingOptions.Variables.find(dOption=> dOption==sOption))
      //                         objMethodology["CostingOptions"].push(sOption);
      //                     } )
      //                     objMethodology["ColSpan"] = objMethodology["CostingOptions"].length;
      //                     tempMethodologies.push(objMethodology);
      //                     colSpanCountry += objMethodology["CostingOptions"].length;
      //                 }
      //                 )
      //                 country.Methodologies = tempMethodologies;
      //                 country["ColSpan"] = colSpanCountry;
      //             });

      SelectedCountries.forEach((country, indexC) => {
        let tempMethodologies = [];
        let colSpanCountry = 0;

        copyProfile.CountryWiseCostingOptions.filter(
          (cwco, indexCWCO) => cwco.Country.value == country.value
        ).forEach((fcwco, indexFCWCO) => {
          fcwco.SelectedMethodologies.forEach((methodology, indexM) => {
            //let destMethodology=_.cloneDeep(cwco);
            if (!tempMethodologies.find((m) => m.value == methodology.value)) {
              let objMethodology = _.cloneDeep(methodology);
              objMethodology["CostingOptions"] = [];
              objMethodology["ColSpan"] = null;
              copyProfile.CountryWiseCostingOptions.filter(
                (cwco_2, indexCWCO_2) =>
                  cwco_2.Country.value == country.value &&
                  cwco_2.SelectedMethodologies.find(
                    (m_2) => m_2.value == objMethodology.value
                  )
              ).forEach((fcwco_2, indexFCWCO_2) => {
                let destCostingOptions = _.cloneDeep(
                  profile.CountrySpecs.filter(
                    (c) => c.id == country.value
                  )[0].MethodologySpecs.filter(
                    (m) => m.Code == methodology.value
                  )[0].CostingOptions[0].Variables
                );
                let cloneCostingOptions = _.cloneDeep(fcwco_2.CostingOption);
                cloneCostingOptions.Variables.forEach((cVar) => {
                  if (
                    !destCostingOptions.find((dVar) => dVar.label == cVar.label)
                  ) {
                    // Display the CostingOptions
                    // cVar.disabled = true;
                    // cVar.ishidden = true;
                    // cVar.value = null;
                  } else if (
                    destCostingOptions.find(
                      (dVar) =>
                        dVar.label == cVar.label &&
                        JSON.stringify(cVar.options) !=
                          JSON.stringify(dVar.options)
                    )
                  ) {
                    // cVar.value = null;
                    cVar.options = destCostingOptions.filter(
                      (dVar) =>
                        dVar.label == cVar.label && cVar.options != dVar.options
                    )[0].options;
                  }
                });

                if (country.label != srcCountry.label) {
                  cloneCostingOptions.Variables.filter(
                    (cov) => cov.id == "citiesCoverage"
                  )[0].value = null;
                }

                objMethodology["CostingOptions"].push(cloneCostingOptions);
              });

              objMethodology["ColSpan"] =
                objMethodology["CostingOptions"].length;
              tempMethodologies.push(objMethodology);
              colSpanCountry += objMethodology["CostingOptions"].length;
            }
          });
        });
        country.Methodologies = tempMethodologies;
        country["ColSpan"] = colSpanCountry;
      });

      setCopyProfile((prevState) => ({
        ...prevState,
        SelectedCountries: SelectedCountries,
        Mode: "Save",
      }));
    } else if (copyProfile.Mode == "Save") {
      copyProfile.SelectedCountries.map((m) => {
        if (
          profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[
            copyProfile.indexSM
          ].label == "Path Tracking"
        ) {
          if (
            m.Methodologies.length == 1 &&
            m.Methodologies[0].label == "Path Tracking"
          ) {
            const tempCountrySpecs = _.cloneDeep(profile.CountrySpecs);
            copyProfile.SelectedCountries.forEach((sc, indexSC) => {
              sc.Methodologies.forEach((m, indexM) => {
                m.CostingOptions.forEach((co, indexCO) => {
                  const tempMethodology = tempCountrySpecs
                    .filter((fc) => fc.label == sc.label)[0]
                    .MethodologySpecs.filter((sm) => sm.label == m.label)[0];
                  const tempCostingOptions = tempCountrySpecs
                    .filter((fc) => fc.label == sc.label)[0]
                    .MethodologySpecs.filter(
                      (sm) => sm.label == m.label
                    )[0].CostingOptions;
                  const tempIndexCO = tempCostingOptions.findIndex(
                    (tempCO) => tempCO.label == co.label
                  );

                  co.Variables.forEach((v, indexV) => {
                    if (!v.Default) {
                      let found = false;
                      if (
                        tempMethodology.Variables.filter((mv) => mv.id == v.id)
                          .length > 0
                      ) {
                        found = true;
                        let objVar = tempMethodology.Variables.filter(
                          (mv) => mv.id == v.id
                        )[0];
                        objVar.selected = true;
                        objVar.value = null;
                      } else if (
                        tempMethodology.OpResourcesVariables.filter(
                          (mv) =>
                            mv.id == v.id ||
                            v.label == "RFQ Form" ||
                            v.label == "Setup & Delivery"
                        ).length > 0
                      ) {
                        found = true;
                        let objOpsVar =
                          tempMethodology.OpResourcesVariables.filter(
                            (mv) =>
                              mv.id == v.id ||
                              v.label == "RFQ Form" ||
                              v.label == "Setup & Delivery"
                          )[0];
                        objOpsVar.selected = true;
                        objOpsVar.value = null;
                      }
                      if (!found) v.NotFound = true;
                    }
                  });

                  co.Variables = co.Variables.filter((v) => v.NotFound != true);

                  if (tempIndexCO >= 0) {
                    const objCO = tempCostingOptions[tempIndexCO];
                    co.Variables.forEach((v, indexV) => {
                      const indexObjV = objCO.Variables.findIndex(
                        (objV) => objV.label == v.label
                      );
                      if (indexObjV >= 0)
                        objCO.Variables[indexObjV].value = v.value;
                      else if (v.label != "Sample Source") {
                        objCO.Variables.push(_.cloneDeep(v));
                      }
                    });
                  } else {
                    let objCO = _.cloneDeep(co);
                    delete objCO.id;
                    tempCostingOptions.push(objCO);
                  }
                  let deletedIndex = [];
                  tempCostingOptions.forEach((delco, decoindex) => {
                    let tempDelete = delco.Variables.filter(
                      (xo) => !xo.value
                    ).length;
                    if (tempDelete == delco.Variables.length)
                      deletedIndex.push(decoindex);
                    //delco.active=false;
                  });
                  let deletedIndexSort = deletedIndex.sort(function (a, b) {
                    return b - a;
                  });
                  deletedIndexSort.forEach((element) => {
                    tempCostingOptions.splice(element, 1);
                  });
                });
              });
            });

            // let profileTemp = update(profile, {
            //     CountrySpecs: { $set: tempCountrySpecs }
            // });
            updateProjectAndProfileWRTCostingOption(profile, tempCountrySpecs);
            setModalState(false);
            toastr.success("Success", "Data Copied Successfully");
          } else {
            toastr.error(
              "Error",
              "Can Not Copy Path Tracking to Other Methodology "
            );
            return;
          }
        } else if (
          profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[
            copyProfile.indexSM
          ].label == "Eye Tracking"
        ) {
          if (
            m.Methodologies.length == 1 &&
            m.Methodologies[0].label == "Eye Tracking"
          ) {
            const tempCountrySpecs = _.cloneDeep(profile.CountrySpecs);
            copyProfile.SelectedCountries.forEach((sc, indexSC) => {
              sc.Methodologies.forEach((m, indexM) => {
                m.CostingOptions.forEach((co, indexCO) => {
                  const tempMethodology = tempCountrySpecs
                    .filter((fc) => fc.label == sc.label)[0]
                    .MethodologySpecs.filter((sm) => sm.label == m.label)[0];
                  const tempCostingOptions = tempCountrySpecs
                    .filter((fc) => fc.label == sc.label)[0]
                    .MethodologySpecs.filter(
                      (sm) => sm.label == m.label
                    )[0].CostingOptions;
                  const tempIndexCO = tempCostingOptions.findIndex(
                    (tempCO) => tempCO.label == co.label
                  );

                  co.Variables.forEach((v, indexV) => {
                    if (!v.Default) {
                      let found = false;
                      if (
                        tempMethodology.Variables.filter((mv) => mv.id == v.id)
                          .length > 0
                      ) {
                        found = true;
                        let objVar = tempMethodology.Variables.filter(
                          (mv) => mv.id == v.id
                        )[0];
                        objVar.selected = true;
                        objVar.value = null;
                      } else if (
                        tempMethodology.OpResourcesVariables.filter(
                          (mv) =>
                            mv.id == v.id ||
                            v.label == "RFQ Form" ||
                            v.label == "Setup & Delivery"
                        ).length > 0
                      ) {
                        found = true;
                        let objOpsVar =
                          tempMethodology.OpResourcesVariables.filter(
                            (mv) =>
                              mv.id == v.id ||
                              v.label == "RFQ Form" ||
                              v.label == "Setup & Delivery"
                          )[0];
                        objOpsVar.selected = true;
                        objOpsVar.value = null;
                      }
                      if (!found) v.NotFound = true;
                    }
                  });

                  co.Variables = co.Variables.filter((v) => v.NotFound != true);

                  if (tempIndexCO >= 0) {
                    const objCO = tempCostingOptions[tempIndexCO];
                    co.Variables.forEach((v, indexV) => {
                      const indexObjV = objCO.Variables.findIndex(
                        (objV) => objV.label == v.label
                      );
                      if (indexObjV >= 0)
                        objCO.Variables[indexObjV].value = v.value;
                      else if (v.label != "Sample Source") {
                        objCO.Variables.push(_.cloneDeep(v));
                      }
                    });
                  } else {
                    let objCO = _.cloneDeep(co);
                    delete objCO.id;
                    tempCostingOptions.push(objCO);
                  }
                  let deletedIndex = [];
                  tempCostingOptions.forEach((delco, decoindex) => {
                    let tempDelete = delco.Variables.filter(
                      (xo) => !xo.value
                    ).length;
                    if (tempDelete == delco.Variables.length)
                      deletedIndex.push(decoindex);
                    //delco.active=false;
                  });
                  let deletedIndexSort = deletedIndex.sort(function (a, b) {
                    return b - a;
                  });
                  deletedIndexSort.forEach((element) => {
                    tempCostingOptions.splice(element, 1);
                  });
                });
              });
            });

            updateProjectAndProfileWRTCostingOption(profile, tempCountrySpecs);

            setModalState(false);
            toastr.success("Success", "Data Copied Successfully");
          } else {
            toastr.error(
              "Error",
              "Can Not Copy Eye Tracking to Other Methodology"
            );
            return;
          }
        } else if (
          profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[
            copyProfile.indexSM
          ].label == "Smart Store VR"
        ) {
          if (
            m.Methodologies.length == 1 &&
            m.Methodologies[0].label == "Smart Store VR"
          ) {
            const tempCountrySpecs = _.cloneDeep(profile.CountrySpecs);
            copyProfile.SelectedCountries.forEach((sc, indexSC) => {
              sc.Methodologies.forEach((m, indexM) => {
                m.CostingOptions.forEach((co, indexCO) => {
                  const tempMethodology = tempCountrySpecs
                    .filter((fc) => fc.label == sc.label)[0]
                    .MethodologySpecs.filter((sm) => sm.label == m.label)[0];
                  const tempCostingOptions = tempCountrySpecs
                    .filter((fc) => fc.label == sc.label)[0]
                    .MethodologySpecs.filter(
                      (sm) => sm.label == m.label
                    )[0].CostingOptions;
                  const tempIndexCO = tempCostingOptions.findIndex(
                    (tempCO) => tempCO.label == co.label
                  );

                  co.Variables.forEach((v, indexV) => {
                    if (!v.Default) {
                      let found = false;
                      if (
                        tempMethodology.Variables.filter((mv) => mv.id == v.id)
                          .length > 0
                      ) {
                        found = true;
                        let objVar = tempMethodology.Variables.filter(
                          (mv) => mv.id == v.id
                        )[0];
                        objVar.selected = true;
                        objVar.value = null;
                      } else if (
                        tempMethodology.OpResourcesVariables.filter(
                          (mv) =>
                            mv.id == v.id ||
                            v.label == "RFQ Form" ||
                            v.label == "Setup & Delivery"
                        ).length > 0
                      ) {
                        found = true;
                        let objOpsVar =
                          tempMethodology.OpResourcesVariables.filter(
                            (mv) =>
                              mv.id == v.id ||
                              v.label == "RFQ Form" ||
                              v.label == "Setup & Delivery"
                          )[0];
                        objOpsVar.selected = true;
                        objOpsVar.value = null;
                      }
                      if (!found) v.NotFound = true;
                    }
                  });

                  co.Variables = co.Variables.filter((v) => v.NotFound != true);

                  if (tempIndexCO >= 0) {
                    const objCO = tempCostingOptions[tempIndexCO];
                    co.Variables.forEach((v, indexV) => {
                      const indexObjV = objCO.Variables.findIndex(
                        (objV) => objV.label == v.label
                      );
                      if (indexObjV >= 0)
                        objCO.Variables[indexObjV].value = v.value;
                      else if (v.label != "Sample Source") {
                        objCO.Variables.push(_.cloneDeep(v));
                      }
                    });
                  } else {
                    let objCO = _.cloneDeep(co);
                    delete objCO.id;
                    tempCostingOptions.push(objCO);
                  }
                  let deletedIndex = [];
                  tempCostingOptions.forEach((delco, decoindex) => {
                    let tempDelete = delco.Variables.filter(
                      (xo) => !xo.value
                    ).length;
                    if (tempDelete == delco.Variables.length)
                      deletedIndex.push(decoindex);
                    //delco.active=false;
                  });
                  let deletedIndexSort = deletedIndex.sort(function (a, b) {
                    return b - a;
                  });
                  deletedIndexSort.forEach((element) => {
                    tempCostingOptions.splice(element, 1);
                  });
                });
              });
            });

            updateProjectAndProfileWRTCostingOption(profile, tempCountrySpecs);

            setModalState(false);
            toastr.success("Success", "Data Copied Successfully");
          } else {
            toastr.error(
              "Error",
              "Can Not Copy Smart Store to Other Methodology"
            );
            return;
          }
        } else {
          const tempCountrySpecs = _.cloneDeep(profile.CountrySpecs);
          copyProfile.SelectedCountries.forEach((sc, indexSC) => {
            sc.Methodologies.forEach((m, indexM) => {
              m.CostingOptions.forEach((co, indexCO) => {
                const tempMethodology = tempCountrySpecs
                  .filter((fc) => fc.label == sc.label)[0]
                  .MethodologySpecs.filter((sm) => sm.label == m.label)[0];
                const tempCostingOptions = tempCountrySpecs
                  .filter((fc) => fc.label == sc.label)[0]
                  .MethodologySpecs.filter(
                    (sm) => sm.label == m.label
                  )[0].CostingOptions;
                const tempIndexCO = tempCostingOptions.findIndex(
                  (tempCO) => tempCO.label == co.label
                );

                co.Variables.forEach((v, indexV) => {
                  if (!v.Default) {
                    let found = false;
                    if (
                      tempMethodology.Variables.filter((mv) => mv.id == v.id)
                        .length > 0
                    ) {
                      found = true;
                      let objVar = tempMethodology.Variables.filter(
                        (mv) => mv.id == v.id
                      )[0];
                      objVar.selected = true;
                      objVar.value = null;
                    } else if (
                      tempMethodology.OpResourcesVariables.filter(
                        (mv) =>
                          mv.id == v.id ||
                          v.label == "RFQ Form" ||
                          v.label == "Setup & Delivery"
                      ).length > 0
                    ) {
                      found = true;
                      let objOpsVar =
                        tempMethodology.OpResourcesVariables.filter(
                          (mv) =>
                            mv.id == v.id ||
                            v.label == "RFQ Form" ||
                            v.label == "Setup & Delivery"
                        )[0];
                      objOpsVar.selected = true;
                      objOpsVar.value = null;
                    }
                    if (!found) v.NotFound = true;
                  }
                });

                co.Variables = co.Variables.filter((v) => v.NotFound != true);

                if (tempIndexCO >= 0) {
                  const objCO = tempCostingOptions[tempIndexCO];
                  co.Variables.forEach((v, indexV) => {
                    const indexObjV = objCO.Variables.findIndex(
                      (objV) => objV.label == v.label
                    );
                    if (indexObjV >= 0)
                      objCO.Variables[indexObjV].value = v.value;
                    else if (v.label != "Sample Source") {
                      objCO.Variables.push(_.cloneDeep(v));
                    }
                  });
                } else {
                  let objCO = _.cloneDeep(co);
                  delete objCO.id;
                  tempCostingOptions.push(objCO);
                }
                let deletedIndex = [];
                tempCostingOptions.forEach((delco, decoindex) => {
                  let tempDelete = delco.Variables.filter(
                    (xo) => !xo.value
                  ).length;
                  if (tempDelete == delco.Variables.length)
                    deletedIndex.push(decoindex);
                  //delco.active=false;
                });
                let deletedIndexSort = deletedIndex.sort(function (a, b) {
                  return b - a;
                });
                deletedIndexSort.forEach((element) => {
                  tempCostingOptions.splice(element, 1);
                });
              });
            });
          });

          updateProjectAndProfileWRTCostingOption(profile, tempCountrySpecs);

          setModalState(false);
          toastr.success("Success", "Data Copied Successfully");
        }
      });
    } else if (copyProfile.Mode == "CopyAsIs") {
      if (
        !copyProfile.SelectedCountries ||
        !copyProfile.SelectedCostingOptions
      ) {
        toastr.error("Error", "Please select required options");
        return;
      }
      const tempFC = _.cloneDeep(profile.CountrySpecs);
      const methodology =
        profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[
          copyProfile.indexSM
        ];

      copyProfile.SelectedCountries.forEach((sc, indexSC) => {
        profile.CountrySpecs.filter((x) => x.label == sc.label)[0]
          .MethodologySpecs.filter((x) => x.label == methodology.label)
          .forEach((m, indexM) => {
            methodology.CostingOptions.forEach((co, indexCO) => {
              const tempCostingOptions = tempFC
                .filter((fc) => fc.label == sc.label)[0]
                .MethodologySpecs.filter(
                  (sm) => sm.label == m.label
                )[0].CostingOptions;
              if (
                !copyProfile.SelectedCostingOptions.find(
                  (x) => x.label == co.label
                )
              )
                return;
              const tempIndexCO = tempCostingOptions.findIndex(
                (tempCO) => tempCO.label == co.label
              );
              //new code
              const tempMethodology = tempFC
                .filter((fc) => fc.label == sc.label)[0]
                .MethodologySpecs.filter((sm) => sm.label == m.label)[0];
              co.Variables.forEach((v, indexV) => {
                if (!v.Default) {
                  let found = false;
                  if (
                    tempMethodology.Variables.filter((mv) => mv.id == v.id)
                      .length > 0
                  ) {
                    found = true;
                    let objVar = tempMethodology.Variables.filter(
                      (mv) => mv.id == v.id
                    )[0];
                    objVar.selected = true;
                    objVar.value = null;
                  } else if (
                    tempMethodology.OpResourcesVariables.filter(
                      (mv) =>
                        mv.id == v.id ||
                        v.label == "RFQ Form" ||
                        v.label == "Setup & Delivery"
                    ).length > 0
                  ) {
                    found = true;
                    let objOpsVar = tempMethodology.OpResourcesVariables.filter(
                      (mv) =>
                        mv.id == v.id ||
                        v.label == "RFQ Form" ||
                        v.label == "Setup & Delivery"
                    )[0];
                    objOpsVar.selected = true;
                    objOpsVar.value = null;
                  }
                  if (!found) v.NotFound = true;
                }
              });
              co.Variables = co.Variables.filter((v) => v.NotFound != true);
              //new code ends

              if (tempIndexCO >= 0) {
                const objCO = tempCostingOptions[tempIndexCO];
                co.Variables.forEach((v, indexV) => {
                  const indexObjV = objCO.Variables.findIndex(
                    (objV) => objV.label == v.label
                  );
                  if (indexObjV >= 0) {
                    objCO.Variables[indexObjV].value = v.value;
                    objCO.Variables[indexObjV].disabled = v.disabled;
                  } else objCO.Variables.push(_.cloneDeep(v));
                });
              } else {
                const objCO = _.cloneDeep(co);
                delete objCO.id;
                tempCostingOptions.push(objCO);
              }
              let deletedIndex = [];
              tempCostingOptions.forEach((delco, decoindex) => {
                let tempDelete = delco.Variables.filter(
                  (xo) => !xo.value
                ).length;
                if (tempDelete == delco.Variables.length)
                  deletedIndex.push(decoindex);
                //delco.active=false;
              });
              let deletedIndexSort = deletedIndex.sort(function (a, b) {
                return b - a;
              });
              deletedIndexSort.forEach((element) => {
                tempCostingOptions.splice(element, 1);
              });
            });
          });
      });

      updateProjectAndProfileWRTCostingOption(profile, tempFC, true);

      setModalState(false);
      toastr.success("Success", "Data Copied Successfully");
    }
  }

  const ReturnControl = (indexV, indexC, indexM, indexCO) => {
    const v =
      copyProfile.SelectedCountries[indexC].Methodologies[indexM]
        .CostingOptions[indexCO].Variables[indexV] || {};

    if (v.type == "integer")
      return (
        <input
          type="number"
          name={v.label}
          value={v.value}
          style={{ width: "100%" }}
          onChange={(e) =>
            InfoChange(indexC, indexM, indexCO, e.target.name, e.target.value)
          }
        />
      );
    else if (v.type == "text")
      return (
        <input
          type="text"
          name={v.label}
          value={v.value}
          style={{ width: "100%" }}
          onChange={(e) =>
            InfoChange(indexC, indexM, indexCO, e.target.name, e.target.value)
          }
        />
      );
    else if (v.type == "textarea")
      return (
        <input
          type="textarea"
          name={v.label}
          value={v.value}
          style={{ width: "100%" }}
          onChange={(e) =>
            InfoChange(indexC, indexM, indexCO, e.target.name, e.target.value)
          }
        />
      );
    else if (v.type == "single")
      return (
        <AvField
          tag={CustomInput}
          name={v.label}
          disabled={v.disabled || disableFields}
          value={
            v.value
              ? typeof v.value === "object"
                ? v.value.value
                : v.value
              : null
          }
          type="select"
          onChange={(e) =>
            InfoChange(indexC, indexM, indexCO, v.label, e.target.value)
          }
        >
          <option value="0"></option>
          {v.optionsLabel
            ? codeLabels[v.optionsLabel].map((opt) => (
                <option key={opt.Code} value={opt.Code}>
                  {opt.Label}
                </option>
              ))
            : v.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
        </AvField>
      );
    else if (v.type == "multi" && v.optionsLabel && v.optionsLabel == "Cities")
      return (
        <>
          {codeLabels["UserScopeOptions"].filter(
            (x) => x.Code == profile.CountrySpecs[indexC].CountryCode
          )[0] ? (
            <Select
              name={v.label}
              value={v.value}
              onChange={(e) => InfoChange(indexC, indexM, indexCO, v.label, e)}
              className="react-select-container"
              classNamePrefix="react-select"
              options={codeLabels["UserScopeOptions"]
                .filter(
                  (x) => x.Code == profile.CountrySpecs[indexC].CountryCode
                )[0]
                ["Cities"].map((opt) => {
                  return {
                    value: opt.id,
                    label: opt.Label,
                  };
                })}
              isMulti
            />
          ) : (
            "No City Found"
          )}
        </>
      );
    else if (
      v.type == "creatableMulti" &&
      v.optionsLabel &&
      v.optionsLabel == "Cities"
    )
      return (
        <>
          {codeLabels["UserScopeOptions"].filter(
            (x) => x.Code == profile.CountrySpecs[indexC].CountryCode
          )[0] ? (
            <CreatableSelect
              className="react-select-container"
              classNamePrefix="react-select"
              value={v.value}
              isMulti
              onChange={(e) => InfoChange(indexC, indexM, indexCO, v.label, e)}
              options={codeLabels["UserScopeOptions"]
                .filter(
                  (x) => x.Code == profile.CountrySpecs[indexC].CountryCode
                )[0]
                ["Cities"].map((opt) => {
                  return {
                    value: opt.id,
                    label: opt.Label,
                  };
                })}
            />
          ) : (
            "No City Found"
          )}
        </>
      );
    else if (v.type == "multi") {
      return (
        <div>
          <Select
            name={v.label}
            value={v.value}
            onChange={(e) => InfoChange(indexC, indexM, indexCO, v.label, e)}
            className="react-select-container"
            classNamePrefix="react-select"
            options={
              v.optionsLabel
                ? codeLabels[v.optionsLabel].map((opt) => {
                    return {
                      value: opt.Code,
                      label: opt.Label,
                    };
                  })
                : v.options.map((option) => {
                    return {
                      value: option.value,
                      label: option.label,
                    };
                  })
            }
            isMulti
          />
        </div>
      );
    }
  };

  function InfoChange(indexC, indexM, indexCO, name, value) {
    const VariablesClone = _.cloneDeep(
      copyProfile.SelectedCountries[indexC].Methodologies[indexM]
        .CostingOptions[indexCO].Variables
    );
    VariablesClone.filter((x) => x.label == name)[0].value = value;
    // let profileTemp = update(copyProfile, {
    //     SelectedCountries: {
    //         [indexC]: {
    //             Methodologies:
    //             {
    //                 [indexM]:
    //                 {
    //                     CostingOptions:
    //                     {
    //                         [indexCO]:
    //                         {
    //                             Variables:
    //                                 { $set: VariablesClone }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // });

    let profileTemp = _.cloneDeep(copyProfile);
    if (
      profile?.SelectedCountries[indexC]?.Methodologies[indexM]?.CostingOptions[
        indexCO
      ]
    )
      profile.SelectedCountries[indexC].Methodologies[indexM].CostingOptions[
        indexCO
      ].Variables = VariablesClone;
    setCopyProfile(profileTemp);
  }


  function getCostingSchema() {
    dispatch(appActions.recordLoadStart());
    axios
      .get("/costingtemplates/FetchByProfile?CostingProfileId=" + profile.id, {
        headers: { "auth-token": localStorage.getItem("auth-token") },
      })
      .then((res) => {
        displayCosting(null, res.data.items);
        setShowSpreadSheet(true);
      })
      .catch((err) => {
        toastr.error("Save Failed", err?.data?.error);
        dispatch(appActions.recordLoadEnd());
      });
  }

  function displayCosting(savedData, spreadSheetSchema) {
    if (savedData) {
      setSpreadSheetData(savedData);
      return;
    }
    let commCountry = profile.Project.CommissioningCountry;
    let userIsOperation = true;
    let tempSpreadSheetData = [];
    let tempDistinctMethodologies = [];
    profile.CountrySpecs.forEach((cs) => {
      cs.MethodologySpecs.forEach((ms) => {
        if (!tempDistinctMethodologies.find((m) => m.Code == ms.Code))
          tempDistinctMethodologies.push({
            Code: ms.Code,
            value: ms.id,
            label: ms.label,
          });
      });
    });

    tempDistinctMethodologies.forEach((sm) => {
      let internalParameters = spreadSheetSchema.filter(
        (ss) =>
          !ss.CountryMethodology ||
          ss.CountryMethodology.find(
            (cm) =>
              profile.CountrySpecs.find(
                (cs) => cs.CountryCode == cm.CountryCode
              ) && cm.Methodologies.find((m) => m == sm.Code)
          )
      );

      internalParameters.forEach((x, i) => {
        x.value = i + 1;
      });

      let variablesCO = [];
      let columns = [
        {
          type: "text",
          title: "Costing Option",
          name: "description",
          width: 400,
        },
      ];
      let data = [];
      let nestedHeaders = [];
      let count = 1;
      let countryHeader = [{ title: "Description" }];
      let style = {};
      let countData = 1;
      let readonly = [];
      let mainStats = [];
      let mainStatsVar = [
        { name: "Selected", type: "checkbox" },
        { name: "Currency", options: [], type: "select" },
        {
          name: "COMMISSIONING COUNTRY / FIELDING COUNTRY",
          disabled: true,
          options: [
            { label: "Commissioning Country", value: 1 },
            { label: "Fielding Country", value: 2 },
          ],
          type: "text",
        },
        {
          name: "CPI to client  (Total Cost / Sample Size)",
          disabled: true,
          type: "text",
        },
        { name: "Ops CPI  (Without Tax/VAT)", disabled: true, type: "text" },
        { name: "Ops CPI  (With Tax/VAT)", disabled: true, type: "text" },
        {
          name: "COST SUBMITTED BY (PLEASE INPUT EMAIL CONTACT HERE)",
          disabled: true,
          type: "text",
        },
        {
          name: "INTERNAL FW OR EXTERNAL FW  (VENDOR)",
          options: [
            { label: "Internal FW", value: 1 },
            { label: "External FW", value: 2 },
            { label: "Vendor FW with local support", value: 3 },
          ],
          type: "select",
        },
        {
          name: "IF EXTERNAL - PLS SPECIFY VENDOR NAME  [MANDATORY]",
          type: "text",
        },
      ];
      let mainStatsData = [];
      let coVariableLength = null;

      profile.CountrySpecs.forEach((fc) => {
        let val = fc.CountryCode == commCountry ? "Commissioning" : "Fielding";
        let fcAutoSelect = val; //{value:val,label:val};
        //Costing options
        let cos = fc.MethodologySpecs.filter((smi) => smi.Code == sm.Code)[0]
          .CostingOptions;
        count += cos.length;
        coVariableLength = cos[0].Variables.length;
        for (var i = 0; i < cos.length; i++) {
          columns.push({
            type: "numeric",
            title: cos[i].label,
            name: "co_" + (i + 1) + "_" + fc.CountryCode + "_" + sm.Code,
            width: 160,
          });
          mainStats.push({
            id: "co_" + (i + 1) + "_" + fc.CountryCode + "_" + sm.Code,
            value: fcAutoSelect,
          });
        }
        cos[0].Variables.forEach((v) => {
          if (!variablesCO.find((vco) => vco == v.label))
            variablesCO.push(v.label);
        });
        countryHeader.push({ title: fc.label, colspan: cos.length });
      });

      mainStatsVar.forEach((x) => {
        let tempData = _.cloneDeep(mainStats);
        if (x.name == "COST SUBMITTED BY") {
          tempData.forEach((k) => {
            k.value = userRecord.FirstName;
          });
        } else if (x.name != "COMMISSIONING COUNTRY / FIELDING COUNTRY") {
          tempData.forEach((k) => {
            k.value = null;
          });
        }
        if (x.name == "IF EXTERNAL - PLS SPECIFY VENDOR NAME [MANDATORY]") {
          tempData.forEach((k) => {
            k.disabled = true;
          });
        }
        mainStatsData.push({
          name: x.name,
          data: tempData,
          options: x.options,
          disabled: x.disabled,
          type: x.type,
        });
      });

      variablesCO.forEach((variable, indexV) => {
        let tempData = { description: variable };
        let colCount = 1;
        profile.CountrySpecs.forEach((fc) => {
          let cos = fc.MethodologySpecs.filter((smi) => smi.Code == sm.Code)[0]
            .CostingOptions;
          cos.forEach((co, i) => {
            let val =
              co.Variables.filter((v) => v.label == variable).length > 0
                ? co.Variables.filter((v) => v.label == variable)[0].value
                : null;
            // Hadle the Object saved for sampling type
            if (typeof val === "object" && val != null) val = val.label;
            if (Array.isArray(val)) {
              val = val.map((v) => v.label).join(",");
            }
            tempData["co_" + (i + 1) + "_" + fc.CountryCode + "_" + sm.Code] =
              val || null;
            readonly.push({ x: colCount, y: indexV });
            colCount++;
          });
        });
        data.push(tempData);
        style["A" + countData] = "text-align: left;";
        countData += 1;
      });

      internalParameters.forEach((parameter, indexP) => {
        let colCount = 1;
        let tempData = { description: parameter.label };
        profile.CountrySpecs.forEach((fc) => {
          let cos = fc.MethodologySpecs.filter((smi) => smi.Code == sm.Code)[0]
            .CostingOptions;
          cos.forEach((co, i) => {
            let parameterIsApplicable =
              !parameter.CountryMethodology ||
              parameter.CountryMethodology.find(
                (cm) =>
                  cm.CountryCode == fc.CountryCode &&
                  cm.Methodologies.find((m) => m == sm.Code)
              );
            if (
              parameter.formula ||
              parameter.header ||
              !parameterIsApplicable ||
              parameter.isSummary
            ) {
              readonly.push({ x: colCount, y: countData - 1 });
            }
            if (parameter.formula) {
              let alpha = (colCount + 10).toString(36).toUpperCase();
              let tempFormula = parameter.formula;
              parameter.keys.forEach((k) => {
                let rowIndex =
                  internalParameters.filter((x) => x.key == k)[0].value +
                  coVariableLength;
                // let re = new RegExp(_.escapeRegExp(k), "g");
                // tempFormula = tempFormula.replace(re, alpha + rowIndex);
              });
              if (parameter.key == "#DT#") {
                if (
                  parameter.countries.filter(
                    (x) => x.code == commCountry && fc.CountryCode == x.code
                  ).length > 0
                ) {
                  tempFormula = tempFormula.replaceAll("#CTX#", 0);
                } else if (
                  parameter.countries.filter(
                    (x) => x.code != commCountry && fc.CountryCode == x.code
                  ).length > 0
                ) {
                  tempFormula = tempFormula.replaceAll(
                    "#CTX#",
                    parameter.countries.filter(
                      (x) => x.code != commCountry && fc.CountryCode == x.code
                    )[0].tax.VAT
                  );
                }
              } else {
                tempFormula = tempFormula.replace("#TAX#", 0);
              }
              tempData["co_" + (i + 1) + "_" + fc.CountryCode + "_" + sm.Code] =
                tempFormula;
            } else
              tempData["co_" + (i + 1) + "_" + fc.CountryCode + "_" + sm.Code] =
                null;
            colCount++;
          });
        });
        data.push(tempData);

        let tempStyle = ";cursor: not-allowed;";
        let styleBold = ";font-weight:bold";
        let bgColorHeader = ";background-color:#bbdefb;";
        let bgColorTax = ";background-color:#90caf9;";
        let bgColorTotal = ";background-color:#64b5f6;";
        let bgColorTotal2 = ";background-color:#42a5f5;";
        let bgColorTotal3 = ";background-color:#2196f3;";
        let bgColorTotal4 = ";background-color:#1e88e5;";
        let diablecolor = ";background-color:#dddddd;";
        let summryColor = ";background-color:#b2d3c2    ;";
        let summryTextColor = ";color:#ffffff;";
        let textLeft = ";text-align: left;";
        let textCenter = ";text-align: center;";
        let textItalic = ";font-style: italic;";
        let textCapatalize = "; text-transform: uppercase;";
        let bgcolorheader = ";background-color:#74b74e;";

        if (
          (!userIsOperation && parameter.csOnly) ||
          (userIsOperation && !parameter.csOnly)
        )
          tempStyle += diablecolor;

        if (parameter.subHeader) tempStyle += styleBold;
        if (parameter.header || parameter.formula)
          tempStyle += styleBold + bgColorHeader;
        if (parameter.total) tempStyle += styleBold + bgColorTotal;
        if (parameter.total2) tempStyle += styleBold + bgColorTotal2;
        if (parameter.total3) tempStyle += styleBold + bgColorTotal3;
        if (parameter.total4) tempStyle += styleBold + bgColorTotal4;
        if (parameter.tax) tempStyle += styleBold + bgColorTax;
        if (parameter.bold) tempStyle += styleBold;

        if (parameter.isSummary) {
          tempStyle += summryColor + summryTextColor + textItalic;
        }
        style["A" + countData] = tempStyle + textLeft;
        if (parameter.label == "Costing Summary") {
          tempStyle += textCenter + textCapatalize + bgcolorheader;
        }

        for (var i = 2; i <= count; i++) {
          let alpha = (i + 9).toString(36).toUpperCase();
          style[alpha + countData] = tempStyle;
        }
        countData += 1;
      });

      nestedHeaders.push(countryHeader);
      let options = {
        data: data,
        columns: columns,
        nestedHeaders: nestedHeaders,
        style: style,
        readonly: readonly,
        mainStatsData: mainStatsData,
        currencies: currencies,
      };
      tempSpreadSheetData.push({
        value: sm.value,
        label: sm.label,
        options: options,
      });
    });
    setSpreadSheetData(tempSpreadSheetData);
  }

  function makeSpreadSheetData() {}

  function saveCosting() {
    let data = {
      CostingData: JSON.stringify(spreadSheetData),
      CostingProfileId: profile.id,
    };

    dispatch(appActions.recordLoadStart());
    axios
      .post("/costingdata/save", data, {
        headers: { "auth-token": localStorage.getItem("auth-token") },
      })
      .then((res) => {
        toastr.success("Success", "Data Saved Successfully");
      })
      .catch((err) => {
        toastr.error("Save Failed", err?.data?.error);
        dispatch(appActions.recordLoadEnd());
      });
  }
  const commercialFields = {
    "Associate Director": "CostIntCommAssociateDirector",
    "Data Science": "CostIntCommDataScience",
    Director: "CostIntCommDirector",
    "Executive Director": "CostIntCommExecDirector",
    Executive: "CostIntCommExecutive",
    Manager: "CostIntCommManager",
    "Senior Executive": "CostIntCommSeniorExecutive",
    "Senior Manager": "CostIntCommSeniorManager",
  };
  function validateCO() {
    //chanege by mridul rishab
    profile?.CountrySpecs.map((fc) =>
      fc?.MethodologySpecs.map((sm) =>
        sm?.CostingOptions.map((co) =>
          co?.Variables?.filter((v) => v?.type == "integer").map((cd) => {
            if (parseInt(cd?.value) > 100 && parseInt(cd?.value) < 1) {
              isvalid = false;
            } else if (parseInt(cd?.value) < 0) {
              isvalid = false;
            } else {
            }
          })
        )
      )
    );
  }

  function validateRFQ() {
    profile?.CountrySpecs.map((fc) =>
      fc?.MethodologySpecs.map((sm) =>
        sm?.Variables.filter((v) => v.type == "integer").map((cd) => {
          if (parseInt(cd?.value) < 0) {
            isvalid = false;
          }
          if (cd?.label == "Incidence Rate Main" && parseInt(cd?.value) > 100) {
            isvalid = false;
          }
        })
      )
    );
  }

  function validateSUD() {
    profile?.CountrySpecs.map((fc) =>
      fc?.MethodologySpecs.map((sm) =>
        sm?.OpResourcesVariables.filter((v) => v?.type == "integer").map(
          (cd) => {
            if (parseInt(cd?.value) < 0) {
              isvalid = false;
            }
          }
        )
      )
    );
  }


  function saveDraft(notAPI) {
    validateCO();
    validateRFQ();
    validateSUD();
    if (isvalid) {
      isvalid = true;
      let _profile = { ...profile }; //_.last(project.CostingProfiles) //currentCosting//_.last(project.CostingProfiles)

      // _profile = { ..._profile, ...currentCosting }
      _profile.AdditionalSheetId = currentCosting.AdditionalSheetId
        ? currentCosting.AdditionalSheetId
        : null;
      _profile.CostingType = currentCosting.CostingType
        ? currentCosting.CostingType
        : null;
      _profile.CostingsSheetId = currentCosting.CostingsSheetId
        ? currentCosting.CostingsSheetId
        : null;
      _profile.Project = project;
      if (!_.includes(pacificMarkets, project.CommissioningCountry)||
      (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
      ) {
        _profile.CountrySpecs = _profile.CountrySpecs?.map((cs) => {
          let requiredcountry = {
            ...currentCosting.CountrySpecs.find((CS) => CS.id == cs.id),
          };
          if (requiredcountry) {
            cs.MethodologySpecs = cs.MethodologySpecs.map((ms) => {
              let requiredMeth = {
                ...requiredcountry.MethodologySpecs.find(
                  (MS) => MS.id == ms.id
                ),
              };
              // delete requiredMeth.CostingOptions
              // delete requiredMeth.NotApplicable
              // delete requiredMeth.Responsibilities
              // delete requiredMeth.ResponsibilityData
              // delete requiredMeth.ResponsibilitySchema
              // delete requiredMeth.SetupData
              // delete requiredMeth.SetupSchema
              // delete requiredMeth.RFQData
              // delete requiredMeth.RFQSchema
              return { ...ms, ...requiredMeth };
            });
          }
          delete requiredcountry.MethodologySpecs;
          return { ...cs, ...requiredcountry };
        });
      }
      if (!_.includes(pacificMarkets, project.CommissioningCountry)||
      (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
      ) {
        _profile.WaveSpecs = [...currentCosting.WaveSpecs];
      }
      var WaveSpecsAll = _profile?.WaveSpecs || [];
      let temporaryWavesForValidation = [];
      _profile?.CountrySpecs?.forEach((cs) => {
        cs?.MethodologySpecs?.forEach((ms) => {
          ms.RFQSchema = mapperFunctions.formatSchemaByVariables(
            _.cloneDeep(ms?.RFQSchema),
            _.cloneDeep(ms?.Variables)
          );
          ms.RFQData = _.cloneDeep(ms?.Variables)
            ? mapperFunctions.getDataJSON(_.cloneDeep(ms?.Variables))
            : mapperFunctions.getDataJSON(_.cloneDeep([]));
          //let WaveSpecs=ms.WaveSpecs||[];
          var costingOptionWaves;
          if (ms?.CostingOptions.length)
            costingOptionWaves = ms?.CostingOptions[0]?.Variables.filter(
              (x) => x?.label == "Number of Waves"
            );
          else {
            costingOptionWaves = ms?.Variables
              ? ms.Variables.filter((x) => x?.id == "numberOfWaves")
              : [];
          }
          var WaveSpecs = WaveSpecsAll.filter(
            (x) => x.MethodologySpecId == ms.id
          );
          var waveSize =
            costingOptionWaves.length > 0
              ? costingOptionWaves[0].value
              : (ms?.OpResourcesVariables.filter(
                  (x) => x.label == "Number of Waves"
                ).length > 0
                  ? ms?.OpResourcesVariables.filter(
                      (x) => x.label == "Number of Waves"
                    )[0].value
                  : 1) || 1;
          let waves =
            WaveSpecs.length > 0 && WaveSpecs.length == waveSize
              ? WaveSpecs.length
              : waveSize;
          if (!waves) {
            waves = 1;
          }
          if (!_.includes(pacificMarkets, project.CommissioningCountry)
          ||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
          ) {
            var tempWaveSpecs = [];
            for (let i = 0; i < waves; i++) {
              var id =
                WaveSpecs.length > 0
                  ? WaveSpecs[i]
                    ? WaveSpecs[i].id
                    : null
                  : null;
              tempWaveSpecs.push({
                id: id,
                WaveNumber: i + 1,
                ResponsibilitySchema: _.cloneDeep(ms.Responsibilities),
                OpsResourcesSchema: mapperFunctions.formatSchemaByVariables(
                  _.cloneDeep(ms.SetupSchema),
                  _.cloneDeep(ms.OpResourcesVariables)
                ),
                OpsResourcesData: mapperFunctions.getDataJSON(
                  _.cloneDeep(ms.OpResourcesVariables)
                ),
                MethodologySpecId: ms.id,
                CostingProfileId: _profile?.id,
              });

              ms["WaveSpecs"] = tempWaveSpecs;
              temporaryWavesForValidation = [
                ...temporaryWavesForValidation,
                ...tempWaveSpecs,
              ];
            }

            ///For Wave Specs Deletion
            if (WaveSpecs.length > waves) {
              for (let i = waves; i < WaveSpecs.length; i++) {
                var id = WaveSpecs[i].id;
                tempWaveSpecs.push({
                  id: id,
                  toRemove: true,
                  WaveNumber: i + 1,
                  ResponsibilitySchema: _.cloneDeep(ms.Responsibilities),
                  OpsResourcesSchema: mapperFunctions.formatSchemaByVariables(
                    _.cloneDeep(ms.SetupSchema),
                    _.cloneDeep(ms.OpResourcesVariables)
                  ),
                  OpsResourcesData: mapperFunctions.getDataJSON(
                    _.cloneDeep(ms.OpResourcesVariables)
                  ),
                  MethodologySpecId: ms.id,
                  CostingProfileId: _profile?.id,
                });
                ms["WaveSpecs"] = tempWaveSpecs;
                temporaryWavesForValidation = [
                  ...temporaryWavesForValidation,
                  ...tempWaveSpecs,
                ];
              }
            }
          } else {
            //todo: wave specs deletion and updation
            //when _.groupBy(profile.WaveSpecs).length>tempWaveSpecs delete respective number of wavespecs
            //for that methodology accordingly, likewise _.groupBy(profile.WaveSpecs).length<tempWaveSpecs
            //create wavespecs, if you fill wavespecs and without IDs it'll create in backend
            var pacificTempWaves = [];
            let waveMethCount = profile.WaveSpecs.filter(
              (x) => x.MethodologySpecId === ms.id
            );
            // to add
            if (waveMethCount.length < waves) {
              for (let i = 0; i < waves; i++) {
                var id =
                  waveMethCount.length > 0
                    ? waveMethCount[i]
                      ? waveMethCount[i].id
                      : null
                    : null;
                pacificTempWaves.push({
                  id: id,
                  WaveNumber: i + 1,
                  ResponsibilitySchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].ResponsibilitySchema)
                    : _.cloneDeep(ms.Responsibilities),
                  CommercialHoursData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].CommercialHoursData)
                    : _.cloneDeep(ms?.CommercialHoursData),
                  OpsResourcesSchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesSchema)
                    : mapperFunctions.formatSchemaByVariables(
                        _.cloneDeep(ms.SetupSchema)
                      ),
                  OpsResourcesData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesData)
                    : {},
                  MethodologySpecId: ms.id,
                  CostingProfileId: profile?.id,
                });
                ms["WaveSpecs"] = pacificTempWaves;
                temporaryWavesForValidation = [
                  ...temporaryWavesForValidation,
                  ...pacificTempWaves,
                ];
              }
            }
            if (pacificTempWaves.length === 0) {
              let data = profile.WaveSpecs.filter(
                (x) => x.MethodologySpecId === ms.id
              ).map((x) => {
                return { ...x };
              });
              ms["WaveSpecs"] = data;
              temporaryWavesForValidation = [
                ...temporaryWavesForValidation,
                ...data,
              ];
            }

            // to Remove
            if (waveMethCount.length > waves) {
              for (let i = waves; i < waveMethCount.length; i++) {
                var id = waveMethCount[i].id;
                pacificTempWaves.push({
                  id: id,
                  toRemove: true,
                  WaveNumber: i + 1,
                  ResponsibilitySchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].ResponsibilitySchema)
                    : _.cloneDeep(ms.Responsibilities),
                  CommercialHoursData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].CommercialHoursData)
                    : _.cloneDeep(ms?.CommercialHoursData),
                  OpsResourcesSchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesSchema)
                    : mapperFunctions.formatSchemaByVariables(
                        _.cloneDeep(ms.SetupSchema)
                      ),
                  OpsResourcesData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesData)
                    : {},
                  MethodologySpecId: ms.id,
                  CostingProfileId: _profile?.id,
                });
                ms["WaveSpecs"] = pacificTempWaves;
                temporaryWavesForValidation = [
                  ...temporaryWavesForValidation,
                  ...pacificTempWaves,
                ];
              }
            }
          }
          if (!_.includes(pacificMarkets, project.CommissioningCountry)||
          (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
          )
            ms.CostingOptions.forEach((co) => {
              co["id"] = co.id;
              co["Name"] = co.label;
              co["Selected"] = co.isSelected;
              co["CostingOptionSchema"] = _.cloneDeep(co.Variables);
              co["CostingOptionData"] = mapperFunctions.getDataJSON(
                _.cloneDeep(co.Variables)
              );
              co["MethodologySpecId"] = ms.id;
            });
        });
      });
      //commercial costs recounting if there any and helps in case if any costing option got deleted
      Object.keys(commercialFields).map((cf) => {
        _profile[commercialFields[cf]] = 0;
      });
      _profile.CountrySpecs = _profile.CountrySpecs.map((cs) => {
        Object.keys(commercialFields).map((cf) => {
          cs[commercialFields[cf]] = 0;
        });
        cs.MethodologySpecs = cs.MethodologySpecs?.map((ms) => {
          ms.CostingOptions?.filter((co) => co.Selected)?.map((co) => {
            Object.keys(commercialFields).map((cf) => {
              cs[commercialFields[cf]] =
                (cs[commercialFields[cf]] ? cs[commercialFields[cf]] : 0) +
                (co[commercialFields[cf]] ? co[commercialFields[cf]] : 0);
            });
          });
          return { ...ms };
        });
        Object.keys(commercialFields).map((cf) => {
          _profile[commercialFields[cf]] =
            (_profile[commercialFields[cf]]
              ? _profile[commercialFields[cf]]
              : 0) + cs[commercialFields[cf]];
        });
        return { ...cs };
      });

      if (_profile.WaveSpecs.length > 0) {
        const noWaves = Object.values(
          _.groupBy(_profile.WaveSpecs, "MethodologySpecId")
        ).map((O) => O.length);
        _profile.NumberOfWaves = _.max(noWaves);
      }
      if (project.ProjectName !== profile.Project.ProjectName) {
        profile.Project.ProjectName = project.ProjectName;
      }
      dispatch(valueChangedInRFQ(false));

      if (!notAPI) {
        dispatch(currentCostingActions.saveCostingProfileDraft(_profile));
      } else {
        return { ..._profile, WaveSpecs: temporaryWavesForValidation };
      }
      setprofileCopy(profile);
      setShouldBlockNavigation(false);
    } else {
      toastr.error("You have Entered Invalid Values");
      isvalid = true;
    }
  }
  const showErrorMessages = (eve, errors, key) => {
    eve.preventDefault();
    dispatch(setFormErrorStatus({ [key]: errors }));
  };
  const ValueChangeCheckInChild = (val) => {
    //console.log(val);
    dispatch(valueChangedInRFQ(val));
    // eve.preventDefault();
    // dispatch(setFormErrorStatus({ [key]: errors }))
  };
  /**
   * copying the market level data
   * @param {index} tab
   */
  const copyFormToCountries = (tab) => {
    if (validateCopyModal()) {
      const tempProfile = { ...profile };
      // selected market data
      const tempCountrySpecs = tempProfile.CountrySpecs[tab];
      // data conversion of the selected items in Modal
      const countryArray = advanceCopying.SelectedCountries.map(
        (SC) => SC.label
      );
      const methodologiesArray = advanceCopying.SelectedMethodologies.map(
        (MS) => MS.value
      );
      // filtering the countries other than selected market
      const filteredCountrySpecsByInd = tempProfile.CountrySpecs.filter(
        (_, ind) => ind !== tab
      );
      let filteredCountrySpecs = [];
      filteredCountrySpecsByInd.forEach((F) => {
        if (countryArray.includes(F.label)) {
          filteredCountrySpecs.push(F);
        }
      });
      // data copying for the selected methodologies and categories at market level
      const convertedCountrySpecs = filteredCountrySpecs.map((C) => {
        C.MethodologySpecs = C.MethodologySpecs.map((M) => {
          if (methodologiesArray.includes(M.Code)) {
            const findMS = tempCountrySpecs.MethodologySpecs.find(
              (MS) => MS.Code === M.Code
            );
            if (findMS) {
              advanceCopying.SelectedCategories.forEach((SC) => {
                switch (SC.value) {
                  case "CostingOptions":
                    M.CostingOptions = M.CostingOptions.map((MC) => {
                      const cOptions = findMS.CostingOptions.find(
                        (F) => F.value === MC.value
                      );
                      if (cOptions) {
                        // except the cities coverage
                        MC.Variables = cOptions?.Variables.filter(
                          (V) => V.id !== "citiesCoverage"
                        );
                      }
                      return MC;
                    });
                    break;
                  case "RFQSchema":
                    if (
                      _.includes(
                        profile.FieldingCountries?.split(","),
                        C.CountryCode
                      )
                    )
                      M.Variables = findMS.Variables;
                    break;
                  case "OpResourcesVariables":
                    M.OpResourcesVariables = findMS.OpResourcesVariables;
                    break;
                  case "Responsibilities":
                    M.Responsibilities = findMS.Responsibilities;
                    break;
                  default:
                    break;
                }
              });
              return M;
            }
            return M;
          }
          return M;
        });
        return C;
      });
      // updating the profile and project data
      let profileTemp = _.cloneDeep(profile);
      // commenting chethan's code as it is replacing the countries with the countries to be copied
      // profileTemp.CountrySpecs = _.cloneDeep(convertedCountrySpecs)
      // added by Ravi Punnam
      profileTemp.CountrySpecs = profileTemp.CountrySpecs.map(
        (obj) => convertedCountrySpecs.find((o) => o.id === obj.id) || obj
      );
      const tempProfiles = project.CostingProfiles.map((cp) => {
        if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
        return { ..._.cloneDeep(cp) };
      });
      dispatch(updateProject({ CostingProfiles: tempProfiles }));
      setModalConfirmMethodology(false);
      toastr.success(
        "Success",
        "Data copied successfully to the selected markets"
      );
    }
  };
  const copyFormToPacificCountries = (tab) => {
    if (validateCopyModal()) {
      const tempProfile = { ...profile };

      let methBreak = {};
      tempProfile.CountrySpecs.map((cs) => {
        cs.MethodologySpecs?.map((ms) => {
          if (!methBreak[ms.id]) {
            methBreak[ms.id] = { Code: ms.Code };
          }
        });
      });

      let selectedCountry = tempProfile.CountrySpecs[tab];
      let selectedCountryMethBreak = {};
      let slectedMethIds = selectedCountry.MethodologySpecs.map((ms) => ms.id);

      tempProfile.WaveSpecs.map((ws) => {
        if (slectedMethIds.indexOf(ws.MethodologySpecId) != -1) {
          if (!selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code]) {
            selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code] = {};
          }
          selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code][
            ws.WaveNumber
          ] = {
            OpsResourcesData: ws.OpsResourcesData,
            CommercialHoursData: ws.CommercialHoursData,
            ResponsibilitySchema: _.cloneDeep(ws.ResponsibilitySchema),
          };
        }
      });
      tempProfile.WaveSpecs = tempProfile.WaveSpecs.map((ws) => {
        if (slectedMethIds.indexOf(ws.MethodologySpecId) == -1) {
          ws.OpsResourcesData =
            selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code][
              ws.WaveNumber
            ]?.OpsResourcesData;
          ws.CommercialHoursData =
            selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code][
              ws.WaveNumber
            ]?.CommercialHoursData;
          ws.ResponsibilitySchema =
            selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code][
              ws.WaveNumber
            ]?.ResponsibilitySchema;
        }
        return { ...ws };
      });

      // selected market data
      const tempCountrySpecs = tempProfile.CountrySpecs[tab];
      // data conversion of the selected items in Modal
      const countryArray = advanceCopying.SelectedCountries.map(
        (SC) => SC.label
      );
      const methodologiesArray = advanceCopying.SelectedMethodologies.map(
        (MS) => MS.value
      );
      // filtering the countries other than selected market
      const filteredCountrySpecsByInd = tempProfile.CountrySpecs.filter(
        (_, ind) => ind !== tab
      );
      let filteredCountrySpecs = [];
      filteredCountrySpecsByInd.forEach((F) => {
        if (countryArray.includes(F.label)) {
          filteredCountrySpecs.push(F);
        }
      });
      // data copying for the selected methodologies and categories at market level
      const convertedCountrySpecs = filteredCountrySpecs.map((C) => {
        C.MethodologySpecs = C.MethodologySpecs.map((M) => {
          if (methodologiesArray.includes(M.Code)) {
            const findMS = tempCountrySpecs.MethodologySpecs.find(
              (MS) => MS.Code === M.Code
            );
            if (findMS) {
              advanceCopying.SelectedCategories.forEach((SC) => {
                switch (SC.value) {
                  case "RFQSchema":
                    M.Variables = M.Variables.map((x) => {
                      if (x.id != "numberOfWaves") {
                        // Not to copy number of Waves
                        return _.cloneDeep(
                          findMS.Variables.find((xy) => xy.id === x.id)
                        );
                      }
                      return _.cloneDeep(x);
                    });
                    break;
                  default:
                    break;
                }
              });
              return M;
            }
            return M;
          }
          return M;
        });
        return C;
      });
      // updating the profile and project data
      let profileTemp = _.cloneDeep(profile);
      profileTemp.CountrySpecs = profileTemp.CountrySpecs.map(
        (obj) => convertedCountrySpecs.find((o) => o.id === obj.id) || obj
      );
      profileTemp.WaveSpecs = _.cloneDeep(tempProfile.WaveSpecs);
      const tempProfiles = project.CostingProfiles.map((cp) => {
        if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
        return { ..._.cloneDeep(cp) };
      });
      dispatch(updateProject({ CostingProfiles: tempProfiles }));
      setModalConfirmMethodology(false);
      toastr.success(
        "Success",
        "Data copied successfully to the selected markets"
      );
    }
  };


  /**
   * To validate the copy modal mandatory fields
   * @returns boolean value
   */
  const validateCopyModal = () => {
    if (
      advanceCopying.SelectedCountries &&
      advanceCopying.SelectedMethodologies &&
      advanceCopying.SelectedCategories
    ) {
      return true;
    }
    toastr.error("Error", "All fields are mandatory");
    return false;
  };

  const confirmModalCopying = () => {
    if (validateCopyModal()) {
      setModalMethodologiesState(false);
      setModalConfirmMethodology(true);
    }
  };

  return (
    
    <React.Fragment>
      {localPageload || recordloading? (
      <div id="pageCoverSpin"></div>
    ) : null}
      {disableFields}
      <MyComponent />
      <Modal
        isOpen={waveModal}
        // toggle={() => setwaveModal(false)}
        size="sm"
        centered={true}
      >
        <ModalHeader>
        {/* <img src={logo}  className="modallogo"/> */}
        </ModalHeader>
        <div>
                        <span className="modaltitle modaltitleDisplay">
                        Update Waves
                        </span>
                    </div>
        <ModalBody>
          Number of Waves has been updated, so please save waves, in order to
          update Setup and delivery form.
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              saveDraft();
              setwaveModal(false);
            }}
          >
            Save Waves
          </Button>
          {/* <Button onClick={() => setwaveModal(false)}>
                        Close
                    </Button> */}
        </ModalFooter>
      </Modal>
      <Modal className="modal-with-header" isOpen={isModalOpen} toggle={() => handleOpen()} size="lg" centered={true}>
        <ModalHeader
          toggle={() => handleOpen(!isModalOpen)}
        >
          {/* {validationHeader} */}
          {/* <img src={logo}  className="modallogo"/> */}
        </ModalHeader>
       
        <ModalBody id="scrollModalBody" className="modal-body-error-header">
         <div>
                        <span className="modaltitle modaltitleDisplay text-danger">
                        {validationHeader}
                        </span>
                    </div>
          <p>
            <b>
              Key Parameters information is missing at cost option level for
              following Country and Methodologies
            </b>
          </p>
          {Object.keys(errorObj)?.length ? (
            <>
              {Object.keys(errorObj).map(function (key) {
                return (
                  <>
                    <div className="displayCountry col-md-12">
                      <h4>
                        <b>{key} :</b>
                      </h4>
                    </div>
                    <div class="col-md-12">
                      {errorObj[key]?.CostingOptions &&
                      Object.keys(errorObj[key]?.CostingOptions).length ? (
                        <>
                          <p>
                            <b>Costing Options Section:</b>
                          </p>
                          <Table id="errorTable">
                            <thead>
                              <tr>
                                <th>Methodologies</th>
                                <th>Fields</th>
                              </tr>
                            </thead>
                            {
                              <tbody>
                                {Object.keys(errorObj[key]?.CostingOptions).map(
                                  function (key1) {
                                    return (
                                      <tr>
                                        {Object.entries(
                                          errorObj[key]?.CostingOptions[key1]
                                        ).length > 0 && (
                                          <>
                                            <td>{key1} </td>
                                            <td>
                                              {errorObj[key]?.CostingOptions[
                                                key1
                                              ].join(", ")}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            }
                          </Table>
                        </>
                      ) : null}
                    </div>

                    <div class="col-md-12">
                      {errorObj[key]?.RFQ &&
                      Object.keys(errorObj[key].RFQ).length ? (
                        <>
                          <p>
                            <b>RFQ Form Section:</b>
                          </p>
                          <Table id="errorTable">
                            <thead>
                              <tr>
                                <th>Methodologies</th>
                                <th>Fields</th>
                              </tr>
                            </thead>
                            {
                              <tbody>
                                {Object.keys(errorObj[key].RFQ).map(function (
                                  key1
                                ) {
                                  return (
                                    <tr>
                                      <td>{key1} </td>
                                      <td>
                                        {errorObj[key].RFQ[key1].join(", ")}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            }
                          </Table>
                        </>
                      ) : null}
                    </div>
                    {_.includes(
                      pacificMarkets,
                      project.CommissioningCountry
                    )  && project.CostingTemplateVersion != "v2.0"? (
                      <div class="col-md-12">
                        {errorObj[key]?.SD &&
                        Object.keys(errorObj[key].SD).length ? (
                          <>
                            <p>
                              <b>Setup and Delivery Section:</b>
                            </p>
                            <Table id="errorTable">
                              <thead>
                                <tr>
                                  <th>Methodologies</th>
                                  <th>Wave Number</th>
                                  <th>Fields</th>
                                </tr>
                              </thead>
                              {
                                <tbody>
                                  {Object.keys(errorObj[key].SD).map(function (
                                    key1
                                  ) {
                                    return Object.keys(
                                      errorObj[key].SD[key1].NO
                                    ).map(function (number) {
                                      return (
                                        <tr>
                                          <td>{key1}</td>
                                          <td>{number} </td>
                                          <td>
                                            {errorObj[key].SD[key1].NO[
                                              number
                                            ].join(", ")}
                                          </td>
                                        </tr>
                                      );
                                    });
                                  })}
                                </tbody>
                              }
                            </Table>
                          </>
                        ) : null}
                      </div>
                    ) : (
                      <div class="col-md-12">
                        {errorObj[key]?.SD &&
                        Object.keys(errorObj[key].SD).length ? (
                          <>
                            <p>
                              <b>Setup and Delivery Section:</b>
                            </p>
                            <Table id="errorTable">
                              <thead>
                                <tr>
                                  <th>Methodologies</th>
                                  <th>Fields</th>
                                </tr>
                              </thead>
                              {
                                <tbody>
                                  {Object.keys(errorObj[key].SD).map(function (
                                    key1
                                  ) {
                                    return (
                                      <tr>
                                        <td>{key1} </td>
                                        <td>
                                          {errorObj[key].SD[key1].join(", ")}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              }
                            </Table>
                          </>
                        ) : null}
                      </div>
                    )}
                  </>
                );
              })}
            </>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleOpen(!isModalOpen)}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      {/* {costingStatus.showCostingInput &&
                <NewSpreadsheet saveDraft={saveDraft} userRecord={userRecord} profile={profile} updateProject={updateProject} />
            } */}
      {/* {costingStatus.showManualCostEntry && !showSpreadSheet &&
                <ManualCostEntry />
            } */}
      {costingStatus.showCommercialCosts && !showSpreadSheet && (
        <CommercialHours editDisabled={false} />
      )}
      {!costingStatus.showManualCostEntry &&
        !costingStatus.showCostingInput &&
        !costingStatus.showCommercialCosts &&
        !showSpreadSheet && (
          <>
            <div className={"details-header"}>
              <Row>
                <Col>
                  <h4>Project Name: {project.ProjectName}</h4>
                  <h4>Project ID: {project.ProjectId}</h4>
                  <h4>{profile.ProfileName}</h4>
                </Col>
                <Col style={{textAlign: 'end'}}>
                  <Button onClick={() => 
                    {
                      dispatch(
                        currentCostingActions.getSummaryCosting(profile, () =>
                          history.push(`/summary/${profile.id}`),
                          profile?.IsOPSOOPAdjusted ? false : true
                        )
                      );
                    }
                    }>
                    Back to Summary
                  </Button>
                </Col>
              </Row>
            </div>

            <Row className="m-2">
              <Col md={12} className={"countryTabs tab-vertical"}>
                <div className={"tab mt-4"}>
                  <Nav tabs>
                    {profile.CountrySpecs &&
                      profile.CountrySpecs.map((fc, indxFC) => (
                        <NavItem key={fc.value}>
                          <NavLink
                            className={classnames({
                              active: activeTabFC === indxFC,
                            })}
                            onClick={() => {
                              toggle(indxFC);
                            }}
                          >
                            <b>{fc.label ? fc.label : fc.CountryName}</b>
                          </NavLink>
                        </NavItem>
                      ))}
                  </Nav>
                  <TabContent activeTab={activeTabFC}>
                    {profile.CountrySpecs &&
                      profile.CountrySpecs.map(
                        (fc, indxFC) =>
                          activeTabFC === indxFC && (
                            <TabPane tabId={indxFC} key={fc.value}>
                              <Row>
                                <Col md={12} className={"methodologyTabs"}>
                                  <Nav tabs>
                                    {fc.MethodologySpecs &&
                                      ((!_.includes(
                                        pacificMarkets,
                                        project.CommissioningCountry
                                      ) ||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0"))
                                        ? fc.MethodologySpecs.filter(
                                            (x) =>
                                              !x.NotApplicable &&
                                              x.CostingOptions.some(
                                                (co) =>
                                                  (project.CostingTemplateVersion ==
                                                    "v2.0" &&
                                                    (co.CoSelectedForSummary == true ||  co.CoSelectedForSummary == undefined ||
                                                      (co.CoSelectedForSummary ==
                                                        null &&
                                                        co.Selected))) ||
                                                  (project.CostingTemplateVersion !=
                                                    "v2.0" &&
                                                    co.Selected)
                                              )
                                          )
                                        : fc.MethodologySpecs
                                      ).map((sm, indxSM) => (
                                        //fc.MethodologySpecs.map((sm, indxSM) => (
                                        <NavItem key={sm.value}>
                                          <NavLink
                                            className={classnames({
                                              active: activeTabSM === indxSM,
                                            })}
                                            onClick={() => {
                                              toggleSM(indxSM);
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <div>
                                                {sm.NotApplicable}
                                                <b>
                                                  {sm.label
                                                    ? sm.label
                                                    : sm.Label}
                                                </b>
                                              </div>
                                            </div>
                                          </NavLink>
                                        </NavItem>
                                      ))}
                                  </Nav>
                                  <TabContent
                                    activeTab={activeTabSM}
                                    style={{ padding: "0.25" }}
                                  >
                                    {fc.MethodologySpecs &&
                                      ((!_.includes(
                                        pacificMarkets,
                                        project.CommissioningCountry
                                      )||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0"))
                                        ? fc.MethodologySpecs.filter(
                                            (x) =>
                                              !x.NotApplicable &&
                                              x.CostingOptions.some(
                                                (co) =>
                                                  (project.CostingTemplateVersion ==
                                                    "v2.0" &&
                                                    (co.CoSelectedForSummary ==
                                                      true ||
                                                      co.CoSelectedForSummary ==
                                                        undefined ||
                                                      (co.CoSelectedForSummary ==
                                                        null &&
                                                        co.Selected))) ||
                                                  (project.CostingTemplateVersion !=
                                                    "v2.0" &&
                                                    co.Selected)
                                              )
                                          )
                                        : fc.MethodologySpecs
                                      ).map(
                                        (sm, indxSM) =>
                                          activeTabSM === indxSM && (
                                            <TabPane
                                              tabId={indxSM}
                                              key={sm.value}
                                            >
                                              {(_.includes(
                                                pacificMarkets,
                                                project.CommissioningCountry
                                              )   && project.CostingTemplateVersion != "v2.0")? null : sm.CostingOptions.some(
                                                  (co) =>  (project.CostingTemplateVersion =="v2.0" &&
                                                    (co.CoSelectedForSummary ==true ||co.CoSelectedForSummary == undefined ||(co.CoSelectedForSummary == null && co.Selected))) ||
                                                  (project.CostingTemplateVersion !="v2.0" &&    co.Selected)
                                                ) ? (
                                                <CostingOption
                                                  profile={profile}
                                                  countryIndex={indxFC}
                                                  methodology={sm}
                                                  methodologyIndex={indxSM}
                                                  updateProject={updateProject}
                                                  errorFieldsObj={errorObj}
                                                  isEditable={
                                                    ValueChangeCheckInChild
                                                  }
                                                  disableFields={disableFields}
                                                />
                                              ) : null}
                                              <TestPageRFQ
                                                profile={profile}
                                                indxFC={indxFC}
                                                methodology={sm}
                                                indxSM={indxSM}
                                                updateProject={updateProject}
                                                showErrorMessages={
                                                  showErrorMessages
                                                }
                                                isEditable={
                                                  ValueChangeCheckInChild
                                                }
                                                disableFields={disableFields}
                                                setWaveModal={setwaveModal}
                                              />
                                              {(_.includes(
                                                pacificMarkets,
                                                project.CommissioningCountry
                                              )  && project.CostingTemplateVersion != "v2.0")? (
                                                <WaveLevelEdit
                                                  profile={profile}
                                                  indxFC={indxFC}
                                                  methodology={sm}
                                                  indxSM={indxSM}
                                                  updateProject={updateProject}
                                                  showErrorMessages={
                                                    showErrorMessages
                                                  }
                                                  errorFieldsObj={errorObj}
                                                  isEditable={
                                                    ValueChangeCheckInChild
                                                  }
                                                  disableFields={disableFields}
                                                />
                                              ) : (
                                                <>
                                                  <TestPageOpResource
                                                    profile={profile}
                                                    indxFC={indxFC}
                                                    methodology={sm}
                                                    indxSM={indxSM}
                                                    updateProject={
                                                      updateProject
                                                    }
                                                    showErrorMessages={
                                                      showErrorMessages
                                                    }
                                                    errorFieldsObj={errorObj}
                                                    isEditable={
                                                      ValueChangeCheckInChild
                                                    }
                                                    disableFields={
                                                      disableFields
                                                    }
                                                  />
                                                </>
                                              )}
                                              {(_.includes(
                                                pacificMarkets,
                                                project.CommissioningCountry
                                              )  && project.CostingTemplateVersion != "v2.0")? (
                                                <CommercialHours_internal
                                                  profile={profile}
                                                  indxFC={indxFC}
                                                  methodology={sm}
                                                  indxSM={indxSM}
                                                  updateProject={updateProject}
                                                  showErrorMessages={
                                                    showErrorMessages
                                                  }
                                                  errorFieldsObj={errorObj}
                                                  editable={false}
                                                  disableFields={disableFields}
                                                />
                                              ) : null}

                                              {profile.IsMultiCountry ? (
                                                (_.includes(
                                                  pacificMarkets,
                                                  project.CommissioningCountry
                                                ) && project.CostingTemplateVersion != "v2.0") ? (
                                                  <TestResponsibilities_internal
                                                    profile={profile}
                                                    indxFC={indxFC}
                                                    methodology={sm}
                                                    indxSM={indxSM}
                                                    updateProject={
                                                      updateProject
                                                    }
                                                    disableFields={
                                                      disableFields
                                                    }
                                                  />
                                                ) : (
                                                  <TestResponsibilities
                                                    profile={profile}
                                                    indxFC={indxFC}
                                                    methodology={sm}
                                                    indxSM={indxSM}
                                                    updateProject={
                                                      updateProject
                                                    }
                                                    disableFields={
                                                      disableFields
                                                    }
                                                  />
                                                )
                                              ) : null}
                                            </TabPane>
                                          )
                                      )}
                                  </TabContent>
                                </Col>
                              </Row>
                            </TabPane>
                          )
                      )}
                  </TabContent>
                </div>
              </Col>
            </Row>
            {/* // )} */}
          </>
        )}

      <AvForm encType="multipart/form-data">
        <Modal
          isOpen={modelState}
          toggle={() => setModalState(!modelState)}
          centered
          size={copyProfile && copyProfile.Mode == "Selection" ? "xm" : "xl"}
        >
          <ModalHeader toggle={() => setModalState(!modelState)}>
          {/* <img src={logo}  className="modallogo"/> */}
            {/* {"Copying Costing Options To Methodologies & Markets"} */}
          </ModalHeader>
          <div>
                        <span className="modaltitle modaltitleDisplay">
                        {"Copying Costing Options To Methodologies & Markets"}
                        </span>
                    </div>

          <ModalBody className="m-3" style={styleModelBody}>
            {copyProfile && copyProfile.Mode == "Selection" && (
              <>
                <Row>
                  <Col md={12} className="selection-buttons-co-copy-m">
                    <Button
                      color="primary"
                      disabled={profile.CountrySpecs.length < 2}
                      onClick={() => {
                        CopyAsIsClicked();
                      }}
                    >
                      Copy As Is
                    </Button>
                    <Button
                      disabled={
                        profile.CountrySpecs[copyProfile.indexFC]
                          .MethodologySpecs[copyProfile.indexSM].label ==
                          "Path Tracking" ||
                        profile.CountrySpecs[copyProfile.indexFC]
                          .MethodologySpecs[copyProfile.indexSM].label ==
                          "Eye Tracking" ||
                        profile.CountrySpecs[copyProfile.indexFC]
                          .MethodologySpecs[copyProfile.indexSM].label ==
                          "Smart Store"
                      }
                      color="primary"
                      onClick={() => {
                        setCopyProfile((prevState) => ({
                          ...prevState,
                          Mode: "First",
                          SelectedCostingOptions: null,
                          SelectedCountries: null,
                        }));
                      }}
                    >
                      Advance Copy
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {copyProfile &&
              (copyProfile.Mode == "First" ||
                copyProfile.Mode == "CopyAsIs") && (
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Select Costing Options</Label>
                      <Select
                        name={"copyCostingOptions"}
                        value={copyProfile.SelectedCostingOptions}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={copyProfile.CostingOptions}
                        isMulti
                        onChange={(val) => {
                          setCopyProfile((prevState) => ({
                            ...prevState,
                            SelectedCostingOptions: val,
                          }));
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Select Countries</Label>
                      <Select
                        name={"copyCountries"}
                        value={copyProfile.SelectedCountries}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        menuPortalTarget={document.querySelector("modal-body")}
                        menuPosition={"fixed"}
                        options={copyProfile.Countries}
                        isMulti
                        onChange={(val) => {
                          setCopyProfile((prevState) => ({
                            ...prevState,
                            SelectedCountries: val,
                          }));
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}

            {copyProfile && copyProfile.Mode == "Second" && (
              <Table
                size="sm"
                bordered
                style={{
                  backgroundColor: "white",
                  minWidth: "100%",
                  width: "auto",
                }}
              >
                <thead>
                  <tr>
                    <th className={"copyCostingOptionFirstTable"}>
                      <span> Costing Option</span>
                    </th>
                    <th className={"copyCostingOptionFirstTable"}>
                      <span> Country</span>
                    </th>
                    <th className={"copyCostingOptionFirstTable"}>
                      <span> Select Methodologies</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {copyProfile.CountryWiseCostingOptions.map(
                    (cwco, indexCWCO) => (
                      <tr>
                        <td>{cwco.CostingOption.label}</td>
                        <td>{cwco.Country.label}</td>
                        <td>
                          <div>
                            <Select
                              name={"methodologies"}
                              value={cwco.SelectedMethodologies}
                              menuPortalTarget={document.querySelector(
                                "modal-body"
                              )}
                              menuPosition={"fixed"}
                              options={cwco.Methodologies}
                              isMulti
                              onChange={(val) => {
                                const copyProfileTemp = update(copyProfile, {
                                  CountryWiseCostingOptions: {
                                    [indexCWCO]: {
                                      $merge: { SelectedMethodologies: val },
                                    },
                                  },
                                });
                                setCopyProfile(_.cloneDeep(copyProfileTemp));
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            )}

            {copyProfile && copyProfile.Mode == "Save" && (
              <Row>
                <Col md={12} className={"tableCopyProject costingOptions"}>
                  <Table
                    size="sm"
                    bordered
                    style={{
                      backgroundColor: "white",
                      maxWidth: "100%",
                      width: "auto",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{ minWidth: "250px", maxWidth: "250px" }}
                        ></th>
                        {copyProfile.SelectedCountries.map((c, indexC) => (
                          <th
                            colSpan={c.ColSpan}
                            key={indexC}
                            style={{ minWidth: "250px", maxWidth: "250px" }}
                          >
                            {c.label}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        <th></th>
                        {copyProfile.SelectedCountries.map((c, indexC) =>
                          c.Methodologies.map((m, indexM) => (
                            <th colSpan={m.ColSpan} key={indexC + "_" + indexM}>
                              {m.label}
                            </th>
                          ))
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th></th>
                        {copyProfile.SelectedCountries.map((c, indexC) =>
                          c.Methodologies.map((m, indexM) =>
                            m.CostingOptions.map((co, indexCO) => (
                              <th key={indexC + "_" + indexM + "_" + indexCO}>
                                {co.label}
                              </th>
                            ))
                          )
                        )}
                      </tr>
                      {copyProfile.CostingOptions[0].Variables.map(
                        (v, indexV) => (
                          <tr key={indexV}>
                            <td>{v.label}</td>
                            {copyProfile.SelectedCountries.map((c, indexC) =>
                              c.Methodologies.map((m, indexM) =>
                                m.CostingOptions.map((co, indexCO) => (
                                  <td
                                    key={indexC + "_" + indexM + "_" + indexCO}
                                  >
                                    {ReturnControl(
                                      indexV,
                                      indexC,
                                      indexM,
                                      indexCO
                                    )}
                                  </td>
                                ))
                              )
                            )}
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            {copyProfile && copyProfile.Mode != "Selection" && (
              <>
                {copyProfile.Mode != "Selection" && (
                  <Button
                    color="success"
                    onClick={() => {
                      CopyProfileActionClicked(true);
                    }}
                  >
                    Back
                  </Button>
                )}

                {copyProfile.Mode != "Selection" && (
                  <Button
                    color="success"
                    onClick={() => {
                      CopyProfileActionClicked();
                    }}
                  >
                    {(copyProfile.Mode == "First" ||
                      copyProfile.Mode == "Second") &&
                      "Next"}
                    {(copyProfile.Mode == "Save" ||
                      copyProfile.Mode == "CopyAsIs") &&
                      " Save"}
                  </Button>
                )}
              </>
            )}
            <Button color="danger" onClick={() => setModalState(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </AvForm>
      {/** Modal: Copying data for market level - Start here */}
      <Modal
        isOpen={modalMethodologiesState}
        toggle={() => setModalMethodologiesState(!modalMethodologiesState)}
        centered
        size={"xm"}
      >
        <ModalHeader
          toggle={() => setModalMethodologiesState(!modalMethodologiesState)}
        >
          {/* {`Copying ${
            profile.CountrySpecs && profile.CountrySpecs[activeTabFC]?.label
          } Methodologies data to other applicable markets`} */}
          {/* <img src={logo}  className="modallogo"/> */}
        </ModalHeader>
        <div>
                        <span className="modaltitleDisplay">
                        {`Copying ${
            profile.CountrySpecs && profile.CountrySpecs[activeTabFC]?.label
          } Methodologies data to other applicable markets`}
                        </span>
                    </div>
        <ModalBody style={{ minHeight: "60px" }}>
          <Row>
            <Col>
              <FormGroup>
                <Label>Select Countries</Label>
                <Select
                  name={"selectCountries"}
                  value={advanceCopying.SelectedCountries}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  menuPortalTarget={document.querySelector("modal-body")}
                  menuPosition={"fixed"}
                  options={advanceCopying.Countries}
                  isMulti
                  onChange={(val) => {
                    setAdvanceCopying((prevState) => ({
                      ...prevState,
                      SelectedCountries: val,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Select Methodologies</Label>
                <Select
                  name={"selectMethodologies"}
                  value={advanceCopying.SelectedMethodologies}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  menuPortalTarget={document.querySelector("modal-body")}
                  menuPosition={"fixed"}
                  options={advanceCopying.Methodologies}
                  isMulti
                  onChange={(val) => {
                    setAdvanceCopying((prevState) => ({
                      ...prevState,
                      SelectedMethodologies: val,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Select Categories</Label>
                <Select
                  name={"selectCategories"}
                  value={advanceCopying.SelectedCategories}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  menuPortalTarget={document.querySelector("modal-body")}
                  menuPosition={"fixed"}
                  options={advanceCopying.Categories}
                  isMulti
                  onChange={(val) => {
                    setAdvanceCopying((prevState) => ({
                      ...prevState,
                      SelectedCategories: val,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>
                {" "}
                <strong>Note:</strong> Copying the information may change the
                data in selected markets.
              </Label>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              confirmModalCopying();
            }}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={() => setModalMethodologiesState(false)}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      {/** Modal: Copying data for market level - End here */}

      {/** Modal: Confirm data copying - Start here */}
      <Modal
        isOpen={modalConfirmMethodology}
        toggle={() => setModalConfirmMethodology(!modalConfirmMethodology)}
        centered
        size={"sm"}
      >
        <ModalHeader
          toggle={() => setModalConfirmMethodology(!modalConfirmMethodology)}
        >
          {/* {`Confirm Copying Data`} */}
          {/* <img src={logo}  className="modallogo"/> */}
        </ModalHeader>
        <div>
                        <span className="modal modaltitleDisplay">
          {`Confirm Copying Data`}
                        
                        </span>
                    </div>
        <ModalBody style={{ minHeight: "60px" }}>
          <Label>{`You are about to copy the ${
            profile.CountrySpecs && profile.CountrySpecs[activeTabFC]?.label
          }'s data to other selected markets. This action is irreversible. Are you sure want to proceed with copying data ?`}</Label>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              if (!_.includes(pacificMarkets, project.CommissioningCountry)
              ||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
              ) {
                copyFormToCountries(activeTabFC);
              } else {
                copyFormToPacificCountries(activeTabFC);
              }
            }}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={() => setModalConfirmMethodology(false)}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      {/** Modal: Confirm data copying - End here */}

      {costingStatus.showCostingInput && (
        <Button
          color="primary"
          onClick={() => {
            dispatch(
              setCostingStatus({
                ...costingStatus,
                showSheetsCosts: false,
                showManualCostEntry: false,
                showCommercialCosts: false,
                showCostingInput: false,
              })
            );
          }}
        >
          Back To RFQ
        </Button>
      )}
    </React.Fragment>
  );
};
export default CountryMethodologyTabsCopy;
