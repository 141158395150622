import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";
import * as costingsActions from "../../redux/actions/costingsActions";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import * as currentWaveSpecActions from "../../redux/actions/currentWaveSpecActions";
import axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import classnames from "classnames";

import Layout from "../../layouts/Project";

import logo from "../../assets/img/avatars/modal-logo.png"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faQuestionCircle,
    faPen,
    faFile,
} from "@fortawesome/free-solid-svg-icons";

import {
    Button,
    CustomInput,
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Collapse,
    CardHeader,
    CardTitle,
    CardText,
    Tooltip,
    Modal,
    Badge,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label, Table, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import { faPencilAlt, faCopy, faPlus, faPlusCircle, faTimesCircle, faInfoCircle, faCommentAlt } from "@fortawesome/free-solid-svg-icons";

import {
    AvForm,
    AvField,
    AvRadioGroup,
    AvInput,
    AvRadio,
    AvSelectField
} from "availity-reactstrap-validation";
import { setCurrentWaveSpec, setWaveSpecs } from "../../redux/actions/waveSpecsActions";
import Selector from "../../components/Selector/Selector_Wavelevel";
import { getLabel } from "../../utils/codeLabels";
import FormFeedback from "reactstrap/lib/FormFeedback";
import Spinner from "../../components/Spinner";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { useParams, useLocation } from "react-router-dom";
import * as appActions from "../../redux/actions/appActions";
import * as mapperFunctions from "../../utils/rfqMapper";
import { calcAll } from "../../utils/calculations";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";
import ReturnControls from "../proposalNew/ReturnControl";
import { isCommissioningCountryIsFildingCountry } from "../../utils/helper";
import { useContext } from "react";
import { ChangesTracker } from "../proposalNew/CountryMethodologyTabsFinal";

const WaveLevelEdit = ({ profile, indxFC, methodology, indxSM, updateProject, showErrorMessages, errorFieldsObj, disableFields, isEditable }) => {
    const location = useLocation();
    const [list, setlist] = useState([]);
    const [opsResourcesVariables] = useState(["surveyProgrammingResource", "surveyProgrammingResourceVendor", "dataProcessingResource", "dataProcessingResourceVendor", "dataProcessingComplexity", "translationsResource", "translationsResourceVendor", "verbatimCodingResource", "verbatimCodingResourceVendor", "chartingResource", "chartingResourceVendor", "dashboardingResource", "dashboardingResourceVendor", "qualityControl", "qualityControlComments", "dataCollectionPlatform", "platformComments"])
    const [checkVariables, setCheckVariables] = useState(false);
    const [storeData, setStoreData] = useState([]);
    const currentCostingProfile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
    );
    const [wavesSpecsData, setwavesSpecsData] = useState(profile.WaveSpecs)
    var ischeck = false;
    const [activeTabFC, setActiveTabFC] = useState();
    const [activeTabSM, setActiveTabSM] = useState();
    const [activeWave, setActiveWave] = useState("1");

    const userRecord = useSelector(
        ({ user }) => user.userRecord
    );
    const [validationHeader, setValidationHeader] = useState("Cannot Save as some Fields are Mandatory!!");
    const [isModalOpen, handleOpen] = useState(false);
    const [indexSM, setIndexSM] = useState()

    const demofn = () => {

        ischeck = true
    }
    var imgStyle = {
        width: "150px",
      };
    const [errorObj, setErrorObj] = useState({});

    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );
    const costingProfiles = useSelector(
        ({ costings }) => costings.costingProfiles
    );


    const [editableCostingProfile, setEditableCosting] = useState(
        currentCostingProfile
    );

    const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
    const currentWaveSpec = useSelector(({ currentWaveSpec }) => currentWaveSpec);
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);
    const app = useSelector(({ app }) => app);

    const [isSaveModal, setSaveModal] = useState(false);
    const [waveEditModal, setWaveEditModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [editableWaveName, setEditableWavename] = useState();

    const [currentBusinessUnit, setCurrentBusinessUnit] = useState({})
    const [allFields] = useState(["DateWaveCommissioned", "NotesPM", "DateFinalQuestionnaire", "NotesFinalQuestionnaire", "DateFieldStart", "DateFieldEnd", "NotesFieldwork", "DateDataProcessing", "DateAnalysisPlan", "DateInterimData", "DateInterimTable", "DateFinalData", "NotesDataProcessing", "DateDashboards", "NotesDashboards", "DateCharts", "DateChartingtemplate", "DateFinalTableCharting", "NotesCharts", "DateTranslations", "NotesTranslations", "ScheduleTranslationsWordCount", "ScheduleTranslationType", "ScheduleOriginalFileFormat", "ScheduleFinalMaterialsDue", "DateVerbatimCoding", "NotesVerbatimCoding", "DateFinalReport", "DateFinalPresentation", "NotesFinalReport", "NotesDataScience", "NotesOther", "DateFinalQuestionnaireNA", "DateFieldworkNA", "DateDataProcessingNA", "DateDashboardsNA", "DateChartsNA", "DateTranslationsNA", "DateVerbatimCodingNA", "DateFinalReportNA", "DeliverableTypes"])
    let pacificData = _.head(codeLabels.PacificCountriesList).Label;
    const [pacificMarkets] = useState(pacificData.split(','));
    const rateCards = useSelector(({ rateCards }) => rateCards);

    const dispatch = useDispatch();
    // const [profile] = useState(currentCostingProfile);
    const [toggle, setToggle] = useState({
        overall: true,
        inst: true,
        other: true,
    });
    const [Fields, updateFields] = useState({

    });

    const collapseAll = () => {
        let Toggle = { ...toggle };
        Object.keys(Fields).map((field) => {
            Toggle[Fields[field].id] = !toggle.overall;
        });
        Object.keys(Toggle).map((t) => {
            Toggle[t] = !toggle.overall;
        });
        setToggle({ ...Toggle, overall: !toggle.overall });
    };
    const history = useHistory();
    const [methBreakdown, setmethBreakdown] = useState({})
    const [waveBreakDown, setWaveBreakDown] = useState({})
    const [currentWaveSet, setCurrentWaveSet] = useState([])
    const [modifiedMeths, setModifiedMeths] = useState([])

    const [countrySpecificData, setCountrySpecificData] = useState([]);
    const {hasDataChanged, deleteId} = useContext(ChangesTracker)


    useEffect(() => {
        if (currentCostingProfile &&
            wavesSpecsData
            && wavesSpecsData.length
            && !Object.keys(waveBreakDown).length) {

            let rData = (profile.CountrySpecs[indxFC]);
            let checData = (rData?.MethodologySpecs[indxSM])
            setActiveTabFC(rData.id);
            setActiveTabSM(checData.id);
            //console.log(indxSM, methodology, "index value check")
            let _waveBreakdownMain = wavesSpecsData.filter(x => x.MethodologySpecId === checData.id)
            let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
            setModifiedMeths(_waveBreakdownMain)

            Object.keys(_waveBreakdown).map(w => {
                _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
            })
            setwavesSpecsData(wavesSpecsData);
            setWaveBreakDown(_waveBreakdown)
            let data = _.head(_waveBreakdown[activeWave])
            setCurrentWaveSet(data)
            setStoreData(data);
            let tempVariables = mapperFunctions.getSchemaWithDataJSON({ ...data?.OpsResourcesSchema }, methodology.code, { ...data?.OpsResourcesData });
            if(!isCommissioningCountryIsFildingCountry(project, profile, indxFC)) {
                tempVariables.forEach(i => {
                    if(_.includes(mapperFunctions.SUD_FIELDS, i.id)) {
                        let defaultValue = "No"
                        if(i.id === "qualityControl") {
                            defaultValue = "No QC"
                        }
                        i.value = i.value || defaultValue
                    }
                })
            }
            var counter = 0
            tempVariables.forEach(x => {
                if (x.dependentOn || x.label == "Coding Required" || x.label == "Verbatim Coding Required") {
                    if (x.dependentOn) {
                        let dependentOnFields = x.dependentOn?.fields.map(x => x.fieldName);
                        let finalProps = Object.keys(data?.OpsResourcesSchema?.properties).filter(xy => _.includes(dependentOnFields, data?.OpsResourcesSchema?.properties[xy].title));
                        if (finalProps.length && finalProps.filter(f => (data?.OpsResourcesData && (data?.OpsResourcesData[f] === "Yes" || data?.OpsResourcesData[f] >= 0))).length) {
                            {
                                if (x.id === "numberOfOpenEndedToCode") {
                                    if ((data?.OpsResourcesData["numberOfSemiOpenEnded"] >= 0 && data?.OpsResourcesData["codingRequired"] === "Yes")) {
                                        x.disabled = false;
                                    }
                                    else {
                                        x.disabled = true;
                                    }
                                }
                                else {
                                    x.disabled = false;

                                }
                            }
                        }
                        else {
                            x.disabled = true;
                        }
                    }
                }
                else if (x.id == 'surveyProgrammingRequired') {
                    if (x.value != "Yes") {
                        if (tempVariables.filter(tv => tv.id == "surveyProgrammingResource")[0]) {
                            tempVariables.filter(tv => tv.id == "surveyProgrammingResource")[0].disabled = true;
                        }
                    }
                    else {
                        if (tempVariables.filter(tv => tv.id == "surveyProgrammingResource")[0]) {
                            tempVariables.filter(tv => tv.id == "surveyProgrammingResource")[0].disabled = false;
                        }
                    }
                }
                if (x.sectionTitle == 'Operations Resources') {
                    if (counter == 0 && (x.id != "surveyProgrammingResource" && !x.disabled || x.id == "surveyProgrammingResource" && tempVariables.filter(tv => tv.id == "surveyProgrammingRequired")[0].value && tempVariables.filter(tv => tv.id == "surveyProgrammingRequired")[0].value == "Yes")) {
                        x.isOps = true;
                        x.isNewSection = true;
                        x.sectionTitle = "Operations Resources";
                        counter++;

                    }
                    else {
                        x.isOps = true;
                        XMLHttpRequest.isNewSection = false;

                    }
                }
            });
            setlist(tempVariables);
            setCheckVariables(tempVariables?.filter(x => x.check));
            dispatch(currentWaveSpecActions.selectWaveSpec({ ...data }));

        }
    }, [currentCostingProfile])

    const ReturnControl = (v, indexV) => {

        return (
          <ReturnControls
          v= {v}
          methodology ={methodology}
          profile = {profile}
          project = {project}
          indexV ={indexV}
          indxFC = {indxFC}
          indxSM = {indxSM}
          errorFieldsObj ={errorFieldsObj}
          component = "SUD"
          InfoChange = {InfoChange}
          errorStyles = {errorStyles}
          disableFields = {disableFields}
          >
        
          </ReturnControls>
        )
        }        
    // const ReturnControl = (v, indexV) => {
    //     let defTable = v.defTable ? <FontAwesomeIcon
    //         title={'click to open definition'}
    //         className="align-middle mr-2 addButton"
    //         icon={faInfoCircle}
    //         // onClick={() => {
    //         //     setModalStateTableDef(true);
    //         //     setTableDef(v.defTable);
    //         // }}
    //         fixedWidth
    //         style={{ cursor: "pointer" }}
    //     /> : null;

    //     if (methodology?.SetupSchema?.properties[v.id]?.defaultValue && !v.value) {
    //         v.value = (v.type === 'integer' ||
    //             v.type === 'text' ||
    //             v.type === 'textarea' ||
    //             v.type === 'multi' ||
    //             v.type === 'date') ? methodology.SetupSchema.properties[v.id].defaultValue :
    //             (v.type === 'single') ? methodology.SetupSchema.properties[v.id].defaultValue.value : v.value
    //         if (v.value)
    //             InfoChange(indexV, v.value)
    //     }

    //     if (v.type == "integer") {
    //         let validate = v.id === "numberOfSemiOpenEndedToCode" && Object.entries(list).map(v => v[1]).find(v => v.id === "numberOfSemiOpenEnded").value
    //             ? { min: { value: 0 }, max: { value: parseInt(Object.entries(list).map(v => v[1]).find(v => v.id === "numberOfSemiOpenEnded").value) } }
    //             : { min: { value: 0 } }
    //         return (
    //             <FormGroup>
    //                 <Label>
    //                     {v.label + (v.mandatory ? '(*)' : '')}
    //                     {v.tooltext &&
    //                         <FontAwesomeIcon
    //                             title={v.tooltext}
    //                             className="align-middle mr-2 addButton"
    //                             icon={faInfoCircle}
    //                             fixedWidth
    //                             style={{ cursor: "pointer" }}
    //                         />
    //                     }
    //                     {defTable}
    //                 </Label>
    //                 <AvField
    //                     // disabled={_.includes(opsResourcesVariables, v.id)}
    //                     disabled={(profile.ProfileStatus == 99) || methodology.NotApplicable || disableFields}
    //                     name={"field_" + indexV}
    //                     type="number"
    //                     value={v.value || ''}
    //                     // errorMessage="Field is required"
    //                     validate={((!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
    //                     ))) ? validate : {}}
    //                     styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
    //                         && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) ? errorStyles : ""}
    //                     onChange={(e) => InfoChange(indexV, e.target.value)}
    //                 />
    //                 {errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave]
    //                     && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave], v.label) ? <p className="invalid-feedback d-flex">Field is required</p> : null}
    //             </FormGroup>
    //         )
    //     }
    //     else if (v.type == "single") {
    //         return (
    //             v.id === "approxPercentageChange" && methodology.RFQData &&
    //                 ((methodology.RFQData.trackingProject && methodology.RFQData.trackingProject != "Yes")
    //                     || parseInt(methodology.RFQData.numberOfWaves) < 2)
    //                 ? null :
    //                 <FormGroup>
    //                     <Label>

    //                         {v.label + (v.mandatory ? '(*)' : '')}
    //                         {v.tooltext &&
    //                             <FontAwesomeIcon
    //                                 title={v.tooltext}
    //                                 className="align-middle mr-2 addButton"
    //                                 icon={faInfoCircle}
    //                                 fixedWidth
    //                                 style={{ cursor: "pointer" }}
    //                             />
    //                         }
    //                         {defTable}
    //                     </Label>
    //                     <AvField
    //                         disabled={(profile.ProfileStatus == 99) || methodology.NotApplicable || disableFields}

    //                         tag={CustomInput}
    //                         name={v.label}
    //                         value={v.value ? v.value : ""}
    //                         type="select"
    //                         // errorMessage="Field is required"
    //                         // validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
    //                         // ))) ? { required: { value: v.mandatory } } : {}}
    //                         styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
    //                             && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
    //                             && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
    //                             && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) ? errorStyles : ""}
    //                         clearable
    //                         onChange={(e) => {
    //                             InfoChange(indexV, e.target.value)

    //                         }
    //                         }
    //                     >
    //                         <option value="0"></option>
    //                         {
    //                             v.optionsLabel ?
    //                                 codeLabels[v.optionsLabel].map(
    //                                     (opt) => (
    //                                         <option key={opt.Code} value={opt.Code}>{opt.Label}</option>
    //                                     ))
    //                                 :
    //                                 v.options?.map((option) => (
    //                                     <option key={option.value} value={option.value}>{option.label}</option>
    //                                 ))
    //                         }
    //                     </AvField>
    //                     {errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave]
    //                         && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave], v.label) ? <p className="invalid-feedback d-flex">Field is required</p> : null}
    //                 </FormGroup>
    //         )
    //     }
    //     else if (v.type == "multi" && (codeLabels[v.optionsLabel] && codeLabels[v.optionsLabel].find(x => x.Label.toLowerCase() == 'others'))) {
    //         return (
    //             <FormGroup>
    //                 <Label>
    //                     {v.label + (v.mandatory ? '(*)' : '')}
    //                     {v.tooltext &&
    //                         <FontAwesomeIcon
    //                             title={v.tooltext}
    //                             className="align-middle mr-2 addButton"
    //                             icon={faInfoCircle}
    //                             fixedWidth
    //                             style={{ cursor: "pointer" }}
    //                         />
    //                     }
    //                     {defTable}
    //                 </Label>
    //                 <CreatableSelect
    //                     isDisabled={(profile.ProfileStatus == 99) || methodology.NotApplicable || disableFields}
    //                     name={v.label}
    //                     value={v.value}
    //                     styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
    //                         && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) ? errorStyles : ""}
    //                     onChange={(e) => InfoChange(indexV, e)}
    //                     className="react-select-container"
    //                     classNamePrefix="react-select"
    //                     validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
    //                     ))) ? { required: { value: v.mandatory } } : {}}
    //                     options={
    //                         v.optionsLabel ?
    //                             codeLabels[v.optionsLabel].map(
    //                                 (opt) => {
    //                                     return {
    //                                         value: opt.Code,
    //                                         label: opt.Label,
    //                                     }
    //                                 })
    //                             :
    //                             v.options?.map((option) => {
    //                                 return {
    //                                     value: option.value,
    //                                     label: option.label,
    //                                 }
    //                             })

    //                     }
    //                     isMulti
    //                 />
    //                 {errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave]
    //                     && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave], v.label) ? <p className="invalid-feedback d-flex">Field is required</p> : null}
    //             </FormGroup>
    //         )
    //     }
    //     else if (v.type == "multi")
    //         return (
    //             <FormGroup>
    //                 <Label>
    //                     {v.label + (v.mandatory ? '(*)' : '')}
    //                     {v.tooltext &&
    //                         <FontAwesomeIcon
    //                             title={v.tooltext}
    //                             className="align-middle mr-2 addButton"
    //                             icon={faInfoCircle}
    //                             fixedWidth
    //                             style={{ cursor: "pointer" }}
    //                         />
    //                     }
    //                     {defTable}
    //                 </Label>

    //                 <Select
    //                     isDisabled={(profile.ProfileStatus == 99) || methodology.NotApplicable || disableFields}
    //                     name={v.label}
    //                     value={v.value}
    //                     styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave]

    //                         && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave], v.label) ? errorStyles : ""}

    //                     onChange={(e) => InfoChange(indexV, e)}
    //                     className="react-select-container"
    //                     classNamePrefix="react-select"
    //                     validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
    //                     ))) ? { required: { value: v.mandatory } } : {}}
    //                     options={
    //                         v.optionsLabel ?
    //                             codeLabels[v.optionsLabel].map(
    //                                 (opt) => {
    //                                     return {
    //                                         value: opt.Code,
    //                                         label: opt.Label,
    //                                     }
    //                                 })
    //                             :
    //                             v.options?.map((option) => {
    //                                 return {
    //                                     value: option.value,
    //                                     label: option.label,
    //                                 }
    //                             })

    //                     }
    //                     isMulti
    //                 />
    //                 {errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave]
    //                     && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave], v.label) ? <p className="invalid-feedback d-flex">Field is required</p> : null}
    //             </FormGroup>
    //         )
    //     else if (v.type == "text")
    //         return (
    //             <FormGroup>
    //                 <Label>
    //                     {v.label + (v.mandatory ? '(*)' : '')}
    //                     {v.tooltext &&
    //                         <FontAwesomeIcon
    //                             title={v.tooltext}
    //                             className="align-middle mr-2 addButton"
    //                             icon={faInfoCircle}
    //                             fixedWidth
    //                             style={{ cursor: "pointer" }}
    //                         />
    //                     }
    //                     {defTable}
    //                 </Label>
    //                 <AvField
    //                     disabled={(profile.ProfileStatus == 99) || methodology.NotApplicable || disableFields}
    //                     name={"field_" + indexV}
    //                     type="text"
    //                     // errorMessage="Field is required"
    //                     // validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
    //                     // ))) ? { required: { value: v.mandatory } } : {}}
    //                     styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
    //                         && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) ? errorStyles : ""}
    //                     onChange={(e) => InfoChange(indexV, e.target.value)}
    //                     value={v.value || ''}
    //                 />
    //                 {errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave]
    //                     && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave], v.label) ? <p className="invalid-feedback d-flex">Field is required</p> : null}
    //             </FormGroup>
    //         )

    //     else if (v.type == "textarea")
    //         return (
    //             <FormGroup>
    //                 <Label>
    //                     {v.label + (v.mandatory ? '(*)' : '')}
    //                     {v.tooltext &&
    //                         <FontAwesomeIcon
    //                             title={v.tooltext}
    //                             className="align-middle mr-2 addButton"
    //                             icon={faInfoCircle}
    //                             fixedWidth
    //                             style={{ cursor: "pointer" }}
    //                         />
    //                     }
    //                     {defTable}
    //                 </Label>
    //                 <AvField
    //                     disabled={(profile.ProfileStatus == 99) || methodology.NotApplicable || disableFields}
    //                     name={"field_" + indexV}
    //                     type="textarea"
    //                     // errorMessage="Field is required"
    //                     // validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
    //                     // ))) ? { required: { value: v.mandatory } } : {}}
    //                     styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
    //                         && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) ? errorStyles : ""}
    //                     onChange={(e) => InfoChange(indexV, e.target.value)}
    //                     value={v.value || ''}
    //                 />
    //                 {errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave]
    //                     && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave], v.label) ? <p className="invalid-feedback d-flex">Field is required</p> : null}
    //             </FormGroup>
    //         )
    //     else if (v.type == "date")
    //         return (
    //             <FormGroup>
    //                 <Label>
    //                     {v.label + (v.mandatory ? '(*)' : '')}
    //                     {v.tooltext &&
    //                         <FontAwesomeIcon
    //                             title={v.tooltext}
    //                             className="align-middle mr-2 addButton"
    //                             icon={faInfoCircle}
    //                             fixedWidth
    //                             style={{ cursor: "pointer" }}
    //                         />
    //                     }
    //                     {defTable}
    //                 </Label>
    //                 <AvField
    //                     disabled={(profile.ProfileStatus == 99) || methodology.NotApplicable || disableFields}
    //                     name={"field_" + indexV}
    //                     placeholder=""
    //                     type="date"
    //                     // errorMessage="Field is required"
    //                     // validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
    //                     // ))) ? { required: { value: v.mandatory } } : {}}
    //                     styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
    //                         && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
    //                         && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) ? errorStyles : ""}
    //                     onChange={(e) => InfoChange(indexV, e.target.value)}
    //                     value={v.value || ''}
    //                 />
    //                 {errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
    //                     && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave]
    //                     && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]["NO"][activeWave], v.label) ? <p className="invalid-feedback d-flex">Field is required</p> : null}
    //             </FormGroup>
    //         )

    //     else if (v.type == "header")
    //         return (
    //             <>
    //                 <h5 id={"header_" + indexV} className={'header'}>{v.label}</h5>
    //                 {v.instructions && <h6 id={"header_i_" + indexV}>{v.instructions}</h6>}
    //             </>
    //         )

    // };
    function getChangedValue(value) {
        let rValue = null;
        if (isNaN(value))
            rValue = "'" + value + "'";
        else
            rValue = value || 0;
        return rValue;
    }
    const errorStyles = {

        control: (styles) => ({ ...styles, borderColor: "#AD4400" })


    }
    function InfoChange(indexV, value) {

        const variablesClone = _.cloneDeep(list);
        const currentVariable = variablesClone[indexV];
        currentVariable["value"] = value;
        var counter = 0;

        variablesClone.forEach((v, indexV) => {

            if (v.dependentOn && v.dependentOn.fields.find(f => f.fieldName == currentVariable.label)) {
                if (v.label == 'Approx Percentage Change - Each Wave') {

                }
                let op = v.dependentOn.andor == "OR" ? "||" : "&&";
                let expression = "";
                v.dependentOn.fields.forEach((f, indexF) => {

                    let field = f.fieldName != currentVariable.label ? variablesClone.filter(v => v.label == f.fieldName)[0] : currentVariable;
                    let fieldVal = field ? field.value : null;
                    if (Array.isArray(fieldVal)) {
                        let tempBool = variablesClone.filter(v => v.label == f.fieldName)[0]["value"].filter(x => x.label == f.fieldValue).length > 0;
                        expression += tempBool + f.operator + "true" + (indexF != v.dependentOn.fields.length - 1 ? op : '');
                    }
                    else {
                        expression += getChangedValue(fieldVal) + f.operator + getChangedValue(f.fieldValue) + (indexF != v.dependentOn.fields.length - 1 ? op : '');
                    }
                });

                let enabled = eval(expression);
                if (enabled) {
                    v.disabled = false;
                    //custom code starts
                    // if (v.label == 'Approx Percentage Change - Each Wave') {

                    //     // if (!v.enabled) {
                    //     //     v.disabled = true;
                    //     // }
                    // }
                    //custom code ends
                }
                else {
                    v.disabled = true;
                    v.value = null;
                    deleteId(v.id, indxFC, indxSM, activeWave - 1)
                    variablesClone.forEach((v2, indexV2) => {
                        if (v2.dependentOn && v2.dependentOn.fields.find(f => f.fieldName == v.label)) {
                            v2.disabled = true;
                            v2.value = null;
                        }
                    });
                }
            }
            if (v.id == 'surveyProgrammingRequired') {
                if (v.value != "Yes") {
                    if (variablesClone.filter(tv => tv.id == "surveyProgrammingResource")[0]) {
                        variablesClone.filter(tv => tv.id == "surveyProgrammingResource")[0].disabled = true;
                    }
                }
                else {
                    if (variablesClone.filter(tv => tv.id == "surveyProgrammingResource")[0]) {
                        variablesClone.filter(tv => tv.id == "surveyProgrammingResource")[0].disabled = false;
                    }
                }
            }

            if (v.sectionTitle == 'Operations Resources') {
                if (counter == 0 && (v.id != "surveyProgrammingResource" && !v.disabled || v.id == "surveyProgrammingResource" && variablesClone.filter(tv => tv.id == "surveyProgrammingRequired")[0].value && variablesClone.filter(tv => tv.id == "surveyProgrammingRequired")[0].value == "Yes")) {
                    v.isOps = true;
                    v.isNewSection = true;
                    v.sectionTitle = "Operations Resources";
                    counter++;

                }
                else {
                    v.isOps = true;
                    v.isNewSection = false;

                }
            }

        });

        
        let waveData = modifiedMeths[activeWave - 1]?.OpsResourcesData
        const hasChanged = hasDataChanged(waveData, variablesClone[indexV], indxFC, indxSM, activeWave - 1)
        isEditable(hasChanged);

        let checkdata = mapperFunctions.getDataJSON(variablesClone);
        storeData.OpsResourcesData = checkdata;
        //console.log(storeData, "StoredData");
        // let tempVariables = mapperFunctions.getSchemaWithDataJSON(storeData.OpsResourcesSchema, "", storeData.OpsResourcesData);

        setlist(variablesClone);
        setCheckVariables(variablesClone?.filter(x => x.check));
        var foundIndex = wavesSpecsData.findIndex(x => x.id == storeData.id);
        wavesSpecsData[foundIndex] = storeData;
        //console.log(wavesSpecsData, "wavesSpecsData")

        let newWaveSpecsCalc = calcAll(
            project,
            currentCostingProfile,
            currentCostingProfile.CountrySpecs,
            wavesSpecsData,
            rateCards,
            pacificMarkets
        );
        setwavesSpecsData(newWaveSpecsCalc.waveSpecs);
        const profileTemp = update(profile, {

            WaveSpecs: { $set: wavesSpecsData }
        });

        const tempProfiles = project.CostingProfiles.map(cp => {
            if (cp.id == profileTemp.id)
                return { ...profileTemp }
            return { ...cp }
        });
        dispatch(updateProject({ CostingProfiles: tempProfiles }));
        // change by Ravi Punnam
        //updating Profile when any change happens
        dispatch(currentCostingActions.updateProfile(profileTemp))

    }

    const checkValues = (v, label, label2, indexV) => {


        var index = list.filter(x => { return (x.label == label2) })[0].checkOptions.map(z => { return (z.label) }).indexOf(label)

        var element = checkVariables.filter(cv => { return (cv.label == label2) })
        let cData = element[0].value
        let str1 = cData.substr(0, index)
        let str2 = cData.substr(index + 1, cData.length - index)
        let str = ""
        if (v.target.checked)
            str = str1 + "t" + str2
        if (!v.target.checked)
            str = str1 + "f" + str2

        list[list.map(cv => { return (cv.label) }).indexOf(label2)].value = str
        let cVariables = [...checkVariables]
        cVariables[cVariables.map(cv => { return (cv.label) }).indexOf(label2)].value = str

        let chval = checkVariables.map(c => { return c.value })

        setCheckVariables(cVariables)



        //v.target.checked= !v.target.checked

    }
    const selectorHandler = (item) => {
        let _waveBreakdownMain = wavesSpecsData.filter(x => x.MethodologySpecId === activeTabSM)
        setActiveWave(item.WaveNumber);
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
        setModifiedMeths(_waveBreakdownMain)
        Object.keys(_waveBreakdown).map(w => {
            _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
        })
        // do nothing if clicked item is current item
        if (item === _.head(currentWaveSet)?.MethodologySpecId) return;
        else {
            setCurrentWaveSet(_.head(_waveBreakdown[item.WaveNumber]))
            let data = _.head(_waveBreakdown[item.WaveNumber])
            setStoreData(data);
            let tempVariables = mapperFunctions.getSchemaWithDataJSON({ ...data?.OpsResourcesSchema }, methodology.code, { ...data?.OpsResourcesData });
            var counter = 0
            tempVariables.forEach(x => {
                if (x.dependentOn || x.label == "Coding Required" || x.label == "Verbatim Coding Required") {
                    if (x.dependentOn) {
                        let dependentOnFields = x.dependentOn?.fields.map(x => x.fieldName);
                        let finalProps = Object.keys(data?.OpsResourcesSchema?.properties).filter(xy => _.includes(dependentOnFields, data?.OpsResourcesSchema?.properties[xy].title))
                        if (finalProps.length && finalProps.filter(f => (data?.OpsResourcesData && (data?.OpsResourcesData[f] === "Yes" || data?.OpsResourcesData[f] >= 0))).length) {
                            {
                                if (x.id === "numberOfOpenEndedToCode") {
                                    if ((data?.OpsResourcesData["numberOfSemiOpenEnded"] >= 0 && data?.OpsResourcesData["codingRequired"] === "Yes")) {
                                        x.disabled = false;
                                    }
                                    else {
                                        x.disabled = true;
                                    }
                                }
                                else {
                                    x.disabled = false;

                                }
                            }
                        }
                        else {
                            x.disabled = true;
                        }
                    }
                }
                else if (x.id == 'surveyProgrammingRequired') {
                    if (x.value != "Yes") {
                        if (tempVariables.filter(tv => tv.id == "surveyProgrammingResource")[0]) {
                            tempVariables.filter(tv => tv.id == "surveyProgrammingResource")[0].disabled = true;
                        }
                    }
                    else {
                        if (tempVariables.filter(tv => tv.id == "surveyProgrammingResource")[0]) {
                            tempVariables.filter(tv => tv.id == "surveyProgrammingResource")[0].disabled = false;
                        }
                    }
                }
                if (x.sectionTitle == 'Operations Resources') {
                    if (counter == 0 && (x.id != "surveyProgrammingResource" && !x.disabled || x.id == "surveyProgrammingResource" && tempVariables.filter(tv => tv.id == "surveyProgrammingRequired")[0].value && tempVariables.filter(tv => tv.id == "surveyProgrammingRequired")[0].value == "Yes")) {
                        x.isOps = true;
                        x.isNewSection = true;
                        x.sectionTitle = "Operations Resources";
                        counter++;

                    }
                    else {
                        x.isOps = true;
                        XMLHttpRequest.isNewSection = false;

                    }
                }

            });
            //console.log(tempVariables, "loop check");
            setlist(tempVariables);
            setCheckVariables(tempVariables?.filter(x => x.check));

        }
        if (_waveBreakdown[item.WaveNumber] && _waveBreakdown[item.WaveNumber].length)
            dispatch(currentWaveSpecActions.selectWaveSpec({ ..._.head(_waveBreakdown[item.WaveNumber]) }));
    };
    const applyToAllWaves = () => {

        modifiedMeths.map(x => x.OpsResourcesData = storeData.OpsResourcesData)
        setModifiedMeths(modifiedMeths)
        const profileTemp = update(profile, {

            WaveSpecs: { $set: wavesSpecsData }
        });

        const tempProfiles = project.CostingProfiles.map(cp => {
            if (cp.id == profileTemp.id)
                return { ...profileTemp }
            return { ...cp }
        });
        dispatch(updateProject({ CostingProfiles: tempProfiles }));
        // change by Ravi Punnam
        //updating Profile when any change happens
        dispatch(currentCostingActions.updateProfile(profileTemp))
        toastr.success("Success", "Applied to all the Waves");


    }
    const renderSelector = () => {
        if (!waveSpecs || (waveSpecs && waveSpecs.length === 1)) return null;
        return (
            <Col lg="2" md="2" sm="12" xs="12">
                {disableFields ? <Selector
                    heading={"Waves"}
                    records={modifiedMeths}
                    // applyAll={applyToAllWaves}
                    // applyAllText={"Apply to All Waves"}
                    clicked={selectorHandler}
                    interPolField={["WaveNumber", "WaveName"]}
                    selected={currentWaveSet}
                /> : <Selector
                    heading={"Waves"}
                    records={modifiedMeths}
                    applyAll={applyToAllWaves}
                    applyAllText={"Apply to All Waves"}
                    clicked={selectorHandler}
                    interPolField={["WaveNumber", "WaveName"]}
                    selected={currentWaveSet}
                />}
            </Col>
        );
    };

    const onChangeHandler = (eve, useCurrentSpec, isApi) => {
        let currentwavespec = {};
        if (useCurrentSpec) {
            currentwavespec = {
                ...useCurrentSpec,
                [eve.target.id]: eve.target.value,
            };
        } else {
            currentwavespec = {
                ...currentWaveSpec,
                [eve.target.id]: eve.target.value,
            };
        }


        let wavespecs = [...waveSpecs];
        wavespecs = wavespecs.map((ws) => {
            if (ws.id == currentWaveSpec.id) {
                ws = currentwavespec;
            }
            return { ...ws };
        });
        dispatch(setWaveSpecs([...wavespecs]));
        dispatch(
            currentWaveSpecActions.updateCurrentWaveSpec({ ...currentwavespec }));

        let _waveBreakdown = _.groupBy(wavespecs, m => m.WaveNumber)
        Object.keys(_waveBreakdown).map(w => {
            _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
        })
        //todo: please check
        // this might not work for multi methodologies
        setCurrentWaveSet(_waveBreakdown[currentWaveSpec.WaveNumber])


        let editablecostingprofile = { ...editableCostingProfile };
        editablecostingprofile.WaveSpecs = editablecostingprofile.WaveSpecs.map(
            (ws) => {
                if (ws.id == currentwavespec.id) {
                    return { ...currentwavespec };
                } else {
                    return { ...ws };
                }
            }
        );
        setEditableCosting(editablecostingprofile);
        if (isApi) {
            dispatch(
                currentCostingActions.saveCostingProfile(
                    { ...editablecostingprofile },
                    () => setWaveEditModal(false)
                )
            );
            dispatch(
                currentCostingActions.updateCostingProfiles(costingProfiles, {
                    ...editablecostingprofile,
                })
            );
        }

    };
    return (
        profile.WaveSpecs?.length ?
            <Card>
                <Row>
                    {list?.length ?
                        <Container>
                            <Card
                                className="ml-2 mr-2 mb-0 p-0"
                                style={{ background: "none", boxShadow: "none" }}>
                                <Row>
                                    {renderSelector()}
                                    <Col>
                                        <Card className="ml-2 mr-2">
                                            <CardHeader>
                                                <Row>
                                                    <Col style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <CardTitle id="header_op_r" className="mb-0">
                                                            Setup and Delivery
                                                        </CardTitle>
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={12} className={"countryTabs tab-vertical"}>
                                                        <div className={"tab"}>
                                                            <Container fluid className="p-1">
                                                                <AvForm encType="multipart/form-data" id="OpsResourcesFinalForm"
                                                                    onSubmit={(e, err) => showErrorMessages(e, err, "OpsformErrors")}
                                                                >
                                                                    <Row>
                                                                        {list && list.map((v, indexV) => {

                                                                            if (!v.Default && !v.selected && !v.disabled && v.isNewSection)
                                                                                return (<>
                                                                                    <Col md={'12'}><h5 id={"header_" + indexV} className={'header'}>{v.sectionTitle}</h5></Col>
                                                                                    {v.instructions && <Col md={'12'}><h6 id={"header_" + indexV}> {v.instructions}</h6></Col>}
                                                                                    <Col md={v.width || '4'}
                                                                                        key={'c_' + indxFC + '_m_' + indxSM + '_co_' + indexV}
                                                                                    >
                                                                                        {ReturnControl(v, indexV)}
                                                                                    </Col></>)

                                                                            else if ((v.selected || v.Default) && v.isNewSection && list?.find(opr => !opr.selected && !opr.disabled && opr.sectionTitle == v.sectionTitle))
                                                                                return (<Col md={'12'}>
                                                                                    <h5 id={"header_" + indexV} className={'header'}>{v.sectionTitle}</h5>
                                                                                    {v.instructions && <Col md={'12'}><h6 id={"header_" + indexV}> {v.instructions}</h6></Col>}
                                                                                </Col>)

                                                                            else if (!v.selected && !v.disabled && !v.Default && !v.isNewSection)
                                                                                return (<Col md={v.width || '4'}
                                                                                    key={'c_' + indxFC + '_m_' + indxSM + '_co_' + indexV}
                                                                                >
                                                                                    {ReturnControl(v, indexV)}

                                                                                </Col>)

                                                                            else if (v.check && !ischeck) {
                                                                                demofn()
                                                                                return (
                                                                                    <Table size="sm" bordered style={{ backgroundColor: "white", width: "100%" }}>
                                                                                        <thead>
                                                                                            <tr> <td md={"4"}><b>Type Of Interactions</b></td>
                                                                                                {checkVariables[0].checkOptions.map(co => {
                                                                                                    return (<td><b>{co.label}</b></td>)
                                                                                                })}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {checkVariables.map((v, index) => {
                                                                                                return (<tr><td md={"4"}>
                                                                                                    {v.label}
                                                                                                </td>
                                                                                                    {v.checkOptions.map((co, coIndex) => {
                                                                                                        return (<td style={{ textAlign: 'center' }}><input
                                                                                                            type="checkbox"
                                                                                                            name={v.label}
                                                                                                            checked={checkVariables[index].value[coIndex] == "f" ? false : true}
                                                                                                            onChange={(e) => checkValues(e, co.label, v.label, indexV)}
                                                                                                        ></input></td>)

                                                                                                    })}
                                                                                                </tr>
                                                                                                )
                                                                                            }
                                                                                            )}
                                                                                        </tbody>
                                                                                    </Table>)
                                                                            }
                                                                        }
                                                                        )}
                                                                    </Row>
                                                                </AvForm>
                                                            </Container>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Container>
                        : null}
                </Row>

                <Col className="justify-content-end">

                    <>
                        {/* <Button className="savedraft" color="primary" onClick={copyToAllMethodologyWaves}>
                        Advance Copy

                    </Button> */}

                        {/* onClick event is commented already. save button creating css issue in rfq page commented by siva  */}
                        {/* {!disableFields && <Button className="validateandsave" color="primary"
                        
                        // onClick={() => {
                        //     validateSave();
                        // }}
                        >
                            Save
                        </Button>
                        } */}
                    </>
                </Col>

                <Modal isOpen={isSaveModal} toggle={() => setSaveModal(false)} size="lg" centered={true}>
                    <ModalHeader toggle={() => setSaveModal(false)}>
                    {/* <img src={logo}  className="modallogo"/> */}
                    </ModalHeader>
                    <div>
                        <span className="modaltitle modalEWN">
                        Early Warning Notification
                        </span>
                    </div>
                    <ModalBody>
                        <p>Your changed have saved successfully.</p>
                        <p>
                            <strong>Would you like to send out an updated EWN?</strong>
                        </p>
                        <p>
                            An email notification will be sent out to all relevant operations
                            teams if the dates are provided for the team.
                        </p>
                        <p>
                            <em>
                                Please Note: You are also required to include finance in the
                                notifications{" "}
                                <strong>
                                    if there are any changes in fieldwork or reports due dates.
                                </strong>{" "}
                                A seperate notification will be sent to the Finance Team contacts.
                            </em>
                        </p>


                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={waveEditModal}
                    toggle={() => setWaveEditModal(!waveEditModal)}
                    centered
                >
                    <ModalHeader toggle={() => setWaveEditModal(!waveEditModal)}>
                        Edit Current Wave Name
                    </ModalHeader>
                    <ModalBody>
                        <Input
                            defaultValue={
                                currentWaveSpec.WaveName ? currentWaveSpec.WaveName : ""
                            }
                            id="WaveName"
                            onChange={(e) => setEditableWavename(e.target.value)}
                            placeholder="Enter Wave Name"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            disabled={app.recordloading}
                            onClick={(e) => {
                                onChangeHandler(
                                    { target: { id: "WaveName", value: editableWaveName } },
                                    null,
                                    true
                                );
                            }}
                        >
                            Update
                            {app.recordloading ? (
                                <Spinner size="small" color="#495057" />
                            ) : null}
                        </Button>
                        <Button
                            color="secondary"
                            disabled={app.recordloading}
                            onClick={() => setWaveEditModal(false)}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

            </Card>
            : <Card> {methodology.SetupSchema && Object.keys(methodology.SetupSchema).length
                ?
                <Card className="ml-2 mr-2 mb-0 p-0">
                    <CardHeader>
                        <Row>
                            <Col style={{ display: "flex", justifyContent: "space-between" }}>
                                <CardTitle id="header_op_r" className="mb-0">
                                    Setup and Delivery
                                </CardTitle>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        Please Save the RFQ Form to input Setup and Delivery/ Operations Resources
                    </CardBody>
                </Card>
                : null
            }
            </Card>
    );
};


export default WaveLevelEdit;

