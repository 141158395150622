import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card,
    CardHeader,
    Col,
    Container,
    CustomInput,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
} from "reactstrap";
import _ from "lodash";
import classnames from "classnames";

import {
    setCurrentWaveSpec,
    setWaveSpecs,
} from "../../redux/actions/waveSpecsActions";
import { selectWaveSpec } from "../../redux/actions/currentWaveSpecActions";
import Selector from "../../components/Selector/Selector_Updated";
import { getLabel } from "../../utils/codeLabels";

const CostBreakDown = () => {
    const dispatch = useDispatch();
    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);

    const currentCostingProfile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
    );
    const currencies = useSelector(({ currentCosting }) =>
        currentCosting.currentCostingProfile.ProfileSetting &&
            currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
            ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
            : []
    );
    let pacificData = _.head(codeLabels.PacificCountriesList)?.Label;
    const [pacificMarkets] = useState(pacificData?.split(','));
    const countrySpecs = useSelector(({ countrySpecs }) => countrySpecs);
    const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
    // const currentWaveSpec = useSelector(({ currentWaveSpec }) => currentWaveSpec);
    const app = useSelector(({ app }) => app);
    const costingProfiles = useSelector(({ costings }) => costings.costingProfiles);
    // const currentCosting = useSelector(({ currentCosting }) => currentCosting.currentCostingProfile);
    const costingStatus = useSelector(({ app }) => app.costingStatus);

    const [countryDetails, setCountryDetails] = useState({});

    const [currentCurrency, setCurrentCurrency] = useState();
    const [methCountrySet, setMethCountrySet] = useState({})
    const [methCountryCostSet, setMethCountryCostSet] = useState({})
    const [methCostFieldsSet, setMethCostFieldsSet] = useState({})
    const [activeCostTab, setActiveCostTab] = useState(0)
    const [shopperMethodologies] = useState(['SM000024', 'SM000026', 'SM000029'])
    const [profile] = useState(currentCostingProfile);
    const [wavesData, setWavesData] = useState(currentCostingProfile.WaveSpecs)
    const [activeTabFC, setActiveTabFC] = useState();
    const [activeTabSM, setActiveTabSM] = useState();
    const [waveBreakDown, setWaveBreakDown] = useState({})
    const [currentWaveSpec, setCurrentWaveSpec] = useState({});
    useEffect(() => {
        if (currentCostingProfile &&
            wavesData
            && wavesData.length
            && !Object.keys(waveBreakDown).length
        ) {

            let countryData = _.head(profile.CountrySpecs);
            let mData = countryData.MethodologySpecs?.filter(ms => !ms.NotApplicable );
            let methodologyID = _.head(mData).id
            
            setActiveTabFC(countryData.id);
            setActiveTabSM(methodologyID);
            let _waveBreakdownMain = wavesData.filter(x => x.MethodologySpecId === methodologyID)
            let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
            Object.keys(_waveBreakdown).map(w => {
                _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
            });
            setWavesData(wavesData);
            setWaveBreakDown(_waveBreakdown)
            let _currentwaveset = _.head(Object.values(_waveBreakdown))
            let data = _.head(_currentwaveset)
            setCurrentWaveSpec(data);
        }
    }, [currentCostingProfile])

    useEffect(() => {
        if (
            currencies &&
            !currentCurrency &&
            currentCostingProfile.CostInputCurrency
        ) {
            let values = currentCostingProfile.CostInputCurrency.split("-");
            let currencyUnit = _.last(values);
            let countryCode = _.head(values);
            let finalCurrency = _.head(
                currencies.filter(
                    (cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
                )
            );
            setCurrentCurrency(finalCurrency);
        }
        if (!Object.keys(methCountrySet).length && currentCostingProfile.CountrySpecs?.length) {
            let methBreak = {}
            currentCostingProfile.CountrySpecs.map(cs => {
                cs.MethodologySpecs?.map(ms => {
                    if (!methBreak[ms.Code]) {
                        methBreak[ms.Code] = { methlabel: ms.Label }
                    }
                })
            })
            setMethCountrySet(methBreak)
        }
        // let finalCostFields = {};
        let _methCountryCostSet = {}
        let _methCostFieldsSet = {}
        // _.head(currentCostingProfile.CountrySpecs)?.MethodologySpecs?.map((ms) => {
        // 	if (ms) {
        // 		Object.keys(ms.CalculationSchema).map((csch) => {
        // 			if (!finalCostFields[csch]) {
        // 				if (_.includes(includeOnlyInMCP, csch)) {
        // 					if (currentCostingProfile.IsMultiCountry) finalCostFields[csch] = [];
        // 				} else {
        // 					finalCostFields[csch] = [];
        // 				}
        // 			}
        // 			Object.keys(ms.CalculationSchema[csch]).map((insch) => {
        // 				if (
        // 					finalCostFields[csch] &&
        // 					!_.includes(finalCostFields[csch], insch)
        // 				) {
        // 					finalCostFields[csch].push(insch);
        // 				}
        // 			});
        // 			if (additionalLabels && additionalLabels[csch]) {
        // 				Object.keys(additionalLabels[csch]).map((lab) => {
        // 					if (!_.includes(finalCostFields[csch], lab)) {
        // 						finalCostFields[csch].push(lab);
        // 					}
        // 				});
        // 			}
        // 		});
        // 	}
        // });
        if (!Object.keys(methCountryCostSet).length && currentCostingProfile.CountrySpecs?.length
            && !Object.keys(methCostFieldsSet).length && currentCostingProfile.Project) {
            currentCostingProfile.CountrySpecs.map(cs => {
                cs.MethodologySpecs.filter(ms => (!_.includes(shopperMethodologies, ms.Code) || (_.includes(pacificMarkets, currentCostingProfile.Project.CommissioningCountry) && currentCostingProfile.Project.CostingTemplateVersion != "v2.0"))).map(ms => {
                    if (ms) {
                        if (!_methCountryCostSet[ms.Code])
                            _methCountryCostSet[ms.Code] = {}
                        _methCountryCostSet[ms.Code][cs.CountryCode] = ms.CostsData

                        if (!_methCostFieldsSet[ms.Code]) {
                            _methCostFieldsSet[ms.Code] = {}
                            ms.CostsSchema.map(cssch => {
                                Object.keys(cssch.properties).map(_prop => {
                                    _methCostFieldsSet[ms.Code][_prop] = cssch.properties[_prop]
                                })
                                // _methCostFieldsSet[ms.Code] = [..._methCostFieldsSet[ms.Code], ...Object.keys(cssch.properties).map(_prop => {
                                // 	return { [_prop]: cssch.properties[_prop] }
                                // })]
                            })
                        }
                    }
                })
            })
            //console.log(_methCostFieldsSet, "_methCostFieldsSet")
            //console.log(_methCountryCostSet, "_methCountryCostSet")
            setMethCountryCostSet(_methCountryCostSet)
            setMethCostFieldsSet(_methCostFieldsSet)
        }

        // setCostFields(finalCostFields);
    }, [currentCostingProfile]);
    useEffect(() => {
        if (countrySpecs && countrySpecs.length && !Object.keys(countryDetails).length) {
            let countrydetails = {};
            countrySpecs.map((cs) => {
                countrydetails[cs.CountryCode] = {};
                cs.MethodologySpecs.map((ms) => {
                    countrydetails[cs.CountryCode][ms.Code] = ms.CostsData;
                });
            });
            setCountryDetails(countrydetails);
        }
    }, [countrySpecs]);
    // useEffect(() => {
    //     if (currentWaveSpec.id != currentWaveSpec.id)
    //         setcurrentWaveSpec({ ...currentWaveSpec })
    // }, [currentWaveSpec])
    const [overRideFields, setOverrideFields] = useState({
        OverrideOpsPMCost: {
            //rfq
            Internal: "CostIntOpsPM",
            External: "",
            Label: "Project Management (Ops Only)",
        },
        OverrideSurveyProgrammingCost: {
            decisiveField: "surveyProgrammingResource",
            Internal: "CostIntOpsSurveyProgramming",
            External: "CostExtOpsSurveyProgramming",
            Label: "Survey Programming",
        },
        OverrideDataProcessingCost: {
            decisiveField: "dataProcessingResource",
            Internal: "CostIntOpsDataProcessing",
            External: "CostExtOpsDataProcessing",
            Label: "Data Processing",
        },

        OverrideChartingCost: {
            decisiveField: "chartingResource",
            Internal: "CostIntOpsCharting",
            External: "CostExtOpsCharting",
            Label: "Charting",
        },
        OverrideCodingCost: {
            decisiveField: "verbatimCodingResource",
            Internal: "CostIntOpsVerbatimCoding",
            External: "CostExtOpsVerbatimCoding",
            Label: "Verbatim Coding",
        },
        OverrideCodingCost: {
            decisiveField: "codingResource",
            Internal: "CostIntOpsVerbatimCoding",
            External: "CostExtOpsVerbatimCoding",
            Label: "Coding",
        },
     
        OverrideDataEntryCost: {
            decisiveField: "dataEntryResource",
            Internal: "CostIntOpsDataEntry",
            External: "CostExtOpsDataEntry",
            Label: "Data Entry",
        },

        OverrideOnlineSampleCost: {
            //rfq
            Internal: "",
            External: "CostExtOpsOnlineSample",
            Label: "Online Sample",
        },

        OverrideAdditionalOperationsSupportCost: {
            //bool
            decisiveField: "additionalOperationsSupport",
            Internal: "CostIntOpsAdditionalOperationsSupport",
            External: "",
            Label: "Additional Operations Support (PM Rates)",
            isBool: true,
        },
        OverrideOtherDataPreparationCost: {
            //bool
            decisiveField: "otherDataPreparationAssistance",
            Internal: "CostIntOpsOtherDataPreparation",
            External: "",
            Label: "Other Data Preparation",
            isBool: true,
        },
        OverrideDataScienceCost: {
            //internal-operations//bool
            decisiveField: "dataScienceRequired",
            Internal: "CostIntOpsDataScience",
            External: "",
            Label: "Data Science (Ops Only)",
            isBool: true,
        },
        OverrideTextAnalyticsCost: {
            decisiveField: "textAnalytics", //bool
            Internal: "",
            External: "CostExtOpsTextAnalytics",
            Label: "Text Analytics",
            isBool: true,
        },

        OverrideHostingCost: {
            //rfq
            Internal: "",
            External: "CostExtOpsHosting",
            Label: "Online Hosting",
        },
    });

    const getDecisiveFieldValue = (orf) => {
        if (overRideFields[orf].decisiveField && !overRideFields[orf].isBool) {
            return currentWaveSpec.OpsResourcesData
                ? currentWaveSpec.OpsResourcesData[overRideFields[orf].decisiveField]
                : null;
        } else if (
            overRideFields[orf].decisiveField &&
            overRideFields[orf].isBool
        ) {
            if (
                currentWaveSpec.OpsResourcesData &&
                currentWaveSpec.OpsResourcesData[overRideFields[orf].decisiveField]
            ) {
                if (overRideFields[orf].Internal) return "Internal";
                else return "External";
            } else return null;
        } else {
            if (overRideFields[orf].Internal) return "Internal";
            else return "External";
        }
    };
    const getCurrentCurrency = (actualvalue) => {
        if (
            currencies &&
            currentCurrency &&
            currentCurrency.ConversionRateToLocal
        ) {
            if (actualvalue) {
                let finalVal = _.round(
                    actualvalue * currentCurrency.ConversionRateToLocal,
                    2
                );
                return `${finalVal} ${currentCurrency.CurrencyUnit}`;
            } else return `0 ${currentCurrency.CurrencyUnit}`;
        }
    };
    const getCurrentCurrencyValue = (actualvalue) => {
        if (
            currencies &&
            currentCurrency &&
            currentCurrency.ConversionRateToLocal
        ) {
            if (actualvalue) {
                let finalVal = _.round(
                    actualvalue * currentCurrency.ConversionRateToLocal,
                    2
                );
                return finalVal;
            } else return 0;
        }
    };
    const renderSelector = () => {
        if (!waveSpecs || (waveSpecs && waveSpecs.length === 1)) return null;
        return (
            <Col lg="2" md="2" sm="12" xs="12">
                <Selector
                    heading={"Waves"}
                    records={waveBreakDown}
                    clicked={selectorHandler}
                    // interPolField={["WaveNumber", "WaveName"]}
                    selected={currentWaveSpec?.WaveNumber}
                />
            </Col>
        );
    };
    const selectorHandler = (item) => {
        let _waveBreakdownMain = _.cloneDeep(wavesData.filter(x => x.MethodologySpecId === activeTabSM))
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
        Object.keys(_waveBreakdown).map(w => {
            _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
        })
        if (item.id === currentWaveSpec.id) return;
        else {
            let data = _.head(_waveBreakdown[item])
            setCurrentWaveSpec(data);
        }
    };
    const onMethodologyChange = (methID) => {
        let _waveBreakdownMain = wavesData?.filter(x => x.MethodologySpecId === methID)
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
        setWaveBreakDown(_waveBreakdown);
        let data = _.head(wavesData.filter(cws => cws.MethodologySpecId == methID))
        setCurrentWaveSpec(data);
    }
    const getFinalProperties = (properties) => {
        let finalProperties = [];
        if (currentCostingProfile.IsMultiCountry) {
            // if (countrySpecs.length > 1) {
            Object.keys(properties).map((prop) => {
                if (properties[prop].isMultiCountry == true || properties[prop].isMultiCountry == undefined) {
                    finalProperties.push(prop);
                }
            });
        } else {
            Object.keys(properties).map((prop) => {
                if (properties[prop].isMultiCountry == false || properties[prop].isMultiCountry == undefined) {
                    finalProperties.push(prop);
                }
            });
        }

        return finalProperties;
    };

    const toggleFC = (tab) => {
        if (activeTabFC !== tab) {
            setActiveTabFC(tab);
            let cData = profile.CountrySpecs.filter(x => x.id === tab);
            let methData = _.head(cData);
            let mData = methData.MethodologySpecs?.filter(ms => !ms.NotApplicable);
            let methodologyID = _.head(mData).id
            setActiveTabSM(methodologyID)
            onMethodologyChange(methodologyID)
        }
    };

    const toggleSM = (tab) => {
        if (activeTabSM !== tab) {
            setActiveTabSM(tab);
            onMethodologyChange(tab)
        }
    };

    const AllOtherCosts = () => {
        return (
            <>
                <Row>
                    <Col md={12} className={"methodologyTabs"}>
                        <h4 className="mb-4 justify-content-start">All Other Costs</h4>
                        <div className="cost-breakdown-in-summary">
                            <Nav tabs>
                                {Object.keys(methCountryCostSet).map((meth, index) => <NavItem>
                                    <NavLink
                                        className={index == activeCostTab ? "active" : ""}
                                        onClick={() => setActiveCostTab(index)}
                                    >
                                        {methCountrySet[meth].methlabel}
                                    </NavLink>
                                </NavItem>)}
                            </Nav>
                            <TabContent activeTab={activeCostTab}>
                                {Object.keys(methCountryCostSet).map((meth, index) =>
                                    <TabPane tabId={index}>
                                        <Table responsive hover striped bordered={true} size="sm">
                                            <thead>
                                                <th>Component</th>
                                                {Object.keys(methCountryCostSet[meth])
                                                    .map((cs, index) => <th>{Object.keys(methCountryCostSet[meth])[index]
                                                    }</th>)}
                                            </thead>
                                            <tbody>

                                                {Object.keys(methCostFieldsSet[meth]).map(field => {
                                                    return methCostFieldsSet[meth][field].title ? <tr>
                                                        <td>{methCostFieldsSet[meth][field].title}</td>
                                                        {Object.keys(methCountryCostSet[meth])
                                                            .map(cs => <td>{methCountryCostSet[meth][cs]
                                                                ? getCurrentCurrency(methCountryCostSet[meth][cs][field]) : "-"}</td>)}

                                                    </tr> : null
                                                })}

                                            </tbody>
                                        </Table>

                                    </TabPane>
                                )}


                            </TabContent>

                        </div>
                    </Col>
                </Row>
            </>
        )
    }

    return (

        <Row>
            <Col md={12} className={'countryTabs tab-vertical'}>

                <div className={"tab mt-5"}>
                    {(_.includes(pacificMarkets, project.CommissioningCountry)   && project.CostingTemplateVersion != "v2.0")
                        ?
                        <>
                            <Nav tabs>
                                {profile.CountrySpecs &&
                                    profile.CountrySpecs.map((fc) => (
                                        <NavItem key={fc.value}>
                                            <NavLink className={classnames({ active: activeTabFC === fc.id })}
                                                onClick={() => {
                                                    toggleFC(fc.id);
                                                }}
                                            >
                                                <b>{fc.CountryName}</b>
                                            </NavLink>
                                        </NavItem>
                                    ))}
                            </Nav>
                            <TabContent activeTab={activeTabFC}>
                                {profile.CountrySpecs &&
                                    profile.CountrySpecs.map((fc, indxFC) => (
                                        activeTabFC === fc.id &&
                                        <TabPane tabId={fc.id} key={fc.value}>
                                            <Row>
                                                <Col md={12} className={"methodologyTabs"}>
                                                    <Nav tabs>
                                                        {fc.MethodologySpecs &&
                                                            fc.MethodologySpecs.filter(x => !x.NotApplicable).map((sm, indxSM) =>
                                                            (
                                                                <NavItem key={sm.value}>
                                                                    <NavLink className={classnames({ active: activeTabSM === sm.id })}
                                                                        onClick={() => {
                                                                            toggleSM(sm.id);
                                                                        }}
                                                                    >
                                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                            <div>
                                                                                <b>{sm.Label}</b>
                                                                            </div>

                                                                        </div>
                                                                    </NavLink>
                                                                </NavItem>
                                                            ))}
                                                    </Nav>

                                                    <TabContent activeTab={activeTabSM} style={{ padding: "0.25" }}>
                                                        {Object.keys(waveBreakDown).length
                                                            ?
                                                            //    <OverrideCostsNew 
                                                            //    // currentWaveSpec={currentWaveSpec} 
                                                            //    waveBreakDown={waveBreakDown}
                                                            //    wavesDataData={wavesData}
                                                            //    />
                                                            <Row>
                                                                <Container fluid className="cost-breakdown-modal-container">
                                                                    <Card className="p-4">
                                                                        <h4 className="mb-4 justify-content-start">Auto-generated Costs</h4>
                                                                        <Row>
                                                                            {renderSelector()}
                                                                            <Col>
                                                                                <Table className="override-main-table">
                                                                                    <thead>
                                                                                        <th>Costing Component</th>
                                                                                        <th>Amount</th>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {Object.keys(overRideFields).map((orf) => {
                                                                                            return getDecisiveFieldValue(orf) ? (
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        {overRideFields[orf].Label}
                                                                                                        {currentWaveSpec.OpsResourcesData ? (
                                                                                                            <span className="decisive-pop-value">
                                                                                                                {getDecisiveFieldValue(orf)}
                                                                                                            </span>
                                                                                                        ) : null}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span>
                                                                                                            {getCurrentCurrency(
                                                                                                                currentWaveSpec[
                                                                                                                overRideFields[orf][
                                                                                                                getDecisiveFieldValue(orf)
                                                                                                                ]
                                                                                                                ]
                                                                                                            )}
                                                                                                        </span>

                                                                                                    </td>
                                                                                                </tr>
                                                                                            ) : null;
                                                                                        })}
                                                                                    </tbody>
                                                                                </Table>
                                                                            </Col>
                                                                        </Row>

                                                                    </Card>
                                                                </Container>
                                                            </Row>

                                                            :
                                                            <h5>No Waves for this particular methodology!!!</h5>
                                                        }


                                                    </TabContent>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    ))}
                            </TabContent>
                            {AllOtherCosts()}
                        </>
                        : <> {AllOtherCosts()}</>
                    }

                </div>
            </Col>
        </Row>


    );
};
export default CostBreakDown;
