import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getMultiOptions } from "../../utils/codeLabels";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faCopy, faPlus, faPlusCircle, faTimesCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import Select from "react-select";
import update from 'immutability-helper';
import './common.css';
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";

import {
    AvForm,

    AvField,
    AvRadioGroup,
    AvInput,
    AvRadio
} from "availity-reactstrap-validation";
import {
    Col,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    FormFeedback,
    FormGroup,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledTooltip,
    UncontrolledCollapse,
    Table,
    CustomInput,
    Button,
    Container,
    Form,
    FormText,
    Input,
    InputGroup,
    InputGroupAddon,
    Label
} from "reactstrap";
import { HashLink } from 'react-router-hash-link';
import Settings from "../../components/Settings";
import { setDate } from "date-fns/fp";
import { element, func } from "prop-types";
import { updateProfile } from "../../redux/actions/currentCostingActions";
import Selector from "../../components/Selector/Selector_Wavelevel";

const Start = ({ profile, indxFC, methodology, indxSM, updateProject,disableFields }) => {
    const dispatch = useDispatch()
    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );
    const [waveBreakDown, setWaveBreakDown] = useState({})
    const [currentWaveSpec, setcurrentWaveSpec] = useState([])
    const [modifiedMeths, setModifiedMeths] = useState([])
    const [wavesSpecsData, setwavesSpecsData] = useState(profile.WaveSpecs);
    const [activeTabFC, setActiveTabFC] = useState();
    const [activeTabSM, setActiveTabSM] = useState();
    const [selectedItemWave, setSelectedItemWave] = useState("1");

    useEffect(() => {
        if (profile &&
            wavesSpecsData
            && wavesSpecsData.length
            && !Object.keys(waveBreakDown).length) {

            let rData = (profile.CountrySpecs[indxFC]);
            let checData = (rData?.MethodologySpecs[indxSM]);
            setActiveTabFC(rData.id);
            setActiveTabSM(checData.id);
            let _waveBreakdownMain = profile.WaveSpecs.filter(x => x.MethodologySpecId === checData.id);
            let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber);
            setModifiedMeths(_waveBreakdownMain);
            Object.keys(_waveBreakdown).map(w => {
                _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel);
            });
            setwavesSpecsData(profile.WaveSpecs);
            setWaveBreakDown(_waveBreakdown);
            let _currentwaveset = _.head(_waveBreakdown[selectedItemWave]);
            setcurrentWaveSpec(_currentwaveset);
        }
    }, [profile])


    const selectorHandler = (item) => {
        setSelectedItemWave(item.WaveNumber);
        let _waveBreakdownMain = profile.WaveSpecs.filter(x => x.MethodologySpecId === activeTabSM)
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
        setModifiedMeths((_waveBreakdownMain))
        Object.keys(_waveBreakdown).map(w => {
            _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
        })
        // do nothing if clicked item is current item
        if (item === (currentWaveSpec)?.MethodologySpecId) return;
        else {
            let data = _.head(_waveBreakdown[item.WaveNumber])
            setcurrentWaveSpec(data);
        }
    };
    const applyToAllWaves = () => {

        modifiedMeths.map(x => x.ResponsibilitySchema = _.cloneDeep(currentWaveSpec.ResponsibilitySchema))
        setModifiedMeths(modifiedMeths);

        let data = wavesSpecsData.map(x => {
            const item = modifiedMeths.find(({ id }) => id === x.id);
            return item ? item : x;
          });
          setwavesSpecsData(data);
        const profileTemp = update(profile, {

            WaveSpecs: { $set: data }
        });

        const tempProfiles = project.CostingProfiles.map(cp => {
            if (cp.id == profileTemp.id)
                return { ...profileTemp }
            return { ...cp }
        });
        dispatch(updateProject({ CostingProfiles: tempProfiles }));
        // change by Ravi Punnam
        //updating Profile when any change happens
        dispatch(updateProfile(profileTemp))
        // changes
        toastr.success("Success", "Applied to all the Waves");

    }
    const renderSelector = () => {
        if (!wavesSpecsData || (wavesSpecsData && wavesSpecsData.length === 1) ) return null;
        return (
            <Col lg="2" md="2" sm="12" xs="12">
               {disableFields? <Selector
                    heading={"Waves"}
                    records={modifiedMeths}
                    // applyAll={applyToAllWaves}
                    // applyAllText={"Apply to All Waves"}
                    clicked={selectorHandler}
                    interPolField={["WaveNumber", "WaveName"]}
                    selected={currentWaveSpec}
                />: <Selector
                    heading={"Waves"}
                    records={modifiedMeths}
                    applyAll={applyToAllWaves}
                    applyAllText={"Apply to All Waves"}
                    clicked={selectorHandler}
                    interPolField={["WaveNumber", "WaveName"]}
                    selected={currentWaveSpec}
                />}
            </Col>
        );
    };
    function InfoChange(e, index, att) {
        const copyJsonData = _.cloneDeep(currentWaveSpec.ResponsibilitySchema);
        let key = Object.keys(copyJsonData)[index];

        copyJsonData[key]["enum"].forEach(x => {
            if (e.target.name != "otherSpecifyText") {
                if (e.target.name != x.label)
                    x.selected = false;
                else
                    x.selected = e.target.checked;
            }
        });

        let aa = Object.keys(currentWaveSpec.ResponsibilitySchema).length > 20 ? 23 : 13;
        if (index >= aa) {

            if (e.target.name != "otherSpecifyText") {
                if (e.target.name != "Not Applicable" && !att.selected && key.indexOf("otherResponsibilities") >= 0)
                    copyJsonData[key].showText = true;
                else {

                    copyJsonData[key].showText = false;
                }
            }
            else {
                copyJsonData[key].value = e.target.value;
            }

        }
        currentWaveSpec.ResponsibilitySchema = _.cloneDeep(copyJsonData);
        var foundIndex = wavesSpecsData.findIndex(x => x.id == currentWaveSpec.id);
        wavesSpecsData[foundIndex] = currentWaveSpec;
        setwavesSpecsData(wavesSpecsData);

        const profileTemp = update(profile, {

            WaveSpecs: { $set: wavesSpecsData }
        });
        const tempProfiles = project.CostingProfiles.map(cp => {
            if (cp.id == profileTemp.id)
                return { ..._.cloneDeep(profileTemp) }
            return { ..._.cloneDeep(cp) }
        });
        dispatch(updateProfile(_.cloneDeep(profileTemp)))
        dispatch(updateProject({ CostingProfiles: tempProfiles }));
    }

    return (
        <React.Fragment>
            {profile.WaveSpecs.length
                ?
                <Container fluid>
                    <Row>
                        {renderSelector()}
                        <Col>
                            <AvForm encType="multipart/form-data">
                                {currentWaveSpec?.ResponsibilitySchema ? <Card className="ml-2 mr-2">
                                    <CardHeader>
                                        <Row>
                                            <Col style={{ display: "flex", justifyContent: "space-between" }}>
                                                <CardTitle id="header_responsibility" className="mb-0">
                                                    Responsibilities
                                                </CardTitle>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md={12}>
                                                <Table size="sm" bordered style={{ backgroundColor: "white", width: "100%" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                            </th>
                                                            <th>
                                                                <span> Commissioning Country</span>
                                                            </th>
                                                            <th>
                                                                <span> Fielding Markets</span>
                                                            </th>
                                                            <th>
                                                                <span> Not Applicable</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.keys(currentWaveSpec.ResponsibilitySchema).map((tr, indexTR) =>
                                                            <tr key={'tr' + indexTR}>
                                                                <td>
                                                                    <span>{currentWaveSpec.ResponsibilitySchema[tr].title}</span>
                                                                    {currentWaveSpec.ResponsibilitySchema[tr].showText &&
                                                                        <input
                                                                            placeholder=""
                                                                            type="text"
                                                                            name="otherSpecifyText"
                                                                            style={{ width: '100%', marginTop: '0.2rem' }}
                                                                            value={currentWaveSpec.ResponsibilitySchema[tr].value || ''}
                                                                            disabled={methodology.NotApplicable||disableFields}
                                                                            onChange={(e) => InfoChange(e, indexTR)}
                                                                        />
                                                                    }
                                                                </td>
                                                                {currentWaveSpec.ResponsibilitySchema[tr]["enum"].map((att, indexATT) =>
                                                                    <td key={'tr' + indexTR + "_att_" + indexATT} style={{ textAlign: 'center' }}>
                                                                        {att.selected}
                                                                        <input name={att.label} checked={att.selected} type="checkbox" disabled={methodology.NotApplicable||disableFields} onChange={(e) => {
                                                                            InfoChange(e, indexTR, att);
                                                                        }}></input>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card> : null}
                            </AvForm>


                        </Col>
                    </Row>
                </Container>
                : 
                <Card className="ml-2 mr-2 mb-0 p-0">
                <CardHeader>
                    <Row>
                        <Col style={{ display: "flex", justifyContent: "space-between" }}>
                            <CardTitle id="header_op_r" className="mb-0">
                                Responsibilities
                            </CardTitle>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    Please Save the RFQ Form to input Responsibilities
                </CardBody>
            </Card>
            }




        </React.Fragment>
    );
};
export default Start;
