import { toastr } from "react-redux-toastr";
import axios from "../axios-interceptor";
import { connect, useDispatch, useSelector } from "react-redux";
import {
    pageLoadEnd,
    pageLoadStart,
    localPageLoadEnd, localPageLoadStart,
    recordLoadEnd,
    recordLoadStart
  } from "../redux/actions/appActions";

export function msSheetAPiCall(paramID, blank = false, isCostingSheet = false, callback) {
    return (dispatch, getState) => {
    if (paramID) {
        dispatch(localPageLoadStart())
        axios.get(`/utils/one-drive/${isCostingSheet ? 'open-costing-sheet' : 'fetch-item-details'}/${paramID}`).then(res => {
            dispatch(localPageLoadEnd())
            window.open(
                res?.data?.webUrl, "_blank"
            )
            if (callback)
                callback()
        }).catch(err => {
            dispatch(localPageLoadEnd())
            if (callback)
                callback()
            toastr.error("Couldn't open the requested resource", err?.data?.message)
        });

    }
    }

}