import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import _, { method, values } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import * as appActions from "../../redux/actions/appActions";
import {
  dashboardmailRequest,
  mailRequest,
} from "../../redux/actions/requestsActions";
import Spinner from "../../components/Spinner";
import axios from "../../axios-interceptor";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Input,
  Row,
  Col,
  FormFeedback,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import * as sheetCall from "../../utils/msSheetAPiCall";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/img/avatars/modal-logo.png";

import { getLabel, stringToMulti } from "../../utils/codeLabels";

let requestTypeOptions = [
  {
    Code: "1",
    label: "Online (Feasibility Check)",
    value: "Online (Feasibility Check)",
    DefaultRecipients: "aashish.negi@nielsen.com,dominic.yang@nielsen.com",
  },
  {
    Code: "2",
    label: "Online (Booster Sample)",
    value: "Online (Booster Sample)",
    DefaultRecipients: "aashish.negi@nielsen.com,dominic.yang@nielsen.com",
  },
  {
    Code: "3",
    label: "Offline (Qualitative)",
    value: "Offline (Qualitative)",
    DefaultRecipients: "dominic.yang@nielsen.com",
  },
  {
    Code: "4",
    label: "Offline (CATI)",
    value: "Offline (CATI)",
    DefaultRecipients: "aashish.negi@nielsen.com",
  },
  {
    Code: "5",
    label: "Offline (Other)",
    value: "Offline (Other)",
    DefaultRecipients: "aashish.negi@nielsen.com,dominic.yang@nielsen.com",
  },
  {
    Code: "6",
    label: "Project Management",
    value: "Project Management",
    DefaultRecipients: "aashish.negi@nielsen.com,dominic.yang@nielsen.com",
  },
];

let dashboardOptions = [];
let costingOptions = [];
let shoppersmes = [];
let shopperdata = [];
let smartEStoresmes = [];
let consumptionmomentssme = [];
let smartStorevrsmes = [];
// let waveSpecs = [];
const RequestCreate = ({ isOpen, toggle }) => {
  // const [waveSpecs, setOptionsData] = useState([]);
  const dispatch = useDispatch();
  const [request, setRequest] = useState({});
  const [validation, setValidation] = useState({});
  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const user = useSelector(({ user }) => user.userRecord);
  const loading = useSelector(({ app }) => app.recordloading);
  const markets = useSelector(({ marketdefaults }) => marketdefaults.items);
  const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
  const [dashboarding, setDashboarding] = useState(true);
  const [isChecked, setIsChecked] = useState(true);
  let countriesList = [];
  for (var i = 0; i < waveSpecs.length; i++) {
    if (
      !countriesList.includes(
        waveSpecs[i].MethodologySpec.CountrySpec.CountryCode
      )
    )
      countriesList.push(waveSpecs[i].MethodologySpec.CountrySpec.CountryCode);
  }

  let check1 = [];
  let list = [];
  let check2 = true;
  let allEmails = dashboardOptions.map((i) => i.contactEmails);
  let mails = allEmails.join("");

  for (var i = 0; i <= waveSpecs.length - 1; i++) {
    if (waveSpecs[i]?.OpsResourcesData) {
      list.push(waveSpecs[i]?.OpsResourcesData.dashboarding);
      let res = {
        contactEmails: mails,
        Methodology: profile?.Methodology,
      };
    }
  }
  useEffect(() => {
    dispatch(appActions.recordLoadStart());
    if (profile.Project && profile.Project.CommissioningCountry) {
      axios
        .get(
          "/marketsettings/" +
            profile.Project.CommissioningCountry +
            "/requesttypes/all"
        )
        .then((res) => {
          requestTypeOptions = res.data.RequestTypes[0].RequestTypes.map(
            (type) => {
              return {
                ...type,
                value: type.RequestTypeName,
                label: type.RequestTypeName,
              };
            }
          );
          dispatch(appActions.recordLoadEnd());
        })
        .catch((err) => {
          dispatch(appActions.recordLoadEnd());
          toastr.error("Error loading request types");
        });
    }
  }, []);

  useEffect(() => {
    dispatch(appActions.recordLoadStart());
    if (profile.Project && profile.Project.CommissioningCountry) {
      axios
        .get(
          "/marketsettings/" +
            profile.Project.CommissioningCountry +
            "/dashboardsme/all"
        )
        .then((res) => {
          dashboardOptions = res.data.DashBoardSme[0].DashBoardSmes.map(
            (type) => {
              return {
                ...type,
                value: type.contactEmails,
                label: type.contactEmails,
              };
            }
          );
          smartStorevrsmes = res.data.DashBoardSme[0].SmartStoreVRSmes.map(
            (type) => {
              return {
                ...type,
                value: type.contactEmails,
                label: type.contactEmails,
              };
            }
          );
          smartEStoresmes = res.data.DashBoardSme[0].SmartEstoreSmes.map(
            (type) => {
              return {
                ...type,
                value: type.contactEmails,
                label: type.contactEmails,
              };
            }
          );
          consumptionmomentssme =
            res.data.DashBoardSme[0].ConsumptionMomentSmes.map((type) => {
              return {
                ...type,
                value: type.contactEmails,
                label: type.contactEmails,
              };
            });
          shopperdata.push(consumptionmomentssme);
          shopperdata.push(smartEStoresmes);
          shopperdata.push(smartStorevrsmes);
          dispatch(appActions.recordLoadEnd());
        })
        .catch((err) => {
          dispatch(appActions.recordLoadEnd());
          toastr.error("Error loading request types");
        });
    }
  }, []);

  const resetForm = () => {
    setRequest({});
    setValidation({});
  };

  useEffect(() => {}, [request]);
  const onChangeHandlers = (field, value) => {
    setRequest({ ...request, [field]: value });
    let shopperdatas = request;
    shopperdatas["Methodology"] = value;
    let Method = ["SM000038", "SM000039", "SM000026"];
    let normalMethod =
      shopperdatas.Methodology != null &&
      shopperdatas.Methodology.length > 0 &&
      shopperdatas.Methodology.map((item) => {
        return item.value;
      });
    if (normalMethod != false) {
      let methdifferent = normalMethod.every((item) => Method.includes(item));
      let normaldiff = normalMethod.every((item) => !Method.includes(item));
      let hasCommon = normalMethod.some((item) => Method.includes(item));
      if (normaldiff == true) {
        let result1 = requestTypeOptions.filter(
          (item) => item.RequestTypeName == request.RequestType
        );
        if (result1.length > 0) {
          setRequest({
            ...request,
            AgentEmail: result1[0].PrimaryContactEmails,
          });
        }
      }
      if (hasCommon == true) {
        let result1 = requestTypeOptions.filter(
          (item) => item.RequestTypeName == request.RequestType
        );
        let selectedEmails = [];
        normalMethod.forEach((item) => {
          if (item == "SM000026" && smartStorevrsmes.length > 0) {
            selectedEmails.push(smartStorevrsmes[0].contactEmails);
          }
          if (item == "SM000038" && consumptionmomentssme.length > 0) {
            selectedEmails.push(consumptionmomentssme[0].contactEmails);
          }
          if (item == "SM000039" && smartEStoresmes.length > 0) {
            selectedEmails.push(smartEStoresmes[0].contactEmails);
          }
        });
        selectedEmails = selectedEmails.map((item) => {
          return item.toLowerCase();
        });
        selectedEmails = selectedEmails.map((item) => {
          if (item.includes(",")) {
            return item.split(",");
          } else {
            return item;
          }
        });
        selectedEmails = [].concat.apply([], selectedEmails);
        selectedEmails = _.uniq(selectedEmails);
        if (result1.length > 0) {
          result1[0].PrimaryContactEmails =
            result1[0].PrimaryContactEmails.toLowerCase();
          selectedEmails = new Set(
            result1[0].PrimaryContactEmails.split(",").concat(selectedEmails)
          );
        }
        selectedEmails = Array.from(selectedEmails).join(",");
        setRequest({
          ...request,
          AgentEmail: selectedEmails,
        });
      }
      if (hasCommon == true && methdifferent == true && normaldiff == false) {
        let result1 = requestTypeOptions.filter(
          (item) => item.RequestTypeName == request.RequestType
        );
        let selectedEmails = [];
        normalMethod.forEach((item) => {
          if (item == "SM000026" && smartStorevrsmes.length > 0) {
            selectedEmails.push(smartStorevrsmes[0].contactEmails);
          }
          if (item == "SM000038" && consumptionmomentssme.length > 0) {
            selectedEmails.push(consumptionmomentssme[0].contactEmails);
          }
          if (item == "SM000039" && smartEStoresmes.length > 0) {
            selectedEmails.push(smartEStoresmes[0].contactEmails);
          }
        });
        selectedEmails = selectedEmails.map((item) => {
          return item.toLowerCase();
        });
        selectedEmails = selectedEmails.map((item) => {
          if (item.includes(",")) {
            return item.split(",");
          } else {
            return item;
          }
        });
        selectedEmails = [].concat.apply([], selectedEmails);
        selectedEmails = _.uniq(selectedEmails);
        if (result1.length > 0) {
          result1[0].PrimaryContactEmails =
            result1[0].PrimaryContactEmails.toLowerCase();
          selectedEmails = new Set(
            result1[0].PrimaryContactEmails.split(",").concat(selectedEmails)
          );
        }

        selectedEmails = Array.from(selectedEmails).join(",");
        setRequest({
          ...request,
          AgentEmail: selectedEmails,
        });
      }
    }
    if (normalMethod == false) {
      let result1 = requestTypeOptions.filter(
        (item) => item.RequestTypeName == request.RequestType
      );
      setRequest({ ...request, AgentEmail: result1[0].PrimaryContactEmails });
    }
  };
  const onChangeHandler = (field, value) => {
    setRequest({ ...request, [field]: value });
  };

  const invalidate = () => {
    let invalid = false;
    let newObj = {};
    if (!request.RequestType || request.RequestType === "") {
      newObj.RequestType = true;
      invalid = true;
    }
    if (!request.Methodology || request.Methodology === "") {
      newObj.Methodology = true;
      invalid = true;
    }
    if (!request.DateDue || request.DateDue === "") {
      invalid = true;
      newObj.DateDue = true;
    }
    if (!request.InitialNotes || request.InitialNotes === "") {
      invalid = true;
      newObj.InitialNotes = true;
    }
    setValidation(newObj);
    return invalid;
  };
  const updateDashboard = (eve) => {
    // setIsChecked={!isChecked}
    setDashboarding(eve.target.checked);
  };
  let dashboardoptions = {};
  let dashboardoptions1 = {};
  let filteredfinaldashboardoptions = [];
  let unique = new Set();
  let uc = new Set();
  let um = new Set();
  const createRequest = () => {
    console.log("Wavespecs", waveSpecs);
    // setDashboarding(true);
    let data;
    let finaldashboardoptions = [];
    if (dashboarding == true && list.includes("Yes")) {
      for (var i = 0; i <= waveSpecs.length - 1; i++) {
        if (countriesList.length > 1) {
          dashboardoptions = {
            contactEmails: mails,

            Methodology: {
              value: waveSpecs[i]?.MethodologySpec?.ParentMethodologyCode,
              label: waveSpecs[i]?.MethodologySpec?.Label,
            },
            FieldingCountry:
              waveSpecs[i]?.MethodologySpec?.CountrySpec.CountryName,
            countryCode: waveSpecs[i]?.MethodologySpec?.CountrySpec.CountryCode,
            dashboardingComplexity:
              waveSpecs[i]?.OpsResourcesData?.dashboardOption,
            MaintenanceFrequency:
              waveSpecs[i]?.OpsResourcesData?.MaintenanceFrequency,
            NumberOfLicenses: waveSpecs[i]?.OpsResourcesData?.NumberOfLicenses,
            isAllDataInOneFile:
              waveSpecs[i]?.OpsResourcesData?.isAllDataInOneFile,
            sampleSizeMain:
              profile?.CountrySpecs[i]?.MethodologySpecs[0]?.CostingOptions[0]
                ?.CostingOptionData?.sampleSizeMain,
            NumberOfWaves:
              waveSpecs[i]?.OpsResourcesData?.numberOfWaves > 1
                ? waveSpecs[i]?.OpsResourcesData?.numberOfWaves
                : "1",
          };
        } else {
          dashboardoptions1 = {
            contactEmails: mails,

            Methodology: {
              value: waveSpecs[i]?.MethodologySpec?.ParentMethodologyCode,
              label: waveSpecs[i]?.MethodologySpec?.Label,
            },
            FieldingCountry:
              waveSpecs[i]?.MethodologySpec?.CountrySpec.CountryName,
            countryCode: waveSpecs[i]?.MethodologySpec?.CountrySpec.CountryCode,
            dashboardingComplexity:
              waveSpecs[i]?.OpsResourcesData?.dashboardOption,
            MaintenanceFrequency:
              waveSpecs[i]?.OpsResourcesData?.MaintenanceFrequency,
            NumberOfLicenses: waveSpecs[i]?.OpsResourcesData?.NumberOfLicenses,
            isAllDataInOneFile:
              waveSpecs[i]?.OpsResourcesData?.isAllDataInOneFile,
            sampleSizeMain:
              profile?.CountrySpecs[0]?.MethodologySpecs[i]?.CostingOptions[0]
                ?.CostingOptionData?.sampleSizeMain,
            NumberOfWaves:
              waveSpecs[i]?.OpsResourcesData?.numberOfWaves > 1
                ? waveSpecs[i]?.OpsResourcesData?.numberOfWaves
                : "1",
          };
        }

        let filterfinaldashboardoptions = [];

        if (
          waveSpecs[i].OpsResourcesData.dashboarding === "Yes" &&
          countriesList.length > 1
        ) {
          finaldashboardoptions =
            finaldashboardoptions.concat(dashboardoptions);
        } else if (waveSpecs[i].OpsResourcesData.dashboarding === "Yes") {
          finaldashboardoptions =
            finaldashboardoptions.concat(dashboardoptions1);
        }
        filterfinaldashboardoptions = finaldashboardoptions.filter((el) => {
          let duplicate = uc.has(el.FieldingCountry);
          uc.add(el.FieldingCountry);
          if (duplicate) {
            duplicate = um.has(el.Methodology.label);
          }
          um.add(el.Methodology.label);
          return !duplicate;
        });
        filteredfinaldashboardoptions = filteredfinaldashboardoptions.concat(
          filterfinaldashboardoptions
        );

        if (waveSpecs.length > 1 && list.includes("Yes")) {
          data = {
            ...request,
            RequestorEmail: user.Email,
            CostingProfileId: profile.id,
            Dashboardsme: filteredfinaldashboardoptions,
          };
        } else {
          if (waveSpecs[i].OpsResourcesData.dashboarding === "Yes") {
            data = {
              ...request,
              RequestorEmail: user.Email,
              CostingProfileId: profile.id,
              Dashboardsme: [
                {
                  contactEmails: mails,

                  countryCode:
                    waveSpecs[i]?.MethodologySpec?.CountrySpec.CountryCode,
                  Methodology: {
                    value: waveSpecs[i]?.MethodologySpec?.ParentMethodologyCode,
                    label: waveSpecs[i]?.MethodologySpec?.Label,
                  },
                  FieldingCountry:
                    waveSpecs[i]?.MethodologySpec?.CountrySpec.CountryName,
                  dashboardingComplexity:
                    waveSpecs[i]?.OpsResourcesData?.dashboardOption,

                  MaintenanceFrequency:
                    waveSpecs[i]?.OpsResourcesData?.MaintenanceFrequency,
                  NumberOfLicenses:
                    waveSpecs[i]?.OpsResourcesData?.NumberOfLicenses,
                  isAllDataInOneFile:
                    waveSpecs[i]?.OpsResourcesData?.isAllDataInOneFile,
                  sampleSizeMain:
                    profile?.CountrySpecs[i]?.MethodologySpecs[i]
                      ?.CostingOptions[i]?.CostingOptionData?.sampleSizeMain,
                  NumberOfWaves:
                    waveSpecs[i]?.OpsResourcesData?.numberOfWaves > 1
                      ? waveSpecs[i]?.OpsResourcesData?.numberOfWaves
                      : "1",
                },
              ],
            };
          }
        }
      }
    } else {
      data = {
        ...request,
        RequestorEmail: user.Email,
        CostingProfileId: profile.id,
      };
      // setDashboarding(true);
    }
    setDashboarding(true);
    // setIsChecked(true);

    if (!invalidate()) {
      dispatch(appActions.recordLoadStart());
      axios
        .post("/requests", data)
        .then((res) => {
          let id = res.data.Request[0].id;
          if (!list.includes("Yes")) {
            dispatch(mailRequest(res.data.Request[0].id, "new"));
            dispatch(appActions.recordLoadEnd());
            toastr.success("Request Sent", res.data.message);
            resetForm();
            toggle();
          } else {
            if (dashboarding === true) {
              dispatch(mailRequest(res.data.Request[1].id, "new"));
              dispatch(dashboardmailRequest(res.data.Request[0].id, "new"));
              // setDashboarding(true);
              dispatch(appActions.recordLoadEnd());
              toastr.success("Request Sent", res.data.message);
              resetForm();
              toggle();
            } else {
              dispatch(mailRequest(res.data.Request[0].id, "new"));
              dispatch(appActions.recordLoadEnd());
              toastr.success("Request Sent", res.data.message);
              resetForm();
              toggle();
            }
          }
        })
        .catch((err) => {
          dispatch(appActions.recordLoadEnd());
          toastr.error("Send failed", err.data.message);
        });
    } else {
      //todo: handle exception
    }
  };

  // let allEmails = dashboardOptions.map((i) => i.contactEmails);
  // let mails = allEmails.join("");
  let uniqueemail;
  let resultemail;
  const requestTypeHandler = (e) => {
    shoppersmes = [];
    profile.CountrySpecs.forEach((cs) => {
      cs.MethodologySpecs.forEach((ms) => {
        if (
          ms.Code == "SM000026" &&
          ms.NotApplicable != true &&
          smartStorevrsmes.length > 0
        ) {
          shoppersmes.push(smartStorevrsmes[0]?.contactEmails);
        }
        if (
          ms.Code == "SM000039" &&
          ms.NotApplicable != true &&
          smartEStoresmes.length > 0
        ) {
          shoppersmes.push(smartEStoresmes[0]?.contactEmails);
        }
        if (
          ms.Code == "SM000038" &&
          ms.NotApplicable != true &&
          consumptionmomentssme.length > 0
        ) {
          shoppersmes.push(consumptionmomentssme[0]?.contactEmails);
        }
      });
    });
    if (shoppersmes.length > 0) {
      shoppersmes = shoppersmes.map((item) => {
        return item.toLowerCase();
      });
      shoppersmes = shoppersmes.map((item) => {
        if (item.includes(",")) {
          return item.split(",");
        } else {
          return item;
        }
      });
    }
    shoppersmes = [].concat.apply([], shoppersmes);
    shoppersmes = _.uniq(shoppersmes);
    e.PrimaryContactEmails = e.PrimaryContactEmails.toLowerCase();
    uniqueemail = new Set(
      e.PrimaryContactEmails.split(",").concat(shoppersmes)
    );
    resultemail = Array.from(uniqueemail).join(",");
    setRequest({
      ...request,
      RequestType: e.value,
      AgentEmail: shoppersmes.length > 0 ? resultemail : e.PrimaryContactEmails,
      CcAgentEmails: e.OtherContactEmails,
    });
  };
  let methData = [];
  const methodologyData = () => {
    profile.CountrySpecs.map((data) => {
      data.MethodologySpecs.map((ms) => {
        if (ms.NotApplicable !== true) {
          if (methData.length == 0) {
            methData.push({ label: ms.Label, value: ms.Code });
          } else {
            if (!methData.some((item) => item.value == ms.Code)) {
              methData.push({ label: ms.Label, value: ms.Code });
            }
          }
        }
      });
    });

    return methData;
  };
  var imgStyle = {
    width: "150px",
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered={true}
      fade={true}
      unmountOnClose={true}
      className="modal-lg"
    >
      <ModalHeader toggle={toggle}>
        {/* <img src={logo} className="modallogo" /> */}
      </ModalHeader>
      <div>
        <span className="modaltitle">Create New Request</span>
      </div>
      <ModalBody>
        {/* {list.includes("Yes") ? (
          <h4>
            <input
              type="checkbox"
              // id="dashboarding"
              name="dashboarding"
              onChange={updateDashboard}

              // checked
            ></input>
            &nbsp;&nbsp; Dashboard Required selected as Yes
          </h4>
        ) : null} */}
        <Row className="mb-1">
          <Col className="modalcontent f-14">Project Name</Col>
          <Col>{profile?.Project?.ProjectName}</Col>
        </Row>
        <Row className="mb-1">
          <Col className="modalcontent f-14">Costing Profile</Col>
          <Col>
            {"#"}
            {profile?.ProfileNumber} {profile?.ProfileName}
          </Col>
        </Row>
        <Row className="mb-1">
          <Col className="modalcontent f-14">Request Type(*)</Col>
          <Col>
            <FormGroup>
              <Select
                options={requestTypeOptions}
                value={
                  request.RequestType
                    ? { value: request.RequestType, label: request.RequestType }
                    : ""
                }
                onChange={(e) => {
                  requestTypeHandler(e);
                }}
                className={
                  validation.RequestType
                    ? "react-select-container is-invalid"
                    : "react-select-container"
                }
              />
              <FormFeedback>Please select a request type</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col className="modalcontent f-14">Default Recipient(s)</Col>
          <Col className="break-all">
            {request.AgentEmail ?? "Select a request type"}
          </Col>
        </Row>
        <Row className="mb-1">
          <Col className="modalcontent f-14">Associated Methodology(*)</Col>
          <Col>
            <FormGroup>
              <Select
                // options={stringToMulti(
                //   "MethodologyOptions",
                //   profile.Methodology
                // )}
                options={methodologyData()}
                // options={
                //   profile.SubMethodology &&
                //   typeof profile.SubMethodology == "string"
                //     ? stringToMulti(
                //         "SubMethodologyOptions",
                //         profile.SubMethodology
                //       )
                //     : profile.SubMethodology
                // }
                isMulti
                defaultValue
                value={request.Methodology ?? null}
                onChange={(e) => onChangeHandlers("Methodology", e)}
                // onChange={(e) => methodologyHandler(e)}
                className={
                  validation.Methodology
                    ? "react-select-container is-invalid"
                    : "react-select-container"
                }
              />

              <FormFeedback>Please select a methodology</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col className="modalcontent f-14">Response Needed By(*)</Col>
          <Col>
            <FormGroup>
              <Input
                type="date"
                invalid={validation.DateDue ?? false}
                value={request.DateDue ?? ""}
                onChange={(e) => onChangeHandler("DateDue", e.target.value)}
              />
              <FormFeedback>Please select a date</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col className="modalcontent f-14">
            Request Notes /Instructions(*)
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input
                type="textarea"
                invalid={validation.InitialNotes}
                placeholder="Please provide all the request specific details here..."
                value={request.InitialNotes ?? ""}
                onChange={(e) =>
                  onChangeHandler("InitialNotes", e.target.value)
                }
              />
              <FormFeedback>Please provide request details</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        {/* {JSON.stringify(waveSpecs[0]?.OpsResourcesData.dashboarding)} */}
        {list.includes("Yes") ? (
          <h4>
            <input
              type="checkbox"
              defaultChecked={dashboarding}
              name="dashboarding"
              // checked={dashboarding}
              onChange={(eve) => updateDashboard(eve)}
              // checked
            ></input>{" "}
            Please select to request cost for Dashboarding-related requirements
          </h4>
        ) : (
          <h4 style={{ color: "grey" }}>
            <input
              type="checkbox"
              // defaultChecked={dashboarding}
              name="dashboarding"
              // checked={dashboarding}
              // onChange={(eve)=>updateDashboard(eve)}
              // checked
              disabled
            ></input>{" "}
            Please select to request cost for Dashboarding-related requirements
          </h4>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end">
          <Button
            color="primary"
            className="mr-2"
            onClick={() => createRequest()}
            disabled={loading}
          >
            Send {loading ? <Spinner size="small" color="#fff" /> : null}
          </Button>
          <Button
            className="mr-2"
            color="secondary"
            //   onClick={() => toggle()}
          >
            <FontAwesomeIcon
              title="Upload Files to Costings Folder"
              icon={faPaperclip}
              fixedWidth
              onClick={() => {
                // window.open(
                //   "https://drive.google.com/drive/folders/" +
                //   profile.Project.CostingsFolderId
                // );
                dispatch(
                  sheetCall.msSheetAPiCall(
                    profile.Project.CostingsFolderId,
                    false
                  )
                );
              }}
            />
          </Button>
          <Button
            className="mr-2"
            color="secondary"
            onClick={() => {
              resetForm();
              toggle();
            }}
            disabled={loading}
          >
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default RequestCreate;
