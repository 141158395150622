import React from "react";

function Notes() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#000"
          d="M2 2v21h21V6.5L18.5 2H2zm1.5 1.5v18h18V8H17V3.5H3.5zm3 12h9V17h-9v-1.5zm0-3h12V14h-12v-1.5zm0-3h12V11h-12V9.5z"
        ></path>
      </g>
    </svg>
  );
}

export default Notes;