import Axios from "../../axios-interceptor";
import { recordLoadEnd, recordLoadStart } from "./appActions";

// export const SHOW_ANNOUNCEMENT = "SHOW_ANNOUNCEMENT";
// export const HIDE_ANNOUNCEMENT = "HIDE_ANNOUNCEMENT";
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";
export const SET_MESSAGE = "SET_MESSAGE";

export const setNewNotification = (hasNewNotification) => {
  return (dispatch) => {
    dispatch({
      type: NEW_NOTIFICATION,
      hasNewNotification: hasNewNotification,
    });
  };
};

export const setMessage = (message) => {
  return (dispatch) => {
    dispatch({
      type: SET_MESSAGE,
      message: message,
    });
  };
};

const validateDatePlusHours = (date, validity) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + validity);
  const now = new Date();
  return newDate > now;
};

export const getNewNotification = () => {
  return async (dispatch, getState) => {
    dispatch(recordLoadStart());
    await Axios.get("/Notifications/default")
      .then((res) => {
        // res.data.Data.Alerts.sort((a, b) => a.IsShownOrder - b.IsShownOrder);
        const finalMessage = res.data.Data.Alerts.filter(
          (alert) => alert.IsActivate
        ).length
          ? res.data.Data.Alerts.filter((alert) => alert.IsActivate)[0]
          : "";
        const isValidTime = validateDatePlusHours(
          finalMessage?.ActivationTime,
          finalMessage?.Validity
        );
        if (finalMessage && isValidTime) {
          dispatch(setMessage(finalMessage?.Details));
          dispatch(setNewNotification(true));
          // setIsVisible(true);
        }
        dispatch(recordLoadEnd());
      })
      .catch((error) => {
        console.error("Error occured while fetching the data!", error);
        dispatch(recordLoadEnd());
      });
  };
};