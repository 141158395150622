import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import axios from "../../../axios-interceptor";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useState, useEffect } from "react";
import _, { cloneDeep } from "lodash";
import update from "immutability-helper";
import WarningModal from "./components/modals/WarningModal";
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Table,
} from "reactstrap";
import { setProfileRequests } from "../../../redux/actions/requestsActions";
import { toastr } from "react-redux-toastr";
import vendorReducer from "../../../redux/reducers/vendorReducer";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import { SET_FILES } from "../../../redux/actions/vendor/ActionTypes";

function BiddingFiles(props) {
  var project = useSelector(({ currentProject }) => currentProject.newProject);
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [toDisplay, setToDisplay] = useState([]);
  const [alertBox, setAlertBox] = useState();
  const [deleteData, setDeleteData] = useState();
  const [messageBox, setMessageBox] = useState(false);
  const [render, setRender] = useState(false);
  const [ViewOnlyMode, setViewOnlyMode] = useState(false);

  const filetypestyle = {
    cursor: "pointer",
    fontSize: "11px",
  };
  const biddingFiles = useSelector(
    ({ vendorReducer }) => vendorReducer.biddingFiles
  );
  const updateProject = (newProject) =>
    dispatch({
      type: "UPDATE_NEW_PROJECT",
      newProject: newProject,
    });

  const vendor = useSelector(({ vendorReducer }) => vendorReducer.vendor);
  var filesToDisplay;
  useEffect(() => {
    setViewOnlyMode(((project?.ProjectStatus == 7)
      || (["2", "3", "4", "5", "6"].indexOf(_.last(project?.CostingProfiles).ProfileStatus) != -1)
    ) ? true : false)
    filesToDisplay = [];
    if (props.vendorfiles) {
      if (
        biddingFiles.find(
          (a) => a.methodologyId == props.MethId && a.vendorId == props.VendorID
        )?.fullservicerequired == props?.fullservicerequired &&
        biddingFiles.find(
          (a) => a.methodologyId == props.MethId && a.vendorId == props.VendorID
        )?.biddingtype == props?.vendor?.biddingType
      ) {
        setFiles(
          biddingFiles.find(
            (a) =>
              a.methodologyId == props.MethId && a.vendorId == props.VendorID
          )?.files
        );
        biddingFiles
          .find(
            (a) =>
              a.methodologyId == props.MethId && a.vendorId == props.VendorID
          )
          ?.files?.forEach((f) => {
            filesToDisplay.push({
              fileName: f.name,
              type: "new",
              originalName: f.name,
            });
          });
      } else if (
        biddingFiles.find(
          (a) => a.methodologyId == props.MethId && a.vendorId == props.VendorID
        )?.files
      ) {
        biddingFiles.find(
          (a) => a.methodologyId == props.MethId && a.vendorId == props.VendorID
        ).files = [];
        setFiles([]);
      }
      props.vendor?.UploadOtherRequiredDocuments?.forEach((a) =>
        filesToDisplay.push({
          fileName: a.fileName,
          type: "old",
          originalName: a.originalName,
        })
      );
      setToDisplay(filesToDisplay);
    } else {
      setFiles(
        biddingFiles.find(
          (a) => a.methodologyId == props.MethId && a.flag == "bidding"
        )?.files
      );
      biddingFiles
        .find((a) => a.methodologyId == props.MethId && a.flag == "bidding")
        ?.files?.forEach((f) => {
          filesToDisplay.push({
            fileName: f.name,
            type: "new",
            originalName: f.name,
          });
        });

      props.methodology?.BiddingDetail?.UploadOtherRequiredDocuments?.forEach(
        (a) => {
          filesToDisplay.push({
            fileName: a.fileName,
            type: "old",
            originalName: a.originalName,
          });
        }
      );
      setToDisplay(filesToDisplay);
    }
  }, [props.methodology, props.vendor]);
  function isChangedFile(change) {
    const tempcostdata = _.cloneDeep(_.last(project.CostingProfiles).CountrySpecs);

    tempcostdata.forEach((s, i) =>
      s.MethodologySpecs.forEach((m, im) => {
        if (m.CostingType == 3) {
          if (props.vendorfiles) {
            m.InvitedVendors?.filter(
              (a) => !a.IsDeleted && a.label != "TCS" && a.label != "Internal" && a.VendorId == props.VendorID
            )?.forEach((v, iv) => {
              if (v.IsMailSent) {
                v["IsFileChanged"] = change;
              }
            });
          }
          else {
            if (props.MethId == m.id) {
              m["IsFileChanged"] = change;
            }
          }
        }
      })
    );
    const newProposal = update(project.CostingProfiles[project.CostingProfiles.length - 1], {
      CountrySpecs: { $set: tempcostdata },
    });
    project.CostingProfiles[project.CostingProfiles.length - 1] = newProposal;
    updateProject(newProposal)
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
  }
  function deleteFile(e, type) {
    var datadel;
    var deletedfiles = biddingFiles;
    if (type == "new") {
      if (props.vendorfiles) {
        deletedfiles.filter(
          (a) => a.vendorId == props.VendorID && a.methodologyId == props.MethId
        )[0].files = deletedfiles
          .filter(
            (a) =>
              a.vendorId == props.VendorID && a.methodologyId == props.MethId
          )[0]
          .files.filter((a) => a.name != e);
        setFiles(
          deletedfiles
            .filter(
              (a) =>
                a.vendorId == props.VendorID && a.methodologyId == props.MethId
            )[0]
            .files.filter((a) => a.name != e)
        );
        setToDisplay(toDisplay.filter((a) => a.fileName != e));
      } else {
        deletedfiles.filter(
          (a) => a.methodologyId == props.MethId && a.flag == "bidding"
        )[0].files = deletedfiles
          .filter(
            (a) => a.methodologyId == props.MethId && a.flag == "bidding"
          )[0]
          .files.filter((a) => a.name != e);
        setFiles(
          deletedfiles
            .filter(
              (a) => a.methodologyId == props.MethId && a.flag == "bidding"
            )[0]
            .files.filter((a) => a.name != e)
        );
        setToDisplay(toDisplay.filter((a) => a.fileName != e));
      }
      isChangedFile(false)
      dispatch({ type: SET_FILES, files: deletedfiles });
    } else {
      isChangedFile(true)
      if (props.vendorfiles) {
        props.methodology.InvitedVendors.find(
          (a) => a.VendorId == props.VendorID
        ).UploadOtherRequiredDocuments = props.methodology.InvitedVendors.find(
          (a) => a.VendorId == props.VendorID
        ).UploadOtherRequiredDocuments.filter((a) => a.fileName != e);
        if (
          deletedfiles.filter(
            (a) =>
              a.vendorId == props.VendorID && a.methodologyId == props.MethId
          ).length > 0
        ) {
          deletedfiles
            .filter(
              (a) =>
                a.vendorId == props.VendorID && a.methodologyId == props.MethId
            )[0]
            .deletefile.push(e);
        } else {
          datadel = {
            vendorId: props.VendorID,
            methodologyId: props.MethId,
            flag: "vendor",
            files: [],
            deletefile: [e],
          };
          deletedfiles.push(datadel);
        }
        setToDisplay(toDisplay.filter((a) => a.fileName != e));
      } else {
        props.methodology.BiddingDetail.UploadOtherRequiredDocuments =
          props.methodology.BiddingDetail.UploadOtherRequiredDocuments.filter(
            (a) => a.fileName != e
          );
        if (
          deletedfiles.filter(
            (a) => a.methodologyId == props.MethId && a.flag == "bidding"
          ).length > 0
        ) {
          deletedfiles
            .filter(
              (a) => a.methodologyId == props.MethId && a.flag == "bidding"
            )[0]
            .deletefile.push(e);
        } else {
          datadel = {
            methodologyId: props.MethId,
            flag: "bidding",
            files: [],
            deletefile: [e],
          };
          deletedfiles.push(datadel);
        }
        // isChangedFile(true)
        setToDisplay(toDisplay.filter((a) => a.fileName != e));
        var newProposal = update(
          project.CostingProfiles[project.CostingProfiles.length - 1],
          {
            CountrySpecs: {
              [props.indexcountry]: {
                MethodologySpecs: {
                  [props.index]: { $set: props.methodology },
                },
              },
            },
          }
        );
        project.CostingProfiles[project.CostingProfiles.length - 1] =
          newProposal;

        dispatch({
          type: currentProjectActions.UPDATE_NEW_PROJECT,
          newProject: project,
        });
        dispatch({ type: SET_FILES, files: deletedfiles });
      }
    }
  }
  function Popup(name, type) {
    setAlertBox("Are you sure you want to delete the file ?");
    setMessageBox(true);
    setDeleteData({ name: name, type: type });
  }

  function FileToSend(e) {
    let bin = [];
    let send = [];
    var allowedExtensions =
      /(\.jpg|\.jpeg|\.png|\.pdf|\.xls|\.xlsx|\.doc|\.docx)$/i;
    Object.keys(e.target.files)?.forEach((a) => {
      if (!allowedExtensions.exec(e.target.files[a].name)) {
        bin.push(e.target.files[a].name);
      } else {
        send.push(e.target.files[a]);
      }
    });
    if (bin.length > 0) {
      toastr.error(
        "Please upload file having extensions .jpeg/.jpg/.png/.pdf/.xls/.xlsx/ .doc/.docx only"
      );
    }
    if (send.length > 0) {
      FileChange(send);
    }
    isChangedFile(true)
  }
  function FileChange(e) {
    let bin = [];
    let send = [];
    let final = [];
    let trash = [];
    e?.forEach((a) => {
      if (a.size > 4200000) {
        bin.push(a.name);
      } else {
        send.push(a);
      }
    });
    send.forEach((a) => {
      if (toDisplay.filter((f) => f.originalName == a.name).length > 0) {
        trash.push(a.name);
      } else {
        final.push(a);
      }
    });

    if (bin.length > 0) {
      toastr.error(`Please Select the ${bin.toString()} file less then 4 mb`);
    }
    if (trash.length > 0) {
      toastr.error(`Do not select the same file again ${trash.toString()}`);
    }
    var tosend = [];
    var toview = [];
    var data;
    var dataFiles = biddingFiles;
    if (toDisplay.length + final.length > 5) {
      toastr.error("You can Upload Max five files");
    } else {
      final.map((a) => {
        tosend.push(a);
        toview.push({ fileName: a.name, type: "new", originalName: a.name });
      });

      setFiles([...(files ? files : []), tosend].flat());
      setToDisplay([...(toDisplay ? toDisplay : []), toview].flat());

      if (props.vendorfiles) {
        if (
          dataFiles.filter(
            (a) =>
              a.vendorId == props.VendorID && a.methodologyId == props.MethId
          ).length > 0
        ) {
          dataFiles.filter(
            (a) =>
              a.vendorId == props.VendorID && a.methodologyId == props.MethId
          )[0].files = [...(files ? files : []), tosend].flat();
        } else {
          data = {
            vendorId: props.VendorID,
            methodologyId: props.MethId,
            flag: "vendor",
            files: [...(files ? files : []), tosend].flat(),
            deletefile: [],
            fullservicerequired: props.fullservicerequired,
            biddingtype: props?.vendor?.biddingType,
          };
          dataFiles.push(data);
        }
      } else {
        if (
          dataFiles.filter(
            (a) => a.methodologyId == props.MethId && a.flag == "bidding"
          ).length > 0
        ) {
          dataFiles.filter(
            (a) => a.methodologyId == props.MethId && a.flag == "bidding"
          )[0].files = [...(files ? files : []), tosend].flat();
        } else {
          data = {
            methodologyId: props.MethId,
            flag: "bidding",
            files: [...(files ? files : []), tosend].flat(),
            deletefile: [],
          };
          dataFiles.push(data);
        }
      }
    }

    dispatch({ type: SET_FILES, files: dataFiles });
  }
  async function onFileClick(e) {
    try {
      var res = await axios.get(`/files/download/vendor/${e.fileName}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/pdf",
        },
      });
      if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${e.originalName}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    } catch (err) {
      toastr.error("Loading Failed", err?.data?.message);
    }
  }
  function handleWarning(type, flag) {
    let warnFlag = type === "ok" ? true : false;
    setMessageBox(flag);
    if (warnFlag) {
      deleteFile(deleteData.name, deleteData.type);
    }
  }
  return (
    <>
      {JSON.stringify()}
      <FormGroup>
        <Label>
          Upload Other Required Documents{" "}
          <span style={filetypestyle}>
            (png, jpg, jpeg, xls, xlsx, doc ,docx and pdf)
          </span>
        </Label>

        <Table className="table table-stripped" size="sm" bordered md={3}>
          <tr>
            <td>
              <Input
                type="file"
                accept=" .png , .jpg , .pdf , .jpeg , .xls , .xlsx , .doc , .docx"
                multiple
                name="other_required_documents_fileName"
                value={[]}
                onChange={(e) => {
                  FileToSend(e);
                }}
                disabled={((project.CostingProfiles[project.CostingProfiles.length - 1].VenodrBidDeadLineStatus == "close")
                  || ViewOnlyMode) ? true : false}
                style={{ color: "transparent" }}
              />
            </td>
          </tr>
        </Table>
      </FormGroup>

      {toDisplay?.length > 0 && (
        <Table className="table table-stripped" size="sm" bordered>
          <tr>
            <th>S.No</th>
            <th>File Name</th>
            <th>Actions</th>
          </tr>
          {toDisplay?.map((f, fi) => (
            <tr>
              <td>
                <b>{fi + 1}</b>
              </td>
              <td md={7}>
                <b>{f.originalName}</b>
              </td>
              {(!ViewOnlyMode) && <td md={1}>
                {f.type != "new" && (
                  <FontAwesomeIcon
                    onClick={() => onFileClick(f)}
                    title="Download File"
                    className="align-middle mr-2 addButton"
                    icon={faDownload}
                    // icon={vendor[f.value].includes(".pdf") ? faFilePdf : (vendor[f.value].includes(".jpg") || vendor[f.value].includes(".jpeg") || vendor[f.value].includes(".png")) ? faImage : faDownload}
                    fixedWidth
                    style={{ cursor: "pointer", color: "#32cd32" }}
                  />
                )}
                {(project.CostingProfiles[project.CostingProfiles.length - 1].VenodrBidDeadLineStatus != "close") &&
                  <FontAwesomeIcon
                    onClick={() => Popup(f.fileName, f.type)}
                    title="Remove File"
                    className="align-middle mr-2 addButton ic-trash-hover"
                    icon={faTrash}
                    fixedWidth
                    color="black"
                    style={{ cursor: "pointer" }}
                  />}
              </td>}
              {ViewOnlyMode && <td md={1}>
                {f.type != "new" && (
                  <FontAwesomeIcon
                    title="Download File"
                    className="align-middle mr-2 addButton"
                    icon={faDownload}
                    // icon={vendor[f.value].includes(".pdf") ? faFilePdf : (vendor[f.value].includes(".jpg") || vendor[f.value].includes(".jpeg") || vendor[f.value].includes(".png")) ? faImage : faDownload}
                    fixedWidth
                    style={{ cursor: "pointer", color: "#85b985" }}
                  />
                )}
                <FontAwesomeIcon
                  title="Remove File"
                  className="align-middle mr-2 addButton ic-trash-hover"
                  icon={faTrash}
                  fixedWidth
                  color="black"
                  style={{ cursor: "pointer", color: "#919591" }}
                />
              </td>}

            </tr>
          ))}
        </Table>
      )}
      <WarningModal
        isOpen={messageBox}
        message={alertBox}
        popupHandler={(type, ev) => handleWarning(type, ev)}
      />
    </>
  );
}

export default BiddingFiles;