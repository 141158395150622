import React, { useState, useEffect } from "react";
import _ from "lodash";
import axios from "../../../axios-interceptor";
import Select from "react-select";
import { useDispatch } from "react-redux";
import update from "immutability-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import { UncontrolledCollapse } from "reactstrap";
import { useSelector } from "react-redux";
import * as masterActions from "../../../redux/actions/masters/Actions";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import VendorCostingAndTimeline from "./CostingAndTimelineVendor";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Table,
} from "reactstrap";
import "./css/common.css";
import { toastr } from "react-redux-toastr";
import { qcAll } from "./constants/detailConstants";

function VendorBiddingFormcopy(props) {
  const mystyle = {
    color: "blue",
    cursor: "pointer",
    fontFamily: "Arial",
  };
  const [ViewOnlyMode, setViewOnlyMode] = useState(false);

  //   const [isOpen,setIsopen]=useState(true)
  const dispatch = useDispatch();
  var project = useSelector(({ currentProject }) => currentProject.newProject);
  function addComments(val) {
    const tempcostdata = _.cloneDeep(props.Vendor);
    tempcostdata.Comments = val.target.value;
    updateByIndex(tempcostdata);
  }
  function updateByIndex(data) {
    let indexVendor = _.last(project.CostingProfiles).CountrySpecs[
      props.indexCountry
    ].MethodologySpecs[props.indexMethodology].InvitedVendors.findIndex(
      (v) => v.VendorId === props.VendorId
    );
    updateProject(
      project,
      props.indexCountry,
      props.indexMethodology,
      indexVendor,
      data
    );
  }
  var selectedrow = [
    {
      id: 504,
      name: "Project Set up",
      selected: true,
      group: "Survey Programing & Platform",
      type: "TimingSection",
      key: "TimingsProjectSetup",
      header: null,
    },
    {
      id: 505,
      name: "Questionnaire Set up",
      selected: true,
      group: "Survey Programing & Platform",
      type: "TimingSection",
      key: "TimingsQuestionnaireSetup",
      header: null,
    },
    {
      id: 50,
      name: "Survey Programing & Platform",
      selected: true,
      type: "Field Cost",
      key: "surveyProgramingPlatform",
      header: "Survey Programing & Platform",
    },
    {
      id: 51,
      name: "Programming/Scripting Costs (ConfirmIT, STG, Decipher) (External)",
      selected: true,
      group: "Survey Programing & Platform",
      type: "Field Cost",
      key: "ProgrammingCostsExternal",
      header: null,
    },
    {
      id: 52,
      name: "Cost per each completed samples (in Total cost)",
      selected: true,
      group: "Survey Programing & Platform",
      type: "Field Cost",
      key: "CostCompletedSample",
      header: null,
    },
    {
      id: 53,
      name: "Other Cost",
      selected: true,
      group: "Survey Programing & Platform",
      type: "Field Cost",
      key: "otherSUD1",
      header: null,
    },
  ];

  useEffect(() => {
    if ((project.CostingProfiles[project.CostingProfiles.length - 1].VenodrBidDeadLineStatus == "close")
      || (project?.ProjectStatus == 7)
      || (["2", "3", "4", "5", "6"].indexOf(_.last(project?.CostingProfiles).ProfileStatus) != -1)) {
      setViewOnlyMode(true);
    }
  }, []);

  function updateProject(
    project,
    indexCountry,
    indexMethodology,
    indexVendor,
    data
  ) {
    const newProposal = update(
      project.CostingProfiles[project.CostingProfiles.length - 1],
      {
        CountrySpecs: {
          [indexCountry]: {
            MethodologySpecs: {
              [indexMethodology]: {
                InvitedVendors: {
                  [indexVendor]: {
                    $set: data
                  },
                },
              },
            },
          },
        },
      }
    );
    project.CostingProfiles[project.CostingProfiles.length - 1] = newProposal;
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
  }

  function currencyDropdown() {
    var currdata = [];
    var curr =
      project.CostingProfiles[project.CostingProfiles.length - 1] &&
        project.CostingProfiles[project.CostingProfiles.length - 1]?.CountrySpecs.length > 0
        ? project.CostingProfiles[
          project.CostingProfiles.length - 1
        ]?.ProfileSetting.CurrenciesData.find(
          (c) =>
            c.Code ==
            project.CostingProfiles[project.CostingProfiles.length - 1]?.CountrySpecs[
              props.indexCountry
            ].CountryCode
        )
        : "";
    var commcountrycurr =
      project.CostingProfiles[project.CostingProfiles.length - 1] &&
        project.CostingProfiles[project.CostingProfiles.length - 1]?.CountrySpecs.length > 0
        ? project.CostingProfiles[project.CostingProfiles.length - 1]?.CountrySpecs[
          props.indexCountry
        ].CountryCode != project.CommissioningCountry
          ? project.CostingProfiles[
            project.CostingProfiles.length - 1
          ]?.ProfileSetting.CurrenciesData.find(
            (c) => c.Code == project.CommissioningCountry
          )
          : ""
        : "";

    //project.CommissioningCountry;
    if (curr != "" && curr != undefined) {
      curr.AllCurrencyUnit = project.CostingProfiles[
        project.CostingProfiles.length - 1
      ]?.ProfileSetting?.CurrenciesData.find(
        (fc) => fc.Code == curr.Code
      )?.AllCurrencyUnit;
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = curr.AllCurrencyUnit
        ? curr.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          currdata.push({
            id: i + 1,
            label: all,
            value: i + 1,
            code: curr.Code,
            ConversionRateToLocal: project.CostingProfiles[
              project.CostingProfiles.length - 1
            ]?.ProfileSetting.CurrenciesData.filter(
              (curr) => curr.CurrencyUnit == all
            )[0].ConversionRateToLocal,
          });
        });
      }
    }
    if (commcountrycurr != "" && commcountrycurr != undefined) {
      var currlength = currdata.length;
      commcountrycurr.AllCurrencyUnit =
        project.CostingProfiles[
          project.CostingProfiles.length - 1
        ]?.ProfileSetting?.CurrenciesData.find(
          (fc) => fc.Code == commcountrycurr.Code
        )?.AllCurrencyUnit;
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = commcountrycurr.AllCurrencyUnit
        ? commcountrycurr.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          if (!currdata.find((a) => a.label == all)) {
            currdata.push({
              id: currlength + i + 1,
              label: all,
              value: currlength + i + 1,
              code: commcountrycurr.Code,
              ConversionRateToLocal: project.CostingProfiles[
                project.CostingProfiles.length - 1
              ]?.ProfileSetting.CurrenciesData.filter(
                (curr) => curr.CurrencyUnit == all
              )[0].ConversionRateToLocal,
            });
          }
        });
      }
    }
    return currdata;
  }

  function defaultvalueforcurrency() {
    var currdata = [];
    var curr =
      project?.CostingProfiles[project.CostingProfiles.length - 1] &&
        project?.CostingProfiles[project.CostingProfiles.length - 1]?.CountrySpecs?.length > 0
        ? project?.CostingProfiles[
          project.CostingProfiles.length - 1
        ]?.ProfileSetting?.CurrenciesData?.find(
          (c) =>
            c.Code ==
            project?.CostingProfiles[project.CostingProfiles.length - 1]?.CountrySpecs[
              props.indexCountry
            ]?.CountryCode
        )
        : "";
    if (curr != "") {
      curr.AllCurrencyUnit = curr?.AllCurrencyUnit.replace(
        /(\r\n|\n|\r)/gm,
        ""
      );
      var allUnits = curr?.AllCurrencyUnit
        ? curr?.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          currdata.push({
            id: i + 1,
            label: all,
            value: i + 1,
            code: curr.Code,
            ConversionRateToLocal: project.CostingProfiles[
              project.CostingProfiles.length - 1
            ]?.ProfileSetting.CurrenciesData.filter(
              (curr) => curr.CurrencyUnit == all
            )[0].ConversionRateToLocal,
          });
        });
      }
    }

    var defaultvalue = currdata.find((a) => a.label == curr.CurrencyUnit);
    var OPSCurrency = props.sm.BiddingDetail?.Currency;
    if (!props.sm.InvitedVendors[0]?.Currency) {
      project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].InvitedVendors[0]["Currency"] =
        OPSCurrency ? OPSCurrency : defaultvalue
      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: project,
      });
    }
    return _.last(project.CostingProfiles).CountrySpecs[props.indexCountry]?.MethodologySpecs[props.indexMethodology]?.InvitedVendors[0]?.Currency;
  }

  function updateVendorCurrency(e) {

    project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].InvitedVendors[0]["Currency"] = e
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });

  }

  async function onFileClick(e) {
    try {
      var res = await axios.get(`/files/download/vendor/${e.fileName}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/pdf",
        },
      });
      if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${e.originalName}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    } catch (err) {
      toastr.error("Loading Failed", err?.data?.message);
    }
  }

  //   return (
  //     <>
  //       <Form>
  //         <Card>
  //           <CardHeader 
  //           >
  //             <CardTitle tag="h5" className="my-2">
  //               <Row>
  //                 <Col md={11}>
  //                   <Label>Bidding Details</Label>
  //                 </Col>
  //                 <Col md={1}>
  //                   <FontAwesomeIcon
  //                     id="collapseBiddingDetails"
  //                     icon={faChevronDown}
  //                     href="#collapseBiddingDetails"
  //                   />
  //                 </Col>
  //               </Row>
  //             </CardTitle>
  //           </CardHeader>
  //           <UncontrolledCollapse
  //             toggler="#collapseBiddingDetails"
  //             defaultOpen={true}
  //           >
  //             <CardBody>
  //               <Row form>
  //                 <Col md={4}>
  //                   <FormGroup>
  //                     <Label>QC Validation Mode</Label>
  //                     <Select
  //                       name="QCValidationMode"
  //                       className="react-select-container"
  //                       classNamePrefix="react-select"
  //                       options={qcAll}
  //                       value={_.head(props.sm.BiddingDetail?.QCValidationMode)}
  //                       isMulti
  //                       isDisabled
  //                     />
  //                   </FormGroup>
  //                 </Col>
  //                 <Col md={4}>
  //                   <FormGroup>
  //                     <Label>Currency for Cost Input</Label>
  //                     <Select
  //                       name="Currency"
  //                       className="react-select-container"
  //                       classNamePrefix="react-select"
  //                       options={[]}
  //                       value={props.sm.BiddingDetail?.Currency}
  //                       isDisabled
  //                     />
  //                     <span style={mystyle}></span>
  //                   </FormGroup>
  //                 </Col>

  //                 <Col md={4}>
  //                   <FormGroup>
  //                     <Label>Other Project Specification</Label>

  //     }


  return (
    <>
      <Form>
        <Card>
          <CardHeader>
            <CardTitle tag="h5" className="my-2">
              <Row>
                <Col md={11}>
                  <Label>Bidding Details</Label>
                </Col>
                <Col md={1}>
                  <FontAwesomeIcon id="collapseBiddingDetails" icon={faChevronDown} href="#collapseBiddingDetails" />
                </Col>
              </Row>
            </CardTitle>
          </CardHeader>
          <UncontrolledCollapse toggler="#collapseBiddingDetails" defaultOpen={true}>
            <CardBody>
              <Row form>
                <Col md={4}>
                  <FormGroup>
                    <Label>QC Validation Mode</Label>
                    <Input
                      name="QCValidationMode"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      value={(props.sm.BiddingDetail?.QCValidationMode?.flatMap(x => x.label)?.toLocaleString())}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Currency Selection</Label>
                    <Select
                      name="CurrencySelection"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isDisabled={ViewOnlyMode}
                      options={
                        project.CostingProfiles[project.CostingProfiles.length - 1]
                          ?.CountrySpecs.length > 0 &&
                          _.head(
                            project.CostingProfiles[project.CostingProfiles.length - 1]
                              ?.ProfileSetting.CurrenciesData
                          )
                          ? currencyDropdown()
                          : []
                      }
                      onChange={(e) => {
                        updateVendorCurrency(e, "VendorCurrency");
                      }}
                      value={defaultvalueforcurrency()}
                    />
                    {/* <Label>Currency for Cost Input</Label>
                    <Input
                      name="Currency"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      value={(props.sm.BiddingDetail?.Currency?.label)}
                      disabled
                    />
                    <span style={mystyle}></span> */}
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>Other Project Specification</Label>

                    <textarea className="form-control w-100" rows="2"
                      type="text"
                      value={
                        props?.sm?.BiddingDetail?.OtherProjectSpecification
                      }
                      disabled />

                  </FormGroup>
                </Col>
                <Col md={12}>
                  {props.sm.BiddingDetail?.UploadOtherRequiredDocuments &&
                    <Label>Download Documents</Label>}
                  {props.sm.BiddingDetail?.UploadOtherRequiredDocuments && <Table
                    className="table table-stripped"
                    size="sm"
                    bordered
                  >
                    {props.sm?.BiddingDetail?.UploadOtherRequiredDocuments?.length > 0 &&
                      <tr>
                        <th>S.No</th>
                        <th>File Name</th>
                        <th>Actions</th>
                      </tr>
                    }{props.sm?.BiddingDetail?.UploadOtherRequiredDocuments?.map((f, fi) =>
                      <tr>
                        <td >
                          <b>{fi + 1}</b>
                        </td>
                        <td md={7}>
                          <b>{f.originalName}</b>
                        </td>
                        {(ViewOnlyMode != 7) && <td md={1}>
                          <FontAwesomeIcon
                            onClick={() => onFileClick(f)}
                            title="Download File"
                            className="align-middle mr-2 addButton"
                            icon={faDownload}
                            // icon={vendor[f.value].includes(".pdf") ? faFilePdf : (vendor[f.value].includes(".jpg") || vendor[f.value].includes(".jpeg") || vendor[f.value].includes(".png")) ? faImage : faDownload}
                            fixedWidth
                            style={{ cursor: "pointer", color: "#32cd32" }}
                          />
                        </td>}
                        {(ViewOnlyMode == 7) && <td md={1}>
                          <FontAwesomeIcon
                            title="Download File"
                            className="align-middle mr-2 addButton"
                            icon={faDownload}
                            // icon={vendor[f.value].includes(".pdf") ? faFilePdf : (vendor[f.value].includes(".jpg") || vendor[f.value].includes(".jpeg") || vendor[f.value].includes(".png")) ? faImage : faDownload}
                            fixedWidth
                            style={{ cursor: "pointer", color: "#85b985" }}
                          />
                        </td>}
                      </tr>
                    )}
                  </Table>}
                </Col>
              </Row>
            </CardBody>
          </UncontrolledCollapse>
        </Card>
        {
          <>
            <Card>
              <CardHeader>
                <CardTitle>
                  <Row>
                    <Col md={11}>
                      <Label>Cost Details</Label>
                    </Col>
                    <Col md={1}>
                      <FontAwesomeIcon id="collapseCostDetails" icon={faChevronDown}
                      />
                    </Col>
                  </Row>
                </CardTitle>
              </CardHeader>
              <UncontrolledCollapse toggler="#collapseCostDetails" defaultOpen={true}>
                <CardBody className="scrolltable">
                  {<>
                    <VendorCostingAndTimeline
                      vendor={props.Vendor}
                      flag={"View"}
                      mappingState={props?.Vendor.SelectedRows}
                      meth={props.sm}
                      To={
                        props.sm.BiddingDetail?.IsFullService
                          ? "FULL SERVICE"
                          : "PARTIAL"
                      }
                      VendorId={props.VendorId}
                      indexCountry={props.indexCountry}
                      indexMethodology={props.indexMethodology}
                    ></VendorCostingAndTimeline>

                    <Col md={12}>
                      <Col md={12}>
                        {props.Vendor?.UploadOtherRequiredDocuments &&
                          <Label>Download Documents</Label>}
                        {props.Vendor?.UploadOtherRequiredDocuments && <Table
                          className="table table-stripped"
                          size="sm"
                          bordered
                        >
                          {props.Vendor?.UploadOtherRequiredDocuments?.length > 0 &&
                            <tr>
                              <th>S.No</th>
                              <th>File Name</th>
                              <th>Actions</th>
                            </tr>
                          }{props.Vendor?.UploadOtherRequiredDocuments?.map((f, fi) =>
                            <tr>
                              <td >
                                <b>{fi + 1}</b>
                              </td>
                              <td md={7}>
                                <b>{f.originalName}</b>
                              </td>
                              {(project?.ProjectStatus != 7) && <td md={1}>
                                <FontAwesomeIcon
                                  onClick={() => onFileClick(f)}
                                  title="Download File"
                                  className="align-middle mr-2 addButton"
                                  icon={faDownload}
                                  // icon={vendor[f.value].includes(".pdf") ? faFilePdf : (vendor[f.value].includes(".jpg") || vendor[f.value].includes(".jpeg") || vendor[f.value].includes(".png")) ? faImage : faDownload}
                                  fixedWidth
                                  style={{ cursor: "pointer", color: "#32cd32" }}
                                />
                              </td>}
                              {(project?.ProjectStatus == 7) && <td md={1}>
                                <FontAwesomeIcon
                                  title="Download File"
                                  className="align-middle mr-2 addButton"
                                  icon={faDownload}
                                  // icon={vendor[f.value].includes(".pdf") ? faFilePdf : (vendor[f.value].includes(".jpg") || vendor[f.value].includes(".jpeg") || vendor[f.value].includes(".png")) ? faImage : faDownload}
                                  fixedWidth
                                  style={{ cursor: "pointer", color: "#85b985" }}
                                />
                              </td>}
                            </tr>
                          )}
                        </Table>}
                      </Col>
                      <FormGroup>
                        <Label>Comments (In General)</Label>
                        <textarea
                          className="form-control w-100"
                          type="text"
                          onChange={(val) => addComments(val)}
                          value={props?.Vendor.Comments}
                          disabled={ViewOnlyMode}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup>
                        <div className={"tab tab-methodology-vendor"}></div>
                      </FormGroup>
                    </Col>
                  </>
                  }
                </CardBody>
              </UncontrolledCollapse>
            </Card>
          </>
        }
      </Form>
    </>
  );
}
export default VendorBiddingFormcopy;