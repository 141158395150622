import * as types from "../actions/notificationActions";

const initialState = {
    NewNotification: true,
    // isAnnouncementVisible: false,
    Message:""
};

export default function notificationReducer(state = initialState, actions) {
    switch (actions.type) {
        // case types.SHOW_ANNOUNCEMENT:
        //     return {
        //         ...state,
        //         ShowAnnouncement: actions.isAnnouncementVisible 
        //     }
        // case types.HIDE_ANNOUNCEMENT:
        //     return {
        //         ...state,
        //         HideAnnouncement: actions.isAnnouncementVisible
        //     }
        case types.NEW_NOTIFICATION:
            return {
                ...state,
                NewNotification: actions.hasNewNotification
            }

        case types.SET_MESSAGE:
            return {
                ...state,
                Message: actions.message
            }
        default:
            return state;
    }
}