import React from 'react'
import {
    CardBody,
    Table,
} from "reactstrap";
const ProfileCostingOptions = ({ profile, allCostingOptions }) => {
    return (
        <>
            <CardBody id="costingOptionsBreakdown">
                <Table responsive hover striped bordered={true} size="sm">
                    <tbody>
                        {profile.CountrySpecs?.map(cs => {
                            return <>
                                <tr><td colSpan="12"><strong>{cs.CountryName}</strong></td></tr>
                                <tr>
                                    <th></th>
                                    {cs.MethodologySpecs?.filter(ms => !ms.NotApplicable)?.map(ms => {
                                        return ms?.CostingOptions?.filter(co => co.Selected)?.map(cso => {
                                            return <th>{ms.Label}-{cso.Name}</th>
                                        })
                                    }
                                    )}
                                </tr>
                                {allCostingOptions.length ? allCostingOptions.map(aco => {
                                    return <>
                                        <tr><td>{aco.label}</td>
                                            {cs.MethodologySpecs?.filter(ms => !ms.NotApplicable)?.map(ms => {
                                                return ms?.CostingOptions?.filter(co => co.Selected)?.map(cso => {
                                                    return <td>{cso.CostingOptionData ?
                                                        Array.isArray(cso.CostingOptionData[aco.id])
                                                            ? cso.CostingOptionData[aco.id].map(v => v.label).join()
                                                            : typeof (cso.CostingOptionData[aco.id]) == "object"
                                                                ? cso.CostingOptionData[aco.id].label : cso.CostingOptionData[aco.id] : "-"}</td>
                                                })
                                            })}
                                        </tr>
                                    </>
                                }) : null}
                            </>
                        })}
                    </tbody>
                </Table>
            </CardBody>
        </>
    )
}

export default ProfileCostingOptions
