const Cryptr = require('cryptr');
const encryptOrDecrypt = new Cryptr("AZURESECRET");

export const encryptOrDecryptString = (str, flag) => {
    if(flag)
        return encryptOrDecrypt.encrypt(str);
    else 
        return encryptOrDecrypt.decrypt(str);
}


