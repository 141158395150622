import React, { useState } from 'react'
import {
    Button,
    CustomInput,
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Collapse,
    CardHeader,
    CardTitle,
    CardText,
    Tooltip,
    Modal,
    Badge,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label, Table
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getLabel } from '../../utils/codeLabels';

const SFProjectInfoSection = ({ profile, project }) => {
    const [sfDetails] = useState(project?.ContractDetails)
    // let WBSNumber = sfDetails?.opportunityLineItemDetails.map(x => x.WBSNumber).join(", ");

    return (
        <>
            <Row className="mb-2">
                <Col>
                    <Table>
                        <tbody>
                            <tr>
                                <td>Opportunity Number</td>
                                <td>Project Name</td>
                                <td>WBS Number</td>
                                <td>Commissioning Country</td>
                                <td>Fielding Country</td>

                            </tr>

                            {project?.ContractDetails?.map(x =>

                                <tr>
                                    <td>{x.OpportunityNumber}</td>
                                    <td>{x.OpportunityName}</td>
                                    <td>
                                        <ul className="no-list-stylespl-0 mb-0 pl-0">
                                            {x?.opportunityLineItemDetails.map(y =>
                                                <li>
                                                    {y.WBSNumber}
                                                </li>
                                            )}
                                        </ul>
                                    </td>
                                    <td>{getLabel("FieldingCountriesOptions", project.CommissioningCountry)}</td>
                                    <td><ul className="no-list-stylespl-0 mb-0 pl-0">
                                        {profile?.FieldingCountries?.split(",").map(fc => <li>{getLabel("FieldingCountriesOptions", fc)}</li>)}
                                    </ul></td>
                                </tr>

                            )}
                            {/* <td>
                                <ul className="no-list-styles">
                                    {sfDetails && sfDetails?.map(x =>
                                        <li> {x.OpportunityNumber}</li>
                                    )}
                                </ul>
                            </td>
                            <tr>


                                <td>
                                    <ul className="no-list-styles">
                                        {sfDetails && sfDetails?.map(x =>
                                            <li> {x.OpportunityName}</li>
                                        )}
                                    </ul>
                                </td>
                            </tr>

                            <tr>


                                <td>
                                    <ul className="no-list-styles">
                                        {sfDetails && sfDetails.map(x => x?.opportunityLineItemDetails.map(y =>

                                            <li>
                                                {y.WBSNumber}
                                            </li>
                                        ))}
                                    </ul>
                                </td>

                            </tr>
                            <tr>


                                <td>{getLabel("FieldingCountriesOptions", project.CommissioningCountry)}</td>
                            </tr>
                            <tr>


                                <td><ul className="no-list-stylespl-0 mb-0">
                                    {profile?.FieldingCountries?.split(",").map(fc => <li>{getLabel("FieldingCountriesOptions", fc)}</li>)}
                                </ul></td>
                            </tr> */}
                        </tbody>
                    </Table>
                </Col>

            </Row>




        </>
    )
}

export default SFProjectInfoSection
