import React from "react";

function Info() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#000"
          d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0a12 12 0 0112 12zm-10.5-1.5h-3v9h3v-9zM12 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"
        ></path>
      </g>
    </svg>
  );
}

export default Info;