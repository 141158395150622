import React, { useEffect, useState } from "react";
import { CardTitle, Col, Row, CustomInput, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import * as _ from "lodash";
import update from 'immutability-helper';
import ModalRFQ from "./ModalRFQ";
import { useSelector } from "react-redux";
import { getSchemaWithDataJSON } from "../../../utils/rfqMapper";
import EditFill from "../../../assets/icons/Connected-System-Icons/Tools/Edit-fill";
import { Link } from "react-router-dom";

const FormPage = ({ type, MasterSchema, initValues, updateForm, smCode, changedItems, setChangedItems }) => {
    const [dataCopy, setDataCopy] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState({});
    const [selectedKey, setSelectedKey] = useState(null);
    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );
    const profile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
      );
      const oppValue = useSelector(

        ({ currentProject }) => currentProject.opportunityValue
    
      );
    const pacific = _.head(initValues.codeLabels.PacificCountriesList)?.Label?.split(",");
    const cityOptions = initValues.codeLabels.CitiesOptions?.find(C => C.Code === initValues.currentCountry.Code);
    const lengthOfInterviews = initValues.codeLabels.LengthOfInterviewOptions;
    const [rfqVariables, setRfqVariables] = useState([]);
    const [commisioningCountry] = useState(project.CommissioningCountry)
    function changedData(variable) {
        if (changedItems.length == 0 || changedItems.indexOf(variable) == -1) {
            setChangedItems([...changedItems, variable])
        }
    }
    useEffect(() => {
        if (!_.isEmpty(MasterSchema)) {
            setDataCopy(MasterSchema);
        }
    });

    useEffect(() => {
        let variables = getSchemaWithDataJSON(MasterSchema.schema, smCode, {}, true, commisioningCountry, pacific,project.CostingTemplateVersion, profile, oppValue)
        setRfqVariables(variables);
    }, [dataCopy])

    // info update of the properties
    const InfoChange = (key, flag, type) => {
        const rfqTemp = update(dataCopy, {
            schema: {
                properties: {
                    [key]: {
                        [type]: { $set: !flag },
                        isRequired: {
                            $set: (type === 'isShow' && flag === true) ? false :
                                (type === 'isShow' && flag === false) ? flag : !flag
                        }
                    }
                }
            }
        })
        if (!rfqTemp.schema.properties[key].isShow && rfqTemp.schema.properties[key].isRequired) {
            rfqTemp.schema.properties[key] = update(rfqTemp.schema.properties[key], {
                isRequired: { $set: !rfqTemp.schema.properties[key].isRequired }
            });
        }
        updateForm(rfqTemp);
    }

    // for modal pop-up save and close
    const toggleHandler = (type, value) => {
        if (type === 'close') {
            setModalOpen(value);
        } else {
            const dataModal = update(dataCopy, {
                schema: {
                    properties: {
                        [selectedKey]: { $set: value }
                    }
                }
            })
            updateForm(dataModal);
            setModalOpen(false);
        }
    }

    const updateModal = (ev) => {
        setSelectedProperty(ev);
    }
    const FormDataInput = (P, ind) => {
        return (
            <Col md={12} key={type + ind}>
                <div className="d-flex flex-row">
                    <div className="ml-3">
                        <Input type="checkbox" id={type + '-check-' + P} name={type + '-check-' + P}
                            key={type + '-check-' + P}
                            checked={dataCopy.schema.properties[P].isShow}
                            style={{ cursor: "pointer" }} title="Show / No Show"
                            onChange={(e) => {
                                InfoChange(P, dataCopy.schema.properties[P].isShow, 'isShow')
                                changedData(P)
                            }}
                        />
                        <CustomInput
                            type="switch"
                            id={type + '-switch-' + P} name={type + '-switch-' + P}
                            className="h5 mb-3"
                            key={type + '-switch-' + P}
                            checked={dataCopy.schema.properties[P].isRequired}
                            onChange={(e) => {
                                InfoChange(P, dataCopy.schema.properties[P].isRequired, 'isRequired')
                                changedData(P)
                            }}
                        />
                    </div>
                    <div className="w-100">
                        <label>
                            {dataCopy.schema.properties[P].title}
                        </label>
                        <FontAwesomeIcon
                            title="Edit"
                            icon={faPencilAlt}
                            fixedWidth
                            onClick={() => {
                                setModalOpen(!isModalOpen);
                                setSelectedKey(P);
                                setSelectedProperty(dataCopy.schema.properties[P]);
                            }}
                            style={{ cursor: "pointer" }}
                            className="small"
                        />
                       
                    </div>
                </div>
            </Col>
        )
    }
    return (
        <React.Fragment>
            <CardTitle className="p-2">{!_.isEmpty(dataCopy) ? dataCopy.schema.title : ''}</CardTitle>
            <Row className="ml-0 mr-0">
                {
                    !_.isEmpty(dataCopy) ?
                        Object.keys(dataCopy.schema.properties).map((P, ind) => {
                            if (dataCopy.schema.properties[P].isNewSection && rfqVariables.filter(x => !x.selected && x.sectionTitle == dataCopy.schema.properties[P].sectionTitle).length > 0)
                                return (
                                    <>
                                        <Col md={'12'}>
                                            <h5 id={"header_" + P} className={'header'}>
                                                <div>{dataCopy.schema.properties[P].sectionTitle}</div>
                                            </h5>
                                        </Col>
                                        <Col md={dataCopy.schema.properties[P].width || '4'}
                                        >
                                            {FormDataInput(P, ind)}
                                        </Col></>)
                            else if ((dataCopy.schema.properties[P].selected || dataCopy.schema.properties[P].Default) && dataCopy.schema.properties[P].isNewSection && rfqVariables.filter(x => !x.selected && x.sectionTitle == dataCopy.schema.properties[P].sectionTitle).length > 0 && !dataCopy.schema.properties[P].category)
                                return (<Col md={'12'}>
                                    <h5 id={"header_" + P} className={'header'}>
                                        <div>{dataCopy.schema.properties[P].sectionTitle}</div>
                                        {dataCopy.schema.properties[P].sectionTitle == 'Quota Specification' &&
                                            <>
                                                {/* <div>
                                                          <div className={'header-sub'}>
                                                              <span style={{ width: '150px' }}>Upload Quota</span>
                                                              <Input type="file" name="SOPTrainingEvidanceFile" />
                                                          </div>
                                                      </div> */}
                                            </>
                                        }
                                    </h5>
                                </Col>)
                            else if (!dataCopy.schema.properties[P].selected && !dataCopy.schema.properties[P].disabled && !dataCopy.schema.properties[P].Default && !dataCopy.schema.properties[P].isNewSection)
                                return (<Col md={dataCopy.schema.properties[P].width || '4'}
                                >
                                    {FormDataInput(P, ind)}
                                </Col>)


                        }) : ''
                }
            </Row>
            <ModalRFQ changedData={changedData} selectedKey={selectedKey} initialValue={initValues} isOpen={isModalOpen} pacific={pacific} type={type} properties={selectedProperty} cityOptions={cityOptions} lengthOfInterviews={lengthOfInterviews} toggleHandler={(type, ev) => toggleHandler(type, ev)} updateModal={updateModal} />
        </React.Fragment>
    )
}

export default FormPage;