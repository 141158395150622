import React from "react";

function Hamburger() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#FFF"
          d="M4.375 7h15.252a1.44 1.44 0 001.374-1.5A1.44 1.44 0 0019.627 4H4.375a1.44 1.44 0 00-1.374 1.5A1.44 1.44 0 004.375 7zm15.252 3H4.375a1.44 1.44 0 00-1.374 1.5A1.44 1.44 0 004.375 13h15.252a1.44 1.44 0 001.374-1.5 1.44 1.44 0 00-1.374-1.5zm0 6H4.375a1.506 1.506 0 000 3h15.252a1.506 1.506 0 000-3z"
        ></path>
      </g>
    </svg>
  );
}

export default Hamburger;