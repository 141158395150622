import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import update from "immutability-helper";
import { ReactReduxContext, useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";
import * as costingsActions from "../../redux/actions/costingsActions";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import * as currentWaveSpecActions from "../../redux/actions/currentWaveSpecActions";
import axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import Form from "@rjsf/bootstrap-4";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faQuestionCircle,
  faPen,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Collapse,
  CardHeader,
  CardTitle,
  CardText,
  Badge,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { setWaveSpecs } from "../../redux/actions/waveSpecsActions";
import Selector from "../../components/Selector/Selector_ProjectReview";
import { getLabel } from "../../utils/codeLabels";
import FormFeedback from "reactstrap/lib/FormFeedback";
import Spinner from "../../components/Spinner";
import { number } from "prop-types";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";

const ReviewVendor = ({ profile, indxFC, methodology, indxSM, disableforCS }) => {
  const user = useSelector(({ user }) => user.userRecord);
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const costingProfiles = useSelector(
    ({ costings }) => costings.costingProfiles
  );
  // const currentCostingProfile = useSelector(
  //   ({ currentCosting }) => currentCosting.currentCostingProfile
  // );

  const [editableCostingProfile, setEditableCosting] = useState(
    profile
  );
  const userRecord = useSelector(({ user }) => user.userRecord);

  const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
  // const currentWaveSpec = useSelector(({ currentWaveSpec }) => currentWaveSpec);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const app = useSelector(({ app }) => app);
  const [activeColumnIndex, setActiveColumnIndex] = useState(0);
  const [deleteVendor, setDeleteVendor] = useState(-1);
  const [waveBreakDown, setWaveBreakDown] = useState({})
  const [currentWaveSpec, setcurrentWaveSpec] = useState([])
  const [modifiedMeths, setModifiedMeths] = useState([])
  const [wavesSpecsData, setwavesSpecsData] = useState(profile.WaveSpecs);
  const [activeTabFC, setActiveTabFC] = useState();
  const [activeTabSM, setActiveTabSM] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [editableWaveName, setEditableWavename] = useState();
  const dispatch = useDispatch();
  const [selectedItemWave, setSelectedItemWave] = useState("1");
  const [waveEditModal, setWaveEditModal] = useState(false);
  //vendor evaluation default value
  const [json, setJSON] = useState(JSON.parse(codeLabels.VendorEvaluationSchema?.find(v => v.Code == "VendorEvaluationSchema")?.Label))
  const [toggle, setToggle] = useState({
    overall: true,
    inst: true,
    other: true,
  });

  useEffect(() => {
    setJSON(JSON.parse(codeLabels.VendorEvaluationSchema?.find(v => v.Code == "VendorEvaluationSchema").Label))

  }, [])


  const [renderTemplate, setRenderTemplate] = useState([]);

  useEffect(() => {
    if (profile &&
      wavesSpecsData
      && wavesSpecsData.length
      && !Object.keys(waveBreakDown).length) {

      profile.WaveSpecs.map(x => {
        if (!x.vendorEvalationSchema) {
          x.vendorEvalationSchema = [];
          if (x.VendorEvaluation &&
            x.VendorEvaluation.length) {
            x.vendorEvalationSchema = _.cloneDeep(Array(x.VendorEvaluation.length).fill(json));
          }
        }
        return x;
      })
      let data = checkCurrentWaveSpec(profile, selectedItemWave);

      setRenderTemplate(data?.VendorEvaluation &&
        data.VendorEvaluation.length &&
        data.VendorEvaluation.length && Array(data.VendorEvaluation.length).fill(json) || []);

      setcurrentWaveSpec(data);
    }
    else {
      let rData = (profile.CountrySpecs[indxFC]);
      let checData = (rData?.MethodologySpecs[indxSM]);
      let _waveBreakdownMain = profile.WaveSpecs.filter(x => x.MethodologySpecId === checData.id);
      setModifiedMeths(_waveBreakdownMain);
    }
  }, [profile])
  useEffect(() => {
    setEditableCosting(profile);
  }, [profile]);

  const checkCurrentWaveSpec = (profileVal, selectedItemWave) => {
    let rData = (profileVal.CountrySpecs[indxFC]);
    let checData = (rData?.MethodologySpecs[indxSM]);
    setActiveTabFC(rData.id);
    setActiveTabSM(checData.id);
    let _waveBreakdownMain = profileVal.WaveSpecs.filter(x => x.MethodologySpecId === checData.id);
    let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber);
    setModifiedMeths(_waveBreakdownMain);
    Object.keys(_waveBreakdown).map(w => {
      _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel);
    });
    setwavesSpecsData(profileVal.WaveSpecs);
    setWaveBreakDown(_waveBreakdown);
    let _currentwaveset = _.head(_waveBreakdown[selectedItemWave]);
    return _currentwaveset;
  }

  const selectorHandler = (item) => {
    setSelectedItemWave(item.WaveNumber);
    let _waveBreakdownMain = profile.WaveSpecs.filter(x => x.MethodologySpecId === activeTabSM)
    let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
    setModifiedMeths((_waveBreakdownMain))
    Object.keys(_waveBreakdown).map(w => {
      _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
    })
    // do nothing if clicked item is current item
    if (item === (currentWaveSpec)?.MethodologySpecId) return;
    else {
      let data = _.head(_waveBreakdown[item.WaveNumber])
      setcurrentWaveSpec(data);
      setActiveColumnIndex(0)
    }
  };

  const onWaveChangeHandler = (eve, useCurrentSpec, isApi) => {
    let currentwavespec = {};
    currentwavespec = checkCurrentWaveSpec(profile, selectedItemWave);
    setcurrentWaveSpec(currentwavespec);

    currentwavespec = {
      ...currentwavespec,
      [eve.target.id]: eve.target.value,
    };

    let wavespecs = [...profile.WaveSpecs];
    wavespecs = wavespecs.map((ws) => {
      if (ws.id == currentWaveSpec.id) {
        ws = currentwavespec;
      }
      return { ...ws };
    });

    setcurrentWaveSpec(currentwavespec);
    let mappedData = modifiedMeths.map(x => {
      if (x.id === currentwavespec.id) {
        x = { ...currentwavespec }
      }
      return x;
    })
    setModifiedMeths(mappedData);
    setwavesSpecsData(wavespecs)

    const profileTemp = update(profile, {
      WaveSpecs: { $set: wavespecs }
    });
    dispatch(currentCostingActions.updateProfile(profileTemp));
    if (isApi) {
      setWaveEditModal(false);
    }
  };
  const renderSelector = () => {
    if (!waveSpecs || (waveSpecs && waveSpecs.length === 1)) return null;
    return (
      <Col lg="2" md="2" sm="12" xs="12">
        <Selector
          heading={"Waves"}
          records={modifiedMeths}
          // applyAll={applyToAllWaves}
          // applyAllText={"Apply to All Waves"}
          clicked={selectorHandler}
          interPolField={["WaveNumber", "WaveName"]}
          displayField={
            <>
              <FontAwesomeIcon
                title="Edit Wave Name"
                size="xs"
                icon={faPen}
                className={
                  userRecord.IsOpsProjectManager ? "pointer" : "no-actions"
                }
                onClick={() => {
                  if (userRecord.IsOpsProjectManager)
                    setWaveEditModal(!waveEditModal);
                }}
              />
            </>
          }
          selected={currentWaveSpec}
        //   labelGroup={"FieldingCountriesOptions"}
        />
      </Col>
    );
  };
  const scheduleNotesHandler = (index, eve) => {
    let VendorEvaluation = [];
    // let waveSpecsToSave = [...waveSpecs];

    if (currentWaveSpec.VendorEvaluation)
      VendorEvaluation = [...currentWaveSpec.VendorEvaluation];
    let currVendor = VendorEvaluation[index] || {};
    currVendor = { ...currVendor, [eve.target.id]: eve.target.value };
    VendorEvaluation[index] = currVendor;
    let currentwaveSpec = checkCurrentWaveSpec(profile, selectedItemWave);
    setcurrentWaveSpec(currentwaveSpec)
    let finalCurrentWaveSpec = { ...currentwaveSpec, VendorEvaluation: [...VendorEvaluation] };
    dispatch(
      currentWaveSpecActions.updateCurrentWaveSpec(finalCurrentWaveSpec)
    );
    onChangeHandler(finalCurrentWaveSpec);
  };

  const onValueChangeHandler = (vendorIndex, index, eve) => {
    let VendorEvaluation = currentWaveSpec.VendorEvaluation || [];

    let currVendor = VendorEvaluation[vendorIndex] || {};
    let overAllEvaluation = currVendor.overAllEvaluation || [];
    let obj = {};
    if (overAllEvaluation && overAllEvaluation[index]) {
      obj = overAllEvaluation[index];
    }
    obj = { ...obj, [eve.target.id]: eve.target.value };
    if (eve.target.value == "1" || eve.target.value) {
      obj = { ...obj, ["Comment"]: "" }
    }
    overAllEvaluation[index] = obj;
    currVendor = { ...currVendor, overAllEvaluation: overAllEvaluation };
    VendorEvaluation[vendorIndex] = currVendor;
    let currentwaveSpec = checkCurrentWaveSpec(profile, selectedItemWave)
    setcurrentWaveSpec(currentwaveSpec)
    let finalCurrentWaveSpec = { ...currentwaveSpec, VendorEvaluation: [...VendorEvaluation] };
    dispatch(
      currentWaveSpecActions.updateCurrentWaveSpec(finalCurrentWaveSpec)
    );
    onChangeHandler(finalCurrentWaveSpec);
  }

  const onChangeHandler = (finalCurrentWaveSpec) => {
    let wavespecs = [...profile.WaveSpecs];

    wavespecs = wavespecs.map((ws) => {
      if (ws.id == finalCurrentWaveSpec.id) {
        ws = finalCurrentWaveSpec;
      }
      return { ...ws };
    });
    dispatch(setWaveSpecs([...wavespecs]));

    let editablecostingprofile = { ...editableCostingProfile };
    editablecostingprofile.WaveSpecs = editablecostingprofile.WaveSpecs.map(
      (ws) => {
        if (ws.id == finalCurrentWaveSpec.id) {
          return { ...finalCurrentWaveSpec };
        } else {
          return { ...ws };
        }
      }
    );
    setcurrentWaveSpec(finalCurrentWaveSpec);
    setwavesSpecsData(wavespecs)
    setEditableCosting(editablecostingprofile);

    const profileTemp = update(editableCostingProfile, {
      WaveSpecs: { $set: wavespecs }
    });
    dispatch(currentCostingActions.updateProfile(profileTemp));
  };

  const deleteVendorByIndex = (index) => {
    let editableTemplate = currentWaveSpec.vendorEvalationSchema;
    editableTemplate.splice(index, 1);
    setRenderTemplate([...editableTemplate])
    currentWaveSpec.vendorEvalationSchema = [...editableTemplate];
    setcurrentWaveSpec(currentWaveSpec);
    if (activeColumnIndex === currentWaveSpec.vendorEvalationSchema.length)
      setActiveColumnIndex(activeColumnIndex > 1 ? activeColumnIndex - 1 : 0);
    let VendorEvaluation = currentWaveSpec.VendorEvaluation;
    if (VendorEvaluation !== null) {
      VendorEvaluation.splice(index, 1);
    }
    let finalCurrentWaveSpec = { ...currentWaveSpec, VendorEvaluation: _.cloneDeep(VendorEvaluation) };
    onChangeHandler(finalCurrentWaveSpec);
  }

  const onAddVendor = () => {
    setRenderTemplate([...renderTemplate, json]);
    currentWaveSpec.vendorEvalationSchema.push(json);
    setcurrentWaveSpec(currentWaveSpec);
    let wavespecs = [...profile.WaveSpecs];

    wavespecs = wavespecs.map((ws) => {
      if (ws.id == currentWaveSpec.id) {
        ws = currentWaveSpec;
      }
      return { ...ws };
    });
    setwavesSpecsData(wavespecs)

    const profileTemp = update(profile, {
      WaveSpecs: { $set: wavespecs }
    });
    dispatch(currentCostingActions.updateProfile(profileTemp));
  };
  return (
    <>
      <>
        <Container className="review-vendor review-schedule">
          <Modal
            size="sm"
            isOpen={deleteVendor !== -1}
            toggle={() => setDeleteVendor(deleteVendor !== -1 ? -1 : 0)}
          >
            <ModalHeader toggle={() => setDeleteVendor(deleteVendor !== -1 ? -1 : 0)}>
              Delete Vendor
            </ModalHeader>
            <ModalBody>
              <strong>
                This Change is Irreversible. Are you sure want to delete Vendor Evaluation?
              </strong>
            </ModalBody>
            <ModalFooter>
              <Row className="justify-content-end">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={() => setDeleteVendor(deleteVendor !== -1 ? -1 : 0)}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className="ml-2"
                  size="sm"
                  onClick={() => {
                    deleteVendorByIndex(deleteVendor);
                    setDeleteVendor(-1);
                  }}
                >
                  Confirm
                </Button>
              </Row>
            </ModalFooter>
          </Modal>
          <Card
            className="ml-2 mr-2 mb-0 p-0"
            style={{ background: "none", boxShadow: "none" }}
          >
            <Row>
              {renderSelector()}
              <Col>
                <Card className="mb-2">
                  <CardHeader className="border-bottom border-light">
                    <Row>
                      <Col lg="4" md="4" sm="12" xs="12">
                        <strong>
                          Evaluate Vendor / Panel Provider Project Performance
                          on
                        </strong>
                      </Col>
                      <Col lg="4" md="4" sm="12" xs="12">
                        <strong>Rating: 1-5</strong>
                      </Col>
                      <Col lg="4" md="4" sm="12" xs="12">
                        <strong>Comments</strong>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div><Button className='bottom-space' onClick={() => { onAddVendor() }}>{'Add Vendor'}</Button> </div>
                    <nav>
                      <div className="nav nav-tabs mt-3 mr-3">
                        {
                          currentWaveSpec && currentWaveSpec?.vendorEvalationSchema && currentWaveSpec?.vendorEvalationSchema.map((column, index) => {
                            return (
                              column ? <a key={'configured-columns_' + index}
                                className={`nav-item nav-link ${index == activeColumnIndex ? 'active' : ''}`}
                                onClick={() => setActiveColumnIndex(index)} >
                                <div>
                                  {currentWaveSpec && currentWaveSpec.VendorEvaluation && currentWaveSpec.VendorEvaluation[index]
                                    && currentWaveSpec.VendorEvaluation[index].vendorName ? currentWaveSpec.VendorEvaluation[index].vendorName : 'Vendor Name'}
                                  {
                                    <FontAwesomeIcon
                                      style={{ marginLeft: '5px' }}
                                      title="Delete Vendor"
                                      size="xs"
                                      icon={faTrash}
                                      className={"pointer"}
                                      onClick={(eve) => {
                                        eve.stopPropagation();
                                        setDeleteVendor(index);
                                        // deleteVendor(index);
                                      }} />}
                                </div>
                              </a> : <></>
                            )
                          })
                        }
                      </div>
                    </nav>
                    <div  >
                      {currentWaveSpec && currentWaveSpec?.vendorEvalationSchema && currentWaveSpec?.vendorEvalationSchema.map((field, vendorIndex) => {
                        let ve = currentWaveSpec.VendorEvaluation;
                        return (
                          <div className="ml-2 mr-2 mb-2" style={{ display: activeColumnIndex == vendorIndex ? 'block' : 'none' }}>
                            <Row className="mb-2 border-bottom border-light">
                              <span className="review-text">{field.vendorNameField.label}</span>
                            </Row>
                            <Row className="mb-2 border-bottom border-light">
                              <input id={field.vendorNameField.id}
                                type={field.vendorNameField.type}
                                disabled={!userRecord.IsOpsProjectManager || disableforCS}
                                readOnly={!userRecord.IsOpsProjectManager}
                                value={ve
                                  && ve[vendorIndex]
                                  ? ve[vendorIndex][field.vendorNameField.id]
                                  : ""}
                                onChange={scheduleNotesHandler.bind(this, vendorIndex)}
                                className="form-control">
                              </input>
                            </Row>
                            <div>
                              <h5>{"Overall Evaluation"}</h5>
                              <hr className='break-line' />
                            </div>
                            {field.overAllEvaluation.map((subField, subIndex) => {
                              return (<>
                                <Row>
                                  <Col md={5}>
                                    <h5>{subField.label}</h5>

                                    <span>{subField.desc}</span>
                                  </Col>


                                  {/* </Row> */}
                                  {/* <hr className='break-line' /> */}
                                  {/* <Row style={{ marginBottom: '10px' }}> */}
                                  {subField.fields.map((obj) => {
                                    let oe = ve && ve[vendorIndex]
                                    return (
                                      <Col md={obj.type == "number" ? 2 : 5}>
                                        {/* <div className={obj.type == "number" ? "col-xs-12 col-sm-6 col-md-4 col-lg-3" : "col"}> <span
                                        className="review-text bottom-space"
                                      > */}
                                        {obj.label}
                                        {/* {obj.label=="Comment" &&oe && oe.overAllEvaluation
                                        && oe.overAllEvaluation[subIndex] &&oe.overAllEvaluation[subIndex]["score"]<3?"*":""}</span> */}

                                        {obj.type === "number" ?
                                          // <input id={obj.id}
                                          //   type={obj.type}
                                          //   disabled={!userRecord.IsOpsProjectManager}
                                          //   readOnly={!userRecord.IsOpsProjectManager}
                                          //   value={oe && oe.overAllEvaluation
                                          //     && oe.overAllEvaluation[subIndex]
                                          //     ? oe.overAllEvaluation[subIndex][obj.id]
                                          //     : ""}
                                          //   onChange={onValueChangeHandler.bind(this, vendorIndex, subIndex)}
                                          //   className="form-control" /> 

                                          // Implemented DropDown as suggested By Melisa
                                          <Row className="m-0">
                                            <SelectWrapper
                                              className="width-150"
                                              onChange={onValueChangeHandler.bind(this, vendorIndex, subIndex)}
                                              id={obj.id}
                                              value={oe && oe.overAllEvaluation
                                                && oe.overAllEvaluation[subIndex]
                                                ? oe.overAllEvaluation[subIndex][obj.id]
                                                : ""}
                                              disabled={disableforCS}
                                              required={oe && oe.overAllEvaluation
                                                && oe.overAllEvaluation[subIndex] && oe.overAllEvaluation[subIndex][obj.id] < 2 ? true : false}
                                            >
                                              <option value={""}>Select Rating</option>
                                              {obj.enum.map((opt, index) => <option key={index} value={opt}>{opt}</option>)}
                                            </SelectWrapper>
                                          </Row>
                                          : <Row className="m-0"><textarea id={obj.id}
                                            disabled={!userRecord.IsOpsProjectManager || disableforCS}
                                            readOnly={!userRecord.IsOpsProjectManager}
                                            value={oe && oe.overAllEvaluation
                                              && oe.overAllEvaluation[subIndex]
                                              ? oe.overAllEvaluation[subIndex][obj.id]
                                              : ""}
                                            onChange={onValueChangeHandler.bind(this, vendorIndex, subIndex)}
                                            className="form-control"
                                            required={oe && oe.overAllEvaluation
                                              && oe.overAllEvaluation[subIndex] && oe.overAllEvaluation[subIndex]["score"] < 2 ? true : false}

                                          /></Row>}
                                        {/* </div> */}
                                      </Col>
                                    )
                                  })}
                                </Row>
                                <hr className='break-line'></hr>
                              </>
                              )
                            })}
                            <div className="row">
                              <span>{field.otherCommentField.label}</span>

                              <textarea id={field.otherCommentField.id}
                                type={field.otherCommentField.type}
                                disabled={!userRecord.IsOpsProjectManager || disableforCS}
                                readOnly={!userRecord.IsOpsProjectManager}
                                value={ve
                                  && ve[vendorIndex]
                                  ? ve[vendorIndex][field.otherCommentField.id]
                                  : ""}
                                className="form-control"
                                onChange={scheduleNotesHandler.bind(this, vendorIndex)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Card>
        </Container>
      </>


      <Modal
        isOpen={waveEditModal}
        toggle={() => setWaveEditModal(!waveEditModal)}
        centered
      >
        <ModalHeader toggle={() => setWaveEditModal(!waveEditModal)}>
          Edit Current Wave Name
        </ModalHeader>
        <ModalBody>
          <Input
            defaultValue={
              currentWaveSpec.WaveName ? currentWaveSpec.WaveName : ""
            }
            id="WaveName"
            onChange={(e) => setEditableWavename(e.target.value)}
            placeholder="Enter Wave Name"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={app.recordloading}
            onClick={(e) => {
              onWaveChangeHandler(
                { target: { id: "WaveName", value: editableWaveName } },
                null,
                true
              );
            }}
          >
            Update
            {app.recordloading ? (
              <Spinner size="small" color="#495057" />
            ) : null}
          </Button>
          <Button
            color="secondary"
            disabled={app.recordloading}
            onClick={() => setWaveEditModal(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ReviewVendor;
