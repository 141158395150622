import React, { useState, useEffect } from "react";
import axios from "../../../axios-interceptor";
import _ from "lodash";
import classnames from "classnames";
import Select from "react-select";
import { useDispatch } from "react-redux";
import update from "immutability-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toastr } from "react-redux-toastr";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import vendorReducer from "../../../redux/reducers/vendorReducer";
import * as proposalActions from "../../../redux/actions/proposal/Actions";
import {
  faChevronDown,
  faPen,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledCollapse,
} from "reactstrap";
import { useSelector } from "react-redux";
import BiddingFiles from "./BiddingFiles";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Table,
} from "reactstrap";
import WarningModal from "./components/modals/WarningModal";
import HistoryModal from "./components/modals/HistoryModal";
import { VendorType, qcAll } from "./constants/detailConstants";
import { onlinemethcode } from "./constants/onlinemethcodeConstants";
import "./css/common.css";
import SplitSampleManagement from "./components/modals/Split-Sample-Adjustment";
import VendorCostingAndTimeline from "./CostingAndTimeline";
import proposalReducer from "../../../redux/reducers/proposalReducer";

function VendorBiddingForm(props) {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const splitdata = useSelector(
    ({ vendorReducer }) => vendorReducer.SplitData
  )
  var project = useSelector(({ currentProject }) => currentProject.newProject);
  const dispatch = useDispatch();
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const [messageBox, setMessageBox] = useState(false);
  const [removeData, setRemovedata] = useState(false);
  const [alertBox, setAlertBox] = useState();
  const [checked, setChecked] = useState();
  const [splitTab, setSplitTab] = useState(0);
  const [valid, setValid] = useState(false);
  const [openModalSplit, setOpenModalSplit] = useState(false);
  const [validBooster, setValidBooster] = useState(false);
  const [validCity, setValidCity] = useState(false);
  let vendorreducer = useSelector(({ vendorReducer }) => vendorReducer);
  function closeSampleSplit() {
    setOpenModalSplit(false);
  }
  function returnList() {
    var list = VendorData?.filter(
      (a) =>
        a.Countries?.filter(
          (b) =>
          ((b.Code ==
            project.CostingProfiles[costingProfileIndex]
              ?.CountrySpecs[props.indexCountry]?.CountryCode)
            ||
            (b.Code == project.CommissioningCountry && (!iscomcountryinvbm))
          )
        )?.length > 0
    )
      .filter(
        (v) =>
          v.Methodology?.filter(
            (s) => s.Code == props.sm.Code
          )?.length > 0
      )
      .filter(
        (vt) =>
          vt.VendorType?.filter(
            (ct) => ct.Code.includes(SudTabsName) && ct.Code != "Online (data collection)"
          )?.length > 0
      )
      ?.map((a) => {
        return {
          label: a.label,
          value: a.value,
          VendorId: a.value,
        };
      })
    var list2 = props.sm?.InvitedVendors?.filter(a => a.ServiceType == 0 && a.IsDeleted == false)

    return (list?.filter((object1) => {
      return !list2?.some((object2) => {
        return object1.VendorId === object2.VendorId;
      });
    }))

  }
  function CheckVendorSelection(e) {
    var val = false
    var vendorlength = props.sm.InvitedVendors.length
    val = props.sm.InvitedVendors?.filter(x => x.ServiceType != 1 && x.VendorCosts)?.flatMap(x => x.VendorCosts)?.filter(x => x.IsSelected)?.length > 0 ? true : false
    if (val) {

      toastr.error(`Vendor has been selected for costing in  
      ${e.target.checked == true
          ? "Field Cost"
          : "Full Service"
        } 
        so we are not able to delete the ${vendorlength > 1 ? "vendors" : "vendor"}`);
    }
    else {
      openModal(e);
    }
  }
  function returnstate(vendor) {
    var rows = []
    if (props.sm.BiddingDetail?.IsFullService) {
      rows = vendor.SelectedRows.filter(a => a.header != "TIMINGS ( # of WORKING DAYS)")
    }
    else if (ServiceType == 1) {
      rows = vendor.SelectedRows.filter(a => a.group == SudTabsName || a.header == SudTabsName)
    }
    else {
      rows = vendor?.SelectedRows?.filter(a => a.group == "Field Cost" || a.header == "Field Cost")
    }
    return rows
  }
  function returnSelectedRows() {
    let data = [];
    let tabs = returnSudTabsdata().map(a => a.header);
    if (props.sm.BiddingDetail?.IsFullService)
      data = initailstate.filter(a => tabs.includes(a.group || a.header) || a.group == "Field Cost" || a.name == "Field Cost").map((u) => {
        return {
          id: u.id,
          name: u.name,
          selected: true,
          group: u.group,
          type: u.type,
          key: u.key,
          header: u.header ? u.header : null
        };
      })
    else {
      data = initailstate
        .filter((e) => e.group == "Field Cost" || e.name == "Field Cost")
        .map((u) => {
          return {
            id: u.id,
            name: u.name,
            selected: true,
            group: u.group,
            type: u.type,
            key: u.key,
            header: u.header ? u.header : null
          };
        });
    }
    return data;
  }
  function openModal(e) {
    setChecked(e.target.checked);
    var tempproject = _.cloneDeep(
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
        props.indexCountry
      ].MethodologySpecs
    );
    if (
      tempproject[props.indexMethodology].InvitedVendors != null &&
      tempproject[props.indexMethodology].InvitedVendors.length > 0
    ) {
      setRemovedata(false);
      setMessageBox(true);
      setAlertBox(
        `Data will be lost, if you unselect ${e.target.checked == true
          ? "Field Execution and Setup & Delivery Work Only"
          : "Full Service"
        } !`
      );
    } else {
      isFullServiceRequired(true, e.target.checked);
    }
  }

  function qcApplied() {
    return qcAll.filter((qc) => {
      return (
        qc.applied == "all" ||
        (qc.applied &&
          qc.applied.filter((a) => a == props.sm.Label).length > 0) ||
        (qc.notApplied &&
          qc.notApplied.filter((na) => na == props.sm.Label).length == 0)
      );
    });
  }

  function currencyDropdown() {
    var currdata = [];
    var curr =
      project.CostingProfiles[costingProfileIndex] &&
        project.CostingProfiles[costingProfileIndex]?.CountrySpecs.length > 0
        ? project.CostingProfiles[
          costingProfileIndex
        ]?.ProfileSetting.CurrenciesData.find(
          (c) =>
            c.Code ==
            project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
              props.indexCountry
            ].CountryCode
        )
        : "";
    var commcountrycurr =
      project.CostingProfiles[costingProfileIndex] &&
        project.CostingProfiles[costingProfileIndex]?.CountrySpecs.length > 0
        ? project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
          props.indexCountry
        ].CountryCode != project.CommissioningCountry
          ? project.CostingProfiles[
            costingProfileIndex
          ]?.ProfileSetting.CurrenciesData.find(
            (c) => c.Code == project.CommissioningCountry
          )
          : ""
        : "";

    //project.CommissioningCountry;
    if (curr != "" && curr != undefined) {
      curr.AllCurrencyUnit = codeLabels.FieldingCountriesOptions.find(
        (fc) => fc.Code == curr.Code
      ).AllCurrencyUnit;
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = curr.AllCurrencyUnit
        ? curr.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          currdata.push({
            id: i + 1,
            label: all,
            value: i + 1,
            code: curr.Code,
            ConversionRateToLocal: project.CostingProfiles[
              costingProfileIndex
            ]?.ProfileSetting.CurrenciesData.filter(
              (curr) => curr.CurrencyUnit == all
            )[0].ConversionRateToLocal,
          });
        });
      }
    }
    if (commcountrycurr != "" && commcountrycurr != undefined) {
      var currlength = currdata.length;
      commcountrycurr.AllCurrencyUnit =
        codeLabels.FieldingCountriesOptions.find(
          (fc) => fc.Code == commcountrycurr.Code
        ).AllCurrencyUnit;
      //  curr.AllCurrencyUnit.replace(/(\r\n|\n|\r)/gm, "");
      var allUnits = commcountrycurr.AllCurrencyUnit
        ? commcountrycurr.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          if (!currdata.find((a) => a.label == all)) {
            currdata.push({
              id: currlength + i + 1,
              label: all,
              value: currlength + i + 1,
              code: commcountrycurr.Code,
              ConversionRateToLocal: project.CostingProfiles[
                costingProfileIndex
              ]?.ProfileSetting.CurrenciesData.filter(
                (curr) => curr.CurrencyUnit == all
              )[0].ConversionRateToLocal,
            });
          }
        });
      }
    }
    return currdata;
  }
  function defaultvalueforcurrency() {
    var currdata = [];
    var curr =
      project?.CostingProfiles[costingProfileIndex] &&
        project?.CostingProfiles[costingProfileIndex]?.CountrySpecs?.length > 0
        ? project?.CostingProfiles[
          costingProfileIndex
        ]?.ProfileSetting?.CurrenciesData?.find(
          (c) =>
            c.Code ==
            project?.CostingProfiles[costingProfileIndex]?.CountrySpecs[
              props.indexCountry
            ]?.CountryCode
        )
        : "";
    if (curr != "") {
      curr.AllCurrencyUnit = curr?.AllCurrencyUnit.replace(
        /(\r\n|\n|\r)/gm,
        ""
      );
      var allUnits = curr?.AllCurrencyUnit
        ? curr?.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          currdata.push({
            id: i + 1,
            label: all,
            value: i + 1,
            code: curr.Code,
            ConversionRateToLocal: project.CostingProfiles[
              costingProfileIndex
            ]?.ProfileSetting.CurrenciesData.filter(
              (curr) => curr.CurrencyUnit == all
            )[0].ConversionRateToLocal,
          });
        });
      }
    }

    var defaultvalue = currdata.find((a) => a.label == curr.CurrencyUnit);

    if (!props.sm.BiddingDetail) {
      project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].BiddingDetail = {}
      project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].BiddingDetail["Currency"] = defaultvalue
      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: project,
      });
    }
    else if (!props.sm.BiddingDetail.Currency) {
      project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs[props.indexCountry].MethodologySpecs[props.indexMethodology].BiddingDetail["Currency"] = defaultvalue
      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: project,
      });
    }
    return _.last(project.CostingProfiles).CountrySpecs[props.indexCountry]?.MethodologySpecs[props.indexMethodology]?.BiddingDetail?.Currency;
  }
  const [costingProfileIndex, setCostingProfileIndex] = useState(0);
  const [proposal, setProposalData] = useState({});
  const [activeTabCostingProfile, setActiveTabCostingProfile] = useState(0);
  const [behalfofvendor, setbehalfofvendor] = useState(false);
  const [show, setshow] = useState(false);
  const [activeTabCountry, setActiveTabCountry] = useState(0);
  const [activeTabMethodology, setActiveTabMethodology] = useState(0);
  const [modelState, setModelState] = useState(false);
  const [chatData, setChatData] = useState(props.chatData);
  const [lastvalue, setlastvalue] = useState(0);
  const [list, setlist] = useState();
  const [conversionRate, setConversionRate] = useState();
  const [activevendor, setactivevendor] = useState(0);
  const [methactiveTabSM, setmethActiveTabSM] = useState(0);
  const [enable, setenable] = useState(true);
  const [ServiceType, setServiceType] = useState(0);
  const [VendorData, setVendorData] = useState([]);
  const [SudTabs, setSudTabs] = useState(0);
  const [initailstate, setinitailstate] = useState([]);
  const [SudTabsName, setSudTabsName] = useState(0);
  const [SUDvendorList, setSUDvendorList] = useState(0);
  const [ViewOnlyMode, setViewOnlyMode] = useState(false);
  const [iscomcountryinvbm, setIsComCountryInVBM] = useState(false);
  var initialstateQuant = [];
  var initialstateQual = [];
  var initailstateOnline = [];

  useEffect(() => {
    if ((project.CostingProfiles[project.CostingProfiles.length - 1].VenodrBidDeadLineStatus == "close")
      || (project?.ProjectStatus == 7)
      || (["2", "3", "4", "5", "6"].indexOf(_.last(project?.CostingProfiles).ProfileStatus) != -1)) {
      setViewOnlyMode(true);
    }

    var index = project.CostingProfiles?.length - 1
    setIsComCountryInVBM(project.CostingProfiles[index].CountrySpecs?.filter(x => x.CountryCode == project.CommissioningCountry)[0]?.MethodologySpecs?.filter(y => y.CostingType == 3)?.length > 0 ? true : false)
    var SelectedMeth = _.cloneDeep(
      project.CostingProfiles[index].CountrySpecs[props.indexCountry]
        .MethodologySpecs[props.indexMethodology]);

    var tempvendor = SelectedMeth.InvitedVendors
    var COData = SelectedMeth.CostingOptions;
    if (tempvendor) {
      tempvendor.map(vendor => {
        var CostingOptionData = [];
        if (vendor.ServiceType != 1) {
          COData.forEach(co => {
            var SelectedCostOptionIds = vendor.VendorCosts.filter(a => a.CostOptionId).flatMap(b => b.CostOptionId)
            if (SelectedCostOptionIds.includes(co.id)) {
              vendor.VendorCosts.filter(a => a.CostOptionId == co.id)[0].IsInvited = vendor.VendorCosts.filter(a => a.CostOptionId == co.id)[0].IsInvited ? co.Selected ? true : false : false;
              CostingOptionData.push(vendor.VendorCosts.filter(a => a.CostOptionId == co.id)[0])
            }
            else
              if (!SelectedCostOptionIds.includes(co.id)) {
                var data = {};
                data.History = [];
                data.CostOptionId = co.id;
                data.CostingsData = [];
                data.IsInvited = co.Selected ? true : false;
                data.IsSelected = false;
                data.SelectedDate = "";
                data.IsEditable = true;
                data.Comments = "";
                data.AchivableSamples = 0;
                data.SampleDistributionDetailsId = null;
                data.RoundId =
                  (_.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == props.sm.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == co.id)[0]?.RemainingSamples &&
                    _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == props.sm.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == co.id)[0]?.RemainingSamples != 0) ? (
                    _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == props.sm.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == co.id)[0]?.SampleDistributionDetails?.length == 0 ?
                      _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == props.sm.id)).id : null) : null
                CostingOptionData.push(data);
              }
          })
          vendor.VendorCosts = _.cloneDeep(CostingOptionData)
        }
        else {
          COData.forEach(co => {
            var SelectedCostOptionIds = vendor.VendorCosts.filter(a => a.CostOptionId).flatMap(b => b.CostOptionId)
            if (SelectedCostOptionIds.includes(co.id)) {
              vendor.VendorCosts.filter(a => a.CostOptionId == co.id)[0].IsInvited = vendor.VendorCosts.filter(a => a.CostOptionId == co.id)[0].IsInvited ? co.Selected ? true : false : false;
              CostingOptionData.push(vendor.VendorCosts.filter(a => a.CostOptionId == co.id)[0])
            }
            else
              if (!SelectedCostOptionIds.includes(co.id)) {
                var data = {};
                data.History = [];
                data.CostOptionId = co.id;
                data.CostingsData = [];
                data.IsInvited = co.Selected ? true : false;
                data.IsSelected = false;
                data.SelectedDate = "";
                data.IsEditable = vendor.label == "TCS" || vendor.label == "Internal" ? false : true;
                data.AchivableSamples = 0;
                data.Comments = "";
                data.SampleDistributionDetailsId = null;
                CostingOptionData.push(data);
              }
          })
          vendor.VendorCosts = _.cloneDeep(CostingOptionData)

        }

      })
    }

    const newProposal = update(project.CostingProfiles[index], {
      CountrySpecs: {
        [props.indexCountry]: {
          MethodologySpecs: {
            [props.indexMethodology]: {
              InvitedVendors: { $set: tempvendor },
            },
          },
        },
      },
    });

    project.CostingProfiles[index] = newProposal;

    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
  }, []);

  useEffect(() => {
    if (props.proposal && !props.proposal.id) {
    } else {
      setProposalData(props.proposal);
    }
  }, [props.proposal]);

  function setList() {
    if (vendorAndList && vendorAndList?.length > 0) {
      initialstateQuant = vendorAndList
        .filter((obj) => obj.Group == "fieldexecutionandsudtable")[0]
        .List.filter((v) => v.Name == "initailstatequant")[0]?.Values;
      initialstateQual = vendorAndList
        .filter((obj) => obj.Group == "fieldexecutionandsudtable")[0]
        .List.filter((v) => v.Name == "initailstatequal")[0]?.Values;
      initailstateOnline = vendorAndList
        .filter((obj) => obj.Group == "fieldexecutionandsudtable")[0]
        .List.filter((v) => v.Name == "initailstateonline")[0]?.Values;
      let typeofquant = codeLabels?.SubMethodologyOptions?.filter(
        (sm) => sm.ResearchType == "Quantitative"
      )?.map((c) => c.Code);
      setinitailstate(
        props.sm.Label == "Online Self Completion Survey"
          ? initailstateOnline
          : typeofquant.find((a) => a == props.sm.Code)
            ? initialstateQuant
            : initialstateQual
      );
      var Selectedinitialstate = props.sm.label == "Online Self Completion Survey"
        ? initailstateOnline
        : typeofquant.find((a) => a == props.sm.Code)
          ? initialstateQuant
          : initialstateQual
    }
  }

  useEffect(() => {
    if (props.msg) alert.show(props.msg);
  }, [props.msg]);

  const vendorAndList = useSelector(
    ({ fetchVendorAndList }) => fetchVendorAndList.vendorAndList
  );

  useEffect(() => {
    if (vendorAndList && vendorAndList?.length > 0) {
      setList();
    }
  }, [vendorAndList]);

  useEffect(() => {
    if (props?.sm?.InvitedVendors?.length > 0 && onlinemethcode.ONLINE_METHODOLOGIES_CODE.includes(props.sm.Code) &&
      (_.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0).length > 0 &&
        _.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0)[0]?.MethodologySpecs.filter(z => z.id != props.sm.id).length > 0 &&
        _.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0)[0]?.MethodologySpecs.filter(z => z.Code == props.sm.Code).length > 0

      )
    ) {
      if (props?.sm?.BiddingDetail?.IsFullService) {
        var vendor =
          props.Vendor &&
            props.sm.InvitedVendors &&
            props.sm.InvitedVendors.length > 0
            ? props.Vendor.filter(
              (v) => props.sm.InvitedVendors.some((s) => s.VendorId == v.value)
            )
            : [];

        vendor = vendor?.filter(
          (a) =>
            a.Countries?.filter(
              (b) =>
              ((b.Code ==
                project.CostingProfiles[costingProfileIndex]
                  ?.CountrySpecs[props.indexCountry]?.CountryCode)
                ||
                (b.Code == project.CommissioningCountry && (!iscomcountryinvbm))
              )
            )?.length > 0
        )
          .filter(
            (v) =>
              v.Methodology?.filter((s) => s.Code == props.sm.Code)
                ?.length > 0
          ).filter(a => a.label != "TCS" && a.label != "Internal")

        props.sm.InvitedVendors = props.sm.InvitedVendors.length > 0
          ? props.sm.InvitedVendors.filter(
            (v) => vendor.some((s) => v.VendorId == s.value)
          )
          : [];
      }
      else {
        var vendorList =
          props.Vendor &&
            props.sm.InvitedVendors &&
            props.sm.InvitedVendors.length > 0 &&
            props.sm.InvitedVendors.filter(x => x.ServiceType == 0).length > 0
            ? props.Vendor.filter(
              (v) => props.sm.InvitedVendors.filter(x => x.ServiceType == 0).some((s) => s.VendorId == v.value)
            )
            : [];
        var sudvendorList =
          props.Vendor &&
            props.sm.InvitedVendors &&
            props.sm.InvitedVendors.length > 0 &&
            props.sm.InvitedVendors.filter(x => x.ServiceType == 1).length > 0
            ? props.Vendor.filter(
              (v) => props.sm.InvitedVendors.filter(x => x.ServiceType == 1).some((s) => s.VendorId == v.value)
            )
            : [];
        vendorList = vendorList?.filter(
          (a) =>
            a.Countries?.filter(
              (b) =>
              ((b.Code ==
                project.CostingProfiles[costingProfileIndex]
                  ?.CountrySpecs[props.indexCountry]?.CountryCode)
                ||
                (b.Code == project.CommissioningCountry && (!iscomcountryinvbm))
              )
            )?.length > 0
        )
          .filter(
            (v) =>
              v.Methodology?.filter((s) => s.Code == props.sm.Code)
                ?.length > 0
          )
          .filter(
            (vt) =>
              vt.VendorType?.filter((ct) => ct.Code == "Field Costs" || ct.Code == "Online (data collection)")
                ?.length > 0
          )?.filter(a => a.label != "TCS" && a.label != "Internal")

        sudvendorList = sudvendorList?.filter(
          (a) =>
            a.Countries?.filter(
              (b) =>
              ((b.Code ==
                project.CostingProfiles[costingProfileIndex]
                  ?.CountrySpecs[props.indexCountry]?.CountryCode)
                ||
                (b.Code == project.CommissioningCountry && (!iscomcountryinvbm))
              )
            )?.length > 0
        )
          .filter(
            (v) =>
              v.Methodology?.filter((s) => s.Code == props.sm.Code)
                ?.length > 0
          )

        var IVendors = props.sm.InvitedVendors.length > 0
          ? props.sm.InvitedVendors.filter(
            (v) => vendorList.some((s) => v.VendorId == s.value)
          )
          : [];

        var SUDVendor = props.sm.InvitedVendors.length > 0
          ? props.sm.InvitedVendors.filter(
            (v) => sudvendorList.some((s) => v.VendorId == s.value)
          )
          : [];

        if (props.sm.InvitedVendors.length > 0) {
          props.sm.InvitedVendors = [...IVendors, ...SUDVendor]
        }
      }
    }
  }, []);


  function toggleMethodology(tab, value) {
    if (methactiveTabSM !== tab) {
      var i = 0;
      props.sm.InvitedVendors.forEach((v, ind) => {
        if (v.VendorId == value) {
          i = ind;
        }
      });
      setactivevendor(i);
      setmethActiveTabSM(tab);
    }
  }

  function rowselected(e, id, group = "") {
    var tempselected = _.cloneDeep(
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
        props.indexCountry
      ].MethodologySpecs[props.indexMethodology].InvitedVendors[activevendor]
        .selectedrows
    );
    var tempvendor = _.cloneDeep(
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
        props.indexCountry
      ].MethodologySpecs[props.indexMethodology].InvitedVendors[activevendor]
    );
    var tempvendordata = _.cloneDeep(
      project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
        props.indexCountry
      ].MethodologySpecs[props.indexMethodology].InvitedVendors[activevendor]
        .CostingData
    );
    var GroupData;
    if (!group) {
      if (
        tempselected?.filter(
          (tf) =>
            tf.selected &&
            tf.group == tempselected?.filter((tf) => tf.id == id)[0].group &&
            tf.type != "TimingSection" &&
            tf.id != 14
        ).length == 1 &&
        e.target.checked == false &&
        tempselected.filter((tf) => tf.selected && tf.id == id)[0].type !=
        "TimingSection" &&
        tempvendor.ServiceType != 2
      ) {
        toastr.error("Atleast one field needs to be selected");
      } else if (
        tempselected?.filter(
          (tf) =>
            tf.selected && tf.group && tf.type != "TimingSection" && tf.id != 14
        ).length == 1 &&
        e.target.checked == false &&
        tempselected.filter((tf) => tf.selected && tf.id == id)[0].type !=
        "TimingSection" &&
        tempvendor.ServiceType == 2
      ) {
        toastr.error("Atleast one field needs to be selected");
      } else {
        if (tempselected.filter((y) => y.id == id).length == 0) {
          tempselected.push({ id: id, name: e.target.name, selected: true });
        }
        tempselected.forEach((ts) => {
          if (ts.id === id) {
            ts.selected = e.target.checked;
          }
        });

        if (
          tempselected?.filter((a) => a.id == id)[0].type == "TimingSection"
        ) {
          GroupData = tempselected?.filter(
            (a) =>
              a.selected &&
              a.type == "TimingSection" &&
              a.name != "TIMINGS ( # of WORKING DAYS)"
          );

          if (
            tempselected.filter(
              (tf) => tf.name == "TIMINGS ( # of WORKING DAYS)"
            ).length
          ) {
            if (GroupData?.length > 0 && tempselected) {
              tempselected.filter(
                (tf) => tf.name == "TIMINGS ( # of WORKING DAYS)"
              )[0].selected = true;
            } else {
              tempselected.filter(
                (tf) => tf.name == "TIMINGS ( # of WORKING DAYS)"
              )[0].selected = false;
            }
          }
        } else {
          GroupData = tempselected?.filter(
            (a) =>
              a.group == tempselected?.filter((b) => b.id == id)[0].group &&
              a.selected &&
              a.type != "TimingSection" &&
              a.id != 14
          );
          if (GroupData?.length > 0 && tempselected) {
            tempselected.filter(
              (tf) => tf.name == GroupData[0].group
            )[0].selected = true;
          } else {
            tempselected.filter(
              (tf) =>
                tf.name ==
                tempselected?.filter(
                  (a) =>
                    a.group == tempselected?.filter((b) => b.id == id)[0].group
                )[0].group
            )[0].selected = false;
          }
        }
      }
    } else {
      if (
        tempselected.filter(
          (tf) =>
            tf.selected &&
            !tf.group &&
            tf.name != "TIMINGS ( # of WORKING DAYS)"
        ).length == 1 &&
        e.target.checked == false &&
        tempselected.filter((tf) => tf.selected && tf.id == id)[0].type !=
        "TimingSection"
      ) {
        toastr.error("Atleast one service needs to be selected");
      } else {
        if (group == "TIMINGS ( # of WORKING DAYS)") {
          tempselected.forEach((ts) => {
            if (ts.type == "TimingSection") {
              ts.selected = e.target.checked;
            }
          });
        } else {
          tempselected.forEach((ts) => {
            if (
              (ts.id == id || ts.group == group) &&
              ts.type != "TimingSection"
            ) {
              ts.selected = e.target.checked;
            }
          });
        }
      }
    }
    if (!e.target.checked) {
      tempvendordata
        .filter((a) => a.soid == id)
        ?.forEach((x) => {
          x.VendorData = "";
          x.VendorDataUSD = "0.0000";
        });
    }
    const vendorSRows = {
      [activevendor]: {
        selectedrows: { $set: tempselected },
        CostingData: { $set: tempvendordata },
      },
    };
    updateProposal(
      "Vendors",
      props.indexCountry,
      props.indexMethodology,
      project,
      vendorSRows
    );
  }

  function filteredVendors() {
    var vendorList =
      props.Vendor &&
        props.sm.InvitedVendors &&
        props.sm.InvitedVendors.length > 0
        ? props.Vendor.filter(
          (v) => !props.sm.InvitedVendors.some((s) => s.value == v.value)
        )
        : props.Vendor;
    if (props.sm?.BiddingDetail?.IsFullService) {
      vendorList =
        vendorList?.length > 0
          ? vendorList
            ?.filter(
              (a) =>
                a.Countries?.filter(
                  (b) =>
                  ((b.Code ==
                    project.CostingProfiles[costingProfileIndex]
                      ?.CountrySpecs[props.indexCountry]?.CountryCode)
                    ||
                    (b.Code == project.CommissioningCountry && (!iscomcountryinvbm)))
                )?.length > 0
            )
            .filter(
              (v) =>
                v.Methodology?.filter((s) => s.Code == props.sm.Code)
                  ?.length > 0
            ).filter(a => a.label != "TCS" && a.label != "Internal")
            ?.map((a) => {
              return { label: a.label, value: a.value, VendorId: a.value };
            })
          : [];
    } else {
      vendorList =
        vendorList?.length > 0
          ? vendorList
            ?.filter(
              (a) =>
                a.Countries?.filter(
                  (b) =>
                  ((b.Code ==
                    project.CostingProfiles[costingProfileIndex]
                      ?.CountrySpecs[props.indexCountry]?.CountryCode)
                    ||
                    (b.Code == project.CommissioningCountry && (!iscomcountryinvbm)))
                )?.length > 0
            )
            .filter(
              (v) =>
                v.Methodology?.filter((s) => s.Code == props.sm.Code)
                  ?.length > 0
            )
            .filter(
              (vt) =>
                vt.VendorType?.filter((ct) => ct.Code == "Field Costs" || ct.Code == "Online (data collection)")
                  ?.length > 0
            )?.filter(a => a.label != "TCS" && a.label != "Internal")?.map((a) => {
              return { label: a.label, value: a.value, VendorId: a.value };
            })
          : [];
    }
    if (!props.sm?.BiddingDetail?.IsFullService) {
      var list1 = props.sm.InvitedVendors?.filter(a => a.ServiceType == 1 && a.SelectedRows.length > 0)
      return (vendorList?.filter((object1) => {
        return !list1?.some((object2) => {
          return object1.VendorId === object2.VendorId;
        });
      }))
    }
    else
      return vendorList;
  }
  const current1 = new Date()
  function vendorAddPartial(e, tab) {
    var VendorsDatapartial = _.cloneDeep(
      props.sm.InvitedVendors.filter((a) => a.ServiceType == 1)
    );

    var add = true;
    var remVendor;
    var currentSudVendor = VendorsDatapartial.filter((a) =>
      a.ServiceLists.includes(tab)
    );
    //    var arrivedVendor = e.filter(a => a.ServiceList.includes(tab))
    if (e?.length < currentSudVendor?.length) {
      add = false;
      remVendor = _.cloneDeep(currentSudVendor?.filter((object1) => {
        return !e?.some((object2) => {
          return object1.VendorId === object2.VendorId;
        });
      }))
      VendorsDatapartial.filter(
        (a) => a.VendorId == remVendor.filter(a => !a.IsDeleted)[0].VendorId
      )[0].ServiceLists = remVendor.filter(a => !a.IsDeleted)[0].ServiceLists.filter((a) => a != tab);
      VendorsDatapartial.filter(
        (a) => a.VendorId == remVendor.filter(a => !a.IsDeleted)[0].VendorId
      )[0].SelectedRows = remVendor.filter(a => !a.IsDeleted)[0].SelectedRows.filter(
        (a) => a.group != tab && a.header != tab
      );
      VendorsDatapartial.filter(
        (a) => a.VendorId == remVendor.filter(a => !a.IsDeleted)[0].VendorId
      )[0].VendorCosts.forEach(vc => {
        vc.CostingsData = vc.CostingsData.filter(cd => cd.group != tab)
      })


      if (VendorsDatapartial.filter(
        (a) => a.VendorId == remVendor.filter(a => !a.IsDeleted)[0].VendorId
      )[0].ServiceLists.length == 0) {
        VendorsDatapartial.forEach(
          (a) => {
            if (a.VendorId == remVendor.filter(a => !a.IsDeleted)[0].VendorId) {
              if (!a.IsCostSubmitted)
                a.IsDeleted = true
              else
                toastr.error("Vendor has submitted the cost so we are not able to delete the vendor")
            }
          }
        )
      }
    }
    if (!e) {
      add = false;
      VendorsDatapartial.filter((a) =>
        a.ServiceLists.includes(tab)
      )?.forEach(a => {
        a.SelectedRows = a.SelectedRows.filter(
          (a) => a.group != tab && a.header != tab
        )
        a.ServiceLists = a.ServiceLists.filter((a) => a != tab)
        a.VendorCosts.forEach(vc => {
          vc.CostingsData = vc.CostingsData.filter(cd => cd.group != tab)
        })
      })
      VendorsDatapartial.filter((a) =>
        a.ServiceLists.includes(tab)
      )?.forEach(a => {
        if (a.ServiceLists.length == 0) {
          VendorsDatapartial.forEach(
            (a) => {
              if (a.VendorId == remVendor.filter(a => !a.IsDeleted)[0].VendorId) {
                if (!a.IsCostSubmitted)
                  a.IsDeleted = true
                else
                  toastr.error("Vendor has submitted the cost so we are not able to delete the vendor")
              }
            }
          )
        }
      }
      )
    }
    if (add) {
      if (VendorsDatapartial.filter((a) =>
        a.ServiceLists.includes(tab)
      ).filter(a => a.label == "TCS" || a.label == "Internal").length > 0) {
        toastr.error("Already TCS/Internal vendor is Selected so we are not able to add other vendor")
      }
      else {
        e?.forEach((vendor) => {
          if (
            VendorsDatapartial.filter((a) => a.VendorId == vendor.VendorId)
              .length > 0
          ) {
            if (
              !VendorsDatapartial.filter(
                (a) => a.VendorId == vendor.VendorId
              )[0].ServiceLists.includes(tab)
            ) {
              VendorsDatapartial.filter(
                (a) => a.VendorId == vendor.VendorId
              )[0].IsDeleted = false
              VendorsDatapartial.filter(
                (a) => a.VendorId == vendor.VendorId
              )[0].ServiceLists.push(tab);
              VendorsDatapartial.filter(
                (a) => a.VendorId == vendor.VendorId
              )[0].SelectedRows = [
                ...VendorsDatapartial.filter(
                  (a) => a.VendorId == vendor.VendorId
                )[0].SelectedRows,
                ...initailstate
                  .filter((e) => e.group == tab || e.header == tab)
                  .map((u) => {
                    return {
                      id: u.id,
                      name: u.name,
                      selected: true,
                      group: u.group,
                      type: u.type,
                      key: u.key,
                      header: u.header ? u.header : null
                    };
                  }),
              ].flat();
            }
          } else {
            var Additional = {};
            var CostingOptionData = [];
            Additional.Comments = "";
            Additional.SelectedDate = current1;
            Additional.IsDeleted = false;
            Additional.DeclineInfo = null;
            Additional.IsAcknowledged = false;
            Additional.IsCostSubmitted = false;
            Additional.IsDeclined = false;
            Additional.IsMailSent = false;
            Additional.MethodologySpecId = props.sm.id;
            Additional.ServiceLists = [tab];
            Additional.ServiceType = 1;
            Additional.UploadOtherRequiredDocuments = null;
            Additional.UploadedDocsByVendor = null;
            props.sm.CostingOptions.forEach((a) => {
              var data = {};
              data.History = [];
              data.CostOptionId = a.id;
              data.CostingsData = [];
              data.IsInvited = a.Selected ? true : false;
              data.IsSelected = false;
              data.SelectedDate = "";
              data.IsEditable = vendor.label == "TCS" || vendor.label == "Internal" ? false : true;
              data.AchivableSamples = 0;
              data.Comments = "";
              data.SampleDistributionDetailsId = null;
              CostingOptionData.push(data);
            });
            Additional.SelectedRows = initailstate
              .filter((e) => e.group == tab || e.header == tab)
              .map((u) => {
                return {
                  id: u.id,
                  name: u.name,
                  selected: true,
                  group: u.group,
                  type: u.type,
                  key: u.key,
                  header: u.header ? u.header : null
                };
              });
            Additional.VendorCosts = CostingOptionData;
            Additional.VendorId = vendor.VendorId;
            Additional.label = vendor.label;
            Additional.VendorType = (vendor.label == "TCS" || vendor.label == "Internal") ? "Internal" : "External";
            VendorsDatapartial.push(Additional);
          }
        });
        if (e.filter(a => a.label == "TCS" || a.label == "Internal").length > 0) {
          VendorsDatapartial.filter((a) =>
            (a.ServiceLists.includes(tab) && a.label != "TCS" && a.label != "Internal")
          )?.forEach(a => {
            a.SelectedRows = a.SelectedRows.filter(
              (a) => a.group != tab && a.header != tab
            )
            a.ServiceLists = a.ServiceLists.filter((a) => a != tab)
          })
        }
      }
    }
    var filterdata = props.sm.InvitedVendors.filter((a) => a.ServiceType != 1);
    var mergeVendors = [...filterdata, ...VendorsDatapartial].flat();
    updateProposal(
      "Vendors",
      props.indexCountry,
      props.indexMethodology,
      project,
      mergeVendors
    );
    if (
      onlinemethcode.ONLINE_METHODOLOGIES_CODE.includes(props.sm.Code) &&
      (
        (_.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0).length > 0 &&
          _.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0)[0]?.MethodologySpecs.filter(z => z.id == props.sm.id).length > 0
        )
      )
    ) {
      var MData = _.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0).map(y => y.MethodologySpecs.filter(z => z.Code == props.sm.Code))
      if (MData.length > 0) {
        var OnlineMethData = [];
        var i = 0;
        MData.forEach(x => x.forEach(y => OnlineMethData.push({ CountrySpecId: y.CountrySpecId, MethodologySpecId: y.id })))
        if (OnlineMethData.length > 0) {
          OnlineMethData.forEach(f => {
            if (i > 0)
              addsudvendorfconlinemeth(e, f, tab)
            i++;
          })
        }
      }
    }
  }
  function addsudvendorfconlinemeth(e, onlinedata, tab) {
    var indexCountry = _.last(project.CostingProfiles)?.CountrySpecs.findIndex(x => x.id == onlinedata.CountrySpecId)
    var indexMethodology = _.last(project.CostingProfiles)?.CountrySpecs.filter(x => x.id == onlinedata.CountrySpecId)[0].MethodologySpecs.findIndex(y => y.id == onlinedata.MethodologySpecId)
    var mdata = _.cloneDeep(_.last(project.CostingProfiles)?.CountrySpecs.filter(x => x.id == onlinedata.CountrySpecId)[0]?.MethodologySpecs.filter(y => y.id == onlinedata.MethodologySpecId)[0])
    var VendorsDatapartial = _.cloneDeep(mdata.InvitedVendors.filter((a) => a.ServiceType == 1));
    var VendorsData = _.cloneDeep(mdata.InvitedVendors.filter((a) => a.ServiceType != 1));
    var add = true;
    var currentSudVendor = VendorsDatapartial.filter((a) =>
      a.ServiceLists.includes(tab)
    );
    //    var arrivedVendor = e.filter(a => a.ServiceList.includes(tab))
    if (e?.length < currentSudVendor?.length) {
      add = false;
    }
    if (!e) {
      add = false;
    }
    if (add) {
      if (VendorsDatapartial.filter((a) =>
        a.ServiceLists.includes(tab)
      ).filter(a => a.label == "TCS" || a.label == "Internal").length > 0) {

      }
      else {
        e?.forEach((vendor) => {
          if (!VendorsData.filter((a) => a.VendorId == vendor.VendorId).length > 0) {

            if (VendorsDatapartial.filter((a) => a.VendorId == vendor.VendorId).length > 0) {
              if (!VendorsDatapartial.filter((a) => a.VendorId == vendor.VendorId)[0].ServiceLists.includes(tab)) {
                VendorsDatapartial.filter(
                  (a) => a.VendorId == vendor.VendorId
                )[0].IsDeleted = false
                VendorsDatapartial.filter(
                  (a) => a.VendorId == vendor.VendorId
                )[0].ServiceLists.push(tab);
                VendorsDatapartial.filter(
                  (a) => a.VendorId == vendor.VendorId
                )[0].SelectedRows = [
                  ...VendorsDatapartial.filter(
                    (a) => a.VendorId == vendor.VendorId
                  )[0].SelectedRows,
                  ...initailstate
                    .filter((e) => e.group == tab || e.header == tab)
                    .map((u) => {
                      return {
                        id: u.id,
                        name: u.name,
                        selected: true,
                        group: u.group,
                        type: u.type,
                        key: u.key,
                        header: u.header ? u.header : null
                      };
                    }),
                ].flat();
              }
            } else {
              var Additional = {};
              var CostingOptionData = [];
              Additional.Comments = "";
              Additional.SelectedDate = current1;
              Additional.IsDeleted = false;
              Additional.DeclineInfo = null;
              Additional.IsAcknowledged = false;
              Additional.IsCostSubmitted = false;
              Additional.IsDeclined = false;
              Additional.IsMailSent = false;
              Additional.MethodologySpecId = mdata.id;
              Additional.ServiceLists = [tab];
              Additional.ServiceType = 1;
              Additional.UploadOtherRequiredDocuments = null;
              Additional.UploadedDocsByVendor = null;
              mdata.CostingOptions.forEach((a) => {
                var data = {};
                data.History = [];
                data.CostOptionId = a.id;
                data.CostingsData = [];
                data.IsInvited = a.Selected ? true : false;
                data.IsSelected = false;
                data.SelectedDate = "";
                data.IsEditable = vendor.label == "TCS" || vendor.label == "Internal" ? false : true;
                data.AchivableSamples = 0;
                data.Comments = "";
                data.SampleDistributionDetailsId = null;
                CostingOptionData.push(data);
              });
              Additional.SelectedRows = initailstate
                .filter((e) => e.group == tab || e.header == tab)
                .map((u) => {
                  return {
                    id: u.id,
                    name: u.name,
                    selected: true,
                    group: u.group,
                    type: u.type,
                    key: u.key,
                    header: u.header ? u.header : null
                  };
                });
              Additional.VendorCosts = CostingOptionData;
              Additional.VendorId = vendor.VendorId;
              Additional.label = vendor.label;
              Additional.VendorType = (vendor.label == "TCS" || vendor.label == "Internal") ? "Internal" : "External";
              VendorsDatapartial.push(Additional);
            }
          }
        });
        if (e.filter(a => a.label == "TCS" || a.label == "Internal").length > 0) {
          VendorsDatapartial.filter((a) =>
            (a.ServiceLists.includes(tab) && a.label != "TCS" && a.label != "Internal")
          )?.forEach(a => {
            a.SelectedRows = a.SelectedRows.filter(
              (a) => a.group != tab && a.header != tab
            )
            a.ServiceLists = a.ServiceLists.filter((a) => a != tab)
          })
        }
      }
    }
    var filterdata = mdata.InvitedVendors.filter((a) => a.ServiceType != 1);
    var sudvendorList =
      props.Vendor &&
        VendorsDatapartial &&
        VendorsDatapartial.length > 0 &&
        VendorsDatapartial.filter(x => x.ServiceType == 1).length > 0
        ? props.Vendor.filter(
          (v) => VendorsDatapartial.filter(x => x.ServiceType == 1).some((s) => s.VendorId == v.value)
        )
        : [];
    sudvendorList = sudvendorList?.filter(
      (a) =>
        a.Countries?.filter(
          (b) =>
          ((b.Code ==
            project.CostingProfiles[costingProfileIndex]
              ?.CountrySpecs[indexCountry]?.CountryCode)
            ||
            (b.Code == project.CommissioningCountry && (!iscomcountryinvbm))
          )
        )?.length > 0
    )
      .filter(
        (v) =>
          v.Methodology?.filter((s) => s.Code == props.sm.Code)
            ?.length > 0
      )

    var SUDVendor = VendorsDatapartial.length > 0
      ? VendorsDatapartial.filter(
        (v) => sudvendorList.some((s) => v.VendorId == s.value)
      )
      : [];


    var mergeVendors = [...filterdata, ...SUDVendor].flat();
    updateProposal(
      "Vendors",
      indexCountry,
      indexMethodology,
      project,
      mergeVendors
    );
  }
  function vendoradd(e) {
    var VendorsData = _.cloneDeep(
      props.sm.InvitedVendors?.filter((a) => a.ServiceType != 1)
    );
    var newVendor = true;
    var removedvendor;
    if (props.sm?.BiddingDetail?.IsFullService) {
      if (e?.length < VendorsData.filter(a => !a.IsDeleted)?.length) {
        newVendor = false;
        removedvendor = _.cloneDeep(VendorsData?.filter((object1) => {
          return !e?.some((object2) => {
            return object1.VendorId === object2.VendorId;
          });
        }))
        VendorsData.forEach(
          (a) => {
            if (a.VendorId == removedvendor.filter(a => !a.IsDeleted)[0].VendorId) {
              if (a?.VendorCosts.filter(x => x?.IsSelected == true && props?.sm?.CostingOptions?.filter(z => z.Selected)?.filter(y => y.id == x.CostOptionId)?.length > 0)?.length > 0) {
                toastr.error("Vendor has been selected for the costing so we are not able to delete the vendor")
              }
              else
                if (!a.IsCostSubmitted)
                  a.IsDeleted = true
                else
                  toastr.error("Vendor has submitted the cost so we are not able to delete the vendor")
            }
          }
        );
      }
    } else {
      if (e?.length < VendorsData?.filter(a => !a.IsDeleted)?.length) {
        newVendor = false;
        removedvendor = _.cloneDeep(VendorsData?.filter((object1) => {
          return !e?.some((object2) => {
            return (object1.VendorId === object2.VendorId);
          });
        }))
        VendorsData.forEach(
          (a) => {
            if (a.VendorId == removedvendor.filter(a => !a.IsDeleted)[0].VendorId) {
              if (a?.VendorCosts.filter(x => x?.IsSelected == true && props.sm.CostingOptions.filter(z => z.Selected).filter(y => y.id == x.CostOptionId)?.length > 0)?.length > 0) {
                toastr.error("Vendor has been selected for the costing so we are not able to delete the vendor")
              }
              else
                if (!a.IsCostSubmitted)
                  a.IsDeleted = true
                else
                  toastr.error("Vendor has submitted the cost so we are not able to delete the vendor")
            }
          }
        );
      }
    }
    if (!e) {
      newVendor = false;
      VendorsData.forEach(
        (a) => {
          {
            if (a?.VendorCosts.filter(x => x?.IsSelected == true && props.sm.CostingOptions.filter(z => z.Selected).filter(y => y.id == x.CostOptionId)?.length > 0)?.length > 0) {
              toastr.error("Vendor has been selected for the costing so we are not able to delete the vendor")
            }
            else
              if (!a.IsCostSubmitted)
                a.IsDeleted = true
              else
                toastr.error("Vendor has submitted the cost so we are not able to delete the vendor")
          }
        }
      );
    }
    if (newVendor) {
      e?.forEach((vendor) => {
        if (VendorsData.filter(a => a.VendorId == vendor.VendorId).length > 0) {
          if (VendorsData.filter(a => a.VendorId == vendor.VendorId)[0]?.IsDeleted) {
            VendorsData.filter(a => a.VendorId == vendor.VendorId)[0].IsDeleted = false
            props.sm.CostingOptions.forEach((f) => {
              VendorsData.filter(a => a.VendorId == vendor.VendorId)[0].VendorCosts.filter(x => x.CostOptionId == f.id)[0].RoundId =
                (_.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == props.sm.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == f.id)[0]?.RemainingSamples &&
                  _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == props.sm.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == f.id)[0]?.RemainingSamples != 0) ? (
                  _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == props.sm.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == f.id)[0]?.SampleDistributionDetails?.length == 0 ?
                    _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == props.sm.id)).id : null) : null
            })
          }
        }
        else {
          var Additional = {};
          var CostingOptionData = [];
          var data
          Additional.Comments = "";
          Additional.IsDeleted = false;
          Additional.DeclineInfo = null;
          Additional.SelectedDate = current1;
          Additional.IsAcknowledged = false;
          Additional.IsCostSubmitted = false;
          Additional.IsDeclined = false;
          Additional.IsMailSent = false;
          Additional.MethodologySpecId = props.sm.id;
          Additional.ServiceLists = props.sm.BiddingDetail?.IsFullService
            ? ["Full Service"]
            : ["Field Cost"];
          Additional.ServiceType = props.sm.BiddingDetail?.IsFullService ? 2 : 0;
          Additional.UploadOtherRequiredDocuments = null;
          Additional.UploadedDocsByVendor = null;
          props.sm.CostingOptions.forEach((a) => {
            data = {};
            data.History = [];
            data.CostOptionId = a.id;
            data.CostingsData = [];
            data.IsInvited = a.Selected ? true : false;
            data.IsSelected = false;
            data.SelectedDate = "";
            data.IsEditable = true;
            data.Comments = "";
            data.AchivableSamples = 0;
            data.SampleDistributionDetailsId = null;
            data.RoundId =
              (_.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == props.sm.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == a.id)[0]?.RemainingSamples &&
                _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == props.sm.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == a.id)[0]?.RemainingSamples != 0) ? (
                _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == props.sm.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == a.id)[0]?.SampleDistributionDetails?.length == 0 ?
                  _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == props.sm.id)).id : null) : null

            CostingOptionData.push(data);
          });
          Additional.SelectedRows = returnSelectedRows()
          Additional.VendorCosts = _.cloneDeep(CostingOptionData);
          Additional.VendorId = vendor.VendorId;
          Additional.label = vendor.label;
          Additional.VendorType = "External";
          VendorsData.push(Additional);
        }
      })
    }
    var filterdata = props.sm.InvitedVendors.filter((a) => a.ServiceType == 1);
    var mergeVendors = [...filterdata, ...VendorsData].flat();
    updateProposal(
      "Vendors",
      props.indexCountry,
      props.indexMethodology,
      project,
      mergeVendors
    );
    if (
      onlinemethcode.ONLINE_METHODOLOGIES_CODE.includes(props.sm.Code) &&
      (
        (_.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0).length > 0 &&
          _.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0)[0]?.MethodologySpecs.filter(z => z.id == props.sm.id).length > 0
        )
      )
    ) {
      var MData = _.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0).map(y => y.MethodologySpecs.filter(z => z.Code == props.sm.Code))
      if (MData.length > 0) {
        var OnlineMethData = [];
        var i = 0;
        MData.forEach(x => x.forEach(y => OnlineMethData.push({ CountrySpecId: y.CountrySpecId, MethodologySpecId: y.id })))
        if (OnlineMethData.length > 0) {
          OnlineMethData.forEach(f => {
            if (i > 0)
              addvendorfconlinemeth(e, f)
            i++
          })
        }
      }
    }
  }
  function addvendorfconlinemeth(e, onlinedata) {
    var indexCountry = _.last(project.CostingProfiles)?.CountrySpecs.findIndex(x => x.id == onlinedata.CountrySpecId)
    var indexMethodology = _.last(project.CostingProfiles)?.CountrySpecs.filter(x => x.id == onlinedata.CountrySpecId)[0].MethodologySpecs.findIndex(y => y.id == onlinedata.MethodologySpecId)
    var mdata = _.cloneDeep(_.last(project.CostingProfiles)?.CountrySpecs.filter(x => x.id == onlinedata.CountrySpecId)[0]?.MethodologySpecs.filter(y => y.id == onlinedata.MethodologySpecId)[0])
    var SUDVendorsData = _.cloneDeep(mdata.InvitedVendors?.filter((a) => a.ServiceType == 1))
    var VendorsData = _.cloneDeep(mdata.InvitedVendors?.filter((a) => a.ServiceType != 1));
    var newVendor = true;
    if (mdata?.BiddingDetail?.IsFullService) {
      if (e?.length < VendorsData.filter(a => !a.IsDeleted)?.length) {
        newVendor = false;
      }
    } else {
      if (e?.length < VendorsData?.filter(a => !a.IsDeleted)?.length) {
        newVendor = false;
      }
    }
    if (!e) {
      newVendor = false;
    }
    if (newVendor) {
      e?.forEach((vendor) => {
        if (!SUDVendorsData?.filter(a => a.VendorId == vendor.VendorId).length > 0) {

          if (VendorsData?.filter(a => a.VendorId == vendor.VendorId).length > 0) {
            if (VendorsData.filter(a => a.VendorId == vendor.VendorId)[0]?.IsDeleted) {
              VendorsData.filter(a => a.VendorId == vendor.VendorId)[0].IsDeleted = false
              mdata.CostingOptions.forEach((f) => {
                VendorsData.filter(a => a.VendorId == vendor.VendorId)[0].VendorCosts.filter(x => x.CostOptionId == f.id)[0].RoundId =
                  (_.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == mdata.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == f.id)[0]?.RemainingSamples &&
                    _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == mdata.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == f.id)[0]?.RemainingSamples != 0) ? (
                    _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == mdata.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == f.id)[0]?.SampleDistributionDetails?.length == 0 ?
                      _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == mdata.id)).id : null) : null

              })
            }
          }
          else {
            var Additional = {};
            var CostingOptionData = [];
            var data
            Additional.Comments = "";
            Additional.IsDeleted = false;
            Additional.DeclineInfo = null;
            Additional.SelectedDate = current1;
            Additional.IsAcknowledged = false;
            Additional.IsCostSubmitted = false;
            Additional.IsDeclined = false;
            Additional.IsMailSent = false;
            Additional.MethodologySpecId = mdata.id;
            Additional.ServiceLists = mdata.BiddingDetail?.IsFullService
              ? ["Full Service"]
              : ["Field Cost"];
            Additional.ServiceType = mdata.BiddingDetail?.IsFullService ? 2 : 0;
            Additional.UploadOtherRequiredDocuments = null;
            Additional.UploadedDocsByVendor = null;
            mdata.CostingOptions.forEach((a) => {
              data = {};
              data.History = [];
              data.CostOptionId = a.id;
              data.CostingsData = [];
              data.IsInvited = a.Selected ? true : false;
              data.IsSelected = false;
              data.SelectedDate = "";
              data.IsEditable = true;
              data.Comments = "";
              data.AchivableSamples = 0;
              data.SampleDistributionDetailsId = null;
              data.RoundId =
                (_.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == mdata.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == a.id)[0]?.RemainingSamples &&
                  _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == mdata.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == a.id)[0]?.RemainingSamples != 0) ? (
                  _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == mdata.id))?.SampleDistributionMasters?.filter(c => c.CostingOptionId == a.id)[0]?.SampleDistributionDetails?.length == 0 ?
                    _.last(splitdata.filter(s => s.id)?.filter(m => m.MethodologySpecId == mdata.id)).id : null) : null
              CostingOptionData.push(data);
            });
            Additional.SelectedRows = mdata.BiddingDetail?.IsFullService
              ? initailstate.map((u) => {
                return {
                  id: u.id,
                  name: u.name,
                  selected: true,
                  group: u.group,
                  type: u.type,
                  key: u.key,
                  header: u.header ? u.header : null
                };
              })
              : initailstate
                .filter((e) => e.group == "Field Cost" || e.name == "Field Cost")
                .map((u) => {
                  return {
                    id: u.id,
                    name: u.name,
                    selected: true,
                    group: u.group,
                    type: u.type,
                    key: u.key,
                    header: u.header ? u.header : null
                  };
                });
            Additional.VendorCosts = _.cloneDeep(CostingOptionData);
            Additional.VendorId = vendor.VendorId;
            Additional.label = vendor.label;
            Additional.VendorType = "External";
            VendorsData.push(Additional);
          }
        }
      })

    }
    var filterdata = mdata.InvitedVendors.filter((a) => a.ServiceType == 1);
    if (props?.sm?.BiddingDetail?.IsFullService) {
      var vendor =
        props.Vendor &&
          VendorsData &&
          VendorsData.length > 0
          ? props.Vendor.filter(
            (v) => VendorsData.some((s) => s.VendorId == v.value)
          )
          : [];

      vendor = vendor?.filter(
        (a) =>
          a.Countries?.filter(
            (b) =>
            ((b.Code ==
              project.CostingProfiles[costingProfileIndex]
                ?.CountrySpecs[indexCountry]?.CountryCode)
              ||
              (b.Code == project.CommissioningCountry && (!iscomcountryinvbm))
            )
          )?.length > 0
      )
        .filter(
          (v) =>
            v.Methodology?.filter((s) => s.Code == props.sm.Code)
              ?.length > 0
        ).filter(a => a.label != "TCS" && a.label != "Internal")

      VendorsData = VendorsData.length > 0
        ? VendorsData.filter(
          (v) => vendor.some((s) => v.VendorId == s.value)
        )
        : [];
    }
    else {
      var vendorList =
        props.Vendor &&
          VendorsData &&
          VendorsData.length > 0 &&
          VendorsData.filter(x => x.ServiceType == 0).length > 0
          ? props.Vendor.filter(
            (v) => VendorsData.filter(x => x.ServiceType == 0).some((s) => s.VendorId == v.value)
          )
          : [];
      vendorList = vendorList?.filter(
        (a) =>
          a.Countries?.filter(
            (b) =>
            ((b.Code ==
              project.CostingProfiles[costingProfileIndex]
                ?.CountrySpecs[indexCountry]?.CountryCode)
              ||
              (b.Code == project.CommissioningCountry && (!iscomcountryinvbm))
            )
          )?.length > 0
      )
        .filter(
          (v) =>
            v.Methodology?.filter((s) => s.Code == props.sm.Code)
              ?.length > 0
        )
        .filter(
          (vt) =>
            vt.VendorType?.filter((ct) => ct.Code == "Field Costs" || ct.Code == "Online (data collection)")
              ?.length > 0
        )?.filter(a => a.label != "TCS" && a.label != "Internal")

      var IVendors = VendorsData.length > 0
        ? VendorsData.filter(
          (v) => vendorList.some((s) => v.VendorId == s.value)
        )
        : [];

      VendorsData = IVendors
    }

    var mergeVendors = [...filterdata, ...VendorsData].flat();

    updateProposal(
      "Vendors",
      indexCountry,
      indexMethodology,
      project,
      mergeVendors
    );
  }
  function updateMethodologyInfo(e, type) {
    let dataSet;
    let proposalType;
    let indexM;
    switch (type) {
      case "Comments":
        dataSet = e.target.value;
        proposalType = "VendorComments";
        indexM = props.indexMethodology;
        break;
      case "OtherProjectSpecification":
        dataSet = {
          [props.indexMethodology]: {
            BiddingDetail: {
              OtherProjectSpecification: { $set: e.target.value },
            }
          },
        };
        proposalType = "Methodology";
        indexM = 0;
        break;
      case "QCValidationMode":
        dataSet = {
          [props.indexMethodology]: {
            BiddingDetail: {
              QCValidationMode: { $set: e },
            }
          },
        };
        proposalType = "Methodology";
        indexM = 0;
        break;
      case "Currency":
        dataSet = {
          [props.indexMethodology]: {
            BiddingDetail: {
              Currency: { $set: e },
            }
          },
        };
        proposalType = "Methodology";
        indexM = 0;
        break;
      default:
        break;
    }
    updateProposal(proposalType, props.indexCountry, indexM, project, dataSet);
  }



  var i = 0;

  const [open, setOpen] = useState(false);
  const [sid, setsid] = useState(0);

  function Togglesud(tm) {
    setSudTabs(0);
    setmethActiveTabSM(0);
    setServiceType(tm);
    if (
      props.sm.InvitedVendors &&
      props.sm.InvitedVendors.filter((p) => p.ServiceType == tm).length > 0
    ) {
      var val = props.sm.InvitedVendors.filter((p) => p.ServiceType == tm)[0]
        .VendorId;

      var ind = 0;
      props.sm.InvitedVendors.forEach((d, i) => {
        if (d.VendorId == val) {
          ind = i;
        }
      });
      setactivevendor(ind);
    } else {
      setactivevendor(
        props.sm.InvitedVendors &&
        props.sm.InvitedVendors.filter((p) => p.ServiceType != tm).length
      );
    }
  }

  function returnSudTabs() {
    // initailstate=(props.sm.label=="Online Self Completion Survey"?initailstateOnline:(props.sm.Quant?initialstateQuant:initialstateQual))
    return (<>
      <Col md={12} className={"methodologyTabs"}>
        {
          <Nav tabs>
            {initailstate.length > 0 &&
              returnSudTabsdata()?.map((subM, indexsubM) => (
                <NavItem key={indexsubM}>
                  <NavLink
                    className={classnames({ active: SudTabs === indexsubM })}
                    onClick={() => {
                      if (SudTabs != indexsubM) {
                        setSudTabs(indexsubM);
                        setSudTabsName(subM.header);
                        setmethActiveTabSM(0);

                        props.sm.InvitedVendors &&
                          setactivevendor(
                            props.sm.InvitedVendors.findIndex(
                              (val) =>
                                val.ServiceLists.includes(
                                  initailstate.filter(
                                    (s) => s.header && s.sud
                                  )[indexsubM].header
                                ) && val.ServiceType == 1
                            )
                          );
                        setVendorData(
                          props.Vendor.filter((v) =>
                            props.sm.InvitedVendors
                              ? !props.sm.InvitedVendors.some(
                                (s) =>
                                  s.ServiceType == 0 && s.VendorId == v.value
                              )
                              : props.Vendor
                          )
                        );
                      }
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        top: "-3rem",
                      }}
                    >
                      <div>
                        <b>{subM.header}</b>
                      </div>
                    </div>
                  </NavLink>
                </NavItem>
              ))}
          </Nav>
        }
        <br />
      </Col>

    </>
    );
  }

  function handleWarning(type, flag) {
    let warnFlag = type === "ok" ? true : false;
    setMessageBox(flag);
    isFullServiceRequired(warnFlag, checked);
  }

  function handleHistory(type, flag) {
    setOpen(type === "close" ? flag : true);
  }

  function isFullServiceRequired(remove, check) {
    if (
      onlinemethcode.ONLINE_METHODOLOGIES_CODE.includes(props.sm.Code) &&
      (
        (_.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0).length > 0 &&
          _.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0)[0]?.MethodologySpecs.filter(z => z.id == props.sm.id).length > 0
        )
      )
    ) {
      let vendor = false
      var MData = _.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs).map(y => y.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3))
      if (MData.length > 0) {
        var OnlineMethData = [];
        MData.forEach(x => x.forEach(y => OnlineMethData.push({ CountrySpecId: y.CountrySpecId, MethodologySpecId: y.id })))
        if (OnlineMethData.length > 0) {
          OnlineMethData.forEach(f => {
            var indexCountry = _.last(project.CostingProfiles)?.CountrySpecs.findIndex(x => x.id == f.CountrySpecId)
            var indexMethodology = _.last(project.CostingProfiles)?.CountrySpecs.filter(x => x.id == f.CountrySpecId)[0].MethodologySpecs.findIndex(y => y.id == f.MethodologySpecId)
            {
              var tempproject = _.cloneDeep(
                project.CostingProfiles[costingProfileIndex]?.CountrySpecs[indexCountry].MethodologySpecs);
              if (remove) {
                let flag = check;
                if (tempproject[indexMethodology].BiddingDetail) {
                  tempproject[indexMethodology].BiddingDetail["IsFullService"] = flag;
                  if (!flag) {
                    tempproject[indexMethodology].InvitedVendors?.filter(a => a.ServiceType == 2)?.forEach(v => { v.IsDeleted = true; v.SelectedRows = [] })
                    if (tempproject[indexMethodology].InvitedVendors?.filter(a => a.ServiceType == 2).length > 0)
                      vendor = true
                  }
                  else {
                    tempproject[indexMethodology].InvitedVendors?.filter(a => a.ServiceType == 0)?.forEach(v => { v.IsDeleted = true; v.SelectedRows = [] })
                    tempproject[indexMethodology].InvitedVendors?.filter(a => a.ServiceType == 1)?.forEach(v => {
                      v.IsDeleted = true;
                      v.ServiceLists = [];
                      v.SelectedRows = [];
                    })
                    if (tempproject[indexMethodology].InvitedVendors.filter(a => a.ServiceType != 2).length > 0)
                      vendor = true
                  }
                }
                else {
                  tempproject[indexMethodology]["BiddingDetail"] = {}
                  tempproject[indexMethodology].BiddingDetail["IsFullService"] = flag;
                  tempproject[indexMethodology].InvitedVendors = [];
                }
                setServiceType(!flag);
              }
              setServiceType(0);

              updateProposal("Methodology", indexCountry, 0, project, { $set: tempproject });


            }
          })
          if (vendor) {
            let data = {};
            data["id"] = project.CostingProfiles[project.CostingProfiles.length - 1].id;
            data["CountryName"] = project?.CommissioningCountry;
            data["Bidding"] = true;
            data["Message"] = check ? "Selected Full Service" : "Deselected Full Service";
            data["IsSubmit"] = false;
            data["type"] = "OPS"
            data["Error"] = false
            data["Project"] = project
            data["VendorBidDeadLine"] = _.last(project.CostingProfiles).VendorBidDeadLine
            data["VenodrBidDeadLineStatus"] = _.last(project.CostingProfiles).VenodrBidDeadLineStatus
            data["CountrySpecs"] = project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs
            var uploadfiles = vendorreducer.biddingFiles.filter(x => x.methodologyId != props.sm.id)
            vendorreducer.biddingFiles.filter(x => x.methodologyId == props.sm.id && x.flag == "bidding")?.map(z => uploadfiles.push(z))
            dispatch(
              proposalActions.saveData(
                data,
                false,
                false,
                uploadfiles
              )
            );
          }
        }
      }
    }
    else {
      let vendor = false
      var tempproject = _.cloneDeep(
        project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
          props.indexCountry
        ].MethodologySpecs
      );
      if (remove) {
        let flag = check;
        if (tempproject[props.indexMethodology].BiddingDetail) {
          tempproject[props.indexMethodology].BiddingDetail["IsFullService"] = flag;
          if (!flag) {
            tempproject[props.indexMethodology].InvitedVendors?.filter(a => a.ServiceType == 2)?.forEach(v => { v.IsDeleted = true; v.SelectedRows = [] })
            if (tempproject[props.indexMethodology].InvitedVendors?.filter(a => a.ServiceType == 2).length > 0)
              vendor = true
          }
          else {
            tempproject[props.indexMethodology].InvitedVendors?.filter(a => a.ServiceType == 0)?.forEach(v => { v.IsDeleted = true; v.SelectedRows = [] })
            tempproject[props.indexMethodology].InvitedVendors?.filter(a => a.ServiceType == 1)?.forEach(v => {
              v.IsDeleted = true;
              v.ServiceLists = [];
              v.SelectedRows = [];
            })
            if (tempproject[props.indexMethodology].InvitedVendors.filter(a => a.ServiceType != 2).length > 0)
              vendor = true
          }
        }
        else {
          tempproject[props.indexMethodology]["BiddingDetail"] = {}
          tempproject[props.indexMethodology].BiddingDetail["IsFullService"] = flag;
          tempproject[props.indexMethodology].InvitedVendors = [];
        }
        setServiceType(!flag);
      }
      setServiceType(0);

      updateProposal("Methodology", props.indexCountry, 0, project, { $set: tempproject });

      if (vendor) {
        let data = {};
        data["id"] = project.CostingProfiles[project.CostingProfiles.length - 1].id;
        data["CountryName"] = project?.CommissioningCountry;
        data["Bidding"] = true;
        data["Message"] = check ? "Selected for Full service" : "Deselected for Full service";
        data["IsSubmit"] = false;
        data["type"] = "OPS"
        data["Error"] = false
        data["Project"] = project
        data["VendorBidDeadLine"] = _.last(project.CostingProfiles).VendorBidDeadLine
        data["VenodrBidDeadLineStatus"] = _.last(project.CostingProfiles).VenodrBidDeadLineStatus
        data["CountrySpecs"] = project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs
        var uploadfiles = vendorreducer.biddingFiles.filter(x => x.methodologyId != props.sm.id)
        vendorreducer.biddingFiles.filter(x => x.methodologyId == props.sm.id && x.flag == "bidding")?.map(z => uploadfiles.push(z))
        dispatch(
          proposalActions.saveData(
            data,
            false,
            false,
            uploadfiles
          )
        );
      }
    }
  }
  function returnSudTabsdata() {
    let tabs = initailstate
      .filter((s) => s.header && s.sud == true)

    let tab = props?.sm?.OpResourcesVariables?.filter(
      (op) => op.type == 'single' && op.value == "Yes"
    ).map(a => a.id)
    let tabToRender = tabs.filter(a => (tab?.includes(a.tabid) || a.show))
    return tabToRender
  }
  function updateProposal(type, indCo, indMeth, project, data) {
    let newProposal;
    switch (type) {
      case "Methodology":
        newProposal = update(project.CostingProfiles[costingProfileIndex], {
          CountrySpecs: {
            [indCo]: {
              MethodologySpecs: data
            },
          },
        });
        break;
      case "Vendors":
        newProposal = update(project.CostingProfiles[costingProfileIndex], {
          CountrySpecs: {
            [indCo]: {
              MethodologySpecs: {
                [indMeth]: {
                  InvitedVendors: { $set: data },
                },
              },
            },
          },
        });
        break;
      case "VendorComments":
        newProposal = update(project.CostingProfiles[costingProfileIndex], {
          CountrySpecs: {
            [indCo]: {
              MethodologySpecs: {
                [indMeth]: {
                  InvitedVendors: {
                    [activevendor]: {
                      Comments: { $set: data },
                    },
                  },
                },
              },
            },
          },
        });
        break;
      default:
        break;
    }
    project.CostingProfiles[costingProfileIndex] = newProposal;
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: project,
    });
  }

  return (
    <>
      <Form>
        <Card>
          <CardHeader>
            <CardTitle tag="h5" className="my-2">
              <Row>
                <Col md={11}>
                  <Label>Bidding Details</Label>
                </Col>
                <Col md={1}>
                  <FontAwesomeIcon
                    id="collapseBiddingDetails"
                    icon={faChevronDown}
                    href="#collapseBiddingDetails"
                  />
                </Col>
              </Row>
            </CardTitle>
          </CardHeader>
          <UncontrolledCollapse
            toggler="#collapseBiddingDetails"
            defaultOpen={true}
          >
            <CardBody>
              <Row form>
                <Col md={4}>
                  <FormGroup>
                    <Label>QC Validation Mode</Label>
                    <Select
                      name="QCValidationMode"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={qcApplied()}
                      onChange={(e) => {
                        updateMethodologyInfo(e, "QCValidationMode");
                      }}
                      value={
                        !props.sm?.BiddingDetail?.QCValidationMode
                          ? "no value"
                          : props.sm?.BiddingDetail?.QCValidationMode
                      }
                      isDisabled={
                        ViewOnlyMode
                      }
                      isMulti
                    />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>Currency Selection</Label>
                    <Select
                      name="CurrencySelection"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={
                        project.CostingProfiles[costingProfileIndex]
                          ?.CountrySpecs.length > 0 &&
                          _.head(
                            project.CostingProfiles[costingProfileIndex]
                              ?.ProfileSetting.CurrenciesData
                          )
                          ? currencyDropdown()
                          : []
                      }
                      isDisabled={
                        ViewOnlyMode
                      }
                      onChange={(e) => {
                        updateMethodologyInfo(e, "Currency");
                      }}
                      value={defaultvalueforcurrency()}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Other Project Specification</Label>
                    <textarea
                      className="form-control w-100"
                      style={{ height: "4rem" }}
                      type="text"
                      name="OtherProjectSpecification"
                      onChange={(e) => {
                        updateMethodologyInfo(e, "OtherProjectSpecification");
                      }}
                      disabled={
                        ViewOnlyMode
                      }
                      value={
                        props.sm.BiddingDetail?.OtherProjectSpecification
                          ? props.sm.BiddingDetail?.OtherProjectSpecification
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <BiddingFiles
                    methodology={props.sm}
                    vendorfiles={false}
                    MethId={props.sm.id}
                    index={props.indexMethodology}
                    indexcountry={props.indexCountry}
                    fullservicerequired={props.sm?.BiddingDetail?.IsFullService}
                  ></BiddingFiles>
                </Col>
              </Row>
              <Row></Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        CheckVendorSelection(e);
                      }}
                      checked={props.sm.BiddingDetail?.IsFullService}
                      disabled={
                        ViewOnlyMode || (onlinemethcode.ONLINE_METHODOLOGIES_CODE.includes(props.sm.Code) && (
                          (_.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0).length > 0 &&
                            _.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0)[0]?.MethodologySpecs?.filter(z => z.Code == props.sm.Code).length > 0 &&
                            !_.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0)[0]?.MethodologySpecs.filter(z => z.id == props.sm.id).length > 0 &&
                            _.last(project.CostingProfiles)?.CountrySpecs?.filter(x => x.MethodologySpecs.filter(z => z.Code == props.sm.Code && z.CostingType == 3).length > 0)[0]?.MethodologySpecs?.filter(z => z.Code == props.sm.Code)[0]?.BiddingDetail ? true : false
                          )
                        ))
                      }
                    />
                    <span>{" "}</span>
                    <Label> Full Service Required</Label>
                  </FormGroup>
                </Col>
                <Col md={4} className="button-border">
                  <Button
                    color="primary"
                    onClick={() => setOpenModalSplit(true)}
                  >
                    Manage Bid Rounds / Sample Split
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col md={12} className={"methodologyTabs"}>
                  <Nav tabs>
                    {
                      <NavItem>
                        <NavLink
                          className={classnames({ active: ServiceType == 0 })}
                          onClick={() => {
                            Togglesud(0);
                          }}
                        >
                          <b>
                            {props.sm.BiddingDetail?.IsFullService
                              ? "Full Service"
                              : "Field Cost"}
                          </b>
                        </NavLink>
                      </NavItem>
                    }
                    {!props.sm.BiddingDetail?.IsFullService && (
                      <NavItem>
                        <NavLink
                          className={classnames({ active: ServiceType == 1 })}
                          onClick={() => {
                            Togglesud(1);

                            setSudTabsName(returnSudTabsdata() ? returnSudTabsdata()[0]?.header : "")
                            setVendorData(
                              props.Vendor?.filter(
                                (v) =>
                                  props.sm.InvitedVendors ? !props.sm.InvitedVendors.some(
                                    (s) => s.ServiceType == 0 && s.VendorId == v.value
                                  ) : props.Vendor
                              )
                            )
                          }}
                        >
                          <b>Setup & Delivery Work Only</b>
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                </Col>
              </Row>
              <br />

              {ServiceType == 1 && returnSudTabs()}

              {ServiceType == 0 && (
                <Col md={12}>
                  <FormGroup>
                    <Label>Recommended Vendors</Label>
                    <Select
                      name="Recommended"
                      onChange={(e) =>
                        project?.CostingProfiles[costingProfileIndex]
                          ?.VenodrBidDeadLineStatus == "close"
                          ? toastr.error("Bidding is closed")
                          : vendoradd(e)
                      }
                      className="basic-multi-select"
                      classNamePrefix="react-select"
                      isDisabled={
                        ViewOnlyMode
                      }
                      value={
                        props.sm.InvitedVendors != null
                          ? !props.sm.BiddingDetail?.IsFullService
                            ? props.sm.InvitedVendors.filter(
                              (v) => (v.ServiceType == ServiceType && !v.IsDeleted)
                            ).map((a) => {
                              return {
                                label: a.label,
                                value: a.VendorId,
                                VendorId: a.VendorId,
                              };
                            })
                            : props.sm.BiddingDetail?.IsFullService
                              ? props.sm.InvitedVendors.filter(
                                (v) => (v.ServiceType == 2 && !v.IsDeleted)
                              ).map((a) => {
                                return {
                                  label: a.label,
                                  value: a.VendorId,
                                  VendorId: a.VendorId,
                                };
                              })
                              : props.sm.InvitedVendors.filter(
                                (v) => v.ServiceType == ServiceType
                              ).filter(
                                (t) =>
                                  t.group ==
                                  initailstate.filter((e) => e.header)[SudTabs]
                                    ?.header
                              ).map((a) => {
                                return {
                                  label: a.label,
                                  value: a.VendorId,
                                  VendorId: a.VendorId,
                                };
                              })
                          : null
                      }
                      options={filteredVendors()}
                      isMulti
                    />
                  </FormGroup>
                </Col>
              )}

              {ServiceType == 1 && (
                <Col md={12}>
                  <FormGroup>
                    <Label>Recommended Vendors</Label>
                    <Select
                      name="Recommended"
                      onChange={(e) =>
                        project?.CostingProfiles[costingProfileIndex]
                          ?.VenodrBidDeadLineStatus == "close"
                          ? toastr.error("Bidding is closed")
                          : vendorAddPartial(e, SudTabsName)
                      }
                      className="basic-multi-select"
                      classNamePrefix="react-select"
                      isDisabled={
                        ViewOnlyMode
                      }
                      value={
                        props.sm.InvitedVendors != null
                          ? props.sm.InvitedVendors.filter(
                            (v) =>
                              v.ServiceType == 1 &&
                              v.ServiceLists.includes(SudTabsName)
                          )?.map((a) => {
                            return {
                              label: a.label,
                              value: a.VendorId,
                              VendorId: a.VendorId,
                            };
                          })
                          : null
                      }
                      options={
                        returnList()
                      }
                      isMulti
                    />
                  </FormGroup>
                </Col>
              )}
              {props.sm.InvitedVendors != null &&
                props.sm.InvitedVendors?.length > 0 &&
                (
                  <TabContent activeTab={methactiveTabSM}>
                    <TabPane tabId={methactiveTabSM}>
                      <Col md={12} className={"methodologyTabs"}>
                        {props.sm.InvitedVendors && (
                          <Nav tabs>
                            {(ServiceType == 1
                              ? props.sm.InvitedVendors.filter(
                                (u) => (u.ServiceType == 1 && !u.IsDeleted)
                              ).filter((e) =>
                                e.ServiceLists.includes(SudTabsName)
                              )
                              : ServiceType == 0 &&
                                props.sm.BiddingDetail?.IsFullService
                                ? props.sm.InvitedVendors.filter(
                                  (u) => (u.ServiceType == 2 && !u.IsDeleted)
                                )
                                : props.sm.InvitedVendors.filter(
                                  (u) => (u.ServiceType == 0 && !u.IsDeleted)
                                )
                            ).map((vendor, indxMV) => (
                              <NavItem>
                                <NavLink
                                  style={
                                    vendor.IsDeclined
                                      ? { backgroundColor: "#b94e48" }
                                      : {}
                                  }
                                  className={classnames({
                                    active: methactiveTabSM === indxMV,
                                  })}
                                  onClick={() => {
                                    toggleMethodology(indxMV, vendor.VendorId);
                                  }}
                                >
                                  <b>{vendor.label}</b>
                                </NavLink>
                              </NavItem>
                            ))}
                          </Nav>
                        )}
                        {(ServiceType == 1
                          ? props.sm.InvitedVendors.filter(
                            (u) => (u.ServiceType == 1 && !u.IsDeleted)
                          ).filter((e) =>
                            e.ServiceLists.includes(SudTabsName)
                          )
                          : ServiceType == 0 &&
                            props.sm.BiddingDetail?.IsFullService
                            ? props.sm.InvitedVendors.filter(
                              (u) => (u.ServiceType == 2 && !u.IsDeleted)
                            )
                            : props.sm.InvitedVendors.filter(
                              (u) => (u.ServiceType == 0 && !u.IsDeleted)
                            )
                        ).map(
                          (vendor, indxMV) =>
                            methactiveTabSM === indxMV && (
                              <>
                                {vendor.IsDeclined && (
                                  <Row form style={{ marginTop: "1rem" }}>
                                    <Col md={12}>
                                      <Card
                                        style={{
                                          backgroundColor: "#b94e48",
                                          color: "white",
                                        }}
                                      >
                                        Note: This methodology been declined by the Vendor.
                                      </Card>
                                    </Col>
                                  </Row>
                                )}
                                <Row form className="scrolltable">
                                  <VendorCostingAndTimeline
                                    vendor={vendor}
                                    flag={"allocation"}
                                    mappingState={returnstate(vendor)}
                                    meth={props.sm}
                                    To={
                                      props.sm.BiddingDetail?.IsFullService
                                        ? "FULL SERVICE"
                                        : "PARTIAL"
                                    }
                                    SudTabs={SudTabsName}
                                    ServiceType={ServiceType}
                                    indexCountry={props.indexCountry}
                                    activeVendor={activevendor}
                                    indexMethodology={props.indexMethodology}
                                  ></VendorCostingAndTimeline>
                                </Row>
                                <BiddingFiles
                                  vendor={vendor}
                                  vendorfiles={true}
                                  VendorID={vendor.VendorId}
                                  methodology={props.sm}
                                  MethId={props.sm.id}
                                  index={props.indexMethodology}
                                  indexcountry={props.indexCountry}
                                  fullservicerequired={
                                    props.sm.BiddingDetail?.IsFullService
                                  }
                                ></BiddingFiles>

                                <FormGroup>
                                  <Label>Comments (In General)</Label>
                                  <textarea
                                    className="form-control w-100"
                                    type="text"
                                    onChange={(e) => {
                                      updateMethodologyInfo(e, "Comments");
                                    }}
                                    value={
                                      props.sm.InvitedVendors[activevendor]
                                        ?.Comments
                                    }
                                    disabled={ViewOnlyMode}
                                  />
                                </FormGroup>
                              </>
                            )
                        )}
                      </Col>
                    </TabPane>
                  </TabContent>
                )}
            </CardBody>
          </UncontrolledCollapse>
        </Card>
      </Form>
      {open && (
        <Modal
          isOpen={open}
          toggle={() => setOpen(!open)}
          centered
          className={"modelSelectVendors"}
        >
          <ModalHeader
            toggle={() => {
              setOpen(!open);
            }}
          >
            <span>Change History</span>
          </ModalHeader>
          <ModalBody className="m-3">
            <div style={{ maxHeight: "400px", overflow: "auto" }}>
              <table
                className="table table-bordered "
                border="0.4"
                style={{ position: "relative" }}
              >
                <tr style={{ backgroundColor: "#00F000", color: "black" }}>
                  <th>S.NO</th>
                  <th>Date</th>
                  <th>Costing Option</th>
                  <th>Last Value</th>
                  <th>Changed By</th>{" "}
                </tr>

                {project.CostingProfiles[costingProfileIndex]?.CountrySpecs[
                  props.indexCountry
                ].MethodologySpecs[props.indexMethodology].InvitedVendors[
                  activevendor
                ].CostingData.filter((r) => r.soid == sid)?.map(
                  (s) =>
                    //  {if(props.sm.InvitedVendors[activevendor].selectedCO.filter(a=>a.id==s.coid)[0].selected){
                    s.History?.map((m) => (
                      <tr>
                        <td>{(i = i + 1)}</td>
                        <td>{m.date}</td>
                        <td>
                          {m.coname}
                          {/* {
                          project.CostingProfiles[0].CountrySpecs[
                            props.indexCountry
                          ].MethodologySpecs[props.indexMethodology].InvitedVendors[
                            activevendor
                          ].CostingData.filter(
                            (d) => d.coid == s.coid && d.soid == 14
                          )[0].cLabel
                        } */}
                        </td>
                        <td>{m.lastvalue}</td>
                        <td>{m.changedby}</td>
                      </tr>
                    ))
                  //}}
                )}
              </table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {openModalSplit && (
        //todo:manage sample split 
        <SplitSampleManagement
          SplitSample={openModalSplit}
          setSplitSample={setOpenModalSplit}
          project={project}
          currentMethodology={props.sm}
          methIndex={props.indexMethodology}
          countryIndex={props.indexCountry}
          SplitSampleInfo={props.SplitSampleInfo}
        />
      )
      }
      <HistoryModal
        isOpen={open}
        project={project}
        indexCountry={props.indexCountry}
        indexMethodology={props.indexMethodology}
        activevendor={activevendor}
        sid={sid}
        popupHandler={(type, ev) => handleHistory(type, ev)}
      />

      <WarningModal
        isOpen={messageBox}
        message={alertBox}
        popupHandler={(type, ev) => handleWarning(type, ev)}
      />
    </>
  );
}
export default VendorBiddingForm;