import React, { useState, useEffect } from "react"
import axios from 'axios';
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { connect, useDispatch, useSelector } from 'react-redux';
import Loader from "../../../components/Loader";
import * as vendorActions from '../../../redux/actions/vendor/Actions';

import * as masterActions from '../../../redux/actions/masters/Actions';
import { getLabel } from "../../../utils/codeLabels";
import {
Button,
Card,
CardBody,
CardHeader,
CardTitle,
Col,
Container,
CustomInput,
Form,
FormGroup,
FormText,
Input,
InputGroup,
InputGroupAddon,
Label,
Row
} from "reactstrap";
import { func } from "prop-types";
import { ERANGE } from "constants";
import { Object, Date } from "core-js";
import { Key, Layout, Map } from "react-feather";
import { stat } from "fs";
import { isArray } from "util";
import {
AvForm,
AvField,
AvRadioGroup,
AvInput,
AvRadio
} from "availity-reactstrap-validation";
import { loadavg } from "os";
import 'react-phone-number-input/style.css'

import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
const mapStateToProps = (state, ownProps) => {
    return {
        vendor: state.vendorReducer.vendor,
        loading: state.vendorReducer.Loading,
        error: state.vendorReducer.error,
        msg: state.vendorReducer.msg,
        redux_masters: state.redux_master.masters,
        id: ownProps.match.params.Id,
    }
}

const vendorform1 = [
    { set:true,sectionTilte: "General Information", type: "text", md: 3, label: "SAP ID", name: "SapId", sectionTilte: "General Information", validate: true },
    {set:true, type: "text", name: "VendorName", label: "Vendor Name", md: 3,  validate: true },
    {
        set:true,type: "isMulti", name: "VendorType", label: "Vendor Type", md: 3, group: "vendortypes",  validate: true 
    },
    {set:true, type: "textarea", name: "Address", label: "Address", md: 12, validate: true },
    {set:true, type: "select1", name: "Office", label: "Head Office Location", md: 3, group: "Countries", validate: true },
    {set:true, type: "text", name: "KeyContactPerson", label: "Name Of Key Contact Person", md: 3, validate: true },
    {set:true, type: "select", display: true, md: 3, label: "Designation Key Contact Person", name: "DesignationKeyContact", option: [{ label: "CEO", value: "1"}, { label: "Cheif Officer", value: "2" }, { label: "Owner", value: "3" }, { label: "MD", value: "4" }], map: "designation", display: true, validate: true },
    {set:true, type: "phone", name: "ContactNumberKeyContactPerson", touched: false, label: "Contact Number", md: 3, validate: true },
    { set:true,type: "email", name: "EmailIdKeyContactPerson", label: "Email Id Key Contact Person", md: 3,  validate: true },
    { set:true,type: "text", name: "PrimaryContactName", label: "Primary Contact Name", md: 3,  validate: true },
    {set:true, type: "select1", md: 3, label: "Designation", name: "PrimaryDesignation",  group: "Designations", map: "designation", validate: true },
    {set:true, type: "phone",  name: "ContactNumberPrimaryContactPerson", touched: false, label: "Contact Number", md: 3,  validate: true },
    {set:true, type: "email", name: "PrimaryContactEmail", label: "Primary Contact Email ID", md: 3,  validate: true },
    {set:true, type: "text", name: "SecondaryContactName", label: "Secondary Contact Name", md: 3,  validate: false },
    {set:true, type: "select1", md: 3, label: "Designation", name: "SecondaryDesignation", group: "Designations", map: "designation", validate: false },
    {set:true, type: "phone",  name: "ContactNumberSecondaryContactName", label: "Contact Number", md: 3,  validate: false },
    {set:true, type: "email", name: "SecondaryContactEmail", label: "Secondary Contact Email ID", md: 3,  validate: false },
    {set:true, sectionTilte: "Coverage", type: "isMulti", touched: false, name: "Countries", label: "Countries Covered", md: 3,  group: "Countries", validate: true },
    {set:true, type: "isMult", name: "Cities", touched: true, label: "City Covered", md: 3,  validate: false },
    {set:true, sectionTilte: "Capabilites", type: "isMulti", name: "Methodologies", touched: false, label: "Methodologies Covered", md: 3,  group: "Methodologies", validate: true },
    {set:true, type: "select", display: false, name: "PanelBook", label: "Panel Book", md: 3, option: [{ label: "Yes", Value: "1" }, { label: "No", Value: "0" }], validate: false, display: false },
    {set:true, type: "text1", multiple: false, md: 3, parent: "PanelBook", label: "Upload Panel Book", name: "UploadPanelBook", touched: false,  validate: true },
    {set:true, type: "text1", multiple: false, md: 3, label: "Upload Company Profile", name: "CompanyProfileFile", validate: true },
    {set:true, type: "text1", multiple: false, md: 3, label: "Field Execution Strength", name: "FieldExecutionStrenght",  validate: true },
    {set:true, sectionTilte: "Training", type: "text1", md: 3, label: "SOP Training Completed", name: "SOPTrainingCompleted",option: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }], validate: true },
    {set:true, type: "text1", multiple: false, md: 3, label: "SOP Training Evidance", name: "SOPTrainingEvidanceFile",  validate: true },
    {set:true, sectionTilte: "Due Diligence", type: "select", display: true, name: "NDASigned", label: "NDA Signed", md: 3,   option: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }], validate: true },
    {set:true, type: "text1", multiple: false, md: 3, parent: "NDASigned", label: "Upload SIgned NDA", name: "UploadSignedNDA",  validate: false },
    {set:true, type: "select", display: true, name: "MSASigned", label: "MSA Signed", md: 3,  option: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }], value: "{Vendor.MSASigned}", validate: true },
    {set:true, type: "text1", multiple: false, parent: "MSASigned", md: 3, label: "Upload Signed MSA", name: "UploadSignedMSA",  validate: false },
    {set:true, type: "select", display: true, name: "DueDeligenceCompleted", label: "Due Deligence Completed", md: 3,  option: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }], validate: true },
    {set:true, type: "select", parent: "DueDeligenceCompleted", display: true, name: "TypeOfDueDeligence", label: "Type Of Due Deligence ", md: 3,  option: [{ label: "Global agreement", value: "Global agreement" }, { label: "Regional agreement", value: "Regional agreement " }, { label: "Local Agreement", value: "Local Agreement" }], validate: false },
    {set:true, type: "text1", parent: "DueDeligenceCompleted", md: 3, multiple: false, label: "Upload Due Deligence Documents", name: "UploadDueDeligenceDocuments",  validate: false }
    ,
    {set:true, type: "textarea", name: "OtherInformation", label: "Other Information", md: 12,  validate: true },
    {set:true, type: "text1", titleText: "Vendor capabilites, Tax certificates etc.", md: 3, label: "Upload Any Other Documents", multiple: true, name: "OtherDocumentsFile",   validate: true }
]

function VendorFormApproveReject(props) {
    const baseurl= process.env.REACT_APP_API_BASE_URL

    const [Masters, setMaster] = useState({});
    const [Vendor, setVendorData] = useState(props.vendor || {});
   // const alert = useAlert();
    const [VendorStatus, setVendorStatus] = useState(null);
    const dispatch = useDispatch();

    const mystyle = {
        color: "blue",
        cursor: "pointer !important",
        fontFamily: "Arial",
        display: 'block',
        fontSize: "11px"
    };

    // Similar to componentDidMount and componentDidUpdate:

    useEffect(() => {
  
        dispatch(masterActions.fetchAllMasters());

    }, []);

    useEffect(() => {
 
        if (props.vendor && !props.vendor.id) {
            dispatch(vendorActions.fetchVendorById(props.id));
        }
        else {
            setVendorData(props.vendor);
            setVendorStatus(props.vendor.Status.Label);

        }
    }, [props.vendor, VendorStatus]);

    useEffect(() => {
        if (props.msg) {
        
           // alert.success(props.msg);
            setTimeout(() => {
            }, 3000);
        }

    }, [props.msg]);
    const headderStyle = {

        backgroundColor: "#dcdcdc",
        height: "30px",
        width: "100%",
        fontWeight: "bolder",
        alignItems: "center",
        fontSize: "18px",
        marginBottom: "10px",
        marginTop: "10px"

    }
    const mystyle1 = {
        color: "#AD4400",
        cursor: "pointer",
        fontFamily: "Arial",
        fontSize: "11px"
    };
    function handleparent(p) {
        var nm = p.label
        let parent = p.parent
        if (nm == "Panel Book") {
            if (p.label == "Panel Book") {
                if (Vendor.Methodologies == undefined || Vendor.Methodologies.length == 0) {
                    return false

                }
                else {


                    var a = Vendor.Methodologies.filter((c) => { return (c.Label === "ONLINE QUANTITATIVE" || c.Label === "ONLINE QUALITATIVE" || c.Label === "ONLINE CLT SELF ADMINISTRATED") })
                    if (a[0] != false && a.length > 0) {

                        p.validate = true;
                        return true
                    }
                    else {

                        p.validate = false
                        return false
                    }
                }



            }

        }

        if (!p.parent) {
            return true
        }

        if (Vendor[parent] == "yes" || Vendor[parent] == "Yes") {


            p.validate = true
            return true
        }
        else {
            p.validate = false

            return false
        }





    }
    const [ValidateMulti, setValidateMulti] = useState(false);
    const [vf, setvf] = useState(vendorform1)//vf=vendorform json
    function handleChange(event, v) {
        if (v.validate == true) {

            if (event.target.value == "") {

                const index = vf.map((x) => { return x.name }).indexOf(v.name);
         
                const values = [...vf]
                values[index]["touched"] = true
                setvf(values)

            }
            else {
                const index = vf.map((x) => { return x.name }).indexOf(v.name);
         
                const values = [...vf]
                values[index]["touched"] = false
                setvf(values)
            }
        }
    }
    const customStyles = {
        control: (base, state) => (
            {
                ...base,
                boxShadow: "none!important",
                border: checkState(state) ? "solid 1px #AD4400" : ""
                // You can also use state.isFocused to conditionally style based on the focus state
            }
        )
    };
    function checkState(state) {
        let name = state.selectProps.name;
        if (ValidateMulti && !state.isFocused) {
            if (!Vendor[name] || Vendor[name].length == 0)
                return true;
        }
        else
            return false;
    }
    const ReturnControl = (v, index) => {

        if (v.type == "email") {
            return (<>
                {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                <Col md={3}>

                    <AvField
                        name={v.name}
                        placeholder=""
                        value={Vendor[`${v.name}`]}
                        label={v.label}
                        type={v.type}
                        disabled />
                </Col>
            </>
            )
        }
        if (v.type == "number") {
            return (<>
                {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                <Col md={v.md}>
                    <FormGroup>
                        <AvField
                            name={v.name}
                            placeholder=""
                            label={v.label}
                            value={Vendor[`${v.name}`]}
                            type={v.type}
                            disabled />
                    </FormGroup>
                </Col>
            </>
            )
        }



        if (v.type == "textarea") {
            return (<>
                {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                <Col md={v.md}>
                    <AvField
                        name={v.name}
                        placeholder=""
                        value={Vendor[`${v.name}`]}
                        label={v.label}
                        type={v.type}
                        disabled />
                </Col>
            </>
            )

        }

        if (v.type == "text") {
            return (
                <>
                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}

                    <Col md={v.md}>
                        <AvField
                            name={v.name}
                            placeholder=""
                            label={v.label}
                            value={Vendor[`${v.name}`]}
                            type={v.type}
                            disabled
                        />
                    </Col>
                </>

            )
        }
        
        if (v.type == "text1") {
            return (
                <>
                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}

                 { (!v.parent||v.parent && Vendor[`${v.parent}`]==1) && <><Col md={v.md} >
                        <AvField
                            name={v.name}
                            placeholder=""
                            label={v.label}
                            value={Vendor[`${v.name}`]}
                            type="text"
                            disabled
                        />    
                    {/* <span style={mystyle} onClick={()=>onFileClick}>{Vendor[v.name] || 'No Document Uploaded'}</span> */}
                    </Col> 

                    <FontAwesomeIcon
                       onClick={()=>onFileClick(Vendor[`${v.name}`])}
                       title="Download FIle"
                        className="align-middle mr-2 addButton"
                        icon={faDownload}
                        fixedWidth
                        style={{ marginTop:"35px", cursor: "pointer" }}
                    />
                       
           {/* <button className="btn btn-primary h-25 w-15 mt-4" onClick={()=>onFileClick(Vendor[`${v.name}`])}>download file</button> */}
           </>
           }
    
                    
</>
            )
        }
        if (v.type == "select") {
            return (

                <>

                    {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}{(handleparent(v) || v.display) &&
                        handleparent(v) &&
                        <Col md={v.md}>


                            <AvField
                                tag={CustomInput}
                                name={v.name}
                                label={v.label}
                           value={Vendor[`${v.name}`]}
                                type={v.type}
                                disabled
                            >
                                    <>
                                <option value="">No</option>
                                
                                    {v.option.map((country) => (
                                        <option key={country.value} value={country.value}>{country.label}</option>
                                    ))}</>
                            
                            </AvField>
                        </Col>}</>

            )
        }

        if (v.type == "select1") {
            return (<>
                {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                <Col md={v.md}>
                    <AvField
                        tag={CustomInput}
                        name={v.name}
                        label={v.label}
                        type="select"
                        value={Vendor[`${v.name}`]}
                        disabled
                    >

                        <><option value="0"></option>
                            {props.redux_masters.filter(x => x.Group == v.group)[0]["List"].map((country) => (
                                <option key={country.value} value={country.value}>{country.label}</option>
                            ))}</>
                    </AvField>
                </Col>
            </>
            )
        }
        if (v.type == "file") {
            return (<>

                {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                {
                handleparent(v) && <Col md={v.md}>

                   <FormGroup>
                       
                       <div>
                        <Label>{v.label}</Label>
                        <input type="file" name={v.name}
                            required="required"
                            onblur={(e) => { handleChange(e, v) }}
                            title={v.titleText || " "}
                            multiple={v.multiple}
                            value={Vendor[`${v.name}`]}
                            onChange={

                                (e) => {
                                    setVendorData({
                                        ...Vendor, [e.target.name]: Object.keys(e.target.files).map(c => { return e.target.files[c].name })

                                    })
                                }

                            } />
                        {Vendor[`${v.name}`] && <span style={mystyle}  onClick={v.onClick}>{`${JSON.stringify(Vendor[`${v.name}`])}`}</span>}
                        {vf[index]["touched"] && <span style={mystyle1} >{`${v.label} is required`}</span>}
                        </div>
                    </FormGroup>
                </Col>
                }
                </>


            )
        }

        if (v.type == "isMulti") {
            return (<>
                {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                <Col md={3}>
                    <Label>{v.label}</Label>
                    {
                        props.redux_masters.length > 0 &&
                        <Select
                            styles={customStyles}
                            name={v.name}
                            rules={{ required: 'Please select an option' }}
                            value={v.label=="Vendor Type"?props.redux_masters.filter(x => x.Group == v.group)[0]["List"].filter(vt=>vt.value==Vendor[`${v.name}`]):Vendor[`${v.name}`]}
                             className="basic-multi-select"
                            classNamePrefix="react-select"
                            required={v.validate}
                            options={v.option ? v.option : props.redux_masters.filter(x => x.Group == v.group)[0]["List"]}
                            isMulti
                            isDisabled
                        />
                    }


                </Col>
            </>

            )


        }
        if (v.type == "isMult") {

            return (<>
                {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}
                <Col md={3}>
                    <Label>{v.label}</Label>
                    {
                        props.redux_masters.length > 0 &&
                        <Select
                            styles={customStyles}
                            value={Vendor[`${v.name}`]}
                            name={v.name}
                           
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={Masters.Cities}
                            isMulti
                            isDisabled
                        />
                    }


                </Col>
            </>
            )
        }



        if (v.type == "phone") {
            return (<>
                {v.sectionTilte && <div style={headderStyle}>{v.sectionTilte}<br></br></div>}

                <Col md={v.md}>

                    <Label>{v.label}</Label>
                    <PhoneInput
                        placeholder="Enter phone number"
                        name={v.name}
                        value={Vendor[`${v.name}`]}
                        onChange={v.setstat}
                        disabled
                      

                    />
                    {vf[index]["touched"] && <span style={mystyle1} >{`${v.label} is required`}</span>}

                </Col>
            </>
            )

        }
    }

  function  onValidSubmit(e){
 
    axios.post(baseurl+"vendors/savestatus",    {
        id: props.id, StatusId: Vendor.StatusId, RejectedReasonId: Vendor.RejectedReasonId || null
        , OtherRejectedComments: Vendor.OtherRejectedComments || null,other_required_documents_filename:{}
    })
    .then(data=>console.log(data))
    .catch(err=>{console.log("err is"); console.log(err)});
           
            // e.preventDefault();
        //     dispatch(vendorActions.saveVendor());
        // }

  }
    function onFileClick(e) {
        // let fileName = e.target.innerHTML;
        let fileName = e;
        
        axios.get(baseurl+"files/download",
            {
                params: {
                    fileName: fileName
                },
                responseType: 'arraybuffer'
            }).then(res => { // then print response status
                var blob = new Blob([res.data]);
                var url = URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            });
    }

    return (
         <Container fluid className="p-0">
            <h1 className="h3 mb-3">{'Vendor Details'}</h1>
            <Row>
                <Col lg="12">
                    {
                        props.redux_masters.length > 0 && Vendor &&
                     
                     <Card>
                            <CardBody>
                  
                                <AvForm encType="multipart/form-data" 
                                >
                                    
                                    <Row form>

                                        {vendorform1.map((e, index) => { return (ReturnControl(e, index)) })}
                                  </Row>
<Row>

                                    {(Vendor.Status.Code == "AP" || Vendor.Status.Code == "PAA") &&

                                    
                                        <Col md={2}> 
                                            
                                            
                                            
                                            <AvRadioGroup inline name="StatusId" value={Vendor.StatusId}
                                                errorMessage="Please select approve or reject" required isdisabled="false">
                                                <AvRadio label="Approve"
                                                    value={2}
                                                    className="mb-2"
                                                    checked={Vendor.StatusId == 2 ? true : false}
                                                    onChange={(e) =>
                                                        setVendorData({
                                                            ...Vendor, [e.target.name]: parseInt(e.target.value)
                                                        })}
                                                    isdisabled="false"
                                                />

                                                
                                                <AvRadio label="Reject"
                                                    value={3}
                                                    className="mb-2"
                                                    checked={Vendor.StatusId == 3 ? true : false}
                                                    onChange={(e) =>
                                                        setVendorData({
                                                            ...Vendor, [e.target.name]: parseInt(e.target.value)
                                                            , "RejectedReasonId": null, "OtherRejectedComments": null
                                                        })
                                                    }
                                                    isdisabled="false"
                                                />
                                            </AvRadioGroup>
                                        </Col>
                                       
                                    }
                                     </Row>
                                    {(Vendor.Status.Code == "AP" || Vendor.Status.Code == "PAA") && Vendor.StatusId == 3 &&
                                        <Col md={12}>
                                            <FormGroup>
                                                <AvField
                                                    tag={CustomInput}
                                                    name="RejectedReasonId"
                                                    label="Rejection Reason"
                                                    type="select"
                                                    value={Vendor.RejectedReasonId}
                                                    onChange={(e) => setVendorData({ ...Vendor, [e.target.name]: e.target.value })}
                                                >
                                                    <option value="">Select reason for rejection</option>
                                                    {props.redux_masters.filter(x => x.Group == "Reasons")[0]["List"].filter(x => x.GROUP == "REJECTED").map((obj) => (
                                                        <option key={obj.value} value={obj.value}>{obj.label}</option>
                                                    ))}
                                                </AvField>
                                            </FormGroup>
                                        </Col>
                                    }
                                    {(Vendor.Status.Code == "AP" || Vendor.Status.Code == "PAA") && Vendor.StatusId == 3 && Vendor.RejectedReasonId == 6 &&
                                        <Col md={12}>
                                            <FormGroup>
                                                <AvField
                                                    name="OtherRejectedComments"
                                                    placeholder=""
                                                    label="Other Rejection Comments"
                                                    type="textarea"
                                                    value={Vendor.OtherRejectedComments}
                                                    onChange={(e) => setVendorData({ ...Vendor, [e.target.name]: e.target.value })}
                                                />
                                            </FormGroup>
                                        </Col>
                                    }

                                    {(Vendor.Status.Code == "AP" || Vendor.Status.Code == "PAA") &&
                                        <Row>
                                            <Col md={12}>
                                                <Button onClick={()=>onValidSubmit()} color="primary">Save</Button>
                                            </Col>
                                        </Row>
                                    }
                                </AvForm>
                            </CardBody>
                        </Card>
                    }
                    {props.redux_masters.length == 0 && <Loader />}
                </Col>
            </Row>
        </Container>
     
    )
}

export default connect(mapStateToProps, null)(VendorFormApproveReject)
