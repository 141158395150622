import * as types from "./ActionTypes";

export const fetchVendors = (offsetss = null, searchBy = null, searchVal = null) =>
{
    let tempSearchVal = null;
    let tempSearchBy = null;
    if (searchBy && searchVal)
    {
        tempSearchBy = searchBy;
        tempSearchVal = searchVal;
    }
    return {
        type: types.FETCH_VENDORS_REQUEST,
        offsetss,
        tempSearchBy,
        tempSearchVal
    }
};

export const fetchVendorById = (id) =>
{
    return {
        type: types.FETCH_VENDOR_DETAILS_REQUEST,
        payload: { id: id }
    };
}

export const saveVendor = (vendor, msg = null) =>
{
    return {
        type: types.SAVE_VENDOR_REQUEST,
        vendor,
        msg: msg
    };
}

export function clearVendorRedirectToState()
{
    return {
        type: types.API_VENDOR_CLEAR_REDIRECT_TO_STATE
    }
}

export const resetMsg = ()=>
{
    return {
        type: types.RESET_MSG
    }
}

