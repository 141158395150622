import React, { useState, useEffect, Suspense } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import _, { cloneDeep, isEmpty } from "lodash";
import { Link, useHistory } from "react-router-dom";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";

import Layout from "../../layouts/Project";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../../components/Spinner";
import {
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faExclamationCircle,
  faHourglassHalf,
  faThumbsUp,
  faThumbsDown,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";

import { getLabel } from "../../utils/codeLabels";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Collapse,
  CardHeader,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  InputGroup,
  InputGroupAddon,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
  CustomInput,
} from "reactstrap";
import classnames from "classnames";

import ModalCommissioning from "./ModalCommissioning";
import ModalPostCommission from "./ModalPostCommission";
import ModalBypass from "./ModalBypass";
import Select from "react-select";
import moment, { invalid } from "moment";
import CostBreakDown from "./CostBreakDown";
import CommercialHours from "../costingProfile/CommercialHours";
import { toastr } from "react-redux-toastr";
import axios from "../../axios-interceptor";
import { pageLoadEnd, pageLoadStart } from "../../redux/actions/appActions";
import CommercialHours_internal from "../costingProfile/CommercialHours_internal";
import BreakDownView from "./BreakDownView";
import ViewCostBreakDown from "./ViewCostBreakdown";
import OopAdjustmentLog from "./OopAdjustmentLog";
import RecordsBadge from "../../components/RecordsBadge";
import UploadedProjectModal from "../../components/UploadedProjectModal";
import ResetApprovalModal from "./ResetApprovalModal";
import ReasonForApproval from "./ReasonForApproval";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import CommissioningConfirmModal from "../../pages/summary/CommissioningConfirmModal";
import logo from "../../assets/img/avatars/modal-logo.png";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";
import MSDValidationModal from "./MSDValidationModal";
import ExceptionModal from "./ExceptionModal";
import TimeCostUI from "./TimeCostUI";
import { setDelegateEmails } from "../../redux/actions/currentProjectActions";
const getTextColor = (flag) => {
  // project.ParentProjectId ?flag==true &&"text-success font-weight-bold":"text-danger font-weight-bold"
  return flag
    ? "text-success font-weight-bold"
    : "text-danger font-weight-bold";
};

const Summary = () => {
  let { profileId } = useParams();
  let resetFlag;
  const history = useHistory();
  const dispatch = useDispatch();
  const [rfqData, setRfqData] = useState({});
  const [allRFQSchema, setAllRFQSchema] = useState({});
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [allOpsSchema, setAllOpsSchema] = useState({});
  const [opsData, setOpsData] = useState({});
  const [costFields, setCostFields] = useState({});
  const [isOpen, setIsOpen] = useState({});
  const [msdValid, setMsdValid] = useState({});
  const [isCommission, setisCommission] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState({});
  const [calledProfile, setProfileCalling] = useState(false);
  const [calledCurrencies, setCalledCurrencies] = useState(false);
  const [showManualCostEntry, setShowManualCostEntry] = useState(false);
  const [showSheetsCosts, setShowSheetsCosts] = useState(false);
  const [showCostMethod, setShowCostMethod] = useState(false);
  const [isCostBDOpen, openCostBreakdown] = useState(false);
  const [isHistoryLogOpen, openHistoryLog] = useState(false);
  const [HistoryLogData, setHistoryLogData] = useState([]);
  const [optionIds, setOptionsId] = useState(null);
  const [rateCards, setRateCards] = useState(null);
  // approvaldata
  const [delegateEmail, setDelegateEmail] = useState([]);
  const [isActivityBDOpen, openActivityBreakdown] = useState(false);
  const [schema, setSchema] = useState({});
  const [bands, setBands] = useState([]);
  const [invalid, setInvalid] = useState({});
  // updateprojectmodal
  const [uploadProject, setUploadProject] = useState(false);
  // resetapprovalmodal
  const [statusCheckApproval, setStatusCheckApproval] = useState(false);

  const [resetModal, setResetModal] = useState(false);
  // reasonforapproval
  const [reasonForApproval, setreasonForApproval] = useState("");
  // popupforhistory
  // const [historyApproval, setHistoryApproval] = useState(false)
  // popupstateforhistory
  const [historyApprovalPopup, sethistoryApprovalPopup] = useState(false);
  // checking profitability
  const [statusCheckProfit, setStatusCheckProfit] = useState(false);

  // confirmation for commission costing
  const [confirmCommission, setConfirmCommission] = useState(false);
  const [statusClickedApproval, setstatusClickedApproval] = useState(true);
  //Zrro cost
  const [isZeroCost, setIsZeroCost] = useState(false);
  const [tempPriceToClient, setTempPriceToClient] = useState();

  const [validBusinessUnitsForOOP] = useState(["CI"]);
  const [tempProfileSetting, setTempProfileSetting] = useState({
    CostIntOpsMultiplier: 0,
    CostIntCommMultiplier: 0,
  });
  const [applyingMultiplier, setApplyingMultiplier] = useState(false);
  const [overheadsPercentage, setOverheadsPercentage] = useState(0);
  const [detailedRFQ, setDetailedRFQ] = useState(false);
  const [exceptionModal,setExceptionModal]=useState(false)
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const CostingTypesEnum = {
    INPUTDIRECTLY: 1,
    MSSHEETS: 2,
    VENDORBIDDING: 3,
  };

  const profitabilityTableOld=[
    {
      id: "CostTotalExternalOperations",
      label: "Total OPS OOP",
      isPrice: true,
    },
    {
      id: "CostTotalExternalCommercial",
      label: "Total CS OOP",
      isPrice: true,
    },
    {
      id: "TotalExternalCostsWithoutTax",
      label: "Total OOP (Ops OOP + CS OOP)",
      isPrice: true,
      derivativeFields: [
        "CostTotalExternalCommercial",
        "CostTotalExternalOperations",
      ],
    },
    { id: "WHT", label: "WHT(With holding tax)", isPrice: true },
    { id: "VAT", label: "VAT(Value added tax)", isPrice: true },
    {
      id: "TotalExternalCosts",
      label: "Total OOP (Ops OOP + CS OOP + Tax)",
      isPrice: true,
    },
    {
      id: "CostTotalInternalOperations",
      label: "Total Time Cost Operations (Ops Time Cost + CC Ops Time Cost)",
      isPrice: true,
    },
    {
      id: "CostTotalInternalCommercial",
      label: "Total Time Cost Commercial (CS Time Cost + CC CS Time Cost)",
      isPrice: true,
    },
    { id: "CostTotalMCPMargin", label: "MCP Margin", isPrice: true },
    {
      id: "Overheads",
      label: `Overheads ${overheadsPercentage}%`,
      isPrice: true,
    },
    {
      id: "RecommendedPrice",
      label: "Recommended Price To Client (Revenue)",
      isPrice: true,
      newPriceChange: true,
    },
    {
      id: "PriceToClient",
      label: "Final Price To Client (Revenue)",
      isPrice: true,
    },
    { id: "OutOfPocketCostPercent", label: "OOP %" },
    {
      id: "ProjectMarginAbsolute",
      label:
        "Project Margin Absolute (Price - (Total Cost Ext + Int + MCP Margin + Overheads))",
      isPrice: true,
    },
    { id: "MarginPercent", label: "Project Margin %" },
    { id: "ClientChargeable", label: "Client Chargeable", isPrice: true },
  ];
  const profitabilityTableNew=[
    {
      id: "CostTotalExternalOperations",
      label: "Total OPS OOP",
      isPrice: true,
    },
    {
      id: "CostTotalExternalCommercial",
      label: "Total CS OOP",
      isPrice: true,
    },
    {
      id: "TotalExternalCostsWithoutTax",
      label: "Total OOP (Ops OOP + CS OOP)",
      isPrice: true,
      derivativeFields: [
        "CostTotalExternalCommercial",
        "CostTotalExternalOperations",
      ],
    },
    { id: "WHT", label: "WHT(With holding tax)", isPrice: true },
    { id: "VAT", label: "VAT(Value added tax)", isPrice: true },
    {
      id: "TotalExternalCosts",
      label: "Total OOP (Ops OOP + CS OOP + Tax)",
      isPrice: true,
    },
    {
      id: "CostTotalInternalOperations",
      label: "Total Time Cost Operations (Ops Time Cost + CC Ops Time Cost)",
      isPrice: true,
    },
    {
      id: "CostTotalInternalCommercial",
      label: "Total Time Cost Commercial (CS Time Cost + CC CS Time Cost)",
      isPrice: true,
    },
    { id: "OutOfPocketCostPercent", label: "OOP %" },
    {
      id: "PriceToClient",
      label: "Final Price To Client (Revenue)",
      isPrice: true,
    },
    { id: "CostTotalMCPMargin", label: "MCP Margin", isPrice: true },
    {
      id: "RecommendedPrice",
      label: "Recommended Price To Client (Revenue)",
      isPrice: true,
      newPriceChange: true,
    },
    { id: "CountryContributionMarginTargetPercent", label: "Country Contribution Margin Target %", isPrice: false },
    {
      id: "ProjectMarginAbsolute",
      label:
        "Contribution Margin Absolute",
      isPrice: true,
    },
    { id: "MarginPercent", label: "Contribution Margin %" },
    { id: "ClientChargeable", label: "Client Chargeable", isPrice: true },
  ];


  const profitabilityTable = profile.CountryContributionMarginTargetPercent===null&&profile.CostingsSheetId!==null?profitabilityTableOld:profitabilityTableNew;
  const [approvalFormValidation, setApprovalValidation] = useState({});
  const [includeOnlyInMCP, setIncludeOnlyInMCP] = useState([
    "MCPLocalCountryExtra",
    "CostTotalMCPMargin",
  ]);
  const userRecord = useSelector(({ user }) => user.userRecord);
  const app = useSelector(({ app }) => app);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
  const userCanExceptionCommision = useSelector(({ user }) => user?.userRecord?.CanBackendCommission);
  const delegateEmailRedux = useSelector(
    ({ currentProject }) => currentProject.setDelegateEmail
  );

  const currencies = useSelector(({ currentCosting }) =>
    currentCosting.currentCostingProfile.ProfileSetting &&
    currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      : []
  );
  const [methCountrySet, setMethCountrySet] = useState({});
  const [methCountryCostSet, setMethCountryCostSet] = useState({});
  const [methCostFieldsSet, setMethCostFieldsSet] = useState({});
  const [methLabels, setMethLabels] = useState({});
  const [activeCostTab, setActiveCostTab] = useState(0);
  const [allCostingOptions, setAllCostingOptions] = useState([]);
  const [allOpsResourcesFields, setOpsResourcesFields] = useState([]);
  const [allRFQOptions, setRFQOptions] = useState({});
  const [allResponsibilities, setallResponsibilities] = useState([]);
  const [allApplicableMethIds, setAllApplicableMethIds] = useState([]);
  let isApprover = true;
  const [opsResourcesVariables] = useState([
    "surveyProgrammingResource",
    "surveyProgrammingResourceVendor",
    "dataProcessingResource",
    "dataProcessingResourceVendor",
    "dataProcessingComplexity",
    "translationsResource",
    "translationsResourceVendor",
    "verbatimCodingResource",
    "verbatimCodingResourceVendor",
    "chartingResource",
    "chartingResourceVendor",
    "dashboardingResource",
    "dashboardingResourceVendor",
    "qualityControl",
    "qualityControlComments",
    "dataCollectionPlatform",
    "platformComments",
  ]);
  const [isModalOpen, handleOpen] = useState(false);
  const [profileCommunicationMessage, setProfileCommunicationMessage] =
    useState("");
  const [profileCommunicationMessages, setProfileCommunicationMessages] =
    useState([]);
  const [shopperMethodologies] = useState(["SM000024", "SM000026", "SM000029"]);
  const [shopperCostFieldsLookup] = useState({
    SM000024: ["ETExtOps", "ETIntCS"],
    SM000029: ["ETExtOps", "ETIntCS"],
    SM000026: ["SSExtOps", "SSIntOps", "SSIntCS"],
  });
  const [activeTabFCP, setActiveTabFCP] = useState(0);
  const [activeTabWBS, setActiveTabWBS] = useState(0);
  let isDuplicate = []
  const [activeTabSMP, setActiveTabSMP] = useState(0);
  const [activeP, setActiveP] = useState(0);
  const [shopperCostLabels] = useState({
    ETExtOps: {
      label: "External Operations Costs",
      properties: [
        "Eye-tracking glasses rental ",
        "Shipment of  ET glasses/Tablets  ",
        "Tablets",
        "Traveling, training",
        "Venue (if applicable)",
        "Translation (Questionnaire, Briefing note, Showcard, etc.)",
        "Application programming",
        "Questionnaire programming Costs (ConfirmIT, STG, Decipher) - (TCS only)",
        "Questionnaire programming Costs (ConfirmIT, STG, Decipher) - (Non-TCS External)",
        "Store plan drawing",
        "Taking photos of POS in stores",
        "Recruitment only",
        "Fieldwork - (Payment to Interviewer and Supervisors)",
        "Outsourced Vendor Data collection/QC (Non-TCS External)",
        "Incentives",
        "QC/ Assurance cost (Payment to part-timers)",
        "Technican operator",
        "GO costs (Coding Of Verbatim) - (TCS only)",
        "GO costs (Coding Of Verbatim)  - (Non-TCS External)",
        "All other external costs",
        "All Other External costs - Printing/Stationaries costs",
        "All Other General Expenses (eg. Refreshments/Respondent travel..etc)",
      ],
    },
    ETIntCS: {
      label: "Internal Commercial Costs",
      properties: [
        "Project Coordination/Project management ",
        "Questionnaire Review/Inputs",
        "Insight Generation/Topline or Full Report Writing ",
        "Other (specify)…",
      ],
    },
    SSExtOps: {
      label: "External Operations Costs",
      properties: [
        "Cost for the Hours to find images online for 2D ",
        "Cost for the Hours to model the 2D products in vendor software",
        "Cost to buy products to photograph for 3D, if applicable",
        "Cost of shipment products for photography",
        "Photography for 3D SKUs (6-10 images per SKU), if applicable",
        "Modelling for 3D SKUs, if applicable",
        "Cost for the Hours to procure custom wallpaper images, if applicable",
        "Other Set-Up materials costs - SPECIFY: ",
        "Other Set-Up materials costs - SPECIFY: ",
        "VR Envoirnment Build / Development Cost (Vendor)",
        "Implicit Analysis Cost (Vendor) if applicable",
        "Sampling costs",
        "Translation questionnaire (if applicable)",
        "Coding OE (please specify number of OE)",
        "Translation verbatims of OE (please specify number of OE)",
        "Other Fieldwork Cost - SPECIFY: ",
        "Other Fieldwork Cost - SPECIFY: ",
        "Webcam Eye-tracking with Sticky (if requested)",
        "Additional Incentives for respondents who agree to participate in Eye Tracking exercise",
        "Video Selfies Cost (assuming 10% of total sample) - based on Nielsen VoxPopMe rate",
        "Translation transcripts from Video Selfie Feedback",
        "Other Video Selfie and/or ET Cost - SPECIFY: ",
        "Programming of questionnaire (TCS or vendor)",
        "Tabulation (CRDC)",
        "Charting (CRDC)",
        "Decipher credits",
        "Other Programming&DP Cost - SPECIFY: ",
        "Other Programming&DP Cost - SPECIFY: ",
      ],
    },
    SSIntCS: {
      label: "Internal Commercial Costs",
      properties: [
        "Questionnaire Review/Inputs (Internal CS time cost)",
        "Insight Generation/Topline or Full Report Writing  (Internal CS time costs)",
        "Others CS Time Cost - SPECIFY: ",
      ],
    },
    SSIntOps: {
      label: "Internal Operations Costs",
      properties: [
        "Cost for the Hours to find images online for 2D ",
        "Cost for the Hours to model the 2D products in vendor software",
        "Cost for the Hours to procure custom wallpaper images, if applicable",
        "Project Management costs (Internal Ops time costs)",
        "Programming of questionnaire (internal)",
        "Tabulation (internal)",
        "Others Ops Time Cost - SPECIFY: ",
      ],
    },
  });

  let pacificData = _.head(codeLabels.PacificCountriesList).Label;
  let arr = pacificData.split(",");
  let approvalRequiredFlag;
  
  if(!project.checkProfitValue){
   approvalRequiredFlag =
    (statusCheckProfit &&
      profile.ApprovalLevelNeeded != -1 &&
      profile.ApprovalLevelNeeded != null &&
      profile.ApprovalLevelAwaiting != null &&
      (profile.ProfileStatus == 1 ||
        profile.ProfileStatus == 2 ||
        profile.ProfileStatus == 3) &&
      (!profile.IsZeroCost || !isZeroCost)) ||
    profile.NeedsApproval == true
      ? "ApprovalRequired"
      : project.PriceChange == 2 ||
        (profile.ProfileStatus === "1" &&
          (profile.IsZeroCost || (isZeroCost && statusCheckProfit))) ||
        (statusCheckProfit &&
          !isZeroCost &&
          profile.ProfileStatus === "1" &&
          !profile.NeedsApproval &&
          (profile.PriceToClient > 0 || profile.OutOfPocketCostPercent > 0)) ||
        profile.ProfileStatus === "5" ||
        (!profile.NeedsApproval && profile.IsSyndicated && statusCheckProfit)
      ? "ContinueWithComission"
      : (project.ProjectStatus == "3" || project.ProjectStatus == "9"  || project.ProjectStatus == "11") && profile.NeedsApproval == false ?
      "Approval is not required" : "Approval Required";
      }
      else{
        let products = []
                while(typeof profile.ProductTableData === "string"){
                  profile.ProductTableData = JSON.parse(profile.ProductTableData)
                }
                profile.ProductTableData.map((data)=>{
                  if(!data.isRed)
                  {
                    products.push(data.MaterialID)
                  } 
                  else if (data.isRed && data.isRed !== true){
                    products.push(data.MaterialID)
                  } 
                })
              //   let TbProducts1 = ["CMI-TB BRAND",
              //     "CMI-TB CONSUMER",
              //     "CMI-TB CX",
              //     "CMI-TB OTHER",
              //     "CMI-TB SHPPRTREND",
              //     "CMI-TB COMMS",
              //     "CMI TB BRAND",
              //     "CMI TB CONSUMER",
              //     "CMI TB CX",
              //     "CMI TB OTHER",
              //     "CMI TB SHPPRTREND", 
              //     "CMI TB COMMS"
              //   ]
              // let TbProductsAva1 = products.some((item)=> TbProducts1.includes(item))
              // if(TbProductsAva1 == true){
              //   approvalRequiredFlag = "ApprovalRequired"
              // }
              // else{
                approvalRequiredFlag = 
               (profile.MarginPercent > 
                   project.ThresholdData) ?
               "Approval is not Required" : "ApprovalRequired"
              // }
      }
  const [pacificMarkets] = useState(arr);

  useEffect(() => {
    if (!allOpsResourcesFields.length && profile.WaveSpecs) {
      let responsibilities = _.head(profile.WaveSpecs)?.ResponsibilitySchema;
      let _allResponsibilities = [];
      if (responsibilities) {
        Object.keys(responsibilities).map((res) => {
          if (!_.includes(_allResponsibilities, res)) {
            _allResponsibilities.push(res);
          }
        });
      }
      let validMethIds = [];
      profile.CountrySpecs?.map((cs) => {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)
          ?.map((ms) => ms.id)
          ?.map((ms) => {
            if (!_.includes(validMethIds, ms)) validMethIds.push(ms);
          });
      });
      setAllApplicableMethIds(validMethIds);
      let allOpsResourcesDataParams = [];
      profile.WaveSpecs.map((t) => {
        if (
          t.OpsResourcesSchema &&
          t.OpsResourcesSchema.properties &&
          _.includes(validMethIds, t.MethodologySpecId)
        ) {
          Object.keys(t.OpsResourcesSchema.properties).map((opd) => {
            if (
              !_.includes(allOpsResourcesDataParams, opd) &&
              !_.includes(_allResponsibilities, opd)
            )
              allOpsResourcesDataParams.push(opd);
          });
        }
        // if (t.OpsResourcesData) {
        // 	Object.keys(t.OpsResourcesData).map(opd => {
        // 		if (!_.includes(allOpsResourcesDataParams, opd)
        // 			&& !_.includes(_allResponsibilities, opd))
        // 			allOpsResourcesDataParams.push(opd)
        // 	})
        // }
      });
      setOpsResourcesFields(allOpsResourcesDataParams);
    }
    if (!Object.keys(allRFQOptions).length && profile.CountrySpecs?.length) {
      let allRFQDataParams = {};
      profile.CountrySpecs?.map((cs) => {
        if (cs.MethodologySpecs?.length) {
          cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable).map((ms) => {
            allRFQDataParams[ms.id] = [];
            let allCostingOptionKeys = [
              "targetGroupDefinition",
              "citiesCoverage",
              "sampleSize",
              "lengthOfInterview",
              "sampleSource",
              "samplingType",
            ];

            ms.CostingOptions?.map((co) => {
              if (co?.CostingOptionData) {
                Object.keys(co.CostingOptionData).map((cd) => {
                  allCostingOptionKeys.push(cd);
                });
              }
            });
            if (ms.RFQSchema && ms.RFQSchema.properties) {
              Object.keys(ms.RFQSchema.properties).map((rfqkey) => {
                if (
                  !_.includes(allRFQDataParams[ms.id], rfqkey) &&
                  !_.includes(allCostingOptionKeys, rfqkey)
                ) {
                  allRFQDataParams[ms.id].push(rfqkey);
                }
              });
            }
            if (ms.RFQSchema?.dependencies) {
              Object.keys(ms.RFQSchema.dependencies).map((dep) => {
                ms.RFQSchema.dependencies[dep].oneOf.map((oo) => {
                  Object.keys(oo.properties).map((rfqkey) => {
                    if (
                      !_.includes(allRFQDataParams[ms.id], rfqkey) &&
                      !_.includes(allCostingOptionKeys, rfqkey)
                    ) {
                      allRFQDataParams[ms.id].push(rfqkey);
                    }
                  });
                  // if (oo.properties[prop] && oo.properties[prop].title) {
                  // 	reqVal = oo.properties[prop];
                  // }
                });
              });
            }
            // if (ms.RFQData) {
            // 	Object.keys(ms.RFQData).map(rfqkey => {
            // 		if (!_.includes(allRFQDataParams, rfqkey) && !_.includes(allCostingOptionKeys, rfqkey)) {
            // 			allRFQDataParams.push(rfqkey)
            // 		}
            // 	})
            // }
          });
        }
      });

      setRFQOptions(allRFQDataParams);
    }
    if (
      profile.IsMultiCountry &&
      !allResponsibilities.length &&
      profile.WaveSpecs
    ) {
      let responsibilities = _.head(profile.WaveSpecs)?.ResponsibilitySchema;
      let _allResponsibilities = [];
      if (responsibilities) {
        Object.keys(responsibilities).map((res) => {
          if (!_.includes(_allResponsibilities, res)) {
            _allResponsibilities.push(res);
          }
        });
      }
      setallResponsibilities(_allResponsibilities);
    }
    if (!Object.keys(methCountrySet).length && profile.CountrySpecs?.length) {
      let methBreak = {};
      profile.CountrySpecs.map((cs) => {
        cs.MethodologySpecs?.map((ms) => {
          if (!methBreak[ms.id]) {
            methBreak[ms.id] = { country: cs.CountryName, methlabel: ms.Label };
          }
        });
      });
      setMethCountrySet(methBreak);
    }
    if (!allCostingOptions.length && profile.CountrySpecs?.length) {
      let _allCostingOptions = [];
      profile.CountrySpecs.map((cs) => {
        if (cs.MethodologySpecs) {
          cs.MethodologySpecs.map((ms) => {
            ms.CostingOptions?.filter((co) => co.Selected).map((t) => {
              t?.CostingOptionSchema?.map((cso) => {
                // if (t.CostingOptionData && t.CostingOptionData[cso.id]) {
                if (
                  !_allCostingOptions.filter((asc) => asc.id == cso.id).length
                ) {
                  _allCostingOptions.push(cso);
                  // }
                }
              });
            });
          });
        }
      });
      setAllCostingOptions(_allCostingOptions);
    }

    let communicationContacts = [];
    if (profile && profile.Project) {
      communicationContacts.push(profile.Project.ProposalOwnerEmail.value);
      if (
        profile &&
        profile.Project &&
        profile.Project.OtherProjectTeamContacts &&
        profile.Project.OtherProjectTeamContacts.length
      ) {
        profile.Project.OtherProjectTeamContacts.forEach((item) => {
          communicationContacts.push(item.value);
        });
      }
      if (profile.ApprovalLevelNeeded === -1) {
      } else {
        profile.ApprovalDetails?.forEach((level) => {
          level.ApproverContacts.forEach((contact) => {
            communicationContacts.push(contact.EmailAddress);
          });
        });
      }
    }
  }, [profile]);
  useEffect(() => {
    if (
      profile?.Project &&
      profile?.Project.PriceChange == "2" &&
      !profile?.ApprovalDetails
    ) {
      dispatch(pageLoadStart());

      axios
        .get("/marketsettings/" + profile.Project.VerticalId + "/approvers/all")
        .then((res) => {
          console.log("check");
          // //console.log(res);
          // fetch approvalSettings
          // sort by order (or have this done in backend)
          res.data.ApprovalDetails.sort((a, b) => {
            return a.Order - b.Order;
          });
          // save approvaldetails to profile
          profile.ApprovalDetails = [...res.data.ApprovalDetails];
          dispatch(currentCostingActions.updateProfile({ ...profile }));
          console.log("updated", profile);
          dispatch(pageLoadEnd());
        })
        .catch((err) => {
          // //console.log(err);
        });
    }
  }, [profile]);

  const approvalFormOnChange = (e) => {
    dispatch(
      currentCostingActions.updateProfile({
        ApprovalFormData: {
          ...profile.ApprovalFormData,
          [e.target.id]: e.target.value,
        },
      })
    );
  };
  function detailedRFQPage() {
    if (project.IsUploaded) {
      setUploadProject(true);
    } else {
      history.push({
        pathname: "/detailed-rfq",
        //state:profile,
        profile: profile,
        project: project,
        state: true,
      });
    }
    // } else {
    // 	setDetailedRFQ(true);
    // }
  }
  {
    project.IsUploaded && uploadProject && (
      <UploadedProjectModal
        uploadProject={uploadProject}
        setUploadProject={setUploadProject}
      />
    );
  }
  function disableDetailedRFQ() {
    var tempdisable;
    if (project.CostingTemplateVersion != "v2.0") {
      tempdisable = true;
    } else if (
      _.includes(pacificMarkets, project.CommissioningCountry) &&
      project.CostingTemplateVersion != "v2.0"
    ) {
      tempdisable = true;
    } else if (project.CostingTemplateVersion == "v2.0") {
      profile.CountrySpecs.map((fc) => {
        fc.MethodologySpecs.filter(
          (x) =>
            !x.NotApplicable &&
            x.CostingOptions.some((s) => s.CoSelectedForSummary)
        ).length > 0
          ? (tempdisable = true)
          : (tempdisable = false);
      });
    } else {
      tempdisable = false;
    }

    setDetailedRFQ(tempdisable);
    return tempdisable;
  }

  useEffect(() => {
    if (!Object.keys(schema).length && profile && profile.ProfileSetting) {
      if (profile.ProfileSetting.CommercialHoursSchema) {
        setSchema(profile.ProfileSetting.CommercialHoursSchema);
        setBands(profile.ProfileSetting.CommercialHoursSchema.bands);
      }
      if (
        profile.ProfileSetting.CostIntCommMultiplier ||
        profile.ProfileSetting.CostIntOpsMultiplier
      ) {
        setTempProfileSetting({
          CostIntCommMultiplier: profile.ProfileSetting.CostIntCommMultiplier,
          CostIntOpsMultiplier: profile.ProfileSetting.CostIntOpsMultiplier,
        });
      }

      setOverheadsPercentage(
        (profile.ProfileSetting.PercentOverhead ?? 0) * 100
      );
    }
  }, [profile]);
  useEffect(() => {
    if (profileId && !calledProfile && !profile.id) {
      setProfileCalling(true);
      let shopperCounter = [
        "shopperCostTotalExternalOperations",
        "shopperCostTotalInternalOperations",
        "shopperCostTotalInternalCommercial",
      ];
      let _shopperSafetyBackup = {};
      shopperCounter.map((sc) => {
        _shopperSafetyBackup[sc] = profile[sc];
      });
      dispatch(
        currentCostingActions.getCosting(
          profileId,
          null,
          profile.IsOPSOOPAdjusted ? false : true,
          _shopperSafetyBackup
        )
      );

      FetchProfileCommunicationBoard(profileId);
    } else if (!profileId) {
      //console.log("boots to dashboard");
      history.replace("/");
    }
  }, []);
  // useEffect(() => {
  //   if (!summaryCalled && profile && profile.ProfileSetting) {
  //     setSummaryCalled(true);
  //     dispatch(currentCostingActions.generateSummary());
  //   }
  // });
  useEffect(() => {
    if (
      !currencies &&
      profile &&
      profile.ProfileSetting &&
      Object.keys(profile.ProfileSetting).length
    ) {
      if (!calledCurrencies && !profile.ProfileSetting.CurrenciesData) {
        setCalledCurrencies(true);
        dispatch(
          currentCostingActions.setCurrencies(() => setCalledCurrencies(false))
        );
      }
    }
  }, [profile]);
  // useEffect(() => {
  //   if (!currencies && !calledCurrencies && (profile
  //     && profile.ProfileSetting
  //     && !profile.ProfileSetting.CurrenciesData)) {
  //     setCalledCurrencies(true);
  //     dispatch(
  //       currentCostingActions.setCurrencies(() => setCalledCurrencies(false))
  //     );
  //   }
  // });

  const getTotalShopper = (meth, _prop) => {
    let _sum = 0;
    Object.keys(methCountryCostSet[meth]).map((cs) => {
      if (methCountryCostSet[meth][cs] && methCountryCostSet[meth][cs][_prop])
        _sum = _sum + parseFloat(methCountryCostSet[meth][cs][_prop]);
    });
    return getCurrentCurrency(_sum);
  };
  const getTotalShopperFinal = (meth, scfl) => {
    let _sum = 0;

    Object.keys(methCountryCostSet[meth]).map((cs) => {
      _sum =
        _sum +
        _.sum(
          shopperCostLabels[scfl]?.properties.map((_prop) =>
            methCountryCostSet[meth] &&
            methCountryCostSet[meth][cs] &&
            methCountryCostSet[meth][cs][_prop]
              ? parseFloat(methCountryCostSet[meth][cs][_prop])
              : 0
          )
        );
    });
    return getCurrentCurrency(_sum);
  };
  const getCurrentCurrency = (actualvalue, currencyvalue, onlyCurrency) => {
    if (
      currencies &&
      currencies.length &&
      currentCurrency &&
      currentCurrency.ConversionRateToLocal
    ) {
      if (actualvalue) {
        let finalVal = actualvalue * currentCurrency.ConversionRateToLocal;
        finalVal = Math.round(finalVal);
        return `${finalVal} ${currentCurrency.CurrencyUnit}`;
      } else return `0 ${currentCurrency.CurrencyUnit}`;
    }
    // }
  };

  const getCountryLevelCost = (MethodologySpecs, currentCode) => {
    let _total = 0;
    MethodologySpecs?.map((ms) => {
      profile?.WaveSpecs?.filter((ws) => ws.MethodologySpecId == ms.id).map(
        (ws) => {
          _total = _total + ws[currentCode];
        }
      );
    });
    return getCurrentCurrency(_total);
  };
  // const getCountryTotalIntComm = (country, currentCode) => {
  // 	let _total = 0
  // 	let requiredCountry = profile.CountrySpecs.find(cs => cs.CountryCode == country.CountryCode)
  // 	requiredCountry?.MethodologySpecs?.map(ms => {
  // 		profile?.WaveSpecs?.filter(ws => ws.MethodologySpecId == ms.id).map(ws => {
  // 			_total = _total + ws[currentCode]
  // 		})
  // 	})
  // 	return getCurrentCurrency(_total)
  // }
  // getGrandTotalWithoutCurrency
  const getGrandTotalWithoutCurrencyCCCoordCosts = (actualvalue, id) => {
    if (
      currencies &&
      currencies.length &&
      currentCurrency &&
      currentCurrency.ConversionRateToLocal
    ) {
      if (
        profile &&
        profile.CountrySpecs[0] &&
        profile.CountrySpecs[0].MethodologySpecs[0] &&
        profile.CountrySpecs[0].MethodologySpecs[0].CostingOptions[0] &&
        profile.CountrySpecs[0].MethodologySpecs[0].CostingOptions[0].CostsData
        // profile.CountrySpecs[0].MethodologySpecs[0].CostingOptions[0].CostsData?.CCOPSCoordinatingCost
      ) {
        if (id === "CostTotalInternalOperations") {
          const temp1 = profile.CountrySpecs[0].MethodologySpecs.map((temp) => {
            return temp.CostingOptions;
          });
          const temp2 = temp1.map((temp) => {
            return temp.map((tem) => {
              return tem.CostsData?.CCOPSCoordinatingCost;
            });
          });
          let CCOPSCoordinatingCost =
            temp2.reduce((acc, val) => acc + parseFloat(val), 0) || 0;
          let finalVal =
            (actualvalue + CCOPSCoordinatingCost) *
            currentCurrency.ConversionRateToLocal;
          finalVal = Math.round(finalVal, 2);
          return `${finalVal}`;
        }
        if (id === "CostTotalInternalCommercial") {
          const temp1 = profile.CountrySpecs[0].MethodologySpecs.map((temp) => {
            return temp.CostingOptions;
          });
          const temp2 = temp1.map((temp) => {
            return temp.map((tem) => {
              return tem.CostsData?.CCCSCoordinatingCost;
            });
          });
          let CCCSCoordinatingCost =
            temp2.reduce((acc, val) => acc + parseFloat(val), 0) || 0;
          let finalVal =
            (actualvalue + CCCSCoordinatingCost) *
            currentCurrency.ConversionRateToLocal;
          finalVal = Math.round(finalVal, 2);
          return `${finalVal}`;
        }
      } else if (actualvalue) {
        let finalVal = actualvalue * currentCurrency.ConversionRateToLocal;
        finalVal = Math.round(finalVal, 2);
        return `${finalVal}`;
      } else return 0;
    }
  };
  const getGrandTotalWithoutCurrency = (actualvalue) => {
    if (
      currencies &&
      currencies.length &&
      currentCurrency &&
      currentCurrency.ConversionRateToLocal
    ) {
      if (actualvalue) {
        let finalVal = actualvalue * currentCurrency.ConversionRateToLocal;
        finalVal = Math.round(finalVal, 2);
        return `${finalVal}`;
      } else return 0;
    }
  };
  const getGrandTotalWithCurrencyCCCoordCosts = (actualvalue, id) => {
    if (
      currencies &&
      currencies.length &&
      currentCurrency &&
      currentCurrency.ConversionRateToLocal
    ) {
      if (
        profile &&
        profile.CountrySpecs[0] &&
        profile.CountrySpecs[0].MethodologySpecs[0] &&
        profile.CountrySpecs[0].MethodologySpecs[0].CostingOptions[0] &&
        profile.CountrySpecs[0].MethodologySpecs[0].CostingOptions[0].CostsData
        // profile.CountrySpecs[0].MethodologySpecs[0].CostingOptions[0].CostsData?.CCOPSCoordinatingCost
      ) {
        if (id === "CostTotalInternalOperations") {
          const temp1 = profile.CountrySpecs[0].MethodologySpecs.map((temp) => {
            return temp.CostingOptions;
          });
          const temp2 = temp1.map((temp) => {
            return temp.map((tem) => {
              return tem.CostsData?.CCOPSCoordinatingCost;
            });
          });
          let CCOPSCoordinatingCost =
            temp2.reduce((acc, val) => acc + parseFloat(val), 0) || 0;
          let finalVal = actualvalue + CCOPSCoordinatingCost;
          finalVal = finalVal.toFixed(2);
          return `${finalVal}`;
        }
        if (id === "CostTotalInternalCommercial") {
          const temp1 = profile.CountrySpecs[0].MethodologySpecs.map((temp) => {
            return temp.CostingOptions;
          });
          const temp2 = temp1.map((temp) => {
            return temp.map((tem) => {
              return tem.CostsData?.CCCSCoordinatingCost;
            });
          });
          let CCCSCoordinatingCost =
            temp2.reduce((acc, val) => acc + parseFloat(val), 0) || 0;
          let finalVal = actualvalue + CCCSCoordinatingCost;
          finalVal = finalVal.toFixed(2);
          return `${finalVal}`;
        }
      } else {
        let finalVal = actualvalue;
        return `${finalVal}`;
      }
    }
  };

  const getCurrentCurrencyOverall = (actualvalue, id, label) => {
    const temp = profile.CountrySpecs;
    const [firstElement, ...remainingElements] = temp;

    const commCountry = (firstElement) => {
      if (
        currencies &&
        currencies.length &&
        currentCurrency &&
        currentCurrency.ConversionRateToLocal
      ) {
        if (
          firstElement &&
          firstElement.MethodologySpecs[0] &&
          firstElement.MethodologySpecs[0].CostingOptions[0] &&
          firstElement.MethodologySpecs[0].CostingOptions[0].CostsData
          // profile.CountrySpecs[0].MethodologySpecs[0].CostingOptions[0].CostsData?.CCOPSCoordinatingCost
        ) {
          if (id === "CostTotalInternalOperations") {
            const temp1 = firstElement.MethodologySpecs.map((temp) => {
              return temp.CostingOptions;
            });
            const temp2 = temp1.map((temp) => {
              return temp.map((tem) => {
                return tem.CostsData?.CCOPSCoordinatingCost;
              });
            });
            let CCOPSCoordinatingCost =
              temp2.reduce((acc, val) => acc + parseFloat(val), 0) || 0;
            let finalVal =
              (actualvalue + CCOPSCoordinatingCost) *
              currentCurrency.ConversionRateToLocal;
            finalVal = Math.round(finalVal, 2);
            return `${finalVal} ${currentCurrency.CurrencyUnit}`;
          }
          if (id === "CostTotalInternalCommercial") {
            const temp1 = firstElement.MethodologySpecs.map((temp) => {
              return temp.CostingOptions;
            });
            const temp2 = temp1.map((temp) => {
              return temp.map((tem) => {
                return tem.CostsData?.CCCSCoordinatingCost;
              });
            });
            let CCCSCoordinatingCost =
              temp2.reduce((acc, val) => acc + parseFloat(val), 0) || 0;
            let finalVal =
              (actualvalue + CCCSCoordinatingCost) *
              currentCurrency.ConversionRateToLocal;
            finalVal = Math.round(finalVal, 2);
            return `${finalVal} ${currentCurrency.CurrencyUnit}`;
          }
        } else return 0;
      }
    };

    const fieldCountry = (remainingElements) => {
      if (
        currencies &&
        currencies.length &&
        currentCurrency &&
        currentCurrency.ConversionRateToLocal
      ) {
        if (
          firstElement &&
          firstElement.MethodologySpecs[0] &&
          firstElement.MethodologySpecs[0].CostingOptions[0] &&
          firstElement.MethodologySpecs[0].CostingOptions[0].CostsData
        ) {
          if (id === "CostTotalInternalOperations") {
            for (let ops of remainingElements) {
              if (actualvalue === ops.CostTotalInternalOperations) {
                let finalVal =
                  ops.CostTotalInternalOperations *
                  currentCurrency.ConversionRateToLocal;
                finalVal = Math.round(finalVal, 2);
                return `${finalVal} ${currentCurrency.CurrencyUnit}`;
              }
            }
          }
          if (id === "CostTotalInternalCommercial") {
            for (let ops of remainingElements) {
              if (actualvalue === ops.CostTotalInternalCommercial) {
                let finalVal =
                  ops.CostTotalInternalCommercial *
                  currentCurrency.ConversionRateToLocal;
                finalVal = Math.round(finalVal, 2);
                return `${finalVal} ${currentCurrency.CurrencyUnit}`;
              }
            }
          }
        } else return 0;
      }
    };

    if (
      (actualvalue === firstElement.CostTotalInternalCommercial &&
        label === firstElement.label) ||
      (actualvalue === firstElement.CostTotalInternalOperations &&
        label === firstElement.label)
    ) {
      let finalVal = commCountry(firstElement);
      return `${finalVal}`;
    } else {
      let finalVal = fieldCountry(remainingElements);
      return `${finalVal}`;
    }
  };

  // ----------------------------------------------------------------
  const getActualPrice = (actualvalue, withCurrency) => {
    if (
      currencies &&
      currencies.length &&
      currentCurrency &&
      currentCurrency.ConversionRateToLocal
    ) {
      if (actualvalue) {
        let finalVal = actualvalue * currentCurrency.ConversionRateToLocal;
        finalVal = Math.round(finalVal, 2);

        return withCurrency
          ? `${finalVal} ${currentCurrency.CurrencyUnit}`
          : finalVal;
      } else return 0;
    }
  };
  const getCurrencyUnit = (currencyInput) => {
    if (!currencyInput) currencyInput = "US-USD";
    let values = currencyInput.split("-");
    let currencyUnit = _.last(values);
    // let countryCode = _.head(values);
    return currencyUnit;
  };

  const getDisabledStatus = () => {
    if (project?.CommissioningCountry === "CN") {
      return true;
    } else if (project.CostingTemplateVersion != "v2.0") return false;
    else if (project.CostingTemplateVersion == "v2.0") {
      var inputStatus = profile.CountrySpecs?.every((cs) =>
        cs.MethodologySpecs?.every(
          (ms) => ms.CostingType == CostingTypesEnum.INPUTDIRECTLY
        )
      );
      if (
        inputStatus ||
        (profile.ProfileStatus == 1 &&
          project.ParentProjectId &&
          project.IsSyndicated &&
          !project.IsCopy)
      )
        return false;
    }
    return true;
  };

  const getApprovalCommentsBoxStatus = () => {
    let adStatus = true;
    let isDelegateUser = false;
    let approversList = [];
    const userEmail = userRecord.Email.toLowerCase();
    profile.ApprovalDetails.forEach((val) =>
      val.ApproverContacts.map((newVal) => {
        approversList.push(newVal.EmailAddress.toLowerCase());
        if (newVal?.delegateUsers.includes(userEmail) && !isDelegateUser) {
          isDelegateUser = true;
        }
      })
    );
    let otherContacts = [];
    project.OtherProjectTeamContacts.map((contactVal) => {
      otherContacts.push(contactVal.value.toLowerCase());
    });
    if (
      project.ProposalOwnerEmail?.value?.toString() === userRecord.Email ||
      _.includes(otherContacts, userEmail) ||
      _.includes(approversList, userEmail) ||
      isDelegateUser
    ) {
      adStatus = false;
    }
    return adStatus;
  };
  const getStatusforSendApproval = () => {
    let userStatus = true;

    if (
      userRecord.Email == project.ProposalOwnerEmail?.value ||
      project.ProposalOwnerEmail?.value == undefined
    ) {
      userStatus = false;
    } else if (
      (project.OtherProjectTeamContacts &&
        project.OtherProjectTeamContacts.length) ||
      project.OtherProjectTeamContacts.length == 0
    ) {
      if (
        _.includes(
          project.OtherProjectTeamContacts.map((otc) => otc.value),
          userRecord.Email
        ) ||
        project.OtherProjectTeamContacts.length == 0
      ) {
        userStatus = false;
      }
    } else if (
      profile.ProfileStatus > "2" ||
      profile.ApprovalLevelReached >= profile.ApprovalLevelNeeded ||
      !profile.PriceToClient
    ) {
      userStatus = false;
    }
    setIsBtnDisabled(userStatus);
  };
  useEffect(() => {
    getStatusforSendApproval();
  }, []);
  const getCurrentCurrencyUnit = (currencyInput) => {
    if (!currencyInput) currencyInput = "US-USD";
    let values = currencyInput.split("-");
    let currencyUnit = _.last(values);
    let countryCode = _.head(values);
    let finalCurrency = null;
    if (currencies && currencies.length) {
      finalCurrency = _.head(
        currencies.filter(
          (cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
        )
      );
      finalCurrency = {
        value: `${finalCurrency.Code}-${finalCurrency.CurrencyUnit}`,
        label: `${finalCurrency.Label} (1USD = ${finalCurrency.ConversionRateToLocal}${finalCurrency.CurrencyUnit})`,
      };
    }
    return finalCurrency;
  };

  const summaryCurrencyChange = (value) => {
    let editableprofile = {
      ...profile,
      CountrySpecs: [...profile.CountrySpecs],
      CostInputCurrency: value.value,
      currencies,
    };
    let reqValue = value.value;
    let values = reqValue.split("-");
    let currencyUnit = _.last(values);
    let countryCode = _.head(values);
    let finalCoversionUnit = "";
    if (currencies && currencies.length) {
      let finalCurr = _.head(
        currencies.filter(
          (cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
        )
      );
      setCurrentCurrency({ ...finalCurr });
      finalCoversionUnit = finalCurr.ConversionRateToLocal;
    }
    // editableprofile.CountrySpecs = editableprofile.CountrySpecs.map((cs) => {
    //   // let values = cs.CostInputCurrency ? cs.CostInputCurrency.split("-") : "US-USD".split("-");
    //   // let currencyunit = _.last(values);
    //   // let countrycode = _.head(values);
    //   // let dollarrate = _.head(currencies.filter((cur) => cur.Code == countrycode && cur.CurrencyUnit == currencyunit)).ConversionRateToLocal
    //   // Object.keys(costFields).map((cf) => {
    //   //   // cs[cf] = cs[cf] / dollarrate
    //   //   cs[cf] = cs[cf] / finalCoversionUnit
    //   // })
    //   return { ...cs, CostInputCurrency: reqValue };
    // });
    dispatch(currentCostingActions.updateProfile({ ...editableprofile }));
  };

  useEffect(() => {
    setStatusCheckProfit(false);
  }, []);

  useEffect(() => {
    let finalCostFields = {};
    let _methCountryCostSet = {};
    let _methCostFieldsSet = {};
    let _methLabels = {};
    if (
      !Object.keys(methCountryCostSet).length &&
      profile.CountrySpecs?.length &&
      !Object.keys(methCostFieldsSet).length &&
      profile.Project
    ) {
      _.head(profile.CountrySpecs)?.MethodologySpecs.map((ms) => {
        if (
          _.includes(shopperMethodologies, ms.Code) &&
          (!_.includes(pacificMarkets, profile.Project.CommissioningCountry) ||
            (_.includes(pacificMarkets, project.CommissioningCountry) &&
              project.CostingTemplateVersion === "v2.0"))
        ) {
          if (ms && ms.CostsData) {
            Object.keys(ms.CostsData).map((csch) => {
              _methLabels[ms.Code] = ms.Label;
              if (!_methCostFieldsSet[ms.Code])
                _methCostFieldsSet[ms.Code] = [];
              if (!_.includes(_methCostFieldsSet[ms.Code], csch))
                _methCostFieldsSet[ms.Code].push(csch);
            });
          }
        } else {
          Object.keys(ms.CalculationSchema).map((csch) => {
            if (!finalCostFields[csch]) {
              if (_.includes(includeOnlyInMCP, csch)) {
                if (profile.IsMultiCountry) finalCostFields[csch] = [];
              } else {
                finalCostFields[csch] = [];
              }
            }
            Object.keys(ms.CalculationSchema[csch]).map((insch) => {
              if (
                finalCostFields[csch] &&
                !_.includes(finalCostFields[csch], insch)
              ) {
                finalCostFields[csch].push(insch);
              }
            });
            if (additionalLabels && additionalLabels[csch]) {
              Object.keys(additionalLabels[csch]).map((lab) => {
                if (!_.includes(finalCostFields[csch], lab)) {
                  finalCostFields[csch].push(lab);
                }
              });
            }
          });
        }
      });
      profile.CountrySpecs.map((cs) => {
        cs.MethodologySpecs.filter(
          (ms) =>
            _.includes(shopperMethodologies, ms.Code) ||
            (_.includes(pacificMarkets, project.CommissioningCountry) &&
              project.CostingTemplateVersion != "v2.0")
        ).map((ms) => {
          if (ms) {
            if (!_methCountryCostSet[ms.Code])
              _methCountryCostSet[ms.Code] = {};
            _methCountryCostSet[ms.Code][cs.CountryCode] = ms.CostsData;
          }
        });
      });
      //console.log(_methCostFieldsSet, "_methCostFieldsSet")
      //console.log(_methCountryCostSet, "_methCountryCostSet")
      setMethCountryCostSet(_methCountryCostSet);
      setMethCostFieldsSet(_methCostFieldsSet);
      setMethLabels(_methLabels);
      setCostFields(finalCostFields);
    }
  }, [profile]);
  useEffect(() => {
    if (profile && profile.CostInputCurrency) {
      let values = profile.CostInputCurrency.split("-");
      let currencyUnit = _.last(values);
      let countryCode = _.head(values);
      if (currencies) {
        setCurrentCurrency(
          _.head(
            currencies.filter(
              (cur) =>
                cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
            )
          )
        );
      }
    }
  }, [profile, currencies]);
  const updateProfileSetting = (eve) => {
    if (eve?.target?.id) {
      // let _profile = { ...profile, ProfileSetting: { ...profile.ProfileSetting, [eve.target.id]: eve.target.value } }

      setTempProfileSetting({
        ...tempProfileSetting,
        [eve.target.id]: eve.target.value,
      });
    }
  };
  const ApplyOOPMultiplierChanges = async () => {
    // dispatch(currentCostingActions.updateProfile({ ...profile, ProfileSetting: { ...profile.ProfileSetting, ...tempProfileSetting } }, () => {
    setApplyingMultiplier(true);
    let _profile = {
      ...profile,
      ProfileSetting: { ...profile.ProfileSetting, ...tempProfileSetting },
    };
    _profile = await currentCostingActions.generateSummary(
      _profile,
      pacificMarkets
    );
    dispatch(
      currentCostingActions.saveCostingProfileDraft(_profile, () => {
        if (_profile.PriceToClient)
          dispatch(
            currentCostingActions.generateProfitability(_profile.PriceToClient)
          );
        setTimeout(() => {
          setApplyingMultiplier(false);
          setOOPMutliplierModal(false);
        });
      })
    );
    // }))
  };
  // useEffect(() => {
  //   if (profile.id) {
  //     dispatch(currentCostingActions.generateProfitability());
  //   }
  // }, []);
  const alternativeLabels = {
    CostTotalInternalCommercial: {
      UsesOopOverrideIntCommCost: "CostIntCommMultiplier",
      // Value: "CostTotalExternalOperations"
    },
    CostTotalInternalOperations: {
      UsesOopOverrideIntOpsCost: "CostIntOpsMultiplier",
      // Value: "CostTotalExternalOperations"
    },
  };
  const additionalLabels = {
    CostTotalExternalOperations: {
      CostExtOpsMCPSubContract: "MCP/Group Company Sub-Contracting",
      // CostExtOpsOtherTaxVAT: "VAT and Other Tax Adjustment",
    },
  };
  const shopperCounter = {
    CostTotalExternalOperations: "shopperCostTotalExternalOperations",
    CostTotalInternalOperations: "shopperCostTotalInternalOperations",
    CostTotalInternalCommercial: "shopperCostTotalInternalCommercial",
  };
  const costlabels = {
    CostExtOpsMCPSubContract: "MCP/Group Company Sub-Contracting",
    CostExtOpsOtherTaxVAT: "VAT and Other Tax Adjustment",
    CostTotalExternalOperations: `Total External Operations Costs ${
      profile.IsMultiCountry ? "(OOP Excluding MCP margin)" : "(OOP)"
    }`,
    CostExtOpsInterviewers: "Interviewers - Temporaries & Subcontractors",
    CostExtOpsDCQCDPSP: "External DC/Coding/QC/DP/Programming/Scripting",
    CostExtOpsTE: "Travel, Lodging and Entertainment",
    CostExtOpsOthers: "External Others",
    CostExtOpsIncentives: "Incentives/Respondent Fees",
    CostExtOpsConsultantVendor: "External Consultant/Vendor",
    CostExtOpsPrintingStationery: "Printing/Stationery",
    CostExtOpsFreightShipping: "Freight/Shipping",
    CostExtOpsVenueHireRecruitment:
      "Venue/Hire/Recruitment and Other Misc. Externals",
    CostTotalInternalOperations: "Total Internal Operations Costs",
    CostIntOpsFieldPMQC: "Internal Time Field, Project Management & QC",
    CostIntOpsOthers: "Internal Other Ops",
    CostIntOpsProgramming: "Internal Time Programming",
    CostIntOpsDPCodingAnalysis: "Internal Time Data Processing/Coding/Analysis",
    CostTotalExternalCommercial: "Total External Commercial Costs",
    CostExtCommTE: "Travel, Lodging and Entertainment",
    CostExtCommConsultant: "External consultant/report writing",
    CostExtCommOthers: "CS Other Expenses",
    CostTotalInternalCommercial: "Total Internal Commercial Costs",
    CostExtOpsOtherTaxVAT: "VAT and Other Tax Adjustment",
    CostTotalMCPMargin: "Total MCP Margin Cost",
    MCPLocalCountryExtra: "MCP Local Margin",
    CostExtOpsMiscelleneous: "Total Miscelleneous cost",
    CostExtOpsFieldCostOthers: "Total other costs",
    CostsExtOpsSUD: "Set-Up & Delivery Costs",
    CostIntOpsSUDCosts: "Set-Up & Delivery Time Costs",
  };
  const costIntCommLabels = {
    CostIntCommExecDirector: "Executive Director",
    CostIntCommDirector: "Director",
    CostIntCommAssociateDirector: "Associate Director",
    CostIntCommSeniorManager: "Senior Manager",
    CostIntCommManager: "Manager",
    CostIntCommSeniorExecutive: "Senior Executive",
    CostIntCommExecutive: "Executive",
    CostIntCommDataScience: "Data Science",
  };
  const rateCardReferences = {
    "Executive Director": "ExecutiveDirector",
    Director: "Director",
    "Associate Director": "AssociateDirector",
    "Senior Manager": "SeniorManager",
    Manager: "Manager",
    "Senior Executive": "SeniorExecutive",
    Executive: "Executive",
    "Data Science": "DatascienceInternalComm",
  };
  // const schema = commercialTimeSchema.default;
  // const bands = schema.bands ? schema.bands : []
  // [
  //   "Executive Director",
  //   "Director",
  //   "Associate Director",
  //   "Senior Manager",
  //   "Manager",
  //   "Senior Executive",
  //   "Executive",
  //   "Data Science",
  // ];
  const [allTabsStatus, setTabStatus] = useState({
    projectDetails: true,
    methSummary: false,
    opsResources: false,
    clientServices: false,
    costBreakdown: false,
    shoppercostBreakdown: false,
    profOverview: true,
    costingOptionsSec: false,
    responsibilitiesSection: false,
    timecost: false,
    approvals: true,
    overall: false,
  });
  const [oopMultiplierModal, setOOPMutliplierModal] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  useEffect(() => {
    if (profile && !Object.keys(rfqData).length && profile.CountrySpecs) {
      let finalData = {};
      let _allRFQSchema = {};
      profile.CountrySpecs.map((cs) => {
        cs.MethodologySpecs.filter((ms) => !ms.NotApplicable).map((ms) => {
          if (!_allRFQSchema[ms.Code]) _allRFQSchema[ms.Code] = ms.RFQSchema;
          if (!finalData[cs.CountryCode]) {
            finalData[cs.CountryCode] = {};
          }
          if (!finalData[cs.CountryCode][ms.Code]) {
            finalData[cs.CountryCode][ms.Code] = {};
          }
          finalData[cs.CountryCode][ms.Code] = ms.RFQData;
        });
      });
      setAllRFQSchema(_allRFQSchema);
      setRfqData(finalData);
      let opsFinal = {};
      let _allOpsSchema = {};
      profile.WaveSpecs.map((ws) => {
        if (
          ws.OpsResourcesSchema &&
          Object.keys(ws.OpsResourcesSchema).length
        ) {
          Object.keys(ws.OpsResourcesSchema.properties).map((_prop) => {
            if (!_allOpsSchema[_prop])
              _allOpsSchema[_prop] = ws.OpsResourcesSchema.properties[_prop];
          });
        }
        opsFinal[ws.id] = ws.OpsResourcesData;
      });
      setAllOpsSchema(_allOpsSchema);
      setOpsData(opsFinal);
    }
  }, [profile]);
  const getOpsTitleProperty = (prop) => {
    // let waveSpecs = profile.WaveSpecs
    // let allProperties = {}
    // waveSpecs.map(ws => {
    // 	if (ws.OpsResourcesSchema && Object.keys(ws.OpsResourcesSchema).length) {
    // 		Object.keys(ws.OpsResourcesSchema).map(_prop => {
    // 			if (!allProperties[_prop])
    // 				allProperties[_prop] = ws.OpsResourcesSchema[_prop]
    // 		})
    // 	}
    // })
    return allOpsSchema[prop] ? allOpsSchema[prop] : null;
  };
  const getProperty = (schema, prop) => {
    if (schema && schema.properties && schema.properties[prop])
      return schema.properties[prop];
    else {
      let reqVal = "";
      if (schema?.dependencies) {
        Object.keys(schema.dependencies).map((dep) => {
          schema.dependencies[dep].oneOf.map((oo) => {
            if (oo.properties[prop] && oo.properties[prop].title) {
              reqVal = oo.properties[prop];
            }
          });
        });
      }
      return reqVal;
    }
  };
  const getResponsibilityProperty = (schema, prop) => {
    if (schema && prop) {
      return schema[prop];
    }
    return prop;
  };

  const checkProjectType = (profile) => {
    let adhoch = false;
    let projectType;
    let checkApproval = true;

    if (!profile?.CostingsSheetId) {
      if (
        profile?.CountrySpecs.some((cs) =>
          cs?.MethodologySpecs.some((ms) =>
            ms.CostingOptions.filter((co) => co.Selected).some(
              (co) =>
                co.CostingOptionData &&
                co.CostingOptionData.trackingProject &&
                co.CostingOptionData.trackingProject == "Yes"
            )
          )
        )
      ) {
        projectType = "Tracking";
        checkApproval = true;
      } else {
        projectType = "Adhoc";
        adhoch = true;
      }
    } else if (profile?.CostingsSheetId) {
      if (
        profile?.CountrySpecs.every((cs) =>
          cs?.MethodologySpecs.every((ms) =>
            ms.CostingOptions.every((co) => co.CoSelectedForSummary != null)
          )
        )
      ) {
        if (
          profile?.CountrySpecs.some((cs) =>
            cs?.MethodologySpecs.some((ms) =>
              ms.CostingOptions.filter((co) => co.CoSelectedForSummary).some(
                (co) =>
                  co.CostingOptionData &&
                  co.CostingOptionData.trackingProject &&
                  co.CostingOptionData.trackingProject == "Yes"
              )
            )
          )
        ) {
          projectType = "Tracking";
        } else {
          projectType = "Adhoc";
          adhoch = true;
        }
      } else {
        checkApproval = false;
        toastr.error("Plese sync the cost from sheet for profitablity check");
        return checkApproval;
      }
    }

    if (adhoch) {
      projectType = "Adhoc";
      // let products = profile.ProductType.split(",")
      let products = [];
      while (typeof profile.ProductTableData === "string") {
        profile.ProductTableData = JSON.parse(profile.ProductTableData);
      }
      profile.ProductTableData.map((data) => {
        if (!data.isRed) {
          products.push(data.MaterialID);
        } else if (data.isRed && data.isRed !== true) {
          products.push(data.MaterialID);
        }
      });

      let TbProducts = [
        "CMI-TB BRAND",
        "CMI-TB CONSUMER",
        "CMI-TB CX",
        "CMI-TB OTHER",
        "CMI-TB SHPPRTREND",
        "CMI-TB COMMS",
        "CMI TB BRAND",
        "CMI TB CONSUMER",
        "CMI TB CX",
        "CMI TB OTHER",
        "CMI TB SHPPRTREND",
        "CMI TB COMMS",
      ];
      let TbProductsAva = products.some((item) => TbProducts.includes(item));
      //  || TbProductsAva == true

      if (
        !(
          products.includes("CMI-CNSMR EPS") ||
          products.includes("CMI CNSMR EPS") || TbProductsAva == true
        )
      ) {
        if (profile.MarginPercent * 100 < 1) {
          if (!profile.ProfileSetting.AdhocLessThanOnePercent) {
            checkApproval = false;
            toastr.error(
              "CAUTION Project EBITDA<1%. Project profitability need to be reviewed. Project must be at least 1% and above to qualify OR go through the approval process"
            );
          } else {
            checkApproval = true;
            // dispatch(
            //   currentCostingActions.generateProfitability(
            //     profile.PriceToClient,projectType
            //   )
            // )
          }
        } else {
          checkApproval = true;
        }
      } else {
        checkApproval = true;
      }
    } else {
      // if (profile.MarginPercent*100< 1) {
      //   checkApproval = false
      //   dispatch(
      //     currentCostingActions.generateProfitability(
      //       profile.PriceToClient,projectType
      //     )
      //   )
      // }
      // else {
      // }
      checkApproval = true;
    }

    console.log(projectType);
    return checkApproval;
  };
  const getResponsibilityValue = (schema, prop) => {
    if (schema && schema[prop]) {
      if (schema[prop].value) return schema[prop].value;
      else if (schema[prop].enum) {
        let val = _.head(schema[prop].enum.filter((e) => e.selected));
        return val ? val.label : "-";
      }
    }
    return "-";
  };
  const getOpsValue = (value, prop) => {
    if (value || value == false) {
      if (prop.toLowerCase().indexOf("complexity") != -1) {
        if (Array.isArray(value)) value = _.head(value);
        if (typeof value == "object") value = value.value;
        if (prop == "surveyProgrammingComplexity") {
          return _.head(
            codeLabels.QuestionnaireComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
        if (prop == "dataProcessingComplexity") {
          return _.head(
            codeLabels.DataProcessingComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
        if (prop == "chartingComplexity") {
          return _.head(
            codeLabels.ChartingComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
      } else {
        if (Array.isArray(value)) {
          if (typeof _.head(value) == "string") return value.join();
          else return value.map((v) => v.label).join();
        } else if (typeof value == "boolean") {
          return value ? "Yes" : "No";
        } else return value;
      }
    } else {
      return "-";
    }
  };

  const getOOPThresholdText = () => {
    if (
      !profile.ApprovalDetails ||
      (profile.ApprovalDetails && !profile.ApprovalDetails[0])
    ) {
      return null;
    }
    if (
      profile.ApprovalLevelNeeded === -1 ||
      (_.includes(pacificMarkets, project.CommissioningCountry) &&
        project.CostingTemplateVersion !== "v2.0")
    ) {
      return `less than ${
        profile.ApprovalDetails[0].ThresholdOutOfPocketPercentage * 100
      }%`;
    } else {
      let index = profile.ApprovalLevelNeeded ? profile.ApprovalLevelNeeded : 0;
      return `greater than ${
        profile.ApprovalDetails[index].ThresholdOutOfPocketPercentage * 100
      }% (Price To Client > ${getActualPrice(
        profile.ApprovalDetails[index].ThresholdRevenueAmount,
        true
      )})`;
    }
  };

  const getOOPThresholdTextforPriceChange = () => {
    if (
      !profile.ApprovalDetails ||
      (profile.ApprovalDetails && !profile.ApprovalDetails[0])
    ) {
      return null;
    }
    if (profile.ApprovalLevelNeeded === -1) {
      return `greater than ${
        profile.ApprovalDetails[0].ThresholdOutOfPocketPercentage * 100
      }%  and less than ${
        profile.ApprovalDetails[0].ThresholdOutOfPocketPercentageR2 * 100
      }% (Price To Client > ${getActualPrice(
        profile.ApprovalDetails[0].ThresholdRevenueAmount,
        true
      )})`;
    } else {
      let index = profile.ApprovalLevelNeeded ? profile.ApprovalLevelNeeded : 0;
      return `greater than ${
        profile.ApprovalDetails[index].ThresholdOutOfPocketPercentage * 100
      }% and less than ${
        profile.ApprovalDetails[index].ThresholdOutOfPocketPercentageR2 * 100
      }% (Price To Client > ${getActualPrice(
        profile.ApprovalDetails[index].ThresholdRevenueAmount,
        true
      )})`;
    }
  };
  const getOOPThresholdTextR2 = () => {
    if (
      !profile.ApprovalDetails ||
      (profile.ApprovalDetails && !profile.ApprovalDetails[0])
    ) {
      return null;
    }
    if (profile.ApprovalLevelNeeded === -1) {
      if (
        profile.ApprovalDetails[0].ThresholdOutOfPocketPercentageR2 &&
        profile.ApprovalDetails[0].ThresholdRevenueAmountR2
      ) {
        return ` less than ${
          profile.ApprovalDetails[0].ThresholdOutOfPocketPercentageR2 * 100
        }% (Price To Client > ${getActualPrice(
          profile.ApprovalDetails[0].ThresholdRevenueAmountR2,
          true
        )})`;
      } else {
        return null;
      }
    } else {
      let index = profile.ApprovalLevelNeeded ? profile.ApprovalLevelNeeded : 0;
      if (
        profile.ApprovalDetails[index].ThresholdOutOfPocketPercentageR2 &&
        profile.ApprovalDetails[index].ThresholdRevenueAmountR2
      ) {
        return `less than ${
          profile.ApprovalDetails[index].ThresholdOutOfPocketPercentageR2 * 100
        }% (Price To Client > ${getActualPrice(
          profile.ApprovalDetails[index].ThresholdRevenueAmountR2,
          true
        )})`;
      } else {
        return null;
      }
    }
  };

  const getOOPThresholdTextR3 = () => {
    if (
      !profile.ApprovalDetails ||
      (profile.ApprovalDetails && !profile.ApprovalDetails[0])
    ) {
      return null;
    }
    if (profile.ApprovalLevelNeeded === -1) {
      if (
        profile.ApprovalDetails[0].ThresholdMarginPercentageR3 &&
        profile.ApprovalDetails[0].ThresholdRevenueAmountR3
      ) {
        return `less than ${
          profile.ApprovalDetails[0].ThresholdMarginPercentageR3 * 100
        }% (Price To Client > ${getActualPrice(
          profile.ApprovalDetails[0].ThresholdRevenueAmountR3,
          true
        )})`;
      } else {
        return null;
      }
    } else {
      let index = profile.ApprovalLevelNeeded ? profile.ApprovalLevelNeeded : 0;
      if (
        profile.ApprovalDetails[index].ThresholdMarginPercentageR3 &&
        profile.ApprovalDetails[index].ThresholdRevenueAmountR3
      ) {
        return `less than ${
          profile.ApprovalDetails[index].ThresholdMarginPercentageR3 * 100
        }%  (Price To Client > ${getActualPrice(
          profile.ApprovalDetails[index].ThresholdRevenueAmountR3,
          true
        )})`;
      } else {
        return null;
      }
    }
  };

  const chooseCostMethod = (method) => {
    dispatch({
      type: currentCostingActions.UPDATE_NEW_COSTING,
      currentCostingProfile: { CostingType: method },
    });
    switch (method) {
      case "VENDOR":
        //console.log("VENDOR");
        setShowCostMethod(false);
        return;
      case "SHEETS":
        //console.log("SHEETS");
        setShowCostMethod(false);
        return;
      case "DEFAULT":
        setShowManualCostEntry(true);
        setShowCostMethod(false);
        return;
    }
  };
  const selectCostInputModal = () => {
    return (
      <Modal
        isOpen={showCostMethod}
        toggle={() => setShowCostMethod(!showCostMethod)}
        centered={true}
        size={"lg"}
        backdrop={"static"}
      >
        <ModalHeader toggle={() => setShowCostMethod(!showCostMethod)}>
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <div>
          <span className="modaltitle modaltitleDisplay">
            Select Costing Method
          </span>
        </div>
        <ModalBody>
          <h5>
            Please Note: You can only select one costing method per profile.
            Some options may not be available depending on your market's default
            settings.
          </h5>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => chooseCostMethod("VENDOR")}>
            Trigger Vendor Bidding
          </Button>{" "}
          <Button onClick={() => chooseCostMethod("SHEETS")}>
            Use MS Sheets
          </Button>{" "}
          <Button onClick={() => chooseCostMethod("DEFAULT")}>
            Input Cost Directly
          </Button>{" "}
        </ModalFooter>
      </Modal>
    );
  };

  const [errorObj, setErrorObj] = useState({});
  const validateForms = (label) => {
    let isValid = true;

    let errorFieldsObj = {};

    let countrySpecs = [...profile.CountrySpecs];
    var allCostingOptionsUsed = [];

    let unavialable = [];
    let methodologyObj = {};
    let methodologyCountryObj = {};
    let isFieldsValid = true;

    countrySpecs.map((cs) => {
      if (
        cs.MethodologySpecs &&
        _.includes(profile.FieldingCountries, cs.CountryCode)
      ) {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)?.map((ms) => {
          methodologyObj[ms.id] = ms.Label;
          methodologyCountryObj[ms.id] = cs.CountryName;
          ms.CostingOptions?.map((co) => {
            co.CostingOptionSchema?.map((cps) => {
              if (!_.includes(allCostingOptionsUsed, cps.id) && cps.id) {
                allCostingOptionsUsed.push(cps.id);
              }
            });
          });
          if (ms?.RFQSchema?.required) {
            if (!ms.RFQData) {
              isValid = false;
              isFieldsValid = false;
              unavialable = ms.RFQSchema?.required;
            } else {
              let allRequiredFields = ms.RFQSchema?.required;
              let _allRequiredFields = allRequiredFields.filter(
                (arf) => ms.RFQSchema?.properties[arf]?.dependentOn
              );
              for (let field of _allRequiredFields) {
                let parent = _.head(
                  ms.RFQSchema?.properties[field].dependentOn.fields
                ).fieldName;
                parent = ms.Variables.find((v) => v.label === parent);
                if (!ms.RFQData[parent] || ms.RFQData[parent] !== "Yes") {
                  allRequiredFields = allRequiredFields.filter(
                    (rf) => rf !== field
                  );
                }
              }
              let existingProperties = Object.keys(ms.RFQSchema?.properties);
              let finalFields = allRequiredFields?.filter((arf) =>
                _.includes(existingProperties, arf)
              );
              unavialable = finalFields?.filter(
                (req) => req && (!ms.RFQData[req] || ms.RFQData[req] == "0")
              );
              let haveInCostingOptions = ms.CostingOptions?.map(
                (co) => co?.CostingOptionData
              )?.filter(
                (co) =>
                  finalFields
                    ?.map((ff) => co && co[ff] && co[ff] != 0)
                    .filter((f) => f).length
              );
              if (unavialable.length && !haveInCostingOptions.length) {
                isValid = false;
                isFieldsValid = false;
              }
            }
          }
          if (!isFieldsValid) {
            let fields = unavialable.map(
              (obj) => ms.RFQSchema["properties"][obj]?.title
            );
            if (!errorFieldsObj[cs.CountryName])
              errorFieldsObj[cs.CountryName] = {};

            if (!errorFieldsObj[cs.CountryName].RFQ)
              errorFieldsObj[cs.CountryName].RFQ = {};

            if (!errorFieldsObj[cs.CountryName].RFQ[ms.Label])
              errorFieldsObj[cs.CountryName].RFQ[ms.Label] = {};

            errorFieldsObj[cs.CountryName].RFQ[ms.Label] = fields;
          }
          isFieldsValid = true;
        });
      }
    });

    if (profile?.WaveSpecs?.length) {
      let validMethIds = [];
      profile.CountrySpecs?.map((cs) => {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)
          ?.map((ms) => ms.id)
          ?.map((ms) => {
            if (!_.includes(validMethIds, ms)) validMethIds.push(ms);
          });
      });
      let wavespecs = [
        ...profile.WaveSpecs?.filter((ws) =>
          _.includes(validMethIds, ws.MethodologySpecId)
        ),
      ];
      let ws = {};
      if (wavespecs.length) {
        wavespecs.map((ws) => {
          // ws = _.head(wavespecs)
          let finalPropsToValidate = [];
          if (ws.OpsResourcesSchema && ws.OpsResourcesSchema.required) {
            ws.OpsResourcesSchema.required.map((prop) => {
              let currentProp = ws.OpsResourcesSchema.properties[prop];

              if (
                currentProp &&
              (!currentProp.dependentOn ||
              !currentProp.disabled) &&
              !currentProp.selected
              ) 
              {
                if (
                  prop === "numberOfOpenEnded" &&                 
                  ws.OpsResourcesData &&
                  ws.OpsResourcesData["numberOfSemiOpenEnded"] >= 0
                )
                return;
                if (
                  prop === "numberOfSemiOpenEnded" &&               
                  ws.OpsResourcesData &&
                  ws.OpsResourcesData["numberOfSemiOpenEnded"] >= 0
                )
                  return;
                if (
                  (!_.includes(opsResourcesVariables, prop) &&
                    !_.includes(allCostingOptionsUsed, prop)) ||
                  (_.includes(opsResourcesVariables, prop) &&
                    userRecord.CanEditOpsResources)
                )
                  if (
                    prop === "surveyProgrammingResource" &&
                    ws.OpsResourcesData &&
                    ws.OpsResourcesData["surveyProgrammingRequired"] === "No"
                  )
                    return;
                finalPropsToValidate.push(prop);
              } else {
                if (
                  currentProp?.dependentOn?.fields?.length &&
                  currentProp &&
                  !currentProp.disabled &&
                  !currentProp.selected
                ) {
                  currentProp?.dependentOn?.fields?.map((_dependentField) => {
                    let depProp = _dependentField;
                    if (depProp) {
                      let parentProp = {};
                      Object.keys(ws.OpsResourcesSchema.properties).map(
                        (pp) => {
                          if (
                            ws.OpsResourcesSchema.properties[pp].title ==
                            depProp.fieldName
                          ) {
                            parentProp = pp;
                          }
                        }
                      );
                      if (
                        prop === "numberofExcelFile" &&
                        parentProp === "chartingComplexity" &&
                        ws.OpsResourcesData &&
                        ws.OpsResourcesData["chartingComplexity"] !==
                          depProp.fieldValue
                      )
                        return;
                      if (
                        prop === "numberOfOpenEnded" &&
                        (parentProp === "surveyProgrammingRequired" ||
                          parentProp === "dataProcessingRequired") &&
                        ws.OpsResourcesData &&
                        ws.OpsResourcesData["numberOfSemiOpenEnded"] >= 0
                      )
                        return;
                      if (
                        prop === "numberOfSemiOpenEnded" &&
                        (parentProp === "surveyProgrammingRequired" ||
                          parentProp === "dataProcessingRequired") &&
                        ws.OpsResourcesData &&
                        ws.OpsResourcesData["numberOfSemiOpenEnded"] >= 0
                      )
                        return;
                      if (
                        prop === "numberOfOpenEndedToCode" &&
                        ws.OpsResourcesData &&
                        ws.OpsResourcesData["codingRequired"] === "No"
                      )
                        return;

                      if (
                        parentProp &&
                        ws.OpsResourcesData &&
                        ws.OpsResourcesData[parentProp] &&
                        ws.OpsResourcesData[parentProp] != "No" &&
                        !_.includes(finalPropsToValidate, prop)
                      ) {
                        if (
                          (!_.includes(opsResourcesVariables, prop) &&
                            !_.includes(allCostingOptionsUsed, prop)) ||
                          (_.includes(opsResourcesVariables, prop) &&
                            userRecord.CanEditOpsResources)
                        ) {
                          finalPropsToValidate.push(prop);
                        }
                      }
                    }
                  });
                }
              }
            });
          }
          let finalErrorFields = [];
          finalPropsToValidate.map((ftv) => {
            if (
              !ws.OpsResourcesData ||
              (ws.OpsResourcesData &&
                (!ws.OpsResourcesData[ftv] || ws.OpsResourcesData[ftv] == "0"))
            ) {
              let excludeError = [
                "numberOfOpenEnded",
                "numberOfSemiOpenEnded",
                "numberOfOpenEndedToCode",
              ];
              
              if (!excludeError.includes(ftv)) {
                isValid = false;              
                isFieldsValid = false;              
                finalErrorFields.push(ws.OpsResourcesSchema.properties[ftv].title);
              }
            }
          });
          if (!isFieldsValid) {
            if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]])
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]] = {};

            if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD)
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD =
                {};

            if (
              !errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                methodologyObj[ws.MethodologySpecId]
              ]
            )
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                methodologyObj[ws.MethodologySpecId]
              ] = {};

            errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
              methodologyObj[ws.MethodologySpecId]
            ] = finalErrorFields;
          }
          isFieldsValid = true;
        });
      }
    }

    if (!isValid) {
      if (document.getElementById("RFQFinalForm")) {
        document.getElementById("RFQFinalForm").requestSubmit();
        document.getElementById("OpsResourcesFinalForm")?.requestSubmit();
      }
      //console.log(errorFieldsObj);
      setErrorObj(errorFieldsObj);
      if (label == "commission"){
        setisCommission(true)
      } else {
        setisCommission(false)
      }
      handleOpen(true);
    }
    return isValid;
  };
  const checkForReset = () => {
    setIsBtnDisabled(false);
    if (profile.ProfileStatus == "2") {
      dispatch(
        currentCostingActions.updateProfile({
          ProfileStatus: "1",
        })
      );
    } else if (
      profile.ProfileStatus == "3" ||
      profile.ProfileStatus == "5" ||
      profile.ProfileStatus == "4"
    ) {
      setResetModal(true);
      resetFlag = true;
    }
  };
  const validateApprovalForm = () => {
    if (!validateForms()) {
      return false;
    }

    let isValid = false;
    let invalidFieldsMsg = {};
    if (
      profile &&
      profile.ProfileSetting &&
      profile.ProfileSetting.ShowDetailedApprovalForm
    ) {
      const mandatoryFields = [
        "newRenewalProject",
        "clientPaymentTerms",
        "billingContractSignature",
        "localTeamsAligned",
        "executionRisk",
        "justifyIntent",
        "reasonForOOP",
        "requestDate",
      ];
      let invalidFields = [];
      if (profile.ApprovalFormData)
        invalidFields = mandatoryFields.filter(
          (mf) =>
            profile.ApprovalFormData[mf] == null ||
            profile.ApprovalFormData[mf] == undefined ||
            profile.ApprovalFormData[mf] === ""
        );
      else invalidFields = mandatoryFields;

      if (invalidFields.length) {
        invalidFields.map((ifield) => {
          invalidFieldsMsg[ifield] = true;
        });

        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      if (
        profile.ApprovalJustification &&
        profile.ApprovalJustification.trim().length > 0
      )
        isValid = true;
    }
    setApprovalValidation(invalidFieldsMsg);
    return isValid;
  };
  const getOopDashboardStatus = () => {
    return validBusinessUnitsForOOP
      ?.map((vb) => _.includes(userRecord.BusinessUnits?.split(","), vb))
      ?.filter((c) => c)?.length;
  };

  const SaveAndSendProfileCommunication = async () => {
    let body = {
      CostingProfileId: profile.id,
      Comment: profileCommunicationMessage,
    };
    dispatch(pageLoadStart());
    let isPosted = await axios.post("/utils/profile-communication-board", body);
    if (isPosted) {
      dispatch(pageLoadEnd());
      FetchProfileCommunicationBoard(profile.id);
      setProfileCommunicationMessage("");
      toastr.success("Message Sent");
    } else {
      dispatch(pageLoadEnd());
      toastr.error("Something went wrong");
    }
  };

  const FetchProfileCommunicationBoard = async (profileId) => {
    dispatch(pageLoadStart());
    let messages = await axios.get(
      `/utils/profile-communication-board/${profileId}`
    );
    if (messages && messages.data && messages.data.board) {
      setProfileCommunicationMessages(messages.data.board);
      dispatch(pageLoadEnd());
    } else {
      setProfileCommunicationMessages([]);
      dispatch(pageLoadEnd());
    }
  };
  const canRenderTotalBreakdown = () => {
    //normal breakdown applies for shopper
    if (
      (_.includes(pacificMarkets, project.CommissioningCountry) &&
        project.CostingTemplateVersion != "v2.0") ||
      project.CostingTemplateVersion == "v2.0"
    )
      return true;

    let nonShopper = [];
    profile.CountrySpecs.map((cs) =>
      cs.MethodologySpecs.map((ms) => {
        if (
          !_.includes(shopperMethodologies, ms.Code) ||
          (_.includes(pacificMarkets, project.CommissioningCountry) &&
            project.CostingTemplateVersion !== "v2.0")
        )
          nonShopper.push(ms);
      })
    );
    return nonShopper.length ? true : false;
  };
  const canRenderShopperBreakdown = () => {
    //normal breakdown applies for shopper
    if (
      (_.includes(pacificMarkets, project.CommissioningCountry) &&
        project.CostingTemplateVersion != "v2.0") ||
      project.CostingTemplateVersion == "v2.0"
    )
      return false;

    let Shopper = [];
    profile.CountrySpecs.map((cs) =>
      cs.MethodologySpecs.map((ms) => {
        if (
          _.includes(shopperMethodologies, ms.Code) &&
          ms.CostsData &&
          Object.keys(ms.CostsData).length
        )
          Shopper.push(ms);
      })
    );
    return Shopper.length ? true : false;
  };
  const continueWithApproval = () => {
    // if(project.ContractDetails.some(cd=>cd.OpportunityNumber)|| project.IsSyndicated){
    //   let index =0
    //   let isValid = true
    //   let contactDetails= [];
    //   if(project.IsSyndicated){
    //       contactDetails =  project.ContractDetails;
    //   } else {
    //       contactDetails =  project.ContractDetails.filter(cd=>cd.OpportunityNumber)
    //       const filter = contactDetails.map(c => ({
    //         ...c,
    //         opportunityLineItemDetails: c.opportunityLineItemDetails.filter((item) => {
    //                     if (
    //                     (item.MaterialID?.slice(0,4).toLowerCase() === "cmi-" || item.MaterialID?.slice(0,3).toLowerCase() === "ci-" || item.MaterialID?.slice(0,4).toLowerCase().replace(' ','') === "tbc" || item.MaterialID?.slice(0,3).toLowerCase().replace(' ','') === "exp") && (item.CharacteristicValues !== null)) {
    //                       return true;
    //                     } else {
    //                       return false;
    //                     }
    //         })
    //       }));
    //         contactDetails = filter;
    //   }
     
    //   if(project.IsSyndicated){



    //     if (contactDetails.some(cd => cd.opportunityLineItemDetails) && contactDetails.every(cd=>cd.opportunityLineItemDetails.every(ol=>ol.WBSNumber)))
    //     {
    //       setStatusCheckApproval(true)
    //       profile.ProfileStatus = "2"
    //       setstatusClickedApproval(false)
    //     }
    //     else{
    //       toastr.error("Cannot Proceed to Commission", 
    //       "WBS not found. Please go to Project Details page and input the WBS to continue.");
    //     }
    //   }
    //   else{
    //     for(let contactDetail of contactDetails){
        
    //     if(contactDetail.OpportunityNumber)
    //       dispatch(
    //         currentProjectActions.msdRefresh(project?.id, contactDetail,()=>{
              
    //           index ++
            
    //         let _isValid = msdValidation("approval")

    //         if(!_isValid)
    //         {
    //           isValid =_isValid
    //         }
    //         if(isValid && index == contactDetails.length)
    //         {
    //           setStatusCheckApproval(true)
    //           profile.ProfileStatus = "2"
    //           setstatusClickedApproval(false)
    //         }  
    //         else if(!isValid && index == contactDetails.length){
    //         setIsOpen({
    //             ...isOpen,
    //             MSDValidation: true,
    //           })}
                      
    //           console.log("Msd Refreshed for ",contactDetail.OpportunityNumber)
    //         },true,history));
    //       }
    //   }
    
    // }
    // else{
    //     toastr.error("Cannot Proceed to Commission", 
    //     "WBS not found. Please go to Project Details page and input the WBS to continue.");
    // }
    setStatusCheckApproval(true)
    profile.ProfileStatus = "2"
    setstatusClickedApproval(false)
  }
  //todo:optimize this logic- written for time being
  const getCountryLevelValue = (summaryKey, csCode) => {
    let total = 0;
    let requiredCountry = profile.CountrySpecs.find(
      (cs) => cs.CountryCode == csCode
    );
    requiredCountry.MethodologySpecs?.map((ms) => {
      ms.CostingOptions?.map((copt) => {
        if (copt && copt.CoSelectedForSummary) {
          let _costData = copt.CostsData
            ? copt.CostsData
            : copt.SelectedVendorCost
            ? copt.SelectedVendorCost
            : {};
          total =
            total +
            parseFloat(_costData[summaryKey] ? _costData[summaryKey] : 0);
        }
      });
    });

    return getCurrentCurrency(total);
  };

  //to refresh costs
  function refreshCostingProfile(costingProfileID) {
    // dispatch(pageLoadStart())
    // dispatch(currentCostingActions.saveCostingProfile(profile, false, true, true) )
    // dispatch(pageLoadEnd())
    dispatch(
      currentCostingActions.getCosting(
        costingProfileID,
        false,
        profile.IsOPSOOPAdjusted ? false : true
      )
    );
  }
  useEffect(() => {
    getHistoryForApproval();
  }, [profile?.id]);

  function historylogData() {
    let costs = [
      "OldOpsOOP",
      "NewOpsOOP",
      "OldCSOOP",
      "NewCSOOP",
      "OldTotalExternalCosts",
      "NewTotalExternalCosts",
      "OldCsInternal",
      "NewCsInternal",
      "OldOpsInternal",
      "NewOpsInternal",
      "OldTotalInternalCosts",
      "NewTotalInternalCosts",
    ];
    axios
      .post("opsAdjustments/opsoop_adjustments/", { ProfileId: profile.id })

      .then((res) => {
        let tempAdjustments = res.data.Result.map((resData) => {
          resData.PricetoClient = _.round(
            profile.PriceToClient * currentCurrency.ConversionRateToLocal,
            2
          );
          costs.forEach((cd) => {
            resData[cd] = _.round(
              resData[cd] * currentCurrency.ConversionRateToLocal,
              2
            );
          });
          console.log(resData, "resData");
          return resData;
        });

        setHistoryLogData(tempAdjustments);

        openHistoryLog(true);
      })
      .catch((error) => {});
  }

  const msdValidation = (modalName = "commission") => {
    let ContractDetails = project.ContractDetails.filter(
      (cd) => cd.OpportunityNumber
    );

    let tempMSDValid = msdValid;

    if (modalName != "approval") {
      if (
        ContractDetails.every((cd) =>
          cd.opportunityLineItemDetails
            .filter(
              (o) =>
                o.MaterialID?.slice(0, 16).toLowerCase().replace(" ", "") !=
                "expenserecharge"
            )
            .every((ol) => ol.WBSNumber)
        )
      ) {
        // const keysToExtract = ["WBSNumber", "MaterialID"];
        // const cdWBS = ContractDetails.map(cd => cd.opportunityLineItemDetails.map(ol => {
        //   if(ol.WBSNumber && ol.MaterialID) {
        //     const extractedObj = {};
        //     keysToExtract.forEach(key => {
        //       extractedObj[key] = ol[key];
        //     });
        //     return extractedObj;
        //   }
        // }));

        tempMSDValid.WBS = true;
      } else {
        const keysToExtract = [
          "WBSNumber",
          "MaterialID",
          "OpportunityLineItemID",
        ];
        const cdWBS = ContractDetails.map((cd) =>
          cd.opportunityLineItemDetails
            .filter(
              (o) =>
                o.MaterialID?.slice(0, 16).toLowerCase().replace(" ", "") !=
                "expenserecharge"
            )
            .map((ol) => {
              if (ol.MaterialID) {
                const extractedObj = {};
                keysToExtract.forEach((key) => {
                  extractedObj[key] = ol[key];
                });
                return extractedObj;
              }
            })
        );
        tempMSDValid.WBS = cdWBS;
      }
    }

    if (modalName != "approval") {
      if (
        ContractDetails.every(
          (cd) => cd.Stage == "Closed Won - Approved" && cd.Probability == 100
        )
      ) {
        // const keysToExtract = ["Probability", "OpportunityNumber", "Stage"];
        // const cdProbability = ContractDetails.map(cd => {
        //   if(cd.Stage && cd.Probability && cd.OpportunityNumber) {
        //     const extractedObj = {};
        //     keysToExtract.forEach(key => {
        //       extractedObj[key] = cd[key];
        //     });
        //     return extractedObj;
        //   }
        // });

        tempMSDValid.Stage = true;
      } else {
        const keysToExtract = ["Probability", "OpportunityNumber", "Stage"];
        const cdProbability = ContractDetails.map((cd) => {
          if (cd.Stage && cd.Probability && cd.OpportunityNumber) {
            const extractedObj = {};
            keysToExtract.forEach((key) => {
              extractedObj[key] = cd[key];
            });
            return extractedObj;
          }
        });
        tempMSDValid.Stage = cdProbability;
      }
    }

    // console.log('llppp',ContractDetails.every(cd=>cd.opportunityLineItemDetails.every(item =>item.MaterialID !== "InterCO Rev line" || item.MaterialID !== "INTERCO REV Share" ||

    // item.MaterialID !== null || item.CharacteristicValues.length > 0)))

    // const newVar = ContractDetails.every(cd=>cd.opportunityLineItemDetails.filter(item =>item.MaterialID?.slice(0,4).toLowerCase() === "cmi-" ||

    // item.MaterialID?.slice(0,3).toLowerCase() === "ci-" ||

    // item.MaterialID?.slice(0,4).toLowerCase().replace(' ','') === "tbc" &&

    // item.CharacteristicValues !== null))
    let isValid;
    const lineItemId = ContractDetails.flatMap((cd) =>
      cd.opportunityLineItemDetails
        .filter(
          (o) =>
            o.MaterialID?.slice(0, 16).toLowerCase().replace(" ", "") !=
            "expenserecharge"
        )
        .map((op) => String(op.id))
    );
    const lineItemIdCostOpt = profile.CountrySpecs.flatMap((cs) =>
      cs.MethodologySpecs.flatMap((ms) =>
        ms.CostingOptions.filter((costopt) => costopt.CoSelectedForSummary).map(
          (co) => String(co.OpportunityLineItemID)
        )
      )
    );
    // console.log("lineItemId,lineItemIdCostOpt",lineItemId,lineItemIdCostOpt);
    const islineItemCostOpt = lineItemId.every((element) =>
      lineItemIdCostOpt.includes(element)
    );
    // console.log("islineItemCostOpt",islineItemCostOpt);
    if (islineItemCostOpt) {
      function validateCosts(lineitem) {
        // console.log("lineitem", lineitem);
        let isClientChargeable = profile.CountrySpecs.flatMap(
          (countrySpec) => countrySpec.MethodologySpecs
        )
          .flatMap((methodologySpec) => methodologySpec.CostingOptions)
          .filter(
            (costOption) =>
              costOption.CoSelectedForSummary &&
              costOption.OpportunityLineItemID === lineitem &&
              costOption?.CostingOptionData?.producttype
                ?.slice(2, 5)
                .toLowerCase()
                .replace(" ", "") !== "exp"
          );
        if (isClientChargeable.length === 0) {
          return true;
        }
        const matchingCostOptions = profile.CountrySpecs.flatMap(
          (countrySpec) => countrySpec.MethodologySpecs
        )
          .flatMap((methodologySpec) => methodologySpec.CostingOptions)
          .filter(
            (costOption) =>
              costOption.CoSelectedForSummary &&
              costOption.OpportunityLineItemID === lineitem
          );

        if (matchingCostOptions.length > 0) {
          // console.log("matchingCostOption", matchingCostOptions);
          const hasPositiveCosts = matchingCostOptions.some(
            (matchingCostOption) => {
              const costsData = matchingCostOption.CostsData;
              let hasPositiveCost =
                costsData?.TotalExternalCosts +
                  costsData?.MCPMargin +
                  costsData?.CostTotalInternalCommercial +
                  costsData?.CostTotalInternalOperations >
                0;
              return hasPositiveCost;
            }
          );
          // ['TotalExternalCosts', 'MCPMargin', 'CostTotalInternalCommercial', 'CostTotalInternalOperations'].every(costType => costs[costType] > 0)

          return hasPositiveCosts;
        }

        return false; // Lineitem not found in any cost option
      }

      // Perform validation for each lineitem
      const validationResults = lineItemId.map((lineitem) =>
        validateCosts(lineitem)
      );

      // Check if all lineitems passed the validation
      isValid = validationResults.every((result) => result);

      console.log("isValid", isValid);
    }
    // console.log("islineItemCostOpt",islineItemCostOpt);
    if (islineItemCostOpt && isValid) {
      // const keysToExtract = ["WBSNumber", "MaterialID", "TotalPriceUSD"];
      // const cdCostopt = ContractDetails.map(cd => cd.opportunityLineItemDetails.map(ol => {
      //   if(ol.WBSNumber && ol.MaterialID && ol.TotalPriceUSD) {
      //     const extractedObj = {};
      //     keysToExtract.forEach(key => {
      //       extractedObj[key] = ol[key];
      //     });
      //     return extractedObj;
      //   }
      // }));

      tempMSDValid.Costopt = true;
    } else {
      let total = [];
      profile.CountrySpecs.forEach((cs) =>
        cs.MethodologySpecs.filter((m) => !m.NotApplicable).forEach((ms) =>
          ms.CostingOptions.filter(
            (co) =>
              co.CoSelectedForSummary &&
              co?.CostingOptionData?.producttype
                ?.slice(2, 5)
                .toLowerCase()
                .replace(" ", "") !== "exp"
          ).forEach((co) => {
            let wbs = null;
            let materialID = null;
            let OpportunityLineItemID = null;
            project.ContractDetails.forEach((cd) =>
              cd.opportunityLineItemDetails
                .filter(
                  (o) =>
                    o.MaterialID?.slice(0, 16).toLowerCase().replace(" ", "") !=
                    "expenserecharge"
                )
                .forEach((ol) => {
                  if (ol.id == co.OpportunityLineItemID) {
                    wbs = ol.WBSNumber;
                    materialID = ol.MaterialID;
                    OpportunityLineItemID = ol.OpportunityLineItemID;
                  }
                })
            );

            if (!total.length && co.OpportunityLineItemID !== null) {
              total.push({
                CostingProfileId: profile.id,
                ProjectId: project.ProjectId,
                WBSNumber: wbs,
                MaterialID: materialID,
                OpportunityLineItemID: OpportunityLineItemID,
                Overheadswbs: co.CostsData.Overheads,
                CostTotalMCPMarginwbs: co.CostsData.CostTotalMCPMargin,
                CostTotalInternalOperationswbs: co.TotalOPSTimeCost,

                CostTotalInternalCommercialwbs: co.TotalCSTimeCost,

                CostTotalExternalOperationswbs:
                  co.CostsData.CostTotalExternalOperations,

                CostTotalExternalCommercialwbs:
                  co.CostsData.CostTotalExternalCommercial,
                PriceToClientwbs: co.CostsData.PriceToClient,
                MCPMarginwbs: co.CostsData.MCPMargin,
                // MarginPercentwbs:co.CostsData.MarginPercent,
                VATwbs: co.CostsData.VAT,
                WHTwbs: co.CostsData.WHT,

                Total:
                  // co.TotalOPSTimeCost +
                  // co.TotalCSTimeCost +
                  co.CostsData.TotalExternalCosts +
                  // co.CostsData.CostTotalExternalOperations +
                  // co.CostsData.CostTotalExternalCommercial +
                  co.CostsData.CostTotalInternalCommercial +
                  co.CostsData.CostTotalInternalOperations +
                  // co.CostsData.Overheads +
                  // co.CostsData.VAT +
                  // co.CostsData.WHT +
                  co.CostsData.MCPMargin,
              });
            } else if (total.length && co.OpportunityLineItemID !== null) {
              let existing = total.find((t) => t.WBSNumber == wbs);
              let temp;
              if (existing) {
                temp = {
                  ...existing,
                  CostTotalMCPMarginwbs:
                    existing.CostTotalMCPMarginwbs +
                    co.CostsData.CostTotalMCPMargin,
                  Overheadswbs: existing.Overheadswbs + co.CostsData.Overheads,
                  CostTotalInternalOperationswbs:
                    existing.CostTotalInternalOperationswbs +
                    co.TotalOPSTimeCost,

                  CostTotalInternalCommercialwbs:
                    existing.CostTotalInternalCommercialwbs +
                    co.TotalCSTimeCost,

                  CostTotalExternalOperationswbs:
                    existing.CostTotalExternalOperationswbs +
                    co.CostsData.CostTotalExternalOperations,

                  CostTotalExternalCommercialwbs:
                    existing.CostTotalExternalCommercialwbs +
                    co.CostsData.CostTotalExternalCommercial,
                  MCPMarginwbs: existing.MCPMarginwbs + co.CostsData.MCPMargin,
                  //  MarginPercentwbs:existing.MarginPercentwbs+co.CostsData.MarginPercent,
                  VATwbs: existing.VATwbs + co.CostsData.VAT,
                  WHTwbs: existing.WHTwbs + co.CostsData.WHT,
                  PriceToClientwbs:
                    existing.PriceToClientwbs + co.CostsData.PriceToClient,
                  Total:
                    existing.Total +
                    // co.TotalOPSTimeCost +
                    // co.TotalCSTimeCost +
                    co.CostsData.TotalExternalCosts +
                    // co.CostsData.CostTotalExternalOperations +
                    // co.CostsData.CostTotalExternalCommercial +
                    co.CostsData.CostTotalInternalCommercial +
                    co.CostsData.CostTotalInternalOperations +
                    // co.CostsData.Overheads +
                    // co.CostsData.VAT +
                    // co.CostsData.WHT +
                    co.CostsData.MCPMargin,
                };
                let index = total.findIndex((ele) => ele.WBSNumber == wbs);
                total[index] = temp;
              } else {
                total.push({
                  WBSNumber: wbs,
                  MaterialID: materialID,
                  OpportunityLineItemID: OpportunityLineItemID,
                  CostingProfileId: profile.id,
                  ProjectId: project.ProjectId,
                  Overheadswbs: co.CostsData.Overheads,
                  CostTotalMCPMarginwbs: co.CostsData.CostTotalMCPMargin,

                  CostTotalInternalOperationswbs: co.TotalOPSTimeCost,

                  CostTotalInternalCommercialwbs: co.TotalCSTimeCost,

                  CostTotalExternalOperationswbs:
                    co.CostsData.CostTotalExternalOperations,

                  CostTotalExternalCommercialwbs:
                    co.CostsData.CostTotalExternalCommercial,
                  PriceToClientwbs: co.CostsData.PriceToClient,
                  MCPMarginwbs: co.CostsData.MCPMargin,
                  // MarginPercentwbs:co.CostsData.MarginPercent,
                  VATwbs: co.CostsData.VAT,
                  WHTwbs: co.CostsData.WHT,
                  Total:
                    // co.TotalOPSTimeCost +
                    // co.TotalCSTimeCost +
                    co.CostsData.TotalExternalCosts +
                    // co.CostsData.CostTotalExternalOperations +
                    // co.CostsData.CostTotalExternalCommercial +
                    co.CostsData.CostTotalInternalCommercial +
                    co.CostsData.CostTotalInternalOperations +
                    // co.CostsData.Overheads +
                    // co.CostsData.VAT +
                    // co.CostsData.WHT +
                    co.CostsData.MCPMargin,
                });
              }
            }
          })
        )
      );

      tempMSDValid.Costopt = total;
    }
    // console.log('newVarnewVar',newVar)

    let totalPriceFromMSD = ContractDetails.reduce(
      (res, cd) => cd.AmountUSD + res,
      0
    );

    let totalPriceFromCintral = profile.ClientChargeable
      ? profile.ClientChargeable + profile.PriceToClient
      : profile.PriceToClient;

    if (
      totalPriceFromMSD >= totalPriceFromCintral * 0.99 &&
      totalPriceFromMSD <= totalPriceFromCintral * 1.01
    ) {
      tempMSDValid.Price = true;
    } else {
      tempMSDValid.Price = [totalPriceFromMSD, totalPriceFromCintral];
    }

    // let timeCost =  profile?.CountrySpecs.some(cs=>cs?.MethodologySpecs.some(ms=>

    //   !ms.NotApplicable && ms?.CostingOptions.some(co => (co.CoSelectedForSummary && (!co?.TotalCSTimeCost && !co?.CsCostreason) || (!co?.TotalOPSTimeCost && !co?.OpsCostreason)))

    //   ))

    // if(!timeCost){

    //     tempMSDValid.Time =true

    //   }

    if (
      tempMSDValid.WBS === true &&
      tempMSDValid.Price === true &&
      tempMSDValid.Stage === true &&
      tempMSDValid.Costopt === true
    ) {
      return true;
    }

    // else if(newVar){

    //   // tempMSDValid.Stage=true

    //   if(ContractDetails.every(cd=>cd.opportunityLineItemDetails.map(item =>item.WBSNumber === null))){

    //     console.log('check ContractDetails')

    //     tempMSDValid.WBS =true

    //     return true

    //     // toastr.error("WBS not found . Can not Proceed. Please go Project Details Page and add the WBS.")

    //     // return false

    //   } else {

    //     return true

    //   }

    // }
    else {
      if (
        modalName === "approval" &&
        tempMSDValid.Price === true &&
        tempMSDValid.Costopt === true
      ) {
        return true;
      }

      setMsdValid(tempMSDValid);

      return false;
    }
  };
  // const checkCommission = () => {
  //   // Updating MSD details to Cintral
  //     //  setConfirmCommission(true)

  //   if(project.ContractDetails.some(cd=>cd.OpportunityNumber)){
  //     let index =0
  //     let isValid = true
  //    let  contactDetails =  project.ContractDetails.filter(cd=>cd.OpportunityNumber)

  //     for(let contactDetail of contactDetails){

  //      if(contactDetail.OpportunityNumber)
  //       dispatch(
  //         currentProjectActions.msdRefresh(project?.id, contactDetail,()=>{

  //           index ++

  //          let _isValid = msdValidation()

  //          if(!_isValid)
  //          {
  //           isValid =_isValid
  //          }
  //          if(isValid && index == contactDetails.length)
  //          {
  //             setConfirmCommission(true)
  //          }
  //          else if(!isValid && index == contactDetails.length){
  //          setIsOpen({
  //             ...isOpen,
  //             MSDValidation: true,
  //           })}

  //           console.log("Msd Refreshed for ",contactDetail.OpportunityNumber)
  //         },true));
  //       }

  // }
  //  else{
  //     toastr.error("Opportunity number not found . Can not Proceed")
  //  }
  // }
  const checkCommission = () => {
    // Updating MSD details to Cintral
    //  setConfirmCommission(true)

    if(profile.ExceptionCommision)
    {
      setConfirmCommission(true);
      return
    }

    if (
      project.ContractDetails.some((cd) => cd.OpportunityNumber) ||
      project.IsSyndicated
    ) {
      let index = 0;
      let isValid = true;
      let contactDetails = [];
      if (project.IsSyndicated) {
        contactDetails = project.ContractDetails;
      } else {
        contactDetails = project.ContractDetails.filter(
          (cd) => cd.OpportunityNumber
        );
        const filter = contactDetails.map((c) => ({
          ...c,
          opportunityLineItemDetails: c.opportunityLineItemDetails.filter(
            (item) => {
              if (
                (item.MaterialID?.slice(0, 4).toLowerCase() === "cmi-" ||
                  item.MaterialID?.slice(0, 3).toLowerCase() === "cmi" ||
                  item.MaterialID?.slice(0, 3).toLowerCase() === "ci-" ||
                  item.MaterialID?.slice(0, 4)
                    .toLowerCase()
                    .replace(" ", "") === "tbc" ||
                  item.MaterialID?.slice(0, 3)
                    .toLowerCase()
                    .replace(" ", "") === "exp") &&
                item.CharacteristicValues !== null
              ) {
                return true;
              } else {
                return false;
              }
            }
          ),
        }));
        console.log("filter", filter);
        contactDetails = filter;
      }

      if (project.IsSyndicated) {
        if (
          contactDetails.some((cd) => cd.opportunityLineItemDetails) &&
          contactDetails.every((cd) =>
            cd.opportunityLineItemDetails.every((ol) => ol.WBSNumber)
          )
        ) {
          setConfirmCommission(true);
        } else {
          toastr.error(
            "Cannot Proceed to Commission",
            "WBS not found. Please go to Project Details page and input the WBS to continue."
          );
        }
      } else {
        for (let contactDetail of contactDetails) {
          if (contactDetail.OpportunityNumber)
            dispatch(
              currentProjectActions.msdRefresh(
                project?.id,
                codeLabels,
                contactDetail,
                () => {
                  index++;

                  let _isValid = msdValidation();

                  if (!_isValid) {
                    isValid = _isValid;
                  }
                  if (isValid && index == contactDetails.length) {
                    setConfirmCommission(true);
                  } else if (!isValid && index == contactDetails.length) {
                    setIsOpen({
                      ...isOpen,
                      MSDValidation: true,
                    });
                  }

                  console.log(
                    "Msd Refreshed for ",
                    contactDetail.OpportunityNumber
                  );
                },
                true,
                history
              )
            );
        }
      }
    } else {
      toastr.error(
        "Cannot Proceed to Commission",
        "WBS not found. Please go to Project Details page and input the WBS to continue."
      );
    }
  };
  const getHistoryForApproval = () => {
    axios
      .get(
        `/costingprofiles/approvals/history/${
          profile.id ? profile.id : profileId
        }`
      )
      .then((res) => {
        setHistoryData(res.data);
      })

      .catch((error) => {
        console.log("errr", error);
      });
  };
  console.log("PROFILE", profile);
  const fetchDelegatedEmail = async () => {
    let approvers;
    if (
      profile.ApprovalDetails !== null &&
      profile.ApprovalDetails !== undefined
    ) {
      approvers = profile.ApprovalDetails.map((i) => {
        if (i.Order <= profile.ApprovalLevelNeeded) {
          return i.ApproverContacts.map((email) => {
            return email.EmailAddress;
          });
        }
      });
      let filteredEmail = approvers.flat();
      let emailValues = [
        ...new Set(
          filteredEmail
            .filter((e) => e !== undefined)
            .map((email) => email.toLowerCase())
        ),
      ];
      // let emailValues = filteredEmail1.filter((value) => {
      //     return value !== undefined
      // })
      let commissionCountry = profile.Project.CommissioningCountry;
      let delegatedEmails = [];
      emailValues.map(async (approverEmail) => {
        console.log("testing api", approverEmail);
        await axios
          .get(`/delegate/email/${approverEmail}/${commissionCountry}`)
          .then((res) => {
            console.log("response", res);
            delegatedEmails.push(res.data.delegatedUsers);
          })
          .catch((error) => {
            console.log("errr", error);
          });
      });
      dispatch(setDelegateEmails(delegatedEmails));
      setDelegateEmail(delegatedEmails);
    }
  };

  useEffect(() => {
    fetchDelegatedEmail();
  }, [profile?.ApprovalDetails]);

  useEffect(() => {
    console.log("delegateEmailRedux", delegateEmailRedux);
    if (delegateEmailRedux.length > 0) {
      setDelegateEmail(delegateEmailRedux);
    }
  }, [profile, delegateEmail]);

  console.log("updated state", delegateEmail);

  return profile && profile.ProfileSetting ? (
    <Layout
      costMethod={profile.CostingType}
      setShowCostMethod={setShowCostMethod}
      showManualCostEntry={showManualCostEntry}
      setShowManualCostEntry={setShowManualCostEntry}
      showSheetsCosts={showSheetsCosts}
      setShowSheetsCosts={setShowSheetsCosts}
      profileStatusToDisplay={getLabel(
        "CostingStatusOptions",
        profile.ProfileStatus
      )}
      projectStatusToDisplay={getLabel(
        "ProjectStatusOptions",
        profile.Project?.ProjectStatus
      )}
      avoidEdit={true}
    >
      {selectCostInputModal()}
      {
        <>
          <MSDValidationModal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            toggle={() => {
              setIsOpen({
                ...isOpen,
                MSDValidation: !isOpen.MSDValidation,
              });
            }}
            msdValid={msdValid}
          ></MSDValidationModal>
          <Container fluid={profile.IsTracker}>
            <Row className="justify-content-space-between m-2">
              {/* refreshing costs if synced costs from sheet */}
              {(profile.Project?.ProjectStatus == "3" ||
                profile.Project?.ProjectStatus == "9") &&
              profile.Project?.PriceChange == 2 ? (
                <p className="text-danger">
                  <b>Note:</b> Pre-approved Commissioned projects cannot be
                  decommissioned. User must copy this project to edit and
                  proceed further
                </p>
              ) : (profile.Project?.ProjectStatus == "3" ||
                  profile.Project?.ProjectStatus == "9") &&
                profile.Project?.PriceChange == 3 ? (
                <p className="text-danger">
                  <b>Note:</b> Locked Commissioned projects cannot be
                  decommissioned. User must copy this project to edit and
                  proceed further
                </p>
              ) : (
                <p />
              )}
              {(!_.includes(pacificMarkets, project.CommissioningCountry) ||
                (_.includes(pacificMarkets, project.CommissioningCountry) &&
                  project.CostingTemplateVersion == "v2.0") ||
                (project.ParentProjectId && !project.IsCopy)) && (
                <Button
                  onClick={() => {
                    setStatusCheckProfit(false);
                    setIsZeroCost(false);
                    // setIsRefreshed(true)
                    if (project.IsUploaded) {
                      setUploadProject(true);
                    } else {
                      refreshCostingProfile(profile.id);
                    }
                  }}
                  disabled={
                    project.PriceChange > 2
                      ? true
                      : project.PriceChange == 2
                      ? parseInt(profile?.ProfileStatus) == 1 ||
                        parseInt(profile?.ProfileStatus) == 2 ||
                        parseInt(profile?.ProfileStatus) == 5
                        ? false
                        : true
                      : profile.Project?.ProjectStatus === "7" ||
                        parseInt(profile?.ProfileStatus) > 1
                  }
                >
                  Refresh Costs
                </Button>
              )}
            </Row>
            {project.IsUploaded && uploadProject && (
              <UploadedProjectModal
                uploadProject={uploadProject}
                setUploadProject={setUploadProject}
              />
            )}
            <Card>
              <CardHeader>
                <Row className="d-flex justify-content-between">
                  <Col xs="8" sm="9" md="9" className="align-self-center">
                    <Row className="d-flex justify-content-start">
                      <Col className="align-self-center">
                        <CardTitle className="text-uppercase mb-0">
                          Costing Profile Summary- {profile.ProfileName}
                        </CardTitle>
                      </Col>
                      {/* <Col className="align-self-center">
                            <Badge title="Costing Profile Status">
                              {getLabel(
                                "CostingStatusOptions",
                                profile?.ProfileStatus
                              )}
                            </Badge>
                          </Col> */}
                    </Row>
                  </Col>
                  <Col xs="4" sm="3" md="3" className="align-self-center">
                    <Link
                      className=" F-1 medium float-right mr-2"
                      onClick={(e) =>
                        setTabStatus({
                          ...allTabsStatus,
                          overall: !allTabsStatus.overall,
                          projectDetails: !allTabsStatus.overall ? true : false,
                          methSummary: !allTabsStatus.overall ? true : false,
                          opsResources: !allTabsStatus.overall ? true : false,
                          clientServices: !allTabsStatus.overall ? true : false,
                          costBreakdown: !allTabsStatus.overall ? true : false,
                          shoppercostBreakdown: !allTabsStatus.overall
                            ? true
                            : false,
                          profOverview: !allTabsStatus.overall ? true : false,
                          approvals: !allTabsStatus.overall ? true : false,
                          costingOptionsSec: !allTabsStatus.overall
                            ? true
                            : false,
                          responsibilitiesSection: !allTabsStatus.overall
                            ? true
                            : false,
                        })
                      }
                    >
                      <Label className="mb-0 mr-1 small">
                        {!allTabsStatus.overall ? "Expand All" : "Collapse All"}
                      </Label>
                      <FontAwesomeIcon
                        icon={
                          !allTabsStatus.overall ? faChevronDown : faChevronUp
                        }
                        fixedWidth
                        className="mb-0 mr-3 medium"
                      />
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </Container>
          <Container fluid={profile.IsTracker}>
            {profile.ProfileStatus === "99" ? (
              <Card className="ml-2 mr-2 mb-2">
                <CardBody>
                  <label>
                    <b>Reasons: </b>
                  </label>
                  <p style={{ display: "flex", flexDirection: "column" }}>
                    {profile?.DecommissionReason &&
                    typeof profile.DecommissionReason == "string"
                      ? profile.DecommissionReason.split(";").map((item) => {
                          return <span>{item}</span>;
                        })
                      : null}{" "}
                  </p>
                  <label>
                    <b>Justification: </b>
                  </label>
                  <p style={{ color: "#AD4400" }}>
                    {profile.DecommissionNotes}
                  </p>
                </CardBody>
              </Card>
            ) : null}
            <Card className="ml-2 mr-2 mb-2">
              <CardHeader
                onClick={(e) =>
                  setTabStatus({
                    ...allTabsStatus,
                    projectDetails: !allTabsStatus.projectDetails,
                  })
                }
              >
                <Row>
                  <Col xs="10" sm="11" md="11">
                    <CardTitle className="mb-0">Project Details</CardTitle>
                  </Col>
                  <Col xs="2" sm="1" md="1">
                    <FontAwesomeIcon
                      className="align-middle mr-2"
                      icon={
                        !allTabsStatus.projectDetails
                          ? faChevronRight
                          : faChevronDown
                      }
                      fixedWidth
                    />
                  </Col>
                </Row>
              </CardHeader>
              <Collapse isOpen={allTabsStatus.projectDetails}>
                <CardBody id="projectDetails">
                  <Table responsive hover striped size="sm">
                    {/*conditional format color of the results to colors - success danger */}
                    <tbody>
                      <tr>
                        <td>Project Name</td>
                        <td>{profile?.Project?.ProjectName}</td>
                      </tr>
                      <tr>
                        <td>Project Number</td>
                        <td>{profile?.Project?.ProjectId}</td>
                      </tr>
                      <tr>
                        <td>Primary Nielsen Contact Email</td>
                        <td>{profile?.Project?.ProposalOwnerEmail.value}</td>
                      </tr>
                      <tr>
                        <td>Other Project Team Contacts</td>
                        <td
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {profile &&
                          profile.Project &&
                          profile.Project.OtherProjectTeamContacts &&
                          profile.Project.OtherProjectTeamContacts.length
                            ? profile.Project.OtherProjectTeamContacts.map(
                                (item) => {
                                  return <span>{item.value}</span>;
                                }
                              )
                            : "No Contacts Available"}
                        </td>
                      </tr>
                      <tr>
                        <td>Syndicated Project</td>
                        <td>{profile?.Project?.IsSyndicated ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <td>Client Details</td>
                        <td>
                          <ul className="m-0 p-0 summary-client-details-list">
                            {profile?.Project?.ContractDetails?.map((item) => {
                              return (
                                <li>
                                  {item.AccountName}{" "}
                                  {item.OpportunityNumber
                                    ? `- (${item.OpportunityNumber})`
                                    : ""}
                                </li>
                              );
                            })}
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Commissioning Country</td>
                        <td>
                          {getLabel(
                            "CommissioningCountriesOptions",
                            profile?.Project?.CommissioningCountry
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Commissioning Office</td>
                        <td>
                          {getLabel(
                            "OfficeOptions",
                            profile?.Project?.CommissioningOffice
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Business Unit</td>
                        <td>
                          {getLabel(
                            "BusinessUnitOptions",
                            profile?.Project?.BusinessUnit
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Industry Vertical</td>
                        <td>
                          {getLabel(
                            "VerticalOptions",
                            profile?.Project?.IndustryVertical
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>All Fielding Countries</td>
                        <td
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {profile.FieldingCountries &&
                          typeof profile.FieldingCountries == "string"
                            ? profile.FieldingCountries.split(",").map(
                                (item) => {
                                  return (
                                    <span>
                                      {getLabel(
                                        "FieldingCountriesOptions",
                                        item
                                      )}
                                    </span>
                                  );
                                }
                              )
                            : null}
                        </td>
                      </tr>

                      <tr>
                        <td>Project Background</td>
                        <td
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {profile?.Project?.ProjectBackground}
                        </td>
                      </tr>
                      <tr>
                        <td>Research Objectives</td>
                        <td
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {profile?.Project?.ResearchObjectives}
                        </td>
                      </tr>
                      {/* {(profile.ProductType) ?
                      <tr>
                        <td>Product Type</td>
                        <td
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {profile?.ProductType &&
                            typeof profile.ProductType == "string"
                            ? profile.ProductType.split(",").map((item) => {
                              return (
                                <span>
                                  {getLabel("ProductTypeOptions", item)}
                                </span>
                              );
                            })
                            : null}
                        </td>
                      </tr>
                        : */}
                      {/* <tr>
                        <td>Study Type</td>
                        <td
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {profile?.StudyType &&
                            typeof profile.StudyType == "string"
                            ? profile.StudyType.split(",").map((item) => {
                              return (
                                <span>
                                  {getLabel("StudyTypeOptions", item)}
                                </span>
                              );
                            })
                            : null}
                        </td>
                      </tr> */}
                      {/* } */}
                      {/* <tr>
                        <td>Methodologies</td>
                        <td
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {profile?.Methodology &&
                            typeof profile.Methodology == "string"
                            ? profile.Methodology.split(",").map((item) => {
                              return (
                                <span>
                                  {getLabel("MethodologyOptions", item)}
                                </span>
                              );
                            })
                            : null}
                        </td>
                      </tr> */}
                      <tr>
                        <td>Sub-Methodologies</td>
                        <td
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {profile?.SubMethodology &&
                          typeof profile.SubMethodology == "string"
                            ? profile.SubMethodology?.split(",").map((item) => {
                                return (
                                  <span>
                                    {getLabel("SubMethodologyOptions", item)}
                                  </span>
                                );
                              })
                            : null}
                        </td>
                      </tr>
                      <tr>
                        <td>Tracker</td>
                        <td>{profile?.IsTracker ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <td>Tracking Frequency</td>
                        <td>
                          {profile.TrackingFrequency
                            ? profile.TrackingFrequency
                            : "Not Applicable"}
                          {/* {profile?.TrackingFrequency
														? getLabel(
															"TrackingFrequencyOptions",
															profile?.TrackingFrequency
														)
														: "Not Applicable"} */}
                        </td>
                      </tr>
                      {/* Pending: Can't find the key */}
                      <tr>
                        <td>WBS Number</td>
                        <td
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {profile?.project?.ContractDetails &&
                          profile?.project?.ContractDetails.length
                            ? // && typeof (profile.SubMethodology) == "string"
                              profile.project?.ContractDetails.map(
                                (cd) => cd.opportunityLineItemDetails
                              )
                                .map((opl) => opl.WBSNumber)
                                .toString()
                            : "Not Available"}
                        </td>
                      </tr>
                      <tr>
                        <td>Costing Details</td>
                        <td
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            color: "blue",
                          }}
                        >
                          <a
                            className="cost-breakdown-link"
                            onClick={() => detailedRFQPage()}
                          >
                            {" "}
                            Detailed RFQ{" "}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Collapse>
            </Card>

            {profile.IsMultiCountry && allResponsibilities.length ? (
              <Card className="ml-2 mr-2 mb-2">
                <CardHeader
                  onClick={(e) =>
                    setTabStatus({
                      ...allTabsStatus,
                      responsibilitiesSection:
                        !allTabsStatus.responsibilitiesSection,
                    })
                  }
                >
                  <Row>
                    <Col xs="10" sm="11" md="11">
                      <CardTitle className="mb-0">Responsibilities</CardTitle>
                    </Col>
                    <Col xs="2" sm="1" md="1">
                      <FontAwesomeIcon
                        className="align-middle mr-2"
                        icon={
                          !allTabsStatus.responsibilitiesSection
                            ? faChevronRight
                            : faChevronDown
                        }
                        fixedWidth
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={allTabsStatus.responsibilitiesSection}>
                  <CardBody id="responsibilitiesBreakdown">
                    <Table responsive hover striped bordered={true} size="sm">
                      <thead>
                        <tr>
                          <th></th>

                          {profile.WaveSpecs?.map((ws) => {
                            return (
                              <>
                                <th>
                                  <p className="m-0">
                                    # {ws.WaveNumber} {ws.WaveName},
                                  </p>
                                  <p className="m-0">
                                    {
                                      methCountrySet[ws.MethodologySpecId]
                                        ?.methlabel
                                    }
                                    ,
                                  </p>
                                  <p className="m-0">
                                    {
                                      methCountrySet[ws.MethodologySpecId]
                                        ?.country
                                    }
                                  </p>
                                </th>
                              </>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {allResponsibilities?.map((res) => {
                          let property = getResponsibilityProperty(
                            _.head(profile.WaveSpecs).ResponsibilitySchema,
                            res
                          );
                          return property ? (
                            <>
                              <tr>
                                <td>{property.title}</td>
                                {profile.WaveSpecs?.map((wave) => (
                                  <td>
                                    {wave?.ResponsibilitySchema
                                      ? getResponsibilityValue(
                                          wave.ResponsibilitySchema,
                                          res
                                        )
                                      : "-"}
                                  </td>
                                ))}
                              </tr>
                            </>
                          ) : null;
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Collapse>
              </Card>
            ) : null}
            {profile.ProfileSetting &&
            !profile.ProfileSetting.UsesOopOverrideIntCommCost &&
            _.includes(pacificMarkets, project.CommissioningCountry) &&
            project.CostingTemplateVersion != "v2.0" ? (
              <Card className="ml-2 mr-2 mb-2">
                <CardHeader
                  onClick={(e) =>
                    setTabStatus({
                      ...allTabsStatus,
                      clientServices: !allTabsStatus.clientServices,
                    })
                  }
                >
                  <Row>
                    <Col xs="6" sm="8" md="8">
                      <CardTitle className="mb-0">
                        Commercial Time Breakdown{" "}
                        {profile.IsTracker ? "(Per Wave)" : ""}
                      </CardTitle>
                    </Col>
                    <Col xs="4" sm="3" md="3">
                      <a
                        className="cost-breakdown-link"
                        onClick={(e) => {
                          e.stopPropagation();
                          openActivityBreakdown(true);
                        }}
                      >
                        View Activity Breakdown
                      </a>
                    </Col>
                    <Col xs="2" sm="1" md="1">
                      <FontAwesomeIcon
                        className="align-middle mr-2"
                        icon={
                          !allTabsStatus.clientServices
                            ? faChevronRight
                            : faChevronDown
                        }
                        fixedWidth
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={allTabsStatus.clientServices}>
                  <CardBody id="commercialbreakdown">
                    <div className="ml-auto mb-2">
                      <Row>
                        <Col lg-1 md-1 xs-12>
                          <Label className="h5">Ratecard applied:</Label>
                        </Col>
                        <Col lg-7 md-7 xs-12>
                          <input
                            value={
                              profile.ProfileSetting?.CSRateCardUsed
                                ?.ProfileName
                            }
                            className="form-control"
                            disabled
                          />
                        </Col>
                      </Row>
                    </div>

                    {_.includes(pacificMarkets, project.CommissioningCountry) &&
                    project.CostingTemplateVersion != "v2.0" ? (
                      <div className="d-flex">
                        <Table
                          responsive
                          hover
                          striped
                          bordered={true}
                          size="sm"
                        >
                          <thead>
                            <tr>
                              <th>Hourly Chargeout Rate</th>
                              {bands.map((band) => (
                                <td>
                                  {profile.ProfileSetting.CSRateCardUsed
                                    ? getCurrentCurrency(
                                        profile.ProfileSetting.CSRateCardUsed[
                                          rateCardReferences[band]
                                        ]
                                      )
                                    : getCurrentCurrency(0)}
                                </td>
                              ))}
                            </tr>
                            <tr>
                              {profile.IsTracker ? <th>Wave</th> : <th></th>}
                              {bands.map((band) => (
                                <th>{band}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {/* {profile.IsTracker
                            ? */}
                            {waveSpecs?.map((wave) => {
                              return (
                                <tr>
                                  <td>
                                    <p className="m-0">
                                      #{wave.WaveNumber} {wave.WaveName},
                                    </p>
                                    <p className="m-0">
                                      {
                                        methCountrySet[wave.MethodologySpecId]
                                          ?.methlabel
                                      }
                                      ,
                                    </p>
                                    <p className="m-0">
                                      {
                                        methCountrySet[wave.MethodologySpecId]
                                          ?.country
                                      }
                                    </p>
                                  </td>
                                  {bands.map((band) => {
                                    return (
                                      <td>
                                        {wave.CommercialHoursData
                                          ? wave.CommercialHoursData[band]
                                            ? wave.CommercialHoursData[band][
                                                "Total"
                                              ]
                                            : "-"
                                          : "-"}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                            {/* // : null} */}
                          </tbody>
                          <tfoot style={{ borderTop: "2px solid #dee2e6" }}>
                            <tr>
                              <th>Hours by Band</th>
                              {bands.map((band) => {
                                return (
                                  <td>
                                    {_.sum(
                                      waveSpecs.map((ws) => {
                                        if (ws.CommercialHoursData) {
                                          if (ws.CommercialHoursData[band])
                                            return ws.CommercialHoursData[band][
                                              "Total"
                                            ];
                                          else return 0;
                                        } else return 0;
                                      })
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                            {/* <tr>
                        <th>Cost by Band</th>
                        {bands.map((band) => (
                          <td>0.00 USD</td>
                        ))}
                      </tr> */}
                            <tr>
                              <th>Total Hours</th>
                              <td>
                                {_.sum(
                                  bands.map((band) => {
                                    let sum = 0;
                                    _.sum(
                                      waveSpecs.map((ws) => {
                                        if (ws.CommercialHoursData) {
                                          if (ws.CommercialHoursData[band])
                                            sum =
                                              sum +
                                              ws.CommercialHoursData[band][
                                                "Total"
                                              ];
                                        }
                                      })
                                    );
                                    return sum;
                                  })
                                )}
                              </td>

                              {/* <th>Total Cost</th>
                        <td>0.00 USD</td> */}
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <Table
                          responsive
                          hover
                          striped
                          bordered={true}
                          size="sm"
                        >
                          <thead>
                            <tr>
                              <th>Hourly Chargeout Rate</th>
                              {bands.map((band) => (
                                <td>
                                  {profile.ProfileSetting.CSRateCardUsed
                                    ? getCurrentCurrency(
                                        profile.ProfileSetting.CSRateCardUsed[
                                          rateCardReferences[band]
                                        ]
                                      )
                                    : getCurrentCurrency(0)}
                                </td>
                              ))}
                            </tr>
                            <tr>
                              {profile.IsTracker ? <th>Wave</th> : <th> - </th>}
                              {bands.map((band) => (
                                <th>{band}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {profile.CountrySpecs?.map((country) => (
                              <>
                                {
                                  <>
                                    <tr key={`${country.id}`}>
                                      <td colSpan="9">
                                        <strong>{country.CountryName}</strong>
                                      </td>
                                    </tr>
                                    {country.MethodologySpecs?.filter(
                                      (ms) => !ms.NotApplicable
                                    )?.map((method) => (
                                      <>
                                        {
                                          <>
                                            <tr
                                              key={`${method.id}_${country.id}`}
                                            >
                                              <td
                                                colSpan="9"
                                                className={`pl-3`}
                                              >
                                                <strong>{method.Label}</strong>
                                              </td>
                                            </tr>
                                            {method.CostingOptions?.filter(
                                              (co) => co.Selected
                                            )?.map((cop) => {
                                              return (
                                                <tr
                                                  key={`${cop.id}-${method.id}-${country.id}`}
                                                >
                                                  <td className={`pl-5`}>
                                                    {cop.Name}
                                                  </td>
                                                  <>
                                                    {bands.map((band) => {
                                                      return (
                                                        <td>
                                                          {/* <Input
                                id={`${band}-${wave.WaveNumber}`}
                                type="number"
                                value={
                                  wave.CommercialHoursData[band]["Total"]
                                }
                              /> */}
                                                          {cop.CommercialHours
                                                            ? cop
                                                                .CommercialHours[
                                                                band
                                                              ]
                                                              ? cop
                                                                  .CommercialHours[
                                                                  band
                                                                ]["Total"]
                                                              : "-"
                                                            : "-"}
                                                        </td>
                                                      );
                                                    })}
                                                  </>
                                                </tr>
                                              );
                                            })}
                                          </>
                                        }
                                      </>
                                    ))}
                                    {/* <tfoot style={{ borderTop: "2px solid #dee2e6" }}> */}
                                    <tr
                                      style={{ borderTop: "2px solid #dee2e6" }}
                                    >
                                      <th>Hours by Band</th>
                                      {bands.map((band) => {
                                        return (
                                          <td>
                                            {_.sum(
                                              [].concat(
                                                ...country.MethodologySpecs?.filter(
                                                  (ms) => !ms.NotApplicable
                                                )?.map((method) => {
                                                  return [].concat(
                                                    ...method.CostingOptions?.filter(
                                                      (co) => co.Selected
                                                    )?.map((cop) => {
                                                      if (cop.CommercialHours) {
                                                        if (
                                                          cop.CommercialHours[
                                                            band
                                                          ]
                                                        )
                                                          return cop
                                                            .CommercialHours[
                                                            band
                                                          ]["Total"];
                                                        else return 0;
                                                      } else return 0;
                                                    })
                                                  );
                                                })
                                              )
                                            )}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      <th>Total Hours</th>
                                      <td>
                                        {_.sum(
                                          [].concat(
                                            ...bands.map((band) => {
                                              return [].concat(
                                                ...country.MethodologySpecs?.filter(
                                                  (ms) => !ms.NotApplicable
                                                )?.map((method) => {
                                                  return [].concat(
                                                    ...method.CostingOptions?.filter(
                                                      (co) => co.Selected
                                                    )?.map((cop) => {
                                                      if (cop.CommercialHours) {
                                                        if (
                                                          cop.CommercialHours[
                                                            band
                                                          ]
                                                        )
                                                          return cop
                                                            .CommercialHours[
                                                            band
                                                          ]["Total"];
                                                        else return 0;
                                                      } else return 0;
                                                    })
                                                  );
                                                })
                                              );
                                            })
                                          )
                                        )}
                                      </td>
                                    </tr>
                                    <tr key={`${country.id}-emtpy`}>
                                      <td colSpan="9">
                                        <strong>&nbsp;</strong>
                                      </td>
                                    </tr>
                                    {/* </tfoot> */}
                                  </>
                                }
                              </>
                            ))}
                          </tbody>

                          <tfoot style={{ borderTop: "2px solid #dee2e6" }}>
                            <tr>
                              <th>Grand Hours by Band</th>
                              {bands?.map((band) => {
                                return (
                                  <td>
                                    {_.sum(
                                      [].concat(
                                        ...profile.CountrySpecs?.map(
                                          (country) => {
                                            return [].concat(
                                              ...country.MethodologySpecs?.filter(
                                                (ms) => !ms.NotApplicable
                                              )?.map((method) => {
                                                return [].concat(
                                                  ...method.CostingOptions?.filter(
                                                    (co) => co.Selected
                                                  )?.map((cop) => {
                                                    if (cop.CommercialHours) {
                                                      if (
                                                        cop.CommercialHours[
                                                          band
                                                        ]
                                                      )
                                                        return cop
                                                          .CommercialHours[
                                                          band
                                                        ]["Total"];
                                                      else return 0;
                                                    } else return 0;
                                                  })
                                                );
                                              })
                                            );
                                          }
                                        )
                                      )
                                    )}
                                  </td>
                                );
                              })}
                            </tr>

                            <tr>
                              <th>Grand Total Hours</th>
                              <td>
                                {_.sum(
                                  [].concat(
                                    ...bands.map((band) => {
                                      return [].concat(
                                        ...profile.CountrySpecs.map(
                                          (country) => {
                                            return [].concat(
                                              ...country.MethodologySpecs.map(
                                                (method) => {
                                                  return [].concat(
                                                    ...method.CostingOptions.map(
                                                      (cop) => {
                                                        if (
                                                          cop.CommercialHours
                                                        ) {
                                                          if (
                                                            cop.CommercialHours[
                                                              band
                                                            ]
                                                          )
                                                            return cop
                                                              .CommercialHours[
                                                              band
                                                            ]["Total"];
                                                          else return 0;
                                                        } else return 0;
                                                      }
                                                    )
                                                  );
                                                }
                                              )
                                            );
                                          }
                                        )
                                      );
                                    })
                                  )
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    )}
                  </CardBody>
                </Collapse>
              </Card>
            ) : null}

            <Card className="ml-2 mr-2 mb-2">
              <CardHeader
                onClick={(e) =>
                  setTabStatus({
                    ...allTabsStatus,
                    timecost: !allTabsStatus.timecost,
                  })
                }
              >
                <Row>
                  <Col xs="10" sm="11" md="11">
                    <CardTitle className="mb-0"> Time Cost</CardTitle>
                  </Col>
                  <Col xs="2" sm="1" md="1">
                    <FontAwesomeIcon
                      className="align-middle mr-2"
                      icon={
                        !allTabsStatus.timecost ? faChevronRight : faChevronDown
                      }
                      fixedWidth
                    />
                  </Col>
                </Row>
              </CardHeader>
              <Collapse isOpen={allTabsStatus.timecost}>
                <CardBody>
                  <Row>
                    <Col md={12} className={"methodologyTabs"}>
                      <div className="tab"></div>
                      <Nav tabs>
                        <NavItem key="0">
                          <NavLink
                            className={classnames({
                              active: activeTabWBS === 0,
                            })}
                            onClick={() => {
                              setActiveTabWBS(0);
                            }}
                          >
                            OverAll{" "}
                          </NavLink>
                        </NavItem>
                        {project.id &&
                        project?.ContractDetails &&
                        _.head(project?.ContractDetails)
                          ?.opportunityLineItemDetails
                          ? project.ContractDetails.map((cd) =>
                              cd.opportunityLineItemDetails.map(
                                (ol, ind) =>
                                  profile &&
                                  profile.id &&
                                  profile?.CountrySpecs.some((cs) =>
                                    cs.MethodologySpecs.some((ms) =>
                                      ms.CostingOptions.some(
                                        (co) =>
                                          co.OpportunityLineItemID == ol.id
                                      )
                                    )
                                  ) && (
                                    <NavItem key={ol.id}>
                                      <NavLink
                                        className={classnames({
                                          active: activeTabWBS === ol.id,
                                        })}
                                        onClick={() => {
                                          setActiveTabWBS(ol.id);
                                        }}
                                      >{`${ind + 1} - ${
                                        ol.MaterialID
                                      }`}</NavLink>
                                    </NavItem>
                                  )
                              )
                            )
                          : null}
                      </Nav>
                      <TabContent activeTab={activeTabWBS} className="p-3">
                        <TabPane tabId={0}>
                          <TimeCostUI
                            LineItemId={activeTabWBS}
                            project={project}
                            profile={profile}
                            optionIds={optionIds}
                            setOptionsId={setOptionsId}
                            rateCards={rateCards}
                            setRateCards={setRateCards}
                          ></TimeCostUI>
                        </TabPane>

                        {project.id &&
                        project?.ContractDetails &&
                        _.head(project?.ContractDetails)
                          ?.opportunityLineItemDetails
                          ? project.ContractDetails.map((cd) =>
                              cd.opportunityLineItemDetails.map((ol) => (
                                <TabPane tabId={ol.id}>
                                  <div>
                                    <h4>Line Item Details</h4>

                                    <table className="table table-bordered mt-3">
                                      <tr>
                                        <th>Material Id</th>
                                        <th>Product Description</th>
                                        <th>WBSNumber</th>
                                      </tr>
                                      <tr>
                                        <td>{ol.MaterialID}</td>
                                        <td>{ol.ProductDescription}</td>
                                        <td>
                                          {ol.WBSNumber ? ol.WBSNumber : "-"}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                  <TimeCostUI
                                    LineItemId={activeTabWBS}
                                    project={project}
                                    profile={profile}
                                    optionIds={optionIds}
                                    setOptionsId={setOptionsId}
                                    rateCards={rateCards}
                                    setRateCards={setRateCards}
                                  ></TimeCostUI>
                                </TabPane>
                              ))
                            )
                          : null}
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Collapse>
            </Card>

            {canRenderTotalBreakdown() ? (
              <Card className="ml-2 mr-2 mb-2">
                <CardHeader
                  onClick={(e) =>
                    setTabStatus({
                      ...allTabsStatus,
                      costBreakdown: !allTabsStatus.costBreakdown,
                    })
                  }
                >
                  {/* {!(_.includes(pacificMarkets, project.CommissioningCountry) &&
									project.CostingTemplateVersion == "v2.0") && */}
                  <Row>
                    <Col xs="6" sm="8" md="8">
                      <CardTitle className="mb-0">
                        Total Cost Breakup
                        <br></br>
                        {((_.includes(
                          pacificMarkets,
                          project.CommissioningCountry
                        ) &&
                          project.CostingTemplateVersion != "v2.0") ||
                          project.CostingTemplateVersion != "v2.0") &&
                          canRenderShopperBreakdown() && (
                            <p className="help mb-0">
                              (Excluding Shopper Methodologies)
                            </p>
                          )}
                      </CardTitle>
                    </Col>
                    <Col xs="4" sm="3" md="3">
                      <a
                        className="cost-breakdown-link"
                        onClick={(e) => {
                          e.stopPropagation();
                          openCostBreakdown(true);
                        }}
                      >
                        View Cost Breakdown
                      </a>
                    </Col>
                    <Col xs="2" sm="1" md="1">
                      <FontAwesomeIcon
                        className="align-middle mr-2"
                        icon={
                          !allTabsStatus.costBreakdown
                            ? faChevronRight
                            : faChevronDown
                        }
                        fixedWidth
                      />
                    </Col>
                  </Row>
                  {/*  } */}
                </CardHeader>
                <Collapse isOpen={allTabsStatus.costBreakdown}>
                  <CardBody id="totalCostbreakdown">
                    {(_.includes(
                      pacificMarkets,
                      project.CommissioningCountry
                    ) &&
                      project.CostingTemplateVersion != "v2.0") ||
                    project.CostingTemplateVersion != "v2.0" ? (
                      <Table responsive hover striped bordered={true} size="sm">
                        <thead>
                          <th>Component</th>
                          <th>Total</th>
                          {profile.IsMultiCountry
                            ? profile.CountrySpecs.map((cs) => (
                                <th>
                                  {getLabel(
                                    "FieldingCountriesOptions",
                                    cs.CountryCode
                                  )}
                                </th>
                              ))
                            : null}
                        </thead>

                        {Object.keys(costFields)?.map((cf) => {
                          return (
                            <tbody>
                              {alternativeLabels[cf] &&
                              Object.keys(alternativeLabels[cf]).filter(
                                (al) => profile.ProfileSetting[al]
                              ).length ? (
                                <tr>
                                  <td>
                                    Breakdown Not Available - Using OOP %
                                    Multiplier
                                  </td>
                                  <td>
                                    {_.round(
                                      profile.ProfileSetting[
                                        _.head(
                                          Object.values(alternativeLabels[cf])
                                        )
                                      ] * 100,
                                      2
                                    )}{" "}
                                    %
                                  </td>
                                </tr>
                              ) : costFields[cf].length ? (
                                costFields[cf]?.map((ccf) => (
                                  <tr>
                                    <td>{costlabels[ccf]}</td>
                                    <td>
                                      {getCurrentCurrency(
                                        profile[ccf],
                                        profile.CostInputCurrency
                                      )}
                                    </td>
                                    {profile.IsMultiCountry
                                      ? profile.CountrySpecs.map((cs) => (
                                          <td>
                                            {getCurrentCurrency(
                                              cs[ccf],
                                              cs.CostInputCurrency
                                            )}{" "}
                                          </td>
                                        ))
                                      : null}
                                  </tr>
                                ))
                              ) : (
                                <>
                                  {Object.keys(costIntCommLabels).map(
                                    (cicl) => (
                                      <tr>
                                        <td>{costIntCommLabels[cicl]}</td>
                                        <td>
                                          {getCurrentCurrency(
                                            profile[cicl],
                                            profile.CostInputCurrency
                                          )}
                                        </td>
                                        {profile.IsMultiCountry
                                          ? profile.CountrySpecs.map((cs) => (
                                              <td>
                                                {
                                                  getCountryLevelCost(
                                                    cs.MethodologySpecs,
                                                    cicl
                                                  )
                                                  // getCurrentCurrency(cs.MethodologySpecs.map(ms => profile.WaveSpecs.find(w => w.MethodologySpecId == ms.id)[cicl]).reduce((r, v) => r + v), profile.CostInputCurrency)
                                                }
                                              </td>
                                            ))
                                          : null}
                                      </tr>
                                    )
                                  )}
                                </>
                              )}

                              {alternativeLabels[cf] &&
                              Object.keys(alternativeLabels[cf]).filter(
                                (al) => profile.ProfileSetting[al]
                              ).length ? (
                                <tr className="text-uppercase">
                                  <th>{costlabels[cf]}</th>
                                  <th>
                                    {getCurrentCurrency(
                                      profile.CostTotalExternalOperations *
                                        profile.ProfileSetting[
                                          _.head(
                                            Object.values(alternativeLabels[cf])
                                          )
                                        ] +
                                        (shopperCounter[cf]
                                          ? profile[shopperCounter[cf]]
                                          : 0)
                                    )}
                                  </th>
                                </tr>
                              ) : (
                                <tr className="text-uppercase">
                                  <th>{costlabels[cf]}</th>
                                  <th>
                                    {getCurrentCurrency(
                                      shopperCounter[cf]
                                        ? profile[cf] -
                                            (profile[shopperCounter[cf]]
                                              ? profile[shopperCounter[cf]]
                                              : 0)
                                        : profile[cf],
                                      profile.CostInputCurrency
                                    )}
                                  </th>
                                  {profile.IsMultiCountry &&
                                  cf != "CostTotalInternalCommercial"
                                    ? profile.CountrySpecs.map((cs) => (
                                        <th>
                                          {getCurrentCurrency(
                                            cs[cf],
                                            cs.CostInputCurrency
                                          )}
                                        </th>
                                      ))
                                    : null}
                                </tr>
                              )}
                            </tbody>
                          );
                        })}
                      </Table>
                    ) : (
                      <ViewCostBreakDown></ViewCostBreakDown>
                    )}
                  </CardBody>
                </Collapse>
              </Card>
            ) : null}
            {canRenderShopperBreakdown() &&
            project.CostingTemplateVersion !== "v2.0" ? (
              <Card className="ml-2 mr-2 mb-2">
                <CardHeader
                  onClick={(e) =>
                    setTabStatus({
                      ...allTabsStatus,
                      shoppercostBreakdown: !allTabsStatus.shoppercostBreakdown,
                    })
                  }
                >
                  <Row>
                    <Col xs="6" sm="8" md="8">
                      <CardTitle className="mb-0">
                        Shoppers Cost Breakdown{" "}
                        {profile.IsMultiCountry ? "(Per Country)" : ""}
                      </CardTitle>
                    </Col>
                    <Col xs="4" sm="3" md="3">
                      {/* <a
											className="cost-breakdown-link"
											onClick={(e) => {
												e.stopPropagation();
												openCostBreakdown(true);
											}}
										>
											View Cost Breakdown
										</a> */}
                    </Col>
                    <Col xs="2" sm="1" md="1">
                      <FontAwesomeIcon
                        className="align-middle mr-2"
                        icon={
                          !allTabsStatus.shoppercostBreakdown
                            ? faChevronRight
                            : faChevronDown
                        }
                        fixedWidth
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={allTabsStatus.shoppercostBreakdown}>
                  <CardBody id="totalShoppersCostbreakdown">
                    <Col md={12}>
                      <div className={"tab"}>
                        <Nav tabs>
                          {Object.keys(methCountryCostSet).map(
                            (meth, index) => (
                              <NavItem>
                                <NavLink
                                  className={
                                    index == activeCostTab ? "active" : ""
                                  }
                                  onClick={() => setActiveCostTab(index)}
                                >
                                  {methLabels[meth]}
                                </NavLink>
                              </NavItem>
                            )
                          )}
                        </Nav>
                        <TabContent activeTab={activeCostTab}>
                          {Object.keys(methCountryCostSet).map(
                            (meth, index) => (
                              <TabPane tabId={index}>
                                <Table className="shopper-summary-table">
                                  <thead>
                                    <th>Property</th>
                                    <th>Total</th>
                                    {Object.keys(
                                      methCountryCostSet[
                                        [
                                          Object.keys(methCountryCostSet)[
                                            activeCostTab
                                          ],
                                        ]
                                      ]
                                    )?.map((cs, index) => (
                                      <th>
                                        {getLabel(
                                          "FieldingCountriesOptions",
                                          cs
                                        )}
                                      </th>
                                    ))}
                                  </thead>
                                  {shopperCostFieldsLookup[meth].map((scfl) => {
                                    return (
                                      <>
                                        {shopperCostLabels[
                                          scfl
                                        ]?.properties.map((_prop) => {
                                          return (
                                            <tr>
                                              <td>{_prop}</td>
                                              <td>
                                                {getTotalShopper(meth, _prop)}
                                              </td>
                                              {Object.keys(
                                                methCountryCostSet[meth]
                                              ).map((cs) => (
                                                <td>
                                                  {methCountryCostSet[meth][cs]
                                                    ? getCurrentCurrency(
                                                        methCountryCostSet[
                                                          meth
                                                        ][cs][_prop]
                                                      )
                                                    : "0"}
                                                </td>
                                              ))}
                                            </tr>
                                          );
                                        })}
                                        <tr className="shopper-sum-row">
                                          <td>
                                            <strong>
                                              {shopperCostLabels[scfl].label}
                                            </strong>
                                          </td>
                                          <td>
                                            <strong>
                                              {getTotalShopperFinal(meth, scfl)}
                                            </strong>
                                          </td>

                                          {Object.keys(
                                            methCountryCostSet[meth]
                                          ).map((cs) => (
                                            <td>
                                              <strong>
                                                {methCountryCostSet[meth][cs]
                                                  ? getCurrentCurrency(
                                                      _.sum(
                                                        shopperCostLabels[
                                                          scfl
                                                        ]?.properties.map(
                                                          (_prop) =>
                                                            methCountryCostSet[
                                                              meth
                                                            ][cs][_prop]
                                                              ? parseFloat(
                                                                  methCountryCostSet[
                                                                    meth
                                                                  ][cs][_prop]
                                                                )
                                                              : 0
                                                        )
                                                      )
                                                    )
                                                  : "-"}
                                              </strong>
                                            </td>
                                          ))}
                                        </tr>
                                      </>
                                    );
                                  })}
                                  {/* {(methCostFieldsSet[meth]).map(field => {
																return <tr>
																	<td>{methCostFieldsSet[meth][field]}</td>
																	{Object.keys(methCountryCostSet[meth]).map(cs => <td>{methCountryCostSet[meth][cs] ? methCountryCostSet[meth][cs][field] : "-"}</td>)}

																</tr>
															})} */}
                                  <tr></tr>
                                </Table>
                              </TabPane>
                            )
                          )}
                        </TabContent>
                        {/* <Nav tabs>
												{profile.CountrySpecs.map((fc, indxFC) => (
													<NavItem key={fc.value}>
														<NavLink className={classnames({ active: activeTabFCB === indxFC })}
															onClick={() => {

																toggleB(indxFC);
																// loadCostBreakDown()
															}}
														>
															<b>{fc.CountryName}</b>
														</NavLink>
													</NavItem>
												))}
											</Nav>
											<TabContent activeTab={activeTabFCB} style={{ "backgroundColor": "#ffffff" }}>
												{profile.CountrySpecs &&
													profile.CountrySpecs.map((fc, indxFC) => (
														activeTabFCB === indxFC &&
														<TabPane tabId={indxFC} key={fc.value} style={{ "backgroundColor": "#ffffff" }}>
															<Row>

																<Col md={12} className={"methodologyTabs"}>
																	<Nav tabs>
																		{fc.MethodologySpecs &&
																			fc.MethodologySpecs.map((sm, indxSM) => (
																				!sm.NotApplicable && _.includes(shopperMethodologies, sm.Code) &&
																				<NavItem key={sm.value}>
																					<NavLink className={classnames({ active: activeTabSMB === indxSM })}
																						onClick={() => {
																							toggleSMB(indxSM);
																							//	setActiveSM(profile.CountrySpecs[activeTabFCB].MethodologySpecs[activeTabSMB].Code)
																							//loadCostBreakDown()
																						}}
																					>
																						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
																							<div>
																								{sm.NotApplicable}
																								<b>{sm.Label}</b>
																							</div>

																						</div>
																					</NavLink>
																				</NavItem>
																			))}
																	</Nav>
																	<TabContent activeTab={activeTabSMB} style={{ padding: "0.25", "backgroundColor": "#ffffff" }}>
																		{fc.MethodologySpecs &&
																			fc.MethodologySpecs.map((sm, indxSM) => {
																				let _reqMethData = profile.CostingDatum.CostingData.filter(fp => fp.Code == activeSM)
																				return !sm.NotApplicable && activeTabSMB === indxSM ?
																					<TabPane tabId={indxSM} key={sm.value}>

																						{_.includes(shopperMethodologies, sm.Code) ? <Table responsive hover striped bordered={true} size="sm">
																							<thead>
																								<tr>
																									<th>Costing Option</th>

																									{sm.CostingOptions &&
																										sm.CostingOptions.map((co, coi) =>
																											co.Selected &&
																											<th >
																												{
																													<span>
																														{co.Name}
																													</span>

																												}


																											</th>
																										)}

																								</tr>
																								{_reqMethData.length > 0 &&
																									_reqMethData.map(s => Object.entries(s.rows).filter(([fkey, fvalue]) => (fvalue.id > 180 && fvalue.id < 200 || fvalue.id == 131)).map((fd, indxSD) => {
																										return (
																											<tr >
																												<td>{fd[1].cells[0].value}</td>
																												{sm.CostingOptions &&
																													sm.CostingOptions.map((co, coi) =>
																														co.Selected && Object.keys(_.head(_reqMethData)?.rows[1].cells).map(ff => {
																															return (
																																_.head(_reqMethData)?.rows[1]?.cells[ff]?.coid == co.id && _.head(_reqMethData)?.rows[1]?.cells[ff]?.cid == fc.CountryCode && _.head(_reqMethData)?.rows[1]?.cells[ff]?.mid == sm.Code &&

																																<td >
																																	<span>
																																		{getCurrentCurrency(parseFloat(fd[1].cells[ff].value), profile.CostInputCurrency)}
																																	</span>


																																</td>)
																														}
																														)

																													)}

																											</tr>
																										)
																									}))}
																							</thead>


																						</Table> :
																							null}

																					</TabPane> : null
																			})}
																	</TabContent>
																</Col>
															</Row>
														</TabPane>
													))}
											</TabContent> */}
                      </div>
                    </Col>
                  </CardBody>
                </Collapse>
              </Card>
            ) : null}
            {
              <Card className="ml-2 mr-2 mb-2">
                <CardHeader
                  onClick={(e) =>
                    setTabStatus({
                      ...allTabsStatus,
                      profOverview: !allTabsStatus.profOverview,
                    })
                  }
                >
                  <Row>
                    <Col xs="3" sm="8" md="4">
                      <CardTitle className="mb-0">
                        Profitability Overview
                      </CardTitle>
                    </Col>
                    <Col xs="3" sm="3" md="3">
                      {!(
                        project.IsSyndicated &&
                        project.ParentProjectId &&
                        !project.IsCopy
                      ) &&
                        profile.ProfileStatus >= "6" && profile.CommissionedDate < "2024-07-01T00:00:00.000Z" && (
                          <a
                            className="cost-breakdown-link"
                            onClick={(e) => {
                              e.stopPropagation();
                              historylogData();
                            }}
                          >
                            Cost Adjustments History
                          </a>
                        )}
                    </Col>
                    <Col xs="3" sm="3" md="4">
                      {!(
                        project.IsSyndicated &&
                        project.ParentProjectId &&
                        !project.IsCopy
                      ) &&
                        !project?.PriceChange && (
                          <Button
                            disabled={
                              project.PriceChange > 2 ||
                              (project.PriceChange == 2 &&
                                (project.ProjectStatus == "3" ||
                                  project.ProjectStatus == "9"))
                                ? true
                                : profile.ProfileStatus > 1
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              setOOPMutliplierModal(true);
                            }}
                          >
                            Edit Time Cost Multiplier
                          </Button>
                        )}
                    </Col>
                    <Col xs="3" sm="1" md="1">
                      <FontAwesomeIcon
                        className="align-middle mr-2"
                        icon={
                          !allTabsStatus.profOverview
                            ? faChevronRight
                            : faChevronDown
                        }
                        fixedWidth
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={allTabsStatus.profOverview}>
                  <CardBody id="profitabilityBreakdown">
                    <Row>
                      <Col>
                        {project.CostingTemplateVersion != "v2.0" ||
                        (_.includes(
                          pacificMarkets,
                          project.CommissioningCountry
                        ) &&
                          project.CostingTemplateVersion != "v2.0") ? (
                          <Table responsive hover striped bordered={true}>
                            <tbody>
                              <tr>
                                <td xs="11">
                                  Total Internal Operations Cost
                                  <br></br>
                                  <p className="help">
                                    {alternativeLabels[
                                      "CostTotalInternalOperations"
                                    ] &&
                                    Object.keys(
                                      alternativeLabels[
                                        "CostTotalInternalOperations"
                                      ]
                                    ).filter((al) => profile.ProfileSetting[al])
                                      .length
                                      ? "Estimated using Multiplier"
                                      : null}
                                  </p>
                                </td>
                                <td xs="1">
                                  {getCurrentCurrency(
                                    profile.CostTotalInternalOperations
                                  )}
                                </td>
                                {profile.CostInputCurrency !== "US-USD" ? (
                                  <td>
                                    {_.round(
                                      profile.CostTotalInternalOperations,
                                      2
                                    )}{" "}
                                    USD
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td xs="11">
                                  Total External Operations / OOP / Third Party
                                  Cost
                                </td>
                                <td xs="1">
                                  {getCurrentCurrency(
                                    profile.CostTotalExternalOperations
                                  )}
                                </td>
                                {profile.CostInputCurrency !== "US-USD" ? (
                                  <td>
                                    {_.round(
                                      profile.CostTotalExternalOperations,
                                      2
                                    )}{" "}
                                    USD
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td xs="11">
                                  Total Internal Commercial Cost<br></br>
                                  <p className="help">
                                    {alternativeLabels[
                                      "CostTotalInternalCommercial"
                                    ] &&
                                    Object.keys(
                                      alternativeLabels[
                                        "CostTotalInternalCommercial"
                                      ]
                                    ).filter((al) => profile.ProfileSetting[al])
                                      .length
                                      ? "Estimated using Multiplier"
                                      : null}
                                  </p>
                                </td>
                                <td xs="1">
                                  {getCurrentCurrency(
                                    profile.CostTotalInternalCommercial
                                  )}
                                </td>
                                {profile.CostInputCurrency !== "US-USD" ? (
                                  <td>
                                    {_.round(
                                      profile.CostTotalInternalCommercial,
                                      2
                                    )}{" "}
                                    USD
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td xs="11">Total External Commercial Cost</td>
                                <td xs="1">
                                  {getCurrentCurrency(
                                    profile.CostTotalExternalCommercial
                                  )}
                                </td>
                                {profile.CostInputCurrency !== "US-USD" ? (
                                  <td>
                                    {_.round(
                                      profile.CostTotalExternalCommercial,
                                      2
                                    )}{" "}
                                    USD
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td xs="11">MCP Local Margin cost</td>
                                <td xs="1">
                                  {getCurrentCurrency(
                                    profile.CostTotalMCPMargin
                                  )}
                                </td>
                                {profile.CostInputCurrency !== "US-USD" ? (
                                  <td>
                                    {_.round(profile.CostTotalMCPMargin, 2)} USD
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td xs="11">
                                  Overheads{" "}
                                  {profile.ProfileSetting?.PercentOverhead *
                                    100}
                                  %
                                </td>
                                <td xs="1">
                                  {getCurrentCurrency(profile.Overheads)}
                                </td>
                                {profile.CostInputCurrency !== "US-USD" ? (
                                  <td>{_.round(profile.Overheads, 2)} USD</td>
                                ) : null}
                              </tr>
                              <tr>
                                <td xs="11">
                                  Markup to get{" "}
                                  {profile.ProfileSetting
                                    ? profile.ProfileSetting.UsesOOPMarkUp
                                      ? profile.ProfileSetting
                                          .TargetPercentOOPMarkUp * 100
                                      : profile.ProfileSetting
                                          .TargetPercentContributionMargin * 100
                                    : ""}
                                  %{" "}
                                  {profile.ProfileSetting?.UsesOOPMarkUp
                                    ? "OOP"
                                    : "Contribution Margin"}
                                </td>
                                <td xs="1">
                                  {getCurrentCurrency(profile.Markup)}
                                </td>
                                {profile.CostInputCurrency !== "US-USD" ? (
                                  <td>{_.round(profile.Markup, 2)} USD</td>
                                ) : null}
                              </tr>
                              {profile.ProfileSetting &&
                              profile.ProfileSetting.ShowMarginPercentage ? (
                                <tr>
                                  <td xs="11">Margin%</td>
                                  <td xs="1">
                                    {/* //  = (Internal Costs+ External Costs)/Price to ClientX100 */}
                                    {profile.MarginPercent !== null
                                      ? `${profile.MarginPercent}%`
                                      : "-"}
                                  </td>
                                </tr>
                              ) : null}
                            </tbody>
                            <tfoot style={{ borderTop: "2px solid #dee2e6" }}>
                              <th xs="11">
                                Minimum Recommended Price to Client
                              </th>
                              <th xs="1">
                                {getCurrentCurrency(profile.RecommendedPrice)}
                              </th>
                              {profile.CostInputCurrency !== "US-USD" ? (
                                <th>
                                  {_.round(profile.RecommendedPrice, 2)} USD
                                </th>
                              ) : null}
                            </tfoot>
                          </Table>
                        ) : (
                          <div className={"tab mt-12"}>
                            <Nav tabs>
                              <NavItem key="Overall">
                                <NavLink
                                  className={classnames({
                                    active: activeP == 0,
                                  })}
                                  onClick={() => {
                                    setActiveP(0);
                                  }}
                                >
                                  {" "}
                                  Overall
                                </NavLink>
                              </NavItem>
                              <NavItem key="By Country">
                                <NavLink
                                  className={classnames({
                                    active: activeP == 1,
                                  })}
                                  onClick={() => {
                                    setActiveP(1);
                                  }}
                                >
                                  By Country x Methodology
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent activeTab={activeP}>
                              <TabPane tabId={0} key={"overAll"}>
                                <Table responsive hover striped bordered={true}>
                                  <thead>
                                    <th>Parameter</th>
                                    <th>
                                      Grand Total (LCY-
                                      {getCurrencyUnit(
                                        profile.CostInputCurrency
                                      )}
                                      )
                                    </th>
                                    {profile.CostInputCurrency !== "US-USD" ? (
                                      <th>Grand Total (USD)</th>
                                    ) : null}
                                    {profile.IsMultiCountry
                                      ? profile.CountrySpecs.map((cs) => (
                                          <th>{cs.CountryName}</th>
                                        ))
                                      : null}
                                  </thead>
                                  {profitabilityTable.map((row) =>
                                    (row.id != "ClientChargeable" &&
                                      !row.newPriceChange) ||
                                    (row.id == "ClientChargeable" &&
                                      profile?.ProfileSetting
                                        ?.ClientChargeableApplicable == true) ||
                                    (row.newPriceChange &&
                                      project.PriceChange &&
                                      project.PriceChange > 1) ? (
                                      <tr>
                                        <td>{row.label}</td>
                                        {/* <td>{row.isPrice ? getCurrentCurrency(profile[row.id]) : `${_.round(profile[row.id] * 100, 2)} %`}</td> */}
                                        <td>
                                          {/* todo: optimize this || (row.derivativeFields ? _.sum(row.derivativeFields.map(df => profile[df])) : 0) */}
                                          {row.isPrice
                                            ? row.id ===
                                                "CostTotalInternalOperations" ||
                                              row.id ===
                                                "CostTotalInternalCommercial"
                                              ? getGrandTotalWithoutCurrencyCCCoordCosts(
                                                  profile[row.id] ||
                                                    (row.derivativeFields
                                                      ? _.sum(
                                                          row.derivativeFields.map(
                                                            (df) => profile[df]
                                                          )
                                                        )
                                                      : 0),
                                                  row.id
                                                )
                                              : getGrandTotalWithoutCurrency(
                                                  profile[row.id] ||
                                                    (row.derivativeFields
                                                      ? _.sum(
                                                          row.derivativeFields.map(
                                                            (df) => profile[df]
                                                          )
                                                        )
                                                      : 0),
                                                  row.id
                                                )
                                            : `${_.round(
                                                profile[row.id] * 100,
                                                2
                                              )} %`}
                                        </td>
                                        {profile.CostInputCurrency !==
                                        "US-USD" ? (
                                          <td>
                                            {row.id ===
                                              "CostTotalInternalOperations" ||
                                            row.id ===
                                              "CostTotalInternalCommercial"
                                              ? getGrandTotalWithCurrencyCCCoordCosts(
                                                  profile[row.id] ||
                                                    (row.derivativeFields
                                                      ? _.sum(
                                                          row.derivativeFields.map(
                                                            (df) => profile[df]
                                                          )
                                                        )
                                                      : 0),
                                                  row.id
                                                )
                                              : profile[row.id] ||
                                                row.derivativeFields
                                                  ?.map(
                                                    (df) => profile[df] || 0
                                                  )
                                                  ?.filter((df) => df)?.length
                                              ? row.isPrice
                                                ? _.round(
                                                    profile[row.id] ||
                                                      _.sum(
                                                        row.derivativeFields.map(
                                                          (df) =>
                                                            profile[df] || 0
                                                        )
                                                      ),
                                                    2
                                                  )
                                                : _.round(
                                                    profile[row.id] * 100,
                                                    2
                                                  )
                                              : 0}{" "}
                                            {row.isPrice ? "" : "%"}
                                          </td>
                                        ) : null}

                                        {profile.IsMultiCountry
                                          ? profile.CountrySpecs.map((cs) => (
                                              <td>
                                                {row.isPrice
                                                  ? row.id ===
                                                      "CostTotalInternalOperations" ||
                                                    row.id ===
                                                      "CostTotalInternalCommercial"
                                                    ? getCurrentCurrencyOverall(
                                                        cs[row.id] ||
                                                          (row.derivativeFields
                                                            ? _.sum(
                                                                row.derivativeFields.map(
                                                                  (df) => cs[df]
                                                                )
                                                              )
                                                            : 0),
                                                        row.id,
                                                        cs.label
                                                      )
                                                    : getCurrentCurrency(
                                                        cs[row.id] ||
                                                          (row.derivativeFields
                                                            ? _.sum(
                                                                row.derivativeFields.map(
                                                                  (df) => cs[df]
                                                                )
                                                              )
                                                            : 0)
                                                      )
                                                  : `${_.round(
                                                      cs[row.id] * 100,
                                                      2
                                                    )} %`}
                                              </td>
                                            ))
                                          : null}
                                      </tr>
                                    ) : null
                                  )}
                                </Table>
                              </TabPane>
                              <TabPane tabId={1} key="byCountry">
                                <Nav tabs>
                                  {profile.CountrySpecs &&
                                    profile.CountrySpecs.map((fc, indxFC) => (
                                      <NavItem key={fc.value}>
                                        <NavLink
                                          className={classnames({
                                            active: indxFC == activeTabFCP,
                                          })}
                                          onClick={() => {
                                            setActiveTabFCP(indxFC);
                                            setActiveTabSMP(0);
                                          }}
                                        >
                                          <b>
                                            {fc.label
                                              ? fc.label
                                              : fc.CountryName}
                                          </b>
                                        </NavLink>
                                      </NavItem>
                                    ))}
                                </Nav>

                                <TabContent activeTab={activeTabFCP}>
                                  {profile.CountrySpecs &&
                                    profile.CountrySpecs.map(
                                      (fc, indxFC) =>
                                        activeTabFCP === indxFC && (
                                          <TabPane
                                            tabId={indxFC}
                                            key={fc.value}
                                          >
                                            <Row>
                                              <Col
                                                md={12}
                                                className={"methodologyTabs"}
                                              >
                                                <Nav tabs>
                                                  {fc.MethodologySpecs &&
                                                    fc.MethodologySpecs.filter(
                                                      (x) =>
                                                        !x.NotApplicable &&
                                                        x.CostingOptions.some(
                                                          (co) =>
                                                            project.CostingTemplateVersion ==
                                                              "v2.0" &&
                                                            (co.CoSelectedForSummary ==
                                                              true ||
                                                              (co.CoSelectedForSummary ==
                                                                null &&
                                                                co.Selected))
                                                        )
                                                    ).map((sm, indxSM) => (
                                                      <NavItem key={sm.value}>
                                                        <NavLink
                                                          className={classnames(
                                                            {
                                                              active:
                                                                activeTabSMP ===
                                                                indxSM,
                                                            }
                                                          )}
                                                          onClick={() => {
                                                            setActiveTabSMP(
                                                              indxSM
                                                            );
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "space-between",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            <div>
                                                              {sm.NotApplicable}
                                                              <b>
                                                                {sm.label
                                                                  ? sm.label
                                                                  : sm.Label}
                                                              </b>
                                                            </div>
                                                          </div>
                                                        </NavLink>
                                                      </NavItem>
                                                    ))}
                                                </Nav>
                                                <TabContent
                                                  activeTab={activeTabSMP}
                                                  style={{ padding: "0.25" }}
                                                >
                                                  {fc.MethodologySpecs &&
                                                    fc.MethodologySpecs.filter(
                                                      (x) =>
                                                        !x.NotApplicable &&
                                                        x.CostingOptions.some(
                                                          (co) =>
                                                            project.CostingTemplateVersion ==
                                                              "v2.0" &&
                                                            (co.CoSelectedForSummary ==
                                                              true ||
                                                              (co.CoSelectedForSummary ==
                                                                null &&
                                                                co.Selected))
                                                        )
                                                    )?.map(
                                                      (sm, indxSM) =>
                                                        activeTabSMP ===
                                                          indxSM && (
                                                          <TabPane
                                                            tabId={indxSM}
                                                            key={sm.value}
                                                          >
                                                            <Table
                                                              responsive
                                                              hover
                                                              striped
                                                              bordered={true}
                                                            >
                                                              <tr>
                                                                <th>
                                                                  Parameters
                                                                </th>
                                                                <th>
                                                                  Total (LCY-
                                                                  {getCurrencyUnit(
                                                                    profile.CostInputCurrency
                                                                  )}
                                                                  )
                                                                </th>
                                                                <th>
                                                                  Total (USD)
                                                                </th>
                                                              </tr>
                                                              {profitabilityTable.map(
                                                                (row) =>
                                                                  (row.id !=
                                                                    "ClientChargeable" &&
                                                                    !row.newPriceChange) ||
                                                                  (row.id ==
                                                                    "ClientChargeable" &&
                                                                    profile
                                                                      ?.ProfileSetting
                                                                      ?.ClientChargeableApplicable ==
                                                                      true) ||
                                                                  (row.newPriceChange &&
                                                                    project.PriceChange &&
                                                                    project.PriceChange >
                                                                      1) ? (
                                                                    <tr>
                                                                      <td>
                                                                        {
                                                                          row.label
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          row.id==="CountryContributionMarginTargetPercent"?
                                                                          `${_.round(
                                                                            sm[row.id] * 100,
                                                                            2
                                                                          )} %`
                                                                          :
                                                                        row.isPrice
                                                                          ? row.id ===
                                                                              "CostTotalInternalOperations" ||
                                                                            row.id ===
                                                                              "CostTotalInternalCommercial"
                                                                            ? getGrandTotalWithoutCurrency(
                                                                                ((sm.CostsData !=
                                                                                null
                                                                                  ? sm
                                                                                      .CostsData[
                                                                                      row
                                                                                        .id
                                                                                    ]
                                                                                  : sm[
                                                                                      row
                                                                                        .id
                                                                                    ]) ||
                                                                                  (row.derivativeFields
                                                                                    ? _.sum(
                                                                                        row.derivativeFields.map(
                                                                                          (
                                                                                            df
                                                                                          ) =>
                                                                                            sm[
                                                                                              df
                                                                                            ]
                                                                                        )
                                                                                      )
                                                                                    : 0)) +
                                                                                  (isNaN(
                                                                                    row.id ===
                                                                                      "CostTotalInternalOperations"
                                                                                      ? sm
                                                                                          ?.CostingOptions[0]
                                                                                          ?.CostsData
                                                                                          ?.CCOPSCoordinatingCost
                                                                                      : sm
                                                                                          ?.CostingOptions[0]
                                                                                          ?.CostsData
                                                                                          ?.CCCSCoordinatingCost
                                                                                  )
                                                                                    ? 0
                                                                                    : row.id ===
                                                                                      "CostTotalInternalOperations"
                                                                                    ? sm
                                                                                        ?.CostingOptions[0]
                                                                                        ?.CostsData
                                                                                        ?.CCOPSCoordinatingCost
                                                                                    : isNaN(
                                                                                        sm
                                                                                          ?.CostingOptions[0]
                                                                                          ?.CostsData
                                                                                          ?.CCOPSCoordinatingCost
                                                                                      )
                                                                                    ? 0
                                                                                    : sm
                                                                                        ?.CostingOptions[0]
                                                                                        ?.CostsData
                                                                                        ?.CCCSCoordinatingCost),
                                                                                row.id
                                                                              )
                                                                            : getGrandTotalWithoutCurrency(
                                                                                (sm.CostsData !=
                                                                                null
                                                                                  ? sm
                                                                                      .CostsData[
                                                                                      row
                                                                                        .id
                                                                                    ]
                                                                                  : sm[
                                                                                      row
                                                                                        .id
                                                                                    ]) ||
                                                                                  (row.derivativeFields
                                                                                    ? _.sum(
                                                                                        row.derivativeFields.map(
                                                                                          (
                                                                                            df
                                                                                          ) =>
                                                                                            sm[
                                                                                              df
                                                                                            ]
                                                                                        )
                                                                                      )
                                                                                    : 0)
                                                                              )
                                                                          : `${_.round(
                                                                              (sm.CostsData !=
                                                                              null
                                                                                ? sm
                                                                                    .CostsData[
                                                                                    row
                                                                                      .id
                                                                                  ]
                                                                                : sm[
                                                                                    row
                                                                                      .id
                                                                                  ]) *
                                                                                100,
                                                                              2
                                                                            )} %`}
                                                                      </td>
                                                                      <td>
                                                                        {row.id==="CountryContributionMarginTargetPercent"?
                                                                          `${_.round(
                                                                            sm[row.id] * 100,
                                                                            2
                                                                          )} `
                                                                          :
                                                                        row.isPrice
                                                                          ? row.id ===
                                                                              "CostTotalInternalOperations" ||
                                                                            row.id ===
                                                                              "CostTotalInternalCommercial"
                                                                            ? ((sm.CostsData !=
                                                                              null
                                                                                ? sm
                                                                                    .CostsData[
                                                                                    row
                                                                                      .id
                                                                                  ]
                                                                                : sm[
                                                                                    row
                                                                                      .id
                                                                                  ]) ||
                                                                                (row.derivativeFields
                                                                                  ? isNaN(
                                                                                      _.sum(
                                                                                        row.derivativeFields.map(
                                                                                          (
                                                                                            df
                                                                                          ) =>
                                                                                            sm[
                                                                                              df
                                                                                            ]
                                                                                        )
                                                                                      )
                                                                                    )
                                                                                    ? 0
                                                                                    : _.sum(
                                                                                        row.derivativeFields.map(
                                                                                          (
                                                                                            df
                                                                                          ) =>
                                                                                            sm[
                                                                                              df
                                                                                            ]
                                                                                        )
                                                                                      )
                                                                                  : 0)) +
                                                                              (isNaN(
                                                                                row.id ===
                                                                                  "CostTotalInternalOperations"
                                                                                  ? sm
                                                                                      ?.CostingOptions[0]
                                                                                      ?.CostsData
                                                                                      ?.CCOPSCoordinatingCost
                                                                                  : sm
                                                                                      ?.CostingOptions[0]
                                                                                      ?.CostsData
                                                                                      ?.CCCSCoordinatingCost
                                                                              )
                                                                                ? 0
                                                                                : row.id ===
                                                                                  "CostTotalInternalOperations"
                                                                                ? sm
                                                                                    ?.CostingOptions[0]
                                                                                    ?.CostsData
                                                                                    ?.CCOPSCoordinatingCost
                                                                                : isNaN(
                                                                                    sm
                                                                                      ?.CostingOptions[0]
                                                                                      ?.CostsData
                                                                                      ?.CCOPSCoordinatingCost
                                                                                  )
                                                                                ? 0
                                                                                : sm
                                                                                    ?.CostingOptions[0]
                                                                                    ?.CostsData
                                                                                    ?.CCCSCoordinatingCost)
                                                                            : _.round(
                                                                                (sm.CostsData !=
                                                                                null
                                                                                  ? sm
                                                                                      .CostsData[
                                                                                      row
                                                                                        .id
                                                                                    ]
                                                                                  : sm[
                                                                                      row
                                                                                        .id
                                                                                    ]) ||
                                                                                  (row.derivativeFields
                                                                                    ? isNaN(
                                                                                        _.sum(
                                                                                          row.derivativeFields.map(
                                                                                            (
                                                                                              df
                                                                                            ) =>
                                                                                              sm[
                                                                                                df
                                                                                              ]
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                      ? 0
                                                                                      : _.sum(
                                                                                          row.derivativeFields.map(
                                                                                            (
                                                                                              df
                                                                                            ) =>
                                                                                              sm[
                                                                                                df
                                                                                              ]
                                                                                          )
                                                                                        )
                                                                                    : 0),
                                                                                2
                                                                              )
                                                                          : _.round(
                                                                              (sm.CostsData !=
                                                                              null
                                                                                ? sm
                                                                                    .CostsData[
                                                                                    row
                                                                                      .id
                                                                                  ]
                                                                                : sm[
                                                                                    row
                                                                                      .id
                                                                                  ]) *
                                                                                100,
                                                                              2
                                                                            )}{" "}
                                                                        {row.isPrice
                                                                          ? ""
                                                                          : "%"}
                                                                      </td>
                                                                    </tr>
                                                                  ) : null
                                                              )}
                                                            </Table>
                                                          </TabPane>
                                                        )
                                                    )}
                                                </TabContent>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                        )
                                    )}
                                </TabContent>
                              </TabPane>
                            </TabContent>
                          </div>
                        )}
                      </Col>
                    </Row>

                    <div id="profitabilityChecksBreakdown">
                      {
                        (profile.ApprovalDetails || profile.IsZeroCost) &&
                        ((_.includes(
                          pacificMarkets,
                          project.CommissioningCountry
                        ) &&
                          project.CostingTemplateVersion != "v2.0") ||
                          (project.PriceChange && project.PriceChange > 1)) ? (
                          <Table
                            responsive
                            hover
                            striped
                            bordered={true}
                            size="sm"
                          >
                            {/*conditional format color of the results to colors - success danger */}
                            <thead>
                              <th>Profitability Checks</th>
                              <th>Target</th>
                              <th>Current</th>
                            </thead>
                            <tbody>
                              {/* {profile.ApprovalDetails ? (
																profile.ApprovalDetails[0] ? (
                              <> {profile.ProfileSetting
                                  ?.NeedsOutOfPocketCostCheck && ( */}
                              <tr>
                                <td xs="6">OOP %</td>
                                <td xs="3">
                                  {project.PriceChange == 2
                                    ? "This is a preapproved project. No approval needed."
                                    : getOOPThresholdText()}
                                </td>
                                <td
                                  xs="3"
                                  className={getTextColor(
                                    profile.Project.ParentProjectId &&
                                      profile.Project.IsCopy == false
                                      ? true
                                      : profile.CheckPassedOutOfPocket
                                  )}
                                >
                                  {profile.hasOwnProperty(
                                    "OutOfPocketCostPercent"
                                  )
                                    ? (
                                        profile.OutOfPocketCostPercent * 100
                                      ).toFixed(2) + "%"
                                    : null}
                                </td>
                              </tr>

                              {profile.ProfileSetting
                                ?.NeedsOutOfPocketCostCheckR2 &&
                                getOOPThresholdTextR2() && (
                                  <tr>
                                    <td xs="6">OOP % (Rule 2)</td>
                                    <td xs="3">
                                      {project.PriceChange == 2
                                        ? "This is a preapproved project. No approval needed."
                                        : getOOPThresholdTextR2()}
                                    </td>
                                    <td
                                      xs="3"
                                      className={getTextColor(
                                        profile.Project?.ParentProjectId
                                          ? true
                                          : profile.CheckPassedOutOfPocket
                                      )}
                                    >
                                      {profile.hasOwnProperty(
                                        "OutOfPocketCostPercent"
                                      )
                                        ? (
                                            profile.OutOfPocketCostPercent * 100
                                          ).toFixed(2) + "%"
                                        : null}
                                    </td>
                                  </tr>
                                )}

                              {/* {profile.ProfileSetting?.NeedsMarginCheck &&
                              getOOPThresholdTextR3() && (
                                <tr>
                                  <td xs="6">Margin %</td>
                                  <td xs="3">{(project.PriceChange == 2) ? "This is a preapproved project. No approval needed.":getOOPThresholdTextR3()}</td>
                                  <td
                                    xs="3"
                                    className={getTextColor(profile.Project?.ParentProjectId ? true :
                                      profile.CheckPassedOutOfPocket
                                    )}
                                  >
                                    {profile.MarginPercent
                                      ? (profile.MarginPercent * 100).toFixed(
                                        2
                                      ) + "%"
                                      : null}
                                  </td>
                                </tr>
                              )} */}
                              {/* )}   </>
																) : null
                           ) : null} */}
                              {profile.ProfileSetting
                                ?.NeedsCommercialCostCheck && (
                                <tr>
                                  <td xs="11">Commercial Cost %</td>
                                  <td xs="3">
                                    {profile.ProfileSetting
                                      .ThresholdPercentIntCommCost * 100}
                                    % or more
                                  </td>
                                  <td
                                    xs="3"
                                    className={getTextColor(
                                      profile.Project?.ParentProjectId
                                        ? true
                                        : profile.CheckPassedCommercialCost
                                    )}
                                  >
                                    {profile.InternalCommercialCostPercent
                                      ? (
                                          profile.InternalCommercialCostPercent *
                                          100
                                        ).toFixed(2) + "%"
                                      : "0%"}
                                  </td>
                                </tr>
                              )}
                              {profile.ProfileSetting?.NeedsNetRevenueCheck && (
                                <tr>
                                  <td xs="11">Net Revenue %</td>
                                  <td xs="3">
                                    {
                                      profile.ProfileSetting
                                        .ThresholdPercentNetRevenue
                                    }
                                    % or more
                                  </td>
                                  <td
                                    xs="3"
                                    className={getTextColor(
                                      profile.Project?.ParentProjectId
                                        ? true
                                        : profile.CheckPassedNetRevenue
                                    )}
                                  >
                                    {(
                                      profile.NetRevenuePercent * 100 ||
                                      (!isNaN(
                                        (profile.PriceToClient -
                                          profile.TotalExternalCosts) /
                                          profile.PriceToClient
                                      )
                                        ? (profile.PriceToClient -
                                            profile.TotalExternalCosts) /
                                            profile.PriceToClient !=
                                          -Infinity
                                          ? (profile.PriceToClient -
                                              profile.TotalExternalCosts) /
                                            profile.PriceToClient
                                          : 0
                                        : 0) * 100
                                    ).toFixed(2) + "%"}
                                  </td>
                                </tr>
                              )}
                              {profile.ProfileSetting
                                ?.NeedsContributionMarginCheck && (
                                <tr>
                                  <td xs="11">Minimum Contribution Margin %</td>
                                  <td xs="3">
                                    {profile.ProfileSetting
                                      .TargetPercentContributionMargin * 100}
                                    % or more
                                  </td>
                                  <td
                                    xs="3"
                                    className={getTextColor(
                                      profile.Project?.ParentProjectId
                                        ? true
                                        : profile.CheckPassedContributionMargin
                                    )}
                                  >
                                    {profile.ContributionMarginPercent ||
                                    profile.ContributionMarginPercent == 0
                                      ? (
                                          profile.ContributionMarginPercent *
                                          100
                                        ).toFixed(2) + "%"
                                      : null}
                                  </td>
                                </tr>
                              )}
                              {profile.ProfileSetting
                                ?.NeedsMinimumProjectValueCheck && (
                                <tr>
                                  <td xs="11">
                                    Minimum Project Value (Price to Client)
                                  </td>
                                  <td xs="3">
                                    {getActualPrice(
                                      profile.ProfileSetting
                                        .ThresholdPriceToClient,
                                      true
                                    )}
                                  </td>
                                  <td
                                    xs="3"
                                    className={getTextColor(
                                      profile.Project?.ParentProjectId
                                        ? true
                                        : profile.CheckPassedMinimumProjectValue
                                    )}
                                  >
                                    {getActualPrice(
                                      tempPriceToClient
                                        ? tempPriceToClient
                                        : profile.PriceToClient,
                                      true
                                    )}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        ) : project.PriceChange ==
                          2 ? null : profile.ApprovalDetails ||
                          profile.IsZeroCost ? (
                          <Table
                            responsive
                            hover
                            striped
                            bordered={true}
                            size="sm"
                          >
                            {/*conditional format color of the results to colors - success danger */}
                            <thead>
                              <th>Profitability Checks</th>
                              <th>Target</th>
                              <th>Current</th>
                            </thead>
                            <tr>
                              {project.PriceChange == 2 ? (
                                <td xs="6">OOP Margin %</td>
                              ) : (
                                <td xs="6">{profile.CountryContributionMarginTargetPercent===null&&profile.CostingsSheetId!==null? "Project Margin %":"Contribution Margin %"}</td>
                              )}
                              <td xs="3">
                                {/* {profile.MarginPercent >=0.1 ? " Approval required" :  "Approval required"} */}
                                {approvalRequiredFlag === "ApprovalRequired"
                                  ? " Approval required"
                                  : approvalRequiredFlag ===
                                    "ContinueWithComission"
                                  ? "Approval is not required"
                                  : "Approval is not required"}
                              </td>
                              <td
                                xs="3"
                                className={getTextColor(
                                  (profile.Project.ParentProjectId &&
                                    profile.Project.IsCopy) == null &&
                                    (profile.ProfileStatus === "3" &&
                                    approvalRequiredFlag === "ApprovalRequired"
                                      ? false
                                      : approvalRequiredFlag ===
                                          "ContinueWithComission" ||
                                        approvalRequiredFlag ===
                                          "ApprovalNotRequired" ||
                                        profile.ProfileStatus === "3")
                                    ? true
                                    : approvalRequiredFlag ===
                                      "ApprovalRequired"
                                    ? false
                                    : true
                                )}
                              >
                                {profile.hasOwnProperty("MarginPercent")
                                  ? (profile.MarginPercent * 100).toFixed(2) +
                                    "%"
                                  : null}
                              </td>
                            </tr>
                          </Table>
                        ) 
                        : project.checkProfitValue !== null? (<Table
                          responsive
                          hover
                          striped
                          bordered={true}
                          size="sm"
                        >
                          {/*conditional format color of the results to colors - success danger */}
                          <thead>
                            <th>Profitability Checks</th>
                            <th>Target</th>
                            <th>Current</th>
                          </thead>
                          <tr>
                            {project.PriceChange == 2 ? (
                              <td xs="6">OOP Margin %</td>
                            ) : (
                              <td xs="6">{profile.CountryContributionMarginTargetPercent===null&&profile.CostingsSheetId!==null? "Project Margin %":"Contribution Margin %"}</td>
                            )}
                            <td xs="3">
                              {/* {profile.MarginPercent >=0.1 ? " Approval required" :  "Approval required"} */}
                              {approvalRequiredFlag === "ApprovalRequired"
                                ? " Approval required"
                                : approvalRequiredFlag ===
                                  "ContinueWithComission"
                                ? "Approval is not required"
                                : "Approval is not required"}
                            </td>
                            <td
                              xs="3"
                              className={getTextColor(
                                (profile.Project.ParentProjectId &&
                                  profile.Project.IsCopy) == null &&
                                  (profile.ProfileStatus === "3" &&
                                  approvalRequiredFlag === "ApprovalRequired"
                                    ? false
                                    : approvalRequiredFlag ===
                                        "ContinueWithComission" ||
                                      approvalRequiredFlag ===
                                        "ApprovalNotRequired" ||
                                      profile.ProfileStatus === "3")
                                  ? true
                                  : approvalRequiredFlag === "ApprovalRequired"
                                  ? false
                                  : true
                              )}
                            >
                              {profile.hasOwnProperty("MarginPercent")
                                ? (profile.MarginPercent * 100).toFixed(2) + "%"
                                : null}
                            </td>
                          </tr>
                        </Table>): null
                        // (
                        //   <div className="text-right">
                        //     <strong>Please Input Price to Client</strong>
                        //   </div>
                        // )
                      }
                    </div>
                    {statusCheckProfit &&
                      profile.ApprovalLevelNeeded != -1 &&
                      profile.ApprovalLevelNeeded != null &&
                      profile.ApprovalLevelAwaiting != null &&
                      profile.ProfileStatus == 1 &&
                      (!profile.IsZeroCost || !isZeroCost) && (
                        <Button
                          style={{ float: "right", marginBottom: "10px" }}
                          disabled={
                            project.PriceChange >= 2
                              ? true
                              : profile.ProfileStatus > 1
                          }
                          onClick={(e) => {
                            if (validateForms()) {
                              continueWithApproval();
                            }
                          }}
                        >
                          Continue With Approval
                        </Button>
                      )}

                    {profile.ExceptionCommision||((project.PriceChange ==2) || (  (profile.ProfileStatus === "1" && (profile.IsZeroCost || (isZeroCost && statusCheckProfit))) 
                      || (statusCheckProfit && !isZeroCost && profile.ProfileStatus === "1" && !profile.NeedsApproval && (profile.PriceToClient > 0 || profile.OutOfPocketCostPercent > 0)) || profile.ProfileStatus === "5" || (!profile.NeedsApproval && profile.IsSyndicated && statusCheckProfit))) ?
                      (<Button style={{ float: "right", marginBottom: "10px" }}
                          disabled={profile.ProfileStatus === "6" ||project.ProjectStatus === "7"||profile.ProfileStatus === "99"}
                          onClick={() => {
                            axios
                            .get(
                              `/projects/duplicate/${
                                project.ProjectId
                              }`
                            )
                            .then((res) => {
                              console.log(res)
                             isDuplicate = res.data.isDuplicate
                             console.log(isDuplicate,"duplicate")
                             if(isDuplicate.length == 0 || userRecord.CanCreateDuplicateProposal){
                               if(validateForms("commission") ){
                                 checkCommission()
                               }
                             }else{
                               console.log(isDuplicate,"else")
                              toastr.error(`There is an existing project with the same opportunity/WBS numbers ${isDuplicate}. You need to get a new Opportunity Number and WBS from MSD / Finance team and create a new CINTRAL Project with this opportunity.`)
                             }
                            })                              
                              
                          }}>
                          Continue to Commission
                        </Button>):null
                      }

                    {/* {statusCheckProfit ?
                      <Button style={{ float: "right", marginBottom: "10px" }}
                        disabled={profile.ProfileStatus == "6"}
                        onClick={() => {
                          checkCommission()
                        }}>
                        Continue to Commission
                      </Button> 
                      : null
                      } */}

                    <CommissioningConfirmModal
                      confirmCommission={confirmCommission}
                      setConfirmCommission={setConfirmCommission}
                    />
                  </CardBody>
                </Collapse>
              </Card>
            }
            <Card className="ml-2 mr-2 mb-2">
              <CardHeader>
                <Row className="d-flex justify-content-between">
                  <Col
                    xs="12"
                    lg="3"
                    md="3"
                    sm="12"
                    className="align-self-center mt-1 mb-1"
                  >
                    <CardTitle className="mb-0">
                      Actual Price Given To Client
                    </CardTitle>
                  </Col>
                  <Col
                    xs="12"
                    lg="9"
                    md="9"
                    sm="12"
                    className="align-self-center"
                  >
                    <Row className="d-flex justify-content-end">
                      <Col
                        xs="12"
                        lg="6"
                        md="6"
                        sm="12"
                        className="align-self-center mt-1 mb-1"
                      >
                        <Select
                          // isDisabled={profile.ProfileStatus > 1}
                          placeholder="Select a Currency..."
                          options={currencies?.map((c) => {
                            return {
                              value: `${c.Code}-${c.CurrencyUnit}`,
                              label: `${c.Label} (1USD = ${c.ConversionRateToLocal}${c.CurrencyUnit})`,
                            };
                          })}
                          value={
                            profile.CostInputCurrency
                              ? getCurrentCurrencyUnit(
                                  profile.CostInputCurrency
                                )
                              : null
                          }
                          onChange={summaryCurrencyChange}
                        />
                      </Col>
                      <Col
                        xs="12"
                        lg="4"
                        md="4"
                        sm="12"
                        className="align-self-center mt-1 mb-1"
                      >
                        <InputGroup className="m-0">
                          <Input
                            defaultValue="0"
                            disabled={
                              profile.Project?.ProjectStatus === "7" ||
                              profile.ProfileStatus > 1 ||
                              getDisabledStatus()
                            }
                            type="number"
                            placeholder="Enter Amount..."
                            value={
                              profile.PriceToClient
                                ? getActualPrice(profile.PriceToClient)
                                : null
                            }
                            onChange={(e) => {
                              dispatch(
                                currentCostingActions.updateProfile({
                                  PriceToClient:
                                    e.target.value /
                                    currentCurrency.ConversionRateToLocal,
                                })
                              );
                            }}
                          ></Input>
                          <InputGroupAddon addonType="append">
                            {getCurrencyUnit(profile.CostInputCurrency)}
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>

                      <Col
                        xs="12"
                        lg="2"
                        md="2"
                        sm="12"
                        className="align-self-center mt-1 mb-1"
                      >
                        {_.includes(
                          pacificMarkets,
                          project.CommissioningCountry
                        ) && project.CostingTemplateVersion != "v2.0" ? (
                          <Button
                            disabled={
                              project.PriceChange > 1
                                ? true
                                : profile.Project?.ProjectStatus === "7" ||
                                  (profile.Project?.ProjectStatus === "8" &&
                                    profile.IsDeleted == true) ||
                                  profile.ProfileStatus > 1 ||
                                  profile.IsImportedProfile
                            }
                            onClick={(e) => {
                              setTempPriceToClient(
                                _.cloneDeep(profile.PriceToClient)
                              );
                              dispatch(
                                currentCostingActions.generateProfitability(
                                  profile.PriceToClient
                                )
                              );
                              setStatusCheckProfit(true);
                            }}
                          >
                            Check Profitability
                          </Button>
                        ) : (
                          <Button
                            disabled={
                              project.PriceChange > 1
                                ? true
                                : profile.Project?.ProjectStatus === "7" ||
                                  profile.ProfileStatus > 1 ||
                                  profile.IsImportedProfile
                            }
                            onClick={(e) => {
                              axios.put(`/projects/${project.ProjectId}/updateStatus`,
                                {checkProfitValue : false}
                              )
                              let projectType;
                              let checkApproval = true;
                              if (
                                project.PriceChange != "2" &&
                                !(
                                  project.IsSyndicated &&
                                  project.ParentProjectId &&
                                  !project.IsCopy
                                )
                              ) {
                                checkApproval = checkProjectType(profile);
                              }

                              if (checkApproval) {
                                dispatch(
                                  currentCostingActions.generateProfitability(
                                    profile.PriceToClient
                                  )
                                );
                                setStatusCheckProfit(true);
                              }
                            }}
                          >
                            Check Profitability
                          </Button>
                        )}
                      </Col>
                    </Row>

                    <Row className="d-flex justify-content-end">
                      <Col
                        xs="12"
                        lg="6"
                        md="6"
                        sm="12"
                        className="align-self-center mt-1 mb-1"
                      ></Col>
                      <Col
                        xs="12"
                        lg="4"
                        md="4"
                        sm="12"
                        className="align-self-center mt-1 mb-1"
                      >
                        {(project.IsSyndicated &&
                          project.ParentProjectId &&
                          !project.IsCopy &&
                          statusCheckProfit &&
                          profile.ProfileStatus == 1 &&
                          (!profile.OutOfPocketCostPercent ||
                            profile.OutOfPocketCostPercent == 0 ||
                            profile.PriceToClient == 0)) ||
                        (profile.IsZeroCost &&
                          (profile.PricetoClient == 0 ||
                            !profile.OutOfPocketCostPercent ||
                            profile.OutOfPocketCostPercent == 0)) ? (
                          <CustomInput
                            style={{ float: "right", marginBottom: "10px" }}
                            type="switch"
                            id="zeroCost"
                            name="zeroCost"
                            disabled={
                              profile.ProfileStatus > 1 ||
                              project.ProjectStatus === "7"
                            }
                            className="h5 mb-3"
                            label={
                              profile.PriceToClient == 0
                                ? "Please confirm this project has zero Price To Client"
                                : "Please confirm this project has zero cost​"
                            }
                            checked={profile.IsZeroCost}
                            onChange={(e) => {
                              setIsZeroCost(e.target.checked);
                              profile.IsZeroCost = e.target.checked;
                            }}
                          />
                        ) : null}
                      </Col>
                      <Col
                        xs="12"
                        lg="2"
                        md="2"
                        sm="12"
                        className="align-self-center mt-1 mb-1"
                      ></Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ justifyContent: "right" }}>
                  <Link
                    onClick={() => {
                      if (historyData.length > 0) {
                        sethistoryApprovalPopup(true);
                      }
                    }}
                  >
                    <div
                      style={{
                        color: historyData.length > 0 ? "#000" : "#dfdfdf",
                      }}
                    >
                      Reason for reset of approval
                    </div>
                  </Link>
                </Row>
              </CardHeader>
            </Card>
            {historyApprovalPopup && (
              <ReasonForApproval
                historyApprovalPopup={historyApprovalPopup}
                sethistoryApprovalPopup={sethistoryApprovalPopup}
                reasonForApproval={reasonForApproval}
                historyData={historyData}
                getHistoryForApproval={getHistoryForApproval}
              />
            )}
            <Card style={{ display: "none" }} className="ml-2 mr-2 mb-2">
              <CardHeader
                onClick={(e) =>
                  setTabStatus({
                    ...allTabsStatus,
                    methSummary: !allTabsStatus.methSummary,
                  })
                }
              >
                <Row>
                  <Col xs="10" sm="11" md="11">
                    <CardTitle className="mb-0">
                      Methodologies &amp; Fieldwork Breakdown{" "}
                      {profile.IsMultiCountry ? "(Per Country)" : ""}
                    </CardTitle>
                  </Col>

                  <Col xs="2" sm="1" md="1">
                    <FontAwesomeIcon
                      className="align-middle mr-2"
                      icon={
                        !allTabsStatus.methSummary
                          ? faChevronRight
                          : faChevronDown
                      }
                      fixedWidth
                    />
                  </Col>
                </Row>
              </CardHeader>
              <Collapse isOpen={allTabsStatus.methSummary}>
                <CardBody id="methodologiesBreakdown">
                  <Table responsive hover striped bordered={true} size="sm">
                    <tbody>
                      {_.head(profile.CountrySpecs)?.MethodologySpecs?.map(
                        (ms) => {
                          if (ms) {
                            return (
                              <>
                                <tr className="meth-head-country">
                                  <th>
                                    <h5 class="text-uppercase mb-0">
                                      {ms.Label}
                                    </h5>
                                  </th>
                                  {profile.CountrySpecs.map((country) => (
                                    <th>
                                      {getLabel(
                                        "FieldingCountriesOptions",
                                        country.CountryCode
                                      )}
                                    </th>
                                  ))}
                                </tr>
                                {allRFQSchema[ms.Code] &&
                                Object.keys(allRFQSchema[ms.Code]).length ? (
                                  Object.keys(
                                    allRFQSchema[ms.Code].properties
                                  )?.map((rfq) => {
                                    let property = getProperty(
                                      ms.RFQSchema,
                                      rfq
                                    );
                                    return property ? (
                                      <>
                                        {property.isNewSection ? (
                                          <tr>
                                            <th>{property.sectionTitle}</th>
                                            {profile.CountrySpecs.map(
                                              (country) => (
                                                <th>
                                                  {/* {getLabel(
                                                                                                        "FieldingCountriesOptions",
                                                                                                        country.CountryCode
                                                                                                    )} */}
                                                </th>
                                              )
                                            )}
                                          </tr>
                                        ) : null}
                                        <tr>
                                          <td>{property.title}</td>
                                          {profile.CountrySpecs.map(
                                            (country) => {
                                              // let methRfqData = ms.RFQData?? {} // Test
                                              var filtered = Object.entries(
                                                ms.RFQSchema.properties
                                              ).filter(
                                                ([key, value]) =>
                                                  !value.isDefaultCostingOption
                                              );

                                              ms.RFQSchema.properties = {
                                                ...Object.fromEntries(filtered),
                                              };

                                              if (!allRFQSchema[ms.Code])
                                                allRFQSchema[ms.Code] =
                                                  ms.RFQSchema;
                                              if (
                                                !rfqData[country.CountryCode]
                                              ) {
                                                rfqData[country.CountryCode] =
                                                  {};
                                              }
                                              return (
                                                <td>
                                                  {rfqData[
                                                    country.CountryCode
                                                  ] &&
                                                  rfqData[country.CountryCode][
                                                    ms.Code
                                                  ]
                                                    ? Array.isArray(
                                                        rfqData[
                                                          country.CountryCode
                                                        ][ms.Code][rfq]
                                                      )
                                                      ? rfqData[
                                                          country.CountryCode
                                                        ][ms.Code][rfq].length
                                                        ? rfqData[
                                                            country.CountryCode
                                                          ][ms.Code][rfq]
                                                            .map((v) => v.label)
                                                            .join()
                                                        : "-"
                                                      : typeof rfqData[
                                                          country.CountryCode
                                                        ][ms.Code][rfq] ==
                                                        "object"
                                                      ? rfqData[
                                                          country.CountryCode
                                                        ][ms.Code][rfq].value
                                                      : typeof rfqData[
                                                          country.CountryCode
                                                        ][ms.Code][rfq] ==
                                                        "boolean"
                                                      ? rfqData[
                                                          country.CountryCode
                                                        ][ms.Code][rfq]
                                                        ? "Yes"
                                                        : "No"
                                                      : rfqData[
                                                          country.CountryCode
                                                        ][ms.Code][rfq]
                                                      ? rfqData[
                                                          country.CountryCode
                                                        ][ms.Code][rfq]
                                                      : "-"
                                                    : "-"}
                                                </td>
                                              );
                                            }
                                          )}
                                        </tr>
                                      </>
                                    ) : null;
                                  })
                                ) : (
                                  <tr>
                                    <td>{ms.Label}</td>
                                    <td>
                                      No data is available for this methodology.
                                    </td>
                                  </tr>
                                )}
                              </>
                            );
                          }
                        }
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Collapse>
            </Card>

            <Card style={{ display: "none" }} className="ml-2 mr-2 mb-2">
              <CardHeader
                onClick={(e) =>
                  setTabStatus({
                    ...allTabsStatus,
                    opsResources: !allTabsStatus.opsResources,
                  })
                }
              >
                <Row>
                  <Col xs="10" sm="11" md="11">
                    <CardTitle className="mb-0">
                      Operations Resources Breakdown{" "}
                    </CardTitle>
                  </Col>
                  <Col xs="2" sm="1" md="1">
                    <FontAwesomeIcon
                      className="align-middle mr-2"
                      icon={
                        !allTabsStatus.opsResources
                          ? faChevronRight
                          : faChevronDown
                      }
                      fixedWidth
                    />
                  </Col>
                </Row>
              </CardHeader>
              <Collapse isOpen={allTabsStatus.opsResources}>
                <CardBody id="operationsResourceBreakdown">
                  <Table responsive hover striped bordered={true} size="sm">
                    <thead>
                      <tr>
                        <th></th>

                        {profile.WaveSpecs?.filter((ws) =>
                          _.includes(
                            allApplicableMethIds,
                            ws?.MethodologySpecId
                          )
                        )?.map((ws) => {
                          return (
                            <>
                              <th>
                                <p className="m-0">
                                  # {ws.WaveNumber} {ws.WaveName},
                                </p>
                                <p className="m-0">
                                  {
                                    methCountrySet[ws.MethodologySpecId]
                                      ?.methlabel
                                  }
                                  ,
                                </p>
                                <p className="m-0">
                                  {
                                    methCountrySet[ws.MethodologySpecId]
                                      ?.country
                                  }
                                </p>
                              </th>
                            </>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {allOpsResourcesFields?.map((ors) => {
                        let property = getOpsTitleProperty(ors);
                        return property ? (
                          <>
                            <tr>
                              <td>{property.title}</td>
                              {profile.WaveSpecs?.filter((ws) =>
                                _.includes(
                                  allApplicableMethIds,
                                  ws?.MethodologySpecId
                                )
                              )?.map((wave) => (
                                <td>
                                  {opsData[wave.id]
                                    ? getOpsValue(opsData[wave.id][ors], ors)
                                    : "-"}
                                </td>
                              ))}
                            </tr>
                          </>
                        ) : null;
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Collapse>
            </Card>

            <Card style={{ display: "none" }} className="ml-2 mr-2 mb-2">
              <CardHeader>
                <Row>
                  <Col xs="10" sm="11" md="11">
                    <CardTitle className="mb-0">Costing Options</CardTitle>
                  </Col>
                  <Col xs="2" sm="1" md="1">
                    <FontAwesomeIcon
                      className="align-middle mr-2"
                      icon={
                        !allTabsStatus.opsResources
                          ? faChevronRight
                          : faChevronDown
                      }
                      fixedWidth
                    />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody id="costingOptionsBreakdown">
                <Table responsive hover striped bordered={true} size="sm">
                  <tbody>
                    {profile.CountrySpecs?.map((cs) => {
                      return (
                        <>
                          <tr>
                            <td colSpan="12">
                              <strong>{cs.CountryName}</strong>
                            </td>
                          </tr>
                          <tr>
                            <th></th>
                            {cs.MethodologySpecs?.filter(
                              (ms) => !ms.NotApplicable
                            )?.map((ms) => {
                              return ms?.CostingOptions?.filter(
                                (co) => co.Selected
                              )?.map((cso) => {
                                return (
                                  <th>
                                    {ms.Label}-{cso.Name}
                                  </th>
                                );
                              });
                            })}
                          </tr>
                          {allCostingOptions.length
                            ? allCostingOptions.map((aco) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{aco.label}</td>
                                      {cs.MethodologySpecs?.filter(
                                        (ms) => !ms.NotApplicable
                                      )?.map((ms) => {
                                        return ms?.CostingOptions?.filter(
                                          (co) => co.Selected
                                        )?.map((cso) => {
                                          return (
                                            <td>
                                              {cso.CostingOptionData
                                                ? Array.isArray(
                                                    cso.CostingOptionData[
                                                      aco.id
                                                    ]
                                                  )
                                                  ? cso.CostingOptionData[
                                                      aco.id
                                                    ]
                                                      .map((v) => v.label)
                                                      .join()
                                                  : typeof cso
                                                      .CostingOptionData[
                                                      aco.id
                                                    ] == "object"
                                                  ? cso.CostingOptionData[
                                                      aco.id
                                                    ].label
                                                  : cso.CostingOptionData[
                                                      aco.id
                                                    ]
                                                : "-"}
                                            </td>
                                          );
                                        });
                                      })}
                                    </tr>
                                  </>
                                );
                              })
                            : null}
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>

            {profile.ApprovalLevelNeeded != -1 &&
            profile.ApprovalLevelNeeded != null &&
            profile.ApprovalLevelAwaiting != null &&
            profile.ProfileStatus > 1 ? (
              <Card className="ml-2 mr-2 mb-2">
                <CardHeader>
                  <Row>
                    <Col xs="3" sm="8" md="4">
                      <CardTitle className="mb-0">Approvals</CardTitle>
                    </Col>
                    <Col xs="3" sm="3" md="3"></Col>
                    <Col xs="3" sm="3" md="4">
                      {((profile?.ProfileStatus !== "6" &&
                        profile?.Project?.ProposalOwnerEmail?.value.toLowerCase() ===
                          userRecord.Email.toLowerCase()) ||
                        project.OtherProjectTeamContacts.map(
                          (otc) => otc.value
                        ).includes(userRecord.Email.toLowerCase())) && (
                        <Row className="justify-content-end m-0">
                          <Button
                            className="m-2"
                            disabled={
                              project.PriceChange > 2
                                ? true
                                : profile.ProfileStatus == "6"
                            }
                            // onClick={(e) => {

                            //   setResetModal(true)
                            //   setIsBtnDisabled(false)
                            // }}
                            onClick={(e) => {
                              checkForReset();
                              // setStatusCheckApproval(false);
                              // setStatusCheckProfit(false)
                              // setStatusCheckProfit(true);
                              if (resetFlag) {
                                setStatusCheckApproval(false);
                                setStatusCheckProfit(false);
                                resetFlag = null;
                              } else {
                                setStatusCheckApproval(false);
                                setStatusCheckProfit(true);
                              }
                            }}
                          >
                            Reset Approvals
                          </Button>

                          <Button
                            className="m-2"
                            disabled={
                              project.PriceChange >= 2
                                ? true
                                : isBtnDisabled ||
                                  profile.ProfileStatus == "3" ||
                                  profile.ProfileStatus == "5" ||
                                  profile.ProfileStatus == "6"
                            }
                            onClick={(e) => {
                              if (validateApprovalForm()) {
                                setInvalid({
                                  ...invalid,
                                  ApprovalJustification: false,
                                });
                                dispatch(
                                  currentCostingActions.sendForApproval()
                                );
                                setIsBtnDisabled(true);
                              } else {
                                setInvalid({
                                  ...invalid,
                                  ApprovalJustification: true,
                                });
                              }
                            }}
                          >
                            Send For Approval
                          </Button>
                        </Row>
                      )}
                    </Col>
                    <Col xs="3" sm="1" md="1">
                      <FontAwesomeIcon
                        className="align-middle mr-2"
                        onClick={(e) =>
                          setTabStatus({
                            ...allTabsStatus,
                            approvals: !allTabsStatus.approvals,
                          })
                        }
                        icon={
                          !allTabsStatus.approvals
                            ? faChevronRight
                            : faChevronDown
                        }
                        fixedWidth
                      />
                    </Col>
                  </Row>
                </CardHeader>

                <Collapse isOpen={allTabsStatus.approvals}>
                  <CardBody>
                    {profile.ApprovalDetails
                      ? profile.ApprovalDetails.map((level) => {
                          if (level.Order <= profile.ApprovalLevelNeeded) {
                            return (
                              <>
                                <Row>
                                  <Col>{level.Label}</Col>
                                  <Col>
                                    <span>
                                      {profile.ApprovalLevelReached >=
                                      level.Order
                                        ? "Approved"
                                        : level.ApproverContacts.length &&
                                          level.ApproverContacts[0]?.Denied
                                        ? "Approval Denied"
                                        : profile.ApprovalLevelAwaiting ===
                                          level.Order
                                        ? "Awaiting"
                                        : null}
                                    </span>
                                  </Col>
                                </Row>
                                <br />
                                <Col>
                                  {level.ApproverContacts?.map((contact) => {
                                    return (
                                      <Row>
                                        <Col>
                                          {contact.Approved ? (
                                            <FontAwesomeIcon
                                              icon={faThumbsUp}
                                              className="text-muted align-middle mr-2"
                                              title="Appproval Granted"
                                              size="sm"
                                            />
                                          ) : contact.Denied ? (
                                            <FontAwesomeIcon
                                              icon={faThumbsDown}
                                              title="Appproval Denied"
                                              className="text-muted align-middle mr-2"
                                              size="sm"
                                            />
                                          ) : (
                                            <FontAwesomeIcon
                                              icon={faHourglassHalf}
                                              title="Appproval Awaited"
                                              className="text-muted align-middle mr-2"
                                              size="sm"
                                            />
                                          )}
                                          {contact.EmailAddress}
                                          {contact.IsMandatoryApprover ? (
                                            <FontAwesomeIcon
                                              icon={faExclamationCircle}
                                              title="Mandatory Approver for this level."
                                              className="text-muted align-middle ml-2"
                                              size="sm"
                                            />
                                          ) : null}

                                          {delegateEmail.map((del) => {
                                            return del.map((mail) => {
                                              if (
                                                mail.ApproverUserEmail.toLowerCase() ===
                                                contact.EmailAddress.toLowerCase()
                                              ) {
                                                return (
                                                  <p>
                                                    (Approval is delegated to{" "}
                                                    <b>
                                                      {mail.DelegateUserEmail}
                                                    </b>{" "}
                                                    from {""}
                                                    {
                                                      mail.StartAt.split("T")[0]
                                                    }{" "}
                                                    {""} to {""}{" "}
                                                    {mail.EndAt.split("T")[0]})
                                                  </p>
                                                );
                                              }
                                            });
                                          })}
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </Col>
                                <br />
                              </>
                            );
                          }
                        })
                      : null}
                    <Row className="mb-2">
                      <Col xs="12" sm="8" md="6">
                        <Label>Approval Request Justification *</Label>
                        <Input
                          id="approvalJustification"
                          type="textarea"
                          className="mb-2"
                          invalid={invalid.ApprovalJustification}
                          value={profile.ApprovalJustification}
                          onChange={(e) => {
                            dispatch(
                              currentCostingActions.updateProfile({
                                ApprovalJustification: e.target.value,
                              })
                            );
                          }}
                          placeholder="Please provide request justification..."
                          disabled={profile.ProfileStatus > 2}
                        />
                        {invalid.ApprovalJustification ? (
                          <p className="error small d-block">
                            Please enter a justification
                          </p>
                        ) : null}
                      </Col>
                      <Col xs="12" sm="8" md="6"></Col>
                    </Row>

                    {profile.ProfileSetting.ShowDetailedApprovalForm ? (
                      <>
                        <Row className="mb-2">
                          <Col xs="12" sm="8" md="6">
                            <Label>New / Renewal Project? *</Label>
                            <SelectWrapper
                              type="select"
                              id="newRenewalProject"
                              invalid={approvalFormValidation.newRenewalProject}
                              value={
                                profile.ApprovalFormData?.newRenewalProject ||
                                null
                              }
                              onChange={(e) => {
                                approvalFormOnChange(e);
                              }}
                              disabled={profile.ProfileStatus > 2}
                            >
                              <option></option>
                              <option>New</option>
                              <option>Renewal</option>
                            </SelectWrapper>
                            {approvalFormValidation.newRenewalProject ? (
                              <p className="error small d-block">
                                This is a required field.
                              </p>
                            ) : null}
                          </Col>
                          <Col xs="12" sm="8" md="6">
                            <Label>Request Date *</Label>
                            <Input
                              type="Date"
                              id="requestDate"
                              className={
                                approvalFormValidation.requestDate
                                  ? "is-invalid"
                                  : ""
                              }
                              value={
                                profile.ApprovalFormData?.requestDate || null
                              }
                              onChange={(e) => {
                                approvalFormOnChange(e);
                              }}
                              disabled={profile.ProfileStatus > 2}
                            ></Input>
                            {approvalFormValidation.requestDate ? (
                              <p className="error small d-block">
                                This is a required field.
                              </p>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs="12" sm="8" md="6">
                            <Label>
                              Reason(s) for high % variable cost (OOP) *
                            </Label>
                            <textarea
                              id="reasonForOOP"
                              className={`form-control ${
                                approvalFormValidation.reasonForOOP
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled={profile.ProfileStatus > 2}
                              defaultValue={
                                profile.ApprovalFormData?.reasonForOOP || null
                              }
                              onChange={(e) => {
                                approvalFormOnChange(e);
                              }}
                            ></textarea>
                            {approvalFormValidation.reasonForOOP ? (
                              <p className="error small d-block">
                                This is a required field.
                              </p>
                            ) : null}
                          </Col>
                          <Col xs="12" sm="8" md="6">
                            <Label>
                              Justify why this CI project is important /
                              strategic intent *
                            </Label>
                            <textarea
                              id="justifyIntent"
                              className={`form-control ${
                                approvalFormValidation.justifyIntent
                                  ? "is-invalid"
                                  : ""
                              }`}
                              defaultValue={
                                profile.ApprovalFormData?.justifyIntent || null
                              }
                              onChange={(e) => {
                                approvalFormOnChange(e);
                              }}
                              disabled={profile.ProfileStatus > 2}
                            ></textarea>
                            {approvalFormValidation.justifyIntent ? (
                              <p className="error small d-block">
                                This is a required field.
                              </p>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs="12" sm="8" md="6">
                            <Label>Project start date</Label>
                            <Input
                              type="Date"
                              id="projectStartDate"
                              value={
                                profile.ApprovalFormData?.projectStartDate ||
                                null
                              }
                              onChange={(e) => {
                                approvalFormOnChange(e);
                              }}
                              disabled={profile.ProfileStatus > 2}
                            ></Input>
                          </Col>
                          <Col xs="12" sm="8" md="6">
                            <Label>Project end date</Label>
                            <Input
                              type="Date"
                              id="projectEndDate"
                              value={
                                profile.ApprovalFormData?.projectEndDate || null
                              }
                              onChange={(e) => {
                                approvalFormOnChange(e);
                              }}
                              disabled={profile.ProfileStatus > 2}
                            ></Input>
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs="12" sm="6" md="6">
                            <Label>
                              Will contract have a clause allowing NLSN to
                              terminate project for convenience & still oblige
                              client to pay us for work covered?
                            </Label>
                            <SelectWrapper
                              type="select"
                              id="contractAllowTermination"
                              value={
                                profile.ApprovalFormData
                                  ?.contractAllowTermination || null
                              }
                              onChange={(e) => {
                                approvalFormOnChange(e);
                              }}
                              disabled={profile.ProfileStatus > 2}
                            >
                              <option></option>
                              <option>Yes</option>
                              <option>No</option>
                              <option>Maybe</option>
                            </SelectWrapper>
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs="12" sm="8" md="6">
                            <Label>Execution Risk *</Label>
                            <SelectWrapper
                              type="select"
                              id="executionRisk"
                              invalid={approvalFormValidation.executionRisk}
                              value={
                                profile.ApprovalFormData?.executionRisk || null
                              }
                              onChange={(e) => {
                                approvalFormOnChange(e);
                              }}
                              disabled={profile.ProfileStatus > 2}
                            >
                              <option></option>
                              <option>Low</option>
                              <option>Medium</option>
                              <option>High</option>
                            </SelectWrapper>
                            {approvalFormValidation.executionRisk ? (
                              <p className="error small d-block">
                                This is a required field.
                              </p>
                            ) : null}
                          </Col>
                          <Col xs="12" sm="8" md="6">
                            <Label>
                              Are Local Commercial & Operations' teams aligned?
                              *
                            </Label>
                            <SelectWrapper
                              type="select"
                              id="localTeamsAligned"
                              invalid={approvalFormValidation.localTeamsAligned}
                              value={
                                profile.ApprovalFormData?.localTeamsAligned ||
                                null
                              }
                              onChange={(e) => {
                                approvalFormOnChange(e);
                              }}
                              disabled={profile.ProfileStatus > 2}
                            >
                              <option></option>
                              <option>Yes</option>
                              <option>No</option>
                            </SelectWrapper>
                            {approvalFormValidation.localTeamsAligned ? (
                              <p className="error small d-block">
                                This is a required field.
                              </p>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs="12" sm="8" md="6">
                            <Label>
                              Billing: % project to bill at contract signature:
                              *
                            </Label>
                            <Input
                              type="text"
                              id="billingContractSignature"
                              className={
                                approvalFormValidation.billingContractSignature
                                  ? "is-invalid"
                                  : ""
                              }
                              value={
                                profile.ApprovalFormData
                                  ?.billingContractSignature || null
                              }
                              onChange={(e) => {
                                approvalFormOnChange(e);
                              }}
                              disabled={profile.ProfileStatus > 2}
                            ></Input>
                            {approvalFormValidation.billingContractSignature ? (
                              <p className="error small d-block">
                                This is a required field.
                              </p>
                            ) : null}
                          </Col>
                          <Col xs="12" sm="8" md="6">
                            <Label>
                              Billing: % project to bill at field start date:
                            </Label>
                            <Input
                              type="text"
                              id="billingFieldStart"
                              value={
                                profile.ApprovalFormData?.billingFieldStart ||
                                null
                              }
                              onChange={(e) => {
                                approvalFormOnChange(e);
                              }}
                              disabled={profile.ProfileStatus > 2}
                            ></Input>
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs="12" sm="8" md="6">
                            <Label>
                              Billing: % project to bill at end of project:
                            </Label>
                            <Input
                              type="text"
                              id="billingProjectEnd"
                              value={
                                profile.ApprovalFormData?.billingProjectEnd ||
                                null
                              }
                              onChange={(e) => {
                                approvalFormOnChange(e);
                              }}
                              disabled={profile.ProfileStatus > 2}
                            ></Input>
                          </Col>
                          <Col xs="12" sm="8" md="6">
                            <Label>
                              Billing: in case some portion is not billed at
                              above listed dates, explain when what portion gets
                              billed so total % billed = 100%
                            </Label>
                            <Input
                              type="text"
                              id="billingExplanation"
                              value={
                                profile.ApprovalFormData?.billingExplanation ||
                                null
                              }
                              onChange={(e) => {
                                approvalFormOnChange(e);
                              }}
                              disabled={profile.ProfileStatus > 2}
                            ></Input>
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs="12" sm="6" md="6">
                            <Label>Client payment terms? *</Label>
                            <SelectWrapper
                              type="select"
                              id="clientPaymentTerms"
                              invalid={
                                approvalFormValidation.clientPaymentTerms
                              }
                              value={
                                profile.ApprovalFormData?.clientPaymentTerms ||
                                null
                              }
                              onChange={(e) => {
                                approvalFormOnChange(e);
                              }}
                              disabled={profile.ProfileStatus > 2}
                            >
                              <option></option>
                              <option>{"< 30 days"}</option>
                              <option>30 days</option>
                              <option>45 days</option>
                              <option>60 days</option>
                              <option>90 days</option>
                              <option>{"> 90 days"}</option>
                            </SelectWrapper>
                            {approvalFormValidation.clientPaymentTerms ? (
                              <p className="error small d-block">
                                This is a required field.
                              </p>
                            ) : null}
                          </Col>
                        </Row>
                        {Object.keys(approvalFormValidation).length ? (
                          <Row className="m-0">
                            <p className="error">
                              Please fill all the required fields to proceed
                            </p>
                          </Row>
                        ) : null}
                      </>
                    ) : (
                      <Row className="mb-2">
                        <Col>
                          <Input
                            id="approvalJustification"
                            type="textarea"
                            className="mb-2"
                            value={profile.ApprovalJustification}
                            onChange={(e) => {
                              dispatch(
                                currentCostingActions.updateProfile({
                                  ApprovalJustification: e.target.value,
                                })
                              );
                            }}
                            disabled={profile.ProfileStatus > 2}
                            placeholder="Please provide a brief explanation for the approvers..."
                          />
                          {invalid.ApprovalJustification ? (
                            <p className="error small d-block">
                              Please enter a justification
                            </p>
                          ) : null}
                        </Col>
                      </Row>
                    )}
                    {((profile?.ProfileStatus !== "6" &&
                      profile?.Project?.ProposalOwnerEmail?.value.toLowerCase() ===
                        userRecord.Email.toLowerCase()) ||
                      project.OtherProjectTeamContacts.map(
                        (otc) => otc.value
                      ).includes(userRecord.Email.toLowerCase())) && (
                      <Row className="justify-content-end m-0">
                        <Button
                          className="m-2"
                          disabled={
                            project.PriceChange > 2
                              ? true
                              : profile.ProfileStatus == "6"
                          }
                          // onClick={(e) => {

                          //   setResetModal(true)
                          //   setIsBtnDisabled(false)

                          // }}
                          onClick={(e) => {
                            checkForReset();
                            setStatusCheckApproval(false);
                            setStatusCheckProfit(false);
                          }}
                        >
                          Reset Approvals
                        </Button>
                        <Button
                          className="m-2"
                          disabled={
                            project.PriceChange >= 2
                              ? true
                              : isBtnDisabled ||
                                profile.ProfileStatus == "3" ||
                                profile.ProfileStatus == "5" ||
                                profile.ProfileStatus == "6"
                          }
                          onClick={(e) => {
                            if (validateApprovalForm()) {
                              setInvalid({
                                ...invalid,
                                ApprovalJustification: false,
                              });
                              dispatch(currentCostingActions.sendForApproval());
                              setIsBtnDisabled(true);
                            } else {
                              setInvalid({
                                ...invalid,
                                ApprovalJustification: true,
                              });
                            }
                          }}
                        >
                          Send For Approval
                        </Button>
                      </Row>
                    )}
                    <>
                      <>
                        <Row className="mb-2">
                          <Col>Approval Communication Board</Col>
                        </Row>
                        {profileCommunicationMessages.length > 0 && (
                          <Table
                            responsive
                            hover
                            striped
                            bordered={true}
                            size="sm"
                          >
                            <thead>
                              <th style={{ width: "25%" }}>Posted By</th>
                              <th style={{ width: "50%" }}>Message</th>
                              <th style={{ width: "25%" }}>Posted On</th>
                            </thead>
                            <tbody>
                              {profileCommunicationMessages
                                .sort((a, b) => b.id - a.id)
                                .map((m) => {
                                  return (
                                    <>
                                      <tr key={m.id}>
                                        <td>
                                          {m.owner
                                            ? `${m.owner.FirstName} ${m.owner.LastName}`
                                            : ""}
                                        </td>
                                        <td>{m.Comment}</td>
                                        <td>
                                          {moment(m.createdAt).format("l LT")}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          </Table>
                        )}
                        <ResetApprovalModal
                          resetModal={resetModal}
                          setResetModal={setResetModal}
                          reasonForApproval={reasonForApproval}
                          setreasonForApproval={setreasonForApproval}
                          setHistoryData={setHistoryData}
                          historyData={historyData}
                          getHistoryForApproval={getHistoryForApproval}
                        />

                        {profile.ApprovalLevelNeeded !=
                          profile.ApprovalLevelReached && (
                          <>
                            <Row className="mb-2">
                              <Col>
                                <Input
                                  disabled={getApprovalCommentsBoxStatus()}
                                  id="approverCommunicationMessage"
                                  type="textarea"
                                  className="mb-2"
                                  value={profileCommunicationMessage}
                                  onChange={(e) => {
                                    setProfileCommunicationMessage(
                                      e.target.value
                                    );
                                  }}
                                  placeholder="Please provide your comments if any..."
                                />
                              </Col>
                            </Row>
                            <Row className="justify-content-end m-0">
                              <Button
                                className="m-2"
                                disabled={
                                  project.PriceChange >= 2
                                    ? true
                                    : profileCommunicationMessage.trim() === ""
                                }
                                onClick={(e) =>
                                  SaveAndSendProfileCommunication()
                                }
                              >
                                Send
                              </Button>
                            </Row>
                          </>
                        )}

                        {/* {historyData.length && <ReasonForApproval historyApprovalPopup={historyApprovalPopup} sethistoryApprovalPopup={sethistoryApprovalPopup} reasonForApproval={reasonForApproval} historyData={historyData} />} */}
                        {/* {historyApprovalPopup && <ReasonForApproval historyApprovalPopup={historyApprovalPopup} sethistoryApprovalPopup={sethistoryApprovalPopup} reasonForApproval={reasonForApproval} historyData={historyData} />} */}
                      </>
                      {userRecord.IsCostingApprover &&
                      !(
                        profile.ProfileStatus === "6" ||
                        profile.ProfileStatus === "99"
                      ) ? (
                        <Row className="justify-content-end">
                          {/* {getOopDashboardStatus() ? (
                            <a
                              className="select-link mr-2 mt-2"
                              onClick={() =>
                                window.open(
                                  process.env.REACT_APP_OOP_DASHBOARD,
                                  "_blank"
                                )
                              }
                            >
                              OOP Dashboard
                            </a>
                          ) : null} */}
                          {userRecord.CanBypassApprovals &&
                          profile.ProfileStatus == "3" ? (
                            <Button
                              className="mt-2"
                              onClick={() => {
                                setIsOpen({
                                  ...isOpen,
                                  ModalBypass: !isOpen.ModalBypass,
                                });
                              }}
                            >
                              Bypass Approval
                            </Button>
                          ) : null}
                          {profile.ApprovalDetails?.map((eo) =>
                            profile.ApprovalLevelAwaiting === eo.Order
                              ? profile.ApprovalLevelNeeded !=
                                  profile.ApprovalLevelReached &&
                                profile.ApprovalDetails[
                                  profile.ApprovalLevelAwaiting
                                ]?.ApproverContacts?.map((ue) => {
                                  if (
                                    isApprover &&
                                    (ue.EmailAddress?.toLowerCase() ===
                                      userRecord.Email?.toLowerCase() ||
                                      ue?.delegateUsers?.includes(
                                        userRecord.Email?.toLowerCase()
                                      ))
                                  ) {
                                    isApprover = false;
                                    let approverResult =
                                      ue.EmailAddress?.toLowerCase() ===
                                        userRecord.Email?.toLowerCase() ||
                                      ue?.delegateUsers?.includes(
                                        userRecord.Email?.toLowerCase()
                                      ) ? (
                                        <Row className="justify-content-end mr-0 mt-2 ml-2">
                                          <Button
                                            className="ml-0"
                                            disabled={
                                              profile.ProfileStatus == "4"
                                            }
                                            onClick={(e) =>
                                              dispatch(
                                                currentCostingActions.deny()
                                              )
                                            }
                                          >
                                            Deny
                                          </Button>

                                          <Button
                                            className="ml-2"
                                            disabled={
                                              profile.ProfileStatus == "4"
                                            }
                                            onClick={(e) =>
                                              dispatch(
                                                currentCostingActions.approve()
                                              )
                                            }
                                          >
                                            Approve
                                          </Button>
                                        </Row>
                                      ) : null;
                                    return approverResult;
                                  }
                                  return null;
                                })
                              : null
                          )}
                        </Row>
                      ) : null}
                    </>
                    {/* ) : null} */}
                  </CardBody>
                </Collapse>
              </Card>
            ) : null}
            <ModalCommissioning
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              toggle={() => {
                setIsOpen({
                  ...isOpen,
                  ModalCommissioning: !isOpen.ModalCommissioning,
                });
              }}
            />
            <ModalPostCommission
              isOpen={isOpen}
              toggle={() => {
                setIsOpen({
                  ...isOpen,
                  ModalPostCommission: !isOpen.ModalPostCommission,
                });
              }}
            />
            <ModalBypass
              isOpen={isOpen}
              toggle={() => {
                setIsOpen({
                  ...isOpen,
                  ModalBypass: !isOpen.ModalBypass,
                });
              }}
            />
            <Modal
              isOpen={isModalOpen}
              toggle={() => handleOpen()}
              size="lg"
              centerd={true}
            >
              <ModalHeader toggle={() => handleOpen(!isModalOpen)}>
                {/* <img src={logo} className="modallogo" /> */}
              </ModalHeader>
              <div>
              {isCommission ?<span className="modaltitle modaltitleDisplay text-danger">
                  Cannot Proceed Commission
                </span> :
                 <span className="modaltitle modaltitleDisplay text-danger">
                  Cannot Proceed Approval
                </span>}
              </div>
              <ModalBody id="scrollModalBody">
                <p>
                  <b>
                    Key Parameters information is missing at cost option level
                    for following Country and Methodologies
                  </b>
                </p>
                {Object.keys(errorObj)?.length ? (
                  <>
                    {Object.keys(errorObj).map(function (key) {
                      return (
                        <>
                          <div className="displayCountry col-md-12">
                            <h4>
                              <b>{key} :</b>
                            </h4>
                          </div>
                          <div class="col-md-12">
                            {errorObj[key]?.RFQ &&
                            Object.keys(errorObj[key].RFQ).length ? (
                              <>
                                <p>
                                  <b>RFQ Form Section:</b>
                                </p>
                                <Table id="errorTable">
                                  <thead>
                                    <tr>
                                      <th>Methodologies</th>
                                      <th>Fields</th>
                                    </tr>
                                  </thead>
                                  {
                                    <tbody>
                                      {Object.keys(errorObj[key].RFQ).map(
                                        function (key1) {
                                          return (
                                            <tr>
                                              <td>{key1} </td>
                                              <td>
                                                {errorObj[key].RFQ[key1].join(
                                                  ", "
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  }
                                </Table>
                              </>
                            ) : null}
                          </div>
                          <div class="col-md-12">
                            {errorObj[key]?.SD &&
                            Object.keys(errorObj[key].SD).length ? (
                              <>
                                <p>
                                  <b>Setup and Delivery Section:</b>
                                </p>
                                <Table id="errorTable">
                                  <thead>
                                    <tr>
                                      <th>Methodologies</th>
                                      <th>Fields</th>
                                    </tr>
                                  </thead>
                                  {
                                    <tbody>
                                      {Object.keys(errorObj[key].SD).map(
                                        function (key1) {
                                          return (
                                            <tr>
                                              <td>{key1} </td>
                                              <td>
                                                {errorObj[key].SD[key1].join(
                                                  ", "
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  }
                                </Table>
                              </>
                            ) : null}
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : null}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={() => handleOpen(!isModalOpen)}
                >
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={oopMultiplierModal}
              size="md"
              toggle={() => setOOPMutliplierModal(!oopMultiplierModal)}
              centerd={true}
            >
              <ModalHeader
                toggle={() => setOOPMutliplierModal(!oopMultiplierModal)}
              >
                {/* <h4>
                  Time Cost Multiplier for {profile.ProfileName}-{" "}
                  {profile.Project?.ProjectName}- ({profile.Project?.ProjectId})
                </h4> */}
                {/* <img src={logo} className="modallogo" /> */}
              </ModalHeader>
              <div>
                <span className="modaltitle modaltitleDisplay">
                  Time Cost Multiplier for {profile.ProfileName}-{" "}
                  {profile.Project?.ProjectName}- ({profile.Project?.ProjectId})
                </span>
              </div>
              <ModalBody>
                {/* make it dynamic */}
                {profile.ProfileSetting ? (
                  <div>
                    <Row className="m-0 mb-2">
                      <Col>
                        <label>Internal Operations %</label>
                      </Col>
                      <Col>
                        <input
                          type="number"
                          step="any"
                          className="form-control"
                          onChange={updateProfileSetting}
                          id="CostIntOpsMultiplier"
                          defaultValue={
                            profile.ProfileSetting.CostIntOpsMultiplier
                              ? profile.ProfileSetting.CostIntOpsMultiplier
                              : null
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col>
                        <label>Internal Commerical %</label>
                      </Col>
                      <Col>
                        <input
                          type="number"
                          step="any"
                          onChange={updateProfileSetting}
                          className="form-control"
                          id="CostIntCommMultiplier"
                          defaultValue={
                            profile.ProfileSetting.CostIntCommMultiplier
                              ? profile.ProfileSetting.CostIntCommMultiplier
                              : null
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div>
                    Sorry, required Profile Settings are not available, contact
                    admin
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Row className="justify-content-end">
                  <Button
                    size="sm"
                    color="primary"
                    onClick={ApplyOOPMultiplierChanges}
                  >
                    Apply
                    {applyingMultiplier ? (
                      <Spinner size="small" color="#495057" />
                    ) : null}
                  </Button>
                  <Button
                    size="sm"
                    className="ml-2"
                    color="secondary"
                    onClick={() => {
                      setTempProfileSetting({
                        CostIntCommMultiplier:
                          profile.ProfileSetting?.CostIntCommMultiplier,
                        CostIntOpsMultiplier:
                          profile.ProfileSetting?.CostIntOpsMultiplier,
                      });
                      setOOPMutliplierModal(false);
                    }}
                  >
                    Close
                  </Button>
                </Row>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={isActivityBDOpen}
              size="xl"
              toggle={() => openActivityBreakdown(!isActivityBDOpen)}
              centered={true}
            >
              <ModalHeader
                toggle={() => openActivityBreakdown(!isActivityBDOpen)}
              >
                {/* <img src={logo} className="modallogo" /> */}
              </ModalHeader>
              <div>
                <span className="modaltitle modaltitleDisplay">
                  Activity Breakdown
                </span>
              </div>

              <ModalBody>
                {/* <ActivityBreakdown /> */}
                {_.includes(pacificMarkets, project.CommissioningCountry) &&
                project.CostingTemplateVersion != "v2.0" ? (
                  <CommercialHours_internal
                    profile={profile}
                    indxFC={0}
                    indxSM={0}
                    editable={true}
                  />
                ) : (
                  //<CommercialHours_internal />
                  <CommercialHours editDisabled={true} />
                )}
                {/* <CommercialHours editDisabled={true} /> */}
              </ModalBody>
              <ModalFooter>
                <Row className="justify-content-end">
                  <Button
                    size="sm"
                    color="secondary"
                    onClick={() => openActivityBreakdown(!isActivityBDOpen)}
                  >
                    Close
                  </Button>
                </Row>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={isCostBDOpen}
              size="xl"
              toggle={() => openCostBreakdown(!isCostBDOpen)}
              centered={true}
            >
              <ModalHeader toggle={() => openCostBreakdown(!isCostBDOpen)}>
                {/* <img src={logo} className="modallogo" /> */}
              </ModalHeader>

              <div>
                <span className="modaltitle">Cost Breakdown</span>
              </div>
              <ModalBody>
                {(_.includes(pacificMarkets, project.CommissioningCountry) &&
                  project.CostingTemplateVersion != "v2.0") ||
                project.CostingTemplateVersion != "v2.0" ? (
                  <CostBreakDown />
                ) : (
                  <BreakDownView />
                )}
              </ModalBody>
              <ModalFooter>
                <Row className="justify-content-end">
                  <Button
                    color="secondary"
                    onClick={() => openCostBreakdown(!isCostBDOpen)}
                  >
                    Close
                  </Button>
                </Row>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={isHistoryLogOpen}
              backdrop="static"
              size="xl"
              toggle={() => openHistoryLog(!isHistoryLogOpen)}
            >
              <ModalHeader
                toggle={() => openHistoryLog(!isHistoryLogOpen)}
                centered={true}
              >
                {/* <h4>Log of Past OOP Adjustments done</h4>
                <h7>
                  Note: Cost Displayed here is in {profile.CostInputCurrency}
                </h7> */}
                {/* <img src={logo} className="modallogo" /> */}
              </ModalHeader>
              <div>
                <span className="modaltitle modaltitleDisplay">
                  Log of Past Cost Adjustments done
                </span>
                <div>
                  <h7>
                    Note: Cost Displayed here is in {profile.CostInputCurrency}
                  </h7>
                </div>
              </div>
              <ModalBody>
                <OopAdjustmentLog
                  HistoryLogData={HistoryLogData}
                ></OopAdjustmentLog>
              </ModalBody>
              <ModalFooter>
                <Row className="justify-content-end">
                  <Button
                    size="sm"
                    color="secondary"
                    onClick={() => openHistoryLog(!isHistoryLogOpen)}
                  >
                    Close
                  </Button>
                </Row>
              </ModalFooter>
            </Modal>
          </Container>
          <Row className="m-0 mr-2 justify-content-end">
            {userCanExceptionCommision&&<Button
                color="primary"
                disabled={false }
                className="mr-2 mt-3"
                onClick={(e) => {
                  setExceptionModal(!exceptionModal)
                }}
              >
                Exception Commission
              </Button>}
            <Button
              color="primary"
              disabled={
                project.PriceChange > 2 ||
                (project.PriceChange == 2 &&
                  (project.ProjectStatus == "3" ||
                    project.ProjectStatus == "9"))
                  ? true
                  : project.IsDeleted == 1 && project.ProjectStatus == "8"
                  ? true
                  : profile.Project?.ProjectStatus === "7" ||
                    app.recordloading ||
                    profile.IsImportedProfile
              }
              className="mr-2 mt-3"
              // onClick={submitCostingProfile}
              onClick={(e) => {
                if (!profile.IsImportedProfile)
                  dispatch(
                    currentCostingActions.saveCostingProfile({
                      ...profile,
                      ProfileSetting: {
                        ...profile.ProfileSetting,
                        CurrenciesData: currencies,
                      },
                    })
                  );
                else e.preventDefault();
              }}
            >
              Save
              {app.recordloading ? (
                <Spinner size="small" className="mr-2" color="#fff" />
              ) : null}
            </Button>

            {(!_.includes(pacificMarkets, project.CommissioningCountry) ||
              (_.includes(pacificMarkets, project.CommissioningCountry) &&
                project.CostingTemplateVersion == "v2.0") ||
              (project.ParentProjectId && !project.IsCopy)) && (
              <Button
                color="secondary"
                className="mr-2 mt-3"
                onClick={() => {
                  axios
                    .put(`/projects/${project.ProjectId}/updateStatus`, {
                      checkProfitValue: true,
                    })
                    .then((res) => {
                      axios
                        .get(`/projects/${project.ProjectId}`)
                        .then((result) => {
                          dispatch({
                            type: currentProjectActions.UPDATE_NEW_PROJECT,
                            newProject: result.data.project,
                          });
                        });
                    });
                  setStatusCheckProfit(false);
                  setIsZeroCost(false);
                  if (project.IsUploaded) {
                    setUploadProject(true);
                  } else {
                    refreshCostingProfile(profile.id);
                  }
                }}
                disabled={
                  project.PriceChange > 2
                    ? true
                    : project.PriceChange == 2
                    ? parseInt(profile?.ProfileStatus) == 1 ||
                      parseInt(profile?.ProfileStatus) == 2 ||
                      parseInt(profile?.ProfileStatus) == 5
                      ? false
                      : true
                    : profile.Project?.ProjectStatus === "7" ||
                      (profile.Project?.ProjectStatus === "8" &&
                        profile.IsDeleted == true) ||
                      parseInt(profile?.ProfileStatus) > 1
                }
              >
                Refresh Costs
              </Button>
            )}

            <Button
              disabled={
                project.PriceChange >= 2
                  ? true
                  : parseInt(profile.ProfileStatus) > 1 ||
                    (profile.Project?.ProjectStatus === "8" &&
                      profile.IsDeleted == true)
              }
              color="secondary"
              className="mt-3"
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          </Row>
          <ExceptionModal isOpen={exceptionModal} isClose={setExceptionModal}/>
          <Modal
            isOpen={detailedRFQ}
            toggle={() => setDetailedRFQ(false)}
            size="sm"
            centered={true}
          >
            <ModalHeader toggle={() => setDetailedRFQ(false)}>
              {/* <h3>
                <p>Cannot navigate to RFQ Details</p>
              </h3> */}
              {/* <img src={logo} className="modallogo" /> */}
            </ModalHeader>
            <div>
              <span className="modaltitle modaltitleDisplay">
                Cannot navigate to RFQ Details
              </span>
            </div>
            <ModalBody>
              <p>
                <h5>
                  Cannot navigate to Detailed RFQ, Since none of the Costing
                  Options are selected in Costing sheet.
                </h5>
              </p>
              <br />
              <p>
                <h5>
                  Please Select the required Costing Options in the Costing
                  Sheet and Sync to the App.
                </h5>
              </p>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex justify-content-between">
                <Button
                  className="form-control"
                  color="secondary"
                  onClick={() => setDetailedRFQ(false)}
                >
                  Cancel
                </Button>
              </div>
            </ModalFooter>
          </Modal>

          <Container className="d-flex justify-content-center">
            <RecordsBadge />
          </Container>
        </>
      }
    </Layout>
  ) : (
    <></>
  );
};

export default Summary;
