export const API_CHANGE_HEADER = 'API_CHANGE_HEADER';
export const API_RESET_HEADER = 'API_RESET_HEADER';

export function setNewHeader(headerInfo) {
    return {
        type: API_CHANGE_HEADER,
        payload: headerInfo
    }
}


export function resetHeader()
{
    return {
        type: API_RESET_HEADER        
    }
}