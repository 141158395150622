import React, { useState, useEffect } from 'react'
import {
    Col, Row, Nav, NavItem, NavLink, TabContent, TabPane,
    Table,
} from "reactstrap";
import Select from "react-select";

import classnames from "classnames";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import * as countryActions from "../../redux/actions/countrySpecsActions";
import { getLabel } from "../../utils/codeLabels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faPaste, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";


const CostingDataView = ({ isEnabled }) => {
    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
      );
    const currentCostingProfile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
    );
    const [profile] = useState(currentCostingProfile);
    const [wavesData, setWavesData] = useState(currentCostingProfile.WaveSpecs)
    const [activeTabFC, setActiveTabFC] = useState();
    const [activeTabSM, setActiveTabSM] = useState();
    const [waveBreakDown, setWaveBreakDown] = useState({})
    const [currentWaveSpec, setCurrentWaveSpec] = useState({});
    const [showManualCostEntry, setShowManualCostEntry] = useState(false);
    const [showSheetsCosts, setShowSheetsCosts] = useState(false);
    const [showCostMethod, setShowCostMethod] = useState(false);
    const dispatch = useDispatch();
    const [modifiedMeths, setModifiedMeths] = useState([])
    const [activeWave, setActiveWave] = useState("1");
    const [toggle, setToggle] = useState({
        costingOption: false
    });
    const [shopperMethodologies] = useState(['SM000024', 'SM000026', 'SM000029'])
    const countrySpecs = useSelector(({ countrySpecs }) => countrySpecs);
    const [costEntryDetails, setCostEntryDetails] = useState({});
    useEffect(() => {
        if (countrySpecs && countrySpecs.length && _.head(countrySpecs).MethodologySpecs?.length && !Object.keys(costEntryDetails).length) {
            let costEntryDetails = {};
            countrySpecs.map((cs) => {
                costEntryDetails[cs.CountryCode] = {};
                cs.MethodologySpecs.map((ms) => {
                    costEntryDetails[cs.CountryCode][ms.Code] = {};
                    ms.CostingOptions.map((co => {
                        if (ms.CostingType == 3) {
                            costEntryDetails[cs.CountryCode][ms.Code][co.id] = co.SelectedVendorCost ? co.SelectedVendorCost : {};
                        }
                        else {
                            costEntryDetails[cs.CountryCode][ms.Code][co.id] = co.CostsData ? co.CostsData : {};
                        }
                    }))
                });
            });
            setCostEntryDetails(costEntryDetails);
        }
    }, [countrySpecs]);

    const selectStyles = {
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: "9999 !important" })
    };

    const getDefaultCurrValue = (value, country) => {
        if (currencies) {
            let currentCurrencyConversion = !country.CostInputCurrency || country.CostInputCurrency == "US-USD" ? 1 : _.head(currencies.filter((c) => c.CurrencyUnit == _.last(country.CostInputCurrency.split("-"))))?.ConversionRateToLocal;
            return _.round(value * currentCurrencyConversion, 2);
        } else {
            return null;
        }
    };
    const updateCostEntryForm = (eve, coId, methCode, country) => {
        let countryCode = country.CountryCode;
        let currentCurrencyConversion = !country.CostInputCurrency || country.CostInputCurrency == "US-USD" ? 1 : _.head(currencies.filter((c) => c.CurrencyUnit == _.last(country.CostInputCurrency.split("-")))).ConversionRateToLocal;
        let costEntryDet = { ...costEntryDetails };
        if (!costEntryDet[countryCode]) costEntryDet[countryCode] = {};
        else costEntryDet[countryCode] = { ...costEntryDet[countryCode] };

        if (!costEntryDet[countryCode][methCode]) costEntryDet[countryCode][methCode] = {};
        else
            costEntryDet[countryCode][methCode] = {
                ...costEntryDet[countryCode][methCode],
            };

        if (!costEntryDet[countryCode][methCode][coId]) costEntryDet[countryCode][methCode][coId] = {};
        else
            costEntryDet[countryCode][methCode][coId] = {
                ...costEntryDet[countryCode][methCode][coId],
            };

        costEntryDet[countryCode][methCode][coId][eve.target.id] = eve.target.value / currentCurrencyConversion;
        setCostEntryDetails({ ...costEntryDet });
        country.MethodologySpecs.map((mth) => {
            if (!_.includes(shopperMethodologies, mth.Code)) {
                if (costEntryDet[country.CountryCode] && costEntryDet[country.CountryCode][mth.Code]) {
                    mth.CostingOptions.map((co => {

                        if (!co.CostsData) co.CostsData = {};
                        if (!co.SelectedVendorCost) co.SelectedVendorCost = {};
                        if (mth.CostingType == 2 || mth.CostingType == 1) {
                            co.CostsData = {
                                ...co.CostsData,
                                ...costEntryDet[country.CountryCode][mth.Code][co.id],
                            };
                        }
                        if (mth.CostingType == 3) {
                            co.SelectedVendorCost = {
                                ...co.SelectedVendorCost,
                                ...costEntryDet[country.CountryCode][mth.Code][co.id],
                            };
                        }
                    }))
                }
            }
        });
        let foundIndex = countrySpecs.findIndex(x => x.id == country.id)
        countrySpecs[foundIndex] = country
        dispatch(countryActions.setCountrySpecs([...countrySpecs]));
    };
    const onCurrencyChange = (value, country) => {
        let countryspecs = [...countrySpecs];
        countryspecs.map((cs) => {
            if (cs.CountryCode == country.CountryCode) {
                let incomingCurrency = value;
                cs.MethodologySpecs.map((meth) => {
                    meth.CostingOptions.map((co => {
                        if (!co.CostsData) co.CostsData = {};
                        if (!co.SelectedVendorCost) co.SelectedVendorCost = {};
                        if (meth.CostingType == 2 || meth.CostingType == 1) {
                            if (costEntryDetails[cs.CountryCode] && costEntryDetails[cs.CountryCode][meth.Code][co.id]) {
                                Object.keys(costEntryDetails[cs.CountryCode][meth.Code][co.id]).map((mc) => {
                                    co.CostsData[mc] = costEntryDetails[cs.CountryCode][meth.Code][co.id][mc];
                                });
                            }
                            co.CostsData = { ...co.CostsData };
                            Object.keys(co.CostsData)?.map((cd) => {
                                if (costEntryDetails[cs.CountryCode] && costEntryDetails[cs.CountryCode][meth.Code] && costEntryDetails[cs.CountryCode][meth.Code][co.id]) {
                                    costEntryDetails[cs.CountryCode][meth.Code][co.id][cd] = co.CostsData[cd];
                                }
                            });
                        }
                        if (meth.CostingType == 3) {
                            if (costEntryDetails[cs.CountryCode] && costEntryDetails[cs.CountryCode][meth.Code][co.id]) {
                                Object.keys(costEntryDetails[cs.CountryCode][meth.Code][co.id]).map((mc) => {
                                    co.SelectedVendorCost[mc] = costEntryDetails[cs.CountryCode][meth.Code][co.id][mc];
                                });
                            }
                            co.SelectedVendorCost = { ...co.SelectedVendorCost };
                            Object.keys(co.CostsData)?.map((cd) => {
                                if (costEntryDetails[cs.CountryCode] && costEntryDetails[cs.CountryCode][meth.Code] && costEntryDetails[cs.CountryCode][meth.Code][co.id]) {
                                    costEntryDetails[cs.CountryCode][meth.Code][co.id][cd] = co.SelectedVendorCost[cd];
                                }
                            });
                        }

                    }))
                });

                if (incomingCurrency) cs.CostInputCurrency = incomingCurrency;
                else cs.CostInputCurrency = "US-USD";
                cs.MethodologySpecs = [...cs.MethodologySpecs];
                cs = { ...cs };

            }
        });

        dispatch(countryActions.setCountrySpecs([...countryspecs]));
    };
    const getDefaultCurrencySelection = (value) => {
        if (currencies) {
            if (!value) value = "US-USD";
            else if (value && value.value) value = value.value;
            let values = value.split("-");
            let currencyUnit = _.last(values);
            let countryCode = _.head(values);
            let finalValue = _.head(currencies.filter((cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit));
            if (finalValue)
                return {
                    value: `${finalValue.Code}-${finalValue.CurrencyUnit}`,
                    label: `${finalValue.Label} (1USD = ${finalValue.ConversionRateToLocal}${finalValue.CurrencyUnit})`,
                };
            else return "";
        } else return "";
    };
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);
    const app = useSelector(({ app }) => app);
    useEffect(() => {
        let countryData = _.head(profile.CountrySpecs);
        let mData = countryData.MethodologySpecs?.filter(ms => !ms.NotApplicable);
        if (_.head(mData).CostingType == 1) {
            isEnabled(true)
        } else {
            isEnabled(false)
        }
        let methodologyID = _.head(mData).id
        setActiveTabFC(countryData.id);
        setActiveTabSM(methodologyID);
        // onMethodologyChange(methodologyID);
    }, [currentCostingProfile])

    const getFinalProperties = (properties) => {
        let finalProperties = [];
        //todo: please apply this when SC,MC changes come
        // if (currentCostingProfile.IsMultiCountry) {
        //     Object.keys(properties).map((prop) => {
        //         if (properties[prop].isMultiCountry == true || properties[prop].isMultiCountry == undefined) {
        //             finalProperties.push(prop);
        //         }
        //     });
        // } else {
        //     Object.keys(properties).map((prop) => {
        //         if (properties[prop].isMultiCountry == false || properties[prop].isMultiCountry == undefined) {
        //             finalProperties.push(prop);
        //         }
        //     });
        // }

        Object.keys(properties).map((prop) => {
            // if (properties[prop].isMultiCountry == false || properties[prop].isMultiCountry == undefined) {
            finalProperties.push(prop);
            // }
        });
        return finalProperties;
    };
    const [costingOptionCostSet, setCostingOptionCostSet] = useState({})
    useEffect(() => {
        let _costingOptionCostSet = {}
        if (!Object.keys(costingOptionCostSet).length) {
            currentCostingProfile.CountrySpecs.map(cs => {
                cs.MethodologySpecs.filter(ms => !ms.NotApplicable).map(ms => {
                    if (!_costingOptionCostSet[ms.Code]) {
                        _costingOptionCostSet[ms.Code] = {}
                        ms.CostsSchema.map(cssch => {
                            Object.keys(cssch.properties).map(_prop => {
                                _costingOptionCostSet[ms.Code][_prop] = cssch.properties[_prop]
                            })
                        })
                    }
                })
            })
        }
        setCostingOptionCostSet(_costingOptionCostSet)
    }, [currentCostingProfile]);
    const currencies = useSelector(({ currentCosting }) =>
        currentCosting.currentCostingProfile.ProfileSetting &&
            currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
            ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
            : []
    );
    const [currentCurrency, setCurrentCurrency] = useState({});
    useEffect(() => {
        if (profile && profile.CostInputCurrency) {
            let values = profile.CostInputCurrency.split("-");
            let currencyUnit = _.last(values);
            let countryCode = _.head(values);
            if (currencies) {
                setCurrentCurrency(
                    _.head(
                        currencies.filter(
                            (cur) =>
                                cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
                        )
                    )
                );
            }
        }
    }, [profile, currencies]);

    const toggleFC = (tab) => {
        if (activeTabFC !== tab) {
            setActiveTabFC(tab);
            let cData = profile.CountrySpecs.filter(x => x.id === tab);
            let methData = _.head(cData);
            let mData = methData.MethodologySpecs?.filter(ms => !ms.NotApplicable   && ms.CostingOptions.some(s => s.CoSelectedForSummary));
            let methodologyID = _.head(mData).id
            if (_.head(mData).CostingType == 1) {
                isEnabled(true)
            } else {
                isEnabled(false)
            }
            setActiveTabSM(methodologyID)
            // onMethodologyChange(methodologyID)
        }
    };

    const toggleSM = (tab) => {
        if (activeTabSM !== tab) {
            setActiveTabSM(tab);
            // onMethodologyChange(tab)
        }
    };


    const onMethodologyChange = (methID) => {
        let _waveBreakdownMain = wavesData?.filter(x => x.MethodologySpecId === methID)
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
        setWaveBreakDown(_waveBreakdown);
        setModifiedMeths((_waveBreakdownMain))
        let data = _.head(wavesData.filter(cws => cws.MethodologySpecId == methID))
        setCurrentWaveSpec(data);
    }
    return (
        <Row>

            <Col md={12} className={'countryTabs tab-vertical'}>

                <div className={"tab mt-5"}>
                    <Nav tabs>
                        {profile.CountrySpecs &&
                            profile.CountrySpecs.map((fc) => (
                                <NavItem key={fc.value}>
                                    <NavLink className={classnames({ active: activeTabFC === fc.id })}
                                        onClick={() => {
                                            toggleFC(fc.id);
                                        }}
                                    >
                                        <b>{fc.CountryName}</b>
                                    </NavLink>
                                </NavItem>
                            ))}
                    </Nav>
                    <TabContent activeTab={activeTabFC}>
                        {countrySpecs &&
                            countrySpecs.map((fc, indxFC) => (
                                activeTabFC === fc.id &&
                                <TabPane tabId={fc.id} key={fc.value}>
                                    <Row>
                                        <div className='col-md-8'>
                                            <h3 >Breakdown by Costing Option</h3>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="input-group mt-2 mb-2">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text text-sm">Currency</span>
                                                </div>
                                                <Select
                                                    className="react-select-container currency-entry custom-select-box"
                                                    classNamePrefix="react-select"
                                                    menuPortalTarget={document.body} menuPosition={'fixed'}
                                                    styles={selectStyles}
                                                    options={currencies?.map((c) => {
                                                        return {
                                                            value: `${c.Code}-${c.CurrencyUnit}`,
                                                            label: `${c.Label} (1USD = ${c.ConversionRateToLocal}${c.CurrencyUnit})`,
                                                        };
                                                    })}
                                                    value={getDefaultCurrencySelection(fc.CostInputCurrency)}
                                                    onChange={(select) => onCurrencyChange(select.value, fc)}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col md={12} className={"methodologyTabs"}>
                                            <Nav tabs>
                                                {fc.MethodologySpecs &&
                                                    fc.MethodologySpecs.filter(x => !x.NotApplicable && x.CostingOptions.some(s => s.CoSelectedForSummary)
                                                    ).map((sm, indxSM) =>
                                                    (
                                                        <NavItem key={sm.value}>
                                                            <NavLink className={classnames({ active: activeTabSM === sm.id })}
                                                                onClick={() => {
                                                                    toggleSM(sm.id);
                                                                }}
                                                            >
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                    <div>
                                                                        <b>{sm.Label}</b>
                                                                    </div>

                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                    ))}
                                            </Nav>

                                            <TabContent activeTab={activeTabSM} style={{ padding: "0.25" }}>
                                                <Row>
                                                    <Col md={12} className={"methodologyTabs"}>
                                                        <Table responsive hover striped bordered={true} size="sm">
                                                            <thead>
                                                                <tr>
                                                                    {fc.MethodologySpecs &&
                                                                        fc.MethodologySpecs.filter(x => x.id == activeTabSM).map((sm, indxSM) =>
                                                                        (
                                                                            <Table inline hover bordered={true}>
                                                                                <thead>
                                                                                    <th>Component</th>
                                                                                    {sm.CostingOptions.filter(selectedCO => selectedCO.CoSelectedForSummary).map((co) => (
                                                                                        <th>
                                                                                            {co.Name ? co.Name : co.value}
                                                                                        </th>
                                                                                    ))}
                                                                                </thead>
                                                                                <tbody>
                                                                                    {sm.CostsSchema?.map((costsch) => {
                                                                                        let finalProps = getFinalProperties(costsch.properties);
                                                                                       let condition = currentCostingProfile?.Project?.PriceChange == "1" ? finalProps.length && !(costsch.title == "FIELD TIME COSTS" || costsch.title =="SET-UP AND DELIVERY TIME COSTS" ) :
                                                                                       finalProps.length
                                                                                        return condition ? (
                                                                                            <>
                                                                                                <tr className="mt-4 h5">
                                                                                                    <td>
                                                                                                        <strong>{costsch.title}</strong>
                                                                                                    </td>
                                                                                                    {sm.CostingOptions.map((co) => (
                                                                                                        co.CostsData ? <td></td> : null
                                                                                                    ))}
                                                                                                </tr>
                                                                                                {finalProps.map((prop) => {
                                                                                                    return costsch.properties[prop].title ? (
                                                                                                        <>
                                                                                                            <tr>
                                                                                                                {/* <td>{costingOptionCostSet[sm.Code][prop]?costingOptionCostSet[sm.Code][prop].title:null}</td> */}
                                                                                                                <td>{costsch.properties[prop].title}</td>
                                                                                                                {sm.CostingOptions.filter(selectedCO => selectedCO.CoSelectedForSummary).map((co) => (
                                                                                                                    <td>
                                                                                                                        <div class="input-group">
                                                                                                                            <input
                                                                                                                                placeholder={sm.NotApplicable ? "Not Applicable" : null}
                                                                                                                                className="form-control"
                                                                                                                                type="number"
                                                                                                                                id={prop} key={prop + sm.Code}
                                                                                                                                value={
                                                                                                                                    costEntryDetails[fc.CountryCode] && costEntryDetails[fc.CountryCode][sm.Code] && costEntryDetails[fc.CountryCode][sm.Code][co.id]
                                                                                                                                        ? getDefaultCurrValue(costEntryDetails[fc.CountryCode][sm.Code][co.id][prop], fc)
                                                                                                                                        : null
                                                                                                                                }
                                                                                                                                onChange={(eve) => updateCostEntryForm(eve, co.id, sm.Code, fc)}
                                                                                                                                disabled={sm.NotApplicable ||
                                                                                                                                    sm.CostingType == "SHEETS" ||
                                                                                                                                    sm.CostingType == 2 || sm.CostingType == 3}
                                                                                                                                min={0}
                                                                                                                                step="any"
                                                                                                                            />{" "}
                                                                                                                            <div class="input-group-append">
                                                                                                                                <span class="input-group-text text-sm">{fc.CostInputCurrency ? _.last(fc.CostInputCurrency.split("-")) : "USD"}</span>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </td>))}
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    ) : null
                                                                                                })}
                                                                                            </>
                                                                                        ) : null;
                                                                                    })}
                                                                                </tbody>

                                                                            </Table>
                                                                        ))}
                                                                </tr>
                                                            </thead>

                                                        </Table>


                                                    </Col>
                                                </Row>
                                            </TabContent>
                                        </Col>

                                    </Row>
                                </TabPane>
                            ))}
                    </TabContent>
                </div>
            </Col>
        </Row >
    )
}

export default CostingDataView
