import axios from "../../axios-interceptor";
import * as appActions from "./appActions";
import { toastr } from "react-redux-toastr";
export const SET_COMMERCIAL_HOURS="SET_COMMERCIAL_HOURS"
export const SET_OPERATIONS_HOURS="SET_OPERATIONS_HOURS"
export const SET_COMMERCIAL_COST="SET_COMMERCIAL_COST"
export const SET_OPERATIONS_COST="SET_OPERATIONS_COST"


export const setCommercialHours=(payload)=>{
    return (dispatch)=>{
        dispatch({
            type:"SET_COMMERCIAL_HOURS",
            payload
        })
    }
}

export const setOperationsHours=(payload)=>{
    return (dispatch)=>{
        dispatch({
            type:"SET_OPERATIONS_HOURS",
            payload
        })
    }
}

export const setCommercialCost=(payload)=>{
    return (dispatch)=>{
        dispatch({
            type:"SET_COMMERCIAL_COST",
            payload
        })
    }
}

export const setOperationsCost=(payload)=>{
    return (dispatch)=>{
        dispatch({
            type:"SET_OPERATIONS_COST",
            payload
        })
    }
}

export const saveHourspayload=(payload,profileID)=>{
    return async(dispatch)=>{
        dispatch(appActions.pageLoadStart());
        axios.put(`/timecost/${profileID}`, payload,{
            headers: { "auth-token": localStorage.getItem("auth-token") },
        })
        .then((res) => {
            toastr.success("Success", res.data.message);
            dispatch(appActions.pageLoadEnd());
        })
        .catch((err) => {
            toastr.error("Save Failed", err.data.error);
            dispatch(appActions.pageLoadEnd());
        });
    }
}

export const saveTotalCost = (payload, profileId) => {
    return async(dispatch)=>{
        dispatch(appActions.pageLoadStart());
        axios
        .put(`/costingoptions/${profileId}`, payload, {
            headers: { "auth-token": localStorage.getItem("auth-token")},
        })
        .then((res) => {
            toastr.success("Successfully Saved");
            dispatch(appActions.pageLoadEnd());
        })
        .catch((err) => {
            toastr.error("Error while saving the data");
            dispatch(appActions.pageLoadEnd());
        });
    }    
}


export const saveTimecost=(hourspayload,costpayload,profileId)=>{
    return async(dispatch)=>{
        dispatch(appActions.pageLoadStart());
        axios
        .put(`/costingoptions/${profileId}`, costpayload, {
            headers: { "auth-token": localStorage.getItem("auth-token")},
        })
        .then((res) => {
            axios.put(`/timecost/${profileId}`, hourspayload,{
                headers: { "auth-token": localStorage.getItem("auth-token") },
            })
            .then((res) => {
                toastr.success("Successfully Saved");
                dispatch(appActions.pageLoadEnd());
            })
            .catch((err) => {
                toastr.error("Save Failed", err.data.error);
                dispatch(appActions.pageLoadEnd());
            });
        })
        .catch((err) => {
            toastr.error("Error while saving the data");
            dispatch(appActions.pageLoadEnd());
        });
    }
}