import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';
// import { Viewer } from '@react-pdf-viewer/core';
// import { Worker } from '@react-pdf-viewer/core';
import _ from "lodash";
import axios from '../../../axios-interceptor';
import { useHistory, Link } from "react-router-dom";
import Select from "react-select";
import InfiniteScroll from "@alexcambose/react-infinite-scroll";
import Loader from "../../../components/Loader";
import * as vendorActions from '../../../redux/actions/vendor/Actions';
import * as masterActions from '../../../redux/actions/masters/Actions';
import { toastr } from "react-redux-toastr";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import classnames from "classnames";
import { faDownload, faFile, faImage, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import * as appActions from "../../../redux/actions/appActions";
import {
    Button,
    Card,
    CardBody,
    Label,
    Col,
    Container,
    Row,
    UncontrolledTooltip,
    Input,
    FormGroup,
    Table,
    Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, TabContent
} from "reactstrap";
import Layout from "../../../layouts/Project";
import {
    faPen,
    faFolderOpen,
    faTrashRestore,
    faTimesCircle,
    faKey,
    faUserTimes,
    faHistory,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import { MinusCircle, PlusCircle } from "react-feather";
import * as headerInfoActions from '../../../redux/actions/header-info.actions';
import { FormCheck, ToggleButton } from "react-bootstrap";
import FolderOpen from "../../../assets/icons/Connected-System-Icons/Objects/FolderOpen";
import EditFill from "../../../assets/icons/Connected-System-Icons/Tools/Edit-fill";
import logo from "../../../assets/img/avatars/modal-logo.png";
var imgStyle = {
    width: "150px",
};
const mapStateToProps = (state, ownProps) => {
    return {
        vendors: state.vendorReducer.items,
        totalItems: state.vendorReducer.totalItems,
        hasMore: state.vendorReducer.hasMore,
        isLoading: state.vendorReducer.loading,
        msg: state.vendorReducer.msg,
        error: state.vendorReducer.error,
        abs: ownProps.abs,
        redux_masters: state.redux_master.masters,
        msg: state.vendorReducer.msg
    }
}

function VendorList(props) {
    let url = process.env.REACT_APP_API_BASE_URL;
    let history = useHistory();
    const mystyle = {
        cursor: "pointer",
        fontFamily: "Arial",
        marginLeft: "0.1rem",

    };

    const styleModelBody = {
        minHeight: "170px"
    }
    let SearchTypeList = [{ label: "Sap ID", value: "SapId", SearchValue: "", type: "number" }
        , { label: "Vendor Name", value: "VendorName", SearchValue: "", type: "text" }
        // , { label: "Key Contact Person", value: "KeyContactPerson", SearchValue: "", type: "text" }
        // , { label: "Contact Number", value: "ContactNumberPrimaryContactPerson", SearchValue: "", type: "number" }
        , { label: "Markets Covered", value: "Countries", group: "Countries", SearchValue: [], type: "text" }
        , { label: "Vendor Type", value: "VendorTypes", group: "vendortypes", SearchValue: [], type: "text" }
        , { label: "Capabilities", value: "SubMethodologies", group: "Methodologies", SearchValue: [], type: "text" }

    ];
    const userRecord = useSelector(({ user }) => user.userRecord);

    const [SearchType, setSearchType] = useState(null);
    const [fileTab, setFileTab] = useState(0);
    const [preview, setPreview] = useState("");
    const [SearchValue, setSearchValue] = useState([]);
    const [BlackListPage, setBlackListPage] = useState(false);
    const [BlackListPageData, setBlackListPageData] = useState(null);
    const [Country, setCountry] = useState([]);
    const [vendor, setVendor] = useState(null);
    const [blacklistedVendors, setBlackListedVendors] = useState(false);
    const [downloaduri, setdownloaduri] = useState("");


    const [docModal, setDocModal] = useState(false)
    const [uri, setUri] = useState("")


    const [modelState, setModelState] = useState(false);
    const [open, setOpen] = useState(false);
    const [name, setname] = useState();
    const [modelType, setModelType] = useState("Block");
    const [ActivateBlockReasonId, setActivateBlockReasonId] = useState(null);
    const [ActivateBlockReasonOther, setActivateBlockReasonOther] = useState(null);
    const [ActionRecordId, setActionRecordId] = useState(null);
    const [filterApplied, setFilterApplied] = useState(false)
    const [vendors, setVendors] = useState([])

    useEffect(() => {

        setVendors(props.vendors)

    }, [props.vendors])

    const issueList = [
        { key: 1, label: "Quality Issue", value: "I1" },
        { key: 2, label: "Integrity Issue", value: "I2" },
        { key: 3, label: "Comparison View", value: "I3" },
        { key: 4, label: "Other,Please Specify", value: "I4" },
    ];
    var totalblacklistedvendor;
    var filelist = [{ label: "Company Profile", value: "UploadCompanyProfile" },
    { label: "Field Execution Strength", value: "FieldExecutionStrenght" },
    { label: "SOP Training Completed", value: "SOPTrainingCompleted" },
    { label: "SOP Training Evidance File", value: "SOPTrainingEvidanceFile" },
    { label: "Signed NDA File", value: "SignedNDAFile" },
    { label: "Panel Book File", value: "PanelBookFile" },
    { label: "Signed MSA File", value: "SignedMSAFile" },
    { label: "Due Deligence Documents File", value: "DueDeligenceDocumentsFile" },
    { label: "Other Documents File", value: "OtherDocumentsFile" },
    { label: "Company Profile File", value: "CompanyProfileFile" },
    ]


    function filterVendors(e) {
        setBlackListedVendors(false)
        if (e.filter(type => type.value == 'SapId')?.length > 0) {
            dispatch(vendorActions.fetchVendors(null, "single", e));
        }
        else {
            dispatch(vendorActions.fetchVendors(null, "multi", e));
        }
    }

    function applyfilter(e, i, name)// i ==index
    {
        if (name == "KeyContactPerson" || name == "ContactNumberPrimaryContactPerson" || name == "SapId") {
            if (e?.length < 16) {
                var search = _.cloneDeep(SearchType)
                search[i].SearchValue = e;
                setSearchType(search)
            }
        }
        else {
            var search = _.cloneDeep(SearchType)
            search[i].SearchValue = e
            setSearchType(search)
        }
    }

    function updateVendors(e) {

        if (!e || e.length < 1) {
            setSearchType(null)
            dispatch(vendorActions.fetchVendors());
        }
        else {
            // if (e.filter(searchType => searchType.value == 'SapId')?.length > 0) {
            //     setSearchType([{ label: 'Sap ID', value: 'SapId', SearchValue: "" }])
            // }
            if (e.filter(searchType => searchType.value == 'SapId')?.length > 0) {
                setSearchType([{ label: 'Sap ID', value: 'SapId', SearchValue: "", type: "number" }])
            }
            else {
                setSearchType(e)
            }
        }
    }

    function handleBlacklisted() {
        //console.log("props.vendors", props.vendors);
        if (blacklistedVendors) {

            setVendors(props.vendors.filter(v => !(v.BlacklistCountries.length > 0)));
            //console.log("if");
            //setVendors(props.vendors)
        }
        else {
            setVendors(props.vendors.filter(v => v.BlacklistCountries.length > 0));
            totalblacklistedvendor = props.vendors.filter(v => v.BlacklistCountries.length > 0);
            //console.log("else");

        }
        setBlackListedVendors(!blacklistedVendors)
        //console.log("hello")
    }
    const dispatch = useDispatch();
    const li = {
        backgroundColor: "aliceblue",
        textAlign: "center"
    }
    const liActive = {
        border: "1px solid white",
        backgroundColor: "#00F000",
        textAlign: "center"
    }
    const a = {
        textDecoration: "none",
        fontWeight: "bold",
        color: "white",
        lineHeight: "40px",
        display: "block",

    }
    const aActive = {
        textDecoration: "none",
        color: "black",
        lineHeight: "40px",
        fontWeight: "bold",
        display: "block"
    }
    const styleLoader = {
        'position': 'absolute',
        'bottom': '100px !important'
    };

    useEffect(() => {
        if (props.redux_masters.length == 0) {

            dispatch(masterActions.fetchAllMasters())
        }

    }, [props.redux_masters])
    useEffect(() => {
        dispatch(vendorActions.fetchVendors());
    }, []);

    useEffect(() => {
        dispatch(headerInfoActions.setNewHeader({ Name: 'Vendors' }));

    }, []);


    useEffect(() => {
        if (SearchType == null) {
            setVendors(props.vendors)
        }
    }, [SearchType]);


    const actions = (row) => {
        return (
            <Col style={{ marginLeft: "90%" }}>
                <h5>Actions</h5>

                {/* <Row style={{ marginLeft: "13px" }}>
                    <FontAwesomeIcon
                        title="Open Uploaded Files"
                        className="align-middle ic-hover"
                        icon={faFolderOpen}
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            if (filelist.map((f, fi) => props.vendors.filter(v => v.id == row.id)[0] && props.vendors.filter(v => v.id == row.id)[0][f.value] != null).filter(x => x).length > 0) {

                                setDocModal(true)
                                setVendor(props.vendors.filter(v => v.id == row.id)[0])
                                setFileTab(filelist.map((f, i) => { if (props.vendors.filter(v => v.id == row.id)[0][f.value]) { return i } })?.filter(a => a).length > 0 ?
                                    filelist.map((f, i) => { if (props.vendors.filter(v => v.id == row.id)[0][f.value]) { return i } })?.filter(a => a)[0]
                                    : 0)
                            }
                            else {
                                toastr.warning("No Files Found / Files are not Uploaded");
                            }
                        }
                        }
                    />
                </Row> */}
                {/* <Link  title="Open Uploaded Files" style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            if (filelist.map((f, fi) => props.vendors.filter(v => v.id == row.id)[0] && props.vendors.filter(v => v.id == row.id)[0][f.value] != null).filter(x => x).length > 0) {

                                setDocModal(true)
                                setVendor(props.vendors.filter(v => v.id == row.id)[0])
                                setFileTab(filelist.map((f, i) => { if (props.vendors.filter(v => v.id == row.id)[0][f.value]) { return i } })?.filter(a => a).length > 0 ?
                                    filelist.map((f, i) => { if (props.vendors.filter(v => v.id == row.id)[0][f.value]) { return i } })?.filter(a => a)[0]
                                    : 0)
                            }
                            else {
                                toastr.warning("No Files Found / Files are not Uploaded");
                            }
                        }
                        }>
                         <FolderOpen/>
                    </Link> */}

                <Row style={{ marginLeft: "13px" }}>
                    <FontAwesomeIcon
                        title="Blacklist-Vendor-Countries"
                        className="align-middle ic-hover"
                        icon={faUserTimes}
                        style={{
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            blaklistModal(row)
                        }}
                    />
                </Row>
                <Row style={{ marginLeft: "13px", marginTop: "10px" }}></Row>
                {/* <Row style={{ marginLeft: "13px" }} >
                    {row.others && row.others.History &&
                        <FontAwesomeIcon
                            title="Blacklisted-History"
                            className="align-middle"
                            icon={faHistory}
                            style={{
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                History(row)
                            }}
                        />}
                </Row>  */}
                <Row style={{ marginLeft: "11px" }} >
                    <FontAwesomeIcon
                        title="Edit Vendor Detail"
                        className="align-middle ic-hover"
                        onClick={() => { history.push('/vendoronboardingV2/vendor-form/' + row.id) }}
                        icon={faPen}
                        style={{
                            cursor: "pointer"

                        }}


                    />
                    {/* <Link   title="Edit Vendor Detail" onClick={() => { history.push('/vendoronboarding/vendor-form/' + row.id) }}
                     style={{
                        cursor: "pointer"

                    }}>
                    <EditFill/>
                    </Link> */}
                    <div id={'open-detail' + row.id} style={mystyle} disabled
                    >

                    </div>
                    <UncontrolledTooltip placement="top" target={'open-detail' + row.id}>
                        Edit
                    </UncontrolledTooltip>
                    {row?.Status?.Code == "InActive" &&
                        <>
                            <div id={'activate' + row.id} style={mystyle} disabled onClick={() => {
                                setActionRecordId(row.id);
                                setModelType("Activate");
                                setActivateBlockReasonId(null);
                                setActivateBlockReasonOther(null);
                                setModelState(true);
                            }
                            }>
                                <FontAwesomeIcon className="ic-hover" icon={faKey} fixedWidth />
                            </div>
                            <UncontrolledTooltip placement="top" target={'activate' + row.id}>
                                Activate
                            </UncontrolledTooltip>
                        </>
                    }
                    {
                        row?.Status?.Code == "Blocked" &&
                        <>
                            <div id={'unblock' + row.id} style={mystyle} disabled onClick={() => {
                                dispatch(vendorActions.saveVendor({ id: row.id, StatusId: 2 }, "Vendor has been unblocked"));
                                setTimeout(() => {
                                    dispatch(vendorActions.fetchVendors());
                                }, 3000);
                            }
                            }>
                                <FontAwesomeIcon className="ic-hover" icon={faTrashRestore} fixedWidth />
                            </div>
                            <UncontrolledTooltip placement="top" target={'unblock' + row.id}>
                                Unblock
                            </UncontrolledTooltip>
                        </>
                    }
                    {row?.Status?.Code == "Active" &&
                        <>
                            <div id={'block' + row.id} style={mystyle} disabled onClick={() => {
                                setActionRecordId(row.id);
                                setModelType("Block");
                                setActivateBlockReasonId(null);
                                setActivateBlockReasonOther(null);
                                setModelState(true);
                            }}>
                                <FontAwesomeIcon className="ic-hover" icon={faTimesCircle} fixedWidth />
                            </div>
                            <UncontrolledTooltip placement="top" target={'block' + row.id}>
                                Block
                            </UncontrolledTooltip>
                        </>
                    }
                </Row>

            </Col>
        );
    };

    const expandRow = {
        renderer: row => (
            blacklistedVendors ?

                <div style={{ maxHeight: "400px", overflow: "auto" }}>
                    <table
                        className="table table-bordered "
                        border="0.4"
                        style={{ position: "relative" }}
                    >
                        <tr style={{ backgroundColor: "#00F000", color: "black" }}>

                            <th>Date of Blacklisting</th>
                            <th>Country</th>
                            <th>Project Id (If Available)</th>
                            <th>Reason</th>
                            <th>Blacklisted By</th>
                        </tr>

                        {JSON.parse(row?.others)?.History ? (JSON.parse(row?.others)?.History).map((a, i) => {
                            return (
                                <tr>
                                    <td>{a.date}</td>
                                    <td>{a.country.map(v => v.Label).toString()}</td>
                                    <td>{a?.projectid}</td>
                                    <td>{a.pleasespecify ? a.pleasespecify : a.Reason?.map(v => v.label).toString()}</td>
                                    <td>{a.doneby}</td>
                                </tr>
                            )
                        }

                        ) : ''}

                    </table>
                </div>
                : <div>
                    <Col xs="11"></Col>
                    {actions(row)}
                </div>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) =>
            isAnyExpands ? (
                <MinusCircle width={16} height={16} />
            ) : (
                <PlusCircle width={16} height={16} />
            ),
        expandColumnRenderer: ({ expanded }) =>
            expanded ? (
                <MinusCircle width={16} height={16} />
            ) : (
                <PlusCircle width={16} height={16} />
            )
    };

    const tableColumns = [
        {
            dataField: "SapId",
            text: "SAP ID",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { minWidth: "100px" };
            },
        },
        {
            dataField: "VendorName",
            text: "Vendor Name",
            sort: true.valueOf,
        },
        // {
        //     dataField: "KeyContactPerson",
        //     text: "Key Contact Person",
        //     sort: true
        // },
        // {
        //     dataField: "ContactNumberKeyContactPerson",
        //     text: "Contact Number",
        //     sort: true
        // },
        // {
        //     dataField: "OfficeLocation",
        //     text: "Head Office Location",
        //     sort: true
        // },
        {
            dataField: "VendorTypeNames",
            text: "Vendor Type",
            sort: true,
        }
    ];
    var project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );
    const current1 = new Date();
    var d = current1.getDate();
    var m = current1.getMonth() + 1;
    var y = current1.getFullYear();
    var current = d + "-" + m + "-" + y;
    function submitdata() {
        var data1 = _.cloneDeep(BlackListPageData)
        if (!(data1.BlacklistCountries.length > 0 && data1?.others?.Reasons?.length > 0)) {
            toastr.error("Please Select Mandatory Fields")
        }
        else {
            data1.Countries = data1.Countries.filter(item => !data1.BlacklistCountries.includes(item))
            if (data1.others.History != undefined) { data1.others.History.push({ date: current, country: data1.BlacklistCountries, Reason: data1.others.Reasons, doneby: userRecord.Email, pleasespecify: data1.others.PleaseSpecify, projectid: data1.others.projectid, projectname: name, Comment: data1.others.Comments }) }
            else {
                data1.others.History = [];
                data1.others.History.push({ date: current, country: data1.BlacklistCountries, Reason: data1.others.Reasons, doneby: userRecord.FirstName + " " + userRecord.LastName, pleasespecify: data1.others.PleaseSpecify, projectid: data1.others.projectid, projectname: name, Comment: data1.others.Comments })
            }
            {
                dispatch(appActions.pageLoadStart());
                axios.post("vendors/blacklist", data1)
                    .then(data => {
                        //console.log(data)
                        toastr.success("Form saved Successfully", props.msg);
                        setBlackListPage(false);
                        dispatch(appActions.pageLoadEnd())
                        dispatch(vendorActions.fetchVendors())
                    })

                    .catch(err => { console.log("err is"); console.log(err) });
            }
        }
    }

    function addvalueinrow(e, name) {
        var data = _.cloneDeep(BlackListPageData)
        if (data.others == undefined) {
            data.others = {}
        }
        if (name == 'BlacklistCountries' || name == 'Reasons') {
            if (name == 'BlacklistCountries') {
                var list = []
                if (e == null)
                    list = []
                else
                    e.forEach(x => data.Countries.forEach(u => { if (u.Code == x.value) { list.push(u) } }));
                if (data[name] == undefined) {
                    data[name] = list
                }
                else { data[name] = list }
            }
            else {
                data.others[name] = e
            }
        }
        else {
            data.others[name] = e.target.value
        }
        setBlackListPageData(data)
    }

    function blaklistModal(row) {
        if (row.others != null && typeof row.others === "string") {
            row.others = JSON.parse(row.others);
        }
        if (row.BlacklistCountries != null && typeof row.BlacklistCountries === "string") {
            row.BlacklistCountries = row.BlacklistCountries;
        }
        var country = []
        setBlackListPage(true)
        setBlackListPageData(row)
        row.Countries.forEach(c => {
            country.push({ key: c.id, label: c.Label, value: c.Code })

        }
        )
        setCountry(country)
    }

    function Handle(e) {
        {
            if (e.target.value.length != 12) {

            }
            else {
                axios.get("/projects/name/" + e.target.value)
                    .then(data => {
                        //console.log(data)
                        setname(data.data.project.ProjectName)
                        history.push("/vendoronboarding")
                    })

                    .catch(err => { console.log("err is"); console.log(err) });
            }
        }
    }
    async function onFileClick(e) {
        try {
            var res = await axios.get(`/files/download/vendor/${e}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/pdf'
                }
            });
            if (res) {
                DownloadFile(res, e)
            }

        }
        catch (err) {

            toastr.error("Loading Failed", err?.data?.message);
        }
    }

    function DownloadFile(res, e) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${e}`)
        document.body.appendChild(link);
        link.click();
    }

    function FileType(data, type) {
        const BlobURL = window.URL.createObjectURL(new Blob([data], type));
        window.open(BlobURL);
    }

    async function onpreviewselectedfile(e) {
        try {
            var res = await axios.get(`/files/download/vendor/${e}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/pdf'
                }
            });
            if (res) {
                let fileType = res.config.url.split('.').pop()
                switch (fileType) {
                    case 'pdf':
                        FileType(res.data, { type: 'application/pdf' })
                        break;
                    case 'jpeg':
                        FileType(res.data, { type: 'image/jpeg' })
                        break;
                    case 'jpg':
                        FileType(res.data, { type: 'image/jpg' })
                        break;
                    case 'png':
                        FileType(res.data, { type: 'image/png' })
                        break;
                    case 'xls':
                        //FileType(res.data, { type: 'application/vnd.ms-excel' })
                        DownloadFile(res, e)
                        break;
                    case 'xlsx':
                        //FileType(res.data, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                        DownloadFile(res, e)
                        break;
                    case 'doc':
                        //FileType(res.data, { type: 'application/msword' })
                        DownloadFile(res, e)
                        break;
                    case 'docx':
                        //FileType(res.data, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
                        DownloadFile(res, e)
                        break;

                    default:
                        toastr.error("Loading Failed")
                        break;
                }
            }
        }
        catch (err) {
            toastr.error("Loading Failed", err?.data?.message);
        }
    }
    function History(row) {
        if (row.others != null && typeof row.others === "string") {
            row.others = JSON.parse(row.others);
        }
        if (row.BlacklistCountries != null && typeof row.BlacklistCountries === "string") {
            row.BlacklistCountries = row.BlacklistCountries;
        }
        setOpen(true)
        setBlackListPageData(row)
    }

    const fetchMoreData = () => {
        if (props.vendors.length >= props.totalItems) {
            return;
        }
        dispatch(vendorActions.fetchVendors(props.vendors.length, null, SearchValue));
    };

    return (
        <div className="vendor_onboarding">
        <Layout
            onlyHeader={true}
            hideProfileName={true}
            avoidEdit={true}
            hideActions={project.Mode == 'CostingOptionsDetails' ? false : true}
            CardHeader="ONBOARDED VENDOR LIST">
            {open &&
                <Modal
                    isOpen={open}
                    toggle={() => setOpen(false)}
                    centered
                    className={"modelSelectVendors"}
                >
                    <ModalHeader
                        toggle={() => {
                            setOpen(false);
                        }}
                    >
                        <span>Change History</span>
                    </ModalHeader>
                    <ModalBody className="m-3">
                        <div style={{ maxHeight: "400px", overflow: "auto" }}>
                            <table
                                className="table table-bordered "
                                border="0.4"
                                style={{ position: "relative" }}
                            >
                                <tr style={{ backgroundColor: "#00F000", color: "black" }}>
                                    <th>S.NO</th>
                                    <th>Date</th>
                                    <th>Country</th>
                                    <th>Reason</th>
                                    <th>Changed By</th>
                                </tr>

                                {BlackListPageData.others.History.map((a, i) => {
                                    return (
                                        <tr>

                                            <td>{i + 1}</td>
                                            <td>{a.date}</td>
                                            <td>
                                                {a.country.map(v => v.Label).toString()}
                                            </td>
                                            <td>{a.Reason.map(v => v.label).toString()}</td>
                                            <td>{a.doneby}</td>
                                        </tr>
                                    )
                                }

                                )}

                            </table>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="danger"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            }

            <Modal
                isOpen={docModal}
                size="xs"
                className={"modelSelectVendors"}
                centered={true}
            >
                <ModalHeader toggle={() => { setDocModal(false) }}>
                    {/* <img src={logo} className="modallogo" /> */}
                </ModalHeader>
                <div>
                    <span className="modaltitle modaltitledocument">
                        Documents List
                    </span>
                </div>
                <ModalBody>
                    {filelist.map((f, fi) => vendor && vendor[f.value] != null).filter(x => x).length > 0
                        ?
                        <Table
                            className="table table-stripped"
                            size="sm"
                            bordered
                        >
                            <tr>
                                {/* <th>S.No</th> */}
                                <th>File Label</th>
                                <th>File Name</th>
                                <th>Preview</th>
                                <th>Download</th>
                            </tr>
                            {filelist.map((f, fi) =>
                                vendor && vendor[f.value] != null &&
                                <tr>
                                    <td >
                                        <b>{f.label}</b>
                                    </td>
                                    <td md={7}>
                                        <b>{vendor[f.value]}</b>
                                    </td>
                                    <td md={1}>
                                        <FontAwesomeIcon
                                            onClick={() => onpreviewselectedfile(vendor[f.value])}
                                            title="Preview file"
                                            className="align-middle mr-2 addButton"
                                            icon={faFile}
                                            fixedWidth
                                            style={{ cursor: "pointer" }}
                                        />
                                    </td>
                                    <td md={1}>
                                        <FontAwesomeIcon
                                            onClick={() => onFileClick(vendor[f.value])}
                                            title="Download File"
                                            className="align-middle mr-2 addButton"
                                            icon={faDownload}
                                            // icon={vendor[f.value].includes(".pdf") ? faFilePdf : (vendor[f.value].includes(".jpg") || vendor[f.value].includes(".jpeg") || vendor[f.value].includes(".png")) ? faImage : faDownload}
                                            fixedWidth
                                            style={{ cursor: "pointer" }}
                                        />
                                    </td>
                                </tr>
                            )}
                        </Table>
                        : <div style={{ textAlign: "center" }} > No Files Found / Files are not Uploaded</div>
                    }

                </ModalBody>
            </Modal>

            {
                BlackListPage && (
                    <Modal
                        isOpen={BlackListPage}
                        toggle={() => setBlackListPage(!BlackListPage)}
                        centered={true}
                        className={"modelSelectVendors"}

                    >
                        <ModalHeader
                            toggle={() => {
                                setBlackListPage(!BlackListPage)
                            }}
                        >
                            {/* <img src={logo} className="modallogo" /> */}
                        </ModalHeader>
                        <div>
                            <span className="modaltitle modalvendorblacklist">
                                Vendor Blacklist Form
                            </span>
                        </div>
                        <ModalBody className="m-3">

                            <form>
                                <Row>
                                    <Col md={6}>
                                        <Label>Name of Vendor</Label>
                                        <Input type="text" name="vendorname" disabled value={BlackListPageData.VendorName} />
                                    </Col>
                                </Row>
                                <br />

                                <Row >
                                    <Col md={6}>
                                        <Label>Blacklist Countries (*)</Label>
                                        <Select
                                            name={'BlacklistCountries'}
                                            rules={{ required: 'Please select an option' }}
                                            className="basic-multi-select"
                                            classNamePrefix="react-select"
                                            required={true}
                                            onChange={(e) => addvalueinrow(e, 'BlacklistCountries')}
                                            options={Country}
                                            isMulti
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Label>Reasons (*)</Label>
                                        <Select

                                            name={'Reasons'}
                                            rules={{ required: 'Please select an option' }}
                                            className="basic-multi-select"
                                            classNamePrefix="react-select"
                                            required={true}
                                            options={issueList}
                                            onChange={(e) => addvalueinrow(e, 'Reasons')}
                                            isMulti
                                        />
                                    </Col>
                                </Row>
                                {
                                    BlackListPageData != null && BlackListPageData.others && BlackListPageData.others.Reasons && BlackListPageData.others.Reasons.filter(a => a.label == "Other,Please Specify").length > 0 &&
                                    <Row>
                                        <Col md={12}>
                                            <Label>Please Specify</Label>
                                            <textarea className="form-control w-100" style={{ height: "2rem" }} type="text"
                                                name="PleaseSpecify"
                                                onChange={(e) => addvalueinrow(e, "PleaseSpecify")}
                                            />
                                        </Col>
                                    </Row>
                                }
                                <br />

                                <Row>
                                    <Col md={6}>
                                        <Label>Project ID If Any (Please press enter after adding 12 digit Project ID)</Label>
                                        <Input type="text" name="projectid" onChange={(e) => addvalueinrow(e, "projectid")} onKeyDown={e => e.key === 'Enter' && Handle(e)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Label>Project Name If Any</Label>
                                        <Input type="text" name="projectname" onChange={(e) => addvalueinrow(e, "projectname")} disabled

                                            value={name}
                                        />
                                    </Col>

                                    <Col md={12}>
                                        <Label>Comments</Label>
                                        <textarea className="form-control w-100" style={{ height: "2rem" }} type="text"
                                            name="Comments"
                                            onChange={(e) => addvalueinrow(e, "Comments")}
                                        />
                                    </Col>
                                </Row>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => {
                                    submitdata()

                                }}
                            >
                                Save
                            </Button>
                            <Button
                                color="secondary"
                                onClick={() => {
                                    setBlackListPage(false);
                                    setname()

                                }}
                            >
                                Close
                            </Button>
                        </ModalFooter>
                    </Modal>
                )
            }

            <Container fluid className="p-0">
                <Card className="p-3">
                    <Row>

                        <Col md="3">
                            <FormGroup>
                                <Select
                                    name="SearchType"
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={Array.isArray(SearchType) && SearchType.length > 0 && SearchType.some(sm => sm.label == "Sap ID") ? [] : SearchTypeList}
                                    placeholder="Search Type"
                                    value={SearchType}
                                    onChange={(e) => {
                                        updateVendors(e)
                                    }
                                    }
                                    isMulti
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                        </Col>
                        <Col md="3">
                            <Button onClick={() => { history.push('/vendoronboardingV2/vendor-form') }}  >Onboard New Vendor</Button>
                        </Col>
                    </Row>
                    {
                        SearchType && SearchType.length &&
                        <>
                            <Row>
                                {SearchType.map((search, i) =>
                                    <>
                                        <Col md="3">
                                            {!search.group && search.type == "number" &&
                                                <Input
                                                    name={search.value}
                                                    type={search.type}
                                                    min="0"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    pattern="[0-9]"
                                                    placeholder={"Enter " + search.label}
                                                    onChange={e => applyfilter(e.target.value, i, search.value)}
                                                    style={{ marginTop: "1px", height: "35px" }}
                                                    value={SearchType.filter(type => type.value == search.value)[0]?.SearchValue}
                                                ></Input>}
                                            {!search.group && search.type == "text" &&
                                                <Input
                                                    name={search.value}
                                                    type={search.type}
                                                    placeholder={"Enter " + search.label}
                                                    onChange={e => applyfilter(e.target.value, i, search.value)}
                                                    style={{ marginTop: "1px", height: "35px" }}
                                                    value={SearchType.filter(type => type.value == search.value)[0]?.SearchValue}
                                                ></Input>}
                                            {search.group &&
                                                <Select
                                                    name={search.value}
                                                    options={props.redux_masters.filter(x => x.Group == search.group)[0]?.List}
                                                    placeholder={"Select " + search.label}
                                                    onChange={e => applyfilter(e, i, search.value)}
                                                    value={SearchType.filter(type => type.value == search.value)[0]?.SearchValue}
                                                    isMulti
                                                ></Select>}
                                        </Col>

                                    </>

                                )
                                }

                                <Col ms="1">
                                    <Button style={{ marginTop: "3px" }}
                                        onClick={() => filterVendors(SearchType)}>Apply Filter</Button>

                                    <Button style={{ marginTop: "3px", marginLeft: "3px" }}
                                        onClick={() => updateVendors([])}>Clear Filter</Button>
                                </Col>
                            </Row>

                        </>
                    }
                </Card>
                <Row>
                    <Col md="10">
                        {props?.vendors && (!blacklistedVendors) &&
                            <h1>
                                Showing {vendors?.length} of {props?.totalItems} Vendors
                            </h1>
                        }
                        {
                            props?.vendors && (blacklistedVendors) &&
                            <h1>
                                Showing {vendors?.length}  Vendors
                            </h1>
                        }
                    </Col>
                    <Col md="2">
                        <FormCheck
                            label="BlackListed Vendors"
                            id="switchEnabled"
                            type="switch"
                            checked={blacklistedVendors}
                            onChange={() => {
                                handleBlacklisted()
                            }}
                        >
                        </FormCheck>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        {(!props?.isLoading || props?.vendors?.length != 0) &&
                            <Card>
                                <CardBody>
                                    <InfiniteScroll
                                        hasMore={props.hasMore}
                                        loadMore={fetchMoreData}
                                        initialLoad={false}>
                                        <BootstrapTable
                                            striped
                                            wrapperClasses="table-responsive"
                                            bootstrap4
                                            bordered={false}
                                            keyField="id"

                                            columns={tableColumns}
                                            data={vendors}

                                            expandRow={expandRow}
                                        />
                                    </InfiniteScroll>
                                </CardBody>
                            </Card>
                        }
                        {props.isLoading && <Loader style={styleLoader} />}
                    </Col>
                </Row>

                <Button
                    id="onboardForm"
                    className="addition-button"
                    onClick={() => history.push('/vendoronboardingV2/vendor-form')}
                >
                    <span>
                        +
                    </span>
                </Button>
                <UncontrolledTooltip placement="top" target='onboardForm'>
                    Onboard New Vendor
                </UncontrolledTooltip>
                <Modal isOpen={modelState} toggle={() => setModelState(!modelState)} centered size="sm">
                    <ModalHeader toggle={() => setModelState(!modelState)}>
                        {modelType == "Block" ? 'Block Vendor' : 'Activate Vendor'}
                    </ModalHeader>
                    {props.redux_masters.length > 0 &&
                        <ModalBody className="m-3" style={styleModelBody}>
                            {modelType == "Block" ?
                                <Col md={12}>
                                    <FormGroup>
                                        <Select
                                            name="ActivateBlockReason"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={props.redux_masters.filter(x => x.Group == "Reasons")[0]["List"].filter(x => x.GROUP == "BLOCKED")}
                                            value={ActivateBlockReasonId}
                                            onChange={(e) => setActivateBlockReasonId(e)}
                                        />
                                    </FormGroup>
                                </Col>
                                :
                                <Col md={12}>
                                    <FormGroup>
                                        <Select
                                            name="ActivateBlockReason"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={props.redux_masters.filter(x => x.Group == "Reasons")[0]["List"].filter(x => x.GROUP == "ACTIVATED")}
                                            value={ActivateBlockReasonId}
                                            onChange={(e) => setActivateBlockReasonId(e)}
                                        />
                                    </FormGroup>
                                </Col>
                            }
                            {ActivateBlockReasonId && ActivateBlockReasonId.Code == "Other" &&
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>Other Reason</Label>
                                        <Input type="textarea" name=" ActivateBlockReasonOther" value={ActivateBlockReasonOther} onChange={(e) => setActivateBlockReasonOther(e.target.value)} />
                                    </FormGroup>
                                </Col>
                            }
                        </ModalBody>
                    }
                    <ModalFooter>
                        <Button color="success" onClick={() => {
                            ;
                            setModelState(!modelState);
                            let newStatus = modelType == "Block" ? 4 : 6;
                            let actionVariableId = modelType == "Block" ? "BlockedReasonId" : "ActivatedReasonId";
                            let actionVariableOther = modelType == "Block" ? "OtherBlockedComments" : "OtherActivatedComments";
                            let msg = modelType == "Block" ? "Vendor has been blocked" : "Vendor activation request has been sent";
                            dispatch(vendorActions.saveVendor({
                                id: ActionRecordId, StatusId: newStatus, [actionVariableId]: ActivateBlockReasonId.value
                                , [actionVariableOther]: ActivateBlockReasonOther
                            }, msg));

                            setTimeout(() => {
                                dispatch(vendorActions.fetchVendors());
                            }, 3000);
                        }}>
                            Submit
                        </Button>
                        <Button color="danger" onClick={() => {
                            setActionRecordId(null);
                            setActivateBlockReasonId(null);
                            setActivateBlockReasonOther(null);
                            setModelState(false);
                        }
                        }>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </Layout >
        </div>
    )
}
export default connect(mapStateToProps, null)(VendorList)
