import React, { useEffect, useState } from "react";
import { Col, Row, CustomInput, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select from "react-select";
import * as _ from "lodash";
import update from 'immutability-helper';
import CreatableSelect from "react-select/creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/img/avatars/modal-logo.png"; 
export var imgStyle = {
    width: "150px",
  };
export const UI_LOCATION = Object.freeze({
    RFQ: "RFQ",
    SUD: "SUD"
})

const ModalRFQ = ({ selectedKey, changedData, initialValue, isOpen, pacific, type, properties, cityOptions, lengthOfInterviews, toggleHandler, updateModal }) => {

    const [isModalOpen, setModalOpen] = useState(false);
    const [copyProperties, setCopyProperties] = useState({});
    const typeArr = ['isRequired', 'isShow', 'isDefaultCostingOption'];
    const [errorMessage, setErrorMessage] = useState({});
    useEffect(() => {
        setModalOpen(isOpen);
        if (!_.isEqual(copyProperties, properties)) {
            setCopyProperties(properties);
        }
    });

    // render modal body UI
    const renderBody = () => {
        
        const getLabel = () => {
            if (selectedKey === "dataProcessingComplexity") {
                return 'Show/Hide'
            }
            return (type === 'rfq') ? 'Show/Hide in RFQ' : 'Show/Hide in SUD'
        }
        
        return (<>
            <Row>
                <Col>
                    <label>Field title</label>
                    <Input type="text" id={type + '-text'} name={type + '-text'}
                        key={type + '-text'} value={copyProperties.title}
                        onChange={(e) => {
                            InfoChange(e.target.value, 'title')
                        }}
                        readOnly />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    {renderControl(copyProperties)}
                </Col>
            </Row>
            {
                (copyProperties.enum) ?
                    <Row className="mt-2">
                        <Col>
                            <label>Set the options</label>
                            <br />
                            <CreatableSelect
                                className="react-select-container"
                                classNamePrefix="react-select"
                                menuPortalTarget={document.querySelector('card-body')}
                                menuPosition={'fixed'}
                                isMulti
                                value={copyProperties.optionsList}
                                onChange={(e) => {
                                    changeOptionValues(e)
                                }}
                                options={copyProperties.optionsList && copyProperties.optionsList.map((opt) => {
                                    return {
                                        value: opt.Code,
                                        label: opt.Label,
                                    }
                                })} />
                        </Col>
                    </Row> : ''
            }
               { (type === 'rfq') ?
                <Row className="mt-2">
                    <Col>
                        <CustomInput type="checkbox" id={type + '-check-default'} name={type + '-check-default'}
                            key={type + '-check-default'}
                            className="ml-0"
                            checked={copyProperties.isDefaultCostingOption}
                            style={{ cursor: "pointer" }} title="Default / No default"
                            label='Default Costing option'
                            onChange={(e) => {
                                InfoChange(copyProperties.isDefaultCostingOption, 'isDefaultCostingOption')
                            }}
                        />
                    </Col>
                </Row> : ''}
            
            <Row className="mt-2">
                <Col>
                    <CustomInput type="checkbox" id={type + '-check'} name={type + '-check'}
                        key={type + '-check'}
                        className="ml-0"
                        checked={copyProperties.isShow}
                        style={{ cursor: "pointer" }}
                        label={getLabel()}
                        onChange={(e) => {
                            InfoChange(copyProperties.isShow, 'isShow')
                        }}
                    />
                </Col>
                <Col>
                    <CustomInput
                        type="switch"
                        id={type + '-switch'} name={type + '-switch'}
                        className="mb-3"
                        key={type + '-switch'}
                        checked={copyProperties.isRequired}
                        onChange={(e) => {
                            InfoChange(copyProperties.isRequired, 'isRequired')
                        }}
                        style={{ cursor: "pointer" }}
                        label='Is field mandatory ?'
                    />
                </Col>
            </Row>
            {selectedKey === "dataProcessingComplexity" && (
                <div className="mb-2">
                    <label>Where to show?</label>
                    <Col className="ml-2">
                        <CustomInput type="radio" id={type + '-rfq-radio'} name={type + '-rfq-radio'}
                            key={type + '-rfq-radio'}
                            className="ml-0"
                            checked={copyProperties.ui_location === UI_LOCATION.RFQ || copyProperties.ui_location === undefined}
                            style={{ cursor: "pointer" }}
                            label={'In RFQ'}
                            disabled={!copyProperties.isShow}
                            onChange={(e) => {
                                InfoChange(UI_LOCATION.RFQ, 'ui_location')
                            }}
                        />
                    </Col>
                    <Col className="ml-2">
                        <CustomInput type="radio" id={type + '-sud--radio'} name={type + '-sud-radio'}
                            key={type + '-sud-radio'}
                            className="ml-0"
                            checked={copyProperties.ui_location === UI_LOCATION.SUD}
                            style={{ cursor: "pointer" }}
                            label={'In SUD'}
                            disabled={!copyProperties.isShow}
                            onChange={(e) => {
                                InfoChange(UI_LOCATION.SUD, 'ui_location')
                            }}
                        />
                    </Col>
                </div>
            )}
            {
                (copyProperties.dependentOn) ?
                    <Row>
                        <Col md={12}><strong>Note:</strong> To display this field, you {(copyProperties.dependentOn.andor === 'OR') ? 'have to choose anyone of the' : 'must choose the'} below dependency in the section.</Col>
                        <Col md={12} className="mt-1">
                            <ol start="1">
                                {
                                    copyProperties.dependentOn.fields.map((F, ind) => {
                                        return (
                                            <li key={ind + F}>{F.fieldName}</li>
                                        )
                                    })
                                }
                            </ol>
                        </Col>
                    </Row> : ''
            }
        </>)
    }

    // info update of the modal properties
    const InfoChange = (value, type) => {
        var propsDraft = update(copyProperties, {
            [type]: { $set: (typeArr.includes(type)) ? !value : value }
        });
        if (!propsDraft.isShow && propsDraft.isRequired) {
            propsDraft = update(propsDraft, {
                isRequired: { $set: !propsDraft.isRequired }
            });
        }
        updateModal(propsDraft);
    }

    const defaultLabel = () => {
        return (
            <label>Set default value</label>
        )
    }

    // check the element type
    const typeCheck = (v) => {
        return (v.enum && v.widgetType) ? 'multi' : (v.enum)
            // ||(v.optionsLabel) 
            ? 'single' :
            (v.type === 'number' || v.type === 'integer') ? 'number' :
                (v.type === 'string' && v.widgetType) ? 'textarea' :
                    (v.type === 'string' && v.format) ? 'date' : 'string'
    }

    const formatOptionLabel = ({ value, label }) => (
        <div className="d-flex justify-content-between">
            <div>{label}</div>
            <FontAwesomeIcon
                title="Remove"
                icon={faTimes}
                fixedWidth
                onClick={(e) => {
                    e.stopPropagation();
                    removeSelectedOption(label);
                }}
                className="small m-1"
            />
        </div>
    );

    const removeSelectedOption = (label) => {
        const newSelect = copyProperties.enum?.filter(
            item => item !== label
        );
        const propsDraft = update(copyProperties, {
            'enum': { $set: newSelect }
        });
        setCopyProperties(propsDraft);
        updateModal(propsDraft);
        if (propsDraft.defaultValue && Array.isArray(propsDraft.defaultValue)) {
            const defaultProps = propsDraft.defaultValue?.filter(
                item => item.label !== label
            )
            const draftValues = update(propsDraft, {
                'defaultValue': { $set: defaultProps }
            });
            setCopyProperties(draftValues);
            updateModal(draftValues);
        } else if (propsDraft.defaultValue && propsDraft.defaultValue.label === label) {
            const draftValues = update(propsDraft, {
                'defaultValue': { $set: null }
            });
            setCopyProperties(draftValues);
            updateModal(draftValues);
        }
    }

    // render form control UI
    const renderControl = (v) => {
        if (v.optionsLabel) {
            switch (v.optionsLabel) {
                case 'Cities':
                    return (
                        <>
                            {defaultLabel()}
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                menuPortalTarget={document.querySelector('card-body')}
                                menuPosition={'fixed'}
                                options={cityOptions.Cities.map((opt) => {
                                    return {
                                        value: opt.Code,
                                        label: opt.Label,
                                    }
                                })}
                                onChange={(e) => { changeControllerValue(e) }}
                                value={v.defaultValue}
                                isMulti="true"
                            />
                        </>
                    )
                case 'LengthOfInterviewOptions':
                    return (
                        <>
                            {defaultLabel()}
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                menuPortalTarget={document.querySelector('card-body')}
                                menuPosition={'fixed'}
                                options={lengthOfInterviews.map((opt) => {
                                    return {
                                        value: opt.Code,
                                        label: opt.Label,
                                    }
                                })}
                                onChange={(e) => { changeControllerValue(e) }}
                                value={v.defaultValue}
                            />
                        </>
                    )
                default:
                    return (
                        <>
                            {defaultLabel()}
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                menuPortalTarget={document.querySelector('card-body')}
                                menuPosition={'fixed'}
                                options={initialValue.codeLabels[v.optionsLabel].map((opt) => {
                                    return {
                                        value: opt.Code,
                                        label: opt.Label,
                                    }
                                })}
                                onChange={(e) => { changeControllerValue(e) }}
                                value={v.defaultValue}
                            />
                        </>
                    )
                        ;
            }
        }
        switch (typeCheck(v)) {
            case 'multi':
                return (
                    <>
                        {defaultLabel()}
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            menuPortalTarget={document.querySelector('card-body')}
                            menuPosition={'fixed'}
                            options={v.enum.map((opt) => {
                                return {
                                    value: opt,
                                    label: opt,
                                }
                            })}
                            onChange={(e) => { changeControllerValue(e) }}
                            value={v.defaultValue}
                            isMulti="true"
                            formatOptionLabel={formatOptionLabel}
                        />
                    </>
                )
            case 'single':
                return (
                    <>
                        {defaultLabel()}
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            menuPortalTarget={document.querySelector('card-body')}
                            menuPosition={'fixed'}
                            options={

                                v.enum.map((opt) => {
                                    return {
                                        value: opt,
                                        label: opt,
                                    }
                                })}
                            onChange={(e) => { changeControllerValue(e) }}
                            value={v.defaultValue}
                            formatOptionLabel={formatOptionLabel}
                        />
                    </>
                )
            case 'number':
                return (
                    <>
                        {defaultLabel()}
                        {
                            v.title && v.title.includes("Rate") ?
                                <Input type="number" id={v.type + 'number'} name={v.type + 'number'}
                                    key={v.type + 'number'} min="0" max="100" value={v.defaultValue}
                                    onChange={(e) => { changeControllerValue(e.target.value) }}
                                />
                                :
                                <Input type="number" id={v.type + 'number'} name={v.type + 'number'}
                                    key={v.type + 'number'} min="0" value={v.defaultValue}
                                    onChange={(e) => { changeControllerValue(e.target.value) }}
                                />}
                        <label style={{ color: '#AD4400' }}>{(!_.isEmpty(errorMessage)) ? errorMessage.integer : ''}</label>
                    </>
                )
            case 'textarea':
                return (
                    <>
                        {defaultLabel()}
                        <Input type="textarea" id={v.type + 'textarea'} name={v.type + 'textarea'}
                            key={v.type + 'textarea'} value={v.defaultValue}
                            onChange={(e) => { changeControllerValue(e.target.value) }}
                        />
                    </>
                )
            case 'string':
                return (
                    <>
                        {defaultLabel()}
                        <Input type="text" id={v.type + 'text'} name={v.type + 'text'}
                            key={v.type + 'text'} value={v.defaultValue}
                            onChange={(e) => { changeControllerValue(e.target.value) }}
                        />
                    </>
                )
            case 'date':
                return (
                    <>
                        {defaultLabel()}
                        <Input type="date" id={v.type + 'date'} name={v.type + 'date'}
                            key={v.type + 'date'} value={v.defaultValue}
                            onChange={(e) => { changeControllerValue(e.target.value) }}
                        />
                    </>
                )
            default:
                break;
        }
    }

    const validateSave = () => {
        if (copyProperties.type === 'integer' && copyProperties.title.includes("Rate") && (copyProperties.defaultValue > 100)) {
            setErrorMessage({ integer: 'Field should not exceed more than 100 ' })
            return true;
        } else {
            setErrorMessage({});
            return false;
        }
    }

    // save the modal data
    const modalSaveHandler = () => {
        if (copyProperties.optionsList) {
            const propsDraft = updatePropsDraft('enum', [...copyProperties.enum, ...copyProperties.optionsList.map(O => O.value)]);
            delete propsDraft.optionsList;
            toggleHandler('save', propsDraft);
        } else {
            if (copyProperties.hasOwnProperty('optionsList')) {
                delete copyProperties.optionsList;
            }
            toggleHandler('save', copyProperties);
        }
    }

    const changeControllerValue = (value) => {
        updateModal(updatePropsDraft('defaultValue', value));
    }

    const changeOptionValues = (values) => {
        let filteredValues = values?.filter((i) => !copyProperties.enum.toString().toLowerCase()?.includes(i.label.toLowerCase()));
        //console.log(filteredValues);
        updateModal(updatePropsDraft('optionsList', (filteredValues) ? [...filteredValues] : null));
    }

    const updatePropsDraft = (type, values) => {
        const propsDraft = update(copyProperties, {
            [type]: { $set: values }
        });
        return propsDraft;
    }

    return (
        <React.Fragment>
            {
                (isModalOpen) ?
                    <Modal isOpen={isModalOpen} toggle={() => toggleHandler('close', !isModalOpen)} centered size={"sm"}>
                        <ModalHeader toggle={() => toggleHandler('close', !isModalOpen)}>
                        {/* <img src={logo}  className="modallogo"/> */}
                            {/* {(type === 'rfq') ? 'RFQ' : 'Setup & Delivery'} schema configuration */}
                        </ModalHeader>
                        <div>
                        <span className="modaltitle modaltitlerfqschema">
                        {(type === 'rfq') ? 'RFQ' : 'Setup & Delivery'} schema configuration
                        </span>
                        </div>
                        <ModalBody style={{ minHeight: '60px' }} className="pr-4" >
                            {renderBody()}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => {
                                if (validateSave()) {
                                } else {
                                    modalSaveHandler()
                                    changedData(selectedKey)
                                }
                            }}>
                                Update
                            </Button>
                            <Button color="secondary" onClick={() => toggleHandler('close', false)}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                    : ''
            }
        </React.Fragment>
    )
}

export default ModalRFQ;