import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import store from "./redux/store/index";
import Routes from "./routes/Routes";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import ReactGA from 'react-ga';

const DROPDOWN_DEFAULT_PROPS = {
  className: "react-select-container",
  classNamePrefix:"react-select"
}
Select.defaultProps = DROPDOWN_DEFAULT_PROPS
CreatableSelect.defaultProps = DROPDOWN_DEFAULT_PROPS

  const TRACKING_ID = "UA-236012581-1" // OLD: "UA-234416706-2"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID, {'siteSpeedSampleRate': 100});


const App = (props) => {
  return (
    <Provider store={store}>
      <Routes />
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        position="bottom-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        showCloseButton={true}
        progressBar={true}
        progressBar
        closeOnToastrClick
      />
    </Provider>
  );
};

export default App;
