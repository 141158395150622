import _ from "lodash";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import Axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { recordLoadEnd, recordLoadStart } from "../../redux/actions/appActions";
import { Link } from "react-router-dom";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
// import logo from "../assets/img/avatars/modal-logo.png";
import logo from "../../assets/img/avatars/modal-logo.png";
import { each } from "jquery";
import { setProjects } from "../../../src/redux/actions/projectsActions";

function ProjectScheduleAdjustment({
  profile,
  project,
  setScheduleDate,
  isScheduleDates,
}) {
  const [selectedMethodologies, setSelectedMethodologies] = useState([]);
  const [wbsNumbers, setWbsNumbers] = useState([]);
  const [scheduleDate, setScheduleDates] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [isHistoryOpen, setHistoryOpen] = useState(false);
  const [historyDatatoDisplay, setHistoryDatatoDisplay] = useState([]);
  const [isclosed, setisClosed] = useState([]);
  const [isCancelled,setIsCancelled]= useState([]);
  const [assignProjectManager, setAssignProjectManager] = useState(false)
  const [currentProject, setCurrentProject] = useState(project);
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [getprojectmanagerlist, setGetPorjectManagerList] = useState([]);
  const [currproj, setCurrProj] = useState([]);
  const [allDates, setAllDates] = useState({
    FWStartDate: '',
    FWEndDate: '',
    QuestionnaireDate: '',
  })
  let [enableonlyonerow, setEnableOnlyOneRow] = useState([]);
  let [wbsnumber, setWbsNumber_sd] = useState({
    wbsnumber_sd: '',
    label_sd: '',
  })
  let [acceptPM, setAcceptPM] = useState({})

  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const projects = useSelector(({ projects }) => projects.items);
  const recordloading = useSelector(({ app }) => app.recordloading);
  const dispatch = useDispatch();
  const userRecord = useSelector(({ user }) => user.userRecord);

  useEffect(() => {
    console.log(project);
    let meths = _.cloneDeep(selectedMethodologies);
    if (profile?.id && project?.id)
      profile?.CountrySpecs.map((cs) =>
        cs?.MethodologySpecs.map((ms) => {
          let opportunityLineItemDetails = [];
          project.ContractDetails.forEach((cd) => {
            opportunityLineItemDetails = [
              ...opportunityLineItemDetails,
              ...cd.opportunityLineItemDetails,
            ];
          });
          let lineItemIds = _.uniq(
            ms.CostingOptions.filter((co) => co.CoSelectedForSummary).map(
              (co) => co.OpportunityLineItemID
            )
          );
          if (opportunityLineItemDetails.length) {
            if (
              opportunityLineItemDetails
                .filter((ol) =>
                  _.includes(lineItemIds, ol.id)
                )
                .every((ol) => ol.WBSNumber)
            ) {
              let _wbs = lineItemIds.map(
                (li) =>
                  opportunityLineItemDetails.find(
                    (ol) => ol.id == li
                  )?.WBSNumber
              );

              if (
                _wbs?.length &&
                !ms.NotApplicable &&
                !meths.filter((sm) => sm.Code == ms.Code).length
              ) {
                meths.push({
                  Code: ms.Code,
                  Label: ms.Label,
                  id: ms.id,
                  wbsnumbers: _wbs,
                });
              }
            } else {
              toastr.error("WBS is Missing");
            }
          } else {
            toastr.error("Opportinty Details Missing this Project");
          }
        })
      );

    console.log(meths);
    setSelectedMethodologies(meths);
    fetchSchedueDates();
  }, []);
  const fetch = async () => {
    await Axios.get("costingProfiles/gethistoryDateAdjustments/" + profile.id, {
      headers: { "auth-token": localStorage.getItem("auth-token") },
    })
      .then((data) => {
        setHistoryDatatoDisplay(data.data.data);
        console.log(data.data.data);
      })
      .catch((err) => {
        console.log("History Data Loadig Failed");
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    // dispatch({ type: "SEARCH_TYPE", SearchingType: "ID" });
    // dispatch({ type: currentCostingActions.CLEAR_NEW_COSTING });
    // dispatch({ type: currentProjectActions.CLEAR_NEW_PROJECT });
    Axios
      .get("/users/opspms/all")
      .then((res) => {
        setGetPorjectManagerList(res.data.users);
      })

      .catch((err) => {
        // dispatch(appActions.pageLoadEnd());
      });
  }, []);


  const fetchSchedueDates = async () => {
    await Axios.get(`/deliveries/getSchedule/${profile.id}`).then((data) => {
      // console.log("/deliveries/getSchedule",data.data);
      if (data.data.data.length) {
        let wbsnumbers = data.data.data.map((number) =>
          number.WBSNumber
        );
        let wbsnumbers1 = {};
        wbsnumbers1 = data.data.data.map((number) => {
          return {
            "wbsnumber": number.WBSNumber,
            "state": number.IsClosed

          }
        });
        setisClosed(wbsnumbers1);
         let wbsnocancelled={};
        wbsnocancelled = data.data.data.map((number) => {
          return {
            "wbsnumber": number.WBSNumber,
            "state": number.IsCancel
          }
        });
        setIsCancelled(wbsnocancelled);
        // console.log(isclosed);
        // console.log("wbsnumbers1",wbsnumbers1);
        wbsnumbers = _.uniq(wbsnumbers);
        let products = wbsnumbers.map((wb) => {
          let product = _.uniq(
            data.data.data
              .filter((d) => d.WBSNumber == wb)
              .map((ele) => ele.Product)
          );
          return {
            wbs: wb,
            product: product.toString(),
          };
        });

        console.log(products);
        //   setSelectedMethodologiesWBS(products)

        let _dates = {};
        let _wbsnumbers = _.cloneDeep(wbsNumbers);
        wbsnumbers.map((wbs) => {
          // console.log(wbs);
          data.data.data
            .filter((ele) => ele.WBSNumber === wbs)
            .map((rec) => {
              let _sm = {
                product: rec?.Product,
                id: rec.id,
                QueDate: new Date(rec.QuestionnaireDate),
                FWStart: new Date(rec.FWStartDate),
                FWEnd: new Date(rec.FWEndDate),
                OPSProjectManager: rec?.OPSProjectManager,
              };
              _dates[wbs] = { ..._dates[wbs], [rec.SubmethodologyLabel]: _sm };
            });
          if (_wbsnumbers.find((wb) => wb.value == wbs)) {
          } else {
            _wbsnumbers.push({
              value: wbs,
              label: wbs,
              product: products.find((p) => p.wbs == wbs)?.product,

            });
          }
        });
        //  let dates =  data.data.data.map((d,i)=>{

        //     tempDAtes[d.SubmethodologyLabel] = {...tempDAtes[d.SubmethodologyLabel],"QueDate":new Date(d.QuestionnaireDate),"FWStart":new Date(d.FWStartDate),"FWEnd":new Date(d.FWEndDate)}

        //  })
        console.log(_dates);
        setWbsNumbers(_wbsnumbers);
        // setisClosed();
        //   setSelectedProduct(_.head(_wbsnumbers).product)
        setScheduleDates(_dates);
        setAcceptPM(_dates)
      } else {
        let wbsnumbers = _.cloneDeep(wbsNumbers);
        if (project && project?.ContractDetails.length) {
          project.ContractDetails.map((cd) =>
            cd.opportunityLineItemDetails
              .filter((ol) => ol.WBSNumber)
              .map((ol) => {
                if (
                  !wbsnumbers.filter((wb) => wb.value === ol.WBSNumber).length
                )
                  wbsnumbers.push({
                    value: ol.WBSNumber,
                    label: ol.WBSNumber,
                    product: cd.opportunityLineItemDetails
                      .filter((ele) => ele.WBSNumber === ol.WBSNumber)
                      .map((lineitem) => lineitem.MaterialID)
                      .toString(),
                  });
              })
          );
          setWbsNumbers([...wbsnumbers]);
          // setSelectedWBS(_.head(wbsnumbers).value)
          //   setSelectedProduct(_.head(wbsnumbers).product)
        }
      }

    });
  };

  console.log("scheduleDate Values", scheduleDate)
  const saveProjectManager = (acceptStatus, updateStatus) => {
    let projectManager;
    let acceptProjectManager;
    projectManager = currproj.map((i) => {
      return i.value
    })
    if(acceptStatus && !updateStatus) {
      acceptProjectManager = _.cloneDeep(acceptPM);
      let _sm = acceptProjectManager[wbsnumber.wbsnumber_sd]
      ? { ...acceptProjectManager[wbsnumber.wbsnumber_sd][wbsnumber.label_sd], OPSProjectManager: projectManager.length ? projectManager.toString() : '' }
      : { ...acceptProjectManager[wbsnumber.label_sd], OPSProjectManager: projectManager.length ? projectManager.toString() : '' };
      acceptProjectManager[wbsnumber.wbsnumber_sd] = { ...acceptProjectManager[wbsnumber.wbsnumber_sd], [wbsnumber.label_sd]: _sm };
    }
    let updateProjectManager = _.cloneDeep(scheduleDate);
    let _sm = updateProjectManager[wbsnumber.wbsnumber_sd]
      ? { ...updateProjectManager[wbsnumber.wbsnumber_sd][wbsnumber.label_sd], OPSProjectManager: projectManager.length ? projectManager.toString() : '' }
      : { ...updateProjectManager[wbsnumber.label_sd], OPSProjectManager: projectManager.length ? projectManager.toString() : '' };
    updateProjectManager[wbsnumber.wbsnumber_sd] = { ...updateProjectManager[wbsnumber.wbsnumber_sd], [wbsnumber.label_sd]: _sm };
    let filtered2;
      if(historyData.length > 0) {
      const asArray = Object.entries(scheduleDate);
      const filtered = asArray.filter(([key, value]) => historyData[0].WBSNumber === key);
      const asArray2 = Object.entries(filtered[0][1]);
      filtered2 = asArray2[0][1]
      }
      let acceptDates;
      const acceptArray = Object.entries(scheduleDate)
      const filteredAccept = acceptArray.filter(([key, value]) => wbsnumber.wbsnumber_sd === key);
      const acceptArray2 = Object.entries(filteredAccept[0][1]);
      acceptDates = acceptArray2[0][1]
      console.log("final Values", acceptDates)

      let dateFWStart = acceptDates.FWStart.toLocaleDateString()
      let dateFWEnd = acceptDates.FWEnd.toLocaleDateString()
      let dateQue = acceptDates.QueDate.toLocaleDateString()
    setEnableUpdate(false)
    dispatch(recordLoadStart());
    saveDatesWbs(updateProjectManager,filtered2,historyData,acceptProjectManager,acceptStatus,updateStatus)
    let latest_history;
    latest_history = historyData
    Axios.put(`/projects/${currentProject.ProjectId}/UpdateProjectParams`, {
      ProjectManagerEmail: currentProject.ProjectManagerEmail
    })
      .then((res) => {
        dispatch(recordLoadEnd());
        //todo: sendemail
        //use SendProjectAssignEmail
        Axios.post(
          `/utils/mail/${currentProject.ProjectId}/SendProjectAssignEmail`,
          {
            ProjectManagerEmail: currproj,
            acceptStatus: acceptStatus,
            updateStatus: updateStatus,
            historyData: latest_history ? latest_history : '',
            allDates: filtered2 ? filtered2 : '',
            wbsnumber: wbsnumber.wbsnumber_sd ? wbsnumber.wbsnumber_sd : '',
            submethodology: wbsnumber.label_sd ? wbsnumber.label_sd : '',
            dateFWStart : dateFWStart ? dateFWStart : '',
            dateFWEnd : dateFWEnd ? dateFWEnd : '',
            dateQue : dateQue ? dateQue : ''
          },
        )
          .then((res) => {
            toastr.success(
              "Mail Sent Successfully",
              "Mail Sent to Selected Project Managers"
            );
          })
          .catch((error) => {
            toastr.error(
              "Mail Sending failed",
              "Mail Sending to Selected Project Managers failed"
            );
          });
        setAssignProjectManager(false);
        setShowModal(false);
        setShowUpdateModal(false);
        let existingProjects = projects.map((it) => {
          if (it.id == currentProject.id) {
            return { ...currentProject };
          } else return { ...it };
        });
        dispatch(setProjects({ items: [...existingProjects] }));

        toastr.success(
          "Successfully Updated",
          "Assigning Project Manager Successful"
        );
      })
      .catch((error) => {
        setAssignProjectManager(false);
        dispatch(recordLoadEnd());
        toastr.error(
          "Something went wrong",
          "Assigning Project Manager Failed"
        );
      });
  };
  const onChangeHandlerUpdate = (label, wbsnumber) => {
    setWbsNumber_sd({
      wbsnumber_sd: wbsnumber,
      label_sd: label
    })
 }

  const onChangeHandlerQueDates = (e, id, sm, selectedWBS) => {
    !(_.isEqual(acceptPM[selectedWBS][sm].QueDate,e))  ? 
      setEnableUpdate(true) : setEnableUpdate(false);
    
    setEnableOnlyOneRow([...enableonlyonerow, selectedWBS])
    setAllDates({ ...allDates, QuestionnaireDate: e })
    let tempDates = _.cloneDeep(scheduleDate);
    let _historyData = _.cloneDeep(historyData);
    let rec = _historyData.find(
      (wb) => wb.WBSNumber == selectedWBS && wb.SubmethodologyLabel == sm
    );

    if (rec) {
      _historyData = _historyData.map((ele) => {
        if (
          ele.WBSNumber == selectedWBS &&
          ele.SubmethodologyLabel == sm &&
          !ele.OldQuestionnaireDate
        ) {
          return {
            ...ele,
            OldQuestionnaireDate: tempDates[selectedWBS][sm][id],
          };
        } else return ele;
      });
    } else {
      _historyData.push({
        OldQuestionnaireDate: tempDates[selectedWBS][sm][id],
        SubmethodologyLabel: sm,
        OldFWEndDate: tempDates[selectedWBS][sm].FWEnd,
        OldFWStartDate: tempDates[selectedWBS][sm][id].FWStart,
        WBSNumber: selectedWBS,
        CostingProfileId: profile.id,
        Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
      });
   }
    setHistoryData((prev) => [...prev, ..._historyData]);

    // if (e < new Date(profile.CommissionedDate)) {
    //   let _sm = tempDates[selectedWBS]
    //     ? { ...tempDates[selectedWBS][sm], [id]: null }
    //     : { ...tempDates[sm], [id]: null };
    //   tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };

    //   setScheduleDates(tempDates);

    //   toastr.info(
    //     "Questionnare Date Should be Greater than Commissioning Date"
    //   );
    // } else
    if (
      tempDates[selectedWBS][sm].FWStart &&
      tempDates[selectedWBS][sm].FWStart.getTime() <= e.getTime()) {
      // let _sm = tempDates[selectedWBS]
      //   ? { ...tempDates[selectedWBS][sm], FWStart: null, FWEnd: null  }
      //   : { ...tempDates[sm],[id]:null};
      // tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };

      // setScheduleDates(tempDates);

      toastr.info(
        "QuestionnaireDate should be less than FW Start Date"
      );
    } else {
      let tempDates = _.cloneDeep(scheduleDate);
      let temp = {
        SubmethodologyLabel: sm,
        QuestionnaireDate: new Date(e),
        OldQuestionnaireDate: tempDates[selectedWBS][sm][id],
        WBSNumber: selectedWBS,
        CostingProfileId: profile.id,
        Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
      };

      let _historyData = _.cloneDeep(historyData);
      let rec = _historyData.find(
        (wb) => wb.WBSNumber == selectedWBS && wb.SubmethodologyLabel == sm
      );
      if (rec) {
        _historyData = _historyData.map((ele) => {
          if (
            ele.WBSNumber == selectedWBS &&
            ele.SubmethodologyLabel == sm &&
            ele.OldQuestionnaireDate
          ) {
            return { ...ele, QuestionnaireDate: e };
          } else if (
            ele.WBSNumber == selectedWBS &&
            ele.SubmethodologyLabel == sm &&
            !ele.OldQuestionnaireDate
          ) {
            return {
              ...ele,
              QuestionnaireDate: e,
              OldQuestionnaireDate: tempDates[selectedWBS][sm][id],
            };
          } else return ele;
        });
      } else {
        _historyData.push(temp);
      }
      console.log(_historyData);

      setHistoryData(_historyData);
      let _sm = tempDates[selectedWBS]
        ? { ...tempDates[selectedWBS][sm], [id]: e }
        : { ...tempDates[sm], [id]: e };
      tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };
      // tempDates[sm] = {...tempDates[sm],WBS:selectedWBS}
      console.log(tempDates);
      setScheduleDates(tempDates);
    }
  };

  const onChangeHandlerFWStart = (e, id, sm, selectedWBS) => {
    setEnableOnlyOneRow([...enableonlyonerow, selectedWBS])
    setAllDates({ ...allDates, FWStartDate: e })
    !(_.isEqual(acceptPM[selectedWBS][sm].FWStart,e))  ? 
      setEnableUpdate(true) : setEnableUpdate(false);
    let tempDates = _.cloneDeep(scheduleDate);

    let _historyData = _.cloneDeep(historyData);
    let rec = _historyData.find(
      (wb) => wb.WBSNumber == selectedWBS && wb.SubmethodologyLabel == sm
    );
    if (rec) {
      _historyData = _historyData.map((ele) => {
        if (
          ele.WBSNumber == selectedWBS &&
          ele.SubmethodologyLabel == sm &&
          !ele.OldFWStartDate
        ) {
          return { ...ele, OldFWStartDate: tempDates[selectedWBS][sm][id] };
        } else return ele;
      });
    } else {
      _historyData.push({
        OldFWStartDate: tempDates[selectedWBS][sm][id],
        SubmethodologyLabel: sm,
        OldFWEndDate: tempDates[selectedWBS][sm].FWEnd,

        WBSNumber: selectedWBS,
        CostingProfileId: profile.id,
        Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
      });
    }

    setHistoryData(_historyData);

    if (tempDates[selectedWBS] && tempDates[selectedWBS][sm].QueDate) {
      if (e.getTime() <= tempDates[selectedWBS][sm].QueDate.getTime()) {
        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS][sm], [id]: null }
          : { ...tempDates[sm], [id]: null };
        tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };

        setScheduleDates(tempDates);
        toastr.info(
          "FW Start Date should be Greater Than QuestionnaireDate"
        );
      }

      else if (
        tempDates[selectedWBS][sm].FWEnd &&
        tempDates[selectedWBS][sm].FWEnd <= e
      ) {
        // let _sm = tempDates[selectedWBS]
        //   ? { ...tempDates[selectedWBS][sm],FWEnd:null}
        //   : { ...tempDates[sm], FWStart: null, };
        // tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };

        // setScheduleDates(tempDates);

        toastr.info("FW Start Date Should be less than FW End Date");
      }
      else {
        let tempDates = _.cloneDeep(scheduleDate);
        let temp = {
          SubmethodologyLabel: sm,
          FWStartDate: new Date(e),
          OldFWStartDate: tempDates[selectedWBS][sm][id],
          WBSNumber: selectedWBS,
          CostingProfileId: profile.id,
          Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
        };
        let _historyData = _.cloneDeep(historyData);
        let rec = _historyData.find(
          (wb) => wb.WBSNumber == selectedWBS && wb.SubmethodologyLabel == sm
        );

        if (rec) {
          _historyData = _historyData.map((ele) => {
            if (
              ele.WBSNumber == selectedWBS &&
              ele.SubmethodologyLabel == sm &&
              ele[`Old${id}Date`]
            ) {
              return { ...ele, FWStartDate: e };
            } else if (
              ele.WBSNumber == selectedWBS &&
              ele.SubmethodologyLabel == sm &&
              !ele[`Old${id}Date`]
            ) {
              return {
                ...ele,
                FWStartDate: e,
                OldFWStartDate: tempDates[selectedWBS][sm][id],
              };
            } else return ele;
          });
        } else {
          _historyData.push(temp);
        }

        setHistoryData(_historyData);
        console.log(_historyData);

        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS][sm], [id]: e }
          : { ...tempDates[sm], [id]: e };

        tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };

        setScheduleDates(tempDates);
      }
    } else {
      let _sm = tempDates[selectedWBS]
        ? { ...tempDates[selectedWBS][sm], [id]: null }
        : { ...tempDates[sm], [id]: null };
      tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };

      setScheduleDates(tempDates);
      toastr.error("Please Select Questionnaire Date");
    }
    console.log(tempDates);
  };

  const onChangeHandlerFwEnd = (e, id, sm, selectedWBS) => {
    setEnableOnlyOneRow([...enableonlyonerow, selectedWBS])
    setAllDates({ ...allDates, FWEndDate: e })
    !(_.isEqual(acceptPM[selectedWBS][sm].FWEnd,e))  ? 
    setEnableUpdate(true) : setEnableUpdate(false);
    let tempDates = _.cloneDeep(scheduleDate);

    let _historyData = _.cloneDeep(historyData);
    let rec = _historyData.find(
      (wb) => wb.WBSNumber == selectedWBS && wb.SubmethodologyLabel == sm
    );
    if (rec) {
      _historyData = _historyData.map((ele) => {
        if (
          ele.WBSNumber == selectedWBS &&
          ele.SubmethodologyLabel == sm &&
          !ele[`Old${id}Date`]
        ) {
          return { ...ele, OldFWEndDate: tempDates[selectedWBS][sm][id] };
        } else return ele;
      });
    } else {
      _historyData.push({
        OldFWEndDate: tempDates[selectedWBS][sm][id],
        SubmethodologyLabel: sm,
        WBSNumber: selectedWBS,
        CostingProfileId: profile.id,
        Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,
      });
    }
    setHistoryData(_historyData);

    if (tempDates[selectedWBS] && tempDates[selectedWBS][sm].FWStart) {
      if (e > tempDates[selectedWBS][sm].FWStart) {
        let tempDates = _.cloneDeep(scheduleDate);
        let temp = {
          SubmethodologyLabel: sm,
          FWEndDate: new Date(e),
          OldFWEndDate: tempDates[selectedWBS][sm][id],
          WBSNumber: selectedWBS,
          CostingProfileId: profile.id,
          Product: wbsNumbers.find((wb) => wb.value == selectedWBS).product,

        };

        let _historyData = _.cloneDeep(historyData);
        let rec = _historyData.find(
          (wb) => wb.WBSNumber == selectedWBS && wb.SubmethodologyLabel == sm
        );
        if (rec) {
          _historyData = _historyData.map((ele) => {
            if (
              ele.WBSNumber == selectedWBS &&
              ele.SubmethodologyLabel == sm &&
              ele[`Old${id}Date`]
            ) {
              return { ...ele, FWEndDate: e };
            } else if (
              ele.WBSNumber == selectedWBS &&
              ele.SubmethodologyLabel == sm &&
              !ele[`Old${id}Date`]
            ) {
              return {
                ...ele,
                FWEndDate: e,
                OldFWEndDate: tempDates[selectedWBS][sm][id],
              };
            } else return ele;
          });
        } else {
          _historyData.push(temp);
        }

        setHistoryData(_historyData);
        console.log(_historyData);

        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS][sm], [id]: e }
          : { ...tempDates[sm], [id]: e };
        tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };

        setScheduleDates(tempDates);
      } else {
        let _sm = tempDates[selectedWBS]
          ? { ...tempDates[selectedWBS][sm], [id]: null }
          : { ...tempDates[sm], [id]: null };
        tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };

        setScheduleDates(tempDates);
        toastr.info("FW End Date should be Greater Than FW Start Date");
      }
    } else {
      let _sm = tempDates[selectedWBS]
        ? { ...tempDates[selectedWBS][sm], [id]: null }
        : { ...tempDates[sm], [id]: null };
      tempDates[selectedWBS] = { ...tempDates[selectedWBS], [sm]: _sm };

      setScheduleDates(tempDates);
      toastr.error("Please Select FW Start Date");
      console.log(tempDates);
    }
  };

  const saveDatesWbs = (updateProjectManager,filtered2,historyData,acceptProjectManager, acceptStatus, updateStatus) => {
    let projectManager;
    projectManager = currproj.map((i) => {
    return i.value
  })
  historyData = historyData.filter((i) => (i.WBSNumber == wbsnumber.wbsnumber_sd && i.SubmethodologyLabel == wbsnumber.label_sd))
    Axios.post("costingProfiles/historyDateAdjustments", historyData, {
      headers: { "auth-token": localStorage.getItem("auth-token") },
    })
      .then((res) => {
        toastr.success("Dates History Saved");
      })
      .catch((err) => {
        console.log("Dates History Saving Failed", err);
      });
    dispatch(recordLoadStart());
    if (
      wbsNumbers
        .map((number) => number.value)
        .every((wbs) =>
          selectedMethodologies
            .filter((m) => _.includes(m.wbsnumbers, wbs))
            .every(
              (sm) =>
                scheduleDate[wbs] &&
                scheduleDate[wbs][sm.Label] &&
                scheduleDate[wbs][sm.Label]?.QueDate &&
                scheduleDate[wbs][sm.Label]?.FWStart &&
                scheduleDate[wbs][sm.Label]?.FWEnd
            )
        )
    ) {
      let data = [];
      Object.keys(scheduleDate).map((wb) => {
        if(wb == wbsnumber.wbsnumber_sd) {
        Object.keys(scheduleDate[wb]).map((sd) => {
          if(sd == wbsnumber.label_sd) {
          if(historyData && historyData.length > 0 && filtered2 && acceptStatus && !updateStatus) {
          data.push({
            QuestionnaireDate: acceptProjectManager[wb][sd].QueDate,
            FWStartDate: acceptProjectManager[wb][sd].FWStart,
            FWEndDate: acceptProjectManager[wb][sd].FWEnd,
            CostingProfileId: profile.id,
            // "WaveNumber":"1",
            // "WaveSpecId":currentWaveSpec.id,
            SubmethodologyLabel: sd,
            WBSNumber: wb,
            Product: wbsNumbers.find((rec) => rec.value === wb)?.product
              ? wbsNumbers.find((rec) => rec.value === wb).product
              : "",
            id: acceptProjectManager[wb][sd].id,
            OPSProjectManager: acceptProjectManager[wb][sd].OPSProjectManager ? acceptProjectManager[wb][sd].OPSProjectManager : ''
          })
        }
        else {
        data.push({
          QuestionnaireDate: scheduleDate[wb][sd].QueDate,
          FWStartDate: scheduleDate[wb][sd].FWStart,
          FWEndDate: scheduleDate[wb][sd].FWEnd,
          CostingProfileId: profile.id,
          // "WaveNumber":"1",
          // "WaveSpecId":currentWaveSpec.id,
          SubmethodologyLabel: sd,
          WBSNumber: wb,
          Product: wbsNumbers.find((rec) => rec.value === wb)?.product
            ? wbsNumbers.find((rec) => rec.value === wb).product
            : "",
          id: scheduleDate[wb][sd].id,
          OPSProjectManager: updateProjectManager ? updateProjectManager[wb][sd].OPSProjectManager ? updateProjectManager[wb][sd].OPSProjectManager : '' : ''
        })
      }
        }})
    }});
      Axios.post("deliveries/shcedule", data)
        .then((res) => {
          setScheduleDate(false);
          toastr.success("Dates Adjustment Success !!");
          dispatch(recordLoadEnd());
        })
        .catch((err) => {
          toastr.error(err);
          dispatch(recordLoadEnd());
        });
    } else {
      toastr.error("Please fill all the dates");
      dispatch(recordLoadEnd());
    }
  };

  const saveDates = () => {
    Axios.post("costingProfiles/historyDateAdjustments", historyData, {
      headers: { "auth-token": localStorage.getItem("auth-token") },
    })
      .then((res) => {
        toastr.success("Dates History Saved");
      })
      .catch((err) => {
        console.log("Dates History Saving Failed", err);
      });

    dispatch(recordLoadStart());
    if (
      wbsNumbers
        .map((number) => number.value)
        .every((wbs) =>
          selectedMethodologies
            .filter((m) => _.includes(m.wbsnumbers, wbs))
            .every(
              (sm) =>
                scheduleDate[wbs] &&
                scheduleDate[wbs][sm.Label] &&
                scheduleDate[wbs][sm.Label]?.QueDate &&
                scheduleDate[wbs][sm.Label]?.FWStart &&
                scheduleDate[wbs][sm.Label]?.FWEnd
            )
        )
    ) {
      let data = [];
      Object.keys(scheduleDate).map((wb) =>
        Object.keys(scheduleDate[wb]).map((sd) =>
          data.push({
            QuestionnaireDate: scheduleDate[wb][sd].QueDate,
            FWStartDate: scheduleDate[wb][sd].FWStart,
            FWEndDate: scheduleDate[wb][sd].FWEnd,
            CostingProfileId: profile.id,
            // "WaveNumber":"1",
            // "WaveSpecId":currentWaveSpec.id,
            SubmethodologyLabel: sd,
            WBSNumber: wb,
            Product: wbsNumbers.find((rec) => rec.value === wb)?.product
              ? wbsNumbers.find((rec) => rec.value === wb).product
              : "",
            id: scheduleDate[wb][sd].id,
            OPSProjectManager: scheduleDate[wb][sd].OPSProjectManager ? scheduleDate[wb][sd].OPSProjectManager : ''
          })
        )
      );

      console.log(data);
      Axios.post("deliveries/shcedule", data)
        .then((res) => {
          setScheduleDate(false);
          toastr.success("Dates Adjustment Success !!");
          dispatch(recordLoadEnd());
        })
        .catch((err) => {
          toastr.error(err);
          dispatch(recordLoadEnd());
        });
    } else {
      toastr.error("Please fill all the dates");
      dispatch(recordLoadEnd());
    }
  };

  const getWorkingDays = (date) => {
    let commCountry = codeLabels.FieldingCountriesOptions.find(
      (fc) => fc.Code == project.CommissioningCountry
    );
    let days = 4;
    let weekendFirstDay = commCountry.WeekStartDay;
    let weekendSecondDay = commCountry.WeekEndDay;
    date = moment(date); // use a clone
    if (date.isoWeekday() == weekendFirstDay) date = date.subtract(1, "days");
    if (date.isoWeekday() == weekendSecondDay) date = date.subtract(2, "days");

    while (days > 0) {
      // if (date.isoWeekday() === weekendFirstDay)
      //     date = date.subtract(3, 'days');
      // else if (date.isoWeekday() === weekendSecondDay)
      //     date = date.subtract(2, 'days');

      date = date.add(1, "days");
      // decrease "days" only if it's a weekday.
      //Dates enum
      // MONDAY: 1,
      // TUESDAY: 2,
      // WEDNESDAY: 3,
      // THURSDAY: 4,
      // FRIDAY: 5,
      // SATURDAY: 6,
      // SUNDAY: 7
      if (
        date.isoWeekday() !== weekendFirstDay &&
        date.isoWeekday() !== weekendSecondDay
      ) {
        days -= 1;
      }
    }
    return date.format("YYYY-MM-DD");
  };
  const disableDatePicker = (wbsno) => {

    let r = false;
    let c= false;
    isclosed.forEach((val, index) => {
      // console.log(val,wbsno);
      if (val.wbsnumber == wbsno) {
        r = val.state;

      }
    })
    isCancelled.forEach((val,index)=>{
      if (val.wbsnumber == wbsno) {
        c = val.state;

      }
    })
    if(r==true || c==true){
      return true;
    }
    else{
      return false;
    }
    // console.log(res);
  };
  // console.log(disableDatePicker("45.89.87"));
  return (
    <div>
      {selectedMethodologies.length && (
        <Container>
          <Card className="ml-2 mr-2 mb-2">
            <CardHeader>
              <Row>
                <Col xs="9">
                  <CardTitle className="text-uppercase">
                    Sub Methodology Level Schedule
                  </CardTitle>
                </Col>
              </Row>
              <h5>
                <Link
                  className="float-right"
                  onClick={() => {
                    fetch();
                    setHistoryOpen(true);
                  }}
                >
                  View History
                </Link>
              </h5>{" "}
            </CardHeader>
            <CardBody>
              {/* {JSON.stringify(historyDatatoDisplay)} */}
              <table className="table table-stripped table-bordered table-responsive">
                <tr>
                  <th>WBS Number</th>
                  <th>Product</th>
                  <th>Sub Methodologies</th>
                  <th>
                    Final Questionnaire Delivery Date in CINTRAL 
                  </th>
                  <th>Planned FW Start Date in CINTRAL</th>
                  <th>
                    Planned FW End Date in CINTRAL
                  </th>
                  <th>
                    Actions
                  </th>
                  <th>
                    Assigned Project Manager
                  </th>
                </tr>
                {selectedMethodologies.map((sm) =>
                  wbsNumbers.map(
                    (wb) =>
                      _.includes(sm.wbsnumbers, wb.value) && (
                        <tr>
                          <td>{wb.label}</td>
                          <td>{wb.product}</td>
                          <td>
                            <Label>
                              {sm.Label}
                            </Label>
                          </td>
                          <td>

                            {" "}
                            <DatePicker
                              // disabled={
                              //     df.DisablingVal||disableforCS
                              // }
                              // locale="en-GB"
                              disabled={(disableDatePicker(wb.value) || userRecord ? !(userRecord.IsClientService) : false)}
                              className="form-control"
                              selected={
                                scheduleDate &&
                                  scheduleDate[wb.value] &&
                                  scheduleDate[wb.value][sm.Label]?.QueDate
                                  ? scheduleDate[wb.value][sm.Label]?.QueDate
                                  : ""
                              }
                              onChange={(e) =>
                                onChangeHandlerQueDates(
                                  e,
                                  "QueDate",
                                  sm.Label,
                                  wb.value
                                )
                              }
                              dateFormat="dd-MM-yyyy"
                              placeholderText="dd-mm-yyyy"
                            />
                          </td>
                          <td>
                            <DatePicker
                              // locale="en-GB"
                              className="form-control"
                              selected={
                                scheduleDate &&
                                  scheduleDate[wb.value] &&
                                  scheduleDate[wb.value][sm.Label]?.FWStart
                                  ? scheduleDate[wb.value][sm.Label]?.FWStart
                                  : ""
                              }
                              disabled={disableDatePicker(wb.value)}
                              onChange={(e) =>
                                onChangeHandlerFWStart(
                                  e,
                                  "FWStart",
                                  sm.Label,
                                  wb.value
                                )
                              }
                              dateFormat="dd-MM-yyyy"
                              placeholderText="dd-mm-yyyy"
                            />
                          </td>
                          <td>
                            <DatePicker
                              // disabled={
                              //     df.DisablingVal||disableforCS
                              // }
                              // locale="en-GB"
                              className="form-control"
                              selected={
                                scheduleDate &&
                                  scheduleDate[wb.value] &&
                                  scheduleDate[wb.value][sm.Label]?.FWEnd
                                  ? scheduleDate[wb.value][sm.Label]?.FWEnd
                                  : ""
                              }
                              disabled={disableDatePicker(wb.value)}
                              onChange={(e) =>
                                onChangeHandlerFwEnd(
                                  e,
                                  "FWEnd",
                                  sm.Label,
                                  wb.value
                                )
                              }
                              dateFormat="dd-MM-yyyy"
                              placeholderText="dd-mm-yyyy"
                            />
                          </td>
                          <td className="d-flex justify-content-center">
                            <Button
                              className="mr-2"
                              color="primary"
                              disabled={userRecord ? !(userRecord.IsOpsProjectManager) : false}
                              onClick={() => {
                                setCurrProj([]);
                                setShowModal(true)
                                onChangeHandlerUpdate(sm.Label,
                                  wb.value)
                              }}
                            >
                              Accept
                            </Button>
                            <Button
                              disabled={!enableonlyonerow.includes(wb.value) || !enableUpdate}
                              onClick={() => {
                                setCurrProj([]);
                                setShowUpdateModal(true)
                                onChangeHandlerUpdate(sm.Label,
                                  wb.value)
                              }}
                              color="primary"
                            >
                              Update
                            </Button>
                          </td>
                          <td width = '25px'>
                            {scheduleDate &&
                              scheduleDate[wb.value] &&
                              scheduleDate[wb.value][sm.Label]?.OPSProjectManager
                              ? scheduleDate[wb.value][sm.Label]?.OPSProjectManager
                              : ""}
                          </td>
                        </tr>
                      )
                  )
                )}
              </table>
            </CardBody>
          </Card>
          <Modal
            isOpen={isHistoryOpen}
            toggle={() => setHistoryOpen(false)}
            className="modal-xl"
            centered={true}
          >
            <ModalHeader>
              {/* <img alt="Nielsen Logo" src={logo} className="modallogo" /> */}
              <div>
                <span className="modaltitle modalops">
                  Date Adjustment History
                </span>
              </div>
            </ModalHeader>
            <ModalBody>
              {historyDatatoDisplay.length && (
                <div
                  style={{
                    maxWidth: "inherit",
                    maxHeight: "400px",
                    overflow: "auto",
                    overflowX: "auto",
                  }}
                >
                  <table className="table table-stripped table-bordered">
                    <tr>
                      <th>WBS Number</th>
                      <th>Product</th>
                      <th>SubMethodology</th>
                      <th>Old QuestionnaireDate</th>
                      <th>New QuestionnaireDate</th>
                      <th>Old FWStartDate</th>
                      <th>New FWStartDate</th>
                      <th>Old FWEndDate</th>
                      <th>New FWEndDate</th>
                      <th>Updated By</th>
                      <th>Updated At</th>
                    </tr>

                    {historyDatatoDisplay.map((hisData) => (
                      <tr>
                        <td>{hisData.WBSNumber}</td>
                        <td>{hisData.Product ? hisData.Product : ""}</td>
                        <td>{hisData.SubmethodologyLabel}</td>
                        <td>
                          {hisData.OldQuestionnaireDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldQuestionnaireDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {hisData.QuestionnaireDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.QuestionnaireDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {hisData.OldFWStartDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldFWStartDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {hisData.FWStartDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.FWStartDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {hisData.OldFWEndDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.OldFWEndDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {hisData.FWEndDate ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.FWEndDate)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>

                        <td>{hisData.UpdatedBY ? hisData.UpdatedBY : ""}</td>
                        <td>
                          {hisData.UpdatedAT ? (
                            <DatePicker
                              disabled={true}
                              selected={new Date(hisData.UpdatedAT)}
                            ></DatePicker>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              )}
            </ModalBody>
          </Modal>
          <Button
            onClick={() => {
              setScheduleDate(false);
            }}
            className="float-right"
            color="primary"
          >
            Close
          </Button>

          <Button
            className="float-right mr-2"
            color="primary"
            onClick={() => saveDates()}
          >
            {recordloading ? (
              <FontAwesomeIcon
                class="saving-btn icon-costingprofile"
                icon={faSpinner}
                fixedWidth
              />
            ) : null}
            Save
          </Button>
          <Modal

            isOpen={showModal}

            toggle={() => setShowModal(false)}

            className="modal-md"

            centerd={true}

          >

            <ModalHeader

              toggle={() => setShowModal(!showModal)}

            >

              {/* <img src={logo} className="modallogo" /> */}



            </ModalHeader>



            <ModalBody>



              <Row>

                <Col>

                  <div>

                    <span className="modaltitle modaltitlemanager">

                      Select Project Manager

                    </span>

                  </div>

                </Col>

              </Row>

              <Select

                className="react-select-container mb-3 lg-12 md-12"

                classNamePrefix="react-select"

                options={getprojectmanagerlist?.map((item) => {

                  return { value: item.Email, label: item.Email };

                })}

                isSearchable

                value={

                  currproj?.length

                    ? currproj

                    : []

                }

                onChange={(e) => {



                  setCurrProj(e);
                }}

                isMulti

              />



            </ModalBody>

            <ModalFooter>

              <Row className="justify-content-end">

                <Button

                  color="primary"

                  disabled={

                    currproj?.length == 0 ||

                    currproj == null

                  }


                  onClick={() => saveProjectManager(true, false)}



                >

                  Save

                  {/* {app.recordloading ? (

<Spinner size="small" color="#495057" />

) : null} */}

                </Button>

                <Button

                  className="ml-2"

                  color="secondary"

                  onClick={() => setShowModal(false)}

                >

                  Cancel

                </Button>

              </Row>

            </ModalFooter>

          </Modal>

          <Modal

            isOpen={showUpdateModal}

            toggle={() => setShowUpdateModal(false)}

            className="modal-md"

            centerd={true}

          >

            <ModalHeader

              toggle={() => setShowUpdateModal(!showUpdateModal)}

            >

              {/* <img src={logo} className="modallogo" /> */}



            </ModalHeader>



            <ModalBody>



              <Label className={inputValue ? "" : "text-danger"}>

                Reason for Update:<sup>*</sup>

              </Label>

              <Input type='text' placeholder="" required

                onChange={(e) => {

                  setInputValue(e.target.value)

                }}

              />


              <Row>

                <Col>

                  <div>

                    <span className="modaltitle modaltitlemanager">

                      Select Project Manager

                    </span>

                  </div>

                </Col>

              </Row>

              <Select

                className="react-select-container mb-3 lg-12 md-12"

                classNamePrefix="react-select"

                options={getprojectmanagerlist?.map((item) => {

                  return { value: item.Email, label: item.Email };

                })}

                isSearchable

                value={

                  currproj?.length

                    ? currproj

                    : []

                }

                onChange={(e) => {

                  setCurrProj(e);
                }}

                isMulti

              />



            </ModalBody>

            <ModalFooter>

              <Row className="justify-content-end">

                <Button

                  color="primary"
                  disabled={

                    currproj?.length == 0 ||

                    currproj == null || inputValue == ""

                  }

                  onClick={() => saveProjectManager(false, true)}



                >

                  Update

                  {/* {app.recordloading ? (

        <Spinner size="small" color="#495057" />

      ) : null} */}

                </Button>

                <Button

                  className="ml-2"

                  color="secondary"

                  onClick={() => {

                    setShowUpdateModal(false);

                    setEnableUpdate(false)
                  }}

                >


                  Cancel

                </Button>

              </Row>

            </ModalFooter>

          </Modal>


        </Container>
      )}
    </div>
  );
}

export default ProjectScheduleAdjustment;
