import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getMultiOptions } from "../../utils/codeLabels";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faCopy, faPlus, faPlusCircle, faTimesCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import Select from "react-select";
import update from 'immutability-helper';
import './common.css';
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";

import {
    AvForm,

    AvField,
    AvRadioGroup,
    AvInput,
    AvRadio
} from "availity-reactstrap-validation";
import {
    Col,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    FormFeedback,
    FormGroup,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledTooltip,
    UncontrolledCollapse,
    Table,
    CustomInput,
    Button,
    Container,
    Form,
    FormText,
    Input,
    InputGroup,
    InputGroupAddon,
    Label
} from "reactstrap";
import { HashLink } from 'react-router-hash-link';
import Settings from "../../components/Settings";
import { setDate } from "date-fns/fp";
import { element, func } from "prop-types";
import { updateProfile } from "../../redux/actions/currentCostingActions";
import logo from "../../assets/img/avatars/modal-logo.png"; 

const Start = ({ profile, indxFC, methodology, indxSM, updateProject,disableFields }) => {
    const dispatch = useDispatch()
    const [modelState, setModalState] = useState(false);
    const [copyOption, setCopyOption] = useState({ setup: null, responsibility: true });
    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );
    function InfoChange(e, index, att) {
        const copyJsonData = _.cloneDeep(methodology.Responsibilities);
        let key = Object.keys(copyJsonData)[index];

        copyJsonData[key]["enum"].forEach(x => {
            if (e.target.name != "otherSpecifyText") {
                if (e.target.name != x.label)
                    x.selected = false;
                else
                    x.selected = e.target.checked;
            }
        });

        let aa = Object.keys(methodology.Responsibilities).length > 20 ? 23 : 13;
        if (index >= aa) {

            if (e.target.name != "otherSpecifyText") {
                if (e.target.name != "Not Applicable" && !att.selected && key.indexOf("otherResponsibilities") >= 0)
                    copyJsonData[key].showText = true;
                else {

                    copyJsonData[key].showText = false;
                }
            }
            else {
                copyJsonData[key].value = e.target.value;
            }

        }
        const profileTemp = update(profile, {
            CountrySpecs: {
                [indxFC]: {
                    MethodologySpecs:
                    {
                        [indxSM]:
                        {
                            Responsibilities:
                                { $set: copyJsonData }
                        }
                    }
                }
            }
        });
        const tempProfiles = project.CostingProfiles.map(cp => {
            if (cp.id == profileTemp.id)
                return { ..._.cloneDeep(profileTemp) }
            return { ..._.cloneDeep(cp) }
        });
        dispatch(updateProfile(_.cloneDeep(profileTemp)))
        dispatch(updateProject({ CostingProfiles: tempProfiles }));
    }

    function CopyData() {
        const countrySpecsClone = _.cloneDeep(profile.CountrySpecs);
        const methodologyClone = _.cloneDeep(profile.CountrySpecs[indxFC].MethodologySpecs[indxSM]);
        countrySpecsClone.forEach((fc, indexFC) => {
            fc.MethodologySpecs.filter((sm, indexSM) => Object.keys(sm.Responsibilities).length == Object.keys(methodology.Responsibilities).length && (indexFC != indxFC || (indexFC == indxFC && indexSM != indxSM))).forEach((sm, indexSM) => {
                sm.Responsibilities = _.cloneDeep(methodology.Responsibilities);
            });
        });
        //new code to copy setup and delivery data also
        if (copyOption.setup) {
            countrySpecsClone.forEach((fc, indexFC) => {
                fc.MethodologySpecs.filter((sm, indexSM) => sm.OpResourcesVariables.length == methodologyClone.OpResourcesVariables.length).forEach((sm, indexSM) => {
                    if (indexFC == indxFC && sm.label == methodologyClone.label)
                        return;
                    sm.OpResourcesVariables.forEach((v, indexV) => {
                        if (methodologyClone.OpResourcesVariables.find(vv => vv.label == v.label)) {
                            v.value = _.cloneDeep(methodologyClone.OpResourcesVariables.filter(vv => vv.label == v.label)[0].value);
                            v.disabled = _.cloneDeep(methodologyClone.OpResourcesVariables.filter(vv => vv.label == v.label)[0].disabled);
                        }
                    });
                });
            });
        }
        //new code to copy setup and delivery data also
        const profileTemp = update(profile, {
            CountrySpecs: { $set: countrySpecsClone }
        });
        const tempProfiles = project.CostingProfiles.map(cp => {
            if (cp.id == profileTemp.id)
                return { ..._.cloneDeep(profileTemp) }
            return { ..._.cloneDeep(cp) }
        });
        dispatch(updateProfile(_.cloneDeep(profileTemp)))
        dispatch(updateProject({ CostingProfiles: tempProfiles }));
        toastr.success("Success", "Data Copied Successfully");
    }

    return (
        <React.Fragment>
            <AvForm encType="multipart/form-data">
                {methodology.Responsibilities ? <Card className="ml-2 mr-2">
                    <CardHeader>
                        <Row>
                            <Col style={{ display: "flex", justifyContent: "space-between" }}>
                                <CardTitle id="header_responsibility" className="mb-0">
                                    Responsibilities
                                </CardTitle>
                                <FontAwesomeIcon
                                    title="Copy Data"
                                    className="align-middle mr-2 addButton ic-hover"
                                    icon={!disableFields?faCopy:""}
                                    fixedWidth
                                    onClick={() => {
                                        setModalState(true);
                                    }}
                                    style={{ cursor: "pointer" }}
                                />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <Table size="sm" bordered style={{ backgroundColor: "white", width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>
                                            </th>
                                            <th>
                                                <span> Commissioning Country</span>
                                            </th>
                                            <th>
                                                <span> Fielding Markets</span>
                                            </th>
                                            <th>
                                                <span> Not Applicable</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(methodology.Responsibilities).map((tr, indexTR) =>
                                            <tr key={'tr' + indexTR}>
                                                <td>
                                                    <span>{methodology.Responsibilities[tr].title}</span>
                                                    {methodology.Responsibilities[tr].showText &&
                                                        <input
                                                            placeholder=""
                                                            type="text"
                                                            name="otherSpecifyText"
                                                            style={{ width: '100%', marginTop: '0.2rem' }}
                                                            value={methodology.Responsibilities[tr].value || ''}
                                                            disabled={methodology.NotApplicable|| disableFields}
                                                            onChange={(e) => InfoChange(e, indexTR)}
                                                        />
                                                    }
                                                </td>
                                                {methodology.Responsibilities[tr]["enum"].map((att, indexATT) =>
                                                    <td key={'tr' + indexTR + "_att_" + indexATT} style={{ textAlign: 'center' }}>
                                                        {att.selected}
                                                        <input name={att.label} checked={att.selected} type="checkbox" disabled={methodology.NotApplicable||disableFields} onChange={(e) => {
                                                            InfoChange(e, indexTR, att);
                                                        }}></input>
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card> : null}
            </AvForm>

            <Modal isOpen={modelState} toggle={() => setModalState(!modelState)} centered size={"xm"}>
                <ModalHeader toggle={() => setModalState(!modelState)}>
                    {/* <img src={logo}  className="modallogo"/> */}
                </ModalHeader>
                <div>
                    <span className="modaltitle">{'Copying RFQ data...'}</span>
                </div>
                <ModalBody className="m-3" style={{ minHeight: '60px' }}>
                    <Row>
                        <Col md={12}>
                            <h4>Do you want to copy Responsibility data to other markets?</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <input disabled name={'copyResponsibility'} checked={true} type="checkbox" onChange={(e) => {
                                setCopyOption({
                                    ...copyOption, responsibility: e.target.checked
                                });
                            }}></input>
                            <span style={{ marginLeft: '10px' }}>Copy Responsibilities</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <input name={'copySetup'} disabled={disableFields} checked={copyOption['setup']} type="checkbox" onChange={(e) => {
                                setCopyOption({
                                    ...copyOption, setup: e.target.checked
                                });
                            }}></input>
                            <span style={{ marginLeft: '10px' }}>Copy Setup & Delivery</span>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        CopyData();
                        setModalState(false);
                        setCopyOption({
                            ...copyOption, setup: false
                        });
                    }}>
                        Yes
                    </Button>
                    <Button color="secondary" onClick={() => {
                        setModalState(false);
                        setCopyOption({
                            ...copyOption, setup: false
                        });
                    }}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};
export default Start;
