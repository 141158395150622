import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import { Link, useHistory } from "react-router-dom";
import * as  sheetCall from "../../utils/msSheetAPiCall";
import { SET_VENDOR_FLAG } from "../../redux/actions/vendor/ActionTypes";
import {
	Card,
	CardBody,
	Container,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
	Button,
	Row,
	Col,
	Badge,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { MinusCircle, PlusCircle } from "react-feather";
import { getLabel } from "../../utils/codeLabels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import Spinner from "../../components/Spinner";
import {
	faPen,
	faCalendarAlt,
	faFileInvoiceDollar,
	faExclamationTriangle,
	faFileExcel,
	faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import UploadedProjectModal from "../../components/UploadedProjectModal";
import EditFill from "../../assets/icons/Connected-System-Icons/Tools/Edit-fill";
import Calendar from "../../assets/icons/Connected-System-Icons/Data-&-Math/Calendar";
import Excel from "../../assets/icons/Connected-System-Icons/Objects/Excel";
import Document from "../../assets/icons/Connected-System-Icons/Objects/Document";
import * as currentProjectActions from "../../redux/actions/currentProjectActions"
const ExpandableRowsTable = ({
	tableData,
	tableColumns,
	getCosting,
	project,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const app = useSelector(({ app }) => app);
	const data = tableData; // changed
	useEffect(() => {
		data.map((dt) => {
	// 		if (!dt.ProfileName.includes(dt.ProfileNumber)) {
	// 			// let replaceProfileName = dt.ProfileName.replaceAll('Copy of', '')
	// 			// if (replaceProfileName.includes("Master Costing Profile")) {
	// 			// 	dt.ProfileName = `${replaceProfileName.replaceAll('1', '')} ${dt.ProfileNumber}`
	// 			// } else 
				if (dt.ProfileName.includes("Copy of")) {
					dt.ProfileName = `Master Costing Profile ${dt.ProfileNumber}`
				}
	// 		}
		})
	}, [])
	data["project"] = project;
	const[uploadProject,setUploadProject]=useState(false);
	let rowData = data;
	const currentCostingProfile = useSelector(
		({ currentCosting }) => currentCosting.currentCostingProfile
	);
	const [deleteProfileModal, setDeleteProfileModal] = useState(false);
	const [deletableProfile, setDeletableProfile] = useState({});
	const codeLabels = useSelector(({ codeLabels }) => codeLabels);
	let pacificData = _.head(codeLabels.PacificCountriesList).Label;
	let arr = pacificData.split(",");
	const [pacificMarkets] = useState(arr);

	const overheadsPercentage =
		currentCostingProfile?.ProfileSetting?.PercentOverhead * 100;

	const getCostingTypes = (row) => {
		let costingTypes = []
		row?.CountrySpecs?.map(cs => cs.MethodologySpecs.length &&
			cs.MethodologySpecs.map(ms =>
				costingTypes.push(getLabel("CostingType", ms.CostingType?.toString()))));
		return costingTypes.length ? [...new Set(costingTypes)].join(', ') : "Not Selected";
	}
	const handleJSONData = (data) => {
		while (typeof data === "string") {
			data = JSON.parse(data);
		  }
		return data;
	  };

	const profileSpecs = (row) => {
		// eslint-disable-next-line no-lone-blocks
		{
			return (
				<Col lg="4" md="4" sm="12" xs="12" className="border-right">
					<Row>
						<Col>
							<h5>Profile Specification</h5>
						</Col>
					</Row>
					<Row>
						<Col>Multi-Country</Col>
						<Col>
						<Row>
						{row.IsMultiCountry ? "Yes" : "No"}
						</Row>
						</Col>
					</Row>
					<Row>
						<Col>Fielding Countries</Col>
						<Col>
							{typeof row.FieldingCountries == "string" ? row.FieldingCountries.split(",").map((item) => {
								return <Row>{getLabel("FieldingCountriesOptions", item)}</Row>;
							}): row.FieldingCountries.map((item) => {
								return (
									<Row>{getLabel("FieldingCountriesOptions", item)}</Row>
								);
							})}
						</Col>
					</Row>
					<Row>
						<Col>Methodology</Col>
						<Col>
							{row.Methodology && Array.isArray(row.Methodology)
								? row.Methodology.map((item) => {
									return (
										<Row>{getLabel("MethodologyOptions", item.value)}</Row>
									);
								})
								: row.Methodology && typeof row.Methodology == "string"
									? row.Methodology.split(",").map((item) => {
										return <Row>{getLabel("MethodologyOptions", item)}</Row>;
									})
									: null}
						</Col>
					</Row>
					<Row>
						<Col>Sub-Methodology</Col>
						<Col>
							{row.SubMethodology && Array.isArray(row.SubMethodology)
								? row.SubMethodology.map((item) => {
									return (
										<Row>{getLabel("SubMethodologyOptions", item.value)}</Row>
									);
								})
								: row.SubMethodology && typeof row.SubMethodology == "string"
									? row.SubMethodology.split(",").map((item) => {
										return <Row>{getLabel("SubMethodologyOptions", item)}</Row>;
									})
									: null}
						</Col>
					</Row>
					<Row>
						<Col>Study Type</Col>
						<Col>
							{row.StudyType && Array.isArray(row.StudyType)
								? row.StudyType.map((item) => {
									return (
										<Row>{getLabel("StudyTypeOptions", item.value)}</Row>
									);
								})
								: row.StudyType && typeof row.StudyType == "string"
									? row.StudyType.split(",").map((item) => {
										return <Row>{getLabel("StudyTypeOptions", item)}</Row>;
									})
									: null}
						</Col>
					</Row>
					<Row>
						<Col>Tracker</Col>
						<Col>
						<Row>
						{row.IsTracker ? "Yes" : "No"}
						</Row>
						</Col>
					</Row>
					{row.IsTracker ? (
						<>
							<Row>
								<Col>Number of Waves</Col>
								<Col>{row.NumberOfWaves}</Col>
							</Row>
							<Row>
								<Col>Tracking Frequency</Col>
								<Col>
									{getLabel("TrackingFrequencyOptions", row.TrackingFrequency)}
								</Col>
							</Row>
						</>
					) : null}
					<Row>
						<Col>Costing Type</Col>
						<Col>
						<Row>
						{(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion != "v2.0")?
							row.CostingType ?
								row.CostingType : "Not Selected" :
							getCostingTypes(row)}
							</Row>
						</Col>
					</Row>
					{/* {row?.OpportunityNumber ?  */}
					<Row>
						<Col>Opportunity Number</Col>
						<Col>
						<Row>
						{row?.OpportunityNumber}
						</Row>
						</Col>
					</Row> 
					{/* :
					project?.ContractDetails[0]?.OpportunityNumber && <Row>
						<Col>Opportunity Number</Col>
						<Col>
						<Row>
						{project?.ContractDetails[0]?.OpportunityNumber}
						</Row>
						</Col>
					</Row> } */}
					<Row>
						<Col>Product Line & WBS Number</Col>
						<Col>
						{row?.ProductTableData != undefined ? handleJSONData(row?.ProductTableData)?.map((r,index) => {
							return (
								!r?.isRed &&
									<Row>
                                    {index + 1}-{r.MaterialID} | {r.WBSNumber=== null ? "No WBS available" : r.WBSNumber}
                                  </Row> 
								
							)
						}) : null}
						</Col>
					</Row>
				</Col>
			);
		}
	};
	const profitabilityChecks = (row) => {
		let cvalues = row?.CostInputCurrency?.split("-");
		let currencyUnit = _.last(cvalues);
		let countryCode = _.head(cvalues);
		let conversionUnit = _.head(
			row?.ProfileSetting?.CurrenciesData?.filter(
				(cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
			)
		)?.ConversionRateToLocal;
		if (!conversionUnit) conversionUnit = 1;
		if (!currencyUnit) currencyUnit = "USD";
		return (
			<Col lg="3" md="3" sm="12" xs="12" className="border-right">
				<Row>
					<Col>
						<h5>Profitability Checks</h5>
					</Col>
				</Row>
				<Row>
					<Col>
						<strong>Actual Price Given To Client</strong>
					</Col>
					<Col>
						<strong>{`${_.round(
							row.PriceToClient * conversionUnit,
							2
						)} ${currencyUnit}`}</strong>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col>
						<strong>Minimum Recommended</strong>
					</Col>
					<Col>
						<strong>{`${_.round(
							row.RecommendedPrice * conversionUnit,
							2
						)} ${currencyUnit}`}</strong>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col>{row.CountryContributionMarginTargetPercent===null&&row.CostingsSheetId!==null ?   "EBITDA Margin":"CM %"}</Col>
					<Col>{row.MarginPercent ? _.round(row.MarginPercent * 100, 2) + "%" : "-"}</Col>
				</Row>
				<Row className="mb-2">
					<Col>Out of Pocket %</Col>
					<Col>{_.round(row.OutOfPocketCostPercent * 100, 2)}%</Col>
				</Row>
				<Row className="mb-2">
					<Col>Net Revenue %</Col>
					<Col>{_.round(row.NetRevenuePercent * 100, 2)}%</Col>
				</Row>
				<Row className="mb-2">
					<Col>Commercial Internal %</Col>
					<Col>{_.round(row.InternalCommercialCostPercent * 100, 2)}%</Col>
				</Row>
			</Col>
		);
	};
	const quickSummary = (row) => {
		let cvalues = row?.CostInputCurrency?.split("-");
		let currencyUnit = _.last(cvalues);
		let countryCode = _.head(cvalues);
		let conversionUnit = _.head(
			row?.ProfileSetting?.CurrenciesData?.filter(
				(cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
			)
		)?.ConversionRateToLocal;
		if (!conversionUnit) conversionUnit = 1;
		if (!currencyUnit) currencyUnit = "USD";
		return (
			<React.Fragment>
				{project.CostingTemplateVersion == "v2.0" &&
					(!_.includes(pacificMarkets, project.CommissioningCountry)||
					(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion === "v2.0") 
					)
					? (
					<Col lg="4" md="4" sm="12" xs="12" className="border-right">
						<Row>
							<Col>
								<h5>Quick Summary</h5>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								<strong>Total OPS OOP</strong>
							</Col>
							<Col>
								<strong>{`${_.round(
									row.CostTotalExternalOperations * conversionUnit,
									2
								)} ${currencyUnit}`}</strong>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								<strong>Total CS OOP </strong>
							</Col>
							<Col>
								<strong>{`${_.round(
									row.CostTotalExternalCommercial * conversionUnit,
									2
								)} ${currencyUnit}`}</strong>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								<strong>Total OOP (Ops OOP + CS OOP+Tax)</strong>
							</Col>
							<Col>
								<strong>{`${_.round(
									row.TotalExternalCosts * conversionUnit,
									2
								)} ${currencyUnit}`}</strong>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>Total Time Cost Operations</Col>
							<Col>{`${_.round(
								row.CostTotalInternalOperations * conversionUnit,
								2
							)} ${currencyUnit}`}</Col>
						</Row>
						<Row className="mb-2">
							<Col>Total Time Cost Commercial</Col>
							<Col>{`${_.round(
								row.CostTotalInternalCommercial * conversionUnit,
								2
							)} ${currencyUnit}`}</Col>
						</Row>
						{row.CountryContributionMarginTargetPercent===null&&row.CostingsSheetId!==null&&<Row className="mb-2">
							<Col>Overheads {overheadsPercentage}%</Col>
							<Col>{`${_.round(
								row.Overheads * conversionUnit,
								2
							)} ${currencyUnit}`}</Col>
							{/* <Col>{_.round(row.Overheads, 2)}</Col> */}
						</Row>}
					</Col>
				) : (
					<Col lg="4" md="4" sm="12" xs="12" className="border-right">
						<Row>
							<Col>
								<h5>Quick Summary</h5>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								<strong>Total Third Party Costs</strong>
							</Col>
							<Col>
								<strong>{`${_.round(
									row.TotalExternalCosts * conversionUnit,
									2
								)} ${currencyUnit}`}</strong>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col>External Operations Costs</Col>
							<Col>{`${_.round(
								row.CostTotalExternalOperations * conversionUnit,
								2
							)} ${currencyUnit}`}</Col>
						</Row>
						<Row className="mb-2">
							<Col>External Commercial Costs</Col>
							<Col>{`${_.round(
								row.CostTotalExternalCommercial * conversionUnit,
								2
							)} ${currencyUnit}`}</Col>
						</Row>
						<Row className="mb-2">
							<Col>Internal Operations Costs</Col>

							<Col>{`${_.round(
								row.CostTotalInternalOperations * conversionUnit,
								2
							)} ${currencyUnit}`}</Col>
						</Row>
						<Row className="mb-2">
							<Col>Internal Commercial Costs</Col>
							<Col>{`${_.round(
								row.CostTotalInternalCommercial * conversionUnit,
								2
							)} ${currencyUnit}`}</Col>
						</Row>
						<Row className="mb-2">
							<Col>Overheads {overheadsPercentage}%</Col>
							<Col>{_.round(row.Overheads, 2)}</Col>
						</Row>
					</Col>
				)}
			</React.Fragment>
		);
	};
	const actions = (row) => {
		console.log(row);

		let isV2 = false;
		project.CostingTemplateVersion === "v2.0" &&
			row.CountrySpecs?.map((cs) => {
				!isV2 &&
					cs.MethodologySpecs?.map((ms) => {
						if (ms.CostingType === 2) {
							isV2 = true;
						}
					});
			});

		return (
			<Col lg="1" md="1" sm="12" xs="12">
				<Row>
					<Col>
						<h5>Actions</h5>
					</Col>
				</Row>
				<Row>
					<Col xs="1">
						<Link
							className={`${ project?.ProjectStatus == '8' &&  project?.IsDeleted ==true
							?  "no-actions" :getLabel(
								"CostingStatusOptions",
								row.ProfileStatus
							)?.toLowerCase() == "in progress"
							 && !row.IsImportedProfile
								&& !((project.IsSyndicated && project.ParentProjectId && !project.IsCopy) || project.ProjectStatus == "7")&&project.PriceChange!==3
								? "pointer"
								: "no-actions"
								}`}	
						>
							<FontAwesomeIcon
								icon={faPen}
								fixedWidth
								title="Edit Costing Profile"
								className="ic-hover"
								onClick={() => {
									if (
										row.ProfileStatus != 6 &&
										!row.IsImportedProfile
									) {
										dispatch({ type: SET_VENDOR_FLAG, Vendor: false });
										dispatch(
											currentCostingActions.getCosting(
												row.id,
												() => history.push("/costing")
											)
										);

										//todo: change the current profile- master profile
										// if (!currentCostingProfile.id) {
										// 	dispatch(
										// 		currentCostingActions.getCosting(row.id, () => history.push("/costing"))
										// 	);
										// }
										// else {
										// 	updateCostingProfile({
										// 		EditClicked: !currentCostingProfile.EditClicked
										// 	});
										// 	history.push("/costing")
										// }
									}
								}}
							/>
						</Link>
						<Link
							className={`${row.CostingsSheetId && row.ProfileStatus === "1" || project.ProjectStatus == "7"
								? (
									project.CostingTemplateVersion === "v2.0"
										? isV2
										: row.CostingType == "SHEETS"
								)
									? "pointer"
									: "no-actions"
								: "no-actions"
								}`}
								
						>
							<FontAwesomeIcon
								icon={faFileExcel}
								title="Open Costings Sheet"
								fixedWidth
								className="ic-hover"
								onClick={() => {
									if (row.CostingsSheetId) {
										if (project.CostingTemplateVersion === "v2.0") {
											if (isV2) {
												
												dispatch(sheetCall.msSheetAPiCall(
													row.CostingsSheetId,
													false,
													true
												));
												
											}
										} else if (isV2 || row.CostingType == "SHEETS") {
											// window.open(
											// 	"https://docs.google.com/spreadsheets/d/" +
											// 	row.CostingsSheetId
											// );
											
											dispatch(sheetCall.msSheetAPiCall(
												row.CostingsSheetId,
												false,
												true
											));
											
										}
									}
								}}
							/>
						</Link>
						<Link
							className={`${row.AdditionalSheet ? "pointer" : "no-actions"}`}
							
						>
							<FontAwesomeIcon
								icon={faFileAlt}
								className="ic-hover"
								title="Open Additional Sheet"
								fixedWidth
								onClick={() => {
									// window.open(
									// 	"https://docs.google.com/spreadsheets/d/" +
									// 	row.AdditionalSheet
									// );
									dispatch(sheetCall.msSheetAPiCall(row.AdditionalSheet, false))
									
								}}
							/>
						</Link>

						{/* <FontAwesomeIcon icon={faCommentDollar} fixedWidth />
            <FontAwesomeIcon icon={faAddressCard} fixedWidth />
            <FontAwesomeIcon icon={faUserPlus} fixedWidth />
             */}

						{/* <Link className={row.IsImportedProfile ? "no-actions" : "pointer"}> */}
						{/* <Link
							className={
								["6", "99"].indexOf(row.ProfileStatus) === -1
									? "pointer"
									: "no-actions"
							}
						>
							<FontAwesomeIcon
								icon={faTrash}
								fixedWidth
								title="Delete Costing Profile"
								onClick={(e) => {
									// if (!row.IsImportedProfile) {
									setDeleteProfileModal(true);
									setDeletableProfile(row);
									// }
								}}
							/>
						</Link> */}
						{/* <Link
							className={
								["6", "99"].indexOf(row.ProfileStatus) === -1 &&
									!row.IsImportedProfile
									? "pointer"
									: "no-actions"
							}
						>
							<FontAwesomeIcon
								icon={faCopy}
								fixedWidth
								title="Create Duplicate Costing Profile"
								onClick={() => {
									dispatch(currentCostingActions.copyCosting(row.id));
								}}
							/>
						</Link> */}
						{(project?.IsSyndicated && project?.ParentProjectId) ? null :
							<Link
								className={project?.ProjectStatus == '8' &&  project?.IsDeleted ==true ?
								"no-actions":
									getLabel(
										"CostingStatusOptions",
										row.ProfileStatus
									).toLowerCase() == "commissioned"
										? "pointer"
										: row?.ProjectStatus == '8' &&  row?.IsDeleted ==true
                    					?  "no-actions"
										: "no-actions"
								}
								
							>
								<FontAwesomeIcon
									title="View/Edit Project Schedule"
									icon={faCalendarAlt}
									className="ic-hover"
									fixedWidth
									onClick={() => {
										if(project.IsUploaded){
                                            setUploadProject(true)

                                        }
                                        else{

                                            if (
                                                getLabel(
                                                    "CostingStatusOptions",
                                                    row.ProfileStatus
                                                ).toLowerCase() == "commissioned"
                                            )
                                                dispatch(
                                                    currentCostingActions.getCosting(row.id, () =>
                                                        history.push(`/schedule/${row.id}`)
                                                    )
                                                );
                                                }
									}}
								/>
							</Link>}
						<Link className={!row.IsImportedProfile ? "pointer" : "no-actions"}>
							<FontAwesomeIcon
								icon={faFileInvoiceDollar}
								fixedWidth
								className="ic-hover"
								title="View Costing Profile Summary"
								onClick={() => {
									dispatch(
										currentCostingActions.getSummaryCosting(
											row,
											() => {
												history.push(`/summary/${row.id}`);
											},
											true
										)
									);
								}}
							/>
						</Link>

						{/* <FontAwesomeIcon icon={faComment} fixedWidth /> */}
					</Col>
				</Row>
			</Col>
		);
	};



	const expandRow = {
		renderer: (row) => (
			<div>
				<Row>
					{profileSpecs(row)}
					{profitabilityChecks(row)}
					{quickSummary(row)}
					{actions(row)}
				</Row>
			</div>
		),
		showExpandColumn: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) =>
			isAnyExpands ? (
				<MinusCircle width={16} height={16} />
			) : (
				<PlusCircle width={16} height={16} />
			),
		expandColumnRenderer: ({ expanded }) =>
			expanded ? (
				<MinusCircle width={16} height={16} />
			) : (
				<PlusCircle width={16} height={16} />
			),
	};

	const updateCostingProfile = (costingProfile) => {
		dispatch({
			type: "UPDATE_NEW_COSTING",
			currentCostingProfile: costingProfile,
		});
	};

	const updateCostingProfileSave = (costingProfile) => {
		dispatch({
			type: currentProjectActions.UPDATE_NEW_PROJECT,
			newProject :{...project, CostingProfiles: costingProfile} ,
		});
	};

	return (
		<>
		<UploadedProjectModal uploadProject={uploadProject} setUploadProject={setUploadProject} />
			<Card>
				<CardBody className="p-0">
					<BootstrapTable
						bootstrap4
						striped
						hover
						condensed
						bordered={false}
						className="m-1 mb-0"
						keyField="ProfileNumber"
						cellEdit={cellEditFactory({
							mode: "click",
							blurToSave: true,
							afterSaveCell: (oldValue, newValue, row, column) => { 
								let newRow =  _.cloneDeep(row)
								updateCostingProfile({ ProfileName: newValue })
								updateCostingProfileSave([newRow])
							 }
						})}
						data={rowData}
						columns={tableColumns}
						expandRow={expandRow}
					// pagination={paginationFactory({
					//   sizePerPage: 10,
					//   sizePerPageList: [5, 10, 25, 50],
					// })}
					/>
				</CardBody>
			</Card>
			<Modal
				size="sm"
				isOpen={deleteProfileModal}
				toggle={() => setDeleteProfileModal(!deleteProfileModal)}
			>
				<ModalHeader toggle={() => setDeleteProfileModal(!deleteProfileModal)}>
					Delete Profile
				</ModalHeader>
				<ModalBody>
					<strong>
						This Change is Irreversible. Are you sure want to delete Profile-
						<i>{deletableProfile?.ProfileName}</i>
					</strong>
				</ModalBody>
				<ModalFooter>
					<Row className="justify-content-end">
						<Button
							color="secondary"
							size="sm"
							onClick={() => setDeleteProfileModal(!deleteProfileModal)}
						>
							Cancel
						</Button>
						<Button
							color="primary"
							className="ml-2"
							size="sm"
							onClick={() => {
								dispatch(
									currentCostingActions.deleteCosting(
										deletableProfile.id,
										() => {
											setDeletableProfile(null);
											setDeleteProfileModal(false);
										}
									)
								);
							}}
						>
							Confirm
						</Button>
					</Row>
				</ModalFooter>
			</Modal>
		</>
	);
};

const CostingProfilesTables = (props) => {
	const dispatch = useDispatch();
	const localPageload = useSelector(({ app }) => app.localPageload);
	const project = useSelector(
		({ currentProject }) => currentProject.newProject
	);
	const currentCostingProfile = useSelector(
		({ currentCosting }) => currentCosting.currentCostingProfile
	);
	const costingProfiles = useSelector(
		({ costings }) => costings.costingProfiles
	);
	const updateCosting = (currentCostingProfile) => {
		dispatch({
			type: "UPDATE_NEW_COSTING",
			currentCostingProfile: currentCostingProfile,
		});
	};

	const getCosting = (profile) =>
		dispatch(currentCostingActions.getCosting(profile));

	const [modal, setModal] = useState(false);

	const toggle = () => {
		// //console.log("CALLED TOGGLE TO", !prevState);
		setModal(!modal);
	};

	const updateCostingProfile = (costingProfile) => {
		dispatch({
			type: "UPDATE_NEW_COSTING",
			currentCostingProfile: costingProfile,
		});
	};
	const updateCostingProfileSave = (costingProfile) => {
		dispatch({
			type: currentProjectActions.UPDATE_NEW_PROJECT,
			newProject :{...project, CostingProfiles: costingProfile} ,
		});
	};
	const getCostingProfilesData = () => {
		let finalProfiles = [];
		if (costingProfiles?.length) {
			finalProfiles = costingProfiles.filter((cp) => cp.ProfileStatus == 6);
			if (!finalProfiles.length)
				finalProfiles = costingProfiles.filter((cp) => !cp.ParentProfileId);
		}
		return finalProfiles;
	};
	const data = {
		tableColumns: [
			{ dataField: "ProfileNumber", text: "Profile Number", sort: true, editable: false },
			{
				dataField: "ProfileName",
				text: "Profile Name",
				sort: true,
				editable: true,
				formatter: (cell, row) => {
					let newRowOne =  _.cloneDeep(row)
					updateCostingProfile({ ProfileName: row.ProfileName })
					updateCostingProfileSave([newRowOne])
					return (
						<span>
							{cell}{" "}
							{row.IsImportedProfile ? (
								<FontAwesomeIcon
									className="warning pointer"
									icon={faExclamationTriangle}
									title="Profile Migrated from v1. Some features may not be available."
								/>
							) : null}
						</span>
					);
				},
			},
			// {
			// 	dataField: "ProfileEditNameButton",
			// 	text: "",
			// 	formatter: (cell, row) => {
			// 		return (
			// 			<FontAwesomeIcon
			// 				icon={faPen}
			// 				fixedWidth
			// 				title="Edit Costing Profile"
			// 				className={row.IsImportedProfile ? "no-actions" : "pointer"}
			// 				style={{ cursor: "pointer" }}
			//                 onClick={(e) =>
			//                 {
			// 					if (row.IsImportedProfile) {
			// 						e.preventDefault();
			// 					} else {
			// 						//console.log("ROW HAS BEEN CLICKED");
			// 						e.stopPropagation();
			// 						dispatch(currentCostingActions.selectCosting(row));
			// 						toggle();
			// 					}
			// 				}}
			// 			/>
			// 		);
			// 	},
			// },
			{
				dataField: "ProfileStatus",
				text: "Profile Status",
				sort: true,
				editable: false,
				formatter: (cell, row) => {
					const label = getLabel("CostingStatusOptions", cell);
					return (
						<div className="text-center btn p-0" key={cell.id}>
							<Badge key={cell.id} color="secondary">
								{label}
							</Badge>
						</div>
					);
				},
			},
			{
				dataField: "CreatedBy",
				text: "Created By",
				sort: true,
				editable: false,
				formatter: (cell) => {
					if (cell) {
						return cell
							.toLowerCase()
							.split("@")[0]
							.split(".")
							.map((word) => {
								return word.replace(word[0], word[0].toUpperCase());
							})
							.join(" ");
					} else {
						return "";
					}
				},
			},
			{
				dataField: "createdAt",
				text: "Created Date",
				sort: true,
				editable: false,
				formatter: (cell) => {
					return cell.split("T")[0];
				},
			},
			{
				dataField: "CommissionedDate",
				text: "Commissioned Date",
				sort: true,
				editable: false,
				formatter: (cell,row) => {
					return row.ProfileStatus=="99"||row.ProfileStatus=="6"?cell ?cell.split("T")[0]:null:null;
				},
			},
		],
		tableData: costingProfiles, //getCostingProfilesData(),
	};

	return (
		<>
			<Container fluid className="p-0">
			{localPageload? (
				<div id="pageCoverSpin"></div>
				) : null}
				<ExpandableRowsTable
					{...data}
					getCosting={getCosting}
					project={project}
				/>
			</Container>

			<Modal isOpen={modal} toggle={toggle} centered>
				<ModalHeader toggle={toggle}>Edit Costing Profile Name</ModalHeader>
				<ModalBody>
					<Input
						value={currentCostingProfile.ProfileName}
						onChange={(e) => updateCosting({ ProfileName: e.target.value })}
						placeholder="Enter Profile Name..."
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						color="primary"
						onClick={(e) => {
							toggle();
							dispatch(
								currentCostingActions.updateCostingProfiles(
									costingProfiles,
									currentCostingProfile
								)
							);
						}}
					>
						Rename
					</Button>
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default CostingProfilesTables;
