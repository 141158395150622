import React from "react";
import filterFactory, {
    textFilter,
} from "react-bootstrap-table2-filter";
import _ from "lodash";

const reportColumns = [
    "Costing Profile Id", "Project ID", "Microsoft Dynamics Number", "WBS Number", "Opportunity Number", "Project Name",
    "Commissioning Country", "Fielding Countries", "Industry", "Verticals", "Practice Area",
    "Product Description", "Microsoft Dynamics Probability", "Methodology", "Sub-Methodology", "MCP", "Syndicated",
    "Tracker", "Price To Client", "Total Costs Raw", "Total External Operations Cost", "Out of Pocket %", "Margin %", "Approval Status",
    "New / Renewal Project?", "Request Date", "Reason(s) for high % variable cost (OOP)",
    "Justify why this CI project is important / strategic intent", "Project start date", "Project end date",
    "Will contract have a clause allowing NLSN to terminate project for convenience & still oblige client to pay us for work covered?",
    "Execution Risk", "Are Local Commercial & Operations teams aligned?", "Billing: % project to bill at contract signature",
    "Billing: % project to bill at field start date", "Billing: % project to bill at end of project",
    "Billing: in case some portion is not billed at above listed dates, explain when what portion gets billed so total % billed = 100%",
    "Client payment terms?", 
    "Microsoft Dynamics Status","Project Status", "Profile Status", "Start Delivery Date", "End Delivery Date",
    "Approval Note", "CS Proposal Owner", "Level1 Approvers", "Level2 Approvers", "Level3 Approvers",
    "Level4 Approvers", "Level5 Approvers"
];

const finalColumns = [
    {
        dataField: "costingProfileId",
        text: "Costing Profile Id",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.costingProfileId}</span>;
        }, headerStyle: {
            minWidth: "100px"
        },
        csvFormatter: (cell, row) => {
            return row.costingProfileId;
        },
        filter: textFilter(),
    },
    {
        dataField: "ProjectId",
        text: "Project ID",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return <span>{row.ProjectId || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ProjectId || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "SfNumber",
        text: "Microsoft Dynamics Number",
        sort: true, headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return <span>{row.SfNumber || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.SfNumber || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "WBSNumber",
        text: "WBS Number",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return <span>{row.WBSNumber || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WBSNumber || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "OpportunityNumber",
        text: "Opportunity Number",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return <span>{row.OpportunityNumber || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.OpportunityNumber || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ProjectName",
        text: "Project Name",
        sort: true,
        headerStyle: {
            minWidth: "200px"
        },
        formatter: (cell, row) => {
            return <span>{row.ProjectName || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ProjectName || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "CommissioningCountry",
        text: "Commissioning Country",
        sort: true, headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.CommissioningCountry}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.CommissioningCountry || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "FieldingCountries",
        text: "Fielding Countries",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.FieldingCountries && row.FieldingCountries.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.FieldingCountries && row.FieldingCountries.split(',').join('|') || ""
        },
        filter: textFilter(),
    },
    {
        dataField: "Industry",
        text: "Industry",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.Industry}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.Industry || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "verticals",
        text: "Verticals",
        sort: true, headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.verticals}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.verticals || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "PracticeArea",
        text: "Practice Area",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.PracticeArea}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.PracticeArea || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "ProductDescription",
        text: "Product Description",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ProductDescription}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ProductDescription || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "sfProbability",
        text: "Microsoft Dynamics Probability",
        sort: true, headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.sfProbability}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.sfProbability || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "Methodology",
        text: "Methodology",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.Methodology && row.Methodology.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.Methodology && row.Methodology.split(',').join('|') || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "SubMethodology",
        text: "Sub-Methodology",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.SubMethodology && row.SubMethodology.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.SubMethodology && row.SubMethodology.split(',').join('|') || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "mcp",
        text: "MCP",
        sort: true, headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.mcp}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (row.mcp) || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "syndicated",
        text: "Syndicated",
        sort: true, headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.syndicated}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (row.syndicated) || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "tracker",
        text: "Tracker",
        sort: true, headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.tracker}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (row.tracker) || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "PriceToClient",
        text: "Price To Client",
        sort: true,
        headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.priceToClient, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.priceToClient && `${_.round(row.priceToClient, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "totalCostRaw",
        text: "Total Costs Raw",
        sort: true,
        headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.totalCostRaw, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.totalCostRaw && `${_.round(row.totalCostRaw, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "totalExternalOperationCost",
        text: "Total External Operations Cost",
        sort: true,
        headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.totalExternalOperationCost, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.totalExternalOperationCost && `${_.round(row.totalExternalOperationCost, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "oopPercent",
        text: "Out of Pocket %",
        sort: true,
        headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {`${_.round(row.oopPercent, 2)}`}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.oopPercent && `${_.round(row.oopPercent, 2)}` || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "marginPercent",
        text: "Margin %",
        sort: true,
        headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.marginPercent}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.marginPercent || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "approvalResult",
        text: "Approval Status",
        sort: true, headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.approvalResult}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.approvalResult || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "newRenewalProject",
        text: "New / Renewal Project?",
        sort: true,
        headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.newRenewalProject}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.newRenewalProject || "";
        },
        filter: textFilter(),
        isOOPOnly: true
    },
    {
        dataField: "requestDate",
        text: "Request Date",
        sort: true,
        headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.requestDate}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.requestDate || "";
        },
        filter: textFilter(),
        isOOPOnly: true
    },
    {
        dataField: "reasonForOOP",
        text: "Reason(s) for high % variable cost (OOP)",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.reasonForOOP}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.reasonForOOP || "";
        },
        filter: textFilter(),
        isOOPOnly: true
    },
    {
        dataField: "justifyIntent",
        text: "Justify why this CI project is important / strategic intent",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.justifyIntent}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.justifyIntent || "";
        },
        filter: textFilter(),
        isOOPOnly: true
    },
    {
        dataField: "projectStartDate",
        text: "Project start date",
        sort: true,
        headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.projectStartDate}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.projectStartDate || "";
        },
        filter: textFilter(),
        isOOPOnly: true
    },
    {
        dataField: "projectEndDate",
        text: "Project end date",
        sort: true,
        headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.projectEndDate}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.projectEndDate || "";
        },
        filter: textFilter(),
        isOOPOnly: true
    },
    {
        dataField: "contractAllowTermination",
        text: "Will contract have a clause allowing NLSN to terminate project for convenience & still oblige client to pay us for work covered?",
        sort: true,
        headerStyle: {
            minWidth: "200px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.contractAllowTermination}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.contractAllowTermination || "";
        },
        filter: textFilter(),
        isOOPOnly: true
    },
    {
        dataField: "executionRisk",
        text: "Execution Risk",
        sort: true, headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.executionRisk}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.executionRisk || "";
        },
        filter: textFilter(),
        isOOPOnly: true
    },
    {
        dataField: "localTeamsAligned",
        text: "Are Local Commercial & Operations teams aligned?",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.localTeamsAligned}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.localTeamsAligned || "";
        },
        filter: textFilter(),
        isOOPOnly: true
    },
    {
        dataField: "billingContractSignature",
        text: "Billing: % project to bill at contract signature",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.billingContractSignature}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.billingContractSignature || "";
        },
        filter: textFilter(),
        isOOPOnly: true
    },
    {
        dataField: "billingFieldStart",
        text: "Billing: % project to bill at field start date",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.billingFieldStart}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.billingFieldStart || "";
        },
        filter: textFilter(),
        isOOPOnly: true
    },
    {
        dataField: "billingProjectEnd",
        text: "Billing: % project to bill at end of project",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.billingProjectEnd}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.billingProjectEnd || "";
        },
        filter: textFilter(),
        isOOPOnly: true
    },
    {
        dataField: "billingExplanation",
        text: "Billing: in case some portion is not billed at above listed dates, explain when what portion gets billed so total % billed = 100%",
        sort: true,
        headerStyle: {
            minWidth: "200px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.billingExplanation}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.billingExplanation || "";
        },
        filter: textFilter(),
        isOOPOnly: true
    },
    {
        dataField: "clientPaymentTerms",
        text: "Client payment terms?",
        sort: true,
        headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.clientPaymentTerms}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.clientPaymentTerms || "";
        },
        filter: textFilter(),
        isOOPOnly: true
    },
    ,
    {
        dataField: "SFStatus",
        text: "Microsoft Dynamics Status",
        sort: true, headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.SFStatus}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.SFStatus || "";
        },
        filter: textFilter(),
    },
    ,
    {
        dataField: "projectStatus",
        text: "Project Status",
        sort: true, headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.projectStatus}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.projectStatus || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "profileStatus",
        text: "Profile Status",
        sort: true, headerStyle: {
            minWidth: "150px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.profileStatus}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.profileStatus || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "startOfDelivery",
        text: "Start Delivery Date",
        sort: true, headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.startOfDelivery}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.startOfDelivery || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "endOfDelivery",
        text: "End Delivery Date",
        sort: true, headerStyle: {
            minWidth: "100px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.endOfDelivery}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.endOfDelivery || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "approverNote",
        text: "Approval Note",
        sort: true,
        headerStyle: {
            minWidth: "200px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.approverNote}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.approverNote || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "csProposalOwner",
        text: "CS Proposal Owner",
        sort: true,
        headerStyle: {
            minWidth: "200px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.csProposalOwner}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.csProposalOwner || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "approversL1",
        text: "Level1 Approvers",
        sort: true,
        headerStyle: {
            minWidth: "200px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.approversL1}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.approversL1 || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "approversL2",
        text: "Level2 Approvers",
        sort: true, headerStyle: {
            minWidth: "200px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.approversL2}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.approversL2 || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "approversL3",
        text: "Level3 Approvers",
        sort: true, headerStyle: {
            minWidth: "200px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.approversL3}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.approversL3 || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "approversL4",
        text: "Level4 Approvers",
        sort: true, headerStyle: {
            minWidth: "200px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.approversL4}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.approversL4 || "";
        },
        filter: textFilter(),
    },
    {
        dataField: "approversL5",
        text: "Level5 Approvers",
        sort: true, headerStyle: {
            minWidth: "200px"
        },
        formatter: (cell, row) => {
            return (
                <span>
                    {row.approversL5}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.approversL5 || "";
        },
        filter: textFilter(),
    }
];

const formattedPrec = (data) => {
    if (data) return (data * 100).toFixed(2) + "%";
    else return "not available";
};

const csvHeaders = finalColumns.map(col => ({ label: col.text, key: col.dataField }));

export { finalColumns, csvHeaders, reportColumns }