import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";
import * as costingsActions from "../../redux/actions/costingsActions";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import * as currentWaveSpecActions from "../../redux/actions/currentWaveSpecActions";
import axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faQuestionCircle,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Collapse,
  CardHeader,
  CardTitle,
  CardText,
  Badge,
  Table,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { setWaveSpecs } from "../../redux/actions/waveSpecsActions";
import Selector from "../../components/Selector/Selector";
import { getLabel } from "../../utils/codeLabels";
import FormFeedback from "reactstrap/lib/FormFeedback";
import Spinner from "../../components/Spinner";

const ReviewOperations = ({ indxFC, methodology, indxSM}) => {
  const user = useSelector(({ user }) => user.userRecord);
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const costingProfiles = useSelector(
    ({ costings }) => costings.costingProfiles
  );
  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );

  const [editableCostingProfile, setEditableCosting] = useState(
    currentCostingProfile
  );
  const userRecord = useSelector(({ user }) => user.userRecord);

  const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
  // const currentWaveSpec = useSelector(({ currentWaveSpec }) => currentWaveSpec);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const app = useSelector(({ app }) => app);

  const [waveEditModal, setWaveEditModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editableWaveName, setEditableWavename] = useState();
  const dispatch = useDispatch();

  const [opsActualsData, setOpsActualsData] = useState({});
  const [waveBreakDown, setWaveBreakDown] = useState({})
  const [currentWaveSpec, setcurrentWaveSpec] = useState([])
  const [modifiedMeths, setModifiedMeths] = useState([])
  const [wavesSpecsData, setwavesSpecsData] = useState(currentCostingProfile.WaveSpecs)
  const [toggle, setToggle] = useState({
    overall: true,
    inst: true,
    other: true,
  });
  useEffect(() => {
    if (currentCostingProfile &&
        wavesSpecsData
        && wavesSpecsData.length
        && !Object.keys(waveBreakDown).length) {

        let rData = (currentCostingProfile.CountrySpecs[indxFC]);
        let checData = (rData?.MethodologySpecs[indxSM])
        // setActiveTabFC(rData.id);
        // setActiveTabSM(checData.id);
        //console.log(indxSM, methodology, "index value check")
        let _waveBreakdownMain = wavesSpecsData.filter(x => x.MethodologySpecId === checData.id)
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
        setModifiedMeths(_waveBreakdownMain)

        Object.keys(_waveBreakdown).map(w => {
            _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
        })
        setwavesSpecsData(wavesSpecsData);
        setWaveBreakDown(_waveBreakdown)
        let _currentwaveset = _.head(Object.values(_waveBreakdown))
        setcurrentWaveSpec((_.head(_currentwaveset)))     
        // dispatch(currentWaveSpecActions.selectWaveSpec({ ..._.head(_currentwaveset) }));

    }
}, [currentCostingProfile])
  useEffect(() => {
    setEditableCosting(currentCostingProfile);
  }, [currentCostingProfile]);

  const selectorHandler = (item) => {
    // do nothing if clicked item is current item
    if (item.id === currentWaveSpec.id) return;
    let waveSpecsToSave = [...waveSpecs];
    waveSpecsToSave = waveSpecsToSave.map((wss) => {
      if (wss.id == currentWaveSpec.id) {
        wss = { ...currentWaveSpec };
      }
      return { ...wss };
    });
    dispatch(setWaveSpecs(waveSpecsToSave));

    // const itemIndex = waveSpecs.findIndex(
    // 	(record) => record.id === currentWaveSpec.id
    // );
    // const newArr = update(waveSpecs, {
    // 	[itemIndex]: { $set: currentWaveSpec },
    // });
    dispatch(currentWaveSpecActions.selectWaveSpec(item));
    // dispatch(setWaveSpecs(newArr));
  };

  const getProperty = (schema, prop) => {
    if (schema.properties[prop]) return schema.properties[prop];
    else {
      let reqVal = "";
      if (schema.dependencies) {
        Object.keys(schema.dependencies).map((dep) => {
          schema.dependencies[dep].oneOf.map((oo) => {
            if (oo.properties[prop] && oo.properties[prop].title) {
              reqVal = oo.properties[prop];
            }
          });
        });
      }
      return reqVal;
    }
  };

  const getOpsValue = (value, prop) => {
    if (value || value == false) {
      if (prop.toLowerCase().indexOf("complexity") != -1) {
        if (prop == "surveyProgrammingComplexity") {
          return _.head(
            codeLabels.QuestionnaireComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
        if (prop == "dataProcessingComplexity") {
          return _.head(
            codeLabels.DataProcessingComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
        if (prop == "chartingComplexity") {
          return _.head(
            codeLabels.ChartingComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
      } else {
        if (Array.isArray(value)) {
          return value.join();
        } else if (typeof value == "boolean") {
          return value ? "Yes" : "No";
        } else return value;
      }
    } else {
      return "-";
    }
  };

  const renderSelector = () => {
    if (!waveSpecs || (waveSpecs && waveSpecs.length === 1)) return null;
    return (
      <Col lg="2" md="2" sm="12" xs="12">
        <Selector
          heading={"Waves"}
          records={waveSpecs}
          clicked={selectorHandler}
          interPolField={["WaveNumber", "WaveName"]}
          displayField={
            <>
              <FontAwesomeIcon
                title="Edit Wave Name"
                size="xs"
                icon={faPen}
                className={
                  userRecord.IsOpsProjectManager ? "pointer" : "no-actions"
                }
                // onClick={() => {
                //   if (userRecord.IsOpsProjectManager)
                //     props.setWaveEditModal(!props.waveEditModal);
                // }}
              />
            </>
          }
          selected={currentWaveSpec}
          //   labelGroup={"FieldingCountriesOptions"}
        />
      </Col>
    );
  };
  const scheduleNotesHandler = (eve) => {
    let notesActual = {};
    // let waveSpecsToSave = [...waveSpecs];

    if (currentWaveSpec.NotesActual)
      notesActual = { ...currentWaveSpec.NotesActual };

    if (!notesActual.OpsResourcesNotes) notesActual.OpsResourcesNotes = {};

    notesActual.OpsResourcesNotes[eve.target.id] = eve.target.value;

    onChangeHandler({
      target: {
        id: "NotesActual",
        value: notesActual,
      },
    });
  };
  const onChangeHandler = (eve, useCurrentSpec, isApi) => {
    let currentwavespec = {};
    if (useCurrentSpec) {
      currentwavespec = {
        ...useCurrentSpec,
        [eve.target.id]: eve.target.value,
      };
    } else {
      currentwavespec = {
        ...currentWaveSpec,
        [eve.target.id]: eve.target.value,
      };
    }
    if (currentwavespec.TotalIntOpsPMActualHours) {
      if (typeof(currentwavespec.TotalIntOpsPMActualHours) === 'string') {
        currentwavespec.TotalIntOpsPMActualHours = parseInt(currentwavespec.TotalIntOpsPMActualHours);
      }
    }
    let wavespecs = [...waveSpecs];
    wavespecs = wavespecs.map((ws) => {
      if (ws.id == currentWaveSpec.id) {
        ws = currentwavespec;
      }
      return { ...ws };
    });
    dispatch(setWaveSpecs([...wavespecs]));
    dispatch(
      currentWaveSpecActions.updateCurrentWaveSpec({ ...currentwavespec })
    );

    let editablecostingprofile = { ...editableCostingProfile };
    editablecostingprofile.WaveSpecs = editablecostingprofile.WaveSpecs.map(
      (ws) => {
        if (ws.id == currentwavespec.id) {
          return { ...currentwavespec };
        } else {
          return { ...ws };
        }
      }
    );
    setEditableCosting(editablecostingprofile);
    if (isApi) {
      dispatch(
        currentCostingActions.saveCostingProfile(
          { ...editablecostingprofile },
          () => setWaveEditModal(false)
        )
      );
      dispatch(
        currentCostingActions.updateCostingProfiles(costingProfiles, {
          ...editablecostingprofile,
        })
      );
    }
  };

  const saveActualDates = () => {
    dispatch(
      currentCostingActions.saveProjectReviewSchedule(editableCostingProfile)
    );
  };

  // boolean value conversion
  const getBooleanConversion = (value) => {
    return (value === true) ? 'Yes' : 'No'
  }

  const capitalizeFirstLetterBoolean = (string) => {
    const str = string.toLowerCase();
    if (str == "yes" || str == "no") {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return string;
    }
  }

  // for select and multi of OPS
  const onOptionSelectChange = (select, ors) => {
    actualResourceHandler(select, ors);
  }


  // Ops resource handler
  const actualResourceHandler = (eve, ors = null) => {
    let OpsResourcesActualData = {};

    if (currentWaveSpec.OpsResourcesActualData)
      OpsResourcesActualData = { ...currentWaveSpec.OpsResourcesActualData };

    if (!OpsResourcesActualData) OpsResourcesActualData = {};

    if (ors) {
      OpsResourcesActualData[ors] = eve;
    } else {
      OpsResourcesActualData[eve.target.id] = capitalizeFirstLetterBoolean(eve.target.value);
      console.log(OpsResourcesActualData[eve.target.id]);
    }

    onChangeHandler({
      target: {
        id: "OpsResourcesActualData",
        value: OpsResourcesActualData,
      },
    });
  };
  return (
    <>
    <>
      <Container className="review-schedule">
        <Card
          className="ml-2 mr-2 mb-0 p-0"
          style={{ background: "none", boxShadow: "none" }}
        >
          <Row>
            {renderSelector()}
            <Col>
              <Card className="mb-2">
                <CardHeader className="border-bottom border-light">
                  <Row>
                    <Col lg="3" md="3" sm="12" xs="12">
                      <strong>Wave Resource</strong>
                    </Col>
                    <Col lg="3" md="3" sm="12" xs="12">
                      <strong>Notes</strong>
                    </Col>
                    <Col lg="3" md="3" sm="12" xs="12">
                      <strong>Planned Resource</strong>
                    </Col>
                    <Col lg="3" md="3" sm="12" xs="12">
                      <strong>Actual Resource</strong>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row className="mb-2 border-bottom border-light">
                    <Col lg="3" md="3" sm="12" xs="12">
                      <h4>Internal Operations Project Management</h4>
                    </Col>
                  </Row>
                  <Row className="mb-2 border-bottom border-light">
                    <Col lg="3" md="3" sm="12" xs="12">
                      Operations Project Management Hours
                    </Col>
                    <Col lg="3" md="3" sm="12" xs="12">
                      <textarea id="TotalIntOpsPMHours"
                        disabled={!userRecord.IsOpsProjectManager}
                        readOnly={!userRecord.IsOpsProjectManager}
                        value={
                          currentWaveSpec.NotesActual &&
                            currentWaveSpec.NotesActual.OpsResourcesNotes &&
                            currentWaveSpec.NotesActual.OpsResourcesNotes[
                            "TotalIntOpsPMHours"
                            ]
                            ? currentWaveSpec.NotesActual.OpsResourcesNotes[
                            "TotalIntOpsPMHours"
                            ]
                            : ""
                        }
                        onChange={scheduleNotesHandler}
                        className="form-control">
                      </textarea>
                    </Col>
                    <Col lg="3" md="3" sm="12" xs="12">
                      {currentWaveSpec.TotalIntOpsPMHours}
                    </Col>
                    <Col lg="3" md="3" sm="12" xs="12">
                      <input
                        className="form-control"
                        disabled={!userRecord.IsOpsProjectManager}
                        readOnly={!userRecord.IsOpsProjectManager}
                        id="TotalIntOpsPMActualHours"
                        onChange={onChangeHandler}
                        value={currentWaveSpec["TotalIntOpsPMActualHours"]
                          ? currentWaveSpec["TotalIntOpsPMActualHours"] : ''}
                        type="number"
                        min={0}
                        step="any"
                      />
                    </Col>
                  </Row>
                  {currentWaveSpec?.OpsResourcesSchema?.order.map((ors) => {
                    if (
                      currentWaveSpec?.OpsResourcesData?.hasOwnProperty(ors)
                    ) {
                      let property = getProperty(
                        currentWaveSpec.OpsResourcesSchema,
                        ors
                      );

                      return (
                        <>
                          {property.isNewSection ? (
                            <Row className="mb-2 border-bottom border-light">
                              <Col lg="3" md="3" sm="12" xs="12">
                                <h4>{property.sectionTitle}</h4>
                              </Col>
                            </Row>
                          ) : null}
                          <Row className="mb-2 border-bottom border-light">
                            <Col lg="3" md="3" sm="12" xs="12">
                              {property.title}
                            </Col>
                            <Col lg="3" md="3" sm="12" xs="12">
                              <textarea id={ors}
                                disabled={!userRecord.IsOpsProjectManager}
                                readOnly={!userRecord.IsOpsProjectManager}
                                value={currentWaveSpec.NotesActual
                                  && currentWaveSpec.NotesActual.OpsResourcesNotes
                                  && currentWaveSpec.NotesActual.OpsResourcesNotes[ors]
                                  ? currentWaveSpec.NotesActual.OpsResourcesNotes[ors]
                                  : ""}
                                onChange={scheduleNotesHandler}
                                className="form-control">
                              </textarea>
                            </Col>
                            <Col lg="3" md="3" sm="12" xs="12">
                              {/* {property.hasOwnProperty("optionsLabel") &&
                                !property.isMulti
                                ? getLabel(
                                  property.optionsLabel,
                                  currentWaveSpec?.OpsResourcesData[ors]
                                )
                                : currentWaveSpec?.OpsResourcesData[ors]}
                              {property.type === "boolean" && currentWaveSpec?.OpsResourcesData[ors]
                                ? getBooleanConversion(
                                  currentWaveSpec?.OpsResourcesData[ors]
                                )
                                : ''} */}
                            </Col>
                            <Col lg="3" md="3" sm="12" xs="12">
                              {property.type == "string" &&
                                property.enum &&
                                property.enum.length ? (
                                <select
                                  className="form-control"
                                  disabled={!userRecord.IsOpsProjectManager}
                                  readOnly={!userRecord.IsOpsProjectManager}
                                  id={ors}
                                  value={currentWaveSpec.OpsResourcesActualData
                                    && currentWaveSpec.OpsResourcesActualData[ors]
                                    ? currentWaveSpec.OpsResourcesActualData[ors]
                                    : ""}
                                  onChange={actualResourceHandler}
                                >
                                  <option value="NA">Select Option</option>
                                  {property.enum.map((propopt) => (
                                    <option value={propopt}>{propopt}</option>
                                  ))}
                                </select>
                              ) : property.type === "array" &&
                                property.items?.enum?.length ? (
                                <Select
                                  className="custom-select-box"
                                  isMulti={property.isMulti}
                                  options={property.items?.enum?.map(
                                    (opt) => {
                                      return {
                                        value: opt,
                                        label: opt,
                                      };
                                    }
                                  )}
                                  value={currentWaveSpec.OpsResourcesActualData
                                    && currentWaveSpec.OpsResourcesActualData[ors]
                                    ? currentWaveSpec.OpsResourcesActualData[ors]
                                    : ""}
                                  onChange={(select) =>
                                    onOptionSelectChange(select, ors)
                                  }
                                />
                              ) : property.optionsLabel ? (
                                <Select
                                  className="custom-select-box"
                                  isMulti={property.isMulti}
                                  options={codeLabels[
                                    property.optionsLabel
                                  ]?.map((opt) => {
                                    return {
                                      value: opt.Code,
                                      label: opt.Label,
                                    };
                                  })}
                                  value={currentWaveSpec.OpsResourcesActualData
                                    && currentWaveSpec.OpsResourcesActualData[ors]
                                    ? currentWaveSpec.OpsResourcesActualData[ors]
                                    : ""}
                                  onChange={(select) =>
                                    onOptionSelectChange(select, ors)
                                  }
                                />
                              ) : (
                                <input
                                  className="form-control"
                                  disabled={!userRecord.IsOpsProjectManager}
                                  readOnly={!userRecord.IsOpsProjectManager}
                                  id={ors}
                                  value={currentWaveSpec.OpsResourcesActualData
                                    && currentWaveSpec.OpsResourcesActualData[ors]
                                    ? currentWaveSpec.OpsResourcesActualData[ors]
                                    : ""}
                                  onChange={actualResourceHandler}
                                />
                              )}
                            </Col>
                          </Row>
                        </>
                      );
                    }
                  })}
                </CardBody>
              </Card>
              <hr></hr>
              <div className="mb-0">
                <CardBody>
                  <Row>
                    <Col>
                      <Label>Other schedule related notes</Label>

                      <Input
                        id="OpsNotesOtherActual"
                        disabled={!userRecord.IsOpsProjectManager}
                        readOnly={!userRecord.IsOpsProjectManager}
                        type="textarea"
                        className="mb-2"
                        onChange={scheduleNotesHandler}
                        value={
                          currentWaveSpec.NotesActual &&
                          currentWaveSpec.NotesActual.OpsResourcesNotes &&
                          currentWaveSpec.NotesActual.OpsResourcesNotes[
                            "OpsNotesOtherActual"
                          ]
                            ? currentWaveSpec.NotesActual.OpsResourcesNotes[
                                "OpsNotesOtherActual"
                              ]
                            : ""
                        }
                        placeholder="Any other notes or topics not covered elsewhere..."
                      />
                    </Col>
                  </Row>
                </CardBody>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
    <Container className="d-flex mt-4 mr-2 justify-content-end">
      <Button
        disabled={app.recordloading}
        className="float-right"
        color="primary"
        onClick={() => {
          saveActualDates();
        }}
        disabled={!userRecord.IsOpsProjectManager}
      >
        Save
        {app.recordloading && !waveEditModal ? (
          <Spinner size="small" color="#495057" />
        ) : null}
      </Button>
    </Container>
    <Modal
      isOpen={waveEditModal}
      toggle={() => setWaveEditModal(!waveEditModal)}
      centered
    >
      <ModalHeader toggle={() => setWaveEditModal(!waveEditModal)}>
        Edit Current Wave Name
      </ModalHeader>
      <ModalBody>
        <Input
          defaultValue={
            currentWaveSpec.WaveName ? currentWaveSpec.WaveName : ""
          }
          id="WaveName"
          onChange={(e) => setEditableWavename(e.target.value)}
          placeholder="Enter Wave Name"
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          disabled={app.recordloading}
          onClick={(e) => {
            onChangeHandler(
              { target: { id: "WaveName", value: editableWaveName } },
              null,
              true
            );
          }}
        >
          Update
          {app.recordloading ? (
            <Spinner size="small" color="#495057" />
          ) : null}
        </Button>
        <Button
          color="secondary"
          disabled={app.recordloading}
          onClick={() => setWaveEditModal(false)}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  </>
  );
};

export default ReviewOperations;
