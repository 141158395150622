import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle, useCallback, useContext, createContext } from "react"
import { useDispatch, useSelector } from 'react-redux';
import axios from "../../../axios-interceptor";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";
// import 'jspdf-autotable';
import { toastr } from "react-redux-toastr";
import _ from "lodash";
import update from "immutability-helper";
import { Base64 } from "js-base64";
import autoTable from 'jspdf-autotable';
import moment from "moment-timezone";
import ct from "countries-and-timezones";

const fs = require('fs');
// import { forwardRef, useRef, useImperativeHandle } from "react"
const PdfContext = createContext(null);

export const usePdfsave = () => {
  return useContext(PdfContext);
};
var getpdf;
const PdfGenerate = React.forwardRef((props, ref, varcheck) => {
  const dispatch = useDispatch();
  props = props.props;

  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const splitdata = useSelector(
    ({ vendorReducer }) => vendorReducer.SplitData
  )

  const VendorID = props?.vendorId
  const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
  };
  const colstyle = {
    width: "30%"
  };
  const tableStyle = {
    width: "100%",
    fontSize: '10px'
  };
  const fontstyle = {
    fontFamily: "sans-serif",
    padding: "20px"
  };
  const declineproposalstyle = {
    fontFamily: "sans-serif",
    fontSize: '16px'
  };

  function returnTargetSample(coheader, mdata) {
    let list;
    let val = splitdata?.filter(a => a.MethodologySpecId == mdata.id).filter(a => {
      if (a.SampleDistributionMasters.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(s => s.id).includes(mdata.InvitedVendors[0].VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId)) { return (a); }
    })
    if (val.length > 0) {
      list = val[0].SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
        return {
          label: a.DistributionName,
          value: a.id,
          DistributionSize: a.DistributionSize
        }
      }).filter(l => l.value == mdata.InvitedVendors[0].VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId)[0]?.DistributionSize
    }
    return list
  }

  function returnDistribution(coheader, mdata) {
    var val = splitdata?.filter(a => a.MethodologySpecId == mdata.id).filter(a => {
      if (a.SampleDistributionMasters.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(s => s.id).includes(mdata.InvitedVendors[0]?.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId)) { return (a); }
    })
    var list

    if (val.length > 0) {
      list = val[0].SampleDistributionMasters?.filter(a => a.CostingOptionId == coheader.id)[0]?.SampleDistributionDetails?.map(a => {
        return {
          label: a.DistributionName,
          value: a.id,
          CoverageDetails: a.CoverageDetails
        }
      }).filter(l => l.value == mdata.InvitedVendors[0]?.VendorCosts.filter(v => v.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId)[0]?.CoverageDetails?.map(a => a.label)?.toString();
    }
    return list
  }

  function returnCost(coheader, mdata, rowdata) {
    var value = (mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == rowdata.id).length > 0 ?
      mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == rowdata.id)[0].VendorDataUSD : "")
    let OV = (mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == rowdata.id).length > 0 ?
      mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == rowdata.id)[0]?.OrignalValue : "")
    let OVLabel = (mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == rowdata.id).length > 0 ?
      mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.id == rowdata.id)[0]?.Currencylabel : "")
    var Currency = mdata?.InvitedVendors[0]?.Currency
    var ConversionRateToLocal = Currency?.label == "USD" ? 1 : Currency.ConversionRateToLocal;
    var tosend;

    if (value == "") { tosend = "" }
    else {
      if (OVLabel == Currency?.label) {
        if (!OV?.includes("."))
          tosend = Math.round(value * ConversionRateToLocal)
        else {
          if (OV?.lastIndexOf(".") == OV?.length - 1) {
            tosend = Math.round(value * ConversionRateToLocal) + "."
          }
          else {
            let fixeddata = (OV?.length - 1) - OV?.lastIndexOf(".")
            tosend = (value * ConversionRateToLocal).toFixed(fixeddata > 2 ? 2 : fixeddata)
          }
        }
      }
      else
        tosend = (value * ConversionRateToLocal).toFixed(2)
    }

    return (tosend ? (tosend + " " + Currency?.label) : tosend)
  }

  function returnTotal(coheader, mdata) {
    var total
    var VendorCurrencyConversion = 1;
    var isconvert = false;
    var Currency = mdata?.InvitedVendors[0]?.Currency

    if (Currency?.label != "USD") {
      VendorCurrencyConversion = Currency.ConversionRateToLocal
      isconvert = true;
    }
    total = mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.type != "TimingSection" && a.selected)?.map((c) => ((isNaN(c.VendorDataUSD) || c.VendorDataUSD == '') ? "0" : c.VendorDataUSD))
      .reduce((a, b) => parseFloat(a) + parseFloat(b) + 0, 0)
    total = isconvert ? (total * VendorCurrencyConversion) : total
    return (total.toFixed(2) + " " + Currency?.label)
  }

  function returnTotalCPI(coheader, mdata) {
    var total;
    var totalCPI;
    var VendorCurrencyConversion = 1;
    var isconvert = false;
    var Currency = mdata?.InvitedVendors[0]?.Currency

    if (Currency?.label != "USD") {
      VendorCurrencyConversion = Currency?.ConversionRateToLocal;
      isconvert = true;
    }

    total = mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.filter(a => a.type != "TimingSection" && a.selected)?.map((c) => ((isNaN(c.VendorDataUSD) || c.VendorDataUSD == '') ? "0" : c.VendorDataUSD))
      .reduce((a, b) => parseFloat(a) + parseFloat(b) + 0, 0)
    total = isconvert ? (total * VendorCurrencyConversion).toFixed(2) : total
    if (mdata.InvitedVendors[0]?.ServiceType != 1) {
      if (mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.AchivableSamples != "" && mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.AchivableSamples)
        totalCPI = total / parseInt(mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.AchivableSamples)
      else
        totalCPI = 0
    } else
      totalCPI = total / (coheader.CostingOptionData?.sampleSizeMain || coheader.CostingOptionData?.numberOfGroup)
    return (totalCPI.toFixed(2))
  }

  function returnVendorBidDeadline(CommissioningCountry) {
    const country_tz = ct.getCountry(CommissioningCountry);
    const timezone = ct.getTimezone(country_tz.timezones[0]);
    return `${moment(_.last(project.CostingProfiles).VendorBidDeadLine).tz(timezone.name).format('lll')} (${moment.tz(timezone.name).zoneAbbr()})`
  }

  function printPDF(id) {
    const totalCountries = project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs;
    return (
      <div className="pdfdownload" id="pdfdownload">
        <table style={tableStyle}>
          <tbody>
            <tr>
              <td>Project Name</td>
              <td>{project?.ProjectName}</td>
            </tr>
            <tr>
              <td>Project ID</td>
              <td>{project?.ProjectId}</td>
            </tr>
            <tr>
              <td>Vendor Name</td>
              {/* <td>{vendorAndList?.filter(x => x.Group == "Vendors")[0]?.List?.filter(v => v.id == VendorID)[0]?.VendorName}</td> */}
              <td>{props?.Name}</td>
            </tr>
            <tr>
              <td>Single/Multiple Country</td>
              <td>{project.CostingProfiles && project.CostingProfiles.length > 0 && project.CostingProfiles[project.CostingProfiles.length - 1].IsMultiCountry == false ? 'Single Country' : 'Multi Country'}</td>
            </tr>
            <tr>
              <td>Background</td>
              <td>{project?.ProjectBackground ? project.ProjectBackground : ''}</td>
            </tr>
            <tr>
              <td>Objectives</td>
              <td>{project?.ResearchObjectives ? project.ResearchObjectives : ''}</td>
            </tr>
            <tr>
              <td>Commissioning Country</td>
              <td>{project.CommissioningCountry && project.CommissioningCountry != "" &&
                project.CostingProfiles && project.CostingProfiles.length > 0 && project.CostingProfiles[project.CostingProfiles.length - 1].ProfileSetting.CurrenciesData &&
                project.CostingProfiles[project.CostingProfiles.length - 1].ProfileSetting.CurrenciesData.length > 0 ? project.CostingProfiles[project.CostingProfiles.length - 1].ProfileSetting.CurrenciesData?.filter(comm => comm.Code == project.CommissioningCountry)[0]?.Label : ""}</td>
            </tr>
            <tr>
              <td>Fielding Countries</td>
              <td>{project.CostingProfiles.length > 0 && project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs &&
                [...new Set(project.CostingProfiles[project.CostingProfiles.length - 1].CountrySpecs?.map((cdata, index) => cdata.MethodologySpecs?.map((mdata, msindex) => mdata.CostingType == 3 && cdata)).flatMap(fm => fm.flatMap(mf => mf.CountryName)))]?.toLocaleString()}
              </td>
            </tr>
            <tr>
              <td>Deadline For Submission</td>
              <td>{returnVendorBidDeadline(project.CommissioningCountry)}</td>
            </tr>
          </tbody>
        </table>

        <div style={fontstyle}>
          {totalCountries?.map((cdata, index) => (
            <div>
              {cdata.MethodologySpecs?.map((mdata, msindex) => (
                <div>
                  {
                    (vendorInfo(cdata, mdata, id))
                  }
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    )
  }

  function vendorInfo(cdata, mdata, vendor) {
    var RFQ = (mdata.BiddingDetail?.SelectedRFQ);
    var SUD = (mdata.BiddingDetail?.SelectedSUD);

    var RFQgroups = RFQ?.map((x =>
      RFQ?.filter(z => z.dependentOn === x.label)
    ))?.filter(m => m.length > 0);

    var SelectedRFQ = RFQ?.filter(b => !b.dependentOn);
    RFQgroups?.map((w, i) => {
      var parent = SelectedRFQ?.filter(t => t.label === w[0]?.dependentOn)
      var pind = SelectedRFQ.findIndex(c => c.label == parent[0]?.label);
      SelectedRFQ.splice(pind + 1, 0, ...RFQgroups[i])
    })

    var SUDgroups = SUD?.map((x =>
      SUD?.filter(z => z.dependentOn === x.label)
    ))?.filter(m => m.length > 0);

    var SelectedSUD = SUD?.filter(b => !b.dependentOn);
    SUDgroups?.map((w, i) => {
      var parent = SelectedSUD?.filter(t => t.label === w[0]?.dependentOn)
      var pind = SelectedSUD.findIndex(c => c.label == parent[0]?.label);
      SelectedSUD.splice(pind + 1, 0, ...SUDgroups[i])
    })
    var OPSCurrency = mdata?.BiddingDetail?.Currency;
    var currdata = [];
    var curr =
      project?.CostingProfiles[project.CostingProfiles.length - 1] &&
        project?.CostingProfiles[project.CostingProfiles.length - 1]?.CountrySpecs?.length > 0
        ? project?.CostingProfiles[
          project.CostingProfiles.length - 1
        ]?.ProfileSetting?.CurrenciesData?.find(
          (c) =>
            c.Code ==
            project?.CostingProfiles[project.CostingProfiles.length - 1]?.CountrySpecs.filter(x => x.id == cdata.id)[0]?.CountryCode
        )
        : "";
    if (curr != "") {
      curr.AllCurrencyUnit = curr?.AllCurrencyUnit.replace(
        /(\r\n|\n|\r)/gm,
        ""
      );
      var allUnits = curr?.AllCurrencyUnit
        ? curr?.AllCurrencyUnit.split(",")
        : [];
      if (allUnits.length > 0) {
        allUnits.forEach((all, i) => {
          currdata.push({
            id: i + 1,
            label: all,
            value: i + 1,
            code: curr.Code,
            VendorId: props.vendorId,
            ConversionRateToLocal: project.CostingProfiles[
              project.CostingProfiles.length - 1
            ]?.ProfileSetting.CurrenciesData.filter(
              (curr) => curr.CurrencyUnit == all
            )[0].ConversionRateToLocal,
          });
        });
      }
    }

    var defaultvalue = currdata.find((a) => a.label == curr.CurrencyUnit);
    if (!mdata.InvitedVendors[0]?.Currency) {
      mdata.InvitedVendors[0]["Currency"] = OPSCurrency ? OPSCurrency : defaultvalue
    }

    return (
      <>
        {mdata.InvitedVendors.length > 0 && <div>
          <h2>{cdata?.CountryName}</h2>
          <h3>{mdata?.Label}</h3>
          {!mdata.InvitedVendors[0].IsDeclined ?
            <div>
              {(mdata.BiddingDetail?.SelectedRFQ != "" && mdata.BiddingDetail?.SelectedRFQ != "[]" && mdata.BiddingDetail?.SelectedRFQ != null) &&
                <div>
                  <h4>Additional Information From RFQ</h4>
                  <table style={tableStyle}>
                    <tbody>
                      <tr>
                        <td>Task</td>
                        <td>Details</td>
                        <td>Comments</td>
                      </tr>
                      {SelectedRFQ?.map(rfq => (
                        <tr>
                          <td>{rfq?.label}</td>
                          <td>{rfq?.value}</td>
                          <td>{(rfq?.Comments == "") ? "" : rfq.Comments?.filter(a => a.vendorid == VendorID)[0]?.comment}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              }
              <br />
              <br />
              {(mdata.BiddingDetail?.SelectedSUD != "" && mdata.BiddingDetail?.SelectedSUD != "[]" && mdata.BiddingDetail?.SelectedSUD != null) &&
                <div>
                  <h4>Additional Information From SUD </h4>
                  <table style={tableStyle}>
                    <tbody>
                      <tr>
                        <td>Task</td>
                        <td>Details</td>
                        <td>Comments</td>
                      </tr>
                      {SelectedSUD?.map(pr => (
                        <tr>
                          <td>{pr?.label}</td>
                          <td>{pr?.value}</td>
                          <td>{(pr?.Comments == "") ? "" : pr.Comments?.filter(a => a.vendorid == VendorID)[0]?.comment}</td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </table>
                </div>
              }
              <h4>Bidding Details</h4>
              <table style={tableStyle}>
                <tbody>
                  <tr>
                    <td>Label</td>
                    <td>Value</td>
                  </tr>
                  <tr>
                    <td>QC Validation Mode</td>
                    <td>{!mdata?.BiddingDetail?.QCValidationMode ? "" : mdata.BiddingDetail?.QCValidationMode?.flatMap(x => x.label)?.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td>Currency Selection</td>
                    <td>
                      {mdata.InvitedVendors[0]?.Currency?.label}
                    </td>
                  </tr>
                  <tr>
                    <td>Other Project Specification</td>
                    <td>{
                      (mdata.BiddingDetail &&
                        mdata.BiddingDetail?.OtherProjectSpecification)
                        ? mdata.BiddingDetail?.OtherProjectSpecification
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>

              {mdata.CostingOptions?.filter(mcf => mdata.InvitedVendors[0].VendorCosts.filter(x => x.CostOptionId == mcf.id && x.IsInvited).length > 0)?.map(
                (coheader) => (
                  <>
                    <h4>{coheader?.Name}</h4>
                    <table style={tableStyle}>
                      <tr>
                        <td>Costing Parameter</td>
                        <td>{coheader?.Name}</td>
                      </tr>
                      {mdata.CostingOptions
                        && mdata.CostingOptions[0].CostingOptionSchema
                        && mdata.CostingOptions[0].CostingOptionSchema?.map((b) => (
                          <tr>
                            {<td>{b.label}</td>}
                            <td>{Array.isArray(coheader.CostingOptionData[b.id]) ? coheader.CostingOptionData[b.id]?.map(a => a.label).toString() : typeof (coheader.CostingOptionData[b.id]) === "object" ? coheader.CostingOptionData[b.id].value : coheader.CostingOptionData[b.id]}</td>

                          </tr>
                        ))}
                    </table>

                    <h4>Cost Details</h4>
                    <table style={tableStyle}>
                      <tr>
                        <td>{mdata.InvitedVendors[0].ServiceType == 0 ? "Field Cost" :
                          mdata.InvitedVendors[0].ServiceType == 1 ? "Setup & Delivery" :
                            mdata.InvitedVendors[0].ServiceType == 2 ? "Full Service" : ""}</td>

                        <td>
                          {coheader.Name}
                        </td>

                      </tr>

                      {mdata.InvitedVendors[0].SelectedRows?.filter(a => a.header && a.type != "TimingSection").map(tab => {
                        return (<>
                          {/* Service Heading */}
                          {mdata.InvitedVendors[0].ServiceType != 0 && <tr><td>{tab.header}</td>

                            <td></td>
                          </tr>
                          }

                          {tab.header == "Field Cost" &&
                            <>
                              <tr>
                                <td>Target Sample</td>

                                <td>
                                  {mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId ? returnTargetSample(coheader, mdata) :
                                    (coheader.CostingOptionData.sampleSizeMain || coheader.CostingOptionData.numberOfGroup)}

                                </td>
                              </tr>
                              <tr>
                                <td>Achievable Samples</td>

                                <td>
                                  {mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.AchivableSamples}
                                </td>
                              </tr>
                              <tr>
                                <td>CityCoverage</td>

                                <td>
                                  {mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.SampleDistributionDetailsId ? returnDistribution(coheader, mdata) :
                                    coheader.CostingOptionData["citiesCoverage"]?.map(a => a.label)?.toString()}
                                </td>
                              </tr>
                              <tr>
                                <td>Comments</td>

                                <td>
                                  {mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.Comments}
                                </td>
                              </tr>
                            </>


                          }
                          {/* Timing Section */}
                          {mdata.InvitedVendors[0].SelectedRows?.filter(a => (a.type == "TimingSection" && a.group == tab.header) || a.header == 'TIMINGS ( # of WORKING DAYS)')?.length > 0 &&
                            <tr>
                              <td>
                                TIMINGS ( # of WORKING DAYS)
                              </td>

                              <td>
                              </td>

                            </tr>}

                          {mdata.InvitedVendors[0].SelectedRows?.filter(a => (a.type == "TimingSection" && a.group == tab.header && a.selected))?.map(rowdata => {
                            return (
                              <tr>
                                <td>{rowdata?.OtherCost ? rowdata.name + " " + rowdata.OtherCost : rowdata.name}</td>

                                <td>{
                                  (mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.
                                    filter(a => a.id == rowdata.id).length > 0 ?
                                    mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.
                                      filter(a => a.id == rowdata.id)[0].VendorDataUSD != "" ?
                                      mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.
                                        filter(a => a.id == rowdata.id)[0].VendorDataUSD > 1 ?
                                        mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.
                                          filter(a => a.id == rowdata.id)[0].VendorDataUSD + " Days" :
                                        mdata.InvitedVendors[0]?.VendorCosts?.filter(a => a.CostOptionId == coheader.id)[0]?.CostingsData?.
                                          filter(a => a.id == rowdata.id)[0].VendorDataUSD + " Day"
                                      : "" : "")
                                }
                                </td>

                              </tr>)
                          })}

                          {/* Costing */}
                          <tr>
                            <td>
                              Costing Section
                            </td>
                            <td>
                            </td>

                          </tr>
                          {mdata.InvitedVendors[0].SelectedRows?.filter(a => (a.group == tab.header) && a.id != 14 && a.type != "TimingSection" && !a.header && a.selected).map(rowdata => {
                            return (
                              <tr>
                                <td>{rowdata?.OtherCost ? rowdata.name + " " + rowdata.OtherCost : rowdata.name}</td>


                                <td>{returnCost(coheader, mdata, rowdata)}
                                </td>

                              </tr>)
                          })}
                        </>)
                      })}

                      <>
                        <tr>
                          <td >
                            Total Outsourced Vendor Data collection with QC (External)
                          </td>

                          <td> {returnTotal(coheader, mdata)} </td>

                        </tr>
                        <tr>
                          <td>
                            Total Outsourced Vendor Data collection with QC (External) CPI
                          </td>
                          <td> {returnTotalCPI(coheader, mdata)} </td>

                        </tr>
                      </>
                    </table>
                  </>))}
              {
                <table style={tableStyle}>
                  <tr><td>Comments (In General)</td></tr>
                  <tr><td>{mdata.InvitedVendors[0]?.Comments}</td></tr>
                </table>
              }

            </div> :
            <div>
              <div style={declineproposalstyle}><b>Declined the Proposal for this Methodology</b></div>
              <br />
              <table style={tableStyle}>
                <tr><td>Name</td><td>{mdata.InvitedVendors[0]?.DeclineInfo?.Name}</td></tr>
                <tr><td>Email Address</td><td>{mdata.InvitedVendors[0]?.DeclineInfo?.Email}</td></tr>
                <tr><td>Reason</td><td>{mdata.InvitedVendors[0]?.DeclineInfo?.Reason}</td></tr>
              </table>
            </div>
          }
        </div>}
      </>
    )

  }
  function Download(id, key, val) {
    const p1 = renderToString(printPDF(id));
    const pdf = new jsPDF(key, "px", "a4");
    const marginsleft = 50;
    const marginstop = 30;
    //pdf.fromHTML(p1, marginsleft, marginstop);
    pdf.autoTable({
      html: '#pdfdownload',
      startY: 60,
      styles: {
        fontSize: 50,
        cellWidth: 'wrap'
      },
      columnStyles: {
        1: { columnWidth: 'auto' }
      }
    });
    pdf.fromHTML(p1, marginsleft, marginstop);
    let pdfoutput = pdf.output();
    let base64string = Base64.encode(pdfoutput);
    //console.log(base64string);
    // props['MailPdfString'] = base64string;
    if (val == 1) { return base64string; }
    else {
      pdf.save((props.Name)?.replace(".", "_") + "_" + props?.projectId);
    }
  };
  React.useImperativeHandle(ref, () => ({
    async PrintDocument1(props) {
      Download(props.vendorId, "2", 2);
    },
    async Textpdf(props) {
      return Download(props.vendorId, "1", 1);
    }
  }))


  return (
    <>
    </>
  )
})
export default PdfGenerate;