import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../layouts/Project";
import { getLabel } from "../../utils/codeLabels";
import { useParams } from "react-router-dom";

import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    CardTitle,
    CardHeader
} from "reactstrap";
import _ from "lodash";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import { useHistory } from "react-router";
import ProjectDeliveryDates from "../projectSchedule/ProjectDeliveryDates";

const ProjectReview = () => {
    let { profileId } = useParams();

    const app = useSelector(({ app }) => app);
    const currentCostingProfile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
    );

    const userRecord = useSelector(({ user }) => user.userRecord);
    const dispatch = useDispatch();
    const history = useHistory();
    const [calledProfile, setCalledProfile] = useState(false)
    const currentProject = useSelector(({ currentProject }) => currentProject.newProject);

    const canRenderReview = () => {
        // if (Object.keys(currentProject.ProjectManagerEmail).length)
        return _.includes(currentProject.ProjectManagerEmail?.map(pm => pm?.value?.toLowerCase()), userRecord.Email?.toLowerCase())
    }
    useEffect(() => {
        if (userRecord && !userRecord.AllActionsAccess && !userRecord.ProjectReviewAccess) {
            if (currentProject.ProposalOwnerEmail?.value?.toLowerCase() !== userRecord.Email?.toLowerCase())
                history.replace("/");
        }
    }, [currentProject]);

    useEffect(() => {
        if (profileId && !calledProfile) {
            setCalledProfile(true);
            dispatch(currentCostingActions.getCosting(profileId, null, true));
        } else if (!profileId) {
            //console.log("boots to dashboard");
            history.replace("/");
        }
    }, []);

    return (currentCostingProfile && currentCostingProfile.ProfileSetting) ? <Layout
        hideActions={true}
        profileStatusToDisplay={getLabel(
            "CostingStatusOptions",
            currentCostingProfile.ProfileStatus
        )}
        projectStatusToDisplay={getLabel(
            "ProjectStatusOptions",
            currentCostingProfile.Project?.ProjectStatus
        )}
        avoidEdit={true}
    >
        <Container>
            <Card>
                <CardHeader>
                    <Row>
                        <Col lg="12" md="12">
                            <h4>Project Review</h4>
                        </Col>
                    </Row>
                </CardHeader>
            </Card>

            {/* Name
                    {currentCostingProfile.Project.ProjectName} */}
            <ProjectDeliveryDates />
            {/* {userRecord} */}

        </Container>
    </Layout> : null
}

export default ProjectReview