import React from "react";

function Right() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000">
          <path d="M8.29289322 5.70710678L9.70710678 4.29289322 17.4142136 12 9.70710678 19.7071068 8.29289322 18.2928932 14.585 12z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Right;