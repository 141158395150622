import axios from "../../../axios-interceptor";
export const MSD_SALES_FORCE = "MSD_SALES_FORCE";
export const MSD_SALES_FORCE_DONE = "MSD_SALES_FORCE_DONE";

export const msdSalesForceAction = (OopId) => {

  return (dispatch) => {
    dispatch({  type: MSD_SALES_FORCE});
    axios
      .get(`salesforce/${OopId}`)
      .then((res) => {
        dispatch({  type: MSD_SALES_FORCE_DONE, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: MSD_SALES_FORCE_DONE, payload: null, errVal: true });
      });
  };
};


  

