import React from "react";

function Up() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000">
          <path d="M12 6.58578644L19.7071068 14.2928932 18.2928932 15.7071068 12 9.415 5.70710678 15.7071068 4.29289322 14.2928932z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Up;