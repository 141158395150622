import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Layout from "../../layouts/RequestsBoardLayout";
import axios from "../../axios-interceptor";
import {
  FormControl,
  FormHelperText,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
} from "@material-ui/core";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Navbar from "../../components/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faCircle,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCheckCircle as faCheckCircleNew
} from "@fortawesome/free-regular-svg-icons";
import { format } from "date-fns";
import logo from "../../assets/img/avatars/nielsen-logo.png";
import { recordLoadEnd, recordLoadStart } from "../../redux/actions/appActions";
import { toastr } from "react-redux-toastr";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  textarea: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    "&:focus": {
      borderColor: "#3f51b5",
      outline: "none",
    },
  },
}));

const initialFormState = {
  Details: "",
  Validity: "",
};

const ManageAlerts = () => {
  const recordloading = useSelector(({ app }) => app.recordloading);
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [activateIndex, setActivateIndex] = useState(null);
  const [formData, setFormData] = useState(initialFormState);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      dispatch(recordLoadStart());
      await axios
        .get("/Notifications/default")
        .then((res) => {          
          let activatedAlerts = res?.data?.Data?.Alerts?.filter((alert) => alert.IsActivate);
          if(activatedAlerts.length) {
            const validity = activatedAlerts[0]?.Validity;
            const date = activatedAlerts[0]?.ActivationTime;
            const isValidAlert = validateDatePlusHours(date, validity);
            if(!isValidAlert) {
              const updatedAlert = res?.data?.Data?.Alerts?.map((activatedAlert) => {
                if(activatedAlert?.IsActivate)
                  return {...activatedAlert, IsActivate: false};
                else 
                  return activatedAlert;
              });
              setRows(updatedAlert.reverse());
              dispatch(recordLoadEnd());
            } else {
              res?.data?.Data?.Alerts?.reverse();          
              setRows(res?.data?.Data?.Alerts);
              dispatch(recordLoadEnd());
            }            
          } else {
            res?.data?.Data?.Alerts?.reverse();          
            setRows(res?.data?.Data?.Alerts);
            dispatch(recordLoadEnd());
          }
        })
        .catch((error) => {
          console.error("There was an error updating the row!", error);
          dispatch(recordLoadEnd());
        });
    }
    fetchData();
  }, []);

  const validateDatePlusHours = (date, validity) => {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + validity);
    const now = new Date();
    return newDate > now;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.Details || formData.Details === "") tempErrors.Details = "Detail is required";
    if (!formData.Validity || formData.Validity === "") {
      tempErrors.Validity = "Validity is required";
    } else if (formData.Validity > 12 || formData.Validity < 1) {
      tempErrors.Validity = "Please enter a number between 1 and 12";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    if (validate()) {
      dispatch(recordLoadStart());
      await axios
        .put("/Notifications/Create", [formData], {
          headers: { "auth-token": localStorage.getItem("auth-token") },
        })
        .then((res) => {
          if (editIndex !== null) {
            const updatedRows = rows.map((row, index) =>
              index === editIndex ? formData : row
            );
            setRows(updatedRows);
            setEditIndex(null);
            toastr.success("Data updated successfully");
          } else {
            toastr.success("Data added successfully");
            setRows([res?.data?.Data[0], ...rows]);
          }
          dispatch(recordLoadEnd());
        })
        .catch((error) => {
          console.error("There was an error updating the row!", error);
          toastr.error("Failed");
          dispatch(recordLoadEnd());
        });
      setFormData(initialFormState);
      setErrors({});
      setEditModal(false);
    }
  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
    setDeleteModal(!deleteModal);
  };

  const handleActivate = (index) => {
    setActivateIndex(index);
    setActivateModal(!activateModal);
  };
  const handleEdit = (index) => {
    setErrors({});
    setFormData(rows[index]);
    setEditModal(!editModal);
    setEditIndex(index);
  };

  const confirmDelete = async () => {
    dispatch(recordLoadStart());
    await axios
      .delete(`/Notifications/${rows[deleteIndex].id}`)
      .then(() => {
        const updatedRows = rows.filter(
          (_, rowIndex) => rowIndex !== deleteIndex
        );
        setRows(updatedRows);
        setDeleteModal(false);
        toastr.success("Data deleted successfully");
        dispatch(recordLoadEnd());
      })
      .catch((error) => {
        console.error("There was an error deleting the row!", error);
        toastr.error("Failed");
        dispatch(recordLoadEnd());
      });
  };

  const confirmActivate = async () => {
    rows[activateIndex].IsActivate = !rows[activateIndex].IsActivate;
    if (rows[activateIndex]?.IsActivate) {
      rows[activateIndex].ActivationTime = new Date();
    }

    const finalRows = Array.from(rows);
    finalRows.map((row, index) =>
      activateIndex !== index
        ? (row.IsActivate = false)
        : (row.IsActivate = true)
    );

    dispatch(recordLoadStart());

    await axios
      .put("/Notifications/Create", finalRows, {
        headers: { "auth-token": localStorage.getItem("auth-token") },
      })
      .then((res) => {
        // setRows([...rows]);
        if (rows[activateIndex]?.IsActivate) {
          toastr.success("Alert activated successfully");
        } else {
          toastr.success("Alert deactivated successfully");
        }
        dispatch(recordLoadEnd());
        setActivateModal(false);
      })
      .catch((error) => {
        console.error("There was an error updating the row!", error);
        toastr.error("Failed");
        dispatch(recordLoadEnd());
      });
  };

  const confirmDeactivate = async () => {
    rows[activateIndex].IsActivate = !rows[activateIndex].IsActivate;
    // if (rows[activateIndex]?.IsActivate) {
    //   rows[activateIndex].ActivationTime = new Date();
    // }

    // const finalRows = Array.from(rows);
    // finalRows.map((row, index) =>
    //   activateIndex !== index
    //     ? (row.IsActivate = false)
    //     : (row.IsActivate = true)
    // );

    dispatch(recordLoadStart());

    await axios
      .put("/Notifications/Create", [rows[activateIndex]], {
        headers: { "auth-token": localStorage.getItem("auth-token") },
      })
      .then((res) => {
        // setRows([...rows]);
        if (rows[activateIndex]?.IsActivate) {
          toastr.success("Alert activated successfully");
        } else {
          toastr.success("Alert deactivated successfully");
        }
        dispatch(recordLoadEnd());
        setActivateModal(false);
      })
      .catch((error) => {
        console.error("There was an error updating the row!", error);
        toastr.error("Failed");
        dispatch(recordLoadEnd());
      });
  };

  const formatUTCDate = (date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <Layout navbar={<Navbar headerTitle="Manage Alerts" header_nav={true} />}>
      {recordloading ? <div id="pageCoverSpin"></div> : null}
      <Card>
        <CardHeader
          style={{
            position: "sticky",
            // top: "20%",
            // zIndex: "9999",
            // marginTop: "60px",
            marginBottom:"30px"
          }}
        >
          <div
            column-gap="80px"
            style={{
              marginLeft: "70%",
              marginBottom: "85px",
            }}
          ></div>
          <div className="col-md-8 col-md-offset-2">
            <form noValidate autoComplete="off">
              <Row>
                <Col>
                  <FormControl
                    className={classes.formControl}
                    error={!!errors.Details}
                  >
                    <TextareaAutosize
                      placeholder="Enter Details"
                      label="Details"
                      name="Details"
                      onChange={handleChange}
                      value={formData.Details}
                      className={classes.textarea}
                      minRows={6}
                    />
                    <FormHelperText>{errors.Details}</FormHelperText>
                  </FormControl>
                </Col>
                <Col>
                  <TextField
                    type="number"
                    name="Validity"
                    label="Validity (In Hours)"
                    placeholder="Enter Validity(in hours)"
                    onChange={handleChange}
                    value={formData.Validity}
                    error={!!errors.Validity}
                    helperText={errors.Validity}
                    inputProps={{ min: 1, max: 12 }}
                    fullWidth
                  />
                </Col>
                <Col>
                  <Button
                    // type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleSubmit}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </CardHeader>
        <CardBody
          style={
            {
              // position: "sticky",
              // top: "20%",
              // zIndex: "9999",
              // marginTop: "120px",
            }
          }
        >
          <TableContainer component={Paper} style={{maxHeight: "380px", overflow: "auto"}}>
            <Table className={classes.table} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Details</TableCell>
                  <TableCell>Validity (In Hours)</TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell>Activation Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {row.Date
                        ? format(new Date(row.Date), "dd-MMM-yyyy")
                        : format(new Date(), "dd-MMM-yyyy")}
                    </TableCell>
                    <TableCell dangerouslySetInnerHTML={{__html:row.Details}}></TableCell>
                    <TableCell>{row.Validity}</TableCell>
                    <TableCell>
                      <FontAwesomeIcon
                        icon={faPen}
                        className="ic-hover pointer"
                        fixedWidth
                        title="Edit Alert"
                        style={{ marginRight: "20px" }}
                        onClick={() => handleEdit(index)}
                        size="lg"
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="ic-hover pointer"
                        fixedWidth
                        title="Delete Alert"
                        style={{ marginRight: "20px", 
                          color: row?.IsActivate ? "gray" : "black", 
                          cursor: row?.IsActivate ? "not-allowed" : "pointer",
                          // opacity: row?.IsActivate ? 0.5 : 1,
                          pointerEvents: row?.IsActivate ? 'none' : 'auto'  
                        }}
                        onClick={() => handleDelete(index)}
                        size="lg"
                      />
                      <FontAwesomeIcon
                        icon={row?.IsActivate ? faCheckCircle : faCheckCircleNew}
                        className="ic-hover pointer"
                        fixedWidth
                        title={
                          row?.IsActivate
                            ? "Deactivate Alert"
                            : "Activate Alert"
                        }
                        style={{ marginRight: "20px" }}
                        onClick={() => handleActivate(index)}
                        size="lg"
                      />
                    </TableCell>
                    <TableCell>
                      {row?.ActivationTime
                        ? formatUTCDate(new Date(row?.ActivationTime))
                        : ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>
      <Modal
        isOpen={editModal}
        toggle={() => setEditModal(false)}
        className="modal-lg"
        centered={true}
      >
        <ModalHeader toggle={() => {
          setEditModal(false);
          setFormData(initialFormState);
        }}>
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <div>
          <span className="modaltitle modaltitleDisplay">Update Alerts</span>
        </div>

        <ModalBody style={{ "margin-left": "10px" }}>
          {/* {console.log(rows)} */}
          {/* {rows.length && ( */}
          <form noValidate autoComplete="off">
            <Row>
              <Col>
                <FormControl
                  className={classes.formControl}
                  error={!!errors.Details}
                >
                  <TextareaAutosize
                    placeholder="Enter Details"
                    label="Details"
                    name="Details"
                    onChange={handleChange}
                    value={formData?.Details}
                    className={classes.textarea}
                    minRows={6}
                  />
                  <FormHelperText>{errors.Details}</FormHelperText>
                </FormControl>
              </Col>
              <Col>
                <TextField
                  type="number"
                  name="Validity"
                  label="Validity"
                  placeholder="Enter Validity"
                  onChange={handleChange}
                  value={formData?.Validity}
                  error={!!errors.Validity}
                  helperText={errors.Validity}
                  inputProps={{ min: 1, max: 12 }}
                  fullWidth
                />
              </Col>
            </Row>
          </form>
          {/* )} */}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setEditModal(false);
              setFormData(initialFormState);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        className="modal-sm"
        centered={true}
      >
        <ModalHeader>
           {/* toggle={() => setDeleteModal(false)}> */}
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <ModalBody>
          <p>
            <strong>Are you sure, you want to delete this row?</strong>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              confirmDelete();
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={activateModal}
        toggle={() => setActivateModal(!activateModal)}
        className="modal-sm"
        centered={true}
      >
        <ModalHeader>
           {/* toggle={() => setActivateModal(false)}> */}
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <ModalBody>
          <p>
            <strong>
              Are you sure, you want to 
              {rows[activateIndex]?.IsActivate
                ? " deactivate "
                : " activate "}
              this Alert?
            </strong>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              rows[activateIndex]?.IsActivate ? confirmDeactivate() : confirmActivate();
            }}
          >
            {/* {rows[activateIndex]?.IsActivate ? "Deactivate" : "Activate"} */}
            Yes
          </Button>
          <Button
            onClick={() => {
              setActivateModal(false);
            }}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      {/* <Button color="primary" onClick={() => setOpenFeeds(false)}>
        Save
    </Button>
    <Button color="secondary" onClick={() => setOpenFeeds(false)}>
        Cancel
    </Button> */}
    </Layout>
  );
};

export default ManageAlerts;