import React, { useState, useEffect } from 'react'
import OverrideCosts from './OverrideCosts'
import {
    Col, Row, Card,
    CardHeader, Nav, NavItem, NavLink, TabContent, TabPane,
    Table, Button,
    Container,
    CustomInput,
} from "reactstrap";
import axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import * as appActions from "../../redux/actions/appActions";

import classnames from "classnames";
import _ from "lodash";
import Layout from "../../layouts/Project";
import { getLabel } from "../../utils/codeLabels";
import OverrideCostsNew from './OverrideCostsNew';
import { useDispatch, useSelector } from "react-redux";

import Selector from "../../components/Selector/Selector_Wavelevel";

import { saveCostingProfile, updateProfile } from "../../redux/actions/currentCostingActions";
import Spinner from "../../components/Spinner";
import Select from "react-select";
const CountryLevelOverrideCosts = () => {

    const currentCostingProfile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
    );
    const [profile] = useState(currentCostingProfile);
    const [wavesData, setWavesData] = useState(currentCostingProfile.WaveSpecs)
    const [activeTabFC, setActiveTabFC] = useState();
    const [activeTabSM, setActiveTabSM] = useState();
    const [waveBreakDown, setWaveBreakDown] = useState({})
    const [currentWaveSpec, setCurrentWaveSpec] = useState({});
    const [showManualCostEntry, setShowManualCostEntry] = useState(false);
    const [showSheetsCosts, setShowSheetsCosts] = useState(false);
    const [showCostMethod, setShowCostMethod] = useState(false);
    const dispatch = useDispatch();
    const [modifiedMeths, setModifiedMeths] = useState([])
    const [activeWave, setActiveWave] = useState("1");

    const currencies = useSelector(({ currentCosting }) =>
        currentCosting.currentCostingProfile.ProfileSetting &&
            currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
            ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
            : []
    );

    const app = useSelector(({ app }) => app);

    const [currentCurrency, setCurrentCurrency] = useState();
    useEffect(() => {
        if (currentCostingProfile &&
            wavesData
            && wavesData.length
            && !Object.keys(waveBreakDown).length
        ) {

            let countryData = _.head(profile.CountrySpecs);

            let mData = countryData.MethodologySpecs?.filter(ms => !ms.NotApplicable);
            let methodologyID=_.head(mData).id

            setActiveTabFC(countryData.id);
            setActiveTabSM(methodologyID);
            let _waveBreakdownMain = wavesData.filter(x => x.MethodologySpecId === methodologyID)
            let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
            setModifiedMeths((_waveBreakdownMain))

            Object.keys(_waveBreakdown).map(w => {
                _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
            });
            setWavesData(wavesData);
            setWaveBreakDown(_waveBreakdown)
            let _currentwaveset = _.head(_waveBreakdown[activeWave])
            setCurrentWaveSpec(_currentwaveset);
        }
    }, [currentCostingProfile])

    useEffect(() => {
        if (
            currencies &&
            !currentCurrency &&
            currentCostingProfile.CostInputCurrency
        ) {
            let values = currentCostingProfile.CostInputCurrency.split("-");
            let currencyUnit = _.last(values);
            let countryCode = _.head(values);
            let finalCurrency = _.head(
                currencies.filter(
                    (cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
                )
            );
            setCurrentCurrency(finalCurrency);
        }
    }, [currentCostingProfile]);

    const [overRideFields, setOverrideFields] = useState({
        OverrideOpsPMCost: {
            //rfq
            Internal: "CostIntOpsPM",
            External: "",
            Label: "Project Management (Ops Only)",
        },
        OverrideSurveyProgrammingCost: {
            decisiveField: "surveyProgrammingResource",
            Internal: "CostIntOpsSurveyProgramming",
            External: "CostExtOpsSurveyProgramming",
            Label: "Survey Programming",
        },
        OverrideDataProcessingCost: {
            decisiveField: "dataProcessingResource",
            Internal: "CostIntOpsDataProcessing",
            External: "CostExtOpsDataProcessing",
            Label: "Data Processing",
        },

        OverrideChartingCost: {
            decisiveField: "chartingResource",
            Internal: "CostIntOpsCharting",
            External: "CostExtOpsCharting",
            Label: "Charting",
        },
        OverrideCodingCost: {
            decisiveField: "verbatimCodingResource",
            Internal: "CostIntOpsVerbatimCoding",
            External: "CostExtOpsVerbatimCoding",
            Label: "Verbatim Coding",
        },
        OverrideCodingCost: {
            decisiveField: "codingResource",
            Internal: "CostIntOpsVerbatimCoding",
            External: "CostExtOpsVerbatimCoding",
            Label: "Coding",
        },
        OverrideDataEntryCost: {
            decisiveField: "dataEntryResource",
            Internal: "CostIntOpsDataEntry",
            External: "CostExtOpsDataEntry",
            Label: "Data Entry",
        },

        OverrideOnlineSampleCost: {
            //rfq
            Internal: "",
            External: "CostExtOpsOnlineSample",
            Label: "Online Sample",
        },

        OverrideAdditionalOperationsSupportCost: {
            //bool
            decisiveField: "additionalOperationsSupport",
            Internal: "CostIntOpsAdditionalOperationsSupport",
            External: "",
            Label: "Additional Operations Support (PM Rates)",
            isBool: true,
        },
        OverrideOtherDataPreparationCost: {
            //bool
            decisiveField: "otherDataPreparationAssistance",
            Internal: "CostIntOpsOtherDataPreparation",
            External: "",
            Label: "Other Data Preparation",
            isBool: true,
        },
        OverrideDataScienceCost: {
            //internal-operations//bool
            decisiveField: "dataScienceRequired",
            Internal: "CostIntOpsDataScience",
            External: "",
            Label: "Data Science (Ops Only)",
            isBool: true,
        },
        OverrideTextAnalyticsCost: {
            decisiveField: "textAnalytics", //bool
            Internal: "",
            External: "CostExtOpsTextAnalytics",
            Label: "Text Analytics",
            isBool: true,
        },

        OverrideHostingCost: {
            //rfq
            Internal: "",
            External: "CostExtOpsHosting",
            Label: "Online Hosting",
        },
    });

    const getDecisiveFieldValue = (orf) => {
        if (overRideFields[orf].decisiveField && !overRideFields[orf].isBool) {
            return currentWaveSpec.OpsResourcesData
                ? currentWaveSpec.OpsResourcesData[overRideFields[orf].decisiveField]
                : null;
        } else if (
            overRideFields[orf].decisiveField &&
            overRideFields[orf].isBool
        ) {
            if (
                currentWaveSpec.OpsResourcesData &&
                currentWaveSpec.OpsResourcesData[overRideFields[orf].decisiveField]
            ) {
                if (overRideFields[orf].Internal) return "Internal";
                else return "External";
            } else return null;
        } else {
            if (overRideFields[orf].Internal) return "Internal";
            else return "External";
        }
    };
    const getCurrentCurrency = (actualvalue) => {
        if (
            currencies &&
            currentCurrency &&
            currentCurrency.ConversionRateToLocal
        ) {
            if (actualvalue) {
                let finalVal = _.round(
                    actualvalue * currentCurrency.ConversionRateToLocal,
                    2
                );
                return `${finalVal} ${currentCurrency.CurrencyUnit}`;
            } else return `0 ${currentCurrency.CurrencyUnit}`;
        }
    };
    const getCurrentCurrencyValue = (actualvalue) => {
        if (
            currencies &&
            currentCurrency &&
            currentCurrency.ConversionRateToLocal
        ) {
            if (actualvalue) {
                let finalVal = _.round(
                    actualvalue * currentCurrency.ConversionRateToLocal,
                    2
                );
                return finalVal;
            } else return 0;
        }
    };
    const onNotesHandler = (eve) => {
        let editablewavespec = { ...currentWaveSpec };
        if (!editablewavespec.CostOverrideNotes)
            editablewavespec.CostOverrideNotes = {};
        editablewavespec.CostOverrideNotes[eve.target.id] = eve.target.value;
        setCurrentWaveSpec({ ...editablewavespec, CostOverrideNotes: { ...editablewavespec.CostOverrideNotes } });
        let data = { ...editablewavespec, CostOverrideNotes: { ...editablewavespec?.CostOverrideNotes } }
        addingToWavesData(data);
    };
    const onCurrencyChange = (eve) => {
        let editablewavespec = { ...currentWaveSpec };
        editablewavespec[eve.target.id] = eve.target.value / currentCurrency.ConversionRateToLocal;
        setCurrentWaveSpec({ ...editablewavespec });
        let data = { ...editablewavespec }
        addingToWavesData(data);
    }

    const addingToWavesData = data => {
        var foundIndex = wavesData.findIndex(x => x.id == data?.id);
        wavesData[foundIndex] = data;
        setWavesData(wavesData);
    }
    const onOverrideCheckChange = (eve) => {
        let editablewavespec = { ...currentWaveSpec };
        let trimmedUniqID = eve.target.id;
        editablewavespec[trimmedUniqID] = eve.target.checked;
        if (eve.target.checked) {
            //no action
        } else {
            let reqProp = overRideFields[trimmedUniqID];
            if (
                editablewavespec[reqProp.Internal] ==
                currentWaveSpec[reqProp.Internal] ||
                editablewavespec[reqProp.External] == currentWaveSpec[reqProp.External]
            ) {
                editablewavespec[reqProp.Internal] = currentWaveSpec[reqProp.Internal];
                editablewavespec[reqProp.External] = currentWaveSpec[reqProp.External];
                if (currentWaveSpec.CostOverrideNotes)
                    currentWaveSpec.CostOverrideNotes[trimmedUniqID] = null;
            } else {
                editablewavespec[reqProp.Internal] = currentWaveSpec[reqProp.Internal];
                editablewavespec[reqProp.External] = currentWaveSpec[reqProp.External];
            }
        }
        setCurrentWaveSpec({ ...editablewavespec });

        let sampleData = { ...editablewavespec };
        addingToWavesData(sampleData);

    };
    const submitCurrentWaveSpec = () => {
        profile.WaveSpecs = wavesData;
        dispatch(appActions.pageLoadStart())

        axios
            .put("/waves/savewaves", { ...profile })
            .then((res) => {
                toastr.success("Save Success", res.data.message);
                dispatch(appActions.pageLoadEnd())
            })
            .catch((err) => {
                toastr.error("Save Failed", err?.data.error);
                dispatch(appActions.pageLoadEnd())
            });

    };
    const applyAllWaves = () => {
        let wavespecsData = _.cloneDeep(wavesData)
        const data = wavespecsData.map(x => {
            if (x.MethodologySpecId === currentWaveSpec.MethodologySpecId) {
                let { WaveNumber, id, ...checkdata } = x;
                x = { ...checkdata, ...currentWaveSpec };
                x.WaveNumber = WaveNumber;
                x.id = id;
                return x
            }
            return x;
        })
        setWavesData(data);
        toastr.success("Success", "Applied to all the Waves");

    };

    const selectorHandler = (item) => {
        setActiveWave(item.WaveNumber);
        let _waveBreakdownMain = _.cloneDeep(wavesData.filter(x => x.MethodologySpecId === activeTabSM))
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
        Object.keys(_waveBreakdown).map(w => {
            _waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
        })
        setModifiedMeths((_waveBreakdownMain))

        if (item.id === currentWaveSpec.id) return;
        else {
            let data = _.head(_waveBreakdown[item.WaveNumber])
            setCurrentWaveSpec(data);
        }
    };

    const renderSelector = () => {
        if (!wavesData || (wavesData && wavesData.length === 1)) return null;
        return (
            <Col lg="2" md="2" sm="12" xs="12">
                <Selector
                    heading={"Waves"}
                    records={modifiedMeths}
                    applyAll={applyAllWaves}
                    applyAllText={"Apply to All Waves"}
                    clicked={selectorHandler}
                    selected={currentWaveSpec}
                    interPolField={["WaveNumber", "WaveName"]}

                />
            </Col>
        );
    };
    const summaryCurrencyChange = (value) => {
        let editableprofile = {
            ...currentCostingProfile,
            CountrySpecs: [...currentCostingProfile.CountrySpecs],
            CostInputCurrency: value.value,
            currencies,
        };
        let reqValue = value.value;
        let values = reqValue.split("-");
        let currencyUnit = _.last(values);
        let countryCode = _.head(values);
        let finalCoversionUnit = "";
        if (currencies && currencies.length) {
            let finalCurr = _.head(
                currencies.filter(
                    (cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
                )
            );
            setCurrentCurrency({ ...finalCurr });
            finalCoversionUnit = finalCurr.ConversionRateToLocal;
        }

        dispatch(updateProfile({ ...editableprofile }));
    };
    const getCurrentCurrencyUnit = (currencyInput) => {
        if (!currencyInput) currencyInput = "US-USD";
        let values = currencyInput.split("-");
        let currencyUnit = _.last(values);
        let countryCode = _.head(values);
        let finalCurrency = null;
        if (currencies && currencies.length) {
            finalCurrency = _.head(
                currencies.filter(
                    (cur) => cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
                )
            );
            finalCurrency = {
                value: `${finalCurrency.Code}-${finalCurrency.CurrencyUnit}`,
                label: `${finalCurrency.Label} (1USD = ${finalCurrency.ConversionRateToLocal}${finalCurrency.CurrencyUnit})`,
            };
        }
        return finalCurrency;
    };

    const toggleFC = (tab) => {
        if (activeTabFC !== tab) {
            setActiveTabFC(tab);
            let cData = profile.CountrySpecs.filter(x => x.id === tab);
            let methData=_.head(cData);

            let mData = methData.MethodologySpecs?.filter(ms => !ms.NotApplicable);
            let methodologyID=_.head(mData).id
            setActiveTabSM(methodologyID)
            onMethodologyChange(methodologyID)
        }
    };

    const toggleSM = (tab) => {
        if (activeTabSM !== tab) {
            setActiveTabSM(tab);
            onMethodologyChange(tab)
        }
    };
    const onMethodologyChange = (methID) => {
        let _waveBreakdownMain = wavesData?.filter(x => x.MethodologySpecId === methID)
        let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
        setWaveBreakDown(_waveBreakdown);
        setModifiedMeths((_waveBreakdownMain))
        let data = _.head(wavesData.filter(cws => cws.MethodologySpecId == methID))
        setCurrentWaveSpec(data);
    }
    return (
        <Layout
            costMethod={currentCostingProfile.CostingType}
            setShowCostMethod={setShowCostMethod}
            showManualCostEntry={showManualCostEntry}
            setShowManualCostEntry={setShowManualCostEntry}
            showSheetsCosts={showSheetsCosts}
            setShowSheetsCosts={setShowSheetsCosts}
            profileStatusToDisplay={getLabel(
                "CostingStatusOptions",
                currentCostingProfile.ProfileStatus
            )}
            projectStatusToDisplay={getLabel(
                "ProjectStatusOptions",
                currentCostingProfile.Project?.ProjectStatus
            )}>
            <Row>
                <Col md={12} className={'countryTabs tab-vertical'}>

                    <div className={"tab mt-5"}>
                        <Nav tabs>
                            {profile.CountrySpecs &&
                                profile.CountrySpecs.map((fc) => (
                                    <NavItem key={fc.value}>
                                        <NavLink className={classnames({ active: activeTabFC === fc.id })}
                                            onClick={() => {
                                                toggleFC(fc.id);
                                            }}
                                        >
                                            <b>{fc.CountryName}</b>
                                        </NavLink>
                                    </NavItem>
                                ))}
                        </Nav>
                        <TabContent activeTab={activeTabFC}>
                            {profile.CountrySpecs &&
                                profile.CountrySpecs.map((fc, indxFC) => (
                                    activeTabFC === fc.id &&
                                    <TabPane tabId={fc.id} key={fc.value}>
                                        <Row>
                                            <Col md={12} className={"methodologyTabs"}>
                                                <Nav tabs>
                                                    {fc.MethodologySpecs &&
                                                        fc.MethodologySpecs.filter(x => !x.NotApplicable).map((sm, indxSM) =>
                                                        (
                                                            <NavItem key={sm.value}>
                                                                <NavLink className={classnames({ active: activeTabSM === sm.id })}
                                                                    onClick={() => {
                                                                        toggleSM(sm.id);
                                                                    }}
                                                                >
                                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                        <div>
                                                                            <b>{sm.Label}</b>
                                                                        </div>

                                                                    </div>
                                                                </NavLink>
                                                            </NavItem>
                                                        ))}
                                                </Nav>

                                                <TabContent activeTab={activeTabSM} style={{ padding: "0.25" }}>
                                                    {Object.keys(waveBreakDown).length
                                                        ?
                                                        //    <OverrideCostsNew 
                                                        //    // currentWaveSpec={currentWaveSpec} 
                                                        //    waveBreakDown={waveBreakDown}
                                                        //    wavesDataData={wavesData}
                                                        //    />
                                                        <Row>
                                                            <Container fluid>
                                                                <Card className="p-4">
                                                                    <CardHeader>
                                                                        <h3>Override Auto Costs</h3>
                                                                    </CardHeader>
                                                                    <Row>
                                                                        {renderSelector()}
                                                                        <Col>
                                                                            <Table className="override-main-table">
                                                                                {/* <Row className="h4 mb-5"> */}
                                                                                <thead>
                                                                                    <th>Costing Component</th>
                                                                                    <th>Override Auto Calculations</th>
                                                                                    <th>Notes</th>
                                                                                    <th>Amount <Select
                                                                                        // isDisabled={profile.ProfileStatus > 1}
                                                                                        placeholder="Select a Currency..."
                                                                                        options={currencies?.map((c) => {
                                                                                            return {
                                                                                                value: `${c.Code}-${c.CurrencyUnit}`,
                                                                                                label: `${c.Label} (1USD = ${c.ConversionRateToLocal}${c.CurrencyUnit})`,
                                                                                            };
                                                                                        })}
                                                                                        value={
                                                                                            currentCostingProfile.CostInputCurrency
                                                                                                ? getCurrentCurrencyUnit(
                                                                                                    currentCostingProfile.CostInputCurrency
                                                                                                )
                                                                                                : null
                                                                                        }
                                                                                        onChange={summaryCurrencyChange}
                                                                                    /></th>
                                                                                </thead>
                                                                                {/* </Row> */}
                                                                                <tbody>
                                                                                    {Object.keys(overRideFields).map((orf, index) => {
                                                                                        return getDecisiveFieldValue(orf) ? (
                                                                                            <tr>
                                                                                                <td>
                                                                                                    {overRideFields[orf].Label}
                                                                                                    {currentWaveSpec.OpsResourcesData ? (
                                                                                                        <span className="decisive-pop-value">
                                                                                                            {getDecisiveFieldValue(orf)}
                                                                                                        </span>
                                                                                                    ) : null}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {/* <input checked={editableWaveSpec[orf]} id={orf} onChange={onOverrideCheckChange} type="checkbox" /> */}
                                                                                                    <span className="d-flex">
                                                                                                        {" "}
                                                                                                        No{" "}
                                                                                                        <CustomInput
                                                                                                            type="switch"
                                                                                                            id={orf}
                                                                                                            name="customSwitch"
                                                                                                            // label="Tracking Project"
                                                                                                            className="h5 ml-2 pointer"
                                                                                                            checked={currentWaveSpec[orf] ? true : false}
                                                                                                            onChange={onOverrideCheckChange}
                                                                                                        />{" "}
                                                                                                        Yes
                                                                                                    </span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <textarea
                                                                                                        className="override-notes form-control"
                                                                                                        value={
                                                                                                            currentWaveSpec?.CostOverrideNotes &&
                                                                                                                currentWaveSpec?.CostOverrideNotes[orf]
                                                                                                                ? currentWaveSpec.CostOverrideNotes[orf]
                                                                                                                : ""
                                                                                                        }
                                                                                                        disabled={!currentWaveSpec[orf]}
                                                                                                        id={orf}
                                                                                                        onChange={onNotesHandler}
                                                                                                    ></textarea>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {currentWaveSpec[orf] ? (
                                                                                                        <div class="input-group">
                                                                                                            <input
                                                                                                                className="form-control"
                                                                                                                type="number"
                                                                                                                min={0}
                                                                                                                id={
                                                                                                                    overRideFields[orf][
                                                                                                                    getDecisiveFieldValue(orf)
                                                                                                                    ]
                                                                                                                }
                                                                                                                step="any"
                                                                                                                value={getCurrentCurrencyValue(
                                                                                                                    currentWaveSpec[
                                                                                                                    overRideFields[orf][
                                                                                                                    getDecisiveFieldValue(orf)
                                                                                                                    ]
                                                                                                                    ]
                                                                                                                )}
                                                                                                                // onChange={(eve) => {
                                                                                                                // 	setCurrentWaveSpec({
                                                                                                                // 		...currentWaveSpec,
                                                                                                                // 		[overRideFields[orf][
                                                                                                                // 			getDecisiveFieldValue(orf)
                                                                                                                // 		]]:
                                                                                                                // 			eve.target.value /
                                                                                                                // 			currentCurrency.ConversionRateToLocal,
                                                                                                                // 	});
                                                                                                                // }}
                                                                                                                onChange={onCurrencyChange}
                                                                                                            />
                                                                                                            <div class="input-group-append">
                                                                                                                <span class="input-group-text text-sm">
                                                                                                                    {currentCurrency?.CurrencyUnit}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <span>
                                                                                                            {getCurrentCurrency(
                                                                                                                currentWaveSpec[
                                                                                                                overRideFields[orf][
                                                                                                                getDecisiveFieldValue(orf)
                                                                                                                ]
                                                                                                                ]
                                                                                                            )}
                                                                                                        </span>
                                                                                                    )}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ) : null;
                                                                                    })}
                                                                                </tbody>
                                                                            </Table>

                                                                            <hr className="border"></hr>
                                                                            <Col>
                                                                                <Row>
                                                                                    <strong>Other Notes</strong>
                                                                                </Row>
                                                                                <Row>
                                                                                    <textarea
                                                                                        id="OtherNotes"
                                                                                        value={
                                                                                            currentWaveSpec?.CostOverrideNotes &&
                                                                                                currentWaveSpec?.CostOverrideNotes["OtherNotes"]
                                                                                                ? currentWaveSpec?.CostOverrideNotes["OtherNotes"]
                                                                                                : ""
                                                                                        }
                                                                                        onChange={onNotesHandler}
                                                                                        className="form-control other-notes-textarea"
                                                                                    ></textarea>
                                                                                </Row>
                                                                            </Col>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="justify-content-end mt-4">
                                                                        <Button
                                                                            onClick={submitCurrentWaveSpec}
                                                                            color="primary"
                                                                        // disabled={app.recordloading}
                                                                        >
                                                                            Save
                                                                            {/* {app.recordloading ? (
                                                                                <Spinner size="small" color="#495057" />
                                                                            ) : null} */}
                                                                        </Button>
                                                                    </Row>
                                                                </Card>
                                                            </Container>
                                                        </Row>

                                                        :
                                                        <h5>No Waves for this particular methodology!!!</h5>
                                                    }


                                                </TabContent>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                ))}
                        </TabContent>
                    </div>
                </Col>
            </Row>
            {/* // )} */}

        </Layout>
    )
}

export default CountryLevelOverrideCosts
