import React from 'react'
import {Modal,ModalBody, ModalFooter,Button,ModalHeader} from "reactstrap";
import logo from "../../src/assets/img/avatars/modal-logo.png"

var imgStyle = {
  width: "150px",
};
function UploadedProjectModal({uploadProject,setUploadProject}) {
  return (
    <>
      <Modal
        isOpen={uploadProject}
        backdrop="static"
        toggle={() => setUploadProject(!uploadProject)}
        centered={true}
        size="md"
      >
        <ModalHeader toggle={() => setUploadProject(!uploadProject)}>
        {/* <img src={logo}  className="modallogo"/> */}
        </ModalHeader>
        <span className="modaltitle">Alert!!!</span>
        <ModalBody>
       <h6>This project came from bulk upload batch for OOP reporting purpose only. It contains limited project information, therefore full CINTRAL functionality is not supported. <br/>Please contact CINTRAL support team if further action is required on this project. <br/><span className="uploaddeprojectmodalmsg">Thank you</span></h6>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setUploadProject(!uploadProject)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    
    
    </>
  )
}

export default UploadedProjectModal