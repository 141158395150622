import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { proposalListRedirectToEdit } from "../../../../../redux/actions/proposal/Actions";
import { imgStyle } from "../../../../marketDefault/DynamicRFQ/ModalRFQ";
import logo from '../../../../../assets/img/avatars/modal-logo.png'

const HistoryModal = ({ isOpen, vendor, meth, sid, sidtype, popupHandler }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [costingProfileIndex, setCostingProfileIndex] = useState(0);
    var i = 0;
    useEffect(() => {
        setModalOpen(isOpen);

    }, [isOpen]);
    return (
        <React.Fragment>
            {
                (isModalOpen) ?
                    <Modal isOpen={isModalOpen} toggle={() => popupHandler('close', !isModalOpen)}
                        centered className={"modelSelectVendors"}>
                        <ModalHeader toggle={() => popupHandler('close', !isModalOpen)}>
                            {/* <img src={logo} className="modallogo" /> */}
                        </ModalHeader>
                        <span className="modaltitle p-0">Change History</span>
                        <ModalBody className="m-3">
                            <div style={{ maxHeight: "400px", overflow: "auto" }}>
                                <table
                                    className="table table-bordered "
                                    border="0.4"
                                    style={{ position: "relative" }}
                                >
                                    {vendor?.CostHistory?.filter(a => a.id == sid)[0]?.history?.length > 0 ?
                                        <tr style={{ backgroundColor: "#00f000", color: "black" }}>
                                            <th>S.NO</th>
                                            <th>Date</th>
                                            <th>Costing Option</th>
                                            <th>Last Value</th>
                                            <th>Latest Value</th>
                                            <th>Changed By</th>{" "}
                                        </tr>
                                        : "No Data Found"
                                    }

                                    {
                                        vendor?.CostHistory?.filter(a => a.id == sid)[0]?.history?.map((m, ind) => (
                                            <tr>
                                                <td>{(i = i + 1)}</td>
                                                <td>{m.date}</td>
                                                <td>
                                                    {m.CostingOptionsName}
                                                </td>
                                                <td>{sidtype == "TimingSection" ? m.oldval : !m.OrignalValue?.includes(".") ?
                                                    Math.round(m.oldval * meth?.BiddingDetail?.Currency.ConversionRateToLocal) :
                                                    (m.OrignalValue?.lastIndexOf(".") == m.OrignalValue?.length - 1) ? Math.round(m.oldval * meth?.BiddingDetail?.Currency.ConversionRateToLocal) + "."
                                                        : (m.oldval * meth?.BiddingDetail?.Currency.ConversionRateToLocal).toFixed((m.OrignalValue?.length - 1) - m.OrignalValue?.lastIndexOf(".") > 2 ? 2 : (m.OrignalValue?.length - 1) - m.OrignalValue?.lastIndexOf("."))
                                                }</td>
                                                <td>{sidtype == "TimingSection" ? m.latestvalue : !m.OrignalValue?.includes(".") ?
                                                    Math.round(m.latestvalue * meth?.BiddingDetail?.Currency.ConversionRateToLocal) :
                                                    (m.OrignalValue?.lastIndexOf(".") == m.OrignalValue?.length - 1) ? Math.round(m.latestvalue * meth?.BiddingDetail?.Currency.ConversionRateToLocal) + "."
                                                        : (m.latestvalue * meth?.BiddingDetail?.Currency.ConversionRateToLocal).toFixed((m.OrignalValue?.length - 1) - m.OrignalValue?.lastIndexOf(".") > 2 ? 2 : (m.OrignalValue?.length - 1) - m.OrignalValue?.lastIndexOf("."))
                                                }</td>
                                                <td>{m.doneby}</td>

                                            </tr>)
                                        )

                                    }

                                </table>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => popupHandler('close', false)}>
                                Close
                            </Button>
                        </ModalFooter>
                    </Modal>
                    : ''
            }
        </React.Fragment>
    )
}
export default HistoryModal;