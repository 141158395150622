import React from "react";

export const SIGNIN = "Sign In"
export const LOGIN = 'Log In';
export const Register = 'Register';
export const ForgotPassword = 'Forgot Password';
export const RequestBoard = 'Request Board';
export const ViewCostingProfileSummary = 'View Costing Profile Summary';
export const OpenCostingFolder = 'Open Costing Folder';
export const AssignToUser = 'Assign To User'
export const CloseRequest = 'Close Request'
export const ReOpenRequest = 'Re-Open Request'
export const SendEmailNotification = 'Send Email Notification'
export const NewProposal = 'New Proposal'
export const SaveandNext = 'Save and Next'
export const RFQForm = 'RFQ Form'
export const SaveAsDraft = 'Save As Draft'
export const ValidateandSave = 'Validate and Save'
export const OpsReport = 'Ops Report'
export const Export = 'Export'
export const ExportallOpsReportdata = 'Export all Ops Report data'
export const OOPApprovalReport = 'OOP Approval Report'
export const FinanceReport = 'Finance Report'
export const ExportWaveLevelFinanceReport = 'Export Wave Level Finance Report'
export const ExportProjectLevelFinanceReport = 'Export Project Level Finance Report'
export const FinanceLogs = 'Finance Logs'
export const Exportalldata = 'Export all data'
export const DeliveryReport = 'Delivery Report'
export const Dashboard = 'Dashboard'
export const EditProposal = 'Edit Proposal'
export const OpenProjectFolder = 'Open Project Folder'
export const OpenCostingfolder = 'Open Costing folder'
export const AssignProjectManager = 'Assign Project Manager'
export const CopyProposal = 'Copy Proposal'
export const DeleteProposal = 'Delete Proposal'
export const InputCosts = 'Input Costs'
export const InputCommercialCost = 'Input Commercial Cost'
export const OverrideAutoCosts = 'Override Auto-Costs'
export const CreateAdditionalSheet = 'Create Additional Sheet'
export const EditProjectDetails = 'Edit Project Details'
export const EditCostingProfile = 'Edit Costing Profile'
export const ViewCostSummary = 'View Cost Summary'
export const AdvancedSearch = 'Advanced Search'
export const Actions = 'Action'
export const CreateNewRequest = 'Create New Request'
export const InputCommercialCosts = 'Input Commercial Costs'
export const ViewRequests = 'View Requests'
export const AddNewUser = 'Add New User'
export const Save = 'Save'
export const Delete = 'Delete'
export const AddRequestType = 'Add Request Type'
export const CopytoAllMarketsandSave = 'Copy to All Markets and Save'
export const Copytoallmethodologiesandsave = 'Copy to all methodologies and saver'
export const SaveSchema = 'Save Schema'
export const UserManagement = 'User Management'
export const MarketSettings = 'MarketSettings'




   


 