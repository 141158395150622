
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import {
    Row,
    Col,
    Collapse,
    Nav,
    UncontrolledDropdown,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ListGroup,
    ListGroupItem,
    Form,
    InputGroup,
    Input,
    Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  FormGroup,
  } from "reactstrap";
  import Select from "react-select";
import DashboardLayout from "../../layouts/Dashboard";
import Layout from "../../layouts/Project"
// import Navbar from "../../components/NavbarMarketDefaults";
import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.module.css'
import NotificationItem from './NotificationItem';
import './NotificationsBoard.css';
import axios from "../../axios-interceptor";
import { useHistory } from "react-router-dom";


const NotificationsBoard = () => {
  const [fromDate, setFromDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [type, setType] = useState(null);
  const [funct, setFunct] = useState(null);
  const [component, setComponent] = useState(null);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  let permission = JSON.parse(localStorage.getItem("userRecord"));
  const [keywords, setKeywords] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [data, setData] = useState(null);
  const [componentList, setComponentList] = useState(null);
  const navigate = useHistory();

  let TypeList = [{label: "New Features", value: "New Features"}, {label: "Enhancements", value: "Enhancements"}, {label: "Bug Fixes", value: "Bug Fixes"}];
  let FunctList = [{label: "ALL", value: "ALL"}, {label: "CS", value: "CS"}, {label: "OPS", value: "OPS"}]
  // let ComponentList;

  function filterData() {
    // const { fromDate, endDate, type, function: func, component, keywords } = filters;
 
    // Convert dates to Date objects if they are not null
    const fromDateObj = fromDate ? new Date(fromDate) : null;
    const endDateObj = endDate ? new Date(endDate) : null;
 
    return data.filter(item => {
        // Convert item release date to Date object
        const releaseDateObj = new Date(item.ReleaseDate);
 
        // Check if the item's release date is within the specified range
        const isDateInRange = (!fromDateObj || releaseDateObj >= fromDateObj) &&
                              (!endDateObj || releaseDateObj <= endDateObj);
 
        // Check if the item's type matches the filter (if filter is not null)
        const isTypeMatch = !type || type.some(t => item.Type?.toLowerCase() === t.value.toLowerCase());
 
        // Check if the item's function matches the filter (if filter is not null)
        const isFunctionMatch = !funct || funct.some(f => item.Function?.toLowerCase() === f.value.toLowerCase());
 
        // Check if the item's component matches the filter (if filter is not null)
        const isComponentMatch = !component || component.some(comp => item.Component?.toLowerCase() === comp.value.toLowerCase());
 
        // Check if the item's details contain the keywords (if filter is not null)
        const isKeywordsMatch = !keywords || item.Details?.toLowerCase().includes(keywords.toLowerCase());
 
        // Return true if all conditions are met
        return isDateInRange && isTypeMatch && isFunctionMatch && isComponentMatch && isKeywordsMatch;
    });
}
 
const clearSearch = () =>{
  setFromDate(null);
   setEndDate(null);
    setType(null);
     setFunct(null);
      setComponent(null);
       setKeywords("");
}


  useEffect(() => {
    console.log("fromDate, endDate, type, component, keywords", fromDate, endDate, type, funct,  component, keywords);
    if (fromDate || endDate || type || funct || component || keywords) {
      const filtered = filterData();
      console.log("filteredData", filtered);
      setFilteredData(filtered)
    } else {
      setFilteredData(data)
    }
    

    
  }, [fromDate, endDate, type, funct, component, keywords]);

  const getNotifications = async() => {
    let response =  await axios.get("/Notifications/default")
 
    response.data.Data.Notifications.sort((a,b) => a.IsShownOrder - b.IsShownOrder);

    setData(response.data.Data.Notifications)
    setFilteredData(response.data.Data.Notifications)
    let b = [];
     codeLabels.ComponentsOptions.map(c => b.push({label:c.Label, value: c.Label}));
     setComponentList(b);
    
  }

  useEffect(() => {
    getNotifications();
    console.log("Notifications", data, permission.CanManageNotification);
    console.log("componentList", componentList);
  }, [])

  const goToManageFeeds = () => {
    navigate.push('/managefeeds');
  };

  const goToManageAlerts = () => {
    navigate.push('/managealerts');
  };

  // let data = [{
  //   Notifications:[{
  //     ReleaseDate:"2024-12-12",
  //     Type:"Enhancements",
  //     Function:"All",
  //     Component : "Dashboard",
  //     Details:"Empty Pack Study - product line: Projects of CM-EPS product line with a positive project margin % will adhere to the standard approval matrix and threshold.",
  //     Link:"Notification",
  //     IsHide:null,
  //     IsShownOrder:0,
  //   },
  //   {
  //       ReleaseDate:"2024-12-12",
  //       Type:"New Features",
  //       Function:"All",
  //       Component : "Dashboard",
  //       Details:"Empty Pack Study - product line: Projects of CM-EPS product line with a positive project margin % will adhere to the standard approval matrix and threshold.",
  //       Link:"Notification",
  //       IsHide:null,
  //       IsShownOrder:0,
  //     },
  //     {
  //       ReleaseDate:"2024-12-12",
  //       Type:"BugFix",
  //       Function:"All",
  //       Component : "Dashboard",
  //       Details:"Notification Board",
  //       Link:"Notification",
  //       IsHide:null,
  //       IsShownOrder:0,
  //     }]
  //   }];
return (
  //   <Layout
  //   // navbar={<Navbar headerTitle="Notification Board" header_nav={true}/>}
    
  // >
  <div >
  <Layout
      onlyHeader={true}
      CardHeader="Notification Board">
    <Card>
    <CardHeader style={{position:'sticky', zIndex:'10'}}>

<div column-gap ='80px' style={{ 
        marginLeft: '60%',  
        marginBottom: '30px',
    }}>
<Row >
<Row>
<Box height={30} width={40} bgcolor="#DAE9F8" p={1} style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)", border: "1px solid #ccc" }}>
</Box>
<Col style={{marginTop: "5px"}}
><h4>New Features</h4></Col>
</Row>
<Col></Col>
{/* <Col><h4>New Feature</h4></Col> */}
<Row>
<Box height={30} width={40} bgcolor="#E9F9EE" p={1} style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)", border: "1px solid #ccc" }}>
</Box>
<Col style={{marginTop: "5px"}}
><h4>Enhancements</h4></Col>
</Row>
<Col></Col>
<Row>
<Box height={30} width={40} bgcolor="#FFF9E3" p={1} style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)", border: "1px solid #ccc" }}>
</Box>
<Col style={{marginTop: "5px"}}
><h4>Bug fixes</h4></Col>
</Row>
<Col></Col>
</Row >
</div >
<h3>Search and Filter</h3>
<div>
<div  className="notification-board-flt"  >
  <Row>
    <Col>     
    <DatePicker
    // locale="en-GB"
    className="form-control notification-filter"
    selected={fromDate}
    onChange={(date) => setFromDate(date)}
    dateFormat="dd-MMM-yyyy"
   placeholderText="From"
    />
</Col>
    <Col>
    <DatePicker 
    // locale="en-GB"
    className="form-control notification-filter"
    selected={endDate}
    onChange={(date) => setEndDate(date)}
    dateFormat="dd-MMM-yyyy"
   placeholderText="To"
    />
    </Col>
  <Col>
  <FormGroup>
    <Select
       name="Select Type"
       className="react-select-container notification-filter"
       isMulti
       isSearchable
       classNamePrefix="react-select"
       options={TypeList}
       placeholder="Select Type"
       value={type}
       onChange={(e) => {
       setType(e)
       }
       }
    />
  </FormGroup>
  </Col>
  <Col>
  <FormGroup>
                            <Select
                                name="Select Function"
                                className="react-select-container notification-filter"
                                isMulti
                                isSearchable
                                classNamePrefix="react-select"
                                options={FunctList}
                                placeholder="Select Function"
                                value={funct}
                                onChange={(e) => {
                                    setFunct(e)
                                }
                                }
                                
                            />
                        </FormGroup>
  </Col>
  <Col>
  <FormGroup>
                            <Select
                                name="Select Component"
                                className="react-select-container notification-filter-component"
                                isMulti
                                isSearchable
                                classNamePrefix="react-select"
                                options={componentList}
                                placeholder="Select Component"
                                value={component}
                                onChange={(e) => {
                                    setComponent(e)
                                }
                                }
                                
                            />
                        </FormGroup>
  </Col>
  <Col>
  <FormGroup>
    <Input type="react-select-container notification-filter-search text" className="react-select-container notification-filter-search" placeholder="Search Keywords" onChange={(e) => setKeywords(e.target.value)} value={keywords}/>

  </FormGroup>
  {/* <TextField id="outlined-basic" label="Search Keywords" variant="outlined" /> */}
  </Col>
  
  <Col>
 {(fromDate || endDate || type || funct || component || keywords) && <Button variant="contained"
                    color="primary" className="notification-button" onClick={clearSearch}>
    Clear Search
  </Button>}
   </Col>
  
   <div className="notification-button-container">
  
{/* <div  className="float-right"> */}
<Col>  {permission.CanManageNotification && <Button variant="contained"
                    color="primary"  className="notification-button" onClick={goToManageFeeds}>
    Manage Feeds
  </Button>}
  </Col>

  <Col>  {permission.CanManageNotification && <Button  variant="contained"  className="notification-button"
                    color="primary" onClick={goToManageAlerts}>
    Manage Alerts
  </Button>}
  </Col>
  </div>
  {/* <Button className="float-right" >
    Manage Alerts
  </Button> */}
  </Row>
  {/* </div> */}
  </div>
  </div>
  <div className="notifications-header" style={{position:'sticky'}}>

<Col className="notification-col-10" style={{marginLeft:'-20px'}} >Release Date</Col>
<Col className="notification-col-10" >Function</Col>
<Col className="notification-col-10" >Component</Col>
<Col className="notification-col-60"  >Details</Col>
<Col className="notification-col-10" style = {{textAlign: 'right', marginLeft: "-10px"}}>Link</Col>

</div>

  </CardHeader>
  <CardBody >
    <div className=" notifications-container notification-board">

      <div className="notifications-list" >
        {filteredData?.map((item, index) => (
          <NotificationItem key={index} {...item} />
        ))}
      </div>
    </div>
    </CardBody>
    </Card>
     </Layout> 
</div>

)
}
export default NotificationsBoard
