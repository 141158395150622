import React from "react";

function Document() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#000"
          d="M18.5 0H2v24h21V4.5L18.5 0zm3 22.5h-18v-21H17V6h4.5v16.5zm-15-3h9V18h-9v1.5zm0-3h12V15h-12v1.5zm0-3h12V12h-12v1.5zm0-3h12V9h-12v1.5zm9-4.5h-9v1.5h9V6z"
        ></path>
      </g>
    </svg>
  );
}

export default Document;