import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import axios from "../../axios-interceptor";
import Layout from "../../layouts/Project";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import {clearCurrentProject, getProject, setCurrentProject,initProject } from "../../redux/actions/currentProjectActions";
import { pageLoadEnd, pageLoadStart, recordLoadEnd, recordLoadStart, setCostingStatus, valueChangedInRFQ } from "../../redux/actions/appActions";
import { getLabel } from "../../utils/codeLabels";
import CountryMethodologyTabs from "./CountryMethodologyTabsFinal";
import { useHistory, useLocation } from "react-router-dom";
import { getSchemaWithDataJSON, GetValueLablesFromCSV, SaveAsDraft } from "../../utils/rfqMapper";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import EditFill from "../../assets/icons/Connected-System-Icons/Tools/Edit-fill";
import { Link} from "react-router-dom";
import logo from '../../assets/img/avatars/modal-logo.png'
import store from "../../redux/store/index"

const Costing = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    let currentCostingProfile = useSelector(({ currentCosting }) => currentCosting.currentCostingProfile);
    const oppValue = useSelector(

        ({ currentProject }) => currentProject.opportunityValue
    
      );
    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );

    const codeLabels = useSelector(({ codeLabels }) => codeLabels);
    const costingStatus = useSelector(({ app }) => app.costingStatus);
    const location = useLocation();

    const [openSaveNewProfile, setOpenNewProfile] = useState(false)
    const [updateProfileName, setUpdateProfileName] = useState(false)
    const [newProfileName, setNewProfileName] = useState(currentCostingProfile.ProfileName?currentCostingProfile.ProfileName:"")
    let pacificData = _.head(codeLabels.PacificCountriesList)?.Label;
    const [pacificMarkets] = useState(pacificData?.split(','));
    const [deleteProfileModal, setDeleteProfileModal] = useState(false);
    const [deletableProfile, setDeletableProfile] = useState({});

    useEffect(()=>{
        setNewProfileName(currentCostingProfile.ProfileName?currentCostingProfile.ProfileName:"")
    },[openSaveNewProfile])
    // useEffect(() => {
    //     currentCostingProfile = store.getState().currentCosting.currentCostingProfile;
    //     console.log("updatedProfile", currentCostingProfile);
    
    //   }, []);

    useEffect(() => {
        // let profileTemp = formatingDataForForms();
        // if (!project.Mode && project.Mode != "decommision") {
            // let length = project.CostingProfiles.length;
            // project.CostingProfiles[length-1] = currentCostingProfile
        const tempProfiles = project.CostingProfiles.map(cp => {
            // console.log("GetValueLablesFromCSV - cp",cp, currentCostingProfile, project.CostingProfiles[length-1])
            // commenting this line coz costingOptions becoming undefined when a new methodology been created

            if (!_.head(_.head(cp.CountrySpecs).MethodologySpecs)?.Variables)
                cp = formatingDataForForms(cp);
            // if (typeof (cp.Methodology) == "string")
            //     cp = {
            //         ...cp, StudyType:  cp.StudyType ,
            //         Methodology: _.cloneDeep(GetValueLablesFromCSV(codeLabels.MethodologyOptions, cp.Methodology)),
            //         SubMethodology: _.cloneDeep(GetValueLablesFromCSV(codeLabels.SubMethodologyOptions, cp.SubMethodology)),
            //         IsMultiCountry: cp.IsMultiCountry,
            //         FieldingCountries: _.cloneDeep(GetValueLablesFromCSV(codeLabels.FieldingCountriesOptions, cp.FieldingCountries)),
            //     }
            return { ..._.cloneDeep(cp) }
        });
        // dispatch(currentCostingActions.updateProfile(tempProfiles.find(tp => !tp.ParentProfileId)))
        dispatch(currentCostingActions.updateProfile(tempProfiles.find(tp => currentCostingProfile.id == tp.id)))
        dispatch(setCurrentProject({ ...project, CostingProfiles: _.cloneDeep(tempProfiles) }))
        // updateProject({
        //     Mode: "CostingOptionsDetails",
        //     CostingProfiles: tempProfiles
        // });
        // }
    }, [])

    useEffect(() => {
        return () => {
          dispatch(valueChangedInRFQ(false));
        }
    }, [])

    function formatingDataForForms(_profile) {
        let typeofquant = ['SM000005', 'SM000006', 'SM000007', 'SM000008', 'SM000009', 'SM000010', 'SM000011', 'SM000021', 'SM000023', 'SM000031', 'SM000032', 'SM000033', 'SM000034', 'SM000035', 'SM000015', 'SM000022', 'SM000001', 'SM000025', 'SM000026', 'SM000027', 'SM000028'];
        let typeofqual = ['SM000012', 'SM000013', 'SM000014', 'SM000016', 'SM000017', 'SM000018', 'SM000019', 'SM000020', 'SM000002', 'SM000003', 'SM000004', 'SM000036', 'SM000037'];
        let cloneProfile = _.cloneDeep(_profile);
        let commCountryCode = project.CommissioningCountry
        cloneProfile.CountrySpecs?.forEach((cs, indexCS) => {
            cs["label"] = cs.CountryName;
            cs.MethodologySpecs?.forEach((ms, indexMS) => {
                if (typeofquant.find(tp => tp == ms.Code))
                    ms["Quant"] = true;
                else if (typeofqual.find(tp => tp == ms.Code))
                    ms["Qual"] = true;

                ms["label"] = ms.Label;
                let tempVariables = getSchemaWithDataJSON(ms.RFQSchema, ms.Code, ms.RFQData, true, commCountryCode, pacificMarkets, project.CostingTemplateVersion,currentCostingProfile, oppValue);
                if (!ms.RFQData) {
                    tempVariables.forEach(x => {
                        if (x.dependentOn)
                            x.disabled = true;
                    });
                }

                let tempOpResourceVariables = getSchemaWithDataJSON(ms.SetupSchema, ms.Code, ms.SetupData);
                if (!ms.RFQData) {
                    tempOpResourceVariables.forEach(x => {
                        if (x.dependentOn || x.label == "Coding Required" || x.label == "Verbatim Coding Required") {
                            x.disabled = true;
                        }
                    });
                }
                // create a costing profile
                if (!_.includes(pacificMarkets, project.CommissioningCountry)||
                (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0") 
                ) {
                    let tempCostingOption = [];
                    if (ms.CostingOptions?.length == 0)
                        tempCostingOption = [{ label: "Default Option", value: "Default Option", isSelected: "true", Variables: _.cloneDeep(tempVariables.filter(x => x.Default)) }];
                    else {
                        ms.CostingOptions.forEach(co => {
                            let _tempCostingOption = {}
                            Object.keys(co).map(optionKey => {
                                _tempCostingOption[optionKey] = co[optionKey]
                            })
                            _tempCostingOption.label = co.Name
                            _tempCostingOption.value = co.Name
                            _tempCostingOption.id = co.id
                            _tempCostingOption.isSelected = co.Selected
                            _tempCostingOption.Variables = _.cloneDeep(co.CostingOptionSchema)
                            tempCostingOption.push(_tempCostingOption)
                            // tempCostingOption.push({
                            //     label: co.Name, value: co.Name, id: co.id, isSelected: co.Selected
                            //     , MethodologySpecId: co.MethodologySpecId, Variables: _.cloneDeep(co.CostingOptionSchema)
                            // })
                        });
                    }
                    ms["CostingOptions"] = _.cloneDeep(tempCostingOption);
                }


                ms["Variables"] = _.cloneDeep(tempVariables);
                ms["OpResourcesVariables"] = tempOpResourceVariables;
                ms["Responsibilities"] = _.cloneDeep(ms.ResponsibilitySchema);
            });
        });
        return cloneProfile;
    }
    const setLastProfileAsCurrentProfile = () => {
        let requiredProfile = _.last(project.CostingProfiles)
        dispatch(currentCostingActions.getCosting(requiredProfile.id))
        dispatch(recordLoadEnd())
    }
    const saveAsNewProfile = () => {
        // let _profile = { ..._.cloneDeep(currentCostingProfile) }
        let _profile = { ..._.clone(project.CostingProfiles.find(cp => cp.id == currentCostingProfile.id)) };
        _profile.ProfileName = newProfileName
        _profile.ProfileNumber = project.CostingProfiles?.length + 1
        _profile.isNewProfile = true
        _profile.ParentProfileId = currentCostingProfile.id
        _profile.ProfileSetting = _.cloneDeep(currentCostingProfile.ProfileSetting)
        _profile.AdditionalSheetId = currentCostingProfile.AdditionalSheetId ? currentCostingProfile.AdditionalSheetId : null
        _profile.CostingType = currentCostingProfile.CostingType ? currentCostingProfile.CostingType : null
        _profile.CostingsSheetId = currentCostingProfile.CostingsSheetId ? currentCostingProfile.CostingsSheetId : null

        // _profile.CountrySpecs = _profile.CountrySpecs.map(country => {
        //     // country.MethodologySpecs = country.MethodologySpecs.filter(ms => !ms.NotApplicable)
        //     country.MethodologySpecs = country.MethodologySpecs.map(ms => {
        //         // ms.CostingOptions = ms.CostingOptions.filter(co => co.isSelected)
        //         return _.cloneDeep(ms)
        //     })
        //     return _.cloneDeep(country)
        // })
        _profile = SaveAsDraft(_profile, pacificMarkets, currentCostingProfile, project)
        dispatch(recordLoadStart());
        axios
            .post("/costingprofiles/duplicate/" + currentCostingProfile.id, { currentCostingProfile: _profile }, {
                headers: { "auth-token": localStorage.getItem("auth-token") },
            })
            .then((res) => {
                setOpenNewProfile(false)
                toastr.success("Costing Profile Created Successfully", res.data.message);
                dispatch(getProject(_profile.Project.ProjectId, () =>
                setTimeout(() => {
                    setLastProfileAsCurrentProfile()
                }, 1000)
                ))
                // dispatch(recordLoadEnd());
            })
            .catch((err) => {
                toastr.error("Save Failed", err?.data?.error);
                dispatch(recordLoadEnd());
            });
    }
    const onTabchange = (costingProfileId) => {
        dispatch(currentCostingActions.getCosting(costingProfileId, (profile) => {
            setTimeout(() => {
                //current costing profile isn't updating by this callback
                //seems some sync-async issues, so getting the updated profile object, 
                //instead of taking from store- not an ideal way,
                // todo: when gets time please figure out what is the issue in updating store
                setNewProfileName(profile?.ProfileName)
                // if (!project.Mode && project.Mode != "decommision") {
                const tempProfiles = project.CostingProfiles.map(cp => {
                    if (cp.id == profile.id)
                        cp = profile
                    // cp = formatingDataForForms(cp)
                    return { ...cp }
                });
                dispatch(currentCostingActions.updateProfile(_.cloneDeep(tempProfiles.find(tp => tp.id == profile.id))))
                dispatch(setCurrentProject({ ...project, CostingProfiles: _.cloneDeep(tempProfiles) }))
            });
        }))
    }
    const updateProfileNameCosting = () => {
        let _project = _.cloneDeep(project)
        _project.CostingProfiles = _project.CostingProfiles.map(cp => {
            if (cp.id == currentCostingProfile.id)
                return _.cloneDeep(currentCostingProfile)
            return _.cloneDeep(cp)
        })
        dispatch(setCurrentProject(_project))
        setUpdateProfileName(false)
    }
    const updateCurrentProfileName = (e) => {
        dispatch(currentCostingActions.updateProfile({ ..._.cloneDeep(currentCostingProfile), ProfileName: e.target.value }))
    }

    const updateClientChargeable = (eve) => {
        dispatch(setCurrentProject({ ..._.cloneDeep(project), ClientChargeableApplicable: eve.target.checked }))
    }

    return <>
        <Layout
            costMethod={currentCostingProfile.CostingType}
            profileStatusToDisplay={getLabel(
                "CostingStatusOptions",
                currentCostingProfile.ProfileStatus
            )}
            projectStatusToDisplay={getLabel(
                "ProjectStatusOptions",
                currentCostingProfile.Project?.ProjectStatus
            )}
            avoidEdit={true}
        >
            <Modal
                size="sm"
                isOpen={deleteProfileModal}
                toggle={() => setDeleteProfileModal(!deleteProfileModal)}>
                <ModalHeader toggle={() => setDeleteProfileModal(!deleteProfileModal)}>
                    Delete Profile
                </ModalHeader>
                <ModalBody>
                    <strong>
                        This Change is Irreversible. Are you sure want to delete Profile-
                        <i>{deletableProfile?.ProfileName}</i>
                    </strong>
                </ModalBody>
                <ModalFooter>
                    <Row className="justify-content-end">
                        <Button
                            color="secondary"
                            size="sm"
                            onClick={() => setDeleteProfileModal(!deleteProfileModal)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            className="ml-2"
                            size="sm"
                            onClick={() => {
                                dispatch(
                                    currentCostingActions.deleteCosting(
                                        deletableProfile.id));
                                setDeletableProfile(null);
                                setDeleteProfileModal(false);
                            }}
                        >
                            Confirm
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>

            <Row className={`costing-parent-component${currentCostingProfile.ProfileStatus !== "1" ? " read-only-body" : ""}`}>
                <Col md={12}>
                    <>
                        {_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion != "v2.0" ? <Nav tabs>
                            {project.CostingProfiles.map(_costingProfile => <NavItem>
                                <NavLink
                                    className={classNames({ active: _costingProfile.id === currentCostingProfile.id })}
                                    onClick={() => { onTabchange(_costingProfile.id) }}
                                >
                                    <Col>
                                        {_costingProfile.ProfileName}
                                        {project.CostingProfiles.length > 1
                                            ?
                                            <FontAwesomeIcon
                                                title="Delete Profile"
                                                className="align-middle ml-3 deleteButton"
                                                icon={faTimesCircle}
                                                fixedWidth
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setDeleteProfileModal(true);
                                                    setDeletableProfile(_costingProfile);
                                                }}
                                            />
                                            : null}
                                    </Col>

                                </NavLink>
                            </NavItem>)}
                        </Nav> : null}
                        <TabContent activeTab={currentCostingProfile.id}>
                            {/* {project.CostingProfiles.map(_costingProfile => */}
                            <TabPane tabId={currentCostingProfile.id}>
                                <Row className="justify-content-between m-3">
                                    <h3 className="mt-3 ml-1 mb-0">{currentCostingProfile.ProfileName} 
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        className="pointer ic-hover"
                                        onClick={() => (currentCostingProfile.ProfileStatus == 99 && currentCostingProfile.ParentProfileId !== null) ? null : setUpdateProfileName(true)}
                                    />
                                    {/* <Link className="pointer"
                                        onClick={() => (currentCostingProfile.ProfileStatus == 99 && currentCostingProfile.ParentProfileId !== null) ? null : setUpdateProfileName(true)}>
                                            <EditFill/>
                                    </Link> */}
                                    </h3>

                                    {/* {currentCostingProfile?.ProfileSetting?.ClientChargeableApplicable ? <Row className="justify-content-end m-2">
                                        <Label><Input type="checkbox" defaultChecked={project.ClientChargeableApplicable} onChange={updateClientChargeable}></Input> Is Client Chargeable for this project?</Label>
                                    </Row> : null} */}
                                    {/* {_.includes(pacificMarkets, project.CommissioningCountry) ? 
                                    <Button color="primary" className="btn-sm view-summary-btn" onClick={() => history.push(`/summary/${currentCostingProfile.id}`)}>
                                        View Cost Summary
                                    </Button> : null} */}
                                </Row>
                                <CountryMethodologyTabs
                                    profile={currentCostingProfile}
                                    updateProject={setCurrentProject}
                                    setOpenNewProfile={() => setOpenNewProfile(true)}
                                    project={project}
                                    disableFields={location.state}
                                />
                            </TabPane>
                            {/* )} */}

                        </TabContent>
                    </>

                    <Row className="m-0 justify-content-end">
                        <Button
                            color="secondary"
                            className="mr-2 mt-3"
                            onClick={() => {
                                if (costingStatus) {
                                    
                                    dispatch(clearCurrentProject());
                                    dispatch(
                                      getProject(
                                        project.ProjectId
                                          ? project.ProjectId
                                          : currentCostingProfile.Project.ProjectId,
                                        () => history.push("/proposal-new")
                                      )
                                    )
                                    dispatch(initProject());
                                  
                                  
                                }
                            }}
                        >
                            Back To Details
                        </Button>
                    </Row>
                </Col>
            </Row>
            <Modal
                isOpen={openSaveNewProfile}
                toggle={() => setOpenNewProfile(!openSaveNewProfile)}
            >
                <ModalHeader
                    toggle={() => setOpenNewProfile(!openSaveNewProfile)}
                >
                    Provide New Profile Name
                </ModalHeader>
                <ModalBody>
                    <input className="form-control"
                        onChange={e => setNewProfileName(e.target.value)}
                        defaultValue={currentCostingProfile.ProfileName} />
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <Button
                            color="secondary"
                            onClick={() => setOpenNewProfile(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            className="ml-2"
                            onClick={saveAsNewProfile}
                        >
                            Create New Profile
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={updateProfileName}
                toggle={() => setUpdateProfileName(!updateProfileName)}
            >
                <ModalHeader
                    toggle={() => setUpdateProfileName(!updateProfileName)}
                >
                    {/* <img src={logo}  className="modallogo"/> */}
                </ModalHeader>
                <span className="modaltitle p-0">Update Profile Name</span>
                <ModalBody>
                    <input className="form-control"
                        onChange={updateCurrentProfileName}
                        defaultValue={currentCostingProfile.ProfileName} />
                </ModalBody>
                <ModalFooter>
                    <Row>
                        {/* todo: give cancel option */}
                        <Button
                            color="secondary"
                            onClick={() => updateProfileNameCosting()}
                        >
                            Confirm
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        </Layout>
    </>
}

export default Costing;