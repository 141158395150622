import React, { useEffect, useState, useRef } from "react";
import { toastr } from "react-redux-toastr";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "@alexcambose/react-infinite-scroll";
import axios from "../../axios-interceptor";
import Navbar from "../../components/Navbar";
import DashboardLayout from "../../layouts/Dashboard";
import RecordsSpinner from "../../components/RecordsSpinner";
import RecordsBadge from "../../components/RecordsBadge";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { getLabel } from "../../utils/codeLabels";
import { CSVLink } from "react-csv";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
  Label,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Card,
  Row,
  Col,
} from "reactstrap";
import _ from "lodash";
import { pageLoadEnd, pageLoadStart } from "../../redux/actions/appActions";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import FinanceLogs from "./FinanceLogs";
import moment from "moment";
import { financeReportColumns, financeReportCsvHeaders, financeReportFinalColumns } from "./constants";
import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';
import * as EventName from '../../utils/GoogleAnalyticsEvents';
import '../oopReport/oop.css';

import logo from "../../assets/img/avatars/modal-logo.png";
var imgStyle = {
  width: "150px",
};

const FinanceDashboard = () => {

  const gaEventTracker = useAnalyticsEventTracker(EventName.FinanceReport);
  const dispatch = useDispatch();
  const [infiniteLoad, setInfiniteLoad] = useState(false);

  const [record, setRecord] = useState({
    records: [],
    hasMore: true,
    totalItems: null,
  });
  const [selectColumns, openSelectColumns] = useState(false);
  const [allColumns] = useState(financeReportColumns);
  const [selectedDisplayColumns, setSelectedDisplayColumns] = useState(
    allColumns
  );
  const [selectedTempDisplayColumns, setSelectedTempDisplayColumns] = useState(
    allColumns
  );
  const [resetDisplayCols, setResetDisplayCols] = useState([]);
  const reportStatuses =
    useSelector(({ reportStatuses }) => reportStatuses) || [];

  const [reportItems, setReportItems] = useState([]);
  const [reportRemoteFilters, setReportRemoteFilters] = useState({});
  const csvInstance = useRef(null);

  useEffect(() => {
    //console.log("initial call");
    dispatch(pageLoadStart());
  }, []);

  const loadInitial = async () => {
    axios
      .post("/reports/finance/waves-new/all?limit=30", { filters: reportRemoteFilters })
      .then((res) => {
        dispatch(pageLoadEnd());
        //console.log(res);
        setRecord({
          ...record,
          records: res.data.items,
          totalItems: res.data.totalItems,
          hasMore: true
        }); // change records to whatever array is named
      })
      .catch((err) => {
        // toastr.error("Loading Failed", err.data.message);
      });
  }

  const loadAllFinances = async (_financeReportStatus) => {
    try {
      dispatch(pageLoadStart());
      var res = await axios.get(`/reports/download/${_financeReportStatus.FileId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'text/csv'
        }
      });
      if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `FinanceReport-${(_financeReportStatus && _financeReportStatus.ReportEndTime) ? moment.utc(_financeReportStatus.ReportEndTime).format('l LT') : ''}-UTC.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
      dispatch(pageLoadEnd());
    }
    catch (err) {
      dispatch(pageLoadEnd());
      toastr.error("Loading Failed", err?.data?.message);
    }
  };
  useEffect(() => {
    //console.log("record has changed", record);
  }, [record]);

  const fetchMoreData = () => {
    //console.log("fetch more called");
    //console.log(record);
    if (record.records.length >= record.totalItems) {
      //console.log("set hasMore false");
      setRecord({ ...record, hasMore: false });
      return;
    }
    setInfiniteLoad(true);
    axios
      .post(
        "/reports/finance/waves-new/all?limit=30&offset=" + record.records.length,
        { filters: reportRemoteFilters }
      )
      .then((res) => {
        //console.log(res);
        //console.log(
        //   "new array",
        //   update(record.records, { $push: res.data.items })
        // );
        setRecord({
          ...record,
          records: update(record.records, { $push: res.data.items }),
          totalItems: res.data.totalItems,
        });
        setInfiniteLoad(false);
      })
      .catch((err) => {
        //console.log(err);
        // toastr.error("Loading Failed", err.data.message);
        setInfiniteLoad(false);
      });
  };


  const [tableColumns, setTableColumns] = useState(financeReportFinalColumns);
  const [showLogs, setShowLogs] = useState(false);
  useEffect(() => {
    if (selectedDisplayColumns) {
      dispatch(pageLoadStart());
      let columns = financeReportFinalColumns
        .filter((col) => _.includes(selectedDisplayColumns, col.text))
        .map((col) => {
          return { ...col };
        });
      setTableColumns([]);
      setTimeout(() => {
        setTableColumns([...columns]);
        dispatch(pageLoadEnd());
      });
    }
  }, [selectedDisplayColumns]);

  const data = {
    tableColumns: [...tableColumns],
    tableData: record.records,
  };

  const ExportCSV = (props) => {
    
    const handleClick = () => {
      gaEventTracker(EventName.Export);
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-secondary" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const financeReportStatus = reportStatuses && reportStatuses.find((s) => s.ReportName === 'FINANCE REPORT') ? reportStatuses.find((s) => s.ReportName === 'FINANCE REPORT') : null
  const financeReportProjectLevelStatus = reportStatuses && reportStatuses.find((s) => s.ReportName === "PROJECT LEVEL FINANCE REPORT") ? reportStatuses.find((s) => s.ReportName === "PROJECT LEVEL FINANCE REPORT") : null
  const getReportStatus = (financeReportStatus) => {
    if (!financeReportStatus)
      return 'Report not generated.';
    switch (financeReportStatus.Status) {
      case 'STARTED':
      case 'REPORT_GENERATED':
        return 'The report data is available for view now, for Export... it is still in progress.'
        break;
      case 'ERROR':
        return 'Report generation failed.'
        break;
      default:
        return ''
        break;
    }
  }

  const handleTableChange = (type, { filters }) => {
    //console.log('filters', filters display)
    if(Object.keys(filters).length !== 0){
      for (const [key, value] of Object.entries(filters)) { 
        value.filterVal = value.filterVal.trim()
      }     
    
    }
    setReportRemoteFilters(filters)
    return true
  }

  useEffect(() => {
    //console.log('reportRemoteFilters', reportRemoteFilters)
    loadInitial();
  }, [reportRemoteFilters])

  return (
    <div className="finance_new">
      {!showLogs ? (
        <DashboardLayout
          navbar={<Navbar headerTitle="FINANCE REPORTS" show={false} header_nav={true}/>}
        >
          <CSVLink style={{ display: 'none' }}
            filename={`FinanceReport-${(financeReportStatus && financeReportStatus.ReportEndTime && financeReportStatus.Status === 'CSV_UPLOADED') ? moment.utc(financeReportStatus.ReportEndTime).format('l LT') : ''}-UTC.csv`}
            data={reportItems} headers={financeReportCsvHeaders} separator={","} enclosingCharacter={`"`}
            ref={csvInstance}>
            Download Report
          </CSVLink>
          <>
            {/* {record.records ? ( */}
            <InfiniteScroll
              loadMore={fetchMoreData}
              hasMore={(financeReportStatus && financeReportStatus.ReportEndTime && (financeReportStatus.Status === 'CSV_UPLOADED'|| financeReportStatus.Status === 'REPORT_GENERATED')) && record.hasMore}
              isLoading={infiniteLoad}
              loading={<RecordsSpinner />}
              noMore={(financeReportStatus && financeReportStatus.ReportEndTime && (financeReportStatus.Status === 'CSV_UPLOADED'|| financeReportStatus.Status === 'REPORT_GENERATED')) ? <RecordsBadge back_top={true} recordTypeLabel="records" /> : null}
              initialLoad={false}
            >
              <ToolkitProvider
                keyField="id"
                data={data.tableData}
                columns={[...data.tableColumns]}
                exportCSV={{ onlyExportFiltered: true, exportAll: false, fileName: `FinanceReport-${(financeReportStatus && financeReportStatus.ReportEndTime && financeReportStatus.Status === 'CSV_UPLOADED') ? moment.utc(financeReportStatus.ReportEndTime).format('l LT') : ''}-UTC.csv` }}
              >
                {(props) => (
                  <div className="finance-new">
                  <Card>

                    <CardHeader style={{position:'fixed',width:'-webkit-fill-available',zIndex:'999',top:'92px',borderBottom:'1px solid lightgray'}}>
                    <div className="row inner" style={{display:'flex',flexWrap:'nowrap', marginTop: "60px"}}>
                    <div className="col-lg-3 col-md-4 float-left">
                      <h3>
                        Showing {record.records?.length || 0} of{" "}
                        {record.totalItems || 0} Waves
                      </h3>
                      {financeReportStatus && financeReportStatus.ReportEndTime && <span className='small'>Last refresh on : {moment.utc(financeReportStatus.ReportEndTime).format('l LT')} UTC</span>}
                    </div>
                    <div className='col-lg-5 col-md-6 p-0'>
                      {financeReportStatus && financeReportStatus.ReportEndTime && financeReportStatus.FileId
                        && financeReportProjectLevelStatus.FileId &&
                        <div className="row">
                        <div className='col-lg-6 col-md-6 p-0'>
                          <button
                              className="btn btn-secondary ml-2"
                              onClick={() => {
                                gaEventTracker(EventName.ExportWaveLevelFinanceReport);
                                loadAllFinances(financeReportStatus)
                              }
                              }
                            >
                              Export Wave Level Finance Report
                          </button>
                        </div>
                        <div className='col-lg-6 col-md-6 p-0'>
                          {/* {financeReportStatus && financeReportStatus.ReportEndTime && <span className='small'>Last refresh on : {moment.utc(financeReportStatus.ReportEndTime).format('l LT')} UTC</span>} */}
                            <button
                              className="btn btn-secondary ml-2"
                              onClick={() => {
                                gaEventTracker(EventName.ExportProjectLevelFinanceReport);
                                loadAllFinances(financeReportProjectLevelStatus)
                              }
                            }
                            >
                              Export Project Level Finance Report
                            </button>
                        </div>
                        </div>
                      }
                     </div> 
                     <div className='col-lg-4 col-md-6'>
                      {financeReportStatus && financeReportStatus.ReportEndTime && (financeReportStatus.Status === 'CSV_UPLOADED'|| financeReportStatus.Status === 'REPORT_GENERATED') &&
                        <div className="row">
                          <div className='col-lg-3 col-md-6 text-center ml-1 mr-1'>
                          <ExportCSV {...props.csvProps} />
                          </div>
                          <div className='col-lg-6 col-md-6 p-0'>
                              <button
                                className="btn btn-secondary mr-2"
                                onClick={() => {
                                  openSelectColumns(!selectColumns);
                                  setResetDisplayCols(selectedTempDisplayColumns);
                                }}
                              >
                                Select Columns to display
                              </button>
                            </div>
                         </div>
                      }
                    </div>
                    </div>
                    </CardHeader>
                    <CardBody className="p-4" style={{position:'relative',marginTop:financeReportStatus && financeReportStatus.ReportEndTime && financeReportStatus.Status === 'CSV_UPLOADED'?'5rem':'10rem'}} >
                    <p className={`text-danger text-center`}style={{position:'fixed',left:'45%'}}>{getReportStatus(financeReportStatus)}</p>
                      {financeReportStatus && financeReportStatus.ReportEndTime && (financeReportStatus.Status === 'CSV_UPLOADED'|| financeReportStatus.Status === 'REPORT_GENERATED') ? <>
                        {data.tableColumns && data.tableColumns.length ? (
                          <BootstrapTable
                            id="mapping_table"
                            classes="sticky-top-table finance-new-report"
                            remote={{ filter: true }}
                            {...props.baseProps}
                            bootstrap4
                            bordered={true}
                            striped
                            hover
                            condensed
                            onTableChange={handleTableChange}
                            filter={filterFactory()}
                            noDataIndication={<b>No data available</b>}

                          />
                        ) : null}
                      </> : null
                      }
                    </CardBody>
                  </Card>
                  </div>
                )}
              </ToolkitProvider>
            </InfiniteScroll>
          </>
          {/* ) : null} */}
          <Modal
            toggle={() => openSelectColumns(!selectColumns)}
            isOpen={selectColumns}
            centered={true}
          >
            <ModalHeader toggle={() => openSelectColumns(!selectColumns)}>
            {/* <img src={logo}  className="modallogo"/> */}
            </ModalHeader>
            <div>
                        <span className="modaltitle modaltitleDisplay">
                        Select Columns to display
                        </span>
                    </div>
            <ModalBody>
              <Row className="justify-content-end">
                <a
                  className="select-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTempDisplayColumns([...allColumns]);
                  }}
                >
                  Select all
                </a>
                <a
                  className="ml-2 mr-4 select-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTempDisplayColumns([]);
                  }}
                >
                  Deselect all
                </a>
              </Row>
              {allColumns.map((ac, index) => (
                <Col lg="6" className="d-inline-flex">
                  <span>
                    <input
                      id={`column_${index}`}
                      type="checkbox"
                      checked={_.includes(selectedTempDisplayColumns, ac)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedTempDisplayColumns([
                            ...selectedTempDisplayColumns,
                            ac,
                          ]);
                        } else {
                          setSelectedTempDisplayColumns([
                            ...selectedTempDisplayColumns.filter(
                              (sdc) => sdc != ac
                            ),
                          ]);
                        }
                      }}
                    />
                    <label className="ml-2 pointer" for={`column_${index}`}>
                      {ac}
                    </label>
                  </span>
                </Col>
              ))}
            </ModalBody>
            <ModalFooter>
              <Row>
                <Button
                  color="primary"
                  size="sm"
                  className="mr-2"
                  onClick={() => {
                    setSelectedDisplayColumns([...selectedTempDisplayColumns]);
                    openSelectColumns(!selectColumns);
                    // setSelectedTempDisplayColumns([])
                  }}
                >
                  Confirm
                </Button>
                <Button
                  color="secondary"
                  size="sm"
                  onClick={() => {
                    openSelectColumns(!selectColumns);
                    setSelectedTempDisplayColumns([...resetDisplayCols]);
                    setResetDisplayCols([]);
                  }}
                >
                  Close
                </Button>
              </Row>
            </ModalFooter>
          </Modal>
        </DashboardLayout >
      ) : (
        <FinanceLogs setShowLogs={setShowLogs} />
      )}
    </div>
  );
};

export default FinanceDashboard;
