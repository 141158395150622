import React, { useState, useEffect } from "react"
import _, { values } from "lodash";
import Select from "react-select";
import { useDispatch } from 'react-redux';
import update from 'immutability-helper';
import { useSelector } from "react-redux";
import { getLabel } from "../utils/codeLabels";
import { useHistory, useLocation } from "react-router-dom";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon,
    Label, Row, Table, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    NavItem,
    NavLink,
    Nav
} from "reactstrap";
import classnames from "classnames";
import Spinner from "../components/Spinner";
import * as currentProjectActions from "../redux/actions/currentProjectActions";
import * as currentCostingActions from "../redux/actions/currentCostingActions";
import { toastr } from "react-redux-toastr";
import * as countryActions from "../redux/actions/countrySpecsActions";
import {
    pageLoadEnd,
    pageLoadStart,
    recordLoadEnd,
} from "../redux/actions/appActions";
import axios from "../axios-interceptor";
import { SET_VENDOR_FLAG } from "../redux/actions/vendor/ActionTypes";

import logo from "../../src/assets/img/avatars/modal-logo.png"; 

function CostingTypeMapping(props) {
    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );
    const currentCostingProfile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
    );
    var imgStyle = {
        width: "150px",
      };
    const headderStyle = {

        backgroundColor: "#dcdcdc",
        // height: "40px",
        width: "100%",
        fontWeight: "bolder",
        alignItems: "center",
        fontSize: "12px",
        marginBottom: "10px",
        marginTop: "10px",
        marginLeft: 0
    }
    const CostingTypesEnum = {
        INPUTDIRECTLY: 1,
        MSSHEETS: 2,
        VENDORBIDDING: 3,
    }
    const [cmlistSheet1, setcmlistSheet1] = useState([]);
    const [cmlistDirect1, setcmlistDirect1] = useState([]);
    const [activeTabSMB, setActiveTabSMB] = useState(0);
    const [showVendor, setShowVendor] = useState(false);
    const history = useHistory();
    const [isCostSelection, setCostSelection] = useState(false);
    const [showCostMethod, setShowCostMethod] = useState(false)
    const [updateCost, setUpdateCost] = useState()
    let pacificData = props.pacificData;
    let arr = pacificData?.split(',');
    const [pacificMarkets] = useState(arr)
    const [activeTabFCB, setActiveTabFCB] = useState(0);
    const app = useSelector(({ app }) => app);
    const dispatch = useDispatch();
    const [value, setValues] = useState([]);
    const [methodologies, setMethodologies] = useState([]);
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);
    var fieldingCountries = Array.isArray(currentCostingProfile?.FieldingCountries) ? currentCostingProfile?.FieldingCountries.map(fc => fc.value) : currentCostingProfile?.FieldingCountries?.split(",")
    var shopperMethodologies = codeLabels?.SubMethodologyOptions?.filter(sm => sm.IsVendorBiddingApplicable)?.map(c => c.Code)
    useEffect(() => {
        setMeth()

    }, [props.showCostMethod, props.isCostSelection])
    useEffect(() => {
        setMethodologies(value[activeTabFCB]?.Methodology?.filter(x => !x.costType))
    }, [activeTabFCB])

    useEffect(() => {
        if (fieldingCountries.length > 0)
            setShowVendor(!_.includes(fieldingCountries, currentCostingProfile?.CountrySpecs[0]?.CountryCode))
    }, [])

    function handleClose() {
        setMeth()
        props.setShowCostMethod(false)
        if (currentCostingProfile.updateCost && currentCostingProfile.updateCost == true) {
            dispatch(
                currentCostingActions.updateProfile({
                    ...currentCostingProfile,
                    updateCost: false,
                })
            );
        }
    }

    function setMeth() {
        let temp = []
        if (_.head(currentCostingProfile.CountrySpecs)?.MethodologySpecs) {
            currentCostingProfile?.CountrySpecs.forEach(country => {
                let metharray = []
                country.MethodologySpecs.forEach(meth => {
                    if (_.includes(shopperMethodologies, meth.Code) || !_.includes(fieldingCountries, country.CountryCode)) {
                        metharray.push({ value: meth.Code, label: meth.Label, costType: 2, shoppers: true, selected: false })
                    }
                    else {
                        if (meth?.NotApplicable == true) {
                            metharray.push({ value: meth.Code, label: meth.Label, costType: 2, shoppers: true, selected: false })
                        }
                        else {
                            metharray.push({ value: meth.Code, label: meth.Label, costType: 2, shoppers: false, selected: true })
                        }
                    }
                })
                temp.push({ Code: country.CountryCode, Label: country.CountryName, Methodology: metharray })
            })
        }
        setValues(temp)
        setMethodologies(temp[activeTabFCB]?.Methodology?.filter(x => !x.costType))
    }

    const submitSelection = (costType) => {

        var commissiongCountryerror = false;
        var commissiongCountry = false;
        if (!_.includes(fieldingCountries, project.CommissioningCountry)) {
            commissiongCountry = true
        }
        else if (_.includes(fieldingCountries, project.CommissioningCountry)) {
            commissiongCountry = false
        }

        props.setCostSelection(false);
        if (costType == parseInt(codeLabels.CostingType[0].Code)) {
            history.push(
                {
                    pathname: `/costing-input/${currentCostingProfile.id}`,
                    state: "DIRECTLY"
                })

        }
        else if (costType == parseInt(codeLabels.CostingType[1].Code)) {

            // history.push({
            //     pathname: `/costing-input/${currentCostingProfile.id}`,
            //     state: "SHEETS"
            // })

        }
        else if (costType == parseInt(codeLabels.CostingType[2].Code)) {
            let sampleSizeavailable = false;
            currentCostingProfile.CountrySpecs.forEach((c, i) => {
                c.MethodologySpecs.forEach((m, mi) => {
                    if (value[0].Methodology.filter(meth => meth.value == m.Code)[0].costType == 3 && commissiongCountry) {
                        commissiongCountryerror = true
                    }
                    if (value[i].Methodology.filter(meth => meth.value == m.Code)[0].costType == 3) {

                        m.CostingOptions.forEach(co => {

                            if (co.isSelected && ((co?.CostingOptionData?.sampleSizeMain || co?.CostingOptionData?.sampleSize ||
                                co?.CostingOptionData?.numberOfGroup) <= 0 || isNaN(co?.CostingOptionData?.sampleSizeMain ||
                                    co?.CostingOptionData?.sampleSize || co?.CostingOptionData?.numberOfGroup))) { sampleSizeavailable = true }

                        }
                        )
                    }
                })
            })
            if (commissiongCountryerror) {
                toastr.error(`Please Select Costing type as MS Sheet for Commissiong Country as it is removed from Fielding Countries`)
                return true
            }
            if (sampleSizeavailable) {
                toastr.error("Please Add SampleSize/Number of Groups for the Methodologies which are Selected for Vendor Bidding")
                return true
            }

            else {
                dispatch({ type: SET_VENDOR_FLAG, Vendor: true })
                history.push('/vendordashboard/vendor-bidding/' + project.ProjectId +"/"+_.last(project.CostingProfiles).id)
            }
        }
        dispatch(recordLoadEnd());
    }

    const checkFunction = () => {
        var lengthMethodology = currentCostingProfile.CountrySpecs.map(cs => { return cs.MethodologySpecs.length }).reduce((result, sm) => result + sm)

        if ((value && value.map(sm => { return (sm?.Methodology?.filter(cm => cm.costType > 0).length) }).reduce((result, sp) => result + sp) == lengthMethodology)) {
            if (lengthMethodology == value.map(sm => { return (sm?.Methodology?.filter(cm => cm.costType == 1).length) }).reduce((result, sp) => result + sp))
                submitSelection(1);
            else if (lengthMethodology == value.map(sm => { return (sm?.Methodology?.filter(cm => cm.costType == 2).length) }).reduce((result, sp) => result + sp))
                submitSelection(2);
            else if (lengthMethodology == value.map(sm => { return (sm?.Methodology?.filter(cm => cm.costType == 3).length) }).reduce((result, sp) => result + sp))
                submitSelection(3);
            else {
                props.setCostSelection(true);
            }
        }

        else
            props.setShowCostMethod(true);
    }

    function toggleB(tab) {
        if (activeTabFCB !== tab) {
            setActiveTabFCB(tab);
            setShowVendor(!_.includes(fieldingCountries, currentCostingProfile.CountrySpecs[tab]?.CountryCode))
            setActiveTabSMB(currentCostingProfile.CountrySpecs[tab].MethodologySpecs.map((m, mIndex) => ({
                methodology: m,
                index: mIndex,
            }))?.filter(x => !x.methodology.NotApplicable)[0]?.index);
        }
    }


    const UpdateCostMethod = (method) => {
        var commissiongCountryerror = false;
        var commissiongCountry = false
        let fieldingCountries = Array.isArray(currentCostingProfile.FieldingCountries) ? currentCostingProfile.FieldingCountries.map(fc => fc.value) : currentCostingProfile.FieldingCountries?.split(",")
        if (!_.includes(fieldingCountries, project.CommissioningCountry)) {
            commissiongCountry = true
        }
        else if (_.includes(fieldingCountries, project.CommissioningCountry)) {
            commissiongCountry = false
        }


        var Vendorflag = value?.map(cm => cm?.Methodology?.filter(cc => cc.costType == 3)?.length).reduce((r, v) => r + v) > 0 ? 3 : 0
        let _currentCostingProfile = _.cloneDeep(currentCostingProfile);
        var allCostingTypeSelection = false;
        var sampleSizeavailable = false;
        //setting MS sheets type

        _currentCostingProfile["updateCost"] = false
        _currentCostingProfile.CountrySpecs.forEach((c, i) => {
            c.MethodologySpecs.forEach((m, mi) => {
                if (value[0].Methodology.filter(meth => meth.value == m.Code)[0].costType == 3 && commissiongCountry) {
                    commissiongCountryerror = true
                }
                if (value[i].Methodology.filter(meth => meth.value == m.Code)[0].costType == 3) {
                    m.CostingOptions.forEach(co => {
                        if (co.isSelected && ((co?.CostingOptionData?.sampleSizeMain || co?.CostingOptionData?.sampleSize ||
                            co?.CostingOptionData?.numberOfGroup) <= 0 || isNaN(co?.CostingOptionData?.sampleSizeMain ||
                                co?.CostingOptionData?.sampleSize || co?.CostingOptionData?.numberOfGroup))) { sampleSizeavailable = true }
                    }
                    )


                }
                m.CostingType = value[i].Methodology.filter(meth => meth.value == m.Code)[0].costType
            })
            if (value.map(sm => { return (sm?.Methodology?.filter(cm => !cm.costType).length) }).reduce((result, sp) => result + sp)) {
                allCostingTypeSelection = true
            }
            else {
                allCostingTypeSelection = false
            }
        })

        if (allCostingTypeSelection) {
            toastr.error("Please select costing type for all countries/methodologies")
            return true
        }
        if (commissiongCountryerror) {
            toastr.error(`Please Select Costing type as MS Sheet for Commissiong Country as it is removed from Fielding Countries`)
            return true
        }
        if (sampleSizeavailable) {
            toastr.error("Please Add SampleSize/Number of Groups for the Methodologies which are Selected for Vendor Bidding")
            return true
        }
        dispatch(pageLoadStart())
        const _project = update(project, {
            CostingProfiles: {
                [project.CostingProfiles?.length - 1]: {
                    $set: _currentCostingProfile
                }
            }
        })
        // _currentCostingProfile.CountrySpecs[0].MethodologySpecs[0].CostingType = 2
        _currentCostingProfile.CountrySpecs.forEach((data)=>{
            data.MethodologySpecs.forEach((meth)=>{
              meth.CostingType = 2
            })
          })
        axios
            .put("/costingprofiles/" + _currentCostingProfile.id, _currentCostingProfile, {
                headers: { "auth-token": localStorage.getItem("auth-token") },
            }).then(res => {
                dispatch({
                    type: "UPDATE_NEW_COSTING",
                    currentCostingProfile: _currentCostingProfile,
                });
                dispatch(
                    currentProjectActions.saveProject({ ..._project, CostingType: Vendorflag })
                );
                dispatch({
                    type: currentProjectActions.UPDATE_NEW_PROJECT,
                    newProject: { ..._project, CostingType: Vendorflag },
                });
                checkFunction()
                dispatch(pageLoadEnd())
                dispatch(countryActions.setCountrySpecs(_currentCostingProfile.CountrySpecs));

            })
        props.setShowCostMethod(false);
        props.setUpdateCost(false)
        let clone = _.cloneDeep(currentCostingProfile)
        clone.updateCost = false
        dispatch({
            type: "UPDATE_NEW_COSTING",
            currentCostingProfile: clone,
        });
    };
    function UpdateCostingType(meth, val) {
        var input = meth == null ? [] : meth
        var toSet = _.cloneDeep(value)
        var filter = toSet[activeTabFCB]?.Methodology?.filter(a => a.costType == val)
        if (val == 2 && toSet[activeTabFCB]?.Methodology?.filter(x => x.selected == false).length == toSet[activeTabFCB]?.Methodology.length) {

        }
        else {
            if (filter?.length > input?.length) {
                toSet[activeTabFCB].Methodology.filter(x => x.costType == val).forEach(check => {
                    if (input?.filter(a => a.value == check.value).length > 0 || check.shoppers) {
                        check.costType = val
                    }
                    else {
                        check.costType = null
                    }
                })
            }
            else {
                toSet[activeTabFCB].Methodology.forEach(x => {
                    if (input?.filter(a => a.value == x.value).length > 0) {
                        x.costType = val
                    }
                })
            }
        }

        setValues(toSet)
        setMethodologies(toSet[activeTabFCB]?.Methodology?.filter(x => !x.costType))
    }
    function UpdateAllMethodologies(e, val) {
        var toSet = _.cloneDeep(value)
        var filter = toSet[activeTabFCB]?.Methodology?.filter(a => a.costType == val)
        if (val == 2 && toSet[activeTabFCB]?.Methodology?.filter(x => x.selected == false).length == toSet[activeTabFCB]?.Methodology.length) {

        }
        else {
            toSet[activeTabFCB].check = e

            if (e) {
                val != 3 ?
                    toSet[activeTabFCB]?.Methodology?.forEach(value => { value.costType = val })
                    : toSet[activeTabFCB]?.Methodology?.filter(m => !m.shoppers && m.selected)?.forEach(value => { value.costType = val })
            }
            else {
                toSet[activeTabFCB]?.Methodology?.forEach(value => { value.costType = null })
            }
        }
        setValues(toSet)
        setMethodologies(toSet[activeTabFCB]?.Methodology?.filter(x => !x.costType))
    }
    return (
        <>
            <Modal
                isOpen={props.showCostMethod || props.updateCost}
                toggle={() => {
                    props.setShowCostMethod(false)
                    if (currentCostingProfile.updateCost && currentCostingProfile.updateCost == true) {
                        dispatch(
                            currentCostingActions.updateProfile({
                                ...currentCostingProfile,
                                updateCost: false,
                            })
                        );
                    }
                }}
                centered={true}
                size={"lg"}
                backdrop={"static"}

            >
                <ModalHeader
                    toggle={() =>
                    //props.setShowCostMethod(!props.showCostMethod)
                    {
                        handleClose()
                    }}>
                    {/* <img src={logo}  className="modallogo"/> */}

                </ModalHeader>
                <div>
                <span className="modaltitle">Select Costing Method</span></div>
                <ModalBody>
                    <h5>
                        Please Note: You can only select one costing method per
                        Current Costing Profile. Some options may not be available depending
                        on your market's default settings.
                    </h5>

                    <div style={{ "display": "flex", "flex-direction": "column" }}>
                        <Row>
                            <Col md={12} className={'methodologyTabs'}>
                                <Nav tabs>
                                    {project.CostingProfiles.length > 0 && _.last(project.CostingProfiles).CountrySpecs &&
                                        _.last(project.CostingProfiles).CountrySpecs.map((fc, indxFC) => (
                                            <NavItem key={fc.value}>
                                                <NavLink
                                                    className={classnames({ active: activeTabFCB === indxFC })}
                                                    onClick={() => {
                                                        toggleB(indxFC);
                                                        // loadCostBreakDown()
                                                    }}
                                                >
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px" }}>
                                                        <div>
                                                            <b>{fc.CountryName}</b>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </NavItem>
                                        ))}
                                </Nav>

                            </Col>
                        </Row>
                    </div>


                    <Row style={headderStyle}>
                        <Col md={3}> Select Costing Process</Col>
                        <Col md={3}> Select If Applicable to all methodologies</Col>
                        <Col md={3}> Select Methodologies</Col>
                    </Row>
                    {(_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion != "v2.0") && <Row>
                        <Col md={3}>
                            <div>
                                <br></br >
                                <h7 >{getLabel("CostingType", "1")}</h7>
                            </div>
                        </Col>
                        <Col md={3} >
                            <br></br >
                            <Input type="checkbox"

                                defaultChecked={false} checked={(value[activeTabFCB]?.Methodology?.length == value[activeTabFCB]?.Methodology?.filter(a => a.costType == 1).length) ? true : false}

                                onChange={(e) => UpdateAllMethodologies(e.target.checked, 1)}
                            />

                        </Col>
                        <Col md={6}>
                            <Label></Label>
                            {<FormGroup>
                                <Select
                                    name="costingDirect"
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    isMulti
                                    isSearchable
                                    value={value[activeTabFCB]?.Methodology?.filter(a => a.costType == 1)}
                                    onChange={(e) => UpdateCostingType(e, 1)}

                                    options={methodologies
                                    }
                                />
                            </FormGroup>
                            }

                        </Col>
                    </Row>}

                    <Row>
                        <Col md={3}>
                            <br></br >
                            <h7>
                                {(getLabel("CostingType", "2"))}
                            </h7>


                        </Col>
                        <Col md={3}>
                            <br></br >
                            <Input type="checkbox"
                               defaultChecked={false}
                               checked={(value[activeTabFCB]?.Methodology?.length == value[activeTabFCB]?.Methodology?.filter(a => a.costType == 2).length) ? true : false}
                                onChange={(e) => UpdateAllMethodologies(e.target.checked, 2)}
                            />
                        </Col>
                        <Col md={6}>
                            <Label></Label>
                            {<FormGroup>
                                <Select
                                    name="costingSheet"
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    isMulti
                                    isSearchable
                                    value={value[activeTabFCB]?.Methodology?.filter(a => a.costType == 2 || a.selected == false)}
                                    onChange={(e) => UpdateCostingType(e, 2)}

                                    options={
                                        methodologies
                                    }
                                />
                            </FormGroup>
                            }

                        </Col>
                    </Row>
                    {/* <Row>{
                        <Col md={3}>
                            <br></br >
                            {
                                (getLabel("CostingType", "3"))
                            }

                        </Col>}
                        <Col md={3} >

                            <br></br >
                            {

                                <Input type="checkbox"
                                    disabled={showVendor}
                                    defaultChecked={false} checked={(value[activeTabFCB]?.Methodology?.length == value[activeTabFCB]?.Methodology?.filter(a => a.costType == 3).length) ? true : false}
                                    onChange={(e) => UpdateAllMethodologies(e.target.checked, 3)} />
                            }
                        </Col>
                        <Col md={6}>
                            <Label></Label>
                            {<FormGroup>
                                <Select
                                    name="costingVendor"
                                    className="react-select-container is-invalid"
                                    classNamePrefix="react-select"
                                    isMulti
                                    isSearchable
                                    disabled={showVendor}
                                    onChange={(e) => UpdateCostingType(e, 3)}
                                    value={value[activeTabFCB]?.Methodology?.filter(a => a.costType == 3 && a.selected == true)}

                                    options={
                                        methodologies?.filter(a => !a.shoppers && a.selected == true)
                                    }
                                />
                            </FormGroup>
                            }

                        </Col>
                    </Row> */}



                </ModalBody>
                <ModalFooter>
                    {currentCostingProfile ? (
                        <Button
                            color="primary"
                            disabled={app.recordloading}
                            onClick={() => UpdateCostMethod()}
                        >
                            Submit
                            {app.recordloading &&
                                currentCostingProfile.CostingType == "VENDOR" ? (
                                <>
                                    <Spinner size="small" color="#cccccc" />
                                </>
                            ) : null}
                        </Button>
                    ) : null}{" "}
                    <Button
                        onClick={() => {
                            handleClose()
                        }}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>


            <Modal
                isOpen={props.isCostSelection}
                toggle={() => props.setCostSelection(!props.isCostSelection)}
                centered={true}
                size=""
            >

                <ModalHeader toggle={() => props.setCostSelection(!props.isCostSelection)}>
                    {/* <img src={logo}  className="modallogo"/> */}
                </ModalHeader>
                <h4>Select Costing Method</h4>
                <ModalBody>
                    <h5>
                        Please select appropriate costing input to continue
                    </h5>
                    <Row style={{ height: 100 }} ></Row>
                    <Row>
                        {(value
                            && value.map(sm => { return (sm?.Methodology?.filter(cm => cm.costType == 1).length) }).length > 0
                            && value.map(sm => { return (sm?.Methodology?.filter(cm => cm.costType == 1).length) }).reduce((result, sp) => result + sp) > 0
                            && (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion != "v2.0"))
                            ?
                            <Col style={{ textAlign: "center" }}>

                                <Button

                                    onClick={(e) => submitSelection(1)}
                                >
                                    Costing Input Directly
                                    {app.recordloading &&
                                        currentCostingProfile.CostingType == "VENDOR" ? (
                                        <>
                                            <Spinner size="small" color="#cccccc" />
                                        </>
                                    ) : null}
                                </Button>
                            </Col> : ""
                        }
                        {
                            (value &&
                                value.map(sm => { return (sm?.Methodology?.filter(cm => cm.costType == 2).length) }).length > 0 &&
                                value.map(sm => { return (sm?.Methodology?.filter(cm => cm.costType == 2).length) }).reduce((result, sp) => result + sp) > 0) ?
                                <Col style={{ textAlign: "center" }}>
                                    {value &&
                                        value.map(sm => { return (sm?.Methodology?.filter(cm => cm.costType == 2).length) }).length > 0 && value.map(sm => { return (sm?.Methodology?.filter(cm => cm.costType == 2).length) }).reduce((result, sp) => result + sp) > 0}

                                    <Button
                                        color="primary"

                                        onClick={() => submitSelection(2)
                                        }

                                    >
                                        MS Sheets
                                        {app.recordloading &&
                                            currentCostingProfile.CostingType == "VENDOR" ? (
                                            <>
                                                <Spinner size="small" color="#cccccc" />
                                            </>
                                        ) : null}
                                    </Button>
                                </Col> : ""}
                        {/* {(value
                            && value.map(sm => { return (sm?.Methodology?.filter(cm => cm.costType == 3).length) }).length > 0
                            && value.map(sm => { return (sm?.Methodology?.filter(cm => cm.costType == 3).length) }).reduce((result, sp) => result + sp) > 0)
                            ? <Col style={{ textAlign: "center" }}>
                                <Button
                                    color="primary"
                                    onClick={(e) =>
                                        submitSelection(3)}
                                >
                                    Vendor Bidding
                                    {app.recordloading &&
                                        currentCostingProfile.CostingType == "VENDOR" ? (
                                        <>
                                            <Spinner size="small" color="#cccccc" />
                                        </>
                                    ) : null}
                                </Button>
                            </Col> : ""} */}
                    </Row>
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>
        </>
    )
}
export default CostingTypeMapping