import React from "react";

function FolderOpen() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#333"
          d="M21.75 9.5V8a3 3 0 00-3-3h-6.3l-3-3H3a3 3 0 00-3 3v18h21.75s1.916-9.3 2.175-10.875A2.193 2.193 0 0021.75 9.5zM1.5 5A1.5 1.5 0 013 3.5h5.828l3 3h6.922a1.5 1.5 0 011.5 1.5v1.5h-6l-3 3h-9L1.5 16V5z"
        ></path>
      </g>
    </svg>
  );
}

export default FolderOpen;