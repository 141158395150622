import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";
import * as costingsActions from "../../redux/actions/costingsActions";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import * as currentWaveSpecActions from "../../redux/actions/currentWaveSpecActions";
import axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronRight,
	faChevronDown,
	faChevronUp,
	faQuestionCircle,
	faPen,
} from "@fortawesome/free-solid-svg-icons";

import {
	Button,
	Row,
	Col,
	Card,
	CardBody,
	Container,
	Collapse,
	CardHeader,
	CardTitle,
	CardText,
	Badge,
	Input,
	Label,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from "reactstrap";
import { setWaveSpecs } from "../../redux/actions/waveSpecsActions";
import Selector from "../../components/Selector/Selector_ProjectReview";
import { getLabel } from "../../utils/codeLabels";
import FormFeedback from "reactstrap/lib/FormFeedback";
import Spinner from "../../components/Spinner";

const ReviewSchedule = ({ profile, indxFC, methodology, indxSM,disableforCS }) => {
	const user = useSelector(({ user }) => user.userRecord);
	const project = useSelector(
		({ currentProject }) => currentProject.newProject
	);
	const costingProfiles = useSelector(
		({ costings }) => costings.costingProfiles
	);
	// const currentCostingProfile = useSelector(
	// 	({ currentCosting }) => currentCosting.currentCostingProfile
	// );

	const [editableCostingProfile, setEditableCosting] = useState(
		profile
	);
	const userRecord = useSelector(({ user }) => user.userRecord);

	const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
	// const currentWaveSpec = useSelector(({ currentWaveSpec }) => currentWaveSpec);
	const codeLabels = useSelector(({ codeLabels }) => codeLabels);
	const app = useSelector(({ app }) => app);

	const [waveEditModal, setWaveEditModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [editableWaveName, setEditableWavename] = useState();
	const dispatch = useDispatch();
	const [waveBreakDown, setWaveBreakDown] = useState({})
	const [currentWaveSpec, setcurrentWaveSpec] = useState([])
	const [modifiedMeths, setModifiedMeths] = useState([])
	const [wavesSpecsData, setwavesSpecsData] = useState(profile.WaveSpecs);
	const [activeTabFC, setActiveTabFC] = useState();
	const [activeTabSM, setActiveTabSM] = useState();
	const [toggle, setToggle] = useState({
		overall: true,
		inst: true,
		other: true,
	});
	const [selectedItemWave, setSelectedItemWave] = useState("1");

	const [Fields, updateFields] = useState({
		Charting: {
			id: "Charting",
			DisablingVal: "DateChartsNA",
			DisplayDate: "DateCharts",
			DateField:
			{
				id: "DateChartsActual",
				label: "Date Charts Due",
			},

			NotesField: {
				id: "NotesChartsActual",
				label: "Notes for Charting Team",
			},
			decisiveField: "chartingResource",
		},
		Dashboarding: {
			id: "Dashboarding",
			DisablingVal: "DateDashboardsNA",
			DisplayDate: "DateDashboards",
			DateField:
			{
				id: "DateDashboardsActual",
				label: "Date Dashboard Due",
			},
			NotesField: {
				id: "NotesDashboardsActual",
				label: "Notes for Dashboarding Team",
			},
			decisiveField: "dashboardingResource"
		},
		"Data Processing": {
			id: "Data Processing",
			DisablingVal: "DateDataProcessingNA",
			DisplayDate: "DateDataProcessing",
			DateField:
			{
				id: "DateDataProcessingActual",
				label: "Date Final Table",
			},
			NotesField: {
				id: "NotesDataProcessingActual",
				label: "Notes for Data Processing Team",
			},
			decisiveField: "dataProcessingResource",
		},
		"Field Work Start": {
			id: "Field Work",
			DisablingVal: "DateFieldworkNA",
			DisplayDate: "DateFieldStart",
			DateField:
			{
				id: "DateFieldStartActual",
				label: "Planned FW Start Date",
			},
			NotesField: {
				id: "NotesFieldStartActual",
				label: "Notes for Field Start",
			},
			decisiveField: ""
		},
		"Field Work End": {
			id: "Field Work",
			DisablingVal: "DateFieldworkNA",
			DisplayDate: "DateFieldEnd",
			DateField:
			{
				id: "DateFieldEndActual",
				label: "Planned FW End Date",
			},
			NotesField: {
				id: "NotesFieldEndActual",
				label: "Notes for Field End",
			},
			decisiveField: ""
		},
		"Survey Programming": {
			id: "SurveyProgramming",
			DisablingVal: "DateFinalQuestionnaireNA",
			DisplayDate: "DateFinalQuestionnaire",
			DateField:
			{
				id: "DateFinalQuestionnaireActual",
				label: "Date Final Questionnaire Available",
			},
			NotesField: {
				id: "NotesFinalQuestionnaireActual",
				label: "Notes for Programming team",
			},
			decisiveField: "surveyProgrammingResource"
		},
		"Final Report": {
			id: "FinalReport",
			DisablingVal: "DateFinalReportNA",
			DisplayDate: "DateFinalReport",
			DateField:
			{
				id: "DateFinalReportActual",
				label: "Date Final Report Due to the Client",
			},
			NotesField: {
				id: "NotesFinalReportActual",
				label: "Any Notes on Final Reporting",
			},
			decisiveField: ""
		},
		Translations: {
			id: "Translations",
			DisablingVal: "DateTranslationsNA",
			DisplayDate: "DateTranslations",
			DateField:
			{
				id: "DateTranslationsActual",
				label: "Date Translations Due",
			},
			NotesField: {
				id: "NotesTranslationsActual",
				label: "Notes for Translations Team",
			},
			decisiveField: "translationsResource"
		},
		"Verbatim Coding": {
			id: "VerbatimCoding",
			DisablingVal: "DateVerbatimCodingNA",
			DisplayDate: "DateVerbatimCoding",
			DateField:
			{
				id: "DateVerbatimCodingActual",
				label: "Date Verbatim Coding Due",
			},
			NotesField: {
				id: "NotesVerbatimCodingActual",
				label: "Notes for Verbatim Coding Team",
			},
			decisiveField: "verbatimCodingResource"
		}
	})
	useEffect(() => {
		if (profile &&
			wavesSpecsData
			&& wavesSpecsData.length
			&& !Object.keys(waveBreakDown).length) {

			let data = checkCurrentWaveSpec(profile, selectedItemWave);
			setcurrentWaveSpec(data);
		}   else
        {
            let rData = (profile.CountrySpecs[indxFC]);
            let checData = (rData?.MethodologySpecs[indxSM]);        
            let _waveBreakdownMain = profile.WaveSpecs.filter(x => x.MethodologySpecId === checData.id);
            setModifiedMeths(_waveBreakdownMain);
        }
	}, [profile])
	useEffect(() => {
		setEditableCosting(profile);
	}, [profile]);

	const checkCurrentWaveSpec = (profileVal, selectedItemWave) => {
		let rData = (profileVal.CountrySpecs[indxFC]);
		let checData = (rData?.MethodologySpecs[indxSM]);
		setActiveTabFC(rData.id);
		setActiveTabSM(checData.id);
		let _waveBreakdownMain = profileVal.WaveSpecs.filter(x => x.MethodologySpecId === checData.id);
		let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber);
		setModifiedMeths(_waveBreakdownMain);

		Object.keys(_waveBreakdown).map(w => {
			_waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel);
		});
		setwavesSpecsData(profileVal.WaveSpecs);
		setWaveBreakDown(_waveBreakdown);
		let _currentwaveset = _.head(_waveBreakdown[selectedItemWave]);
		return _currentwaveset;
	}
	const selectorHandler = (item) => {
		setSelectedItemWave(item.WaveNumber);
		let _waveBreakdownMain = profile.WaveSpecs.filter(x => x.MethodologySpecId === activeTabSM)
		let _waveBreakdown = _.groupBy(_waveBreakdownMain, m => m.WaveNumber)
		setModifiedMeths((_waveBreakdownMain))
		Object.keys(_waveBreakdown).map(w => {
			_waveBreakdown[w] = _.uniqBy(_waveBreakdown[w], t => t.MethodologyLabel)
		})
		// do nothing if clicked item is current item
		if (item === (currentWaveSpec)?.MethodologySpecId) return;
		else {
			let data = _.head(_waveBreakdown[item.WaveNumber])
			setcurrentWaveSpec(data);
		}
	};

	const renderSelector = () => {
		if (!wavesSpecsData || (wavesSpecsData && wavesSpecsData.length === 1)) return null;
		return (
			<Col lg="2" md="2" sm="12" xs="12">
				<Selector
					heading={"Waves"}
					records={modifiedMeths}
					// applyAll={applyToAllWaves}
					// applyAllText={"Apply to All Waves"}
					clicked={selectorHandler}
					interPolField={["WaveNumber", "WaveName"]}
					displayField={
						<>
							<FontAwesomeIcon
								title="Edit Wave Name"
								size="xs"
								icon={faPen}
								className={userRecord.IsOpsProjectManager ? "pointer" : "no-actions"}
								onClick={() => {
									if (userRecord.IsOpsProjectManager)
										setWaveEditModal(!waveEditModal)
								}}
							/>
						</>
					}
					selected={currentWaveSpec}
				//   labelGroup={"FieldingCountriesOptions"}
				/>
			</Col>
		);
	};
	const scheduleNotesHandler = (eve) => {
		let notesActual = {}
		// let waveSpecsToSave = [...waveSpecs];
		setwavesSpecsData(profile.WaveSpecs)
		let currentwavespec = checkCurrentWaveSpec(profile, selectedItemWave);
		setcurrentWaveSpec(currentwavespec);
		if (currentwavespec.NotesActual)
			notesActual = { ...currentwavespec.NotesActual }

		if (!notesActual.ScheduleNotes)
			notesActual.ScheduleNotes = {}

		notesActual.ScheduleNotes[eve.target.id] = eve.target.value

		onChangeHandler(
			{
				target: {
					id: "NotesActual",
					value: notesActual
				}
			})
	}
	const onChangeHandler = (eve, useCurrentSpec, isApi) => {
		let currentwavespec = {};
		let profileToSave = { ...profile };
		currentwavespec = checkCurrentWaveSpec(profileToSave, selectedItemWave);
		setcurrentWaveSpec(currentwavespec);
		if (useCurrentSpec) {
			currentwavespec = {
				...useCurrentSpec,
				[eve.target.id]: eve.target.value,
			};
		} else {
			currentwavespec = {
				...currentwavespec,
				[eve.target.id]: eve.target.value,
			};
		}
		let wavespecs = [...profile.WaveSpecs];
		wavespecs = wavespecs.map((ws) => {
			if (ws.id == currentwavespec.id) {
				ws = currentwavespec;
			}
			return { ...ws };
		});
		setcurrentWaveSpec(currentwavespec);
		let mappedData = modifiedMeths.map(x => {
			if (x.id === currentwavespec.id) {
			  x = { ...currentwavespec }
			}
			return x;
		  })
		  setModifiedMeths(mappedData);
		setwavesSpecsData(wavespecs);
	
		const profileTemp = update(profileToSave, {
			WaveSpecs: { $set: wavespecs }
		});
		dispatch(currentCostingActions.updateProfile(profileTemp));	
		setEditableCosting(profileTemp);
		if (isApi) {
			setWaveEditModal(false);
		}
	};

	
	return (
		<>
			<>
				<Container className="review-schedule">
					<Card
						className="ml-2 mr-2 mb-0 p-0"
						style={{ background: "none", boxShadow: "none" }}
					>
						<Row>
							{renderSelector()}
							<Col>
								<Card className="mb-2">
									<CardHeader className="border-bottom border-light">
										<Row>
											<Col lg="3" md="3" sm="12" xs="12">
												<strong>Wave Schedule</strong>
											</Col>
											<Col lg="3" md="3" sm="12" xs="12">
												<strong>Notes</strong>
											</Col>
											<Col lg="3" md="3" sm="12" xs="12">
												<strong>Planned Dates</strong>
											</Col>
											<Col lg="3" md="3" sm="12" xs="12">
												<strong>Actual Dates</strong>
											</Col>
										</Row>
									</CardHeader>
									<CardBody>{Object.keys(Fields).map((field, index) => {
										return (
											<Row key={index} className="mb-2 border-bottom border-light">
												<Col lg="3" md="3" sm="12" xs="12">
													{field} {currentWaveSpec[Fields[field].decisiveField] ? <span className="decisive-pop-value">{currentWaveSpec[Fields[field].decisiveField]}</span> : null}
												</Col>
												<Col lg="3" md="3" sm="12" xs="12">
													<textarea id={Fields[field].NotesField.id}
														disabled={!userRecord.IsOpsProjectManager||disableforCS}
														readOnly={!userRecord.IsOpsProjectManager}
														value={currentWaveSpec.NotesActual
															&& currentWaveSpec.NotesActual.ScheduleNotes
															&& currentWaveSpec.NotesActual.ScheduleNotes[Fields[field].NotesField.id]
															? currentWaveSpec.NotesActual.ScheduleNotes[Fields[field].NotesField.id]
															: ""}
														onChange={scheduleNotesHandler}
														className="form-control">
													</textarea>
												</Col>
												<Col lg="3" md="3" sm="12" xs="12">
													<span>{currentWaveSpec[Fields[field].DisplayDate] ?
														moment(currentWaveSpec[Fields[field].DisplayDate]).format("ll")
														: "-"}</span>
												</Col>
												<Col lg="3" md="3" sm="12" xs="12">
													<input type="date" id={Fields[field].DateField.id}
														disabled={!userRecord.IsOpsProjectManager||disableforCS}
														readOnly={!userRecord.IsOpsProjectManager}
														className="form-control"
														onChange={onChangeHandler}
														value={currentWaveSpec[Fields[field].DateField.id] ? _.head(currentWaveSpec[Fields[field].DateField.id].split("T")) : ""} />
												</Col>
											</Row>
										);
									})}
									</CardBody>
								</Card>
								<hr></hr>
								<div className="mb-0">
									<CardBody>
										{/* <Row className="mb-2">
											<Col>
												<Label>Notes for Data/Measurement Science</Label>

												<Input
													className="mb-2"
													type="textarea"
													id="NotesDataScienceActual"
													onChange={scheduleNotesHandler}
													disabled={!userRecord.IsOpsProjectManager}
													readOnly={!userRecord.IsOpsProjectManager}
													value={
														currentWaveSpec.NotesActual
															&& currentWaveSpec.NotesActual.ScheduleNotes
															&& currentWaveSpec.NotesActual.ScheduleNotes["NotesDataScienceActual"]
															? currentWaveSpec.NotesActual.ScheduleNotes["NotesDataScienceActual"]
															: ""
													}
													placeholder="Please provide any data/measurement science related notes here..."
												/>
											</Col>
										</Row> */}
										<Row>
											<Col>
												<Label>Other schedule related notes</Label>

												<Input
													id="NotesOtherActual"
													disabled={!userRecord.IsOpsProjectManager||disableforCS}
													readOnly={!userRecord.IsOpsProjectManager}
													type="textarea"
													className="mb-2"
													onChange={scheduleNotesHandler}
													value={
														currentWaveSpec.NotesActual
															&& currentWaveSpec.NotesActual.ScheduleNotes
															&& currentWaveSpec.NotesActual.ScheduleNotes["NotesOtherActual"]
															? currentWaveSpec.NotesActual.ScheduleNotes["NotesOtherActual"]
															: ""
													}
													placeholder="Any other notes or topics not covered elsewhere..."
												/>
											</Col>
										</Row>
									</CardBody>

								</div>
							</Col>
						</Row>
					</Card>
				</Container>
			</>

			<Modal
				isOpen={waveEditModal}
				toggle={() => setWaveEditModal(!waveEditModal)}
				centered
			>
				<ModalHeader toggle={() => setWaveEditModal(!waveEditModal)}>
					Edit Current Wave Name
				</ModalHeader>
				<ModalBody>
					<Input
						defaultValue={
							currentWaveSpec.WaveName ? currentWaveSpec.WaveName : ""
						}
						id="WaveName"
						onChange={(e) => setEditableWavename(e.target.value)}
						placeholder="Enter Wave Name"
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						color="primary"
						disabled={app.recordloading||disableforCS}
						onClick={(e) => {
							onChangeHandler(
								{ target: { id: "WaveName", value: editableWaveName } },
								null,
								true
							);
						}}
					>
						Update
						{app.recordloading ? (
							<Spinner size="small" color="#495057" />
						) : null}
					</Button>
					<Button
						color="secondary"
						disabled={app.recordloading}
						onClick={() => setWaveEditModal(false)}
					>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>

		</>
	);
};

export default ReviewSchedule;
